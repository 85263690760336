const intialState = {
    propinsi: [
        {
            id: 'p1',
            propinsi: "Bali"
        },
        {
            id: 'p2',
            propinsi: "Bangka Belitung"
        },
        {
            id: 'p3',
            propinsi: "Banten"
        },
        {
            id: 'p4',
            propinsi: "Bengkulu"
        },
        {
            id: 'p5',
            propinsi: "DI Yogyakarta"
        },
        {
            id: 'p6',
            propinsi: "DKI Jakarta"
        },
        {
            id: 'p7',
            propinsi: "Gorontalo"
        },
        {
            id: 'p8',
            propinsi: "Jambi"
        },
        {
            id: 'p9',
            propinsi: "Jawa Barat"
        },
        {
            id: 'p10',
            propinsi: "Jawa Tengah"
        },
        {
            id: 'p11',
            propinsi: "Jawa Timur"
        },
        {
            id: 'p12',
            propinsi: "Kalimantan Barat"
        },
        {
            id: 'p13',
            propinsi: "Kalimantan Selatan"
        },
        {
            id: 'p14',
            propinsi: "Kalimantan Tengah"
        },
        {
            id: 'p15',
            propinsi: "Kalimantan Timur"
        },
        {
            id: 'p16',
            propinsi: "Kalimantan Utara"
        },
        {
            id: 'p17',
            propinsi: "Kepulauan Riau"
        },
        {
            id: 'p18',
            propinsi: "Lampung"
        },
        {
            id: 'p19',
            propinsi: "Maluku"
        },
        {
            id: 'p20',
            propinsi: "Maluku Utara"
        },
        {
            id: 'p21',
            propinsi: "Nanggroe Aceh Darussalam"
        },
        {
            id: 'p22',
            propinsi: "Nusa Tenggara Barat"
        },
        {
            id: 'p23',
            propinsi: "Nusa Tenggara Timur"
        },
        {
            id: 'p24',
            propinsi: "Papua"
        },
        {
            id: 'p25',
            propinsi: "Papua Barat"
        },
        {
            id: 'p26',
            propinsi: "Riau"
        },
        {
            id: 'p27',
            propinsi: "Sulawesi Barat"
        },
        {
            id: 'p28',
            propinsi: "Sulawesi Selatan"
        },
        {
            id: 'p29',
            propinsi: "Sulawesi Tengah"
        },
        {
            id: 'p30',
            propinsi: "Sulawesi Tenggara"
        },
        {
            id: 'p31',
            propinsi: "Sulawesi Utara"
        },
        {
            id: 'p32',
            propinsi: "Sumatera Barat"
        },
        {
            id: 'p33',
            propinsi: "Sumatera Selatan"
        },
        {
            id: 'p34',
            propinsi: "Sumatera Utara"
        },
    ],
    kabupaten: {
        "p1": [
            {
                "id": "k1",
                "kabupaten": "Jembrana",
                "SMA": [
                    "SMAN 1 Melaya",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k2",
                "kabupaten": "Tabanan",
                "SMA": [
                    "SMAN 1 Tabanan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k3",
                "kabupaten": "Buleleng",
                "SMA": [
                    "SMAN 1 Singaraja",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k4",
                "kabupaten": "Karangasem"
            },
            {
                "id": "k5",
                "kabupaten": "Klungkung",
                "SMA": [
                    "SMAN 1 Semarapura",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k6",
                "kabupaten": "Bangli"
            },
            {
                "id": "k7",
                "kabupaten": "Gianyar",
                "SMA": [
                    "SMAN 1 UBUD",
                    "SMAN 1 Sukawati", 
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k8",
                "kabupaten": "Badung",
                "SMA": [
                    "SMAS Katolik Soverdi Tuban Bali",
                    "SMAN 1 Kuta Selatan" ,
                    "SMAN 3 Kuta Selatan",
                    "SMAN 1 Kuta",
                    "SMA Jembatan Budaya",
                    "SMAN 1 Kuta Utara",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k9",
                "kabupaten": "Denpasar",
                "SMA": [
                    "SMAN 3 Denpasar",
                    "SMAN 2 Denpasar",
                    "SMAN 1 Denpasar",
                    "SMA Kristen Harapan",
                    "SMA Albanna Bali",
                    "SMAS K Santo Yoseph Denpasar",
                    "SMAN 9 Denpasar",
                    "SMA Tunas Daud"
                ],
                "SMK": [
                    "SMK PGRI 5 Denpasar",
                    "SMKN 1 Denpasar"
                ],
                "MA (Non Pesantren)": [
                    "MA AL-Ma'ruf"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p2": [
            {
                "id": "k10",
                "kabupaten": "Bangka Selatan"
            },
            {
                "id": "k11",
                "kabupaten": "Bangka Tengah",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "Man Insan Cendekia Bangka Tengah"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k12",
                "kabupaten": "Belitung Timur",
                "SMA": [
                    "SMA Negeri 1 Manggar",
                    "SMA Al-Bayan"
                ],
                "SMK": [
                    "SMKN 1 Kelapa Kampit"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k13",
                "kabupaten": "Belitung"
            },
            {
                "id": "k14",
                "kabupaten": "Bangka Barat",
                "SMA": [
                    "SMAN 1 Muntok",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k15",
                "kabupaten": "Bangka",
                "SMA": [
                    "SMAN 1 Pemali",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k16",
                "kabupaten": "Pangkal Pinang",
                "SMA": [
                    "SMA Santo Yosef",
                    "SMAN 4  Pangkal Pinang",
                    "SMAN 3 Pangkal Pinang",
                    "SMA Santa Yosef",
                ],
                "SMK": [
                    "SMK Tunas Karya"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p3": [
            {
                "id": "k17",
                "kabupaten": "Serang",
                "SMA": [
                    "SMAN 1 Kota Serang",
                    "SMAN 2 Kota Serang",
                    "SMAN 3 Kota Serang",
                    "SMAN 1 Ciruas",
                    "SMAI Nurul Fikri Boarding School Serang",
                    "SMAIT Bina Insani",
                    "SMAN 1 Kramatwatu",
                ],
                "SMK": [
                    "SMK Ambura",
                    "SMK Kimia PGRI Kota Serang",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Serang "
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k18",
                "kabupaten": "Cilegon",
                "SMA": [
                    "SMAN 1 Cilegon",
                    "SMAN 2 Krakatau Steel Cilegon",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "SMAI Nurul Fikri Boarding School Serang"
                ]
            },
            {
                "id": "k19",
                "kabupaten": "Lebak"
            },
            {
                "id": "k20",
                "kabupaten": "Pandeglang",
                "SMA": [
                    "SMAN Cahaya Madani Banten Boarding School",
                    "SMAN 5 Pandeglang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k21",
                "kabupaten": "Tangerang",
                "SMA": [
                    "SMA Strada St. Thomas Aquino",
                    "SMA UPH College Distance Learning",
                    "SMAN 15 Kota Tangerang",
                    "SMA PGRI 83 Legok",
                    "SMA Muhammadiyah 3 Kota Tangerang",
                    "SMA Muhammadiyah 4 Kota Tangerang",
                    "SMA Mutiara Bangsa 1",
                    "SMAN 12 Kota Tangerang",
                    "SMAN 28 Kabupaten Tangerang",
                    "SMA Citra Islami",
                    "SMA YP Karya",
                    "SMAN 14 Kabupaten Tangerang",
                    "SMAN 3 Kabupaten Tangerang",
                    "SMA GenIUS",
                    "SMAN 6 Kabupaten Tangerang",
                    "SMA Mutiara Bangsa 2",
                    "SMAN 8 Tangerang",
                    "SMAK Penabur Kota Tangerang",
                    "SMAN 1 Kota Tangerang",
                    "SMAN 6 Kabupaten Tangerang",
                    "SMA Pahoa",
                    "SMAN 19 Kabupaten Tangerang",
                    "SMAN 17 Kabupaten Tangerang",
                    "SMA Muhammadiyah 2 Cipondoh",
                    "SMA Gracia School",
                    "SMA Muhammadiyah 2 Kota Tangerang",
                ],
                "SMK": [
                    "SMK PGRI 1 Tangerang",
                    "SMK AL-Barkah",
                    "SMK AL-Husna",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Tangerang",
                    "MAN 2 Tangerang", 
                    "MA AL Istiqomah",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k22",
                "kabupaten": "Tangerang Selatan",
                "SMA": [
                    "SMA Arif Rahman Hakim",
                    "SMA Mentari Intercultural School Bintaro",
                    "SMA Dua Mei",
                    "SMAS Syafana Islamic School",
                    "SMA Plus Pembangunan Jaya",
                    "SMA Athalia ",
                    "SMA Bina Nusantara Serpong",
                    "SMAK BPK Penabur Bintaro Jaya",
                    "SMA Technosa School",
                    "SMA Labschool Cirendeu ",
                    "SMA Al-Fath Cireundeu",
                    "SMA Santa Laurensia",
                    "SMAN 2 Kota Tangerang Selatan",
                    "SMAN 8 Kota Tangerang Selatan",
                    "SMA Triguna Utama Syarif Hidayatullah",
                    "SMA Kharisma Bangsa",
                    "SMAN 6 Kota Tangerang Selatan",
                    "SMAN 11 Kota Tangerang Selatan",
                    "SMAN 12 Kota Tangerang Selatan",
                    "SMAI Sinar Cendekia",
                    "SMA Saint John's Catholic School",
                    "SMA Cikal Serpong",
                    "SMAN 1 Kota Tangerang Selatan",
                    "SMAN 4 Kota Tangerang Selatan",
                    "SMA Global Islamic School 2 Serpong",
                    "SMA Taruna Mandiri",
                    "SMA Waskito",
                    "SMA Mutiara Harapan",
                    "SMAN 10 Kota Tangerang Selatan",
                    "SMA Islam Al-Azhar BSD",
                    "SMA Kharisma Bangsa",
                    "SMAK Mater Dei Pamulang",
                ],
                "SMK": [
                    "SMK Sasmita Jaya 2",
                    "SMK Mega Bangsa",
                    "SMK Bina Informatika Bintaro",
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Serpong",
                    "MAN IC Serpong",
                    "MA Pembangunan UIN Jakarta"
                ],
                "Pesantren": [
                    "Pesantren Peradaban Dunia Jagat 'Arsy"
                ]
            }
        ],
        "p4": [
            {
                "id": "k23",
                "kabupaten": "Kepahiang"
            },
            {
                "id": "k24",
                "kabupaten": "Lebong"
            },
            {
                "id": "k25",
                "kabupaten": "Rejang Lebong",
                "SMA": [
                    "SMAN 1 Rejang Lebong",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k26",
                "kabupaten": "Kaur"
            },
            {
                "id": "k27",
                "kabupaten": "Seluma"
            },
            {
                "id": "k28",
                "kabupaten": "Muko Muko"
            },
            {
                "id": "k29",
                "kabupaten": "Bengkulu Utara",
                "SMA": [
                    "SMAN 2 Bengkulu Utara",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k30",
                "kabupaten": "Bengkulu Selatan"
            },
            {
                "id": "k31",
                "kabupaten": "Bengkulu Tengah"
            },
            {
                "id": "k32",
                "kabupaten": "Bengkulu",
                "SMA": [
                    "SMAS Sint Carolus Bengkulu",
                    "SMAN 5 Kota Bengkulu",
                    "SMAIT IQRA Kota Bengkulu",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p5": [
            {
                "id": "k33",
                "kabupaten": "Gunung Kidul"
            },
            {
                "id": "k34",
                "kabupaten": "Bantul",
                "SMA": [
                    "SMAN 1 Sedayu",
                    "SMAN 1 Jetis",
                    "SMAN 1 Bantul",
                    "SMAN 1 Bangunrapan",
                    "SMA Pangudi Luhur St. Louis IX Sedayu",
                    "SMAN 2 Bantul",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k35",
                "kabupaten": "Kulon Progo",
                "SMA": [
                    "SMAIT Abu Bakar Boarding School Kulon Progo",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k36",
                "kabupaten": "Sleman",
                "SMA": [
                    "SMA Cahaya Bangsa Utama",
                    "SMAN 1 Prambanan",
                    "SMA Dan Pesantren Bumi Cendekia",
                    "SMA Sains Al-Qur'an ",
                    "SMAN 1 Sleman",
                    "SMA Olifant",
                    "SMAN 1 Pakem",
                    "SMAN 2 Sleman",
                    "SMA Kolese De Britto",
                    "SMA Masa Depan",
                ],
                "SMK": [
                    "SMKN 1 Depok Sleman"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MAN 3 Sleman",
                    "MA Sunan Pandanaran",
                    "SMA Sains Wahid Hasyim",
                    "SMA Sains Al-Qur'an",
                    "MA Darul Quran Wahid Hasyim",
                ]
            },
            {
                "id": "k37",
                "kabupaten": "Yogyakarta",
                "SMA": [
                    "SMA Muhammadiyah 2 Yogyakarta",
                    "SMA Stella Duce 1 Yogyakarta",
                    "SMAN 6 Yogyakarta",
                    "SMAN 8 Yogyakarta",
                    "SMA Muhammadiyah 1 Yogyakarta",
                    "SMA Santa Maria Yogyakarta",
                    "SMAIT Abu Bakar Yogyakarta",
                    "SMAN 3 Yogyakarta",
                    "SMAN 7 Yogyakarta",
                    "SMAN 11 Yogyakarta",
                    "SMAN 1 Yogyakarta",
                    "SMA BOPKRI 1 Yogyakarta",
                    "SMAN 9 Yogyakarta",
                    "SMAN 2 Yogyakarta",
                ],
                "SMK": [
                    "SMKN 7 Yogyakarta",
                    "SMK SMTI Yogyakarta",
                    "SMK Negeri 5 Yogyakarta",
                    "SMK SMF Indonesia Yogyakarta",
                ],
                "MA (Non Pesantren)": [
                    "Madrasah Mu'allimin Muhammadiyah Yogyakarta",
                    "Madrasah Mu'allimaat Muhammadiyah Yogyakarta",
                    "MAN 2 Yogyakarta",
                ],
                "Pesantren": [
                    "Madrasah Mu'allimin Muhammadiyah Yogyakarta",
                    "Madrasah Mu'allimaat Muhammadiyah Yogyakarta",
                ]
            }
        ],
        "p6": [
            {
                "id": "k38",
                "kabupaten": "Kepulauan Seribu",
                "SMA": [
                    "SMAN 69 Jakarta"
                ],
                "SMK": [
                    "SMKN 61 Jakarta"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k39",
                "kabupaten": "Jakarta Utara",
                "SMA": [
                    "SMAN 72 Jakarta Utara",
                    "SMAN 110 Jakarta Utara",
                    "SMA Laphat Plus",
                    "SMA Yappenda",
                    "SMAN 111 Jakarta Utara",
                    "SMA Nurul Falah",
                    "SMA Kristen Calvin",
                    "SMA Permai",
                    "SMAS Fons Vitae 2 Marsudirini Jakarta Utara",
                    "SMA Dharma Suci",
                    "SMAN 92 Jakarta Utara",
                    "SMA Jubilee Jakarta Utara",
                    "SMAK BPK Penabur Jakarta Utara",
                ],
                "SMK": [
                    "SMKN 33 Jakarta Utara",
                    "SMK Kesehatan Global Cendekia",
                ],
                "MA (Non Pesantren)": [
                    "MAN 21 Jakarta Utara"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k40",
                "kabupaten": "Jakarta Timur",
                "SMA": [
                    "SMA Islam PB Soedirman Jakarta Timur",
                    "SMA St Antonius",
                    "SMAN 42 Jakarta Timur",
                    "SMAN 21 Jakarta Timur",
                    "SMAN 71 Jakarta Timur",
                    "SMA Muhammadiyah 12 Jakarta Timur",
                    "SMAN 9 Jakarta Timur",
                    "SMA PGRI 24 Jakarta Timur",
                    "SMAN 31 Jakarta Timur",
                    "SMA Diponegoro 1 Jakarta Timur",
                    "SMAN 104 Jakarta Timur",
                    "SMA Muhammadiyah 4 Jakarta Timur",
                    "SMAN 36 Jakarta Timur",
                    "SMAN 11 Jakarta Timur",
                    "SMAN 62 Jakarta Timur ",
                    "SMAN 48 Jakarta Timur",
                    "SMAN 107 Jakarta Timur",
                    "SMA Fons Vitae 1",
                    "SMA Angkasa 2 Jakarta Timur",
                    "SMAK 7 Penabur Jakarta Timur",
                    "SMAN 53 Jakarta Timur",
                    "SMAN 99 Jakarta Timur",
                    "SMAS Angkasa 1 Jakarta Timur",
                    "SMAN 88 Jakarta Timur",
                    "SMA PGRI 4 Jakarta Timur",
                    "SMAN 58 Jakarta Timur",
                    "SMAN 61 Jakarta Timur",
                    "SMAS Santo Yoseph Jakarta Timur",
                    "SMAN 14 Jakarta Timur",
                    "SMAN 53 Jakarta Timur",
                    "SMAN 81 Jakarta Timur",
                    "SMAN 91 Jakarta Timur",
                    "SMAN 51 Jakarta Timur",
                    "SMANU M.H. Thamrin",
                    "SMA Ignatius Slamet Riyadi",
                    "SMA Muhammadiyah 11 Jakarta Timur",
                    "SMA Jakarta Islamic School",
                    "SMA Muhammadiyah 23 Jakarta Timur",
                    "SMAN 54 Jakarta Timur",
                    "SMA Labschool Jakarta Timur",
                    "SMAS Perguruan Rakyat 3",
                    "SMA Prestasi Prima",
                    "SMAIT Buahati",
                    "SMA Global Islamic School",
                ],
                "SMK": [
                    "SMKN 66 Jakarta Timur",
                    "SMKN 67 Jakarta Timur",
                    "SMK Perguruan Rakyat 2",
                    "SMK 24 Jakarta Timur",
                ],
                "MA (Non Pesantren)": [
                    "MAN 14 Jakarta Timur",
                    "MAN 8 Jakarta Timur",
                    "MA Al-Falah Klender",
                    "MAN 2 Jakarta Timur",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k41",
                "kabupaten": "Jakarta Selatan",
                "SMA": [
                    "SMAN 3 Jakarta Selatan",
                    "SMAN 109 Jakarta Selatan",
                    "SMA Menlo Park School",
                    "SMAN 70 Jakarta Selatan",
                    "SMAN 55 Jakarta Selatan",
                    "SMA Labschool Kebayoran",
                    "SMAN 8 Jakarta Selatan",
                    "SMAS 17 Agustus 1945 Jakarta Selatan",
                    "SMA Muhammadiyah 3 Jakarta Selatan",
                    "SMAN 87 Jakarta Selatan ",
                    "SMAN 34 Jakarta Selatan",
                    "SMA Islam Al-Azhar 2 Pejaten",
                    "SMAN 49 Jakarta Selatan",
                    "SMAN 82 Jakarta Selatan",
                    "SMA Sumbangsih",
                    "SMAN 28 Jakarta Selatan",
                    "SMA Islam Al-Izhar Pondok Labu",
                    "SMAN 37 Jakarta Selatan ",
                    "SMAN 90 Jakarta Selatan ",
                    "SMAN 6 Jakarta Selatan",
                    "SMA Kolese Gonzaga",
                    "SMAN 79 Jakarta Selatan",
                    "SMAN 63 Jakarta Selatan",
                    "SMA Sekolah Murid Merdeka",
                    "SMA Garuda Cendekia",
                    "SMA Tarakanita 1 Jakarta Selatan",
                    "SMA Bakti Mulya 400",
                    "SMA Citra Alam",
                    "SMAN 60 Jakarta Selatan ",
                    "SMA Islam Al-Azhar 1 Jakarta",
                    "SMA Hang Tuah 1 Jakarta",
                    "SMAN 47 Jakarta Selatan",
                    "SMAN 38 Jakarta Selatan",
                    "SMA Cenderawasih 1 Jakarta Selatan",
                    "SMAN 26 Jakarta Selatan",
                    "SMA Islam Harapan Ibu",
                    "SMA HighScope Indonesia",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 13 Jakarta Selatan",
                    "MAN 4 Jakarta Selatan",
                ],
                "Pesantren": [
                    "Ma'had Al azhary Indonesia"
                ]
            },
            {
                "id": "k42",
                "kabupaten": "Jakarta Barat",
                "SMA": [
                    "SMA Muhammadiyah 13 Jakarta",
                    "SMAK Kalam Kudus",
                    "SMAN 78 Jakarta Barat",
                    "SMAS YADIKA 5",
                    "SMA Mutiara Bangsa 3",
                    "SMAK Sang Timur Jakarta",
                    "SMAN 2 Jakarta Barat",
                    "SMAN 16 Jakarta Barat",
                    "SMAK St Kristoforus 1",
                    "SMAN 65 Jakarta Barat ",
                    "SMAN 96 Jakarta Barat",
                    "Nafiri Study Center",
                    "SMA Al-Chasanah",
                    "SMA Santo Leo II",
                    "SMA Vianney",
                    "SMAK Petra Alpha ",
                    "SMAK 1 BPK Penabur Jakarta",
                    "SMA Sumpah Pemuda",
                    "SMAK 8 Penabur Tanjung Duren",
                ],
                "SMK": [
                    "SMKN 45 Jakarta Barat"
                ],
                "MA (Non Pesantren)": [
                    "MA Al-Falah",
                    "MAN 22 Jakarta Barat",
                    "MAN 12 Jakarta",
                ],
                "Pesantren": [
                    "MA Manba'ul Ulum Asshiddiqiyah",
                ]
            },
            {
                "id": "k43",
                "kabupaten": "Jakarta Pusat",
                "SMA": [
                    "SMA Santa Theresia",
                    "SMAN 25 Jakarta Pusat",
                    "SMA 3 PSKD",
                    "SMA Mahatma Gandhi School Kemayoran",
                    "SMAN 35 Jakarta Pusat",
                    "SMAN 4 Jakarta Pusat",
                    "SMAN 68 Jakarta Pusat",
                    "SMAN 30 Jakarta Pusat ",
                    "SMAN 77 Jakarta Pusat",
                    "SMA Kartini 1",
                    "SMAS Muhammadiyah 1 Jakarta Pusat",
                    "SMA Santa Ursula Jakarta Pusat",
                    "SMA Bunda Mulia",
                    "SMAS Muhammadiyah 2 Jakarta Pusat",
                ],
                "SMK": [
                    "SMKN 27 Jakarta Pusat",
                    "SMKN 31 Jakarta Pusat",
                    "SMKN 34 Jakarta Pusat",
                    "SMK Santa Maria Juanda",
                    "SMK Farmasi DITKESAD",
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p7": [
            {
                "id": "k44",
                "kabupaten": "Bone Bolango",
                "SMA": [
                ],
                "SMK": [
                    "SMKS Bina Mandiri Bone Bolango"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k45",
                "kabupaten": "Gorontalo Utara"
            },
            {
                "id": "k46",
                "kabupaten": "Pohuwato"
            },
            {
                "id": "k47",
                "kabupaten": "Gorontalo",
                "SMA": [
                    "SMAN 3 Kota Gorontalo",
                    "SMAN 1 Limboto",
                ],
                "SMK": [
                    "SMKN 1 Mootilango"
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Gorontalo"
                ],
                "Pesantren": [
                    "SMA IT Al-Ishlah Boarding School Gorontalo"
                ]
            },
            {
                "id": "k48",
                "kabupaten": "Boalemo"
            }
        ],
        "p8": [
            {
                "id": "k49",
                "kabupaten": "Tebo"
            },
            {
                "id": "k50",
                "kabupaten": "Sarolangun"
            },
            {
                "id": "k51",
                "kabupaten": "Merangin",
                "SMA": [
                    "SMAN 8 Merangin",
                    "SMA Hopes and Dreams",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Jambi"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k52",
                "kabupaten": "Bungo",
                "SMA": [
                    "SMAN 1 Bungo",
                    "SMAN 9 Bungo",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k53",
                "kabupaten": "Kerinci"
            },
            {
                "id": "k54",
                "kabupaten": "Sungaipenuh",
                "SMA": [
                    "SMAN 1 Sungai Penuh ",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Sungai Penuh"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k55",
                "kabupaten": "Tanjung Jabung Timur"
            },
            {
                "id": "k56",
                "kabupaten": "Batang Hari"
            },
            {
                "id": "k57",
                "kabupaten": "Tanjung Jabung Barat"
            },
            {
                "id": "k58",
                "kabupaten": "Muaro Jambi"
            },
            {
                "id": "k59",
                "kabupaten": "Jambi",
                "SMA": [
                    "SMA Xaverius 1 Kota Jambi",
                    "SMAN 1 Kota Jambi",
                    "SMA Unggul Sakti",
                    "SMAN 5 Kota Jambi",
                    "SMAN 3 Kota Jambi",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Kota Jambi"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p9": [
            {
                "id": "k60",
                "kabupaten": "Tasikmalaya",
                "SMA": [
                    "SMAN 1 Tasikmalaya",
                    "SMAN 1 Karangnuggal",
                    "SMAN 4 Tasikmalaya",
                ],
                "SMK": [
                    "SMK Informatika Sumedang"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k61",
                "kabupaten": "Pangandaran"
            },
            {
                "id": "k62",
                "kabupaten": "Ciamis",
                "SMA": [
                    "SMAN 1 Ciamis",
                    "SMAN 2 Ciamis",
                ],
                "SMK": [
                    "SMKN 1 Ciamis"
                ],
                "MA (Non Pesantren)": [
                    "MA Persis 80 Al Amin Sindangkasih",
                    "MAN 4 Ciamis",
                ],
                "Pesantren": [
                    "MAN 1 Darussalam Ciamis"
                ]
            },
            {
                "id": "k63",
                "kabupaten": "Banjar",
                "SMA": [
                    "SMAN 3 Banjar"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k64",
                "kabupaten": "Cirebon",
                "SMA": [
                    "SMA Putra Nirmala Cirebon",
                    "SMAN 1 Waled Kabupaten Cirebon",
                    "SMAN 1 Ciledug",
                    "SMAN 1 Sumber",
                    "SMA Pelita Bangsa Cirebon",
                    "SMAN 1 Palimanan",
                    "SMAN 1 Kota Cirebon",
                    "SMAN 7 Cirebon",
                    "SMAN 1 Gegesik",
                    "SMAN 2 Cirebon ",
                ],
                "SMK": [
                    "SMKN 1 Kedawung"
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Cirebon",
                    "MAN 3 Cirebon",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k65",
                "kabupaten": "Kuningan",
                "SMA": [
                    "SMAN 1 Mandirancan",
                    "SMAN 2 Kuningan",
                    "SMA IT Ainurrafiq",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MA Husnul Khotimah"
                ]
            },
            {
                "id": "k66",
                "kabupaten": "Majalengka",
                "SMA": [
                    "SMAS Al-Mizan",
                ],
                "SMK": [
                    "SMKN 1 Palasah"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k67",
                "kabupaten": "Sumedang",
                "SMA": [
                    "SMA Plus Al-Aqsha",
                    "SMAN Darmaraja",
                    "SMAN 1 Sumedang",
                    "SMAN 1 Cimalaka",
                    "SMAN 3 Sumedang",
                    "SMA IT Insan Sejahtera",
                    "SMAN Tomo",
                    "SMAN Tanjungsari",
                ],
                "SMK": [
                    "SMK Informatika Sumedang"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k68",
                "kabupaten": "Indramayu",
                "SMA": [
                    "SMAN 1 Krangkeng",
                    "SMA Juara Wirautama",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k69",
                "kabupaten": "Garut",
                "SMA": [
                    "SMA Welas Asih",
                    "SMAN 2 Garut",
                ],
                "SMK": [
                    "SMK 12 Garut"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Peacesantren Welas Asih",
                    "Pondok Pesantren Darul Arqam Muhammadiyah Garut",
                ]
            },
            {
                "id": "k70",
                "kabupaten": "Sukabumi",
                "SMA": [
                    "SMA PU Al-Bayan Putri Selabintana",
                    "SMA PU Al-Bayan Cibadak",
                    "SMA PU Al-Bayan Putri Goalpara",
                    "SMAN 1 Ciemas",
                    "SMAN 3 Kota Sukabumi",
                    "SMAN 1 Cibadak ",
                    "SMAN 1 Nyalindung",
                    "SMAN 1 Cicurug",
                    "SMA Mardi Yuana Sukabumi",
                    "SMAN 1 Surade",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 4 Sukabumi"
                ],
                "Pesantren": [
                    "SMA PU Al-Bayan Putri Selabintana",
                    "SMA PU Al-Bayan Cibadak",
                    "SMA PU Al-Bayan Putri Goalpara"
                ]
            },
            {
                "id": "k71",
                "kabupaten": "Cianjur",
                "SMA": [
                    "SMAN 1 Cianjur",
                    "SMAN 1 Ciranjang",
                ],
                "SMK": [
                    "SMKN 1 Bojongpicung"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "SMA Plus Al-Ittihad",
                ]
            },
            {
                "id": "k72",
                "kabupaten": "Karawang",
                "SMA": [
                    "SMA Tunas Dharma",
                    "SMAN 1 Telukjambe",
                    "SMAN 1 Karawang",
                    "SMAN 3 Karawang",
                    "SMAN 2 Karawang",
                ],
                "SMK": [
                    "SMK 2 Karawang",
                    "SMKN 1 Jatisari"
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Karawang"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k73",
                "kabupaten": "Subang",
                "SMA": [
                    "SMAN 1 Subang",
                    "SMAIT As-Syifa Boarding School Jalancagak",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pondok Pesantren As-Syifa Sagalaherang",
                ]
            },
            {
                "id": "k74",
                "kabupaten": "Purwakarta",
                "SMA": [
                    "SMAN 2 Purwakarta", 
                    "SMAN 1 Purwakarta",
                    "SMA Al-Muhajirin Purwakarta"
                ],
                "SMK": [
                    "SMKN 2 Purwakarta",
                    "SMK Al-Muhajirin 3 Purwakarta"
                ],
                "MA (Non Pesantren)": [
                    "MAN Purwakarta"
                ],
                "Pesantren": [
                    "AQL Islamic School 2 Purwakarta",
                ]
            },
            {
                "id": "k75",
                "kabupaten": "Bandung",
                "SMA": [
                    "SMAN 2 Bandung ",
                    "SMAN 22 Bandung",
                    "SMA PGRI Rancaekek",
                    "SMAN 1 Rancaekek ",
                    "SMAN 4 Bandung",
                    "SMAN 5 Bandung",
                    "SMAN 1 Majalaya",
                    "SMAN 23 BANDUNG",
                    "SMAK 3 Bina Bakti",
                    "SMAN 7 Bandung",
                    "SMAN 1 Baleendah",
                    "SMAN 15 Bandung",
                    "SMA BPI 1 Bandung",
                    "PKBM Ahsani",
                    "SMA Islam Cendekia Muda",
                    "SMAN 11 Bandung ",
                    "SMAN 25 Bandung",
                    "SMA Taruna Bakti",
                    "SMAN 3 Bandung",
                    "SMA Telkom Bandung",
                    "SMAN 20 Bandung",
                    "SMAN 1 Margahayu ",
                    "SMA Pasundan 2 Bandung",
                    "SMAN 13 Bandung ",
                    "SMAN 1 Cileunyi",
                    "SMAN 1 Ciwidey",
                    "SMA Tunas Unggul",
                    "SMA Muhammadiyah 1 Bandung",
                    "SMA Alfa Centauri",
                    "SMA Bina Bangsa Bandung",
                    "SMA Pribadi Bilingual Boarding School Bandung",
                    "SMAN 6 Bandung ",
                    "SMA Laboratorium Percontohan UPI",
                    "SMA Edu Global Bandung",
                    "SMAK 1 BPK Penabur Bandung",
                    "SMA Santa Angela",
                    "SMAS Taruna Bakti ",
                    "SMA Daarut Tauhiid Boarding School Putri",
                    "SMAN 8 Bandung",
                    "SMAN 19 Bandung ",
                    "SMAN 21 Bandung ",
                    "SMA Mekar Arum",
                    "SMA Darul Hikam ",
                    "SMA Edu Global School",
                    "SMAN 18 Bandung",
                    "SMAN 1 Bandung",
                    "SMAN 1 Soreang",
                ],
                "SMK": [
                    "SMK Bakti Nusantara 666",
                    "SMK PU Negeri Bandung",
                    "SMKN 9 Bandung",
                    "SMKN 5 Bandung",
                    "SMKN 7 Baleendah",
                    "SMKN 1 Bandung",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Bandung"
                ],
                "Pesantren": [
                    "SMA Daarut Tauhiid Boarding School Putri",
                    "SMA Muhammadiyah 1 Bandung",
                ]
            },
            {
                "id": "k76",
                "kabupaten": "Bandung Barat",
                "SMA": [
                    "SMAN 1 Cisarua",
                    "SMA Cahaya Bangsa Classical School",
                    "SMAN 1 Lembang",
                    "SMAN 1 Ngamprah",
                    "SMAN 1 Parongpong",
                    "SMAN 1 Batujajar",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k77",
                "kabupaten": "Cimahi",
                "SMA": [
                    "SMA 3 Pasundan",
                    "SMAN 3 Cimahi",
                    "SMAN 2 Cimahi",
                    "SMAN 5 Cimahi",
                    "SMAN 1 Cimahi",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Kota Cimahi"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k78",
                "kabupaten": "Bekasi",
                "SMA": [
                    "SMAN 1 Cikarang Barat",
                    "SMAN 4 Kota Bekasi",
                    "SMAK Penabur Summarecon Bekasi",
                    "SMA Taman Harapan 2",
                    "SMAN 14 Kota Bekasi",
                    "SMAN 3 Kota Bekasi ",
                    "SMAIT Thariq Bin Ziyad ",
                    "SMA Dharma Putra Advent",
                    "SMAN 2 Kota Bekasi",
                    "SMA Tunas Jakasampurna",
                    "SMAN 2 Cikarang Selatan",
                    "SMA Future Gate Putri",
                    "SMAI PB Soedirman 1 Kota Bekasi",
                    "SMA Yadika 13",
                    "SMA Alexandria Islamic School",
                    "SMA Victory Plus",
                    "SMA Widya Nusantara",
                    "SMA Tulus Bhakti",
                    "SMAN 10 Kota Bekasi",
                    "SMAN 1 Kota Bekasi",
                    "SMAN 9 Tambun Selatan ",
                    "SMA Islam Al-Azhar 8 Summarecon Bekasi",
                    "SMAN 1 Tambun Utara",
                    "SMA Pangudi Luhur 2",
                    "SMAIT Thariq Bin Ziyad",
                    "SMA Islam PB Soedirman Bekasi",
                    "SMAN 2 Cibarusah ",
                    "SMAN 5 Kota Bekasi",
                    "SMAK IPEKA Grand Wisata",
                    "SMAN 1 Pebayuran",
                    "SMA Martia Bhakti",
                    "SMAN 2 Cikarang Pusat",
                    "SMAN 5 Tambun Selatan",
                    "SMA Mutiara 17 Agustus",
                    "SMA Tunas Bangsa",
                    "SMAN 1 Tambun Selatan",
                    "SMAN 2 Cikarang Utara",
                    "SMA Global Prestasi",
                    "SMAN 18 Kota Bekasi",
                    "SMA PGRI 2 Kota Bekasi",
                    "SMAN 1 Cikarang Pusat",
                    "SMAN 5 Tambun Selatan",
                    "SMAN 1 Kedungwaringin",
                    "SMAN 1 Pebayuran",
                    "SMAN 1 Muaragembong",
                    "SMAN 1 Bekasi",
                    "SMA Islam Al-Azhar 4",
                    "SMAN 2 Tambun Selatan",
                    "SMA Islam Al-Azhar 4 Kemang Pratama",
                    "SMA Negeri 3 Tambun Selatan",
                    "SMA Islam Al Azhar 22",
                    "SMAN 6 Tambun Selatan",
                    "SMA Yadika 8",
                    "SMA Labschool Cibubur",
                    "SMA Islam Darussalam",
                    "SMA Dian Harapan Cikarang",
                    "SMAN 2 Sukatani",
                    "SMAIT Al-Fidaa",
                    "SMAN 2 Cikarang Utara",
                ],
                "SMK": [
                    "SMKN 1 Kota Bekasi",
                    "SMK Santa Maria Monica",
                    "SMK Daya Utama ",
                    "SMK Bina Tunggal Bekasi",
                    "SMK Global Prima Islamic School",
                    "SMKN 1 Pebayuran",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Kota Bekasi",
                    "MA AS Asyafi'iyah 2",
                    "MAN 1 Kota Bekasi",
                    "MI Yapink 1",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k79",
                "kabupaten": "Bogor",
                "SMA": [
                    "SMA Nuraida Islamic Boarding School",
                    "SMAIT Darul Qur'an",
                    "SMA BP FAI UMJ",
                    "SMAIT Insantama Bogor",
                    "SMAN 10 Bogor ",
                    "SMA Regina Pacis Bogor",
                    "SMAN 6 Bogor",
                    "SMAN 9 Bogor",
                    "SMAK BPK Penabur Kota Wisata",
                    "SMAN 1 Bojonggede",
                    "SMAS YPHB Kota Bogor",
                    "SMA Plus PGRI Cibinong",
                    "SMAN 2 Bogor",
                    "SMAN 2 Gunung Putri",
                    "SMA Taruna Bangsa",
                    "SMAN 1 CILEUNGSI",
                    "SMAN 1 Bogor",
                    "SMAN 3 Cibinong",
                    "SMA Kesatuan Bogor",
                    "SMAIT Nurul Fikri Boarding School Bogor",
                    "SMAN 5 Bogor ",
                    "SMAN 2 Cibinong",
                    "SMAN 3 Cibinong",
                    "SMA Labschool Kornita IPB",
                    "SMAN 1 Leuwiliang ",
                    "SMX School of Makers",
                    "SMAN 1 Cigombong",
                    "SMA Muhammadiyah Cileungsi",
                    "SMAN 1 Parung",
                    "SMA Bina Insani",
                    "SMA Daar En Nisa",
                    "SMA Regina Caeli",
                    "SMAN 4 Cibinong",
                    "SMA Bunda Hati Kudus Kota Wisata",
                    "PKBM ABhome",
                    "SMAN 1 Tajurhalang",
                    "SMAIT Insan Cendikia Madani Gunung Geulis Bogor",
                    "SMA Islam Ibnu Hajar",
                    "SMA Bogor Raya",
                    "SMA Mardi Yuana",
                    "SMAS Citra Nusa",
                ],
                "SMK": [
                    "SMK Islamic Development Network Boarding School",
                    "SMK SMAK Bogor",
                    "SMK Pariwisata Citayam",
                    "SMK Sekolah Analis Kimia Bogor",
                    "SMKN 1 Cibinong",
                    "SMK Adi Sanggoro",
                    "SMK Farmasi Bogor",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Kota Bogor",
                    "MAN 1 Kota Bogor",
                    "MA Terpadu Darul Fallah",
                ],
                "Pesantren": [
                    "Al Wafi Islamic Boarding School",
                    "Al Kahfi Boarding School",
                    "Fitrah Islamic World Academy",
                    "MBS Ki Bagus Hadikusumo",
                    "MAs Sahid",
                    "Al Andalus Islamic Internasional Boarding School Bogor",
                    "Pesantren Pertanian Darul Fallah Bogor",
                    "MA Darunnajah 2",
                    "Ma'had Rahmaniyah Al Islamy"
                ]
            },
            {
                "id": "k80",
                "kabupaten": "Depok",
                "SMA": [
                    "SMA AL-Hasra",
                    "SMAIT Nurul Fikri",
                    "SMA Avicenna Cinere",
                    "SMAN 4 Depok",
                    "SMAN 1 Depok",
                    "SMAN 2 Depok",
                    "SMAN 12 DEPOK",
                    "SMA Yadika 12 ",
                    "SMA Perjuangan Terpadu",
                    "SMAN 14 Depok",
                    "SMAN 8 Depok",
                    "SMAS YAPEMRI Depok",
                    "SMA IT Nurul Fikri",
                    "SMA Sejahtera 1 Depok",
                    "SMAN 11 Depok ",
                    "SMAN 3 Depok",
                    "SMA IAS Al-Jannah",
                    "SMA Ibnu Hajar Boarding School",
                    "SMAN 2 Depok",
                    "SMAN 9 Depok",
                    "SMAN 5 Depok",
                    "SMA Karakter",
                    "SMA Yaspen Tugu Ibu 1 Depok",
                    "Springfield International School",
                    "SMA 5 Terbuka Depok",
                    "SMA Budi Cendekia Islamic School",
                ],
                "SMK": [
                    "SMKN 2 Kota Depok"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p10": [
            {
                "id": "k100",
                "kabupaten": "Banjarnegara"
            },
            {
                "id": "k101",
                "kabupaten": "Purbalingga",
                "SMA": [
                    "SMAN 1 Bukateja",
                    "SMAN 1 Purbalingga",
                ],
                "SMK": [
                    "SMKN 1 Purbalingga"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k102",
                "kabupaten": "Cilacap",
                "SMA": [
                    "SMA Muhammadiyah 1 Cilacap",
                    "SMAN 1 Majenang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k103",
                "kabupaten": "Banyumas",
                "SMA": [
                    "SMA Bruderan Purwokerto",
                    "SMAN 1 Purwokerto",
                    "SMAN 1 Jatilawang",
                    "SMAN 3 Purwokerto",
                    "SMAN 1 Banyumas",
                    "SMAN 2 Purwokerto",
                    "SMAN 1 Sumpiuh",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k104",
                "kabupaten": "Tegal",
                "SMA": [
                    "SMAN 1 Tegal"
                ],
                "SMK": [
                    "SMKN 1 Dukuhturi"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k105",
                "kabupaten": "Pemalang"
            },
            {
                "id": "k106",
                "kabupaten": "Brebes",
                "SMA": [
                    "SMAN 1 Bumiayu",
                    "SMAN 2 Brebes"
                ],
                "SMK": [
                    "SMK Ma'arif NU 03 Larangan",
                    "SMK Bhakti Husada Brebes",
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MA Al-Hikmah 2"
                ]
            },
            {
                "id": "k107",
                "kabupaten": "Kendal",
                "SMA": [
                    "SMAN 1 Kendal", 
                ],
                "SMK": [
                    "SMKN 4 Kendal",
                    "SMKN 1 Kendal", 
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k108",
                "kabupaten": "Batang"
            },
            {
                "id": "k109",
                "kabupaten": "Pekalongan",
                "SMA": [
                    "SMAN 1 Kota Pekalongan",
                    "SMAI Islam Pekalongan",
                    "SMAN 1 Kedungwuni",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Pekalongan",
                    "MAN Insan Cendekia Pekalongan",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k110",
                "kabupaten": "Semarang",
                "SMA": [
                    "SMAN 1 Ungaran",
                    "SMAN 2 Semarang",
                    "SMAN 5 Semarang",
                    "SMAN 3 Semarang",
                    "SMA Kesatrian 2 Semarang",
                    "SMAN 1 Semarang",
                    "SMAN 2 Ungaran ",
                    "SMA Karangturi",
                    "SMAN 7 Semarang",
                    "SMAN 4 Semarang",
                    "SMA Sultan Agung 1 Semarang",
                    "SMA Kolese Loyola",
                    "SMAN 6 Semarang",
                    "SMA Semesta Bilingual Boarding School",
                ],
                "SMK": [
                    "SMKN 6 Semarang",
                    "SMKN 11 Semarang",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Semarang"
                ],
                "Pesantren": [
                    "Askhabul Kahfi"
                ]
            },
            {
                "id": "k111",
                "kabupaten": "Salatiga",
                "SMA": [
                    "PKBM Karisma",
                    "SMAN 1 Salatiga",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k81",
                "kabupaten": "Demak",
                "SMA": [
                    "SMAN 2 Mranggen",
                    "SMAN 1 Mranggen",
                    "SMAN 1 Demak",
                    "SMA Futuhiyyah",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k82",
                "kabupaten": "Jepara",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Jepara"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k83",
                "kabupaten": "Kudus",
                "SMA": [
                    "SMAN 1 Kudus",
                ],
                "SMK": [
                    "SMK Raden Umar Said Kudus",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Kudus",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k84",
                "kabupaten": "Rembang"
            },
            {
                "id": "k85",
                "kabupaten": "Pati",
                "SMA": [
                    "SMAN 1 Tayu",
                    "SMA PGRI 1 Pati",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MA Miftahul Ulum",
                    "MAN 2 Pati",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k86",
                "kabupaten": "Blora"
            },
            {
                "id": "k87",
                "kabupaten": "Grobogan",
                "SMA": [
                    "SMAN 1 Purwodadi",
                    "SMAN 1 Pulokulon",
                ],
                "SMK": [
                    "SMKN 2 Purwodadi"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k88",
                "kabupaten": "Karanganyar",
                "SMA": [
                    "SMAN Karanganyar"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Karanganyar"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k89",
                "kabupaten": "Wonogiri",
                "SMA": [
                    "SMAN 1 Girimarto",
                    "SMAN 1 Wonogiri",
                ],
                "SMK": [
                    "SMKN 1 Pracimantoro"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k90",
                "kabupaten": "Sukoharjo",
                "SMA": [
                    "SMA Unggulan CT Arsa Foundation Sukoharjo",
                    "SMAI Al-Azhar 7 Solo Baru",
                    "SMAIT Nur Hidayah Sukoharjo",
                    "SMA PPMI Assalaam",
                    "SMAN 1 Sukoharjo",
                    "SMAN 1 Mojolaban",
                ],
                "SMK": [
                    "SMK Veteran 1 Sukoharjo"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "SMA PPMI Assalaam"
                ]
            },
            {
                "id": "k91",
                "kabupaten": "Klaten",
                "SMA": [
                    "SMAN 1 Klaten",
                    "SMAN 1 Polanharjo",
                    "SMAN 3 Klaten",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k92",
                "kabupaten": "Boyolali",
                "SMA": [
                    "SMAN 1 Karanggede",
                    "SMA Pradita Dirgantara",
                    "SMAN 1 Teras",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k93",
                "kabupaten": "Sragen",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Sragen"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k94",
                "kabupaten": "Surakarta (Solo)",
                "SMA": [
                    "SMAN 2 Surakarta",
                    "SMAN 1 Surakarta",
                    "SMAN 4 Surakarta",
                    "SMA Al-Abidin Bilingual Boarding School",
                    "SMAN 3 Surakarta",
                    "SMAN 7 Surakarta",
                    "SMA Muhammadiyah PK Kottabarat Surakarta",
                    "SMAN 5 Surakarta",
                ],
                "SMK": [
                    "SMKN 4 Surakarta",
                    "SMKN 3 Surakarta",
                    "SMK Kesehatan Mandala Bhakti Surakarta",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Surakarta",
                    "MAN 2 Surakarta",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k95",
                "kabupaten": "Magelang",
                "SMA": [
                    "SMAN 3 Kota Magelang", 
                    "SMAN 1 Kota Magelang",
                    "SMAN 4 Kota Magelang",
                    "SMA PL Van Lith",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Magelang"
                ],
                "Pesantren": [
                    "MA Pondok Pesantren Pabelan"
                ]
            },
            {
                "id": "k96",
                "kabupaten": "Wonosobo",
                "SMA": [
                    "SMAN 1 Wonosobo"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Wonosobo"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k97",
                "kabupaten": "Temanggung",
                "SMA": [
                    "SMAN 3 Temanggung",
                    "SMAN 1 Temanggung",
                    "SMAN 1 Parakan",
                ],
                "SMK": [
                    "SMKN 1 Dukuhturi"
                ],
                "MA (Non Pesantren)": [
                    "MAN Temanggung"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k98",
                "kabupaten": "Kebumen",
                "SMA": [
                    "SMAN 1 Gombong", 
                    "SMAN 2 Kebumen",
                    "SMAN 1 Kebumen",
                    "SMAN 1 Prembun",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k99",
                "kabupaten": "Purworejo",
                "SMA": [
                    "SMAN 1 Purworejo",
                    "SMAN 2 Purworejo",
                    "SMAN 7 Purworejo",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p11": [
            {
                "id": "k112",
                "kabupaten": "Sumenep"
            },
            {
                "id": "k113",
                "kabupaten": "Pamekasan",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MA Mambaul Ulum Bata-Bata"
                ]
            },
            {
                "id": "k114",
                "kabupaten": "Sampang"
            },
            {
                "id": "k115",
                "kabupaten": "Bangkalan",
                "SMA": [
                    "SMAN 2 Bangkalan",
                    "SMAN 1 Arosbaya",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k116",
                "kabupaten": "Banyuwangi",
                "SMA": [
                    "SMAN 1 Giri Taruna Bangsa",
                    "SMAN 1 Giri Banyuwangi",
                    "SMAN 1 Pesanggaran",
                    "SMAN 1 Glagah",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k117",
                "kabupaten": "Situbondo",
                "SMA": [
                    "SMAN 1 Situbondo"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Situbondo"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k118",
                "kabupaten": "Bondowoso"
            },
            {
                "id": "k119",
                "kabupaten": "Jember",
                "SMA": [
                    "SMAN 1 Jember",
                    "SMAN 2 Jember",
                    "SMAN 1 Kencong",
                    "SMAN 5 Jember",
                    "SMAN Pakusari",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k120",
                "kabupaten": "Lumajang",
                "SMA": [
                    "SMAN 3 Lumajang",
                    "SMAN 1 Tempeh",
                ],
                "SMK": [
                    "SMKN 1 Lumajang"
                ],
                "MA (Non Pesantren)": [
                    "MA Pesantren Terpadu Al-Fauzan",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k121",
                "kabupaten": "Probolinggo",
                "SMA": [
                    "SMA Negeri 1 Probolinggo"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k122",
                "kabupaten": "Pasuruan",
                "SMA": [
                    "SMAN 1 Taruna Madani",
                    "SMAN 1 Pandaan",
                    "SMA Excellent Al-Yasini",
                ],
                "SMK": [
                    "SMKN 1 Bangil"
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Pasuruan",
                    "MAN Insan Cendekia Pasuruan",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k123",
                "kabupaten": "Trenggalek"
            },
            {
                "id": "k124",
                "kabupaten": "Tulungagung",
                "SMA": [
                    "SMAN 1 Ngunut",
                    "SMAN 1 Boyolangu",
                    "SMAN 1 Kedungwaru",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k125",
                "kabupaten": "Blitar",
                "SMA": [
                    "SMAN 3 Blitar",
                    "SMAN 2 Blitar",
                    "SMAN 1 Blitar",
                    "SMA Muhammadiyah 1 Blitar",
                    "SMA Katolik Diponegoro",
                ],
                "SMK": [
                    "SMKN 1 Blitar"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k126",
                "kabupaten": "Malang",
                "SMA": [
                    "SMAN 4 Malang",
                    "SMAK Charis National Academy",
                    "SMAN 1 Malang",
                    "SMAK Kalam Kudus Malang",
                    "SMA Laboratorium UM Malang",
                    "SMAK Frateran Malang",
                    "SMAN 3 Malang",
                    "SMA PJ Global School",
                    "SMAN 7 Malang",
                    "SMAN 8 Malang",
                    "SMAK Cor Jesu",
                    "SMA Aisyiyah Boarding School Malang",
                    "SMA Brawijaya Smart School",
                    "SMAN 10 Malang",
                    "SMAK St Albertus",
                    "SMAN 9 Malang",
                    "SMAN 6 Malang",
                    "SMA Modern Al-Rifa'ie",
                ],
                "SMK": [
                    "SMKN 1 Turen",
                    "SMK Mandiri",
                    "SMKN 11 Malang",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Malang",
                    "MAN 2 Kota Malang",
                ],
                "Pesantren": [
                    "SMA Modern Al-Rifa'ie 2",
                    "Thursina IIBS Islamic Boarding school",
                ]
            },
            {
                "id": "k127",
                "kabupaten": "Batu",
                "SMA": [
                    "SMAN 1 Batu",
                    "SMA Immanuel Batu",
                    "SMA Al-Hikmah Boarding School Batu",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Kota Batu"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k128",
                "kabupaten": "Nganjuk",
                "SMA": [
                    "SMAN 2 Nganjuk",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k129",
                "kabupaten": "Kediri",
                "SMA": [
                    "SMAN 2 Kediri",
                    "SMAN 1 Kediri",
                    "SMAN 5 Taruna Brawijaya",
                    "SMA Ar-Risalah ",
                    "SMAN 2 Taruna Brawijaya",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Kota Kediri"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k130",
                "kabupaten": "Pacitan",
                "SMA": [
                    "SMAN 1 Pacitan",
                    "SMAN 2 Pacitan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k131",
                "kabupaten": "Ponorogo",
                "SMA": [
                    "SMAN 3 Ponorogo"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Ponorogo",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k132",
                "kabupaten": "Magetan",
                "SMA": [
                    "SMAN 1 Magetan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k133",
                "kabupaten": "Ngawi",
                "SMA": [
                    "SMAN 2 Ngawi",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k134",
                "kabupaten": "Madiun",
                "SMA": [
                    "SMAN 1 Geger",
                    "SMAN 2 Madiun",
                    "SMAN 3 Taruna Angkasa Madiun",
                    "SMAN 1 Kota Madiun",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Kota Madiun",
                    "MAN 2 Kota Madiun", 
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k135",
                "kabupaten": "Tuban",
                "SMA": [
                    "SMAN 4 Tuban"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k136",
                "kabupaten": "Lamongan",
                "SMA": [
                    "SMAN 1 Sukodadi",
                    "SMAN 2 Lamongan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k137",
                "kabupaten": "Bojonegoro",
                "SMA": [
                    "SMAN 4 Bojonegoro",
                    "SMAN Model Terpadu Bojonegoro",
                    "SMAN 2 Bojonegoro",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k138",
                "kabupaten": "Jombang",
                "SMA": [
                    "SMA Darul Ulum 2"
                ],
                "SMK": [
                    "SMK Telkomunikasi Darul Ulum",
                    "SMK Plus Khoiriyah Hasyim"
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Jombang",
                    "MAN 3 Jombang",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k139",
                "kabupaten": "Mojokerto",
                "SMA": [
                    "SMAN 1 Puri Mojokerto",
                    "SMA 1 Mojosari",
                    "SMAN 1 Sooko",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MA Unggulan Amanatul Ummah Program Bertaraf Internasional"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k140",
                "kabupaten": "Sidoarjo",
                "SMA": [
                    "SMAN 4 Sidoarjo",
                    "SMA Petra 4",
                    "SMAN 3 Sidoarjo",
                    "SMAN 2 Sidoarjo",
                    "SMAN 1 Gendangan",
                    "SMA Insan Cendekia Mandiri Boarding School",
                    "SMA Muhamadiyyah 2 Sidoarjo",
                    "SMAN 1 Taman",
                    "SMAN 1 Sidoarjo",
                    "SMA Wahid Hasyim 2 Taman",
                    "SMA Hang Tuah 2 Sidoarjo",
                    "SMA Pembangunan Jaya 2",
                    "SMAN 1 Waru",
                    "SMA Muhamadiyyah 1 Taman",
                ],
                "SMK": [
                    "SMK Sepuluh Nopember Sidoarjo"
                ],
                "MA (Non Pesantren)": [
                    "MAN Sidoarjo"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k141",
                "kabupaten": "Gresik",
                "SMA": [
                    "SMA Semen Gresik",
                    "SMAN 1 Sidayu",
                    "SMAN 1 Driyorejo",
                    "SMAN 1 Kebomas",
                ],
                "SMK": [
                    "SMK Semen Gresik"
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Gresik"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k142",
                "kabupaten": "Surabaya",
                "SMA": [
                    "SMAS Intan Permata Hati Surabaya",
                    "SMAK Gloria 1 Surabaya",
                    "SMA Muhammadiyah 7 Surabaya ",
                    "SMAK Petra 1 Surabaya",
                    "SMA Al-Falah Ketintang Surabaya",
                    "SMAK Petra 2 Surabaya",
                    "SMAN 4 Surabaya",
                    "SMAN 15 Surabaya",
                    "SMAN 2 Surabaya",
                    "SMAN 22 Surabaya ",
                    "SMAIT Al-Uswah Surabaya",
                    "SMAN 11 Surabaya ",
                    "SMAK St Louis 1 Surabaya",
                    "SMAN 12 Surabaya",
                    "SMAN 14 Surabaya ",
                    "SMA Citra Berkat ",
                    "Christian Life Academy",
                    "SMAN 17 Surabaya",
                    "SMAN 5 Surabaya",
                    "SMA Khadijah Wonokromo Surabaya",
                    "SMAK Happy Family School Surabaya",
                    "SMAN 3 Surabaya ",
                    "SMA IPH East Surabaya",
                    "SMAK Santo Hendrikus Surabaya",
                    "SMAN 19 Surabaya",
                    "SMAK Frateran Surabaya",
                    "SMAN 16 Surabaya",
                    "SMA 17 Agustus 1945",
                    "SMAN 10 Surabaya",
                ],
                "SMK": [
                    "SMKN 6 Surabaya ",
                    "SMK Labschool UNESA Surabaya",
                    "SMKN 12 Surabaya",
                    "SMKN 2 Surabaya",
                    "SMKN 5 Surabaya",
                ],
                "MA (Non Pesantren)": [
                    "MAN Kota Surabaya"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p12": [
            {
                "id": "k143",
                "kabupaten": "Melawi"
            },
            {
                "id": "k144",
                "kabupaten": "Sekadau"
            },
            {
                "id": "k145",
                "kabupaten": "Sambas"
            },
            {
                "id": "k146",
                "kabupaten": "Landak",
                "SMA": [
                    "SMAN 1 Ngabang",
                    "SMAK Makedonia",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Landak"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k147",
                "kabupaten": "Bengkayang"
            },
            {
                "id": "k148",
                "kabupaten": "Singkawang",
                "SMA": [
                    "SMA Santo Ignasius Singkawang",
                    "SMA Bersatu Singkawang",
                ],
                "SMK": [
                    "SMKN 2 Singkawang"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k149",
                "kabupaten": "Pontianak",
                "SMA": [
                    "SMAN 1 Mempawah Hilir",
                    "SMAN 7 Pontianak",
                    "SMAN 2 Pontianak",
                    "SMA Santo Paulus Pontianak",
                    "SMAN 7 Pontianak",
                    "SMAN 3 Pontianak",
                    "SMA Gembala Baik Pontianak",
                    "SMAN 12 Pontianak",
                    "SMAN 4 Pontianak",
                    "SMAN 11 Pontianak",
                    "SMA Pelita Cemerlang Pontianak",
                    "SMAN 1 Pontianak",
                    "SMAN 8 Pontianak",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Pontianak",
                    "MAN 1 Pontianak",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k150",
                "kabupaten": "Ketapang",
                "SMA": [
                    "SMAN 2 Ketapang"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k151",
                "kabupaten": "Kayong Utara"
            },
            {
                "id": "k152",
                "kabupaten": "Kapuas Hulu"
            },
            {
                "id": "k153",
                "kabupaten": "Sintang",
                "SMA": [
                    "SMAN 3 Sintang",
                    "SMAN 1 Sintang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k154",
                "kabupaten": "Sanggau"
            },
            {
                "id": "k155",
                "kabupaten": "Kubu Raya",
                "SMA": [
                    "SMAN 1 Sungai Raya",
                    "SMAIT Al-Fityan School Kubu Raya",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p13": [
            {
                "id": "k156",
                "kabupaten": "Tanah Bumbu"
            },
            {
                "id": "k157",
                "kabupaten": "Kotabaru"
            },
            {
                "id": "k158",
                "kabupaten": "Tabalong",
                "SMA": [
                    "SMAN 2 Tanjung",
                    "SMAN 1 Tanjung",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k159",
                "kabupaten": "Balangan",
                "SMA": [
                ],
                "SMK": [
                    "SMKN 1 Batumandi"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k160",
                "kabupaten": "Hulu Sungai Utara"
            },
            {
                "id": "k161",
                "kabupaten": "Hulu Sungai Tengah"
            },
            {
                "id": "k162",
                "kabupaten": "Hulu Sungai Selatan"
            },
            {
                "id": "k163",
                "kabupaten": "Banjar",
                "SMA": [
                    "SMAN 1 Sungai Tabuk",
                    "SMAN Banua Kalsel Bilingual Boarding School",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN PK Martapura"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k164",
                "kabupaten": "Tapin"
            },
            {
                "id": "k165",
                "kabupaten": "Tanah Laut",
                "SMA": [
                    "SMAN 1 Pelaihari"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Tanah Laut"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k166",
                "kabupaten": "Banjarbaru",
                "SMA": [
                    "SMAN 3 Banjarbaru",
                    "SMAN 2 Banjarbaru", 
                ],
                "SMK": [
                    "SMKN 1 Banjarbaru"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k167",
                "kabupaten": "Barito Kuala"
            },
            {
                "id": "k168",
                "kabupaten": "Banjarmasin",
                "SMA": [
                    "SMAN 7 Banjarmasin",
                    "SMAIT Ukhuwah",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Banjarmasin"
                ],
                "Pesantren": [
                    "Umar bin khattab Banjarmasin"
                ]
            }
        ],
        "p14": [
            {
                "id": "k169",
                "kabupaten": "Pulang Pisau",
                "SMA": [
                    "SMA 2 Pulang Pisau"
                ],
                "SMK": [
                    "SMKS Karya Pulang Pisau"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k170",
                "kabupaten": "Lamandau"
            },
            {
                "id": "k171",
                "kabupaten": "Kapuas",
                "SMA": [
                    "SMAN 1 Kuala Kapuas",
                    "SMAN 2 Kuala Kapuas",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Kapuas"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k172",
                "kabupaten": "Gunung Mas"
            },
            {
                "id": "k173",
                "kabupaten": "Katingan"
            },
            {
                "id": "k174",
                "kabupaten": "Kotawaringin Timur",
                "SMA": [
                    "SMAN 2 Sampit",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Kotawaringin Timur"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k175",
                "kabupaten": "Seruyan"
            },
            {
                "id": "k176",
                "kabupaten": "Kotawaringin Barat"
            },
            {
                "id": "k177",
                "kabupaten": "Sukamara"
            },
            {
                "id": "k178",
                "kabupaten": "Murung Raya"
            },
            {
                "id": "k179",
                "kabupaten": "Barito Utara"
            },
            {
                "id": "k180",
                "kabupaten": "Barito Selatan",
                "SMA": [
                    "SMAN 3 Buntok",
                    "SMAN 1 Buntok",
                    "SMAN 1 Jenamas",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Banjarmasin"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k181",
                "kabupaten": "Barito Timur",
                "SMA": [
                    "SMAN 5 Barito Timur",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k182",
                "kabupaten": "Palangka Raya",
                "SMA": [
                    "SMAN 2 Palangka Raya",
                    "SMAN 1 Palangka Raya",
                    "SMAN 5 Palangka Raya",
                    "SMA Golden Christian School Palangka Raya",
                    "SMAN 3 Palangka Raya",
                    "SMAN 4 Palangka Raya",
                ],
                "SMK": [
                    "SMK Karsa Mulya",
                    "SMKN 3 Palangka Raya",
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p15": [
            {
                "id": "k183",
                "kabupaten": "Berau",
                "SMA": [
                    "SMAN 13 Berau",
                    "SMAN 1 Berau",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k184",
                "kabupaten": "Samarinda",
                "SMA": [
                    "SMAN 10 Samarinda",
                    "SMAN 2 Samarinda",
                    "SMAN 9 Samarinda",
                    "SMAN 5 Samarinda",
                    "SMA Islam Samarinda ",
                    "SMA Fastabiqul Khairat Samarinda",
                    "SMAN 3 Samarinda",
                    "SMA Budi Bakti Samarinda",
                    "SMAN 8 Samarinda",
                    "SMAN 1 Samarinda",
                ],
                "SMK": [
                    "SMKN 1 Samarinda",
                    "SMKN 4 Samarinda",
                    "SMKN 3 Samarinda",
                    "SMKN 7 Samarinda",
                    "SMKN 19 Samarinda",
                    "SMK TI Airlangga",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Samarinda"
                ],
                "Pesantren": [
                    "SMA Madina Citra Insani"
                ]
            },
            {
                "id": "k185",
                "kabupaten": "Penajam Paser Utara"
            },
            {
                "id": "k186",
                "kabupaten": "Paser",
                "SMA": [
                    "SMAN 2 Unggulan Tanah Grogot"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Paser"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k187",
                "kabupaten": "Balikpapan",
                "SMA": [
                    "SMAN 2 Balikpapan",
                    "SMAN 1 Balikpapan",
                    "SMAN 3 Balikpapan",
                    "SMAK Adisutjipto Balikpapan",
                    "SMAN 5 Balikpapan",
                    "SMAN 4 Balikpapan",
                    "SMAN 6 Balikpapan",
                    "SMA Al-Hasaan Balikpapan",
                ],
                "SMK": [
                    "SMKN 2 Balikpapan",
                    "SMKN 4 Balikpapan",
                    "SMK Migas Balikpapan",
                    "SMKN 3 Balikpapan",
                ],
                "MA (Non Pesantren)": [
                    "MAN Balikpapan"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k188",
                "kabupaten": "Kutai Kartanegara",
                "SMA": [
                    "SMAN 2 Tenggarong",
                    "SMAN 3 Tenggarong",
                    "SMAN 1 Anggana",
                    "SMAN 1 Tenggarong",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k189",
                "kabupaten": "Kutai Barat"
            },
            {
                "id": "k190",
                "kabupaten": "Kutai Timur",
                "SMA": [
                    "SMAN 1 Sangatta Utara"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k191",
                "kabupaten": "Bontang",
                "SMA": [
                    "SMAN 1 Bontang",
                    "SMA Yayasan Pupuk Kaltim",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p16": [
            {
                "id": "k192",
                "kabupaten": "Malinau"
            },
            {
                "id": "k193",
                "kabupaten": "Nunukan",
                "SMA": [
                    "SMAN 1 Nunukan",
                    "SMAK Frateran Santo Gabriel Nunukan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k194",
                "kabupaten": "Tana Tidung"
            },
            {
                "id": "k195",
                "kabupaten": "Bulungan (Bulongan)",
                "SMA": [
                    "SMAN 1 Tanjung Selor"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k196",
                "kabupaten": "Tarakan"
            }
        ],
        "p17": [
            {
                "id": "k197",
                "kabupaten": "Lingga"
            },
            {
                "id": "k198",
                "kabupaten": "Kepulauan Anambas"
            },
            {
                "id": "k199",
                "kabupaten": "Natuna",
                "SMA": [
                    "SMAN 4 Karimun"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Natuna"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k200",
                "kabupaten": "Karimun",
                "SMA": [
                    "SMAN 1 Bunguran Barat",
                    "SMAN 2 Bunguran Barat",
                    "SMAN 1 Bunguran Timur",
                ],
                "SMK": [
                    "SMKN 2 Bunguran Timur"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k201",
                "kabupaten": "Batam",
                "SMA": [
                    "SMAN 27 Batam",
                    "SMA Harapan Utama Batam",
                    "SMA Maitreyawira Batam",
                    "SMAN 24 Batam",
                    "SMA Mondial Batam",
                    "SMAN 3 Batam",
                ],
                "SMK": [
                    "SMKN 1 Batam"
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Batam",
                    "MAN Insan Cendekia Batam",
                ],
                "Pesantren": [
                    "MAN Insan Cendekia Batam",
                ]
            },
            {
                "id": "k202",
                "kabupaten": "Bintan",
                "SMA": [
                    "SMA Tunas Bangsa Bintan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k203",
                "kabupaten": "Tanjung Pinang",
                "SMA": [
                    "SMAN 2 Tanjung Pinang",
                    "SMAN 1 Tanjung Pinang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Natuna"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p18": [
            {
                "id": "k204",
                "kabupaten": "Tanggamus"
            },
            {
                "id": "k205",
                "kabupaten": "Pringsewu"
            },
            {
                "id": "k206",
                "kabupaten": "Lampung Selatan",
                "SMA": [
                    "SMA Kebangsaan",
                    "SMA 1 Natar",
                    "SMAN 1 Tanjung Bintang",
                    "SMAS Al-Huda",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k207",
                "kabupaten": "Lampung Tengah",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pondok Pesantren Modern Muhammadiyah Boarding School Poncowati"
                ]
            },
            {
                "id": "k208",
                "kabupaten": "Pesawaran"
            },
            {
                "id": "k209",
                "kabupaten": "Bandar Lampung",
                "SMA": [
                    "SMA Quran Darul Fattah Bandar Lampung",
                    "SMAN 15 Bandar Lampung",
                    "SMAN 2 Bandar Lampung",
                    "SMAN 1 Bandar Lampung",
                    "SMA Darma Bangsa Bandar Lampung",
                    "SMAN 9 Bandar Lampung",
                    "SMAN 5 Bandar Lampung",
                    "SMA IT Daarul Ilmi Bandar Lampung",
                    "SMAN 14 Bandar Lampung",
                    "SMA Al-Kautsar Bandar Lampung",
                    "SMAN 12 Bandar Lampung",
                    "SMA Taman Siswa Bandar Lampung",
                    "SMAN 10 Bandar Lampung",
                ],
                "SMK": [
                    "SMKN 5 Bandar Lampung"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k210",
                "kabupaten": "Lampung Barat",
                "SMA": [
                    "SMAN 1 Liwa"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k211",
                "kabupaten": "Pesisir Barat"
            },
            {
                "id": "k212",
                "kabupaten": "Way Kanan"
            },
            {
                "id": "k213",
                "kabupaten": "Mesuji"
            },
            {
                "id": "k214",
                "kabupaten": "Tulang Bawang Barat"
            },
            {
                "id": "k215",
                "kabupaten": "Tulang Bawang"
            },
            {
                "id": "k216",
                "kabupaten": "Lampung Utara"
            },
            {
                "id": "k217",
                "kabupaten": "Lampung Timur",
                "SMA": [
                    "SMAN 1 Bandar Sribhawono",
                    "SMAN 1 Way Jepara",
                ],
                "SMK": [
                    "SMK Perintis Adiluhur"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k218",
                "kabupaten": "Metro",
                "SMA": [
                    "SMAN 5 Metro",
                    "SMAN 2 Metro",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p19": [
            {
                "id": "k219",
                "kabupaten": "Kepulauan Aru"
            },
            {
                "id": "k220",
                "kabupaten": "Maluku Tenggara"
            },
            {
                "id": "k221",
                "kabupaten": "Maluku Barat Daya"
            },
            {
                "id": "k222",
                "kabupaten": "Tual"
            },
            {
                "id": "k223",
                "kabupaten": "Seram Bagian Timur"
            },
            {
                "id": "k224",
                "kabupaten": "Maluku Tengah",
                "SMA": [
                    "SMAN 4 Maluku Tengah"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k225",
                "kabupaten": "Buru"
            },
            {
                "id": "k226",
                "kabupaten": "Buru Selatan"
            },
            {
                "id": "k227",
                "kabupaten": "Seram Bagian Barat"
            },
            {
                "id": "k228",
                "kabupaten": "Maluku Tenggara Barat"
            },
            {
                "id": "k229",
                "kabupaten": "Ambon",
                "SMA": [
                    "SMA Lentera Harapan Ambon",
                    "SMA Xaverius Ambon",
                    "SMAN 1 Ambon ",
                    "SMAN Siwalima Ambon",
                    "SMAN 2 Ambon",
                    "SMAN 15 Ambon",
                    "SMAN 12 Ambon ",
                    "SMAK Rehoboth Ambon",
                    "SMAN 11 Ambon",
                ],
                "SMK": [
                    "SMKN 1 Ambon"
                ],
                "MA (Non Pesantren)": [
                    "MAN Ambon"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p20": [
            {
                "id": "k230",
                "kabupaten": "Halmahera Timur",
                "SMA": [
                    "SMAN 1 Halmahera Timur"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k231",
                "kabupaten": "Halmahera Tengah"
            },
            {
                "id": "k232",
                "kabupaten": "Tidore Kepulauan"
            },
            {
                "id": "k233",
                "kabupaten": "Kepulauan Sula"
            },
            {
                "id": "k234",
                "kabupaten": "Halmahera Selatan",
                "SMA": [
                    "SMAN 8 Halmahera Selatan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k235",
                "kabupaten": "Pulau Morotai"
            },
            {
                "id": "k236",
                "kabupaten": "Halmahera Utara"
            },
            {
                "id": "k237",
                "kabupaten": "Halmahera Barat"
            },
            {
                "id": "k238",
                "kabupaten": "Ternate",
                "SMA": [
                    "SMAN 10 Kota Ternate",
                    "SMAN 1 Kota Ternate",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p21": [
            {
                "id": "k239",
                "kabupaten": "Aceh Singkil"
            },
            {
                "id": "k240",
                "kabupaten": "Subulussalam"
            },
            {
                "id": "k241",
                "kabupaten": "Aceh Tenggara",
                "SMA": [
                    "SMAN 1 Kutacane",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k242",
                "kabupaten": "Gayo Lues"
            },
            {
                "id": "k243",
                "kabupaten": "Bener Meriah"
            },
            {
                "id": "k244",
                "kabupaten": "Aceh Tengah",
                "SMA": [
                    "SMAN 1 Takengon",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k245",
                "kabupaten": "Aceh Tamiang",
                "SMA": [
                    "SMA IT Al-Arabiyyah",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k246",
                "kabupaten": "Aceh Timur",
                "SMA": [
                    "SMAN Unggul Aceh Timur",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Aceh Timur",
                ],
                "Pesantren": [
                    "Madrasah Ulumul Quran Langsa"
                ]
            },
            {
                "id": "k247",
                "kabupaten": "Langsa",
                "SMA": [
                    "SMA Unggul Cut Nyak Dhien Langsa",
                    "SMAN 1 Langsa",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MA Ulumul Qur'an Langsa"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k248",
                "kabupaten": "Aceh Utara",
                "SMA": [
                    "SMAN 1 Tanah Jambo Aye",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k249",
                "kabupaten": "Lhokseumawe",
                "SMA": [
                    "SMA Sukma Bangsa Lhokseumawe",
                    "SMAN 1 Lhokseumawe",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Kota Lhokseumawe"
                ],
                "Pesantren": [
                    "MAS Yapena",
                    "Pesantren Modern Misbahul Ulum",
                    "MA Dayah Modern Arun",
                ]
            },
            {
                "id": "k250",
                "kabupaten": "Bireuen",
                "SMA": [
                    "SMAN 1 Bireuen",
                    "SMA Sukma Bangsa Bireuen",
                    "SMA IT Entrepreneur Muhammadiyah Boarding School Bireuen",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k251",
                "kabupaten": "Pidie Jaya",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MAS Jeumala Amal",
                ]
            },
            {
                "id": "k252",
                "kabupaten": "Pidie",
                "SMA": [
                    "SMAN 3 Unggul Sigli",
                    "SMA Sukma Bangsa Pidie",
                    "SMAN Ulumul Qur'an",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k253",
                "kabupaten": "Aceh Besar",
                "SMA": [
                    "SMA IT Al-Arabiyyah",
                    "SMAN 1 Peukan Bada",
                    "SMAN Modal Bangsa Banda Aceh",
                    "SMAN 1 Indrapuri",
                    "SMAN 2 Unggul Ali Hasjmy",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pesantren Modern Al-Manar",
                    "MAS Ulumul Qur’an Pagar Air",
                    "MAS TGK Chiek Oemar Diyan",
                    "SMAS Darul Quran Aceh",
                ]
            },
            {
                "id": "k254",
                "kabupaten": "Simeulue"
            },
            {
                "id": "k255",
                "kabupaten": "Aceh Selatan"
            },
            {
                "id": "k256",
                "kabupaten": "Aceh Barat Daya"
            },
            {
                "id": "k257",
                "kabupaten": "Aceh Barat",
                "SMA": [
                    "SMAN 4 Wira Bangsa",
                    "SMAN 2 Meulaboh",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k258",
                "kabupaten": "Nagan Raya"
            },
            {
                "id": "k259",
                "kabupaten": "Aceh Jaya"
            },
            {
                "id": "k260",
                "kabupaten": "Sabang",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "SMA Islam Al-Mujaddid"
                ]
            },
            {
                "id": "k261",
                "kabupaten": "Banda Aceh",
                "SMA": [
                    "SMA Teuku Nyak Arif Fatih Bilingual School Banda Aceh",
                    "SMAN 3 Banda Aceh",
                    "SMAS Laboratorium Unsyiah Banda Aceh",
                    "SMAN 6 Kota Banda Aceh",
                    "SMAN 10 Fajar Harapan Banda Aceh ",
                    "SMA Methodist Banda Aceh",
                    "SMAN 2 Banda Aceh",
                    "SMAN 4 Banda Aceh",
                    "SMAN 1 Banda Aceh",
                    "SMAN 7 Banda Aceh",
                ],
                "SMK": [
                    "SMKN 3 Banda Aceh",
                    "SMKN 1 Banda Aceh",
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Banda Aceh",
                    "MAN Unggulan Akademik Nasional",
                    "MAN 2 Banda Aceh",
                ],
                "Pesantren": [
                    "SMAS Inshafuddin",
                    "Dayah Modern Darul Ulum Banda Aceh",
                ]
            }
        ],
        "p22": [
            {
                "id": "k262",
                "kabupaten": "Sumbawa Barat",
                "SMA": [
                    "SMA Buin Batu"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k263",
                "kabupaten": "Sumbawa",
                "SMA": [
                    "SMAN 1 Alas",
                    "SMAN 1 Sumbawa Besar",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pesantren Modern Internasional Dea Malela"
                ]
            },
            {
                "id": "k264",
                "kabupaten": "Dompu"
            },
            {
                "id": "k265",
                "kabupaten": "Bima",
                "SMA": [
                    "SMAN 1 kota Bima"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k266",
                "kabupaten": "Lombok Timur",
                "SMA": [
                    "SMAN 1 Selong",
                    "SMAN 1 Terara",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MAN Insan Cendekia Lombok timur",
                    "MA Mu'Allimin NWDI Pancor",
                ]
            },
            {
                "id": "k267",
                "kabupaten": "Lombok Tengah",
                "SMA": [
                    "SMAN 1 Praya"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Lombok Tengah",
                    "MA Nurussabah",
                ],
                "Pesantren": [
                    "SMA Islam Uswatun Hasanah"
                ]
            },
            {
                "id": "k268",
                "kabupaten": "Mataram",
                "SMA": [
                    "SMAN 7 Mataram",
                    "SMAN 1 Mataram",
                    "SMAN 9 Mataram",
                    "SMA IT Yarsi Mataram",
                    "SMAN 5 Mataram",
                    "SMAK Tunas Daud Mataram",
                    "SMAN 3 Mataram",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Mataram"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k269",
                "kabupaten": "Lombok Barat",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pondok Pesantren Nurul Haramain Putri NW Narmada",
                    "Pondok Pesantren Alam Sayang Ibu",
                ]
            },
            {
                "id": "k270",
                "kabupaten": "Lombok Utara"
            }
        ],
        "p23": [
            {
                "id": "k271",
                "kabupaten": "Sumba Barat"
            },
            {
                "id": "k272",
                "kabupaten": "Sumba Tengah",
                "SMA": [
                    "SMA Kristen Waibakul"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k273",
                "kabupaten": "Sumba Barat Daya",
                "SMA": [
                    "SMA Harapan Bangsa",
                    "SMA Santo Thomas Aquinas",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k274",
                "kabupaten": "Sumba Timur"
            },
            {
                "id": "k275",
                "kabupaten": "Manggarai Barat"
            },
            {
                "id": "k276",
                "kabupaten": "Lembata"
            },
            {
                "id": "k277",
                "kabupaten": "Manggarai"
            },
            {
                "id": "k278",
                "kabupaten": "Manggarai Timur"
            },
            {
                "id": "k279",
                "kabupaten": "Nagekeo",
                "SMA": [
                    "SMAK Baleriwu"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k280",
                "kabupaten": "Ngada"
            },
            {
                "id": "k281",
                "kabupaten": "Ende",
                "SMA": [
                    "SMAK Frateran Ndao Ende",
                    "SMAK Syuradikara Ende",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k282",
                "kabupaten": "Flores Timur",
                "SMA": [
                    "SMAK Frateran Podor",
                    "SMAK Frateran Maumere",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k283",
                "kabupaten": "Sikka",
                "SMA": [
                    "SMAN 1 Maumere",
                    "SMAK Frateran Maumere",
                ],
                "SMK": [
                    "SMAK Frateran Maumere"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k284",
                "kabupaten": "Rote Ndao"
            },
            {
                "id": "k285",
                "kabupaten": "Alor"
            },
            {
                "id": "k286",
                "kabupaten": "Belu"
            },
            {
                "id": "k287",
                "kabupaten": "Timor Tengah Utara"
            },
            {
                "id": "k288",
                "kabupaten": "Timor Tengah Selatan",
                "SMA": [
                    "SMAN 1 Soe"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k289",
                "kabupaten": "Sabu Raijua"
            },
            {
                "id": "k290",
                "kabupaten": "Kupang",
                "SMA": [
                    "SMAN 3 Kupang ",
                    "SMAN 1 Kupang",
                    "SMAK Citra Bangsa Mandiri Kupang",
                    "SMAK Giovanni Kupang",
                    "SMAN 6 Kupang",
                    "SMAN 5 Kupang ",
                    "SMA Dian Harapan Kupang",
                    "SMA Lentera Harapan Kupang",
                    "SMAK 2 Kupang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p24": [
            {
                "id": "k291",
                "kabupaten": "Mimika"
            },
            {
                "id": "k292",
                "kabupaten": "Mappi"
            },
            {
                "id": "k293",
                "kabupaten": "Asmat"
            },
            {
                "id": "k294",
                "kabupaten": "Boven Digoel"
            },
            {
                "id": "k295",
                "kabupaten": "Merauke",
                "SMA": [
                    "SMAN 3 Merauke"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k296",
                "kabupaten": "Yalimo"
            },
            {
                "id": "k297",
                "kabupaten": "Yahukimo"
            },
            {
                "id": "k298",
                "kabupaten": "Pegunungan Bintang"
            },
            {
                "id": "k299",
                "kabupaten": "Nduga"
            },
            {
                "id": "k300",
                "kabupaten": "Tolikara"
            },
            {
                "id": "k301",
                "kabupaten": "Lanny Jaya"
            },
            {
                "id": "k302",
                "kabupaten": "Jayawijaya"
            },
            {
                "id": "k303",
                "kabupaten": "Mamberamo Tengah"
            },
            {
                "id": "k304",
                "kabupaten": "Keerom"
            },
            {
                "id": "k305",
                "kabupaten": "Mamberamo Raya"
            },
            {
                "id": "k306",
                "kabupaten": "Sarmi"
            },
            {
                "id": "k307",
                "kabupaten": "Jayapura",
                "SMA": [
                    "SMA YPPK Teruna Bakti",
                    "SMA YPPK Asisi Sentani",
                    "SMAN 3 Jayapura",
                    "SMAN 1 Sentani",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k308",
                "kabupaten": "Puncak"
            },
            {
                "id": "k309",
                "kabupaten": "Puncak Jaya"
            },
            {
                "id": "k310",
                "kabupaten": "Dogiyai"
            },
            {
                "id": "k311",
                "kabupaten": "Nabire"
            },
            {
                "id": "k312",
                "kabupaten": "Intan Jaya"
            },
            {
                "id": "k313",
                "kabupaten": "Paniai"
            },
            {
                "id": "k314",
                "kabupaten": "Deiyai (Deliyai)"
            },
            {
                "id": "k315",
                "kabupaten": "Waropen"
            },
            {
                "id": "k316",
                "kabupaten": "Kepulauan Yapen (Yapen Waropen)"
            },
            {
                "id": "k317",
                "kabupaten": "Biak Numfor"
            },
            {
                "id": "k318",
                "kabupaten": "Supiori"
            }
        ],
        "p25": [
            {
                "id": "k319",
                "kabupaten": "Fakfak"
            },
            {
                "id": "k320",
                "kabupaten": "Kaimana"
            },
            {
                "id": "k321",
                "kabupaten": "Maybrat"
            },
            {
                "id": "k322",
                "kabupaten": "Raja Ampat"
            },
            {
                "id": "k323",
                "kabupaten": "Tambrauw"
            },
            {
                "id": "k324",
                "kabupaten": "Sorong",
                "SMA": [
                    "SMAN 2 Kota Sorong"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Sorong"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k325",
                "kabupaten": "Sorong Selatan"
            },
            {
                "id": "k326",
                "kabupaten": "Teluk Bintuni",
                "SMA": [
                    "SMAN 1 Bintuni",
                    "SMA Harmony School terpadu",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k327",
                "kabupaten": "Teluk Wondama"
            },
            {
                "id": "k328",
                "kabupaten": "Manokwari Selatan"
            },
            {
                "id": "k329",
                "kabupaten": "Pegunungan Arfak"
            },
            {
                "id": "k330",
                "kabupaten": "Manokwari"
            }
        ],
        "p26": [
            {
                "id": "k331",
                "kabupaten": "Kuantan Singingi",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MA Al-Hidayah"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k332",
                "kabupaten": "Indragiri Hulu"
            },
            {
                "id": "k333",
                "kabupaten": "Indragiri Hilir"
            },
            {
                "id": "k334",
                "kabupaten": "Rokan Hilir",
                "SMA": [
                    "SMAN 1 Kubu"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k335",
                "kabupaten": "Dumai",
                "SMA": [
                    "SMAN Binaan Khusus Dumai",
                    "SMAN 2 Dumai",
                    "SMAS YKPP Kota Dumai",
                    "SMAIT Plus Bazma Brilliant",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k336",
                "kabupaten": "Bengkalis",
                "SMA": [
                    "SMA IT Mutiara Bengkalis",
                    "SMAN 3 Mandau",
                    "SMAN 7 Mandau",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k337",
                "kabupaten": "Siak",
                "SMA": [
                    "SMAN 5 Tualang",
                    "SMAN 1 Tualang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Siak"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k338",
                "kabupaten": "Kepulauan Meranti"
            },
            {
                "id": "k339",
                "kabupaten": "Rokan Hulu",
                "SMA": [
                    "SMAN 2 Rambah Hilir"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k340",
                "kabupaten": "Kampar",
                "SMA": [
                    "SMAN Plus Provinsi Riau"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k341",
                "kabupaten": "Pelalawan",
                "SMA": [
                    "SMAN 1 Kerumutan",
                    "SMA Mutiara Harapan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k342",
                "kabupaten": "Pekanbaru",
                "SMA": [
                    "SMAN 1 Pekanbaru",
                    "SMAN 4 Pekanbaru",
                    "SMAN 8 Pekanbaru",
                    "SMA Cendana Pekanbaru",
                    "SMA Taruna Pekanbaru",
                    "SMA Darma Yudha",
                    "SMAN 2 Pekanbaru",
                    "SMAN 5 Pekanbaru",
                ],
                "SMK": [
                    "SMKN 2 Pekanbaru",
                    "SMKN 3 Pekanbaru",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Pekanbaru",
                    "MAN 1 Pekanbaru",
                ],
                "Pesantren": [
                ]
            }
        ],
        "p27": [
            {
                "id": "k343",
                "kabupaten": "Polewali Mandar",
                "SMA": [
                    "SMA 1 Polewali",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k344",
                "kabupaten": "Mamuju Utara"
            },
            {
                "id": "k345",
                "kabupaten": "Mamuju"
            },
            {
                "id": "k346",
                "kabupaten": "Majene"
            },
            {
                "id": "k347",
                "kabupaten": "Mamasa",
                "SMA": [
                    "SMAN 1 Mamasa",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            }
        ],
        "p28": [
            {
                "id": "k348",
                "kabupaten": "Luwu Timur",
                "SMA": [
                    "SMAN 1 Luwu Timur"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k349",
                "kabupaten": "Luwu Utara"
            },
            {
                "id": "k350",
                "kabupaten": "Selayar (Kepulauan Selayar)"
            },
            {
                "id": "k351",
                "kabupaten": "Bone",
                "SMA": [
                    "SMAN 1 Bone",
                    "SMA Islam Athirah Bone",
                    "SMAN 13 Bone",
                    "SMAN 3 Bone",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MA Al-ikhlas Ujung Bone"
                ]
            },
            {
                "id": "k352",
                "kabupaten": "Maros",
                "SMA": [
                    "SMA Angkasa Lanud Sultan Hasanuddin Maros",
                    "SMAN 1 Maros",
                    "SMA IT Darul Istiqamah",
                    "SMA IT Putri Darul Istiqomah",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pesantren IMMIM putra Moncongloe"
                ]
            },
            {
                "id": "k353",
                "kabupaten": "Sinjai",
                "SMA": [
                    "SMAN 5 Sinjai"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k354",
                "kabupaten": "Bulukumba",
                "SMA": [
                    "SMAN 2 Bulukumba"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MA Rahmatul Asri"
                ]
            },
            {
                "id": "k355",
                "kabupaten": "Jeneponto"
            },
            {
                "id": "k356",
                "kabupaten": "Bantaeng",
                "SMA": [
                    "SMAN 1 Bantaeng"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k357",
                "kabupaten": "Takalar",
                "SMA": [
                    "SMAN 1 Takalar"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k358",
                "kabupaten": "Gowa",
                "SMA": [
                    "SMAN 1 Gowa",
                    "SMA IT Alfityan Gowa",
                    "SMAN 10 Gowa",
                    "SMAN 5 Gowa",
                ],
                "SMK": [
                   "SMKN 2 Gowa"
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Gowa"
                ],
                "Pesantren": [
                    "Pondok Pesantren Sultan Hasanuddin Gowa"
                ]
            },
            {
                "id": "k359",
                "kabupaten": "Toraja Utara",
                "SMA": [
                    "SMAK 2 Rantepao",
                    "SMAK Barana",
                    "SMAN 1 Toraja Utara",
                    "SMA Lentera Harapan Toraja",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k360",
                "kabupaten": "Luwu"
            },
            {
                "id": "k361",
                "kabupaten": "Palopo",
                "SMA": [
                    "SMAN 3 Palopo",
                    "SMAN 1 Palopo",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k362",
                "kabupaten": "Tana Toraja",
                "SMA": [
                    "SMAN 1 Tana Toraja"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k363",
                "kabupaten": "Enrekang"
            },
            {
                "id": "k364",
                "kabupaten": "Sidenreng Rappang/Rapang"
            },
            {
                "id": "k365",
                "kabupaten": "Pinrang",
                "SMA": [
                    "SMAN 3 Pinrang",
                    "SMAN 2 Pinrang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k366",
                "kabupaten": "Parepare",
                "SMA": [
                    "SMAN 1 Parepare",
                    "SMAN 5 Parepare",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k367",
                "kabupaten": "Wajo",
                "SMA": [
                    "SMAN 7 Wajo"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Wajo"
                ],
                "Pesantren": [
                    "MA Putri Sengkang"
                ]
            },
            {
                "id": "k368",
                "kabupaten": "Soppeng",
                "SMA": [
                    "SMAN 1 Soppeng"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k369",
                "kabupaten": "Barru",
                "SMA": [
                    "SMAN 1 Barru"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k370",
                "kabupaten": "Pangkajene Kepulauan",
                "SMA": [
                    "SMAN 11 Pangkajene Kepulauan",
                    "SMAN 13 Pangkajene Kepulauan",
                ],
                "SMK": [
                    "SMKN 1 Pangkajene Kepulauan"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Pondok Pesantren Shohwatul Is'ad"
                ]
            },
            {
                "id": "k371",
                "kabupaten": "Makassar",
                "SMA": [
                    "SMAN 16 Makassar",
                    "SMAN 17 Makassar",
                    "SMAN 18 Makassar",
                    "SMAK Rajawali",
                    "SMA Plus Al-Ashri",
                    "SMAN 5 Makassar",
                    "SMAN 1 Makassar",
                    "SMAK Rajawali",
                    "SMA Advent Makassar",
                    "SMAN 21 Makassar",
                    "SMAN 17 Makassar",
                    "SMAN 23 Makassar",
                    "SMAIT Ar-Rahmah",
                    "SMAN 9 Makassar",
                    "SMAN 6 Makassar",
                    "SMAK Gamaliel Makassar",
                    "SMAN 2 Makassar",
                    "SMAN 12 Makassar",
                    "SMA Metro Makassar",
                    "SMA Islam Athirah 2 Makassar",
                    "SMAK Cendrawasih Makassar",
                    "SMA Islam Athirah 1 Makassar",
                ],
                "SMK": [
                    "SMK Telkom Makassar",
                    "SMK Kehutanan Negeri Makassar",
                    "SMKN 8 Makassar",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Makassar",
                    "MAN 1 Makassar",
                    "MAN 3 Makassar",
                ],
                "Pesantren": [
                    "Pondok Pesantren IMMIM Putra Makassar",
                    "Pondok Pesantren Puteri Ummul Mukminin Makassar",
                ]
            }
        ],
        "p29": [
            {
                "id": "k372",
                "kabupaten": "Morowali"
            },
            {
                "id": "k373",
                "kabupaten": "Banggai Kepulauan"
            },
            {
                "id": "k374",
                "kabupaten": "Banggai"
            },
            {
                "id": "k375",
                "kabupaten": "Tojo Una-Una"
            },
            {
                "id": "k376",
                "kabupaten": "Poso"
            },
            {
                "id": "k377",
                "kabupaten": "Buol"
            },
            {
                "id": "k378",
                "kabupaten": "Toli-Toli"
            },
            {
                "id": "k379",
                "kabupaten": "Parigi Moutong"
            },
            {
                "id": "k380",
                "kabupaten": "Sigi"
            },
            {
                "id": "k381",
                "kabupaten": "Donggala"
            },
            {
                "id": "k382",
                "kabupaten": "Palu",
                "SMA": [
                    "SMA Labschool Untad Palu",
                    "SMA Al-Azhar Mandiri Palu",
                    "SMAN Model Terpadu Madani",
                    "SMAK Palu",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Kota Palu",
                    "MAN 2 Palu",
                ],
                "Pesantren": [
                    "MA Putri Sengkang"
                ]
            }
        ],
        "p30": [
            {
                "id": "k383",
                "kabupaten": "Kolaka Utara"
            },
            {
                "id": "k384",
                "kabupaten": "Kolaka",
                "SMA": [
                    "SMAN 1 Kolaka"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k385",
                "kabupaten": "Konawe Selatan",
                "SMA": [
                    "SMAN 3 Konawe Selatan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k386",
                "kabupaten": "Wakatobi"
            },
            {
                "id": "k387",
                "kabupaten": "Bombana"
            },
            {
                "id": "k388",
                "kabupaten": "Buton"
            },
            {
                "id": "k389",
                "kabupaten": "Konawe",
                "SMA": [
                    "SMAN 1 Wawotobi"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k390",
                "kabupaten": "Bau-Bau",
                "SMA": [
                    "SMAN 2 Bau-Bau"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k391",
                "kabupaten": "Muna",
                "SMA": [
                    "SMAN 1 Kusambi"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k392",
                "kabupaten": "Buton Utara"
            },
            {
                "id": "k393",
                "kabupaten": "Konawe Utara"
            },
            {
                "id": "k394",
                "kabupaten": "Kendari",
                "SMA": [
                    "SMAN 4 kendari"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Kendari"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p31": [
            {
                "id": "k395",
                "kabupaten": "Minahasa Selatan"
            },
            {
                "id": "k396",
                "kabupaten": "Minahasa Tenggara"
            },
            {
                "id": "k397",
                "kabupaten": "Kepulauan Talaud"
            },
            {
                "id": "k398",
                "kabupaten": "Kepulauan Siau Tagulandang Biaro (Sitaro)"
            },
            {
                "id": "k399",
                "kabupaten": "Kepulauan Sangihe",
                "SMA": [
                    "SMA Lentera Harapan Sangihe"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k400",
                "kabupaten": "Bolaang Mongondow Timur"
            },
            {
                "id": "k401",
                "kabupaten": "Bolaang Mongondow Selatan"
            },
            {
                "id": "k402",
                "kabupaten": "Bolaang Mongondow (Bolmong)"
            },
            {
                "id": "k403",
                "kabupaten": "Bolaang Mongondow Utara"
            },
            {
                "id": "k404",
                "kabupaten": "Kotamobagu",
                "SMA": [
                    "SMAK Kotamobagu"
                ],
                "SMK": [
                    "SMKN 2 Kotamobagu"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k405",
                "kabupaten": "Minahasa",
                "SMA": [
                ],
                "SMK": [
                    "SMKN PP Kalasey"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k406",
                "kabupaten": "Bitung"
            },
            {
                "id": "k407",
                "kabupaten": "Tomohon",
                "SMA": [
                    "SMA Lentera Harapan Tomohon",
                    "SMAN 1 Tomohon",
                    "SMAK 1 Tomohon",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k408",
                "kabupaten": "Minahasa Utara"
            },
            {
                "id": "k409",
                "kabupaten": "Manado",
                "SMA": [
                    "SMAN 9 Manado",
                    "SMAN 1 Manado",
                    "SMAN 7 Manado",
                    "SMA Frater Don Bosco Manado",
                    "SMAK Eben Haezar Manado",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Model 1 Plus Keterampilan Manado"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p32": [
            {
                "id": "k410",
                "kabupaten": "Solok Selatan"
            },
            {
                "id": "k411",
                "kabupaten": "Dharmasraya"
            },
            {
                "id": "k412",
                "kabupaten": "Sijunjung (Sawah Lunto Sijunjung)"
            },
            {
                "id": "k413",
                "kabupaten": "Sawah Lunto",
                "SMA": [
                    "SMAN 2 Sawahlunto"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k414",
                "kabupaten": "Solok",
                "SMA": [
                    "SMAN 3 Solok",
                    "SMAN 2 Sumatra Barat",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k415",
                "kabupaten": "Tanah Datar",
                "SMA": [
                    "SMA Excellent Nurul Ikhlas"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Tanah Datar"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k416",
                "kabupaten": "Padang Panjang",
                "SMA": [
                    "SMAN 1 Padang Panjang",
                    "SMAN 2 Padang Panjang",
                    "SMAN 3 Padang Panjang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAS Kulliyatul Muballighien Muhammadiyah"
                ],
                "Pesantren": [
                    "MAS Kui Thawalib Putra",
                    "Pondok Pesantren Perguruan Diniyyah Puteri",
                    "MAS KMM Kauman Padang Panjang",
                    "MAS Kulliatul Muballighien",
                ]
            },
            {
                "id": "k417",
                "kabupaten": "Pasaman Barat",
                "SMA": [
                    "SMAN 1 Pasaman"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k418",
                "kabupaten": "Agam",
                "SMA": [
                    "SMAN Agam Cendekia",
                    "SMAN 2 Lubuk Basung",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "MAs Sumatera Thawalib Parabek Bukittinggi Agam"
                ]
            },
            {
                "id": "k419",
                "kabupaten": "Pasaman",
                "SMA": [
                    "SMAN 3 Sumatra Barat"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k420",
                "kabupaten": "Lima Puluh Koto/Kota",
                "SMA": [
                    "SMA IT Insan Cendekia Boarding School Harau Payakumbuh"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Lima Puluh Kota"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k421",
                "kabupaten": "Payakumbuh",
                "SMA": [
                    "SMAN 2 Payakumbuh",
                    "SMAN 3 Payakumbuh",
                    "SMA IT Insan Cendekia Payakumbuh",
                    "SMAN 4 Payakumbuh",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k422",
                "kabupaten": "Bukittinggi",
                "SMA": [
                    "SMAN 1 Bukittinggi",
                    "SMAN 2 Bukittinggi",
                    "SMAN 3 Bukittinggi",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k423",
                "kabupaten": "Pesisir Selatan",
                "SMA": [
                    "SMAN 3 Painan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k424",
                "kabupaten": "Padang Pariaman",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Padang Pariaman"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k425",
                "kabupaten": "Pariaman",
                "SMA": [
                    "SMAN 1 Pariaman"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k426",
                "kabupaten": "Kepulauan Mentawai"
            },
            {
                "id": "k427",
                "kabupaten": "Padang",
                "SMA": [
                    "SMA Xaverius Padang",
                    "SMAN 13 Padang",
                    "SMAN 3 Padang",
                    "SMAN 10 Padang",
                    "SMAN 8 Padang",
                    "SMAN 12 Padang",
                    "SMA Pembangunan Laboratorium UNP padang",
                    "SMAN 1 Padang",
                    "SMAN 4 Padang",
                    "SMA Donbosco Padang",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Padang"
                ],
                "Pesantren": [
                    "MAS Ar-Risalah"
                ]
            }
        ],
        "p33": [
            {
                "id": "k428",
                "kabupaten": "Ogan Komering Ulu Timur"
            },
            {
                "id": "k429",
                "kabupaten": "Ogan Komering Ulu Selatan"
            },
            {
                "id": "k430",
                "kabupaten": "Ogan Komering Ulu"
            },
            {
                "id": "k431",
                "kabupaten": "Muara Enim",
                "SMA": [
                ],
                "SMK": [
                    "SMK Bina Mulya"
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k432",
                "kabupaten": "Musi Rawas"
            },
            {
                "id": "k433",
                "kabupaten": "Lubuk Linggau",
                "SMA": [
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Model Lubuk Linggau"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k434",
                "kabupaten": "Empat Lawang"
            },
            {
                "id": "k435",
                "kabupaten": "Lahat"
            },
            {
                "id": "k436",
                "kabupaten": "Pagar Alam"
            },
            {
                "id": "k437",
                "kabupaten": "Prabumulih",
                "SMA": [
                    "SMAN 6 Prabumulih"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k438",
                "kabupaten": "Banyuasin",
                "SMA": [
                    "SMAN Sumatra Selatan",
                    "SMAN Plus 2 Banyuasin III",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k439",
                "kabupaten": "Ogan Ilir"
            },
            {
                "id": "k440",
                "kabupaten": "Musi Banyuasin"
            },
            {
                "id": "k441",
                "kabupaten": "Ogan Komering Ilir",
                "SMA": [
                    "SMAN 1 Kayuagung",
                    "SMAN 1 Lempung Jaya",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Insan Cendekia Ogan Komering Ilir"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k442",
                "kabupaten": "Palembang",
                "SMA": [
                    "SMAN Sumatra Selatan",
                    "SMA Kusuma Bangsa Palembang",
                    "SMA Xaverius 1 Palembang",
                    "SMAN 9 Palembang",
                    "SMA Ignatius Global Palembang",
                    "SMAN Plus 17 Palembang",
                    "SMAN 3 Palembang",
                    "SMAN 1 Palembang",
                    "SMAN 4 Palembang",
                    "SMA Tri Dharma Palembang",
                    "SMAN 6 Palembang",
                    "SMAN 13 Palembang",
                    "SMAN 3 Palembang",
                    "SMAN Maju Grak Palembang",
                    "SMAN 17 Palembang",
                    "SMAN 15 Palembang",
                    "SMA Muhammadiyah 1 Palembang",
                    "SMAN 8 Palembang",
                    "SMAN 2 Palembang ",
                    "SMAN 10 Palembang",
                    "SMA YPI Tunas Bangsa",
                    "SMA Methodist 2 Palembang",
                ],
                "SMK": [
                    "SMKN Sumatera Selatan",
                    "SMKN 2 Palembang",
                ],
                "MA (Non Pesantren)": [
                    "MAN 3 Palembang"
                ],
                "Pesantren": [
                ]
            }
        ],
        "p34": [
            {
                "id": "k443",
                "kabupaten": "Langkat"
            },
            {
                "id": "k444",
                "kabupaten": "Mandailing Natal",
                "SMA": [
                    "SMAN 2 Plus Panyabungan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k445",
                "kabupaten": "Nias Selatan",
                "SMA": [
                    "SMAN 1 Teluk Dalam"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k446",
                "kabupaten": "Nias"
            },
            {
                "id": "k447",
                "kabupaten": "Nias Barat"
            },
            {
                "id": "k448",
                "kabupaten": "Gunungsitoli"
            },
            {
                "id": "k449",
                "kabupaten": "Nias Utara"
            },
            {
                "id": "k450",
                "kabupaten": "Padang Lawas"
            },
            {
                "id": "k451",
                "kabupaten": "Tapanuli Selatan"
            },
            {
                "id": "k452",
                "kabupaten": "Padang Lawas Utara"
            },
            {
                "id": "k453",
                "kabupaten": "Padang Sidempuan",
                "SMA": [
                    "SMAS Nurul 'Ilmi Padang Sidempuan",
                    "SMA Nurul Ilmi",
                    "SMAN 2 Padang Sidempuan",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN 1 Padang Sidempuan ",
                    "MAN 1 Plus Riset",
                    "MAN 2 Model Padang Sidempuan ",
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k454",
                "kabupaten": "Tapanuli Tengah",
                "SMA": [
                    "SMAN 1 Matauli Pandan"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k455",
                "kabupaten": "Sibolga",
                "SMA": [
                    "SMAN 3 Sibolga",
                    "SMAK Sibolga",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k456",
                "kabupaten": "Tapanuli Utara",
                "SMA": [
                    "SMAN 3 Tarutung"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k457",
                "kabupaten": "Humbang Hasundutan"
            },
            {
                "id": "k458",
                "kabupaten": "Samosir"
            },
            {
                "id": "k459",
                "kabupaten": "Toba Samosir",
                "SMA": [
                    "SMA Unggul Del"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k460",
                "kabupaten": "Dairi"
            },
            {
                "id": "k461",
                "kabupaten": "Pakpak Bharat"
            },
            {
                "id": "k462",
                "kabupaten": "Karo",
                "SMA": [
                    "SMAN 2 Kabanjahe"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k463",
                "kabupaten": "Labuhan Batu Utara"
            },
            {
                "id": "k464",
                "kabupaten": "Labuhan Batu",
                "SMA": [
                    "SMAN 1 Panai Hulu"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k465",
                "kabupaten": "Labuhan Batu Selatan"
            },
            {
                "id": "k466",
                "kabupaten": "Asahan",
                "SMA": [
                    "SMAN 4 Kisaran",
                    "SMAN 2 Kisaran",
                    "SMA Diponegoro Kisaran",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k467",
                "kabupaten": "Tanjung Balai",
                "SMA": [
                    "SMAN 2 Tanjung Balai"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Tanjungbalai"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k468",
                "kabupaten": "Batu Bara",
                "SMA": [
                    "SMAN 1 Sei Suka"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k469",
                "kabupaten": "Simalungun",
                "SMA": [
                    "SMA Plus Efarina"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k470",
                "kabupaten": "Pematang Siantar",
                "SMA": [
                    "SMAN 6 Pematang Siantar",
                    "SMAN 4 Pematang Siantar",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k471",
                "kabupaten": "Serdang Bedagai"
            },
            {
                "id": "k472",
                "kabupaten": "Binjai",
                "SMA": [
                    "SMAN 2 Binjai"
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Binjai"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k473",
                "kabupaten": "Tebing Tinggi",
                "SMA": [
                    "SMAN 1 Tebing Tinggi",
                    "SMAN 2 Tebing Tinggi",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                    "MAN Tebing Tinggi"
                ],
                "Pesantren": [
                ]
            },
            {
                "id": "k474",
                "kabupaten": "Deli Serdang",
                "SMA": [
                    "SMAN 2 Lubuk Pakam",
                    "SMAN 1 Lubuk Pakam",
                    "SMA Harapan 3 Deli Tua",
                    "SMAN 1 Galang",
                    "SMA Unggulan CT Arsa Foundation",
                    "SMA Yapim Biru Biru",
                ],
                "SMK": [
                ],
                "MA (Non Pesantren)": [
                ],
                "Pesantren": [
                    "Madrasah Aliyah Swasta Nurul Hakim"
                ]
            },
            {
                "id": "k475",
                "kabupaten": "Medan",
                "SMA": [
                    "SMAK Kalam Kudus Medan",
                    "SMA Al-Azhar Medan",
                    "SMA Swasta Panca Budi",
                    "SMAN 7 Medan",
                    "SMA Lentera Harapan Medan",
                    "SMAIT Al-Fityan Medan",
                    "SMAN 1 Medan",
                    "SMA Methodist 5 Medan",
                    "SMA Global Prima",
                    "SMAN 4 Medan ",
                    "SMA Methodist 8 Medan",
                    "SMA Panca Budi Medan",
                    "SMA Methodist 2 Medan",
                    "SMA Bangun Insan Mandiri",
                    "SMA Sutomo 2",
                    "SMA Santo Thomas 3 Medan",
                    "SMA Methodist 3 Medan",
                    "SMA Santo Thomas 2 Medan",
                    "SMA PrimeOne",
                    "SMA Laksamana Martadinata",
                    "SMA Sultan Iskandar Muda",
                    "SMA Dharmawangsa",
                    "SMA Islam Plus Adzkia Medan",
                    "SMA Plus Taruna Akterlis Medan",
                    "MA Muallimin UNIVA",
                    "SMAN 8 Medan",
                ],
                "SMK": [
                    "SMK Panca Budi",
                    "SMKN 8 Medan",
                    "SMKN 1 Medan",
                    "SMKN 7 Medan",
                ],
                "MA (Non Pesantren)": [
                    "MAN 2 Medan",
                    "MAN 1 Medan",
                    "MAN 2 Model Medan",
                ],
                "Pesantren": [
                ]
            }
        ]
    },
    kecamatan: {
        "k1": [
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Pulukan",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Pekutatan",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Pengeragoan (Pengragoan)",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Pangyangan",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Medewi",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Gumbrih",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Manggissari",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Pekutatan",
                "kelurahan": "Asahduren",
                "kodepos": "82262"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Yeh Embang Kauh",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Yeh Sumbul",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Yeh Embang Kangin",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Yeh Embang",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Tegal Cangkring",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Pohsanten",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Pergung",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Penyaringan",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Mendoyo Dangin Tukad",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Mendoyo Dauh Tukad",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Mendoyo",
                "kelurahan": "Delod Berawah",
                "kodepos": "82261"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Warnasari",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Tuwed",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Nusa Sari",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Tukadaya",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Melaya",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Manistutu",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Gilimanuk",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Ekasari",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Blimbingsari (Belimbingsari)",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Melaya",
                "kelurahan": "Candikusuma",
                "kodepos": "82252"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Tegal Badeng Timur",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Yeh Kuning",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Tegal Badeng Barat",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Pengambengan",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Perancak",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Sangkaragung",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Kaliakah",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Dauhwaru",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Cupel",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Budeng",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Dangin Tukadaya",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Banyubiru",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Berangbang",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Batuagung",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Baluk",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Air Kuning",
                "kodepos": "82218"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Loloan Barat",
                "kodepos": "82215"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Loloan Timur",
                "kodepos": "82216"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Banjar Tengah",
                "kodepos": "82213"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Lelateng",
                "kodepos": "82214"
            },
            {
                "kecamatan": "Negara",
                "kelurahan": "Baler Bale Agung",
                "kodepos": "82212"
            },
            {
                "kecamatan": "Jembrana",
                "kelurahan": "Pendem",
                "kodepos": "82211"
            }
        ],
        "k2": [
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Perean Tengah",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Perean",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Perean Kangin",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Luwus",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Mekarsari",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Baturiti",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Candikuning",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Bangli",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Batunya",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Antapan",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Apuan",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Baturiti",
                "kelurahan": "Angseri",
                "kodepos": "82191"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Tegaljadi",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Tua",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Peken Belayu",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Petiga",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Selanbawak",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Marga Dauh Puri",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Payangan",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Marga",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Marga Dajan Puri",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Kukuh",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Kuwum",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Beringkit",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Caubelayu",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Geluntung",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Marga",
                "kelurahan": "Batannyuh",
                "kodepos": "82181"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Pujungan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Pupuan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Sanda",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Padangan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Pajahan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Kebon Padangan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Munduk Temu",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Karya Sari",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Belimbing",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Jelijih Punggang",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Batungsel",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Belatungan",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Pupuan",
                "kelurahan": "Bantiran",
                "kodepos": "82163"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Wanagiri Kauh",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Wanagiri",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Tiying Gading",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Tangguntiti",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Tegal Mengkeb",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Selemadeg",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Serampingan",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Pupuan Sawah",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Selabih",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Mundeh",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Mundeh Kangin",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Mundeh Kauh",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Megati",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Manikyang",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Mambang",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Lumbung Kauh",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Lumbung",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Gunung Salak",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Lalang Linggah",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Gadung Sari",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Gadungan",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Beraban",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Dalang",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Berembeng",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Bengkel Sari",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Bajera Utara",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salamadeg Timur",
                "kelurahan": "Bantas",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Bajera",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Antosari",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg",
                "kelurahan": "Antap",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Selemadeg / Salemadeg Barat",
                "kelurahan": "Angkah",
                "kodepos": "82162"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Tibu Biu (Tibubiyu)",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Timpag",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Tista",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Penarukan",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Samsam",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Sembung Gede",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Meliling",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Pangkung Karung",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Kerambitan",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Kesiut",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Kukuh",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Belumbang",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Kelating",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Batuaji",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Kerambitan",
                "kelurahan": "Baturiti",
                "kodepos": "82161"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Tegalinggah",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Tengkudak",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Wongaya Gede",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Senganan",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Tajen",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Sangketan",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Rejasa",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Riang Gede",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Pesagi",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Pitra",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Penatahan",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Penebel",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Jatiluwih",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Jegu",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Mengeste",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Buruan",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Babahan",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Penebel",
                "kelurahan": "Biaung",
                "kodepos": "82152"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Banjar Anyar",
                "kodepos": "82123"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Pangkung Tibah",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Pejaten",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Pandak Bandung",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Pandak Gede",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Nyambu",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Nyitdah",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Kaba-Kaba",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Kediri",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Buwit",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Cepaka",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Bengkel",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Beraban",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Abian Tuwung",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Belalang",
                "kodepos": "82121"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Sudimara",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Tunjuk",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Subamia",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Sesandan",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Gubug",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Dejan Peken (Dajan Peken)",
                "kodepos": "82114"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Buahan",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Denbantas",
                "kodepos": "82115"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Bongan (Boongan)",
                "kodepos": "82112"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Delod Peken",
                "kodepos": "82113"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Wanasari",
                "kodepos": "82111"
            },
            {
                "kecamatan": "Tabanan",
                "kelurahan": "Dauh Peken",
                "kodepos": "82111"
            }
        ],
        "k3": [
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Tejakula",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Tembok",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Sambirenteng",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Sembiran",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Pacung",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Penuktukan",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Les",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Madenan",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Bondalem",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Tejakula",
                "kelurahan": "Julah",
                "kodepos": "81173"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Tunjung",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Tajun",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Tambakan",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Tamblang",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Mengening",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Pakisan",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Depeha",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Kubutambahan",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Bukti",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Bulian",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Bila",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Bontihing",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Kubutambahan",
                "kelurahan": "Bengkala",
                "kodepos": "81172"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Suwug",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Sudaji",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Sekumpul",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Sinabun",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Sawan",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Sangsit",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Menyali",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Kerobokan",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Lemukih",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Galungan",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Giri Emas",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Jagaraga",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Bebetin",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sawan",
                "kelurahan": "Bungkulan",
                "kodepos": "81171"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Tegal Linggah (Tegalinggah)",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Wanagiri",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Silangjana",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Sukasada",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Sambangan",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Selat",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Panji Anom",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Pegadungan",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Pegayaman",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Padangbulia",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Pancasari",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Panji",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Gitgit",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Kayuputih",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Tukad Sumaga",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Sukasada",
                "kelurahan": "Ambengan",
                "kodepos": "81161"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Sumberkima",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Tinga Tinga",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Sanggalangit",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Sumber Klampok",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Pemuteran",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Pemuteran",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Pengulon",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Patas",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Pejarakan",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Celukan Bawang",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Gerokgak",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Musi",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Gerokgak",
                "kelurahan": "Banyupoh",
                "kodepos": "81155"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Titab",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Umejero",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Telaga",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Tinggarsari",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Tista",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Sepang Kelod",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Subuk",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Sepang",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Pucaksari",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Bongancina",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Kedia (Kedis)",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Busungbiu",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Kekeran",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Unggahan",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Pelapuan",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Busungbiu",
                "kelurahan": "Bengkel",
                "kodepos": "81154"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Ularan",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Umeanyar",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Tangguwisia",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Seririt",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Sulanyah",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Rangdu",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Ringdikit",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Pangkungparuk",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Patemoh (Patemon)",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Pengastulan",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Mayong",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Munduk Bestala",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Kalianget",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Kalisada",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Bubunan",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Lokapaksa",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Banjar Asem",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Gunungsari",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Bestala",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Seririt",
                "kelurahan": "Joanyar",
                "kodepos": "81153"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Tirtasari",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Tigawasa",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Temukus",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Pedawa",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Sidetapa",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Tampekan",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Kayuputih",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Munduk",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Gobleg",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Kaliasem",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Cempaga",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banjar Tegeha",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Dencarik",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Tukadmungga",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Gesing",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Petandakan",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banyuatis",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Poh Bergong",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banyusri",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Sari Mekar",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banjar",
                "kodepos": "81152"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Penarukan",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Penglatan",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Liligundi",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Nagasepaha",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Pemaron",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Jinengdalem",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kalibukbuk",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Beratan",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Bhakti Seraga (Baktiseraga)",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Banyuning",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kaliuntu",
                "kodepos": "81116"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Alasangker",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kampung Anyar",
                "kodepos": "81115"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Anturan",
                "kodepos": "81119"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kampung Bugis",
                "kodepos": "81115"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Banjar Tegal",
                "kodepos": "81117"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kampung Baru",
                "kodepos": "81114"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Paket Agung",
                "kodepos": "81118"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kampung Kajanan",
                "kodepos": "81114"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Banyuasri",
                "kodepos": "81116"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Banjar Bali",
                "kodepos": "81113"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Banjar Jawa",
                "kodepos": "81113"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Astina",
                "kodepos": "81112"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kendran",
                "kodepos": "81112"
            },
            {
                "kecamatan": "Buleleng",
                "kelurahan": "Kampung Singaraja",
                "kodepos": "81111"
            }
        ],
        "k4": [
            {
                "kecamatan": "Manggis",
                "kelurahan": "Sengkidu",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Tenganan",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Ulakan",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Padangbai",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Pesedahan",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Selumbung",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Manggis",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Antiga Kelod",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Ngis",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Wisma Kerta",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Nyuh Tebel",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Antiga",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Manggis",
                "kelurahan": "Antiga Kelod",
                "kodepos": "80871"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Telaga Tawang",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Tri Eka Buana",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Sindu Wati",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Talibeng",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Tangkup",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Sangkan Gunung",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Sidemen",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Lokasari",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Rendang",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Sidemen",
                "kelurahan": "Kertha Buana",
                "kodepos": "80864"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Pempatan",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Pesaban",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Nongan",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Menanga",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Sebudi",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Rendang",
                "kelurahan": "Besakih",
                "kodepos": "80863"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Duda Utara",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Pering Sari",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Muncan",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Duda Timur",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Amerta Bhuana",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Duda",
                "kodepos": "80862"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Macang",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Sibetan",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Bungaya Kangin",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Jungutan",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Budakeling (Bude Keling)",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Bungaya (Bungaya Kauh)",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Bebandem",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Bebandem",
                "kelurahan": "Buana Giri",
                "kodepos": "80861"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tianyar Timur",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tulamben",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sukadana",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tianyar Barat",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tianyar Tengah",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Kubu",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Dukuh",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Ban",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Batu Ringgit",
                "kodepos": "80853"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Tiyingtali",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Tri Buana",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Tista",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Nawakerti",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Pidpid",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Purwakerti",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Kesimpar",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Laba Sari",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Datah",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Kerta Mandala",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Culik",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Ababi",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Abang",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Abang",
                "kelurahan": "Bunutan",
                "kodepos": "80852"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Subagan",
                "kodepos": "80813"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Tegallinggah",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Tumbu",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Seraya Tengah",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Seraya Timur",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Pertima",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Seraya Barat",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Padang Kerta",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Karangasem",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Bugbug",
                "kodepos": "80811"
            },
            {
                "kecamatan": "Karang Asem",
                "kelurahan": "Bukit",
                "kodepos": "80811"
            }
        ],
        "k5": [
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Suana",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Tanglad",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Sekartaji",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Sakti",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Ped",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Pejukutan",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Lembongan",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Kutampi Kaler",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Klumpu",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Kutampi",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Kampung Toyapakeh",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Jungutbatu",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Batununggul",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Bunga Mekar",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Batukandik",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Nusapenida",
                "kelurahan": "Batumadeg",
                "kodepos": "80771"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Sampalan Tengah",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Sulang",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Sampalan Klod",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Pikat",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Pesinggahan",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Paksebali",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Kusamba",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Kampung Kusamba",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Gunaksa",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Dawan Klod",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Besan",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Dawan",
                "kelurahan": "Dawan Kaler",
                "kodepos": "80761"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Tohpati",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Tusan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Timuhun",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Takmung",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Tihingan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Nyanglan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Nyalian",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Negari",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Getakan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Bungbungan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Bakas",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Banjarangkan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Banjarangkan",
                "kelurahan": "Aan",
                "kodepos": "80752"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Tegak",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Tojan",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Klod/Kelod",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Tangkas",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Kangin",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Klod Kangin",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Selat",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Selisihan",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Satra",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Kampung Gelgel",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Manduang",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Jumpai",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Kamasan",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Akah",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Gelgel",
                "kodepos": "80716"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Kaja",
                "kodepos": "80712"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Kauh",
                "kodepos": "80713"
            },
            {
                "kecamatan": "Klungkung",
                "kelurahan": "Semarapura Tengah",
                "kodepos": "80711"
            }
        ],
        "k6": [
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Undisan",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Yangapi",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Peninjoan",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Tembuku",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Bangbang",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Tembuku",
                "kelurahan": "Jehem",
                "kodepos": "80671"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Sulahan",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Susut",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Tiga",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Penglumbaran",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Selat",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Pengiangan",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Apuan",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Demulih",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Susut",
                "kelurahan": "Abuan",
                "kodepos": "80661"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Suter",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Terunyan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Ulian",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Songan B",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Subaya",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Sukawana",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Serahi",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Siyakin",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Songan A",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Sekardadi",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Selulung",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Sekaan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Pinggan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Satra",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Manikliyu",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Mengani",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Pengejaran",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Langgahan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Lembean",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Mangguh",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Kintamani",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Kutuh",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Kedisan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Gunungbau",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Katung",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Daup",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Dausa",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Bunutin",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Catur",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Binyan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Bonyoh",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Buahan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Belanga",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Belantih",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Belancan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Belandingan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Bayungcerik",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Bayunggede",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Batur Selatan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Batur Tengah",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Batur Utara",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Batu Dinding",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Batukaang",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Bantang",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Banua",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Abuan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Awan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Kintamani",
                "kelurahan": "Abang Songan",
                "kodepos": "80652"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Pengotan",
                "kodepos": "80614"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Taman Bali",
                "kodepos": "80614"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Bebalang",
                "kodepos": "80614"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Bunutin",
                "kodepos": "80614"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Kayubihi",
                "kodepos": "80614"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Kawan",
                "kodepos": "80613"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Landih",
                "kodepos": "80611"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Cempaga",
                "kodepos": "80612"
            },
            {
                "kecamatan": "Bangli",
                "kelurahan": "Kubu",
                "kodepos": "80611"
            }
        ],
        "k7": [
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Singapadu Kaler",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Singapadu Tengah",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Sukawati",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Ketewel",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Singapadu",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Guwang",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Kemenuh",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Celuk",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Batubulan",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Batubulan Kangin",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Batuan",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Sukawati",
                "kelurahan": "Batuan Kaler",
                "kodepos": "80582"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Pering",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Saba",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Keramas",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Medahan",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Bona",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Buruan",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Belega",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Melinggih Kelod",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Blahbatuh",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Puhu",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Belah Batuh (Blahbatuh)",
                "kelurahan": "Bedulu",
                "kodepos": "80581"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Kelusa",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Kerta",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Melinggih",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Buahan Kaja",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Bukian",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Bresela (Beresela)",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Payangan",
                "kelurahan": "Buahan",
                "kodepos": "80572"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Singakerta (Singekerta)",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Ubud",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Petulu",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Sayan",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Peliatan",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Mas",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Kedewatan",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Ubud",
                "kelurahan": "Lodtunduh",
                "kodepos": "80571"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Taro",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Tegallalang",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Kenderan",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Pupuan",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Sebatu",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Kedisan",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tegallalang",
                "kelurahan": "Keliki",
                "kodepos": "80561"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Sanding",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Tampaksiring",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Pejeng Klod (Kelod)",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Pejeng Kangin",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Pejeng Kawan",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Pejeng",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Pejeng Kaja",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Tampak Siring",
                "kelurahan": "Manukaya",
                "kodepos": "80552"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Tegal Tugu",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Temesi",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Tulikup",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Sumita",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Suwat",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Siangan",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Sidan",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Petak Kaja",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Serongga",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Lebih",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Petak",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Bakbakan",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Bitera",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Beng",
                "kodepos": "80513"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Abianbase",
                "kodepos": "80515"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Gianyar",
                "kodepos": "80511"
            },
            {
                "kecamatan": "Gianyar",
                "kelurahan": "Samplangan",
                "kodepos": "80512"
            }
        ],
        "k8": [
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Ungasan",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta",
                "kelurahan": "Tuban",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Tibubeneng",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Tanjung Benoa",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta",
                "kelurahan": "Legian",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta",
                "kelurahan": "Seminyak",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Pecatu",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta",
                "kelurahan": "Kuta",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Kutuh",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Kerobokan Kelod",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Kerobokan Kaja",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Kerobokan Kaja",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta",
                "kelurahan": "Kedonganan",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Jimbaran",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Canggu",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Kuta Utara",
                "kelurahan": "Dalung",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Sulangai",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Kuta Selatan",
                "kelurahan": "Benoa",
                "kodepos": "80361"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Petang",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Pelaga",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Carangsari",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Getasan",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Pangsan",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Petang",
                "kelurahan": "Belok",
                "kodepos": "80353"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Sibang Kaja",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Taman",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Selat",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Sibang Gede",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Mambal",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Punggul",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Mekar Bhuwana",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Sangeh",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Dauh Yeh Cani",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Sedang",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Jagapati",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Bongkasa",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Bongkasa Pertiwi",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Darmasaba",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Ayunan",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Blahkiuh",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Abiansemal",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Abiansemal",
                "kelurahan": "Angantaka",
                "kodepos": "80352"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Tumbak Bayuh",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Werdi Bhuwana",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Sembung",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Sempidi",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Sobangan",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Penarungan",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Pererenan",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Sading",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Mengwitani",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Munggu",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Kuwum",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Lukluk",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Mengwi",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Kapal",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Kekeran",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Gulingan",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Baha",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Buduk",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Cemagi",
                "kodepos": "80351"
            },
            {
                "kecamatan": "Mengwi",
                "kelurahan": "Abianbase",
                "kodepos": "80351"
            }
        ],
        "k9": [
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Tonja",
                "kodepos": "80239"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Sumerta Kelod/Klod",
                "kodepos": "80239"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Penatih",
                "kodepos": "80238"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Penatih Dangin Puri",
                "kodepos": "80238"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Kesiman Kertalangu",
                "kodepos": "80237"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Kesiman Petilan",
                "kodepos": "80237"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Sumerta Kauh",
                "kodepos": "80236"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Kesiman",
                "kodepos": "80237"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Dangin Puri Klod",
                "kodepos": "80234"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Sumerta",
                "kodepos": "80235"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Sumerta Kaja",
                "kodepos": "80236"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Dangin Puri Kangin",
                "kodepos": "80233"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Dangin Puri Kauh",
                "kodepos": "80231"
            },
            {
                "kecamatan": "Denpasar Timur",
                "kelurahan": "Dangin Puri",
                "kodepos": "80232"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Dangin Puri Kaja",
                "kodepos": "80231"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Serangan",
                "kodepos": "80229"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Sanur Kauh",
                "kodepos": "80227"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Sanur",
                "kodepos": "80228"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Renon",
                "kodepos": "80226"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Sanur Kaja",
                "kodepos": "80227"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Panjer",
                "kodepos": "80225"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Sesetan",
                "kodepos": "80223"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Sidakarya",
                "kodepos": "80224"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Pemogan",
                "kodepos": "80221"
            },
            {
                "kecamatan": "Denpasar Selatan",
                "kelurahan": "Pedungan",
                "kodepos": "80222"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Pemecutan Klod/Kelod",
                "kodepos": "80119"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Tegal Harum",
                "kodepos": "80119"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Tegal Kertha",
                "kodepos": "80119"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Pemecutan",
                "kodepos": "80119"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Pemecutan Kaja",
                "kodepos": "80118"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Padangsambian Kaja",
                "kodepos": "80117"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Padangsambian Klod/Kelod",
                "kodepos": "80117"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Padangsambian",
                "kodepos": "80117"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Ubung Kaja",
                "kodepos": "80116"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Peguyangan Kangin",
                "kodepos": "80115"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Ubung",
                "kodepos": "80116"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Peguyangan",
                "kodepos": "80115"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Peguyangan Kaja",
                "kodepos": "80115"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Dauh Puri Kauh",
                "kodepos": "80113"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Dauh Puri Klod/Kelod",
                "kodepos": "80114"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Dauh Puri Kangin",
                "kodepos": "80112"
            },
            {
                "kecamatan": "Denpasar Barat",
                "kelurahan": "Dauh Puri",
                "kodepos": "80113"
            },
            {
                "kecamatan": "Denpasar Utara",
                "kelurahan": "Dauh Puri Kaja",
                "kodepos": "80111"
            }
        ],
        "k10": [
            {
                "kecamatan": "Lepar Pongok",
                "kelurahan": "Tanjung Sangkar",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Lepar Pongok",
                "kelurahan": "Tanjung Labu",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Kepulauan Pongok",
                "kelurahan": "Pongok",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Lepar Pongok",
                "kelurahan": "Penutuk",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Lepar Pongok",
                "kelurahan": "Kumbung",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Kepulauan Pongok",
                "kelurahan": "Celagen",
                "kodepos": "33791"
            },
            {
                "kecamatan": "Tukak Sadai",
                "kelurahan": "Tukak",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Toboali",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Tukak Sadai",
                "kelurahan": "Tiram",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Teladan",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Tanjung Ketapang",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Serdang",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Tukak Sadai",
                "kelurahan": "Sadai",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Rindik",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Rias",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Tukak Sadai",
                "kelurahan": "Pasir Putih",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Kepoh",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Kaposang/Keposang",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Jeriji",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Gadung",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Tukak Sadai",
                "kelurahan": "Bukit Terap",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Toboali",
                "kelurahan": "Bikang",
                "kodepos": "33783"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Tepus",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Sidoharjo",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Ranggas",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Pergam",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Nangka",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Nyelanding",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Delas",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Bencah",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Air Gegas",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Air Gegas",
                "kelurahan": "Air Bara",
                "kodepos": "33782"
            },
            {
                "kecamatan": "Pulau Besar",
                "kelurahan": "Sumber Jaya Permai (Trans 4)",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Pulau Besar",
                "kelurahan": "Sukajaya",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Sengir",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Ranggung",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Payung",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Pangkalbuluh",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Pulau Besar",
                "kelurahan": "Panca Tunggal (Trans 3)",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Paku",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Nadung",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Malik",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Irat",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Pulau Besar",
                "kelurahan": "Fajar Indah (Trans 1)",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Bedengung",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Pulau Besar",
                "kelurahan": "Batu Betumpang",
                "kodepos": "33778"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Simpang Rimba",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Sebagin",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Rajik",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Permis",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Jelutung II",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Gudang",
                "kodepos": "33777"
            },
            {
                "kecamatan": "Simpang Rimba",
                "kelurahan": "Bangka Kota",
                "kodepos": "33777"
            }
        ],
        "k11": [
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Padang Baru",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Pelindang (Selindung)",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Namang",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Mangkol",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Kayu Besi",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Kebintik",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Jeruk",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Jelutung",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Dul",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Cambai Selatan",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Cambai",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Benteng",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Belilik",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Beluluk",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Airmesu",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Batu Belubang",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Pangkalan Baru",
                "kelurahan": "Air Mesu Timur",
                "kodepos": "33684"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Trubus",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Terentang III",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Simpang Perlang",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Perlang",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Penyak",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Nibung",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Padang Mulya",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Lubuk Pabrik",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Lubuk Besar",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Lubuk Lingkuk",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Kurau Barat",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Kurau (Timur)",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Kulur Hilir",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Koba",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Kulur",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Guntung",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Bukit Kijang",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Berok",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Belimbing",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Lubuk Besar",
                "kelurahan": "Batu Beriga",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Koba",
                "kelurahan": "Arung Dalam",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Namang",
                "kelurahan": "Baskara Bakti/Bhakti",
                "kodepos": "33681"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Tanjung Pura",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Sungai Selan Atas",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Sarangmandi",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Sungai Selan",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Ramadhon (Romadhoni)",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Melabun",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Munggu",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Lampur",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Keretak Atas",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Kerantai",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Keretak",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Kerakas",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Sungai Selan",
                "kelurahan": "Kemingking",
                "kodepos": "33675"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Terak",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Teru",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Simpang Katis",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Sungkap",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Pinang Sebatang",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Puput",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Pasir Garam",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Celuak",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Katis",
                "kodepos": "33674"
            },
            {
                "kecamatan": "Simpang Katis",
                "kelurahan": "Beruas",
                "kodepos": "33674"
            }
        ],
        "k12": [
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Senyubuk",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Mentawak",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Pembaharuan",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Mayang",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Cendil",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Damar",
                "kelurahan": "Burung Mandi",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Kelapa Kampit",
                "kelurahan": "Buding",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Simpang Renggiang",
                "kelurahan": "Simpang Tiga",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Damar",
                "kelurahan": "Air Kelik",
                "kodepos": "33571"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Selingsing",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Simpang Renggiang",
                "kelurahan": "Renggiang",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Simpang Renggiang",
                "kelurahan": "Lintang",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Limbongan",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Lenggang",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Lilangan",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Jangkar Asam",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Gantung",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Gantung",
                "kelurahan": "Batu Penyu",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Simpang Renggiang",
                "kelurahan": "Aik Madu",
                "kodepos": "33562"
            },
            {
                "kecamatan": "Simpang Pesak",
                "kelurahan": "Tanjung Kelumpang",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Simpang Pesak",
                "kelurahan": "Tanjung Batu Itam",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Nyuruk",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Simpang Pesak",
                "kelurahan": "Simpang Pesak",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Jangkang",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Simpang Pesak",
                "kelurahan": "Dukong",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Balok",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Dendang",
                "kodepos": "33561"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Kelubi",
                "kodepos": "33517"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Mekar Jaya",
                "kodepos": "33517"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Bentaian Jaya",
                "kodepos": "33517"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Padang",
                "kodepos": "33516"
            },
            {
                "kecamatan": "Damar",
                "kelurahan": "Mengkubang",
                "kodepos": "33514"
            },
            {
                "kecamatan": "Damar",
                "kelurahan": "Mempaya",
                "kodepos": "33515"
            },
            {
                "kecamatan": "Damar",
                "kelurahan": "Sukamandi",
                "kodepos": "33513"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Kurnia Jaya",
                "kodepos": "33512"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Pulau Buku Limau",
                "kodepos": "33511"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Baru",
                "kodepos": "33512"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Lalang Jaya",
                "kodepos": "33511"
            },
            {
                "kecamatan": "Manggar",
                "kelurahan": "Lalang",
                "kodepos": "33511"
            }
        ],
        "k13": [
            {
                "kecamatan": "Selat Nasik",
                "kelurahan": "Suak Gual",
                "kodepos": "33481"
            },
            {
                "kecamatan": "Selat Nasik",
                "kelurahan": "Petaling",
                "kodepos": "33481"
            },
            {
                "kecamatan": "Selat Nasik",
                "kelurahan": "Pulau Gresik",
                "kodepos": "33481"
            },
            {
                "kecamatan": "Selat Nasik",
                "kelurahan": "Selat Nasik",
                "kodepos": "33481"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Simpang Rusa",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Tanjung Rusa",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Pulau Seliu",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Pulau Sumedang",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Padang Kandis",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Perpat",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Mentigi",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Lassar",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Membalong",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Gunung Riting",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Kembiri",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Sungai Samak",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Membalong",
                "kelurahan": "Bantan",
                "kodepos": "33452"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Kacang Botor",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Pegantungan",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Cerucuk",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Ibul",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Badau",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Air Batu",
                "kodepos": "33451"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Juru Seberang",
                "kodepos": "33417"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Terong",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Air Saga",
                "kodepos": "33415"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Tanjung Binga",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Tanjong Tinggi",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Sungai Padang",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Sijuk",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Paal Satu",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Pelepak Pute",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Keciput",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Batu Itam",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Air Seru",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Sijuk",
                "kelurahan": "Air Selumar",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Aik Palempang Jaya",
                "kodepos": "33414"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Perawas",
                "kodepos": "33413"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Aik Rayak",
                "kodepos": "33413"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Pangkal Lalang",
                "kodepos": "33412"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Tanjung Pendam",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Parit",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Lesung Batang",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Kota Tanjung Pandan",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Dukong",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Buluh Tumbang",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Air Merbau",
                "kodepos": "33411"
            },
            {
                "kecamatan": "Tanjung Pandan",
                "kelurahan": "Aik Ketekok",
                "kodepos": "33411"
            }
        ],
        "k14": [
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Simpang Tiga",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Simpang Gung",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Rambat",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Peradong",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Pangek",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Pelangas",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Mayang",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Kundi",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Ibul",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Bukit Terak",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Berang",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Air Nyatoh",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Simpang Teritip",
                "kelurahan": "Air Menduyung",
                "kodepos": "33366"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Tempilang",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Tanjungniur",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Sinar Surya",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Simpang Yul",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Sangku",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Penyampak",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Buyan Kelumbi",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Benteng Kota/Kuta",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Tempilang",
                "kelurahan": "Air Lintang",
                "kodepos": "33365"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Tuik",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Terentang",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Tugang",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Tebing",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Sinar Sari",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Pangkal Beras",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Pusuk",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Mancung",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Kayuarang",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Kelapa",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Beruas",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Dendang",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Kacung",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Tumbak Petar",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Kelapa",
                "kelurahan": "Air Bulin",
                "kodepos": "33364"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Telak",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Teluk Limau",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Sinar Manik",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Sungai Buluh",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Sekar Biru",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Semulut",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Ranggi Asam",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Rukam",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Pebuar",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Puput",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Mislak",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Kelabat",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Ketap",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Limbung",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Kapit",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Cupat",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Jebus",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Bakit",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Jebus",
                "kelurahan": "Air Kuang",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Parittiga",
                "kelurahan": "Air Gantang",
                "kodepos": "33362"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Air Limau",
                "kodepos": "33351"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Air Putih",
                "kodepos": "33351"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Belolaut",
                "kodepos": "33351"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Sungai Daeng",
                "kodepos": "33313"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Air Belo",
                "kodepos": "33351"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Sungai Baru",
                "kodepos": "33312"
            },
            {
                "kecamatan": "Mentok (Muntok)",
                "kelurahan": "Tanjung",
                "kodepos": "33311"
            }
        ],
        "k15": [
            {
                "kecamatan": "Pemali",
                "kelurahan": "Air Ruai",
                "kodepos": "33255"
            },
            {
                "kecamatan": "Pemali",
                "kelurahan": "Karya Makmur",
                "kodepos": "33255"
            },
            {
                "kecamatan": "Pemali",
                "kelurahan": "Pemali",
                "kodepos": "33255"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Silip",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Pemali",
                "kelurahan": "Air Duren",
                "kodepos": "33255"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Riau",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Riding Panjang",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Pugul",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Mapur",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Pangkal Niur",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Gunung Pelawan",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Kuto Panji",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Lumut",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Gunung Muda",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Deniang",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Bukit Ketok",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Cit",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Bintet",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Berbura",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Belinyu",
                "kelurahan": "Air Jukung",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Riau Silip",
                "kelurahan": "Banyu Asin",
                "kodepos": "33253"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Neknang",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Tiang Tara",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Mangka",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Maras Senang",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Mabat",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Bukit Layang",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Dalil",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Kapuk",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Bakam",
                "kelurahan": "Bakam",
                "kodepos": "33252"
            },
            {
                "kecamatan": "Pemali",
                "kelurahan": "Penyamun",
                "kodepos": "33251"
            },
            {
                "kecamatan": "Pemali",
                "kelurahan": "Sempan",
                "kodepos": "33251"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Parit Padang",
                "kodepos": "33215"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Sinar Baru",
                "kodepos": "33212"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Kudai (Kuday)",
                "kodepos": "33213"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Srimenanti",
                "kodepos": "33214"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Kenanga",
                "kodepos": "33211"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Rebo",
                "kodepos": "33211"
            },
            {
                "kecamatan": "Sungai Liat",
                "kelurahan": "Sungailiat",
                "kodepos": "33211"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Saing",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Tanah Bawah",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Nibung",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Pudingbesar",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Labu",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Kayu Besi",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Puding Besar",
                "kelurahan": "Kota Waringin",
                "kodepos": "33179"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Petaling Banjar",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Rukam",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Zed",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Penagan",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Petaling",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Mendo (Menduk)",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Payabenua",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Kota Kapur",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Labuh Air Pandan",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Kace Timur",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Kemuja",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Cengkong Abang",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Kace",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Air Buluh",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Mendo Barat",
                "kelurahan": "Air Duren",
                "kodepos": "33173"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Pagarawan",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Riding Panjang",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Kimak",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Merawang",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Jada Bahrin",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Jurung",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Baturusa",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Dwi Makmur",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Air Anyir",
                "kodepos": "33172"
            },
            {
                "kecamatan": "Merawang",
                "kelurahan": "Balun Ijuk",
                "kodepos": "33172"
            }
        ],
        "k16": [
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Air Itam",
                "kodepos": "33149"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Temberan",
                "kodepos": "33147"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Bacang",
                "kodepos": "33148"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Sinar Bulan",
                "kodepos": "33147"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Pasir Putih",
                "kodepos": "33147"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Semabung Lama",
                "kodepos": "33147"
            },
            {
                "kecamatan": "Bukit Intan",
                "kelurahan": "Air Mawar",
                "kodepos": "33147"
            },
            {
                "kecamatan": "Girimaya",
                "kelurahan": "Bukit Besar",
                "kodepos": "33145"
            },
            {
                "kecamatan": "Girimaya",
                "kelurahan": "Semabung Baru",
                "kodepos": "33146"
            },
            {
                "kecamatan": "Girimaya",
                "kelurahan": "Pasar Padi",
                "kodepos": "33142"
            },
            {
                "kecamatan": "Girimaya",
                "kelurahan": "Sriwijaya",
                "kodepos": "33143"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Pasir Putih",
                "kodepos": "33139"
            },
            {
                "kecamatan": "Girimaya",
                "kelurahan": "Batu Intan",
                "kodepos": "33141"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Bintang",
                "kodepos": "33138"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Melintang",
                "kodepos": "33136"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Parit Lalang",
                "kodepos": "33137"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Keramat",
                "kodepos": "33134"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Asam",
                "kodepos": "33135"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Tua Tunu indah",
                "kodepos": "33124"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Pintu Air",
                "kodepos": "33133"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Kacang Pedang",
                "kodepos": "33125"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Gedung Nasional",
                "kodepos": "33127"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Bukit Sari",
                "kodepos": "33123"
            },
            {
                "kecamatan": "Rangkui",
                "kelurahan": "Masjid Jamik",
                "kodepos": "33132"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Kejaksaan",
                "kodepos": "33123"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Taman Bunga",
                "kodepos": "33123"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Air Kepala Tujuh",
                "kodepos": "33123"
            },
            {
                "kecamatan": "Gerunggang",
                "kelurahan": "Bukit Merapin (Bukit Merapen)",
                "kodepos": "33123"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Opas Indah",
                "kodepos": "33121"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Rawa Bangun",
                "kodepos": "33121"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Jerambah Gantung",
                "kodepos": "33119"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Batin Tikal",
                "kodepos": "33121"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Selindung Baru",
                "kodepos": "33117"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Gabek Satu",
                "kodepos": "33118"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Gabek Dua",
                "kodepos": "33116"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Selindung (Selindung Lama)",
                "kodepos": "33117"
            },
            {
                "kecamatan": "Pangkal Balam",
                "kelurahan": "Lontong Pancur",
                "kodepos": "33115"
            },
            {
                "kecamatan": "Pangkal Balam",
                "kelurahan": "Pasir Garam",
                "kodepos": "33115"
            },
            {
                "kecamatan": "Pangkal Balam",
                "kelurahan": "Rejosari",
                "kodepos": "33111"
            },
            {
                "kecamatan": "Pangkal Balam",
                "kelurahan": "Ampui",
                "kodepos": "33113"
            },
            {
                "kecamatan": "Gabek",
                "kelurahan": "Air Salemba",
                "kodepos": "33111"
            },
            {
                "kecamatan": "Pangkal Balam",
                "kelurahan": "Ketapang",
                "kodepos": "33111"
            }
        ],
        "k17": [
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Sumuranja",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Salira",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Margasari",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Pulo Ampel",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Pulo Panjang",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Banyuwangi",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Kedung Soka",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Mangunreja",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Pulo Ampel",
                "kelurahan": "Argawana",
                "kodepos": "42455"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Pengarengan",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Ukirsari",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Wanakarta",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Mekar Jaya",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Pakuncen",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Mangkunegara",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Margagiri",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Lambangsari",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Kertasana",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Bojonegara",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Bojonegara",
                "kelurahan": "Karangkepuh",
                "kodepos": "42454"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Waringinkurung",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Sukadalem",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Telaga Luhur",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Sasahan",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Sukabares",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Sampir",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Melati",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Sambilawang",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Cokopsulanjana",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Kemuning",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Waringin Kurung",
                "kelurahan": "Binangun",
                "kodepos": "42453"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Renged",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Lamaran",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Warakas",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Gembor",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Sukamampir",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Cakung",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Binuang",
                "kodepos": "42196"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Walikukun",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Ragasmesigit",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Teras",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Pamanuk",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Panenjoan",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Carenang",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Mandaya",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Carenang (Cerenang)",
                "kelurahan": "Mekarsari",
                "kodepos": "42195"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Tenjo Ayu",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Sukamanah",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Tanara",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Cibodas",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Siremen",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Wargasara",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Pedaleman",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Bendung",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Lempuyang",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Tengkurak",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tanara",
                "kelurahan": "Cerukcuk",
                "kodepos": "42194"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Tirtayasa",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Samparwadi",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Sujung",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Susukan",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Puser",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Lontar",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Pontang Legon",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Laban",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Kebuyutan",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Kemanisan",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Kebon",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Wanayasa",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Tirtayasa",
                "kelurahan": "Alang Alang",
                "kodepos": "42193"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Sukajaya",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Singarajan",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Sukanegara",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Linduk",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Pulo Kencana",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Pontang",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Keserangan",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Kubang Puji",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Domas",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Pontang",
                "kelurahan": "Kalapian",
                "kodepos": "42192"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Sawah Luhur",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Terumbu",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Warung Jaud",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Kilasah",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Margaluyu",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Mesjid Priyayi",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Kasunyatan",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Banten",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Bendung",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Kasemen",
                "kelurahan": "Kasemen",
                "kodepos": "42191"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Songgom Jaya",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Sukatani",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Parigi",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Situterate",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Nambo Udik",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Kamurang",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Koper",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Leuwilimus",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Cikande Permai",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Gembor Udik",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Julang",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Bakung",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Cikande",
                "kelurahan": "Cikande",
                "kodepos": "42186"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Tambak",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Sukamaju",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Nambo Ilir",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Kibin",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Nagara",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Cijeruk",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Ketos",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Barengkok",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kibin",
                "kelurahan": "Ciagel",
                "kodepos": "42185"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Tegalmaja",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Undar Andir",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Terasbendung",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Sukajadi",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Sentul",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Silebu",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Pematang",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Kragilan",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Kramatjati",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Kendayakan",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Kamaruton",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Dukuh",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Jeruktipis",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Walantaka",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Kragilan",
                "kelurahan": "Cisait",
                "kodepos": "42184"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Tegalsari",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Teritih",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pengampelan",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pipitan",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pageragung",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pasuluhan",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pageragung",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Nyapah",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Pabuaran",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Lebakwangi",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Lebakwangi",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Lebak Kepuh",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Kiara",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Kencana Harapan",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Kalodoran / Kalodran",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Kepuren",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Walantaka",
                "kelurahan": "Cigoong",
                "kodepos": "42183"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Tirem",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Singamerta",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Pulo",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Ranjeng",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Pelawad",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Kaserangan",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Penggalang",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Kebonratu",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Kepandean",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Citerep",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Pamong",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Gosara",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Kaserangan",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Cigelam",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Ciruas",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Bumijaya",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Ciruas",
                "kelurahan": "Beberan",
                "kodepos": "42182"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Bolang",
                "kodepos": "42181"
            },
            {
                "kecamatan": "Lebak Wangi",
                "kelurahan": "Purwadadi",
                "kodepos": "42181"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Nanggung",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Nyompok",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Rancasumur",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Kopo",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Mekarbaru",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Babakan Jaya",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Gabus",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Pagintungan",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Garut",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Parakan",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Carenang Udik",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Kareo",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Kopo",
                "kelurahan": "Cidahu",
                "kodepos": "42178"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Majasari",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Pasirbuyut",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Jawilan",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Junti",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Cemplang",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Jawilan",
                "kelurahan": "Bojot",
                "kodepos": "42177"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Wirana",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Sangiang",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Pudar",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Pringwulung",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Pasirlimus",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Pangawinan",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Pasir Kembang",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Mander",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Panamping",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Pamarayan",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Malabar",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Malabar",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Damping",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Kampung Baru",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Pamarayan",
                "kelurahan": "Binong",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Blokang",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Bandung",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Sukaratu",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Babakan",
                "kodepos": "42176"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Sukaraja",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Sukarame",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Sukamaju",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Sukamenak",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Mongpok",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Panosogan",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Panyabrangan",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Katulisan",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Harundang",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Dahu",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Gandayasa",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Cilayang Guha",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Cimaung",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Cikeusal",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Cilayang",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Cikeusal",
                "kelurahan": "Bantar Panjang",
                "kodepos": "42175"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Sukasari",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Tunjung Teja",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Panunggulan",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Malanggah",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Pancarecang",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Kamuning",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Bojong Catang",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Bojong Menteng",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Tunjung Teja",
                "kelurahan": "Bojong Pandan",
                "kodepos": "42174"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Tamansari",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Tejamari",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sukamenak",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sukacai",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sukamanah",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sindangmandi",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Suka Indah",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sidamukti",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sinarmukti",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Panyirapan",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Curug Agung",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Padasuka",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Cisalam",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Baros",
                "kodepos": "42173"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Seuat Jaya",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Sindangsari",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Tambiluk",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Padasuka",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Petir",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Sanding",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Seuat",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Mekarbaru",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Nagara Padang",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Kampung Baru",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Kubang Jaya",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Kadugenep",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Cirangkong",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Kadugenep",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Sukawana",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Tinggar",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Petir",
                "kelurahan": "Bojong Nangka",
                "kodepos": "42172"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Pancalaksana",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Sukajaya",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Sukalaksana",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Curug",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Curugmanis",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Kamanisan",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Cilaku",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Cipete",
                "kodepos": "42171"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Kramatlaban",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Padarincang",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Kadu Kempong",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Kadubeureum",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Kalumpang",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Citasuk",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Curug Goong",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Cisaat",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Cisaat",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Bugel",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Cibojong",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Umbul Tanjung",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Barugbug",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Batukuwung",
                "kodepos": "42168"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Rancasanggal",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Sindanglaya",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Mekarsari",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Pasauran",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Karang Suraga",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Kubang Baros",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Cikolelet",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Cinangka",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Kamasan",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Baros Jaya",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Bulakan",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Bantarwangi",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Cinangka",
                "kelurahan": "Bantarwaru",
                "kodepos": "42167"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Sindangmandi",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Tambang Ayam",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Tanjungmanis",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Mekarsari",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Sindangkarya",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Grogol Indah",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Kosambi Ronyok",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Bunihara",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Cikoneng",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Bandulu",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Banjarsari",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Winong",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Anyar",
                "kelurahan": "Anyar",
                "kodepos": "42166"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Sigedong",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Talaga",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Waringin",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Pasirwaru",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Sangiang",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Labuhan",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Mancak",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Batukuda",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Cikedung",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Ciwarna",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Bale Kencana",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Balekambang",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Mancak",
                "kelurahan": "Angsana",
                "kodepos": "42165"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Ujungtebu",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Sukadana",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Sukarena",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Siketug",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Sukabares",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Panyaungan Jaya",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Pondok Kahuru",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Citaman",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Lebak",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Padarincang",
                "kelurahan": "Ciomas",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Cisitu",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Tanjungsari",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Cemplang",
                "kodepos": "42164"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Talaga Warna",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Sukalaba",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Tamiang",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Sindangheula",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Sindangsari",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pancanegara",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pasanggrahan",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Kadubeureum",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Luwuk",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Gunungsari",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Kadu Agung",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Curug Sulanjana",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Curug Sulanjana",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Umbul Tengah",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Kuranji",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Ciherang",
                "kodepos": "42163"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Lialang",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Taktakan",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Drangong",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Tamanbaru",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Kalang Anyar",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Sepang",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Wanayasa",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Pancur",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Cilowong",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Panggungjati",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Terate",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Taktakan",
                "kelurahan": "Sayar",
                "kodepos": "42162"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Tonjong",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Toyomerto",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Serdang",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Teluk Terate",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Pejaten",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Pelamunan",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Pamengkang",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Pegadingan",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Lebakwana",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Margasana",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Gelam",
                "kodepos": "42128"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Kramatwatu",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Karundang",
                "kodepos": "42125"
            },
            {
                "kecamatan": "Kramatwatu",
                "kelurahan": "Harjatani",
                "kodepos": "42161"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Tembong",
                "kodepos": "42126"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Dalung",
                "kodepos": "42127"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Banjarsari",
                "kodepos": "42123"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Panancangan",
                "kodepos": "42124"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Cipocok Jaya",
                "kodepos": "42121"
            },
            {
                "kecamatan": "Cipocok Jaya",
                "kelurahan": "Banjaragung",
                "kodepos": "42122"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Sumurpecung",
                "kodepos": "42118"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Terondol",
                "kodepos": "42119"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Sukawana",
                "kodepos": "42116"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Cipare",
                "kodepos": "42117"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Serang",
                "kodepos": "42116"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Kaligandu",
                "kodepos": "42116"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Lontarbaru",
                "kodepos": "42115"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Lopang",
                "kodepos": "42113"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Kagungan",
                "kodepos": "42114"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Kotabaru",
                "kodepos": "42112"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Cimuncang",
                "kodepos": "42111"
            },
            {
                "kecamatan": "Serang",
                "kelurahan": "Unyur",
                "kodepos": "42111"
            }
        ],
        "k18": [
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Gunungsugih",
                "kodepos": "42447"
            },
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Kepuh",
                "kodepos": "42446"
            },
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Randakari",
                "kodepos": "42446"
            },
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Kubangsari",
                "kodepos": "42445"
            },
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Tegalratu",
                "kodepos": "42445"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Samangraya",
                "kodepos": "42443"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Warnasari",
                "kodepos": "42443"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Deringo",
                "kodepos": "42444"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Lebakdenok",
                "kodepos": "42442"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Tamanbaru",
                "kodepos": "42441"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Kebonsari",
                "kodepos": "42442"
            },
            {
                "kecamatan": "Ciwandan",
                "kelurahan": "Banjar Negara",
                "kodepos": "42441"
            },
            {
                "kecamatan": "Citangkil",
                "kelurahan": "Citangkil",
                "kodepos": "42441"
            },
            {
                "kecamatan": "Pulomerak",
                "kelurahan": "Suralaya",
                "kodepos": "42439"
            },
            {
                "kecamatan": "Gerogol",
                "kelurahan": "Gerem",
                "kodepos": "42438"
            },
            {
                "kecamatan": "Pulomerak",
                "kelurahan": "Mekarsari",
                "kodepos": "42438"
            },
            {
                "kecamatan": "Pulomerak",
                "kelurahan": "Tamansari",
                "kodepos": "42438"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Purwakarta",
                "kodepos": "42437"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Tegal Bunder",
                "kodepos": "42437"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Pabean",
                "kodepos": "42437"
            },
            {
                "kecamatan": "Gerogol",
                "kelurahan": "Rawa Arum",
                "kodepos": "42436"
            },
            {
                "kecamatan": "Gerogol",
                "kelurahan": "Gerogol/Grogol",
                "kodepos": "42436"
            },
            {
                "kecamatan": "Gerogol",
                "kelurahan": "Kotasari",
                "kodepos": "42436"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Kotabumi",
                "kodepos": "42434"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Ramanuju",
                "kodepos": "42431"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Kebondalem",
                "kodepos": "42433"
            },
            {
                "kecamatan": "Pulomerak",
                "kelurahan": "Lebak Gede",
                "kodepos": "42431"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cikerai",
                "kodepos": "42427"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Karangasem",
                "kodepos": "42425"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Bulakan",
                "kodepos": "42426"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cibeber",
                "kodepos": "42423"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Kalitimbang",
                "kodepos": "42424"
            },
            {
                "kecamatan": "Cilegon",
                "kelurahan": "Bagendung",
                "kodepos": "42419"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Kedaleman",
                "kodepos": "42422"
            },
            {
                "kecamatan": "Cilegon",
                "kelurahan": "Bendungan",
                "kodepos": "42417"
            },
            {
                "kecamatan": "Cilegon",
                "kelurahan": "Ciwedus",
                "kodepos": "42418"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Sukmajaya",
                "kodepos": "42416"
            },
            {
                "kecamatan": "Cilegon",
                "kelurahan": "Ketileng",
                "kodepos": "42416"
            },
            {
                "kecamatan": "Cilegon",
                "kelurahan": "Ciwaduk",
                "kodepos": "42415"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Gedong Dalem",
                "kodepos": "42413"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Masigit",
                "kodepos": "42414"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jombang Wetan",
                "kodepos": "42411"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Panggung Rawi",
                "kodepos": "42412"
            }
        ],
        "k19": [
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Sukatani",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Wanasalam",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Parungpanjang",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Parungsari",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Muara",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Karangpamindangan",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Ketapang",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Cipeucang",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Cisarap",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Cilangkap",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Cipedang",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Bejod",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Wanasalam",
                "kelurahan": "Cikeusik",
                "kodepos": "42396"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Sukasenang",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Wangunjaya",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Mugijaya",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Peucangpari",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Mekarjaya",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Kapunduhan",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cimega",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cipalabuh",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Kandangsapi",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Cikaret",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Cikate",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Cikadongdong",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cikaratuan",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cihujan",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cijaku",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Cibungur",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cigemblong",
                "kelurahan": "Cigemblong",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Cibeureum",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cijaku",
                "kelurahan": "Ciapus",
                "kodepos": "42395"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Warnasari",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Warungbanten",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Sinargalih",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Situmulya",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Sukamulya",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Mekarsari",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Neglasari",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Kujangjaya",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Kujangsari",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Citorek Timur (Ciparay)",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Gunungwangun",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Hegarmanah",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Citorek Kidul (Ciusul)",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Citorek Sabrang (Girimukti)",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Citorek Tengah",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cisungsang",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Citorek Barat",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cikadu",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cikotok",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cihambali",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Ciherang",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cibeber",
                "kodepos": "42394"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Suwakan",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Sawarna",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Sawarna Timur",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Pasirbungur",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Pamubulan",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Pasirgombong",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Gunungbatu",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Lebaktipar",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Darmasari",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Girimukti",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Cisuren",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cireundeu",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cilograng",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Cimancak",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cikamunding",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cikatomas",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Cidikit",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cijengkol",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Cilograng",
                "kelurahan": "Cibareno",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Bayah Barat",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Bayah",
                "kelurahan": "Bayah Timur",
                "kodepos": "42393"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Sogong",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Sukajadi",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Situragen",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Pondokpanjang",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Sindangratu",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Panyaungan",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Panggarangan",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Mekarsari",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Mekarjaya",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Karangkamulyan",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Lebak Peundeuy",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Hegarmanah",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Jatake",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Citepuseun",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Gunung Gede",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Ciparahu",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Cimandiri",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Cihara",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Panggarangan",
                "kelurahan": "Cibarengkok",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Cihara",
                "kelurahan": "Barunai",
                "kodepos": "42392"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Sumber Waras",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Senanghati",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Sukamanah",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Sukaraja",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Rahong",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Sanghiang",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Malingping Utara",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Pagelaran",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Kersaratu",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Malingping Selatan",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Kadujajar",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Cilangkahan",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Kadujajar",
                "kodepos": "42391"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Sindangmulya",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Tanjung Sari",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Pasir Kembang",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Sangiang",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Sekarwangi",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Pasir Kecapi",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Padasuka",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Maja Baru",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Mayak",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Mekarsari",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Maja",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Lebakkasih",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Curugbitung",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Gubugcibeureum",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Guradog",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Cipining",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Curug Badak",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Cidadap",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Cilayang",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cilangkap",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Ciburuy",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Curugbitung",
                "kelurahan": "Candi",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Buyut",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Talagahiang/Talagahiyang",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Binong",
                "kodepos": "42381"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Sipayung",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Sukasari",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Luhurjaya",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Malangsari",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Pasirhaur",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Lebaksangka",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Lebaksitu",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Jayapura",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Lebakgedong",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Haurgajrug",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Girilaya",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Harumsari",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Cipanas",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Giriharja",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Ciladaeun",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Bintangsari",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Banjar Irigasi",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Bintangresmi",
                "kodepos": "42372"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sukamarga",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sukarame",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sindangsari",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sukajaya",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sajira",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Sajiramekar",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Paja",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Pajagan",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Parungsari",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Margaluyu",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Mekarsari",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Calungbungur",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Ciuyah",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Maraya",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Tanjungwangi",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Sajira",
                "kelurahan": "Bangunmekar",
                "kodepos": "42371"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Sindangwangi",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Sukanagara",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Muncang",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Pasirnangka",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Mekarwangi",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Karangcombong",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Leuwicoo",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Girijagabaya",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Jagaraksa",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Cikarang",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Muncang",
                "kelurahan": "Ciminyak",
                "kodepos": "42364"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Pasirbitung",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Parakanlima",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Mekarmanik",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Parakanbeusi",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Nangerang",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Keboncau",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Mekar Rahayu",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Kadurahayu",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Karoya",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Kadurahayu",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Kadudamas",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Harjawana",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Cimayang",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Cirinten",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Datarcae",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Bojongmanik",
                "kelurahan": "Bojongmanik",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Cempaka",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Cibarani",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Cirinten",
                "kelurahan": "Badur",
                "kodepos": "42363"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Nayagati",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Sangkanwangi",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Wantisari",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Leuwidamar",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Margawangi",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Jalupang Mulya",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Kanekes",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Lebak Parahiang",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Cibungur",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Cisimeut",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Cisimeut Raya",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Leuwidamar",
                "kelurahan": "Bojong Menteng",
                "kodepos": "42362"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Tambak",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Sangkan Manik",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Sarageni",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Sudamanik",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Mekar Jaya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Mekarmulya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Sangiang Jaya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Margaluyu",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Margatirta",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Karya Jaya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Marga Jaya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Jayamanik",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Jayasari",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Girimukti",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Gunung Anten",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Inten Jaya",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cimarga",
                "kelurahan": "Cimarga",
                "kodepos": "42361"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Tambakbaya",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Pasar Keong",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Mekar Agung",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Panancangan",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Cisangu",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Malabar",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Cibadak",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Cimenteng Jaya",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Bojongcae",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Bojongleles",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Asem Margaluyu",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Asem",
                "kodepos": "42357"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Taman Jaya",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Sukaharja",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Sumurbandung",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Pasirgintung",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Sukadaya",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Parage",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Muncangkopong",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Muaradua",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Curugpanjang",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Cikulur",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Cigoong Utara",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Cigoong Selatan",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Cikulur",
                "kelurahan": "Anggalan",
                "kodepos": "42356"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Umbuljaya",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Leuwiipuh",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Tamansari",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Lebakkeusik",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Labanjaya",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kumpay",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Keusik",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kertarahayu",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kertaraharja",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kerta",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kaduhauk",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Jalupang Girang",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Gunungsari",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Ciruji",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cisampih",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cilegong Ilir",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cidahu",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cibaturkeusik",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Bojongjuruh",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Bendungan",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Lebakgedong",
                "kelurahan": "Banjarsari",
                "kodepos": "42355"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Tanjungsari Indah",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Sukanegara",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Keramatjaya/Kramatjaya",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Gunungkendeng",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Cisampang",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Gunung Kencana",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Cimanyangray",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Ciginggang",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Cicaringin",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Ciakar",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Bojongkoneng",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Gunung Kencana",
                "kelurahan": "Bulakan",
                "kodepos": "42354"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Pasindangan",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Prabugantungan",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Parungkujang",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Margamulya",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Mekarjaya",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Kujangsari",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Gumuruh",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Daroyon",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Cipadang",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Cikareo",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Cileles",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Warunggunung",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Cileles",
                "kelurahan": "Banjarsari",
                "kodepos": "42353"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Sindangsari",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Sukaraja",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Sukarendah",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Padasuka",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Pasirtangkil",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Selaraja",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Cibuah",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Jagabaya",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Cempaka",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Cempaka",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Warunggunung",
                "kelurahan": "Banjarsari",
                "kodepos": "42352"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Narimbang Mulia",
                "kodepos": "42319"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sukamaju",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sukaresmi",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Majasari",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sobang",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sukajaya",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Kaduagung Barat",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Kaduagung Tengah",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Kaduagung Timur",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Citujah",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Hariang",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Cilebang",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Cirompang",
                "kodepos": "42318"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Cijoro Pasir",
                "kodepos": "42316"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Cijoro Lebak",
                "kodepos": "42317"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Jatimulya",
                "kodepos": "42315"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sindanglaya",
                "kodepos": "42315"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Rangkasbitung Timur",
                "kodepos": "42313"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Muara Ciujung Timur",
                "kodepos": "42314"
            },
            {
                "kecamatan": "Malingping",
                "kelurahan": "Cipeundeuy",
                "kodepos": "42313"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Sukamekarsari",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Rangkasbitung Barat",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Sukamanah",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Sangiang Tanjung",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Pasirtanjung",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Pasirkupa",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Nameng",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Pabuaran",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Mekarsari",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Kalanganyar",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Kolelet Wetan",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Cimangeungteung",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Citeras",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Aweh",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Cilangkap",
                "kodepos": "42312"
            },
            {
                "kecamatan": "Kalanganyar",
                "kelurahan": "Cikatapis",
                "kodepos": "42311"
            },
            {
                "kecamatan": "Rangkasbitung",
                "kelurahan": "Muara Ciujung Barat",
                "kodepos": "42311"
            }
        ],
        "k20": [
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Umbulan",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Sumurbatu",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Tanjungan",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Sukaseneng",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Sukawaris",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Sukamulya",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Rancaseneng",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Nanggala",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Parungkokosan",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Cikiruhwetan",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Curugciung",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Leuwibalang",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Cikadongdong",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cikeusik",
                "kelurahan": "Cikeusik",
                "kodepos": "42286"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Sukajadi",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Sorongan",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Sudimanik",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Manglid",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Sindangkerta",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Mendung",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Malangnengah",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Mahendra",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Kiarajangkung",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Kiarapayung",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Kutakarang",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Curug",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Citeluk",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cikiruh",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cikadu",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cikalong",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Cihanjuang",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Cibingbin",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Waringinkurung",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cibaliung",
                "kelurahan": "Cibaliung",
                "kodepos": "42285"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Tangkilsari",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Tugu",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Rancapinang",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Mangkualam",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Padasuka",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cimanggu",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Kramatjaya",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Ciburial",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cijaralang",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Batuhideung",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cibadak",
                "kodepos": "42284"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Tunggaljaya",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Ujungjaya",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Sumberjaya",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Tamanjaya",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Kertajaya",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Kertamukti",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Waringinjaya",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Sumur",
                "kelurahan": "Cigorondong",
                "kodepos": "42283"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Sinarjaya",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Tarumanagara",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Karyabuana",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Katumbiri",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Ciseureuheun",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Karangbolong",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Banyuasih",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Cigeulis",
                "kelurahan": "Cigeulis",
                "kodepos": "42282"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Tanjungjaya",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Teluklada",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Panimbangjaya",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Pangkalan",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Sobang",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Mekarjaya",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Mekarsari",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Kertaraharja",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Kutamekar",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Citeureup",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Panimbang",
                "kelurahan": "Gombong",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Bojen",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Cimanis",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Sobang",
                "kelurahan": "Cimanis",
                "kodepos": "42281"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Padamulya",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Sumurlaban",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Kramatmanik",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Padaherang",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Lebak",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Cipinang",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Kadubadak",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Karangsari",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Angsana",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Cikayas",
                "kodepos": "42277"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Sukasaba",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Pasirtenjo",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Sindangresmi",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Pasirlancar",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Pasirloa",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Pasirdurung",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Pasanggrahan",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Munjul",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Panacaran",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Kadumalati",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Kotadukuh",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Curuglanglang",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Gunungbatu",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Ciodeng",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Munjul",
                "kelurahan": "Cibitung",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Bojongmanik",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Sindangresmi",
                "kelurahan": "Campakawarna",
                "kodepos": "42276"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Pasirpanjang",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Pasirsedang",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Kadubera",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Kolelet",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Bungurcopong",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Cililitan",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Ganggaeng",
                "kodepos": "42275"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Mekarsari",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Citumenggung",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Geredug",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Manggungjaya",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cahayamekar",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cijakan",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Banyumas",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong",
                "kodepos": "42274"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Talagasari",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Wanagiri",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Sodong",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Sindanghayu",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Sukalangu",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Sanghiangdengdek",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Saketi",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Rawasari",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Parigi",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Pasireurih",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Palembang",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Medalsari",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Mekarwangi",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Langensari",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Majau",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Kondangjaya",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Kubangkondang",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Kaduronyok",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Kadudampit",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Girijaya",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Cilentung",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Cisereh",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Cibarani",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cisata",
                "kelurahan": "Ciherangjaya",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Saketi",
                "kelurahan": "Ciandur",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Banjarnegara",
                "kodepos": "42273"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Pasireurih",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Pasirmae",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Parumasan",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Palanyar",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Kalanggunung",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Koncang",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Cikadueun",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Curugbarang",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Kadugadung",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cipeucang",
                "kelurahan": "Baturanjang",
                "kodepos": "42272"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Tegal",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Sukamulya",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Wirasinga",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Rancabugel",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Rocek",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Sekong",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Padahayu",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Pareang",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Mekarjaya",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Mekarjaya",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Medong",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Kupahandap",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Kadumadang",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Karyasari",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Karyautama",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Kadudodol",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Kadujangkung",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Kadubungbang",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Mekarjaya",
                "kelurahan": "Kadubelang",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Gunungcupu",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Gunungdatar",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Dalembalar",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Dahu",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Cimanuk",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Cipicung",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Weru",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Cimanuk",
                "kelurahan": "Batubantar",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Babakanlor",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Bangkuyung",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Tegalpapak",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Cikeudal (Cikedal)",
                "kelurahan": "Cening",
                "kodepos": "42271"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Turus",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Surakarta",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Surianeun",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukaresmi",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sukadame",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sindanglaya",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Simpangtiga",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Seuseupan",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sidamukti",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Senangsari",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Rahayu",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Perdana",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Pasirgadung",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Patia",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Pasirkadu",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Montor",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pagelaran",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Margagiri",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Margasana",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Kartasana",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Karyasari",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Kubangkampil",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Idaman",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Harapankarya",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Cimoyan",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cikuya",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Ciawi",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cibungur",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Patia",
                "kelurahan": "Babakankeusik",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Bama",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Bulagor",
                "kodepos": "42265"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Teluk",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Tembong",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Sukanagara",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Sukarame",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Sukamaju",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Sukajadi",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Rancateureup",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Pejamben",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Sindanglaut",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Kawoyang",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Cigondang",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Kalanganyar",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Cinoyong",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Banyumekar",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Caringin",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Carita",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Carita",
                "kelurahan": "Banjarmasin",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Banyubiru",
                "kodepos": "42264"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Tenjolahang",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Sukacai",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Sukamanah",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Sukaraja",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Sampangbitung",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Sikulan",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Pamarayan",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Salapraya",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Jayamekar",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Jiput",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Banyuresmi",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Citaman",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Janaka",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Tegalwangi",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Jiput",
                "kelurahan": "Babadsari",
                "kodepos": "42263"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Sukamanah",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Sukasari",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Ramaya",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Sindangkarya",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Menes",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Muruy",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Purwaraja",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Koranji",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Kananga",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Karyawangi",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Kadupayung",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Banjarwangi",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Cigandeng",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Cilabanbulan",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Menes",
                "kelurahan": "Alaswangi",
                "kodepos": "42262"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Sinarjaya",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Sirnagalih",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Panjangjaya",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Pari",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Ramea",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Nembol",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Pandat",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Kurungkambing",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Mandalasari",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Mandalawangi",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Giripawana",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Gunungsari",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Cikumbueun",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Curuglemo",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Mandalawangi",
                "kelurahan": "Cikoneng",
                "kodepos": "42261"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Sukasari",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Saninten",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Sukamanah",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Mandalasari",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Palurahan",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Kadugemblo",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Kaduhejo",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Campaka",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Ciputri",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Banjarsari",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Kaduhejo",
                "kelurahan": "Bayumundu",
                "kodepos": "42253"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Pasirawi",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Mogana",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Kadumaneuh",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Kadulimus",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Citalahab",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Gunungputri",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Kadubale",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Cibeureum",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Cibodas",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banjar",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Bandung",
                "kodepos": "42252"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Tegalongok",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Tapos",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Sukajaya",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Tanagara",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Setrajaya",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Pasirpeuteuy",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Pasirkarang",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Pasirjaksa",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Paniis",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Karang Tanjung",
                "kelurahan": "Pagadungan",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Pakuluran",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Kurungdahu",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Koroncong",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Koranji",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Kaungcaang",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Karang Tanjung",
                "kelurahan": "Kadumerak",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Karangsetra",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Kaduengang",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Kaduela",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Gerendong",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Cikentrung",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Karang Tanjung",
                "kelurahan": "Cigadung",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Ciinjuk",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Cadasari",
                "kelurahan": "Cadasari",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Bangkonol",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Koroncong",
                "kelurahan": "Awilega",
                "kodepos": "42251"
            },
            {
                "kecamatan": "Pandeglang",
                "kelurahan": "Babakan Kalang Anyar",
                "kodepos": "42219"
            },
            {
                "kecamatan": "Pandeglang",
                "kelurahan": "Kadomas",
                "kodepos": "42218"
            },
            {
                "kecamatan": "Majasari",
                "kelurahan": "Sukaratu",
                "kodepos": "42217"
            },
            {
                "kecamatan": "Majasari",
                "kelurahan": "Saruni",
                "kodepos": "42216"
            },
            {
                "kecamatan": "Majasari",
                "kelurahan": "Pagerbatu",
                "kodepos": "42215"
            },
            {
                "kecamatan": "Majasari",
                "kelurahan": "Cilaja",
                "kodepos": "42214"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Kadupandak",
                "kodepos": "42213"
            },
            {
                "kecamatan": "Karang Tanjung",
                "kelurahan": "Juhut",
                "kodepos": "42213"
            },
            {
                "kecamatan": "Picung",
                "kelurahan": "Ciherang",
                "kodepos": "42213"
            },
            {
                "kecamatan": "Pandeglang",
                "kelurahan": "Kabayan",
                "kodepos": "42212"
            },
            {
                "kecamatan": "Pandeglang",
                "kelurahan": "Pandeglang",
                "kodepos": "42211"
            },
            {
                "kecamatan": "Majasari",
                "kelurahan": "Karaton",
                "kodepos": "42211"
            }
        ],
        "k21": [
            {
                "kecamatan": "Legok",
                "kelurahan": "Rancagong",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Serdang Wetan",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Legok",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Pala Sari",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Cirarab",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Kamuning (Kemuning)",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Caringin",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Ciangir",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Babat",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Bojong Kamal",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Legok",
                "kelurahan": "Babakan",
                "kodepos": "15820"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Pakulonan Barat",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Suka Bakti",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Kelapa Dua",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Kadu",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Kadu Jaya",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Curug Sangerang (Curug Sangereng)",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Curug Wetan",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Bojong Nangka",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Cukang Galih",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Curug Kulon",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Bencongan Indah",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Curug",
                "kelurahan": "Binong",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Solear",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Kelapa Dua",
                "kelurahan": "Bencongan",
                "kodepos": "15810"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Sukatani",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Pasanggrahan",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Selapajang",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Munjul",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Karangharja",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Jeung Jing",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Cisoka",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Cireundeu",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Cikasungka",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Cikuya",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Solear",
                "kelurahan": "Cikareo",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Caringin",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Cempaka",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Cibugel",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Bojongloa",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Tegalsari",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Cisoka",
                "kelurahan": "Carenang",
                "kodepos": "15730"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Tigaraksa",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Tipar Jaya (Tiparraya)",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Tapos",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Taban",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Sodong",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Suka Manah",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Rancabuaya",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Pematang",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Pete",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Pasir Nangka",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Pasir Bolang",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Pasir Barat",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Mekarsari",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Mata Gara",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Kutruk",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Cileles",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Kadu Agung",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Cisereh",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Marga Sari",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Tigaraksa",
                "kelurahan": "Bantar Panjang",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Daru",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Ancol Pasir",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Jambe",
                "kelurahan": "Jambe",
                "kodepos": "15720"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Talaga Sari",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Talaga",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Suka Mulya",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Suka Nagara",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Serdang Kulon",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Suka Damai",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Ranca Iyuh",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Ranca Kalapa",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Pasir Jaya",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Ciakar",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Peusar",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Cibadak",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Pasir Gadung",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Bojong",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Budi Mulya",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Panongan",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Bunder",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Panongan",
                "kelurahan": "Mekar Bakti",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Bitung Jaya",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Cikupa",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Tamiang",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Cikupa",
                "kelurahan": "Dukuh",
                "kodepos": "15710"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Talok",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Ranca Ilat",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Renged",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Kanda Wati",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Sidoko",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Gunung Kaler",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Patra Sana",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Cipaeh",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Ranca Gede",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Cibetok",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Pasir Ampo",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Talagasari",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Onyam",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Tobat",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Koper",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Sumur Bandung",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Kresek",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Suka Murni",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Suka Mulya",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Kemuning",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Saga",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Gunung Kaler",
                "kelurahan": "Kedung",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Sentul",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Kresek",
                "kelurahan": "Jengkol",
                "kodepos": "15620"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Jayanti",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Sentul Jaya",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Gembong",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Pasir Muncang",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Dang Deur",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Pasir Gintung",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Cikande",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Parahu",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Cangkudu",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Pabuaran",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Bunar",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Jayanti",
                "kelurahan": "Pangkat",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Merak",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Buni Ayu",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Kali Asin",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Balaraja",
                "kelurahan": "Balaraja",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Kubang",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Sukamulya",
                "kelurahan": "Benda",
                "kodepos": "15610"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Sukawali",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Surya Bahari",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Pakuhaji",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Rawa Boni",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Laksana",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Paku Alam",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Kiara Payung",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Kohod",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Kramat",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Gaga",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Buaran Mangga",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Kalibaru",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Bunisari (Bonasari)",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Pakuhaji",
                "kelurahan": "Buaran Bambu",
                "kodepos": "15570"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Wana Kerta",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Sukamantri",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Sukaasih",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Suka Harja",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Sindang Sari",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Sindang Sono",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Sindang Asih",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Sindang Jaya",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Sindang Panon",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Pasar Kemis",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Kuta Jaya",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Pangadegan",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Kuta Baru",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Kuta Bumi",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Waliwis",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Pasar Kemis",
                "kelurahan": "Gelam Jaya",
                "kodepos": "15560"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Pasir",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Pasilian",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Pagedangan Ilir",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Pagedangan Udik",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Pagenjahan",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Muncung",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Mekar Baru",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Kronjo",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Kosambi Dalam",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Jenggot",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Kedaung",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Klutuk",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Cirumpak",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Ganda Ria",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Mekar Baru",
                "kelurahan": "Cijeruk",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Blukbuk",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Tanjakan Mekar",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Kronjo",
                "kelurahan": "Bakung",
                "kodepos": "15550"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Suka Sari",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Suka Tani",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Tanjakan",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Rajegmulya",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Ranca Bango",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Suka Manah",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Pangarengan",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Rajeg",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Jambu Karya",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Lembang Sari",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Mekarsari",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Rajeg",
                "kelurahan": "Daon",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Tegal Kunir Kidul",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Tegal Kunir Lor",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sindang Jaya",
                "kelurahan": "Badak Anom",
                "kodepos": "15540"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Tanjung Anom",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Sukadiri",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Sasak",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Rawa Kidang",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Ranca Labuh",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Pekayon",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Patra Manggala",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Mekar Kondang",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Mauk Timur",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Marga Mulya",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Mauk Barat",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Lontar",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Legok Sula Maju",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Kosambi",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Ketapang",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kjlebet (Kelebet)",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Kedung Dalem",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kemiri",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Karang Anyar",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Karang Serang",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Jati Waringin",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Gunung Sari",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Gintung",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Mauk",
                "kelurahan": "Banyu Asih",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sukadiri",
                "kelurahan": "Buaran Jati",
                "kodepos": "15530"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Tanah Merah",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Sepatan",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Sangiang",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Sarakan",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Pondok Jaya",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Pondok Kelor",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Pisangan Jaya",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Kayu Bongkok",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Kedaung Barat",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Lebak Wangi",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Karet",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan",
                "kelurahan": "Kayu Agung",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Jati Mulya",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Kampung Kelor",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Teluk Naga",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Sepatan Timur",
                "kelurahan": "Gempol Sari",
                "kodepos": "15520"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Tegal Angus",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Pangkalan",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Tanjung Burung",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Kampung Melayu Timur",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Lemo",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Kebon Cau",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Muara",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Kampung Besar",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Kampung Melayu Barat",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Babakan Asem",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Teluknaga",
                "kelurahan": "Bojong Renged",
                "kodepos": "15510"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Cibogo",
                "kodepos": "15344"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Malang Nengah",
                "kodepos": "15330"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Sampora",
                "kodepos": "15345"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Lengkong Kulon",
                "kodepos": "15331"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Dangdang",
                "kodepos": "15342"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Jatake",
                "kodepos": "15330"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Suradita",
                "kodepos": "15343"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Mekarwangi",
                "kodepos": "15340"
            },
            {
                "kecamatan": "Cisauk",
                "kelurahan": "Cisauk",
                "kodepos": "15341"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Pagedangan",
                "kodepos": "15339"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Situ Gadung",
                "kodepos": "15338"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Cicalengka",
                "kodepos": "15336"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Kadu Sirung",
                "kodepos": "15337"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Cijantra",
                "kodepos": "15335"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Cihuni",
                "kodepos": "15332"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Medang",
                "kodepos": "15334"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Rawa Burung",
                "kodepos": "15215"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Rawa Rengas",
                "kodepos": "15215"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Kosambi Timur",
                "kodepos": "15213"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Salembaran Jati",
                "kodepos": "15214"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Salembaran Raya",
                "kodepos": "15214"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Cengklong",
                "kodepos": "15212"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Kosambi Barat",
                "kodepos": "15213"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Dadap",
                "kodepos": "15211"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Jatimulya",
                "kodepos": "15211"
            },
            {
                "kecamatan": "Kosambi",
                "kelurahan": "Belimbing",
                "kodepos": "15212"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Pondok Bahar",
                "kodepos": "15159"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Parung Jaya",
                "kodepos": "15159"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Padurenan (Pedurenan)",
                "kodepos": "15159"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Karang Timur",
                "kodepos": "15157"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Pondok Pucung",
                "kodepos": "15158"
            },
            {
                "kecamatan": "Pagedangan",
                "kelurahan": "Karang Tengah",
                "kodepos": "15157"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Kreo Selatan",
                "kodepos": "15156"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Karang Mulya",
                "kodepos": "15157"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Karang Tengah",
                "kodepos": "15157"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Kreo",
                "kodepos": "15156"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Cipadu Jaya",
                "kodepos": "15155"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan Selatan",
                "kodepos": "15154"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Gaga",
                "kodepos": "15154"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan Utara",
                "kodepos": "15154"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan Indah",
                "kodepos": "15154"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Cipadu",
                "kodepos": "15155"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Paninggilan Utara",
                "kodepos": "15153"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Parung Serab",
                "kodepos": "15153"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Paninggilan",
                "kodepos": "15153"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Sudimara Timur",
                "kodepos": "15151"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Tajur",
                "kodepos": "15152"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Sudimara Jaya",
                "kodepos": "15151"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Sudimara Selatan",
                "kodepos": "15151"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Sudimara Barat",
                "kodepos": "15151"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Cipondoh Makmur",
                "kodepos": "15148"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Cipondoh",
                "kodepos": "15148"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Cipondoh Indah",
                "kodepos": "15148"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Kenanga",
                "kodepos": "15146"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Ketapang",
                "kodepos": "15147"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Petir",
                "kodepos": "15147"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Gondrong",
                "kodepos": "15146"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Sudimara Pinang",
                "kodepos": "15145"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Nerogtog",
                "kodepos": "15145"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Pinang",
                "kodepos": "15145"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Kunciran",
                "kodepos": "15144"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Kunciran Indah",
                "kodepos": "15144"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Kunciran Jaya",
                "kodepos": "15144"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Panunggangan",
                "kodepos": "15143"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Panunggangan Timur",
                "kodepos": "15143"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Panunggangan Utara",
                "kodepos": "15143"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Cipete",
                "kodepos": "15142"
            },
            {
                "kecamatan": "Pinang (Penang)",
                "kelurahan": "Pakojan",
                "kodepos": "15142"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Poris Plawad Utara",
                "kodepos": "15141"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Poris Plawad",
                "kodepos": "15141"
            },
            {
                "kecamatan": "Cipondoh",
                "kelurahan": "Poris Plawad Indah",
                "kodepos": "15141"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Uwung Jaya",
                "kodepos": "15138"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Panunggangan Barat",
                "kodepos": "15139"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Cibodas Baru",
                "kodepos": "15138"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Cibodas Sari",
                "kodepos": "15138"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Manis Jaya",
                "kodepos": "15136"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Gandasari",
                "kodepos": "15137"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Cibodas",
                "kodepos": "15138"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Pasir Jaya",
                "kodepos": "15135"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Jatake",
                "kodepos": "15136"
            },
            {
                "kecamatan": "Cibodas",
                "kelurahan": "Jatiuwung",
                "kodepos": "15134"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Keroncong",
                "kodepos": "15134"
            },
            {
                "kecamatan": "Periuk",
                "kelurahan": "Gembor",
                "kodepos": "15133"
            },
            {
                "kecamatan": "Jatiuwung",
                "kelurahan": "Alam Jaya",
                "kodepos": "15133"
            },
            {
                "kecamatan": "Periuk",
                "kelurahan": "Periuk Jaya",
                "kodepos": "15131"
            },
            {
                "kecamatan": "Periuk",
                "kelurahan": "Gebang Raya",
                "kodepos": "15132"
            },
            {
                "kecamatan": "Periuk",
                "kelurahan": "Sangiang Jaya",
                "kodepos": "15132"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Neglasari",
                "kodepos": "15129"
            },
            {
                "kecamatan": "Periuk",
                "kelurahan": "Periuk",
                "kodepos": "15131"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Kedaung Wetan",
                "kodepos": "15128"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Mekar Sari",
                "kodepos": "15129"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Selapajang Jaya",
                "kodepos": "15127"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Kedaung Baru",
                "kodepos": "15128"
            },
            {
                "kecamatan": "Benda",
                "kelurahan": "Jurumudi Baru",
                "kodepos": "15124"
            },
            {
                "kecamatan": "Benda",
                "kelurahan": "Benda",
                "kodepos": "15125"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Poris Gaga",
                "kodepos": "15122"
            },
            {
                "kecamatan": "Benda",
                "kelurahan": "Pajang",
                "kodepos": "15126"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Poris Gaga Baru",
                "kodepos": "15122"
            },
            {
                "kecamatan": "Benda",
                "kelurahan": "Belendung",
                "kodepos": "15123"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Batu Ceper",
                "kodepos": "15122"
            },
            {
                "kecamatan": "Benda",
                "kelurahan": "Jurumudi",
                "kodepos": "15124"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Karang Sari",
                "kodepos": "15121"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Poris Jaya",
                "kodepos": "15122"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Batu Sari",
                "kodepos": "15121"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Kebon Besar",
                "kodepos": "15122"
            },
            {
                "kecamatan": "Neglasari",
                "kelurahan": "Karang Anyar",
                "kodepos": "15121"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Buaran Indah",
                "kodepos": "15119"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Cimone",
                "kodepos": "15114"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "15119"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Suka Jadi",
                "kodepos": "15113"
            },
            {
                "kecamatan": "Batuceper",
                "kelurahan": "Batu Jaya",
                "kodepos": "15121"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Gerendeng",
                "kodepos": "15113"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Babakan",
                "kodepos": "15118"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Marga Sari",
                "kodepos": "15113"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Sukasari",
                "kodepos": "15118"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Bugel",
                "kodepos": "15113"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Cikokol",
                "kodepos": "15117"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Pabuaran Tumpeng",
                "kodepos": "15112"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Kelapa Indah",
                "kodepos": "15117"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Pasar Baru",
                "kodepos": "15112"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Karawaci Baru",
                "kodepos": "15116"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Koang Jaya",
                "kodepos": "15112"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Nusa Jaya",
                "kodepos": "15116"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Nambo Jaya",
                "kodepos": "15112"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Karawaci",
                "kodepos": "15115"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Suka Asih",
                "kodepos": "15111"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Cimone Jaya",
                "kodepos": "15116"
            },
            {
                "kecamatan": "Tangerang",
                "kelurahan": "Sukarasa",
                "kodepos": "15111"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Pabuaran",
                "kodepos": "15114"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Sumur Pancing (Pacing)",
                "kodepos": "15114"
            },
            {
                "kecamatan": "Karawaci",
                "kelurahan": "Bojong Jaya",
                "kodepos": "15115"
            }
        ],
        "k22": [
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Pisangan",
                "kodepos": "15419"
            },
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Cireundeu",
                "kodepos": "15419"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Pondok Cabe Udik",
                "kodepos": "15418"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Benda Baru",
                "kodepos": "15418"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Jombang",
                "kodepos": "15414"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Pondok Cabe Ilir",
                "kodepos": "15418"
            },
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Rempoa",
                "kodepos": "15412"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Pamulang Barat",
                "kodepos": "15417"
            },
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Rengas",
                "kodepos": "15412"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Pamulang Timur",
                "kodepos": "15417"
            },
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Pondok Ranji",
                "kodepos": "15412"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Kedaung",
                "kodepos": "15415"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Ciputat",
                "kodepos": "15411"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Pondok Benda",
                "kodepos": "15416"
            },
            {
                "kecamatan": "Ciputat Timur",
                "kelurahan": "Cempaka Putih",
                "kodepos": "15412"
            },
            {
                "kecamatan": "Pamulang",
                "kelurahan": "Bambu Apus",
                "kodepos": "15415"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Cipayung",
                "kodepos": "15411"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Sarua Indah",
                "kodepos": "15414"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Sarua (Serua)",
                "kodepos": "15414"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Sawah Baru",
                "kodepos": "15413"
            },
            {
                "kecamatan": "Ciputat",
                "kelurahan": "Sawah Lama",
                "kodepos": "15413"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Pondok Jagung",
                "kodepos": "15326"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Pondok Jagung Timur",
                "kodepos": "15326"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Pakulonan",
                "kodepos": "15325"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Jelupang",
                "kodepos": "15323"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Paku Jaya",
                "kodepos": "15324"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Lengkong Gudang Timur",
                "kodepos": "15321"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Lengkong Wetan",
                "kodepos": "15322"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Pakualam",
                "kodepos": "15320"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Lengkong Gudang",
                "kodepos": "15321"
            },
            {
                "kecamatan": "Serpong Utara",
                "kelurahan": "Lengkong Karya",
                "kodepos": "15320"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Rawa Buntu",
                "kodepos": "15318"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Babakan",
                "kodepos": "15315"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Bakti Jaya",
                "kodepos": "15315"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Setu",
                "kodepos": "15314"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Muncul",
                "kodepos": "15314"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Kranggan",
                "kodepos": "15312"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Kademangan",
                "kodepos": "15313"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Rawa Mekar Jaya",
                "kodepos": "15310"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Serpong",
                "kodepos": "15311"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Buaran",
                "kodepos": "15310"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Ciater",
                "kodepos": "15310"
            },
            {
                "kecamatan": "Serpong",
                "kelurahan": "Cilenggang",
                "kodepos": "15310"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Perigi Lama",
                "kodepos": "15227"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Perigi Baru",
                "kodepos": "15228"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Pucung",
                "kodepos": "15229"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Kacang Barat",
                "kodepos": "15226"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Kacang Timur",
                "kodepos": "15226"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Aren",
                "kodepos": "15224"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Karya",
                "kodepos": "15225"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Jurang Mangu Timur",
                "kodepos": "15222"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Jurang Mangu Barat",
                "kodepos": "15223"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Betung",
                "kodepos": "15221"
            },
            {
                "kecamatan": "Pondok Aren",
                "kelurahan": "Pondok Jaya",
                "kodepos": "15220"
            }
        ],
        "k23": [
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Warung Pojok",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Tebat Laut",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Warung Pojok",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Talang Tige",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Talang Sawah",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Talang Pito",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Taba Baru",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Sosokan Baru",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Sosokan Tabah",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Sosokan Cinta Mandi",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Pematang Danau Air Les",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Renah Kurung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Limbur/Lembur Baru",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Muara Langkap",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Pagar Agung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Langgar Jaya",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Limbur Lama (Lembur Lama)",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Kembang Seri",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Kota Agung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Gunung Agung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Keban Agung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Embong Ijuk",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Embong Sido",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Bukit Menyan",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Cinta Mandi Baru",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Cinto Mandi (Cinta Mandi)",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Benuang Galing",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Batu Kalung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Batu Belarik",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Batu Bandung",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Bermani Ilir",
                "kelurahan": "Air Raman",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Air Selimang",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Tugurejo",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Muara Kemumu",
                "kelurahan": "Air Punggur",
                "kodepos": "39374"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Temedak/Temdak",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Tertik",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Tapak Gedung",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Tebat Karai",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Tebing Penyamun",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Talang Karet",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Talang Gelompok",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Taba Santing (Tabasating)",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Talang Babatan",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Taba Saling",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Taba Padang",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Sungai Jernih",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Taba Air Pauh",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Sukasari",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Sumber Sari",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Sido Rejo",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Sinar Gunung",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Sido Makmur",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Penanjung Panjang Atas",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Peraduan Binjai",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Penanjung Panjang",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Nanti Agung",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Pematang Donok",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Lubuk Saung",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Mekar Sari",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Tebat Karai",
                "kelurahan": "Karang Tengah",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Kandang",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Bukit Sari",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Cirebon Baru (Cirbon Baru)",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Bandung Jaya",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Bayung",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Bandung Baru",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Seberang Musi",
                "kelurahan": "Air Pesi",
                "kodepos": "39373"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Tebat Monok",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Weskust (Westkust)",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Taba Tebelet",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Tangsi Duren",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Permu bawah",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Sukamerindu",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pensiunan",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Permu",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pasar Sejantung",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pasar Ujung",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pelangkian",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pasar Kepahiang",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pagar Gunung",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Pagar Gunung",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kuto Rejo (Kuterejo)",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kelobak",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kepahiang (Dusun Kepahingan)",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Karang Endah",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kelilik",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kampung Bogor",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Kampung Pensiunan",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Karang Anyar",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Imigrasi Permu",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Kabawetan/Tangsi Baru",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kepahiang",
                "kelurahan": "Bogor Baru",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Barat Wetan",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Babakan Bogor",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Kebawetan (Kabawetan)",
                "kelurahan": "Air Sempiang",
                "kodepos": "39372"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Ujan Mas Bawah",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Tanjung Alam",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Ujan Mas Atas",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Taba Mulan",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Suro Muncar",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Suro Ilir",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Suro Lembak",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Suro Bali",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Suro Baru",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Pungguk Meranti",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Simpang Kota Bingin",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Pungguk Meringang",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Pulo Geto Baru",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Pulogeto",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Meranti Jaya",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Pekalongan",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Durian Depun",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Daspetah II",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Lubuk Penyamun",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Daspetah",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Cugung Lalang",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Bumi Sari",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Bukit Barisan",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Air Hitam",
                "kodepos": "39371"
            },
            {
                "kecamatan": "Merigi",
                "kelurahan": "Batu Ampar",
                "kodepos": "39371"
            }
        ],
        "k24": [
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Sebelat",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Sungai Lisai",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Tambang Sawah",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Ketenong II",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Ketenong Jaya",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Air Kopras",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Bioa Putiak",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Pinang Belapis",
                "kelurahan": "Ketenong I",
                "kodepos": "39269"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Tangua",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Pangkalan",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Kota Baru",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Lemeu / Limau",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Embong",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Embong I",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Uram Jaya",
                "kelurahan": "Kota Agung",
                "kodepos": "39268"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Ujung Tanjung I (satu)",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Ujung Tanjung II (dua)",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Ujung Tanjung III (tiga)",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Suka Bumi",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Tabeak Dipoa",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Tabeak Kauk",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Magelang Baru",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Muning Agung",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "UPT Tanjung Agung",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Sakti",
                "kelurahan": "Lemeu Pit",
                "kodepos": "39267"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Tanjung Agung",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Tik Teleu",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Tabeak Blau",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Tabeak Blau I",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Tabeak Blau Dua/II",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Suka Kayo",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Sukau Datang",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Sukau Datang I",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Kota Baru Santan",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Pelabai (Pelabi)",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Daneo / Daneu / Danau",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Pelabai",
                "kelurahan": "Gunung Alam",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Atas Tebing",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Lebong Atas",
                "kelurahan": "Blau",
                "kodepos": "39265"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Talang Bunut",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Talang Ulu",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Tunggang",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Sukaraja",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Sungai Gerong",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Selebar Jaya",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Suka Marga",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Paya Embik",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Pasar Muara Aman",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Nangai Tayau",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Nangai Tayau I",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Lokasari",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Muara Ketayu",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Ladang Palembang",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Lebong Donok",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Lebong Tambang",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Kampung Muara Aman",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Kampung Gandung",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Kampung Jawa Baru",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Kampung Jawa Dalam",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Gandung Baru",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Garut",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Dusun Muara Aman",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Embong Uram",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Gandung",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Utara",
                "kelurahan": "Bentangur",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Amen",
                "kelurahan": "Amen",
                "kodepos": "39264"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Ujung Tanjung I",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Ujung Tanjung II",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Tanjung Bunga II (dua)",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Taba Seberang",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Talang Sakti",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Semelako",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Suka Bumi",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Suka Damai",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Magelang Baru",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Pagar Agung",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Karang Anyar",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Limaupit",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "UPT Mangku Rajo",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Tengah",
                "kelurahan": "Embong Panjang",
                "kodepos": "39263"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Turan Lalang",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Topos",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Turan Tiging",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Talang Leak II",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Tes",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Talang Donok I",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Talang Kerinci",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Talang Leak I",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Taba Anyar",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Talang Baru II",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Suka Sari",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Pungguk Pedaro",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Mubai",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Pelabuhan Talang Leak",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Lebong Selatan",
                "kelurahan": "Kutai Donok",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Karang Dapo Bawah",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Bukit Nibung",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Bungin",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Bingin Kuning",
                "kelurahan": "Karang Dapo Atas",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Ajai Siang",
                "kodepos": "39262"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Tik Kuto",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Tanjung",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Teluk Dien",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Talang Donok",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Talang Ratu",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Talang Ratu",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Suka Negeri",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Talang Baru I",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Bioa Sengok",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Rimbo Pengadang",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Topos",
                "kelurahan": "Bandar Agung",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Bajok",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Bandar Agung",
                "kodepos": "39261"
            },
            {
                "kecamatan": "Rimbo Pengadang",
                "kelurahan": "Air Dingin",
                "kodepos": "39261"
            }
        ],
        "k25": [
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Taba Anyar",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Tanjung Gelang",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Sukakarya",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Suka Rami",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Suka Merindu",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Sari Pulau",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Periang",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Merantau",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Lubuk Mumpo",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Lubuk Tanjung/Tunjung",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Lubuk Bingin Baru",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Lubuk Belimbing II",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Kota Padang Baru",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Lubuk Belimbing I",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Kota Padang",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Dusun Baru",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Durian Mas",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Derati",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Kota Padang",
                "kelurahan": "Bedeng SS",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Sindang Beliti Ilir",
                "kelurahan": "Balai Buntar/Butar",
                "kodepos": "39183"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Ulak Tanding",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Ujan Panas",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Tanjung Sanai II",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Tanjung Sanai I",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Tanjung Agung",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Tanjung Heran",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Taktoi",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Taba Tinggi",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Binduriang",
                "kelurahan": "Taba Padang",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Binduriang",
                "kelurahan": "Simpang Beliti",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Pengambang",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Pasar Padang Ulak Tanding",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Muara Telita",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Lubuk Alai",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Lawang Agung",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Binduriang",
                "kelurahan": "Kepala Curup",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Kasie Kasubun",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Karang Pinang",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Karang Baru",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Binduriang",
                "kelurahan": "Kampung Jeruk",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Jabi",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Guru Agung",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Bukit Batu",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Belumai II",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Apur",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Belumai I",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Beliti Ulu",
                "kelurahan": "Air Nau",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Binduriang",
                "kelurahan": "Air Apo",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Padang Ulak Tanding",
                "kelurahan": "Air Kati",
                "kodepos": "39182"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "Warung Pojok",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Tanjung Aur",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Talang Lahat",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "Talang Belitar",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Sumber Bening",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Sumber Urip",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Suban Ayam",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Sindang Jaya",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Sindang Jati",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "Sinar Gunung",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Simpang Nangka",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Sambirejo",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Mojorejo",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Pelalo",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Kayu Manis",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Kayu Manis",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Kampung Baru",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Karang Jaya",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "IV/Empat Suku Menanti",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Kali Padang",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Cawang Baru",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Cawang Lama",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "Bengko",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Beringin Tiga",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Cahaya Negeri",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Belitar Muka",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Belitar Seberang",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Air Putih Kali Bandung",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Daratan",
                "kelurahan": "Air Rusa",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Air Duku",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Selupu Rejang",
                "kelurahan": "Air Meles Atas",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Sindang Kelingi",
                "kelurahan": "Air Dingin",
                "kodepos": "39153"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Tebat Tenong Dalam",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Tebat Tenong Luar",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Tebat Pulau",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Sumber Rejo Transad",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Sentral Baru",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Suka Rami",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Purwodadi",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Selamat Sudiarjo",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Pal VIII/Delapan",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Pal Seratus/100",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Pal VII/Tujuh",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Kampung Sajad",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Pagar Gunung",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Kampung Melayu",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Dataran Tapus",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Baru Manis",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Bangun Jaya",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Babakan Baru",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Bandung Marga",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Air Mundu",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu",
                "kelurahan": "Air Pikat",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Bermani Ulu Raya",
                "kelurahan": "Air Bening",
                "kodepos": "39152"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Turan Baru",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Watas Marga",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Teladan (Desa Teladan)",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Timbul Rejo",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Tasikmalaya",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Tabarenah",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Talang Ulu",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Suka Marga",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Suka Datang",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Pungguk Lalang",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Rimbo Recap",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Seguring",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Lubuk Ubar",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Perbo",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Kota Pagu",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Lubuk Kembang",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Kesambe Baru",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Kesambe Lama",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Dusun Sawah",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Kampung Delima",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Duku Ilir",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Duku Ulu",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Desa Pahlawan",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Batu Dewa",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Batu Panco",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Batu Galing",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Air Merah",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Banyumas",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Air Bang",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Air Lanang",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Adirejo",
                "kodepos": "39125"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Tempel Rejo",
                "kodepos": "39124"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Tunas Harapan",
                "kodepos": "39123"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Sukaraja",
                "kodepos": "39121"
            },
            {
                "kecamatan": "Curup Utara",
                "kelurahan": "Dusun Curup",
                "kodepos": "39119"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Sidorejo/Siderejo",
                "kodepos": "39119"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Pasar Tengah",
                "kodepos": "39117"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Talang Benih",
                "kodepos": "39118"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Jalan Baru",
                "kodepos": "39117"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Kepala Siring",
                "kodepos": "39116"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Pelabuhan Baru",
                "kodepos": "39115"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Karang Anyar",
                "kodepos": "39116"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Kampung Jawa",
                "kodepos": "39115"
            },
            {
                "kecamatan": "Curup Timur",
                "kelurahan": "Air Meles Bawah",
                "kodepos": "39115"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Talang Rimbo Lama",
                "kodepos": "39114"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Pasar Baru",
                "kodepos": "39113"
            },
            {
                "kecamatan": "Curup Tengah",
                "kelurahan": "Talang Rimbo Baru",
                "kodepos": "39113"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Dwi Tunggal",
                "kodepos": "39112"
            },
            {
                "kecamatan": "Curup Selatan",
                "kelurahan": "Air Putih Baru",
                "kodepos": "39112"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Air Putih Lama",
                "kodepos": "39112"
            },
            {
                "kecamatan": "Curup",
                "kelurahan": "Air Rambai",
                "kodepos": "39111"
            }
        ],
        "k26": [
            {
                "kecamatan": "Maje",
                "kelurahan": "Way Hawang",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Tanjung Ganti",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Tanjung Aur",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Tanjung Baru",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Suka Menanti",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Sumber Harapan",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Pematang Danau",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Penyandingan",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Muara Jaya",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Parda Suka",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Kedataran",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Linau",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Arga Mulya",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Bakal Makmur",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Benteng Harapan",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Air Jelatang",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Air Long",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Maje",
                "kelurahan": "Air Bacang",
                "kodepos": "38965"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Tebing Rambutan",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Tri Jaya",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Ulak Pandan",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Suku Tiga",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Sumber Harapan",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Tanjung Betuah",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Sinar Banten",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Suka Jaya",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Pasar Baru",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Pasar Jumat",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Merpas",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Muara Dua",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Gedung Menung",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Burni",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Batu Lungun",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Bukit Indah",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Nasal",
                "kelurahan": "Air Batang",
                "kodepos": "38964"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Tanjung Besar",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Suka Raja",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Suka Banjar",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Suka Bandung",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Selasih",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Sinar Pagi",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Sawah Jangkung",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Sekunyit",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Pasar Saoh",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Pengubaian",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Pahlawan Ratu",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Pasar Baru",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Pasar Lama",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Padang Petron",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Padang Genting",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Padang Binjai",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Kepala Pasar",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Muara Tetap",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Kepahyang",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Gedung Sako II",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Jembatan Dua",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Kasuk Baru",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Cucupan",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Gedung Sako",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Tetap (Muara Tetap)",
                "kelurahan": "Babat",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Bandar Bintuhan",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Kaur Selatan",
                "kelurahan": "Air Dingin",
                "kodepos": "38963"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Tanjung Baru",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Talang Padang",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Tanjung Alam",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Suka Merindu",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Pinang Jawa II",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Talang Beringin",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Pinang Jawa",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Penandingan",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Pengurung",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Padang Panjang",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Papahan",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Mentiring",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Nusuk",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Lubuk Gung",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Masria Baru",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Jawi",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Gunung Tiga II",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Karang Dapo",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Gunung Tiga",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Gunung Megang",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Gunung Terang",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Geramat",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Kinal",
                "kelurahan": "Gedung Wani",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Bunga Melur",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Cahaya Batin",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Semidang Gumai (Gumay)",
                "kelurahan": "Awat Mata",
                "kodepos": "38962"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Umbul",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Ulak Lebar",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Tuguk (Puguk)",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Ulak Bandung",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Tanjung Pandan",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Tri Tunggal Bhakti",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Tanjung Iman",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Sumber Makmur",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Sukarami",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Pulang Panggung",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Sinar Jaya",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Pajar Bulan",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Penyandingan",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Padang Hangat",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Padang Jati",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Muara Sahung",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Padang Baru",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Kaur Tengah",
                "kelurahan": "Kemang Manis",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Kepahyang",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Durian Besar",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Ganda Suli (Gindo Suli)",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Cinta Makmur",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Cahaya Negeri/Negri",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Muara Sahung",
                "kelurahan": "Bukit Makmur",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Bangun Jiwa",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Luas",
                "kelurahan": "Benua Ratu",
                "kodepos": "38961"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Ulak Agung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Tanjung Ganti I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Tanjung Ganti II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Tanjung Betung I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Tanjung Betung II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Tanjung Bunian",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Talang Marap",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Talang Padang",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Talang Besar",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Talang Jawi I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Talang Jawi II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Suka Rami II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Sukarami",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Suka Nanti",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Simpang Tiga",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Sinar Bulan",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Senak Perda",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Rigangan II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Rigangan III",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Pulau Panggung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Rigangan I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Perugian",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Pagar Gunung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Pancur Negara",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Naga Rantai",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Pagar Alam",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Padang Manis",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Marga Mulyo",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Manau Sembilan I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Manau Sembilan II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Jati Mulyo",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Lawang Agung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Gunung Kaya",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Guru Agung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Guru Agung II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Gunung Agung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Datar Lebar",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Datar Lebar II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Coko Betung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Coko Enau",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Bungin Tambun III",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Bungin Tambun I",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hulu",
                "kelurahan": "Bungin Tambun II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Kaur Utara",
                "kelurahan": "Bandu Agung",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Lungkang Kule",
                "kelurahan": "Aur Gading",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Air Kering II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Padang Guci Hilir",
                "kelurahan": "Air Kering II",
                "kodepos": "38956"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Kemuning III",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tinggi Ari",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Iman II",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Kemuning",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Kemuning II",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Iman",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Aur",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Tanjung Aur II",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Talang Tais",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Sulauwangi (Sula Wangi)",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Siring Agung",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Selika III",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Penantian",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Selika I",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Selika II",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Pelajaran I",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Pelajaran II",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Padang Kedondong",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Padang Leban",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Kelam Tengah",
                "kelurahan": "Darat Sawah",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Aur Ringit",
                "kodepos": "38955"
            },
            {
                "kecamatan": "Tanjung Kemuning",
                "kelurahan": "Beriang Tinggi",
                "kodepos": "38955"
            }
        ],
        "k27": [
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Tebat Sibun",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Taba",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Talang Padang",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Suka Bulan",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Suka Merindu",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Sungai Petai",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Pering Baru",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Napalan",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Bakal Dalam",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Talo Kecil",
                "kelurahan": "Lubuk Lagan",
                "kodepos": "38888"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Talang Kabu",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Talang Panjang",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Tanah Abang",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Penago II",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Rawa Indah",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Pasar Talo",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Penago Baru",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Penago I",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Padang Cekur",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Paluah Terap",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Nanti Agung",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Padang Batu",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Mekar Sari",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Margo Sari",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ilir Talo",
                "kelurahan": "Dusun Baru",
                "kodepos": "38887"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Pagar Banyu",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Sipur/Simpur Ijang",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Pagar",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Pagar Agung",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Muara Nibung",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Muara Simpur",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Hargo Binangun",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Mekar Jaya",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Giri Mulya",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Giri Nanto",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Banyu Kencana",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Ulu Talo",
                "kelurahan": "Air Keruh",
                "kodepos": "38886"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Talang Sali",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Tenangan",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Selebar",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Sembayat",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Kunduran",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Rawasari",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Bunga Mas",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Timur",
                "kelurahan": "Kota Agung",
                "kodepos": "38885"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Talang Rami",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Talang Empat",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Talang Beringin",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Simpang",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Sinar Pagi",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Selingsingan",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Sekalak",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Puguk",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Pandan",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Utara",
                "kelurahan": "Lubuk Resam",
                "kodepos": "38884"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Talang Tinggi",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Talang Perapat",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Sengkuang Jaya",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Purbosari",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Pagar Agung",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Lubuk Lagan",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Lunjuk",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Seluma Barat",
                "kelurahan": "Air Latak",
                "kodepos": "38883"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Tumbuan",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Tanjung Kuaw",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Talang Giring",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Talang Kebun",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Sakaian",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Renah/Rena Panjang",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Padang Capo Ilir",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Padang Capo Ulu",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Napal Jungur",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Lubuk Terentang",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Dusun Tengah",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Gunung Agung",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Cawang",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Lubuk Sandi",
                "kelurahan": "Arang Sapat",
                "kodepos": "38882"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Tawang Rejo",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Talang Sebaris",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Talang Alai",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Talang Benuang",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Taba Lubuk Puding",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Sukasari",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Pasar Ngalam",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Suka Maju",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Padang Pelasan",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Lubuk Gilang",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Lokasi Baru",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Lawang Agung",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Keban Agung",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Kungkai Baru",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Dermayu",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Air Periukan",
                "kelurahan": "Air Periukan",
                "kodepos": "38881"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Tanjungan",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Tanjung Seru",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Tangga Batu",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Tanjung Seluai",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Sido Mulyo",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Suka Rami",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Rimbo Kedui",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Sengkuang",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Padang Rambun",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Pasar Seluma",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Padang Merbau",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Seluma Selatan",
                "kelurahan": "Padang Genting",
                "kodepos": "38878"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sumber Makmur",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sumber Arung/Arum",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sido Sari",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukaraja",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sari Mulyo",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sido Luhur",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Riak Siabun",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Riak Siabun I",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Niur",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Padang Kuas",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Padang Pelawi",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Lubuk Sahung",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Kuti Agung",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Jenggalu",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Kayu Arang",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Bukit Peninjauan II",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cahaya Negeri",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Bukit Peninjauan I",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Air Petai",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Babatan",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Air Kemuning",
                "kodepos": "38877"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Talang Dantuk",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Talang Saling",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Lubuk Lintang",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Napal",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Pasar Tais",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Lubuk Kebur",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Ujung Padang",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Seluma",
                "kelurahan": "Dusun Baru",
                "kodepos": "38876"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Talang Kemang",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Tedunan",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Talang Beringin",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Serian Bandung",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Talang Alai",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Rimbo Besak",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Sendawar",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Padang Peri",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Pematang Riding/Rindang",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Padang Kelapa",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Padang Bakung",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Muara Maras",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Muara Timput",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Maras Bantan",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Maras Tengah",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Karang Anyar",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Ketapang Baru",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Kembang Mumpo",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Lubuk Betung",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Gunung Kembang",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Karang Anyar",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Gunung Bantan",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Genting Luar",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Simpang Tiga Pagar Gasing",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Semidang Alas Maras",
                "kelurahan": "Gelombang",
                "kodepos": "38875"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Napal Melintang",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Serambi Gunung",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Lubuk Ngantungan",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Masmambang",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Muara Danau",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Lubuk Gadis",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Lubuk Gio",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Durian Bubur",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Kampai",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Kembang Seri",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Batu Tugu",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Bunut Tinggi",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Air Teras",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Talo",
                "kelurahan": "Air Teras",
                "kodepos": "38874"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Talang Durian",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Tebat/Teba Gunung",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Telatan",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Suban",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Renah Gajah Mati II",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Pinju Layang",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Rantau Panjang",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Renah Gajah Mati 1",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Pajar Bulan",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Petai Kayu",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Nanti Agung",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Napalan",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Padang Serunaian",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Mekar Sari Mukti",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Muara Dua",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Nanjungan",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Maras Jauh",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Kayu Elang",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Kemang Manis",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Cugung Langu",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Gunung Megang",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Gunung Mesir",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Air Melancar",
                "kodepos": "38873"
            },
            {
                "kecamatan": "Semidang Alas",
                "kelurahan": "Bandung Agung",
                "kodepos": "38873"
            }
        ],
        "k28": [
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Wonosobo",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Wonosobo",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Teruntung",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Tunggal Jaya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Teras Terunjam",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Talang Medan",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Talang Buai",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Talang Kuning",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Sungai Jerinjing",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Surian Bungkal",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Sungai Ipuh",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Sungai Ipuh Dua",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Sungai Ipuh Satu",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Sungai Gading",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Sumber Mulya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Suka Maju",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Sido Mulyo",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Sido Mulya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Pondok Kopi",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Setia Budi",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Pondok Baru",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Mekar Mulya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Penarik",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Mekar Jaya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Mekar Mulya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Marga Mukti",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Marga Mukti",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Selagan Raya",
                "kelurahan": "Lubuk Sahung",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Lubuk Mukti",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Karang Jaya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Lubuk Bangko",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Bumi Mulya",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Penarik",
                "kelurahan": "Bukit Makmur",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Teras Terunjam",
                "kelurahan": "Aur Cina",
                "kodepos": "38768"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Tirta Mulya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Tanjung Alai",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Air Majunto",
                "kelurahan": "Tirta Mulya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Talang Sepakat",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Sungai Rengas",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Talang Petai",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Talang Sakti",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Sumber Makmur",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Sungai Lintang",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Sinar Jaya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Suka Pindah",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Sido Makmur",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Rasno",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Ranah Karya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Pondok Panjang",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Pondok Tengah",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Air Majunto",
                "kelurahan": "Manjunto Jaya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Air Majunto",
                "kelurahan": "Pondok Makmur",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Lubuk Gedang",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Lubuk Pinang",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Lalang Luas",
                "kodepos": "38767"
            },
            {
                "kecamatan": "V Koto",
                "kelurahan": "Lubuk Cabau",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Arah Tiga",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Lubuk Pinang",
                "kelurahan": "Kota Praja",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Air Majunto",
                "kelurahan": "Agung Jaya",
                "kodepos": "38767"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Teluk Bakung",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Tunggang (Dusun Tunggang)",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Teramang Jaya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Sumber Makmur",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Talang Gading",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Sinar Laut",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Sidodadi",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Sido Makmur",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Retak Mudik",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Pondok Kandang",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Pondok Suguh",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Pernyah",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Pondok Baru",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Padang Gading",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Mekar Sari",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Pasar Bantal",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Nenggalo",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Nelan Indah",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Mandi Angin Jaya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Lubuk Selandak",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Lubuk Bento",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Gajah Mati",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Karya Mulya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Gading Jaya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Bumi Mekar Jaya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Brangan Mulya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Batu Ejung",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Banjar Sari",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Teramang Jaya",
                "kelurahan": "Bandar Jaya",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Air Bikuk",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Air Hitam",
                "kodepos": "38766"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Tanjung Mulya",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Sumber Sari",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Pondok Suguh",
                "kelurahan": "Air Berau",
                "kodepos": "38766"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Selagan Jaya",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Tanah Harapan",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Sari Bulan",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Tanah Rekah",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Sari Makmur",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Rawa Bangun",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Rawa Mulya",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Pauh Terenjah",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Pondok Lunang",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Pasar Sebelah",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Lubuk Sanai III",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Koto Jaya",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Lubuk Sanai",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Lubuk Sanai II",
                "kodepos": "38765"
            },
            {
                "kecamatan": "XIV Koto",
                "kelurahan": "Dusun Baru Pelokan",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Dusun Baru V Koto",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Bandar Ratu",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Air Kasai",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Tirta Mulya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Dikit",
                "kelurahan": "Air Dikit",
                "kodepos": "38765"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Tanjung Medan",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Tirta Kencana",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Talang Rio",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Talang Baru",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Sibak (Dusun Sibak)",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Talang Arah",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Semundam",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Serami Baru",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Retak Hilir",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Semambang Makmur (Semambung)",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Pulau Payung (Pulai Payung)",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Rami Mulia",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Pulau Makmur",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Pulau Baru",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Mundam Marap",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Pasar Baru",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Pasar Ipuh",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Mekar Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Medan Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Marga Mulia",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Gajah Makmur",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Malin Deman",
                "kelurahan": "Lubuk Talang",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Makmur Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Dusun Pulau",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Bukit Mulya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Cinta Asih",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Bukit Harapan",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Air Rami",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Air Rami",
                "kelurahan": "Arga Jaya",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Air Buluh",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Ipuh (Muko-Muko Selatan)",
                "kelurahan": "Air Mera",
                "kodepos": "38764"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Pondok Batu",
                "kodepos": "38713"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Ujung Padang",
                "kodepos": "38714"
            },
            {
                "kecamatan": "Kota Mukomuko (Mukomuko Utara)",
                "kelurahan": "Pasar Mukomuko",
                "kodepos": "38711"
            }
        ],
        "k29": [
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Tambak Rejo",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Tanah Hitam",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Sido Mukti",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Talang Tua/Tuo",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Marga Sakti",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Padang Jaya",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Sido Luhur",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Lubuk Banyau",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Marga Jaya",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Ulak Tanding",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Padang Jaya",
                "kelurahan": "Arga Mulya",
                "kodepos": "38657"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Sukamarga",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Taba Kelintang",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Sekiau",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Selolong",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Serangai",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Samban Jaya",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Seberang Tunggal",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Pagar Ruyung",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Peninjau (Maninjau)",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Bintunan",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Durian Amparan",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Airlakok",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Batik Nau",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Wonoharjo",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Batik Nau",
                "kelurahan": "Air Menganyau",
                "kodepos": "38656"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Sukamakmur",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Tanjung Anom",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Rena Jaya",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Suka Mulya",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Giri Mulia",
                "kelurahan": "Giri Mulya",
                "kodepos": "38655"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Tanjung Aur",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Teluk Ajang",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Taba Baru",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Talang Rasau",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Talang Ulu",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Suka Langu",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Retes",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Sukarami",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Padang Kala",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Pal Tiga Puluh",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Pasar Lais",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Lubuk Mumpo",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Mesigit",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Kembang Manis",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Lubuk Gedang",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Lubuk Lesung",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Jago Bayo",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Kalbang",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Durian Daun",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Dusun Rajo/Raja",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Air Padang",
                "kelurahan": "Balam",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Datar Lebar",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Karang Suci",
                "kodepos": "38619"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Air Padang",
                "kodepos": "38653"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Lubuk Saung/Sahung",
                "kodepos": "38618"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Datar Ruyung",
                "kodepos": "38617"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Tanjung Raman",
                "kodepos": "38617"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Talang Denau",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Rama Agung",
                "kodepos": "38614"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Sidodadi",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Purwodadi",
                "kodepos": "38612"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Sumber Agung",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Tebing Kaning",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Senali",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Taba Tembilang",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Sido Urip",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Pematang Sapang",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Pagar Banyu",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Pagar Ruyung",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Kuro Tidur",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Kemumu",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Karang Anyar",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Karang Anyar Ilir",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Gunung Selam / Selan",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Kalai Duai",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Kali",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Gunung Alam",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Gunung Besar",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arga Makmur",
                "kelurahan": "Gunung Agung",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Gardu",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Arma Jaya",
                "kelurahan": "Air Merah",
                "kodepos": "38611"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Tanjung Genting",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Tanjung Karet",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Talang Renah",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Talang Pungguk",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Talang Lembak",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Talang Ginting",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Sungai Pura",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Talang Baru Ginting",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Sawang Lebar Ilir",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Penyangkak",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Padang Sepan",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Lubuk Balam",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Kota Agung",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Kertapati",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Datar Macang",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Genting Perangkap",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Air Besi",
                "kelurahan": "Curup (Dusun Curup)",
                "kodepos": "38575"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Kaana",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Apoho",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Malakoni",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Meok",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Khayapu",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Enggano",
                "kelurahan": "Banjar Sari",
                "kodepos": "38387"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Tebat Pacur",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Talang Rendah",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Tanjung Putus",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Talang Curup",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Talang Jambu",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Talang Pasak",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Taba Padang Kol",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Taba Padang Rejang",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Sumberejo",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Simpang Ketenong",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Serumbung",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Lubuk Pendam",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Sengkuang",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Magelang",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Perbo",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Lubuk Durian",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Salam Harjo",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Lubuk Jale",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Penyangkak",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Kota Lekat Ilir",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Padang Bendar",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Kota Lekat Mudik",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Pematang Dalam",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Kedu Baru",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Jogya Baru",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Batu Roto",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Batu Layang",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Batu Raja Kol",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Tebing Kandang",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Batu Raja Rejang",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Tepi Laut (Tepian Laut)",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Banyumas",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Selubuk",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Banyumas Baru",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Talang Jarang",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Alun Dua",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Sawang Lebar",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Kerkap",
                "kelurahan": "Aur Gading",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Pasar Palik",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Air Baus I",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Pasar Tebat",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Air Baus II",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Pasar Bemba",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Hulu Palik",
                "kelurahan": "Air Banai",
                "kodepos": "38374"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Pasar Kerkap",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Talang Kering",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Lubuk Sematung",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Lubuk Tanjung",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Lubuk Gading",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Dusun Pukur",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Tanjung Agung Palik",
                "kelurahan": "Ketapi",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Air Napal",
                "kelurahan": "Air Napal",
                "kodepos": "38373"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Teluk Anggung",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Tanjung Sari",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Tanjung Kemayan (Kemenyan)",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Tanjung Alai",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Talang Berantai",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Pondok Bakil",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Muara Santan",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Napal Putih",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Pagar Din",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Kinal Jaya",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Lebong Tandai",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Gembung Raya",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Jabi (Dusun Jabi)",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Bukit Berlian",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Bukit Sari",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Napal Putih",
                "kelurahan": "Air Tenang",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Bangun Karya",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Urai",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ulok Kupai",
                "kelurahan": "Air Lelangi",
                "kodepos": "38363"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Tanjung Muara",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Tanjung Muara 1",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Sumber Mulya",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Talang Baru",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Pasar Ketahun",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Lubuk Mindai",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Marga Bakti",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Gunung Payung",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Kuala Langi/Lelangi",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Dusun Raja",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Fajar Baru",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Giri Kencana",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Bumi Harjo",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Bukit Makmur",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Bukit Harapan",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Bukit Indah",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Air Sekamanak",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Air Simpang",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Ketahun",
                "kelurahan": "Air Sebayur",
                "kodepos": "38361"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Talang Arah",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Merindu",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Negara",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Medan",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Makmur",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Maju",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Suka Baru",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Pasar Sebelat",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Pasar Baru Kota Bani",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Karya Pelita",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Karya Bakti",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Karya Jaya",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Karang Tengah",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Karang Pulau",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Cipta Mulya",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Air Putih",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Air Petai",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Air Pandan",
                "kodepos": "38326"
            },
            {
                "kecamatan": "Putri Hijau",
                "kelurahan": "Air Muring",
                "kodepos": "38326"
            }
        ],
        "k30": [
            {
                "kecamatan": "Pino",
                "kelurahan": "Ulak Lebar",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Tungkal II",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Tungkal I",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Telaga Dalam",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Tanjung Eran",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Tanjung Aur I",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Tanggo Raso",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Tanjung Aur",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Talang Padang",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Talang Tinggi",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Sukarami",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Suka Negeri",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Suka Maju",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Suka Bandung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Suka Bandung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Simpang Pino",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Serang Bulan",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Selali I",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Sebilo",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Pino Baru",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Puding",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Penandingan",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Pasar Pino",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Palak Bengkerung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Padang Tambak",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Pagar Gading",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Padang Serasan",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Padang Mumpo",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Padang Lebar",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Padang Beriang",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Napal Melintang",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Nanjungan",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Masat",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Merambung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Maras",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Lubuk Tapi",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Kota Bumi",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Kembang Seri",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Kemang Manis",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Keban Jati",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Air Nipis",
                "kelurahan": "Keban Jati",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Kayu Ajaran",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Karang Cayo",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Gedung Agung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Ganjuh",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Cinto Mandi",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Batu Panco",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Beringin Datar",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Batu Kuning",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Batu Bandung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Bandar Agung",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Bandung Ayu",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Ulu Manna",
                "kelurahan": "Air Tenam Dalam",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Air Umban",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pino",
                "kelurahan": "Anggut",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Pinoraya",
                "kelurahan": "Air Kemang",
                "kodepos": "38571"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Tanjung Negara",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Tanjung Besar",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Tanjung Nanti (Sukananti)",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Sukarami I",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Tanjung Alam",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Suka Jaya",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Suka Raja",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Penindaian",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Palak Siring",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Rantau Sialang",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Pagar Banyu",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Pajar Bulan",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Padang Bindu",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Nanti Agung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Nanjungan",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Muara Tiga Ilir",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Muara Tiga",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Limus",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Lubuk Ladung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Lubuk Resam",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Keban Agung III",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Lawang Agung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Keban Agung I",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Keban Agung II",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Karang Agung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Karang Agung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Durian Sebatang",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Bumi Agung",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Betungan",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang",
                "kelurahan": "Batu Ampar",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Kedurang Ilir",
                "kelurahan": "Air Sulau",
                "kodepos": "38553"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Tanjung Menang",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Pasar Baru",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Sindang Bulan",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Sukaraja",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Padang Siring",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Pajar Bulan",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Muara Pulutan",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Padang Lebar",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Muara Danau",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Muara Payang",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Gunung Ayu II",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Kota Agung",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Durian Seginim",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Kota Bumi Baru",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Dusun Baru",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Gunung Ayu II",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Banding Agung",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Darat Sawah Ilir (Hilir)",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Darat Sawah Ulu (Hulu)",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Babatan Ulu",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Seginim",
                "kelurahan": "Babatan Ilir",
                "kodepos": "38552"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Pasar Mulia",
                "kodepos": "38518"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "38518"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Padang Sialang",
                "kodepos": "38517"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Batu Kuning",
                "kodepos": "38518"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Ketapang Besar",
                "kodepos": "38516"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Gunung Mesir",
                "kodepos": "38517"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Belakang Gedung",
                "kodepos": "38514"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Pasar Bawah",
                "kodepos": "38515"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38513"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Kota Medan",
                "kodepos": "38513"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Gunung Ayu I",
                "kodepos": "38513"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Ibul",
                "kodepos": "38513"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Pasar Baru",
                "kodepos": "38512"
            },
            {
                "kecamatan": "Pasar Manna",
                "kelurahan": "Batu Lambang",
                "kodepos": "38513"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Tumbuk Tebing",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Tebat Kubu",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Terulung",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Tanjung Tebat",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Tanjung Raman",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Tanjung Aur",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Tanjung Besar",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Talang Indah",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Tambangan",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Padang Nibung",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Padang Pandan",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Padang Niur",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Padang Manis",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Padang Kapuk",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Padang Burnai",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Padang Jawi",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Melao",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Manggul",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Padang Berangin",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Lubuk Sirih Ilir",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Lubuk Sirih Ulu",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Ketaping",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Kota Padang",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Kuripan",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Kembang Ayun",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Kampung Baru",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Kayu Kunyit",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Jeranglah Rendah",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Jeranglah Tinggi",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Gunung Kembang",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Manna",
                "kelurahan": "Gunung Sakti",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Gindosuli",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Bunga Mas",
                "kelurahan": "Gunung Kayo",
                "kodepos": "38511"
            },
            {
                "kecamatan": "Kota Manna",
                "kelurahan": "Gelombang (Gelumbang)",
                "kodepos": "38511"
            }
        ],
        "k31": [
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Ulak Lebar",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Tanjung Heran",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Tanjung Raman",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Taba Teret",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Talang Ambung / Lambung",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Taba Penanjung",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Lubuk Unen",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Taba Durian Sebakul",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Kota Niur",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Surau",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Kelindang Atas",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Taba Baru",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Karang Tengah",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Rindu Hati",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Jambu",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Suka Rami",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Kelindang",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Pungguk Beringin",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Datar Lebar",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Pungguk Ketupat",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Bajak I",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Taba Penanjung",
                "kelurahan": "Penum",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Bajak I",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Penembang",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Tengah Padang",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Lubuk Unen Baru",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Talang Empat",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Padang Kedeper",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Taba Lagan",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Jumat",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Merigi Kelindang",
                "kelurahan": "Lubuk Unen",
                "kodepos": "38386"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Taba Pasema (Pasmah)",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Jaya Karta",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Pagar Jati",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Air Sebakul",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Pulau Panggung",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Bukit",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Lagan Bungin",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Air Putih",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Nakau",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Tumbuk",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Padang UlakTanjung",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Taba Renah / Rena",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Kembang Seri",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Talang Curup",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Talang Empat",
                "kelurahan": "Lagan",
                "kodepos": "38385"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Temiang",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Susup",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Taba Gematung",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Rena Jaya",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Rena Kandis",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Punjung",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Rajab Besi / Rajak Besi",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Pagar Jati",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Pungguk Jaya",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Pagar Agung",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Pagar Besi",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Lubuk Pendam",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Lubuk Puar",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Komering",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Layang Lekat",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Kerta Pati",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Kerta Pati Mudik",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Karang Are",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Karang Panggung",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Keroya",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Datar Penokot / Penokat",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Durian Lebar",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Bajak III",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Curup",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Merigi Sakti",
                "kelurahan": "Arga Indah II",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Ujung Karang",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Pagar Jati",
                "kelurahan": "Arga Indah I",
                "kodepos": "38383"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Rena Semanek",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Taba Mutung",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Taba Terunjam",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Plaju (Pelajau)",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Rena Lebar",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Penanding",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Pagar Gunung",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Padang Siring",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Padang Tambak",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Karang Tinggi",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Karang Nanding",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Durian Demang",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Dusun Baru II",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Kancing",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Desa Semidang",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "38379"
            },
            {
                "kecamatan": "Karang Tinggi",
                "kelurahan": "Desa Gajah Mati",
                "kodepos": "38382"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Margo Mulya",
                "kodepos": "38377"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Taba Jambu",
                "kodepos": "38378"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Dusun Baru I",
                "kodepos": "38376"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Harapan Makmur",
                "kodepos": "38376"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Pondok Kubang",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Talang Tengah",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Tanjung terdana",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Paku Haji",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Paku Haji",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Batu Raja",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Pondok Kubang",
                "kelurahan": "Dusun Anyar",
                "kodepos": "38375"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Talang Donok",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Sekayun Mudik",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Sungkai Berayun",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Tanjung Kepayang / Kepahyang",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Senabah / Senaban",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Tiambang",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Sekayun",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Talang Panjang",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Sekayun Hilir / Ilir",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Talang Tengah II",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Pematang Tiga",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Talang Donok",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Pematang Tiga Lama",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Lubuk Langkap",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Padang Berunai",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Kota Titik",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Genting Dabuk",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Kebun Lebar",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Bang Haji",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Genting",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Batu Bariang / Beriang",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Aturan Mumpo",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Aturan Mumpo II",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Bang Haji",
                "kelurahan": "Air Napal",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pematang Tiga",
                "kelurahan": "Air Kotok",
                "kodepos": "38372"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Sunda Kelapa",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Talang Buseng / Boseng",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Talang Pauh",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Sri Kuncoro",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Srikaton",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Pondok Kelapa",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Sidodadi",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Sidoreja (Sidorejo/Siderejo)",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Panca Mukti",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Pasar Pedati",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Pekik Nyaring",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Kembang Ayun",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Padang Betuah",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Bintang Selatan",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Desa Harapan",
                "kodepos": "38371"
            },
            {
                "kecamatan": "Pondok Kelapa",
                "kelurahan": "Abu Sakim",
                "kodepos": "38371"
            }
        ],
        "k32": [
            {
                "kecamatan": "Gading Cempaka",
                "kelurahan": "Sido Mulyo",
                "kodepos": "38229"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Sawah Lebar",
                "kodepos": "38228"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Dusun Besar",
                "kodepos": "38229"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Padang Jati",
                "kodepos": "38227"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Kebun Tebeng",
                "kodepos": "38227"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Panorama",
                "kodepos": "38226"
            },
            {
                "kecamatan": "Gading Cempaka",
                "kelurahan": "Padang Harapan",
                "kodepos": "38225"
            },
            {
                "kecamatan": "Gading Cempaka",
                "kelurahan": "Jalan Gedang",
                "kodepos": "38225"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Tanah Patah",
                "kodepos": "38224"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Nusa Indah",
                "kodepos": "38224"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Jembatan Kecil",
                "kodepos": "38224"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Penurunan",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Sawah Lebar Baru",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Lempuing",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Kebun Kenanga",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Agung",
                "kelurahan": "Kebun Beler",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Anggut Bawah",
                "kodepos": "38223"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Kebun Geran (Gerand)",
                "kodepos": "38222"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Anggut Atas",
                "kodepos": "38222"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Anggut Dalam",
                "kodepos": "38222"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Timur Indah",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Penggantungan",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Padang Nangka",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Singaran Pati",
                "kelurahan": "Lingkar Timur",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Gading Cempaka",
                "kelurahan": "Lingkar Barat",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Gading Cempaka",
                "kelurahan": "Cempaka Permai",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Kebun Dahri",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Ratu Samban",
                "kelurahan": "Belakang Pondok",
                "kodepos": "38221"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Sukarami",
                "kodepos": "38216"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Sumur Dewa",
                "kodepos": "38216"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Kandang",
                "kodepos": "38216"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Bumi Ayu",
                "kodepos": "38216"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Padang Serai",
                "kodepos": "38215"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Sumber Jaya",
                "kodepos": "38215"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Teluk Sepang",
                "kodepos": "38215"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Kandang Mas",
                "kodepos": "38215"
            },
            {
                "kecamatan": "Kampung Melayu",
                "kelurahan": "Muara Dua",
                "kodepos": "38215"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Pekan Sabtu",
                "kodepos": "38213"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Betungan",
                "kodepos": "38214"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Bentiring",
                "kodepos": "38126"
            },
            {
                "kecamatan": "Selebar",
                "kelurahan": "Pagar Dewa",
                "kodepos": "38211"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Kandang Limun",
                "kodepos": "38122"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Pematang Gubernur",
                "kodepos": "38125"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Beringin Raya",
                "kodepos": "38121"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Rawa Makmur",
                "kodepos": "38121"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Rawa Makmur Permai",
                "kodepos": "38121"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Muara Bangka Hulu",
                "kelurahan": "Bentiring Permai",
                "kodepos": "38121"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Tanjung Agung",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Semarang",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Suka Merindu",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Surabaya",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Malabero/Malabro",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Pasar Bengkulu",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Kampung Bali",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Sungai Serut",
                "kelurahan": "Kampung Kelawi/Klawi",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Tengah Padang",
                "kodepos": "38118"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Jitra (Pasar Jitra)",
                "kodepos": "38119"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Kebun Roos/Ros",
                "kodepos": "38117"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Pondok Besi",
                "kodepos": "38117"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Bajak (Kampung Teleng)",
                "kodepos": "38118"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Kebun Keling",
                "kodepos": "38116"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Pintu Batu",
                "kodepos": "38115"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Pasar Baru",
                "kodepos": "38114"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Pasar Melintang",
                "kodepos": "38115"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Berkas",
                "kodepos": "38114"
            },
            {
                "kecamatan": "Teluk Segara",
                "kelurahan": "Sumur Meleleh",
                "kodepos": "38113"
            }
        ],
        "k33": [
            {
                "kecamatan": "Semanu",
                "kelurahan": "Pacarejo",
                "kodepos": "55893"
            },
            {
                "kecamatan": "Semanu",
                "kelurahan": "Semanu",
                "kodepos": "55893"
            },
            {
                "kecamatan": "Semanu",
                "kelurahan": "Dadapayu",
                "kodepos": "55893"
            },
            {
                "kecamatan": "Semanu",
                "kelurahan": "Ngeposari",
                "kodepos": "55893"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Umbul Rejo",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Semanu",
                "kelurahan": "Candirejo",
                "kodepos": "55893"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Sumber Giri",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Tambakromo",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Ponjong",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Sawahan",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Sidorejo",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Karang Asem",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Kenteng",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Bedoyo",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Genjahan",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Ponjong",
                "kelurahan": "Gombang",
                "kodepos": "55892"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Ngipak",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Wiladeg",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Karangmojo",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Kelor",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Ngawis",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Gedang Rejo",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Jati Ayu",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Tileng",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Bejiharjo",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Karangmojo",
                "kelurahan": "Bendungan",
                "kodepos": "55891"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Songbanyu",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Pucanganom",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Semugih",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Pucung",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Petir",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Pringombo",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Karangwuni",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Melikan",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Nglindur",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Jepitu",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Jerukwudel",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Karangawen",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Bohol",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Rongkop",
                "kelurahan": "Botodayakan (Botodayaan)",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Tepus",
                "kelurahan": "Tepus",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Girisubo",
                "kelurahan": "Balong",
                "kodepos": "55883"
            },
            {
                "kecamatan": "Tepus",
                "kelurahan": "Sidoharjo",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tepus",
                "kelurahan": "Sumber Wungu",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Ngestirejo",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tepus",
                "kelurahan": "Purwodadi",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Kemadang",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Kemiri",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Hargosari",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tepus",
                "kelurahan": "Giripanggung",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Banjarejo",
                "kodepos": "55881"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Giritirto",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Girisuko",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Giriwungu",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Giripurwo",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Girisekar",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Girijati",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Girikarto",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Girimulyo",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Panggang",
                "kelurahan": "Giriharjo",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Giriasih",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Giricahyo",
                "kodepos": "55872"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Planjan",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Sodo",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Nglora",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Mulusan",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Pampang",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Krambil Sawit",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Monggol",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Karang Asem",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Karang Duwet",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Kepek",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Kanigoro",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Grogol",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Sapto Sari",
                "kelurahan": "Jetis",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Paliyan",
                "kelurahan": "Giring",
                "kodepos": "55871"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Serut",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Tegalrejo",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Watu Gajah",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Sampang",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Ngalang",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Sampang",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Terbah",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Gedang Sari",
                "kelurahan": "Hargo Mulyo",
                "kodepos": "55863"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Salam",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Semoyo",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Putat",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Pengkok",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Nglegi",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Ngoro Oro",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Patuk",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Bunder",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Nglanggeran",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Plembutan",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Patuk",
                "kelurahan": "Beji",
                "kodepos": "55862"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Playen",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Ngawu",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Ngleri",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Ngunut",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Logandeng",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Dengok",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Gading",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Getas",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Banyusoco",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Bleberan",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Banaran",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Playen",
                "kelurahan": "Bandung",
                "kodepos": "55861"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Semin",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Sumberrejo",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Kemejing",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Pundung Sari",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Rejosari",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Kalitekuk",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Karang Sari",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Bulurejo",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Candi Rejo",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Semin",
                "kelurahan": "Bendung",
                "kodepos": "55854"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Tancep",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Watu Sigar",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Jurang Jero",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Kampung",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sambirejo",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Pilang Rejo",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Beji",
                "kodepos": "55853"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Nglipar",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Pengkol",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Kedung Keris",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Kedungpoh",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Natah",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Nglipar",
                "kelurahan": "Katongan",
                "kodepos": "55852"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wunung",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wareng",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wonosari",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Pulutan",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Selang",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Siraman",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Mulo",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Piyaman",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Gari",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Karang Rejek",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Karang Tengah",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Duwet",
                "kodepos": "55851"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Kepek",
                "kodepos": "55813"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Baleharjo",
                "kodepos": "55811"
            }
        ],
        "k34": [
            {
                "kecamatan": "Piyungan",
                "kelurahan": "Srimartani",
                "kodepos": "55792"
            },
            {
                "kecamatan": "Piyungan",
                "kelurahan": "Srimulyo",
                "kodepos": "55792"
            },
            {
                "kecamatan": "Piyungan",
                "kelurahan": "Sitimulyo",
                "kodepos": "55792"
            },
            {
                "kecamatan": "Pleret",
                "kelurahan": "Wonolelo",
                "kodepos": "55791"
            },
            {
                "kecamatan": "Pleret",
                "kelurahan": "Segoroyoso",
                "kodepos": "55791"
            },
            {
                "kecamatan": "Pleret",
                "kelurahan": "Wonokromo",
                "kodepos": "55791"
            },
            {
                "kecamatan": "Pleret",
                "kelurahan": "Pleret",
                "kodepos": "55791"
            },
            {
                "kecamatan": "Pleret",
                "kelurahan": "Bawuran",
                "kodepos": "55791"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Terong",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Temuwuh",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Mangunan",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Muntuk",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Jatimulyo",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Dlingo",
                "kelurahan": "Dlingo",
                "kodepos": "55783"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Wukirsari",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Sriharjo",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Selopamioro",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Kebon Agung",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Karangtalun",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Imogiri",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Karang Tengah",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Imogiri",
                "kelurahan": "Girirejo",
                "kodepos": "55782"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Trimulyo",
                "kodepos": "55781"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Patalan",
                "kodepos": "55781"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Sumber Agung",
                "kodepos": "55781"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Canden",
                "kodepos": "55781"
            },
            {
                "kecamatan": "Kretek",
                "kelurahan": "Tirtosari",
                "kodepos": "55772"
            },
            {
                "kecamatan": "Kretek",
                "kelurahan": "Tirtomulyo",
                "kodepos": "55772"
            },
            {
                "kecamatan": "Kretek",
                "kelurahan": "Tirtohargo",
                "kodepos": "55772"
            },
            {
                "kecamatan": "Kretek",
                "kelurahan": "Parangtritis",
                "kodepos": "55772"
            },
            {
                "kecamatan": "Kretek",
                "kelurahan": "Donotirto",
                "kodepos": "55772"
            },
            {
                "kecamatan": "Pundong",
                "kelurahan": "Srihardono",
                "kodepos": "55771"
            },
            {
                "kecamatan": "Pundong",
                "kelurahan": "Seloharjo",
                "kodepos": "55771"
            },
            {
                "kecamatan": "Pundong",
                "kelurahan": "Panjangrejo",
                "kodepos": "55771"
            },
            {
                "kecamatan": "Bambang Lipuro",
                "kelurahan": "Sumbermulyo",
                "kodepos": "55764"
            },
            {
                "kecamatan": "Bambang Lipuro",
                "kelurahan": "Sidomulyo",
                "kodepos": "55764"
            },
            {
                "kecamatan": "Bambang Lipuro",
                "kelurahan": "Mulyodadi",
                "kodepos": "55764"
            },
            {
                "kecamatan": "Sanden",
                "kelurahan": "Srigading",
                "kodepos": "55763"
            },
            {
                "kecamatan": "Sanden",
                "kelurahan": "Murtigading",
                "kodepos": "55763"
            },
            {
                "kecamatan": "Sanden",
                "kelurahan": "Gadingsari",
                "kodepos": "55763"
            },
            {
                "kecamatan": "Sanden",
                "kelurahan": "Gadingharjo",
                "kodepos": "55763"
            },
            {
                "kecamatan": "Srandakan",
                "kelurahan": "Trimurti",
                "kodepos": "55762"
            },
            {
                "kecamatan": "Srandakan",
                "kelurahan": "Poncosari",
                "kodepos": "55762"
            },
            {
                "kecamatan": "Pandak",
                "kelurahan": "Wijirejo",
                "kodepos": "55761"
            },
            {
                "kecamatan": "Pandak",
                "kelurahan": "Triharjo",
                "kodepos": "55761"
            },
            {
                "kecamatan": "Pandak",
                "kelurahan": "Caturharjo",
                "kodepos": "55761"
            },
            {
                "kecamatan": "Pandak",
                "kelurahan": "Gilangharjo",
                "kodepos": "55761"
            },
            {
                "kecamatan": "Sedayu",
                "kelurahan": "Argosari",
                "kodepos": "55752"
            },
            {
                "kecamatan": "Sedayu",
                "kelurahan": "Argorejo",
                "kodepos": "55752"
            },
            {
                "kecamatan": "Sedayu",
                "kelurahan": "Argodadi",
                "kodepos": "55752"
            },
            {
                "kecamatan": "Sedayu",
                "kelurahan": "Argomulyo",
                "kodepos": "55752"
            },
            {
                "kecamatan": "Pajangan",
                "kelurahan": "Sendangsari",
                "kodepos": "55751"
            },
            {
                "kecamatan": "Pajangan",
                "kelurahan": "Triwidadi",
                "kodepos": "55751"
            },
            {
                "kecamatan": "Bantul",
                "kelurahan": "Sabdodadi",
                "kodepos": "55715"
            },
            {
                "kecamatan": "Pajangan",
                "kelurahan": "Guwosari",
                "kodepos": "55751"
            },
            {
                "kecamatan": "Bantul",
                "kelurahan": "Palbapang",
                "kodepos": "55713"
            },
            {
                "kecamatan": "Bantul",
                "kelurahan": "Trirenggo",
                "kodepos": "55714"
            },
            {
                "kecamatan": "Bantul",
                "kelurahan": "Ringin Harjo",
                "kodepos": "55712"
            },
            {
                "kecamatan": "Bantul",
                "kelurahan": "Bantul",
                "kodepos": "55711"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Baturetno",
                "kodepos": "55197"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Banguntapan",
                "kodepos": "55198"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Potorono",
                "kodepos": "55196"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Wirokerten",
                "kodepos": "55194"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Jambidan",
                "kodepos": "55195"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Jagalan",
                "kodepos": "55192"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Singosaren",
                "kodepos": "55193"
            },
            {
                "kecamatan": "Banguntapan",
                "kelurahan": "Tamanan",
                "kodepos": "55191"
            },
            {
                "kecamatan": "Sewon",
                "kelurahan": "Bangunharjo",
                "kodepos": "55188"
            },
            {
                "kecamatan": "Sewon",
                "kelurahan": "Panggungharjo",
                "kodepos": "55188"
            },
            {
                "kecamatan": "Sewon",
                "kelurahan": "Timbulharjo",
                "kodepos": "55186"
            },
            {
                "kecamatan": "Kasihan",
                "kelurahan": "Tamantirto",
                "kodepos": "55183"
            },
            {
                "kecamatan": "Kasihan",
                "kelurahan": "Bangunjiwo",
                "kodepos": "55184"
            },
            {
                "kecamatan": "Sewon",
                "kelurahan": "Pendowoharjo",
                "kodepos": "55185"
            },
            {
                "kecamatan": "Kasihan",
                "kelurahan": "Tirtonirmolo",
                "kodepos": "55181"
            },
            {
                "kecamatan": "Kasihan",
                "kelurahan": "Ngestiharjo",
                "kodepos": "55182"
            }
        ],
        "k35": [
            {
                "kecamatan": "Girimulyo",
                "kelurahan": "Purwosari",
                "kodepos": "55674"
            },
            {
                "kecamatan": "Girimulyo",
                "kelurahan": "Pendoworejo",
                "kodepos": "55674"
            },
            {
                "kecamatan": "Girimulyo",
                "kelurahan": "Giripurwo",
                "kodepos": "55674"
            },
            {
                "kecamatan": "Girimulyo",
                "kelurahan": "Jatimulyo",
                "kodepos": "55674"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Purwoharjo",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Sidoharjo",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Ngargosari",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Pagerharjo",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Gerbosari",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Kebon Harjo",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Banjaroyo",
                "kodepos": "55672"
            },
            {
                "kecamatan": "Samigaluh",
                "kelurahan": "Banjarsari",
                "kodepos": "55673"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Banjarasri",
                "kodepos": "55672"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Banjarharjo",
                "kodepos": "55672"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Banjararum",
                "kodepos": "55672"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Wijimulyo",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Kembang",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Tanjungharjo",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Donomulyo",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Jati Sarono",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Tuksono",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Nanggulan",
                "kelurahan": "Banyuroto",
                "kodepos": "55671"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Sukoreno",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Sentolo",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Srikayangan",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Kaliagung",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Salamrejo",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Demangrejo",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Sentolo",
                "kelurahan": "Banguncipto",
                "kodepos": "55664"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Sidorejo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Wahyuharjo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Gulurejo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Jatirejo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Ngentakrejo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Tirta Rahayu",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Lendah",
                "kelurahan": "Bumirejo",
                "kodepos": "55663"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Pandowan",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Karang Sewu",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Kranggan",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Nomporejo",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Brosot",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Tayuban",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Galur",
                "kelurahan": "Banaran",
                "kodepos": "55661"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Pleret",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Panjatan",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Gotakan",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Kanoman",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Krembangan",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Depok",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Garongan",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Bugel",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Cerme",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Panjatan",
                "kelurahan": "Bojong",
                "kodepos": "55655"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Temon Kulon",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Temon Wetan",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Plumbon",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Sindutan",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Kedundang",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Kulur",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Palihan",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Karang Wuluh",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Kebonrejo",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Kali Dengen",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Kaligintung",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Glagah",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Jangkaran",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Janten",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Kokap",
                "kelurahan": "Kalirejo",
                "kodepos": "55653"
            },
            {
                "kecamatan": "Temon",
                "kelurahan": "Demen",
                "kodepos": "55654"
            },
            {
                "kecamatan": "Kokap",
                "kelurahan": "Hargotirto",
                "kodepos": "55653"
            },
            {
                "kecamatan": "Kokap",
                "kelurahan": "Hargowilis",
                "kodepos": "55653"
            },
            {
                "kecamatan": "Kokap",
                "kelurahan": "Hargomulyo",
                "kodepos": "55653"
            },
            {
                "kecamatan": "Kokap",
                "kelurahan": "Hargorejo",
                "kodepos": "55653"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Sidomulyo",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Tawangsari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Sendangsari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Margosari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Sendangsari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Karangsari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Pengasih",
                "kelurahan": "Kedungsari",
                "kodepos": "55652"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Triharjo",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Wates",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Ngestiharjo",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Sogan",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Karang Wuni",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Kulwaru",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Giri Peni",
                "kodepos": "55651"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Bendungan",
                "kodepos": "55651"
            }
        ],
        "k36": [
            {
                "kecamatan": "Gamping",
                "kelurahan": "Nogotirto",
                "kodepos": "55592"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Widodo Martani",
                "kodepos": "55584"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Umbulmartani",
                "kodepos": "55584"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Wedomartani",
                "kodepos": "55584"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Bimo Martani",
                "kodepos": "55584"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Sindumartani",
                "kodepos": "55584"
            },
            {
                "kecamatan": "Cangkringan",
                "kelurahan": "Umbul Harjo",
                "kodepos": "55583"
            },
            {
                "kecamatan": "Cangkringan",
                "kelurahan": "Wukir Sari",
                "kodepos": "55583"
            },
            {
                "kecamatan": "Cangkringan",
                "kelurahan": "Kepuh Harjo",
                "kodepos": "55583"
            },
            {
                "kecamatan": "Cangkringan",
                "kelurahan": "Argo Mulyo",
                "kodepos": "55583"
            },
            {
                "kecamatan": "Cangkringan",
                "kelurahan": "Glagah Harjo",
                "kodepos": "55583"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Pakem Binangun",
                "kodepos": "55582"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Purwo Binangun",
                "kodepos": "55582"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Hargo Binangun",
                "kodepos": "55582"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Harjo Binangun",
                "kodepos": "55582"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Candi Binangun",
                "kodepos": "55582"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Sinduharjo",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Suko Harjo",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Sari Harjo",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Donoharjo",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Minomartani",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Ngaglik",
                "kelurahan": "Sardonoharjo",
                "kodepos": "55581"
            },
            {
                "kecamatan": "Berbah",
                "kelurahan": "Tegal Tirto",
                "kodepos": "55573"
            },
            {
                "kecamatan": "Berbah",
                "kelurahan": "Jogo Tirto",
                "kodepos": "55573"
            },
            {
                "kecamatan": "Berbah",
                "kelurahan": "Kali Tirto",
                "kodepos": "55573"
            },
            {
                "kecamatan": "Berbah",
                "kelurahan": "Sendang Tirto",
                "kodepos": "55573"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Wukir Harjo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Sambi Rejo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Sumber Harjo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Boko Harjo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Gayam Harjo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Madu Rejo",
                "kodepos": "55572"
            },
            {
                "kecamatan": "Kalasan",
                "kelurahan": "Tirto Martani",
                "kodepos": "55571"
            },
            {
                "kecamatan": "Kalasan",
                "kelurahan": "Purwo Martani",
                "kodepos": "55571"
            },
            {
                "kecamatan": "Kalasan",
                "kelurahan": "Selo Martani",
                "kodepos": "55571"
            },
            {
                "kecamatan": "Kalasan",
                "kelurahan": "Taman Martani",
                "kodepos": "55571"
            },
            {
                "kecamatan": "Moyudan",
                "kelurahan": "Sumbersari",
                "kodepos": "55563"
            },
            {
                "kecamatan": "Moyudan",
                "kelurahan": "Sumberarum",
                "kodepos": "55563"
            },
            {
                "kecamatan": "Moyudan",
                "kelurahan": "Sumberrahayu",
                "kodepos": "55563"
            },
            {
                "kecamatan": "Moyudan",
                "kelurahan": "Sumber Agung",
                "kodepos": "55563"
            },
            {
                "kecamatan": "Minggir",
                "kelurahan": "Sendang Rejo",
                "kodepos": "55562"
            },
            {
                "kecamatan": "Minggir",
                "kelurahan": "Sendangagung",
                "kodepos": "55562"
            },
            {
                "kecamatan": "Minggir",
                "kelurahan": "Sendangsari",
                "kodepos": "55562"
            },
            {
                "kecamatan": "Minggir",
                "kelurahan": "Sendang Arum",
                "kodepos": "55562"
            },
            {
                "kecamatan": "Minggir",
                "kelurahan": "Sendang Mulyo",
                "kodepos": "55562"
            },
            {
                "kecamatan": "Seyegan",
                "kelurahan": "Margoluwih",
                "kodepos": "55561"
            },
            {
                "kecamatan": "Seyegan",
                "kelurahan": "Margomulyo",
                "kodepos": "55561"
            },
            {
                "kecamatan": "Seyegan",
                "kelurahan": "Margoagung",
                "kodepos": "55561"
            },
            {
                "kecamatan": "Seyegan",
                "kelurahan": "Margodadi",
                "kodepos": "55561"
            },
            {
                "kecamatan": "Seyegan",
                "kelurahan": "Margokaton",
                "kodepos": "55561"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Sumber Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Tambak Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Moro Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Pondok Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Margo Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Merdiko Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Lumbung Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Tempel",
                "kelurahan": "Banyu Rejo",
                "kodepos": "55552"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Wono Kerto",
                "kodepos": "55551"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Bangun Kerto",
                "kodepos": "55551"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Donokerto",
                "kodepos": "55551"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Giri Kerto",
                "kodepos": "55551"
            },
            {
                "kecamatan": "Sleman",
                "kelurahan": "Catur Harjo",
                "kodepos": "55515"
            },
            {
                "kecamatan": "Sleman",
                "kelurahan": "Tri Mulyo",
                "kodepos": "55513"
            },
            {
                "kecamatan": "Sleman",
                "kelurahan": "Triharjo",
                "kodepos": "55514"
            },
            {
                "kecamatan": "Sleman",
                "kelurahan": "Tridadi",
                "kodepos": "55511"
            },
            {
                "kecamatan": "Sleman",
                "kelurahan": "Pandowo Harjo",
                "kodepos": "55512"
            },
            {
                "kecamatan": "Gamping",
                "kelurahan": "Balecatur",
                "kodepos": "55295"
            },
            {
                "kecamatan": "Gamping",
                "kelurahan": "Trihanggo",
                "kodepos": "55291"
            },
            {
                "kecamatan": "Gamping",
                "kelurahan": "Banyuraden",
                "kodepos": "55293"
            },
            {
                "kecamatan": "Gamping",
                "kelurahan": "Ambarketawang",
                "kodepos": "55294"
            },
            {
                "kecamatan": "Mlati",
                "kelurahan": "Sumberadi",
                "kodepos": "55288"
            },
            {
                "kecamatan": "Mlati",
                "kelurahan": "Tlogoadi",
                "kodepos": "55286"
            },
            {
                "kecamatan": "Mlati",
                "kelurahan": "Tirtoadi",
                "kodepos": "55287"
            },
            {
                "kecamatan": "Mlati",
                "kelurahan": "Sinduadi",
                "kodepos": "55284"
            },
            {
                "kecamatan": "Mlati",
                "kelurahan": "Sendangadi",
                "kodepos": "55285"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Maguwoharjo",
                "kodepos": "55282"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Condong Catur",
                "kodepos": "55283"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Catur Tunggal",
                "kodepos": "55281"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidomoyo",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidomulyo",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidorejo",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidokarto",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidoluhur",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidoagung",
                "kodepos": "55264"
            },
            {
                "kecamatan": "Godean",
                "kelurahan": "Sidoarum",
                "kodepos": "55264"
            }
        ],
        "k37": [
            {
                "kecamatan": "Gedong Tengen",
                "kelurahan": "Sosromenduran",
                "kodepos": "55271"
            },
            {
                "kecamatan": "Gedong Tengen",
                "kelurahan": "Pringgokusuman",
                "kodepos": "55272"
            },
            {
                "kecamatan": "Ngampilan",
                "kelurahan": "Notoprajan",
                "kodepos": "55262"
            },
            {
                "kecamatan": "Wirobrajan",
                "kelurahan": "Pakuncen",
                "kodepos": "55253"
            },
            {
                "kecamatan": "Ngampilan",
                "kelurahan": "Ngampilan",
                "kodepos": "55261"
            },
            {
                "kecamatan": "Wirobrajan",
                "kelurahan": "Patangpuluhan",
                "kodepos": "55251"
            },
            {
                "kecamatan": "Wirobrajan",
                "kelurahan": "Wirobrajan",
                "kodepos": "55252"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Bener",
                "kodepos": "55243"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Tegalrejo",
                "kodepos": "55244"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Karangwaru",
                "kodepos": "55241"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Kricak",
                "kodepos": "55242"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Cokrodiningratan",
                "kodepos": "55233"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Gowongan",
                "kodepos": "55232"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Bumijo",
                "kodepos": "55231"
            },
            {
                "kecamatan": "Gondokusuman",
                "kelurahan": "Kotabaru",
                "kodepos": "55224"
            },
            {
                "kecamatan": "Gondokusuman",
                "kelurahan": "Baciro",
                "kodepos": "55225"
            },
            {
                "kecamatan": "Gondokusuman",
                "kelurahan": "Demangan",
                "kodepos": "55221"
            },
            {
                "kecamatan": "Gondokusuman",
                "kelurahan": "Klitren",
                "kodepos": "55222"
            },
            {
                "kecamatan": "Gondokusuman",
                "kelurahan": "Terban",
                "kodepos": "55223"
            },
            {
                "kecamatan": "Danurejan",
                "kelurahan": "Suryatmajan",
                "kodepos": "55213"
            },
            {
                "kecamatan": "Danurejan",
                "kelurahan": "Bausasran",
                "kodepos": "55211"
            },
            {
                "kecamatan": "Danurejan",
                "kelurahan": "Tegal Panggung",
                "kodepos": "55212"
            },
            {
                "kecamatan": "Kotagede",
                "kelurahan": "Purbayan",
                "kodepos": "55173"
            },
            {
                "kecamatan": "Kotagede",
                "kelurahan": "Rejowinangun",
                "kodepos": "55171"
            },
            {
                "kecamatan": "Kotagede",
                "kelurahan": "Prenggan",
                "kodepos": "55172"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Semaki",
                "kodepos": "55166"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Tahunan",
                "kodepos": "55167"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Giwangan",
                "kodepos": "55163"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Warungboto",
                "kodepos": "55164"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Muja Muju",
                "kodepos": "55165"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Pandeyan",
                "kodepos": "55161"
            },
            {
                "kecamatan": "Umbulharjo",
                "kelurahan": "Sorosutan",
                "kodepos": "55162"
            },
            {
                "kecamatan": "Mergangsan",
                "kelurahan": "Brontokusuman",
                "kodepos": "55153"
            },
            {
                "kecamatan": "Mergangsan",
                "kelurahan": "Wirogunan",
                "kodepos": "55151"
            },
            {
                "kecamatan": "Mergangsan",
                "kelurahan": "Keparakan",
                "kodepos": "55152"
            },
            {
                "kecamatan": "Mantrijeron",
                "kelurahan": "Gedongkiwo",
                "kodepos": "55142"
            },
            {
                "kecamatan": "Mantrijeron",
                "kelurahan": "Mantrijeron",
                "kodepos": "55143"
            },
            {
                "kecamatan": "Mantrijeron",
                "kelurahan": "Suryodiningratan",
                "kodepos": "55141"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Kadipaten",
                "kodepos": "55132"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Patehan",
                "kodepos": "55133"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Panembahan",
                "kodepos": "55131"
            },
            {
                "kecamatan": "Gondomanan",
                "kelurahan": "Ngupasan",
                "kodepos": "55122"
            },
            {
                "kecamatan": "Gondomanan",
                "kelurahan": "Prawirodirjan",
                "kodepos": "55121"
            },
            {
                "kecamatan": "Pakualaman",
                "kelurahan": "Gunung Ketur",
                "kodepos": "55111"
            },
            {
                "kecamatan": "Pakualaman",
                "kelurahan": "Purwo Kinanti",
                "kodepos": "55112"
            }
        ],
        "k38": [
            {
                "kecamatan": "Kepulauan Seribu Utara",
                "kelurahan": "Pulau Harapan",
                "kodepos": "14540"
            },
            {
                "kecamatan": "Kepulauan Seribu Utara",
                "kelurahan": "Pulau Kelapa",
                "kodepos": "14540"
            },
            {
                "kecamatan": "Kepulauan Seribu Selatan",
                "kelurahan": "Pulau Tidung",
                "kodepos": "14520"
            },
            {
                "kecamatan": "Kepulauan Seribu Utara",
                "kelurahan": "Pulau Panggang",
                "kodepos": "14530"
            },
            {
                "kecamatan": "Kepulauan Seribu Selatan",
                "kelurahan": "Pulau Untung Jawa",
                "kodepos": "14510"
            },
            {
                "kecamatan": "Kepulauan Seribu Selatan",
                "kelurahan": "Pulau Pari",
                "kodepos": "14520"
            }
        ],
        "k39": [
            {
                "kecamatan": "Penjaringan",
                "kelurahan": "Kamal Muara",
                "kodepos": "14470"
            },
            {
                "kecamatan": "Penjaringan",
                "kelurahan": "Pluit",
                "kodepos": "14450"
            },
            {
                "kecamatan": "Penjaringan",
                "kelurahan": "Kapuk Muara",
                "kodepos": "14460"
            },
            {
                "kecamatan": "Penjaringan",
                "kelurahan": "Pejagalan",
                "kodepos": "14450"
            },
            {
                "kecamatan": "Pademangan",
                "kelurahan": "Ancol",
                "kodepos": "14430"
            },
            {
                "kecamatan": "Penjaringan",
                "kelurahan": "Penjaringan",
                "kodepos": "14440"
            },
            {
                "kecamatan": "Pademangan",
                "kelurahan": "Pademangan Timur",
                "kodepos": "14410"
            },
            {
                "kecamatan": "Pademangan",
                "kelurahan": "Pademangan Barat",
                "kodepos": "14420"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Sunter Jaya",
                "kodepos": "14360"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Warakas",
                "kodepos": "14370"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Papanggo",
                "kodepos": "14340"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Sunter Agung",
                "kodepos": "14350"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Sungai Bambu",
                "kodepos": "14330"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Tanjung Priok",
                "kodepos": "14310"
            },
            {
                "kecamatan": "Tanjung Priok",
                "kelurahan": "Kebon Bawang",
                "kodepos": "14320"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Lagoa",
                "kodepos": "14270"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Tugu Utara",
                "kodepos": "14260"
            },
            {
                "kecamatan": "Kelapa Gading",
                "kelurahan": "Kelapa Gading Timur",
                "kodepos": "14240"
            },
            {
                "kecamatan": "Kelapa Gading",
                "kelurahan": "Pegangsaan Dua",
                "kodepos": "14250"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Tugu Selatan",
                "kodepos": "14260"
            },
            {
                "kecamatan": "Kelapa Gading",
                "kelurahan": "Kelapa Gading Barat",
                "kodepos": "14240"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Rawa Badak Selatan",
                "kodepos": "14230"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Rawa Badak Utara",
                "kodepos": "14230"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Marunda",
                "kodepos": "14150"
            },
            {
                "kecamatan": "Koja",
                "kelurahan": "Koja (Utara-Selatan)",
                "kodepos": "14210"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Sukapura",
                "kodepos": "14140"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Semper Barat",
                "kodepos": "14130"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Semper Timur",
                "kodepos": "14130"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Rorotan",
                "kodepos": "14140"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Cilincing",
                "kodepos": "14120"
            },
            {
                "kecamatan": "Cilincing",
                "kelurahan": "Kali Baru",
                "kodepos": "14110"
            }
        ],
        "k40": [
            {
                "kecamatan": "Cakung",
                "kelurahan": "Ujung Menteng",
                "kodepos": "13960"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Pulo Gebang",
                "kodepos": "13950"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Jatinegara",
                "kodepos": "13930"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Penggilingan",
                "kodepos": "13940"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Cakung Timur",
                "kodepos": "13910"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Rawa Terate",
                "kodepos": "13920"
            },
            {
                "kecamatan": "Cakung",
                "kelurahan": "Cakung Barat",
                "kodepos": "13910"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Bambu Apus",
                "kodepos": "13890"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Cilangkap",
                "kodepos": "13870"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Setu",
                "kodepos": "13880"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Munjul",
                "kodepos": "13850"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Pondok Rangon",
                "kodepos": "13860"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Cipayung",
                "kodepos": "13840"
            },
            {
                "kecamatan": "Ciracas",
                "kelurahan": "Rambutan",
                "kodepos": "13830"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Ceger",
                "kodepos": "13820"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Lubang Buaya",
                "kodepos": "13810"
            },
            {
                "kecamatan": "Pasar Rebo",
                "kelurahan": "Kalisari",
                "kodepos": "13790"
            },
            {
                "kecamatan": "Pasar Rebo",
                "kelurahan": "Baru",
                "kodepos": "13780"
            },
            {
                "kecamatan": "Pasar Rebo",
                "kelurahan": "Cijantung",
                "kodepos": "13770"
            },
            {
                "kecamatan": "Pasar Rebo",
                "kelurahan": "Gedong",
                "kodepos": "13760"
            },
            {
                "kecamatan": "Ciracas",
                "kelurahan": "Susukan",
                "kodepos": "13750"
            },
            {
                "kecamatan": "Ciracas",
                "kelurahan": "Ciracas",
                "kodepos": "13740"
            },
            {
                "kecamatan": "Ciracas",
                "kelurahan": "Kelapa Dua Wetan",
                "kodepos": "13730"
            },
            {
                "kecamatan": "Ciracas",
                "kelurahan": "Cibubur",
                "kodepos": "13720"
            },
            {
                "kecamatan": "Pasar Rebo",
                "kelurahan": "Pekayon",
                "kodepos": "13710"
            },
            {
                "kecamatan": "Makasar",
                "kelurahan": "Kebon Pala",
                "kodepos": "13650"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Cililitan",
                "kodepos": "13640"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Cawang",
                "kodepos": "13630"
            },
            {
                "kecamatan": "Makasar",
                "kelurahan": "Cipinang Melayu",
                "kodepos": "13620"
            },
            {
                "kecamatan": "Makasar",
                "kelurahan": "Halim Perdana Kusumah",
                "kodepos": "13610"
            },
            {
                "kecamatan": "Makasar",
                "kelurahan": "Makasar",
                "kodepos": "13570"
            },
            {
                "kecamatan": "Makasar",
                "kelurahan": "Pinang Ranti",
                "kodepos": "13560"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Dukuh",
                "kodepos": "13550"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Kampung Tengah",
                "kodepos": "13540"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Balekambang",
                "kodepos": "13530"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Kramat Jati",
                "kodepos": "13510"
            },
            {
                "kecamatan": "Kramat Jati",
                "kelurahan": "Batuampar",
                "kodepos": "13520"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Pondok Kopi",
                "kodepos": "13460"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Klender",
                "kodepos": "13470"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Pondok Kelapa",
                "kodepos": "13450"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Malaka Jaya",
                "kodepos": "13460"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Malaka Sari",
                "kodepos": "13460"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Duren Sawit",
                "kodepos": "13440"
            },
            {
                "kecamatan": "Duren Sawit",
                "kelurahan": "Pondok Bambu",
                "kodepos": "13430"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Cipinang Muara",
                "kodepos": "13420"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Cipinang Besar Selatan",
                "kodepos": "13410"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Cipinang Besar Utara",
                "kodepos": "13410"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Rawa Bunga",
                "kodepos": "13350"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Kampung Melayu",
                "kodepos": "13320"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Bidaracina",
                "kodepos": "13330"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Cipinang Cempedak",
                "kodepos": "13340"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Bali Mester",
                "kodepos": "13310"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Jatinegara Kaum",
                "kodepos": "13250"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Pulo Gadung",
                "kodepos": "13260"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Cipinang",
                "kodepos": "13240"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Rawamangun",
                "kodepos": "13220"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Pisangan Timur",
                "kodepos": "13230"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Kayu Putih",
                "kodepos": "13210"
            },
            {
                "kecamatan": "Pulo Gadung",
                "kelurahan": "Jati",
                "kodepos": "13220"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Pal Meriam",
                "kodepos": "13140"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Kebon Manggis",
                "kodepos": "13150"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Utan Kayu Utara",
                "kodepos": "13120"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Kayu Manis",
                "kodepos": "13130"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Pisangan Baru",
                "kodepos": "13110"
            },
            {
                "kecamatan": "Matraman",
                "kelurahan": "Utan Kayu Selatan",
                "kodepos": "13120"
            }
        ],
        "k41": [
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Pasar Manggis",
                "kodepos": "12970"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Guntur",
                "kodepos": "12980"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Kuningan Timur",
                "kodepos": "12950"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Menteng Atas",
                "kodepos": "12960"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Karet Kuningan",
                "kodepos": "12940"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Karet Semanggi",
                "kodepos": "12930"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Setiabudi",
                "kodepos": "12910"
            },
            {
                "kecamatan": "Setia Budi",
                "kelurahan": "Karet",
                "kodepos": "12920"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Menteng Dalam",
                "kodepos": "12870"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Manggarai",
                "kodepos": "12850"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Manggarai Selatan",
                "kodepos": "12860"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Kebon Baru",
                "kodepos": "12830"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Bukit Duri",
                "kodepos": "12840"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Tebet Barat",
                "kodepos": "12810"
            },
            {
                "kecamatan": "Tebet",
                "kelurahan": "Tebet Timur",
                "kodepos": "12820"
            },
            {
                "kecamatan": "Mampang Prapatan",
                "kelurahan": "Tegal Parang",
                "kodepos": "12790"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Pancoran",
                "kodepos": "12780"
            },
            {
                "kecamatan": "Mampang Prapatan",
                "kelurahan": "Mampang Prapatan",
                "kodepos": "12790"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Cikoko",
                "kodepos": "12770"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Pengadegan",
                "kodepos": "12770"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Duren Tiga",
                "kodepos": "12760"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Kalibata",
                "kodepos": "12740"
            },
            {
                "kecamatan": "Pancoran",
                "kelurahan": "Rawa Jati",
                "kodepos": "12750"
            },
            {
                "kecamatan": "Mampang Prapatan",
                "kelurahan": "Pela Mampang",
                "kodepos": "12720"
            },
            {
                "kecamatan": "Mampang Prapatan",
                "kelurahan": "Bangka",
                "kodepos": "12730"
            },
            {
                "kecamatan": "Mampang Prapatan",
                "kelurahan": "Kuningan Barat",
                "kodepos": "12710"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Srengseng Sawah",
                "kodepos": "12640"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Cipedak",
                "kodepos": "12630"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Lenteng Agung",
                "kodepos": "12630"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Ciganjur",
                "kodepos": "12630"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Jagakarsa",
                "kodepos": "12620"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Cilandak Timur",
                "kodepos": "12560"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Ragunan",
                "kodepos": "12550"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Jati Padang",
                "kodepos": "12540"
            },
            {
                "kecamatan": "Jagakarsa",
                "kelurahan": "Tanjung Barat",
                "kodepos": "12530"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Pasar Minggu",
                "kodepos": "12520"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Pejaten Timur",
                "kodepos": "12510"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Kebagusan",
                "kodepos": "12520"
            },
            {
                "kecamatan": "Pasar Minggu",
                "kelurahan": "Pejaten Barat",
                "kodepos": "12510"
            },
            {
                "kecamatan": "Cilandak",
                "kelurahan": "Pondok Labu",
                "kodepos": "12450"
            },
            {
                "kecamatan": "Cilandak",
                "kelurahan": "Cilandak Barat",
                "kodepos": "12430"
            },
            {
                "kecamatan": "Cilandak",
                "kelurahan": "Lebak Bulus",
                "kodepos": "12440"
            },
            {
                "kecamatan": "Cilandak",
                "kelurahan": "Cipete Selatan",
                "kodepos": "12410"
            },
            {
                "kecamatan": "Cilandak",
                "kelurahan": "Gandaria Selatan",
                "kodepos": "12420"
            },
            {
                "kecamatan": "Pesanggrahan",
                "kelurahan": "Bintaro",
                "kodepos": "12330"
            },
            {
                "kecamatan": "Pesanggrahan",
                "kelurahan": "Pesanggrahan",
                "kodepos": "12320"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Pondok Pinang",
                "kodepos": "12310"
            },
            {
                "kecamatan": "Pesanggrahan",
                "kelurahan": "Petukangan Selatan",
                "kodepos": "12270"
            },
            {
                "kecamatan": "Pesanggrahan",
                "kelurahan": "Petukangan Utara",
                "kodepos": "12260"
            },
            {
                "kecamatan": "Pesanggrahan",
                "kelurahan": "Ulujami",
                "kodepos": "12250"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Kebayoran Lama Selatan",
                "kodepos": "12240"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Kebayoran Lama Utara",
                "kodepos": "12240"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Grogol Selatan",
                "kodepos": "12220"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Cipulir",
                "kodepos": "12230"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Senayan",
                "kodepos": "12190"
            },
            {
                "kecamatan": "Kebayoran Lama",
                "kelurahan": "Grogol Utara",
                "kodepos": "12210"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Petogogan",
                "kodepos": "12170"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Rawa Barat",
                "kodepos": "12180"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Melawai",
                "kodepos": "12160"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Pulo",
                "kodepos": "12160"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Gandaria Utara",
                "kodepos": "12140"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Cipete Utara",
                "kodepos": "12150"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Kramat Pela",
                "kodepos": "12130"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Selong",
                "kodepos": "12110"
            },
            {
                "kecamatan": "Kebayoran Baru",
                "kelurahan": "Gunung",
                "kodepos": "12120"
            }
        ],
        "k42": [
            {
                "kecamatan": "Kalideres",
                "kelurahan": "Semanan",
                "kodepos": "11850"
            },
            {
                "kecamatan": "Kalideres",
                "kelurahan": "Tegal Alur",
                "kodepos": "11820"
            },
            {
                "kecamatan": "Kalideres",
                "kelurahan": "Pegadungan",
                "kodepos": "11830"
            },
            {
                "kecamatan": "Kalideres",
                "kelurahan": "Kalideres",
                "kodepos": "11840"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Duri Kosambi",
                "kodepos": "11750"
            },
            {
                "kecamatan": "Kalideres",
                "kelurahan": "Kamal",
                "kodepos": "11810"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Rawa Buaya",
                "kodepos": "11740"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Cengkareng Timur",
                "kodepos": "11730"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Cengkareng Barat",
                "kodepos": "11730"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Kedaung Kali Angke",
                "kodepos": "11710"
            },
            {
                "kecamatan": "Cengkareng",
                "kelurahan": "Kapuk",
                "kodepos": "11720"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Joglo",
                "kodepos": "11640"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Meruya Selatan (Udik)",
                "kodepos": "11650"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Meruya Utara (Ilir)",
                "kodepos": "11620"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Srengseng",
                "kodepos": "11630"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Kembangan Selatan",
                "kodepos": "11610"
            },
            {
                "kecamatan": "Kembangan",
                "kelurahan": "Kembangan Utara",
                "kodepos": "11610"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Sukabumi Selatan (Udik)",
                "kodepos": "11560"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Kelapa Dua",
                "kodepos": "11550"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Kebon Jeruk",
                "kodepos": "11530"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Sukabumi Utara (Ilir)",
                "kodepos": "11540"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Kedoya Utara",
                "kodepos": "11520"
            },
            {
                "kecamatan": "Palmerah",
                "kelurahan": "Palmerah",
                "kodepos": "11480"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Duri Kepa",
                "kodepos": "11510"
            },
            {
                "kecamatan": "Kebon Jeruk",
                "kelurahan": "Kedoya Selatan",
                "kodepos": "11520"
            },
            {
                "kecamatan": "Palmerah",
                "kelurahan": "Kemanggisan",
                "kodepos": "11480"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Tanjung Duren Utara",
                "kodepos": "11470"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Tanjung Duren Selatan",
                "kodepos": "11470"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Jelambar Baru",
                "kodepos": "11460"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Wijaya Kusuma",
                "kodepos": "11460"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Jelambar",
                "kodepos": "11460"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Grogol",
                "kodepos": "11450"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Tomang",
                "kodepos": "11440"
            },
            {
                "kecamatan": "Grogol Petamburan",
                "kelurahan": "Tomang",
                "kodepos": "11440"
            },
            {
                "kecamatan": "Palmerah",
                "kelurahan": "Kota Bambu Utara",
                "kodepos": "11420"
            },
            {
                "kecamatan": "Palmerah",
                "kelurahan": "Kota Bambu Selatan",
                "kodepos": "11420"
            },
            {
                "kecamatan": "Palmerah",
                "kelurahan": "Slipi",
                "kodepos": "11410"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Jembatan Besi",
                "kodepos": "11320"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Angke",
                "kodepos": "11330"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Duri Utara",
                "kodepos": "11270"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Kali Anyar",
                "kodepos": "11310"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Krendang",
                "kodepos": "11260"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Duri Selatan",
                "kodepos": "11270"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Pekojan",
                "kodepos": "11240"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Jembatan Lima",
                "kodepos": "11250"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Roa Malaka",
                "kodepos": "11230"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Tanah Sereal",
                "kodepos": "11210"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Tambora",
                "kodepos": "11220"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Tangki",
                "kodepos": "11170"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Mangga Besar",
                "kodepos": "11180"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Taman Sari",
                "kodepos": "11150"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Maphar",
                "kodepos": "11160"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Keagungan",
                "kodepos": "11130"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Krukut",
                "kodepos": "11140"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Glodok",
                "kodepos": "11120"
            },
            {
                "kecamatan": "Taman Sari",
                "kelurahan": "Pinangsia",
                "kodepos": "11110"
            }
        ],
        "k43": [
            {
                "kecamatan": "Sawah Besar",
                "kelurahan": "Mangga Dua Selatan",
                "kodepos": "10730"
            },
            {
                "kecamatan": "Sawah Besar",
                "kelurahan": "Karang Anyar",
                "kodepos": "10740"
            },
            {
                "kecamatan": "Sawah Besar",
                "kelurahan": "Kartini",
                "kodepos": "10750"
            },
            {
                "kecamatan": "Sawah Besar",
                "kelurahan": "Gunung Sahari Utara",
                "kodepos": "10720"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Utan Panjang",
                "kodepos": "10650"
            },
            {
                "kecamatan": "Sawah Besar",
                "kelurahan": "Pasar Baru",
                "kodepos": "10710"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Sumur Batu",
                "kodepos": "10640"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Serdang",
                "kodepos": "10650"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Cempaka Baru",
                "kodepos": "10640"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Harapan Mulya",
                "kodepos": "10640"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Kemayoran",
                "kodepos": "10620"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Kebon Kosong",
                "kodepos": "10630"
            },
            {
                "kecamatan": "Kemayoran",
                "kelurahan": "Gunung Sahari Selatan",
                "kodepos": "10610"
            },
            {
                "kecamatan": "Cempaka Putih",
                "kelurahan": "Rawasari",
                "kodepos": "10570"
            },
            {
                "kecamatan": "Johar Baru",
                "kelurahan": "Johar Baru",
                "kodepos": "10560"
            },
            {
                "kecamatan": "Johar Baru",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "10540"
            },
            {
                "kecamatan": "Johar Baru",
                "kelurahan": "Kampung Rawa",
                "kodepos": "10550"
            },
            {
                "kecamatan": "Cempaka Putih",
                "kelurahan": "Cempaka Putih Barat",
                "kodepos": "10520"
            },
            {
                "kecamatan": "Johar Baru",
                "kelurahan": "Galur",
                "kodepos": "10530"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Bungur",
                "kodepos": "10460"
            },
            {
                "kecamatan": "Cempaka Putih",
                "kelurahan": "Cempaka Putih Timur",
                "kodepos": "10510"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Paseban",
                "kodepos": "10440"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Kramat",
                "kodepos": "10450"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Kenari",
                "kodepos": "10430"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Senen",
                "kodepos": "10410"
            },
            {
                "kecamatan": "Senen",
                "kelurahan": "Kwitang",
                "kodepos": "10420"
            },
            {
                "kecamatan": "Menteng",
                "kelurahan": "Kebon Sirih",
                "kodepos": "10340"
            },
            {
                "kecamatan": "Menteng",
                "kelurahan": "Gondangdia",
                "kodepos": "10350"
            },
            {
                "kecamatan": "Menteng",
                "kelurahan": "Pegangsaan",
                "kodepos": "10320"
            },
            {
                "kecamatan": "Menteng",
                "kelurahan": "Cikini",
                "kodepos": "10330"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Gelora",
                "kodepos": "10270"
            },
            {
                "kecamatan": "Menteng",
                "kelurahan": "Menteng",
                "kodepos": "10310"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Petamburan",
                "kodepos": "10260"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Kampung Bali",
                "kodepos": "10250"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Kebon Kacang",
                "kodepos": "10240"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Kebon Melati",
                "kodepos": "10230"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Karet Tengsin",
                "kodepos": "10220"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Bendungan Hilir",
                "kodepos": "10210"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Petojo Selatan",
                "kodepos": "10160"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Duri Pulo",
                "kodepos": "10140"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Cideng",
                "kodepos": "10150"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Petojo Utara",
                "kodepos": "10130"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Gambir",
                "kodepos": "10110"
            },
            {
                "kecamatan": "Gambir",
                "kelurahan": "Kebon Kelapa",
                "kodepos": "10120"
            }
        ],
        "k44": [
            {
                "kecamatan": "Bone",
                "kelurahan": "Waluhu",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Tunas Jaya",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Uabanga",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Tumbuh Mekar",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Tongo",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Tombulilato",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Tihu",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Tolotio",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Tamboo",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Taludaa",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Permata",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Sogitia",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Pelita Jaya",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Pelita Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Patoa",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Ombulo Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Oluhuta",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Nyiur Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Olele",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Muara Bone",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Mopuya",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Mootayu",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Mootinelo",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Moopiya (Mopuya)",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Mootawa",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Moodulio",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Monano",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Molotabu (Molutabu)",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Molamahu",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Modelomo",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Masiaga",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Mamungaa",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Mamungaa Timur",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Lembah Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Laut Biru",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Kaidundu Barat",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Kemiri",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Inomata",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Kaidundu",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Ilohuuwa",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Inogaluma",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Cendana Putih",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Bunga Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Bunga",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bulawa",
                "kelurahan": "Bukit Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Botutonuo (Botutonua)",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Bilungala Utara",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Bintalahe",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Bilungala",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone",
                "kelurahan": "Bilolantuna",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bonepantai",
                "kelurahan": "Batu Hijau",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Bone Raya",
                "kelurahan": "Alo",
                "kodepos": "96585"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Ulanta",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Tulabolo Barat",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Tulabolo Timur",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Tulabolo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Tolomato",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Tingkohubu",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Tingkohubu Timur",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Tinelo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Tinelo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Tilangobula",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Tapadaa",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Pinogu",
                "kelurahan": "Pinogu Permai",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Poduoma",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Pinogu",
                "kelurahan": "Pinogu",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Panggulo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Molintogupo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Pancuran",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Lompotoo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Lombongo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Libungo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Huluduotamo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Dumbayabulan",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Helumo",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Dumbayabulan Timur",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Timur",
                "kelurahan": "Dataran Hijau",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Duano",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Pinogu",
                "kelurahan": "Dataran Hijau",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Bulontala",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Bulontala Timur",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Bube Baru",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Bubeya",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Bube",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Bonedaa",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Bonda Raya",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Selatan",
                "kelurahan": "Bondawuna",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa",
                "kelurahan": "Boludawa",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Pinogu",
                "kelurahan": "Bangio",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Suwawa Tengah",
                "kelurahan": "Alele",
                "kodepos": "96584"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Tunggulo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Tunggulo Selatan",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Toto Utara",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Tumbihe",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Toto Selatan",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Timbuolo Timur",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Timbuolo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Timbuolo Tengah",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Tanggilingo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Tanah Putih",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Talango",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Tamboo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Sukma",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Poowo Barat",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Permata",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Poowo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Panggulo Barat",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Pauwo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Panggulo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Oluhuta Utara",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Padengo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Oluhuta",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Motilango (Mootilango)",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Moutong",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Luwohu",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Lonuo",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Huangobotu",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Iloheluma",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Dutohe",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila",
                "kelurahan": "Dutohe Barat",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Butu",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Botu Pingge",
                "kelurahan": "Buata",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Botubarani",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Bongopini",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Bongoime",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Bongohulowa",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Kabila Bone",
                "kelurahan": "Biluango",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Tilongkabila",
                "kelurahan": "Berlian",
                "kodepos": "96583"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Tupa",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Tuloa",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Timur",
                "kelurahan": "Toluwaya (Tuloa)",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Tinelo Ayula",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Talumopatu",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Talulobutu",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Talulobutu Selatan",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Suka Damai",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Suka Makmur",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Sejahtera",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Timur",
                "kelurahan": "Popodu",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Owata",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Pilolaheya",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Mongiilo",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Mongiilo Utara",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Meranti",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Lomaya",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Longalo",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Lamahu",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Langge",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Kopi",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Keramat",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Ulu",
                "kelurahan": "Ilomata",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Huntu Selatan",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Huntu Utara",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Bunuo",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Tapa",
                "kelurahan": "Dunggala",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Timur",
                "kelurahan": "Bulotalangi Barat",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Timur",
                "kelurahan": "Bulotalangi Timur",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Timur",
                "kelurahan": "Bulotalangi",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Boidu",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Utara",
                "kelurahan": "Bandungan",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Ayula Timur",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Ayula Utara",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Ayula Selatan",
                "kodepos": "96582"
            },
            {
                "kecamatan": "Bulango Selatan",
                "kelurahan": "Ayula Tilango",
                "kodepos": "96582"
            }
        ],
        "k45": [
            {
                "kecamatan": "Monano",
                "kelurahan": "Zuriyati",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Tutuwoto",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Tudi",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Tolongio",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Tolitehuyu",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Tolango",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Sogu",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Popalo",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Putiana",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Mootilango",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Pilohulata",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Mokonowu",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Monano",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Monas",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Langge",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Ilodulunga",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Iloheluma",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Ilangata",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Ibarat",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Helumo",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Hiyalooile",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Garapia",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Monano",
                "kelurahan": "Dunu",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Dudepo",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Anggrek",
                "kelurahan": "Datahu",
                "kodepos": "96525"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Windu",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Topi",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Tolite Jaya",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Tolinggula Ulu",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Tolinggula Tengah",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Tolinggula Pantai",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Sembihingan",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Potanga (Potango)",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Papulangi (Papualangi)",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Omuto",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Molangga",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Luhuto",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Limbato",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Ilotunggulo",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Ilomangga",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Didingga",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Tolinggula",
                "kelurahan": "Cempaka Putih",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Bualo",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Bohulo",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Biawu (Biau)",
                "kodepos": "96524"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Wubudu",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Puncak Mandiri",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Tumba",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Pulohenti",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Motihelumo",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Mebongo",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Lelato",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Koluwoka",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Kikia",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Hutakalo",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Kasia",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Hulawa",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Dulukapa",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Deme Dua (Deme II)",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Deme Satu (Deme I)",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Buluwatu",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Bulontio Timur",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Bulontio Barat",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata",
                "kelurahan": "Buloila",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Buladu",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Sumalata Timur",
                "kelurahan": "Bubalango",
                "kodepos": "96254"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Wapalo",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Tombulilato",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Sigaso",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Pinontoyonga",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Posono",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Oluhuta",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Pasalae",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Motomingo",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Monggupo",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Nanati Jaya",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Langke",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Molonggota",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Kotajin (Kota Jin)",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Kotajin Utara",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Ipilo",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Ketapang",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Imana",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Inomata (Ilomata)",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Gentuma",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Iloheluma",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Durian",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Bohusami",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Gentuma Raya",
                "kelurahan": "Dumolodo",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Buata",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Atinggola",
                "kelurahan": "Bintana",
                "kodepos": "96253"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Titidu",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Tanjung Karang",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Ponelo Kepulauan",
                "kelurahan": "Tihengo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Posso",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Pontolo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Pontolo Atas",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Ponelo Kepulauan",
                "kelurahan": "Otiola",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Ponelo Kepulauan",
                "kelurahan": "Ponelo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Mutiara Laut",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Mootinelo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Ombulodata",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Molingkapoto Selatan",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Moluo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Molingkapoto (Molinggapoto)",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Molantadu",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Milango",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Masuru",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Leyao",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Ponelo Kepulauan",
                "kelurahan": "Malambe",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Leboto",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Huidu Melito",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Katialada",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Jembatan Merah",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Bulango Raya",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Dambalo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Cisadane",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Tomolito",
                "kelurahan": "Bubode",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Bulalo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Bualemo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Botuwombata",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Botungobungo",
                "kodepos": "96252"
            },
            {
                "kecamatan": "Kwandang",
                "kelurahan": "Alata Karya",
                "kodepos": "96252"
            }
        ],
        "k46": [
            {
                "kecamatan": "Randangan",
                "kelurahan": "Yipilo",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Tuweya",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Tirto Asri",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Siduwonge",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Sidorukun",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Sari Murni",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Pelambane Perda",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Patuhu",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Panca Karsa I",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Panca Karsa II",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Motolohu Selatan",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Omayuwa",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Marisa IV",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Manunggal Karya",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Motolohu",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Makarti Jaya",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Malango",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Taluditi (Taluduti)",
                "kelurahan": "Kalimas",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Limbula",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Imbodu",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Huyula",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Ayula",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Randangan",
                "kelurahan": "Banuroja",
                "kodepos": "96469"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Wonggarasi Tengah",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Wonggarasi Barat",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Wanggarasi",
                "kelurahan": "Wanggarasi (Wonggarasi Timur)",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Suka Damai",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Lomuli",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Lemito",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Lemito Utara",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Wanggarasi",
                "kelurahan": "Lembah Permai (Malango II)",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Kenari",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Wanggarasi",
                "kelurahan": "Bukit Harapan",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Wanggarasi",
                "kelurahan": "Bohusami",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Lemito",
                "kelurahan": "Babalonge",
                "kodepos": "96468"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Tunas Harapan",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Tunas Jaya",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Trikora",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Torosiaje Jaya",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Telaga Biru",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Torosiaje",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Tahele",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Telaga",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Persatuan",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Popayato",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Molosipat Utara",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Padengo",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Molosipat",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Milangodaa",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Londoun (Londuun)",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Maleo",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Kelapa Lima",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Dambalo",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Dudewulo",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Barat",
                "kelurahan": "Butungale",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Bunto",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Bumi Bahari",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Popayato",
                "kelurahan": "Bukit Tingki",
                "kodepos": "96467"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Marisa Selatan",
                "kodepos": "96466"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Marisa Utara",
                "kodepos": "96466"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Teratai",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Taluduyunu",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Taluduyunu Utara",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Suka Makmur",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Sipatana",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Pohuwato",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Pohuwato Timur",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Mootilango",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Padeng",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Palopo",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Mekar Jaya",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Popayato Timur",
                "kelurahan": "Marisa",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Manawa",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Ilo Heluma",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Karya Indah",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Dulomo",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Hulawa",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Duhiadaa",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Dudepo",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Buntulia Tengah",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Buntulia",
                "kelurahan": "Buntulia Utara",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Buntulia Selatan",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Bulili",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Buntulia Barat",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Duhiadaa",
                "kelurahan": "Buntulia Jaya",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Batubilotahu Indah",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Marisa",
                "kelurahan": "Bulangita",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Patilanggio",
                "kelurahan": "Balayo",
                "kodepos": "96266"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Sipayo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Soginti",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Dengilo",
                "kelurahan": "Popaya",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Siduan",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Pentadu",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Dengilo",
                "kelurahan": "Padengo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Molamahu",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Libuo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Maleo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Dengilo",
                "kelurahan": "Karya Baru",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Kamiri",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Dengilo",
                "kelurahan": "Karangetang",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Dengilo",
                "kelurahan": "Huta Moputi",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Bumbulan",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Bunuyo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Paguat",
                "kelurahan": "Buhu Jaya",
                "kodepos": "96265"
            }
        ],
        "k47": [
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Olimoo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Tontayuo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Olimeyala",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Luluo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Lopo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Lobuto",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Lobuto Timur",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Langgula",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Kayubulan",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Lamu",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Botubolu&#8217;o (Botuboluo)",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Huwongo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Buhudaa",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Bongo",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Batudaa Pantai",
                "kelurahan": "Biluhu Timur",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Biluhu Tengah",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Biluhu",
                "kelurahan": "Biluhu Barat",
                "kodepos": "96272"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Upomela",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Tohupo",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Tabongo Timur",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Teratai",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Tabongo Barat",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Pilolalenga",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Payunga",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Pilobuhuta",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Otopade",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Owalanga",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Pangadaa",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Motinelo",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Momala",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Molas",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Molopatodu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Molanihu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Moahudu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Liyodu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Liyoto",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Limehu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Limehe Barat",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Limehe Timur",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Kaliyoso",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Kayumerah",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Iluta",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Tabongo",
                "kelurahan": "Ilomangga",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Ilohungayo",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Huntu Lo Hulawa",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Huntu",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Duwanga",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Dunggala",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Dungaliyo",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Bua",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Bua",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Bongomeme",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Botubu lowe",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Batulayar",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Batu Loreng",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Batudaa",
                "kelurahan": "Barakati",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Ayuhula",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Dungaliyo",
                "kelurahan": "Ambara",
                "kodepos": "96271"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Totopo",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Taulaa",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Tolite",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Suka Damai",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Sidomulyo Selatan",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Sidodadi",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Sidomulyo",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Potanga",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Pelehu",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Musyawarah",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Parungi",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Motoduto",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Lamahu",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Monggolito",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Ilomata",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Juriya",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Iloheluma",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Dulohupa",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Diloniyohu",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Bumela",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Bongongayu",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Bilato",
                "kelurahan": "Bilato",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Boliohuto (Boliyohuto)",
                "kelurahan": "Bandung Rejo",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Tolotio",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Ulobua",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Molowahu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Motilango",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Reksonegoro",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Isimu Utara",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Labanu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Isimu Raya",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Isimu Selatan",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Ilomata",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Iloponu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Datahu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Dunggala",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Botumoputi",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Buhu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Tibawa",
                "kelurahan": "Balahu",
                "kodepos": "96251"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Hunggaluwa",
                "kodepos": "96219"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Bolihuangga",
                "kodepos": "96218"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Tenilo",
                "kodepos": "96218"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Yosonegoro (Yoosonegoro)",
                "kodepos": "96216"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Tunggulo",
                "kodepos": "96217"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Hutabohu",
                "kodepos": "96216"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Hutabohu",
                "kodepos": "96216"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Huidu Utara",
                "kodepos": "96216"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Pone",
                "kodepos": "96215"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Hayahaya",
                "kodepos": "96216"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Huidu",
                "kodepos": "96215"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Ombulo",
                "kodepos": "96215"
            },
            {
                "kecamatan": "Limboto Barat",
                "kelurahan": "Daenaa",
                "kodepos": "96215"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Tamalia Utara",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Tiohu",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Tilihua",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Sukamakmur Utara",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Tamaila",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Sidoharjo (Sidoarjo)",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Sukamakmur",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Polohungo",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Prima",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Ombulo Tango",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Pangahu",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Molohu",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Mohiyolo",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Olimohulo",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Makmur Abadi",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Margomulyo",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Kayumerah",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Lakeya",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Karya Indah",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Himalaya",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Karya Baru",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Gandasari",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Gandaria",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Bontulo",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Bululi",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Bongomeme",
                "kelurahan": "Bongohulawa",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Bongohulawa",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Tolangohula",
                "kelurahan": "Bina Jaya",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Asparaga",
                "kelurahan": "Bihe",
                "kodepos": "96214"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Hutuo",
                "kodepos": "96213"
            },
            {
                "kecamatan": "Telaga Jaya",
                "kelurahan": "Bulota",
                "kodepos": "96213"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Dutulanaa",
                "kodepos": "96213"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Polohungo",
                "kodepos": "96212"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Bulota",
                "kodepos": "96213"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Malahu",
                "kodepos": "96211"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Biyonga",
                "kodepos": "96212"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Hepuhulawa",
                "kodepos": "96212"
            },
            {
                "kecamatan": "Limboto",
                "kelurahan": "Kayubulan",
                "kodepos": "96211"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Ulapato A",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Ulapato B",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Tualango",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Tonala",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Tinelo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Timuato",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Tinelo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Tenggela",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Tilote",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Tabumela",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Talumelito",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Tapaluluo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Pilohayanga",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Pilohayanga Barat",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Pentadio Barat",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Pentadio Timur",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Pantungo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Mongolato",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Jaya",
                "kelurahan": "Luwoo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Lupoyo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Modelidu (Modellidu)",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Lauwonu",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Luhu",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Ilotideaa",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Jaya",
                "kelurahan": "Hutadaa",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Dumati",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Dulohupa",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Hulawa",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Dulamayo Utara",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Dulamayo Selatan",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Jaya",
                "kelurahan": "Bunggalo",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Dulamayo Barat",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga Jaya",
                "kelurahan": "Buhu",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Telaga",
                "kelurahan": "Bulila",
                "kodepos": "96181"
            },
            {
                "kecamatan": "Dungingi",
                "kelurahan": "Tomulabutao Selatan",
                "kodepos": "96139"
            },
            {
                "kecamatan": "Dungingi",
                "kelurahan": "Huangobotu",
                "kodepos": "96138"
            },
            {
                "kecamatan": "Dungingi",
                "kelurahan": "Tomulabutao",
                "kodepos": "96139"
            },
            {
                "kecamatan": "Telaga Biru",
                "kelurahan": "Tuladenggi",
                "kodepos": "96137"
            },
            {
                "kecamatan": "Dungingi",
                "kelurahan": "Tuladenggi",
                "kodepos": "96137"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Buladu",
                "kodepos": "96136"
            },
            {
                "kecamatan": "Dungingi",
                "kelurahan": "Libuo",
                "kodepos": "96135"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Molosifat W (Molosipat W)",
                "kodepos": "96134"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Buliide",
                "kodepos": "96133"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Tenilo",
                "kodepos": "96133"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Dembe I",
                "kodepos": "96131"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Lekobalo",
                "kodepos": "96131"
            },
            {
                "kecamatan": "Kota Barat",
                "kelurahan": "Pilolodaa",
                "kodepos": "96132"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Paguyaman",
                "kodepos": "96129"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Wumialo",
                "kodepos": "96128"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Liluwo",
                "kodepos": "96129"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Dulalowo",
                "kodepos": "96128"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Dulalowo Timur",
                "kodepos": "96128"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Toyidito (Toydito)",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Tridarma",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Talumopatu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Talumopatu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Satria",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Sido Mukti",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Pulubala",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Puncak",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Kota Tengah",
                "kelurahan": "Pulubala",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Pongongaila",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Payu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Pilomonu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Paris",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Mulyonegoro",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Molalahu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Molamahu",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Helumo",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Huyula",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Mootilango",
                "kelurahan": "Karya Mukti",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Bakti",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Bukit Aren",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Pulubala",
                "kelurahan": "Ayumolingo",
                "kodepos": "96127"
            },
            {
                "kecamatan": "Sipatana",
                "kelurahan": "Tanggikiki",
                "kodepos": "96126"
            },
            {
                "kecamatan": "Sipatana",
                "kelurahan": "Tapa",
                "kodepos": "96125"
            },
            {
                "kecamatan": "Sipatana",
                "kelurahan": "Molosifat U (Molosipat U)",
                "kodepos": "96126"
            },
            {
                "kecamatan": "Sipatana",
                "kelurahan": "Bulotadaa Barat",
                "kodepos": "96124"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Dulomo Utara",
                "kodepos": "96123"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Dulomo Selatan",
                "kodepos": "96123"
            },
            {
                "kecamatan": "Tilango",
                "kelurahan": "Dulomo",
                "kodepos": "96123"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Wongkaditi Timur",
                "kodepos": "96122"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Dembe Jaya",
                "kodepos": "96121"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Wongkaditi Barat",
                "kodepos": "96122"
            },
            {
                "kecamatan": "Kota Utara",
                "kelurahan": "Dembe II",
                "kodepos": "96121"
            },
            {
                "kecamatan": "Sipatana",
                "kelurahan": "Bulotadaa Timur",
                "kodepos": "96121"
            },
            {
                "kecamatan": "Dumbo Raya",
                "kelurahan": "Leato Utara",
                "kodepos": "96119"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Heledulaa Utara",
                "kodepos": "96119"
            },
            {
                "kecamatan": "Dumbo Raya",
                "kelurahan": "Leato Selatan",
                "kodepos": "96119"
            },
            {
                "kecamatan": "Dumbo Raya",
                "kelurahan": "Botu",
                "kodepos": "96118"
            },
            {
                "kecamatan": "Dumbo Raya",
                "kelurahan": "Talumolo",
                "kodepos": "96118"
            },
            {
                "kecamatan": "Hulonthalangi",
                "kelurahan": "Tenda",
                "kodepos": "96117"
            },
            {
                "kecamatan": "Kota Selatan",
                "kelurahan": "Limba U Satu (Limba U I)",
                "kodepos": "96115"
            },
            {
                "kecamatan": "Hulonthalangi",
                "kelurahan": "Donggala",
                "kodepos": "96116"
            },
            {
                "kecamatan": "Hulonthalangi",
                "kelurahan": "Pohe",
                "kodepos": "96117"
            },
            {
                "kecamatan": "Kota Selatan",
                "kelurahan": "Limba B",
                "kodepos": "96115"
            },
            {
                "kecamatan": "Kota Selatan",
                "kelurahan": "Limba U Dua (Limba U II)",
                "kodepos": "96115"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Tamalate",
                "kodepos": "96113"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Heledulaa Selatan",
                "kodepos": "96114"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Ipilo",
                "kodepos": "96112"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Moodu",
                "kodepos": "96113"
            },
            {
                "kecamatan": "Kota Timur",
                "kelurahan": "Padebuolo",
                "kodepos": "96113"
            },
            {
                "kecamatan": "Hulonthalangi",
                "kelurahan": "Tanjung Kramat",
                "kodepos": "96111"
            },
            {
                "kecamatan": "Dumbo Raya",
                "kelurahan": "Bugis",
                "kodepos": "96112"
            },
            {
                "kecamatan": "Hulonthalangi",
                "kelurahan": "Siendeng",
                "kodepos": "96111"
            },
            {
                "kecamatan": "Kota Selatan",
                "kelurahan": "Biawao",
                "kodepos": "96111"
            },
            {
                "kecamatan": "Kota Selatan",
                "kelurahan": "Biawu",
                "kodepos": "96111"
            }
        ],
        "k48": [
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Tabulo Selatan",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Tabulo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Salilama",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Pontolo",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Mananggu",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Keramat",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Kaaruyan",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Buti",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Mananggu",
                "kelurahan": "Bendungan",
                "kodepos": "96265"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Tutulo",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Tapadaa",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Potanga",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Rumbia",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Patoameme",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Hutamonu",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Dulangea (Dulangeya)",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Botumoito",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Botumoita (Botumoito)",
                "kelurahan": "Bolihutuo",
                "kodepos": "96264"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Tenilo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Tanah Putih",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Tangga Barito",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Tangga Jaya",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Polohungo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Tabongo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Pentadu Barat",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Pentadu Timur",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Piloliyanga",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Pangi",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Mohungo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Lamu",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Limbato",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Modelomo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Kotaraja",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Lahumbo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Dulupi",
                "kelurahan": "Dulupi",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Hungayonaa",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Ayuhulalo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Tilamuta",
                "kelurahan": "Bajo",
                "kodepos": "96263"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tri Rukun",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Suka Mulia (Sukamulya)",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sejahtera **",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Suka Maju",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Raharja",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sari Tani",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Pangeya (Pangea)",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Makmur **",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Mekar Jaya",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Jati Mulya",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Dulohupa",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Harapan",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bongo III",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Dimito",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bongo II",
                "kodepos": "96262"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Wonggahu",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Towayu",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Tenilo",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Sumber Jaya **",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Tangkobu",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Sosial",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Rejonegoro",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Saripi",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Olibu",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Permata",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Mustika",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Mutiara",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Limbatihu",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Molombulahe",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Lito",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Karya Murni",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Kualalumpur",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Hulawe (Hulawa)",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Huwongo",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Diloato",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Girisa",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Bubaa",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Bukit Karya",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Bongo Tua",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Bualo",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Bongo Nol",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Batu Kramat/Keramat",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Bongo IV",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Bangga",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman",
                "kelurahan": "Balate Jaya",
                "kodepos": "96261"
            },
            {
                "kecamatan": "Paguyaman Pantai",
                "kelurahan": "Apitalawu (Apitalawo)",
                "kodepos": "96261"
            }
        ],
        "k49": [
            {
                "kecamatan": "Sumay",
                "kelurahan": "Tuo Sumay",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Teluk Singkawang",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Teriti",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Teluk Langkap",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Tambun Arang",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Suo Suo",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Pemayungan (Pemayongan)",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Puntikalo",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Lembak Bungur",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Muara Sekalo",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Desa Semambu",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Sumay",
                "kelurahan": "Jati Belarik",
                "kodepos": "37573"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Teluk Rendah Pasar",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Teluk Rendah Ulu",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Teluk Rendah Ilir",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Tambun Arang",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Tanah Garo",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Sungai Aro",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Sungai Jernih",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Sei/Sungai Bengkal",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Sei/Sungai Bengkal Barat",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Pintas Tuo",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tengah Ilir",
                "kelurahan": "Rantau Api",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Olak Kemang",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tengah Ilir",
                "kelurahan": "Penapalan",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Muara Ketalo",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tengah Ilir",
                "kelurahan": "Muara Kilis",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tengah Ilir",
                "kelurahan": "Lubuk Mandarasah",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tengah Ilir",
                "kelurahan": "Mengupeh",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Kunangan",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Embacang Gedang",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Betung Bedarah Timur",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Desa Tuo Ilir",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Ilir",
                "kelurahan": "Betung Bedarah Barat",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Bangko Pintas",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Muara Tabir",
                "kelurahan": "Bangun Saranten",
                "kodepos": "37572"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Tengah Ulu",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Teluk Pandak",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Sungai Keruh",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Sungai Alai",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Pelayang",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Semabu",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Muara Tebo",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Kandang",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Mangun Jayo",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Bedaro Rampak",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Tengah",
                "kelurahan": "Aburan Batang Tebo",
                "kodepos": "37571"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Ulak Banjir Rambahan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Teluk Melintang",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Teluk Pandan Rambahan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Teluk Kembang Jambu",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Teluk Kuali",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Teluk Kasai Rambahan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Tanjung Aur Seberang",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Sungai Rambai",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Tanjung Aur",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Sekutur Jaya",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Sako Makmur",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Pulau Temiang",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Rantau Langkap",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Pulau Jelmu",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Pulau Panjang",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Pinang Belai/Balai",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Pagar Puding",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Pagar Puding Lamo",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Napal Putih",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Lubuk Benteng",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Malako Intan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Medan Seri Rambahan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Bungo Tanjung",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Tebo Ulu",
                "kelurahan": "Jambu",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Serai Serumpun",
                "kelurahan": "Bukit Pemuatan",
                "kodepos": "37554"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Wirtho/Wirotho Agung",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Wanareja",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Tirtakencana",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Tegal Arum",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Sungai Pandan",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Sumber Sari",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Suka Damai",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Suka Maju",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Sumber Agung",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ulu",
                "kelurahan": "Sido Rukun",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Sido Rejo",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Sari Mulyo/Mulya",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Sepakat Bersatu",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Rimbo Mulyo",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Sapta Mulyo/Mulia",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Purwo Harjo",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Pulung Rejo",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Rantau Kembang",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Pematang Sapat",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Bujang",
                "kelurahan": "Perintis",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Giri Purno",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Giriwinangun",
                "kodepos": "37553"
            },
            {
                "kecamatan": "Rimbo Ilir",
                "kelurahan": "Karang Dadi",
                "kodepos": "37553"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Teluk Lancang",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Teluk Kayu Putih",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Teluk Kepayang Pulau Indah",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Tabun",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Sungai Karang",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Pucuk Jambi",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Sungai Abang",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Pasir Mayang",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Muara Tabun",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Paseban",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Kuamang",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Muara Niro",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Dusun Baru",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Cermin Alam",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto Ilir",
                "kelurahan": "Balai Rajo",
                "kodepos": "37259"
            },
            {
                "kecamatan": "VII Koto",
                "kelurahan": "Aur Cino",
                "kodepos": "37259"
            }
        ],
        "k50": [
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Taman Dewa (Desa Taman Dewa)",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Sungairotan",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Talang Serdang",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Sungai Butang",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Suka Maju",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Simpang Kertopati",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Rangkiling",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Rangkiling Simpang",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Simpang Gurun Tuo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Petiduran Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Muara Ketalo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Pemusiran",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Meranti Jaya",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Meranti Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Mandiangin Pasar",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Mandiangin Tuo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Kute Jaye",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Mandiangin",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Jernang Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Kerto Pati",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Jati Baru Mudo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Jati Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Gurun Tuo Simpang",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Gurun Tuo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Gurun Mudo",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Guruh Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Butang Baru",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Mandiangin",
                "kelurahan": "Bukit Peranginan",
                "kodepos": "37492"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Taman Bandung",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Sepintun",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Semurung",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Seko Besar",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Semaran",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Pengidaran (Pangidaran)",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Pematang Kabau",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Pangkal Bulian",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Pauh",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Mentawak Baru",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Mentawak Ulu",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Lubuk Napal",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Lubuk Kepayang",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Kasang Melintang",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Lamban Sigatal",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Lubuk Jering",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Jernih",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Karang Mendapo",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Desa Baru (Dusun Baru)",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Danau Serdang",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Bukit Suban",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Batu Kucing",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Batu Ampar",
                "kodepos": "37491"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Sungai Keradak",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Tambak Ratu",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Sungai Baung",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Sungai Bemban",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Rantau Panjang",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Simpang Narso",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Pulau Salak Baru",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Raden Anom",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Paniban Baru",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Pekan Gedang",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Padang Jering",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Muaro/Muara Cuban",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Muaro/Muara Pemuat",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Kasiro Ilir",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Lubuk Bangkar",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Muaro/Muara Air Dua",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Datuk Nan Duo",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Kasiro",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Bukit Berantai",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Bukit Kalimau Ulu",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Bukit Sulah",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Bathin/Batin Pengambang",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Batang Asai",
                "kelurahan": "Batu Empang",
                "kodepos": "37485"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Sungai Merah",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Sungai Gedang",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Simpang Nibung",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Sungai Benteng",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Sendang Sari",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Siliwangi",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Rantau Tenang (Rantanu Tenang)",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Pulau Aro",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Perdamaian",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Pelawan Jaya",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Pematang Kolim/Kulim",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Penegah",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Pelawan",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Pasar Singkut",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Payo Lebar",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Mekarsari",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Muara Danau",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Pasar Pelawan",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Lubuk Sepuh",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Lubuk Sayak",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Bukitbumi Raya",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Bukittalang Mas",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Bukit Murau",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Bukit Tigo",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Bukit",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Pelawan",
                "kelurahan": "Batu Putih",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Singkut",
                "kelurahan": "Argo Sari",
                "kodepos": "37482"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Tingting",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Teluk Mancur",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Tanjung",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Tanjung Gagak",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Teluk Kecimbung (Sikumbang)",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Sukasari",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Sungai Abang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Sukajadi",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Sei/Sungai Baung",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Rantau Gedang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Sarolangun Kembang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Pulau Lintang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Pulau Melako/Malako",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Pasar Sarolangun",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Penarun",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Pulau Buayo",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Muaralati",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Panti",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Limbur Tembesi",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Ladang Panjang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Lidung",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Dusun Sarolangun",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Gunung Kembang",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Dusun Dalam",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Bernai Dalam",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Batu Penyabung",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Bernai",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Bathin VIII (Batin VIII)",
                "kelurahan": "Bangun Jayo",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Baru",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Tendah",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Sarolangun",
                "kelurahan": "Aur Gading",
                "kodepos": "37481"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Temalang",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Temenggung",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Teluk Rendah",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Teluk Tigo",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Sungaikeramat",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Tambang Tinggi",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Tanjung Raden",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Sekamis",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Suka Damai",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Pemuncak (Pemancak)",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Pulau Pandan",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Pemuncak (Pemancak)",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Panca Karya",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Napal Melintang",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Muara Limun",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Muara Mansao",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Meribung/Maribung",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Mersip",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Moenti",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Lubuk Resam",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Lubuk Bedorong",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Lubuk Resam Hilir",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Cermin Nan Gadang",
                "kelurahan": "Kampung Tujuh",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Demang",
                "kodepos": "37382"
            },
            {
                "kecamatan": "Limun",
                "kelurahan": "Berkun",
                "kodepos": "37382"
            }
        ],
        "k51": [
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Tuo (Dusun Tuo)",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Tanjung Mudo",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Tanjung Kasri",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Tanjung Berugo",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Tanjung Alam",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Tanjung Beruang",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Talang Paruh",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Talang Tembago",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Sungai Lalang",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Sungai Lisai",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Talang Asal",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Sungai Hitam",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Simpang Talang Tembago",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Renah Alai",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Renah Kemumu",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Renah Pelaan",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Rantau Suli",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Rantau Kermas",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Rancan",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Rantau Jering",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Pematang Pauh",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Pulau Tengah",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Nilo Dingin",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Pasar Masurai",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Muara Madras (Manderas/Mandras)",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Muara Lengayo",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Muara Pangi",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Lubuk Pungguk",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Muara Kelukup",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Lubuk Mentilin",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Koto Tapus",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Koto Teguh",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Jangkat",
                "kelurahan": "Koto Rawang",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Koto Rami",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Kabu",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Koto Baru",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Gedang",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Jangkar",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Lembah Masurai",
                "kelurahan": "Durian Mukut",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Baru",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Sungai Tenang",
                "kelurahan": "Beringin Tinggi",
                "kodepos": "37372"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Tiaro",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Teluk Sikumbang",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Tiang Pumpung",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Sepantai Renah",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Sepantai Renah",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Sei Kancing (Sekancing)",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Sekancing Ilir",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Rantau Macang",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Rantau Panjang",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Pulau Raman",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Rantau Bayur",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Rantau Bidaro (Rantai Bedaro)",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Peradun Temeras",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Muara Siau",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Pasar Muara Siau",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Lubuk Beringin",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Lubuk Birah",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Durian Rambun",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Beringin Sanggul",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Baru Tiang Pumpung",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Badak Tekurung",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Muara Siau",
                "kelurahan": "Air Lago",
                "kodepos": "37371"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Tiga/Tigo Alur Pangkalan Jambu",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Tiangko",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Tiangko",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Talang Segegah",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Sungai/Sei Pinang",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Sungai Nilau",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Sungai Manau",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Sungai Jering",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Simpang Tiga Muara Panco",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Simpang Parit",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Simpang Muaro Panco Timur",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Saringat/Seringat",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Renah Medan",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Parit Ujung Tanjung",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Palipan",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Muara Bantan",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Markeh",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Marus Jaya",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Kampung Limo",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Guguk",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Gelanggang",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Durian Lecah",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Durian Batakuk",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Bukit Perentak",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Bungo Tanjung",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Bukit Batu",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Birun",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Sungai Manau",
                "kelurahan": "Benteng",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Pangkalan Jambu",
                "kelurahan": "Baru Pangkalan Jambu",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Renah Pembarap",
                "kelurahan": "Air Batu",
                "kodepos": "37361"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Telentam",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Tanjung Putus",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Sungai Tabir",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Pulau Terbakar",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Rantau Ngarau / Ngaro",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Pulau Lebar",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Pulau Aro",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Ngaol Ilir",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Ngaol",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Muaro Langeh",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Muara Seketuk",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Muara Kibul",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Medan Baru",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Muara Jernih",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Ulu",
                "kelurahan": "Kapuk",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Batang Kibul",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Air Liki",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Air Liki Baru",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Barat",
                "kelurahan": "Baru Kibul",
                "kodepos": "37356"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Sungai Sahut",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Muara Delang",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Rawa Jaya",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Sinar Gading",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Gading Jaya",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Ulak Makam",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Selatan",
                "kelurahan": "Bunga Antoi",
                "kodepos": "37354"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Tunggul Bulin",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Tegal Rejo",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Tanjung Ilir",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Lintas",
                "kelurahan": "Tambang Baru",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Timur",
                "kelurahan": "Sungai Limau",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Suko Rejo",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Sumber Agung",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Timur",
                "kelurahan": "Sungai Bulian",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Sido Rukun",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Timur",
                "kelurahan": "Sri Sembilan",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Lintas",
                "kelurahan": "Sido Harjo",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Lintas",
                "kelurahan": "Sido Lego",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Lintas",
                "kelurahan": "Sido Makmur",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Seling",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Rejo Sari",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Pasar Rantau Panjang",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tiang Pumpung",
                "kelurahan": "Rantau Limau Kapas",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Rantau Limau Manis",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Pasar Baru Rantau",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Mekar Limau Manis",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Margo Tabir",
                "kelurahan": "Lubuk Bumbun",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Lubuk Napal",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Mampun",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Koto Rayo",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Lintas",
                "kelurahan": "Koto Baru",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Kota Raja",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Kampung Baruh",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Kandang",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Dusun Baru",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir Timur",
                "kelurahan": "Bukit Subur",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Tabir",
                "kelurahan": "Beluran Panjang (Buluran)",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Tanjung Lamin",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Tabir Ilir",
                "kelurahan": "Air Batu",
                "kodepos": "37353"
            },
            {
                "kecamatan": "Pamenang Selatan",
                "kelurahan": "Tanjung Benuang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Tanjung Gedang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Selatan",
                "kelurahan": "Tambang Emas",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Tanah Abang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Simpang Limbur Merangin",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Sungai Udang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Selatan",
                "kelurahan": "Selango",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Sialang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Renah Pemenang",
                "kelurahan": "Rasau",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Renah Pemenang",
                "kelurahan": "Rasau",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "PulauTujuh",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Selatan",
                "kelurahan": "Pulau Bayur",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Pinang Merah",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Pelakar Jaya",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Pematang Kancil",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Pauh Menang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Papit",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Muara/Muaro Belengo",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Pamenang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Renah Pemenang",
                "kelurahan": "Meranti",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Mampun Baru",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Renah Pemenang",
                "kelurahan": "Lantak Seribu",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Limbur Merangin",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Karang Berahi/Birahi",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Keroya",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang Barat",
                "kelurahan": "Karang Anyar",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Empang Benao",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Pamenang",
                "kelurahan": "Jelatang",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Renah Pemenang",
                "kelurahan": "Bukit Bungkul",
                "kodepos": "37352"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Pematang Kandis",
                "kodepos": "37314"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Dusun Bangko",
                "kodepos": "37313"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Pasar Atas Bangko",
                "kodepos": "37312"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Titian Teras",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Tambang Nibung",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Telun",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Tambang Besi",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Sungai Putih",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Sungai Ulak",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Sungai Kapas",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Salam Buku",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Rantau Alai",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Pulau Rengas",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Pulau Rengas Ulu",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Pulau Layang",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Pulau Baru",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Pelangki",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Pasar Bangko",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Nalo Gedang",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Mudo (Dusun Mudo)",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Mudo (Dusun Mudo)",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Mentawak",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Lubuk Gaung",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Langling",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Kungkai",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Batang Masumai",
                "kelurahan": "Kederasan Panjang",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Danau",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Bukit Beringin",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Bedeng Rejo",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Bangko Barat",
                "kelurahan": "Biuku Tanjung",
                "kodepos": "37311"
            },
            {
                "kecamatan": "Nalo Tantan",
                "kelurahan": "Aur Berduri",
                "kodepos": "37311"
            }
        ],
        "k52": [
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Tenam",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Telentam",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Teluk Pandak",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Tanjung",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Tanah Periuk",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Sungai Tembang",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Tanah Bekali",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Sungai Puri",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Sungai Puri",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Sungai Lilin",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Sungai Gambir",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Rantau Embacang",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Rantau Makmur",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Pematang Panjang",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Pasar Lubuk Landai",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Pasar Rantau Embacang",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Paku Aji",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Lubuk Landai",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal Lintas",
                "kelurahan": "Embacang Gedang",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Empelu",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Tanah Sepenggal",
                "kelurahan": "Candi",
                "kodepos": "37263"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Sungai Beringin",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Sungai Gurun",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Sekampil",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Senamat",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Rantel",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Mulya Jaya",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Rantau Keloyang",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Mulya Bakti",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Gapura Suci",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Cilodang",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Dwi Karya Bakti",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Baru Pelepat",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Batu Kerbau",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Bukit Telago",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Timbolasi",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Pelepat",
                "kelurahan": "Balai Jaya",
                "kodepos": "37262"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Talang Sungai Bungo",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Senamat Ulu",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Sungai Telang",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Rantau Duku",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Rantau Pandan",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Lubuk Mayan",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Muara Buat",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Leban",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Rantau Pandan",
                "kelurahan": "Lubuk Kayu Aro",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Lubuk Beringin",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Laman Panjang",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Buat",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Karak Apung",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Bathin III Ulu",
                "kelurahan": "Aur Cino",
                "kodepos": "37261"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Tepian Danto",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Talang Pemesun (Pamesun)",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Tanjung Belit",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Sirih Sekapur Perkembangan",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Sirih Sekapur",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Sari Mulya",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Pulau Jelmu",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Rantau Ikil",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Rantau Panjang",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Pulau Batu",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Lubuk Tenam",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Jumbak",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Kuamang",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan",
                "kelurahan": "Baru Balai Panjang",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Bukit Sari",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Jujuhan Ilir",
                "kelurahan": "Aur Gading",
                "kodepos": "37257"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Teluk Kecimbung",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Tebing Tinggi Uleh",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Tanah Tumbuh",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Bathin II Pelayang",
                "kelurahan": "Seberang Jaya",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Bathin II Pelayang",
                "kelurahan": "Talang Silungko",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Rambah",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Renah Jelmu",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Bathin II Pelayang",
                "kelurahan": "Pulau Kerakap",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Perenti Lueh/Luweh",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Bathin II Pelayang",
                "kelurahan": "Pelayang",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Bathin II Pelayang",
                "kelurahan": "Peninjau",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Pedukun",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Lubuk Niur",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Panjang",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Bukit Kemang Baru",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Tanah Tumbuh",
                "kelurahan": "Koto Jayo",
                "kodepos": "37255"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Sumber Mulya",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Tirta Mulya",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Purwasari",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Sumber Harapan",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Padang Palangeh",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Muara Kuamang",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Lubuk",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Maju Jaya",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Lingga Kuamang",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Lembah Kuamang",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Kuamang Jaya",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Kuning Gading",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Karya Harapan Mukti",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Koto Jayo",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Danau",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Daya Murni",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Pelepat Ilir",
                "kelurahan": "Bangun Harjo/Harja",
                "kodepos": "37252"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Tebat",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Pekan Jum&#8217;at",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Suka Jaya",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Tanjung Agung",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Mangun Jayo",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Datar",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Bedaro",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Muko-Muko Batin VII",
                "kelurahan": "Baru Pusat Jalo",
                "kodepos": "37216"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Tuo Sepunggur",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Tuo Lubuk Mengkuang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Teluk Panjang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Tebo Jaya",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Tuo Limbur",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Tanjung Menanti",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Tanjung Gedang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Tanjung Bungo",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Rimbo Tengah",
                "kelurahan": "Sungai Mengkuang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bungo Dani",
                "kelurahan": "Sungai Pinang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bungo Dani",
                "kelurahan": "Talang Pantai",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bungo Dani",
                "kelurahan": "Sungai Kerjan",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Rimbo Tengah",
                "kelurahan": "Sungai Buluh",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Sungai Binjai",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bungo Dani",
                "kelurahan": "Sungai Arang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Suka Makmur",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Sepunggur",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Simpang Babeko",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Sarana Jaya",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Renah Sungai Ipuh",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Sekar Mengkuang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Renah Sungai Besar",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bungo Dani",
                "kelurahan": "Pulau Pekan",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Purwo Bakti",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Rantau Tipu",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Pemunyian",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Pauh Agung",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Muara/Muaro Tebo Pandak",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Rimbo Tengah",
                "kelurahan": "Pasir Putih",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Lubuk Tanah Terban",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Manggis",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Limbur Baru",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Lubuk Benteng",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Jaya Setia",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Rimbo Tengah",
                "kelurahan": "Candika",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Bungo Timur",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin III",
                "kelurahan": "Bungo Taman Agung",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Batang Bungo",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Bungo Barat",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Pasar Muara Bungo",
                "kelurahan": "Baru Teluk Panjang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Limbur Lubuk Mengkuang",
                "kelurahan": "Baru Lubuk Mengkuang",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Babeko",
                "kodepos": "37211"
            },
            {
                "kecamatan": "Bathin II Babeko",
                "kelurahan": "Babeko",
                "kodepos": "37211"
            }
        ],
        "k53": [
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Tamiai",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Tarutung",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pulausangkar",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Seberang Merangin",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pondok",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pulaupandan",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pengasi Baru",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pengasi Lama",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Pematang Lingkung",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Pasar Tamiai",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Muak",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Lubuk Paku",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Muara Hemat",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Karang Pandan",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Batang Merangin",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Batang Merangin",
                "kelurahan": "Baru Pulau Sangkar",
                "kodepos": "37175"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Talang Kemuning",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Tanjung Syam",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Selampaung",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Sungai Hangat",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Muaro Lulo",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Pasar Kerman",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Perikan Tengah",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Masgo",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Manjunto Lempur",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Lolo Hilir",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Lolo Kecil",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Lolo Gedang",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Lempur Mudik",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Lempur Tengah",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Kebun Lima",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Lempur Hilir",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Kebun Baru",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Dusun Baru Lempur",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Bukitkerman",
                "kelurahan": "Bintang Marak",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Gunung Raya",
                "kelurahan": "Air Mumu",
                "kodepos": "37174"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Tanjung Pauh Mudik",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Tanjung Batu",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Tanjung Pauh Hilir",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Talang Lindung",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Talago Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Sumur Jauh",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Semerap",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Serumpun Pauh",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Punai Merindu",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pulautengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pondok Siguang",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pidung",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Permai Baru",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pasar Semerap",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pasar Jujun",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pancuran Tiga",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Pancuran Bangko",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Lempur Danau",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Limok Manaih Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Tuo Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Patah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Baru Semerap",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Dian Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Baru",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Koto Agung",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Keluru",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Jembatan Merah Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Jujun",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Dusun Baru Pulau Tengah",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Bukitpulai",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Keliling Danau",
                "kelurahan": "Benik",
                "kodepos": "37173"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Ujung Pasir",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Tanjung Tanah",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Tanjungharapan",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Talang Kemulun",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Simpang Empat",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Seleman",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Sanggaran Agung",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Pendung Talang Genting",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Pasar Sore Seleman",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Tuo Ujung Pasir",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Tengah",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Salak",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Petai",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Iman",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Koto Baru Sanggaran Agung",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Dusun Baru Tanjung Tanah",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Agung Koto Iman",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Danau Kerinci",
                "kelurahan": "Cupak",
                "kodepos": "37172"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Tanjung Mudo",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Semerah",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Sebukar",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Pendung Tengah",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Pondok Beringin",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Pendung Hilir",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Penawar Tinggi",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Koto Sekilan Ambai",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Hiang Tinggi",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Koto Baru Hiang",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Hiang Sakti",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Hiang Lestari",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Hiang Karya",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Betung Kuning",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Baru Semerah",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Angkasa Pura",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Ambai Bawah",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Ambai Atas",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Telun Berasap",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Tanjung Bungo",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Tangkil",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sungai Tanduk",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Sungai Sikai",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Sungai Rumpun",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sungai Sampun",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sungai Renah",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sungai Kering",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sungai Lintang",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Sungai Jernih",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sungai Jambu",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sungai Bendung Air",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sungai Dalam",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sangir Tengah",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sungai Asam",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Sangir",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Sako Duo/Dua",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Renah Kasak",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Pelompek Pasar Baru",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Pesisir Bukit",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Pauh Tinggi",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Pelompek",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Pasar Sungai Tanduk",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Patok Empat",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Mekar Sari",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Pasar Minggu",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Lubuk Pauh",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Mekar Jaya",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Lindung Jaya",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Koto Tuo",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Koto Periang",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Koto Tengah",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Koto Baru",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Koto Panjang",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Kersik Tuo",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Kebun Baru",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Sitinjau Laut",
                "kelurahan": "Kayuaro Ambai",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Kampung Baru",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Jernih Jaya",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Giri Mulyo",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Gunung Labu",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Bumbun Duri",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Ensatu",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Bento",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Tujuh",
                "kelurahan": "Bengkolan Dua",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Bedeng Dua",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Bendung Air Timur",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Bedeng Baru",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Bedeng Delapan",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro Barat",
                "kelurahan": "Batu Hampar",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Kayu Aro",
                "kelurahan": "Batang Sangir",
                "kodepos": "37163"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Ujung Ladang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Tutung Bungkuk",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Telago Biru",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Tanjunggenting Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Tanjung Genting",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Sungai Lebuh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Talang Tinggi",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Sungai Pegeh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Sungai Langkap",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Sungai Kuning",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Sungai Gelampeh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Sungai Batu Gantih Hilir",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Sungai Betung Hilir",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Sungai Betung Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Suko Pangkat",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Sungai Batu Gantih",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Siulak Panjang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Siulak Tenang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Siulak Kecil Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Siulak Kecil Hilir",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Siulak Gedang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Siulak Deras Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Siulak Deras",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Simpang Tutup",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Senimpik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Pelak Gedang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Plak Naneh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Pasar Siulak Gedang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Pasir Jaya",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Padang Jantung",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Pasar Senen",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Tinggi",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Tengah",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Seberang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Pintu",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Lubuk Tabun",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Mukai Hilir",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Tengah",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Lubuk Nagodang",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Rendah",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak Mukai",
                "kelurahan": "Koto Lua",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Kapeh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Lebuh Tinggi",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Aro",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Koto Beringin",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Dusun Baru",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Dusun Dalam",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Demong Sakti",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Danau Tinggi",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Baru Sungai Pegeh",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Bendar Sedap",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Baru Sungai Betung Mudik",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Gunung Kerinci",
                "kelurahan": "Air Betung",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Siulak",
                "kelurahan": "Air Terjun",
                "kodepos": "37162"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Tebat/Tebak Ijuk",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Tebat Ijuk Dili",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Taman Jernih Sungaitutung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Tambak Tinggi",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Sungai Medang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Sungai Tutung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Sungai Abu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Simpang Empat Sungai Tutung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Semumu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Simpang Belui",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Sekungkung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Sawakan Koto Majidin",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Pungut Tengah",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Sawakan Jaya",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Pungut Hilir",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Pungut Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Pugu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Pondok Sungai Abu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Pendung Tengah",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Pendung Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Pendung Hilir",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Pasar Semurup",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Pahlawan Belui",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Muara Semerah Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Muara Semerah",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Ladeh",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Lubuk Suli",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Kubang Agung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Kubang Gedang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Koto Tuo",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Koto Tebat",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Tengah",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Koto Payang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Koto Simpai Kubang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Mebai",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Koto Panjang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Koto Majidin Hilir",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Koto Majidin Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Koto Lanang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Koto Majidin Di Air",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Duo/Dua Baru",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Koto Dua/Duo Lama",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Koto Diair",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Datuk",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Koto Cayo",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Raya",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Tinggi",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Mudik",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Hilir",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Kebalai",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Agung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Kemantan Darat",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Kayu Aho Mangkak Koto Lanang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Kecil",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Hamparan Pugu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Belui Tinggi",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Belui",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Baru Sungaimedang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Baru Sungaiabu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Baru Sungaideras",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Baru Sungai Tutung",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Baru Semurup",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Depati Tujuh",
                "kelurahan": "Baru Kubang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Baru Air Hangat",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Baru (Koto Baru Semurup)",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Balai",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat",
                "kelurahan": "Air Tenang",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Air Panas Sungaiabu",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Air Panas",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Barat",
                "kelurahan": "Air Bersih",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Air Hangat",
                "kodepos": "37161"
            },
            {
                "kecamatan": "Air Hangat Timur",
                "kelurahan": "Sungai Deras",
                "kodepos": "37152"
            }
        ],
        "k54": [
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Tanjung Karang",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Tanjung Bunga/Bungo",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Sembilan",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Pendung Hiang",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Mekar Jaya",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Tuo",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Tengah",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Pudung",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Panap",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Padang",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Dumo",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Koto Baru Tanah Kampung",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Tanah Kampung",
                "kelurahan": "Baru Debai",
                "kodepos": "37171"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Tanjung",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Tanjung Muda/Mudo",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Sungai Liuk",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Sumur Gedang",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Simpang Tiga Rawang",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Seberang",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Permai Indah",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Paling Serumpun",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Maliki Air",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Koto Teluk",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Larik Kemahan",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Koto Limau Manis",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Dua / Duo",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Koto Beringin",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Koto Dian",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Kampung Tengah",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Koto Baru (Kota Baru)",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Dusun Diilir",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Kampung Dalam",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Kampung Diilir",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Hamparan Rawang",
                "kelurahan": "Cempaka",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Dujung Sakti",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Baru Sri Menanti",
                "kodepos": "37152"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Pondok Tinggi",
                "kodepos": "37114"
            },
            {
                "kecamatan": "Sungai Penuh",
                "kelurahan": "Pasar Sungai Penuh",
                "kodepos": "37113"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Dusun Baru",
                "kodepos": "37112"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Ulu Air",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Talang Lindung",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Penuh",
                "kelurahan": "Sungai Penuh",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Sungai Ning",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Sumur Anyir",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Sungai Jernih",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Sandaran Galeh",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Pondok Agung",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Renah Kayu Embun",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Permanti",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Pinggir Air",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Penuh",
                "kelurahan": "Pasar Baru",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Pelayang Raya",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Lawang Agung",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Muara Jaya",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Kumun Hilir",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Kumun Mudik",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Tengah",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Bungkal",
                "kelurahan": "Koto Tinggi",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Renah",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Koto Lembu/Lebu",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Lolo",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Bento",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pesisir Bukit",
                "kelurahan": "Koto Keras",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Penuh",
                "kelurahan": "Gedang",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Karya Bakti",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Pondok Tinggi",
                "kelurahan": "Aur Duri",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Debai",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Kumun Debai",
                "kelurahan": "Air Teluk",
                "kodepos": "37111"
            },
            {
                "kecamatan": "Sungai Penuh",
                "kelurahan": "Amar Sakti",
                "kodepos": "37111"
            }
        ],
        "k55": [
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Lokan",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Sayang",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Itik",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Jambat",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Cemara",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Remau Baku Tuo",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Sungai Benuh",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Air Hitam (Laut)",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Sadu",
                "kelurahan": "Labuhan Pering",
                "kodepos": "36773"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Rantau Rasau II",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Sungai Dusun",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Tri Mulya/Mulyo",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Rantau Jaya",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Rantau Rasau I",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Marga Mulya",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Pematang Mayan",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Harapan Makmur",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Karya Bakti",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Bandar Jaya",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Rantau Rasau",
                "kelurahan": "Bangun Karya",
                "kodepos": "36772"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Teluk Kijing",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Sungai Raya",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Sungai Tering",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Simpang Jelita",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Sungai Jeruk",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Pemusiran",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Simpang Datuk",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Nipah Panjang I",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Nipah Panjang II",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Nipah Panjang",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "36771"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Sungai Toman",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Sungai Tawar",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Sungai Beras",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Suka Maju",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Sinar Wajo",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Sinar Kalimantan",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Simpang Tuan",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Rantau Karya",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Pangkal Duri Ilir",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Pangkalan/Pangkal Duri",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Pematang Rahim",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Pandan Makmur",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Pandan Sejahtera",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Pandan Lagan/Logan",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Pandan Jaya",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Merbau",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Mendahara Tengah",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Mencolok",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Mendahara Ilir",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Lagan Tengah",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Lagan Ulu",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Geragai",
                "kelurahan": "Kota Baru",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Lagan Ilir",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara",
                "kelurahan": "Bhakti/Bakti Idaman",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Mendahara Ulu",
                "kelurahan": "Bukit Tempurung",
                "kodepos": "36764"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Rantau Indah",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Sido Mukti",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Kuala Dendang",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Kuta/Koto Kandis Dendang",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Jati Mulyo",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Koto Kandis",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Teluk Majelis",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Dendang",
                "kelurahan": "Catur Rahayu",
                "kodepos": "36763"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Teluk Dawan",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Talang Babat",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Tanjung Solok",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Siau Dalam",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Simbur Naik",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Sungai Ular",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Parit Culum II",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Rano",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Nibung Putih",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Parit Culum I",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Muara Sabak Ilir",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Muara Sabak Ulu",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Manunggal Makmur",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Majelis Hidayah",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Lambur",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Lambur I",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Lambur II",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Kuala Lagan",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Kota Raja",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Kuala Simbur",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Kota Harapan",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Barat",
                "kelurahan": "Kampung Singkep",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Kuala Jambi",
                "kelurahan": "Kampung Laut",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Muara Sabak Timur",
                "kelurahan": "Alang-Alang",
                "kodepos": "36761"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Sungai Rambut",
                "kodepos": "36572"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Telaga Lima (Telago Limo)",
                "kodepos": "36572"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Rawasari",
                "kodepos": "36572"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Simpang",
                "kodepos": "36572"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Rantau Makmur",
                "kodepos": "36572"
            },
            {
                "kecamatan": "Berbak",
                "kelurahan": "Rantau Rasau",
                "kodepos": "36572"
            }
        ],
        "k56": [
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Teluk Ketapang",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Ture",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Teluk",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Serasah",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Simpang Kubu Kandang",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Senaning",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Pulau Raman",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Selat",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Olak Rambahan",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Pulau Betung",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Kubu Kandang",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Lopak Aur",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Lubuk Ruso",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Kaos",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Kuap",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Bukit Sare",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Jembatan Mas",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Pemayung",
                "kelurahan": "Awin",
                "kodepos": "36657"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Simpang Jelutih",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Simpang Karmeo",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Terentang Baru",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Olak Besar",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Paku Aji",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Muara Jangga",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Mata Gual",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Karmeo",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Koto Buayo",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Jangga Baru",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Jelutih",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Jangga Aur",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Durian Luncuk",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Hajran",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Bulian Baru",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Batin XXIV",
                "kelurahan": "Aur Gading",
                "kodepos": "36656"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Tidar Kuranji",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Terusan",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Teluk Leban",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Sungai Ruan Ulu",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Sungai Lingkar",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Sungai Ruan Ilir",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Simpang Sungai Rengas",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Rengas Sembilan",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Peninjauan",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Padang Kelapo",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Olak Kemang",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Kembang Seri",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Kehidupan Baru",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Kampung Baru",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Karyamukti",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Danau Embat",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Buluh Kasab",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Bulian Jaya",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ilir",
                "kelurahan": "Bukit Sari",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Maro Sebo Ulu",
                "kelurahan": "Batu Sawar",
                "kodepos": "36655"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Teluk Melintang",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Tapah/Tapak Sari",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Sungai Puar",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Simpang Rantau Gedang",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Sengkati Kecil",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Sengkati Gedang",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Rantau Gedang",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Sengkati Baru",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Pematang Gadung",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Mersam IV (Bukit Kemuning)",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Mersam II (Belanti Jaya)",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Mersam",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Kembang Tanjung",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Kembang Paseban",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Bukit Harapan",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Mersam",
                "kelurahan": "Benteng Rendah",
                "kodepos": "36654"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Tanjung Marwo",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Sungai Pulau/Pulai",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Rantau Kapas Tuo",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Sukaramai",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Rambutan Masam",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Rantau Kapas Mudo",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Pelayangan",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Pulau",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Kampung Baru",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Pasar Muara Tembesi",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Ampelu Mudo (Empelu)",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Jebak",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Tembesi",
                "kelurahan": "Ampelu (Empelu)",
                "kodepos": "36653"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Sridadi",
                "kodepos": "36614"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Teratai",
                "kodepos": "36612"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Muara Bulian",
                "kodepos": "36613"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Sungkai",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Sungai Buluh",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Tenam",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Singkawang",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Sungai Baung",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Simpang Terusan",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Rengas Condong",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Rambahan (Pelayangan Rambahan)",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Rantau Puri",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Pompa Air",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Penerokan",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Petajen (Petajin)",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Pasar Baru",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Pasar Terusan",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Napal Sisik",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Olak",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Muara Singoan",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Mekar Jaya",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Malapari",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Ladang Peris",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Kilangan",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Bungku",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Bajubang Laut",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Bajubang",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Bajubang",
                "kelurahan": "Batin",
                "kodepos": "36611"
            },
            {
                "kecamatan": "Muara Bulian",
                "kelurahan": "Aro",
                "kodepos": "36611"
            }
        ],
        "k57": [
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Tungkal I",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Tungkal II",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Tungkal III",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Tungkal Empat Kota",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Tungkal Harapan",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Teluk Sialang",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Telukkulbi",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Terjun Gajah",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Sungainibung",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Sungai Tera",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Sungai Gebar",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Sungai Gebar Barat",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Sungai Dualap",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Sungai Dungun",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Serdang Jaya",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Suak Labu",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Sriwijaya",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Pematang Buluh",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Pematang Lumut",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Muntialo",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Patunas",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Mandala Jaya",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Mekar Jaya",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Lubuk Terentang",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Makmur Jaya",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Kuala Indah",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Kampung Nelayan",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Dataran Pinang",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Betara",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Betara Kanan",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Kuala Betara",
                "kelurahan": "Betara Kiri",
                "kodepos": "36555"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Tanjung Makmur",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Tanjung Paku",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Tanah Tumbuh",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Tanjung Benanak",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Sungai Papauh",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Sungai Pau",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Sungai Muluk",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Rantau Benar",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Sei/Sungai Rotan",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Rantau Badak Lamo",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Pulau Pauh",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Rantau Badak",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Penyabungan",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Pinang Gading",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Pematang Balam",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Muara Danau",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Lubuk Terap",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Merlung (Desa)",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Merlung (Kelurahan)",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Lubuk Kambing",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Lubuk Sebontan",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Kemang Manis",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Lampisi",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Dusun Mudo",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Intan Jaya",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Muara Papalik",
                "kelurahan": "Bukit Indah",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Cinta Damai",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Bukit Harapan",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Renah Mendaluh",
                "kelurahan": "Bukit Bakar",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Merlung",
                "kelurahan": "Adi Purwa",
                "kodepos": "36554"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Teluk Nilau",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Teluk Ketapang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Sungai Raya",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Sungsang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Sungai Pampang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Sungai Rambai",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Sungai Kepayang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Sungai Landak",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Sungai Jering",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Sungai Kayu Aro",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Suak Samin",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Sungai Baung",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Sei/Sungai Serindit",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Senyerang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Pasar Senin",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Parit Sidang",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Parit Bilal",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Parit Pudin",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Margo Rukun",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Mekar Jati",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Lumahan",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Senyerang",
                "kelurahan": "Kempas Jaya",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Pengabuan",
                "kelurahan": "Karya Maju",
                "kodepos": "36553"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Telukpengkah",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Tanjung Tayas",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Tanjung Bojo",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Talang Makmur",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Taman Raja",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sungaikeruh",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Sungai Badar",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Sungai Penoban",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sukadamai",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Suban",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Rawang Kempas",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Sri Agung",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Pematang Tembesu",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Purwodadi",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Rawa Medang",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Pelabuhan Dagang",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Pematang Pauh",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Lubuk Bernai",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Lubuk Lawas",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Kuala Dasal",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kelagian",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Kampung Baru",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Gemuruh",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Batang Asam",
                "kelurahan": "Dusun Kebon/Kebun",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Brasau",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Dataran Kempas",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Delima",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Badang",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tungkal Ulu",
                "kelurahan": "Badang Sepakat",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Adi Jaya",
                "kodepos": "36552"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Tungkal Empat (IV) Desa",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Tungkal Lima (V)",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Teluk Pulai Raya",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Tanjung Senjulang",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Pembengis",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Semau",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Mekar Tanjung",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Pantai Gading",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Kuala Baru",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Jati Emas",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Kemuning",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Bram Itan Kanan (Beram Itam)",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Bram Itan Kiri (Beram Itam)",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Bram Itam",
                "kelurahan": "Bram Itam Raya",
                "kodepos": "36514"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Muara Seberang",
                "kodepos": "36511"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Kuala Kahar",
                "kodepos": "36511"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Mekar Alam",
                "kodepos": "36511"
            },
            {
                "kecamatan": "Seberang Kota",
                "kelurahan": "Harapan Jaya",
                "kodepos": "36511"
            }
        ],
        "k58": [
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Teluk Jambu",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Tanjung Katung",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Tebat Patah",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Setiris",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Sekumbung",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Talang Duku",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Mudung Darat",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Niaso",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Mudo",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Maro Sebo",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Lubuk Raman",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Muara/Muaro Jambi",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Kemingking Luar",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Kunangan",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Kemingking Dalam",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Jambi Kecil",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Jambi Kulo/Tulo",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Danau Lamo",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Baru",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Danau Kedap",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Maro Sebo",
                "kelurahan": "Bakung",
                "kodepos": "36382"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Tunas Baru",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Tunas Baru",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Tanjung Lanjut",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Tantan",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Suak Putat",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Suko Awin Jaya",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Sekernan",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Sengeti",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Pematang Pulai/Pule",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Pulau Kayu Aro",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Rantau Majo",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Kedotan",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Keranggan",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Bukit Baling",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Gerunggung",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sekernan",
                "kelurahan": "Berembang",
                "kodepos": "36381"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Trimulya Jaya",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Teluk Raya",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Tarikan",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Tangkit",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Tangkit Baru",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Sungai Terap",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Sungai Gelam",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Solok",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Sumber Agung",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Sumber Jaya",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Sakean",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Sipin Teluk Duren",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Sido Mukti",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Pudak",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Ramin",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Parit",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Petaling Jaya",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Pemunduran",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Mekar Jaya",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Mingkung Jay",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Muara Kumpeh",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Kota/Koto Karang",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Lopak Alai",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Kasang Kumpeh",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Kasang Pudak",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Kasang Lopak Alai",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Arang Arang",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh Ulu",
                "kelurahan": "Kasang Kota Karang",
                "kodepos": "36373"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Sungai Aur",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Sungai Bungur",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Tanjung",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Seponjen",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Sogo",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Rukam",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Rondang",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Rantau Panjang",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Petanang",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Puding",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Pulau Mentaro",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Mekar Sari",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Pematang Raman",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Taman Rajo / Rejo",
                "kelurahan": "Manis Mato",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Maju Jaya",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Jebus",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Londrang (Londerang)",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Betung",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Kumpeh",
                "kelurahan": "Gedong Karya",
                "kodepos": "36371"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Tanjungmulya (Tanjung Mulia)",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Trijaya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Tanjung Lebar",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Tanjung Sari",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Tanjung Baru",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Sungai Dayo",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Talang Bukit",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Talang Datar",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Suka Makmur",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Sumber Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Panca Bakti",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Panca Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Pinang Tinggi",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Mulya Jaya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Mekarsari Makmur",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Matra Manunggal",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Marga Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Markanding",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Bukit Subur",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Marga Manunggal Jaya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Bukit Makmur",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Bukit Mas",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Bukit Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Bukit Jaya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Berkah (Desa Berkah)",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Sungai Bahar",
                "kelurahan": "Bakti Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Utara",
                "kelurahan": "Bahar Mulya",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Bahar Selatan",
                "kelurahan": "Adipura Kencana",
                "kodepos": "36365"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Tempino",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Tanjung Pauh Km. 39",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Tanjungpauh Talang Pelita",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Tanjung Pauh",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Talang Belido",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Talang Kerinci",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Sungai Landai",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Suka Maju",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Suka Damai",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Pelempang",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Pondok Meja",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Sebapo",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Nyogan",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Ladang Panjang",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Muaro Sebapo",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Naga Sari",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Sungai Gelam",
                "kelurahan": "Kebon Sembilan (IX)",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Ibru",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Sungai Duren",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Mestong",
                "kelurahan": "Baru",
                "kodepos": "36364"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Simpang Sungai/Sei Duren",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Sungai Bertam",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Senaung",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Simpang Limo",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Sembubuk",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Rengas Bandung",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Sarang Burung",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Penyengat Olak",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Pijoan",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Pematang Jering",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Muhajirin",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Pematang Gajah",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Mendalo Laut",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Muara Pijoan (Muaro Pijoan)",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Kademangan",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Mendalo Darat",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Mendalo Indah",
                "kodepos": "36361"
            },
            {
                "kecamatan": "Jambi Luar Kota",
                "kelurahan": "Danau Sarang Elang",
                "kodepos": "36361"
            }
        ],
        "k59": [
            {
                "kecamatan": "Danau Teluk",
                "kelurahan": "Pasir Panjang",
                "kodepos": "36265"
            },
            {
                "kecamatan": "Danau Teluk",
                "kelurahan": "Tanjung Raden",
                "kodepos": "36264"
            },
            {
                "kecamatan": "Danau Teluk",
                "kelurahan": "Olak Kemang",
                "kodepos": "36262"
            },
            {
                "kecamatan": "Danau Teluk",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "36263"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Tanjung Johor",
                "kodepos": "36256"
            },
            {
                "kecamatan": "Danau Teluk",
                "kelurahan": "Ulu Gedong",
                "kodepos": "36261"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Tengah",
                "kodepos": "36254"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Tahtul Yaman",
                "kodepos": "36255"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Mudung Laut",
                "kodepos": "36252"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Jelmu",
                "kodepos": "36253"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Sijenjang (Sijinjang)",
                "kodepos": "36149"
            },
            {
                "kecamatan": "Pelayangan",
                "kelurahan": "Arab Melayu",
                "kodepos": "36251"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Tanjung Sari",
                "kodepos": "36147"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Payo Selincah/Silincah",
                "kodepos": "36148"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Tanjung Pinang",
                "kodepos": "36146"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Sulan Jana",
                "kodepos": "36144"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Budiman",
                "kodepos": "36145"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Rajawali",
                "kodepos": "36143"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Talang Banjar",
                "kodepos": "36142"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Kasang",
                "kodepos": "36141"
            },
            {
                "kecamatan": "Jambi Timur",
                "kelurahan": "Kasang Jaya",
                "kodepos": "36141"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Tambak Sari",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Pasir Putih",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Talang Bakung",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Paal Merah",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Lingkar Selatan",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Eka Jaya",
                "kodepos": "36139"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "The Hok",
                "kodepos": "36138"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Kebun Handil",
                "kodepos": "36137"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Jelutung",
                "kodepos": "36136"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Handil Jaya",
                "kodepos": "36137"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Payo Lebar",
                "kodepos": "36135"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Lebak Bandung",
                "kodepos": "36135"
            },
            {
                "kecamatan": "Pasar Jambi",
                "kelurahan": "Sungai Asam",
                "kodepos": "36134"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Talang Jauh",
                "kodepos": "36133"
            },
            {
                "kecamatan": "Jelutung",
                "kelurahan": "Cempaka Putih",
                "kodepos": "36134"
            },
            {
                "kecamatan": "Pasar Jambi",
                "kelurahan": "Pasar Jambi",
                "kodepos": "36133"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Pakuan Baru",
                "kodepos": "36132"
            },
            {
                "kecamatan": "Jambi Selatan",
                "kelurahan": "Wijaya Pura",
                "kodepos": "36131"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Selamat",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Pematang Sulur",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Mayang Mangurai",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Kenali Besar",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Beliung Patah",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Bagan Pete",
                "kodepos": "36129"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Kenali Asam Bawah",
                "kodepos": "36128"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Paal Lima",
                "kodepos": "36128"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Kenali Asam Atas",
                "kodepos": "36128"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Suka Karya",
                "kodepos": "36127"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Simpang Tiga Sipin",
                "kodepos": "36126"
            },
            {
                "kecamatan": "Kota Baru",
                "kelurahan": "Rawa Sari",
                "kodepos": "36125"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Simpang Empat Sipin",
                "kodepos": "36124"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Teluk Kenali",
                "kodepos": "36123"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Penyengat Rendah",
                "kodepos": "36124"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Telanaipura",
                "kodepos": "36122"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Buluran Kenali",
                "kodepos": "36123"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Sungai Putri",
                "kodepos": "36122"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Solok Sipin",
                "kodepos": "36121"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Murni",
                "kodepos": "36121"
            },
            {
                "kecamatan": "Telanaipura",
                "kelurahan": "Legok",
                "kodepos": "36121"
            },
            {
                "kecamatan": "Pasar Jambi",
                "kelurahan": "Beringin",
                "kodepos": "36112"
            },
            {
                "kecamatan": "Pasar Jambi",
                "kelurahan": "Orang Kayo Hitam",
                "kodepos": "36111"
            }
        ],
        "k60": [
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Wandasari",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Purwaraharja",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Sindangsari",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Toblongan",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Pedangkamulyan",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Mertajaya",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Mangkonjaya",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Kertanegla",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Girijaya",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Ciroyom",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Girimukti",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Cikadongdong",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Campakasari",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Bojonggambir",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Bojonggambir",
                "kelurahan": "Bojongkapol",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Singasari",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Taraju",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Bojongasih",
                "kelurahan": "Bojongasih",
                "kodepos": "46475"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Purwarahayu",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Raksasari",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Raksasari",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Deudeul",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Kertaraharja",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Cikubang",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Taraju",
                "kelurahan": "Banyuasih",
                "kodepos": "46474"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Sukabakti",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Sodonghilir",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Raksajaya",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Sepatnunggal",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Pakalongan",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Parumasan",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Leuwidulang",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Muncang",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Cukangjayaguna",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Cukangkawung",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Cikalong",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Sodonghilir",
                "kelurahan": "Cipaingeun",
                "kodepos": "46473"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Tanjungsari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Tenjowaringin",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Sundawenang",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Sukasari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Serang",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Sukarasa",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Salawu",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Puspasari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Puspahiang",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Puspajaya",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Pusparahayu",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Neglasari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Margalaksana",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Mandalasari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Kutawaringin",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Luyubakti",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Jahiang",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Karangmukti",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Salawu",
                "kelurahan": "Kawungsari",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Padakembang",
                "kelurahan": "Rancapaku",
                "kodepos": "46466"
            },
            {
                "kecamatan": "Puspahiang",
                "kelurahan": "Cimanggu",
                "kodepos": "46471"
            },
            {
                "kecamatan": "Padakembang",
                "kelurahan": "Mekarjaya",
                "kodepos": "46466"
            },
            {
                "kecamatan": "Padakembang",
                "kelurahan": "Padakembang",
                "kodepos": "46466"
            },
            {
                "kecamatan": "Padakembang",
                "kelurahan": "Cilampunghilir",
                "kodepos": "46466"
            },
            {
                "kecamatan": "Padakembang",
                "kelurahan": "Cisaruni",
                "kodepos": "46466"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Sukamulih",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Sirnasari",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Sukaharja (Sukaraharja)",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Sariwangi",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Selawangi",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Linggasirna",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Jayaratu",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Sariwangi",
                "kelurahan": "Jayaputra",
                "kodepos": "46465"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Mandalagiri",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Linggawangi",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Jayamukti",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Linggamulya",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Cigadog",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Ciawang",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Leuwisari",
                "kelurahan": "Arjasari",
                "kodepos": "46464"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Tenjonagara",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Tanjungkarang",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Sukamanah",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Sirnaraja",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Sirnaputra",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Sirnagalih",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Puspamukti",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Pusparaja",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Parentas",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Nangtang",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Nanggerang",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Lengkongjaya",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Kersamaju",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Jayapura (Jayaputra)",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Cigalontang",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Cigalontang",
                "kelurahan": "Cidugaleun",
                "kodepos": "46463"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Sukasukur",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Sukaluyu",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Salebu",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Pasirsalam",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Margajaya",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Mangunreja",
                "kelurahan": "Mangunreja",
                "kodepos": "46462"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Wargakerta",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Sukarapih",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Sukarame",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Suka Menak",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Sukakarsa",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Padasuka",
                "kodepos": "46461"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Tanjungsari",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Tanjungsari",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Sukaresik",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Sukaratu",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Sukapancar",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Sukamenak",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Malatisuka",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Margamulya",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Jatijaya",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Giriwangi",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Gunung Tanjung",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Cinunjang",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Cipondok",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Cikunir",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Cikadongdong",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Gunung Tanjung",
                "kelurahan": "Bojongsari",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Sukaresik",
                "kelurahan": "Banjarsari",
                "kodepos": "46418"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Cipakat",
                "kodepos": "46417"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Cintaraja",
                "kodepos": "46417"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Sukamulya",
                "kodepos": "46416"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Ciherang",
                "kodepos": "46416"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Tawangbanteng",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Sukaratu",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Sukagalih",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Sukamahi",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Sukaasih",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Linggajati",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Sinagar",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Indrajaya",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Cikunten",
                "kodepos": "46414"
            },
            {
                "kecamatan": "Sukaratu",
                "kelurahan": "Gunungsari",
                "kodepos": "46415"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Sukaherang",
                "kodepos": "46413"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Sukadana",
                "kodepos": "46413"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Singaparna",
                "kodepos": "46411"
            },
            {
                "kecamatan": "Singaparna",
                "kelurahan": "Singasari",
                "kodepos": "46412"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Panyingkiran",
                "kodepos": "46411"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Sirnajaya",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Rajadatu",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Pasirmukti",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Madiasari",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Nagaratengah",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Karangjaya",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Karanglayung",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Citalahab",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Cisarua",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Cineam",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Cijulang",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Cikondang",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Ancol",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Cineam",
                "kelurahan": "Ciampanan",
                "kodepos": "46198"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Pasirbatang",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Pasirpanjang",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Margahayu",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Margaluyu",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Kamulyan",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Manonjaya",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Gunajaya",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Kalimanggis",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Cibeber",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Cihaur",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Cilangkap",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Manonjaya",
                "kelurahan": "Batusumur",
                "kodepos": "46197"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Tamanjaya",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Tamansari",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sumelap",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Sukanagara",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Sukajaya",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Sukamenak",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukahurip",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Sukaasih",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Setiawargi",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Singkup",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Setianagara",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Setiaratu",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Setiamulya",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Setiajaya",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Mulyasari",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Purbaratu",
                "kelurahan": "Purbaratu",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Mugarsari",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Margabakti",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Kersanagara",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Kotabaru",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Ciakar",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Awipari",
                "kodepos": "46196"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Sindangjaya",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Singkir",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Tonjongsari",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Mandalajaya",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Panyiaran",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Kalapagenep",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Kubangsari",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cikancra",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cimanuk",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cikalong",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cikadu",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cibeber",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Cikalong",
                "kelurahan": "Cidadali",
                "kodepos": "46195"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Tawang",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Tonjong",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Pancawangi",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Pangliaran",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Mekarsari",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Neglasari",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Margaluyu",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Cikawung",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Jayamukti",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Cibongas",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Pancatengah",
                "kelurahan": "Cibuniasih",
                "kodepos": "46194"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Sindangasih",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Tanjungbarang",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Pakemitan",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Gunungsari",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Lengkongbarang",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Linggalaksana",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Cogreg",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Cayur",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Cikatomas",
                "kelurahan": "Cilumba",
                "kodepos": "46193"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Mandalawangi",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Mulyasari",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Tanjungsari",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Mandalaguna",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Mandalahayu",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Kawitan",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Karyamandala",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Karyawangi",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Salopa",
                "kelurahan": "Banjarwaringin",
                "kodepos": "46192"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Tobongjaya",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Sindangkerta",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Sukahurip",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Nangelasari",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Padawaras",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Pameutingan",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Kertasari",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Nagrog",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Darawati",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Cipanas",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Cipatujah",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Ciheras",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Cikawungading",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Sukamaju",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Ciandum",
                "kodepos": "46189"
            },
            {
                "kecamatan": "Culamega",
                "kelurahan": "Cipicung",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Culamega",
                "kelurahan": "Mekarlaksana",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Culamega",
                "kelurahan": "Cikuya",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Culamega",
                "kelurahan": "Cintabodas",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Culamega",
                "kelurahan": "Bojongsari",
                "kodepos": "46188"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Sirnagalih",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Wangunsari",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Parakanhonje",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Simpang",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Hegarwangi",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Pamijahan",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Cipatujah",
                "kelurahan": "Bantarkalong",
                "kodepos": "46187"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Sarimukti",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Sukawangun",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Kujang",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Sarimanggu",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Karangmekar",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Karangnunggal",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cikupa",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cintawangi",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cikapinis",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cikukulu",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cibatuireng",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cidadap",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Ciawi",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Karangnunggal",
                "kelurahan": "Cibatu",
                "kodepos": "46186"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Singajaya",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Sukakerta",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Setiawangi",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Setiawaras",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Parungponteng",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Parung",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Neglasari",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Papayan",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Mandalahurip",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Mandalamekar",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Kersagalih",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Kertarahayu",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Karyabakti",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Kaputihan",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Girikencana",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Jatiwaras",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Eureunpalay",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Jatiwaras",
                "kelurahan": "Ciwarak",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Cisempur",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Cigunung",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Cibungur",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Cibalong",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Cibanteng",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Barumekar",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Parungponteng",
                "kelurahan": "Burujuljaya",
                "kodepos": "46185"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Sukasenang",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Tanjungjaya",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Cintajaya",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Sukanagara",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Cilolohan",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Cikeusal",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Tarunajaya",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Tanjungjaya",
                "kelurahan": "Cibalanarik",
                "kodepos": "46184"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Sukaraja",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukapura",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Mekarjaya",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sirnajaya",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Linggaraja",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Margalaksana",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Janggala",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Leuwibudah",
                "kodepos": "46183"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Urug",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Talagasari",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Tanjung",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Karsamenak",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Leuwiliang",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Gunungtandala",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Karanganyar",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Cilamajang",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Gununggede",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Sambongpari",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Kawalu",
                "kelurahan": "Cibeuti",
                "kodepos": "46182"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Mangkubumi",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Sambongjaya",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Karikil",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Linggajaya",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Cipari",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Cipawitra",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Tanjungmekar",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Mangkubumi",
                "kelurahan": "Cigantang",
                "kodepos": "46181"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Sindangraja",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Karangsembung",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Karangresik",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Geresik",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Karangmulya",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Bojonggaok",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Jamanis",
                "kelurahan": "Condong",
                "kodepos": "46175"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Tanjungkerta",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Bantarkalong",
                "kelurahan": "Wakap",
                "kodepos": "46168"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Sukamaju",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Sukapada",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Pagersari",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Puteran",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Nanggewer",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Pagerageung",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Guranteng",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Pagerageung",
                "kelurahan": "Cipacing",
                "kodepos": "46158"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Pasirhuni",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Sukamantri",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Pamoyanan",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Pakemitan",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Pakemitankidul",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Margasari",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Mekarsari",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Kertamukti",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Kurniabakti",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Gombong",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Dirgahayu",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Kadipaten",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Cibahayu",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Ciawi",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Citamba",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Buniasih",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Bugel",
                "kodepos": "46156"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Sundakerta",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Tanjungpura",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Sukahening",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Rajapolah",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Sukanagalih",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Manggungjaya",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Manggungsari",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Rajamandala",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Kiarajangkung",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Kudadepa",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Calingcing",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Rajapolah",
                "kelurahan": "Dawagung",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Banyurasa",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Sukahening",
                "kelurahan": "Banyuresmi",
                "kodepos": "46155"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Sukasetia",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Sukasukur",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Sukaraharja",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Sukamukti",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Santanamekar",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Sukajadi",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Nusawangi",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Purwasari",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Jatihurip",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Mekarwangi",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Cileuleus",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Cisayong",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Cisayong",
                "kelurahan": "Cikadu",
                "kodepos": "46153"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Sukarindik",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Sukamulya",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Sukamajukaler",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Sukamajukidul",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Sukalaksana",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Sukajaya",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Sirnagalih",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Parakannyasag",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Indihiang",
                "kelurahan": "Indihiang",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Bungursari",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Cibunigeulis",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Cipedes",
                "kelurahan": "Panglayungan",
                "kodepos": "46134"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Bantarsari",
                "kodepos": "46151"
            },
            {
                "kecamatan": "Cipedes",
                "kelurahan": "Nagarasari",
                "kodepos": "46132"
            },
            {
                "kecamatan": "Cipedes",
                "kelurahan": "Cipedes",
                "kodepos": "46133"
            },
            {
                "kecamatan": "Cipedes",
                "kelurahan": "Sukamanah",
                "kodepos": "46131"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Tuguraja",
                "kodepos": "46125"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Tugujaya",
                "kodepos": "46126"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Cilembang",
                "kodepos": "46123"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Nagarawangi",
                "kodepos": "46124"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Yudanagara",
                "kodepos": "46121"
            },
            {
                "kecamatan": "Cihideung",
                "kelurahan": "Argasari",
                "kodepos": "46122"
            },
            {
                "kecamatan": "Tawang",
                "kelurahan": "Cikalang",
                "kodepos": "46114"
            },
            {
                "kecamatan": "Tawang",
                "kelurahan": "Kahuripan",
                "kodepos": "46115"
            },
            {
                "kecamatan": "Tawang",
                "kelurahan": "Tawangsari",
                "kodepos": "46112"
            },
            {
                "kecamatan": "Tawang",
                "kelurahan": "Empangsari",
                "kodepos": "46113"
            },
            {
                "kecamatan": "Tawang",
                "kelurahan": "Lengkongsari",
                "kodepos": "46111"
            }
        ],
        "k61": [
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Tunggilis",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Pamotan",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Putrapinggan",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Emplak",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Kalipucang",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Ciparakan",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Bagolo",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Banjarharja",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Kalipucang",
                "kelurahan": "Cibuluh",
                "kodepos": "46397"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Wonoharjo",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Sidomulyo",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Sukahurip",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Pangandaran",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Purbahayu",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Pagergunung",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Pananjung",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Pangandaran",
                "kelurahan": "Babakan",
                "kodepos": "46396"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Sukajaya",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Sindangsari",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Mekarsari",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Limusgede",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Masawah",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Kertaharja",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Kertamukti",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Legokjawa",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Ciparanti",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Batumalang",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cimerak",
                "kelurahan": "Cimerak",
                "kodepos": "46395"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Margacinta",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Kertayasa",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Kondangjajar",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Cibanten",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Cijulang",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Batukaras",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Cijulang",
                "kelurahan": "Ciakar",
                "kodepos": "46394"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Parigi",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Selasari",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Parakanmanggu",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Karangbenda",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Karangjaladri",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Cintakarya",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Cintaratu",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Cibenda",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Ciliang",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Pagerbumi",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Bojong",
                "kodepos": "46393"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Harum Mandala",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Kertajaya",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cigugur",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cimindi",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Bunisari",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Campaka",
                "kodepos": "46392"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Sukamulya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Mekarwangi",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Pangkalan",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Jayasari",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Karangkamiri",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Langkaplancar",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Jadimulya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Cimanggu",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Cisarua",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Jadikarya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Bojongkondang",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Bungur Raya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Bangunkarya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Bojong",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Langkaplancar",
                "kelurahan": "Bangunjaya",
                "kodepos": "46391"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Sindangwangi",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Panyutran",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Pasirgeulis",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Maruyung Sari",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Padaherang",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Paledah",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Kedungwuluh",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Karangsari",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Cibogo",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Karangmulya",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Karangpawitan",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Bojongsari",
                "kodepos": "46384"
            },
            {
                "kecamatan": "Mangunjaya",
                "kelurahan": "Sukamaju",
                "kodepos": "46371"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Ciganjeng",
                "kodepos": "46372"
            },
            {
                "kecamatan": "Mangunjaya",
                "kelurahan": "Mangunjaya",
                "kodepos": "46371"
            },
            {
                "kecamatan": "Mangunjaya",
                "kelurahan": "Sindang Jaya",
                "kodepos": "46371"
            },
            {
                "kecamatan": "Mangunjaya",
                "kelurahan": "Jangraga",
                "kodepos": "46371"
            },
            {
                "kecamatan": "Mangunjaya",
                "kelurahan": "Kertajaya",
                "kodepos": "46371"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Sidamulih",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Sukaresik",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Kersaratu",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Pajaten",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Cikembulan",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Kalijati",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Sidamulih",
                "kelurahan": "Cikalong",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Padaherang",
                "kelurahan": "Sukanagara",
                "kodepos": "46267"
            }
        ],
        "k62": [
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Tambaksari",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Mekarsari",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Sukasari",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Karangpaningal",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Kaso",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Wangunsari",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Kadupandak",
                "kodepos": "46388"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Situmandala",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Patakaharja",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Rancah",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Kawunglarang",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Kiarapayung",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Karangpari",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Jangalaharja",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Dadiharja",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Giriharja",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Cileungsir",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Cisontrol",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Rancah",
                "kelurahan": "Bojonggedang",
                "kodepos": "46387"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Sukahurip",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Tanjungjaya",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Wangunjaya",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Mekarmukti",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Sidamulya",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Karyamulya",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Kepel",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Danasari",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Girimukti",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Cisaga",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Cisaga",
                "kelurahan": "Bangunharja",
                "kodepos": "46386"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Sukanagara",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Tambakreja",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Sukamulya",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Sindangangin",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Sidarahayu",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Sidaharja",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Purwadadi",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Purwajaya",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Puloerang",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Pasirlawang",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Kutawaringin",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Padaringan",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Kertajaya",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Karangpaningal",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Kalapasawit",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Cintaratu",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Baregbeg",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Lakbok",
                "kelurahan": "Cintajaya",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Bantardawa",
                "kodepos": "46385"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Tanjungasari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sindangsari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sukasari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sindanghayu",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sindangrasa",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Ratawangi",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sindangasih",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Pasawahan",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Purwasari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kawasen",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Langkapsari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Ciulu",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kalijaya",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Karyamukti",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cikaso",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cikupa",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cigayam",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Ciherang",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cicapar",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Cibadak",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Banjaranyar",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Banjarsari",
                "kodepos": "46383"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sukajaya",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sukamukti",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sidaharja",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sukahurip",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sukajadi",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Pamarican",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Pasirnagara",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Margajaya",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Mekarmulya",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Neglasari",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Bangunsari",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Bantarsari",
                "kodepos": "46382"
            },
            {
                "kecamatan": "Cimaragas",
                "kelurahan": "Raksabaya",
                "kodepos": "46381"
            },
            {
                "kecamatan": "Cimaragas",
                "kelurahan": "Cimaragas",
                "kodepos": "46381"
            },
            {
                "kecamatan": "Cimaragas",
                "kelurahan": "Jayaraksa",
                "kodepos": "46381"
            },
            {
                "kecamatan": "Cimaragas",
                "kelurahan": "Beber",
                "kodepos": "46381"
            },
            {
                "kecamatan": "Cimaragas",
                "kelurahan": "Bojongmalang",
                "kodepos": "46381"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Sidamulih",
                "kodepos": "46365"
            },
            {
                "kecamatan": "Pamarican",
                "kelurahan": "Kertahayu",
                "kodepos": "46361"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Sukasari",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Janggala",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Jelegong",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Hegarmanah",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Ciparay",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Cidolog",
                "kodepos": "46352"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Sukamaju",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Sukamulya",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Pusakanagara",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Saguling",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Mekarjaya",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Petirhilir",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Jelat",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Karangampel",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Sukanagara",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Baregbeg",
                "kelurahan": "Baregbeg",
                "kodepos": "46274"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Dayeuhluhur",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Jatinagara",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Mulyasari",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Cintanagara",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Jatinagara",
                "kelurahan": "Bayasari",
                "kodepos": "46273"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Salakaria",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Margajaya",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Ciparigi",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Margaharja",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Bunter",
                "kodepos": "46272"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Pamalayan",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Utama",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Karangkamulyan",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Kertabumi",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Kertaharja",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Handapherang",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Karanganyar",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Dewasari",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Ciharalang",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Cijeungjing",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Wanasigra",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Cijeungjing",
                "kelurahan": "Bojongmengger",
                "kodepos": "46271"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Sukaresik",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Sukasenang",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Sukaraja",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Gunungcupu",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Sindangkasih",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Sukamanah",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Budiasih",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sindangkasih",
                "kelurahan": "Budiharja",
                "kodepos": "46268"
            },
            {
                "kecamatan": "Sukamantri",
                "kelurahan": "Sindanglaya",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Sukamantri",
                "kelurahan": "Sukamantri",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Sukamantri",
                "kelurahan": "Tenggerraharja",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Panjalu",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Sandingtaman",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Maparah",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Sukamantri",
                "kelurahan": "Mekarwangi",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Kertamandala",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Mandalare",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Ciomas",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Hujungtiwu",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Sukamantri",
                "kelurahan": "Cibeureum",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panjalu",
                "kelurahan": "Bahara",
                "kodepos": "46264"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Sukakerta",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Tanjungmulya",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Sindangherang",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Sindangmukti",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Payungagung",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Payungsari",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Sindangbarang",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Kertaraharja",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Medanglayang",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Panumbangan",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Jayagiri",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Jayagiri",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Banjarangsana",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Panumbangan",
                "kelurahan": "Buanamekar",
                "kodepos": "46263"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sukamulya",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sukasetia",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sumberjaya",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sukahurip",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sukamaju",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Pasirtamiang",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Sukahaji",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Pamokolan",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Cihaurbeuti",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Cijulang",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cihaurbeuti",
                "kelurahan": "Padamulya",
                "kodepos": "46262"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Sindangsari",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Nasol",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Panaragan",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Kujang",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Margaluyu",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Darmacaang",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Gegempalan",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Cikoneng",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Cikoneng",
                "kelurahan": "Cimari",
                "kodepos": "46261"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Sadewata",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Sukaraharja",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Lumbungsari",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Rawa",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Cikupa",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Darmaraja",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Lumbung",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Lumbung",
                "kelurahan": "Awiluar",
                "kodepos": "46258"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Tanjungsari",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Werasari",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Sukajadi",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Mekarjadi",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Sadananya",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Gunungsari",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Mangkubumi",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Sadananya",
                "kelurahan": "Bendasari",
                "kodepos": "46256"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Panawangan",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Sadapaingan",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Sagalaherang",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Nagarapageuh",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Nagarawangi",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Natanegara",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Nagarajati",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Nagarajaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Mekarbuana",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Kertayasa",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Karangpaningal",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Kertajaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Indragiri",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Jagabaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Gardujaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Girilaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Cinyasag",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Tigaherang",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Panawangan",
                "kelurahan": "Bangunjaya",
                "kodepos": "46255"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Tanjungsari",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Tanjungsukur",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Sukajaya",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Tanjungjaya",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Sirnajaya",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Sukaharja",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Rajadesa",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Sirnabaya",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Purwaraja",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Rajadesa",
                "kelurahan": "Andapraja",
                "kodepos": "46254"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Winduraja",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Talagasari",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Sindangsari",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Selasari",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Purwasari",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Linggapura",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Margamulya",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Kawalimukti",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Karangpawitan",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Kawali",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Kawali",
                "kelurahan": "Citeureup",
                "kodepos": "46253"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Sukawening",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Selamanik",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Selacai",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Pusakasari",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Muktisari",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Jalatrang",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Mekarsari",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Gereba",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Cieurih",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Cipaku",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Ciakar",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Buniseuri",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Cipaku",
                "kelurahan": "Bangbayang",
                "kodepos": "46252"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Imbanagara Raya",
                "kodepos": "46219"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Pawindan",
                "kodepos": "46218"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Imbanagara",
                "kodepos": "46219"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Benteng",
                "kodepos": "46217"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Linggasari",
                "kodepos": "46216"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Cisadap",
                "kodepos": "46215"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Sindangrasa",
                "kodepos": "46215"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Maleber",
                "kodepos": "46214"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Kertasari",
                "kodepos": "46213"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Panyingkiran",
                "kodepos": "46211"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Cigembor",
                "kodepos": "46212"
            },
            {
                "kecamatan": "Ciamis",
                "kelurahan": "Ciamis",
                "kodepos": "46211"
            }
        ],
        "k63": [
            {
                "kecamatan": "Langensari",
                "kelurahan": "Rejasari",
                "kodepos": "46344"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Muktisari",
                "kodepos": "46343"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Waringinsari",
                "kodepos": "46342"
            },
            {
                "kecamatan": "Purwaharja",
                "kelurahan": "Mekarharja",
                "kodepos": "46334"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Mulyasari",
                "kodepos": "46335"
            },
            {
                "kecamatan": "Purwaharja",
                "kelurahan": "Raharja",
                "kodepos": "46333"
            },
            {
                "kecamatan": "Purwaharja",
                "kelurahan": "Purwaharja",
                "kodepos": "46331"
            },
            {
                "kecamatan": "Purwaharja",
                "kelurahan": "Karangpanimbal",
                "kodepos": "46332"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Karyamukti",
                "kodepos": "46327"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Langensari",
                "kodepos": "46324"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Bojongkantong",
                "kodepos": "46325"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Bojongkantong",
                "kodepos": "46325"
            },
            {
                "kecamatan": "Langensari",
                "kelurahan": "Kujangsari",
                "kodepos": "46324"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Sinartanjung",
                "kodepos": "46323"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Sukamukti",
                "kodepos": "46323"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Pataruman",
                "kodepos": "46323"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Mekarsari",
                "kodepos": "46321"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Hegarsari",
                "kodepos": "46322"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Jajawar",
                "kodepos": "46317"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Neglasari",
                "kodepos": "46315"
            },
            {
                "kecamatan": "Pataruman",
                "kelurahan": "Binangun",
                "kodepos": "46316"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Cibeureum",
                "kodepos": "46313"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Situbatu",
                "kodepos": "46314"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Banjar",
                "kodepos": "46311"
            },
            {
                "kecamatan": "Banjar",
                "kelurahan": "Balokang",
                "kodepos": "46312"
            }
        ],
        "k64": [
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Sindangmekar",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Mandala",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Sindangjawa",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Kedongdong Kidul",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Kepunduan",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Dukupuntang",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Girinata",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Cipanas",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Cisaat",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Cangkoak",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Cikalahang",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Balad",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Dukupuntang",
                "kelurahan": "Bobos",
                "kodepos": "45652"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Perbutulan",
                "kodepos": "45613"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Setu Wetan",
                "kodepos": "45651"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Watubelah",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Babakan",
                "kodepos": "45612"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sumber",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Tukmudal",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sidawangi",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sendang",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Matangaji",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Pasalakan",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Pejambon",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Kaliwadas",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Kemantren",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Kenanga",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Gegunung",
                "kodepos": "45611"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Tersana",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Sidaresmi",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Silihasih",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Pabedilan Wetan",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Pasuruan",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Pabedilan Kaler",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Pabedilan Kidul",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Pabedilan Kulon",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Kalibuntu",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Kalimukti",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Dukuhwidara",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Babakan Losari Lor",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Pabedilan",
                "kelurahan": "Babakan Losari",
                "kodepos": "45193"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Panggangsari",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Tawangsari",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Mulyasari",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Losari Kidul",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Losari Lor",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Barisan",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Kalirahayu",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Kalisari",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Ambulu",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Astanalanggar",
                "kodepos": "45192"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Sumber Lor",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Serang Wetan",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Sumber Kidul",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Pakusamben",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Serang Kulon",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pelayangan (Playangan)",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Kudumulya",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Melakasari",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Karangwangun",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Kudukeras",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kalimekar",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kalipasung",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kalimaro",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Gembongan",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Gembonganmekar",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebangmekar",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebangudik",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebang",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebang Kulon",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebangilir",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Dompyong Wetan",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gagasari",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Dompyong Kulon",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Cangkuang",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Babakangebang",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Bojonggebang",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Windujaya",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Babakan",
                "kelurahan": "Babakan",
                "kodepos": "45191"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Sedong Lor",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Winduhaji",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Sedong Kidul",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Panongan",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Panongan Lor",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Putat",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Panambangan",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Karangwuni",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Sedong",
                "kelurahan": "Kertawangun",
                "kodepos": "45189"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Tenjomaya",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran Wetan",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Sukadana",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran Kidul",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran Lor",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Leuweunggajah",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Jatiseeng",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Jatiseeng Kidul",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Hulubanteng Lor",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Jatirenggang",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Hulubanteng",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Damarguna",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Ciledug Tengah",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Ciledug Wetan",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Ciledug Kulon",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Ciledug Lor",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Ciledug",
                "kelurahan": "Bojongnegara",
                "kodepos": "45188"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Waled Kota",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Waled Desa",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Tanjung Anom",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Tonjong",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Waled Asem",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Pasaleman",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Mekarsari",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Gunungsari",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Karangsari",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Cisaat",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Ciuyah",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Cilengkrang",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Cilengkrang Girang",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Cikulak",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Cikulak Kidul",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Cigobang Wangi",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Pasaleman",
                "kelurahan": "Cigobang",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Ambit",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Waled",
                "kelurahan": "Cibogo",
                "kodepos": "45187"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Sumurkondang",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Tambelang",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Seuseupan",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Kubangkarang",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Kubangdeleg",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Karangwangi",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Karangwareng",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Karangsembung",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Karangsuwung",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Karangtengah",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Karangmalang",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Karangmekar",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Karangasem",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Karanganyar",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangsembung",
                "kelurahan": "Kalimeang",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Jatipiring",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Wilulang",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Karangwareng",
                "kelurahan": "Blender",
                "kodepos": "45186"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Susukan Agung",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Susukan Lebak",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Susukan Tonggoh",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Pasawahan",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Sampih",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Kaligawe Wetan",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Karangmanggu",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Curug Wetan",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Kaligawe",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Ciawijapura",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Curug",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Wangkelang",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Susukan Lebak",
                "kelurahan": "Ciawiasih",
                "kodepos": "45185"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Sigong",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Sindanglaut",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Tuk Karangsuwung",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Leuwidingding",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Picungpugur",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Sarajaya",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Lemahabang",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Lemahabang Kulon",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Cipeujeuh Kulon",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Cipeujeuh Wetan",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Asem",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Belawa",
                "kodepos": "45183"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Pangenan",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Rawaurip",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Japura Lor",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Pangarengan",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Getrakmoyan",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Beringin",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Ender",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Astanamukti",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Pangenan",
                "kelurahan": "Bendungan",
                "kodepos": "45182"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Munjul",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Sidamulya",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Mertapada Kulon",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Mertapada Wetan",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Lebakmekar",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Kendal",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Kanci",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Kanci Kulon",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Gumulungtonggoh",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Japura Kidul",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Japurabakti",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Gumulunglebak",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Buntet",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Astanajapura",
                "kelurahan": "Astanajapura",
                "kodepos": "45181"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Suci",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Waruduwur",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Sinarrancang",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Penpen",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Setupatok",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Mundupesisir",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Pamengkang",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Citemu",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Luwung",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Mundumesigit",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Bandengan",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Mundu",
                "kelurahan": "Banjarwangunan",
                "kodepos": "45173"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Wanayasa",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Sindanghayu",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Sindangkempeng",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Sindangkasih",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Nanggela",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Patapan",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Kamaranglebak",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Kondangsari",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Kamarang",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Jatipancur",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Halimpu",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Greged (Greget)",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Greged (Greget)",
                "kelurahan": "Durajaya",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Cikancas",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Cipinang",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Beber",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Beber",
                "kelurahan": "Ciawigajah",
                "kodepos": "45172"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Wanasaba Lor",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Sarwadadi",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Wanasaba Kidul",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Kubang",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Sampiran",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Kepongpongan",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Kerandon",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Ciperna",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Cirebongirang",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Kecomberan",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Talun (Cirebon Selatan)",
                "kelurahan": "Cempaka",
                "kodepos": "45171"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Gintung Kidul",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Gintung Tengah",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Gintungranjeng",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Ciwaringin",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Galagamba",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Bringin",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Budur",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Wiyong",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Ciwaringin",
                "kelurahan": "Babakan",
                "kodepos": "45167"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Susukan",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Tangkil",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Jatipura",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Ujunggebang",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kedongdong",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kejiwan",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Gintung Lor",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Luwung Kencana",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Jatianom",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Bojong Kulon",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Bunder",
                "kodepos": "45166"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Ujungsemi",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Wargabinangun",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Prajawinangun Kulon",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Prajawinangun Wetan",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Kaliwedi Kidul",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Kaliwedi Lor",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Kalideres",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Guwa Kidul",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Kaliwedi",
                "kelurahan": "Guwa Lor",
                "kodepos": "45165"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Sibubut",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Slendra",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Kedungdalem",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Panunggul",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Jagapura Kidul",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Jagapura Wetan",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Jagapura Kulon",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Jagapura Lor",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Gegesik Lor",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Gegesik Wetan",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Gegesik Kulon",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Bayalangu Lor",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Gegesik Kidul",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Gegesik",
                "kelurahan": "Bayalangu Kidul",
                "kodepos": "45164"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Panguragan Kulon",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Panguragan Lor",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Panguragan Wetan",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Kroya",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Lemahtamba",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Panguragan",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Kalianyar",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Karanganyar",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Tegalgubug Lor",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Panguragan",
                "kelurahan": "Gujeg",
                "kodepos": "45163"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Rawagatel",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Sende",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Tegalgubug",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Karangsambung",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Kebonturi",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Jungjang Wetan",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Bulak",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Geyongan",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Jungjang",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Winong",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Arjawinangun",
                "kelurahan": "Arjawinangun",
                "kodepos": "45162"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Tegalkarang",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Walahar",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Pegagan",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Semplo",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Palimanan Timur",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Panongan",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Palimanan Barat",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Kepuh",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Lungbenda",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Kedungbunder",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Kempek",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Cupang",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Gempol",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Cilukrak",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Cikeusal",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Ciawi",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Balerante",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Beberan",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Palimanan",
                "kelurahan": "Cengkuang",
                "kodepos": "45161"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Slangit",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Wangunharja",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Serang",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Sitiwinangun",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Pekantingan",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Orimalang",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Jemaras Lor",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Klangenan",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Kreyo",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Jemaras Kidul",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Jamblang",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Bojong Wetan",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Danawinangun",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Bojong Lor",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Klangenan",
                "kelurahan": "Bangodua",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Bakung Lor",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Waruroyom",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Jamblang",
                "kelurahan": "Bakung Kidul",
                "kodepos": "45156"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Warujaya",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Warukawung",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Purbawinangun",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Warugede",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Plumbon",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Pamijahan",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Pasanggrahan",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Kejuden",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Lurah",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Marikangen",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Keduanan",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Kedungsana",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Kasugengan Lor",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Kebarepan",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Karangwangi",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Kasugengan Kidul",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Karangasem",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Karangmulya",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Getasan",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Gombang",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Depok",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Danamulya",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Depok",
                "kelurahan": "Cikeduk",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Bode Lor",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Bodesari",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plumbon",
                "kelurahan": "Cempaka",
                "kodepos": "45155"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Wotgali",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Weru Kidul",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Weru Lor",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Trusmi Kulon",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Trusmi Wetan",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Tegalwangi",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Tegalsari",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Setu Kulon",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Sarabau",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Panembahan",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Pangkalan",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Megugede",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Megucilik",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karangsari",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Kertasari",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Kaliwulu",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Gamel",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Cangkring",
                "kodepos": "45154"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "TUK",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Sutawinangun",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Pilangsari",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Palir",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kertawinangun",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Kemlakagede",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kedungjaya",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kedungdawa",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kedawung",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Kalitengah",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kalikoa",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Kalibaru",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Gesik",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Dawuan",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Battembat",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Tengah Tani",
                "kelurahan": "Astapada",
                "kodepos": "45153"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Suranenggala Lor",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Suranenggala Kulon",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Suranenggala Kidul",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Suranenggala",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Surakarta",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Purwawinangun",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Pegagan Lor",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Pegagan Kidul",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Muara",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Kertasura",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Keraton",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Karangkendal",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Karangreja",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Kapetakan",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Grogol",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Dukuh",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Bungko Lor",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Kapetakan",
                "kelurahan": "Bungko",
                "kodepos": "45152"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Wanakaya",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Sirnabaya",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Suranenggala",
                "kelurahan": "Sambeng",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Pasindangan",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Mertasinga",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Mayung",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Jatimerta",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Kalisapu",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Klayan",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Grogol",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Jadimulya",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Babadan",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Buyut",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Astana",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Gunung Jati (Cirebon Utara)",
                "kelurahan": "Adidharma",
                "kodepos": "45151"
            },
            {
                "kecamatan": "Harjamukti",
                "kelurahan": "Argasunya",
                "kodepos": "45145"
            },
            {
                "kecamatan": "Harjamukti",
                "kelurahan": "Harjamukti",
                "kodepos": "45143"
            },
            {
                "kecamatan": "Harjamukti",
                "kelurahan": "Kalijaga",
                "kodepos": "45144"
            },
            {
                "kecamatan": "Harjamukti",
                "kelurahan": "Larangan",
                "kodepos": "45141"
            },
            {
                "kecamatan": "Harjamukti",
                "kelurahan": "Kecapi",
                "kodepos": "45142"
            },
            {
                "kecamatan": "Kesambi",
                "kelurahan": "Kesambi",
                "kodepos": "45134"
            },
            {
                "kecamatan": "Kesambi",
                "kelurahan": "Sunyaragi",
                "kodepos": "45132"
            },
            {
                "kecamatan": "Kesambi",
                "kelurahan": "Drajat",
                "kodepos": "45133"
            },
            {
                "kecamatan": "Kesambi",
                "kelurahan": "Karyamulya",
                "kodepos": "45131"
            },
            {
                "kecamatan": "Kesambi",
                "kelurahan": "Pekiringan",
                "kodepos": "45131"
            },
            {
                "kecamatan": "Kejaksan",
                "kelurahan": "Kejaksan",
                "kodepos": "45123"
            },
            {
                "kecamatan": "Kejaksan",
                "kelurahan": "Kesenden",
                "kodepos": "45121"
            },
            {
                "kecamatan": "Kejaksan",
                "kelurahan": "Sukapura",
                "kodepos": "45122"
            },
            {
                "kecamatan": "Kejaksan",
                "kelurahan": "Kebonbaru",
                "kodepos": "45121"
            },
            {
                "kecamatan": "Pekalipan",
                "kelurahan": "Pekalipan",
                "kodepos": "45117"
            },
            {
                "kecamatan": "Pekalipan",
                "kelurahan": "Pekalangan",
                "kodepos": "45118"
            },
            {
                "kecamatan": "Pekalipan",
                "kelurahan": "Jagasatru",
                "kodepos": "45115"
            },
            {
                "kecamatan": "Pekalipan",
                "kelurahan": "Pulasaren",
                "kodepos": "45116"
            },
            {
                "kecamatan": "Lemahwungkuk",
                "kelurahan": "Pegambiran",
                "kodepos": "45113"
            },
            {
                "kecamatan": "Lemahwungkuk",
                "kelurahan": "Kesepuhan",
                "kodepos": "45114"
            },
            {
                "kecamatan": "Lemahwungkuk",
                "kelurahan": "Lemahwungkuk",
                "kodepos": "45111"
            },
            {
                "kecamatan": "Lemahwungkuk",
                "kelurahan": "Panjunan",
                "kodepos": "45112"
            }
        ],
        "k65": [
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Nanggela",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Legok",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Kertawinangun",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Datar",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Jatimulya",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cihideunggirang",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cihideunghilir",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cikeusik",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cieurih",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cibulan",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cidahu",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Bunder",
                "kodepos": "45595"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Partawangunan",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Wanasaraya",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Kertawana",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Cipancur",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Kalimanggis Kulon",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Kalimanggis",
                "kelurahan": "Kalimanggis Wetan",
                "kodepos": "45594"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Cihirup",
                "kodepos": "45593"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Susukan",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Sukamukti",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Salareuma",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Suganangan",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Muncangela",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Pamulihan",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Karoya",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Cipicung",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Cimaranten",
                "kodepos": "45592"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Sukaraja",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Sukadana",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Sidaraja",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Pamijahan",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Pangkalan",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Pajawanlor",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Mekarjaya",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Padarama",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Lebaksiuh",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Keramatmulya",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Karangkamulyan",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Kapandayan",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Kadurama",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Geresik",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Dukuhdalem",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Ciputat",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Cikubangmulya",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Ciomas",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Cihaur",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Cijagamulya",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Ciawilor",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Cigarukgak",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Ciawigebang",
                "kelurahan": "Ciawigebang",
                "kodepos": "45591"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Sukarapih",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Sumurwiru",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Tarikolot",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Sukadana",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Kawungsari",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Randusari",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Cimara",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Cibeureum",
                "kodepos": "45588"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Sukamaju",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Sukaharja",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Dukuhbadag",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Sindangjawa",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Citenjo",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Cisaat",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Cipondok",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Cibingbin",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Ciangir",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Cibingbin",
                "kelurahan": "Bantarpanjang",
                "kodepos": "45587"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Tangkolo",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Subang",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Situgede",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Pamulihan",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Jatisari",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Gunungaci",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Bangunjaya",
                "kodepos": "45586"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Patala",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Mandapajaya",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Legokherang",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Jalatrang",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Cilimusari",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Cilebak",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Cilebak",
                "kelurahan": "Bungurberes",
                "kodepos": "45585"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Tanjungkerta",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Sukasari",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Simpayjaya",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Segong",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Margacina",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Karangkancana",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Kaduagung",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Jabranti",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Karangkancana",
                "kelurahan": "Cihanjaro",
                "kodepos": "45584"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Sumberjaya",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Sagaranten",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Linggajaya",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Lebakherang",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Karangbaru",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Garajati",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Ciwaru",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Citundun",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Cilayung",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Andamui",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Ciwaru",
                "kelurahan": "Baok",
                "kodepos": "45583"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Mulyajaya",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Sukajaya",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Margamukti",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Mekarjaya",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Cimulya",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Gunungsari",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Kananga",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Cimahi",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Cikeusal",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Cileuya",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Benda",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Cimahi",
                "kelurahan": "Cikaduwetan",
                "kodepos": "45582"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Walaharcageur",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Wilanagara",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Sindangsari",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Sindangsuka",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Luragungtonggoh",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Margasari",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Panyosogan",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Dukuhpicung",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Gunungkarung",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Luragunglandeuh",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Cirahayu",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Dukuhmaja",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Cigedang",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Luragung",
                "kelurahan": "Cikandang",
                "kodepos": "45581"
            },
            {
                "kecamatan": "Cipicung",
                "kelurahan": "Mekarsari",
                "kodepos": "45576"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Padamulya",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Parakan",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Mekarsari",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Maleber",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Giriwaringin",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Karangtengah",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Kutamandarakan",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Galaherang",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Garahaji",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Dukuhtengah",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Cikahuripan",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Cipakem",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Sindang",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Buniasih",
                "kodepos": "45575"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Pajawankidul",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Pasayangan",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Mekarwangi",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Pagundan",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Mandalajaya",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Mandalajaya",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Lebakwangi",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Mancagar",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Langseb",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Kutaraja",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Cipeutir/Cipetir",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Maleber",
                "kelurahan": "Ciporang",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Cinagara",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Cineubeuy/Cineumbeuy",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Tirtawangunan",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Lebakwangi",
                "kelurahan": "Bendungan",
                "kodepos": "45574"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Sindangsari",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Taraju",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Mekarmukti",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Sindangagung",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Kertawangunan",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Kertayasa",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Kertaungaran",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Dukuhlor",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Kaduagung",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Babakanreuma",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Sindangagung",
                "kelurahan": "Balong",
                "kodepos": "45573"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Tembong",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Pakembangan",
                "kodepos": "45572"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Sukamulya",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Tambakbaya",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Sukaimut",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Mancagar",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Mekarmulya",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Purwasari",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Kutakembaran",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Lengkong",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Karamatwangi",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Selajambe",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Gewok",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Jamberama",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Kadatuan",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Kutawaringin",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Citiusari",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Bagawat",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Garawangi",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Cantilan",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Cikananga",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Ciberung",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Garawangi",
                "kelurahan": "Cirukem",
                "kodepos": "45571"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Rambatan",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Selajambe",
                "kelurahan": "Padahurip",
                "kodepos": "45566"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Pinara",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Pamupukan",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Mungkaldatar",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Cipedes",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Cikondang",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Gunungmanik",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Citapen",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Longkewang",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Windusari",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Ciniru",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Bunigeulis",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Tudagan",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Nusaherang",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Ciniru",
                "kelurahan": "Cijemit",
                "kodepos": "45565"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Kertayuga",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Pakapasan Girang",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Jambar",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Pakapasan Hilir",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Kertawirama",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Pasiragung",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Haurkuning",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Hantara",
                "kelurahan": "Hantara",
                "kodepos": "45564"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Ciasih",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Nusaherang",
                "kelurahan": "Cikadu",
                "kodepos": "45563"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Tugumulya",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Sakerta Timur",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Situsari",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Sukarasa",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Sagarahiang",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Sakerta Barat",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Kawahmanuk",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Paninggaran",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Parung",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Jagara",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Karanganyar",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Karangsari",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Cipasung",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Darma",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Gunungsirah",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Cimenga",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Cikupa",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Bakom",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Darma",
                "kelurahan": "Cageur",
                "kodepos": "45562"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Tinggar",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Windujanten",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Margabakti",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Nangka",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Sindangjawa",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Cisukadana",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Kadugede",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Ciketak",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Cipondok",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Bayuning",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Ciherang",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Singkup",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Kadugede",
                "kelurahan": "Babatan",
                "kodepos": "45561"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Paniis",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Pasawahan",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Kaduela",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Padabeunghar",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Padamatang",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Cimara",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Ciwiru",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Cibuntu",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Cidahu",
                "kodepos": "45559"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Trijaya",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Salakadomas",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Seda",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Sukasari",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Randobawagirang",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Randobawailir",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Pakembangan",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Mandirancan",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Nanggela",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Nanggerangjaya",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Cirea",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Mandirancan",
                "kelurahan": "Kertawinangun",
                "kodepos": "45558"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Tarikolot",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Tenjolayar",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Tajurbuntu",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Sumbakeling",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Sindangkempeng",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Silebu",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Rajawetan",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Sarewu",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Pancalang",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Patalagan",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Danalampah",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Kahiyangan",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Pancalang",
                "kelurahan": "Mekarjaya",
                "kodepos": "45557"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Timbang",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Setianegara",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Sangkanmulya",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Sangkanurip",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Sampora",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Panawuan",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Linggarjati",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Linggasana",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Linggaindah",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Linggamekar",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Kaliaren",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Karangmuncang",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Koreak",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Jambugeulis",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Cilimus",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Indapatra",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Caracas",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Cibeureum",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Cibuntu",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Bojong",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Bunigeulis",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Bandorasa Kulon",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cilimus",
                "kelurahan": "Bandorasa Wetan",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Cigandamekar",
                "kelurahan": "Babakanjati",
                "kodepos": "45556"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Singkup",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Wano",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Kalimati",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Rajadanu",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Garatengah",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Japara",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Cikeleng",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Citapen",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Dukuhdalem",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Japara",
                "kelurahan": "Cengal",
                "kodepos": "45555"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sindangbarang",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sukamukti",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sayana",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sembawa",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sidamulya",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sadamantra",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Sangkanerang",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Padamenak",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Peusing",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Maniskidul",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Manislor",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Ciniru",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Jalaksana",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Jalaksana",
                "kelurahan": "Babakanmulya",
                "kodepos": "45554"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Ragawacana",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Widarasari",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Nanggerang",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Padarek",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Pajambon",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Kalapagunung",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Karamatmulya",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Karangmangu",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Gandasoli",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Gereba",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Cikubangsari",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Cilaja",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Cilowa",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Cibentang",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Cikaso",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Bojong",
                "kodepos": "45553"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Winduherang",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Puncak",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Sukamulya",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Gunungkeling",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cipari",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cisantana",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cigadung",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cigugur",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Cileuleuy",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Cigugur",
                "kelurahan": "Babakanmulya",
                "kodepos": "45552"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Cirendang",
                "kodepos": "45518"
            },
            {
                "kecamatan": "Kramat Mulya",
                "kelurahan": "Kasturi",
                "kodepos": "45521"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Winduhaji",
                "kodepos": "45516"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Cigintung",
                "kodepos": "45517"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Karangtawang",
                "kodepos": "45515"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Windusengkahan",
                "kodepos": "45515"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Citangtu",
                "kodepos": "45516"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Ancaran",
                "kodepos": "45514"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Ciporang",
                "kodepos": "45514"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Purwawinangun",
                "kodepos": "45512"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Cijoho",
                "kodepos": "45513"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Kedungarum",
                "kodepos": "45513"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Cibinuang",
                "kodepos": "45511"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Kuningan",
                "kodepos": "45511"
            },
            {
                "kecamatan": "Kuningan",
                "kelurahan": "Awirarangan",
                "kodepos": "45511"
            }
        ],
        "k66": [
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Tenjolayar",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Tajur",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Kutamanggu",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Simpeureum",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Karayunan",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Kawunghilir",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Cicenang",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Cigasong",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Batujaya",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Cigasong",
                "kelurahan": "Baribis",
                "kodepos": "45476"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Weragati",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Waringin",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Trajaya",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Tarikolot",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Sindangwasa",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Sindanghaji",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Palasah",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Pasir",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Karamat",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Majasuka",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Enggalwangi",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Cisambeng",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Palasah",
                "kelurahan": "Buniwangi",
                "kodepos": "45475"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Padaherang",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Sindangwangi",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Ujungberung",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Leuwilaja",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Lengkongkulon",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Lengkongwetan",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Jerukleueut",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Buahkapas",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Balagedog",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Sindangwangi",
                "kelurahan": "Bantar Agung",
                "kodepos": "45474"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Tanjungsari",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Patuanan",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Rajawangi",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Parungjaya",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Parakan",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Nanggerang",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Mirat",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Mindi",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Leuwimunding",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Leuwikujang",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Lame",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Karangasem",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Heuleut",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Leuwimunding",
                "kelurahan": "Ciparay",
                "kodepos": "45473"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Teja",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Singawada",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Sindangpano",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Sadomas",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Rajagaluh Lor",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Rajagaluh Kidul",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Rajagaluh",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Payung",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Pajajar",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Kumbung",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Cisetu",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Cipinang",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Rajagaluh",
                "kelurahan": "Babakankareo",
                "kodepos": "45472"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Tanjungsari",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Sukahaji",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Sindang",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Sangkanhurip",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Salagedang",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Pasirayu",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Palabuhan/Palabuan",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Padahanten",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Nanggewer",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Jayi",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Indrakila",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Gunung Kuning",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Garawastu",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Ciomas",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Cikoneng",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Cikeusik",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Cikalong",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Bayureja",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Candrajaya",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Sukahaji",
                "kelurahan": "Babakan Manjeti",
                "kodepos": "45471"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Suniabaru",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Sunia",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Sindangpala",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Sangiang",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Panyindangan",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Kareo",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Kagok",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Hegarmanah",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Girimulya",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Genteng",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Darmalarang",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Cimeong",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Banjaran",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Banjaran",
                "kodepos": "45468"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Wangkelang",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Sedaraja",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Rawa",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Nagarakembang",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Muktisari",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Maniis",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Kondangmekar",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Ciranjeng",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Cintaasih",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Cingambul",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Cimanggugirang",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Cikondang",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cingambul",
                "kelurahan": "Cidadap",
                "kodepos": "45467"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Sunalari",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Sukasari",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Sukamukti",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Sindangpanji",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Sindang",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Kasturi",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Kancana",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Jagasari",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Cisoka",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Cipulus",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Cilangcang",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Cidulang",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Cikijing",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Banjaransari",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Cikijing",
                "kelurahan": "Bagjasari",
                "kodepos": "45466"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Sukamaju",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Sukajadi",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Sinargalih",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Padarek",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Sadawangi",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Mekar Wangi",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Mekarmulya",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Lemahsugih",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Margajaya",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Lemah Putih",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Kalapadua",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Kepuh",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Cisalak",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Dayeuhwangi",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Cigaleuh",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Cipasung",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Borogojol",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Cibulan",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Lemahsugih",
                "kelurahan": "Bangbayang",
                "kodepos": "45465"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Werasari",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Sukamenak",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Wadowetan",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Sindanghurip",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Sukadana",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Salawangi",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Silihwangi",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Lebakwangi",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Malausma",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Jagamulya",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Keramat Jaya",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Haurgeulis",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Girimukti",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Gununglarang",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Ciranca",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Cipeundeuy",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Cimuncang",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Cimangguhilir",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Cinambo",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Buninagara",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Cikidang",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Malausma",
                "kelurahan": "Banyusari",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Babakansari",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Bantarujeg",
                "kelurahan": "Bantarujeg",
                "kodepos": "45464"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Talagakulon",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Talagawetan",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Salado",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Sukaperna",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Margamukti",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Mekarhurip",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Mekarraharja",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Kertarahayu",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Lampuyang",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Gunungmanik",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Jatipamor",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Ganeas",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Cibeureum",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Cicanir",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Cikeusal",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Argasari",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Talaga",
                "kelurahan": "Campaga",
                "kodepos": "45463"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Sukasari Kidul",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Tejamulya",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Sukadana",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Sukasari Kaler",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Sadasari",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Sagara",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Heubeulisuk",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Mekarwangi",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Gunungwangi",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Haurseah",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Cikaracak",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Argamukti",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Cibunut",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Wanahayu",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Argapura",
                "kelurahan": "Argalingga",
                "kodepos": "45462"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Tegalsari",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Sindangkerta",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Paniis",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Pasanggrahan",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Nunuk Baru",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Pageraji",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Maja Selatan",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Maja Utara",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Malongpong",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Kertabasuki",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cihaur",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cipicung",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cicalung",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cieurih",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Cengal",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Anggrawati",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Maja",
                "kelurahan": "Banjaran",
                "kodepos": "45461"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Panyingkiran",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Pasirmuncang",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Jatiserang",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Karyamukti",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Leuwiseeng",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Cijurey",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Jatipamor",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Bonang",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Panyingkiran",
                "kelurahan": "Bantrangsana",
                "kodepos": "45459"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Sumber Kulon",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Sumber Wetan",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Randegan Wetan",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Pilangsari",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Putridalem",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Randegan Kulon",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Pasindangan",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Panongan",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Panyingkiran",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Jatitujuh",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Pangkalanpari",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Jatiraga",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Jatitengah",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Babajurang",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Jatitujuh",
                "kelurahan": "Biyawak",
                "kodepos": "45458"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Sukamulya",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Sukawana",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Sahbandar",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Sukakerta",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Palasah",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Pasiripis",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Mekarmulya",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Pakubeureum",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Kertawinangun",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Mekarjaya",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Kertajati",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Kertasari",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Bantarjati",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Kertajati",
                "kelurahan": "Babakan",
                "kodepos": "45457"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Tegalaren",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Wanasalam",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Ligung Lor",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Majasari",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Sukawera",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Leuwiliang Baru",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Ligung",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Kodasari",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Leuweunghapit",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Kertasari",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Kedungsari",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Cibogor",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Gandawesi",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Kedungkancana",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Beusi",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Buntu",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Bantarwaru",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Beber",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Sumberjaya",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Ligung",
                "kelurahan": "Ampel",
                "kodepos": "45456"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Sepat",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Parapatan",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Rancaputat",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Panjalin Kidul",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Panjalin Lor",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Loji Kobong",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Pancaksuji",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Paningkiran",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Gelok Mulya",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Bongas Wetan",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Cidenok",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Garawangi",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Sumberjaya",
                "kelurahan": "Bongas Kulon",
                "kodepos": "45455"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Surawangi",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Sutawangi",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Sukaraja Kulon",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Sukaraja Wetan",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Mekarsari",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Pinangraja",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Leuwenggede",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Loji",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Jatisura",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Jatiwangi",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Ciborelang",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Cicadas",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Burujul Wetan",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Cibentar",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Andir",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Jatiwangi",
                "kelurahan": "Burujul Kulon",
                "kodepos": "45454"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Sinarjati",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Wanajaya",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Salawana",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Ranjiwetan",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Pasirmalati",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Ranjikulon",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Mandapa",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Leuwikidang",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Karanganyar",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Kasokandel",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Jatimulya",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Jatisawit",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Gunungsari",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Genteng",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Girimukti",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Gandu",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kasokandel",
                "kelurahan": "Gandasari",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Baturuyuk",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Bojongcideres",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Dawuan",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Pagandon",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Balida",
                "kodepos": "45453"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Karangsambung",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Liangjulang",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Heuleut",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Kadipaten",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Babakan Anyar",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Kadipaten",
                "kelurahan": "Cipaku",
                "kodepos": "45452"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Majalengka Kulon",
                "kodepos": "45418"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Babakan Jawa",
                "kodepos": "45419"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Tarikolot",
                "kodepos": "45416"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Cijati",
                "kodepos": "45417"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Munjul",
                "kodepos": "45417"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Tonjong",
                "kodepos": "45414"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Cikasarung",
                "kodepos": "45415"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Sindangkasih",
                "kodepos": "45411"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Cicurug",
                "kodepos": "45412"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Sidamukti",
                "kodepos": "45411"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Kulur",
                "kodepos": "45411"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Majalengka Wetan",
                "kodepos": "45411"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Kawunggirang",
                "kodepos": "45411"
            },
            {
                "kecamatan": "Majalengka",
                "kelurahan": "Cibodas",
                "kodepos": "45411"
            }
        ],
        "k67": [
            {
                "kecamatan": "Surian",
                "kelurahan": "Wanajaya",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Wanasari",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Suriamukti",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Surian",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Tanjung",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Ranggasari",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Suriamendal",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Sekarwangi",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Surian",
                "kelurahan": "Pamekarsari",
                "kodepos": "45393"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Nagrak",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Panyindangan",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Karangbungur",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Mekarmukti",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Citaleus",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Gendereh",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Hariang",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Cikurubuk",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Cilangkap",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Buahdua",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Ciawitali",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Cibitung",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Buahdua",
                "kelurahan": "Bojongloa",
                "kodepos": "45392"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Ungkal",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Padaasih",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Karanglayung",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Narimbang",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Conggeang Wetan",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Jambu",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Cibubuan",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Cipamekar",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Conggeang Kulon",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Cacaban",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Cibeureuyeuh",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Ujungjaya",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Conggeang",
                "kelurahan": "Babakan Asem",
                "kodepos": "45391"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Sakurjaya",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Sukamulya",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Kudangwangi",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Palabuan",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Palasari",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Cipelang",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Kebon Cau",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Tomo",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Ujungjaya",
                "kelurahan": "Cibuluh",
                "kodepos": "45383"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Marongge",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Tolengas",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Jembarwangi",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Karyamukti",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Cicarimanah",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Cipeles",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Darmawangi",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Pasireungit",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Tomo",
                "kelurahan": "Bugel",
                "kodepos": "45382"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Paseh Kaler",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Paseh Kidul",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Padanaan",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Legok Kidul",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Citepok",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Haurkuning",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Legok Kaler",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Bongkok",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Cijambe",
                "kodepos": "45381"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Mekarasih",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Sukakersa",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Lebaksiuh",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Kadujaya",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Karedok",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Jemah",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Kadu",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Cipicung",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Ciranggem",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Cisampih",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Cijeungjing",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatigede",
                "kelurahan": "Cintajaya",
                "kodepos": "45377"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Sukamanah",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Tarikolot",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Sarimekar",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Sirnasari",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Kirisik",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Pawenang",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Cimanintin",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Cipeundeuy",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Tamansari",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Jatinunggal",
                "kelurahan": "Banjarsari",
                "kodepos": "45376"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Sukaraja",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Jaya Mekar",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Cibugel",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Cipasang",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Wado",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Cibugel",
                "kelurahan": "Buana Mekar",
                "kodepos": "45375"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Sukajadi",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Sukapura",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Mulyajaya",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Padajaya",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Cimungkal",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Cisurat",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Ganjaresik",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Cikareo Selatan",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Cikareo Utara",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Wado",
                "kelurahan": "Cilengkrang",
                "kodepos": "45373"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Sukaratu",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Tarunajaya",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Paku Alam",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Ranggon",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Sukamenak",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Leuwihideung",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Neglasari",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Karang Pakuan",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Darmaraja",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Jatibungur",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Cipeuteuy",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Darmajaya",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Cikeusi",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Cipaku",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Cibogo",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Darmaraja",
                "kelurahan": "Cieunteung",
                "kodepos": "45372"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Situraja Utara",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Sukatali",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Wanakerta",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Mekarmulya",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Situraja",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Karangheuleut",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Malaka",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Cikadu",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Jatimekar",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Kaduwulung",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Bangbayang",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Cijati",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Cijeler",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Situraja",
                "kelurahan": "Ambit",
                "kodepos": "45371"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sindangsari",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukarapih",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukasari",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Nanggerang",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Mekarsari",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Genteng",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Banyuresmi",
                "kodepos": "45366"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Sukawangi",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Pamulihan",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Mekarbakti",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Haurngombong",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Citali",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Ciptasari",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Cinanggerang",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Cimarias",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Cilembu",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Cijeruk",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Cigendel",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Tegalmanggung",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Sukadana",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Sindulang",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Sindangpakuon",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Sindanggalih",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Pasirnunjang",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Sawahdadap",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Mangunarga",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Cimanggung",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Cikahuripan",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cimanggung",
                "kelurahan": "Cihanjuang",
                "kodepos": "45364"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Situmekar",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Sundamekar",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Sayang",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Ranjeng",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Pajagan",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Mekargalih",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Linggajaya",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Jatimukti",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Jatiroke",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Hegarmanah",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Cisitu",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cisempur",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cipacing",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cinta Mulya",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Cinangsi",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Cilopang",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Cimarga",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cileles",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cikeruh",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cilayung",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Cisitu",
                "kelurahan": "Cigintung",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Jatinangor",
                "kelurahan": "Cibeusi",
                "kodepos": "45363"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Tanjungsari",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Pasigaran",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Raharja",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Marga Jaya",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Margaluyu",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Kutamandiri",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Gunungmanik",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Jatisari",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Kadakajaya",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Gudang",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Cijambu",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Cinanjung",
                "kodepos": "45362"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Sukasirnarasa",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Sukahayu",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Sukamaju",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Pasir Biru",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Rancakalong",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Pangadegan",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Nagarawangi",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Pamekaran",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Cibunar",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Rancakalong",
                "kelurahan": "Cibungur",
                "kodepos": "45361"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Tanjunghurip",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Sukaluyu",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Sukawening",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Dayeuh Luhur",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Ganeas",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Cikondang",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Ganeas",
                "kelurahan": "Cikoneng",
                "kodepos": "45356"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Ciuyah",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Kebonkalapa",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cisalak",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cisarua",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cipandanwangi",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Bantarmara",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cimara",
                "kodepos": "45355"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Wargaluyu",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Tanjungwangi",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Tanjungmulya",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Tanjungmedar",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Tanjungmekar",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Sukamukti",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Sukatani",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Sukamantri",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Mulyamekar",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Kertamukti",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Kertaharja",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Kertamekar",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Jingkang",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Kamal",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Cipanas",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Gunturmekar",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Cigentur",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungmedar",
                "kelurahan": "Cikaramas",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Banyuasih",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Tanjungkerta",
                "kelurahan": "Boros",
                "kodepos": "45354"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Trunamanggala",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Padasari",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Serang",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Naluk",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Nyalindung",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Licin",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Mandalaherang",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Citimun",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Galudra",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Cimalaka",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Cimuja",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Cibeureum Kulon",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Cibeureum Wetan",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Cimalaka",
                "kelurahan": "Cikole",
                "kodepos": "45353"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Kotakaler",
                "kodepos": "45322"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Situ",
                "kodepos": "45323"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Sirnamulya",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Talun",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Padasuka",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Rancamulya",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Mulyasari",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Margamukti",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Mekarjaya",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Jatimulya",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Kebonjati",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Girimukti",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Utara",
                "kelurahan": "Jatihurip",
                "kodepos": "45321"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Cipameungpeuk",
                "kodepos": "45315"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Sukajaya",
                "kodepos": "45314"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Sukagalih",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Pasanggrahan",
                "kodepos": "45313"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Regol Wetan",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Margamekar",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Mekar Rahayu",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Kotakulon",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Margalaksana",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Citengah",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Gunasari",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Cipancar",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Baginda",
                "kodepos": "45311"
            },
            {
                "kecamatan": "Sumedang Selatan",
                "kelurahan": "Ciherang",
                "kodepos": "45311"
            }
        ],
        "k68": [
            {
                "kecamatan": "Arahan",
                "kelurahan": "Tawangsari",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Sukasari",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Sukadadi",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Pranggong",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Linggajati",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Cidempet",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Arahan Lor",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Arahan",
                "kelurahan": "Arahan Kidul",
                "kodepos": "45365"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Sudimampirlor",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Tegalsembadra",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Majakerta",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Sudimampir",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Gelarmendala",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Gelarmendala",
                "kodepos": "45285"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Srengseng",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Tanjakan",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Purwajaya",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Singakerta",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Krangkeng",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Luwunggesik",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Kalianyar",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Kapringan",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Kedungwungu",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Tanjungsari",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Krangkeng",
                "kelurahan": "Dukuhjati",
                "kodepos": "45284"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Sendang",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Tanjungpura",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Mundu",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Pringgacala",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Kedokan Agung",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Kedokanbunder",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Kedokanbunder Wetan",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Karangampel",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Karangampel Kidul",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Jayawinangun",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Kaplongan",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Kaplonganlor",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Jayalaksana",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Dukuhjeruk",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Dukuhtengah",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Kedokan Bunder",
                "kelurahan": "Cangkingan",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Tinumpuk",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Karangampel",
                "kelurahan": "Benda",
                "kodepos": "45283"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Sambimaya",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Segeran",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Segeran Kidul",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Lombang",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Pondoh",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Juntiweden",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Limbangan",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Juntikedokan",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Juntinyuat",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Junti Kebon",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Tugu Kidul",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Juntinyuat",
                "kelurahan": "Dadap",
                "kodepos": "45282"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Tambi Lor",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Tugu",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Sudikampiran",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Tambi",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Sliyeg",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Sliyeglor",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Mekargading",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Sleman",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Sleman Lor",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Majasih",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Longok",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Majasari",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Tulungagung",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sliyeg",
                "kelurahan": "Gadingan",
                "kodepos": "45281"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Tersana",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Tenajar Kidul",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Tenajar Lor",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Tegalwirangrong",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Tenajar",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Sukagumiwang",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Manguntara",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Sukawera",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Laranganjambe",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Lemahayu",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Kertasemaya",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Kliwed",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Jengkok",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Gunungsari",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Kertasemaya",
                "kelurahan": "Jambe",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Cadangpinggan",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Cibeber",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Gedangan",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Sukagumiwang",
                "kelurahan": "Bondan",
                "kodepos": "45274"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Pawidean",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Pilangsari",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Sukalila",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Malangsemirang",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Lobener",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Lobener Lor",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kalimati",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kebulen",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Krasak",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatisawit",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatisawit Lor",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatibarang",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatibarang Baru",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Bulak",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Bulak Lor",
                "kodepos": "45273"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Wanasari",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Tukdana",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Tegalgirang",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Sukaperna",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Sukadana",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Sukamulya",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Rancasari",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Pagedangan",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Rancajawat",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Mekarsari",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Mekarsari",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Malangsari",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Kerticala",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Lajer",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Karanggetas",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Karangkerta",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Cangko",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Gadel",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Tukdana",
                "kelurahan": "Bodas",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Bangodua",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Bangodua",
                "kelurahan": "Beduyut",
                "kodepos": "45272"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Widasari",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Ujungpendokjaya",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Leuwigede",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Ujungaris",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Ujungjaya",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Kalensari",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Kasmaran",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Kongsijaya",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Bangkaloa Ilir",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Widasari",
                "kelurahan": "Bunder",
                "kodepos": "45271"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Temiyangsari",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Temiyang",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Sumberjaya",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Sumbon",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Tanjungkerta",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Kroya",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Sukamelang",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Sukaslamet",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Wanakaya",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Jayamulya",
                "kodepos": "45265"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Sukajati",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Sumbermulya",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Situraja",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Sidadadi",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Mekarjaya",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Mekarwaru",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Sanca",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Karangtumaritis",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Kertanegara",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Karangtumaritis",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Gantar",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Haurgeulis",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Haurkolot",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Baleraja",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gantar",
                "kelurahan": "Bantarwaru",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Haurgeulis",
                "kelurahan": "Cipancuh",
                "kodepos": "45264"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Rancamulya",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Sekarmulya",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Kedokangabus",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Kedungdawa",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Rancahan",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Gabuskulon",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Gabuswetan",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Babakanjaya",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Drunten Kulon",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Gabuswetan",
                "kelurahan": "Drunten Wetan",
                "kodepos": "45263"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Rajasinga",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Mundakjaya",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Plosokerep",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Manggungan",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Loyang",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Karangasem",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Kendayakan",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Jatimunggul",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Jatisura",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Jatimulya",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Cikedung Lor",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Jambak",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Cikawung",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Cikedung",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Trisi/Terisi",
                "kelurahan": "Cibereng",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Cikedung",
                "kelurahan": "Amis",
                "kodepos": "45262"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Tugu",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Tunggulpayung",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Tempel",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Tempelkulon",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Pangauban",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Tamansari",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Telagasari",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Nunuk",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Langgengsari",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Lelea",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Panyingkiran Lor",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Lelea",
                "kelurahan": "Cempeh",
                "kodepos": "45261"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Lamarantarung",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Panyingkiran Kidul",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Cangkring",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Cantigi Kulon",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Cantigi",
                "kelurahan": "Cantigi Wetan",
                "kodepos": "45258"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Ujunggebang",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Sumuradem Timur",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Tegaltaman",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Sumuradem",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Sukra",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Sukrawetan",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Sukahaji",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Patrol Baru",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Patrollor",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Patrol",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Limpas",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Mekarsari",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Bugel",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Karang Layung",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Patrol",
                "kelurahan": "Arjasari",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Sukra",
                "kelurahan": "Bogor",
                "kodepos": "45257"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Salamdarma",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Wanguk",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Lempuyang",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Mangunjaya",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Kedungwungu",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Kopyah",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Cilandak",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Cilandak Lor",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Bugis",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Bugistua",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Anjatan Baru",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Anjatan Utara",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Sidamulya",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Anjatan",
                "kelurahan": "Anjatan",
                "kodepos": "45256"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Margamulya",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Plawangan",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Kertajaya",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Kertamulya",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Cipaat",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Cipedang",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Bongas",
                "kelurahan": "Bongas",
                "kodepos": "45255"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Soge",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Wirakanan",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Wirapanjunan",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Parean Girang",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Pranti",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Ilir",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Karangmulya",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Kertawinangun",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Eretan Wetan",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Eretan Kulon",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Curug",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Santing",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Bulak",
                "kodepos": "45254"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Rajaiyang",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Ranjeng",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Pegagan",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Puntang",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Muntur",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Pangkalan",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Losarang",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Jumbleng",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Krimun",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Jangga",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Cemara Kulon",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Losarang",
                "kelurahan": "Cemara",
                "kodepos": "45253"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Waru",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Rambatan Kulon",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Sindangkerta",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Pamayahan",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Lohbener",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Legok",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Lanjan",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Larangan",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Langut",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Kiajaran Kulon",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Kijaran Wetan",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Lohbener",
                "kelurahan": "Bojongslawi",
                "kodepos": "45252"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Pasekan",
                "kodepos": "45228"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Totoran",
                "kodepos": "45229"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Wanantara",
                "kodepos": "45227"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Pagirikan",
                "kodepos": "45227"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Babadan",
                "kodepos": "45227"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Kenanga",
                "kodepos": "45226"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Panyindangan Wetan",
                "kodepos": "45225"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Panyindangan Kulon",
                "kodepos": "45225"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Terusan",
                "kodepos": "45224"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Dermayu",
                "kodepos": "45223"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Sindang",
                "kodepos": "45222"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Rambatan Wetan",
                "kodepos": "45221"
            },
            {
                "kecamatan": "Sindang",
                "kelurahan": "Penganjang",
                "kodepos": "45221"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Tambak",
                "kodepos": "45219"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Pabeanudik",
                "kodepos": "45219"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Karangsong",
                "kodepos": "45219"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Pabeanilir",
                "kodepos": "45219"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Brondong",
                "kodepos": "45219"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Singaraja",
                "kodepos": "45218"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Singajaya",
                "kodepos": "45218"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Sukaurip",
                "kodepos": "45217"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Tegalurung",
                "kodepos": "45217"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Sukareja",
                "kodepos": "45217"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Rawadalem",
                "kodepos": "45217"
            },
            {
                "kecamatan": "Balongan",
                "kelurahan": "Balongan",
                "kodepos": "45217"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Pekandangan",
                "kodepos": "45216"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Telukagung",
                "kodepos": "45215"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Kepandean",
                "kodepos": "45214"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Dukuh",
                "kodepos": "45215"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Plumbon",
                "kodepos": "45215"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Karangmalang",
                "kodepos": "45213"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Bojongsari",
                "kodepos": "45214"
            },
            {
                "kecamatan": "Pasekan",
                "kelurahan": "Karanganyar",
                "kodepos": "45213"
            },
            {
                "kecamatan": "Kandanghaur",
                "kelurahan": "Karanganyar",
                "kodepos": "45213"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Karanganyar",
                "kodepos": "45213"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Lemahabang",
                "kodepos": "45212"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Lemahmekar",
                "kodepos": "45212"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Paoman",
                "kodepos": "45211"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Pekandangan Jaya",
                "kodepos": "45211"
            },
            {
                "kecamatan": "Indramayu",
                "kelurahan": "Margadadi",
                "kodepos": "45211"
            }
        ],
        "k69": [
            {
                "kecamatan": "Cibiuk",
                "kelurahan": "Majasari",
                "kodepos": "44193"
            },
            {
                "kecamatan": "Cibiuk",
                "kelurahan": "Cibiuk Kidul",
                "kodepos": "44193"
            },
            {
                "kecamatan": "Cibiuk",
                "kelurahan": "Cipareuan",
                "kodepos": "44193"
            },
            {
                "kecamatan": "Cibiuk",
                "kelurahan": "Lingkungpasir",
                "kodepos": "44193"
            },
            {
                "kecamatan": "Cibiuk",
                "kelurahan": "Cibiuk Kaler",
                "kodepos": "44193"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Margahayu",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Sindangsari",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Tambaksari",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Margacinta",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Karangsari",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Leuwigoong",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Dungusiku",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Leuwigoong",
                "kelurahan": "Karanganyar",
                "kodepos": "44192"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukasenang",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukaratu",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukaraja",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukamukti",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukakarya",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Sukalaksana",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Karyasari",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Pamekarsari",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Dangdeur",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Karyamukti",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Cipicung",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Cimareme",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Banyuresmi",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Binakarya",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sukarasa",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sukaratu",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Banyuresmi",
                "kelurahan": "Bagendit",
                "kodepos": "44191"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sukamanah",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sekarwangi",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sukajaya",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sakawayana",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Sanding",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Mekar Mulya",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Mekarasih",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Lewobaru",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Malangbong",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Karangmulya",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Kutanagara",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Citeras",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Girimakmur",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cilampuyang",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cinagara",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cisitu",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cibunar",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cihaurkuning",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Cikarag",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Bunisari",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Campaka",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Malangbong",
                "kelurahan": "Barudua",
                "kodepos": "44188"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Putrajawa",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Samida",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Selaawi",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Mekarsari",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Pelita Asih",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Cigawir",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Selaawi",
                "kelurahan": "Cirapuhan",
                "kodepos": "44187"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Surabaya",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Simpen Kidul",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Pasirwaru",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Simpen Kaler",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Pangeureunan",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Limbangantimur",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Neglasari",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Limbanganbarat",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Limbangantengah",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Dunguswiru",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Galihpakuwon",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Ciwangi",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Cijolang",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Blubur Limbangan",
                "kelurahan": "Cigagade",
                "kodepos": "44186"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Wanakerta",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Sukamaju",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Sukamerang",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Sindangsuka",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Sukalilah",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Padasuka",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Mekarraya",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Nanjungjaya",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Mekarsari",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Kersamanah",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Kertajaya",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Keresek",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Karyamukti",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Kersamanah",
                "kelurahan": "Girijaya",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Girimukti",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cibunar",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sukawening",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cibatu",
                "kodepos": "44185"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sukamukti",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sukasono",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sukahaji",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sukaluyu",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Sudalarang",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sindanggalih",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Mekarwangi",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Pasanggrahan",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Maripari",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Mekarhurip",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Sukawening",
                "kelurahan": "Mekarluyu",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Cinta",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Cintamanik",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Caringin",
                "kodepos": "44184"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Wanaraja",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Wanasari",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Wanajaya",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Wanamekar",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Sukamulya",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Sukarasa",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Sukahurip",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Sindangratu",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Sukamenak",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Sindangmekar",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Sindangprabu",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Wanaraja",
                "kelurahan": "Cinunuk",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Citangtu",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Cimaragas",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Karangsari",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Babakanloa",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Pangatikan",
                "kelurahan": "Cihuni",
                "kodepos": "44183"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Sucikaler",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Tanjungsari",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Situsari",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Suci",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Situjaya",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Situsaeur",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Situgede",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Sindanglaya",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Sindangpalay",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Mekarsari",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Sindanggalih",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Lebakjaya",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Lengkongjaya",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Karangsari",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Lebakagung",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Karangmulya",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Karangpawitan",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Godog",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Jatisari",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Sukamurni",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Sukatani",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Karangpawitan",
                "kelurahan": "Cimurah",
                "kodepos": "44182"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Sukamaju",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Sukamukti",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Ngamplangsari",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Pasanggrahan",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Sukahati",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Ngamplang",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Mekarmukti",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Mekarsari",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Margalaksana",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Karyamekar",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Mangkurayat",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Dawungsari",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Dayeuhmanggung",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Desakolot",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Cilawu",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Cilawu",
                "kelurahan": "Dangiang",
                "kodepos": "44181"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Saribakti",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Sukanagara",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Toblong",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Peundeuy",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Purwajaya",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Peundeuy",
                "kelurahan": "Pangrumasan",
                "kodepos": "44178"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Tipar",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Pamalayan",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Linggamanik",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Kertamukti",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Ciroyom",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Girimukti",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Karangsari",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Cijambe",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Cikelet",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Awassagara",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cikelet",
                "kelurahan": "Cigadog",
                "kodepos": "44177"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Sagara",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Sancang",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Simpang",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Mekarwangi",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Najaten",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Mekarsari",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Karyasari",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Maroko",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Mekarmukti",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Cigaronggong",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Cibalong",
                "kelurahan": "Karya Mukti",
                "kodepos": "44176"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Pameungpeuk",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Sirnabakti",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Mandalakasih",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Paas",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Bojong Kidul",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Jatimulya",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Mancagahar",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Bojong",
                "kodepos": "44175"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Sukamukti",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Sukanagara",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Panyindangan",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Sindangsari",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Neglasari",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Jatisari",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Margamulya",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Depok",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Cikondang",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Cisompet",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Sukawangi",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Cisompet",
                "kelurahan": "Cihaurkuning",
                "kodepos": "44174"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Pancasura",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Singajaya",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Sukamulya",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Mekartani",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Cihurip",
                "kelurahan": "Mekarwangi",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Cihurip",
                "kelurahan": "Jayamukti",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Girimukti",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Karangagung",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Cihurip",
                "kelurahan": "Cisangkal",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Ciudian",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Cihurip",
                "kelurahan": "Cihurip",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Singajaya",
                "kelurahan": "Cigintung",
                "kodepos": "44173"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Tanjungjaya",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Wangunjaya",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Padahurip",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Talagajaya",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Talagasari",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Kadongdong",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Mulyajaya",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Jayabakti",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Bojong",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Dangiang",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Simpang",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Banjarwangi",
                "kelurahan": "Banjarwangi",
                "kodepos": "44172"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Mekarsari",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Padasuka",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Margamulya",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Mekarjaya",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Girijaya",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Kramatwangi",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Cikandang",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Cipangramatan",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Giriawas",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Cibodas",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Cikajang",
                "kelurahan": "Cikajang",
                "kodepos": "44171"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Pananjung",
                "kodepos": "44168"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Panawa",
                "kodepos": "44168"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Garumukti",
                "kodepos": "44168"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Linggarjati",
                "kodepos": "44168"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Sukamaju",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Sukamulya",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Selaawi",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Sukalaksana",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Mekarwangi",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Mekarmulya",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Sukarame",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Talegong",
                "kelurahan": "Mekarmukti",
                "kodepos": "44167"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Purbayani",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Samuderajaya",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Sukajaya",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Pamalayan",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Panggalih",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Mekarsewu",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Nyalindung",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Karangsewu",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Indralayang",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Girimukti",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cimahi",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Cisewu",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Caringin",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Cisewu",
                "kelurahan": "Cikarang",
                "kodepos": "44166"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Tegalega",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Wangunjaya",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Mekarmukti",
                "kelurahan": "Mekarmukti",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Mekarmukti",
                "kelurahan": "Mekarsari",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Sinarjaya",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Mekarjaya",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Margalaksana",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Mekarbakti",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Mekarmukti",
                "kelurahan": "Jagabaya",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Mekarmukti",
                "kelurahan": "Karangwangi",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Hegarmanah",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Gunung Jampang",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Hanjuang",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Gunamekar",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Mekarmukti",
                "kelurahan": "Cijayana",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Cihikeu",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Bungbulang",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Bungbulang",
                "kelurahan": "Bojong",
                "kodepos": "44165"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Wangunjaya",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Tanjungmulya",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Tegalgede",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Sukamulya",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Talagawangi",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Tanjungjaya",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Panyindangan",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Pasirlangu",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pamulihan",
                "kelurahan": "Pakenjeng",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Neglasari",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Jayamekar",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Karangsari",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Tambakbaya",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Depok",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Pakenjeng",
                "kelurahan": "Jatiwangi",
                "kodepos": "44164"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Sukatani",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Sukawargi",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukamulya",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukaresmi",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukajaya",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukalilah",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Situsari",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Sirnagalih",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Sirnajaya",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Pangauban",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Simpangsari",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Pakuwon",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Pamulihan",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Mekarjaya",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Padamukti",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Cisurupan",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Karamatwangi",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Cipaganti",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Cisero",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cintadamai",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Cintaasih",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Balewangi",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Cisurupan",
                "kelurahan": "Cidatar",
                "kodepos": "44163"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Sukarame",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Sukasenang",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Sukamanah",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Sirnagalih",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Salakuray",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Panembong",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Pamalayan",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Mulyasari",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Mekarjaya",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Mekarsari",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Karyajaya",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Hegarmanah",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Cinisti",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Cikedokan",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Ciela",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Ciburuy",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Bayongbong",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Bayongbong",
                "kelurahan": "Banjarsari",
                "kodepos": "44162"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Tanjunganom",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Tanjung Karya",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Sukarasa",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Talaga",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Sukalaksana",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Sukakarya",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Sirnasari",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Sirnajaya",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Sarimukti",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Samarang",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Pasirwangi",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Pasirkiamis",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Parakan",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Padasuka",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Padamulya",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Padamukti",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Padaasih",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Padaawas",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Karyamekar",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Cisarua",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Cintarayat",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Cintarasa",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Cintaasih",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Samarang",
                "kelurahan": "Cintakarya",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Pasirwangi",
                "kelurahan": "Barusari",
                "kodepos": "44161"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Tanggulun",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Rancasalak",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Talagasari",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Mekarbakti",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Neglasari",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Karangmulya",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Karangtengah",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Mandalasari",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Hegarsari",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Kadungora",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Harumansari",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Cisaat",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Gandamekar",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Kadungora",
                "kelurahan": "Cikembulan",
                "kodepos": "44153"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sukarame",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Salamnunggal",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Leles",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Lembang",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Margaluyu",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Jangkurang",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Kandangmukti",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Dano",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Haruman",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Cipancar",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Ciburial",
                "kodepos": "44152"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Tanjungkamuning",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Tarogong",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Sukawangi",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Sukajaya",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Sukakarya",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Sukagalih",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Sukajadi",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Sukabakti",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Sirnajaya",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Rancabango",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Pataruman",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Panjiwangi",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Pasawahan",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Mekarwangi",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Pananjung",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Mekarjaya",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Mekargalih",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Langensari",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Jayawaras",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Kersamenak",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Jati",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Jayaraga",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kaler",
                "kelurahan": "Cimanganten",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Haurpanggung",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Muara Sanding",
                "kodepos": "44119"
            },
            {
                "kecamatan": "Tarogong Kidul",
                "kelurahan": "Cibunar",
                "kodepos": "44151"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Cangkuang",
                "kodepos": "44119"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Pakuwon",
                "kodepos": "44117"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Paminggir",
                "kodepos": "44118"
            },
            {
                "kecamatan": "Cigedug",
                "kelurahan": "Sukahurip",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Sukamantri",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Cigedug",
                "kelurahan": "Cigedug",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Cigedug",
                "kelurahan": "Cintanagara",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Cigedug",
                "kelurahan": "Sindangsari",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Cigedug",
                "kelurahan": "Barusuda",
                "kodepos": "44116"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Tegalpanjang",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Tenjonagara",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Sukalaksana",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Sukaratu",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Linggamukti",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Sadang",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Sucinaraja",
                "kelurahan": "Cigadog",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Ciwalen",
                "kodepos": "44115"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Margawati",
                "kodepos": "44113"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Regol",
                "kodepos": "44114"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Kotakulon",
                "kodepos": "44112"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Cimuncang",
                "kodepos": "44113"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Kotawetan",
                "kodepos": "44111"
            },
            {
                "kecamatan": "Garut Kota",
                "kelurahan": "Sukanegla",
                "kodepos": "44111"
            }
        ],
        "k70": [
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Tugubandung",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Kabandungan",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Mekarjaya",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Cihamerang",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Cipeuteuy",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Kabandungan",
                "kelurahan": "Cianaga",
                "kodepos": "43368"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Tamansari",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Pangkalan",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Sampora",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Gunung Malang",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Mekarnangka",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Nangkakoneng",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Cikidang",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Cikiray",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Cicareuh",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Cijambe",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Wanajaya",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Wangunsari",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cikidang",
                "kelurahan": "Bumisari",
                "kodepos": "43367"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Pasirbaru",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Sirnaresmi",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Karangpapak",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Gunung Kramat",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Gunung Tanjung",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Cikelat",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Cisolok",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Cicadas",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Cikahuripan",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Sukamaju",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cisolok",
                "kelurahan": "Caringin",
                "kodepos": "43366"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Ridogalih",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Sirnarasa",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Gandasoli",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Margalaksana",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Citepus",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Cimaja",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Cirendang",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Cikakak",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Cikakak",
                "kelurahan": "Cileungsing",
                "kodepos": "43365"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Pelabuhanratu (Palabuhanratu)",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Tonjong",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Jayanti",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Pasirsuren",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Cimanggu",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Citarik",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Cibodas",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Cikadu",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Bantargadung",
                "kelurahan": "Mangun Jaya",
                "kodepos": "43363"
            },
            {
                "kecamatan": "Pelabuhan/Palabuhan Ratu",
                "kelurahan": "Buniwangi",
                "kodepos": "43364"
            },
            {
                "kecamatan": "Bantargadung",
                "kelurahan": "Bojong Galing",
                "kodepos": "43363"
            },
            {
                "kecamatan": "Bantargadung",
                "kelurahan": "Limus Nunggal",
                "kodepos": "43363"
            },
            {
                "kecamatan": "Bantargadung",
                "kelurahan": "Bantar Gadung",
                "kodepos": "43363"
            },
            {
                "kecamatan": "Bantargadung",
                "kelurahan": "Bantar Gebang",
                "kodepos": "43363"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Sukaharja",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Warungkiara",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Kertamukti",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Ubrug",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Mekarjaya",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Sirnajaya",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Girijaya",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Hegarmanah",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Bojongkerta",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Warung Kiara",
                "kelurahan": "Bantarkalong",
                "kodepos": "43362"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Mekarasih",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Sangrawayang",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Loji",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Cihaur",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Kertajaya",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Cibuntu",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Simpenan",
                "kelurahan": "Cidadap",
                "kodepos": "43361"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Tenjoayu",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Tenjolaya",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Pasawahan",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Purwasari",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Nanggerang",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Nyangkowek",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Kutajaya",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Mekarsari",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Cicurug",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Cisaat",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Benda",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Caringin",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Tangkil",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cicurug",
                "kelurahan": "Bangbayang",
                "kodepos": "43359"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Pondok Kaso Tonggoh",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Jayabakti",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Pasirdoton",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Pondok Kaso Tengah",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Cidahu",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Girijaya",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Sundawenang",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Cidahu",
                "kelurahan": "Babakan Pari",
                "kodepos": "43358"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Parung Kuda",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Pondok Kaso Landeuh",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Langensari",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Palasari Hilir",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Bojong Kokosan",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Kompa",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Pawenang",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Parung Kuda",
                "kelurahan": "Babakanjaya",
                "kodepos": "43357"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Nagrak Selatan",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Nagrak Utara",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Ciambar",
                "kelurahan": "Munjul",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Kalaparea",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Ciambar",
                "kelurahan": "Ginanjar",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Girijaya",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Cisarua",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Darmareja",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Ciambar",
                "kelurahan": "Cibunarjaya",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Cihanyawar",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Ciambar",
                "kelurahan": "Ciambar",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Balekambang",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Ciambar",
                "kelurahan": "Ambarjaya",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Nagrak",
                "kelurahan": "Babakan Panjang",
                "kodepos": "43356"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Sukakersa",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Sukatani",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Lebaksari",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Parakansalak",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Bojongasih",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Parakan Salak",
                "kelurahan": "Bojonglongok",
                "kodepos": "43355"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Pulosari",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Walangsari",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Kalapa Nunggal",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Makasari",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Palasari Girang",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Kadununggal",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Kalapa Nunggal",
                "kelurahan": "Gunung Endut",
                "kodepos": "43354"
            },
            {
                "kecamatan": "Bojong Genteng",
                "kelurahan": "Cipanengah",
                "kodepos": "43353"
            },
            {
                "kecamatan": "Bojong Genteng",
                "kelurahan": "Bojong Genteng",
                "kodepos": "43353"
            },
            {
                "kecamatan": "Bojong Genteng",
                "kelurahan": "Cibodas",
                "kodepos": "43353"
            },
            {
                "kecamatan": "Bojong Genteng",
                "kelurahan": "Berekah",
                "kodepos": "43353"
            },
            {
                "kecamatan": "Bojong Genteng",
                "kelurahan": "Bojong Galing/Galih",
                "kodepos": "43353"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Sukasirna",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Tenjojaya",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Warnajati",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Sekarwangi",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Sekarwangi",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Cibadak",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Ciheulang Tonggoh",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Karangtengah",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Neglasari",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Cibadak",
                "kelurahan": "Batununggal",
                "kodepos": "43351"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Karangjaya",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Sukamanah",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Cijurey",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Gegerbitung",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Ciengang",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Wangunreja",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Buniwangi",
                "kodepos": "43197"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Neglasari",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Nyalindung",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Sukamaju",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Kertaangsana",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Mekarsari",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Cijangkar",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Cisitu",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Bojongsari",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Kebonpedes",
                "kelurahan": "Sasagaran",
                "kodepos": "43194"
            },
            {
                "kecamatan": "Nyalindung",
                "kelurahan": "Bojongkalong",
                "kodepos": "43196"
            },
            {
                "kecamatan": "Kebonpedes",
                "kelurahan": "Jambenenggang",
                "kodepos": "43194"
            },
            {
                "kecamatan": "Kebonpedes",
                "kelurahan": "Kebonpedes",
                "kodepos": "43194"
            },
            {
                "kecamatan": "Kebonpedes",
                "kelurahan": "Bojongsawah",
                "kodepos": "43194"
            },
            {
                "kecamatan": "Kebonpedes",
                "kelurahan": "Cikaret",
                "kodepos": "43194"
            },
            {
                "kecamatan": "Cireunghas",
                "kelurahan": "Cipurut",
                "kodepos": "43193"
            },
            {
                "kecamatan": "Cireunghas",
                "kelurahan": "Cireunghas",
                "kodepos": "43193"
            },
            {
                "kecamatan": "Cireunghas",
                "kelurahan": "Tegalpanjang",
                "kodepos": "43193"
            },
            {
                "kecamatan": "Cireunghas",
                "kelurahan": "Cikurutug",
                "kodepos": "43193"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukaraja",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Cireunghas",
                "kelurahan": "Bencoy",
                "kodepos": "43193"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukamekar",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Selaawi",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Selawangi",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Pasirhalang",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Limbangan",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Margaluyu",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Titisan",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cisarua",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Langensari",
                "kodepos": "43192"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Sukalarang",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Sukamaju",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Semplak",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Prianganjaya",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Purabaya",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Sukalarang",
                "kelurahan": "Cimangkok",
                "kodepos": "43191"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Margaluyu",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Neglasari",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Pagelaran",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Cimerang",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Citamiang",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Tegalbuleud",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Purabaya",
                "kelurahan": "Cicukang",
                "kodepos": "43187"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Rambay",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Sumberjaya",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Calingcing",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Nangela",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Bangbayang",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Tegal Buleud",
                "kelurahan": "Bangbayang",
                "kodepos": "43186"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Sekarsari",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Sukaluyu",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Cimahpar",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Kalibunder",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Balekambang",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Kali Bunder",
                "kelurahan": "Bojong",
                "kodepos": "43185"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Mekarjaya",
                "kodepos": "43184"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Tegallega",
                "kodepos": "43184"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Cidolog",
                "kodepos": "43184"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Cikarang",
                "kodepos": "43184"
            },
            {
                "kecamatan": "Cidolog",
                "kelurahan": "Cipamingkis",
                "kodepos": "43184"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Padasenang",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Tenjolaut",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Hegarmulya",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Mekartani",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Banjarsari",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Cidadap",
                "kodepos": "43183"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Sindangraja",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Tanjungsari",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Curugkembar",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Mekartanjung",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Bojong Tugu",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Curugkembar",
                "kelurahan": "Cimenteng",
                "kodepos": "43182"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Sagaranten",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Sinar Bentang",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Mekarsari",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Pasanggrahan",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Puncakmanggis",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Hegarmanah",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Margaluyu",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Datarnangka",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Gunung Bentang",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Curugluhur",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Cibaregbeg",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Sagaranten",
                "kelurahan": "Cibitung",
                "kodepos": "43181"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Swakarya",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Wanasari",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Sirnasari",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Sukatani",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Kadaleman",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Kademangan",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Pasiripis",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Gunung Sungging",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Jagamukti",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Cipeundeuy",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Citanglar",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Surade",
                "kelurahan": "Buniwangi",
                "kodepos": "43179"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Tanjung",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Sukamaju",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Sukamanah",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Sukajadi",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Nagraksari",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Padajaya",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Karangmekar",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Mekarjaya",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Jampang Kulon",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Karanganyar",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cimanggu",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Ciparay",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Cikarang",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Cikaranggeusan",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Bojongsari",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Boregah Indah",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Jampang Kulon",
                "kelurahan": "Bojong Genteng",
                "kodepos": "43178"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Tamanjaya",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Mekarjaya",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Mekarsakti",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Girimukti",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Mandrajaya",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Ciemas",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Ciwaru",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Purwasedar",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Ciemas",
                "kelurahan": "Cibenda",
                "kodepos": "43177"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Gunungbatu",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Mekarsari",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Pasirpanjang",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Cikangkung",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Ciracap",
                "kelurahan": "Ciracap",
                "kodepos": "43176"
            },
            {
                "kecamatan": "Waluran",
                "kelurahan": "Waluran",
                "kodepos": "43175"
            },
            {
                "kecamatan": "Waluran",
                "kelurahan": "Waluran Mandiri",
                "kodepos": "43175"
            },
            {
                "kecamatan": "Waluran",
                "kelurahan": "Mekar Mukti",
                "kodepos": "43175"
            },
            {
                "kecamatan": "Waluran",
                "kelurahan": "Sukamukti",
                "kodepos": "43175"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Tegallega",
                "kodepos": "43174"
            },
            {
                "kecamatan": "Waluran",
                "kelurahan": "Caringin Nunggal",
                "kodepos": "43175"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Lengkong",
                "kodepos": "43174"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Neglasari",
                "kodepos": "43174"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Cilangkap",
                "kodepos": "43174"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Langkapjaya",
                "kodepos": "43174"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Sirnasari",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Sukajaya",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Ciwalat",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Bantarsari",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Cibadak",
                "kodepos": "43173"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cidahu",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Talagamurni",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Banyuwangi",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cibitung",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cibodas",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Banyumurni",
                "kodepos": "43172"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Panumbangan",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Sindangresmi",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Tanjungsari",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Nangerang",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Padabeunghar",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Cijulang",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Jampang Tengah",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Bantarpanjang",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Bojong Jengkol",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Bojongtipar",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Jampang Tengah",
                "kelurahan": "Bantar Agung",
                "kodepos": "43171"
            },
            {
                "kecamatan": "Lembursitu",
                "kelurahan": "Lembursitu",
                "kodepos": "43169"
            },
            {
                "kecamatan": "Lembursitu",
                "kelurahan": "Situmekar",
                "kodepos": "43169"
            },
            {
                "kecamatan": "Lembursitu",
                "kelurahan": "Cikundul",
                "kodepos": "43168"
            },
            {
                "kecamatan": "Lembursitu",
                "kelurahan": "Sindangsari",
                "kodepos": "43167"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Jayaraksa",
                "kodepos": "43166"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Jayamekar",
                "kodepos": "43166"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Limusnunggal",
                "kodepos": "43165"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Sindangpalay",
                "kodepos": "43162"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Cibeureumhilir",
                "kodepos": "43165"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Baros",
                "kodepos": "43161"
            },
            {
                "kecamatan": "Baros",
                "kelurahan": "Sudajayahilir",
                "kodepos": "43161"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Sukamulya",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Sukamaju",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Kertaraharja",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Parakanlima",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Cimanggu",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Cikembar",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Cibatu",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Bojongraharja",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Bojongkembar",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Cikembar",
                "kelurahan": "Bojong",
                "kodepos": "43157"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Sirnaresmi",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Kebonmanggu",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Gunung Guruh",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Cikujang",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Cibolang",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Gunung Guruh",
                "kelurahan": "Cibentang",
                "kodepos": "43156"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Sukadamai",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Lembursawah",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Hegarmanah",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Cisande",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Cimanggis",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Cimahi",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Cijalingan",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Cicantayan",
                "kelurahan": "Cicantayan",
                "kodepos": "43155"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Talaga",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Sukamulya",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Seuseupan",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Mekarjaya",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cijengkol",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cikembang",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Caringin Wetan",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Caringin Kulon",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Geger Bitung",
                "kelurahan": "Caringin",
                "kodepos": "43154"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Undrus Binangun",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Sukamaju",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Kadudampit",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Sukamanis",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Gede Pangrango",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Muaradua",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Cipetir",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Citamiang",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Kadudampit",
                "kelurahan": "Cikahuripan",
                "kodepos": "43153"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Sukaresmi",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Sukamantri",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Selajambe",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Sukamanah",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Padaasih",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Kutasirna",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Gunungjaya",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Cisaat",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Cibolang Kaler",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Babakan",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Cibatu",
                "kodepos": "43152"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Sukajaya",
                "kodepos": "43151"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Warnasari",
                "kodepos": "43151"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Karawang",
                "kodepos": "43151"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Parungseah",
                "kodepos": "43151"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Sudajaya Girang",
                "kodepos": "43151"
            },
            {
                "kecamatan": "Citamiang",
                "kelurahan": "Nanggeleng",
                "kodepos": "43145"
            },
            {
                "kecamatan": "Citamiang",
                "kelurahan": "Gedongpanjang",
                "kodepos": "43144"
            },
            {
                "kecamatan": "Citamiang",
                "kelurahan": "Cikondang",
                "kodepos": "43142"
            },
            {
                "kecamatan": "Citamiang",
                "kelurahan": "Citamiang",
                "kodepos": "43143"
            },
            {
                "kecamatan": "Citamiang",
                "kelurahan": "Tipar",
                "kodepos": "43141"
            },
            {
                "kecamatan": "Cibeureum",
                "kelurahan": "Babakan",
                "kodepos": "43142"
            },
            {
                "kecamatan": "Warudoyong",
                "kelurahan": "Sukakarya",
                "kodepos": "43135"
            },
            {
                "kecamatan": "Warudoyong",
                "kelurahan": "Dayeuhluhur",
                "kodepos": "43134"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Sukasari",
                "kodepos": "43134"
            },
            {
                "kecamatan": "Warudoyong",
                "kelurahan": "Warudoyong",
                "kodepos": "43133"
            },
            {
                "kecamatan": "Lembursitu",
                "kelurahan": "Cipanengah",
                "kodepos": "43134"
            },
            {
                "kecamatan": "Cisaat",
                "kelurahan": "Nagrak",
                "kodepos": "43132"
            },
            {
                "kecamatan": "Warudoyong",
                "kelurahan": "Nyomplong",
                "kodepos": "43131"
            },
            {
                "kecamatan": "Warudoyong",
                "kelurahan": "Benteng",
                "kodepos": "43132"
            },
            {
                "kecamatan": "Gunung Puyuh",
                "kelurahan": "Karamat",
                "kodepos": "43122"
            },
            {
                "kecamatan": "Gunung Puyuh",
                "kelurahan": "Gunung Puyuh",
                "kodepos": "43123"
            },
            {
                "kecamatan": "Gunung Puyuh",
                "kelurahan": "Karangtengah",
                "kodepos": "43121"
            },
            {
                "kecamatan": "Gunung Puyuh",
                "kelurahan": "Sriwedari",
                "kodepos": "43121"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Cisarua",
                "kodepos": "43115"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Subangjaya",
                "kodepos": "43116"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Cikole",
                "kodepos": "43113"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Selabatu",
                "kodepos": "43114"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Gunung Parang",
                "kodepos": "43111"
            },
            {
                "kecamatan": "Cikole",
                "kelurahan": "Kebonjati",
                "kodepos": "43112"
            }
        ],
        "k71": [
            {
                "kecamatan": "Mande",
                "kelurahan": "Sukamanah",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Mulyasari",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Murnisari",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Leuwikoja",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Mande",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Mekarjaya",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Jamali",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Kademangan",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Kutawaringin",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Bobojong",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Ciandam",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Mande",
                "kelurahan": "Cikidangbayabang",
                "kodepos": "43292"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Sukagalih",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Sukamulya",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Warudoyong",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Mentengsari",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Neglasari",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Padajaya",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Mekargalih",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Mekarjaya",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Majalaya",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Mekar Mulya",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Mekar Sari",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Lembahsari",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Gudang",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Kamurang",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Cinangsi",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Cirama Girang",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Cigunungherang",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cikalongkulon",
                "kelurahan": "Cijagang",
                "kodepos": "43291"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Sukakerta",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Sukasari",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Rancagoong",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Sindangsari",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Sirnagalih",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Munjul",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Rahong",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Ciharashas",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Mulyasari",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Cilaku",
                "kelurahan": "Cibinong Hilir",
                "kodepos": "43285"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Tanjungsari",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Sukamulya",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Sukasirna",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Sukamulya",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Sukamanah",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Sukamahi",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Sukamaju",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Sukaluyu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Sukaluyu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Sinarbakti",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Sukaluyu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Sindangraja",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Parakantugu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Selajambe",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Panyusuhan",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Padaluyu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Padaasih",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Mekarlaksana",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Mekarwangi",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Mekarjaya",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Mekarjaya",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Hegarmanah",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Kalapanunggal",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Cikadu",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cikadu",
                "kelurahan": "Cisaranten",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Cijati",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Cibodas",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Bojonglarang",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Cijati",
                "kelurahan": "Caringin",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Sukaluyu",
                "kelurahan": "Babakansari",
                "kodepos": "43284"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Sukaratu",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Sukatani (Mekarwangi)",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Sukajaya",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Sukarama",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Sukajaya",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Kertasari",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Neglasari",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Jatisari",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Kemang",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Hegarmanah",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Jati",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Cihea",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Haurwangi",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Cikondang",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Bojongpicung",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Bojongpicung",
                "kelurahan": "Cibarengkok",
                "kodepos": "43283"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Sindangjaya",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Sindangsari",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Mekarwangi",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Nanggalamekar",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Kertamukti",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Mekargalih",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Gunungsari",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Karangwangi",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Karangwangi",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Haurwangi",
                "kelurahan": "Cipeuyeum",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Ciranjang",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukataris",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Ciranjang",
                "kelurahan": "Cibiuk",
                "kodepos": "43282"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukasarana",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukasari",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukamanah",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukamantri",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukamulya",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sindanglaka",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sukajadi",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sabandar",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Sindangasih",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Langensari",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Maleber",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Ciputri",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Hegarmanah",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Bojong",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Ciherang",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Sukapura",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Babakancaringin",
                "kodepos": "43281"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Neglasari",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Puncakbaru",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Karyabakti",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Kertajadi",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Mekarjaya",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Karangwangi",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Jayapura",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Gelarpawitan",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Gelarwangi",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Cidamar",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Cimaragang",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Cisalak",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Wangunsari",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Cidaun",
                "kelurahan": "Cibuluh",
                "kodepos": "43275"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Sukamulya",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Wanasari",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Wangunjaya",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Naringgul",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Sukabakti",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Mekarsari",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Margasari",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Malati",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Cinerang",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Naringgul",
                "kelurahan": "Balegede",
                "kodepos": "43274"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Wangunjaya",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Wanasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Walahir",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Tanjungsari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sukamulya",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sukasirna",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Sukamanah",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sukajaya",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sindangsari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Sirnasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Sinarlaut",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Purabaya",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Pusakasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Puncakwangi",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Neglasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Nagasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Mulyasari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Mekarsari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Mandalawangi",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Leles",
                "kelurahan": "Karyamukti",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Karangsari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Bojongkaso",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Agrabinta",
                "kelurahan": "Bunisari",
                "kodepos": "43273"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Talagasari",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Sirnagalih",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Saganten",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Mekarlaksana",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Muaracikadu",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Kertasari",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Kertamukti",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Jatisari",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Jayagiri",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Wargaluyu",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Hegarsari",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Sukamekar",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Sindangbarang",
                "kelurahan": "Girimukti",
                "kodepos": "43272"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Sukajadi",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Panyindangan",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Pananggapan",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Pamoyanan",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Padasuka",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Mekarmukti",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Hamerang",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Cimaskara",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Girijaya",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Cikangkareng",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Ciburial",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Campaka Mulya",
                "kelurahan": "Sukasirna",
                "kodepos": "43269"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Batulawang",
                "kodepos": "43271"
            },
            {
                "kecamatan": "Campaka Mulya",
                "kelurahan": "Cibanggala",
                "kodepos": "43269"
            },
            {
                "kecamatan": "Campaka Mulya",
                "kelurahan": "Sukabungah",
                "kodepos": "43269"
            },
            {
                "kecamatan": "Campaka Mulya",
                "kelurahan": "Campakamulya",
                "kodepos": "43269"
            },
            {
                "kecamatan": "Campaka Mulya",
                "kelurahan": "Campakawarna",
                "kodepos": "43269"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Wargaasih",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Wargasari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Talagasari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sukasari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sukaraharja",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sukaraja",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sukaresmi",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sindangsari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Sukakerta",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Neglasari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Pasirdalem",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Gandasari",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Kadupandak",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Kadupandak",
                "kelurahan": "Bojongkasih",
                "kodepos": "43268"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Sirnajaya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Sukajaya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Tanggeung",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Pasirjambu",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Rawagede",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Padamulya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Padaluyu",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Padamulya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Margaluyu",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Mekarmulya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Kertajaya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Kubang",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Karangtengah",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Girijaya",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Girimukti",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Cilongsong",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Tanggeung",
                "kelurahan": "Bojongpetir",
                "kodepos": "43267"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sindangkerta",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Situhiang",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sukamaju",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Selagedang",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Simpang",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pangadegan",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pasirbaru",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Pusakajaya",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Padamaju",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pagelaran",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Karangjaya",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Kertaraharja",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Mekarsari",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pasirkuda",
                "kelurahan": "Kalibaru",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Karangharja",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Bunijaya",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Buniwangi",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Gelaranyar",
                "kodepos": "43266"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Sukagalih",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Waringinsari",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Sindanghayu",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Sindangresmi",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Pasawahan",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Simpang",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Hegarmanah",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Cisujen",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Takokak",
                "kelurahan": "Bungbangsari",
                "kodepos": "43265"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukanagara",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukarame",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukakarya",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukalaksana",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukamekar",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sindangsari",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Sukajembar",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Gunungsari",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Jayagiri",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Wangunjaya",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Sukanagara",
                "kelurahan": "Ciguha",
                "kodepos": "43264"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Susukan",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Sukajadi",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Sukadana",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Karyamukti",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Margaluyu",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Mekarjaya",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cimenteng",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Girimukti",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cidadap",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Campaka",
                "kodepos": "43263"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Sukamaju",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Sukamanah",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Sukamanah",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Salagedang",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Salamnunggal",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Mayak",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Peuteuycondong",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Kanoman",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Karangnunggal",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cisalak",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Girimulya",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cimanggu",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cipetir",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cibokor",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cihaur",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cikondang",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cibaregbeg",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Cibeber",
                "kelurahan": "Cibadak",
                "kodepos": "43262"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Sukawangi",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Tegallega",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Sukaratu",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Sukamulya",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Mekarwangi",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Songgom",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Jambudipa",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Kebonpeuteuy",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Ciwalen",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Gekbrong",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Cintaasih",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Cisarandi",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Cikancana",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Cikaroya",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Cikahuripan",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Bunisari",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Cieundeur",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Warungkondang",
                "kelurahan": "Bunikasih",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukaresmi",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Gekbrong",
                "kelurahan": "Bangbayang",
                "kodepos": "43261"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Rawabelut",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Sukamahi",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Kubang",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Pakuon",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Ciwalen",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Kawungluwuk",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cikancana",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cikanyere",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cibadak",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Sukaresmi",
                "kelurahan": "Cibanteng",
                "kodepos": "43254"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Sukanagalih",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Sukatani",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Sindangjaya",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Sindanglaya",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Palasari",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Gadog",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Cipanas",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cipendawa",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Ciloto",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Cimacan",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cibodas",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Ciherang",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Wangunjaya",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cipanas",
                "kelurahan": "Batulawang",
                "kodepos": "43253"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Sukamulya",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Talaga",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Sukajaya",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Sukamanah",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Nyalindung",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Padaluyu",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Sarampad",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Gasol",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Mangunkerta",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Galudra",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Cirumput",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Cibeureum",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Cibulakan",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Cijedil",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cugenang",
                "kelurahan": "Benjot",
                "kodepos": "43252"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Nagrak",
                "kodepos": "43215"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Bojongherang",
                "kodepos": "43216"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Solokpandan",
                "kodepos": "43214"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Muka",
                "kodepos": "43215"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Sukamaju",
                "kodepos": "43211"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Sawah Gede",
                "kodepos": "43212"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Sayang",
                "kodepos": "43213"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Mekarsari",
                "kodepos": "43211"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Pamoyanan",
                "kodepos": "43211"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Babakankaret",
                "kodepos": "43211"
            },
            {
                "kecamatan": "Cianjur",
                "kelurahan": "Limbangan Sari",
                "kodepos": "43211"
            }
        ],
        "k72": [
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Tempuran",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Tanjungsari",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Tanjungjaya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Sumberjaya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Purwajaya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Pancakarya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Pagadungan",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Mekarpohaci",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Lemahsubur",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Lemahkarya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Lemahmakmur",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Lemahduhur",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Jayanagara",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Dayeuhluhur",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Ciptamargi",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Ciparagejaya",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Cikuntul",
                "kodepos": "41385"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Tegalwaru",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Tegalurung",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Tegalsari",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Sumurgede",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Sukatani",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Sukamulya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Sukakerta",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Sukajaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Rawagempol Wetan",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Rawagempol Kulon",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Pasirukem",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Pasirjaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Muktijaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Muarabaru",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Mekarmaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Muara",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Manggungjaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Langensari",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Kiara",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Cikarang",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Cilamaya",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Wetan",
                "kelurahan": "Cikalong",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Bayur Lor",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Cilamaya Kulon",
                "kelurahan": "Bayur Kidul",
                "kodepos": "41384"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Waringinkarya",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Pulomulya",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Pulokelapa",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Pulojaya",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Pasirtanjung",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Lemahmukti",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Lemahabang",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Kedawung",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Karyamukti",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Ciwaringin",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Lemahabang",
                "kelurahan": "Karangtanjung",
                "kodepos": "41383"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Sukapura",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Sukaraja",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Sekarwangi",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Sukamerta",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Pasirawi",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Pasirkaliki",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Purwamekar",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Mekarjaya",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Panyingkiran",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Gombongsari",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Kutawargi",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Balongsari",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Rawamerta",
                "kelurahan": "Cibadak",
                "kodepos": "41382"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Talagamulya",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Talagasari",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Pasirmukti",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Pasirtalaga",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Pulosari",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Pasirkamuning",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Kalisari",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Linggarsari",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Kalibuaya",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Kalijaya",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Cariumulya",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Cilewo",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Ciwulan",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Talagasari",
                "kelurahan": "Cadaskertajaya",
                "kodepos": "41381"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Telarsari",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Wancimekar",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Tanjung",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Talunjaya",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Situdam",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Sukamekar",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Sarimulya",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Pangulah Utara",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Pucung",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Pangulah Baru",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Pangulah Selatan",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Pacing",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Pamekaran",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Kutaraharja",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Mekarasih",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Mekarsari",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Kalijati",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Jomin Barat",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Jomin Timur",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Jayamukti",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Jatiwangi",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Jatiragas",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Jatisari",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Gempol",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Gempolkolot",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Jatibaru",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Cirejag",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Gembongan",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Cikalongsari",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Cikampek Utara",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Cicinde Utara",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Cicinde Selatan",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Barugbug",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Banyusari",
                "kelurahan": "Banyuasih",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Jatisari",
                "kelurahan": "Balonggandu",
                "kodepos": "41374"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Tegalsari",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Purwasari",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Sukasari",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Tamelang",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Karangsari",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Mekarjaya",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Kalihurip",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Kamojing",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Dawuan Tengah",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Dawuan Timur",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Cikampek Timur",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Dawuan Barat",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Cikampek Pusaka",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Cikampek Selatan",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Cikampek Barat",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Cikampek",
                "kelurahan": "Cikampek Kota",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Parakanmulya",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Cengkong",
                "kodepos": "41373"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Tirtasari",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Kertawaluya",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Parakan",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Karangsinom",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Citarik",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Kamurang",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Karangjaya",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Cipondoh",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Warungbambu",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Tirtamulya",
                "kelurahan": "Bojongsari",
                "kodepos": "41372"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Sumurkondang",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Walahar",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Sarijaya",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Pasirmulya",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Pancawati",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Pasirjengkol",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Margasari",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Lemahmulya",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Majalaya",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Kondangjaya",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Klari",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Karanganyar",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Kiarapayung",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Duren",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Gintungkerta",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Purwasari",
                "kelurahan": "Darawolong",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Ciranggon",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Curug",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Cibalongsari",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Cimahi",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Bengle",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Belendung",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Tegalega",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Klari",
                "kelurahan": "Anggadita",
                "kodepos": "41371"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Parungmulya",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Mulyasari",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Mulyasejati",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Kutapohaci",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Kutamekar",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Ciampel",
                "kelurahan": "Kutanegara",
                "kodepos": "41363"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Wargasetra",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Tamansari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Tamanmekar",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Mulangsari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Mekarbuana",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Medalsari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Kutamaneuh",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Jatilaksana",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Kertasari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Kutalanggeng",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Cipurwasari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Ciptasari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Cintalanggeng",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Cintawargi",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Cintalaksana",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Pangkalan",
                "kelurahan": "Cintaasih",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Tegalwaru",
                "kelurahan": "Cigunungsari",
                "kodepos": "41362"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Wanakerta",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Wanasari",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Wanajaya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Telukjambe",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Wadas",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Sukaluyu",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Sukamakmur",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Sukaharja",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Puseurjaya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Sirnabaya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Pinayungan",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Timur",
                "kelurahan": "Purwadana",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Mulyajaya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Parungsari",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Margamulya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Mekarmulya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Karangmulya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Margakaya",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Waluya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Telukjambe Barat",
                "kelurahan": "Karangligar",
                "kodepos": "41361"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Sindangmulya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Sindangsari",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Sindangkarya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Sindangmukti",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Mulyajaya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Sampalan",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Kutamukti",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Kutaraja",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Kutajaya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Kutakarya",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Tambaksari",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Tambaksumur",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Kutawaluya",
                "kelurahan": "Kutagandok",
                "kodepos": "41358"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Srikamulyan",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Sumurlaban",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Sabajaya",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Srijaya",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Kutamakmur",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Medankarya",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Pisangsambo",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Bolang",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Tirtajaya",
                "kelurahan": "Gempolkarya",
                "kodepos": "41357"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Sedari",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Sukasari",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Kedungjeruk",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Kertarahayu",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Pejaten",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Kalidungjaya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Kedungjaya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Gebangjaya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Jayamulya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Cemarajaya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Cibuaya",
                "kelurahan": "Cibuaya",
                "kodepos": "41356"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Telukbuyung",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Telukjaya",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Tanjungmekar",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Tanjungpakis",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Tanjungbungin",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Talagajaya",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Tanahbaru",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Telukambulu",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Telukbango",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Pakisjaya",
                "kelurahan": "Solokan",
                "kodepos": "41355"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Segaran",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Segarjaya",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Kutaampel",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Karyamakmur",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Karyamulya",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Karyabakti",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Batujaya",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Batujaya",
                "kelurahan": "Baturaden",
                "kodepos": "41354"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Sungaibuntu",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Sukaratu",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Randumulya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Rawasari",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Puspasari",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Pusakajaya Utara",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Payungsari",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Pusakajaya Selatan",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Labanjaya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Malangsari",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Kertamulya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Kertaraharja",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Kosambibatu",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Kedaljaya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Kertamukti",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Jatimulya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Karangjaya",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Pedes",
                "kelurahan": "Dongkal",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Rengasdengklok Utara",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Cilebar",
                "kelurahan": "Cikande",
                "kodepos": "41353"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Rengasdengklok Selatan",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Medangasem",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Kertasari",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Makmurjaya",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Kemiri",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Kertajaya",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Karyasari",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Kalangsari",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Kalangsuria",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Kampungsawah",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Jayakerta",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Jayamakmur",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Dukuhkarya",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Dewisari",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Jayakerta",
                "kelurahan": "Ciptamarga",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Tanjungpura",
                "kodepos": "41316"
            },
            {
                "kecamatan": "Rengasdengklok",
                "kelurahan": "Amansari",
                "kodepos": "41352"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Karangpawitan",
                "kodepos": "41315"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Tegalsawah",
                "kodepos": "41314"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Karawang Wetan",
                "kodepos": "41314"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Palawad",
                "kodepos": "41314"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Palumbonsari",
                "kodepos": "41314"
            },
            {
                "kecamatan": "Karawang Timur",
                "kelurahan": "Adiarsa Timur",
                "kodepos": "41314"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Nagasari",
                "kodepos": "41312"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Tanjungmekar",
                "kodepos": "41311"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Tunggakjati",
                "kodepos": "41311"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Karawang Kulon",
                "kodepos": "41311"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Mekarjati",
                "kodepos": "41311"
            },
            {
                "kecamatan": "Karawang Barat",
                "kelurahan": "Adiarsa Barat",
                "kodepos": "41311"
            }
        ],
        "k73": [
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Sukahurip",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Tanjungwangi",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Cirangkong",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Gunungtua",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Cikadu",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Cimenteng",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Cijambe",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cijambe",
                "kelurahan": "Bantarsari",
                "kodepos": "41286"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Sadawarna",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Sumurbarang",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Cisaga",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Majasari",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Padaasih",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Cinangsi",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Balendung",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Cibalandong Jaya",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Cibogo",
                "kelurahan": "Cibogo",
                "kodepos": "41285"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Sindanglaya",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Sirap",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Tanjungsiang",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Kawungluwuk",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Rancamanggung",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Gandasoli",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Cimeuhmal",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Cikawung",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Buniara",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Tanjungsiang",
                "kelurahan": "Cibuluh",
                "kodepos": "41284"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Tenjolaya",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Sukamelang",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Sukakerti",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Pasanggrahan",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Sindangsari",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Mayang",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Pakuhaji",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Gardusayang",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Cupunagara",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Darmaga",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Cimanggu",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Cisalak",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Cisalak",
                "kelurahan": "Cigadog",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Bojongloa",
                "kodepos": "41283"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Talagasari",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Sukamandi",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Sagalaherang",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Sagalaherang Kaler",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Ponggang",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Leles",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Cipancar",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Curugagung",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Dayeuhkolot",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Cijengkol",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Cikujang",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Serangpanjang",
                "kelurahan": "Cintamekar",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Sagalaherang",
                "kelurahan": "Cicadas",
                "kodepos": "41282"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Tambakan",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Tambakmekar",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Sarireja",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Sanca",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Kumpay",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Nagrak",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Kasomalang Wetan",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Kasomalang Kulon",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Curugrendeng",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Jalancagak",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Cisaat",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Kasomalang",
                "kelurahan": "Cimanglid",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Cibitung",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Cibeusi",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Ciater",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Jalancagak",
                "kelurahan": "Bunihayu",
                "kodepos": "41281"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Sawangan",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Wantilan",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Jalupang",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Lengkong",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Cipeundeuy",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Cimayasari",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Banggalamulya",
                "kodepos": "41272"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Tanggulun Timur",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Tanggulun Barat",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Situsari",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Sukasari",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Margasari",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Rawalele",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Manyeti",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Marengmang",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Kalijati Timur",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Kalijati Barat",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Kaliangsana",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Jambelaer",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Jalupang",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Dawuan Kidul",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Dawuan Kaler",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Cisampih",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Caracas",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Ciruluk",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Dawuan",
                "kelurahan": "Batusari",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Kalijati",
                "kelurahan": "Banggalamulya",
                "kodepos": "41271"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Tanjungrasa Kidul",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Tanjungrasa Kaler",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Tambakjati",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Rancajaya",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Rancamulya",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Rancabango",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Rancaasih",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Gempolsari",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Jatiragas Hilir",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Patokbeusi",
                "kelurahan": "Ciberes",
                "kodepos": "41263"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Siluman",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Salamjaya",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pringkasap",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Kadawung",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Karanghegar",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Cihambulu",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Balebandung Jaya",
                "kodepos": "41262"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Wanakerta",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Rancamahi",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Purwadadi Timur",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Purwadadi Barat",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Pasirbungur",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Parapatan",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Panyingkiran",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Pagon",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Koranji",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Purwadadi",
                "kelurahan": "Belendung (Blendung)",
                "kodepos": "41261"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Tanjungtiga",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Rawameneng",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Rawamekar",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Muara",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Langensari",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Cilamaya Hilir",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Jayamukti",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Cilamaya Girang",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Blanakan",
                "kelurahan": "Blanakan",
                "kodepos": "41259"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Sukatani",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Sukadana",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Mekarjaya",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Kiarasari",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Kalensari",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Jatimulya",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Jatireja",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Compreng",
                "kelurahan": "Compreng",
                "kodepos": "41258"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Wanasari",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Tanjung",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Sidamulya",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Simpar",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Parigimulya",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Sidajaya",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Manyingsal",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Padamulya",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Kosambi",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Cipunagara",
                "kelurahan": "Jati",
                "kodepos": "41257"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Sukamandijaya",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Sukahaji",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Mandalawangi",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Pinangsari",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Jatibaru",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Dukuh",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Ciasem Tengah",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Ciasembaru",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Ciasem Girang",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Ciasem",
                "kelurahan": "Ciasem Hilir",
                "kodepos": "41256"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Rangdu",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Pusakaratu",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Rancadaka",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Patimban",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Pusakajaya",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Kotasari",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Mundusari",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Karanganyar",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Kebondanas",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Kalentambo",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Cigugur Kaler",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakanagara",
                "kelurahan": "Gempol",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Bojongtengah",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Cigugur",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Pusakajaya",
                "kelurahan": "Bojong Jaya",
                "kodepos": "41255"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukasari",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Tegalurung",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukamaju",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukareja",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Rancasari",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Rancahilir",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Pangarengan",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Pamanukan Hilir",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Pamanukan Sebrang",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Mulyasari",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Pamanukan",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Lengkongjaya",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Mayangan",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Legonwetan",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Karangmulya",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Legonkulon",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Curugreja",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Pamanukan",
                "kelurahan": "Bongas",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Legonkulon",
                "kelurahan": "Bobos",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Anggasari",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Batangsari",
                "kodepos": "41254"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Tanjungsari Timur",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Wanajaya",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Tanjungrasa",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Tanjungsari Barat",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Tambakdahan",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Sindangsari",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Rancaudik",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Pasirmuncang",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Mulyasari",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Nanggerang",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Mariuk",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Mekarsari",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Kihiyang",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Kertajaya",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Kediri",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Kaunganten",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Karangwangi",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Karangsari",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Gardumukti",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Gandasari",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Citrajaya",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Cikaum Timur",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Cicadas",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Cikaum",
                "kelurahan": "Cikaum Barat",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Bojonegara",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Tambakdahan",
                "kelurahan": "Bojongkeding",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Binong",
                "kelurahan": "Binong",
                "kodepos": "41253"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Sumurgintung",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Sumbersari",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Pagaden",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Pangsor",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Neglasari",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Mekarwangi",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Munjul",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Kamarung",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Margahayu",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Gunungsari",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Gunungsembung",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Jabong",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Cidahu",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Gambarsari",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Gembor",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Bendungan",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Cidadap",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden Barat",
                "kelurahan": "Balingbing",
                "kodepos": "41252"
            },
            {
                "kecamatan": "Pagaden",
                "kelurahan": "Sukamulya",
                "kodepos": "41251"
            },
            {
                "kecamatan": "Ciater",
                "kelurahan": "Palasari",
                "kodepos": "41251"
            },
            {
                "kecamatan": "Pabuaran",
                "kelurahan": "Pabuaran",
                "kodepos": "41251"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Soklat",
                "kodepos": "41215"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Cigadung",
                "kodepos": "41213"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Wanareja",
                "kodepos": "41211"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Dangdeur",
                "kodepos": "41212"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Pasirkareumbi",
                "kodepos": "41211"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Sukamelang",
                "kodepos": "41211"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Karanganyar",
                "kodepos": "41211"
            },
            {
                "kecamatan": "Subang",
                "kelurahan": "Parung",
                "kodepos": "41211"
            }
        ],
        "k74": [
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Wanawali",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Wanakerta",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Kertamukti",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Karangmukti",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Karyamekar",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cisaat",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Ciwangi",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Dangdeur",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cirende",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cirangkong",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Ciparungsari",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cipancur",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cipinang",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cimahi",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cipancur",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cilandak",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Cikopo",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cikumpay",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cijunti",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cikadu",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Cijaya",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Cibodas",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Cibungur",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cibukamanah",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Cibening",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Campakasari",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Cibatu",
                "kelurahan": "Cibatu",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Campaka",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Bungursari",
                "kelurahan": "Bungursari",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Campaka",
                "kelurahan": "Benteng",
                "kodepos": "41181"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Sumbersari",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Taringgul Landeuh",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Parakan Garokgek",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Parakan Garokgek",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Margaluyu",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Mekarjaya",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Gardu",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Kiarapedes",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Cibeber",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Kiarapedes",
                "kelurahan": "Ciracas",
                "kodepos": "41175"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Wanasari",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Wanayasa",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Taringgul Tengah",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Taringgul Tonggoh",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Sukadami",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Sumurugul",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Sakambang",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Simpang",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Raharja",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Nangerang",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Nagrog",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Cibuntu",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Legokhuni",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Ciawi",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Babakan",
                "kodepos": "41174"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Selaawi",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Warung Kadu",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Sawah Kulon",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Pasawahananyar",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Pasawahan Kidul",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Lebak Anyar",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Pasawahan",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Cihuni",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Kertajaya",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Cidahu",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Ciherang",
                "kodepos": "41172"
            },
            {
                "kecamatan": "Pasawahan",
                "kelurahan": "Margasari",
                "kodepos": "41171"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukamaju",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukatani",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Tajursindang",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sindanglaya",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukajaya",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Panyindangan",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Pasirmunjul",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Malangnengah",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cilalawi",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cipicung",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cibodas",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cijantung",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cianting",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Cianting Utara",
                "kodepos": "41167"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Sukamukti",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Tegaldatar",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Sinargalih",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Pasirjambu",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Citamiang",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Gunungkarung",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Cijati",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Maniis",
                "kelurahan": "Ciramahilir",
                "kodepos": "41166"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Warungjeruk",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Tegalwaru",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Sukamulya",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Tegalsari",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Karoya",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Pasanggrahan",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Citalang",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Galumpit",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Cadassari",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Cisarua",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Batutumpang",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Cadasmekar",
                "kodepos": "41165"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sukamanah",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sindangpanon",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sindangsari",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Pasanggrahan",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Pawenang",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kertasari",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Pangkalan",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cileunca",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cipeundeuy",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cibingbin",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cihanjawar",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cikeris",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong Timur",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Sirnamanah",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong Barat",
                "kodepos": "41164"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Sadarkarya",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Sawit",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Neglasari",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Pasirangin",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Nangewer",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Nagrak",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Mekarsari",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Linggasari",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Linggamukti",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Legoksari",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Depok",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Gununghejo",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Darangdan",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Darangdan",
                "kelurahan": "Cilingga",
                "kodepos": "41163"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Sindangsari",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Sempur",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Rawasari",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Plered",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Pamoyanan",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Liunggunung",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Palinggihan",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Linggarsari",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Gandamekar",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Gandasoli",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Citeko Kaler",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Cibogohilir",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Citeko",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Cibogogirang",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Anjun",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Plered",
                "kelurahan": "Babakansari",
                "kodepos": "41162"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Parakanlima",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Bunder",
                "kodepos": "41161"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Kembangkuning",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Mekargalih",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Jatiluhur",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Jatimekar",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Cisalada",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Cibinong",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Cikaobandung",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Jatiluhur",
                "kelurahan": "Cilegong",
                "kodepos": "41152"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Maracang",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Mulyamekar",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Kadumekar",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Ciwareng",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Hegarmanah",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Cicadas",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Cigelam",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Cilangkap",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Babakancikao",
                "kelurahan": "Babakancikao",
                "kodepos": "41151"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Ciseureuh",
                "kodepos": "41118"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Purwamekar",
                "kodepos": "41119"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Tegalmunjul",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Munjuljaya",
                "kodepos": "41117"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Kutamanah",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Parungbanteng",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukasari",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Kertamanah",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Tanjung Sari",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Ciririp",
                "kodepos": "41116"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Sukajadi",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Salem",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Situ",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Salamjaya",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Salammulya",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Parakansalam",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Pondokbungur",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Nagrikaler",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Gurudug",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Galudra",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Pondoksalam",
                "kelurahan": "Bungurjaya",
                "kodepos": "41115"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Nagritengah",
                "kodepos": "41114"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Sindangkasih",
                "kodepos": "41112"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Cipaisan",
                "kodepos": "41113"
            },
            {
                "kecamatan": "Tegal Waru",
                "kelurahan": "Sukahaji",
                "kodepos": "41111"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Nagrikidul",
                "kodepos": "41111"
            },
            {
                "kecamatan": "Purwakarta",
                "kelurahan": "Citalang",
                "kodepos": "41111"
            }
        ],
        "k75": [
            {
                "kecamatan": "Ranca Bali",
                "kelurahan": "Sukaresmi",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Sukawening",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ranca Bali",
                "kelurahan": "Patengan",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Panyocokan",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Rawabogo",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Lebakmuncang",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Nengkelan",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Panundaan",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ranca Bali",
                "kelurahan": "Indragiri",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ciwidey",
                "kelurahan": "Ciwidey",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ranca Bali",
                "kelurahan": "Alamendah",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Ranca Bali",
                "kelurahan": "Cipelah",
                "kodepos": "40973"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Sugihmukti",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Tenjolaya",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Mekarsari",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Pasirjambu",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Cukanggenteng",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Margamulya",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Mekarmaju",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Cikoneng",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Cisondari",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Sukamukti",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Pasirjambu",
                "kelurahan": "Cibodas",
                "kodepos": "40972"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Pangauban",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Sangkanhurip",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Cilampeni",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Gandasari",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Katapang",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Katapang",
                "kelurahan": "Banyusari",
                "kodepos": "40921"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Parungserab",
                "kodepos": "40914"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Sekarwangi",
                "kodepos": "40914"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Panyirapan",
                "kodepos": "40915"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Karamatmulya",
                "kodepos": "40914"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Sadu",
                "kodepos": "40913"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Cingcin",
                "kodepos": "40914"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Sukanagara",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Pamekaran",
                "kodepos": "40912"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Sukamulya",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Sukajadi",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Pameuntasan",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Soreang",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Kutawaringin",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Padasuka",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Kopo",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Jelegong",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Jatisari",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Gajahmekar",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Cibodas",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Cilame",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Kutawaringin",
                "kelurahan": "Buninagara",
                "kodepos": "40911"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cibiru Hilir",
                "kodepos": "40626"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cibiru Wetan",
                "kodepos": "40625"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cinunuk",
                "kodepos": "40624"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cimekar",
                "kodepos": "40623"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cileunyi Wetan",
                "kodepos": "40622"
            },
            {
                "kecamatan": "Cileunyi",
                "kelurahan": "Cileunyi Kulon",
                "kodepos": "40621"
            },
            {
                "kecamatan": "Ujung Berung",
                "kelurahan": "Pasir Endah",
                "kodepos": "40619"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Girimekar",
                "kodepos": "40619"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Melatiwangi",
                "kodepos": "40618"
            },
            {
                "kecamatan": "Ujung Berung",
                "kelurahan": "Pasirwangi",
                "kodepos": "40618"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Cipanjalu",
                "kodepos": "40618"
            },
            {
                "kecamatan": "Ujung Berung",
                "kelurahan": "Pasanggrahan",
                "kodepos": "40617"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Ciporeat",
                "kodepos": "40617"
            },
            {
                "kecamatan": "Ujung Berung",
                "kelurahan": "Pasirjati",
                "kodepos": "40616"
            },
            {
                "kecamatan": "Cibiru",
                "kelurahan": "Pasirbiru",
                "kodepos": "40615"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Jatiendah",
                "kodepos": "40616"
            },
            {
                "kecamatan": "Cibiru",
                "kelurahan": "Palasari",
                "kodepos": "40615"
            },
            {
                "kecamatan": "Cilengkrang",
                "kelurahan": "Cilengkrang",
                "kodepos": "40615"
            },
            {
                "kecamatan": "Panyileukan",
                "kelurahan": "Mekarmulya",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Cibiru",
                "kelurahan": "Cisurupan",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Panyileukan",
                "kelurahan": "Cipadung Wetan",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Panyileukan",
                "kelurahan": "Cipadung Kulon",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Panyileukan",
                "kelurahan": "Cipadung Kidul",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Cibiru",
                "kelurahan": "Cipadung",
                "kodepos": "40614"
            },
            {
                "kecamatan": "Ujung Berung",
                "kelurahan": "Cigending",
                "kodepos": "40611"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Srirahayu",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Tanjunglaya",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Hegarmanah",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Mandalasari",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Mekarlaksana",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Ciluluk",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Cikasungka",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Cihanyir",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cikancung",
                "kelurahan": "Cikancung",
                "kodepos": "40396"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Tenjolaya",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Waluya",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Panenjoan",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Tanjungwangi",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Nagrog",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Narawita",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Dampit",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Margaasih",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Cicalengka Wetan",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Cikuya",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Babakan Peuteuy",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Cicalengka",
                "kelurahan": "Cicalengka Kulon",
                "kodepos": "40395"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Sukamulya",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Tegal Sumedang",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Sangiang",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Sukamanah",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Rancaekek Kulon",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Rancaekek Wetan",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Linggar",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Nanjungmekar",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Rancaekek Kencana",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Haurpugur",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Jelegong",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Bojongsalam",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Cangkuang",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Rancaekek",
                "kelurahan": "Bojongloa",
                "kodepos": "40394"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Sukapura",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Tarumajaya",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Santosa",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Neglawangi",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Resmi Tingal",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Cihawuk",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Cikembang",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Kertasari",
                "kelurahan": "Cibeureum",
                "kodepos": "40386"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Tanjungwangi",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Pangauban",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Sukarame",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Mekarjaya",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Mekarsari",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Nagrak",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Girimulya",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Mandalahaji",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Maruyung",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cikitu",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cinanggela",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cipeujeuh",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Tanggulun",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cikawao",
                "kodepos": "40385"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Sudi",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Talun",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Neglasari",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Pangguh",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Lampegan",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Mekarwangi",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Karyalaksana",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Laksana",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Ibun",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Cibeet",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Ibun",
                "kelurahan": "Dukuh",
                "kodepos": "40384"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Sukamanah",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Sukamantri",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Tangsimekar",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Mekarpawitan",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Sindangsari",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Karangtunggal",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Loa",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Cipaku",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Cipedes",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Drawati",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Cigentur",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Paseh",
                "kelurahan": "Cijagra",
                "kodepos": "40383"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Sukamukti",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Wangisagara",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Padaulun",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Sukamaju",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Majasetra",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Neglasari",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Padamulya",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Majakerta",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Majalaya",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Bojong",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Sumbersari",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Majalaya",
                "kelurahan": "Biru",
                "kodepos": "40382"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Sarimahi",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Serangmekar",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Sigaracipta",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Mekar Laksana",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Mekarsari",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Pakutandang",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Gunungleutik",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Manggungharja",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Cikoneng",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Ciparay",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Babakan",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Bumiwangi",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Ciparay",
                "kelurahan": "Ciheulang",
                "kodepos": "40381"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Rancakole",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Wargaluyu",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Pinggirsari",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Patrolsari",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Mangunjaya",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Mekarjaya",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Lebakwangi",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Baros",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Batukarut",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Ancolmekar",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Arjasari",
                "kelurahan": "Arjasari",
                "kodepos": "40379"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Warnasari",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Tribaktimulya",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Wanasuka",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Sukaluyu",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Sukamanah",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Margamulya",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Pangalengan",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Pulosari",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Margamekar",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Margamukti",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Lamajang",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Margaluyu",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Tarajusari",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Pangalengan",
                "kelurahan": "Banjarsari",
                "kodepos": "40378"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Neglasari",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Pasirmulya",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Sindangpanon",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Mekarjaya",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Kiangroke",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Margahurip",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Ciapus",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Kamasan",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Banjaran Kulon",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Banjaran",
                "kelurahan": "Banjaran Wetan",
                "kodepos": "40377"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Solokan Jeruk",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Sukasari",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Panyadap",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Rancakasumba",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Langensari",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Rancamulya",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Langonsari",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Rancatungku",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Cibodas",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Panyadap",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Bojongmanggu",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Pameungpeuk",
                "kelurahan": "Bojongkunci",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Solokan Jeruk",
                "kelurahan": "Bojongemas",
                "kodepos": "40376"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Rancamanyar",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Wargamekar",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Mekarsari",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Jelekong",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Manggahang",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Baleendah",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Bojongmalaka",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Warjabakti",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Andir",
                "kodepos": "40375"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Sukamaju",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Pasirhuni",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Malasari",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Cimaung",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Cipinang",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Jagabaya",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Campakamulya",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cimaung",
                "kelurahan": "Cikalong",
                "kodepos": "40374"
            },
            {
                "kecamatan": "Cinambo",
                "kelurahan": "Sukamulya",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Rancasari",
                "kelurahan": "Manjahlega (Cisarantenkidul)",
                "kodepos": "40295"
            },
            {
                "kecamatan": "Gedebage",
                "kelurahan": "Rancabalong",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Gedebage",
                "kelurahan": "Rancanumpang",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Arcamanik",
                "kelurahan": "Cisarenten Bina Harapan",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Cinambo",
                "kelurahan": "Cisaranten Wetan",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Cinambo",
                "kelurahan": "Pakemitan",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Gedebage",
                "kelurahan": "Cimenerang (Cimincrang)",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Gedebage",
                "kelurahan": "Cisaranten Kidul",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Arcamanik",
                "kelurahan": "Sukamiskin",
                "kodepos": "40293"
            },
            {
                "kecamatan": "Cinambo",
                "kelurahan": "Babakan Penghulu",
                "kodepos": "40294"
            },
            {
                "kecamatan": "Arcamanik",
                "kelurahan": "Cisaranten Kulon",
                "kodepos": "40293"
            },
            {
                "kecamatan": "Arcamanik",
                "kelurahan": "Sindang Jaya",
                "kodepos": "40293"
            },
            {
                "kecamatan": "Rancasari",
                "kelurahan": "Darwati",
                "kodepos": "40292"
            },
            {
                "kecamatan": "Rancasari",
                "kelurahan": "Mekar Mulya (Mekarjaya)",
                "kodepos": "40292"
            },
            {
                "kecamatan": "Antapani (Cicadas)",
                "kelurahan": "Antapani Tengah",
                "kodepos": "40291"
            },
            {
                "kecamatan": "Antapani (Cicadas)",
                "kelurahan": "Antapani Wetan",
                "kodepos": "40291"
            },
            {
                "kecamatan": "Rancasari",
                "kelurahan": "Cipamokolan",
                "kodepos": "40292"
            },
            {
                "kecamatan": "Antapani (Cicadas)",
                "kelurahan": "Antapani Kulon",
                "kodepos": "40291"
            },
            {
                "kecamatan": "Antapani (Cicadas)",
                "kelurahan": "Antapani Kidul",
                "kodepos": "40291"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Bojongsoang",
                "kodepos": "40288"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Tegalluar",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Bojongsari",
                "kodepos": "40288"
            },
            {
                "kecamatan": "Bandung Kidul",
                "kelurahan": "Kujangsari",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Lengkong",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Cipagalo",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Buahbatu (Margacinta)",
                "kelurahan": "Cijaura (Margasenang)",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Bojongsoang",
                "kelurahan": "Buahbatu",
                "kodepos": "40287"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Babakan Surabaya",
                "kodepos": "40281"
            },
            {
                "kecamatan": "Buahbatu (Margacinta)",
                "kelurahan": "Margasari",
                "kodepos": "40286"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Gumuruh",
                "kodepos": "40275"
            },
            {
                "kecamatan": "Buahbatu (Margacinta)",
                "kelurahan": "Sekejati",
                "kodepos": "40286"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Binong",
                "kodepos": "40275"
            },
            {
                "kecamatan": "Buahbatu (Margacinta)",
                "kelurahan": "Jatisari",
                "kodepos": "40286"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Maleer",
                "kodepos": "40274"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Sukapura",
                "kodepos": "40285"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Samoja",
                "kodepos": "40273"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Kebon Kangkung",
                "kodepos": "40284"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Kebon Gedang",
                "kodepos": "40274"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Babakan Sari",
                "kodepos": "40283"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Cibangkong",
                "kodepos": "40273"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Cicaheum",
                "kodepos": "40282"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Kebonwaru",
                "kodepos": "40272"
            },
            {
                "kecamatan": "Kiaracondong",
                "kelurahan": "Kebun Jayanti",
                "kodepos": "40281"
            },
            {
                "kecamatan": "Batununggal",
                "kelurahan": "Kacapiring",
                "kodepos": "40271"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Sukapura",
                "kodepos": "40267"
            },
            {
                "kecamatan": "Bandung Kidul",
                "kelurahan": "Mengger",
                "kodepos": "40267"
            },
            {
                "kecamatan": "Bandung Kidul",
                "kelurahan": "Batununggal",
                "kodepos": "40266"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Cijagra",
                "kodepos": "40265"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Turangga",
                "kodepos": "40264"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Lingkar Selatan",
                "kodepos": "40263"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Malabar",
                "kodepos": "40262"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Burangrang",
                "kodepos": "40262"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Paledang",
                "kodepos": "40261"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Cikawao",
                "kodepos": "40261"
            },
            {
                "kecamatan": "Baleendah",
                "kelurahan": "Malakasari",
                "kodepos": "40258"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Dayeuhkolot",
                "kodepos": "40258"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Citeureup",
                "kodepos": "40257"
            },
            {
                "kecamatan": "Bandung Kidul",
                "kelurahan": "Wates",
                "kodepos": "40256"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Pasawahan",
                "kodepos": "40256"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Ciseureuh",
                "kodepos": "40255"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Pasirluyu",
                "kodepos": "40254"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Ancol",
                "kodepos": "40254"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Cigereleng",
                "kodepos": "40253"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Ciateul",
                "kodepos": "40252"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Pungkur",
                "kodepos": "40252"
            },
            {
                "kecamatan": "Regol",
                "kelurahan": "Balong Gede",
                "kodepos": "40251"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Pelindung Hewan",
                "kodepos": "40243"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Panjunan",
                "kodepos": "40242"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Karasak",
                "kodepos": "40243"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Karanganyar",
                "kodepos": "40241"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Nyengseret",
                "kodepos": "40242"
            },
            {
                "kecamatan": "Astana Anyar",
                "kelurahan": "Cibadak",
                "kodepos": "40241"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Cibaduyut Kidul",
                "kodepos": "40239"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Cangkuang Kulon",
                "kodepos": "40239"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Nagrak",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Pananjung",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Tanjungsari",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Jatisari",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Cibaduyut Wetan",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Ciluncat",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Dayeuhkolot",
                "kelurahan": "Cangkuang Wetan",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Cangkuang",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Mekarwangi",
                "kodepos": "40237"
            },
            {
                "kecamatan": "Cangkuang",
                "kelurahan": "Bandasari",
                "kodepos": "40238"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Kebon Lega",
                "kodepos": "40235"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Cibaduyut",
                "kodepos": "40236"
            },
            {
                "kecamatan": "Bojongloa Kidul",
                "kelurahan": "Situsaeur",
                "kodepos": "40234"
            },
            {
                "kecamatan": "Bojongloa Kaler",
                "kelurahan": "Babakan Asih",
                "kodepos": "40232"
            },
            {
                "kecamatan": "Bojongloa Kaler",
                "kelurahan": "Babakan Tarogong",
                "kodepos": "40232"
            },
            {
                "kecamatan": "Bojongloa Kaler",
                "kelurahan": "Kopo",
                "kodepos": "40233"
            },
            {
                "kecamatan": "Bojongloa Kaler",
                "kelurahan": "Jamika",
                "kodepos": "40231"
            },
            {
                "kecamatan": "Bojongloa Kaler",
                "kelurahan": "Suka Asih",
                "kodepos": "40231"
            },
            {
                "kecamatan": "Margahayu",
                "kelurahan": "Sulaeman",
                "kodepos": "40229"
            },
            {
                "kecamatan": "Margahayu",
                "kelurahan": "Sayati",
                "kodepos": "40228"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Cirangrang",
                "kodepos": "40227"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Cirangrang",
                "kodepos": "40227"
            },
            {
                "kecamatan": "Margahayu",
                "kelurahan": "Margahayu Selatan",
                "kodepos": "40226"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Margasuka",
                "kodepos": "40225"
            },
            {
                "kecamatan": "Margahayu",
                "kelurahan": "Margahayu Tengah",
                "kodepos": "40225"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Margahayu Utara",
                "kodepos": "40224"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Babakan",
                "kodepos": "40222"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Babakan Ciparay",
                "kodepos": "40223"
            },
            {
                "kecamatan": "Babakan Ciparay",
                "kelurahan": "Sukahaji",
                "kodepos": "40221"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Mekar Rahayu",
                "kodepos": "40218"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Rahayu",
                "kodepos": "40218"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Lagadar",
                "kodepos": "40216"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Nanjung",
                "kodepos": "40217"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Nagreg",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Nagreg Kendan",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Mandalawangi",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Margaasih",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Gempolsari",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Citaman",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Ganjar Sabar",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Ciherang",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Cigondewah Rahayu",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Bojong",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Nagreg",
                "kelurahan": "Ciaro",
                "kodepos": "40215"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Cigondewah Kidul",
                "kodepos": "40214"
            },
            {
                "kecamatan": "Margaasih",
                "kelurahan": "Cigondewah Hilir",
                "kodepos": "40214"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Cigondewah Kaler",
                "kodepos": "40214"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Cijerah",
                "kodepos": "40213"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Caringin",
                "kodepos": "40212"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Cibuntu",
                "kodepos": "40212"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Mekarsaluyu",
                "kodepos": "40198"
            },
            {
                "kecamatan": "Bandung Kulon",
                "kelurahan": "Warung Muncang",
                "kodepos": "40211"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Ciburial",
                "kodepos": "40198"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Mekarmanik",
                "kodepos": "40196"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Cimenyan",
                "kodepos": "40197"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Sindanglaya",
                "kodepos": "40195"
            },
            {
                "kecamatan": "Mandalajati",
                "kelurahan": "Pasir Impun",
                "kodepos": "40195"
            },
            {
                "kecamatan": "Mandalajati",
                "kelurahan": "Sindang Jaya",
                "kodepos": "40195"
            },
            {
                "kecamatan": "Mandalajati",
                "kelurahan": "Jatihandap",
                "kodepos": "40195"
            },
            {
                "kecamatan": "Mandalajati",
                "kelurahan": "Karang Pamulang",
                "kodepos": "40195"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Pasirlayung",
                "kodepos": "40192"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Pasirlayung",
                "kodepos": "40192"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Cikadut",
                "kodepos": "40191"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Padasuka",
                "kodepos": "40191"
            },
            {
                "kecamatan": "Cibeunying Kaler",
                "kelurahan": "Cigadung",
                "kodepos": "40191"
            },
            {
                "kecamatan": "Cimeunyan",
                "kelurahan": "Cibeunying",
                "kodepos": "40191"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Maleber (Maleer)",
                "kodepos": "40184"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Campaka",
                "kodepos": "40184"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Garuda",
                "kodepos": "40184"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Ciroyom",
                "kodepos": "40182"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Dungus Cariang",
                "kodepos": "40183"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Sukaraja",
                "kodepos": "40175"
            },
            {
                "kecamatan": "Andir",
                "kelurahan": "Kebon Jeruk",
                "kodepos": "40181"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Husen Sastranegara",
                "kodepos": "40174"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Pajajaran",
                "kodepos": "40173"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Pamoyanan",
                "kodepos": "40173"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Pasir Kaliki",
                "kodepos": "40171"
            },
            {
                "kecamatan": "Cicendo",
                "kelurahan": "Arjuna",
                "kodepos": "40172"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Sukagalih",
                "kodepos": "40163"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Sukawarna",
                "kodepos": "40164"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Cipedes",
                "kodepos": "40162"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Sukabungah",
                "kodepos": "40162"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Isola",
                "kodepos": "40154"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Pasteur",
                "kodepos": "40161"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sukarasa",
                "kodepos": "40152"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Geger Kalong",
                "kodepos": "40153"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Ciumbuleuit",
                "kodepos": "40142"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Ledeng",
                "kodepos": "40143"
            },
            {
                "kecamatan": "Sukasari",
                "kelurahan": "Sarijadi",
                "kodepos": "40151"
            },
            {
                "kecamatan": "Cidadap",
                "kelurahan": "Hegarmanah",
                "kodepos": "40141"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Sekeloa",
                "kodepos": "40134"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Dago",
                "kodepos": "40135"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Lebak Siliwangi",
                "kodepos": "40132"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Sadang Serang",
                "kodepos": "40133"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Lebak Gede",
                "kodepos": "40132"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Sukapada",
                "kodepos": "40125"
            },
            {
                "kecamatan": "Coblong",
                "kelurahan": "Cipaganti",
                "kodepos": "40131"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Padasuka",
                "kodepos": "40125"
            },
            {
                "kecamatan": "Cibeunying Kaler",
                "kelurahan": "Neglasari",
                "kodepos": "40124"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Cikutra",
                "kodepos": "40124"
            },
            {
                "kecamatan": "Cibeunying Kaler",
                "kelurahan": "Sukaluyu",
                "kodepos": "40123"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Sukamaju",
                "kodepos": "40121"
            },
            {
                "kecamatan": "Cibeunying Kaler",
                "kelurahan": "Cihaur Geulis",
                "kodepos": "40122"
            },
            {
                "kecamatan": "Cibeunying Kidul",
                "kelurahan": "Cicadas",
                "kodepos": "40121"
            },
            {
                "kecamatan": "Sumur Bandung",
                "kelurahan": "Babakan Ciamis",
                "kodepos": "40117"
            },
            {
                "kecamatan": "Bandung Wetan",
                "kelurahan": "Tamansari",
                "kodepos": "40116"
            },
            {
                "kecamatan": "Bandung Wetan",
                "kelurahan": "Cihapit",
                "kodepos": "40114"
            },
            {
                "kecamatan": "Bandung Wetan",
                "kelurahan": "Citarum",
                "kodepos": "40115"
            },
            {
                "kecamatan": "Sumur Bandung",
                "kelurahan": "Kebon Pisang",
                "kodepos": "40112"
            },
            {
                "kecamatan": "Sumur Bandung",
                "kelurahan": "Merdeka",
                "kodepos": "40113"
            },
            {
                "kecamatan": "Sumur Bandung",
                "kelurahan": "Braga",
                "kodepos": "40111"
            }
        ],
        "k76": [
            {
                "kecamatan": "Rongga",
                "kelurahan": "Sukaresmi",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Sukamanah",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Cinengah",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Cicadas",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Cibitung",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Bojongsalam",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Bojongsalam",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Rongga",
                "kelurahan": "Bojong",
                "kodepos": "40566"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Wargasaluyu",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Tamanjaya",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Sukasari",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Sirnajaya",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Gununghalu",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Sindangjaya",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Cilangari",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Celak",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Gununghalu",
                "kelurahan": "Bunijaya",
                "kodepos": "40565"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Sukamulya",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Sarinagen",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Sirnagalih",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Mekarsari",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Neglasari",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Girimukti",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Karangsari",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Cijenuk",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Cintaasih",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Citalem",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Cicangkang Hilir",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Cijambu",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Baranangsiang",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Cipongkor",
                "kelurahan": "Cibenda",
                "kodepos": "40564"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Wangunsari",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Weninggalih",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Ranca Senggang",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Sindangkerta",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Pasirpogor",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Puncaksari",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Cintakarya",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Mekarwangi",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Cikadu",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Cicangkang Girang",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Sindangkerta",
                "kelurahan": "Buninagara",
                "kodepos": "40563"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Tanjungjaya",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Tanjungwangi",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Singajaya",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Situwangi",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Pataruman",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Nanggerang",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Rancapanggung",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Mukapayung",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Mekarmukti",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Mekarjaya",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Karangtanjung",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Karyamukti",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Kidangpananjung",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Karanganyar",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Citapen",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Cililin",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Cipatik",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Budiharja",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cihampelas",
                "kelurahan": "Cihampelas",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Batulayang",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Cililin",
                "kelurahan": "Bongas",
                "kodepos": "40562"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Selacau",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Saguling",
                "kelurahan": "Saguling",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Pangauban",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Pangauban",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Saguling",
                "kelurahan": "Girimukti",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Galanggang",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Giriasih",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Saguling",
                "kelurahan": "Cikande",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Saguling",
                "kelurahan": "Cipangeran",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Cangkorah",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Saguling",
                "kelurahan": "Bojonghaleuang",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Batujajar Barat",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Batujajar",
                "kelurahan": "Batujajar Timur",
                "kodepos": "40561"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Karyawangi",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Sariwangi",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Cihanjuang Rahayu",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Cihideung",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Ciwaruga",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Cigugur Girang",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Parongpong",
                "kelurahan": "Cihanjuang",
                "kodepos": "40559"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Sirnaraja",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Sukahaji",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Sirnagalih",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Margalaksana",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Margaluyu",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Nanggeleng",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Nyenang",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Ciroyom",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Jatimekar",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Bojongmekar",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Ciharashas",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cipeundeuy",
                "kelurahan": "Cipeundeuy",
                "kodepos": "40558"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Wangunjaya",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Puteran",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Rende",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Tenjolaut",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Mandalamukti",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Mandalasari",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Mekarjaya",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Ganjarsari",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Kanangasari",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Ciptagumati",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Cisomang Barat",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Cikalong",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cikalong Wetan",
                "kelurahan": "Cipada",
                "kodepos": "40556"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Sumurbandung",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Sarimukti",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Nyalindung",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Rajamandala Kulon",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Mandalasari",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Mandalawangi",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Citatah",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Gunungmasigit",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Kertamukti",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Ciptaharja",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Cirawamekar",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Cipatat",
                "kelurahan": "Cipatat",
                "kodepos": "40554"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Laksanamekar",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Padalarang",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Tagogapu",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Kertajaya",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Kertamulya",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Cipeundeuy",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Jayamekar",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Ciburuy",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Cimerang",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Tanimulya",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Padalarang",
                "kelurahan": "Cempakamekar",
                "kodepos": "40553"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Sukatani",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Ngamprah",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Pakuhaji",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Margajaya",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Mekarsari",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Cimareme",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Gadobangkong",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Cilame",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Cimanggu",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Tugumukti",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Ngamprah",
                "kelurahan": "Bojongkoneng",
                "kodepos": "40552"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Pasirlangu",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Sadangmekar",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Padaasih",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Pasirhalang",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cipada",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Jambudipa",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Kertawangi",
                "kodepos": "40551"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Wangunharja",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Wangunsari",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Sukajaya",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Suntenjaya",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Lembang",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Mekarwangi",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Pagerwangi",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Langensari",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Kayuambon",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Jayagiri",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Cikole",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Gudangkahuripan",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Cibogo",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Cikahuripan",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Cikidang",
                "kodepos": "40391"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Cibodas",
                "kodepos": "40391"
            }
        ],
        "k77": [
            {
                "kecamatan": "Cimahi Selatan",
                "kelurahan": "Cibeureum",
                "kodepos": "40535"
            },
            {
                "kecamatan": "Cimahi Selatan",
                "kelurahan": "Melong",
                "kodepos": "40534"
            },
            {
                "kecamatan": "Cimahi Selatan",
                "kelurahan": "Leuwigajah",
                "kodepos": "40532"
            },
            {
                "kecamatan": "Cimahi Selatan",
                "kelurahan": "Utama",
                "kodepos": "40533"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Padasuka",
                "kodepos": "40526"
            },
            {
                "kecamatan": "Cimahi Selatan",
                "kelurahan": "Cibeber",
                "kodepos": "40531"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Setiamanah",
                "kodepos": "40524"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Cimahi",
                "kodepos": "40525"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Cigugur Tengah",
                "kodepos": "40522"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Karangmekar",
                "kodepos": "40523"
            },
            {
                "kecamatan": "Cimahi Tengah",
                "kelurahan": "Baros",
                "kodepos": "40521"
            },
            {
                "kecamatan": "Cimahi Utara",
                "kelurahan": "Cibabat",
                "kodepos": "40513"
            },
            {
                "kecamatan": "Cimahi Utara",
                "kelurahan": "Pasirkaliki",
                "kodepos": "40514"
            },
            {
                "kecamatan": "Cimahi Utara",
                "kelurahan": "Cipageran",
                "kodepos": "40511"
            },
            {
                "kecamatan": "Cimahi Utara",
                "kelurahan": "Citeureup",
                "kodepos": "40512"
            }
        ],
        "k78": [
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Pantai Mekar",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Pantai Sederhana",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Pantai Harapan Jaya",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Jayasakti",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Pantai Bahagia",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Muara Gembong",
                "kelurahan": "Pantai Bakti",
                "kodepos": "17730"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Sindangsari",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Sindang Jaya",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Setialaksana",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Setia Jaya",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Lenggah Sari",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Jaya Laksana",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Lenggah Jaya",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Cabangbungin",
                "kelurahan": "Jaya Bakti",
                "kodepos": "17720"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Sumbersari",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Sumberurip",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Sumberreja",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Kertasari",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Kertajaya",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karangsegar",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karangreja",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karangjaya",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karangpatri",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karang Harja",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Karanghaur",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Bantarjaya",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Pebayuran",
                "kelurahan": "Bantarsari",
                "kodepos": "17710"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukamurni",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukarukun",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukamanah",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukamulya",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukalaksana",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukamakmur",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukajadi",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukakarya",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukakersa",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukahurip",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukaindah",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Suka Asih",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Sukadarma",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Sukatani",
                "kelurahan": "Banjar Sari",
                "kodepos": "17630"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukawijaya",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukawangi",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukaringin",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukatenang",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukaraja",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukarapih",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukamantri",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukarahayu",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukamekar",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukamaju",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukakerta",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukabudi",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Sukawangi",
                "kelurahan": "Sukadaya",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Tambelang",
                "kelurahan": "Sukabakti",
                "kodepos": "17620"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Pantai Hurip",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Kedungjaya",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Muara Bakti",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Kebalen",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Kedung Pengawas",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Buni Bakti",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Hurip Jaya",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Babelan Kota",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Babelan",
                "kelurahan": "Bahagia",
                "kodepos": "17610"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Waringin Jaya",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Karang Sambung",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Kedungwaringin",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Mekar Jaya",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Karang Harum",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Karang Mekar",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Kedung Waringin",
                "kelurahan": "Bojongsari",
                "kodepos": "17540"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Wangunharja",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Telajung",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Waluya",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Telaga Asih",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Telaga Murni",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Tanjungbaru",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Tanjungsari",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Sukaraya",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Sukaresmi",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Sukasejati",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Sukamahi",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Sukadanau",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Sukadami",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Simpangan",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Sertajaya",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Pasirtanjung",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Pasirsari",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Serang",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Pasirpanji",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Pasir Gombong",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Mekar Wangi",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Mekarmukti",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Karangsari",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Labansari",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Karangasih",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Karangraharja",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karangmukti",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karang Setu",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karanganyar",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karang Sentosa",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karang Setra",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karang Rahayu",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Karang Baru",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Karangbahagia",
                "kelurahan": "Karang Bahagia",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Kali Jaya",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Jayamukti",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Jatiwangi",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Jatibaru",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Jatireja",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Hegarmukti",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Hegarmanah",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Gandasari",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Harja Mekar",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Danau Indah",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Gandamekar",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Timur",
                "kelurahan": "Cipayung",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Barat",
                "kelurahan": "Cikedokan",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Utara",
                "kelurahan": "Cikarang Kota",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Cibatu",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cikarang Pusat",
                "kelurahan": "Cicau",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Wanajaya",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Wanasari",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Cikarang Selatan",
                "kelurahan": "Ciantra",
                "kodepos": "17530"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Sarimukti",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Sukajaya",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Kerta Mukti",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Muktiwari",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Tridaya Sakti",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Cibitung",
                "kelurahan": "Cibuntu",
                "kodepos": "17520"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Sumber Jaya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Tambun",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Srijaya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Srimahi",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Srimukti",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Setiadarma",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Setiamekar",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Sriamur",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Mekarsari",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Satriajaya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Satriamekar",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Lambangsari",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Mangunjaya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Karangsatria",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Lambangjaya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Jati Sampurna",
                "kelurahan": "Jatiranggon",
                "kodepos": "17432"
            },
            {
                "kecamatan": "Tambun Utara",
                "kelurahan": "Jalenjaya (Jejalenjaya)",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Jati Sampurna",
                "kelurahan": "Jatiraden",
                "kodepos": "17433"
            },
            {
                "kecamatan": "Tambun Selatan",
                "kelurahan": "Jatimulya",
                "kodepos": "17510"
            },
            {
                "kecamatan": "Pondok Melati",
                "kelurahan": "Jatimurni",
                "kodepos": "17431"
            },
            {
                "kecamatan": "Jati Sampurna",
                "kelurahan": "Jatikarya",
                "kodepos": "17435"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jatisari",
                "kodepos": "17426"
            },
            {
                "kecamatan": "Jati Sampurna",
                "kelurahan": "Jatisampurna",
                "kodepos": "17433"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jatiluhur",
                "kodepos": "17425"
            },
            {
                "kecamatan": "Jati Sampurna",
                "kelurahan": "Jatirangga",
                "kodepos": "17434"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jatirasa",
                "kodepos": "17424"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jatiasih",
                "kodepos": "17423"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jati Mekar",
                "kodepos": "17422"
            },
            {
                "kecamatan": "Jatiasih",
                "kelurahan": "Jatikramat",
                "kodepos": "17421"
            },
            {
                "kecamatan": "Pondok Melati",
                "kelurahan": "Jatiwarna",
                "kodepos": "17415"
            },
            {
                "kecamatan": "Pondok Melati",
                "kelurahan": "Jati Rahayu",
                "kodepos": "17414"
            },
            {
                "kecamatan": "Pondok Melati",
                "kelurahan": "Jatimelati",
                "kodepos": "17414"
            },
            {
                "kecamatan": "Pondok Gede",
                "kelurahan": "Jatibening Baru",
                "kodepos": "17412"
            },
            {
                "kecamatan": "Pondok Gede",
                "kelurahan": "Jatimakmur",
                "kodepos": "17413"
            },
            {
                "kecamatan": "Pondok Gede",
                "kelurahan": "Jaticempaka",
                "kodepos": "17411"
            },
            {
                "kecamatan": "Pondok Gede",
                "kelurahan": "Jatiwaringin",
                "kodepos": "17411"
            },
            {
                "kecamatan": "Pondok Gede",
                "kelurahan": "Jatibening",
                "kodepos": "17412"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Karangmulya",
                "kodepos": "17356"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Sukabungah",
                "kodepos": "17350"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Sukamukti",
                "kodepos": "17350"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Sukamukti",
                "kodepos": "17350"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Karangindah",
                "kodepos": "17350"
            },
            {
                "kecamatan": "Bojongmangu",
                "kelurahan": "Medalkrisna",
                "kodepos": "17350"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Sirnajati",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Wibawamulya",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Ridogalih",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Ridomanah",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Sindangmulya",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Cibarusahjaya",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Cibarusah",
                "kelurahan": "Cibarusahkota",
                "kodepos": "17340"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Sukaragam",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Sukasari",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Nagasari",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Sirnajaya",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Nagacipta",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Jayamulya",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Cilangkara",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Serang Baru",
                "kelurahan": "Jaya Sampurna",
                "kodepos": "17330"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Taman Rahayu",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Taman Sari",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Muktijaya",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Ragemanunggal",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Lubangbuaya",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Kertarahayu",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Cileduk",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Cikarageman",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Cibening",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Cijengkol",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Setu",
                "kelurahan": "Burangkeng",
                "kodepos": "17320"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Segara Jaya",
                "kodepos": "17218"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Pahlawan Setia",
                "kodepos": "17216"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Samudra Jaya",
                "kodepos": "17217"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Pusaka Rakyat",
                "kodepos": "17214"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Setia Asih",
                "kodepos": "17215"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Setia Mulya",
                "kodepos": "17213"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Segara Makmur",
                "kodepos": "17211"
            },
            {
                "kecamatan": "Tarumajaya",
                "kelurahan": "Pantai Makmur",
                "kodepos": "17212"
            },
            {
                "kecamatan": "Mustika Jaya",
                "kelurahan": "Mustika Sari",
                "kodepos": "17157"
            },
            {
                "kecamatan": "Mustika Jaya",
                "kelurahan": "Mustika Jaya",
                "kodepos": "17158"
            },
            {
                "kecamatan": "Mustika Jaya",
                "kelurahan": "Cimuning",
                "kodepos": "17155"
            },
            {
                "kecamatan": "Mustika Jaya",
                "kelurahan": "Pedurenan (Padurenan)",
                "kodepos": "17156"
            },
            {
                "kecamatan": "Bantar Gebang",
                "kelurahan": "Cikiwul",
                "kodepos": "17152"
            },
            {
                "kecamatan": "Bantar Gebang",
                "kelurahan": "Ciketing Udik",
                "kodepos": "17153"
            },
            {
                "kecamatan": "Bantar Gebang",
                "kelurahan": "Sumur Batu",
                "kodepos": "17154"
            },
            {
                "kecamatan": "Bekasi Selatan",
                "kelurahan": "Pekayon Jaya",
                "kodepos": "17148"
            },
            {
                "kecamatan": "Bantar Gebang",
                "kelurahan": "Bantar Gebang",
                "kodepos": "17151"
            },
            {
                "kecamatan": "Bekasi Selatan",
                "kelurahan": "Jaka Setia",
                "kodepos": "17147"
            },
            {
                "kecamatan": "Bekasi Selatan",
                "kelurahan": "Jaka Mulya",
                "kodepos": "17146"
            },
            {
                "kecamatan": "Bekasi Barat",
                "kelurahan": "Jaka Sampurna",
                "kodepos": "17145"
            },
            {
                "kecamatan": "Bekasi Selatan",
                "kelurahan": "Kayuringin Jaya",
                "kodepos": "17144"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Marga Mulya",
                "kodepos": "17142"
            },
            {
                "kecamatan": "Medan Satria",
                "kelurahan": "Harapan Mulya",
                "kodepos": "17143"
            },
            {
                "kecamatan": "Bekasi Barat",
                "kelurahan": "Bintara Jaya",
                "kodepos": "17136"
            },
            {
                "kecamatan": "Bekasi Selatan",
                "kelurahan": "Margajaya",
                "kodepos": "17141"
            },
            {
                "kecamatan": "Bekasi Barat",
                "kelurahan": "Kota Baru",
                "kodepos": "17133"
            },
            {
                "kecamatan": "Bekasi Barat",
                "kelurahan": "Bintara",
                "kodepos": "17134"
            },
            {
                "kecamatan": "Bekasi Barat",
                "kelurahan": "Kranji",
                "kodepos": "17135"
            },
            {
                "kecamatan": "Medan Satria",
                "kelurahan": "Medan Satria",
                "kodepos": "17132"
            },
            {
                "kecamatan": "Medan Satria",
                "kelurahan": "Kali Baru",
                "kodepos": "17133"
            },
            {
                "kecamatan": "Medan Satria",
                "kelurahan": "Pejuang",
                "kodepos": "17131"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Kali Abang Tengah",
                "kodepos": "17125"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Harapan Baru",
                "kodepos": "17123"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Harapan Jaya",
                "kodepos": "17124"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Teluk Pucung",
                "kodepos": "17121"
            },
            {
                "kecamatan": "Bekasi Utara",
                "kelurahan": "Perwira",
                "kodepos": "17122"
            },
            {
                "kecamatan": "Rawalumbu",
                "kelurahan": "Bojong Menteng",
                "kodepos": "17117"
            },
            {
                "kecamatan": "Rawalumbu",
                "kelurahan": "Sepanjang Jaya",
                "kodepos": "17114"
            },
            {
                "kecamatan": "Rawalumbu",
                "kelurahan": "Pengasinan",
                "kodepos": "17115"
            },
            {
                "kecamatan": "Rawalumbu",
                "kelurahan": "Bojong Rawalumbu",
                "kodepos": "17116"
            },
            {
                "kecamatan": "Bekasi Timur",
                "kelurahan": "Bekasi Jaya",
                "kodepos": "17112"
            },
            {
                "kecamatan": "Bekasi Timur",
                "kelurahan": "Margahayu",
                "kodepos": "17113"
            },
            {
                "kecamatan": "Bekasi Timur",
                "kelurahan": "Aren Jaya",
                "kodepos": "17111"
            },
            {
                "kecamatan": "Bekasi Timur",
                "kelurahan": "Duren Jaya",
                "kodepos": "17111"
            }
        ],
        "k79": [
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Ciangsana",
                "kodepos": "16968"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Bojong Kulur",
                "kodepos": "16969"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Cikeas Udik",
                "kodepos": "16966"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Nagrag",
                "kodepos": "16967"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Wanaherang",
                "kodepos": "16965"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Bojong Nangka",
                "kodepos": "16963"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Cicadas",
                "kodepos": "16964"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Gunung Putri",
                "kodepos": "16961"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Tlajung Udik",
                "kodepos": "16962"
            },
            {
                "kecamatan": "Gunung Putri",
                "kelurahan": "Karanggan",
                "kodepos": "16960"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Bojong Gede",
                "kodepos": "16922"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Kedung Waringin",
                "kodepos": "16923"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Waringin Jaya",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Pabuaran",
                "kodepos": "16921"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Rawa Panjang",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Susukan",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Ragajaya",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Cimanggis",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Bojonggede",
                "kelurahan": "Bojong Baru",
                "kodepos": "16920"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Ciriung",
                "kodepos": "16918"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Cirimekar",
                "kodepos": "16917"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Pabuaran",
                "kodepos": "16916"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Pakansari",
                "kodepos": "16915"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Tengah",
                "kodepos": "16914"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Pondok Rajeg",
                "kodepos": "16914"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Sukahati",
                "kodepos": "16913"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Harapan Jaya",
                "kodepos": "16914"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Karadenan",
                "kodepos": "16913"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Nanggewer Mekar",
                "kodepos": "16912"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Nanggewer",
                "kodepos": "16912"
            },
            {
                "kecamatan": "Cibinong",
                "kelurahan": "Cibinong",
                "kodepos": "16911"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Tegal Panjang",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Tanjungsari",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Sukarasa",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Tanjungrasa",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Sukajadi",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Sirnasari",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Sirnarasa",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Selawangi",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Pasir Tanjung",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Mekarwangi",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Kuta Mekar",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Karya Mekar",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Cikutamahi",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Cibatu Tiga",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Cibadak",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Cariu",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Buanajaya",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Bantar Kuning",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Cariu",
                "kelurahan": "Babakan Raden",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Tanjungsari",
                "kelurahan": "Antajaya",
                "kodepos": "16840"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Weninggalih",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Wargajaya",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukawangi",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukasirna",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukaresmi",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukanegara",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukamulya",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukamanah",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukamakmur",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukamaju",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukaharja",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukajaya",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sukadamai",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sukagalih",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Sirnajaya",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Sirnagalih",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Pabuaran",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Singajaya",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Singasari",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Cibodas",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Jonggol",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Sukamakmur",
                "kelurahan": "Cibadak",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Bendungan",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Setu Sari",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Jonggol",
                "kelurahan": "Balekambang",
                "kodepos": "16830"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Pasir Angin",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Mekarsari",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Mampir",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Limus Nunggal",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Gandoang",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Jatisari",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Cipeucang",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Dayeuh",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Cipenjo",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Cileungsi Kidul",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Cileungsi",
                "kelurahan": "Cileungsi",
                "kodepos": "16820"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Tajur",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Tangkil",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Tarikolot",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Sentul",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Sumur Batu",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Sukahati",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Puspasari",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Sanja",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Purwabakti",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Puspanegara",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Pasir Mukti",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Pasarean",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Leuwinutug",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Pamijahan",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Karang Asem Timur",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Karang Tengah",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Karang Asem Barat",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Kadumangu",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Gunung Sari",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Hambalang",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Hambalang",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Gunung Menyan",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Gunung Picung",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Gunung Bunder 1",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Gunung Bunder 2",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Citeureup",
                "kelurahan": "Citeureup",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Cipambuan",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Citaringgul",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Cimayang",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Cijayanti",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Cibunian",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Cibitung Kulon",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Cibitung Wetan",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Ciasmara",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Cibening",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Bojong Koneng",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Pamijahan",
                "kelurahan": "Ciasihan",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukaresmi",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Babakan Madang",
                "kelurahan": "Babakan Madang",
                "kodepos": "16810"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukamanah",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukakarya",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukamahi",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukamaju",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Pasir Angin",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Sukagalih",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Kuta",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Megamendung",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Cipayung Girang",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Gadog",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Tugu Utara",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Megamendung",
                "kelurahan": "Cipayung (Cipayung Datar)",
                "kodepos": "16770"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Tugu Selatan",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Jogjogan",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Kopo",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Leuwimalang",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cisarua",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Citeko",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Batu Layang",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cibeureum",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cisarua",
                "kelurahan": "Cilember",
                "kodepos": "16750"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Tanjung Sari",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Warung Menteng",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Sukaharja",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Tajur Halang",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Palasari",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Cipicung",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Cijeruk",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Cipelang",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Tangkil",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Cijeruk",
                "kelurahan": "Cibalung",
                "kodepos": "16740"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Pasir Buncir",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Pasir Muncang",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Lemah Duhur",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Muarajaya",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Pancawati",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cimande Hilir",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cinagara",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Ciderum",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Ciherang Pondok",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Cimande",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Teluk Pinang",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Caringin",
                "kelurahan": "Caringin",
                "kodepos": "16730"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Jambu Luwuk",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Pandansari",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Ciawi",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Cibedug",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Cileungsi",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Citapen",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Bitung Sari",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Bojong Murni",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Banjar Waru",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Bendungan",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Banjar Wangi",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Ciawi",
                "kelurahan": "Banjar Sari",
                "kodepos": "16720"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Pasirlaja",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukaraja",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Sukatani",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Nambo",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Nagrak",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Pasir Jambu",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Lulut",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Ligarmukti",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Kembang Kuning",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Klapanunggal",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Leuwikaret",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cimandala",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Gunung Geulis",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cilebut Barat",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cilebut Timur",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cijujung",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cikeas",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Cikahuripan",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cadas Ngampar",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Sukaraja",
                "kelurahan": "Cibanon",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Sukawening",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Bantar Jati",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Klapa Nunggal (Kelapa Nunggal)",
                "kelurahan": "Bojong",
                "kodepos": "16710"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Sinar Sari",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Sukadamai",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Neglasari",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Petir",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Purwasari",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Cikarawang",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Dramaga",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Ciherang",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Dramaga",
                "kelurahan": "Babakan",
                "kodepos": "16680"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Setu",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Sipak",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Tegal Wangi",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Pangaur",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Pangradin",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Neglasari",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Pamagersari",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Jugala Jaya",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Kalongsawah",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Koleang",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Jasinga",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Barengkok",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Cikopomayak",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Curug",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Jasinga",
                "kelurahan": "Bagoang",
                "kodepos": "16670"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Wargajaya",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Urug",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Tegallega",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Sukamulih",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Sukaraksa",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Sukamaju",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Sukajaya",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Sipayung",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Mekarjaya",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Rengasjajar",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Pasir Madang",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Kiara Pandak",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Kiarasari",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Cisarua",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Harkatjaya",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Jayaraharja",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Cintamanik",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Cileuksa",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Bunar",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Cigudeg",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Batu Jajar",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Banyu Asih",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Banyu Resmi",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Banyu Wangi",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Argapura",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Cigudeg",
                "kelurahan": "Bangunjaya",
                "kodepos": "16660"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Pangkal Jaya",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Parakan Muncang",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Sukaluyu",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Nanggung",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Kalong Liud",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Malasari",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Hambaro",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Cisarua",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Curug Bitung",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Nanggung",
                "kelurahan": "Bantar Karet",
                "kodepos": "16650"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Wangun Jaya",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Sibanteng",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Sadeng",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Sadengkolot",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Pabangbon",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Purasari",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Puraseda",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Leuwimekar",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Leuwisadeng",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Karyasari",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Leuwiliang",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Kalong 2",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Karacak",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Karehkel",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Kalong 1",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Cibeber 2",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Barengkok",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Leuwiliang",
                "kelurahan": "Cibeber 1",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Situ Udik",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Sukamaju",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Leuwisadeng",
                "kelurahan": "Babakan Sadeng",
                "kodepos": "16640"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Situ Ilir",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Leuweung Kolot",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Dukuh",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Galuga",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Girimulya",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cimanggu 2",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cibatok 2",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cijujung",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cimanggu 1",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Ciaruteun Udik",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cibatok 1",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Cemplang",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Cibungbulang",
                "kelurahan": "Ciaruteun Ilir",
                "kodepos": "16630"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cinangka",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Tegal Waru",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cicadas",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cihideung Ilir",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cihideung Udik",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cibanteng",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cibuntu",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Ciampea Udik",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Cibadak",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Ciampea",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Bojong Jengkol",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Bojong Rangkas",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Tamansari",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciampea",
                "kelurahan": "Benteng",
                "kodepos": "16620"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukamantri",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukaresmi",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Sukamakmur",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukaluyu",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukajaya",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Sukaharja",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sirnagalih",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Sukajadi",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Parakan",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tamansari",
                "kelurahan": "Pasireurih",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Padasuka",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Pagelaran",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Mekarjaya",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Kota Batu",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Laladon",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Ciomas Rahayu",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Ciapus",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Ciomas",
                "kelurahan": "Ciomas",
                "kodepos": "16610"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Tenjo",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Tapos 1",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Tapos 2",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Tapos",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Situ Daun",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Singabangsa",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Singabraja",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Cinangneng",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Gunung Malang",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Ciomas",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjolaya",
                "kelurahan": "Cibitung Tengah",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Cilaku",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Babakan",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Batok",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Tenjo",
                "kelurahan": "Bojong",
                "kodepos": "16370"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Lumpang",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Parungpanjang",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Pingku",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Jagabita",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Kabasiran",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Gintung Cilejet",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Gorowong",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Jagabaya",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Cibunar",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Cikuda",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Parung Panjang",
                "kelurahan": "Dago",
                "kodepos": "16360"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Sukasari",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Taman Sari",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Rabak",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Rumpin",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Sukamulya",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Leuwibatu",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Mekar Sari",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Mekarjaya",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Gobang",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Kampung Sawah",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Kerta Jaya",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Cidokom",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Cipinang",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Rumpin",
                "kelurahan": "Cibodas",
                "kodepos": "16350"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Pengasinan",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Rawakalong",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Pabuaran",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Padurenan",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Gunung Sindur",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Jampang",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Curug",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Cidokom",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Cibinong",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Gunung Sindur",
                "kelurahan": "Cibadung",
                "kodepos": "16340"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Waru",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Warujaya",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Parung",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Pamagersari",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Jabon Mekar",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Iwul",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Cogreg",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Bojong Sempu",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Parung",
                "kelurahan": "Bojong Indah",
                "kodepos": "16330"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Tonjong",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Sukmajaya",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Tajur Halang",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Sasak Panjang",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Nanggerang",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Kalisuren",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Tajurhalang",
                "kelurahan": "Citayam",
                "kodepos": "16320"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Tegal",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Semplak Barat",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Rancabungur",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Pondok Udik",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Pasirgaok",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Pabuaran",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Parakan Jaya",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Mekarsari",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Kemang",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Candali",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Jampang",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Bantarsari",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Bojong",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Ranca Bungur",
                "kelurahan": "Bantarjaya",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Kemang",
                "kelurahan": "Atang Senjaya",
                "kodepos": "16310"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kayumanis",
                "kodepos": "16169"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kencana",
                "kodepos": "16167"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Mekarwangi",
                "kodepos": "16168"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Cibadak",
                "kodepos": "16166"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Sukadamai",
                "kodepos": "16165"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Sukaresmi",
                "kodepos": "16165"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kedung Waringin",
                "kodepos": "16164"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kedung Badak",
                "kodepos": "16164"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kedung Jaya",
                "kodepos": "16164"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Kebon Pedes",
                "kodepos": "16162"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Kedunghalang",
                "kodepos": "16158"
            },
            {
                "kecamatan": "Tanah Sereal",
                "kelurahan": "Tanah Sareal",
                "kodepos": "16161"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Ciparigi",
                "kodepos": "16157"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Cimahpar",
                "kodepos": "16155"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Ciluar",
                "kodepos": "16156"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Bantarjati",
                "kodepos": "16153"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Tanah Baru",
                "kodepos": "16154"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Cibuluh",
                "kodepos": "16151"
            },
            {
                "kecamatan": "Bogor Utara - Kota",
                "kelurahan": "Tegal Gundil",
                "kodepos": "16152"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Sindangrasa",
                "kodepos": "16145"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Sindangsari",
                "kodepos": "16146"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Baranangsiang",
                "kodepos": "16143"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Katulampa",
                "kodepos": "16144"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Tajur",
                "kodepos": "16141"
            },
            {
                "kecamatan": "Bogor Timur - Kota",
                "kelurahan": "Sukasari",
                "kodepos": "16142"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Bojongkerta",
                "kodepos": "16139"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Rancamaya",
                "kodepos": "16139"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Kertamaya",
                "kodepos": "16138"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Harjasari",
                "kodepos": "16138"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Muarasari",
                "kodepos": "16137"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Ranggamekar",
                "kodepos": "16136"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Genteng",
                "kodepos": "16137"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Mulyaharja",
                "kodepos": "16135"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Pamoyanan",
                "kodepos": "16136"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Lawang Gintung",
                "kodepos": "16134"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Pakuan",
                "kodepos": "16134"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Batutulis",
                "kodepos": "16133"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Cipaku",
                "kodepos": "16133"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Bondongan",
                "kodepos": "16131"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Cikaret",
                "kodepos": "16132"
            },
            {
                "kecamatan": "Bogor Selatan - Kota",
                "kelurahan": "Empang",
                "kodepos": "16132"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Sempur",
                "kodepos": "16129"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Tegal Panjang",
                "kodepos": "16129"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Babakan",
                "kodepos": "16128"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Panaragan",
                "kodepos": "16125"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Babakan Pasar",
                "kodepos": "16126"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Ciwaringin",
                "kodepos": "16124"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Kebon Kelapa",
                "kodepos": "16125"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Cibogor",
                "kodepos": "16124"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Paledang",
                "kodepos": "16122"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Kuripan",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Gudang",
                "kodepos": "16123"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Parigi Mekar",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Putat Nutug",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Ciseeng",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Bogor Tengah - Kota",
                "kelurahan": "Pabaton",
                "kodepos": "16121"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Karihkil",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Cibeuteung Udik",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Cihoe (Cihowe)",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Babakan",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Cibentang",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Ciseeng",
                "kelurahan": "Cibeuteung Muara",
                "kodepos": "16120"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Pasir Jaya",
                "kodepos": "16119"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Pasir Kuda",
                "kodepos": "16119"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Pasir Mulya",
                "kodepos": "16118"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Pasir Jaya",
                "kodepos": "16119"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Sindangbarang",
                "kodepos": "16117"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Gunungbatu",
                "kodepos": "16118"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Margajaya",
                "kodepos": "16116"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Loji",
                "kodepos": "16117"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Balungbang Jaya",
                "kodepos": "16116"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Bubulak",
                "kodepos": "16115"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Situ Gede",
                "kodepos": "16115"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Curug Mekar",
                "kodepos": "16113"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Semplak",
                "kodepos": "16114"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Cilendek Timur",
                "kodepos": "16112"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Curug",
                "kodepos": "16113"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Menteng",
                "kodepos": "16111"
            },
            {
                "kecamatan": "Bogor Barat - Kota",
                "kelurahan": "Cilendek Barat",
                "kodepos": "16112"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Tugujaya",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Watesjaya",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Cisalada",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Srogol",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Ciburuy",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Cigombong",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Ciadeg",
                "kodepos": "16110"
            },
            {
                "kecamatan": "Cigombong",
                "kelurahan": "Ciburayut",
                "kodepos": "16110"
            }
        ],
        "k80": [
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Bedahan",
                "kodepos": "16519"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Pasir Putih",
                "kodepos": "16519"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Pengasihan",
                "kodepos": "16518"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Duren Seribu",
                "kodepos": "16518"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Serua",
                "kodepos": "16517"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Duren Mekar",
                "kodepos": "16518"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Curug",
                "kodepos": "16517"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Pondok Petir",
                "kodepos": "16517"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Bojong Sari Baru",
                "kodepos": "16516"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Cinangka",
                "kodepos": "16516"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Kedaung",
                "kodepos": "16516"
            },
            {
                "kecamatan": "Limo",
                "kelurahan": "Meruyung",
                "kodepos": "16515"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Bojong Sari (Lama)",
                "kodepos": "16516"
            },
            {
                "kecamatan": "Cinere",
                "kelurahan": "Cinere",
                "kodepos": "16514"
            },
            {
                "kecamatan": "Limo",
                "kelurahan": "Limo",
                "kodepos": "16515"
            },
            {
                "kecamatan": "Cinere",
                "kelurahan": "Pangkalan Jati",
                "kodepos": "16513"
            },
            {
                "kecamatan": "Cinere",
                "kelurahan": "Pangkalan Jati Baru",
                "kodepos": "16513"
            },
            {
                "kecamatan": "Limo",
                "kelurahan": "Grogol",
                "kodepos": "16512"
            },
            {
                "kecamatan": "Limo",
                "kelurahan": "Krukut",
                "kodepos": "16512"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Sawangan Baru",
                "kodepos": "16511"
            },
            {
                "kecamatan": "Cinere",
                "kelurahan": "Gandul",
                "kodepos": "16512"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Sawangan (Lama)",
                "kodepos": "16511"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Cimpaeun",
                "kodepos": "16459"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Leuwinanggung",
                "kodepos": "16456"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Tapos",
                "kodepos": "16457"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Cilangkap",
                "kodepos": "16458"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Sukatani",
                "kodepos": "16454"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Sukamaju Baru",
                "kodepos": "16455"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Harjamukti",
                "kodepos": "16454"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Curug",
                "kodepos": "16453"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Mekarsari",
                "kodepos": "16452"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Pasir Gunung Selatan",
                "kodepos": "16451"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Tugu",
                "kodepos": "16451"
            },
            {
                "kecamatan": "Cimanggis",
                "kelurahan": "Cisalak Pasar",
                "kodepos": "16452"
            },
            {
                "kecamatan": "Tapos",
                "kelurahan": "Jatijajar",
                "kodepos": "16451"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Cipayung Jaya",
                "kodepos": "16437"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Pondokjaya",
                "kodepos": "16438"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Ratu Jaya",
                "kodepos": "16439"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Pancoran Mas",
                "kodepos": "16436"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Bojong Pondok Terong",
                "kodepos": "16436"
            },
            {
                "kecamatan": "Cipayung",
                "kelurahan": "Cipayung",
                "kodepos": "16437"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Rangkapanjaya",
                "kodepos": "16435"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Rangkapanjaya Baru",
                "kodepos": "16434"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Mampang",
                "kodepos": "16433"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Depok",
                "kodepos": "16431"
            },
            {
                "kecamatan": "Pancoran Mas",
                "kelurahan": "Depok Jaya",
                "kodepos": "16432"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Pondok Cina",
                "kodepos": "16424"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Kukusan",
                "kodepos": "16425"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Tanah Baru",
                "kodepos": "16426"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Beji Timur",
                "kodepos": "16422"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Kemiri Muka",
                "kodepos": "16423"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Beji",
                "kodepos": "16421"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Baktijaya",
                "kodepos": "16418"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Abadijaya",
                "kodepos": "16417"
            },
            {
                "kecamatan": "Cilodong",
                "kelurahan": "Sukamaju",
                "kodepos": "16415"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Cisalak",
                "kodepos": "16416"
            },
            {
                "kecamatan": "Cilodong",
                "kelurahan": "Cilodong",
                "kodepos": "16414"
            },
            {
                "kecamatan": "Cilodong",
                "kelurahan": "Cilodong",
                "kodepos": "16414"
            },
            {
                "kecamatan": "Cilodong",
                "kelurahan": "Jatimulya",
                "kodepos": "16413"
            },
            {
                "kecamatan": "Cilodong",
                "kelurahan": "Kalimulya",
                "kodepos": "16413"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Sukmajaya",
                "kodepos": "16412"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Tirtajaya",
                "kodepos": "16412"
            },
            {
                "kecamatan": "Sukmajaya",
                "kelurahan": "Mekarjaya",
                "kodepos": "16411"
            }
        ],
        "k81": [
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Tlogosih",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Tanggul",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Werdoyo",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Sarimulyo",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Soko Kidul",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Solowire",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Prigi",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Rejosari",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Pilangwetan",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Pasir",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Pecuk",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Ngelo Kulon",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Ngelo Wetan",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Mijen",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Mlaten",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Ngegot",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Megonten",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Mijen",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Klampok Lor",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Mangunan Lor",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Mangunrejo",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Kebonagung",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Jleper",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Gempolsongo",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Geneng",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Banteng Mati",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Bermi",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Bakung",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Kebonagung",
                "kelurahan": "Babad",
                "kodepos": "59583"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Wonorejo",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Undaan Lor",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Wonoketingal",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Tugu Lor",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Tuwang",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Undaan Kidul",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kotakan",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Ngaluran",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Ngemplik Wetan",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kedungwaru Lor",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Ketanjung",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Jatirejo",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karanganyar",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kedungwaru Kidul",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Cangkring",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Cangkring Rembang",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Wilalung",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Bandungrejo",
                "kodepos": "59582"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Tambirejo",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Tanjunganyar",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Tlogopandogan",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Surodadi",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Sambung",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Sarirejo",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Mojosimo",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Sambiroto",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Medini",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Mlatiharjo",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Mlekang",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Jatisono",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Kedondong",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Gajah",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Gedangalas",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Boyolali",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Gajah",
                "kelurahan": "Banjarsari",
                "kodepos": "59581"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Merak",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Sidomulyo",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Kunir",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Kuwu",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Kedungori",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Kramat",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Karangrejo",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Kebonsari",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Jerukgulung",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Dempet",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Gempoldenok",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Harjowinangun",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Botosengon",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Brakas",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Baleromo",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Dempet",
                "kelurahan": "Balerejo",
                "kodepos": "59573"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Trengguli",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Wonosalam",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Tlogodowo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Tlogorejo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Pilangrejo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Sido Mulyo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Mranak",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Mrisen",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Lempuyang",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Mojodemak",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Kerangkulon",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Kuncir",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Karangrejo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Karangrowo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Kendaldoyong",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Jogoloyo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Kalianyar",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Bunderan",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Doreng",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Getas",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Botorejo",
                "kodepos": "59571"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Wringin Jajar",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Waru",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Tegalarum",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Sumberejo",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Tamansari",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Ngemplak",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Mranggen",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Menur",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Kembangarum",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Kebonbatur",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Karangsono",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Kangkung",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Jamus",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Kalitengah",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Candisari",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Brumbung",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Batursari",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Banyumeneng",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Mranggen",
                "kelurahan": "Bandungrejo",
                "kodepos": "59567"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Wonosekar",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Tlogorejo",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Teluk",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Sido Rejo",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Rejosari",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Margohayu",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Pundenarum",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Kuripan",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Karangawen",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Jragung",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Bumirejo",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Karangawen",
                "kelurahan": "Brambang",
                "kodepos": "59566"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Wonorejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Turitempel",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Trimulyo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Tlogoweru",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Temuroso",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Tlogorejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Tangkis",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Sukorejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Sidokumpul",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Sidoharjo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Sarirejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Pamongan",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Krandon",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Guntur",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Gaji",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Bumiharjo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Blerong",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Bogosari",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Bakalrejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Guntur",
                "kelurahan": "Banjarejo",
                "kodepos": "59565"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Tambakroto",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Timbulsloko",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Tugu",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Sriwulan",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Surodadi",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Sidogemah",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Sidorejo",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Sayung",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Pilangsari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Purwosari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Loireng",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Perampelan",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Jetaksari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Kalisari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Karangasem",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Gemulak",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Bulusari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Dombo",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Banjarsari",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Sayung",
                "kelurahan": "Bedono",
                "kodepos": "59563"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Wonokerto",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Wonowoso",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Tambakbulusan",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Wonoagung",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Sampang",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Pulosari",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Rejosari",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Klitih",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Pidodo",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Ploso",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Karangtowo",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Kedunguter",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Dukun",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Grogol",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Karangsari",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Batu",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Karang Tengah",
                "kelurahan": "Donorejo",
                "kodepos": "59561"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Wedung",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Tedunan",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Tempel",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Mutih Wetan",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Ngawen",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Ruwit",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Mutih Kulon",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Kendalasem",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Kenduren",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Mandung",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Kedungkarang",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Kedungmutih",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Jungpasir",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Jungsemi",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Bungo",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Jetak",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Berahan Kulon",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Berahan Wetan",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Buko",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Wedung",
                "kelurahan": "Babalan",
                "kodepos": "59554"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Weding",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Wonosari",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Tlogoboyo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Tridonorejo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Sukodono",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Sumberejo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Purworejo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Serangan",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Morodemak",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Poncoharjo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Krajanbogo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Margolinduk",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Karangrejo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Jatirogo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Jali",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Jatimulyo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Gebang",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Gebangarum",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Betahwalang",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Bonangrejo",
                "kodepos": "59552"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Sedo",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Tempuran",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Turirejo",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Mulyorejo",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Raji",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Karangmlati",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Kedondong",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Kalikondang",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Donorejo",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Kadilangu",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Cabean",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Bango",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Bolo",
                "kodepos": "59517"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Mangunjiwan",
                "kodepos": "59515"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Katonsari",
                "kodepos": "59516"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Kalicilik",
                "kodepos": "59514"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Singorejo",
                "kodepos": "59513"
            },
            {
                "kecamatan": "Bonang",
                "kelurahan": "Kembangan",
                "kodepos": "59511"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Betokan",
                "kodepos": "59512"
            },
            {
                "kecamatan": "Demak",
                "kelurahan": "Bintoro",
                "kodepos": "59511"
            }
        ],
        "k82": [
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Pringtulis",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Tritis",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Tunggulpandean",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Muryolobo",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Nalumsari",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Ngetuk",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Jatisari",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Karangnongko",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Gemiring Kidul",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Gemiring Lor",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Daren",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Dorang",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Blimbingrejo",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Tigajuru",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Bategede",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Nalumsari",
                "kelurahan": "Bendanpete",
                "kodepos": "59466"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Sengonbugel",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Singorojo",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Pelemkerep",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Pule",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Rajekwesi",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Pelang",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Pancur",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Paren",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Mayong Kidul",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Mayong Lor",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Ngroto",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Jebol",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Kuanyar",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Bungu",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Datar",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Bandung",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Mayong",
                "kelurahan": "Buaran",
                "kodepos": "59465"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Ujungpandan",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Welahan",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Sidigede",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Telukwetan",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Kendengsidialit",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Ketilengsingolelo",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Kalipucang Wetan",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Karanganyar",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Kedungsarimulyo",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Guwosobokerto",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Kalipucang Kulon",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Gedangan",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Gidangelo",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Brantaksekarjati",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Welahan",
                "kelurahan": "Bugo",
                "kodepos": "59464"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Tedunan",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Wanusobo",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Surodadi",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Tanggultlare",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Sukosono",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Sowan Lor",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Panggung",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Rau",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Sowan Kidul",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Menganti",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Kedungmalang",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Kerso",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Jondang",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Kalianyar",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Karangaji",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Bulakbaru",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Dongos",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Kedung",
                "kelurahan": "Bugel",
                "kodepos": "59463"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Troso",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Robayan",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Sendang",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Rengging",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Purwogondo",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Pecangaan Wetan",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Pulodarat",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Pendosawalan",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Ngeling",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Pecangaan Kulon",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Lebuawu",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Manyargading",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Margoyoso",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Krasak",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Kriyan",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Karangrandu",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Gerdu",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Kaliombo",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Pecangaan",
                "kelurahan": "Gemulung",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Damarjati",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Banyuputih",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Batukali",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Bakalan",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Kalinyamatan",
                "kelurahan": "Bandungrejo",
                "kodepos": "59462"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Raguklampitan",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Somosari",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Ngasem",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Pekalongan",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Geneng",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Mindahan",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Mindahan Kidul",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Bringin",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Batealit",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Bawu",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Batealit",
                "kelurahan": "Bantrung",
                "kodepos": "59461"
            },
            {
                "kecamatan": "Karimunjawa",
                "kelurahan": "Parang",
                "kodepos": "59455"
            },
            {
                "kecamatan": "Karimunjawa",
                "kelurahan": "Kemojan (Kemujan)",
                "kodepos": "59455"
            },
            {
                "kecamatan": "Karimunjawa",
                "kelurahan": "Nyamuk",
                "kodepos": "59455"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Watuaji",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Karimunjawa",
                "kelurahan": "Karimunjawa",
                "kodepos": "59455"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Tunahan",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Tulakan",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Ujungwatu",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Kunir",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Tempur",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Sumber Rejo",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Kelet",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Keling",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Klepu",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Jlegong",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Kaligarang",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Jugo",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Damarwulan",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Gelang",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Clering",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Keling",
                "kelurahan": "Bumiharjo",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Blingoh",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Bandungharjo",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Banyumanis",
                "kodepos": "59454"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Tengguli",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Wedelan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Tubanan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Srikandang",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Pendem",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Sumanding",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Kepuk",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Papasan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Kancilan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Kedungleper",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Jinggotan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Kaliaman",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Guyangan",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Jerukwangi",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Cepogo",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Dermolo",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Dudakawu",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Banjaran",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Bondo",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Bucu",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Bangsri",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Bangsri",
                "kelurahan": "Banjar Agung",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Kembang",
                "kelurahan": "Balong",
                "kodepos": "59453"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Suwawal Timur",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Tanjung",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Slagi",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Suwawal",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Slagi",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Sinanggul",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Sekuro",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Plajan",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Lebak",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Mambak",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Mororejo",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Kawak",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Karanggondang",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Jambu",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Mlonggo",
                "kelurahan": "Jambu Timur",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Pakis Aji",
                "kelurahan": "Bulungan",
                "kodepos": "59452"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Tahunan",
                "kodepos": "59451"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Kedungcino",
                "kodepos": "59432"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Bandengan",
                "kodepos": "59432"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Mulyoharjo",
                "kodepos": "59431"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Wonorejo",
                "kodepos": "59431"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Kuwasen",
                "kodepos": "59431"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Ngabul",
                "kodepos": "59428"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Kecapi",
                "kodepos": "59429"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Tegalsambi",
                "kodepos": "59427"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Telukawur",
                "kodepos": "59427"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Sukodono",
                "kodepos": "59425"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Senenan",
                "kodepos": "59426"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Semat",
                "kodepos": "59424"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Langon",
                "kodepos": "59425"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Petekeyan",
                "kodepos": "59423"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Platar",
                "kodepos": "59423"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Mantingan",
                "kodepos": "59421"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Demangan",
                "kodepos": "59422"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Mangunan",
                "kodepos": "59422"
            },
            {
                "kecamatan": "Tahunan",
                "kelurahan": "Krapyak",
                "kodepos": "59421"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Demaan",
                "kodepos": "59419"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Kauman",
                "kodepos": "59417"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Bulu",
                "kodepos": "59418"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Jobokuto",
                "kodepos": "59416"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Ujungbatu",
                "kodepos": "59416"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Saripan",
                "kodepos": "59414"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Pengkol",
                "kodepos": "59415"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Potroyudan",
                "kodepos": "59412"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Bapangan",
                "kodepos": "59413"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Karangkebagusan",
                "kodepos": "59411"
            },
            {
                "kecamatan": "Jepara",
                "kelurahan": "Panggang",
                "kodepos": "59411"
            }
        ],
        "k83": [
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Tanjungrejo",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Terban",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Sidomulyo",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Klaling",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Pladen",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Sadang",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Honggosoco",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Jekulo",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Bulungcangkring",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Gondoharum",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Hadipolo",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Jekulo",
                "kelurahan": "Bulung Kulon",
                "kodepos": "59382"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Payaman",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Temulus",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Tenggeles",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Kirig",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Mejobo",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Jepang",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Jojo",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Kesambi",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Gulang",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Hadiwarno",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Wonosoco",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Mejobo",
                "kelurahan": "Golantepus",
                "kodepos": "59381"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Undaan Tengah",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Wates",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Undaan Lor",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Undaan Kidul",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Ngemplak",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Sambung",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Terangmas",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Lambangan",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Larikrejo",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Medini",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Karangrowo",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Kutuk",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Glagahwaru",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Kalirejo",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Tergo",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Ternadi",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Undaan",
                "kelurahan": "Berugenjang",
                "kodepos": "59372"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Samirejo",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Soco",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Rejosari",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Piji",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Puyoh",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Kuwukan",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Lau",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Margorejo",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Kajar",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Kandangmas",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Glagah Kulon",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Japan",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Dukuhwaringin",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Colo",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Cranggang",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Dawe",
                "kelurahan": "Cendono",
                "kodepos": "59353"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Tumpang Krasak",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Pasuruhan Lor",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Tanjung Karang",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Pasuruhan Kidul",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jetis Kapuan",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Ploso",
                "kodepos": "59348"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jepangpakis",
                "kodepos": "59349"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jati Kulon",
                "kodepos": "59347"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jati Wetan",
                "kodepos": "59346"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Loram Wetan",
                "kodepos": "59344"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Loram Kulon",
                "kodepos": "59344"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Getas Pejaten",
                "kodepos": "59343"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Megawon",
                "kodepos": "59342"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Ngembal Kulon",
                "kodepos": "59341"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Rahtawu",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Padurenan",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Menawan",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Kedungsari",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Klumpit",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Karangmalang",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Jurang",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Gribig",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Getasrabi",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Gondosari",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Gebog",
                "kelurahan": "Besito",
                "kodepos": "59333"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Sidorekso",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Setrokalangan",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Prambatan Lor",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Prambatan Kidul",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Mijen",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Papringan",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kedungdowo",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Karangampel",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kaliwungu",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Garung Kidul",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Garung Lor",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Gamong",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Blimbing Kidul",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Banget",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Bakalankrapyak",
                "kodepos": "59332"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Purworejo",
                "kodepos": "59327"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Peganjaran",
                "kodepos": "59327"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Gondangmanis",
                "kodepos": "59327"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Bae",
                "kodepos": "59327"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Panjang",
                "kodepos": "59326"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Bacin",
                "kodepos": "59325"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Karangbener",
                "kodepos": "59323"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Pedawang",
                "kodepos": "59324"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Ngembalrejo",
                "kodepos": "59322"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Mlati Norowito",
                "kodepos": "59319"
            },
            {
                "kecamatan": "Bae",
                "kelurahan": "Dersalam",
                "kodepos": "59321"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Mlati Kidul",
                "kodepos": "59319"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Mlati Lor",
                "kodepos": "59319"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Wergu Kulon",
                "kodepos": "59318"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Wergu Wetan",
                "kodepos": "59318"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Panjunan",
                "kodepos": "59317"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Sunggingan",
                "kodepos": "59317"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Purwosari",
                "kodepos": "59316"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Demangan",
                "kodepos": "59317"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Damaran",
                "kodepos": "59316"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Janggalan",
                "kodepos": "59316"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Kerjasan",
                "kodepos": "59315"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Langgardalem",
                "kodepos": "59315"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Kauman",
                "kodepos": "59315"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Krandon",
                "kodepos": "59314"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Singocandi",
                "kodepos": "59314"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Demaan",
                "kodepos": "59313"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Glantengan",
                "kodepos": "59313"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Kajeksan",
                "kodepos": "59314"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Kaliputu",
                "kodepos": "59312"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Kramat",
                "kodepos": "59312"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Rendeng",
                "kodepos": "59311"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Barongan",
                "kodepos": "59312"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Burikan",
                "kodepos": "59311"
            },
            {
                "kecamatan": "Kudus Kota",
                "kelurahan": "Nganguk",
                "kodepos": "59311"
            }
        ],
        "k84": [
            {
                "kecamatan": "Sarang",
                "kelurahan": "Temperak",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Sendangmulyo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Sumbermulyo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Tawangrejo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Sampung",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Sarangmeduro",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Nglojo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Pelang",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Lodan Kulon",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Lodan Wetan",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Karangmangu",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Gunungmulyo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Jambangan",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Kalipang",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Gilis",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Gonggang",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Bonjor",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Dadapmulyo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Bajingmeduro",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Banowan",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Baturno",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Bajingjowo",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Sarang",
                "kelurahan": "Babaktulung",
                "kodepos": "59274"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Watupecah",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Woro",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Tegalmulyo",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Terjan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Tanjungsari",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sumurpule",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sumurtawang",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Tanjungan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sumbersari",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sudan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sumbergayam",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sendangmulyo",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sendangwaru",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Pandangan Wetan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Plawangan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Sendang",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Ngasinan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Pandangan Kulon",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Mojokerto",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Narukan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Kebloran",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Kragan",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Kebloran",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Karangharjo",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Karanglincak",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Balongmulyo",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Kragan",
                "kelurahan": "Karanganyar",
                "kodepos": "59273"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Sluke",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Trahan",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Sanetan",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Sendangmulyo",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Rakitan",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Leran",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Manggar",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Pangkalan",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Labuhan Kidul",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Langgar",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Jurangjero",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Jatisari",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Bendo",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Sluke",
                "kelurahan": "Blimbing",
                "kodepos": "59272"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Tasiksono",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Sumbergirang",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Sriombo",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Sendangasri",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Sendangcoyo",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Soditan",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Ngemplak",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Selopuro",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Karangturi",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Karasgede",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Ngargomulyo",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Jolotundo",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Kajar",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Gowak",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Gedongmulyo",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Dorokandang",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Bonang",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Dasun",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Babagan",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Lasem",
                "kelurahan": "Binangun",
                "kodepos": "59271"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Ukir",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Wonokerto",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Tahunan",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Tengger",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Sale",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Sumbermulyo",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Pakis",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Rendeng",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Mrayun",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Ngajaran",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Jinanten",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Joho",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Gading",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Bancang",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sale",
                "kelurahan": "Bitingan",
                "kodepos": "59265"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sidomulyo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sidorejo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sambong",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sedan",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sambiroto",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Sambiroto",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Mojosari",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Ngulahan",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Kumbo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Lemahputih",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Menoro",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Kedungringin",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Kenongo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Jambeyan",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Karangasem",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Karas",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Gandrirojo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Gesikan",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Candimulyo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Dadapan",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Trembes",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Sedan",
                "kelurahan": "Bogorejo",
                "kodepos": "59264"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Telgawah",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Timbrangan",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Sidomulyo",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Tegaldowo",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Sidomulyo",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Sendangmulyo",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Panohan",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Pasucen",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Sambongpayak",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Kajar",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Kulutan",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Dowan",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Gunem",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Banyuurip",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Gunem",
                "kelurahan": "Demaan",
                "kodepos": "59263"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Warugunung",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Wuwur",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Sumberagung",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Trenggulunan",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Tuyuhan",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Sidowayah",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Pohlandak",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Punggurharjo",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Pancur",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Pandan",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Ngulangan",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Ngroto",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Karaskepoh",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Kedung",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Langkir",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Johogunung",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Kalitengah",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Gemblengmulyo",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Japeledok",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Jeruk",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Banyuurip",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Criwik",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pancur",
                "kelurahan": "Doropayung",
                "kodepos": "59262"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Tulung",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Tempaling",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Sumbangrejo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Sumberjo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Sidorejo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Sendangagung",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Samaran",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Segoromulyo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Pamotan",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Pragen",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Ringin",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Ngemplakrejo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Megal",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Mlagen",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Mlawat",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Joho",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Kepohagung",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Ketangi",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Gegersimo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Japerejo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Bangunrejo",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Gambiran",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Pamotan",
                "kelurahan": "Bamban",
                "kodepos": "59261"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Warugunung",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pondokrejo",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Sendangmulyo",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Sumbermulyo",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Ngulaan",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pasedan",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pinggan",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Mlatirejo",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Mantingan",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Lambangan Kulon",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Lambangan Wetan",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Kadiwono",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Karangasem",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Jukung",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Bulu",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Cabean",
                "kodepos": "59255"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Sulang",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Tanjung",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Seren",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Sudo",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Pranti",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Rukem",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Pomahan",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Pragu",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Pedak",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Kunir",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Landoh",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Kerep",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Korowelang",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Kebonagung",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Kemadu",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Karangharjo",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Karangsari",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Jatimudo",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Kaliombo",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Bogorame",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sulang",
                "kelurahan": "Glebeg",
                "kodepos": "59254"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sukorejo",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sumber",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Tlogotunggal",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Ronggomulyo",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sekarsari",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Polbayem",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Randuagung",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Pelemsari",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Megulung",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Krikilan",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Logede",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Logung",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Jatihadi",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Kedungasem",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Kedungtulup",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Grawan",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Jadi",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Bogorejo",
                "kodepos": "59253"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Tunggulsari",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Wiroto",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Tambakagung",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Tasikharjo",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Sendangagung",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Sidomulyo",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Pengkol",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Sambiyan",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Pengkol",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Mojowarno",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Pantiharjo",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Maguan",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Meteseh",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Mojorembun",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Karangsekar",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Kuangsan",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Dresi Kulon",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Dresi Wetan",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Gunungsari",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Banyudono",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Bogoharjo",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Babadan",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Kaliori",
                "kelurahan": "Banggi",
                "kodepos": "59252"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Weton",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Waru",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tritunggal",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Turusgede",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sumberjo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tireman",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tlogomojo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sridadi",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sukoharjo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pulo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Punjulharjo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Ngotet",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Padaran",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pasarbanggi",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Ngadem",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kumendung",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Mondoteko",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kedungrejo",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Ketanggi",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kabongan Lor",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kasreman",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sidowayah",
                "kodepos": "59218"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Gedangan",
                "kodepos": "59219"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Leteh",
                "kodepos": "59217"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kabongan Kidul",
                "kodepos": "59218"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Magersari",
                "kodepos": "59214"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sawahan",
                "kodepos": "59215"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pacar",
                "kodepos": "59213"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tanjungsari",
                "kodepos": "59213"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Gegunung Kulon",
                "kodepos": "59213"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Gegunung Wetan",
                "kodepos": "59213"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pandean",
                "kodepos": "59211"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tasikagung",
                "kodepos": "59212"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kutoharjo",
                "kodepos": "59211"
            }
        ],
        "k85": [
            {
                "kecamatan": "Batangan",
                "kelurahan": "Tompomulyo",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Raci",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Sukoagung",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Tlogomojo",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Ngening",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Pecangaan",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Kuniran",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Lengkong",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Mangunlegi",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Jembangan",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Kedalon",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Ketitangwetan",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Klayusiwalan",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Gajahkumpul",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Gunungsari",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Bulumulyo",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Bumimulyo",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Batangan",
                "kelurahan": "Batursari",
                "kodepos": "59186"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Tluwah",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Trimulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Pajeksan",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Pekuwon",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Sejomulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Margomulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Mintomulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Ketip",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Kudukeras",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Langenharjo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Kedungpancing",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Kebonsawahan",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Karangrejo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Kauman",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Jepuro",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Karang",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Growong Lor",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Growong Kidul",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Genengmulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Dukutalit",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Gadingrejo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Doropayung",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bumirejo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bendar",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bringin",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bakaran Wetan",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bakaran Kulon",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Bajomulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Juwana",
                "kelurahan": "Agungmulyo",
                "kodepos": "59185"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Trikoyo",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Tegalarum",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Tamansari",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sumberrejo",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sumberarum",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sumberan",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sumberagung",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sukorukun",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sriwedari",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Srikaton",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sidomukti",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Sidoluhur",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Ronggo",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Mojoluhur",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Mojolampir",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Mantingan",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Manjang",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Kebonturi",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Lundo",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Boto",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Jaken",
                "kelurahan": "Aromanis (Arumanis)",
                "kodepos": "59184"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Wateshaji",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Triguno",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Terteg",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Tanjungsekar",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Tegalwero",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Sokopuluhan",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Sitimulyo",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Pucakwangi",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Plosorejo",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Mojoagung",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Pelemgede",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Kletek",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Lumbungmas",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Mencon",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Karangwotan",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Kepohkencono",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Jetak",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Karangrejo",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Bodeh",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Pucakwangi",
                "kelurahan": "Grogolsari",
                "kodepos": "59183"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Tondokerto",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Tondomulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Tanjungsari",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Tlogorejo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Tambahmulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Sonorejo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Sendangsoko",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Sidoarum",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Sidomulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Puluhan Tengah",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Sembaturagung",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Mantingan Tengah",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Ngastorejo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Plosojenar",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Karangrowo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Kedungmulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Kalimulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Karangrejo Lor",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Glonggong",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Jakenan",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Jatisari",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Bungasrejo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Jakenan",
                "kelurahan": "Dukuhmulyo",
                "kodepos": "59182"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Winong",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Wirun",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Tanggel",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Tawangrejo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Tlogorejo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Sumbermulyo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Serutsadang",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Sugihan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Pohgading",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Pulorejo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Sarimulyo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Pagendisan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Pekalongan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Padangan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Kudur",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Mintorahayu",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Klecoregonang",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Kropak",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Kebolampang",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Kebowan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Karangsumber",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Gunungpanti",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Guyangan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Karangkonang",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Godo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Degan",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Danyangmulyo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Blingijati",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Bringinwareng",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Winong",
                "kelurahan": "Bumiharjo",
                "kodepos": "59181"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Tambakromo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Wukirsari",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Tambahagung",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Tambaharjo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Sinomwidodo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Sitirejo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Mojomulyo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Pakis",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Maitan",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Mangunrekso",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Kedalingan",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Larangan",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Karangwono",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Keben",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Karangawen",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Karangmulyo",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Angkatan Kidul",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Tambakromo",
                "kelurahan": "Angkatan Lor",
                "kodepos": "59174"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tlogoayu",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Wuwur",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tanjang",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tanjunganom",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Sunggingwarno",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tambahmulyo",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Sambirejo",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Soko",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Sugihrejo",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Penanggungan",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Plumbungan",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Mojolawaran",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Pantirejo",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Mintobasuki",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Kosekan",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Koryokalangan",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Karaban",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Koripandriyo",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Gabus",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Gebang",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Gempolsari",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Banjarsari",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Bogotanjung",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Wotan",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Babalan",
                "kodepos": "59173"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Tompegunung",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Wegil",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Sukolilo",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Sumbersoko",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Prawoto",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Porang Paring",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Kuwawur",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Pakem",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Kasiyan",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Kedumulyo",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Kedungwinong",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Cengkalsewu",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Gadudero",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Baturejo",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Baleadi",
                "kodepos": "59172"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Trimulyo",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Sundoluhur",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Talun",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Slungkep",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Srikaton",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Sumbersari",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Rogomulyo",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Pesagi",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Purwokerto",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Kayen",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Pasuruhan",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Durensawit",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Jatiroto",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Jimbaran",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Boloagung",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Brati",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Wangunrejo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Kayen",
                "kelurahan": "Beketel",
                "kodepos": "59171"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Sukoharjo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Sukobubuk",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Penambuhan",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Sokokulon",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Pegandan",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Muktiharjo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Ngawen",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Margorejo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Metaraman",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Langenharjo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Langse",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Jambean Kidul",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Jimbaran",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Bumirejo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Dadirejo",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Badegan",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Margorejo",
                "kelurahan": "Banyuurip",
                "kodepos": "59163"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Sitiluhur",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Wonosekar",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Semirejo",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Pohgading",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Klakah Kasian",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Plukaran",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Kedungbulus",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Ketanggan",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Bageng",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Bermi",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Gembong",
                "kelurahan": "Gembong",
                "kodepos": "59162"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Wonorejo",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Tamansari",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Tlogorejo",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Tlogosari",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Tajungsari",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Sambirejo",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Sumbermulyo",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Suwatu",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Purwosari",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Regaloh",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Guwo",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Klumpit",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Lahar",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Cabak",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Tlogowungu",
                "kelurahan": "Gunungsari",
                "kodepos": "59161"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Tegalombo",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Wedusan",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Ngagel",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Puncel",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Kembang",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Kenanti",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Dukuhseti",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Dumpil",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Grogolan",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Bakalan",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Bakalan",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Dukuhseti",
                "kelurahan": "Banyutowo",
                "kodepos": "59158"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Sumur",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Sentul",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Sirahan",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Payak",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Plaosan",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Mojo",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Ngablak",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Ngawen",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Gesengan",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Karangsari",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Medani",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Bleber",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Cluwak",
                "kelurahan": "Gerit",
                "kodepos": "59157"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Sidomulyo",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Sumberejo",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Pesagen",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Sampok",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Jrahi",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Ngetuk",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Perdopo",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Jembulwunut",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Jepalo",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Gunungwungkal",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Gulangpungge",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Gajihan",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Giling",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Bancak",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Gunungwungkal",
                "kelurahan": "Gadu",
                "kodepos": "59156"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Tendas",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Tunggulsari",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Sendangrejo",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Tayukulon",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Tayuwetan",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Purwokerto",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Sambiroto",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Pakis",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Pondowan",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Pundenrejo",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Luwang",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Margomulyo",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Keboromo",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Kedungbang",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Kedungsari",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Jepat Lor",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Kalikalong",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Dororejo",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Jepat Kidul",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Bendokaton Kidul",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Tayu",
                "kelurahan": "Bulungan",
                "kodepos": "59155"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Tunjungrejo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Waturoyo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Soneyan",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Tanjungrejo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Tegalarum",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Semerak",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Sidomukti",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Purwodadi",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Purworejo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Sekarjalak",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Pangkalan",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Pohijo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Ngemplak Kidul",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Ngemplak Lor",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Langgenharjo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Margotuhu Kidul",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Margoyoso",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Kajen",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Kertomulyo",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Bulumanis Kidul",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Bulumanis Lor",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Margoyoso",
                "kelurahan": "Cebolek Kidul",
                "kodepos": "59154"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Trangkil",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Tegalharjo",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Tlutup",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Rejoagung",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Sambilawang",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Krandan",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Mojoagung",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Pasucen",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Karangwage",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Kertomulyo",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Ketanen",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Kajar",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Karanglegi",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Guyangan",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Kadilangu",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Tluwuk",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Wedarijaksa",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Trangkil",
                "kelurahan": "Asempapan",
                "kodepos": "59153"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Suwaduk",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Tawangharjo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Tlogoharum",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Sukoharjo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Panggungroyom",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Sidoharjo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Ngurensiti",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Pagerharjo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Ngurenrejo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Ngurenrejo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Jetak",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Jontro",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Kepoh",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Bumiayu",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Jatimulyo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Widorokandang",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Wedarijaksa",
                "kelurahan": "Bangsalrejo",
                "kodepos": "59152"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Tambaharjo",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Tambahsari",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Sinoman",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Sugiharjo",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Payang",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Purworejo",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Parenggan",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Mulyoharjo",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Ngepungrojo",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Dengkek",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Geritan",
                "kodepos": "59119"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Kutoharjo",
                "kodepos": "59118"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Sarirejo",
                "kodepos": "59118"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Mustokoharjo",
                "kodepos": "59117"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Sidoharjo",
                "kodepos": "59117"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Semampir",
                "kodepos": "59116"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Kalidoro",
                "kodepos": "59117"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Pati Wetan",
                "kodepos": "59115"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Gajahmati",
                "kodepos": "59116"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Panjunan",
                "kodepos": "59116"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Blaru",
                "kodepos": "59114"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Pati Kidul",
                "kodepos": "59114"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Plangitan",
                "kodepos": "59113"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Puri",
                "kodepos": "59113"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Sidokerto",
                "kodepos": "59111"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Ngarus",
                "kodepos": "59112"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Winong",
                "kodepos": "59112"
            },
            {
                "kecamatan": "Pati",
                "kelurahan": "Pati Lor",
                "kodepos": "59111"
            }
        ],
        "k86": [
            {
                "kecamatan": "Jati",
                "kelurahan": "Tobo",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Randulawang",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Singget",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Kepoh",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Pelem",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Pengkoljagong",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jati",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Jegong",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Doplang",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Gabusan",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Gempol",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Sumber",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Jati",
                "kelurahan": "Bangkleyan",
                "kodepos": "58384"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Nglebak",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Nglungger",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Ngrawoh",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Mojorembun",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Nginggil",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Medalem",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Megeri",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Mendenrejo",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Getas",
                "kodepos": "58383"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Wulung",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Temulus",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Tlogotuwung",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Tanggel",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Sumberjo",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Randublatung",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Sambongwangan",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Plosorejo",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Pilang",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Ngliron",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Kutukan",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Kediren",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Kalisari",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Jeruk",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Kadengan",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Gembyungan",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Bodeh",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Randublatung",
                "kelurahan": "Bekutuk",
                "kodepos": "58382"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Wado",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Tanjung",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Sogo",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Sidorejo",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Pulo",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Panolan",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Ngraho",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Klagen",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Nglandeyan",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Ketuwan",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Kemantren",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Kedungtuban",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Kalen",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Gondel",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Jimbung",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Galuk",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Kedungtuban",
                "kelurahan": "Bajo",
                "kodepos": "58381"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Singonegoro",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Nglobo",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Nglebur",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Ketringan",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Jiworejo",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Jiken",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Janjang",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Genjahan",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Cabak",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Bleboh",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Jiken",
                "kelurahan": "Bangowan",
                "kodepos": "58372"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Temengeng",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Sambongrejo",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Sambong",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Ledok",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Pojokwatu",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Gagakan",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Giyanti",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Brabowan",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Gadu",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Tambakromo",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Sambong",
                "kelurahan": "Biting",
                "kodepos": "58371"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Ngloram",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Sumberpitu",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Nglanjuk",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Kentong",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Mernung",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Mulyorejo",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Jipang",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Kapuan",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Karangboyo",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Cabeyan (Cabean)",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Gadon",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Getas",
                "kodepos": "58315"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Ngroto",
                "kodepos": "58314"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Balun",
                "kodepos": "58311"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Cepu",
                "kodepos": "58312"
            },
            {
                "kecamatan": "Cepu",
                "kelurahan": "Ngelo",
                "kodepos": "58313"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Sendangrejo",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Tempurejo",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Nglengkir",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Prantaan",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Sarirejo",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Karang",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Karanganyar",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Gombang",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Jeruk",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Jurangjero",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Gembol",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Gayam",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Bogorejo",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Bogorejo",
                "kelurahan": "Gandu",
                "kodepos": "58262"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Turirejo",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Waru",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Soko",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Sumurboto",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Tempellemahbang",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Semanggi",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Seso",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Puledagel",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Semampir",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Nglarohgunung",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Palon",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Ngampon",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Kemiri",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Kawengan",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Jatirejo",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Jatirejo",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Jepon",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Geneng",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Gersi",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Gedangdowo",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Blungun",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Brumbung",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Bangsri",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Balong",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Jepon",
                "kelurahan": "Bacem",
                "kodepos": "58261"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Tlogowungu",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Wotbakah",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Sumberejo",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Tengger",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Pengkolrejo",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Padaan",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Ngapus",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Ngiyono",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Ngrambitan",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Kalinanas",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Krocok",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Harjowinangun",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Japah",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Dologan",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Gaplokan",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Bogem",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Bogorejo",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Japah",
                "kelurahan": "Beganjing",
                "kodepos": "58257"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Wukirsari",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Sonokulon",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Tinapan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Todanan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Sendang",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Sambeng",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Pelemsengir",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Prigi",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Ngumbul",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Ledok",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Kembang",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Ketileng",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Karanganyar",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Kedungbacin",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Kedungwungu",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Kacangan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Kajengan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Gunungan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Gondoriyo",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Dalangan",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Dringo",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Cokrowati",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Bicak",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Candi",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Todanan",
                "kelurahan": "Bedingin",
                "kodepos": "58256"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Sonokidul",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Tawangrejo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Sambiroto",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Sempu",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Sendangwates",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Ploso Rejo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Ngawenombo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Ngilen",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Muraharjo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Kunduran",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Klokah",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Kodokan",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Kedungwaru",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Kemiri",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Jetak",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Kalangrejo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Karanggeneng",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Jagong",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Gagaan",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Cungkup",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Buloh",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Blumbangrejo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Botoreco",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Balong",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Bejirejo",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Wantilgung",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Kunduran",
                "kelurahan": "Bakah",
                "kodepos": "58255"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Trembulrejo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sumberejo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Talokwohmojo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sendangrejo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Srigading",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sendangmulyo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sendangagung",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Semawur",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sambongrejo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sarimulyo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Punggursugih",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Rowobungkul",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Sambonganyar",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Plumbon",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Kendayaan",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Ngawen",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Karangtengah",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Kedungsatriyan",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Jetakwanger",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Karangjong",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Gedebeg",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Gondang",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Gotputuk",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Bogowanti",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Bradag",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Berbak",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Bergolo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Wonosemi",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Bandungrojo",
                "kodepos": "58254"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Sendangwungu",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Sidomulyo",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Sumberagung",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Sembongin",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Sendanggayam",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Mojowetan",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Plosorejo",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Kebonrejo",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Kembang",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Klopoduwur",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Jatiklampok",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Jatisari",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Karangtalun",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Gedongsari",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Banjarejo",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Buluroto",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Bacem",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Balongrejo",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Banjarejo",
                "kelurahan": "Balongsari",
                "kodepos": "58253"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Tunjungan",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Tutup",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Tamanrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Tambahrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Tawangrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Sitirejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Sukorejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Keser",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Nglangitan",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Sambongrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Kedungringin",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Kedungrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Gempolrejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Kalangan",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Tunjungan",
                "kelurahan": "Adirejo",
                "kodepos": "58252"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tempuran",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tempurejo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Temurejo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tegalgunung",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tambaksari",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Sendangharjo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Purworejo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Purwosari",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Pelem",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Plantungan",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Ngampel",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Patalan",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Karangjati",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Ngadipurwo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Jepangrejo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Kamolan",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Bangkle",
                "kodepos": "58218"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Bangkle",
                "kodepos": "58218"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Andongrejo",
                "kodepos": "58219"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Beran",
                "kodepos": "58216"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Kedung Jenar",
                "kodepos": "58217"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tambahrejo",
                "kodepos": "58214"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Mlangsen",
                "kodepos": "58215"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Sonorejo",
                "kodepos": "58213"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Jetis",
                "kodepos": "58214"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Kunden",
                "kodepos": "58212"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Kauman",
                "kodepos": "58213"
            },
            {
                "kecamatan": "Blora kota",
                "kelurahan": "Tempelan",
                "kodepos": "58211"
            }
        ],
        "k87": [
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Sumberagung",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Tanjungharjo",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Truwolu",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Sarirejo",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Sendangrejo",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Pendem",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Ngaraparap",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Ngaringan",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Kalanglundo",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Belor",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Kalangdosari",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Wirosari",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Ngaringan",
                "kelurahan": "Bandungsari",
                "kodepos": "58193"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Tanjungrejo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Tegalrejo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Tambakselo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Sambirejo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Tambahrejo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Kunden",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Mojorebo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Karangasem",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Kropak",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Dokoro",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Gedangan",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Kalirejo",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Wirosari",
                "kelurahan": "Dapurno",
                "kodepos": "58192"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Selo",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Tarub",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Tawangharjo",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Pojok",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Pulongrambe",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Plosorejo",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Kemaduhbatur",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Mayahan",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Godan",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Tawangharjo",
                "kelurahan": "Jono",
                "kodepos": "58191"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tunggulrejo",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tahunan",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Tlogotirto",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Pelem",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Sulursari",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Suwatu",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Nglinduk",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Pandanharum",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Karangrejo",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Keyongan",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Bendoharjo",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Gabus",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Kalipang",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Gabus",
                "kelurahan": "Banjarejo",
                "kodepos": "58183"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Simo",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Tanjungsari",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Sengonwetan",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Rejosari",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Sambongbangi",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Kuwu",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Pakis",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Kalisari",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Kradenan",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Crewek",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Grabagan",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Banjardowo",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Banjarsari",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Kradenan",
                "kelurahan": "Bago",
                "kodepos": "58182"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Tuko",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Sembungharjo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Sidorejo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Pulokulon",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Randurejo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Panunggalan",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Pojok",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Karangharjo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Mangunrejo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Mlowokarangtalun",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Jetaksari",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Jambon",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Pulokulon",
                "kelurahan": "Jatiharjo",
                "kodepos": "58181"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Sobo",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Suru",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Ngrandu",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Rambat",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Karang Anyar",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Ledokdawan",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Monggot",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Juworo",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Kalangbancar",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Geyer",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Jambangan",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Bangsri",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Tunggak",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Geyer",
                "kelurahan": "Asemrudung",
                "kodepos": "58172"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Sugihan",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Tambirejo",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Plosoharjo",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Sindurejo",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Ngrandah",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Pilangpayung",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Krangganharjo",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Kenteng",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Genengadal",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Genengsari",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Katong",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Depok",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Dimoro",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Bandungharjo",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Toroh",
                "kelurahan": "Boloh",
                "kodepos": "58171"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Prigi",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Wates",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Ngombak",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Padas",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Panimbo",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Kentengsari",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Klitikan",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Karanglangu",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Kedungjati",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Jumo",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Kalimaro",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Tanggungharjo",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Kedungjati",
                "kelurahan": "Deras",
                "kodepos": "58167"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Sugihmanik",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Ngambakrejo",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Padang",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Ringinpitu",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Kapung",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Mrisi",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Brabo",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tanggungharjo",
                "kelurahan": "Kaliwenang",
                "kodepos": "58166"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tlogorejo",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tunjungharjo",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tegowanu Wetan",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tegowanu Kulon",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tanggirejo",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Sukorejo",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Tajemsari",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Medani",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Pepe",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Mangunsari",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Kejawan",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Kedungwungu",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Kebonagung",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Karangpasar",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Gebangan",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Gaji",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Cangkring",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Tegowanu",
                "kelurahan": "Curug",
                "kodepos": "58165"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Trisari",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Tlogomulyo",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Tambakan",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Saban",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Ringinkidul",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Rowosari",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Ringinharjo",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Pranten",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Papanrejo",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Penadaran",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Ngroto",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Mlilir",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Kunjeng",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Kuwaron",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Kemiri",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Jatipecaron",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Jeketro",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Gubug",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Ginggangtani",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Baturagung",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Gubug",
                "kelurahan": "Gelapan",
                "kodepos": "58164"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Termas",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Temurejo",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Telawah",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Sumberejosari",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Sendangharjo",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Putatnganten",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Rawoh",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Parakan",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Pangkalan",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Nampu",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Mojoagung",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Ketro",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Mangin",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Karanganyar",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Karangsono",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Gunung Tumpeng",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Jetis",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Cekel",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Karangrayung",
                "kelurahan": "Dempel",
                "kodepos": "58163"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Werdoyo",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Tungu",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Wanutunggal",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Sumurgede",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Tinanding",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Sambung",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Sumberagung",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Pahesan",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Rajek",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Manggarmas",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Manggarwetan",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Kopek",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Latak",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Klampok",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Ketangirejo",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Ketitang",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Karanggeneng",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Kemloko",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Jatilor",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Guyangan",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Harjowinangun",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Guci",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Gundi",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Dorolegi",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Godong",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Anggaswangi",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Bringin",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Godong",
                "kelurahan": "Bugel",
                "kodepos": "58162"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Wolo",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Watu Pawon",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Wedoro",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Winong",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Toko",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Tunggu",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Pulutan",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Sedadi",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Pengkol",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Ngeluk",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Penawangan",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Kramat",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Lajer",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Leyangan",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Karangpaing",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Kluwan",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Jipang",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Karangawader",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Bologarang",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Penawangan",
                "kelurahan": "Curut",
                "kodepos": "58161"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Wandankemiri",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Terkesi",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Selojari",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Taruman",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Menawan",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Penganten",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Kandangrejo",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Klambu",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Klambu",
                "kelurahan": "Jenengan",
                "kodepos": "58154"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Temon",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Tirem",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Menduran",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Tegalsumur",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Kronggen",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Lemahputih",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Karangsari",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Katekan",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Brati",
                "kelurahan": "Jangkungharjo",
                "kodepos": "58153"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Sumber Jatipohon",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Tanggungharjo",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Teguhan",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Rejosari",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Sedayu",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Putatsari",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Lebengjumuk",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Ngabenrejo",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Lebak",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Getasrejo",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Grobogan",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Grobogan",
                "kelurahan": "Karangrejo",
                "kodepos": "58152"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Putat",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Warukaranganyar",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Nglobar",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Ngraji",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Pulorejo",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Nambuhan",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Ngembak",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kandangan",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Karanganyar",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kedungrejo",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Genuksuran",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kalongan",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Danyang",
                "kodepos": "58113"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Candisari",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Cingkrong",
                "kodepos": "58114"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwodadi",
                "kodepos": "58111"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kuripan",
                "kodepos": "58112"
            }
        ],
        "k88": [
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Sidomukti",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Trengguli",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Seloromo",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Lempong",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Menjing",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Gumeng",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Jenawi",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Anggrasmanis",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Jenawi",
                "kelurahan": "Balong",
                "kodepos": "57794"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Puntukrejo",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Segorogunung",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Kemuning",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Ngargoyoso",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Nglegok",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Jatirejo",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Berjo",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Girimulyo",
                "kodepos": "57793"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Tawangmangu",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Tengklik",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Nglebak",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Plumbon",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Sepanjang",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Kalisoro",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Karanglo",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Blumbang",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Gondosuli",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Tawangmangu",
                "kelurahan": "Bandardawung",
                "kodepos": "57792"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Ngemplak",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Salam",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Tohkuning",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Karang",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Karang Pandan",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Gerdu",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Gondangmanis",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Dayu",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Doplang",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Wukirsawit",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Bangsri",
                "kodepos": "57791"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Tlobo",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Wonokeling",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Wonorejo",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Karangsari",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Petung",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Jatisawit",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Jatiyoso",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatiyoso",
                "kelurahan": "Beruk",
                "kodepos": "57785"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatiwarno",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Ngepungsari",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatiroyo",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatisobo",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatisuko",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatipuro",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatipurwo",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatikuwung",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatimulyo",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Ploso",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jatipuro",
                "kelurahan": "Jatiharjo",
                "kodepos": "57784"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Lemahbang",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Paseban",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Kedawung",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Kwangsan",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Jumapolo",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Karangbangun",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Jumantoro",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Giriwondo",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Jatirejo",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Tugu",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Bakalan",
                "kodepos": "57783"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Tunggulrejo",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Sukosari",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Sedayu",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Sringin",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Ngunut",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Sambirejo",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Gemantar",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Genengan",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Kebak",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Plosorejo",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Jumantono",
                "kelurahan": "Blorong",
                "kodepos": "57782"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Pablengan",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Ngadiluwih",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Koripan",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Matesih",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Girilayu",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Karangbangun",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Dawung",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Matesih",
                "kelurahan": "Gantiwarno",
                "kodepos": "57781"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Pulosari",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Waru",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Macanan",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Malanggaten",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Nangsri",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Kemiri",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Kaliwuluh",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Kebak",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Banjarharjo",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kebakkramat",
                "kelurahan": "Alastuwo",
                "kodepos": "57762"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Tawangsari",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Plosorejo",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Sumberejo",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Tamansari",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Kuto",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Kwadungan",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Gempolan",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Botok",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Ganten",
                "kodepos": "57753"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Pojok",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Sewurejo",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Ngadirejo",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Pendem",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Pereng",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Mojogedang",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Mojoroto",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Kaliboto",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Kedungjeruk",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Gebyok",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Gentungan",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Buntar",
                "kodepos": "57752"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Suruhkalang",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Jetis",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Ngringo",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Sroyo",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Jaten",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Jati",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Dagen",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Ngargoyoso",
                "kelurahan": "Dukuh",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Wonolopo",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Jaten",
                "kelurahan": "Brujul",
                "kodepos": "57731"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Papahan",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Suruh",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Kaling",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Karangmojo",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Pandeyan",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Buran",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Gaum",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Kalijirak",
                "kodepos": "57722"
            },
            {
                "kecamatan": "Tasikmadu",
                "kelurahan": "Ngijo",
                "kodepos": "57721"
            },
            {
                "kecamatan": "Mojogedang",
                "kelurahan": "Munggur",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Lalung",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Jumapolo",
                "kelurahan": "Kadipiro",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Gedong",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Jantiharjo",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Bolong",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Delingan",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Gayamdompo",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Popongan",
                "kodepos": "57715"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Bejen",
                "kodepos": "57716"
            },
            {
                "kecamatan": "Karangpandan",
                "kelurahan": "Harjosari",
                "kodepos": "57715"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Tegalgede",
                "kodepos": "57714"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Cangakan",
                "kodepos": "57712"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Jungke",
                "kodepos": "57713"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karanganyar",
                "kodepos": "57711"
            },
            {
                "kecamatan": "Kerjo",
                "kelurahan": "Karangrejo",
                "kodepos": "57711"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Wonosari",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Wonorejo",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Selokaton",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Tuban",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Rejosari",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Krendowahono",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Kragan",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Jeruksawit",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Karangturi",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Jatikuwung",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Dayu",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Bulurejo",
                "kodepos": "57188"
            },
            {
                "kecamatan": "Gondangrejo",
                "kelurahan": "Plesungan",
                "kodepos": "57181"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Ngasem",
                "kodepos": "57179"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Bolon",
                "kodepos": "57178"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Malangjiwan",
                "kodepos": "57177"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Gajahan",
                "kodepos": "57176"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Paulan",
                "kodepos": "57176"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Gawanan",
                "kodepos": "57175"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Tohudan",
                "kodepos": "57173"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Blulukan",
                "kodepos": "57174"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Klodran",
                "kodepos": "57172"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Gedongan",
                "kodepos": "57173"
            },
            {
                "kecamatan": "Colomadu",
                "kelurahan": "Baturan",
                "kodepos": "57171"
            }
        ],
        "k89": [
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Tengger",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Sukorejo",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Nguneng",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Puhpelem",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Giriharjo",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Puhpelem",
                "kelurahan": "Golo",
                "kodepos": "57698"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Ngaglik",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Sugihan",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Tanjung",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Geneng",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Krandegan",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Nadi",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Conto",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Domas",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Bulukerto",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Bulukerto",
                "kelurahan": "Bulurejo",
                "kodepos": "57697"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Plosorejo",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Pucung",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Lemahbang",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Miri",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Ngroto",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Gedawung",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Gesing",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Kismantoro",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Bugelan",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Kismantoro",
                "kelurahan": "Gambiranom",
                "kodepos": "57696"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Talesan",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Tegalrejo",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Sukomangu",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Sumber",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Purwantoro",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Sendang",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Miricinde",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Ploso",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Joho",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Kenteng",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Kepyar",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Biting",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Gondang",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Bakalan",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Purwantoro",
                "kelurahan": "Bangsri",
                "kodepos": "57695"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Waru",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Watusomo",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Soco",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Sokoboyo",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Tunggur",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Setren",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Slogohimo",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Sambirejo",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Sedayu",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Padarangin",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Pandan",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Randusari",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Klunggen",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Made",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Gunan",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Karang",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Slogohimo",
                "kelurahan": "Bulusari",
                "kodepos": "57694"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Mangunharjo",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Slogoretno",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Tawangrejo",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Kembang",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Kopen",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Jatipurwo",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Jeporo",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Jatipurno",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Girimulyo",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Giriyoso",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatipurno",
                "kelurahan": "Balepanjang",
                "kodepos": "57693"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Sanggrong",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Sugihan",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Pesido",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Pingkuk",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Ngelo",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Pengkol",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Jatirejo",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Jatiroto",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Mojopura",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Duren",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Guno",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Brenggolo",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Cangkring",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Dawungan",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Boto",
                "kodepos": "57692"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Tanjungsari",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Tasikhargo",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Watangsono",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Sumberejo",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Tanggulangin",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Sidorejo",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Sambirejo",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Semen",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Pelem",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Pule",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Rejosari",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Ngrompak",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Pandeyan",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Jatisari",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Jatisrono",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Gunungsari",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Jatisrono",
                "kelurahan": "Gondangsari",
                "kodepos": "57691"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Waleng",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Tambakmerang",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Semagarduwur",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Sidokerto",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Selorejo",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Sanan",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Nungkulan",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Jendi",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Jatirejo",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Girimarto",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Giriwarno",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Gemawang",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Doho",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Girimarto",
                "kelurahan": "Bubakan",
                "kodepos": "57683"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Widoro",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Tremes",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Tempursari",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Sempukerep",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Sidoharjo",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Sembukan",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Ngabeyan",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Mojoreno",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Kebonagung",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Kedunggupit",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Kayuloko",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Jatinom",
                "kodepos": "57682"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Pondok",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Ngadirojolor",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Ngadirojokidul",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Mlokomaniswetan",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Mlokomaniskulon",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Kerjolor",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Kerjokidul",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Kasihan",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Jatimarto",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Gemawang",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Gedong",
                "kodepos": "57681"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Tlogosari",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Tlogoharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Songbledeg",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Sambiharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Pucanganom",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Paranggupito",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Ngargoharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Ketos",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Gunturharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Johunut",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Jatirejo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Gudangharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Giritontro",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Paranggupito",
                "kelurahan": "Gendayakan",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Temboro",
                "kodepos": "57677"
            },
            {
                "kecamatan": "Giritontro",
                "kelurahan": "Bayemharjo",
                "kodepos": "57678"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Ngambarsari",
                "kodepos": "57677"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Purwoharjo",
                "kodepos": "57677"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Jeblogan",
                "kodepos": "57677"
            },
            {
                "kecamatan": "Karangtengah",
                "kelurahan": "Karangtengah",
                "kodepos": "57677"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Tukulrejo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Tirtosuworo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Sendangagung",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Sirnoboyo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Tawangharjo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Sejati",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Selomarto",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Pideksa",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Platarejo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Giriwoyo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Guwotirto",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Ngancar",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Bumiharjo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Gedongrejo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Girikikis",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Giriwoyo",
                "kelurahan": "Bulurejo",
                "kodepos": "57675"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Tegiri",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Sumberagung",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Sumberejo",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Ronggojati",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Selopuro",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Sendangsari",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Batuwarno",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Batuwarno",
                "kelurahan": "Kudi",
                "kodepos": "57674"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Temon",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Watuagung",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Setrorejo",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Talunombo",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Saradan",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Sendangrejo",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Gambiranom",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Glesungrejo",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Kedungombo",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Boto",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Baturetno",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Belikurip",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Wiroko",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Baturetno",
                "kelurahan": "Balepanjang",
                "kodepos": "57673"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Sukoharjo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Tanjungsari",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Tirtomoyo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Sidorejo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Ngarjosari",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Sendangmulyo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Hargosari",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Hargantoro",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Hargorejo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Genengharjo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Girirejo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Dlepih",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Wonoharjo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Tirtomoyo",
                "kelurahan": "Banyakprodo",
                "kodepos": "57672"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Pondoksari",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Semin",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Ngadipiro",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Ngadiroyo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Kedungrejo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Kulurejo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Bumiharjo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Gebang",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Beji",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Bulurejo",
                "kodepos": "57671"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Watangrejo",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Wonodadi",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Tubokarto",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Trukan",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Suci",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Sumberagung",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Sambiroto",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Sedayu",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Lebak",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Petirsari",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Pracimantoro",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Jimbar",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Joho",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Glinggang",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Gambirmanis",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Gebangharjo",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Gedong",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Pracimantoro",
                "kelurahan": "Banaran",
                "kodepos": "57664"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Sumberharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Tegalharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Tempurharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Puloharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Sindukarta",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Pucung",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Panekan",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Pasekan",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Ngandong",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Ngunggahan",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Minggarharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Ngadirejo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Basuhan",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Eromoko",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Punduhsari",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Eromoko",
                "kelurahan": "Baleharjo",
                "kodepos": "57663"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Pagutan",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Pijiharjo",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Karanglor",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Kepuhsari",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Bero",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Manyaran",
                "kelurahan": "Gunungan",
                "kodepos": "57662"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Wuryantoro",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Pulutanwetan",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Sumberejo",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Mojopura",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Pulutankulon",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Gumiwanglor",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Mlopoharjo",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Singodutan",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Wuryantoro",
                "kelurahan": "Genukharjo",
                "kodepos": "57661"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Pule",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Sendangijo",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Nambangan",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Pare",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Kepatihan",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Kaliancar",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Keloran",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Jaten",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Jendi",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Wonokerto",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Selogiri",
                "kelurahan": "Gemantar",
                "kodepos": "57652"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Wonoboyo",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Wonoharjo",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Purwosari",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Sendang",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Sonoharjo",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Pokohkidul",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Purworejo",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Bulusulur",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Manjung",
                "kodepos": "57615"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Giriwono",
                "kodepos": "57613"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Wuryorejo",
                "kodepos": "57614"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Giripurwo",
                "kodepos": "57612"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Wonokarto",
                "kodepos": "57612"
            },
            {
                "kecamatan": "Wonogiri",
                "kelurahan": "Giritirto",
                "kodepos": "57611"
            }
        ],
        "k90": [
            {
                "kecamatan": "Nguter",
                "kelurahan": "Serut",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Tanjung",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Tanjungrejo",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Pondok",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Pengkol",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Plesan",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Lawu",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Nguter",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Kepuh",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Juron",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Kedungwinong",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Daleman",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Gupit",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Jangglengan",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Baran",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Nguter",
                "kelurahan": "Celep",
                "kodepos": "57571"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Sanggang",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Tiyaran",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Ngasinan",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Puron",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Lengking",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Malangan",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Kedungsono",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Kunden",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Karangasem",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Gentan",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Kamal",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Bulu",
                "kodepos": "57563"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Tegalsari",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Weru",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Ngreco",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Tawang",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karangtengah",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karangwuni",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Krajan",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karanganyar",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karangmojo",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Jatingarang",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Karakan",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Alasombo",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Weru",
                "kelurahan": "Grogol",
                "kodepos": "57562"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Tangkisan",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Watubonang",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Ponowaren",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Pundungrejo",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Tambakboyo",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Majasto",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Pojok",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Lorog",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Grajegan",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Kedungjambal",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Keteguhan",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Wironanggan",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Tawangsari",
                "kelurahan": "Dalangan",
                "kodepos": "57561"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Trangsan",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Trosemi",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Tempel",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Sraten",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Mayang",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Sanggung",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Krajan",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Luwang",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Jati",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Kagokan",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Klaseman",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Blimbing",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Gatak",
                "kelurahan": "Geneng",
                "kodepos": "57557"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Siwal",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Waru",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Ngrombo",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Purbayan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Mancasan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Menuran",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Jetis",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Kadilangu",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Kudu",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Gedongan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Gentan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Bentakan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Duwet",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Baki",
                "kelurahan": "Bakipandeyan",
                "kodepos": "57556"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Tepisari",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Wonorejo",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Polokarto",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Pranan",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Rejosari",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Kenokorejo",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Ngombakan",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Kemasan",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Kajuapak",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Karangwuni",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Genengsari",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Godog",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Jatisobo",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Bugel",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Bulu",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Triyagan",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Wirun",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Bakalan",
                "kodepos": "57555"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Tegalmade",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Sapen",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Palur",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Plumbon",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Klumprit",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Kragilan",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Laban",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Gadingan",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Joho",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Demakan",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Dukuh",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Bekonang",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Mojolaban",
                "kelurahan": "Cangkol",
                "kodepos": "57554"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Sanggrahan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Telukan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Pandeyan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Parangjoro",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Pondok",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Madegondo",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Manang",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Kadokan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Kwarasan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Langenharjo",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Cemani",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Gedangan",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Grogol",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sonorejo",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Banaran",
                "kodepos": "57552"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Bulakrejo",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Kenep",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Kriwen",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Begajah",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Bulakan",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Banmati",
                "kodepos": "57551"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Sugihan",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Toriyo",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Mulur",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Paluhombo",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Puhgogor",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Mojorejo",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Mertan",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Gentan",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Jagan",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Manisharjo",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Bendosari",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Cabeyan",
                "kodepos": "57528"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Sidorejo",
                "kodepos": "57527"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Dukuh",
                "kodepos": "57524"
            },
            {
                "kecamatan": "Bendosari",
                "kelurahan": "Jombor",
                "kodepos": "57521"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Combongan",
                "kodepos": "57519"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Gayam",
                "kodepos": "57514"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Mandan",
                "kodepos": "57516"
            },
            {
                "kecamatan": "Polokarto",
                "kelurahan": "Mranggen",
                "kodepos": "57513"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Joho",
                "kodepos": "57513"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Jetis",
                "kodepos": "57511"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo",
                "kodepos": "57512"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Ngemplak",
                "kodepos": "57169"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Pabelan",
                "kodepos": "57169"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Gumpang",
                "kodepos": "57169"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Kartasura",
                "kodepos": "57169"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Gonilan",
                "kodepos": "57169"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Wirogunan",
                "kodepos": "57166"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Pucangan",
                "kodepos": "57168"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Ngabeyan",
                "kodepos": "57165"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Kertonatan",
                "kodepos": "57166"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Ngadirejo",
                "kodepos": "57163"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Singopuran",
                "kodepos": "57164"
            },
            {
                "kecamatan": "Kartasura",
                "kelurahan": "Makamhaji",
                "kodepos": "57161"
            }
        ],
        "k91": [
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Pluneng",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Menden",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Ngrundul",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Karangduren",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Malangjiwan",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Basin",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Kebonarum",
                "kelurahan": "Gondang",
                "kodepos": "57486"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Tanjungsari",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Taskombang",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Tijayan",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Solodiran",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Sukorini",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Ngemplak Seneng",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Sapen",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Nangsri",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Kranggan",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Leses",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Kecemen",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Kepurun",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Borangan",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Kebonalas",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Barukan",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Manisrenggo",
                "kelurahan": "Bendan",
                "kodepos": "57485"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Tegalmulyo",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Tlogowatu",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Talun",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Tangkil",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Panggang",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Sidorejo",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Kemalang",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Kendalsari",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Keputran",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Bumiharjo",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Dompol",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Bawukan",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Kemalang",
                "kelurahan": "Balerante",
                "kodepos": "57484"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Ngemplak",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Somokaton",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Karang Nongko",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Logede",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Kadilajo",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Kanoman",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Jagalan",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Jetis",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Jiwan",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Gumul",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Demakijo",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Gemampir",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Blimbing",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Karangnongko",
                "kelurahan": "Banyuaeng",
                "kodepos": "57483"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Wunut",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Sudimoro",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Tulung",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Sorogaten",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Pucang Miliran",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Sedayu",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Pomah",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Mundu",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Malangan",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Majegan",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Kiringan",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Kemiri",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Daleman",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Gedongjetis",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Dalangan",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Cokro",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Bono",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Tulung",
                "kelurahan": "Beji",
                "kodepos": "57482"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Tibayan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Socokangsi",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Temuireng",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Randulanang",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Puluhan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Mranggen",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Pandeyan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Kayumas",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Krajan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Jemawan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Jatinom",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Gedaren",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Glagah",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Cawan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Bonyokan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Beteng",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Bengking",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Jatinom",
                "kelurahan": "Bandungan",
                "kodepos": "57481"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Troso",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Tarubasan",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Soropaten",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Padas",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Pondok",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Ngabeyan",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Kunden",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Karanganom",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Kadirejo",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Karangan",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Jurangjero",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Jeblog",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Jungkare",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Gempol",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Gledeg",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Jambeyan",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Blanceran",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Brangkal",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Karanganom",
                "kelurahan": "Beku",
                "kodepos": "57475"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Turus",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Wangen",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Sidoharjo",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Sidowayah",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Ponggok",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Polan",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Kranggan",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Nganjat",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Ngaran",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Kebonharjo",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Keprabon",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Kapungan",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Karanglo",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Kauman",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Jimus",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Glagah Wangi",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Janti",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Polanharjo",
                "kelurahan": "Borongan",
                "kodepos": "57474"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Wonosari (Wonososa)",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Teloyo",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wadung Getas",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tegalgondo",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tegalgondo",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Pandanan",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sekaran",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sidowarno",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Lumbung Kerep",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Ngreden",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Jelobo",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Kingkang",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Duwet",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Gunting",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bolali",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Boto",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bulan",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bentangan",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Trasan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bener",
                "kodepos": "57473"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Tanjung",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Tlogorandu",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Serenan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Taji",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Sawahan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Mrisen",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Pundungan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Ketitang",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Kwarasan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Juwiring",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Kenaiban",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Jaten",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Jetis",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Juwiran",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Carikan",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Gondangsari",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Bulurejo",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Juwiring",
                "kelurahan": "Bolopleret",
                "kodepos": "57472"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Sribit",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Tlobong",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Sidomulyo",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Mendak",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Sabrang",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Segaran",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Kepanjen",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Krecek",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Jetis",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Karang",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Gatak",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Dukuh",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Butuhan",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Delanggu",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Banaran",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Delanggu",
                "kelurahan": "Bowan",
                "kodepos": "57471"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Troketon",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Sobayan",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Tambakboyo",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Temuwangi",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Lemahireng",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Ngaren",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Kaligawe",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Keden",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Kedungan",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Jetis Wetan",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Kalangan",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Bendo",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Jatimulyo",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Pedan",
                "kelurahan": "Beji",
                "kodepos": "57468"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sumber",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Trucuk",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Wanglu",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Pundungsari",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sabrang Lor",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sajen",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Palar",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Planggu",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Puluhan",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Kradenan",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Mandong",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Mireng",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Kalikebo",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Karangpakel",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Gaden",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Jatipuro",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Tempursari",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Bero",
                "kodepos": "57467"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Ngawen",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Pepe",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Senden",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Manjungan",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Mayungan",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Kwaren",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Manjung",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Kahuman",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Gatak",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Drono",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Duwet",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Tegalrejo",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ngawen",
                "kelurahan": "Candi Rejo",
                "kodepos": "57466"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Pokak",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Srebegan",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Ngawonggo",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Pasungan",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Meger",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Mlese",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Kuncen",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Kurung",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Klepu",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Kujon",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Jombor",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Kajen",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Dlimas",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Jambu Kidul",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Jambu Kulon",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Cetan",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Ceper",
                "kelurahan": "Ceper",
                "kodepos": "57465"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Tumpukan",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Tegalampel",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Tulas",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Soka",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Tambak",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Ringinputih",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Sentono",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Ngolodono",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Pugeran",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Kupang",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Munggung",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Karangtalun",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Karangwungu",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Karangjoho",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Demangan",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Karangdowo",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Bakungan",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Bulusan",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Karangdowo",
                "kelurahan": "Babadan",
                "kodepos": "57464"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Tirtomarto",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Tlingsing",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Tugu",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Plosowangi",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Pogung",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Nanggulan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Pakisan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Karangasem",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Kedungampel",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Karangasem",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Gombang",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Japanan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Burikan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Cawas",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Bendungan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Bogor",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Barepan",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Bawak",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Balak",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Cawas",
                "kelurahan": "Baran",
                "kodepos": "57463"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Tegalrejo",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Wiro",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Talang",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Tawangrejo",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Paseban",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Ngerangan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Krikilan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Nengahan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Jotangan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Kebon",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Krakitan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Jambakan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Jarum",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Dukuh",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Gunung Gajah",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Bogem",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Beluk",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Trotok",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Bayat",
                "kelurahan": "Banyuripan",
                "kodepos": "57462"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Sembung",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Sukorejo",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Tanjungan",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Pasung",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Pesu",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Melikan",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Pacing",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Pandes",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Kaligayam",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Kalitengah",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Jiwo Wetan",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Kadibolo",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Kadilanggon",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Dengkeng",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Gadungan",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Birit",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Brangkal",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Wedi",
                "kelurahan": "Canan",
                "kodepos": "57461"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Sawit",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Towangsan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Ngandong",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Mutihan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Muruh",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Kerten",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Kragilan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Mlese",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Karangturi",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Katekan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Jabung",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Jogoprayan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Ceporan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Gentan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Gesikan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Gantiwarno",
                "kelurahan": "Baturan",
                "kodepos": "57455"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Sengon",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Taji",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Tlogo",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Pereng",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Randusari",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Sanggrahan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Kotesan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Kebon Dalem Lor",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Kemudo",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Kokosan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Geneng",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Joho",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Kebon Dalem Kidul",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Brajan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Bugisan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Prambanan",
                "kelurahan": "Cucukan",
                "kodepos": "57454"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Titang",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Wonoboyo",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Tangkisan Pos",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Sumyang",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Tambakan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Rejoso",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Somopuro",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Pakahan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Plawikan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Prawatan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Karangdukuh",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Kraguman",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Ngering",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Gondangan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Granting",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Joton",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Bakung",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Jogonalan",
                "kelurahan": "Dompyongan",
                "kodepos": "57452"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Tambongwetan",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Ngemplak",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Kalikotes",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Krajan",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Jimbung",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Jogosetran",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Karanganom",
                "kodepos": "57438"
            },
            {
                "kecamatan": "Kalikotes",
                "kelurahan": "Gemblegan",
                "kodepos": "57451"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Belang Wetan",
                "kodepos": "57438"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Ketandan",
                "kodepos": "57437"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Bareng Lor",
                "kodepos": "57438"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Gergunung",
                "kodepos": "57434"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Jonggrangan",
                "kodepos": "57435"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Jebugan",
                "kodepos": "57433"
            },
            {
                "kecamatan": "Klaten Utara",
                "kelurahan": "Sekarsuli",
                "kodepos": "57432"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Glodogan",
                "kodepos": "57426"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Kajoran",
                "kodepos": "57426"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Sumberejo",
                "kodepos": "57426"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Danguran",
                "kodepos": "57425"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Ngalas",
                "kodepos": "57425"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Karanglo",
                "kodepos": "57423"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Merbung",
                "kodepos": "57424"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Tegalyoso",
                "kodepos": "57424"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Gayamprit",
                "kodepos": "57423"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Nglinggi",
                "kodepos": "57422"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Trunuh",
                "kodepos": "57421"
            },
            {
                "kecamatan": "Klaten Selatan",
                "kelurahan": "Jetis",
                "kodepos": "57421"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Buntalan",
                "kodepos": "57419"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Gumulan",
                "kodepos": "57417"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Jomboran",
                "kodepos": "57418"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Mojayan",
                "kodepos": "57416"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Bareng",
                "kodepos": "57414"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Semangkak",
                "kodepos": "57415"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Tonggalan",
                "kodepos": "57412"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Kabupaten",
                "kodepos": "57413"
            },
            {
                "kecamatan": "Klaten Tengah",
                "kelurahan": "Klaten",
                "kodepos": "57411"
            }
        ],
        "k92": [
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Sambeng",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Pilangrejo",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Ngleses",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Ngaren",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Krobokan",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Kalimati",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Kayen",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Juwangi",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Cerme",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Juwangi",
                "kelurahan": "Jerukan",
                "kodepos": "57391"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Sumber Agung",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Tanjung",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Sangge",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Sendangrejo",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Klego",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Karanggatak",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Karangmojo",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Kalangan",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Jaten",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Gondanglegi",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Blumbang",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Bade",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Klego",
                "kelurahan": "Banyu Urip",
                "kodepos": "57385"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Semawung",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Sempu",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Senggrong",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Pranggong",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Pakel",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Pelemrejo",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Pakang",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Munggur",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Kunti",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Mojo",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Kedungdowo",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Kacangan",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Kadipaten",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Beji",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Gondang Rawe",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Andong",
                "kelurahan": "Andong",
                "kodepos": "57384"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Sarimulyo",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Watugede",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Wonoharjo (Wanoharjo)",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Klewor",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Lemahireng",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Kemusu",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Kendel",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Kedungrejo",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Kauman",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Kedungmulyo",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Guwo",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Genengsari",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Kemusu",
                "kelurahan": "Bawu",
                "kodepos": "57383"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Wonosegoro",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Repaking",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Ngablak",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Ketoyan",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Karangjati",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Kedungpilang",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Kalinanas",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Jatilawang",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Gunungsari",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Gilirejo",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Gosono",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Garangan",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Bolo",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Bojong",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Bercak",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Bengle",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Banyusri",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Wonosegoro",
                "kelurahan": "Bandung",
                "kodepos": "57382"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Sranten",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Sendang",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Sempulur",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Pinggir",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Pengkol",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Mojosari",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Klumpit",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Manyaran",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Kebonan",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Klari",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Karangkepoh",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Dologan",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Grogolan",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Bantengan",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Karanggede",
                "kelurahan": "Bangkok",
                "kodepos": "57381"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Tegalgiri",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Rembun",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Sembungan",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Pulutan",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Potronayan",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Pojok",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Keyongan",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Ketitang",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Jeron",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Kenteng",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Glonggong",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Guli",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Wates",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Nogosari",
                "kelurahan": "Bendo",
                "kodepos": "57378"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Teter",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Walen",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Sumber",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Talakbroto",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Temon",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Pentur",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Simo",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Kedung Lengkong",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Pelem",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Blagung",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Gunung",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Simo",
                "kelurahan": "Bendungan",
                "kodepos": "57377"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Tempursari",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Trosobo",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Senting",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Tawengan",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Ngaglik",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Nglembu",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Sambi",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Jagoan",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Jatisari",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Kepoh",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Demangan",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Glintang",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Canden",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Catur",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Cermo",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Sambi",
                "kelurahan": "Babadan",
                "kodepos": "57376"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Sindon",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Sobokerto",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Ngesrep",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Pandeyan",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Sawahan",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Ngargorejo",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Kismoyoso (Kesmoyoso)",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Manggung",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Giriroto",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Donohudan",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Gagaksipat",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Ngemplak",
                "kelurahan": "Dibal",
                "kodepos": "57375"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Manjung",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Tegalrejo",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Tlawong",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Kateguhan",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Kemasan",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Jenengan",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Karangduren",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Gombang",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Guwokajen",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Jatirejo",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Cepokosawit",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Sawit",
                "kelurahan": "Bendosari",
                "kodepos": "57374"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Sambon",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Tanjungsari",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Trayu",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Ngaru Aru",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Kuwiran",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Jembungan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Jipangan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Ketaon",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Denggungan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Dukuh",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Bendan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Cangkringan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Banyudono",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Batan",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Bangak",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Banyudono",
                "kelurahan": "Bangak",
                "kodepos": "57373"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Sudimoro",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Tawangsari",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Randusari",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Salakan",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Mojolegi",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Nepen",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Kopen",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Krasak",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Gumukrejo",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Kadireso",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Doplang",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Teras",
                "kelurahan": "Bangsalan",
                "kodepos": "57372"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Tarubatang",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Tlogolele",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Selo",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Senden",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Suroteleng",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Samiran",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Lencoh",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Jrakah",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Klakah",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Wonodoyo",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Selo",
                "kelurahan": "Jeruk",
                "kodepos": "57363"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Sukabumi",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Sumbung",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Mliwis",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Paras",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Jombong",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Kembang Kuning",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Jelok",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Gedangan",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Genting",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Gubug",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Candigatak",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Cepogo",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Bakulan",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Cepogo",
                "kelurahan": "Cabean Kunti",
                "kodepos": "57362"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Tanduk",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Urutsewu",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Seboto",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Selodoko",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngenden",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Sampetan",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngargosari",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngampon",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngargoloko",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngadirojo",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Ngagrong",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Kaligentong",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Kembang",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Jlarem",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Gladagsari",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Gondang Slamet",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Candi",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Candisari",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Banyuanyar",
                "kodepos": "57352"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Sumur",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Sruni",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Sukorame",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Sukorejo",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Ringin Larik",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Sangup",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Pagerjurang",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Pusporenggo",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Mriyan",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Musuk",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Keposong",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Lampar",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Lanjaran",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Kebongulo",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Kembangsari",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Karanganyar",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Karang Kendal",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Dragan",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Jemowo",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Kragilan",
                "kodepos": "57323"
            },
            {
                "kecamatan": "Musuk",
                "kelurahan": "Cluntang",
                "kodepos": "57331"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Singosari",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Tambak",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Metuk",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Mojosongo",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Madu",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Manggis",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Karangnongko",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Butuh",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Dlingo",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Jurug",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Brajan",
                "kodepos": "57322"
            },
            {
                "kecamatan": "Mojosongo",
                "kelurahan": "Kemiri",
                "kodepos": "57321"
            },
            {
                "kecamatan": "Ampel",
                "kelurahan": "Sidomulyo",
                "kodepos": "57316"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Mudal",
                "kodepos": "57316"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Penggung",
                "kodepos": "57316"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Pulisen",
                "kodepos": "57316"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Kebonbimo",
                "kodepos": "57316"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Kiringan",
                "kodepos": "57314"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Winong",
                "kodepos": "57315"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Karanggeneng",
                "kodepos": "57312"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Banaran",
                "kodepos": "57313"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Siswodipuran",
                "kodepos": "57311"
            },
            {
                "kecamatan": "Boyolali",
                "kelurahan": "Siswodipuran",
                "kodepos": "57311"
            }
        ],
        "k93": [
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Sukorejo",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Musuk",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Sambi",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Sambirejo",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Jetis",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Kadipiro",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Dawung",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Jambeyan",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Wonorejo",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Sambirejo",
                "kelurahan": "Blimbing",
                "kodepos": "57293"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Mojokerto",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Pengkok",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Wonokerso",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Kedawung",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Mojodoyong",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Karang Pelem",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Celep",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Jenggrik",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Kedawung",
                "kelurahan": "Bendungan",
                "kodepos": "57292"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Sidokerto",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Somomoro Dukuh",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Plupuh",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Pungsari",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Sambirejo",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Manyarejo",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Ngrombo",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Karungan",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Karangwaru",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Karang Anyar",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Gentan Banaran",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Jabung",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Jembangan",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Dari",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Gedongan",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Sidodadi",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Plupuh",
                "kelurahan": "Cangkol",
                "kodepos": "57283"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Pringanom",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Sepat",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Masaran",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Pilang",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Krebet",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Krikilan",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Karang Malang",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Kliwonan",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Gebang",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Jati",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Jirapan",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Tenggak",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Masaran",
                "kelurahan": "Dawungan",
                "kodepos": "57282"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Sribit",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Taraman",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Sidoharjo",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Singopadu",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Patihan",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Purwosuman",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Jambanan",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Jetak",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Pandak",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Bentak",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Sidoharjo",
                "kelurahan": "Duyungan",
                "kodepos": "57281"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Suwatu",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Tanon",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Slogo",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Sambi Duwur",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Pengkol",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Ketro",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Padas",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Karang Talun",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Karangasem",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Kecik",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Jono",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Kalikobok",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Gading",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Gawan",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Bonagung",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Tanon",
                "kelurahan": "Gabugan",
                "kodepos": "57277"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Soko",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Sunggingan",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Girimargo",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Girimargo",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Gilirejo",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Gilirejo Baru",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Brojol",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Doyong",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Geneng",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Miri",
                "kelurahan": "Bagor",
                "kodepos": "57276"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Trobayan",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Wonorejo",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Saren",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Tegalombo",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Krikilan",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Ngebung",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Samberembe",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Keden",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Karangjati",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Jetis Karangpung",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Kalimacan",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Bukuran",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Donoyudan",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Tegaldowo",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Kalijambe",
                "kelurahan": "Banaran",
                "kodepos": "57275"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Peleman",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Purworejo",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Nganti",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Ngembat Padas",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Kaloran",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Kragilan",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Kwangen",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Kalangan",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Jatibatur",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Jenalas",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Gemolong",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Geneng Duwur",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Gemolong",
                "kelurahan": "Brangkal",
                "kodepos": "57274"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Pendem",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Tlogo Tirto",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Ngargotirto",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Pagak",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Mojopuro",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Ngandul",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Ngargosari",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Hadiluwih",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Jati",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Kacangan",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Trombol",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Sumberlawang",
                "kelurahan": "Cepoko",
                "kodepos": "57272"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Tempelrejo",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Sumberejo",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Pare",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Sono",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Jekani",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Kedawung",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Gemantar",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Mondokan",
                "kelurahan": "Jambangan",
                "kodepos": "57271"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Pantirejo",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Majenang",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Newung",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Jati Tengah",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Juwok",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Karang Anom",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Bendo",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Gebang",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Baleharjo",
                "kodepos": "57263"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Tanggan",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Srawung",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Pilangsari",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Poleng",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Slendro",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Blangu",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Gesi",
                "kelurahan": "Gesi",
                "kodepos": "57262"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Sigit",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Katelan",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Ngrombo",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Jekawal",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Galeh",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Denanyar",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Tangen",
                "kelurahan": "Dukuh",
                "kodepos": "57261"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Ngepringan",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Mlale",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Mlale",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Jenar",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Japoh",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Banyuurip",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Jenar",
                "kelurahan": "Dawung",
                "kodepos": "57256"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Tunggul",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Wonotolo",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Srimulyo",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Tegalrejo",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kaliwedi",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Plosorejo",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Glonggong",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondang",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Bumiaji",
                "kodepos": "57254"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Sambungmacan",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Toyogo",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Plumbon",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Gringging",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Karanganyar",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Banyuurip",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Bedoro",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Cemeng",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Sambung Macan",
                "kelurahan": "Banaran",
                "kodepos": "57253"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Pilangsari",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Ngarum",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Kebonromo",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Klandungan",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Gabus",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Karangudi",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Bener",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Ngrampal",
                "kelurahan": "Bandung",
                "kodepos": "57252"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Saradan",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Puro",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Plumbungan",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Plosokerep",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Pelem Gadung",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Mojorejo",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Kedung Waduk",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Guworejo",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Jurangjero",
                "kodepos": "57222"
            },
            {
                "kecamatan": "Karangmalang",
                "kelurahan": "Kroyo",
                "kodepos": "57221"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Karang Tengah",
                "kodepos": "57216"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Sragen Wetan",
                "kodepos": "57214"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Nglorog",
                "kodepos": "57215"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Sine",
                "kodepos": "57213"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Sragen Kulon",
                "kodepos": "57212"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Tangkil",
                "kodepos": "57211"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Sragen Tengah",
                "kodepos": "57211"
            },
            {
                "kecamatan": "Sragen",
                "kelurahan": "Kedungupit",
                "kodepos": "57211"
            }
        ],
        "k94": [
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Sangkrah",
                "kodepos": "57199"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Semanggi",
                "kodepos": "57191"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Jagalan",
                "kodepos": "57162"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Joyotakan",
                "kodepos": "57157"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Serengan",
                "kodepos": "57155"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Danukusuman",
                "kodepos": "57156"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Gajahan",
                "kodepos": "57155"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Tipes",
                "kodepos": "57154"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Jayengan",
                "kodepos": "57152"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Kratonan",
                "kodepos": "57153"
            },
            {
                "kecamatan": "Serengan",
                "kelurahan": "Kemlayan",
                "kodepos": "57151"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Bumi",
                "kodepos": "57149"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Panularan",
                "kodepos": "57149"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Sondakan",
                "kodepos": "57147"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Laweyan",
                "kodepos": "57148"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Pajang",
                "kodepos": "57146"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Karangasem",
                "kodepos": "57145"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Jajar",
                "kodepos": "57144"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Kerten",
                "kodepos": "57143"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Baluwarti",
                "kodepos": "57144"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Purwosari",
                "kodepos": "57142"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Penumping",
                "kodepos": "57141"
            },
            {
                "kecamatan": "Laweyan",
                "kelurahan": "Sriwedari",
                "kodepos": "57141"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Setabelan",
                "kodepos": "57139"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Manahan",
                "kodepos": "57139"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Mangkubumen",
                "kodepos": "57139"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Banyuanyar",
                "kodepos": "57137"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Sumber",
                "kodepos": "57138"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kadipiro",
                "kodepos": "57136"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Nusukan",
                "kodepos": "57135"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Kestalan",
                "kodepos": "57133"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Gilingan",
                "kodepos": "57134"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Kedung Lumbu",
                "kodepos": "57133"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Punggawan",
                "kodepos": "57132"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Kampung Baru",
                "kodepos": "57133"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Keprabon",
                "kodepos": "57131"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Timuran",
                "kodepos": "57131"
            },
            {
                "kecamatan": "Banjarsari",
                "kelurahan": "Ketelan",
                "kodepos": "57132"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Sewu",
                "kodepos": "57129"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Kepatihan Kulon",
                "kodepos": "57129"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Kepatihan Wetan",
                "kodepos": "57129"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Tegalharjo",
                "kodepos": "57128"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Mojosongo",
                "kodepos": "57127"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Purwodiningratan",
                "kodepos": "57128"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Kauman",
                "kodepos": "57122"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Pucangsawit",
                "kodepos": "57125"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Jebres",
                "kodepos": "57126"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Pasar Kliwon",
                "kodepos": "57118"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Sudiroprajan",
                "kodepos": "57121"
            },
            {
                "kecamatan": "Jebres",
                "kelurahan": "Gandekan",
                "kodepos": "57122"
            },
            {
                "kecamatan": "Pasar Kliwon",
                "kelurahan": "Joyosuran",
                "kodepos": "57116"
            }
        ],
        "k95": [
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Wringinputih",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Tuksongo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Wanurejo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Sambeng",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Sambeng",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Majaksingi",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Ngadiharjo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Ngargogondo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Kembanglimus",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Kenalan",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Karangrejo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Kebonsari",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Giritengah",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Karanganyar",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Candirejo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Giripurno",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Bumiharjo",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Bigaran",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Borobudur",
                "kodepos": "56553"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Rambeanak",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Senden",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Treko",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Paremono",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Progowati",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Pabelan",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Pagersari",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Mungkid",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Ngrajek",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Gondang",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Mendut",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Blondo",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Bojong",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Bumirejo",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Sawitan",
                "kodepos": "56511"
            },
            {
                "kecamatan": "Mungkid",
                "kelurahan": "Ambartawang",
                "kodepos": "56512"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Somokaton",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Pakunden",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Plosogede",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Karangtalun",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Ngluwar",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Blongkeng",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Jamuskauman",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Ngluwar",
                "kelurahan": "Bligo",
                "kodepos": "56485"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Sucen",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Tersangede",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Tirto",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Sirahan",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Somoketro",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Salam",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Seloboro",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Kadiluwih",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Mantingan",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Gulon",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Jumoyo",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Salam",
                "kelurahan": "Baturono",
                "kodepos": "56484"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Tegalrandu",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Srumbung",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Sudimoro",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Pandanretno",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Polengan",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Pucanganom",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Nglumut",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Ngargosoko",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Mranggen",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Ngablak",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Kemiren",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Kradenan",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Kaliurang",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Kamongan",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Bringin",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Jerukagung",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Srumbung",
                "kelurahan": "Banyuadem",
                "kodepos": "56483"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sumber",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Wates",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sewukan",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sengi",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Ngargomulyo",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Paten",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Mangunsoko",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Ngadipuro",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Krinjing",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Keningar",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Ketunggeng",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Banyudono",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Dukun",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Kalibening",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Banyubiru",
                "kodepos": "56482"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Tirtosari",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Wulung Gunung",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Sawangan",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Soronalan",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Krogowanan",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Mangunsari",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Podosoko",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Ketep",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Kapuhan",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Gondowangi",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Jati",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Gantang",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Butuh",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Banyuroto",
                "kodepos": "56481"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Sokorini",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Sriwedari",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Tanjung",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Ngawen",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Keji",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Menayu",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Gondosuli",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Gunungpring",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Adikarto",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Congkrang",
                "kodepos": "56415"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Pandansari",
                "kodepos": "56414"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Pucungrejo",
                "kodepos": "56414"
            },
            {
                "kecamatan": "Borobudur",
                "kelurahan": "Tegalarum",
                "kodepos": "56413"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Tamanagung",
                "kodepos": "56413"
            },
            {
                "kecamatan": "Sawangan",
                "kelurahan": "Wonolelo",
                "kodepos": "56411"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Sedayu",
                "kodepos": "56412"
            },
            {
                "kecamatan": "Muntilan",
                "kelurahan": "Muntilan",
                "kodepos": "56411"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Balerejo",
                "kodepos": "56411"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sumurarum",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tirto",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tlogorejo",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sugihmas",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Seworan",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sidogede",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Pucungsari",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Salam",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sambungrejo",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Pesidi",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Ngasinan",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Ngrancah",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Losari",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Lebak",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kleteran",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Klegen",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Ketawang",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kalipucang",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kartoharjo",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Grabag",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kalikuto",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Giriwetan",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Cokro",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Citrosono",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Banyusari",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Banaran",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Banjarsari",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Baleagung",
                "kodepos": "56196"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Secang",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Sidomulyo",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Purwosari",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Pucang",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Pirikan",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Payaman",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Pancuranmas",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Ngadirojo",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Ngabean",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Madiocondro",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Madusari",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Krincing",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Karangkajen",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Kalijoso",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Jambewangi",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Girikulon",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Donorejo",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Candisari",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Donomulyo",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Secang",
                "kelurahan": "Candiretno",
                "kodepos": "56195"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Tejosari",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Sumberejo",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Seloprojo",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Selomirah",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Pandean",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Pagergunung",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Ngablak",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Magersari",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Madyogondo",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Keditan",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Kanigoro",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Jogonayan",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Jogoyasan",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Genikan",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Girirejo",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Rejosari",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Ngablak",
                "kelurahan": "Bandungrejo",
                "kodepos": "56194"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Petung",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Pogalan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Pakis",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Muneng",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Muneng Warangan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Kragilan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Losari",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Ketundan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Kenalan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Jambewangi",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Kajangkoso",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Kaponan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Gondangsari",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Gumelem",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Daleman Kidul",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Daseh",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Gejagan",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Banyusidi",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Bawang",
                "kodepos": "56193"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Wonokerto",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Sukorejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Tampingan",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Tegalrejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Soroyudan",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Sidorejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Purwodadi",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Purwosari",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Mangunrejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Ngadirejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Ngasem",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Kebonagung",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Klopo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Glagahombo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Japan",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Donorojo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Girirejo",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Dawung",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Dlimas",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Banyusari",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Tegalrejo",
                "kelurahan": "Banyuurip",
                "kodepos": "56192"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tempursari",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Trenten",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tempak",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tembelang",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tampirwetan",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tegalsari",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Tampirkulon",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Surodadi",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Surojoyo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Sidomulyo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Sonorejo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Podosoko",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Purworejo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Mejing",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Kebonrejo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Kembaran",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Candimulyo",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Giyanti",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Sumberrejo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Candimulyo",
                "kelurahan": "Bateh",
                "kodepos": "56191"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Sukorejo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Mertoyudan",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Pasuruhan",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Donorojo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Jogonegoro",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Kalinegoro",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Deyangan",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Bulurejo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Danurejo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Banyurojo",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Bondowoso",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Mertoyudan",
                "kelurahan": "Banjarnegoro",
                "kodepos": "56172"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Wuwuharjo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Wadas",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Wonogiri",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sukomulyo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sukorejo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sutopati",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sukomakmur",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sidorejo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sidowangi",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Pucungroto",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sambak",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Sangen",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Ngendrosari",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Pandanretno",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Mangunrejo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Ngargosari",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Madugondo",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Madukoro",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Kwaderan",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Lesanpuro",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Kajoran",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Krinjing",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Krumpakan",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Banjaragung",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Banjaretno",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Bumiayu",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Bambusari",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Kajoran",
                "kelurahan": "Bangsri",
                "kodepos": "56163"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Sriwedari",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Tanjunganom",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Sawangargo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Sidomulyo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Paripurno",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Purwosari",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Salaman",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Ngadirejo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Ngampeldento",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Ngargoretno",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Menoreh",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Margoyoso",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Kebonrejo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Krasak",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Kalirejo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Kalisalak",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Banjarharjo",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Jebengsari",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Kaliabu",
                "kodepos": "56162"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Temanggal",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Tempurejo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Tugurejo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Sidoagung",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Sumberarum",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Tanggulrejo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Pringombo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Ringinanom",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Kemutuk",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Prajeksari",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Kalisari",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Growong",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Jogomulyo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Bawang",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Tempuran",
                "kelurahan": "Girirejo",
                "kodepos": "56161"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Temanggung",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Pengarengan",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Selomoyo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Ngawonggo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Ngendrokilo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Munggangsari",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Ngargosoko",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Maduretno",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Mangli",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Ketangi",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Kebonlegi",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Girirejo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Giriwarno",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Kaliangkrik",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Beseran",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Bumirejo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Balekerto",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Banjarejo",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Kaliangkrik",
                "kelurahan": "Adipuro",
                "kodepos": "56153"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Wonoroto",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Umbulsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Windusari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Semen",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Tanjungsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Ngemplak",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Pasangsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Kentengsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Mangunsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Gunungsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Kalijoso",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Kembangkuning",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Girimulyo",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Gondangrejo",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Candisari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Dampit",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Genito",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Bandarsedayu",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Banjarsari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Windusari",
                "kelurahan": "Balesari",
                "kodepos": "56152"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Sukosari",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Tonoboyo",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Trasan",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Sidorejo",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Sukodadi",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Rejosari",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Salamkanci",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Ngepanrejo",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Kebonagung",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Kedungsari",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Gandusari",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Kalegen",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Bandongan",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Bandongan",
                "kelurahan": "Banyuwangi",
                "kodepos": "56151"
            },
            {
                "kecamatan": "Salaman",
                "kelurahan": "Sidosari",
                "kodepos": "56126"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Rejowinangun Utara",
                "kodepos": "56127"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Tidar Utara",
                "kodepos": "56125"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Magersari",
                "kodepos": "56126"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Rejowinangun Selatan",
                "kodepos": "56124"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Tidar Selatan",
                "kodepos": "56125"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Jurangombo Selatan",
                "kodepos": "56123"
            },
            {
                "kecamatan": "Magelang Selatan",
                "kelurahan": "Jurangombo Utara",
                "kodepos": "56123"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Kemirirejo",
                "kodepos": "56122"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Magelang",
                "kodepos": "56117"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Cacaban",
                "kodepos": "56121"
            },
            {
                "kecamatan": "Magelang Utara",
                "kelurahan": "Potrobangsan",
                "kodepos": "56116"
            },
            {
                "kecamatan": "Magelang Utara",
                "kelurahan": "Kramat Utara",
                "kodepos": "56115"
            },
            {
                "kecamatan": "Magelang Utara",
                "kelurahan": "Kedungsari",
                "kodepos": "56114"
            },
            {
                "kecamatan": "Magelang Utara",
                "kelurahan": "Kramat Selatan",
                "kodepos": "56115"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Gelangan",
                "kodepos": "56112"
            },
            {
                "kecamatan": "Magelang Utara",
                "kelurahan": "Wates",
                "kodepos": "56113"
            },
            {
                "kecamatan": "Magelang Tengah",
                "kelurahan": "Panjang",
                "kodepos": "56111"
            }
        ],
        "k96": [
            {
                "kecamatan": "Kepil",
                "kelurahan": "Teges Wetan",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Warangan",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Tanjunganom",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Tegalgot",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Rejosari",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Rejosari",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Ropoh",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Ngalian",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Pulosaren",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Kapulogo",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Kepil",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Kaliwuluh",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Kalipuru",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Jangkrikan",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Kagungan",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Gadingsukuh",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Gondowulan",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Burat",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Gadingrejo",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Bener",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kepil",
                "kelurahan": "Beran",
                "kodepos": "56374"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Tempurejo",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Tempursari",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Surojoyo",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Talunombo",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Tempuranduwur",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Sapuran",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Sedayu",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Pengarengan",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Pecekelan",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Rimpak",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Ngadikerso",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Ngadisalam",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Marongsari",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Mergolangu",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Kalikarung",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Karangsari",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Karangsambung",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Glagah",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Kalialang",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Jolontoro",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Dempel",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalibawang",
                "kelurahan": "Depok",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Batursari",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Bogoran",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Sapuran",
                "kelurahan": "Banyumudal",
                "kodepos": "56373"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Tegalombo",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Wonosari",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Purwojiwo",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Rejosari",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Simbang",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Mungkung",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Perboto",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Lamuk",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Maduretno",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Mangunrejo",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Kembaran",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Kwadungan",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Karangduwur",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Kedalon",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Kalikajar",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Kalikuning",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Bowongso",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Butuh",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kalikajar",
                "kelurahan": "Butuh Kidul",
                "kodepos": "56372"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Tlogomulyo",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Wringinanom",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Sumberdalem",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Surengede",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Tlogodalem",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Sudungdewo",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Reco",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Sindupaten",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Purbosono",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Purwojati",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Ngadikusuman",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Pagerejo",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Kapencar",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Karangluhur",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Kertek",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Candimulyo",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Candiyasan",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Damarkasian",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Bejiarum",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Bojasari",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Wadas Lintang",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Kertek",
                "kelurahan": "Banjar",
                "kodepos": "56371"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Tirip",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Trimulyo",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Sumberejo",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Sumbersari",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Somogede",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Panerusan",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Plunjaran",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Lancar",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Ngalian",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Kaligowong",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Karang Anyar",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Kumejing",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Gumelar",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Kalidadap",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Besuki",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Wadaslintang",
                "kelurahan": "Erorejo",
                "kodepos": "56365"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Tracap",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Winongsari",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Sukoreno",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Tanjunganom",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Pucungkerep",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Purwosari",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Selomanik",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Ngadisono",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Ngasinan",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Pesodongan",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Lebak",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Medono",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Lamuk",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Kauman",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Kemiriombo",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Kaliwiro",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Gambaran",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Grugu",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Kaliguwo",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Bendungan",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Kaliwiro",
                "kelurahan": "Cledok",
                "kodepos": "56364"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Wonokerto",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Tlogo",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Timbang",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Suroyudan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Sojokerto",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sempol",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Sawangan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Selokromo",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Pulus",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Rogojati",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Plodongan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Pacarmulyo",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Pucung Wetan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Mergosari",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Manggis",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Leksono",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Lipursari",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Kalimendong",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Karanganyar",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Kupangan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Kalibening",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Jlamprang",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Kajeksan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Jonggolsari",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Gunungtugel",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Jebengplampitan",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Garunglor",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Gumiwang",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Besani",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Leksono",
                "kelurahan": "Durensawit",
                "kodepos": "56362"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Wilayu",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Wonorejo",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Wulungsari",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Sumberwulan",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Tumenggungan",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Simbarejo",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Sinduagung",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Semayu",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Sidorejo",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Plobangan",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Selomerto",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Pakuncen",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Ngadimulyo",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Krasak",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Karangrejo",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Kecis",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Kaliputih",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Kalierang",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Kadipaten",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Gunungtawang",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Candi",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Bumitirto",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Adiwarno",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Selomerto",
                "kelurahan": "Balekambang",
                "kodepos": "56361"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Tieng",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Tambi",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Sikunang",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Surengede",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Sigedang",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Serang",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Sembungan",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Patakbanteng",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Kreo",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Parikesit",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Kejajar",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Jojogan",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Dieng",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Igirmranak",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Buntu",
                "kodepos": "56354"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Tlogo",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Siwuran",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Tegalsari",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Sitiharjo",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Sendangsari",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Mlandi",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Menjer",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Maron",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Lengkong",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Kuripan",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Larangan Lor",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Kayugiyang",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Jengkol",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Garung",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Garung",
                "kelurahan": "Gemblengan",
                "kodepos": "56353"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Wonosroyo",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Wonoroto",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Wonokampir",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Pasuruhan",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Watumalang",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Limbangan",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Lumajang",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Mutisari",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Krinjing",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Kuripan",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Gumawang Kidul",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Kalidesel",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Binangun",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Bumiroso",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Gondang",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Watumalang",
                "kelurahan": "Banyukembar",
                "kodepos": "56352"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Sukorejo",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Wonokromo",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Slukatan",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Sojopuro",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Mudal",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Pungangan",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Larangan Kulon",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Mojosari",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Krasak",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Kebrengan",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Keseneng",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Guntur Madu",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Kalibeber",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Deroduwur",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Derongisor",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Bumirejo",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Candirejo",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Andongsili",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Mojotengah",
                "kelurahan": "Blederan",
                "kodepos": "56351"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Wonosari",
                "kodepos": "56318"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Jlamprang",
                "kodepos": "56319"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kalianget",
                "kodepos": "56319"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Wonosobo Timur",
                "kodepos": "56317"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Bomerto",
                "kodepos": "56318"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Tlogojati",
                "kodepos": "56317"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Rojoimo",
                "kodepos": "56316"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Bumireso",
                "kodepos": "56317"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sariyoso",
                "kodepos": "56317"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kramatan",
                "kodepos": "56315"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Pancurwening",
                "kodepos": "56316"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Jaraksari",
                "kodepos": "56314"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Pagerkukuh",
                "kodepos": "56314"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Jogoyitnan",
                "kodepos": "56315"
            },
            {
                "kecamatan": "Kejajar",
                "kelurahan": "Campursari",
                "kodepos": "56314"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Wonolelo",
                "kodepos": "56313"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sambek",
                "kodepos": "56312"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Tawangsari",
                "kodepos": "56313"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Wonosobo Barat",
                "kodepos": "56311"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Mlipak",
                "kodepos": "56312"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kejiwan",
                "kodepos": "56311"
            }
        ],
        "k97": [
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Sucen",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Muncar",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Ngadisepi",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Kemiriombo",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Krempong",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Karangseneng",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Gemawang",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Jambon",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Kalibanger",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Tlogowungu",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Gemawang",
                "kelurahan": "Banaran",
                "kodepos": "56283"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Tepusen",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Tleter",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Tegowanuh",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Tempuran",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Kemiri",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Kwarakan",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Kaloran",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Keblukan",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Geblog",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Getas",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Kalimanggis",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Gandon",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kaloran",
                "kelurahan": "Gandulan",
                "kodepos": "56282"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Tlogopucang",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Wadas",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Rowo",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Samiranan",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Ngemplak",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kembangsari",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Malebo",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Margolelo",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kedawung",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kedungumpul",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Gesing",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kandangan",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Caruban",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Banjarsari",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Blimbing",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Baledu",
                "kodepos": "56281"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Soborejo",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Soropadan",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Wonokerso",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Pringsurat",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Rejosari",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Pagergunung",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Pingit",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Nglorog",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Klepu",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Kupen",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Ngipik",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Karangwuni",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Kebumen",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Pringsurat",
                "kelurahan": "Gowak",
                "kodepos": "56272"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Sanggrahan",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Purwosari",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Pendowo",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Nguwet",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Pare",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Kranggan",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Ngropoh",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Klepu",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Kramat",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Bengkal",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Gentan",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Kemloko",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Kranggan",
                "kelurahan": "Badran",
                "kodepos": "56271"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Wonoboyo",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Wonocoyo",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Tawangsari",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Tening",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Wates",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Rejosari",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Semen",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Purwosari",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Pateken",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Pesantren",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Pitrosari",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Cemoro",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Wonoboyo",
                "kelurahan": "Kebonsari",
                "kodepos": "56266"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Tanurejo",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Tlogowero",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Purborejo",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Rejosari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Mranggen Kidul",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Mranggen Tengah",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Gunungsari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Mojosari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Campuranom",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Candisari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Gentingsari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Balesari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Bansari",
                "kelurahan": "Bansari",
                "kodepos": "56265"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Tuksari",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Paponan",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Petarangan",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Tlahap",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Kwadungan Gunung",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Kwadungan Jurang",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Kledung",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Kruwisan",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Jeketro",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Kalirejo",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Batursari",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Canggal",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Kledung",
                "kelurahan": "Jambu",
                "kodepos": "56264"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Tlilir",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Tlogomulyo",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Tanjungsari",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Sriwungu",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Legoksari",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Losari",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Pagersari",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Kerokan",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Langgeng",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Balerejo",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Candisari",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Tlogomulyo",
                "kelurahan": "Gedegan",
                "kodepos": "56263"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Selopampang",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Tanggulanom",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Plumbon",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Salamrejo",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Ngaditirto",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Jetis",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Kebonagung",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Kecepit",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Bumiayu",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Gambasan",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Bagusan",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Selopampang",
                "kelurahan": "Bulan",
                "kodepos": "56262"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Tawangsari",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Tembarak",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Wonokerso",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Purwodadi",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Krajan",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Menggoro",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Jragan",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Kemloko",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Gandu",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Greges",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Botoputih",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Drono",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Tretep",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tembarak",
                "kelurahan": "Banaran",
                "kodepos": "56261"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Tempelsari",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Tlogo",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Simpar",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Sigedong",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Donorojo",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Nglarangan",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Bojong",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Bonjor",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Campurejo",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Tretep",
                "kelurahan": "Bendungan",
                "kodepos": "56259"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Prangkokan",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Selosabrang",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Tanjung Sari",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Petung",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Larangan Luwok",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Lowungu",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Ngaliyan",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Kemuning",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Kebondalem",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Congkrang",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Duren",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Jlegong",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Banjarsari",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Bejen",
                "kelurahan": "Bejen",
                "kodepos": "56258"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Plosogaden",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Sidoharjo",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Muneng",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Muntung",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Ngabean",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Lempuyang",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Mento",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Kentengsari",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Krawitan",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Gunungpayung",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Candiroto",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Canggal",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Batursari",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Sukomarto",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Candiroto",
                "kelurahan": "Bantir",
                "kodepos": "56257"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Ketitang",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Morobongo",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Padureso",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Karangtejo",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Kertosari",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Jumo",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Jamusan",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Jombor",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Giyono",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Gunung Gempol",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Barang",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Jumo",
                "kelurahan": "Gedongsari",
                "kodepos": "56256"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Purbosari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Tegalrejo",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Ngaren",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Petirejo",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Pringapus",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Munggangsari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Ngadirejo",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Manggong",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Mangunsari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Medari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Katekan",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Gondangwinangun",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Karanggedong",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Kataan",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Gejagan",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Giripurno",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Campursari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Dlimoyo",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Gandu Wetan",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Ngadirejo",
                "kelurahan": "Banjarsari",
                "kodepos": "56255"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Traji",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Wanutengah",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Watukumpul",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Tegalroso",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Parakan Wetan",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Ringinanom",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Sunggingsari",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Nglondong",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Parakan Kauman",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Depokharjo",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Glapansari",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Mandisari",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Caturanom",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Dangkel",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Bagusan",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Parakan",
                "kelurahan": "Campursalam",
                "kodepos": "56254"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Wonotirto",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Tegalrejo",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Wonosari",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Tegallurung",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pengilon",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Putat",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pakurejo",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pandemulyo",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pasuruhan",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Ngimbrang",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Pagergunung",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Gondosuli",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Malangsari",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Mondoretno",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Danupayan",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Gandurejo",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Bansari",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Bulu",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Bulu",
                "kelurahan": "Campursari",
                "kodepos": "56253"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Tegalsari",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Mojotengah",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Ngadimulyo",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Salamsari",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Kutoanyar",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Mergowati",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Karangtejo",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Kedu",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Kundisari",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Danurejo",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Gondangwayang",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Candimulya",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Bandunggede",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Kedu",
                "kelurahan": "Bojonegoro",
                "kodepos": "56252"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Tlogorejo",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Walitelon Selatan",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Walitelon Utara",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Madureso",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Nampirejo",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Guntur",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Joho",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Lungge",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Mudal",
                "kodepos": "56228"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Gilingsari",
                "kodepos": "56229"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Giyanti",
                "kodepos": "56226"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Purworejo",
                "kodepos": "56227"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Manding",
                "kodepos": "56224"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Mungseng",
                "kodepos": "56225"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Jurang",
                "kodepos": "56222"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Kebonsari",
                "kodepos": "56223"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Sidorejo",
                "kodepos": "56221"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Kertosari",
                "kodepos": "56217"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Kowangan",
                "kodepos": "56218"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Jampirejo",
                "kodepos": "56215"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Jampiroso",
                "kodepos": "56216"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Temanggung II",
                "kodepos": "56213"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Butuh",
                "kodepos": "56214"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Banyuurip",
                "kodepos": "56211"
            },
            {
                "kecamatan": "Temanggung",
                "kelurahan": "Temanggung I",
                "kodepos": "56212"
            }
        ],
        "k98": [
            {
                "kecamatan": "Buayan",
                "kelurahan": "Wonodadi",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Sikayu",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Tugu",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Rogodono",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Semampir",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Purbowangi",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Rangkah",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Rogodadi",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Mergosono",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Nogoraji",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Pakuran",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Karangbolong",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Karangsari",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Jogomulyo",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Jatiroto",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Jladri",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Buayan",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Geblug",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Watukelir",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Adiwarno",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Buayan",
                "kelurahan": "Banyumudal",
                "kodepos": "54474"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Srati",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Tlogosari",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Kedungweru",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Mangunweni",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Pasir",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Kalipoh",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Karangduwur",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Jintung",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Kalibangkang",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Demangsari",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Jatijajar",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Candirenggo",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Banjararjo",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Bulurejo",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Argosari",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Ayah",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Wonoharjo",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Ayah",
                "kelurahan": "Argopeni",
                "kodepos": "54473"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Rowokele",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Sukomulyo",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Wagirpandan",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Pringtutul",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Redisari",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Kalisari",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Kretek",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Giyanti",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Jatiluhur",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Tunjungseto",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Rowokele",
                "kelurahan": "Bumiagung",
                "kodepos": "54472"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Semali",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Sempor",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Sidoharum",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Somagede",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Pekuncen",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Sampang",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Selokerto",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Kedungwringin",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Kenteng",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Jatinegoro",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Kalibeji",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Kedungjati",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Bonosari",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Donorojo",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Wonosigro",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Sempor",
                "kelurahan": "Bejiruyung",
                "kodepos": "54421"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Panjangsari",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Patemon",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Sidayu",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Wero",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Kemukus",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Klopogodo",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Banjarsari",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Kalitengah",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Kedungpuji",
                "kodepos": "54416"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Semondo",
                "kodepos": "54413"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Semanding",
                "kodepos": "54414"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Gombong",
                "kodepos": "54411"
            },
            {
                "kecamatan": "Gombong",
                "kelurahan": "Wonokriyo",
                "kodepos": "54412"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Wiromartan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Wergonayan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Winong",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Wirogaten",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Tlogorejo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Tlogopragoto",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Sitibentar",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Tlogodepok",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Sirnoboyo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Singoyudan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Sarwogadung",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Selotumpeng",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Rowosari",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Rowo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Pujodadi",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Pekutan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Patukrejomulyo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Patukrejo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Patukgawemulyo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Ngasinan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Miritpetikusan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Ngabeyan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Mrentul",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Mangunranan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Mirit",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Lembupurwo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Krubungan",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Kertodeso",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Mirit",
                "kelurahan": "Karanggede",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Bonorowo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Bonjoklor",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Bonjokkidul",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Bonorowo",
                "kelurahan": "Balorejo",
                "kodepos": "54395"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Tunggalroso",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Tersobo",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Sidototo",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Sidogede",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Sendangdalem",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Sembirkadipaten",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Rahayu",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Prembun",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Pesuningan",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Pejengkolan",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Pecarikan",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Padureso",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Mulyosri",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Merden",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Kedungwaru",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Kedungbulus",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Kaligubuk",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Kalijering",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Kabuaran",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Kabekelan",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Padureso",
                "kelurahan": "Balingasal",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Prembun",
                "kelurahan": "Bagung",
                "kodepos": "54394"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Triwarno",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Ungaran",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Tirtomoyo",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Tegalrejo",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Tanjungseto",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Tanjungsari",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Tanjungmeru",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Soka",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Poncowarno",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Pekunden",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Pesalakan",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Pejagatan",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Mrinen",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Mekarsari",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Lundong",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Lumbu",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Lerepkebumen",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Kuwarisan",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Kutowinangun",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Korowelang",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Kedungdowo",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Karangtengah",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Kebapangan",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Karangsari",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Kaliputih",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Jlegiwinangun",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Jembangan",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Jatipurus",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Poncowarno",
                "kelurahan": "Blater",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Kutowinangun",
                "kelurahan": "Babadsari",
                "kodepos": "54393"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Surobayan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sumberjati",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sinungreja",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Singosari",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sidomulyo",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sidomukti",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sidoluhur",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Sidareja",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Pucangan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Prasutan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Plempukankembaran",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Peneket",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Pagedangan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Pasarsenen",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Lajer",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Kenayajayan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Kradenan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Kaibon",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Kaibonpetangkuran",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Kembangsawit",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Entak",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Gondanglegi",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Blengorwetan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Dukuhrejasari",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Benerkulon",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Benerwetan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Blengorkulon",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Ambarwinangun",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Banjarsari",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Ambalkliwonan",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Ambalresmi",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Ambal",
                "kelurahan": "Ambalkebrek",
                "kodepos": "54392"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Tanjungsari",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Waluyo",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Tambakrejo",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Tanjungrejo",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Sangubanyu",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Setrojenar",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Sidomoro",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Maduretno",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Rantewringin",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Jogopaten",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Kloposawit",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Brecong",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Buluspesantren",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Indrosari",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Banjurpasar",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Bocor",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Ayamputih",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Banjurmukadan",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Ampih",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Arjowinangun",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Wetonwetan",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Buluspesantren",
                "kelurahan": "Ambalkumolo",
                "kodepos": "54391"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Wetonkulon",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Tukinggedong",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Waluyorejo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Srusuhjurutengah",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Surorejan",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Tambakmulyo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Sidoharjo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Sitiadi",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Sidobunder",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Sidodadi",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Purwoharjo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Purwosari",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Madurejo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Pasuruhan (Pasuruan)",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Puliharjo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Kedalemanwetan",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Krandegan",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Kaleng",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Kedalemankulon",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Banjarejo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Bumirejo",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Puring",
                "kelurahan": "Arjowinangun",
                "kodepos": "54383"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Tegalretno",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Tresnorejo",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Tanjungsari",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Petanahan",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Podourip",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Sidomulyo",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Munggu",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Nampudadi",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Kritig",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Kuwangunan",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Karanggadung",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Karangrejo",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Kebonsari",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Jatimulyo",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Karangduwur",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Grujugan",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Jagamertan",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Grogolbeningsari",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Grogolpenatus",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Ampelsari",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Petanahan",
                "kelurahan": "Banjarwinangun",
                "kodepos": "54382"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Tanggulangin",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Wotbuwono",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Tambakagung",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Tambakprogaten",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Ranterejo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Sitirejo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Pandanlor",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Podoluhur",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Klegenwonosari",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Klirong",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Kedungsari",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Kedungwinangun",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Klegenrejo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Karangglonggong",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Kebadongan",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Jogosimo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Kaliwungu",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Gebangsari",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Jatimalang",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Jerukagung",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Dorowati",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Gadungrejo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Bendogarap",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Klirong",
                "kelurahan": "Bumiharjo",
                "kodepos": "54381"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Tambaksari",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Wonoyoso",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Sidomukti",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Sawangan",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Serut",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Pondokgebangsari",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Purwodadi",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Mangli",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Ori",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Lemahduwur",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Madureso",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Kuwarasan",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Kuwaru",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Jatimulya",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Kalipurwo",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Kamulyan",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Gunungmujil",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Harjodowo",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Bendungan",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Gandusari",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Gumawang",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Wonotirto",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Kuwarasan",
                "kelurahan": "Banjareja",
                "kodepos": "54366"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Selogiri",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Pagebangan",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Penimbun",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Karangtengah",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Kebakalan",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Logandu",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Karanggayam",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Karangmojo",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Karangrejo",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Kalirejo",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Kalibening",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Kajoran",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Glontor",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Gunungsari",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Ginandong",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Giritirto",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Binangun",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanggayam",
                "kelurahan": "Clapar",
                "kodepos": "54365"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Sidomulyo",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Wonorejo",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Plarangan",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Pohkumbang",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Panjatan",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karangkemiri",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karanganyar",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Grenggeng",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Jatiluhur",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Candi",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Giripurno",
                "kodepos": "54364"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Wajasari",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Tegalsari",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Temanggal",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Tepakyang",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Sugihwaras",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Tambakharjo",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Sidamukti",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Sidamulyo",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Pekuwon",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Sekarteja",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Mangunharjo",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Meles",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Kemujan",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Joho",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Bonjok",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Candi Wulan",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Caruban",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Banyuroto",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Arjosari",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Adimulyo",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Arjomulyo",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Adikarto",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Adimulyo",
                "kelurahan": "Adiluhur",
                "kodepos": "54363"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Trikarso",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Trikarso",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Sidoharjo",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Sruweng",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Purwodeso",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Sidoagung",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Pengempon",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Penusupan",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Pandansari",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Pakuran",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Menganti",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Kejawang",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Klepusanggar",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Karangjambu",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Karangpule",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Karangsari",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Jabres",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Karanggedang",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Donosari",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Giwangretno",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Sruweng",
                "kelurahan": "Condongcampur",
                "kodepos": "54362"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Watulawang",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Pengaringan",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Peniron",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Prigi",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Pejagoan",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Kedawung",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Kuwayuhan",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Logede",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Kebagoran",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Kebulusan",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Jemur",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Karangpoh",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Pejagoan",
                "kelurahan": "Aditirto",
                "kodepos": "54361"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Wonosari",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Totogan",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Wadasmalang",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Widoro",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Seling",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Tlepok",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Seboro",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Sadangwetan",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Seboro",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Pujotirto",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Pucangan",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Plumbon",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Pencil",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Langse",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Kedunggong",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Kedungwaru",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Kaligending",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Kalisana",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Karangsambung",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Sadang",
                "kelurahan": "Cangkring",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Karangsambung",
                "kelurahan": "Banioro",
                "kodepos": "54353"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Tlogowulung",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Wonokromo",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Surotrunan",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Tanuharjo",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Krakal",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Sawangan",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Seliling",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Kemangguhan",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Karangkembang",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Karangtanjung",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Kalirancang",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Kambangsari",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Kalijaya",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Kaliputih",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Jatimulyo",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Alian/Aliyan",
                "kelurahan": "Bojongsari",
                "kodepos": "54352"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Tanahsari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Wonosari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Muktisari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Roworejo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Sumberadi",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Muktirejo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kutosari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Mengkowo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Karangsari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kawedusan",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kalijirek",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kalirejo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Jemur",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kalibagor",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Gesikan",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Jatisari",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Depokrejo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Gemeksekti",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Candimulyo",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Candiwulan",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Adikarso",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Argopeni",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Bandung",
                "kodepos": "54317"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kembaran",
                "kodepos": "54315"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Bumirejo",
                "kodepos": "54316"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Selang",
                "kodepos": "54314"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Tamanwinangun",
                "kodepos": "54313"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Kebumen",
                "kodepos": "54311"
            },
            {
                "kecamatan": "Kebumen",
                "kelurahan": "Panjer",
                "kodepos": "54312"
            }
        ],
        "k99": [
            {
                "kecamatan": "Grabag",
                "kelurahan": "Ukirsari",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Wonoenggal",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tulusrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tunggulrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Trimulyo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tlepok Kulon",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tlepok Wetan",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Tegalrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sangubanyu",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Sumberagung",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Rowodadi",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Roworejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Patutrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Rejosari",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Pasaranom",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Munggangsari",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Nambangan",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kumpulrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Ketawangrejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kese",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kertojayan",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kedung Kamal",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Harjobinangun",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Kalirejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Duduwetan",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Dukuhdungus",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Grabag",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Bendungan",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Dudukulon",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Banyuyoso",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Bakurejo",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Grabag",
                "kelurahan": "Aglik",
                "kodepos": "54265"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Wonorejo Wetan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Wonorejo Kulon",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Wonodadi",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Wironatan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Wareng",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Tlogorejo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Tegalgondo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Tanjunganom",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Tamansari",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Sumbersari",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Sruwohrejo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Sruwoh Dukuh",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Sidomulyo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Rowodadi",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Panggeldlanggu",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Polomarto",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Mangunjayan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lugurejo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lugu",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lubang Sampang",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lubang Lor",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lubang Kidul",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lubang Indangan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Lubang Dukuh",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Langenrejo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kunirejo Kulon",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kunirejo Wetan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kunir",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Klepu",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Ketug",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kedungsri",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kedungmulyo",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kedungagung",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kaliwatukranggan",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Karanganom",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kaliwatubumi",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Butuh",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Dlangu",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Binangun",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Andong",
                "kodepos": "54264"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Wonoyoso",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Wonosido",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Waru",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Tunjungtejo",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Tersidi Lor",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Tersidi Kidul",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Tasikmadu",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Tapen",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Sumber",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Sikambang",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Somogede",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Semampir",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Sawangan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Sekartejo",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Sambeng",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Prapag Lor",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Prigelan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Prapag Kidul",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Pituruh",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Polowangi",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Pepe",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Petuguran",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Pamriyan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Pangkalan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Pekacangan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Ngampel",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Ngandagan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Megulung Kidul",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Megulung Lor",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kesawen",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Luweng Kidul",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Luweng Lor",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kendalrejo",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kembangkuning",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Keburusan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kedung Batur",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kalimati",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Karanganyar",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Karanggetas",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kalijering",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kalikotes",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kaligintung",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Kaligondang",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Girigondo",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Gumawangrejo",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Dlisen Wetan",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Brengkol",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Dlisen Kulon",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Blekatuk",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Pituruh",
                "kelurahan": "Blekatuk",
                "kodepos": "54263"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Winong",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Wonosari",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Turus",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Waled",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Wanurojo",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Sokogelap",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Sutoragan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Samping",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Sidodadi",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Rejowinangun",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Rowobayem",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Rebug",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Rejosari",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Paitan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Purbayan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Loning",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kroyo Lor",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kerep",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kroyo Kulon",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kemiri Lor",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kemiri Kidul",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kemiri",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kedung Pomahan Wetan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kedunglo",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Karangluas",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kedung Pomahan Kulon",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kapiteran",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kaliurip",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kaliglagah",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Kalimeneng",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Gunungteges",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Jatiwangsan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Girijoyo",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Girimulyo",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Gedong",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Gesikan",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Dilem",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Bedono Kluwung",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Bedono Pageron",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Kemiri",
                "kelurahan": "Bedono Karangduwur",
                "kodepos": "54262"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Tegalsari",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Watuduwur",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Plipiran",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Puspo",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Somoleter",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Kemranggen",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Pakisarum",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Karanggedang",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Kaliwungu",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Kambangan",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Gunung Condong",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Giyombong",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Gowong",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Brunosari",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Cepedak",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Brondong",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Brunorejo",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bruno",
                "kelurahan": "Blimbing",
                "kodepos": "54261"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Tanjungrejo",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kutoarjo",
                "kodepos": "54251"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Tangkisan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Sucenjuru Tengah",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Pucang Agung",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Sambeng",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Pogung Juru Tengah",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Pogungkalangan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Pogungrejo",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Krandegan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Pekutan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Ketiwijayan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Jono",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Jrakah",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Kalimiru",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Jatingarang",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Dukuhrejo",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Grantung",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Dewi",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Bringin",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Boto Daleman",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Botorejo",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Banjarejo",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Bayan",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Bandung Kidul",
                "kodepos": "54224"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Besole",
                "kodepos": "54223"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Bandungrejo",
                "kodepos": "54222"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Bayem",
                "kodepos": "54215"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Wirun",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Katerban",
                "kodepos": "54214"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Tuntungpahit",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Tursino",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Tepus Wetan",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Tunggorono",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Sokoharjo",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Suren",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Tepus Kulon",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Semawung Kembaran",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Sidarum",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Purwosari",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Semawung Daleman",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Pringgowijayan",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Pacor",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Majir",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kepuh",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kiyangkongrejo",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kuwurejo",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kebondalem",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kemadu Lor",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Karangwuluh",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Kaligesing",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Karangrejo",
                "kodepos": "54212"
            },
            {
                "kecamatan": "Kutoarjo",
                "kelurahan": "Bandung",
                "kodepos": "54211"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Winong Lor",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Wonotopo",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Tlogosono",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Winong Kidul",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Sidoleren",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Seren",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Rendeng",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Salam",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Prumben",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Redin",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pelutan",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Penungkulan",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Ngaglik",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Ngemplak",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pakem",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kroyo",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Lugosobo",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Mlaran",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kalitengkek",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kragilan",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gebang",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Gintungan",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Bendosari",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Bulus",
                "kodepos": "54191"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Sidomukti",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Sukowuwuh",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Wadas",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Sendangsari",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Sendangsari",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Medono",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Ngasinan",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Nglaris",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Limbangan",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Mayung Sari",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kedung Pucang",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Ketosari",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Legetan",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Karang Sari",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kedung Loteng",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kaliwader",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kamijoro",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kalitapas",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kalijambe",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kali Urip",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Kaliboto",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Guntur",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Jati",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Cacaban Kidul",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Cacaban Lor",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Benowo",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Bleber",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Trirejo",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Bener",
                "kelurahan": "Bener",
                "kodepos": "54183"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Sedayu",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Tepansari",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Tridadi",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Rimun",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Mudalrejo",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Ngargosari",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Maron",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kemejing",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Loano",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Karangrejo",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kebongunung",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kedungpoh",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kalinongko",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kalisemo",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kali Glagah",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Kalikalong",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Guyangan",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Jetis",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Banyuasin Kembaran",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Loano",
                "kelurahan": "Banyuasin Separe",
                "kodepos": "54181"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Tlogo Rejo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Tlogobulu",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Tlogoguwo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Tawangsari",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Somowono",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Sudorogo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Pucungroto",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Purbowono",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Somongari",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Ngaran",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Pandanrejo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Kaliharjo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Kedunggubah",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Ngadirejo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Jelok",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Kaligono",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Hardimulyo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Hulosobo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Jatirejo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Donorejo",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Kaligesing",
                "kelurahan": "Gunungwangi",
                "kodepos": "54175"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Somorejo",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Tlogokotes",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Sokoagung",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Soko",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Semagung",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Semono",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Krendetan",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Piji",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Kalirejo",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Kemanukan",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Durensari",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Hargorojo",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Bugel",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Clapar",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Dadirejo",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Bapangsari",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Bagelen",
                "kelurahan": "Bagelen",
                "kodepos": "54174"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Tlogorejo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Watukuro",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sumbersari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Tegalaren",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sukomanah",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sumberejo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwosari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sendangsari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sidoharjo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Plandi",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Pundensari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwodadi",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Nampurejo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Ketangi",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Nampu",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kentengrejo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Keponggok",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kesugihan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kebonsari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Keduren",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Karanganyar",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Karangmulyo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Karangsari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jenar Wetan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jogoboyo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jogoresan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jenar Kidul",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jenar Lor",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jatikontal",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jatimalang",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Geparang",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Gesing",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Guyangan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Brondongrejo",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Bubutan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Gedangan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Bongkot",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Bragolan",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Banjarsari",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Blendung",
                "kodepos": "54173"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wunut",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wonoroto",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wonosari",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wonosri",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wingkomulyo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wonoboyo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wingko Tinumpuk",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wingkoharjo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wingko Sanggrahan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wingko Sigromulyo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wasiat",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Wero",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Walikoro",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Tunjungan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Tanjung",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Tanjungrejo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Tumenggungan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Sumberejo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Susuk",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Singkil Wetan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Sruwoh",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Seboro Pasar",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Secang",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Singkil Kulon",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Rasukan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Ringgit",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Piyono",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Pulutan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Ngombol",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Pagak",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Pejagran",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Mendiro",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Ngentak",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Laban",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Malang",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kumpulsari",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kuwukan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kembangkuning",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kesidan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Klandaran",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Keburuhan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kedondong",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Karangtalun",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kalitanjung",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kaliwungu Kidul",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Kaliwungu Lor",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Jombang",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Joso",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Girirejo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Jeruken",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Curug",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Depokrejo",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Cokroyasan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Candi",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Bojong",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Triwarno",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Briyan",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Wangunrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Ngombol",
                "kelurahan": "Awuawu",
                "kodepos": "54172"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Tegalrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Tanjunganom",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Tegalkuning",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Surorejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Sumber Sari",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Seborokrapyak",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Sokowaten",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Sawit",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Popongan",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Pakisrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Malangrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Kliwonan",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Kledung Kradenan",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Kledung Karangdalem",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Golok",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Kertosono",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Cengkawakrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Condongsari",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Candingasinan",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Candisari",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Boro Wetan",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Boro Kulon",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Bencorejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Bajangrejo",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Banyuurip",
                "kelurahan": "Banyu Urip",
                "kodepos": "54171"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Wonotulus",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Purworejo",
                "kodepos": "54151"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Wonoroto",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Sudimoro",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Sidorejo",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Sido Mulyo",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Plipir",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Semawung",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Pacekelan",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Mudal",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Kedung Sari",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Keseneng",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Ganggeng",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Donorati",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Brenggong",
                "kodepos": "54119"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Tambakrejo",
                "kodepos": "54118"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Baledono",
                "kodepos": "54118"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Cangkrep Lor",
                "kodepos": "54117"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Cangkrep Kidul",
                "kodepos": "54117"
            },
            {
                "kecamatan": "Butuh",
                "kelurahan": "Kedungsari",
                "kodepos": "54116"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Pangenrejo",
                "kodepos": "54115"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Pangenjuru Tengah",
                "kodepos": "54114"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Doplang",
                "kodepos": "54114"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Paduroso",
                "kodepos": "54112"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Sindurjan",
                "kodepos": "54113"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Mranti",
                "kodepos": "54112"
            }
        ],
        "k100": [
            {
                "kecamatan": "Madukara",
                "kelurahan": "Sered",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Talunamba",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Petambakan",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Rakitan",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Rejasa",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Penawangan",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Pakelen",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Pekauman",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Limbangan",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Madukara",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Pagelak",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Kenteng",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Kutayasa",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Kaliurip",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Karanganyar",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Clapar",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Dawuhan",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Gununggiana",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Bantarwaru",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Madukara",
                "kelurahan": "Blitar",
                "kodepos": "53482"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Tunggara",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Wanacipta",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Sigaluh",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Singomerto (Singamerta)",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Randegan",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Sawal",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Panawaren",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Prigi",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Pringamba",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Kemiri",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Kalibenda",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Karangmangu",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Bojanegara",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Gembongan",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Susukan",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Sigaluh",
                "kelurahan": "Bandingan",
                "kodepos": "53481"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Panerusan Wetan",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Piasa Wetan",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kemranggon",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Pakikiran",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Panerusan Kulon",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Karangsalam",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kedawung",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Karangjati",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Dermasari",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Gumelem Kulon",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Gumelem Wetan",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Brengkok",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Derik",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Sirkandi",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Berta",
                "kodepos": "53475"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Pagak",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Purworejo",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Kaliwinasuh",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Kecitran",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Klampok",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Kalilandak",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Purworejo Klampok",
                "kelurahan": "Kalimandi",
                "kodepos": "53474"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Simbang",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Somawangi",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Panggisari",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Purwasaba",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Salamerta",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Kertayasa",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Mandiraja Kulon",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Mandiraja Wetan",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Kebakalan",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Kebanaran",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Glempang",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Jalatunda",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Kaliwungu",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Candiwulan",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Banjengan",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Mandiraja",
                "kelurahan": "Blimbing",
                "kodepos": "53473"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Purwonegoro",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Petir",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Pucungbedug",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Merden",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Mertasari",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Parakan",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Karanganyar",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Kutawuluh",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Kalipelus",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Kalitengah",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Gumiwang",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Kaliajir",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Purwonegoro",
                "kelurahan": "Danaraja",
                "kodepos": "53472"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Watuurip",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Winong",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Wiramastra",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Serang",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Wanadri",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Mantrianom",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Masaran",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Pucang",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Majalengka",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Kebondalem",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Kutayasa",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Gemuruh",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Joho",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Blambangan",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Depok",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Bandingan",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Bawang",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Binorong",
                "kodepos": "53471"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Situwangi",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Tanjunganom",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Pingit",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Rakit",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Lengkong",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Luwung",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Gelang",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Kincang",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Badamita",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Bandingan",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Tribuana",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Rakit",
                "kelurahan": "Adipasir",
                "kodepos": "53463"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Tanjungtirta",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Tlaga",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Sawangan",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Sidarata",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Sambong",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Punggelan",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Purwasana",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Mlaya",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Petuguran",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Kecepit",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Klapa",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Jembangan",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Karangsari",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Bondolharjo",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Danakerta",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Wanakarsa",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Punggelan",
                "kelurahan": "Badakarya",
                "kodepos": "53462"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Medayu",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Tapen",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Wanadadi",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Linggasari",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Kasalib",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Lemahjaya",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Karangjambe",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Karangkemiri",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Gumingsir",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Wanadadi (Wonodadi)",
                "kelurahan": "Kandangwangi",
                "kodepos": "53461"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Sirongge",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Sirukem",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Sirukun",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Sinduaji",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Sikumpul",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Sembawa",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Sidakangen",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Pringamba",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Plorengan",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Pingit Lor",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Pandanarum",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Pasegeran",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Majatengah",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Lawen",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Kasinoman",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Kertasari",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Kalisat Kidul",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Karang Anyar",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Kalibombong",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Kalibening",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Pandanarum",
                "kelurahan": "Beji",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Gununglangit",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Asinan",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Kalibening",
                "kelurahan": "Bedana",
                "kodepos": "53458"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Wanaraja",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Wanayasa",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Suwidak",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Tempuran",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Pesantren",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Susukan",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Pandansari",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Penanggungan",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Pagergunung",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Kubang",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Legoksayem",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Karangtengah",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Kasimpar",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Dawuhan",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Jatilawang",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Balun",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Wanayasa",
                "kelurahan": "Bantar",
                "kodepos": "53457"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Pekasiran",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Sumberejo",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Karangtengah",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Kepakisan",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Pasurenan",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Batur",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Dieng Kulon",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Tegaljeruk",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Batur",
                "kelurahan": "Bakal",
                "kodepos": "53456"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Plumbungan",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Sokaraja",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Nagasari",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Pagentan",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Majasari",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Metawana",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Kasmaran",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Kayuares",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Larangan",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Karangnangka",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Karekan",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Gumingsir",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Kalitlaga",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Aribaya",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pagentan",
                "kelurahan": "Babadan",
                "kodepos": "53455"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Semangkung",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Sidengok",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Tlahap",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Sarwodadi",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Pegundungan",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Pejawaran",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Ratamba",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Karangsari",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Panusupan",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Giritirta",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Grogol",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Kalilunjar",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Darmayasa",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Gembol",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Biting",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Condong Campur",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Pejawaran",
                "kelurahan": "Beji",
                "kodepos": "53454"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Sampang",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Slatri",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Paweden",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Purwodadi",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Leksana",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Pagerpelah",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Pasuruhan",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Karanggondang",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Karangkobar",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Jlegong",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Binangun",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Gumelar",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Karangkobar",
                "kelurahan": "Ambal",
                "kodepos": "53453"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Sipedang",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Sijenggung",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Sijeruk",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Prendengan",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Rejasari",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Sigeblog",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Paseh",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Pekandangan",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Kesenet",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Majatengah",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Kendaga",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Jenggawur",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Kalilunjar",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Beji",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Gripit",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Banjarkulon",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Banjarmangu",
                "kelurahan": "Banjarmangu",
                "kodepos": "53452"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Twelagiri",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Sokayasa",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Tlagawera",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Pesangkalan",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Semampir",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Pagedongan",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Kebutuhjurang",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Lebakwangi",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Kuta Banjarnegara",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Gunungjati",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Kebutuhduwur",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Duren",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Pagedongan",
                "kelurahan": "Gentansari",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Argasoka",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Cendana",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Wangon",
                "kodepos": "53417"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Ampelsari",
                "kodepos": "53418"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Krandegan",
                "kodepos": "53414"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Karangtengah",
                "kodepos": "53416"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Semarang",
                "kodepos": "53411"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Parakancanggah",
                "kodepos": "53412"
            },
            {
                "kecamatan": "Banjarnegara",
                "kelurahan": "Sokanandi",
                "kodepos": "53413"
            }
        ],
        "k101": [
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Tumanggal",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Pasunggingan",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Tegalpingen",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Tetel",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Panunggalan",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Karangjoho",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Larangan",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Pangadegan",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Pengadegan",
                "kelurahan": "Bedagas",
                "kodepos": "53393"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Pangempon",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Sokanegara",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Nangkod",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Pandansari",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Lamuk",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Langgar",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Nangkasawit",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Kejobong",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Krenceng",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Bandingan",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Gumiwang",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Kedarpan",
                "kodepos": "53392"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Slinga",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Tejasari",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Sidareja",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Sinduraja",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Sempor Lor",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Sidanegara",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Penolih",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Selakambang",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Selanegara",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Lamongan",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Pagerandong",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Kalikajar",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Kembaran Wetan",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Kaligondang",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Brecek",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Cilapar",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Tidu",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Wirasaba",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Arenan",
                "kodepos": "53391"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Majasari",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Penaruban",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Kedungjati",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Kembangan",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Kutawis",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Karanggedang",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Karangnangka",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Kebutuh",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Cipawon",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Karangcengis",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Bajong",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Bukateja",
                "kelurahan": "Bukateja",
                "kodepos": "53382"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Sumilir",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Toyareka",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Plumutan",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Senon",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Panican",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Pegandekan",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Majatengah",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Muntang",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Majasem",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Kedunglegok",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Kemangkon",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Kedungbenda",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Kalialang",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Karangkemiri",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Karangtengah",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Gambarsari",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Jetis",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Bokol",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Sokawera",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Kemangkon",
                "kelurahan": "Bakulan",
                "kodepos": "53381"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Purbayasa",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Padamara",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Prigi",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Karangsentul",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Mipiran",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Karangpule",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Karangjambe",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Karanggambas",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Kalitinggar",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Kalitinggar Kidul",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Gemuruh",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Dawuhan",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Padamara",
                "kelurahan": "Bojanegara",
                "kodepos": "53372"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Sidakangen",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Selabaya",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Rabak",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Mewek",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Manduraga",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Klapasawit",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Kedungwuluh",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Karangsari",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Karangmanyar",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Karangpetir",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Kalimanah Wetan",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Kalimanah Kulon",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Jompo",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Blater",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Grecol",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Babakan",
                "kodepos": "53371"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Pekalongan",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Patemon",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Pagedangan",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Karangbanjar",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Metenggeng",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Kajongan",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Gembong",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Bumisari",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Galuh",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Brobot",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Bojongsari",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Banjaran",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Bojongsari",
                "kelurahan": "Beji",
                "kodepos": "53362"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Sumingkir",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Meri",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Munjul",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Limbangan",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Kutasari",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karanglewas",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karangreja",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karangjengkol",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karangklesem",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karangcegak",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Karangaren",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Candiwulan",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Cendana",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Tlahab Lor",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Kutasari",
                "kelurahan": "Candinata",
                "kodepos": "53361"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Siwarak",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Tlahab Kidul",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Sirandu",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Serang",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Purbasari",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Sanguwatang",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Kutabawa",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Karangjambu",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Karangreja",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Jingkang",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangreja",
                "kelurahan": "Gondang",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Karangjambu",
                "kelurahan": "Danasari",
                "kodepos": "53357"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Wlahar",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tanalum",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Wanogara Kulon",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Wanogara Wetan",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Panusupan",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sumampir",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Makam",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Karangbawang",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Losari",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Bantarbarang",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Bodas Karangjati",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Gunungwuled",
                "kodepos": "53356"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Tunjungmuli",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Tajug",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Tamansari",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Pepedan",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Rajawana",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Sirau",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Kramat",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Pekiringan",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Baleraksa",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Grantung",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karangmoncol",
                "kelurahan": "Karangsari",
                "kodepos": "53355"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Ponjen",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Maribaya",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Margasana (Mergasana)",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Langkap",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Lumpang",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Krangean",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Kasih",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Kertanegara",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Karangpucung",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Karangasem",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karanggedang",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Karang Tengah",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karanganyar",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kalijaran",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kaliori",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Jambudesa",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kabunderan",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Darma",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Bungkanel",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Condong",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Brakas",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Buara",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Banjarkerta",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Kertanegara",
                "kelurahan": "Adiarsa",
                "kodepos": "53354"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Tlagayasa",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Palumbungan Wetan",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Talagening",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Pakuncen",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Palumbungan Kulon",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Limbasari",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Majapura",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Karangmalang",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Karangtalun",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Kalapacung",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Karangduren",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Gandasuli",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Gunungkarang",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Bobotsari",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Dagan",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Tangkisan",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Bobotsari",
                "kelurahan": "Banjarsari",
                "kodepos": "53353"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Serayu Larangan",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Sindang",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Sangkanayu",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Selaganggeng",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Serayu Karanganyar",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Pengalusan",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Onje",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Pager Andong",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Mangunegara",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Mrebet",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Kradenan",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Lambur",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Karang Nangka",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Karangturi",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Bojong",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Campakoah",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Cipaku",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Kaligondang",
                "kelurahan": "Penaruban",
                "kodepos": "53331"
            },
            {
                "kecamatan": "Mrebet",
                "kelurahan": "Binangun",
                "kodepos": "53352"
            },
            {
                "kecamatan": "Kalimanah",
                "kelurahan": "Kalikabong",
                "kodepos": "53321"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Kandang Gampang",
                "kodepos": "53319"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Kembaran Kulon",
                "kodepos": "53319"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Wirasana",
                "kodepos": "53318"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Bojong",
                "kodepos": "53319"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Jatisaba",
                "kodepos": "53316"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Purbalingga Wetan",
                "kodepos": "53317"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Toyareja",
                "kodepos": "53315"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Bancar",
                "kodepos": "53316"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Kedung Menjangan",
                "kodepos": "53315"
            },
            {
                "kecamatan": "Kejobong",
                "kelurahan": "Timbang",
                "kodepos": "53314"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Penambongan",
                "kodepos": "53314"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Purbalingga Kidul",
                "kodepos": "53313"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Purbalingga Lor",
                "kodepos": "53311"
            },
            {
                "kecamatan": "Purbalingga",
                "kelurahan": "Purbalingga Kulon",
                "kodepos": "53312"
            }
        ],
        "k102": [
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Purwadadi",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Sikanco",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Nusawangkal",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Nusawungu",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Klumprit",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Kedungbenda",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Karangputat",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Karangsembung",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Karangtawang",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Jetis",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Karangpakis",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Danasri",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Danasri Kidul",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Danasri Lor",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Banjarsari",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Banjarwaru",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Nusawungu",
                "kelurahan": "Banjareja",
                "kodepos": "53283"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Pucung Lor",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Sikampuh",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Pucung Kidul",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Pekuncen",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Pesanggrahan",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Mergawati",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Mujur",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Mujur Lor",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Karangturi",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Kedawung",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Kroya",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Gentasari",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Karangmangu",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Bajing Kulon",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Buntu",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Ayamalas",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Kroya",
                "kelurahan": "Bajing",
                "kodepos": "53282"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Widarapayung Kulon",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Widarapayung Wetan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Sidaurip",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Sidayu",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Rawajaya",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Pesawahan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Pasuruhan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Pagubugan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Pagubugan Kulon",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Kepudang",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Kemojing",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Karangnangka",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Kamulyan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Kedungwadas",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Jepara Kulon",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Jepara Wetan",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Jati",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Citembong",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Binangun",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Bulaksari",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Cikedondong",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Bantarsari",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Bantarsari",
                "kelurahan": "Binangun",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Bangkal",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Slarang",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Alangamba",
                "kodepos": "53281"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Pesanggrahan",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Planjan",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Kuripan Kidul",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Menganti",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Kesugihan Kidul",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Kuripan",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Kesugihan",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Karangkandri",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Keleng",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Karangjengkol",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Jangrana",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Kalisabuk",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Ciwuni",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Dondong",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Sidasari",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Kesugihan",
                "kelurahan": "Bulupayung",
                "kodepos": "53274"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Paketingan",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Sampang",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Paberasan",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Nusajati",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Ketanggung",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Karangjati",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Karangtengah",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Brani",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Karangasem",
                "kodepos": "53273"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Maos Lor",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Mernek",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Penisihan",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Klapagada",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Maos Kidul",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Karangreja",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Karangrena",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Glempang",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Kalijaran",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Maos",
                "kelurahan": "Karangkemiri",
                "kodepos": "53272"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Wlahar",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Penggalang",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Welahan Wetan",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Karangsari",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Pedasong",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Kalikudi",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Karanganyar",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Karangbenda",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Gombolharjo",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Bunton",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Doplang",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Glempangpasir",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Adiraja",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Adireja Kulon",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Adireja Wetan",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Adipala",
                "kelurahan": "Adipala",
                "kodepos": "53271"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Panulisan Timur",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Sumpinghayu",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Panulisan Barat",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Matenggeng",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Panulisan",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Hanum",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Kutaagung",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Ciwalen",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Datar",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Dayeuhluhur",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Cijeruk",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Cilumping",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Bingkeng",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Dayeuhluhur",
                "kelurahan": "Bolang",
                "kodepos": "53266"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Tarisi",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Wanareja",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Tambaksari",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Purwasari",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Sidamulya",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Majingklak",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Malabar",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Palugon",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Madura",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Madusari",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Cilongkrang",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Jambu",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Bantar",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Adimulya",
                "kodepos": "53265"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Rawaapu",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Sidamukti",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Purwodadi",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Patimuan",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Cimrutu",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Cinyawang",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Tambaksari",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Patimuan",
                "kelurahan": "Bulupayung",
                "kodepos": "53264"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Sidanegara",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Tambakreja",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Kaliwungu",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Kedungreja",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Rejamulya",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Bumireja",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Ciklapa",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Jatisari",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Bangunreja",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Kedungreja",
                "kelurahan": "Bojongsari",
                "kodepos": "53263"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Segaralangu",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Serang",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Sidasari",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Mulyadadi",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Pegadingan",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Cisuru",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Karangreja",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Kutasari",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Mekarsari",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Caruy",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Cipari",
                "kelurahan": "Cipari",
                "kodepos": "53262"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Sudagaran",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Tegalsari",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Tinggarjaya",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Sidamulya",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Sidareja",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Margasari",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Penyarang",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Kunci",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Gunungreja",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Sidareja",
                "kelurahan": "Karanggedang",
                "kodepos": "53261"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Sindangsari",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Ujungbarang",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Sadahayu",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Salebu",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Sepatnunggal",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Pahonjean",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Pengadegan",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Sadabumi",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Padangjaya",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Padangsari",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Mulyadadi",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Mulyasari",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Cilopadang",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Jenang",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Bener",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Boja",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Majenang",
                "kelurahan": "Cibeunying",
                "kodepos": "53257"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Pesahangan",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Rejodadi",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Negarajati",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Panimbang",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Mandala",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Karangsari",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Kutabima",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cisalak",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Karangreja",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cilempuyang",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cimanggu",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cibalung",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Cijati",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Bantarmangu",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Cimanggu",
                "kelurahan": "Bantarpanjang",
                "kodepos": "53256"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Tayem",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Tayemtimur",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Sindangbarang",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Surusunda",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Pengawaren",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Sidamulya",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Karangpucung",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Pamulihan",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Gunungtelu",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Ciporos",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Ciruyung",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Cidadap",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Babakan",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Karangpucung",
                "kelurahan": "Bengbulang",
                "kodepos": "53255"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Sidaurip",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Wringinharjo",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Muktisari",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Rungkang",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Layansari",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Karanggintung",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Kertajaya",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Gintungreja",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Karanganyar",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Gandrungmangu",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Gandrungmanis",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Cinangsi",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Cisumur",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Gandrungmangu",
                "kelurahan": "Bulusari",
                "kodepos": "53254"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Ujungmanik",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kampung Laut",
                "kelurahan": "Ujunggagak",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kampung Laut",
                "kelurahan": "Ujungalang",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Sidaurip",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Sarwadadi",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kampung Laut",
                "kelurahan": "Panikel",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Mentasan",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Kubangkangkung",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kampung Laut",
                "kelurahan": "Klaces",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Kawunganten Lor",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Kawunganten",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Kalijeruk",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Grugu",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Bringkeng",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Bojong",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Kawunganten",
                "kelurahan": "Babakan",
                "kodepos": "53253"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Tritih Wetan",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Tritih Lor",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Sumingkir",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Sawangan",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Prapagan",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Karangkemiri",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Mendala",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Jeruklegi Wetan",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Jambusari",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Jeruklegi Kulon",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Citepus",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Brebeg",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Jeruklegi",
                "kelurahan": "Cilibang",
                "kodepos": "53252"
            },
            {
                "kecamatan": "Cilacap Utara",
                "kelurahan": "Kebonmanis",
                "kodepos": "53235"
            },
            {
                "kecamatan": "Cilacap Utara",
                "kelurahan": "Karangtalun",
                "kodepos": "53234"
            },
            {
                "kecamatan": "Cilacap Utara",
                "kelurahan": "Tritih Kulon",
                "kodepos": "53233"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Limbangan",
                "kodepos": "53232"
            },
            {
                "kecamatan": "Cilacap Utara",
                "kelurahan": "Mertasinga",
                "kodepos": "53232"
            },
            {
                "kecamatan": "Cilacap Utara",
                "kelurahan": "Gumilir",
                "kodepos": "53231"
            },
            {
                "kecamatan": "Cilacap Tengah",
                "kelurahan": "Kutawaru",
                "kodepos": "53225"
            },
            {
                "kecamatan": "Cilacap Tengah",
                "kelurahan": "Gunungsimping",
                "kodepos": "53224"
            },
            {
                "kecamatan": "Cilacap Tengah",
                "kelurahan": "Donan",
                "kodepos": "53222"
            },
            {
                "kecamatan": "Cilacap Tengah",
                "kelurahan": "Sidanegara",
                "kodepos": "53223"
            },
            {
                "kecamatan": "Wanareja",
                "kelurahan": "Cigintung",
                "kodepos": "53222"
            },
            {
                "kecamatan": "Cilacap Tengah",
                "kelurahan": "Lomanis",
                "kodepos": "53221"
            },
            {
                "kecamatan": "Cilacap Selatan",
                "kelurahan": "Tegalrejo",
                "kodepos": "53215"
            },
            {
                "kecamatan": "Cilacap Selatan",
                "kelurahan": "Tegalkamulyan",
                "kodepos": "53215"
            },
            {
                "kecamatan": "Cilacap Selatan",
                "kelurahan": "Tambakreja",
                "kodepos": "53213"
            },
            {
                "kecamatan": "Cilacap Selatan",
                "kelurahan": "Sidakaya",
                "kodepos": "53212"
            },
            {
                "kecamatan": "Cilacap Selatan",
                "kelurahan": "Cilacap",
                "kodepos": "53211"
            }
        ],
        "k103": [
            {
                "kecamatan": "Tambak",
                "kelurahan": "Purwodadi",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Watuagung",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Plangkapan",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Prembun",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Pesantren",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Karangpetir",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Karangpucung",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Gumelar Lor",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Kamulyan",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Gebangsari",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Gumelar Kidul",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Buniayu",
                "kodepos": "53196"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Selanegara",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Sumpiuh",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Nusadadi",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Pandak",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Selandaka",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Kuntili",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Lebeng",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Ketanda",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Kradenan",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Kemiri",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Karanggedang",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Kebokura",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Banjarpanepen",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Sumpiuh",
                "kelurahan": "Bogangin",
                "kodepos": "53195"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Sirau",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Sibrama",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Sidamulya",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Petarangan",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Sibalung",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Pageralang",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Kedungpring",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Nusamangir",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Kecila",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Karangsalam",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Kebarongan",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Karangjati",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Karanggintung",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Alasmalang",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Kemranjen",
                "kelurahan": "Grujugan",
                "kodepos": "53194"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Somagede",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Somakaton",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Tanggeran",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Plana",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Sokawera",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Klinting",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Piasa Kulon",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Kemawi",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Somagede",
                "kelurahan": "Kanding",
                "kodepos": "53193"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Sudagaran",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Pasinggangan",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Pekunden",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Papringan",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Kejawar",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Kedunguter",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Kedunggede",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Karangrau",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Dawuhan",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Kalisube",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Danaraja",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Binangun",
                "kodepos": "53192"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Wlahar Wetan",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Suro",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Srowot",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Pajerukan (Pejerukan)",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Pekaja",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Petir",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Kalisogra Wetan",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Karangdadap",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Kaliori",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Kalicupak Lor (Kalipucak Lor)",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Kalicupak Kidul (Kalipucak Kidul)",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Kalibagor",
                "kelurahan": "Kalibagor",
                "kodepos": "53191"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Tambaksogra",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Susukan",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Silado",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Sumbang",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Limpakuwus",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Sikapat",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Kotayasa",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Kedung Malang",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Kawungcarang",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Kebanggan",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Karanggintung",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Karangturi",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Gandatapa",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Karangcegak",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Ciberem",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Datar",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Banteran",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Banjarsari Wetan",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Sumbang",
                "kelurahan": "Banjarsari Kulon",
                "kodepos": "53183"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Tambaksari Kidul",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Sambeng Kulon",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Sambeng Wetan",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Purwodadi",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Purbadana",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Linggasari",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Pliken",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Kramat",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Ledug",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Karangtengah",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Kembaran",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Dukuhwaluh",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Karangsoka",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Karangsari",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Bantarwuni",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Kembaran",
                "kelurahan": "Bojongsari",
                "kodepos": "53182"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Wiradadi",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Sokaraja Tengah",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Sokaraja Wetan",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Sokaraja Kulon",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Sokaraja Lor",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Pamijen",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Sokaraja Kidul",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Klahang",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Lemberang",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Kedondong",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Karangnanas",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Karangrau",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Kalikidang",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Karangduren",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Karangkedawung",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Banjarsari Kidul",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Jompo Kulon",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Sokaraja",
                "kelurahan": "Banjaranyar",
                "kodepos": "53181"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Lumbir",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Parungkamal",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Kedunggede",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Cirahab",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Dermaji",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Karanggayam",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Cidora",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Cingebul",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Besuki",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Lumbir",
                "kelurahan": "Canduk",
                "kodepos": "53177"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Wlahar",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Wangon",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Windunegara",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Randegan",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Rawaheng",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Klapagading Kulon",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Pangadegan",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Jurangbahas",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Klapagading",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Cikakak",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Jambu",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Purwojati",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Wangon",
                "kelurahan": "Banteran",
                "kodepos": "53176"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Karangtalun Kidul",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Karangtalun Lor",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Klapasawit",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Kaliwangi",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Karangmangu",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Kalitapen",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Kaliurip",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Gerduren",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Purwojati",
                "kelurahan": "Kaliputih",
                "kodepos": "53175"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Tunjung (Tanjung)",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Pekuncen",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Tinggarjaya",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Karanglewas",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Kedungwringin",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Margasana",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Gentawangi",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Gunung Wetan",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Karanganyar",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Adisara",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Jatilawang",
                "kelurahan": "Bantar",
                "kodepos": "53174"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Sidamulih",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Tambaknegara",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Tipar",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Rawalo",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Sanggreman",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Menganti",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Pesawahan",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Banjarparakan",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Rawalo",
                "kelurahan": "Losari",
                "kodepos": "53173"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Tumiyang",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Mandirancan",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Randegan",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Sawangan",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Kaliwedi",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Karangsari",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Kebasen",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Gambarsari",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Kalisalak",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Bangsa",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Cindaga",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Wlahar Kulon",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Kebasen",
                "kelurahan": "Adisana",
                "kodepos": "53172"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Sidabowa",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Sokawera Kidul",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Sawangan Wetan",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Patikraja",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Pegalongan",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Kedungwuluh Kidul",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Kedungwuluh Lor",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Notog",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Kedungwringin",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Karangendep",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Kedungrandu",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Tlaga",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Patikraja",
                "kelurahan": "Karanganyar",
                "kodepos": "53171"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Samudra Kulon",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Paningkaban",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Samudra",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Kedungurang",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Gumelar",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Karangkemojing",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Gancang",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Cihonje",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Gumelar",
                "kelurahan": "Cilangkap",
                "kodepos": "53165"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Petahunan",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Semedo",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Tumiyang",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Pasiraman Lor",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Pekuncen",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Krajan",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Kranggan",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Pasiraman Kidul",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Karangkemiri",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Karangklesem",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Cikawung",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Cikembulan",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Glempang",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Cibangkong",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Candinegara",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Tipar (Tipar Kidul)",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Pekuncen",
                "kelurahan": "Banjaranyar",
                "kodepos": "53164"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Pancurendang",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Pandansari",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Sawangan",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Pancasan",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Lesmana",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Kalibenda",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Karangbawang",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Kracak",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Darmakradenan",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Jingkang",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Banjarsari (Banjarsari Kidul)",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Ciberung",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Ajibarang",
                "kelurahan": "Ajibarang Wetan",
                "kodepos": "53163"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Sokawera",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Sudimara",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Sokawera",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Rancamaya",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Sambirata",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Pejogol",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Pernasidi",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Panembangan",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Panusupan",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Kasegeran",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Langgongsari",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Pageraji",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Karanglo",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Karangtengah",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Kalisari",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Cipete",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Gununglurah",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Jatisaba",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Cikidang",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Cilongok",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Tamansari",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Cilongok",
                "kelurahan": "Batuanten",
                "kodepos": "53162"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Pasir Wetan",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Singasari",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Sunyalangu",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Pasir Kulon",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Pasir Lor",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Kediri",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Pangebatan (Pengebatan)",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Karangkemiri",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Karanglewas Kidul",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Jipang",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Karanggude Kulon",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Karanglewas",
                "kelurahan": "Babakan",
                "kodepos": "53161"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Melung",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Windujaya",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Keniten",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kutaliman",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kebocoran",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kedungbanteng",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Karangnangka",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Karangsalam Kidul",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kalikesur",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kalisalak",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Beji",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Dawuhan Kulon",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Dawuhan Wetan",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Baseh",
                "kodepos": "53152"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Rempoah",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Pamijen",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Pandak",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Kutasari",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Ketenger",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Kemutug Lor",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Kebumen",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Kemutug Kidul",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Karangsalam Lor",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Karangtengah",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Purwokerto Kidul",
                "kodepos": "53147"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Karangmangu",
                "kodepos": "53151"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Berkoh",
                "kodepos": "53146"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Tanjung",
                "kodepos": "53144"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Teluk",
                "kodepos": "53145"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Karang Pucung",
                "kodepos": "53142"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Karang Klesem",
                "kodepos": "53144"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Pasirmuncang",
                "kodepos": "53137"
            },
            {
                "kecamatan": "Purwokerto Selatan",
                "kelurahan": "Purwokerto Kulon",
                "kodepos": "53141"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Pasir Kidul",
                "kodepos": "53135"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Karanglewas Lor",
                "kodepos": "53136"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Kober",
                "kodepos": "53132"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Bantarsoka",
                "kodepos": "53133"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Rejasari",
                "kodepos": "53134"
            },
            {
                "kecamatan": "Purwokerto Barat",
                "kelurahan": "Kedungwuluh",
                "kodepos": "53131"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Bobosan",
                "kodepos": "53127"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Purwanegara",
                "kodepos": "53127"
            },
            {
                "kecamatan": "Baturaden",
                "kelurahan": "Purwosari",
                "kodepos": "53126"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Sumampir",
                "kodepos": "53125"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Karangwangkal",
                "kodepos": "53123"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Pabuaran",
                "kodepos": "53124"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Bancarkembar",
                "kodepos": "53121"
            },
            {
                "kecamatan": "Purwokerto Utara",
                "kelurahan": "Grendeng",
                "kodepos": "53122"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Kranji",
                "kodepos": "53116"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Purwokerto Lor",
                "kodepos": "53114"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Sokanegara",
                "kodepos": "53115"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Arcawinangun",
                "kodepos": "53113"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Purwokerto Wetan",
                "kodepos": "53111"
            },
            {
                "kecamatan": "Purwokerto Timur",
                "kelurahan": "Mersi",
                "kodepos": "53112"
            }
        ],
        "k104": [
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Wotgalih",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Tamansari",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Setail",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Sumbarang",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Padasari",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Penyalahan",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Luwijawa",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Mokaha",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Lebakwangi",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Lembahsari",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Jatinegara",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Kedungwungu",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Dukuhbangsa",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Gantungan",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Capar",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Cerih",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Jatinegara",
                "kelurahan": "Argatawang",
                "kodepos": "52473"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Sumingkir",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Tonggara",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Semedo",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Penujah",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kedung Banteng",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Margamulya",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Kebandingan",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Karangmalang",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Karang Anyar",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Kedung Banteng",
                "kelurahan": "Dukuhjati Wetan",
                "kodepos": "52472"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Talok",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Rancawiru",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Penusupan",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Purbayasa",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Pener",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Pecabean",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Pangkah",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Paketiban",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Kendalserut",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Kalikangkung",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Grobog Wetan",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Jenggawur",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Dukuhsembung",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Grobog Kulon",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Dukuhjati Kidul",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Dermasuci",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Dermasandi",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Depok",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Curug",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Bogares Lor",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Bogares Kidul",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Bedug",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Pangkah",
                "kelurahan": "Balamoa",
                "kodepos": "52471"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Traju",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Sumbaga",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Sokatengah",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Sokasari",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Sigedong",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Muncanglarang",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Pagerkasih",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Jejeg",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Gunung Agung",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Guci",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Dukuh Benda",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Cintamanik",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Cempaka",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Cawitali",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Bumijawa",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Carul",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Batumirah",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bumijawa",
                "kelurahan": "Begawat",
                "kodepos": "52466"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sangkanayu",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Suniarsih",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Tuwel",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Rembul",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kedawung",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Lengkong",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Pucang Luwuk",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kalijambu",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Karangmulyo",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Gunungjati",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kajenengan",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Danasari",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Dukuhtengah",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Buniwah",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Cikura",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Batunyana",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong",
                "kodepos": "52465"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Tembongwah",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Wringin Jenggot",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Sangkanjaya",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Sesepan",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Pagerwangi",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Pamiritan",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Kaliwungu",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Karangjambu",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Harjowinangun",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Kalibakung",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Danareja",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Danawarih",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Cibunar",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Cilongok",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Bukateja",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Cenggini",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Banjar Anyar",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Batuagung",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Balapulang Kulon",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Balapulang",
                "kelurahan": "Balapulang Wetan",
                "kodepos": "52464"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Prupuk Utara",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Wanasari",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Pakulaut",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Prupuk Selatan",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Marga Ayu",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Margasari",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Kalisalak",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Karangdawa",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Jembayat",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Kaligayam",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Dukuh Tengah",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Jatilaba",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Margasari",
                "kelurahan": "Danaraja",
                "kodepos": "52463"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Sido Mulyo",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Srengseng",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Surokidul",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Rajegwesi",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Randusari",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Semboja",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Pagerbarang",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Pesarean",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Mulyoharjo",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Kertaharja",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Karanganyar",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Kedungsugih",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Yamansari",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Pagerbarang",
                "kelurahan": "Jatiwangi",
                "kodepos": "52462"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Tegalandong",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Timbangreja",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Slarang Kidul",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Lebaksiu Lor",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Pendawa",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Lebaksiu Kidul",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Kesuben",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Lebak Goah",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Kambangan",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Kajen",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Dukuhlo",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Jatimulyo",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Balaradin",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Lebaksiu",
                "kelurahan": "Dukuhdamu",
                "kodepos": "52461"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Selapura",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Sindang",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Slarang Lor",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Kalisoka",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Pedagangan",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Gumayun",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Kabunan",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Bulakpacing",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Dukuhwaru",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Dukuhwaru",
                "kelurahan": "Blubuk",
                "kodepos": "52451"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Dukuh Ringin (Dukuhwringin)",
                "kodepos": "52419"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Slawi Kulon",
                "kodepos": "52419"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Kalisapu",
                "kodepos": "52416"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Dukuh Salam",
                "kodepos": "52418"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Trayeman",
                "kodepos": "52414"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Pakembaran",
                "kodepos": "52415"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Procot",
                "kodepos": "52412"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Kudaile",
                "kodepos": "52413"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Kagok",
                "kodepos": "52411"
            },
            {
                "kecamatan": "Slawi",
                "kelurahan": "Slawi Wetan",
                "kodepos": "52411"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Tembok Lor",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Tembok Luwung",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Ujungrusi",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Tembok Kidul",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Pesarean",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Tembok Banjaran",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Pedeslohor",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Penarukan",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Pagiyanten",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Pecangakan",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Lumingser",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Pagedangan",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Kedungsukun",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Lemahduwur",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Harjosari Lor",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Kaliwadas",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Bersole",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Gumalar",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Harjosari Kidul",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Adiwerna",
                "kodepos": "52194"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Wangandawa",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Pesayangan",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Talang",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Tegalwangi",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Pegirikan",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Pekiringan",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Pacul",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Pasangan",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Kaligayam",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Kebasen",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Langgen",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Kajen",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Kaladawa",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Gembong Kulon",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Getaskerep",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Dawuhan",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Dukuhmalang",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Bengle",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Talang",
                "kelurahan": "Cangkring",
                "kodepos": "52193"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Sidapurna",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Sutapranan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Pengarasan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Pepedan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Sidakaton",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Pekauman Kulon",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Pengabean",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Kupu",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Lawatan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Pagongan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Kepandean",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Ketanggungan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Grogol",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Kademangaran",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Karanganyar",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Debong Wetan",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Dukuhturi",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Tarub",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Dukuhturi",
                "kelurahan": "Bandasari",
                "kodepos": "52192"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Purbasana",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Setu",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Lebeteng",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Mangunsaren",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Margapadang",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Mindaka",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kesadikan",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kesamiran",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kedokan Sayang",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kedung Bungkus",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kemanggungan",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kalijambe",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Karangjati",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Karangmangu",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Bumiharja",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Jatirawa",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Kabukan",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Brekat",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Tarub",
                "kelurahan": "Bulakwaru",
                "kodepos": "52184"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Sigentong",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Sukareja",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Warureja",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Sidomulyo",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Rangimulya",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Kedungkelor",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Kendayakan",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Kreman",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Kedungjati",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Banjar Agung",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Banjarturi",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Warurejo",
                "kelurahan": "Demangharjo",
                "kodepos": "52183"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Suradadi",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Kertasari",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Purwahamba",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Sidoharjo",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Karangmulya",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Karangwuluh",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Jatibogor",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Jatimulya",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Harjasari",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Gembongdadi",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Tanjungharja",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Surodadi",
                "kelurahan": "Bojongsana",
                "kodepos": "52182"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Padaharja",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Plumbungan",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Mejasem Barat",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Mejasem Timur",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Munjung Agung",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kramat",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Maribaya",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Ketileng",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kertaharja",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kertayasa",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kemuning",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kepunduhan",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Kemantran",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Dampyak",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Dinuk",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Jatilawang",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Bangun Galih",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Bongkok",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Pesurungan Lor",
                "kodepos": "52147"
            },
            {
                "kecamatan": "Kramat",
                "kelurahan": "Babakan",
                "kodepos": "52181"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Cabawan",
                "kodepos": "52147"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Kaligangsa",
                "kodepos": "52147"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Kalinyamat Kulon",
                "kodepos": "52144"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Krandon",
                "kodepos": "52146"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Margadana",
                "kodepos": "52143"
            },
            {
                "kecamatan": "Margadana",
                "kelurahan": "Sumurpanggang",
                "kodepos": "52141"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Debong Kidul",
                "kodepos": "52138"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Bandung",
                "kodepos": "52137"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Kalinyamat Wetan",
                "kodepos": "52136"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Keturen",
                "kodepos": "52134"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Tunon",
                "kodepos": "52135"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Debong Kulon",
                "kodepos": "52133"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Debong Tengah",
                "kodepos": "52132"
            },
            {
                "kecamatan": "Tegal Selatan",
                "kelurahan": "Randugunting",
                "kodepos": "52131"
            },
            {
                "kecamatan": "Tegal Timur",
                "kelurahan": "Slerok",
                "kodepos": "52125"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Pekauman",
                "kodepos": "52125"
            },
            {
                "kecamatan": "Tegal Timur",
                "kelurahan": "Kejambon",
                "kodepos": "52124"
            },
            {
                "kecamatan": "Tegal Timur",
                "kelurahan": "Mangkukusuman",
                "kodepos": "52123"
            },
            {
                "kecamatan": "Tegal Timur",
                "kelurahan": "Panggung",
                "kodepos": "52122"
            },
            {
                "kecamatan": "Tegal Timur",
                "kelurahan": "Mintaragen",
                "kodepos": "52121"
            },
            {
                "kecamatan": "Adiwerna",
                "kelurahan": "Kalimati",
                "kodepos": "52121"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Muarareja",
                "kodepos": "52117"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Pesurungan Kidul",
                "kodepos": "52117"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Debong Lor",
                "kodepos": "52115"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Kemandungan",
                "kodepos": "52114"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Kraton",
                "kodepos": "52112"
            },
            {
                "kecamatan": "Tegal Barat",
                "kelurahan": "Tegalsari",
                "kodepos": "52111"
            }
        ],
        "k105": [
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Wiyorowetan",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Sukorejo",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Tasikrejo",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Rowosari",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Samong",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Pesantren",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Pagergunung",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Pamutih",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Mojo",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Padek",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Ketapang",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Limbangan",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Kaliprau",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Kertosari",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Botekan",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Bumirejo",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Ambowetan",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Ulujami",
                "kelurahan": "Blendung",
                "kodepos": "52371"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Pendowo",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Pasir",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Payung",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Parunggalih",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Kwasen",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Longkeyang",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Muncang",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Kesesirejo",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Kebandungan",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Kelangdepok",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Karangbrai",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Kebandaran",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Jatiroyom",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Jraganan",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Gunungbatu",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Jatingarang",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Bodeh",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Cangak",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Wonogiri",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Bodeh",
                "kelurahan": "Babakan",
                "kodepos": "52365"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tegalsari Timur",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Ujunggede",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Sokawati",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tegalsari Barat",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Kemuning",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Losari",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Sidokare",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Karangtengah",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Kebagusan",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Cibiyuk",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Jatirejo",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Karangtalok",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Blimbing",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Ampelgading",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Banglarangan",
                "kodepos": "52364"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Tumbal",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Wonokromo",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Sidorejo",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Sikayu",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Susukan",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Purwoharjo",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Purwosari",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Sarwodadi",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Lowa",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Pecangakan",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Kauman",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Kebojongan",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Klegen",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Gintung",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Kandang",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Ambokulon",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Gandu",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Comal",
                "kelurahan": "Gedeg",
                "kodepos": "52363"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Temuireng",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Widodaren",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Sirangkang",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Tegalmlati",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Petarukan",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Serang",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Petanjungan",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Panjunan",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Pegundan",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Pesucen",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Loning",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Nyamplung Sari",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Kendalrejo",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Kendalsari",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Klareyan",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Kendaldoyong",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Iser",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Kalirandu",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Karangasem",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Petarukan",
                "kelurahan": "Bulu",
                "kodepos": "52362"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Wanarejan Selatan",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Wanarejan Utara",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sokawangi",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Taman",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Penggarit",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sitemu",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Pener",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Pedurungan",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kedungbanjar",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kejambon",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Jrakah",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kabunan",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kaligelang",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Jebed Selatan",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Jebed Utara",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Gondang",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Beji",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Cibelok",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Asemdoyong",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Banjaran",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Banjardawa",
                "kodepos": "52361"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Watukumpul",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Wisnu",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Tundagan",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Tambi",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Tlagasana",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Medayu",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Pagelaran",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Jojogan",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Majakerta",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Majalangu",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Cikadu",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Gapura",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Bongas",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Cawet",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Watukumpul",
                "kelurahan": "Bodas",
                "kodepos": "52357"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Mendelem",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Sikasur",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Simpur",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Kuta",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Gunungtiga",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Kalisaleh",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Gombong",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Gunungjaya",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Beluk",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Bulakan",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Belik",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Belik",
                "kelurahan": "Badak",
                "kodepos": "52356"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Pulosari",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Siremeng",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Nyalembeng",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Pagenteran",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Penakir",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Jurangmangu",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Karangsari",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Gambuhan",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Gunungsari",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Cikendung",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Clekatakan",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Pulosari",
                "kelurahan": "Batursari",
                "kodepos": "52355"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Wangkelang",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Warungpring",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Sima",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Walangsanga",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Pepedan",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Plakaran",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Moga",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Mereng",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Pakembaran",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Kebanggan",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Mandiraja",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Datar",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Karangdawa",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Gendowang",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Warungpring",
                "kelurahan": "Cibuyur",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Semingkir",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Tanahbaya",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Moga",
                "kelurahan": "Banyumudal",
                "kodepos": "52354"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Rembul",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Semaya",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Penusupan",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Randudongkal",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Mejagong",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Kreyo",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Lodaya",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Mangli",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Kecepit",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Kejene",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Karangmoncol",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Kalitorong",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Gongseng",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Kalimas",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Wanarata",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Banjaranyar",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Randudongkal",
                "kelurahan": "Gembyang",
                "kodepos": "52353"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Sarwodadi",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Sumurkidang",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Suru",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Pegiringan",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Purana",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Sambeng",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Paguyangan",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Pedagung",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Pabuaran",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Lenggerong",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Karanganyar",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Kebon Gede",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Kuta",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Glandang",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Banjarsari",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Bantarbolang",
                "kelurahan": "Bantarbolang",
                "kodepos": "52352"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Saradan",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Sewaka",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Sungapan",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Wanamulya",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Paduraksa",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Pagongsoran",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Bojongbata",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Bojongbata",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Surajaya",
                "kodepos": "52318"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Banjarmulya",
                "kodepos": "52319"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Tambakrejo",
                "kodepos": "52317"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Bojongnangka",
                "kodepos": "52318"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Kramat",
                "kodepos": "52318"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Widuri",
                "kodepos": "52314"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Sugihwaras",
                "kodepos": "52315"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Lawangrejo",
                "kodepos": "52316"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Mulyoharjo",
                "kodepos": "52313"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Danasari",
                "kodepos": "52314"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Pelutan",
                "kodepos": "52311"
            },
            {
                "kecamatan": "Pemalang",
                "kelurahan": "Kebondalem",
                "kodepos": "52312"
            }
        ],
        "k106": [
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Winduaji",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Taraban",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Wanatirta",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Ragatunjung",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Paguyangan",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Pakujati",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Pandansari",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Kretek",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Pagojengan",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Cipetung",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Kedungoleng",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Paguyangan",
                "kelurahan": "Cilibur",
                "kodepos": "52276"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Windusakti",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Tembongraja",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Wanoja",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Winduasri",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Salem",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Pasir Panjang",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Pabuaran",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Indrajaya",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Kadumanis",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Gunung Tajem",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Gunung Sugih",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Gunung Larang",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Gunung Jaya",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Ganggawang",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Gandoang",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Ciputih",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Citimbang",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Capar",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Bentarsari",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Bentar",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Salem",
                "kelurahan": "Banjaran",
                "kodepos": "52275"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Waru",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Terlaya",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Tambakserang",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Telaga",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Sindangwangi",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Pengarasan",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Legok",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Pangebatan",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Kebandungan",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Karangpari",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Jipang",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Ciomas",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Cinanas",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Cibentang",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Bantarwaru",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Bantarkawung",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Banjarsari",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bantarkawung",
                "kelurahan": "Bangbayang",
                "kodepos": "52274"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Panggarutan",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Pruwatan",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Pamijen",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Negaradaha",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Laren",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Langkap",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Kaliwadas",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Kalisumur",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Kalinusu",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Kalilangkap",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Kalierang",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Jatisawit",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Dukuhturi",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Adisana",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Bumiayu",
                "kelurahan": "Bumiayu",
                "kodepos": "52273"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Wanareja",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Sridadi",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Plompong",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Mlayang",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Mendala",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Manggis",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Kaligiri",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Kaliloka",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Igirklanceng",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Dawuhan",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Benda",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Buniwah",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Watujaya",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Sirampog",
                "kelurahan": "Batursari",
                "kodepos": "52272"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Tanggeran",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Tonjong",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Purbayasa",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Purwodadi",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Rajawetan",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Negarayu",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Pepedan",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Kutayu",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Linggapura",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Kutamendala",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Kalijurang",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Karangjongkeng",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Wanatawang",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Tonjong",
                "kelurahan": "Galuh Timur",
                "kodepos": "52271"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Songgom Lor",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Wanacala",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Jatirokeh",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Karangsembung",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Songgom",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Gegerkunci",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Jatimakmur",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Cenang",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Songgom",
                "kelurahan": "Dukuhmaja",
                "kodepos": "52266"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Tegalreja",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Tiwulandu",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Sindangheula",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Sukareja",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Parireja",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Penanggapan",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Pende",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Kubangjero",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Malahayu",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Kertasari",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cipajang",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Dukuhjeruk",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Karangmaja",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cikuya",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cimunding",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cikakak",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cihaur",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cibuniwangi",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cigadung",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Ciawi",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Cibendung",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Banjar Lor",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Banjarharjo",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Blandongan",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Sutamaja",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Banjarharjo",
                "kelurahan": "Bandungsari",
                "kodepos": "52265"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Sindangjaya",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Pende",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Kubangpari",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Limbangan",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Kradenan",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Kramatsampang",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Kemukten",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Kersana",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Cikandang",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Jagapura",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Ciampel",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Kersana",
                "kelurahan": "Cigedog",
                "kodepos": "52264"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Sindangjaya",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Tanggungsari",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Padakaton",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Pamedaran",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Kubangwungu",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Kubangsari",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Ketanggungan",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Kubangjati",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Karangmalang",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Jemasih",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Karangbandung",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Dukuh Tengah",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Dukuhbadag",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Dukuhturi",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Cikeusal Lor",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Ciseureuh",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Cikeusal Kidul",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Bulakelor",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Ciduwet",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Baros",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Ketanggungan",
                "kelurahan": "Buara",
                "kodepos": "52263"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Slatri",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Wlahar",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Sitanggal",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Siandong",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Pamulihan",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Pamulihan",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Luwunggede",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Karangbale",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Kedungbokor",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Kamal",
                "kodepos": "52262"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Rengasbandung",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Tegalwulung",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Tembelang",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Pedeslohor",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kramat",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Pamengger",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Klampis",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Klikiran",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kendawa",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kertasinduyasa",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kedungtukang",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kemiriamba",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Karanglo",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kebogadung",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kebonagung",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kalialang",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Kalipucang",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatibarang Kidul",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Jatibarang Lor",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Buaran",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Janegara",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Jatibarang",
                "kelurahan": "Bojong",
                "kodepos": "52261"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Radegan",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Randusari",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Rungkang",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Prapag Kidul",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Prapag Lor",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Pengabean",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Losari Lor",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Negla",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Pekauman",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Kedungneng",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Limbangan",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Losari Kidul",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Karangjunti",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Karangsambung",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Kecipir",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Kalibuntu",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Karangdempel",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Bojongsari",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Dukuhsalam",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Jati Sawit",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Babakan",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Losari",
                "kelurahan": "Blubuk",
                "kodepos": "52255"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tegongan",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tengguli",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tanjung",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sengon",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sidakaton",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Pejagan",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Pengaradan",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sarireja",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Mundu",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Luwungbata",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Lemah Abang",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Luwung Gede",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kemurang Wetan",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Krakahan",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kubangputat",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kedawung",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kemurang Kulon",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Tegalglagah",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Karangreja",
                "kodepos": "52254"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Rancawuluh",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Siwuluh",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Petunjungan",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Pulogading",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Luwungragi",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Pakijangan",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Karangsari",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Kluwut",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Jubang",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Dukuhlo",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Grinting",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Cimohong",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Cipelem",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Bulakparen",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Bulusari",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Banjaratma",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Bulakamba",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Bulakamba",
                "kelurahan": "Bangsri",
                "kodepos": "52253"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Tegalgandu",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Wanasari",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Siwungkuk",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Tanjungsari",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Sigentong",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Sisalam",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Siasem",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Sidamulya",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Sawojajar",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Kupu",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Lengkong",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Kertabesuki",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Klampok",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Jagalempeni",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Keboledan",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Glonggong",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Dukuhwringin",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Dumeling",
                "kodepos": "52252"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Pesantunan",
                "kodepos": "52221"
            },
            {
                "kecamatan": "Wanasari",
                "kelurahan": "Pebatan",
                "kodepos": "52222"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Tengki",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Terlangu",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Randusanga Wetan",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Sigambir",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Randusanga Kulon",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Pemaron",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Lembarawa",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Limbangan Kulon",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Pagejugan",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Krasak",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Kalimati",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Kaliwlingi",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Kedunguter",
                "kodepos": "52219"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Kaligangsa Wetan",
                "kodepos": "52217"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Limbangan Wetan",
                "kodepos": "52218"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Banjaranyar",
                "kodepos": "52216"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Kaligangsa Kulon",
                "kodepos": "52217"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Wangandalem",
                "kodepos": "52214"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Gandasuli",
                "kodepos": "52215"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Pulosari",
                "kodepos": "52213"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Padasugih",
                "kodepos": "52214"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Pasar Batang",
                "kodepos": "52211"
            },
            {
                "kecamatan": "Brebes",
                "kelurahan": "Brebes",
                "kodepos": "52212"
            }
        ],
        "k107": [
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Tambahsari",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Tamanrejo",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Tabet",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Sumber Rahayu",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Peron",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Sriwulan",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Pakis",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Pagerwojo",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Ngesrepbalong",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Pagertoyo",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Margosari",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Limbangan",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Jawisari",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Kedungboto",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Gonoharjo",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Limbangan",
                "kelurahan": "Gondang",
                "kodepos": "51383"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Trayu",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Singorojo",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Sukodadi",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Ngareanak",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Merbuh",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Kertosari",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Kalirejo",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Kedungsari",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Kaliputih",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Jerukgiling",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Getas",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Cening",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Cacaban",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Singorojo",
                "kelurahan": "Banyuringin",
                "kodepos": "51382"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Trisobo",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Tampingan",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Purwogondo",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Salamsari",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Pasigitan",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Puguh",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Medono",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Metesih",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Ngabean",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Kliris",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Leban",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Karangmanggis",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Karangmanggis",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Boja",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Campurejo",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Blimbing",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Banjarejo",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Boja",
                "kelurahan": "Bebengan",
                "kodepos": "51381"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Sumberejo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Wonorejo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Sarirejo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Sidomakmur",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Sukomulyo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Protomulyo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Mororejo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Nolokerto",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Plantaran",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Magelung",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kumpulrejo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kutoharjo",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Kedungsuren",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Krajankulon",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Karangtengah",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Kaliwungu Selatan",
                "kelurahan": "Derupono/Darupono",
                "kodepos": "51372"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Turunrejo",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Tosari",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Tunggulsari",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Sidorejo",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Sumur",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Purwokerto",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Rejosari",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Penjalin",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Kertomulyo",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Brangsong",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Kebon Adem",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Brangsong",
                "kelurahan": "Blorok",
                "kodepos": "51371"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Sukomangli",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Wirosari",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Sidodadi",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Sidokumpul",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Plososari",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Selo",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Pagersari",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Pakisan",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Kalilumpang",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Mlatiharjo",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Kalibareng",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Kalices",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Curugsewu",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Patean",
                "kelurahan": "Gedong",
                "kodepos": "51364"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Tamping Winarno",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Trimulyo",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Selokaton",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sukorejo",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Taman Rejo",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Purwo Sari",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Peron",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Pesaren",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Ngadiwarno",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Ngargosari",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kebumen",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Mulyosari",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kalibogor",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kalipakis",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Genting Gunung",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Harjodowo",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Bringinsari",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Damarjati",
                "kodepos": "51363"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Wonodadi",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Tlogopayung",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Wadas",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Mojoagung",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Tirtomulyo",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Kediten",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Manggungmangu",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Jurangagung",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Karanganyar",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Blumah",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Jati",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Plantungan",
                "kelurahan": "Bendosari",
                "kodepos": "51362"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Tambahrejo",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Surokonto Kulon",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Surokonto Wetan",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Petung",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Pucakwangi",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Pageruyung",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Parakan Sebaran",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Kebongembong",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Krikil",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Pagergunung",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Getas Blawong",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Gondoharum",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Bangunsari",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Pagerruyung",
                "kelurahan": "Gebangan",
                "kodepos": "51361"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Winong",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Wonosari",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Sudipayung",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Sumbersari",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Tegorejo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Rejosari",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Putatgede",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Pesawahan",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Pucang Rejo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Puguh",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Pegandon",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Pekuncen",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Penanggulan",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Ngampel Wetan",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Margomulyo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Ngampel Kulon",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Karangmulyo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Kebonagung",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Jatirejo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Gubugsari",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Dempelrejo",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Pegandon",
                "kelurahan": "Dawungsari",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Bojonggede",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Wungurejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ngampel",
                "kelurahan": "Banyuurip",
                "kodepos": "51357"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Tlahab",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Triharjo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Tamangede",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Tejorejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Sedayu",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Sojomerto",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Ringinarum",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Rowobranten",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Poncorejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Pucangrejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Purworejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Pamriyan",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Ngawensari",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Ngerjo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Pagerdawung",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Mojo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Krompaan",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Lumansari",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Kedungasri",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Kedunggading",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Johorejo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Jenarsari",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Gebang",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Gemuhblanten",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Cepokomulyo",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Gemuh",
                "kelurahan": "Galih",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Weleri",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Ringinarum",
                "kelurahan": "Caruban",
                "kodepos": "51356"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Sumberagung",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Tratemulyo",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Sambongsari",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Sidomukti",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Penaruban",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Penyangkringan",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Pucuksari",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Payung",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Nawangsari",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Ngasinan",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Manggungsari",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Montongsari",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Bumiayu",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Karanganom",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Weleri",
                "kelurahan": "Karangdowo",
                "kodepos": "51355"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Tanjungsari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Wonotenggang",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Tambaksari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Tanjunganom",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Sendang Sikucing",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Sendangdawuhan",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Randusari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Rowosari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Kebonsari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Parakan",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Pojoksari",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Jatipurwo",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Gempolsewu",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Truko",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Bulak",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Gebanganom",
                "kodepos": "51354"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Tanjungmojo",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Sendangkulon",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Sukodadi",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Rejosari",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Rejosari",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Laban",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Lebosari",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Kangkung",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Karangmalang Wetan",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Kadilangu",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Kalirejo",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Kaliyoso",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Jungsemi",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Sidomulyo",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Kangkung",
                "kelurahan": "Gebanganom Wetan",
                "kodepos": "51353"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Margorejo",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Pandes",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Podosari",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Korowelang Anyar",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Korowelang Kulon",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Kalirandu Gede",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Karangayu",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Karangsuno",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Juwiring",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Kaliayu",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Damarsari",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Gondang",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Cepiring",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Wonosari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Cepiring",
                "kelurahan": "Botomulyo",
                "kodepos": "51352"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Sukolilan",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Tambakrejo",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Purwokerto",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Purwosari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Margosari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Pidodo Kulon (Widodo Kulon)",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Pidodo Wetan (Widodo Wetan)",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Kumpulrejo",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Lanji",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Magersari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Kartikajaya",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Kebonharjo",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Donosari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Jambearum",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Bangunsari",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Bulugede",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Pakauman",
                "kodepos": "51319"
            },
            {
                "kecamatan": "Patebon",
                "kelurahan": "Bangunrejo",
                "kodepos": "51351"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Ketapang",
                "kodepos": "51319"
            },
            {
                "kecamatan": "Rowosari",
                "kelurahan": "Karangsari",
                "kodepos": "51319"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Banyutowo",
                "kodepos": "51319"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Karang Sari",
                "kodepos": "51319"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Kebondalem",
                "kodepos": "51318"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Trompo",
                "kodepos": "51317"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Kalibuntu Wetan",
                "kodepos": "51318"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Candiroto",
                "kodepos": "51317"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Sukodono",
                "kodepos": "51317"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Jotang",
                "kodepos": "51316"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Tunggulrejo",
                "kodepos": "51316"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Jetis",
                "kodepos": "51315"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Sijeruk",
                "kodepos": "51315"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Pegulon",
                "kodepos": "51313"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Bugangin",
                "kodepos": "51314"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Langenharjo",
                "kodepos": "51314"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Balok",
                "kodepos": "51312"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Bandengan",
                "kodepos": "51312"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Ngilir",
                "kodepos": "51311"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Patukangan",
                "kodepos": "51311"
            }
        ],
        "k108": [
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Yosorejo",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Timbang",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Sidorejo",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Surodadi",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Sawangan",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Sentul",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Plelen",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Penundan",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Lebo",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Mentosari",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Krengseng",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Kutosari",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Ketanggan",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Gringsing",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Gringsing",
                "kelurahan": "Kebondalem",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Banaran",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Bulu",
                "kodepos": "51281"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Wono Sari",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Soka",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Surjo",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Sangubanyu",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Sibebek",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Sidoharjo",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Pranten",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Purbo",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Pangempon",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Pasusukan",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Kalirejo",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Kebaturan",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Jlamprang",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Jambangan",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Deles",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Getas",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Gunungsari",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Candigugur",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Candirejo",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Wonosobo",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Bawang",
                "kelurahan": "Bawang",
                "kodepos": "51274"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Tambakboyo",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Wonorojo",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Semampir",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Sojomerto",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Sukomangli",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Polodoro",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Reban",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Ngroto",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Pacet",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Padomasan",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Kumesu (Kemesu)",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Mojotengah",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Ngadirejo",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Kepundung",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Karanganyar",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Cablikan",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Kalisari",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Adinuso",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Wanar",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Reban",
                "kelurahan": "Adinuso",
                "kodepos": "51273"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Tedunan",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Tegalombo",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Sidalang",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Sumurbanger",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Tanjungsari",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Rejosari Timur",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Satriyan",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Sendang",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Pujut",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Rejosari Barat",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Margosono",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Plosowangi",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Kranggan",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Lobang",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Madugowongjati",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Harjowinangun Timur",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Kebumen",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Harjowinangun Barat",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Gondo",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Harjowinangun Barat",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Tersono",
                "kelurahan": "Banteng",
                "kodepos": "51272"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Sidomulyo",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Sukorejo",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Tembok",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Sempu",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Sembung",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Rowosari",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Plumbon",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Pungangan",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Luwung",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Ngaliyan",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Kedawung",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Kepuh",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Limpung",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Kalibalik",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Kalangsono",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Kalisalak",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Dlimas",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Dlisen",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Donorejo",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Babadan",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Banyuputih",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Limpung",
                "kelurahan": "Amongrogo",
                "kodepos": "51271"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Subah",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Tenggulangharjo",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Sengon",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Siberuk",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Siguci",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Pretek",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Randu",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Selokarto",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Menjangan",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Pecalungan",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kuripan",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Mangunharjo",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Keniten",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kemiri Timur",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kumejing",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kebumen",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kemiri Barat",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Keborangan",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Karangtengah",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Jrakahpayung",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Kalimanggis",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Jatisari",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Jolosekti",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Gumawang",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Gondang",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Gombong",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Gemuh",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Cluwuk",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Durenombo",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Clapar",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Pecalungan",
                "kelurahan": "Bandung",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Adinuso",
                "kodepos": "51262"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Wringingintung",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Ujungnegoro",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Wonokerso",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Tulis",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Tragung",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Tegalsari",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Simbangdesa",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Simbangjati",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Sembojo",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Posong",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Ponowareng",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Lawangaji",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Kedungsegog",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Kenconorejo",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Kandeman",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Karanganom",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Karanggeneng",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Kaliboyo",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Juragan",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Cempereng",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Depok",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Botolambat",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Tulis",
                "kelurahan": "Beji",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Kandeman",
                "kelurahan": "Bakalan",
                "kodepos": "51261"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Wonorojo",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Selopajang Timur",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Wonobodro",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Pesantren",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Selopajang Barat",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Keputon",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Keteleng",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Kambangan (Kembangan)",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Kembanglangit",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Kalisari",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Kalitengah",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Gondang",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Kalipancur",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Gerlang",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Blado",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Cokro",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Bismo",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Bawang",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Blado",
                "kelurahan": "Besani",
                "kodepos": "51255"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Wonosegoro",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Wonokerto",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Wonomerto",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Wonodadi",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Toso",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tumbrep",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tombo",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Simpar",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tambahrejo",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pesalakan",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pucanggading",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Sidayu",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Manggis",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Candi",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Kluwih",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Batiombo",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Binangun",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Wonotunggal",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bandar",
                "kodepos": "51254"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Wates",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Sodong",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Siwatu",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Sigayam",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Silurah",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Sendang",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Kreyo",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Penangkan",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Kemligi",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Gringgingsari",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Kedungmalang",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Brokoh",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Dringo",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Warungasem",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Wonotunggal",
                "kelurahan": "Brayo",
                "kodepos": "51253"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Terban",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Sijono",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Sidorejo",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Sariglagah",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Sawahjoho",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Pesaren",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Pandansari",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Pejambon",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Masin",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Menguneng",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Lebo",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Gapuro",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Kalibeluk",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Kaliwareng",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Cepagan",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Banjiran",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Warungasem",
                "kelurahan": "Candiareng",
                "kodepos": "51252"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Pasekaran",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Rowobelang",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Watesalit",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Klidang Lor",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Klidang Wetan",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Karangasem Utara",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kecepak",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Karanganyar",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Karangasem Selatan",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kalisalak",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kalipucang Wetan",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Denasri Wetan",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kalipucang Kulon",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Denasri Kulon",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Cepokokuning",
                "kodepos": "51216"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kauman",
                "kodepos": "51215"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Sambong",
                "kodepos": "51212"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kasepuhan",
                "kodepos": "51214"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Proyonanggan Utara",
                "kodepos": "51211"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Proyonanggan Selatan",
                "kodepos": "51211"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Proyonanggan Tengah",
                "kodepos": "51211"
            }
        ],
        "k109": [
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Yosorejo",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Songgodadi",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Tlogohendro",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Tlogopakis",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Simego",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Kasimpar",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Kayupuring",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Gumelem",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Petungkriono/Petungkriyono",
                "kelurahan": "Curugmuncar",
                "kodepos": "51193"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Talun",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Sengare",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Mesoyi",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Karangasem",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Krompeng",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Kalirejo",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Jolotigo",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Donowangun",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Batursari",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Wringin Agung",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Banjarsari",
                "kodepos": "51192"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Sidoharjo",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Sawangan",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Rogoselo",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Pungangan",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Randusari",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Larikan",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Lemah Abang",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Kalimojosari",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Kutosari",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Dororejo",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Harjosari",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Doro",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Doro",
                "kelurahan": "Bligorejo",
                "kodepos": "51191"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Wonosido",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Timbangsari",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Tembelang Gunung",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Pamutuh",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Sidomulyo",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Lebakbarang",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Mendolo",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Kutorembet",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Depok",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Kapundutan",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Lebakbarang",
                "kelurahan": "Bantar Kulon",
                "kodepos": "51183"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Sukosari",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Wonosari",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Pododadi",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Sidomukti",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Lolong",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Pedawang",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Limbangan",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Legok Kalong",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kayugeritan",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kulu",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Kutosari",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Karanganyar",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karangsari",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Gutomo",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karang Gondang",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Banjarejo",
                "kodepos": "51182"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Wonopringgo",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Wonorejo",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Sastrodirjan",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Surabayan",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Rowokembu",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Sampih",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Legok Gunung",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Pegaden Tengah",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Kwagean",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Jetak Kidul",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Jetak Lengkong",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Getas",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Gondang",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Wonopringgo",
                "kelurahan": "Galang Pengampon Gede",
                "kodepos": "51181"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Pegandon",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Pagumengan Mas",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Pangkah",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Kedung Kebo",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Logandeng",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Kebonrowo Pucang",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Kebonsari",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Kalilembu",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Karangdadap",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Kaligawe",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Karangdadap",
                "kelurahan": "Jrebeng Kembang",
                "kodepos": "51174"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Tosaran",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Tangkil Kulon",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Tangkil Tengah",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Rowocacing",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Salakbrojo",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Proto",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Rengas",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Pekajangan",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Podo",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Pajomblangan",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Pakisputih",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Langkap",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Kwayangan",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Kedungwuni Barat",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Kedungwuni Timur",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Karangdowo",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Kedung Patangewu",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Ambokembang",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Kedungwuni",
                "kelurahan": "Bugangan/Bungangan",
                "kodepos": "51173"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Simbang Wetan",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Watusalam",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Wonoyoso",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Sapugarut",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Simbang Kulon",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Pakumbulan",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Paweden",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Kertijayan",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Coprayan",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Buaran",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Winduaji",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Buaran",
                "kelurahan": "Bligo",
                "kodepos": "51171"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Tenogo",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Werdi",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Tanggeran",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Depok",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Paninggaran",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Sawangan",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Boyo Teluk",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Notogiwang",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Blimbing Wuluh",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Lomeneng",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Blacanan",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Kuripan Lor",
                "kodepos": "51136"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Krandegan",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Lambanggelun",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Kuripan Kidul",
                "kodepos": "51135"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Kaliombo",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Kertoharjo",
                "kodepos": "51134"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Kaliboja",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Jenggot",
                "kodepos": "51133"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Domiyang",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Kradenan",
                "kodepos": "51132"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Botosari",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Paninggaran",
                "kelurahan": "Bedagung",
                "kodepos": "51164"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Sugihwaras",
                "kodepos": "51129"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Wangkelang",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Sokorejo",
                "kodepos": "51129"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Landungsari",
                "kodepos": "51129"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Trajumas",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Noyontaan",
                "kodepos": "51129"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Sukoharjo",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Baros",
                "kodepos": "51129"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Tajur",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Luragung",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Keputran",
                "kodepos": "51128"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Lambur",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Klesem",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Kauman",
                "kodepos": "51127"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Karanggondang",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Kauman",
                "kodepos": "51127"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Sampangan",
                "kodepos": "51126"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Kandangserang",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Klego",
                "kodepos": "51124"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Gembong",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Gamer",
                "kodepos": "51123"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Garungwiyoro",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Dekoro",
                "kodepos": "51123"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Bojongkoneng",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Bubak",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Poncol",
                "kodepos": "51122"
            },
            {
                "kecamatan": "Kandangserang",
                "kelurahan": "Bodas",
                "kodepos": "51163"
            },
            {
                "kecamatan": "Pekalongan Timur",
                "kelurahan": "Karangmalang",
                "kodepos": "51122"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Bendan",
                "kodepos": "51119"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Bendan",
                "kodepos": "51119"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Watupayung",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Pasirsari",
                "kodepos": "51118"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Watugajah",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Ujung Negoro",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Pringlangu",
                "kodepos": "51117"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Kramatsari",
                "kodepos": "51118"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Sukorejo",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Kraton Kidul",
                "kodepos": "51117"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Srinahan",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Bumirejo",
                "kodepos": "51117"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Sidomulyo",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Sidosari",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Kergon",
                "kodepos": "51113"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Tegalrejo",
                "kodepos": "51116"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Ponolawen",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Podosari",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Kebulen",
                "kodepos": "51112"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Sapuro",
                "kodepos": "51112"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Pantirejo",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Podosugih",
                "kodepos": "51111"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Langensari",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Mulyorejo",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Medono",
                "kodepos": "51111"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Kwigaran",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Kwasen",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Kesesi",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Krandon",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Karyomukti",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Karangrejo",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Kaibahan",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Kalimade",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Jagung",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kesesi",
                "kelurahan": "Brondong",
                "kodepos": "51162"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Wonorejo",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Tanjungsari",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Tanjung Kulon",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Sokoyoso",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Tambakroto",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Sinangoh Prendeng",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Sangkan Joyo",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Sambiroto",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Sabarwangi",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Pringsurat",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Rowolaku",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Pekiringan Alit",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Pekiringan Ageng",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Nyamok",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kutorojo",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Linggoasri",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kutorejo",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kebon Agung",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kajongan",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kalijoyo",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Kajen",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Gejlig",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Gandarum",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Kajen",
                "kelurahan": "Brengkolang",
                "kodepos": "51161"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Wiroditan",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Wangandowo",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sumur Jomblangbogo",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Rejosari",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Sembung Jambu",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Randu Muktiwaren",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Menjangan",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Pantianom",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Legok Clile",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Ketitanglor",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Ketitang Kidul",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kemasan",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Karangsari",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Kalipancur",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Jajar Wayang",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Duwet",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bukur",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojonglor",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong Minggir",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Bojong Wetan",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Babalanlor",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Bojong",
                "kelurahan": "Babalan Kidul",
                "kodepos": "51156"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Tegalontar",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Tegal Suruh",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sumub Lor",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sumub Kidul",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sragi",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sijeruk",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Purworejo",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Purwodadi",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Mrican",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Krasakageng",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Klunjukan",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Ketanon Ageng",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Kedungjaran",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Gebangkerep",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Kalijambe",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Bulaksari",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Bulak Pelem",
                "kodepos": "51155"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Wonokerto Wetan",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Wonokerto Kulon",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Werdi",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Tratebang",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Sijambe",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Semut",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Rowoyoso",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Pesanggrahan",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Bebel",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Pecakaran",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wonokerto",
                "kelurahan": "Api-Api",
                "kodepos": "51153"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Wiradesa",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Warukidul",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Warulor",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Petukangan",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Pekuncen",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Mayangan",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Kepatihan",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Karangjati",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Kemplong",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Kampil",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Kadipaten",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Gumawang",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Delegtukang",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Bondansari",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Wiradesa",
                "kelurahan": "Bener",
                "kodepos": "51152"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Wuled",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Pekalongan Barat",
                "kelurahan": "Tirto",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Tegaldowo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Tanjung",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Silirejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Sidorejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Pucung",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Samborejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Pandan Arum",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Pacar",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Ngalian",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Mulyorejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Mulyorejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Karangjompo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Jeruksari",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Dadirejo",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Tirto",
                "kelurahan": "Curug",
                "kodepos": "51151"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Krapyak Lor",
                "kodepos": "51149"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Kandangpanjang",
                "kodepos": "51149"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Degayu",
                "kodepos": "51148"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Krapyak Kidul",
                "kodepos": "51147"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Dukuh",
                "kodepos": "51146"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Kraton Lor",
                "kodepos": "51145"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Pabean",
                "kodepos": "51144"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Bandengan",
                "kodepos": "51143"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Panjang Wetan",
                "kodepos": "51141"
            },
            {
                "kecamatan": "Pekalongan Utara",
                "kelurahan": "Panjang Baru",
                "kodepos": "51141"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Soko",
                "kodepos": "51139"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Banyurip Alit",
                "kodepos": "51139"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Banyurip Ageng",
                "kodepos": "51139"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Duwet",
                "kodepos": "51138"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Yosorejo",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Pekalongan Selatan",
                "kelurahan": "Yosorejo",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Wonosari",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Tunjungsari",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Tengeng Wetan",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Tengengkulon",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Siwalan",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Rembun",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Pait",
                "kodepos": "51137"
            },
            {
                "kecamatan": "Siwalan",
                "kelurahan": "Mejasem",
                "kodepos": "51137"
            }
        ],
        "k110": [
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Jimbaran",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Harjosari",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Doplang",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Timpik",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Bawen",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Asinan",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Tawang",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Pojoksari",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Tegalsari",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Sidoharjo",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Susukan",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Pasekan",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Ngasinan",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Panjang",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Muncar",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Mlilir",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Ketapang",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Koripan",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Ngampin",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Jetis",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Kenteng",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kemetul",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Kenteng",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Duren",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Gentan",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Bakalrejo",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Candi",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Susukan",
                "kelurahan": "Badran",
                "kodepos": "50777"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Baran",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Bejalen",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Suruh",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Bandungan",
                "kodepos": "50614"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Kranggan",
                "kodepos": "50613"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Sukorejo",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Jagalan",
                "kodepos": "50613"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Reksosari",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Tambakboyo",
                "kodepos": "50612"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Medayu",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Medayu",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Purworejo",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Losari",
                "kodepos": "50611"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Ketanggi",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Kupang",
                "kodepos": "50612"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Krandon Lor",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Kedungringin",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Lodoyong",
                "kodepos": "50611"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Kebowan",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Wujil",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Jatirejo",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Wringin Putih",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Gunung Tumpeng",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Randugunting",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Dersansari",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Pagersari",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Dadapayam",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Ngempon",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Cukilan",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Bonomerto",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Munding",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Beji Lor",
                "kodepos": "50776"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Klepu",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Karangjati",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Jatijajar",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Tengaran",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Diwak",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Gebugan",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Sugihan",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Tegalwaton",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Bergas Lor",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Sruwen",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Bergas Kidul",
                "kodepos": "50552"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Regunung",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Nyatnyono",
                "kodepos": "50551"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Patemon",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Gogik",
                "kodepos": "50551"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Nyamat",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Klero",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Mluweh",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Karangduren",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Mijen",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Duren",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Leyangan",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Cukil",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Butuh",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Lerep",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Keji",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Bener",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Kawengen",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Barukan",
                "kodepos": "50775"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Kalongan",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Kalisidi",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Wates",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Tolokan",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Tajuk",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Kalikayen",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Gedanganak",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Sumogawe",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Branjang",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Samirono",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Beji",
                "kodepos": "50519"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Polobogo",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Nogosaren",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Langensari",
                "kodepos": "50518"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Ngrawan",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Bandarjo",
                "kodepos": "50517"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Manggihan",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Susukan",
                "kodepos": "50516"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Kopeng",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Kalirejo",
                "kodepos": "50515"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Jetak",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Timur",
                "kelurahan": "Sidomulyo",
                "kodepos": "50514"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Penawangan",
                "kodepos": "50513"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Getasan",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Getasan",
                "kelurahan": "Batur",
                "kodepos": "50774"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Candirejo",
                "kodepos": "50513"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Watuagung",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Tuntang",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Genuk",
                "kodepos": "50512"
            },
            {
                "kecamatan": "Ungaran Barat",
                "kelurahan": "Ungaran",
                "kodepos": "50511"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Tlompakan",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Sraten",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Rowosari",
                "kodepos": "50279"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Kramas",
                "kodepos": "50278"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Rowosari",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Ngajaran",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Bulusan",
                "kodepos": "50277"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Lopait",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Sambiroto",
                "kodepos": "50276"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Tembalang",
                "kodepos": "50275"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Karanganyar",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Kesongo",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Tandang",
                "kodepos": "50274"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Kalibeji",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Jatisari",
                "kodepos": "50275"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Jangli",
                "kodepos": "50274"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Jombor",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Gedangan",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Sendangguwo",
                "kodepos": "50273"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Delik",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Candirejo",
                "kodepos": "50773"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Kedungmundu",
                "kodepos": "50273"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Sendangmulyo",
                "kodepos": "50272"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Wiru",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Mangunharjo",
                "kodepos": "50272"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Truko",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Tembalang",
                "kelurahan": "Meteseh",
                "kodepos": "50271"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Tempuran",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Genting",
                "kodepos": "50271"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Tanjung",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Sumurboto",
                "kodepos": "50269"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Sendang",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Pedalangan",
                "kodepos": "50268"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Sambirejo",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Padangsari",
                "kodepos": "50267"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Popongan",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Jabungan",
                "kodepos": "50266"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Pakis",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Gedawang",
                "kodepos": "50266"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Nyemoh",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Lebak",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Banyumanik",
                "kodepos": "50264"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Pudakpayung",
                "kodepos": "50265"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Kalikurmo",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Srondol Wetan",
                "kodepos": "50263"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Kalijambe",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Gogodalem",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Srondol Kulon",
                "kodepos": "50263"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Bringin",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Tinjomoyo",
                "kodepos": "50262"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Ujung-Ujung",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Banyumanik",
                "kelurahan": "Ngesrep",
                "kodepos": "50261"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Banding",
                "kodepos": "50772"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Tukang",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Candi",
                "kodepos": "50257"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Terban",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Jomblang",
                "kodepos": "50256"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Sumberejo",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Karanganyar Gunung",
                "kodepos": "50255"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Sukoharjo",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Jatingaleh",
                "kodepos": "50254"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Semowo",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Kaliwiru",
                "kodepos": "50253"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Segiri",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Candisari",
                "kelurahan": "Wonotingal",
                "kodepos": "50252"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Padaan",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Kauman Lor",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Pabelan",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Pandean Lamper",
                "kodepos": "50249"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Mugassari",
                "kodepos": "50249"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Karanggondang",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Lamper Lor",
                "kodepos": "50249"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Kadirejo",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Jembrak",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Lamper Kidul",
                "kodepos": "50249"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Glawan",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Lamper Tengah",
                "kodepos": "50248"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Gayamsari",
                "kodepos": "50248"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Palebon",
                "kodepos": "50246"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Bendungan",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Giling",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Gemah",
                "kodepos": "50246"
            },
            {
                "kecamatan": "Pabelan",
                "kelurahan": "Bejaten",
                "kodepos": "50771"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Bulustalan",
                "kodepos": "50246"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Barusari",
                "kodepos": "50245"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Randusari",
                "kodepos": "50244"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Wonodri",
                "kodepos": "50242"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Peterongan",
                "kodepos": "50242"
            },
            {
                "kecamatan": "Semarang Selatan",
                "kelurahan": "Pleburan",
                "kodepos": "50241"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Pekunden",
                "kodepos": "50241"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Petompon",
                "kodepos": "50237"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Karang Rejo",
                "kodepos": "50235"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Bendan Duwur",
                "kodepos": "50235"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Bendan Ngisor",
                "kodepos": "50233"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Sampangan",
                "kodepos": "50232"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Karangtempel",
                "kodepos": "50232"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Gajah Mungkur",
                "kodepos": "50232"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Lempongsari",
                "kodepos": "50231"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Genuksari",
                "kodepos": "50231"
            },
            {
                "kecamatan": "Gajah Mungkur",
                "kelurahan": "Bendungan",
                "kodepos": "50231"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Siwal",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Udanwuh",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Sekaran",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Rogomulyo",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Pungangan",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Payungan",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Papringan",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Wirogomo",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Pager",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Tegaron",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Mukiran",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Sepakung",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Rowoboni",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kener",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kaliwungu",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Kalisegoro",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Ngrapah",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Kemambang",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Jetis",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Kebumen",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Kebondowo",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Gunung Pati",
                "kodepos": "50229"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Patemon",
                "kodepos": "50228"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Ngijo",
                "kodepos": "50228"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Gedong",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Kemijen",
                "kodepos": "50228"
            },
            {
                "kecamatan": "Banyubiru",
                "kelurahan": "Banyubiru",
                "kodepos": "50664"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Rejomulyo",
                "kodepos": "50227"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Rejosari",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Kuwarasan",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Pakintelan",
                "kodepos": "50227"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Kebondalem",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Kelurahan",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Mangunsari",
                "kodepos": "50227"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Jambu",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Sumurejo",
                "kodepos": "50226"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Gondoriyo",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Tengaran",
                "kelurahan": "Tegalrejo",
                "kodepos": "50225"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Gemawang",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Plalangan",
                "kodepos": "50225"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Brongkol",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Nongkosawit",
                "kodepos": "50224"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Jatirejo",
                "kodepos": "50223"
            },
            {
                "kecamatan": "Jambu",
                "kelurahan": "Bedono",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Cepoko",
                "kodepos": "50223"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Banyukuning",
                "kodepos": "50663"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Sadeng",
                "kodepos": "50222"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Trayu",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Kandri",
                "kodepos": "50222"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Sumowono",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Gunungpati",
                "kelurahan": "Sukorejo",
                "kodepos": "50221"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Pledokan",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Piyanggang",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Kaliwungu",
                "kelurahan": "Kradenan",
                "kodepos": "50221"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Ngadikerso",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Tlogo",
                "kodepos": "50219"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Mendongan",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Jatibarang",
                "kodepos": "50219"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Lanjan",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Rembes",
                "kodepos": "50218"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Keseneng",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Purwosari",
                "kodepos": "50217"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Kemitir",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Polaman",
                "kodepos": "50217"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Kemawi",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Karangmalang",
                "kodepos": "50216"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Jubelan",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Cangkiran",
                "kodepos": "50216"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Duren",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Bubakan",
                "kodepos": "50216"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Candigaron",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Wonolopo",
                "kodepos": "50215"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Bumen",
                "kodepos": "50662"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Sidomukti",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Sidomukti",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Samban",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Wonoyoso",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Wonoplumbon",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Poncoruso",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Pringsari",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Polosiri",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Bandungan",
                "kelurahan": "Pakopen",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Pringapus",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Lemahireng",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Jatirunggo",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Bawen",
                "kelurahan": "Kandangan",
                "kodepos": "50661"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Derekan",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Candirejo",
                "kodepos": "50214"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Ngadirgo",
                "kodepos": "50213"
            },
            {
                "kecamatan": "Pringapus",
                "kelurahan": "Wonorejo",
                "kodepos": "50212"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Pesantren",
                "kodepos": "50212"
            },
            {
                "kecamatan": "Mijen",
                "kelurahan": "Kedungpani",
                "kodepos": "50211"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Bambankerep",
                "kodepos": "50211"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Plamongan Sari",
                "kodepos": "50199"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Kalicari",
                "kodepos": "50198"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Muktiharjo Kidul",
                "kodepos": "50197"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Tlogosari Kulon",
                "kodepos": "50196"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Tlogosari Wetan",
                "kodepos": "50196"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Pedurungan Tengah",
                "kodepos": "50192"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Penggaron Kidul",
                "kodepos": "50194"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Pedurungan Kidul",
                "kodepos": "50192"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Pedurungan Lor",
                "kodepos": "50192"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Bringin",
                "kodepos": "50189"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Wates",
                "kodepos": "50188"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Kauman",
                "kodepos": "50188"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Podorejo",
                "kodepos": "50187"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Gondoriyo",
                "kodepos": "50187"
            },
            {
                "kecamatan": "Bergas",
                "kelurahan": "Gondoriyo",
                "kodepos": "50187"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Plumbon",
                "kodepos": "50186"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Tugurejo",
                "kodepos": "50185"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Tambakaji",
                "kodepos": "50185"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Purwoyoso",
                "kodepos": "50184"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Kembangarum",
                "kodepos": "50183"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Wonokerto",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Kalipancur",
                "kodepos": "50183"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Pucung",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Plumutan",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Lembu",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Jlumpang",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Boto",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Bantal",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Bancak",
                "kodepos": "50182"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Randu Garut",
                "kodepos": "50181"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Bulu Lor",
                "kodepos": "50179"
            },
            {
                "kecamatan": "Ngaliyan",
                "kelurahan": "Ngaliyan",
                "kodepos": "50181"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Panggung Lor",
                "kodepos": "50177"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Panggung Kidul",
                "kodepos": "50178"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Tanjungmas",
                "kodepos": "50174"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Bandarharjo",
                "kodepos": "50175"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Kuningan",
                "kodepos": "50176"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Dadapsari",
                "kodepos": "50173"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Tambakrejo",
                "kodepos": "50174"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Purwosari",
                "kodepos": "50172"
            },
            {
                "kecamatan": "Semarang Utara",
                "kelurahan": "Plombokan",
                "kodepos": "50171"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Sambirejo",
                "kodepos": "50166"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Sawahbesar",
                "kodepos": "50163"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Kaligawe",
                "kodepos": "50164"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Mangkang Wetan",
                "kodepos": "50156"
            },
            {
                "kecamatan": "Gayamsari",
                "kelurahan": "Siwalan",
                "kodepos": "50162"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Mangunharjo",
                "kodepos": "50154"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Mangkang Kulon",
                "kodepos": "50155"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Jerakah",
                "kodepos": "50151"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Karanganyar",
                "kodepos": "50152"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Salamanmloyo",
                "kodepos": "50149"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Tambak Harjo",
                "kodepos": "50149"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Kalibanteng Kidul",
                "kodepos": "50149"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Karang Ayu",
                "kodepos": "50149"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Bongsari",
                "kodepos": "50148"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Ngemplak Simongan",
                "kodepos": "50148"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Gisikdrono",
                "kodepos": "50149"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Krapyak",
                "kodepos": "50146"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Manyaran",
                "kodepos": "50147"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Tawangsari",
                "kodepos": "50144"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Kalibanteng Kulon",
                "kodepos": "50145"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Krobokan",
                "kodepos": "50141"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Tawangmas",
                "kodepos": "50144"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Cabean",
                "kodepos": "50141"
            },
            {
                "kecamatan": "Semarang Barat",
                "kelurahan": "Bojongsalaman",
                "kodepos": "50141"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Bangunharjo",
                "kodepos": "50138"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Kranggan",
                "kodepos": "50139"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Pandansari",
                "kodepos": "50139"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Gabahan",
                "kodepos": "50135"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Purwodinatan",
                "kodepos": "50137"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Miroto",
                "kodepos": "50134"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Brumbungan",
                "kodepos": "50135"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Sekayu",
                "kodepos": "50132"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Kembangsari",
                "kodepos": "50133"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Pendrikan Kidul",
                "kodepos": "50131"
            },
            {
                "kecamatan": "Semarang Tengah",
                "kelurahan": "Pendrikan Lor",
                "kodepos": "50131"
            },
            {
                "kecamatan": "Tuntang",
                "kelurahan": "Karangtengah",
                "kodepos": "50131"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Mlatiharjo",
                "kodepos": "50126"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Rejosari",
                "kodepos": "50125"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Bugangan",
                "kodepos": "50126"
            },
            {
                "kecamatan": "Bancak",
                "kelurahan": "Rejosari",
                "kodepos": "50125"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Sarirejo",
                "kodepos": "50124"
            },
            {
                "kecamatan": "Sumowono",
                "kelurahan": "Kebonagung",
                "kodepos": "50123"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Karangturi",
                "kodepos": "50124"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Mlatibaru",
                "kodepos": "50122"
            },
            {
                "kecamatan": "Semarang Timur",
                "kelurahan": "Kebonagung",
                "kodepos": "50123"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Gebangsari",
                "kodepos": "50117"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Karangroto",
                "kodepos": "50117"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Trimulyo",
                "kodepos": "50118"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Sembungharjo",
                "kodepos": "50116"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Banjardowo",
                "kodepos": "50117"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Bangetayu Wetan",
                "kodepos": "50115"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Kudu",
                "kodepos": "50116"
            },
            {
                "kecamatan": "Pedurungan",
                "kelurahan": "Tlogomulyo",
                "kodepos": "50113"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Bangetayu Kulon",
                "kodepos": "50115"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Terboyo Wetan",
                "kodepos": "50112"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Penggaron Lor",
                "kodepos": "50113"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Muktiharjo Lor",
                "kodepos": "50111"
            },
            {
                "kecamatan": "Genuk",
                "kelurahan": "Terboyo Kulon",
                "kodepos": "50112"
            }
        ],
        "k111": [
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Tingkir Lor",
                "kodepos": "50746"
            },
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Tingkir Tengah",
                "kodepos": "50745"
            },
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Kalibening",
                "kodepos": "50744"
            },
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Gendongan",
                "kodepos": "50743"
            },
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Kutowinangun",
                "kodepos": "50742"
            },
            {
                "kecamatan": "Tingkir",
                "kelurahan": "Sidorejo Kidul",
                "kodepos": "50741"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Noborejo",
                "kodepos": "50736"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Cebongan",
                "kodepos": "50736"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Cebongan",
                "kodepos": "50736"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Randuacir",
                "kodepos": "50735"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Kumpulrejo",
                "kodepos": "50734"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Tegalrejo",
                "kodepos": "50733"
            },
            {
                "kecamatan": "Argomulyo",
                "kelurahan": "Ledok",
                "kodepos": "50732"
            },
            {
                "kecamatan": "Sidomukti",
                "kelurahan": "Kalicacing",
                "kodepos": "50724"
            },
            {
                "kecamatan": "Sidomukti",
                "kelurahan": "Kecandran",
                "kodepos": "50723"
            },
            {
                "kecamatan": "Sidomukti",
                "kelurahan": "Dukuh",
                "kodepos": "50722"
            },
            {
                "kecamatan": "Sidomukti",
                "kelurahan": "Mangunsari",
                "kodepos": "50721"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Pulutan",
                "kodepos": "50716"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Blotongan",
                "kodepos": "50715"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Sidorejo Lor",
                "kodepos": "50714"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Bugel",
                "kodepos": "50713"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Kauman Kidul",
                "kodepos": "50712"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Salatiga",
                "kodepos": "50711"
            }
        ],
        "k112": [
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Tanjungkiaok",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Sasiil (Saseel)",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Sepanjang",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Sakala",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Sapeken",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Paliat",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Sabuntan",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Pagerungan Besar",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Sapeken",
                "kelurahan": "Pagerungan Kecil",
                "kodepos": "69493"
            },
            {
                "kecamatan": "Masalembu",
                "kelurahan": "Sukajeruk",
                "kodepos": "69492"
            },
            {
                "kecamatan": "Masalembu",
                "kelurahan": "Massalima",
                "kodepos": "69492"
            },
            {
                "kecamatan": "Masalembu",
                "kelurahan": "Masakambing",
                "kodepos": "69492"
            },
            {
                "kecamatan": "Masalembu",
                "kelurahan": "Karamian",
                "kodepos": "69492"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Timur Janjang",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Toerjek (Torjek)",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Tembayangan",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Sumbernangka",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Sawahsumur",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Saobi",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Sambakati",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Pandeman",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Paseraman",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Pajenangger",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Laok Jangjang",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Pabian",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kolo Kolo",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Kangayan",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kalisangka",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kalikatak",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Jukong Jukong",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kalianyar",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Gelaman",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Duko",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Daandung",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Buddi",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Cangkramaan (Cangkreman)",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Bilis Bilis",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Kangayan",
                "kelurahan": "Batuputih",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Arjasa",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Angkatan",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Angon Angon",
                "kodepos": "69491"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Tonduk",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Ketupat",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Poteran",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Karangnangka",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Karopoh",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Guwa Guwa",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Jungkat",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Brakas",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Tanahmerah",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Ra'as (Raas)",
                "kelurahan": "Alasmalang",
                "kodepos": "69485"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Talaga",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Sonok",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Sokarame Timur",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Somber",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Sokarame Paseser",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Rosong",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Nonggunong",
                "kelurahan": "Nonggunong",
                "kodepos": "69484"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Tarebung",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Prambanan",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Nyamplong",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Pancor",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Karangtengah",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Kalowang",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Jambuir",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Gendang Timur",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Gendang Barat",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Gayam",
                "kodepos": "69483"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Lombang",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Jate",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Gedugan",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Bringsang",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Galis",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Banbaru",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Banmaleng",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Gili Ginting (Giligenteng)",
                "kelurahan": "Aenganyar",
                "kodepos": "69482"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Palasa",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Poteran",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Talango",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Kombang",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Padike",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Gapurana",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Cabbiya",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Talango",
                "kelurahan": "Essang",
                "kodepos": "69481"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Romben Rana",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Tamansare",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Romben Barat",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Romben Guna",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Lapa Taman",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Lapa Daya",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Lapa Laok",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Dungkek",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Jadung",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Bunpenang",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Candi",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Bungin Bungin",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Bicabi",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Banraas",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Dungkek",
                "kelurahan": "Bancamara",
                "kodepos": "69474"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Nyabakan Timur",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Tamedung",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Totosan",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Lombang",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Nyabakan Barat",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Legung Timur",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Legung Barat",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Kolpo",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Jenangger",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Jangkong",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Dapenda",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Bilangan",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Batangbatang Laok",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Banuaju Timur",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Batangbatang Daya",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Batang Batang",
                "kelurahan": "Banuaju Barat",
                "kodepos": "69473"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Panagan",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Poja",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Mandala",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Palokloan (Paloloan)",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Karang Budi",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Longos",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Grujugan",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Gapura Tengah",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Gapura Timur",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Gersik Putih",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Beraji",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Gapura Barat",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Banjar Timur",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Batudinding",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Banjar Barat",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Andulang",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Gapura",
                "kelurahan": "Baban",
                "kodepos": "69472"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Marengan Laok",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Pinggirpapas",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Kertasada",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Kalianget Timur",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Kalimo&#8217;ok",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Karang Anyar",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Kalianget",
                "kelurahan": "Kalianget Barat",
                "kodepos": "69471"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Tanjung",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Saronggi",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Talang",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Tanah Merah",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Pagarbatu",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Saroka",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Muangan",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Nambakor",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Kebundadap Timur",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Langsar",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Kambingan Timur",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Kebundadap Barat",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Aengtongtong",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Saronggi",
                "kelurahan": "Juluk",
                "kodepos": "69467"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Sera Tengah",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Sera Timur",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Sera Barat",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Palongan",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Pakandangan Tengah",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Pakandangan Barat",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Pakandangan Sangra",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Masaran",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Lobuk",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Kapedi",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Karang Cempaka",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Ging Ging",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Gulukmanjung",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Bungbungan",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Errabu",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Gilang",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Aengdake",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Bluto",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Aengbaja Kenek",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Bluto",
                "kelurahan": "Aengbaja Raja",
                "kodepos": "69466"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Sentol Laok",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Sendang",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Sentol Daya",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Rombasan",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Pragaan Daya",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Pragaan Laok",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Prenduan",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Pakamban Daya",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Pakamban Laok",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Kaduara Timur",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Karduluk",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Larangan Pereng",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Aengpanas",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Pragaan",
                "kelurahan": "Jaddung",
                "kodepos": "69465"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Tambuko",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Payudan Nangger",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Pordapor",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Payudan Karangsokon",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Payudan Daleman",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Payudan Dundang",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Ketawang Laok",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Pananggungan",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Batuampar",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Bragung",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Guluk Guluk",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Guluk Guluk",
                "kelurahan": "Bakeyong",
                "kodepos": "69463"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Talaga",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Rombiya Timur",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Ketawang Parebaan",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Rombiya Barat",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Ketawang Larangan",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Ganding",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Ketawang Daleman",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Ketawang Karay",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Gadu Barat",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Gadu Timur",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Bilapora Barat",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Bilapora Timur",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Bataal Barat",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Ganding",
                "kelurahan": "Bataal Timur",
                "kodepos": "69462"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Tarogan",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Poreh",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Sendir",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Moncek Timur",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Moncek Tengah",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Moncek Barat",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Lenteng Timur",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Medelan",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Lenteng Barat",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Lembung Barat",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Lembung Timur",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Kambingan Barat",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Jambu",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Ellak Laok",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Daramesta",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Ellak Daya",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Bilapora Reba",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Cangkreng",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Banaresep Barat",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Lenteng",
                "kelurahan": "Banaresep Timur",
                "kodepos": "69461"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Rajun",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Soddara",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Prancak",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Padangdangan",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Panaongan",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Pasongsongan",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Montorna",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Lebeng Timur",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Lebeng Barat",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Pasongsongan",
                "kelurahan": "Campaka",
                "kodepos": "69457"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Pakondang",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Rubaru",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Tambaksari",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Matanair",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Karangnangka",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Mandala",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Kalebengan",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Bunbarat",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Duko",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Bana Sare",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Rubaru",
                "kelurahan": "Basoka",
                "kodepos": "69456"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Tambaagung Timur",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Tambaagung Barat",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Tambaagung Tengah",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Sogian",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Tambaagung Ares",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Campor Timur",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Keles",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Beluk Raja",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Bukabu",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Campor Barat",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Beluk Ares",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Beluk Kenek",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Ambunten Tengah",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Ambunten Timur",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Ambunten",
                "kelurahan": "Ambunten Barat",
                "kodepos": "69455"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Semaan",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Slopeng",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Nyapar",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Kerta Timur",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Mantajun",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Kecer",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Kerta Barat",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Jelbudan",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Dasuk Laok",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Dasuk Timur",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Bringin",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Dasuk Barat",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Batubelah Barat",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Batubelah Timur",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Tengedan",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Dasuk",
                "kelurahan": "Bates",
                "kodepos": "69454"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Larangan Kerta",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Sergang",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Juruan Laok",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Larangan Barma",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Juruan Daya",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Bulaan (Bullaan)",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Gedang Gedang",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Batuputih Kenek",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Batuputih Laok",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Bantelan",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Batuputih Daya",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Badur",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Batuputih",
                "kelurahan": "Aengmerah",
                "kodepos": "69453"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Manding Timur",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Tenonan",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Manding Daya",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Manding Laok",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Kasengan",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Lalangon",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Lanjuk",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Gunung Kembar",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Jaba&#8217;an",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Gadding",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Manding",
                "kelurahan": "Giring",
                "kodepos": "69452"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Torbang",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Gunggung",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Patean",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Gedungan",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Gelugur",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Batuan",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Batuan",
                "kelurahan": "Babalan",
                "kodepos": "69451"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Pabian",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Parsanga",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Paberasan",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Kolor",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Marengan Daya",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Karangduak",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Kebunan",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Bangkal",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Kacongan",
                "kodepos": "69417"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Bangselok",
                "kodepos": "69416"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Pajagalan",
                "kodepos": "69416"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Pandian",
                "kodepos": "69414"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Kepanjin",
                "kodepos": "69415"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Kebonagung",
                "kodepos": "69413"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Pangarangan",
                "kodepos": "69412"
            },
            {
                "kecamatan": "Kota Sumenep",
                "kelurahan": "Pamolokan",
                "kodepos": "69412"
            }
        ],
        "k113": [
            {
                "kecamatan": "Larangan",
                "kelurahan": "Tentenan Timur",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Trasak",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Peltong",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Taraban",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Tentenan Barat",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Montok",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Panaguan",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan Dalam",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Larangan Luar",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Lancar",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Kaduara Barat",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Dukuh/Duko Timur",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Grujugan",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Tobungan",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Larangan",
                "kelurahan": "Blumbungan",
                "kodepos": "69383"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Polagan",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Ponteh",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Pandan",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Pagendingan",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Lembung",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Galis",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Konang",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Bulay",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Tlesa",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Artodung",
                "kodepos": "69382"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Terrak",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Tlanakan",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Panglegur",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Taro&#8217;an",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Mangar",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Larangan Slampar",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Larangan Tokol",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Gugul",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Kramat",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Dabuan",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Ceguk",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Bukek",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Branta Tinggi",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Branta Pasisir",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Bandaran",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Tlanakan",
                "kelurahan": "Ambat",
                "kodepos": "69371"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Toket",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Tlangoh",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Tattangoh",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Srambah",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Samatan",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Samiran",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Rang Perang Laok",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Proppo",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Rang Perang Daya",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Pangurayan",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Pangtonggal",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Pangbatok",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Panglemah",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Mapper",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Panaguan",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Klampar",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Kodik",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Lenteng",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Karanganyar",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Jambringin",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Candi Burung",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Gro&#8217;om",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Billa&#8217;an",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Campor",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Banyu Bulu",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Batu Kalangan",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Proppo",
                "kelurahan": "Badung",
                "kodepos": "69363"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Rombuh",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Rek Kerrek",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Potoan Laok",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Panaan",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Potoan Daja",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Palengaan Laok",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Larangan Badung",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Palengaan Daja",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Kacok",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Angsanah",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Banyupelle",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Palenga'an",
                "kelurahan": "Akkor",
                "kodepos": "69362"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Tlagah (Talagah)",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Tebul Barat",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Tebul Timur",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Tanjung",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Plakpak",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Pegantenan (Pagantenan)",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Palesanggar",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Pasanggar",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Bulangan Timur",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Bulangan Haji",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Bulangan Branta",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Ambender",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pegantenan",
                "kelurahan": "Bulangan Barat",
                "kodepos": "69361"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Tegangser Daya",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Tlontoraja",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Sana Tengah",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Sotobar (Sotabar)",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Dempo Timur",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Sana Daja",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Bindang",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Dempo Barat",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Pasean",
                "kelurahan": "Batukerbuy",
                "kodepos": "69356"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Pamoroh",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Sokolelah",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Kertagena Tengah",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Pamaroh",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Kertagena Laok",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Kertagena Daya",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Kadur",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Bungbaruh",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Gagah",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Kadur",
                "kelurahan": "Bangkes",
                "kodepos": "69355"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Tamberu",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Ponjanan Timur",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Ponjanan Barat",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Pangereman",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Lesong Laok",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Lesong Daja",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Kapong",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Bujur Timur",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Bujur Tengah",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Blaban",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Bujur Barat",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Bangsereh",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Batumarmar",
                "kelurahan": "Batu Bintang",
                "kodepos": "69354"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Waru Barat",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Waru Timur",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tegangser Laok (Tagangser Laok)",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tlonto Ares",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tampojung Pregi",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tampojung Tengah",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tampojung Tenggina",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Sumber Waru",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tampojung Guwa",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Ragang",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Sana Laok",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Somalang",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Bajur",
                "kodepos": "69353"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Seddur",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Palalang",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Lebbek",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Pakong",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Klompang Barat",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Klompang Timur",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Cenlecen",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Bandungan",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Bicorong",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Banban",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pakong",
                "kelurahan": "Banban",
                "kodepos": "69352"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Tambung",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Tanjung",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Sopa&#8217;ah",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Sumedangan",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Pagagan",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Prekbun",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Sentol",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Pademawu Barat",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Pademawu Timur",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Murtajih",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Padelegan (Pedelegan)",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Lemper",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Majungan",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Lawangan Daya",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Durbuk",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Jarin",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Dasok",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Buddih",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Bunder",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Budagan (Buddagan)",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Barurambat Timur",
                "kodepos": "69321"
            },
            {
                "kecamatan": "Pademawu",
                "kelurahan": "Baddurih",
                "kodepos": "69323"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Tejah/Teja Timur",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Toronan",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Panempan",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Tejah/Teja Barat",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Nylabu Daya/Daja",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Nylabu Laok",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Kowel",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Laden",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Jalmak",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Jungcangcang",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Kangenan",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Gladak Anyar",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Bettet",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Barurambat Kota (Barkot)",
                "kodepos": "69317"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Bugih",
                "kodepos": "69316"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Patemon",
                "kodepos": "69312"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Kolpajung",
                "kodepos": "69314"
            },
            {
                "kecamatan": "Pamekasan",
                "kelurahan": "Parteker",
                "kodepos": "69311"
            }
        ],
        "k114": [
            {
                "kecamatan": "Omben",
                "kelurahan": "Temoran",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Sogiyan (Sogian)",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Tambak",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Rapa Daya",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Rapa Laok",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Rongdalem (Rongdalam)",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Omben",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Pandan",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Napo Daya (Napa Daya)",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Napolaok (Napa Laok)",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Madulang",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Meteng",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Karang Nangger",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Kebun Sareh",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Karang Gayam",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Jrangoan (Jranguan)",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Kamondung",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Astapah",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Gersempal",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Omben",
                "kelurahan": "Angsokah",
                "kodepos": "69291"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Taddan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Tambaan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Sejati",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Rabasan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Prajjan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Plampaan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Pamolaan",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Madupat",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Dharma Tanjung",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Dharma Camplong",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Batu Karang",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Banjar Talela",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Banjar Tabulu",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Camplong",
                "kelurahan": "Anggersek",
                "kodepos": "69281"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Taman",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Sreseh",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Plasah",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Noreh",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Marparan",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Labuhan",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Labang",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Klobur",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Junuk (Junok)",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Disanah",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Bundah",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Sreseh",
                "kelurahan": "Bangsah",
                "kodepos": "69273"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Taman",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Plakaran",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Panyepen",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Mlaka",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Margantoko",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Kotah",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Majangan",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Kalangan Prao",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Jungkarang",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Jrengik",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Buker",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Bancelok",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Asem Raja",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Jrengik",
                "kelurahan": "Asem Nonggal",
                "kodepos": "69272"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Torjun",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Tanah Merah",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Ragung",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Patapan",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Patarongan",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Panyerangan (Panyirangan)",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Pangongsean",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Pangarengan",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Pacanggaan",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Krampon",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Kodak",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Kara",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Kanjar",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Jeruk Porot",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Gulbung",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Dulang",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Torjun",
                "kelurahan": "Bringin Nonggal",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Pangarengan",
                "kelurahan": "Apaan",
                "kodepos": "69271"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Tolang",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Trapang",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Tlagah",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Terosan",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Tapaan",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Tebanah",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Planggaran Timur",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Planggaran Barat",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Nepa",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Olor",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Nagasareh",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Montor",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Morbatoh",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Masaran",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Lar Lar",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Kembang Jeruk",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Jatra Timur",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Batioh",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Banyuates",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Banyuates",
                "kelurahan": "Asem Jaran",
                "kodepos": "69263"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tobai Timur",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tobai Tengah",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tobai Barat",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tamberu Laok",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tamberu Timur",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tamberu Daya",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Tamberu Barat",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Sokobanah Tengah",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Sokobanah Laok",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Sokobanah Daya",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Bira Timur",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Sokobanah",
                "kelurahan": "Bira Tengah",
                "kodepos": "69262"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Rabiyan",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Paopalelaok",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Paopale Daya",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Pangereman",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Pancor",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ketapang Timur",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ketapang Laok",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ketapang Daya",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ketapang Barat",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Karang Anyar",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Bunten Timur",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Bunten Barat",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Bira Barat",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Banyusokah",
                "kodepos": "69261"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Tragih",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Torjunan",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Tlambah",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Sawah Tengah",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Robatal",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Poreh",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Pandiyangan",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Karang Penang Onjur",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Lepelle",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Karang Penang Oloh",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Jelgung",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Gunung Rancak",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Gunung Kesan",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Bulmatet",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Karang Penang",
                "kelurahan": "Bluuran (Blu Uran)",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Robatal",
                "kelurahan": "Bapelle",
                "kodepos": "69254"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Tambelangan",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Somber",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Mambulu Barat",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Samaran",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Karang Anyar",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Birem",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Batorasang (Baturasang)",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Beringin (Bringin)",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Banjar Billah",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Tambelangan",
                "kelurahan": "Barung Gagah",
                "kodepos": "69253"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Rohayu",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Rabasan",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Pasarenan",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Pajeruan",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Palenggiyan (Palenggian)",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Nyeloh",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Ombul",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Kramat",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Muktesareh (Moktesareh)",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Kedungdung",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Komis",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Gunung Eleh",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Daleman",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Batoporo Timur",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Batoporo Barat",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Banjar",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Banyukapah",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Kedungdung",
                "kelurahan": "Bajrasokah",
                "kodepos": "69252"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Tanggumong",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Rong Tengah",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Taman Sareh",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Pekalongan (Pakalongan)",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Pulau Mandangin (Mandingan)",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Paseyan",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Panggung",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Pangelen",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Karang Dalem",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Gunung Sekar",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Kamoning",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Baruh",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Gunung Maddah",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Banyumas",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Banyuanyar",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Aeng Sareh",
                "kodepos": "69216"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Polagan",
                "kodepos": "69215"
            },
            {
                "kecamatan": "Sampang",
                "kelurahan": "Dalpenang",
                "kodepos": "69212"
            }
        ],
        "k115": [
            {
                "kecamatan": "Konang",
                "kelurahan": "Sen Asen",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Pakes",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Sambiyan",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Konang",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Kanegarah (Kanagereh)",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Galis Daya/Dajah",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Genteng",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Durin Barat",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Durin Timur",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Cangkarman (Cangkareman)",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Campor",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Batokaban",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Konang",
                "kelurahan": "Bandung",
                "kodepos": "69175"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Rosep",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Pangeran Gedungan (Gadungan)",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Panjalinan",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Nyor Manis (Nyormanes)",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Lombang Daya",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Lombang Laok",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Lomaer",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Kojolan (Ko&#8217;olan)",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Karangnangka",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Karpote",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Karang Panasan",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Karang Gayam",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Kampao",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Gigir",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Kajan",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Blega",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Blegaoloh",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Bates",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Blega",
                "kelurahan": "Alasraja",
                "kodepos": "69174"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Tellok",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Telagah (Tlagah)",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Separah",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Sorpa",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Sadah",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Pekadan",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Paterongan",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Pakaan Daya",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Paka&#8217;an Laok",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Lantek Temor",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Longkek",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Lantek Barat",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Kranggan Timur",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Kelbung",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Kajuanak",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Daleman",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Galis",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Belateran (Blateran)",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Banyubunih",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Banjar",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Galis",
                "kelurahan": "Bangpendah",
                "kodepos": "69173"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tanah Merah Laok",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tlomar (Tlomor)",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Rongdurin",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tanah Merah Dajah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Poter",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Petrah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Pettong",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Patemon",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Padurungan",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Pangeleyan",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Pacentan",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Mrecah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Landak",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Kranggan Barat",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Kendaban",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Dumajah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Jangkar",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Dlambah Laok",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Buddan",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Dlambah Dajah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Basanah",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Batangan",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Baipajung",
                "kodepos": "69172"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Srabi/Serabi Timur",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Suwaan",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Patereman",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Srabi/Serabi Barat",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Pangpajung",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Patengteng",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Pakong",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Neroh",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Paeng",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Manggaan",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Modung",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Kolla",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Langpanggang",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Karanganyar",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Glisgis (Gligis)",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Brakas Dajah",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Modung",
                "kelurahan": "Alaskokon",
                "kodepos": "69166"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Tragah",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Soket Laok",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Tambin",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Soket Dajah",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Pamorah",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Pocong",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Masaran",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Pacangan",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Keteleng",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Kemoneng (Kemoning)",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Jaddung",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Karang Leman/Lemen",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Jaah",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Dukotambin",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Banyu Beseh",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Bancang",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Bajeman",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Tragah",
                "kelurahan": "Alang Alang",
                "kodepos": "69165"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Sumur Kuning (Somor Koneng)",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Tebul",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Sukolilo Barat",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Sukolilo Timur",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Sendang Daya/Dajah",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Sendang Laok",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Petapan",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Pasanggrahan (Pesanggrahan)",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Pangpong",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Paoran",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Pandanan",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Morkepek",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Morombuh",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Labang",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Ketetang",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Kwanyar Barat",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Kesek",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Karang Entang/Gentang",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Jukong",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Karang Anyar",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Gunong/Gunung Sereng",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Janteh",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Duwek Buter",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Dlemer",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Bringin (Bringen)",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Bunajih",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Batah Barat",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kwanyar",
                "kelurahan": "Batah Timur",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Labang",
                "kelurahan": "Ba&#8217;engas",
                "kodepos": "69163"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Tellang (Telang)",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Tajungan",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Tanjung Jati",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Kebun",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Pendabah (Pendebeh)",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Gili Barat",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Gili Timur",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Kamal",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Gili Anyar",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Socah",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Kamal",
                "kelurahan": "Binajuh (Banyu Ajuh)",
                "kodepos": "69162"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Petaonan",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Sanggra/Sanggar Agung",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Parseh",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Pernajuh",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Junganyar",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Keleyan",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Buluh",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Dakiring",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Jaddih",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Socah",
                "kelurahan": "Bilaporah",
                "kodepos": "69161"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Tanjung Bumi",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Telaga/Tlaga Biru",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Tlangoh",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Tambak Pocok",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Planggiran",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Tagungguh",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Macajah",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Paseseh",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Bungkeng",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Larangan Timur",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Banyu Sangkah",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Bumi Anyar",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Aeng Tabar",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Tanjungbumi",
                "kelurahan": "Bandang Dayah",
                "kodepos": "69156"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Tramok",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Tlokoh",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Mandung",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Mano&#8217;an",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Kokop",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Lembung Gunung/Gunong",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Durjan",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Katol Timur",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Batokorogan",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Dupok",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Bandang Laok",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Amparaan",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Kokop",
                "kelurahan": "Banda Soleh",
                "kodepos": "69155"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Tanah Gurah Barat (Tanagura Barat)",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Tanah Gurah Temor (Tanagura Timur)",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Saplasah",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Sepulu",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Prancak",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Lembung Paseser",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Maneron",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Klapayan (Klapaian)",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Labuhan",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Kelbung",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Klabetan",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Gangseyan (Gengseyan)",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Gunelap (Genelap)",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Bangsereh",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Sepulu",
                "kelurahan": "Banyior",
                "kodepos": "69154"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Tolbuk (Tolluk)",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Trogan",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Ra&#8217;as",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Tenggun Daya",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Tobaddung",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Polongan",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Muarah (Moarah)",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Panyaksagan",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Mrandung",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Manonggal",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Larangan Sorjan",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Lergunong",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Ko&#8217;ol",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Larangan Glintong",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Klampis Timur",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Klampis Barat",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Buluk Agung",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Bulung",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Karang Asem",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Bator",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Bragang",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Klampis",
                "kelurahan": "Bantean (Banteyan)",
                "kodepos": "69153"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Tagubang (Togubang)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Tegerpriyah (Tegarpriyah)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kompol",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Lerpak",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Katol Barat",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kombangan",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kampak",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Geger",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Batobella (Batobelle)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Campor",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Dabung (Debung)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Banyoneng Laok (Bayoneng)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Banyoneng Dajah (Bayoneng)",
                "kodepos": "69152"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Tambegan",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Tengket",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Plakaran",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Mangkon",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Ombul",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Pandan Lanjang",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Lajing",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Makam Agung",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Karang Duwak",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Karang Pao",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Cendagah",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Dlemer",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Glagah (Glagga)",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Berbeluk",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Buduran",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Balung",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Batonaong",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Arosbaya",
                "kelurahan": "Arosbaya",
                "kodepos": "69151"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Perreng",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Sobih",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Tonjung (Tunjung)",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Pangolangan",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Kapor",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Langkap",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Binoh",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Burneh",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Jambu",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Arok",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Benangkah",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Burneh",
                "kelurahan": "Alas Kembang",
                "kodepos": "69121"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Kramat (Keramat)",
                "kodepos": "69119"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Mertajasah (Martajasah)",
                "kodepos": "69119"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Ujung Piring",
                "kodepos": "69118"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Keraton",
                "kodepos": "69119"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Mlajah",
                "kodepos": "69116"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Sembilangan",
                "kodepos": "69118"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Pangeranan",
                "kodepos": "69115"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Kemayoran",
                "kodepos": "69116"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Gebang",
                "kodepos": "69114"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Demangan",
                "kodepos": "69115"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Sabiyan",
                "kodepos": "69113"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Pejagan (Pajagan)",
                "kodepos": "69112"
            },
            {
                "kecamatan": "Bangkalan",
                "kelurahan": "Bancaran",
                "kodepos": "69112"
            }
        ],
        "k116": [
            {
                "kecamatan": "Pesanggaran",
                "kelurahan": "Sumberagung",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Pesanggaran",
                "kelurahan": "Sumbermulyo",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Siliragung",
                "kelurahan": "Seneporejo",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Siliragung",
                "kelurahan": "Siliragung",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Siliragung",
                "kelurahan": "Kesilir",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Pesanggaran",
                "kelurahan": "Pesanggaran",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Pesanggaran",
                "kelurahan": "Sarongan",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Pesanggaran",
                "kelurahan": "Kandangan",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Siliragung",
                "kelurahan": "Barurejo",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Siliragung",
                "kelurahan": "Buluagung",
                "kodepos": "68488"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Sukorejo",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Temurejo",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Sambimulyo",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Sambirejo",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Kebondalem",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Ringintelu",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Bangorejo",
                "kelurahan": "Bangorejo",
                "kodepos": "68487"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Wringinrejo",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Yosomulyo",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Wringin Agung",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Purwodadi",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Jajag",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Gambiran",
                "kelurahan": "Gambiran",
                "kodepos": "68486"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Tegalsari",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Tegalrejo",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Tamansari",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Karangmulyo",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Karangdoro",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Dasri",
                "kodepos": "68485"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Wringinpitu",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Tegaldlimo",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Purwoasri",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Purwoagung",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Kendalrejo",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Kedungwungu",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Kedunggebang",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Kedungasri",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Tegaldlimo",
                "kelurahan": "Kalipait",
                "kodepos": "68484"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Sumberasri",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Sidorejo",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Purwoharjo",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Kradenan",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Karetan (Keretan)",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Grajagan",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Glagahagung",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Purwoharjo",
                "kelurahan": "Bulurejo",
                "kodepos": "68483"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Tampo",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Tamanagung",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Sraten",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Sembulung",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Sarimulyo",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Plampangrejo",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Kaliploso",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Cluring",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Cluring",
                "kelurahan": "Benculuk",
                "kodepos": "68482"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Wringin Putih",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Tembokrejo",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Tapanrejo",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Tambakrejo",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Sumbersewu",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Kumendung",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Sumberberas",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Kedungringin (Kedungpringin)",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Kedungrejo",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Muncar",
                "kelurahan": "Blambangan",
                "kodepos": "68472"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Wonosobo",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Sukonatar",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Sumbersari",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Rejoagung",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Sukomaju",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Kepundungan",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Parijatah Kulon",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Parijatah Wetan",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Bagorejo",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Srono",
                "kelurahan": "Kebaman",
                "kodepos": "68471"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Temuasri",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Temuguruh",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Sempu",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Tegalarum",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Jambewangi",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Karangsari",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Sempu",
                "kelurahan": "Gendoh",
                "kodepos": "68468"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Kebonrejo",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Kalibaru Wetan",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Kalibaru Manis",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Kalibaru Kulon",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Banyuanyar",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Kalibaru",
                "kelurahan": "Kajarharjo",
                "kodepos": "68467"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Tulungrejo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Sumbergondo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Tegalharjo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Margomulyo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Sepanjang",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Karangharjo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Glenmore",
                "kelurahan": "Bumiharjo",
                "kodepos": "68466"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Setail",
                "kodepos": "68465"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Kembiritan",
                "kodepos": "68465"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Genteng Wetan",
                "kodepos": "68465"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Kaligondo",
                "kodepos": "68465"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Genteng Kulon",
                "kodepos": "68465"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Singolatren",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Sumber Baru",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Padang",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Singojuruh",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Kemiri",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Lemahbang Kulon",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Gumirih",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Benelan Kidul",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Cantuk",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Gambor",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Sumber Bulu",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Singojuruh",
                "kelurahan": "Alas Malang",
                "kodepos": "68464"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Sumber Arum",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Sragi",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Songgon",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Bedewang",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Parangharjo",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Bayu",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Bangunsari",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Songgon",
                "kelurahan": "Balak",
                "kodepos": "68463"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Rogojampi",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Watu Kebo",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Patoman",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Pengantigan",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Lemahbang Dewo",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Mangir",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Kedaleman",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Karangrejo",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Karang Bendo",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Kaligung",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Kaotan",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Gitik",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Gladag",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Bubuk",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Gintangan",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Blimbing Sari",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Bomo",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Rogojampi",
                "kelurahan": "Aliyan",
                "kodepos": "68462"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Sukojati",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Tambong",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Pondoknongko",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Pendarungan",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Pakistaji",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Macan Putih",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Labanasem",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Kedayunan",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Kalirejo",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Gombolirang",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Kabat",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Bunder",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Dadapan",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Bareng",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Benelan Lor",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kabat",
                "kelurahan": "Badean",
                "kodepos": "68461"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Pesucen",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Telemung",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Kelir",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Ketapang",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Gombengsari",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Kalipuro",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Bulusari",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Tamansari",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Bulusan",
                "kodepos": "68455"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Pakel",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Segobang",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Kluncing",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Licin",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Gumuk",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Jelun",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Licin",
                "kelurahan": "Banjar",
                "kodepos": "68454"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Watukebo",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Wongsorejo",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Wongsorejo",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Sumberanyar",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Sidowangi",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Sidodadi",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Bimorejo",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Bangsring",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Bengkak",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Bajulmati",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Alasrejo",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Wongsorejo",
                "kelurahan": "Alasbulu",
                "kodepos": "68453"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Tamansuruh",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Rejosari",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Paspan",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Olehsari",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Kenjo",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Kemiren",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Kampunganyar",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Glagah",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Banjarsari",
                "kodepos": "68432"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Bakungan",
                "kodepos": "68431"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Mojopanggung",
                "kodepos": "68425"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Jambesari",
                "kodepos": "68425"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Grogol",
                "kodepos": "68425"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Boyolangu",
                "kodepos": "68424"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Giri",
                "kodepos": "68423"
            },
            {
                "kecamatan": "Giri",
                "kelurahan": "Penataban",
                "kodepos": "68422"
            },
            {
                "kecamatan": "Kalipuro",
                "kelurahan": "Klatak",
                "kodepos": "68421"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Sumberrejo",
                "kodepos": "68419"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Pakis",
                "kodepos": "68419"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Kampung Mandar",
                "kodepos": "68419"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Sobo",
                "kodepos": "68418"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Kertosari",
                "kodepos": "68418"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Kebalenan",
                "kodepos": "68417"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Tukangkayu",
                "kodepos": "68416"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Tamanbaru",
                "kodepos": "68416"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Penganjuran",
                "kodepos": "68416"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Singonegaran",
                "kodepos": "68415"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Panderejo",
                "kodepos": "68415"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Singotrunan",
                "kodepos": "68414"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Pengantigan",
                "kodepos": "68414"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Lateng",
                "kodepos": "68413"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Temenggungan",
                "kodepos": "68412"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Kampung Melayu",
                "kodepos": "68412"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Kepatihan",
                "kodepos": "68411"
            },
            {
                "kecamatan": "Banyuwangi",
                "kelurahan": "Karangrejo",
                "kodepos": "68411"
            }
        ],
        "k117": [
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Sumberwaru",
                "kodepos": "68374"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Wonorejo",
                "kodepos": "68374"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Sumberanyar",
                "kodepos": "68374"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Sumberejo",
                "kodepos": "68374"
            },
            {
                "kecamatan": "Banyuputih",
                "kelurahan": "Banyu Putih",
                "kodepos": "68374"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Trigonco",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Wringin Anom",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Perante",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Kertosari",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Mojosari",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Kedonglo",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Awar-Awar",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Bantal",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Gudang",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Asembagus",
                "kelurahan": "Asembagus",
                "kodepos": "68373"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Sopet",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Pesanggrahan",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Palangan",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Kumbangsari",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Curah Kalak",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Gadingan",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Jangkar",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Jangkar",
                "kelurahan": "Agel",
                "kodepos": "68372"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Lamongan",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kayu Mas",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kedung Dowo",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Ketowan",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Curah Tatal",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Jatisari",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Arjasa",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Bayeman",
                "kodepos": "68371"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Trebungan",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Tanjung Pecinan",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Tanjung Glugur",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Tanjung Kamal",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Semiring",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Mangaran",
                "kelurahan": "Mangaran",
                "kodepos": "68363"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Seletreng",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Wonokoyo",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Poka&#8217;an",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Landangan",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Peleyan",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Kesambi Rampak",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Kapongan",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Gebangan",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Kandang",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Kapongan",
                "kelurahan": "Corak Cotok",
                "kodepos": "68362"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Tepos",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Telempong",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Selobanteng",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Kalisari",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Lubawang",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Kalianget",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Banyuglugur",
                "kelurahan": "Banyuglugur",
                "kodepos": "68359"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Selowogo",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Selowogo",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Patemon",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Mlandingan Wetan",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Pasir Putih",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Bletok",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Bungatan",
                "kelurahan": "Bungatan",
                "kodepos": "68358"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Sumberanyar",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Wringinanom",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Semambung",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Patemon",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Pategalan",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Jatibanteng",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Kembangsari",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Jatibanteng",
                "kelurahan": "Curah Suri",
                "kodepos": "68357"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Widoro Payung",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Sumberejo",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Pesisir",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Langkap",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Jetis",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Kalimas",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Bloro",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Demung",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Blimbing",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Besuki",
                "kodepos": "68356"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Tlogosari",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Tamankursi",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Taman Sari",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Sumberargo",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Taman",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Plalangan",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Kalirejo",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Alas Tengah",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Sumbermalang",
                "kelurahan": "Baderan",
                "kodepos": "68355"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Mojo Dungkol",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Suboh",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Gunung Putri",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Ketah",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Gunung Malang",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Dawuan",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Cemara",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Suboh",
                "kelurahan": "Buduan",
                "kodepos": "68354"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Sumber Anyar",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Sumber Pinang",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Trebungan",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Selomukti",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Campoan",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Mlandingan Kulon",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Mlandingan",
                "kelurahan": "Alas Bayur",
                "kodepos": "68353"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Tambak Ukir",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Kukusan",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Rajekwesi",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Kendit",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Klatakan",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Balung",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Kendit",
                "kelurahan": "Bugeman",
                "kodepos": "68352"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Wringinanom",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Paowan",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Peleyan",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Sumber Kolak",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Gelung",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Kilensari",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Alas Malang",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panarukan",
                "kelurahan": "Duwet",
                "kodepos": "68351"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Tenggir",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Tokelan",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Panji Lor",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Sliwung",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Klampokan",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Panji Kidul",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Curah Jeru",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Juglangan",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Kayu Putih",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Mimbaan",
                "kodepos": "68322"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Battal",
                "kodepos": "68323"
            },
            {
                "kecamatan": "Panji",
                "kelurahan": "Ardirejo",
                "kodepos": "68321"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Olean",
                "kodepos": "68316"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Talkandang",
                "kodepos": "68315"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Kotakan",
                "kodepos": "68313"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Kalibagor",
                "kodepos": "68314"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Dawuhan",
                "kodepos": "68311"
            },
            {
                "kecamatan": "Situbondo",
                "kelurahan": "Dawuhan",
                "kodepos": "68311"
            }
        ],
        "k118": [
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Trebungan",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Taman",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Tanggulangin",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Tegalampel",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Sumberkokap",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Sekarputih",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Paguan",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Purnama",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Kretek",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Mandiro",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Klabang Agung",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Klabang",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Kemuningan",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Tegalampel",
                "kelurahan": "Karanganyar",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Taman Krocok",
                "kelurahan": "Gentong",
                "kodepos": "68291"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Sempol",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Sumber Rejo",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Kalisat",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Kaligedang",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Kalianyar",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sempol",
                "kelurahan": "Jampit",
                "kodepos": "68288"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Tegaljati",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Sumber Wringin",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Sumber Gading",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sukosari",
                "kelurahan": "Sukosari Lor",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Sukosari Kidul",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Sukorejo",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sumber Wringin",
                "kelurahan": "Rejoagung",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sukosari",
                "kelurahan": "Pecalongan",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sukosari",
                "kelurahan": "Nogosari",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Sukosari",
                "kelurahan": "Kerang",
                "kodepos": "68287"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Suling Wetan",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Suling Kulon",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Solor",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Ramban Wetan",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Ramban Kulon",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Pelalangan",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Kladi",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Jirek Mas",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Bercak Asri",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Cermee",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Bercak",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Batu Salang",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Bajuran",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Batu Ampar",
                "kodepos": "68286"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Tarum",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Walidono",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Prajekan Lor",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Sempol",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Penang",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Prajekan Kidul",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Klekehan (Klekean)",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Gayam",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Gayam Lor",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Bandilan",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Prajekan",
                "kelurahan": "Cangkring",
                "kodepos": "68285"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Wonokerto",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Sumbersuko",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Wonoboyo",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Sumbercanting",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Pandak",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Lumutan",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Leprak",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Lanas",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Klampokan",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Klabang",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Karanganyar",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Karang Sengon",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Botolinggo",
                "kelurahan": "Botolinggo",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Blimbing",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Klabang",
                "kelurahan": "Besuk",
                "kodepos": "68284"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Wonokusumo",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Tapen",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Taal",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Mrawan",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Mangli Wetan",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Kalitapen",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Jurangsapi",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Gununganyar",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Tapen",
                "kelurahan": "Cindogo",
                "kodepos": "68283"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wonosari",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tumpeng",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Traktakan",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Tangsil Wetan",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sumberkalong",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Pelalangan",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Pasarejo",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Lombok Wetan",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Lombok Kulon",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Kapuran",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Jumpong",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bendoarum",
                "kodepos": "68282"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Tenggarang",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Tangsil Kulon",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Sumbersalam",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Lojajar",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Pekalangan",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Koncer Kidul",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Koncer Darul Alam",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Kesemek (Kasemek)",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Kajar",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Gebang",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Dawuhan",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tenggarang",
                "kelurahan": "Bataan",
                "kodepos": "68281"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Trotosari",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Tlogosari",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Sulek",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Patemon",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Pakisan",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Kembang",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Jebung Lor",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Jebung Kidul",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Brambang Darussolah",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Tlogosari",
                "kelurahan": "Gunosari",
                "kodepos": "68272"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Sukowono",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Sukokerto",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Sukodono",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Randucangkring",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Pengarang",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Padasan",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Mengok",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Maskuning Wetan",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Maskuning Kulon",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Mangli",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Alassumur",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Pujer",
                "kelurahan": "Kejayan",
                "kodepos": "68271"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Tegalpasir",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Wonosuko",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Sumberkemuning",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Tamanan",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Sumber Anyar",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Sumber Jeruk",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Sumber Anom",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Pucang Anom",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Sukosari",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Kemirian",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Mengen",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Kalianyar",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Tamanan",
                "kelurahan": "Karangmelok",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Jambe Sari",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Jambe Anom",
                "kodepos": "68263"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Tanahwulan",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Sumber Anyar",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Sumberpakem",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Sumbersari",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Suger Lor",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Pujerbaru",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Sucolor",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Maesan",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Pakuniran",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Penanggungan",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Gambangan",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Maesan",
                "kelurahan": "Gunung Sari",
                "kodepos": "68262"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Wonosari",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Tegalmijin",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Wanisodo",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Taman",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Pekauman",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Sumberpandan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Pejagan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Kejawan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Jambe Sari Darus Sholah",
                "kelurahan": "Grujugan Lor",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Kabuaran",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Cermee",
                "kelurahan": "Grujugan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Grujugan Kidul",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Dawuhan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Grujugan",
                "kelurahan": "Dadapan",
                "kodepos": "68261"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Petung",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Sumber Dumpyong",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Pakem",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Patemon",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Gadingsari",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Kupang",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Andungsari",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Pakem",
                "kelurahan": "Ardisaeng",
                "kodepos": "68253"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Wringin",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Sumbermalang",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Sumbercanting",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Jatisari",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Jatitamban",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Jambewungu",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Gubrih",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Bukor",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Glingseran",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Ampelan",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Banyuputih",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Banyuwulu",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Wringin",
                "kelurahan": "Ambulu",
                "kodepos": "68252"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Sumbertengah",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Sumberwaru",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Sumbersuko",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Silolembu",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Sumber Salak",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Poncogati",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Pakuwesi",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Penambangan",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Petung",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Locare",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Kembangan",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Kupang",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Jeruksoksok",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Jetis",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Gadingsari",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Curahdami",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Curahdami",
                "kelurahan": "Curahpoh",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Binakal",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Bendelan",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Binakal",
                "kelurahan": "Baratan",
                "kodepos": "68251"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Pancoran",
                "kodepos": "68219"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Sukowiryo",
                "kodepos": "68219"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Kembang",
                "kodepos": "68219"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Kademangan",
                "kodepos": "68217"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Pejaten",
                "kodepos": "68218"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Nangkaan",
                "kodepos": "68215"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Tamansari",
                "kodepos": "68216"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Badean",
                "kodepos": "68214"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Kotakulon",
                "kodepos": "68213"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Dabasah",
                "kodepos": "68211"
            },
            {
                "kecamatan": "Bondowoso",
                "kelurahan": "Blindungan",
                "kodepos": "68212"
            }
        ],
        "k119": [
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Sumber Lesung",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Sumber Salak",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Suren",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Sumber Anget",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Sumber Bulus",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Sukogidri",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Slateng",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Karang Paiton",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Ledokombo",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Ledokombo",
                "kelurahan": "Lembengan",
                "kodepos": "68196"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Rowosari",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Sumber Pakem",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Sumberjambe",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Randu Agung",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Plereyan",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Pringgondani",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Gunung Malang",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Jambe Arum",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sumber Jambe",
                "kelurahan": "Cumedak",
                "kodepos": "68195"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sumberwaru",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sumber Wringin",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sumberdanti",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sukosari",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sukowono",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sukokerto",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Sukorejo",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Mojogeni",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Pocangan",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Balet Baru",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Dawuhan Mangli",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Sukowono",
                "kelurahan": "Arjasa",
                "kodepos": "68194"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Sumber Jeruk",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Sumber Kalong",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Sumber Ketempah",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Sebanen",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Sukoreno",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Kalisat",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Patempuran",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Glagahwero",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Gumuksari",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Ajung",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Gambiran",
                "kodepos": "68193"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Suko Jember",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Sukowiryo",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Panduman",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Suco Pangepok",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Suger Kidul",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Jelbuk",
                "kelurahan": "Jelbuk",
                "kodepos": "68192"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kemuningllor",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Darsono",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Kamal",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Biting",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Candijati",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Arjasa",
                "kelurahan": "Arjasa",
                "kodepos": "68191"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Silo",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Sumberjati",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Sidomulyo",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Sempolan",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Mulyorejo",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Pace",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Harjomolyo",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Karangharjo",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Silo",
                "kelurahan": "Garahan",
                "kodepos": "68184"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Sumber Kejayan",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Tegal Waru",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Sidomukti",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Seputih",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Mayang",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Mrawan",
                "kodepos": "68182"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Subo",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Sumber Pinang",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Pakusari",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Patemon",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Kertosari",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Bedadung",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Pakusari",
                "kelurahan": "Jatian",
                "kodepos": "68181"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Wirowongso",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Rowo Indah",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Sukamakmur",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Mangaran",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Pancakarya",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Ajung",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Ajung",
                "kelurahan": "Klompangan",
                "kodepos": "68175"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Suco",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Tamansari",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Lengkong",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Mumbulsari",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Kawangrejo",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Lampeji",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Mumbulsari",
                "kelurahan": "Karangkedawung",
                "kodepos": "68174"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Wonoasri",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Tempurejo",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Sidodadi",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Pondokrejo",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Sanenrejo",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Curahtakir",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Curahnongko",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Tempurejo",
                "kelurahan": "Andongrejo",
                "kodepos": "68173"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Sumberrejo",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Tegalsari",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Pontang",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Sabrang",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Andongsari",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Ambulu",
                "kodepos": "68172"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Wonojati",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Seruni",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Kertonegoro",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Kemuning Sari Kidul",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Jatimulyo",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Jatisari",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Jenggawah",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jenggawah",
                "kelurahan": "Cangkring",
                "kodepos": "68171"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Padomasan",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Sarimulyo",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Wringin Agung",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Keting",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Ngampelrejo",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jombang",
                "kodepos": "68168"
            },
            {
                "kecamatan": "Kencong",
                "kelurahan": "Wonorejo",
                "kodepos": "68167"
            },
            {
                "kecamatan": "Kencong",
                "kelurahan": "Kraton",
                "kodepos": "68167"
            },
            {
                "kecamatan": "Kencong",
                "kelurahan": "Paseban",
                "kodepos": "68167"
            },
            {
                "kecamatan": "Kencong",
                "kelurahan": "Cakru",
                "kodepos": "68167"
            },
            {
                "kecamatan": "Kencong",
                "kelurahan": "Kencong",
                "kodepos": "68167"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Umbulrejo",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Umbulsari",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Tanjungsari",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Tegal Wangi",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Sukoreno",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Mundurejo",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Paleran",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Sidorejo",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Gunungsari",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Umbulsari",
                "kelurahan": "Gadingrejo",
                "kodepos": "68166"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Tembokrejo",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Menampu",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Purwoasri",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Karang Rejo",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Kepanjen",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Mayangan",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Bagorejo",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Gumuk Mas",
                "kelurahan": "Gumukmas",
                "kodepos": "68165"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Wringin Telu",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Wonosari",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Puger Kulon",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Puger Wetan",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Mojomulyo",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Mojosari",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Kasiyan",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Kasiyan Timur",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Mlokorejo",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Grenden",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Jambearum",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Puger",
                "kelurahan": "Bagon",
                "kodepos": "68164"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Tamansari",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Lojejer",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Glundengan",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Kesilir",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Ampel",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Wuluhan",
                "kelurahan": "Dukuh Dempok",
                "kodepos": "68162"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Tutul",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Karang Duren",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Karang Semanding",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Curahlele",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Gumelar",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Balung Kulon",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Balung Lor",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Balung",
                "kelurahan": "Balung Kidul",
                "kodepos": "68161"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Sidomulyo",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Semboro",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Sidomekar",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Pondok Dalem",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Pondok Joyo",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Semboro",
                "kelurahan": "Rejo Agung",
                "kodepos": "68157"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Sumber Agung",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Yosorati",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Karang Bayat",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Pringgowirawan",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Rowo Tengah",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Jamintoro",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Jatiroto",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Kaliglagah",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Gelang",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Sumber Baru",
                "kelurahan": "Jambesari",
                "kodepos": "68156"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Tanggul Kulon",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Tanggul Wetan",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Selodakon",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Patemon",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Kramat Sukoharjo",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Manggisan",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Darungan",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Tanggul",
                "kelurahan": "Klatakan",
                "kodepos": "68155"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Tugusari",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Sukorejo",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Tisnogambar",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Langkap",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Petung",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Gambirono",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Karangsono",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Banjarsari",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Curah Kalong",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Badean",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Bangsalsari",
                "kelurahan": "Bangsalsari",
                "kodepos": "68154"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Suci",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Panti",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Serut",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Kemuningsari Lor",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Pakis",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Glagahwero",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Kemiri",
                "kodepos": "68153"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Rambipuji",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Rowotamtu",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Pecoro",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Rambigundam",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Kaliwining",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Nogosari",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Gugut",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Sukorambi",
                "kelurahan": "Sukorambi",
                "kodepos": "68151"
            },
            {
                "kecamatan": "Rambipuji",
                "kelurahan": "Curahmalang",
                "kodepos": "68152"
            },
            {
                "kecamatan": "Sukorambi",
                "kelurahan": "Kelungkung",
                "kodepos": "68151"
            },
            {
                "kecamatan": "Sukorambi",
                "kelurahan": "Jubung",
                "kodepos": "68151"
            },
            {
                "kecamatan": "Sukorambi",
                "kelurahan": "Karangpring",
                "kodepos": "68151"
            },
            {
                "kecamatan": "Sukorambi",
                "kelurahan": "Dukuh Mencek",
                "kodepos": "68151"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Kebon Agung",
                "kodepos": "68137"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Kepatihan",
                "kodepos": "68137"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Mangli",
                "kodepos": "68136"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Kaliwates",
                "kodepos": "68133"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Sempusari",
                "kodepos": "68135"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Tegal Besar",
                "kodepos": "68132"
            },
            {
                "kecamatan": "Ambulu",
                "kelurahan": "Karang Anyar",
                "kodepos": "68132"
            },
            {
                "kecamatan": "Kaliwates",
                "kelurahan": "Jember Kidul",
                "kodepos": "68131"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Keranjingan",
                "kodepos": "68126"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Antirogo",
                "kodepos": "68125"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Tegal Gede",
                "kodepos": "68124"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Wirolegi",
                "kodepos": "68124"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Karangrejo",
                "kodepos": "68124"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Sumbersari",
                "kodepos": "68121"
            },
            {
                "kecamatan": "Sumber Sari",
                "kelurahan": "Kebonsari",
                "kodepos": "68122"
            },
            {
                "kecamatan": "Mayang",
                "kelurahan": "Tegalrejo",
                "kodepos": "68118"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Jember Lor",
                "kodepos": "68118"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Gebang",
                "kodepos": "68117"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Banjar Sengon",
                "kodepos": "68118"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Slawu",
                "kodepos": "68116"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Jumerto",
                "kodepos": "68114"
            },
            {
                "kecamatan": "Kalisat",
                "kelurahan": "Plalangan",
                "kodepos": "68113"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Bintoro",
                "kodepos": "68113"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Patrang",
                "kodepos": "68111"
            },
            {
                "kecamatan": "Patrang",
                "kelurahan": "Baratan",
                "kodepos": "68112"
            }
        ],
        "k120": [
            {
                "kecamatan": "Kunir",
                "kelurahan": "Sukosari",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Kunir Lor",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Sukorejo",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Kunir Kidul",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Kedungmoro",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Karanglo",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Kabuaran",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Jatirejo",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Jatimulyo",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Jatigono",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Kunir",
                "kelurahan": "Dorogowok",
                "kodepos": "67383"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Yosowilangun Lor",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Yosowilangun Kidul",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Wotgalih",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Tunjungrejo",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Munder",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Krai",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Kraton",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Kebonsari",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Karangrejo",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Karangayar",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Kalipepe",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Yosowilangun",
                "kelurahan": "Darungan",
                "kodepos": "67382"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Wonosari",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Wonokerto",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Wonogriyo",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Tekung",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Tukum",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Mangunsari",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Klampokarum",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tekung",
                "kelurahan": "Karangbendo",
                "kodepos": "67381"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Tempursari",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Tempurrejo",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Tegalrejo",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Purorejo",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Pundungsari",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Kaliuling",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Tempursari",
                "kelurahan": "Bulurejo",
                "kodepos": "67375"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Tamanayu",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Supiturang",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Sumberurip",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Sidomulyo",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Pronojiwo",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Pronojiwo",
                "kelurahan": "Oro Oro Ombo",
                "kodepos": "67374"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Tumpeng",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sumberwuluh",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Tambahrejo",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sumberrejo",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sumbermujur",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Penanggal",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Jugosari",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Kloposawit",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Candipuro",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Jarit",
                "kodepos": "67373"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Sememu",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Selok Awar Awar",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Pasirian",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Selok Anyar",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Madurejo",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Nguter",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Kalibendo",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Condro",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Gondoruso",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Bago",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Pasirian",
                "kelurahan": "Bades",
                "kodepos": "67372"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Tempeh Lor",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Tempeh Tengah",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Sumberjati",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Tempeh Kidul",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Pandanarum",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Pandanwangi",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Pulo",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Lempeni",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Jokarto",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Kaliwungu",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Jatisari",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Gesang",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Tempeh",
                "kelurahan": "Besuk",
                "kodepos": "67371"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Wonocepokoayu",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Senduro",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Sukorejo",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Ranupani",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Sarikemuning",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Purworejo",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Pasrujambe (Pasujambe)",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Pandansari",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Kertosari",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Pagowan",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Karanganom",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Kandangtepus",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Kandangan",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Jambekumbu",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Pasrujambe/Pasujambe",
                "kelurahan": "Jambearum",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Burno",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Bedayu",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Bedayutalang",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Senduro",
                "kelurahan": "Argosari",
                "kodepos": "67361"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Sumbersari",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Rowokangkung",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Sidorejo",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Sumberanyar",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Nogosari",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Dawuhan Wetan",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Rowokangkung",
                "kelurahan": "Kedungrejo",
                "kodepos": "67359"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Umbul",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Wonorejo",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Tempursari",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Sawaran Kulon",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Pandansari",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Krasak",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Jatisari",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Kedungjajang",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Curahpetung",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Grobogan",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Bence",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Wonoayu",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Kedungjajang",
                "kelurahan": "Bandaran",
                "kodepos": "67358"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Wates Kulon",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Wates Wetan",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Sumberpetung",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Tegalbangsri",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Ranubedali",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Ranuyoso",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Meninjo",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Penawungan",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Alun Alun",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Ranuyoso",
                "kelurahan": "Jenggrong",
                "kodepos": "67357"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Tegalrandu",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Tegalciut",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Sruni",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Sumberwringin",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Sawaran Lor",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Papringan",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Ranupakis",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Mlawang",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Kebonan",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Klakah",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Kudus",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Klakah",
                "kelurahan": "Duren",
                "kodepos": "67356"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Sukosari",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Rojopolo",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Kaliboto Kidul",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Kaliboto Lor",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Banyuputih Kidul",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Jatiroto",
                "kelurahan": "Jatiroto",
                "kodepos": "67355"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Salak",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Tunjung",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Ranulogong",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Ranuwurung",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Pejarakan",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Randuagung",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Kalipenggung",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Ledoktempuro",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Gedangmas",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Kalidilem",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Buwek",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Wonokerto",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Randuagung",
                "kelurahan": "Banyuputih Lor",
                "kodepos": "67354"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Sombo",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Tunjung",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Kertowono",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Pakel",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Kenongo",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Gucialit",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Jeruk",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Gucialit",
                "kelurahan": "Dadapan",
                "kodepos": "67353"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Uranggantung",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Tanggung",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Selokgondang",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Sumberejo",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Selokbesuki",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Padang",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Kutorenon",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Merakan",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Mojo",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Klanting",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Kedawung",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Kebonagung",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Karangsari",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Kalisemut",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Bondoyudo",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Dawuhan Lor",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Barat",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Bodang",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Sumbersuko",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Padang",
                "kelurahan": "Babakan",
                "kodepos": "67352"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Sentul",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Rogotrunan",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Purwosono",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Petahunan",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Labruk Lor",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Mojosari",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Kepuharjo",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Labruk Kidul",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Grati",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Sumbersuko",
                "kelurahan": "Kebonsari",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Boreng",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Denok",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Banjarwaru",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Blukon",
                "kodepos": "67316"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Jogotrunan",
                "kodepos": "67314"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Jogoyudan",
                "kodepos": "67315"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Citrodiwangsan",
                "kodepos": "67312"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Ditotrunan",
                "kodepos": "67313"
            },
            {
                "kecamatan": "Lumajang",
                "kelurahan": "Tompokersan",
                "kodepos": "67311"
            }
        ],
        "k121": [
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Tambakukir",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Triwungan",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sukorejo",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sumbercentang (Sumbercenteng)",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Talkadang",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sidorejo",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sambirampak Lor",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sidomulyo",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Kota Anyar",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Pasembon",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Sambirampak Kidul",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Curahtemu",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Kota Anyar",
                "kelurahan": "Kedungrejoso",
                "kodepos": "67293"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Wangkal",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Sumber Kembar",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Sumbersecang",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Sogaan",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Sentul",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Ranon",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Ranuwurung",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Renteng",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Patemon Kulon",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Prasi",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Pakuniran",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Nogosaren",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Mojolegi",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Kertonegoro",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Kertosono",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Kedungsumur",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Keben",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Kalidandan",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Kaliacar",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Gunggungan Kidul",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Gunggungan Lor",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Jurangjero",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Glagah",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Gondosuli",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Duren",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Gading Wetan",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Condong",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Dandang",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Bucor Kulon",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Bulupandak",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Bucor Wetan",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Bimo",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Blimbing",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Batektaman (Betektaman)",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Gading",
                "kelurahan": "Batur",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Taman",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Pakuniran",
                "kelurahan": "Alas Pandan",
                "kodepos": "67292"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Sumberanyar",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Sumberejo",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Sidodadi",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Sukodadi",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Pondok Kelor",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Randu Merak",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Randu Tatah",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Patunjungan",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Plampang",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Paiton",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Pandean",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Karanganyar",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Kalikajar Kulon",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Kalikajar Wetan",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Jabung Candi",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Jabung Sisir",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Jabung Wetan",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Alas Tengah",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Paiton",
                "kelurahan": "Bhinar",
                "kodepos": "67291"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Tambelang",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Watupanjang",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Seneng",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Sumber Duren",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Pandanlaras",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Plaosan",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Roto",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Krobongan (Krobungan)",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Krucil",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Kertosuko",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Kalianan",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Betek",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Guyangan",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Wedusan",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Krucil",
                "kelurahan": "Bermi (Bremi)",
                "kodepos": "67288"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Tlogoargo",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Tlogosari",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Tulupari",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Segaran",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Tegalwatu",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Tiris",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Ranuagung",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Ranugedang",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Renjing (Rejing)",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Pegadangan",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Pesawahan",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Racek",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Andung Sari",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Jangkang",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Widoro",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Tiris",
                "kelurahan": "Andung Biru",
                "kodepos": "67287"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Sumberkatimoho",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Tanjungsari",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Temenggungan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Seboro",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Sentong",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Sokaan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Patemon",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Rawan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Kedungcaluk",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Krejengan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Opo Opo",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Kamalkuning",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Karangren",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Gebangan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Jatiurip",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Krejengan",
                "kelurahan": "Dawuan",
                "kodepos": "67284"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Sumberan",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Sumur Dalam",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Randu Jalak",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Sindet Anyar",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Sindet Lami",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Klampokan",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Krampilan",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Matekan",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Jambangan",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Kecik",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Besuk Agung",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Besuk Agung",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Besuk",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Alas Sumur Lor",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Alas Tengah",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Bago",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Alas Kandang",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Besuk",
                "kelurahan": "Alas Nyiur",
                "kodepos": "67283"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Sumberlele",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Taman Sari",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Semampir",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Sidomukti",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Sidopekso",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Rangang (Rangkang)",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Rondokuning",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kregenan (Keregenan)",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Patokan",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kraksaan Wetan",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kebonagung",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kalibuntu",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kandangjati Kulon",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Kandangjati Wetan",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Asembagus",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Asembakor",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Bulu",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Kraksaan",
                "kelurahan": "Alassumur Kulon",
                "kodepos": "67282"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Sukomulyo",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Tanjung",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Selogudig Kulon",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Selogudig Wetan",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Sukokerto",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Penambangan",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Pajarakan Kulon",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Karanggeger",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Karangpranti",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Ketompen",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Gejungan",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Pajarakan",
                "kelurahan": "Karangbong",
                "kodepos": "67281"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Sumberpoh",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Wonorejo",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Suko",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Sumberdawe",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Puspan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Satreyan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Maron Wetan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Pegalangan Kidul",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Maron Kidul",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Maron Kulon",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Gerongan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Kedungsari",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Brumbungan Kidul",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Ganting Kulon",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Ganting Wetan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Brani Kulon",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Brani Wetan",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Tarokan",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Maron",
                "kelurahan": "Brabe",
                "kodepos": "67276"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Pendil",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Sentulan",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Liprak Kulon",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Liprak Wetan",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Klenang Kidul",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Klenang Lor",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Liprak Kidul",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Gunung Geni",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Gading Kulon",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Banyuanyar Tengah",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Blado Wetan",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Banyuanyar Kidul",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Banyu Anyar",
                "kelurahan": "Alassapi",
                "kodepos": "67275"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Tegal Sono",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Tegal Siwalan",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Tegal Mojo",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Sumberkledung",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Sumberbulu",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Malasan Wetan",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Paras",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Gunung Bekel",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Bulujaran Lor",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Blado Kulon",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Bulujaran Kidul",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Tegal Siwalan",
                "kelurahan": "Banjarsawah",
                "kodepos": "67274"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Tigasan Wetan",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Warujinggo",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Tigasan Kulon",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Sumber Kedawung",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Pondok Wuluh",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Malasan Kulon",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Keprangan (Kerpangan)",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Leces",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Jorongan",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Leces",
                "kelurahan": "Clarak",
                "kodepos": "67273"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Sebaung",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Sumberkerang",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Pikatan",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Randupitu",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Pesisir",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Pajurangan",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Klaseman",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Gending",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Jatiadi",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Curahsawo",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Brumbungan Lor",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Bulang",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Gending",
                "kelurahan": "Banyuanyar Lor",
                "kodepos": "67272"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Watuwungkuk",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Tegalrejo",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Sumber Suko",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Taman Sari",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Sumber Agung",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Sekarkare",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Randuputih",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Pabean",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Ngepoh",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Kedungdalem",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Mranggon Lawang",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Kalirejo",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Kalisalam",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Dringu",
                "kelurahan": "Dringu",
                "kodepos": "67271"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Tukul",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Wonokerso",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sumberanom",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Rambaan",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Sumber",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Ledokombo",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Pandansari",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Cepoko",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Sumber",
                "kelurahan": "Gemito",
                "kodepos": "67263"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Wringin Anom",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Menyono",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Resongo",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Wonoasri",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Karangrejo",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kedawung",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Jatisari",
                "kodepos": "67262"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Patokan",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Tempuran",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Kropak",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Legundi",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Kedung Rejo",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Kramat Agung (Kramatung)",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Gunung Tugel",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Karang Anyar",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Wonogoro",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Bantaran",
                "kelurahan": "Bantaran",
                "kodepos": "67261"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Sapih",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Tandongsentul (Tandon Sentul)",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Palangbesi",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Purut",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Lumbang",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Negororejo",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Boto",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Branggah",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Lambangkuning",
                "kodepos": "67255"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Wonokerto",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Wonotoro",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Sariwani",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Sukapura",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Sapikerep",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Pakel",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Ngadisari",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Ngepung",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Kedasih",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Ngadas",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Ngadirejo",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Wonorejo",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Sukapura",
                "kelurahan": "Jetak",
                "kodepos": "67254"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Sumberkare",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Tunggak Cerme",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Sepuh Gempol",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Pohsangit Ngisor",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Pohsangit Tengah",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Kedung Supit",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Patalan",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Pohsangit Lor",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Jrebeng",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Wonomerto",
                "kelurahan": "Kareng Kidul",
                "kodepos": "67253"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Tongas Kulon",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Tongas Wetan",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Wringinanom",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Tambakrejo",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Tanjungrejo",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Sumberkramat",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Sumendi",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Pamatan",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Sumberejo",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Klampok",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Curah Tulis",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Dungun",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Bayeman",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Tongas",
                "kelurahan": "Curah Dringu",
                "kodepos": "67252"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Sumberbendo",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Sumurmati",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Pesisir",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Pohsangit Leres",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Muneng",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Muneng Kidul",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Lemahkembar",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Mentor",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Jangur",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Laweyan",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Gili Ketapang",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Ambulu",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Sumberasih",
                "kelurahan": "Banjarsari",
                "kodepos": "67251"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Pakistaji",
                "kodepos": "67239"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Jrebeng Wetan",
                "kodepos": "67239"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Kedunggaleng",
                "kodepos": "67239"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Jrebeng Lor",
                "kodepos": "67238"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Sumber Taman",
                "kodepos": "67237"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Jrebeng Kidul",
                "kodepos": "67233"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Kedungasem",
                "kodepos": "67236"
            },
            {
                "kecamatan": "Wonoasih",
                "kelurahan": "Wonoasih",
                "kodepos": "67232"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Kedopok",
                "kodepos": "67231"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Kareng Lor",
                "kodepos": "67228"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Jrebeng Kulon",
                "kodepos": "67229"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Pohsangit Kidul",
                "kodepos": "67226"
            },
            {
                "kecamatan": "Kedopok (Kedopak)",
                "kelurahan": "Sumber Wetan",
                "kodepos": "67227"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Triwung Kidul",
                "kodepos": "67224"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Kademangan",
                "kodepos": "67225"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Ketapang",
                "kodepos": "67222"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Triwung Lor",
                "kodepos": "67223"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Pilang",
                "kodepos": "67221"
            },
            {
                "kecamatan": "Mayangan",
                "kelurahan": "Mayangan",
                "kodepos": "67218"
            },
            {
                "kecamatan": "Mayangan",
                "kelurahan": "Sukabumi",
                "kodepos": "67219"
            },
            {
                "kecamatan": "Mayangan",
                "kelurahan": "Jati",
                "kodepos": "67217"
            },
            {
                "kecamatan": "Mayangan",
                "kelurahan": "Mangunharjo",
                "kodepos": "67217"
            },
            {
                "kecamatan": "Mayangan",
                "kelurahan": "Wiroborang",
                "kodepos": "67216"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Kebonsari Kulon",
                "kodepos": "67214"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Kebonsari Wetan",
                "kodepos": "67214"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Sukoharjo",
                "kodepos": "67215"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Curahgrinting",
                "kodepos": "67212"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Kanigaran",
                "kodepos": "67213"
            },
            {
                "kecamatan": "Kanigaran",
                "kelurahan": "Tisnonegaran",
                "kodepos": "67211"
            }
        ],
        "k122": [
            {
                "kecamatan": "Lekok",
                "kelurahan": "Tampung",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Wates",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Tambak Lekok",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Semedusari",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Pasinan",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Rowo Gempol",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Gejug Jati",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Jatirejo",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Balung Anyar",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Branang",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Wot Galih",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Lekok",
                "kelurahan": "Alas Tlogo",
                "kodepos": "67186"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Wates Tani",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Watuprapat",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Sedarum",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Sudimulyo",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Sumber Anyar",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Sang Anom",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Sebalong",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Nguling",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Penunggul",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Randuati",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Kapasan",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Kedawang",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Mlaten",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Trewung",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Nguling",
                "kelurahan": "Dandang Gendis",
                "kodepos": "67185"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Sumber Agung",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Sumber Dawesari",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Ranu Klindungan",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Rebalas",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Kedawung Kulon",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Kedawung Wetan",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Plososari",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Karang Kliwon",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Karang Lo",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Kebon Rejo",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Kambingan Rejo",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Grati Tunon",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Kalipang",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Grati",
                "kelurahan": "Cukur Gondang",
                "kodepos": "67184"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Welulang",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Wonorejo",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Panditan",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Watulumbung",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Kronto",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Lumbang",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Pancur",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Karang Asem",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Karang Jati",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Banjarimbo",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Bulukandang",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Lumbang",
                "kelurahan": "Cukurguling",
                "kodepos": "67183"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Winongan Lor",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Winongan Kidul",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Umbulan",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Sruwi",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Sumber Rejo",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Penataan",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Prodo",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Sidepan",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Menyarik",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Minggir",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Kedung Rejo",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Lebak",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Mendalan",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Kandung",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Karang Tengah",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Jeladri",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Gading",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Winongan",
                "kelurahan": "Bandaran",
                "kodepos": "67182"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Segoro Puro",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Toyaning",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Sadeng Rejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Sambirejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Rejoso Kidul",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Rejosolor",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Pandan Rejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Patuguran",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Manik Rejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Kedung Bako",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Kemantren Rejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Ketegan",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Karang Pandan",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Kawis Rejo",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Jarangan",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Arjosari",
                "kodepos": "67181"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Tosari",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Wonokitri",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Ngadiwono",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Podokoyo",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Sedaeng",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Kandangan",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Mororejo",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Pusung Malang",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Tosari",
                "kelurahan": "Baledono",
                "kodepos": "67177"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Palang Sari",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Puspo",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Jimbaran",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Keduwung",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Kemiri",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Puspo",
                "kelurahan": "Jangjangwulung",
                "kodepos": "67176"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Tambakrejo",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Tempuran",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Rejosalam",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Sapulante",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Sibon",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Poh Gading",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Poh Gedang",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Ngantungan",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Pasrepan",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Petung",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Lemahbang",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Mangguan",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Galih",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Jogorepuh",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Klakah",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Ampelsari",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Pasrepan",
                "kelurahan": "Cengkrong",
                "kodepos": "67175"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Wonojati",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Wonosari",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Tebas",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Tenggilis Rejo",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Ranggeh",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Sekar Putih",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Pateguhan",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Pekangkungan",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Lajuk",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Kersikan",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Lajuk",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Kali Rejo",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Karang Sentul",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Gondang Wetan",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Grogol",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Gayam",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Gondang Rejo",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Brambang",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Bayeman",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Wonosari",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Gondang Wetan",
                "kelurahan": "Bajangan",
                "kodepos": "67174"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Tamansari",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Wonorejo",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Pakijangan",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Rebono",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Sambisirah",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Kendang Dukuh",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Kluwut",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Lebaksari",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Karangjati Anyar",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Karangsono",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Karang Menggah",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Karang Asem",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Wrati",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Coban Blimbing",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Wonorejo",
                "kelurahan": "Jati Gunting",
                "kodepos": "67173"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Tundo Soro",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Wangkal Wetan",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Sumber Banteng",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Sumber Suko",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Tanggulangin",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Patebon",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Randu Gong",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Sladi",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Luwuk",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Oro Oro Pule",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Pacar Keling",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kurung",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Linggo",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kurung",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Klangrong",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Klinter",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kejayan",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kepuh",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Ketangi Rejo (Kangirejo)",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Coban Joyo",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kedemungan",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Kedung Pengaron",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Benerwojo",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Kejayan",
                "kelurahan": "Ambal Ambil",
                "kodepos": "67172"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Susukan Rejo",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Tidu",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Warung Dowo",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Sungi Kulon",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Sungi Wetan",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Pohjentrek",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Sukorejo",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Paras Rejo",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Pleret",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Wonosari",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Pohjentrek",
                "kelurahan": "Logowok",
                "kodepos": "67171"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Tlogosari",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Tutur",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Pungging",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Sumber Pitu",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Ngadirejo",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Ngembal",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Gendro",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Kali Pucang",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Kayu Kebek",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Andono Sari",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Tutur",
                "kelurahan": "Blarang",
                "kodepos": "67165"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Tambak Sari",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Semut",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sentul",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Pucang Sari",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwodadi",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Lebak Rejo",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Parerejo",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Gerbo",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Jatisari",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Cowek",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Dawuhan Sengon",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Gajah Rejo",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Capang",
                "kodepos": "67163"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Tejowangi",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sumber Rejo",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sumber Suko",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sengon Agung",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sukodermo",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Purwosari",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sekar Mojo",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Pager",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Pucangsari",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Kayoman",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Kertosari",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Martopuro",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Karang Rejo",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Bakalan",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Cendono",
                "kodepos": "67162"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Wonokerto",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Suwayuwo",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Tanjungarum",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sukorame",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sukorejo",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Pakukerto",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sebandung",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Ngadimulyo",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Mojotengah",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Lemahbang",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Lecari",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kenduruan",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Karangsono",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kalirejo",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Gunting",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Glagah Sari",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Dukuh Sari",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Curah Rejo",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Candi Binangun",
                "kodepos": "67161"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Watuagung",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Sukoreno",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Sukolelo",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Sekarjoho",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Prigen",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Lumbang Rejo",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Pecalukan",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Ledug",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Ketanireng",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Jatiarjo",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Dayurejo",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Gambiran",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Candi Wates",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Prigen",
                "kelurahan": "Bulukandang",
                "kodepos": "67157"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Wedoro",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Tunggul Wulung",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Tawang Rejo",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Sumber Rejo",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Sumber Gedang",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Plintahan",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Sebani",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Petungasri",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Pandaan",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Nogosari",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Kutorejo",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Kebon Waris",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Kemiri Sewu",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Karang Jati",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Jogosari",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Durensewu",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Banjarsari",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Pandaan",
                "kelurahan": "Banjar Kejen",
                "kodepos": "67156"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Wonosunyo",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Winong",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Wonosari",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Sumber Suko",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Watukosek",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Ngerong",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Randupitu",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Kepulungan",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Legok",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Kejapanan",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Jeruk Purut",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Karangrejo",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Carat",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Gempol",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Wonokoyo",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Gempol",
                "kelurahan": "Bulusari",
                "kodepos": "67155"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Pagak",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Sidowayah",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Kenep",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Ngembe",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Kedung Ringin",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Kedung Boto",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Glanggang",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Gunung Gangsir",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Gunung Sari",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Cangkring Malang",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Gajahbendo",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Baujeng",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Beji",
                "kelurahan": "Beji",
                "kodepos": "67154"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Tambakan",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Raci",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Masangan",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Pogar",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Manaruwi",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kolursari",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Latek",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kidul Dalem",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kauman",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kersikan",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kalianyar",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Kalirejo",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Gempeng",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Bendo Mungal",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Bangil",
                "kelurahan": "Dermo",
                "kodepos": "67153"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Sumber Glagah",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Tampung",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Siyar",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Rembang",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pekoren",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pejangkungan",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Oroombo Wetan",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pajaran",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Pandean",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Orobulu",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Oroombo Kulon",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Mojoparon",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kedung Banteng",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Krengih",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kalisat",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Kanigoro",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Rembang",
                "kelurahan": "Geneng Waru",
                "kodepos": "67152"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Tambaksari",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Slambrit",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Tambakrejo",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Sidogiri",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Rejosari",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Selotambak",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Semare",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Pukul",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Pulokerto",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Ngempit",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Plinggisan",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Ngabar",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Klampis Rejo",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Kraton",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Mulyo Rejo",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Karang Anyar",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Kebotohan",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Kalirejo",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Jeruk",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Dhompo",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Gambir Kuning",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Gerongan",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Curah Dukuh",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Asemkandang",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Kraton",
                "kelurahan": "Bendungan",
                "kodepos": "67151"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Sebani",
                "kodepos": "67139"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Bukir",
                "kodepos": "67138"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Gentong",
                "kodepos": "67139"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Krapyakrejo",
                "kodepos": "67137"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Petahunan",
                "kodepos": "67136"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Randusari",
                "kodepos": "67136"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Gadingrejo",
                "kodepos": "67134"
            },
            {
                "kecamatan": "Gadingrejo",
                "kelurahan": "Karangketug",
                "kodepos": "67135"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Tambaan",
                "kodepos": "67133"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Karanganyar",
                "kodepos": "67131"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Trajeng",
                "kodepos": "67132"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Kepel",
                "kodepos": "67129"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Tapaan",
                "kodepos": "67129"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Bugul Kidul",
                "kodepos": "67129"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Bugul Lor",
                "kodepos": "67129"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Bakalan",
                "kodepos": "67128"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Blandongan",
                "kodepos": "67128"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Sekargadung",
                "kodepos": "67127"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Petamanan",
                "kodepos": "67126"
            },
            {
                "kecamatan": "Bugul Kidul",
                "kelurahan": "Krampyangan",
                "kodepos": "67127"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Pekuncen",
                "kodepos": "67126"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Kandang Sapi",
                "kodepos": "67125"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Mandaranrejo",
                "kodepos": "67123"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Panggungrejo",
                "kodepos": "67124"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Wirogunan",
                "kodepos": "67118"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Purutrejo",
                "kodepos": "67117"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Tembokrejo",
                "kodepos": "67118"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Purworejo",
                "kodepos": "67115"
            },
            {
                "kecamatan": "Purworejo",
                "kelurahan": "Kebonagung",
                "kodepos": "67116"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Kebonsari",
                "kodepos": "67114"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Bangilan",
                "kodepos": "67111"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Mayangan",
                "kodepos": "67112"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Ngemplakrejo",
                "kodepos": "67113"
            }
        ],
        "k123": [
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Watulimo",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Tasikmadu",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Watuagung",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Slawe",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Pakel",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Prigi",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Sawahan",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Margomulyo",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Ngembel",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Dukuh",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Gemaharjo",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Watulimo",
                "kelurahan": "Karanggandu",
                "kodepos": "66382"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Sumbergayam",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Semarum",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Sumberejo",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Pandean",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Panggungsari",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Malasan",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Ngadisuko",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Pakis",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Karanganom",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Kendalrejo",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Gador",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Kamulan",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Durenan",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Durenan",
                "kelurahan": "Baruharjo",
                "kodepos": "66381"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Sugihan",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Timahan",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Karangrejo",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Ngadimulyo",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Senden",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Bogoran",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Kampak",
                "kelurahan": "Bendoagung",
                "kodepos": "66373"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Wonoanti",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Wonorejo",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Sukorejo",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Widoro",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Melis",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Ngrayung",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Sukorame",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Jajar",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Karanganyar",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Krandegan",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Gandusari",
                "kodepos": "66372"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Ngulanwetan",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Pogalan",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Wonocoyo",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Ngulankulon",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Ngetal",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Ngadirejo",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Ngadirenggo",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Bendorejo",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Gembleb",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Pogalan",
                "kelurahan": "Kedunglurah",
                "kodepos": "66371"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Tawing",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Ngulungwetan",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Sobo",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Masaran",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Munjungan",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Ngulungkulon",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Craken",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Karangturi",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Bendoroto",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Besuki",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Wonocoyo",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Munjungan",
                "kelurahan": "Bangun",
                "kodepos": "66365"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Tangkil",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Terbis",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Panggul",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Sawahan",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Ngrambingan",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Ngrencak",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Nglebeng",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Kertosono",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Manggis",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Gayam",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Karangtengah",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Bodag",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Depok",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Barang",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Besuki",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Panggul",
                "kelurahan": "Banjar",
                "kodepos": "66364"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Sumberbening",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Watuagung",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Salamwates",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Siki",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Petung",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Pringapus",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Pandean",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Dongko",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Ngerdani",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Dongko",
                "kelurahan": "Cakul",
                "kodepos": "66363"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Wonokerto",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Sukokidul",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Tanggaran",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Suruh",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Sidomulyo",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Puyung",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Puru",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Pule",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Ngrandu",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Pakel",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Mlinjon",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Nglebo",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Kembangan",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Jombok",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Karanganyar",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Suruh",
                "kelurahan": "Gamping",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Pule",
                "kelurahan": "Joho",
                "kodepos": "66362"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Sumber",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Sumberingin",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Salamrejo",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Sukowetan",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Kedungsigit",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Kerjo",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Ngentrong",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Karangan",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Kayen",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Jatiprahu",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Jati",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Buluagung",
                "kodepos": "66361"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Tegaren",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Tumpuk",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Winong",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Pucanganak",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Sukorejo",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Prambon",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Nglongsor",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Jambu",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Ngepeh",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Nglinggis",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Gading",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Gondang",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Dermosari",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Duren",
                "kodepos": "66352"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Srabah",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Sumurup",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Surenlor",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Masaran",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Sengon",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Depok",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Dompyong",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Sumberdadi",
                "kodepos": "66319"
            },
            {
                "kecamatan": "Bendungan",
                "kelurahan": "Botoputih",
                "kodepos": "66351"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Sukosari",
                "kodepos": "66318"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Karangsoko",
                "kodepos": "66319"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Ngares",
                "kodepos": "66319"
            },
            {
                "kecamatan": "Tugu",
                "kelurahan": "Banaran",
                "kodepos": "66318"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Dawuhan",
                "kodepos": "66318"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Surodakan",
                "kodepos": "66316"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Parakan",
                "kodepos": "66317"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Rejowinangun",
                "kodepos": "66317"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Sumbergedong",
                "kodepos": "66315"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Kelutan",
                "kodepos": "66313"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Sambirejo",
                "kodepos": "66314"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Ngantru",
                "kodepos": "66311"
            },
            {
                "kecamatan": "Trenggalek",
                "kelurahan": "Tamanan",
                "kodepos": "66312"
            }
        ],
        "k124": [
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Tenggur",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Tugu",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Tenggong",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Tanen",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Tegalrejo",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Sukorejo Wetan",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Sumberagung",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Panjerejo",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Rejotangan",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Jatidowo",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Karangsari",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Pakisrejo",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Blimbing",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Buntaran",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Aryojeding",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Rejotangan",
                "kelurahan": "Banjarejo",
                "kodepos": "66293"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Sumberingin Kidul",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Sumberingin Kulon",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Selorejo",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Sumberejo Kulon",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Sumberejo Wetan",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Purworejo",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Samir",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Pulosari",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Pulotondo",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Kromasan",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Ngunut",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Pandansari",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Kaliwungu",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Karangsono",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Gilang",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Kacangan",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Kalangan",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Ngunut",
                "kelurahan": "Balesono",
                "kodepos": "66292"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Wates",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Wonorejo",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Sumberdadi",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Tambakrejo",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Trenceng",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Sambijajar",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Sambirobyong",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Sambidoplang",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Podorejo",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Jabalsari",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Junjung",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Mirigambar",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Bukur",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Doroampel",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Bendiljati Kulon",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Bendiljati Wetan",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Sumbergempol",
                "kelurahan": "Bendilwungu",
                "kodepos": "66291"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Sumberbendo",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Sumberdadap",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Panggunguni",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Pucanglaban",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Manding",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Panggungkalak",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Kalidawe",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Kaligentong",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Pucanglaban",
                "kelurahan": "Demuk",
                "kodepos": "66284"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Tenggarejo",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Pakisrejo",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Tanggunggunung",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Ngepoh",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Ngrejo",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Jengglungharjo",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Tanggung Gunung",
                "kelurahan": "Kresikan",
                "kodepos": "66283"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Winong",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Tunggangri",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Salak Kembang",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Sukorejo Kulon",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Tanjung",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Rejosari",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Pakisaji",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Ngubalan",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Pagersari",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Kalidawir",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Karangtalun",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Joho",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Kalibatur",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Jabon",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Domasan",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Betak",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Kalidawir",
                "kelurahan": "Banyuurip",
                "kodepos": "66281"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Wateskroyo",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Tulungrejo",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Tanggulwelahan",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Tanggulkundung",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Tanggulturus",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Sedayugunung",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Siyotobagus",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Keboireng",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Besuki",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Besuki",
                "kelurahan": "Besole",
                "kodepos": "66275"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Talunkulon",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Suwaru",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Suruhan Lor",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Suruhan Kidul",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Sukoharjo",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Soko",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Singgit",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Sebalor",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Ngunggahan",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Ngepeh",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Nglampir",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Mergayu",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Kesambi",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Kedungwilut",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Gandong",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Bulus",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Bantengan",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Bandung",
                "kelurahan": "Bandung",
                "kodepos": "66274"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Suwaluh",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Tamban",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Sukoanyar",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Sodo",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Sanan",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Sambitan",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Pakel",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Pecuk",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Ngrance",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Ngebong",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Kasreman",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Gombang",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Gesikan",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Duwet",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Gebang",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Gempolan",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Bangunmulyo",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Bono",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Pakel",
                "kelurahan": "Bangunjaya",
                "kodepos": "66273"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Tanggung",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Wates",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Pojok",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Sawo",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Gedangan",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Ngentrong",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Pelem",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Campurdarat",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Campur Darat",
                "kelurahan": "Gamping",
                "kodepos": "66272"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Wonokromo",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Tawing",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Tiudan",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sidem",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sidomulyo",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Rejosari",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sepatan",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Ngrendeng",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Notorejo",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kiping",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Macanbang",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Mojoarum",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Jarakan",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kendal",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondang",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondosuli",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Blendis",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Dukuh",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Bendo",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Bendungan",
                "kodepos": "66263"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Wonorejo",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Segawe",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Sidomulyo",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Penjor",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Samar",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Mulyosari",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Pagerwojo",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Kedungcangkring",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Kradinan",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Gambiran",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Pagerwojo",
                "kelurahan": "Gondanggunung",
                "kodepos": "66262"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Sidorejo",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Mojosari",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Panggungrejo",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Pucangan",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Kates",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Kauman",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Kalangbret",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Karanganom",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Bolorejo",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Jatimulyo",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Banaran",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Batangsaren",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Balerejo",
                "kodepos": "66261"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Talang",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Tugu",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Picisan",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Sendang",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Nglurup",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Nglutung",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Krosok",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Nyawangan",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Dono",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Kedoyo",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Sendang",
                "kelurahan": "Geger",
                "kodepos": "66254"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Tanjungsari",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Tulungrejo",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Sukowidodo",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Sukowiyono",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Sembon",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Sukodono",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Sukorejo",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Karangrejo",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Punjul",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Gedangan",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Jeli",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Babadan",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Karang Rejo",
                "kelurahan": "Bungur",
                "kodepos": "66253"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Pulerejo",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Srikaton",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Pinggirsari",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Pojok",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Pojok",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Padangan",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Pakel",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Kepuhrejo",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Mojoagung",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Ngantru",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Batokan",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Bendosari",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Ngantru",
                "kelurahan": "Banjarsari",
                "kodepos": "66252"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Wajak Lor",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Waung",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Tanjungsari",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Wajak Kidul",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Serut",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Sanggrahan",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Pucung Kidul",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Kendalbulur",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Moyoketen",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Ngranti",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Boyolangu",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Karangrejo",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Bono",
                "kodepos": "66235"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Kepuh",
                "kodepos": "66234"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Sobontoro",
                "kodepos": "66232"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Beji",
                "kodepos": "66233"
            },
            {
                "kecamatan": "Boyolangu",
                "kelurahan": "Gedangsewu",
                "kodepos": "66231"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Tapan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Winong",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Ngujang",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Plandaan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Simo",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Majan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Loderesan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Bulusari",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Gendingan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Bangoan",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Boro",
                "kodepos": "66229"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Mangunsari",
                "kodepos": "66228"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Ketanon",
                "kodepos": "66226"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Tawangsari",
                "kodepos": "66227"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Kedungwaru",
                "kodepos": "66224"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Rejoagung",
                "kodepos": "66225"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Tunggulsari",
                "kodepos": "66222"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Ringinpitu",
                "kodepos": "66223"
            },
            {
                "kecamatan": "Kedungwaru",
                "kelurahan": "Plosokandang",
                "kodepos": "66221"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Sembung",
                "kodepos": "66219"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kauman",
                "kodepos": "66219"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kepatihan",
                "kodepos": "66219"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Jepun",
                "kodepos": "66218"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Tamanan",
                "kodepos": "66217"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Bago",
                "kodepos": "66218"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Tertek",
                "kodepos": "66216"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Karangwaru",
                "kodepos": "66217"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kutoanyar",
                "kodepos": "66215"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Panggungrejo",
                "kodepos": "66214"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kedungsoko",
                "kodepos": "66215"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kampungdalem",
                "kodepos": "66212"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Kenayan",
                "kodepos": "66212"
            },
            {
                "kecamatan": "Tulungagung",
                "kelurahan": "Botoran",
                "kodepos": "66213"
            }
        ],
        "k125": [
            {
                "kecamatan": "Wates",
                "kelurahan": "Wates",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Tulungrejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Sumberarum",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Tugu Rejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Ringin Rejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Sukorejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Mojorejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Purworejo",
                "kodepos": "66194"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Tawang Rejo",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Umbul Damar",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Sumber Kembar",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Sukorame",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Salamrejo",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Sambigede",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Ngembul",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Rejoso",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Kedungwungu",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Ngadri",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Binangun",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Binangun",
                "kelurahan": "Birowo",
                "kodepos": "66193"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Sidomulyo",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Sumber Agung",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Pohgajih",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Selorejo",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Ngreco",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Ngrendeng",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Olak Alen",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Banjarsari",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Boro",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Selorejo",
                "kelurahan": "Ampelgading",
                "kodepos": "66192"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Tapakrejo",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Tepas",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Pagerwojo",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Siraman",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Sukoanyar",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Kesamben",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Pagergunung",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Jugo",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Kemirigede",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Bumirejo",
                "kodepos": "66191"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Sumber Agung",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Tambakan",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Tulungrejo",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Soso",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Sukosewu",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Ngaringan",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Semen",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Slumbung",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Kotes",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Krisik",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Gandusari",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Gondang",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Gadungan",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Gandusari",
                "kelurahan": "Butun",
                "kodepos": "66187"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Sumber Urip",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Suru",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Resapombo",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Sidorejo",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Slorok",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Kalimanis",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Plumbangan",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Doko",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Genengan",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Doko",
                "kelurahan": "Jambepawon",
                "kodepos": "66186"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Wlingi",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Tegalasri",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Tembalang",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Tegalrejo",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Tangkil",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Selopuro",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Ploso",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Popoh",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Ngadirenggo",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Mandesan",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Mronjo",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Klemunan",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Jatitengah",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Beru",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Selopuro",
                "kelurahan": "Jambewangi",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Balerejo",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Wlingi",
                "kelurahan": "Babadan",
                "kodepos": "66184"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Wonorejo",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Tumpang",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Sragi",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Talun",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Kaweron",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Kendalrejo",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Pasirharjo",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Jajar",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Jeblog",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Kamulan",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Duren",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Jabung",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Bajang",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Talun",
                "kelurahan": "Bendosewu",
                "kodepos": "66183"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Tawang Sari",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Tingal",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Sumberdiren",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Sidodadi",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Slorok",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Pojok",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Garum",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Karangrejo",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Sumberasri",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Garum",
                "kelurahan": "Bence",
                "kodepos": "66182"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Ngoran",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Penataran",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Krenceng",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Modangan",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Nglegok",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Kedawung",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Kemloko",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Dayu",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Jiwut",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Sumbersih",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Nglegok",
                "kelurahan": "Bangsri",
                "kodepos": "66181"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Serang",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Sumber Agung",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Margo Mulyo",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Panggung Asri",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Panggung Rejo",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Kali Gambir",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Kalitengah",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Bumiayu",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Panggungrejo",
                "kelurahan": "Bale Rejo",
                "kodepos": "66174"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Tambakrejo",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Wonotirto",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Ngeni",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Pasiraman",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Sumberboto",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Kaligrenjeng",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Ngadipuro",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Sumberjo",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Wonotirto",
                "kelurahan": "Gunung Gede",
                "kodepos": "66173"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Sotojayan (Sutojayan)",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Sukorejo",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Kedung Bunder",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Kembangarum",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Pandanarum",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Kalipang",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Kaulon",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Bacem",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Jegu",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Sutojayan",
                "kelurahan": "Jingglong",
                "kodepos": "66172"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Sawentar",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Tlogo",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Papungan",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Satreyan",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Karangsono",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Kuningan",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Minggirsari",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Gogodeso",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Jatinom",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Kanigoro",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Banggle",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Kanigoro",
                "kelurahan": "Gaprang",
                "kodepos": "66171"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Tumpakoyot",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Sidomulyo",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Sumberdadi",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Tumpakkepuh",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Ngrejo",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Plandirejo",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Pulerejo",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Kedungbanteng",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Lorejo",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Bakung",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Bakung",
                "kelurahan": "Bululawang",
                "kodepos": "66163"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Sumberjo",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Suruhwadang",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Sumberjati",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Plumpungrejo",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Rejowinangun",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Plosorejo",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Pakisaji",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Panggungduwet",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Kebonsari",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Maron",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Jimbe",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Kademangan",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Bendosari",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Darungan",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Kademangan",
                "kelurahan": "Dawuhan",
                "kodepos": "66161"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Wonodadi",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Salam",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Tawangrejo",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Pikatan",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Rejosari",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Kolomayan",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Kunir",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Kaliboto",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Kebonagung",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Gandekan",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Wonodadi",
                "kelurahan": "Jaten",
                "kodepos": "66155"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Temenggungan",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Tunjung",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Sumbersari",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Slemanan",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Sukorejo",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Karanggondang",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Mangunan",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Ringinanom",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Besuki",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Jati",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Bakung",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Udanawu",
                "kelurahan": "Bendorejo",
                "kodepos": "66154"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Sidorejo",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Ponggok",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Ringinanyar",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Pojok",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Maliran",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Langon",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Kebonduren",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Karangbendo",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Kawedusan",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Jatilengger",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Gembongan",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Dadaplangu",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Candirejo",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Bendo",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Ponggok",
                "kelurahan": "Bacem",
                "kodepos": "66153"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Wonorejo",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Togogan",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Srengat",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Selokajang",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Purwokerto",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Pakisrejo",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Ngaglik",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Maron",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Kerjen",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Kauman",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Kendalrejo",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Karanggayam",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Kandangan",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Dermojayan",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Dandong",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Srengat",
                "kelurahan": "Bagelenan",
                "kodepos": "66152"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Tuliskriyo",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Sumberingin",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Sumberjo",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Sumber",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Sanankulon",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Purworejo",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Plosoarang",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Jeding",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Kalipucung",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Gledug",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Bendowulung",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Kulon",
                "kelurahan": "Bendosari",
                "kodepos": "66151"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Sanan Wetan",
                "kodepos": "66137"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Karang Tengah",
                "kodepos": "66137"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Gedog",
                "kodepos": "66137"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Klampok",
                "kodepos": "66136"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Rembang",
                "kodepos": "66135"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Plosokerep",
                "kodepos": "66134"
            },
            {
                "kecamatan": "Sanan Wetan",
                "kelurahan": "Bendogerit",
                "kodepos": "66133"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Tanjung Sari",
                "kodepos": "66126"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Turi",
                "kodepos": "66126"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Blitar",
                "kodepos": "66126"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Karang Sari",
                "kodepos": "66125"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Pakunden",
                "kodepos": "66122"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Tlumpu",
                "kodepos": "66124"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sukorejo",
                "kodepos": "66121"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Kauman",
                "kodepos": "66117"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Kepanjen Kidul",
                "kodepos": "66117"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Kepanjen Lor",
                "kodepos": "66117"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Bendo",
                "kodepos": "66116"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Tanggung",
                "kodepos": "66115"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Ngadirejo",
                "kodepos": "66114"
            },
            {
                "kecamatan": "Kepanjen Kidul",
                "kelurahan": "Sentul",
                "kodepos": "66113"
            }
        ],
        "k126": [
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Sukosari",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Wonoagung",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Pait",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Pondok Agung",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Bayem",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Kasembon",
                "kelurahan": "Kasembon",
                "kodepos": "65393"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Tulungrejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Waturejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Purworejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Sidodadi",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Sumberagung",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Pagersari",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Pandansari",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Mulyorejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Ngantru",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Kaumrejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Jombok",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Banjarejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Ngantang",
                "kelurahan": "Banturejo",
                "kodepos": "65392"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Wiyurejo",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Sukomulyo",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Tawangsari",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Pujon Kidul",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Pujon Lor",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Ngabab",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Ngroto",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Pandesari",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Bendosari",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Pujon",
                "kelurahan": "Madiredo",
                "kodepos": "65391"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Wonorejo",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Sidoluhur",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Srigading",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Sumber Ngepoh",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Sumber Porong",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Mulyoarjo",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Sidodadi",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Bedali",
                "kodepos": "65215"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Kalirejo",
                "kodepos": "65216"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Ketindan",
                "kodepos": "65214"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Turirejo",
                "kodepos": "65213"
            },
            {
                "kecamatan": "Lawang",
                "kelurahan": "Lawang",
                "kodepos": "65211"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tirtomoyo",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Wirotaman",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tawangagung",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tirtomarto",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Sonowangi",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tamanasri",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Tamansari",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Sidorenggo",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Simojayan",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Mulyoasri",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Purwoharjo",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Lebakharjo",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Ampelgading",
                "kelurahan": "Argoyuwono",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Wonoagung",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Ampelgading",
                "kodepos": "65183"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Tirtoyudo",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Tlogosari",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Tamansatriyan",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Sukorejo",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Sumbertangkil",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Tamankuncaran",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Pujiharjo",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Purwodadi",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Kepatihan",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Gadungsari",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Tirtoyudo",
                "kelurahan": "Jogomulyan",
                "kodepos": "65182"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Sumbersuko",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Srimulyo",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Sukodono",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Pojok",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Rembun",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Jambangan",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Majangtengah",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Pamotan",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Bumirejo",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Dampit",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Baturetno",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Wonorejo",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Dampit",
                "kelurahan": "Amadanom",
                "kodepos": "65181"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Sumberbening",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Wonokerto",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Rejoyoso",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Srigonco",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Pringgodani",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Rejosari",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Bantur",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Karangsari",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Tumpakrejo",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Bantur",
                "kelurahan": "Bandungrejo",
                "kodepos": "65179"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Sindurejo",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Sumberejo",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Segaran",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Sidodadi",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Gedangan",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Girimulyo",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Gajahrejo",
                "kodepos": "65178"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Tambak Asri",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Tambakrejo",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Tegalrejo",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Sumberagung",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Sumbermanjing Wetan",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Sekarbanyu",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Sidoasri",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Sitiarjo",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Ringinkembar",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Ringinsari",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Kedungbanteng",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Klepu",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Druju",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Harjokuncaran",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Undaan",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Sumbermanjing Wetan",
                "kelurahan": "Argotirto",
                "kodepos": "65176"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Tumpukrenteng",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Turen",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Tanggung",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Tawangrejeni",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Sedayu",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Talangsuko",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Talok",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Sawahan",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Pagedangan",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Sanankerto",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Sananrejo",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Kedok",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Kemulan",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Gedog Wetan",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Jeru",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Urek Urek",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Turen",
                "kelurahan": "Gedog Kulon",
                "kodepos": "65175"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Sukosari",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Sumberjaya",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Suwaru",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Sukorejo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Putukrejo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Sepanjang",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sidorejo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Panggungharjo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Putat Kidul",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Putat Lor",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Ketawang",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Karangsuko",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pagelaran",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Kademangan",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Kanigoro",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Gondanglegi Wetan",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Ganjaran",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Gondanglegi Kulon",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Clumprit",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Gondanglegi",
                "kelurahan": "Bulupitu",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Banjarejo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Brongkal",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Wonoayu",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Balearjo",
                "kodepos": "65174"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Sumberputih",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Wajak",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Sukolilo",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Sukoanyar",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Ngembal",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Patokpicis",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Codo",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Dadapan",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Kidangbang",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Blayu",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Bringin",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Tangkilsari",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Wajak",
                "kelurahan": "Bambang",
                "kodepos": "65173"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Tajinan",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Tambakasri",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Randugading",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Sumbersuko",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Pandanmulyo",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Purwosekar",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Jatisari",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Ngawonggo",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Gunungsari",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Jambearjo",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Wandanpuro",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Tajinan",
                "kelurahan": "Gunungronggo",
                "kodepos": "65172"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Sudimoro",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Sukonolo",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Lumbangsari",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Pringu",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Sempalwadak",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Krebet Senggrong",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Kuwolu",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Kasembon",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Kasri",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Krebet",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Bululawang",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Gading",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Tlogorejo",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Bululawang",
                "kelurahan": "Bakalan",
                "kodepos": "65171"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Sumbermanjing Kulon",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Sumberrejo",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Sempol",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Sumberkerto",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Pagak",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Pandanrejo",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Pagak",
                "kelurahan": "Gampingan",
                "kodepos": "65168"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Tulungrejo",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Tempursari",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Tlogosari",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Purwodadi",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Purworejo",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Sumberoto",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Donomulyo",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Kedungsalam",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Mentaraman",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Tumpakrejo",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Donomulyo",
                "kelurahan": "Banjarjo",
                "kodepos": "65167"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Sukowilangun",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Sumberpetung",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Kalirejo",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Putukrejo",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Kaliasri",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Kalipare",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Arjowilangun",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Ternyang",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kalipare",
                "kelurahan": "Arjosari",
                "kodepos": "65166"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Senggreng",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Sumberpucung",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Slorok",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Sambigede",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Peniwen",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Ngebruk",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Ngadirejo",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Karangrejo",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Kromengan",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Jatikerto",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Jatiguwi",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Sumberpucung",
                "kelurahan": "Karangkates",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Kromengan",
                "kelurahan": "Jambuwer",
                "kodepos": "65165"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sumberdem",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Wonosari",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Sumber Tempur",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Plandi",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Plaosan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Ngasem",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Palaan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Kranggan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Maguan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Ngajum",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Kesamben",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Kebobang",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Kluwut",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Banjarsari",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Wonosari",
                "kelurahan": "Bangelan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Babadan",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Ngajung (Ngajum)",
                "kelurahan": "Balesari",
                "kodepos": "65164"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Sukoraharjo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Talangagung",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Tegalsari",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Panggungrejo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Penarukan",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Sengguruh",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Mojosari",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Ngadilangkung",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Kemiri",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Kepanjen",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Mangunrejo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Jatirejoyoso",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Jenggolo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Kedungpedaringan",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Ardirejo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Cepokomulyo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Curung Rejo",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Kepanjen",
                "kelurahan": "Dilem",
                "kodepos": "65163"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Wonokerso",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Wadung",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Permanu",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Sutojayan",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Pakisaji",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Kendalpayak",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Karangduren",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Karangpandan",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Kebonagung",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Glanggang",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Jatisari",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Sumbersuko",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Pakisaji",
                "kelurahan": "Genengan",
                "kodepos": "65162"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Sidorahayu",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Sitirejo",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Sukodadi",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Parangargo",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Petungsewu",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Pandanlandung",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Pandanrejo",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Jedong",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Mendalanwangi",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Dalisodo",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Wagir",
                "kelurahan": "Gondowangi",
                "kodepos": "65158"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Wonorejo",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Wringinanom",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Sumberejo",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Wonomulyo",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Poncokusumo",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Ngebruk",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Pajaran",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Pandansari",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Ngadas",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Ngadireso",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Karanganyar",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Karangnongko",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Dawuhan",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Gubukklakah",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Jambesari",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Argosuko",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Poncokusumo",
                "kelurahan": "Belung",
                "kodepos": "65157"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Wringinsongo",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Tumpang",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Slamet",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Tulusbesar",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Ngingit",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Pandanajeng",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Pulungdowo",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Malangsuko",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Kambingan",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Kidal",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Duwet Krajan",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Jeru",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Duwet",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Bokor",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Tumpang",
                "kelurahan": "Benjor",
                "kodepos": "65156"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Taji",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Sukopuro",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Sukolilo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Slamparejo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Sidorejo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Sidomulyo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Pandansari Lor",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Ngadirejo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Kenongo",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Kemiri",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Kemantren",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Jabung",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Gunung Jati",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Gading Kembar",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Argosari",
                "kodepos": "65155"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Tirtomoyo",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Sumberpasir",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Sumberkradenan",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Sukoanyar",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Sekarpuro",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Saptorenggo",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Pucang Songo",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Pakiskembar",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Mangliawan",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Pakisjajar",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Kedungrejo",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Bunutwetan",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Banjarrejo",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Asrikaton",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Pakis",
                "kelurahan": "Ampeldento",
                "kodepos": "65154"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Watugede",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Wonorejo",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Tunjungtirto",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Toyomarto",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Tamanharjo",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Randuagung",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Purwoasri",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Losari",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Pagentan",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Klampok",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Langlang",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Dengkol",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Gunungrejo",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Baturetno",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Candirenggo",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Ardimulyo",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Singosari",
                "kelurahan": "Banjararum",
                "kodepos": "65153"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Tawangargo",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Tegalgondo",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Ngijo",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Ngenep",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Girimoyo",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Kepuharjo",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Bocek",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Donowarih",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Karangploso",
                "kelurahan": "Ampeldento",
                "kodepos": "65152"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Selorejo",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Sumbersekar",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Tegalweru",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Mulyoagung",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Petung Sewu",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Kucur",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Landungsari",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Kalisongo",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Karangwidoro",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Kebonsari",
                "kodepos": "65149"
            },
            {
                "kecamatan": "Dau",
                "kelurahan": "Gadingkulon",
                "kodepos": "65151"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Karang Besuki",
                "kodepos": "65149"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Bandungrejosari",
                "kodepos": "65148"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Ciptomulyo",
                "kodepos": "65148"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Gadang",
                "kodepos": "65149"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Tanjungrejo",
                "kodepos": "65147"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Bakalan Krajan",
                "kodepos": "65148"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Mulyorejo",
                "kodepos": "65147"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Sukun",
                "kodepos": "65147"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Bandulan",
                "kodepos": "65146"
            },
            {
                "kecamatan": "Sukun",
                "kelurahan": "Pisang Candi",
                "kodepos": "65146"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Ketawanggede",
                "kodepos": "65145"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Sumbersari",
                "kodepos": "65145"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Tlogomas",
                "kodepos": "65144"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Dinoyo",
                "kodepos": "65144"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Merjosari",
                "kodepos": "65144"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Tasikmadu",
                "kodepos": "65143"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Tunggulwulung",
                "kodepos": "65143"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Tulusrejo",
                "kodepos": "65141"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Mojolangu",
                "kodepos": "65142"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Tunjungsekar",
                "kodepos": "65142"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Jatimulyo",
                "kodepos": "65141"
            },
            {
                "kecamatan": "Lowokwaru",
                "kelurahan": "Lowokwaru",
                "kodepos": "65141"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Sawojajar",
                "kodepos": "65139"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Cemorokandang",
                "kodepos": "65138"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Lesanpuro",
                "kodepos": "65138"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Madyopuro",
                "kodepos": "65139"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Kedungkandang",
                "kodepos": "65137"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Jodipan",
                "kodepos": "65137"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Wonokoyo",
                "kodepos": "65135"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Buring",
                "kodepos": "65136"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Kotalama",
                "kodepos": "65136"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Bumiayu",
                "kodepos": "65135"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Mergosono",
                "kodepos": "65134"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Arjowinangun",
                "kodepos": "65132"
            },
            {
                "kecamatan": "Kedungkandang",
                "kelurahan": "Tlogowaru",
                "kodepos": "65133"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Balearjosari",
                "kodepos": "65126"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Polowijen",
                "kodepos": "65126"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Purwodadi",
                "kodepos": "65125"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Arjosari",
                "kodepos": "65126"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Pandanwangi",
                "kodepos": "65124"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Blimbing",
                "kodepos": "65125"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Polehan",
                "kodepos": "65121"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Purwantoro",
                "kodepos": "65122"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Bunulrejo",
                "kodepos": "65123"
            },
            {
                "kecamatan": "Blimbing",
                "kelurahan": "Kesatrian",
                "kodepos": "65121"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Oro Oro Dowo",
                "kodepos": "65119"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Kauman",
                "kodepos": "65119"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Kidul Dalem",
                "kodepos": "65119"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Sukoharjo",
                "kodepos": "65118"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Kasin",
                "kodepos": "65117"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Bareng",
                "kodepos": "65116"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Samaan",
                "kodepos": "65112"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Penanggungan",
                "kodepos": "65113"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Gadingkasri",
                "kodepos": "65115"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Klojen",
                "kodepos": "65111"
            },
            {
                "kecamatan": "Klojen",
                "kelurahan": "Rampal Celaket",
                "kodepos": "65111"
            }
        ],
        "k127": [
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Sumber Brantas",
                "kodepos": "65338"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Gunung Sari",
                "kodepos": "65337"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Punten",
                "kodepos": "65338"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Sumber Gondo",
                "kodepos": "65335"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Tulung Rejo",
                "kodepos": "65336"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Giripurno",
                "kodepos": "65333"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Bulukerto",
                "kodepos": "65334"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Pandan Rejo",
                "kodepos": "65332"
            },
            {
                "kecamatan": "Bumiaji",
                "kelurahan": "Bumiaji",
                "kodepos": "65331"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Tlekung",
                "kodepos": "65327"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Torongrejo",
                "kodepos": "65325"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Beji",
                "kodepos": "65326"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Pendem",
                "kodepos": "65324"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Junrejo",
                "kodepos": "65321"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Mojorejo",
                "kodepos": "65322"
            },
            {
                "kecamatan": "Junrejo",
                "kelurahan": "Dadaprejo",
                "kodepos": "65323"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Sumberejo (Sumber Rejo)",
                "kodepos": "65318"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Temas",
                "kodepos": "65315"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Oro-Oro Ombo",
                "kodepos": "65316"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Sidomulyo",
                "kodepos": "65317"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Pesanggrahan",
                "kodepos": "65313"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Sisir",
                "kodepos": "65314"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Ngaglik",
                "kodepos": "65311"
            },
            {
                "kecamatan": "Batu",
                "kelurahan": "Songgo Kerto",
                "kodepos": "65312"
            }
        ],
        "k128": [
            {
                "kecamatan": "Prambon",
                "kelurahan": "Watu Dandang",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Tegaron",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Tanjung Tani",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Sono Ageng",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Sugihwaras",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Sanggrahan",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Singkal Anyar",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Nglawak",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Rowoharjo",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Kurungrejo",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Mojo Agung",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Bandung",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Gondanglegi",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Wates",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Baleturi",
                "kodepos": "64484"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Tanjung Anom",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Warujayeng",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Sumberkepuh",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Sonobekel",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Sambirejo",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Sidoharjo",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Kedungrejo",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Malangsari",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Ngadirejo",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Kampungbaru",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Kedung Ombo",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Getas",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Jogomerto",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Demangan",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Sumengko",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Tanjunganom",
                "kelurahan": "Banjar Anyar",
                "kodepos": "64482"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Sukomoro",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Putren",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Ngrengket",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Pehserut",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Ngrami",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kedung Soko",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Nglundo",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bungur",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kapas",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bagor Wetan",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Blitaran",
                "kodepos": "64481"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Sidorejo",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Siwalan",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Ngliman",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Sawahan",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Kebon Agung",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Margopatut",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Duren",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Bendolo",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Bareng",
                "kodepos": "64475"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Oro-Oro Ombo",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Suru",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Mojoduwur",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Ngetos",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Kuncir",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Kweden",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Kepel",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Klodan",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Tiripan",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Ngetos",
                "kelurahan": "Blongko",
                "kodepos": "64474"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Sumber Urip",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Sumberwindu",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Sendang Bumen",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Sengkut",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Sono Patik",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Salamrojo",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Semare",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Ngrawan",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Patranrejo",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Kacangan",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Maguan",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Mlilir",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Bulu",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Cepoko",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Grojogan",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Balongrejo",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Bendungrejo",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Berbek",
                "kelurahan": "Berbek",
                "kodepos": "64473"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Sanan",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Pace Wetan",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Pacekulon",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Plosoharjo",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Kecubung",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Kepanjen",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Mlandangan",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Jetis",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Joho",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Gemenggeng",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Jampes",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Jatigreges",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Batembat",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Bodor",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Cerme",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Banaran",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Tempel Wetan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Babadan",
                "kodepos": "64472"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Tanjungrejo",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Teken Glagahan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Sombron",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Sukorejo",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Putukrejo",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Sekaran",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Nglaban",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Patihan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Mungkung",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Ngepeh",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Kwagean",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Loceret",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Macanan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Karangsono",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Kenep",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Jatirejo",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Genjeng",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Godean",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Bajulan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Candirejo",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Loceret",
                "kelurahan": "Gejagan",
                "kodepos": "64471"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Wilangan",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Ngudikan",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Sukoharjo",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Sudimoroharjo",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Ngadipiro",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Sugih Waras",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Wilangan",
                "kelurahan": "Mancon",
                "kodepos": "64462"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Sekar Putih",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Selorejo",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Paron",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Pesudukuh",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Petak",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Ngumpul",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Kutorejo",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Kendal Rejo",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Kerep Kidul",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Karang Tengah",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Kedondong",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Gemenggeng",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Girirejo",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Guyangan",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Buduran",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Gandu",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Banaran Kulon",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Banaran Wetan",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Bagor Kulon",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Bagor",
                "kelurahan": "Balongrejo",
                "kodepos": "64461"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Wengkal",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Talun",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Tritik",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Sidokare",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Sukorejo",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Talang",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Sambi Kerep",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Setren",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Puh Kerep",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Rejoso",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Musir Lor",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Ngadiboyo",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Ngangkatan",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Musir Kidul",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Mungkung",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Klagen",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Mlorah",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Mojorembun",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Jatirejo",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Jintel",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Kedung Padang",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Benda Asri",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Gempol",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Rejoso",
                "kelurahan": "Banjarejo",
                "kodepos": "64453"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Tempuran",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Lengkong Lor",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Ngluyu",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Sugihwaras",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Bajang",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Ngluyu",
                "kelurahan": "Gampeng",
                "kodepos": "64452"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sumber Agung",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sumberjo",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Senggowar",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Senjayan",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Ngujung",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Pandean",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sanggrahan",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Mojoseto",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Nglinggo",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kedung Glugu",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Ketawang",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Losari",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Karangsemi",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Jaan",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Pace",
                "kelurahan": "Gondang",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondang Kulon",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Balong Gebang",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Campur",
                "kodepos": "64451"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Ringin Anom",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Werungotok",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Kramat",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Mangun Dikaran",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Ganung Kidul",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Kedung Dowo",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Ploso",
                "kodepos": "64417"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Payaman",
                "kodepos": "64418"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Balong Pacul",
                "kodepos": "64419"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Jatirejo",
                "kodepos": "64416"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Kartoharjo",
                "kodepos": "64416"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Bogo",
                "kodepos": "64415"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Cangkringan",
                "kodepos": "64415"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Kauman",
                "kodepos": "64411"
            },
            {
                "kecamatan": "Nganjuk",
                "kelurahan": "Begadung",
                "kodepos": "64413"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Tanjung Kalang",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Trayang",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Mojokendil",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Ngronggot",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Kaloran",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Kelutan",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Klurahan",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Juwet",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Kali Anyar",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Cengkok",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Dadapan",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Banjar Sari",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Ngronggot",
                "kelurahan": "Betet",
                "kodepos": "64395"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Sambiroto",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Waung",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Mabung",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Kemlokolegi",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Katerban",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Kemaduh",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Jambi",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Jekek",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Baron",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Garu",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Baron",
                "kelurahan": "Gebang Kerep",
                "kodepos": "64394"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Sumbermiri",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Sumbersono",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Sawahan",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Sumber Kepuh",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Ngringin",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Pinggir",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Prayungan",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Ngepung",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Lengkong",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Jegreg",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Kedung Mlaten",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Ketandan",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Banjar Dowo",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Jati Punggur",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Balong Asem",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Lengkong",
                "kelurahan": "Banggle",
                "kodepos": "64393"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Pule",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Pulowetan",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Munung",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Ngasem",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Perning",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Jatikalen",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Lumpang Kuwik",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Dluru",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Gondang Wetan",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Begendeng",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Jatikalen",
                "kelurahan": "Dawuhan",
                "kodepos": "64392"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Rowomarto",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Tirtobinangun",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Pecuk",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Pisang",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Ngrombot",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Pakuncen",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Patianrowo",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Lestari",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Ngepung",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Babadan",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Patianrowo",
                "kelurahan": "Bukur",
                "kodepos": "64391"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Tembarak",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Nglawak",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Pandan Toyo",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Tanjung",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Lambang Kuning",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Juwono",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Kalianyar",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Kepuh",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Drenges",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Bangsri",
                "kodepos": "64315"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Pelem",
                "kodepos": "64314"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Kutorejo",
                "kodepos": "64313"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Kudu",
                "kodepos": "64312"
            },
            {
                "kecamatan": "Kertosono",
                "kelurahan": "Banaran",
                "kodepos": "64311"
            }
        ],
        "k129": [
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Mlancu",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Medowo",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Klampisan",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kemiri",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kasreman",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Karangtengah",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Jlumbang",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kandangan",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Jerukwangi",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Jerukgulung",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Bukur",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Banaran",
                "kodepos": "64294"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Siman",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Krenceng",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Kencong",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Kepung",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Keling",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Kebonrejo",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Kampungbaru",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Damarwulan",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Brumbung",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Kepung",
                "kelurahan": "Besowo",
                "kodepos": "64293"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Wonorejo",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Watugede",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Sidomulyo",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Satak",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Puncu",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Manggis",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Gadungan",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Puncu",
                "kelurahan": "Asmorobangun",
                "kodepos": "64292"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Sempu",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Sugihwaras",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Margourip",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Ngancar",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Pandantoyo",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Manggis",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Babadan",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Bedali",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Jagul",
                "kodepos": "64291"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Sumberbendo",
                "kodepos": "64228"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Sidorejo",
                "kodepos": "64229"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Sambirejo",
                "kodepos": "64226"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Darungan",
                "kodepos": "64227"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Bendo",
                "kodepos": "64225"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Sekoto",
                "kodepos": "64223"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Bringin",
                "kodepos": "64224"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Badas",
                "kodepos": "64221"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Tunglur",
                "kodepos": "64222"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Krecek",
                "kodepos": "64218"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Blaru",
                "kodepos": "64219"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Canggu",
                "kodepos": "64217"
            },
            {
                "kecamatan": "Badas",
                "kelurahan": "Lamong",
                "kodepos": "64216"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Gedangsewu",
                "kodepos": "64214"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Tertek",
                "kodepos": "64215"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Tulungrejo",
                "kodepos": "64212"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Pelem",
                "kodepos": "64213"
            },
            {
                "kecamatan": "Pare",
                "kelurahan": "Pare",
                "kodepos": "64211"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Wates",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Wonosari",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Tengger Kidul",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Sitimert0",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Tanjung",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Sukoharjo",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Senden",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Semanding",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Semen",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Sambirobyong",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Sekaran",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Semambung",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Pagu",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Padangan",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Mukuh",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Nanggungan",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Kambingan",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Menang",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Kayen Kidul",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Jagung",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Jambu",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Bendo",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Pagu",
                "kelurahan": "Bulupasar",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Bangsongan",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Kayen Kidul",
                "kelurahan": "Baye",
                "kodepos": "64183"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Wanengpaten",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Wonocatur",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Turus",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Tugurejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Toyoresmi",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Sukorejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Sumberjo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Sambirejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Sambiresik",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Plosorejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Putih",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Paron",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Ngebrak",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Ngasem",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Kwadungan",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Nambaan",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Kepuhrejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Karangrejo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Jongbiru",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Kalibelo",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gampengrejo",
                "kelurahan": "Gampeng (Gamping)",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Gogorante",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Wonojoyo",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Doko",
                "kodepos": "64182"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Tiru Lor",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Turus",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Tambakrejo",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Tiru Kidul",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Sukorejo",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Sumbercangkring",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Nglumbang",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Ngasem",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Kerkep",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Kranggan",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Gabru",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Gempolan",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Gurah",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Blimbing",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Bogem",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Banyuanyar",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Besuk",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Adan-Adan",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Bangkok",
                "kodepos": "64181"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Selodono",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Srikaton",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Susuhbango",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Sambi",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Nambaan",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Purwodadi",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Ringinrejo",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Deyeng",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Jemekan",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Batuaji",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Ringinrejo",
                "kelurahan": "Dawung",
                "kodepos": "64176"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Wonorejo Trisulo",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Punjul",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Sepawon",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Sumberagung",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Plosolor",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Pranggang",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Klanderan",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Panjer",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Plosokidul",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Kawedusan",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Kayunan",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Gondang",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Jarak",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Brenggolo",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Plosoklaten",
                "kelurahan": "Donganti",
                "kodepos": "64175"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Tunge",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Wates",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Wonorejo",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Tempurejo",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Silir",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Sumberagung",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Tawang",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Segaran",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Sidomulyo",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Pagu",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Plaosan",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Pojok",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Joho",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Karanganyar",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Jajar",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Janti",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Duwet",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Wates",
                "kelurahan": "Gadungan",
                "kodepos": "64174"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Tegalan",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Ringinsari",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Selosari",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Sumberejo",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Purworejo",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Pule",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Ngreco",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Kandat",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Karangrejo",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Blabak",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Cendono",
                "kodepos": "64173"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Rejomulyo",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Setonorejo",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Nyawangan",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Pelas",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Purwodadi",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Kras",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Mojosari",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Karangtalun",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Krandang",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Jambean",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Kanigoro",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Butuh",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Jabang",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Bendosari",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Bleber",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Kras",
                "kelurahan": "Banjaranyar",
                "kodepos": "64172"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Wonorejo",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Seketi",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Slumbung",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Tales",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Rembang",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Rembangkepuh",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Ngadiluwih",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Purwokerto",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Dukuh",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Mangunrejo",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Banjarejo",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Bedug",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Branggahan",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Badalpandean",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Banggle",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Ngadiluwih",
                "kelurahan": "Badal",
                "kodepos": "64171"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Tambibendo",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Sukoanyar",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Surat",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Ploso",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Ponggok",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Petok",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Petungroto",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Ngetrep",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Pamongan",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Mojo",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Mondo",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Ngadi",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Maesan",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Mlati",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Keniten",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Kranding",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Kraton",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Jugo",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Kedawung",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Mojo",
                "kelurahan": "Blimbing",
                "kodepos": "64162"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Semen",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Sidomulyo",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Titik",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Puhrubuh",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Puhsarang",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Selopanggung",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Kanyoran",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Kedak",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Pagung",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Joho",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Bulu",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Semen",
                "kelurahan": "Bobang",
                "kodepos": "64161"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Sendang",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Tiron",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Ngablak",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Parang",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Jatirejo",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Manyaran",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Maron",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Banyakan",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Banyakan",
                "kelurahan": "Jabon",
                "kodepos": "64157"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Tenggerlor",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Wonorejo",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Kuwik",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Pakis",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Parelor",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Ngancar",
                "kelurahan": "Kunjang",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Kunjang",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Kapas",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Kapi",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Klepek",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Dungus",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Juwet",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Wonokerto",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Kunjang",
                "kelurahan": "Balongjeruk (Balung Jeruk)",
                "kodepos": "64156"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Sukoharjo",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Tegowangi",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Sebet",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Sidowarek",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Ringinpitu",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Puhjarak",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Ngino",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Payaman",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Plemahan",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Mojoayu",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Mojokerep",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Langenharjo",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Mejono",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Bogokidul",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Kayen Lor",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Wonotengah",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Woromarto",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Plemahan",
                "kelurahan": "Banjarejo",
                "kodepos": "64155"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Sumberjo",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Tugu",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Purwodadi",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Sidomulyo",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Pesing",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Purwoasri",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Muneng",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Pandansari",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Merjoyo",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Mranggen",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Klampitan",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Mekikis",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Karangpakis",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Kempleng",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Ketawang",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Dawuhan",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Dayu",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Jantok",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Blawe",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Bulu",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Tanon",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Purwoasri",
                "kelurahan": "Belor",
                "kodepos": "64154"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Srikaton",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Sukomoro",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Pehwetan",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Puhjajar",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Purwotengah",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Papar",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Pehkulon",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Minggiran",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Ngampel",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Maduretno",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Kwaron",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Kedungmalang",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Kepuh",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Jambangan",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Janti",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Tarokan",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Papar",
                "kelurahan": "Dawuhan Kidul",
                "kodepos": "64153"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Kerep",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Sumberduren",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Kaliboto",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Kalirong",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Kedungsari",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Cengkok",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Jati",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Blimbing",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Tarokan",
                "kelurahan": "Bulusari",
                "kodepos": "64152"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Wonoasri",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Kalipang",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Sonorejo",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Sumberejo",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Datengan",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Gambyok",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Grogol",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Bakalan",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Grogol",
                "kelurahan": "Cerme",
                "kodepos": "64151"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Tempurejo",
                "kodepos": "64138"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Ketami",
                "kodepos": "64139"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Ngletih",
                "kodepos": "64137"
            },
            {
                "kecamatan": "Kandat",
                "kelurahan": "Ngletih",
                "kodepos": "64137"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Bawang",
                "kodepos": "64136"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Tosaren",
                "kodepos": "64133"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Betet",
                "kodepos": "64134"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Blabak",
                "kodepos": "64135"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Tinalan",
                "kodepos": "64132"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Banaran",
                "kodepos": "64133"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Pakunden",
                "kodepos": "64132"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Singonegaran",
                "kodepos": "64132"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Pesantren",
                "kodepos": "64131"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Jamsaren",
                "kodepos": "64132"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Bangsal",
                "kodepos": "64131"
            },
            {
                "kecamatan": "Pesantren",
                "kelurahan": "Burengan",
                "kodepos": "64131"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Setono Pande",
                "kodepos": "64129"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Ringin Anom",
                "kodepos": "64129"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Setono Gedong",
                "kodepos": "64129"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Balowerti",
                "kodepos": "64129"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Rejomulyo",
                "kodepos": "64129"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Ngronggo",
                "kodepos": "64127"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Manisrenggo",
                "kodepos": "64128"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Kaliombo",
                "kodepos": "64126"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Kampung Dalem",
                "kodepos": "64126"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Jagalan",
                "kodepos": "64125"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Kemasan",
                "kodepos": "64125"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Pakelan",
                "kodepos": "64123"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Pocanan",
                "kodepos": "64123"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Banjaran",
                "kodepos": "64124"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Dandangan",
                "kodepos": "64122"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Ngadirejo",
                "kodepos": "64122"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Bujel",
                "kodepos": "64119"
            },
            {
                "kecamatan": "Kediri Kota",
                "kelurahan": "Semampir",
                "kodepos": "64121"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Banjar Melati (Banjarmlati)",
                "kodepos": "64119"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Tamanan",
                "kodepos": "64116"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Lirboyo",
                "kodepos": "64117"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Bandar Kidul",
                "kodepos": "64118"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Campurejo",
                "kodepos": "64116"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Sukorame",
                "kodepos": "64114"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Pojok",
                "kodepos": "64115"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Gayam",
                "kodepos": "64113"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Bandar Lor",
                "kodepos": "64114"
            },
            {
                "kecamatan": "Gurah",
                "kelurahan": "Gayam",
                "kodepos": "64113"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Mojoroto",
                "kodepos": "64112"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Ngampel",
                "kodepos": "64112"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Dermo",
                "kodepos": "64111"
            },
            {
                "kecamatan": "Mojoroto",
                "kelurahan": "Mrican",
                "kodepos": "64111"
            }
        ],
        "k130": [
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Tokawi (Tokawai)",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Sempu",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Penggung",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Pakisbaru",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Ngromo",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Nawangan",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Mujing",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Jetislor",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Nawangan",
                "kelurahan": "Gondang",
                "kodepos": "63584"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Watupatok",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tumpuk",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Petungsinarang",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Ngunut",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Kledung",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Jeruk",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bangunsari",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bandar",
                "kodepos": "63583"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Tegalombo",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Tahunan",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Tahunan Baru",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Pucangombo (Pucung Ombo)",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Ploso",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Ngreco (Ngereco)",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Kemuning",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Kebondalem",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Kasihan",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Gemaharjo",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Tegalombo",
                "kelurahan": "Gedangan",
                "kodepos": "63582"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Tremas",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Temon",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Sedayu",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Pagutan",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Mangunharjo",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Mlati",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Kedungbendo",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Karangrejo",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Karanggede",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Jetis Kidul",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Jatimalang",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Gembong",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Gunungsari",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Gayuhan",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Gegeran",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Arjosari",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Arjosari",
                "kelurahan": "Borang",
                "kodepos": "63581"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Sudimoro",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Sukorejo",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Sumberejo (Sumber Rejo)",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Pager Lor",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Sembowo",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Klepu (Kelepu)",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Pager Kidul",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Karang Mulyo",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Ketanggung",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Sudimoro",
                "kelurahan": "Gunung Rejo",
                "kodepos": "63573"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wonodadi Wetan",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wonokarto",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wonosobo",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wiyoro",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wonoasri",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Wonodadi Kulon",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Sidomulyo",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Tanjung Lor",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Tanjungpuro",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Nogosari",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Pagerejo (Pangerejo)",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Hadiwarno",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Ngadirojo",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Cangkring",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Cokrokembang",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Hadiluwih",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Bodag",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Ngadirojo",
                "kelurahan": "Bogoharjo (Begoharjo)",
                "kodepos": "63572"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Tulakan",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Wonoanti",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Wonosidi",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Nglaran (Ngelaran)",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Ngumbul",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Padi",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Losari",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Ngile",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Ketro",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Kluwih",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Jatigunung",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Jetak",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Kalikuning",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Bubakan",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Bungur",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Tulakan",
                "kelurahan": "Gasang",
                "kodepos": "63571"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Worawari",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Sidomulyo",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Wonogondo",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Purwoasri",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Sanggrahan",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Plumbungan (Pelumbungan)",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Punjung",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Klesem",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Mantren",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Ketepung",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Ketro",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Karangnongko",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Katipugal",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Kebonagung",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Kalipelus",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Karanganyar",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Gawang",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Gembuk",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Kebon Agung",
                "kelurahan": "Banjarjo",
                "kodepos": "63561"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Sukodono",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Widoro",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Sekar",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Sendang",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Klepu",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Sawahan",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Kalak",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Donorojo",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Gedompol",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Gendaran",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Cemeng",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Donorojo",
                "kelurahan": "Belah",
                "kodepos": "63554"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Wareng",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Sooka (Soko)",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Tinatar",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Ploso",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Punung",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Mendolo Kidul",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Mendolo Lor",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Piton",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Kendal",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Mantren",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Gondosari",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Kebonsari",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Punung",
                "kelurahan": "Bomo",
                "kodepos": "63553"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Watu Karung",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Sugihwaras",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Tamanasri",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Pringkuku",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Sobo",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Pelem",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Poko",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Glinggangan",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Jlubang",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Ngadirejan",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Dadapan",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Dersono",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Widoro",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pringkuku",
                "kelurahan": "Candi",
                "kodepos": "63552"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Tanjungsari",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Tambakrejo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sumberharjo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sirnoboyo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sukoharjo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sedeng",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Semanten",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Purworejo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sambong",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Nanggungan",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Ponggok",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Kembang",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Mentoro",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Bolosingo",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Kayen",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Banjarsari",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Bangunsari",
                "kodepos": "63518"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Menadi",
                "kodepos": "63517"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Ploso",
                "kodepos": "63515"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Arjowinangun",
                "kodepos": "63516"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Pucangsewu (Pacungsewu)",
                "kodepos": "63513"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Sidoharjo",
                "kodepos": "63514"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Baleharjo",
                "kodepos": "63511"
            },
            {
                "kecamatan": "Pacitan",
                "kelurahan": "Pacitan",
                "kodepos": "63512"
            }
        ],
        "k131": [
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Wagir Lor",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Sempu",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Talun",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Pupus",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Sahang",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Gondowido",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Ngebel",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Ngebel",
                "kelurahan": "Ngrogung",
                "kodepos": "63493"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Wates",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Singosaren",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Sraten",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Tanjung Sari",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Semanding",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Setono",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Plalangan",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Panjeng",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Sedah",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Paringan",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Pintu",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Ngrupit",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Mrican",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Nglayang",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Jimbe",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Kemiri",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Trisono",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Jenangan",
                "kelurahan": "Jenangan",
                "kodepos": "63492"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Pondok",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Purwosari",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Sukosari",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Patihan Wetan",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Polorejo",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Lembah",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Ngunut",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Japan",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Kadipaten",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Kertosari",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Bareng",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Cekok",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Gupolo",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Babadan",
                "kelurahan": "Babadan",
                "kodepos": "63491"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Sooko",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Suru (Seru)",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Klepu",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Ngadirojo (Ngadirejo)",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Jurug",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Bedoho",
                "kodepos": "63482"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Wayang",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Wotan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Singgahan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Tegalrejo",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Wagirkidul",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Sidoharjo",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Pulung Merdiko",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Serag",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Plunturan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Pomahan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Pulung",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Patik",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Munggung",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Karangpatihan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Kesugihan",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Banaran",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Bedrug",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Pulung",
                "kelurahan": "Bekiring",
                "kodepos": "63481"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Tumpakpelem",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Tumpuk",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Tempuran",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Tugurejo",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Sriti (Serinti)",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Temon",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Prayungan",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Sawoo",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Ngindeng",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Pangkal",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Grogol",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Ketro",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Kori",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sawoo",
                "kelurahan": "Bondrang",
                "kodepos": "63475"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Sambit",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Wilangan",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Wringinanom",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Ngadisanan",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Nglewan (Ngelewan)",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Kemuning",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Maguwan",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Campursari",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Gajah",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Jrakah",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Campurejo",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Bulu",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Besuki",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Bangsalan",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Bedingin",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Wonoketro",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Sambit",
                "kelurahan": "Bancangan",
                "kodepos": "63474"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Turi",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Winong",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Mojorejo",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Ngasinan",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Tegalsari",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Kutu Wetan",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Mojomati",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Karanggebang",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Kradenan (Kradinan)",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Kutu Kulon",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Jetis",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Josari",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Coper",
                "kodepos": "63473"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Suren",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Totokan",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Tugu (Tugurejo)",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Serangan",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Siwalan",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Nglumpang",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Ngrukem (Ngerukem)",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Mlarak",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Kaponan",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Gontor",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Jabung",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Joresan",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Candi",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Gandu",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Tranjang",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Mlarak",
                "kelurahan": "Bajang",
                "kodepos": "63472"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Siman",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Tajug",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Ronowijayan",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Sawuh",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Sekaran (Sakaran)",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Pijeran",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Ronosentanan",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Patihan Kidul",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Ngabar",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Madusari",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Mangunsuman (Ngamunsuman)",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Manuk",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Kepuh Rubuh",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Jarak",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Brahu",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Demangan",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Temon",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Wonodadi",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Siman",
                "kelurahan": "Beton",
                "kodepos": "63471"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Selur",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Sendang",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Gedangan",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Mrayan (Merayan)",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Ngrayun (Ngerayun)",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Binade",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Cepoko",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Baosan Kidul",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Ngrayun",
                "kelurahan": "Baosan Lor",
                "kodepos": "63464"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Wates",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Truneng",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Tugurejo",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Senepo",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Simo",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Slahung",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Ngloning",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Plancungan",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Nailan",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Ngilo-Ilo",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Kambeng",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Menggare",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Mojopitu",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Jebeng (Jabeng)",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Gombang",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Gundik",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Janti",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Crabak",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Duri",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Galak",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Broto",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Slahung",
                "kelurahan": "Caluk",
                "kodepos": "63463"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Pelem",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Sambilawang",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Padas",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Pager",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Munggu",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Nambak",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Kupuk",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Kwajon",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Ketonggo",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Koripan",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Kunti",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Kalisat",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bungkal",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bungu",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bedi Wetan",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bekare",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Belang (Blang)",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bancar",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Bungkal",
                "kelurahan": "Bedi Kulon",
                "kodepos": "63462"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Tatung",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Singkil",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Sumberejo",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Pandak",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Purworejo",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Sedarat",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Ngraket",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Ngumpul",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Ngampel",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Ngendut",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Karangpatihan",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Muneng",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Karangan",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Karangmojo",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Dadapan",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Jalen",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Bulak",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Bulukidul",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Balong",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Balong",
                "kelurahan": "Bajang",
                "kodepos": "63461"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Sidoharjo",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Srandil",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Pulosari",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Sendang",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Krebet",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Menang",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Poko",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Karanglo Kidul",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Jambon",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Jonggol",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Bulu Lor",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Blembem",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Jambon",
                "kelurahan": "Bringinan",
                "kodepos": "63456"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Tanjungrejo",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Watubonang",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Karangjoho",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Karangan",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Dayakan",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Kapuran",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Biting",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Bandaralim",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Badegan",
                "kelurahan": "Badegan",
                "kodepos": "63455"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Tulung",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Sampung",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Ringinputih",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Pohijo",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Pagerukir",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Nglurup",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Karang Waluh",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Kunti",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Jenangan",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Glinggang",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Gelangkulon",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sampung",
                "kelurahan": "Carang Rejo",
                "kodepos": "63454"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sukorejo",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sragi",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Sidorejo",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Serangan",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Nampan",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Prajegan",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Nambangrejo",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Morosari",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Lengkong",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kranggan",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kedung Banteng",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Kalimalang",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Karanglo Lor",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Golan",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Gelanglor",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Gegeran",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Gandukepuh",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Sukorejo",
                "kelurahan": "Bangunrejo",
                "kodepos": "63453"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Tosanan",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Tegalombo",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Somoroto",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Sukosari",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Plosojenar",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Semanding",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Pengkol",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Nglarangan (Nlarangan)",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Ngrandu",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Nongkodono",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Kauman",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Maron",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Ciluk",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Gabel",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Carat",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Kauman",
                "kelurahan": "Bringin",
                "kodepos": "63451"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Taman Arum",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Tambak Bayan",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Pinggir Sari",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Surodikraman",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Bangunsari",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Brotonegaran",
                "kodepos": "63419"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Tonatan",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Tambang",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Pudakkulon",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Pudakwetan",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Bareng",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Krisik",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Pudak",
                "kelurahan": "Banjarjo",
                "kodepos": "63418"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Pakunden",
                "kodepos": "63416"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Purbosuman",
                "kodepos": "63417"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Kauman",
                "kodepos": "63414"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Paju",
                "kodepos": "63415"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Kepatihan",
                "kodepos": "63416"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Keniten",
                "kodepos": "63412"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Mangkujayan",
                "kodepos": "63413"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Nologaten",
                "kodepos": "63411"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Beduri",
                "kodepos": "63412"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Cokromenggalan",
                "kodepos": "63411"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Jingglong",
                "kodepos": "63411"
            },
            {
                "kecamatan": "Ponorogo",
                "kelurahan": "Banyudono",
                "kodepos": "63411"
            }
        ],
        "k132": [
            {
                "kecamatan": "Karas",
                "kelurahan": "Temboro",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Temenggungan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Tebon",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Sumursongo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Taji",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Sambirembe",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Sobontoro",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Sukowidi",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Purwodadi",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Rejomulyo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Pencol",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Prampelan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Patihan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Panggung",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Pelem",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Ngelang",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Ngumpul",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Mrahu",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Mantren",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Maron",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Manisrejo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Manjung",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Kuwon",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Klurahan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Mangge",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Kartoharjo (Kertoharjo)",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Kauman",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Klagen",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Karas",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Karangsono",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Jungke",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Karangmojo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Karangrejo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Jonggrang",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Gunungan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Jajar",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Jeruk",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Gondang",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Grabahan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Geplak",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Ginuk",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Botok",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Gebyog",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Blaran",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Bogorejo",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Bayem Taman",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Kartoharjo (Kertoharjo)",
                "kelurahan": "Bayem Wetan",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Karangrejo",
                "kelurahan": "Baluk",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Bangunasri",
                "kodepos": "63395"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Suratmajan",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Tanjungsepreh",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Sumberejo",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Sempol",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Sugihwaras",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Ronowijayan",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Pandeyan",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Pesu",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Mranggen",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Ngujung",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Kraton",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Malang",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Maospati",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Gulun",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Maospati",
                "kelurahan": "Klagen Gambiran",
                "kodepos": "63392"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Tinap",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Truneng",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Tambakmas",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Sukomoro",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Tamanan",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kembangan",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kentangan",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Pojoksari",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kedungguwo",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bulu",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Kalangketi",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bibis",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bogem",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Sukomoro",
                "kelurahan": "Bandar",
                "kodepos": "63391"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Tanjung",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Tegalarum",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Setren",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Soco",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Lemahbang",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Pingkuk",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Kleco",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Kledokan",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Duwet",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Kinandang",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Carikan",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Dukuh",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Bendo",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Bulak",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Bulugledeg",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Bendo",
                "kelurahan": "Belotan",
                "kodepos": "63384"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Waduk",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Takeran",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Tawangrejo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Simbatan",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Sukowidi",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Sawojajar",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Semen",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Nguntoronadi",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Petungrejo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Purworejo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Kuwonharjo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Madigondo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Kerik",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Kiringan",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Kepuhrejo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Kerang",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Jomblang",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Kenongomulyo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Takeran",
                "kelurahan": "Duyung",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Gorang Gareng",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Nguntoronadi",
                "kelurahan": "Driyorejo",
                "kodepos": "63383"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Tulung",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Selorejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Sugihrejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Tladan",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Sampung",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Pojok",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Rejosari",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Ngentep",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Ngunut",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Ngadirejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Mangunrejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Mojorejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Karangrejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Kawedanan",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Genengan",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Giripurno",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Jambangan",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Bogem",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Garon",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Kawedanan",
                "kelurahan": "Balerejo",
                "kodepos": "63382"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Pupus",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Tapen",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Tunggur",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Lembeyan Wetan",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Nguri",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Lembeyan Kulon",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Kedungpanji",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Krowe",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Dukuh",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Lembeyan",
                "kelurahan": "Kediren",
                "kodepos": "63372"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Trosono",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Sayutan",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Sundul",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Tamanarum",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Parang",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Pragak",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Nglopang",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Ngunut",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Mategal",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Ngaglik",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Krajan",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Bungkuk",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Parang",
                "kelurahan": "Joketro",
                "kodepos": "63371"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Poncol",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Sombo",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Plangkrongan",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Janggan",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Genilangit",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Gonggang",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Alastuwo",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Poncol",
                "kelurahan": "Cileng",
                "kodepos": "63362"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Sumberagung",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Sidorejo",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Sidomukti",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Sidomukti",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Randugede",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Sarangan",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Sendangagung",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Plumpung",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Puntukdoro",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Pacalan",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Plaosan",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Ngancar",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Nitikan",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Mojopurno",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Durenan",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Getasanyar",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Bulugunung",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Buluharjo",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Dadi",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Plaosan",
                "kelurahan": "Bogoarum",
                "kodepos": "63361"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Widorokandang",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Wates",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Terung",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Turi",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Tanjungsari",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Tapak",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Sukowidi",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Sumberdodol",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Sumbersawit",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Sidowayah",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Sidokerto",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Rejomulyo",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Milangasri",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Ngiliran",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Panekan",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Jabung",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Manjung",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Bedagung",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Cepoko",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Sumberdukun",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Panekan",
                "kelurahan": "Banjarejo",
                "kodepos": "63352"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Selopanggung",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Selotinatah",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Ngariboyo",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Pendem",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Banjarpanjang",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Banyudono",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Balegondo",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Bangsri",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Banjarejo",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Ngariboyo",
                "kelurahan": "Baleasri",
                "kodepos": "63351"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Sukowinangun",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Tambakrejo",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Sambirobyong",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Purwosari",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Ringinagung",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Magetan",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Kalang",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Candirejo",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Sidorejo",
                "kelurahan": "Campursari",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Tambran",
                "kodepos": "63318"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Baron",
                "kodepos": "63319"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Mangkujayan",
                "kodepos": "63316"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Kebonagung",
                "kodepos": "63317"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Selosari",
                "kodepos": "63313"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Bulukerto",
                "kodepos": "63315"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Kepolorejo",
                "kodepos": "63311"
            },
            {
                "kecamatan": "Magetan",
                "kelurahan": "Tawanganom",
                "kodepos": "63312"
            },
            {
                "kecamatan": "Barat",
                "kelurahan": "Banjarejo",
                "kodepos": "63137"
            }
        ],
        "k133": [
            {
                "kecamatan": "Bringin",
                "kelurahan": "Sumber Bening",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Suruh",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Krompol",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Lego Wetan",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Mojo",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Dero",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Gandong",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Kenongorejo",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Bringin",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Bringin",
                "kelurahan": "Dampit",
                "kodepos": "63285"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Sidokerto",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Sidorejo",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Sawo",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Sembung",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Rejomulyo",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Ringin Anom",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Rejomulyo",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Legundi",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Ploso Lor",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Puhti",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Jatipuro",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Karangjati",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Dungmiri",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Gempol",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Campur Asri",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Danguk",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Waruk Kalong",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Karangjati",
                "kelurahan": "Brangol",
                "kodepos": "63284"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Sumengko",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Tirak",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Purwosari",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Simo",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Mojomanis",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Pojok",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Kendung",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Kendung",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Jenangan",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Karangsono",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Budug",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Dinden",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Kwadungan",
                "kelurahan": "Banget",
                "kodepos": "63283"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Sumber",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Waruk Tengah",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Paras",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Pleset",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Poh Konyal",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Ngompro",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Pangkur",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Babadan",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Pangkur",
                "kelurahan": "Gandri",
                "kodepos": "63282"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Tungkulrejo",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Tawun",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Tambakromo",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Sambiroto",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Sukowiyono",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Pacing",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Padas",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Lego Kulon",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Munggut",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Kiyonten",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Kedung Prahu",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Kwadungan Lor",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Karang Malang",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Kasreman",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Cangakan",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Gunungsari",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Kasreman",
                "kelurahan": "Jatirejo",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Bendo",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Bintoyo",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Padas",
                "kelurahan": "Banjaransari",
                "kodepos": "63281"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Tambakromo",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Tepas",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Gerih",
                "kelurahan": "Widodaren",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Sidorejo",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Gerih",
                "kelurahan": "Randusongo",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Klampisan",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Klitik",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Keras Wetan",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Kersikan",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Kersoharjo",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Gerih",
                "kelurahan": "Keras Kulon",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Keniten",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Gerih",
                "kelurahan": "Guyung",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Kasreman",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Gerih",
                "kelurahan": "Gerih",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Geneng",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Dempel",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Geneng",
                "kelurahan": "Baderan",
                "kodepos": "63271"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Wonosari",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Tulakan",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Sumbersari",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Sumberejo",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Pocol",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Sine",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Pandansari",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Ngrendeng",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Kuniran",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Ketanggung",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Kauman",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Jagir",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Hargosari",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Girikerto",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Sine",
                "kelurahan": "Gendol",
                "kodepos": "63264"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Wakah",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Tawangrejo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Sidomulyo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Setono",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Sambirejo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Ngrambe",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Pucangan",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Mendiro",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Manisharjo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Krandegan",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Giriharjo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Hargomulyo",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Cepoko",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Ngrambe",
                "kelurahan": "Babadan",
                "kodepos": "63263"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Umbulrejo",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Tanjungsari",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Talang",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Ngrayudan",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Soco",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Macanan",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Kletekan",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Jogorogo",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Jaten",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Girimulyo",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Brubuh",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Jogorogo",
                "kelurahan": "Dawung",
                "kodepos": "63262"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Simo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Tambak Boyo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Ploso",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Sidorejo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Sambirejo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Patalan",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Patalan",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Mantingan",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Pakah",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Majasem",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Kedungharjo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Kendal",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Karanggupito",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Karangrejo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Mantingan",
                "kelurahan": "Jatimulyo",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Dadapan",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Kendal",
                "kelurahan": "Gayam",
                "kodepos": "63261"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Sekarjati",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Sri Wedari",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Mengger",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Pandean",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Bangunrejo",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Gembol",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Karanganyar",
                "kelurahan": "Karang Anyar",
                "kodepos": "63257"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Widodaren",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Walikukun",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Sidolaju",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Sidomakmur",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Sekar Alas",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Sekar Putih",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Kayutrejo",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Kedunggudel",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Kauman",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Karang Banyu",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Banyu Biru",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Widodaren",
                "kelurahan": "Gendingan",
                "kodepos": "63256"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Wonorejo",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Pelang Kidul",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Pelang Lor",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Wonokerto",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Kawu",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Kedunggalar",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Jenggrik",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Katikan",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Gemarang",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Jati Gembol",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Bangunrejo Kidul",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Kedunggalar",
                "kelurahan": "Begal",
                "kodepos": "63254"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Teguhan",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Tempuran",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Semen",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Sirigan",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Ngale",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Paron",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Kedung Putri",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Jeblogan",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Kebon",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Gentong",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Jambangan",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Dawu",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Gelung",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Selopuro",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Paron",
                "kelurahan": "Babadan",
                "kodepos": "63253"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Pitu",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Papungan",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Karanggeneng",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Ngancar",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Cantel",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Dumplengan",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Kalang",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Banjarbanggi",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Watualang",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Pitu",
                "kelurahan": "Bangunrejo Lor",
                "kodepos": "63252"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Kerek",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Mangunharjo",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Ngawi",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Kartoharjo",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Karang Tengah Prandon",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Karangasri",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Kandangan",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Karang Tengah",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Margomulyo",
                "kodepos": "63217"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Banyu Urip",
                "kodepos": "63218"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Jururejo",
                "kodepos": "63215"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Beran",
                "kodepos": "63216"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Pelem",
                "kodepos": "63212"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Grudo",
                "kodepos": "63214"
            },
            {
                "kecamatan": "Ngawi",
                "kelurahan": "Ketanggi",
                "kodepos": "63211"
            }
        ],
        "k134": [
            {
                "kecamatan": "Kare",
                "kelurahan": "Morang",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Randualas",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Kepel",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Kuwiran",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Kare",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Cermo",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Bodag",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Kare",
                "kelurahan": "Bolo",
                "kodepos": "63182"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Sobrah",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Tempursari",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Wungu",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Pilangrejo",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Sidorejo",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Munggut",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Nglambangan",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Nglanduk",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Mojopurno",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Mojorayung",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Karangrejo",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Kresek",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Bantengan",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Wungu",
                "kelurahan": "Brumbun",
                "kodepos": "63181"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Lembah",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Mlilir",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Suluk",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Glonggong",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Ketawang",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Kradinan",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Doho",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Dolopo",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Blimbing",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Candimulyo",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Bangunsari",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Dolopo",
                "kelurahan": "Bader",
                "kodepos": "63174"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Tambakmas",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Tanjungrejo",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Sidorejo",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Singgahan",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Sukorejo",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Pucanganom",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Rejosari",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Palur",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Mojorejo",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Kebonsari",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Kendondong",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Krandegan",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Bacem",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Kebonsari",
                "kelurahan": "Balerejo",
                "kodepos": "63173"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Tileng",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Segulung",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Sewulan",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Sukosari",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Padas",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Prambon",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Mruwak",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Ngranget",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Ketandan",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Mendak",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Kepet",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Jetis",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Joho",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Banjarsari Wetan",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Dagangan",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Banjarejo",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Dagangan",
                "kelurahan": "Banjarsari Kulon",
                "kodepos": "63172"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Uteran",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Sareng",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Slambur",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Sumberejo",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Sambirejo",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Sangen",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Purworejo",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Putat",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Pagotan",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kranggan",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Nglandung",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kertobanyon",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kertosari",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Klorogan",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Jogodayuh",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Kaibon",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Banaran",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Geger",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Geger",
                "kelurahan": "Jatisari",
                "kodepos": "63171"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Sidomulyo",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Rejosari",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Sawahan",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Pucangrejo",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Pule",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Klumpit",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Krokeh",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Lebakayu",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Kajang",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Kanung",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Bakur",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Cabean",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Golan",
                "kodepos": "63162"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Teguhan",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Wayut",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Samberejo",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Sukolilo",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Metesih",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Ngetrep",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Kwangsen",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Kincang Wetan",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Klagen Serut",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Grobogan",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Jiwan",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Bibrik",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Bukur",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Wonoasri",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Jiwan",
                "kelurahan": "Bedoho",
                "kodepos": "63161"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Purwosari",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Sidomulyo",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Klitik",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Ngadirejo",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Plumpungrejo",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Buduran",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Jatirejo",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Bancong",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Wonoasri",
                "kelurahan": "Banyukambang",
                "kodepos": "63157"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Winong",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Sebayi",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Tawangrejo",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Nampu",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Durenan",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Gemarang",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Tulung",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Gemarang",
                "kelurahan": "Batok",
                "kodepos": "63156"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sukorejo",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sumberbendo",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sumbersari",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sidorejo",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sugihwaras",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Pajaran",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Sambirejo",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Klumutan",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Ngepeh",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Klangon",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Bener",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Bongsopotro",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Bajulan",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Saradan",
                "kelurahan": "Bandungan",
                "kodepos": "63155"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Wonoayu",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Sumbergandu",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Pulerejo",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Purworejo",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Ngale",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Ngengor",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Pilangkenceng",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Luworo",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Muneng",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Kenongorejo",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Krebet",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Kedung Banteng",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Kedungmaron",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Kedungrejo",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Duren",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Gandul",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Bulu",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Pilangkenceng",
                "kelurahan": "Dawuhan",
                "kodepos": "63154"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Pandeyan",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Wonorejo",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Mejayan",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Ngampel",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Klecorejo",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Krajan",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Kuncen",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Kebonagung",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Darmorejo",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Kaliabu",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Kaligunting",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Bangunsari",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Blabakan",
                "kodepos": "63153"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Tapelan",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Warurejo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Sogo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Sumberbening",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Kuwu",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Pacinan",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Simo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Kedungjati",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Kedungrejo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Glonggong Lor",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Jerukgulung",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Kebonagung",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Gading",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Garon",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Balerejo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Banaran",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Bulakrejo",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Balerejo",
                "kelurahan": "Babadan Lor",
                "kodepos": "63152"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Tulungrejo",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Sumberejo",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Tanjungrejo",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Tiron",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Sendangrejo",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Sirapan",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Nglames",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Gunungsari",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Dempelan",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Dimong",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Bagi",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Banjarsari",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Madiun",
                "kelurahan": "Betek",
                "kodepos": "63151"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Mojorejo",
                "kodepos": "63139"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Banjarejo",
                "kodepos": "63137"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Manisrejo",
                "kodepos": "63138"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Demangan",
                "kodepos": "63136"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Josenan",
                "kodepos": "63134"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kuncen",
                "kodepos": "63135"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Pandean",
                "kodepos": "63133"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Taman",
                "kodepos": "63131"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kejuron",
                "kodepos": "63132"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Sogaten",
                "kodepos": "63129"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Nambangan Kidul",
                "kodepos": "63128"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Nambangan Lor",
                "kodepos": "63129"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Winongo",
                "kodepos": "63126"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Manguharjo",
                "kodepos": "63127"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Madiun Lor",
                "kodepos": "63122"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Patihan",
                "kodepos": "63123"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Ngegong",
                "kodepos": "63125"
            },
            {
                "kecamatan": "Manguharjo",
                "kelurahan": "Pangongangan",
                "kodepos": "63121"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Pilangbango",
                "kodepos": "63119"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Sukosari",
                "kodepos": "63119"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Kanigoro",
                "kodepos": "63118"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Oro Oro Ombo",
                "kodepos": "63119"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Tawangrejo",
                "kodepos": "63113"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Kartoharjo",
                "kodepos": "63117"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Klegen",
                "kodepos": "63117"
            },
            {
                "kecamatan": "Mejayan",
                "kelurahan": "Sidodadi",
                "kodepos": "63112"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Rejomulyo",
                "kodepos": "63111"
            },
            {
                "kecamatan": "Kartoharjo",
                "kelurahan": "Kelun",
                "kodepos": "63112"
            }
        ],
        "k135": [
            {
                "kecamatan": "Palang",
                "kelurahan": "Wangun",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Sumurgung",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Tasikmadu",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Tegalbang",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Pliwetan",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Pucangan",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Ngimbang",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Palang",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Panyuran",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Kradenan",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Leran Kulon",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Leran Wetan",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Glodog",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Karangagung",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Ketambul",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Dawung",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Gesikharjo",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Cendoro",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Palang",
                "kelurahan": "Cepokorejo",
                "kodepos": "62391"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Tegalsari",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Widang",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Sumberejo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Tegalrejo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Patihan",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Simorejo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Mrutuk",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Ngadipuro",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Ngadirejo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Minohorejo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Mlangi",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Compreng",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Kedungharjo",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Kujung",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Bunut",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Widang",
                "kelurahan": "Banjar",
                "kodepos": "62383"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Sumurjalak",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Trutup",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Sembungrejo",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Sumberagung",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Plumpang",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Penidon",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Plandirejo",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Ngrayung",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Magersari",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Klotok",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Kesamben",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Kepohagung",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Kedungsoko",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Kedungrojo",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Kebomlati",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Cangkring",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Jatimulyo",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Plumpang",
                "kelurahan": "Bandungrejo",
                "kodepos": "62382"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Tunah",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Tegalagung",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Semanding",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Sambongrejo",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Prunggahan Kulon",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Prunggahan Wetan",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Penambangan",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Kowang",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Ngino",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Karang",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Jadi",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Gesing",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Genaharjo",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Gedongombo",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Bektiharjo",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Boto",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Semanding",
                "kelurahan": "Bejagung",
                "kodepos": "62381"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Wadung",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Tluwe",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Sumurcinde",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Sokosari",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Simo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Sandingrowo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Rahayu",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Pandanwangi",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Prambontergayang",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Pandanagung",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Nguruan",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Mentoro",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Mojoagung",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Klumpit",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Menilo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Kendalrejo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Kenongosari",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Jati",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Jegulo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Glagahsari",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Gununganyar",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Bangunrejo",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Soko",
                "kelurahan": "Cekalang",
                "kodepos": "62372"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Waleran",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Tambakrejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Rengel",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Sawahan",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Sumberejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Prambon Wetan",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Punggulrejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Pekuwon",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Pakis",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Ngarum",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Ngrejeng",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Menyunyur",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Ngandong",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Ngadirejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Kebonagung",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Maibit",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Karangtinoto",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Gesikan",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Kanorejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Grabagan",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Dahor",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Dermawuhardjo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Bulurejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Campurejo",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Grabagan",
                "kelurahan": "Banyubang",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Banjararum",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Wukirharjo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Rengel",
                "kelurahan": "Banjaragung",
                "kodepos": "62371"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Sukorejo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Sendangrejo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Suciharjo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Sugihwaras",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Sembung",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Parangbatu",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Selogabus",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Pacing",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Mojomalang",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Ngawun",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Mergoasri",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Mergorejo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Kemlaten",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Kumpulrejo",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Dagangan",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Brangkal",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Parengan",
                "kelurahan": "Cengkong",
                "kodepos": "62366"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Wanglu Wetan",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Wonosari",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Wanglu Kulon",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Sidoharjo",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Senori",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Rayung",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Sendang",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Leran",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Medalem",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Katerban",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Jatisari",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Kaligede",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Weden",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Senori",
                "kelurahan": "Banyuurip",
                "kodepos": "62365"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Sidokumpul",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Sidotentrem",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Sidodadi",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Kumpulrejo",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Ngrojo",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Kedungmulyo",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Klakeh",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Kedunghardjo",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Kedungjambangan",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Kablukan",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Bangilan",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Banjarworo",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Bangilan",
                "kelurahan": "Bate",
                "kodepos": "62364"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Tawaran",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Sokogunung",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Sokogrenjeng",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Sidomukti",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Sidorejo",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Jlodro",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Sidohasri",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Bendonglateng",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Kenduruan",
                "kelurahan": "Jamprong",
                "kodepos": "62363"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Wotsogo",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Sugihan",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Wangi",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Sidomulyo",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Sadang",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Sekaran",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Paseyan",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Ketodan",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Ngepon",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Kedungmakam",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Kebonharjo",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Karangtengah",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Jatiklabang",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Jombok",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Demit",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Dingil",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Besowo",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Jatirogo",
                "kelurahan": "Bader",
                "kodepos": "62362"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Tanjungrejo",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Tingkis",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Tunggulrejo",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Tanggir",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Saringembat",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Mulyoagung",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Mulyorejo",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Mergosari",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Lajo Kidul",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Lajo Lor",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Binangun",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Singgahan",
                "kelurahan": "Kedungjambe",
                "kodepos": "62361"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Talun",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Tanggulangin",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Sumurgung",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Talangkembar",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Pakel",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Pucangan",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Montongsekar",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Nguluhan",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Maindu",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Manjung",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Guwoterus",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Jetak",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Montong",
                "kelurahan": "Bringin",
                "kodepos": "62357"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Trantang",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Wolutengah",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Tengger Wetan",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Temayang",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Padasan",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Sidonganti",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Sumberarum",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Margorejo",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Mliwang",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Kedungrejo",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Margomulyo",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Karanglo",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Kasiman",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Hargoretno",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Jarorejo",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tuwiri Wetan",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Gaji",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Kerek",
                "kelurahan": "Gemulung",
                "kodepos": "62356"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tobo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tuwiri Kulon",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Temandang",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tlogowaru",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tahulu",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Tegalrejo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sumberejo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sumber",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sendanghaji",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sugihan",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Pongpongan",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sambonggede",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Sembungrejo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Kapu",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Mandirejo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Bogorejo",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Merakurak",
                "kelurahan": "Borehbangle",
                "kodepos": "62355"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Tlogoagung",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Sumberan",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Tengger Kulon",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Tergambang",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Sukolilo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Sidomulyo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Sukoharjo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Pugoh",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Sembungin",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Margosuko",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Siding",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Latsari",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Ngampelrejo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Karangrejo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Ngujuran",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Kayen",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Cingklung",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Jatisari",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Bulujowo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Bulumeduro",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Bogorejo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Boncong",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Bancar",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Bancar",
                "kelurahan": "Banjarejo",
                "kodepos": "62354"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Sotang",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Tambakboyo",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Sobontoro",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Mander",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Plajan",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Merkawang",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Pulogede",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Glondonggede",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Sawir",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Kenanti",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Ngulahan",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Klutuk",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Pabeyan",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Dikir",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Gadon",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Dasin",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Cokrowati",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Tambakboyo",
                "kelurahan": "Belikanget",
                "kodepos": "62353"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Temaji",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Wadung",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Tasikharjo",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Suwalan",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Sumurgeneng",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Sekardadi",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Mentoso",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Socorejo",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Purworejo",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Sugihwaras",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Karangasem",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Rawasan",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Kaliuntu",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Remen",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Jenggolo",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Jenu",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sumurgung",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Jenu",
                "kelurahan": "Beji",
                "kodepos": "62352"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sugiharjo",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Mondokan",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Perbon",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sendangharjo",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sukolilo",
                "kodepos": "62318"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Kembangbilo",
                "kodepos": "62319"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Kebonsari",
                "kodepos": "62317"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Baturetno",
                "kodepos": "62318"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sidorejo",
                "kodepos": "62315"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Doromukti",
                "kodepos": "62316"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Latsari",
                "kodepos": "62314"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Karangsari",
                "kodepos": "62314"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Kingking",
                "kodepos": "62313"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Ronggomulyo",
                "kodepos": "62313"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Kutorejo",
                "kodepos": "62311"
            },
            {
                "kecamatan": "Tuban",
                "kelurahan": "Sidomulyo",
                "kodepos": "62312"
            }
        ],
        "k136": [
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Windu",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Sukorejo",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Waruk",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Watangpanjang",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Somowinangun",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Sambopinggir",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Putatbangah",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Palangan",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Pendowolimo",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Priyoso",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Kuro",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Mayong",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Karangbinangun",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Ketapangtelu",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Gawerejo",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Karanganom",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Baranggayam",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Blawi",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Bogobabadan",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Banjarejo",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Karangbinangun",
                "kelurahan": "Banyuurip",
                "kodepos": "62293"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Wonorejo",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Tanggungprigel",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Wangen",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Wedoro",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Soko",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Sudangan",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Pasi",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Rayunggumuk",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Menganti",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Morocalan",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Panggang",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Meluwur",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Mendogo",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Medang",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Meluntur",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Karangturi",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Kentong",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Konang",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Margoanyar",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Jatirenggo",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Karangagung",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Gempolpendowo",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Glagah",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Began",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Duduk Lor",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Dukuhtunggal",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Bapuh Baru",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Bapuhbandung",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Weduni",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Glagah",
                "kelurahan": "Bangkok",
                "kodepos": "62292"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Srirande",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Sugihwaras",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Tukkerto",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Sidomulyo",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Sidorejo",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Rejosari",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Rejotengah (Tejotengah)",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Sidobinangun",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Laladan",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Pandanpancur",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Plosobuden",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Dinoyo",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Dlanggu",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Deket Wetan",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Deket Kulon",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Wudi",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Deket",
                "kelurahan": "Babatagung",
                "kodepos": "62291"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Wateswinangun",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Wonorejo",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Sumbersari",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Tenggiring",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Selorejo",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Semampirejo",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Sidokumpul",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Sekidang",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Pataan",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Pamotan",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Pasarlegi",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Kedungwangi",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Kreteranggon",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Nogojatisari",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Jatipandak",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Kedungbanjar",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Gempolmanis",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Candisari",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Garung",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Ardirejo",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Sambeng",
                "kelurahan": "Barurejo",
                "kodepos": "62284"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Tugu",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Tunggunjagir",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sumberdadi",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sumberkerep",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sumberagung",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sumberbendo",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sukobendu",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sukosari",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Sidomulyo",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Plabuhanrejo",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Rumpuk",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Mojosari",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Mantup",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Kedungsoko",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Mantup",
                "kelurahan": "Kedukbembem",
                "kodepos": "62283"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Tlogoagung",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Sukosongo",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Sidomukti",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Randubener",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Puter",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Pelang",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Maor",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Moronyamplung",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Mangkujajar",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Lopang",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Kembangbahu",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Kedungmegarih",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Katemas",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Kedungasri",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Kaliwates",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Gintungan",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Dumpiagung",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Kembangbahu",
                "kelurahan": "Doyomulyo",
                "kodepos": "62282"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Wonokromo",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Tambakrigadung",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Tambakmenjangan",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Takeranklating",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Sumberjo",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Soko",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Simbatan",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Sarirejo",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Pengumbulanadi",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Kelorarum",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Kedungkumpul",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Jotosanur",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Jatirejo",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Guminingrejo",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Gempol Tukmloko",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Dukuhagung",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Canggah",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Dermo Lemahbang",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Botoputih",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sarirejo",
                "kelurahan": "Beru",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Balongwangi",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Tikung",
                "kelurahan": "Bakalanpule",
                "kodepos": "62281"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Wedoro",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Sukorame",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Sewor",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Sembung",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Mragel",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Pendowokumpul",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Kedungkumpul",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Kedungrejo",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Sukorame",
                "kelurahan": "Banggle",
                "kodepos": "62276"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Sidomulyo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Sumberagung",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Yungyang",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Sambungrejo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Sidodowo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Pule",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Sambangrejo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Nguwok",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Mojorejo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Kedungrejo",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Kedungwaras",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Medalem",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Kedunglerep",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Kedungpengaron",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Jegreg",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Kacangan",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Talunrejo",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Modo",
                "kelurahan": "Jatipayak",
                "kodepos": "62275"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Sumberbanjar",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Primpen",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Songowareng",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Cangkring",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Kuwurejo",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Bluluk",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Bronjong",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Bluluk",
                "kelurahan": "Banjargondang",
                "kodepos": "62274"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Slaharwotan",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Tlemang",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Purwokerto",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Sendangrejo",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Ngasem Lemahbang",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Ngimbang",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Mendogo",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Munungrejo",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Kedungmentawar",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Lamongrejo",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Lawak",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Jejel",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Kakatpenjalin",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Gebangangkrik",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Girik",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Ganggantingan",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Drujugurit",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Durikedungjero",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Warungering",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Ngimbang",
                "kelurahan": "Cerme",
                "kodepos": "62273"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Tenggerejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Tlanak",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Sumengko",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Sukomalo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Nglebur",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Sidobangun",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Sidomlangean",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Mlati",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Mojodadi",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Majenang",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Mekanderejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Kradenanrejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Maindu",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Karangcangkring",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Kedungpring",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Kalen",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Kandangrejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Gunungrejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Jatidrojok",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Blawirejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Dradah Blumbang",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Truni",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Kedungpring",
                "kelurahan": "Banjarrejo",
                "kodepos": "62272"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Sumur Genuk",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Trepan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Tritunggal",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Sambangan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Sogo",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Pucakwangi",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Patihan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Plaosan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Keyongan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Kuripan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Moropelang",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Kebalandono",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Kebalanpelang",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Kebonagung",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Gendong Kulon",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Karangkembang",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Bulumargi",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Datinawong",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Gembong",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Banaran",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Bedahan",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Babat",
                "kelurahan": "Babat",
                "kodepos": "62271"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Takerharjo",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Tebluru",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Tenggulun",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Sugihan",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Solokuro",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Dadapan",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Dagan",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Payaman",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Banyubang",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Solokuro",
                "kelurahan": "Bluri",
                "kodepos": "62265"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Weru",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Tunggul",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Warulor",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Sidokumpul",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Sumurgayam",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Tlogosadang",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Sendangduwur",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Sidokelar",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Sendangagung",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Kranji",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Paciran",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Paloh",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Kemantren",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Drajat",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Kandang Semangkon",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Banjarwati",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Paciran",
                "kelurahan": "Blimbing",
                "kodepos": "62264"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Sumberagung",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Tlogoretno",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Sedayulawas",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Sendangharjo",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Sidomukti",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Lembor",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Lohgung",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Brondong",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Labuhan",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Tejoasri",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Brondong",
                "kelurahan": "Brengkok",
                "kodepos": "62263"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Siser",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Taman Prijek",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Pesanggrahan",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Laren",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Mojo Asem",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Pelangwot",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Karangwungu Lor",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Keduyung",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Jabung",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Karangtawar",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Gampang Sejati",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Gelap",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Godog",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Dateng",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Duri Kulon",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Bulutigo",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Centini",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Brangsi",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Laren",
                "kelurahan": "Bulubrangsi",
                "kodepos": "62262"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Trosono",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Turi",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Sungegeneng",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Titik",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Taji",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Siwuran",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Sekaran",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Siman",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Porodeso",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Parengan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Pringgoboyo",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Pangean",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Pangkatrejo",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Moro",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Ngarum",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Ngayung",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Manyar",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Miru",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Latek",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Maduran",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Keting",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Kudikan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Klagensrampat",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Kembangan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Kendal",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Kanugrahan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Karang",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Kebalan Kulon",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Jangkungsomo",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Jugo",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Gedangan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Gumantuk",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Bulutengger",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Duriwetan",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Bugel",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Brumbun",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Maduran",
                "kelurahan": "Blumbang",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Waruwetan",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Sekaran",
                "kelurahan": "Besur",
                "kodepos": "62261"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Tanggungan",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Wanar",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Warukulon",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Pucuk",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Sumberjo",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Padenganploso",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Paji",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Plososetro",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Ngambeg",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Kedali",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Kesambi",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Cungkup",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Gempolpading",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Karangtinggil",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Babatkumpul",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Pucuk",
                "kelurahan": "Bugoharjo",
                "kodepos": "62257"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Sugio",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Supenuh",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Sekarbagus",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Sidobogem",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Sidorejo",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Lawangan Agung",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Lebakadi",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Pangkatrejo",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Karang Sambigalih",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Kedungbanjar",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Kedungdadi",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Jubel Lor",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Kalipang",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Kalitengah",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "German",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Gondang Lor",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Jubel Kidul",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Bedingin",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Daliwangun",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Deketagung",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Tunjungmekar",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Sugio",
                "kelurahan": "Bakalrejo",
                "kodepos": "62256"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Tiwet",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Somosari",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Sugihwaras",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Pengangsalan",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Pucangro",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Pucangtelu",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Lukrejo",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Mungli",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Kediren",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Kuluran",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Jelakcatur",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Kalitengah",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Cluring",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Dibe",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Gambuhan",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Butungan",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Canditunggal",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Blajo",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Kalitengah",
                "kelurahan": "Bojoasri",
                "kodepos": "62255"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Sungelebak",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Tracal",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Prijek Ngablag",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Sonoadi",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Sumberwudi",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Latukan",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Mertani",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Kawistolegi",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Kendalkemlagi",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Karangrejo",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Karangwungu",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Kaligerman",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Karanggeneng",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Jagran",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Kalanganyar",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Bantengputih",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Guci",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Tlogorejo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Karang Geneng",
                "kelurahan": "Banjarmadu",
                "kodepos": "62254"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sumberaji",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Surabayan",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sukolilo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sumberagung",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sugihrejo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sukodadi",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Plumpang",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Sidogembul",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Siwalanrejo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Madulegi",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Menongo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Pajangan",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Kadungrembug",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Kebonsari",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Baturono",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Gedangan",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Banjarejo",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Bandungsari",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Sukodadi",
                "kelurahan": "Balungtawun (Balongtawun)",
                "kodepos": "62253"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Turi",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Wangunrejo",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Sukorejo",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Tambakploso",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Tawangrejo",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Putatkumpul",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Sukoanyar",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Pomahan Janggan",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Kepudibener",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Ngujungrejo",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Keben",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Kemlaggi Lor",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Kemlagigede",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Geger",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Karangwedoro",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Bambang",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Gedongboyo Untung",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Badurame",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Turi",
                "kelurahan": "Balun",
                "kodepos": "62252"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Tlogoanyar",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Wajik",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sumberjo",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Tanjung",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sidomukti",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sendangrejo",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Plosowahyu",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Rancang Kencono",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Made",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Pangkatrejo",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Kebet",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Kramat",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sukomulyo",
                "kodepos": "62216"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sidoharjo",
                "kodepos": "62217"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Karanglangit",
                "kodepos": "62218"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Tumenggungan",
                "kodepos": "62214"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sukorejo",
                "kodepos": "62215"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Banjarmendalan",
                "kodepos": "62212"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Sidokumpul",
                "kodepos": "62213"
            },
            {
                "kecamatan": "Lamongan",
                "kelurahan": "Jetis",
                "kodepos": "62211"
            }
        ],
        "k137": [
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Tumbrasanom",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Tondomulo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Sidorejo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Tlogoagung",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Pejok",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Sidomulyo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Ngrandu",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Panjang",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Megale",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Mlideg",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Mojorejo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Kepoh Kidul",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Kesongo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Kedungrejo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Kendung",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Geger",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Jamberejo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Kedungadem",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Drokilo",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Duwel",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Balongcabe",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Dayukidul",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kedungadem",
                "kelurahan": "Babad",
                "kodepos": "62195"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Woro",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Tlogorejo",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Turigede",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Sumberoto",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Sumberagung",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Sumbergede",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Sugihwaras",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Simorejo",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Pohwates",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Sidomukti",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Pejok",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Nglumber",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Ngranggon Anyar",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Mudung",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Mojosari",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Krangkong",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Karangan",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Kepoh",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Jipo",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Cengkir",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Brangkal",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Bumirejo",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Betet",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Bayemgede",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kepoh Baru",
                "kelurahan": "Balongdowo",
                "kodepos": "62194"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Temu",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Tejo",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Sumberwangi",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Tambahrejo",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Sroyo",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Simorejo",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Semambung",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Simbatan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Sedeng",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Samberan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Sarangan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Prigi",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Piyak",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Pilang",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Pesen",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Nglarangan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Palembon",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Kedungprimpen",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Kanor",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Gedongarum",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Kabalan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Caruban",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Cangakan",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Bungur",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Kanor",
                "kelurahan": "Bakung",
                "kodepos": "62193"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Tulungagung",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Trojalu",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Tanggungan",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Tlogoagung",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Sraturejo",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Sumuragung",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Sembunglor",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Selorejo",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Pucangarum",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Ngemplak",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Pasinan",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Pomahan",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Lebaksari",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Karangdayu",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Kauman",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Kadungrejo",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Kalisari",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Drajat",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Gajah",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Gunungsari",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Bumiayu",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Blongsong",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Baureno",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Banjaran",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Baureno",
                "kelurahan": "Banjaranyar",
                "kodepos": "62192"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Tulungrejo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Wotan",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Teleng",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Tlogohaji",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sumberrejo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Sumuragung",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Talun",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Sumberharjo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Sumberrejo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Prayungan",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Sambongrejo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Sendangagung",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Pejambon",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Pekuwon",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Mlinjeng",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Ngampal",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Kedungrejo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Margoagung",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Mejuwet",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Karangdowo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Kayulemah",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Jatigede",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Karang Dinoyo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Butoh",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Deru",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Banjarjo",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Sumberrejo",
                "kelurahan": "Bogangin",
                "kodepos": "62191"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Soko",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Temayang",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Papringan",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Pandantoyo",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Ngujung",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Pancur",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Jono",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Kedungsari",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Kedungsumber",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Belun",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Buntalan",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Temayang",
                "kelurahan": "Bakulan",
                "kodepos": "62184"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Wedoro",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Tegalkodo",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Trate",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Sukosewu",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Sumberejo Kidul",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Siwalan",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Sugihwaras",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Sitiaji",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Sidodadi",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Sidorejo",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Semawot",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Semen Kidul",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Purwoasri",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Panunggalan",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Pacing",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Panemon",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Klepek",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Nglajang",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Jumput",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Kalicilik",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Kedungdowo",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Glagahan",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Jatitengah",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sukosewu",
                "kelurahan": "Duyungan",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Genjor",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Glagah Wangi",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Bulu",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Drenges",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Alasgung",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Balongrejo",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Sugihwaras",
                "kelurahan": "Bareng",
                "kodepos": "62183"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Suwaloh",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Sekaran",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Sidobandung",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Sobontoro",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Poh Bogo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Prambatan",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Sarirejo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Penganten",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Pilang Gede",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Mulyorejo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Ngadiluhur",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Lengkong",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Mayangkawis",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Mulyoagung",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Kemamang",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Kenep",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Kabunan",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Kedungbondo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Kedungdowo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Bulaklo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Bulu",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Balenrejo",
                "kodepos": "62182"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Wedi",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Tanjung Harjo",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Tapelan",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Tikusan",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Sukowati",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Sembung",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Semen Pinggir",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Sambiroto",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Plesungan",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Mojodeso",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Ngampel",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Padang Mentoyo",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Klampok",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Kumpul Rejo",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Kapas",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Kedaton",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Kalianyar",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Bogo",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Bangilan",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Bendo",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Kapas",
                "kelurahan": "Bakalan",
                "kodepos": "62181"
            },
            {
                "kecamatan": "Bubulan",
                "kelurahan": "Sumber Bendo",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sengaten",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Pajeng",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Pragelan",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Sambongrejo",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Bubulan",
                "kelurahan": "Ngorogunung",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Jari",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Krondonan",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Bubulan",
                "kelurahan": "Clebung",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondang",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Bubulan",
                "kelurahan": "Bubulan",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Bubulan",
                "kelurahan": "Cancung",
                "kodepos": "62172"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Sumber Tlaseh",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Sumodikaran",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Sumber Agung",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Sumber Arum",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Ngumpak Dalem",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Ngunut",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Sendangrejo",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Ngraseh",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Ngulanan",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Mojoranu",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Ngablak",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Karangsono",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Kunci",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Jatiblimbing",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Dander",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Dander",
                "kelurahan": "Growok",
                "kodepos": "62171"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Ngelo",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Sumberejo",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Margomulyo",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Meduri",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Kalangan",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Balen",
                "kelurahan": "Margomulyo",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Margomulyo",
                "kelurahan": "Geneng",
                "kodepos": "62168"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Sekar",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Ngambon",
                "kelurahan": "Nglampin",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Ngambon",
                "kelurahan": "Sengon",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Miyono",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Ngambon",
                "kelurahan": "Ngambon",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Klino",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Ngambon",
                "kelurahan": "Karangmangu",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Deling",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Ngambon",
                "kelurahan": "Bondol",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Bareng",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Sekar",
                "kelurahan": "Bobol",
                "kodepos": "62167"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Turi",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Tambak Rejo",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Tanjung",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Sendangrejo",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Sukorejo",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Ngrancang",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Pengkol",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Maling Mati",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Mulyorejo",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Napis",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Jawik",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Kacangan",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Kalisumber",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Gamongan",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Jatimulyo",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Dolok Gede",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Gading",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Tambakrejo",
                "kelurahan": "Bakalan",
                "kodepos": "62166"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Sumberarum",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Tanggungan",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Tapelan",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Payaman",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Sugih Waras",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Sumberagung",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Ngraho",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Pandan",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Luwihaji",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Mojorejo",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Nganti",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Jumok",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Kalirejo",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Klempun",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Blimbing Gede",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Ngraho",
                "kelurahan": "Bancer",
                "kodepos": "62165"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Tambakmerak",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Tembeling",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kedewan",
                "kelurahan": "Wonocolo",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Sekaran",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Sidomukti",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Ngaglik",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Sambeng",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kedewan",
                "kelurahan": "Kedewan",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Kasiman",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kedewan",
                "kelurahan": "Kawengan",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Besah",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Besah",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Betet",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Kedewan",
                "kelurahan": "Beji",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Tebon",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Kasiman",
                "kelurahan": "Batokan",
                "kodepos": "62164"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Sidorejo",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Sonorejo",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Prangi",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Purworejo",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Ngradin",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Nguken",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Padangan",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Ngeper",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Kuncen",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Ngasinan",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Kebunagung",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Kendung",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Cendono",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Dengok",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Tlatah",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Padangan",
                "kelurahan": "Banjarjo",
                "kodepos": "62162"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Purwosari",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Sedahkidul",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Tinumpuk",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Pojok",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Punggur",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Kuniran",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Ngrejeng",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Pelem",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Gapluk",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Kaliombo",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Purwosari",
                "kelurahan": "Donan",
                "kodepos": "62161"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Trucuk",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Tulungrejo",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sranak",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Sumbang Timun",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Padang",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Pagerwesi",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Kandangan",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Kanten",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Mori",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Banjarsari",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Trucuk",
                "kelurahan": "Guyangan",
                "kodepos": "62155"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Trenggulunan",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Wadang",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Sendangharjo",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Setren",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Tengger",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Sambong",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Ringintunggal",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Ngantru",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Ngasem",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Mojodelik",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Ngadiluwih",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Jelu",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Kolong",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Mediyunan",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Jampet",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Gayam",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Butoh",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Dukohkidul",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Brabowan",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Begadon",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Bonorejo",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Bareng",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Tulungagung",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Ngasem",
                "kelurahan": "Bandungrejo",
                "kodepos": "62154"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Tinawun",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Trembes",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Sumberejo",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Tambakromo",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Tanggir",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Sukorejo",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Semlaran",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Sudah",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Petak",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Rendeng",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Kliteh",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Malo",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Ngujung",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Kemiri",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Ketileng",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Kacangan",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Kedungrejo",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Banaran",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Malo",
                "kelurahan": "Dukuh Lor",
                "kodepos": "62153"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Sumengko",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Talok",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Wotan Ngare",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Sukoharjo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Sudu",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Pilangsari",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Pungpungan",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Ngujo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Panjunan",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Ngraho",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Ngringinrejo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Mlaten",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Mojo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Mojosari",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Mayanggeneng",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Mayangrejo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Manukan",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Leran",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Katur",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Kalitidu",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Cengungklung",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Grebegan",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Gayam",
                "kelurahan": "Beged",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Kalitidu",
                "kelurahan": "Brenggolo",
                "kodepos": "62152"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Ngrowo",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Semanding",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Karang Pacar",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Mojo Kampung",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Mulyoagung",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Kalirejo",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Sumbang",
                "kodepos": "62115"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Banjarejo",
                "kodepos": "62118"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Campurjo",
                "kodepos": "62119"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Sukorejo",
                "kodepos": "62115"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Jetak",
                "kodepos": "62114"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Pacul",
                "kodepos": "62114"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Kauman",
                "kodepos": "62113"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Klangon",
                "kodepos": "62113"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Ledok Kulon",
                "kodepos": "62112"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Ledok Wetan",
                "kodepos": "62112"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Kadipaten",
                "kodepos": "62111"
            },
            {
                "kecamatan": "Bojonegoro",
                "kelurahan": "Kepatihan",
                "kodepos": "62111"
            }
        ],
        "k138": [
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Tambar",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Sukosari",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Sambirejo",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Sawiji",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Ngumpul",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Mayangan",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Jogoroto",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Jarak Kulon",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Janti",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Jogoroto",
                "kelurahan": "Alang Alang Caruban",
                "kodepos": "61485"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Wuluh",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Watu Dakon",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Pojok Rejo",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Pojok Kulon",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Kesamben",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Podoroto",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Kedung Mlati",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Kedung Betik",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Jombatan",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Jombok",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Jati Duwur",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Gumulan",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Blimbing",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Kesamben",
                "kelurahan": "Carang Rejo",
                "kodepos": "61484"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Talun Kidul",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Trawasan",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Sumobito",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Segodorejo",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Sebani",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Plosokerep",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Plemahan",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Nglele",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Palrejo",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Mlaras",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Mentoro",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Madyo Puro",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Kendalsari",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Kedungpapar",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Jogoloyo",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Curah Malang",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Gedangan",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Budugsidorejo",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Brudu",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Bakalan",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Tejo",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Sumobito",
                "kelurahan": "Badas",
                "kodepos": "61483"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Seketi",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Tanggalrejo",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Miagan",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Mojotrisno",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Murukan",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Kauman",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Kedunglumpang",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Mancilan",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Karangwinongan",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Karobelah",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Janti",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Johowinong",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Kademangan",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Dukuhdimoro",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Gambiran",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Betek",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Mojoagung",
                "kelurahan": "Dukuh Mojo",
                "kodepos": "61482"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Tengaran",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Tugusumberjo",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Sumberagung",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Ngrandu Lor",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Peterongan",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Senden",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Mancar",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Morosunggingan",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Kebontemu",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Keplaksari",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Kepuhkembeng",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Bongkot",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Peterongan",
                "kelurahan": "Dukuh Klopo",
                "kodepos": "61481"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Wonomerto",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Wonosalam",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Sumberjo",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Wonokerto",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Sambirejo",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Jarak",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Panglungan",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Carangwulung",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Wonosalam",
                "kelurahan": "Galengdowo",
                "kodepos": "61476"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Wringinpitu",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Sidokerto",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Sukomulyo",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Rejoslamet",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Selorejo",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Mojowarno",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Penggaron",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Mojojejer",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Mojowangi",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Menganto",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Mojoduwur",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Latsari",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Kedungpari",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Japanan",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Karanglo",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Gedangan",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Gondek",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Grobogan",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Tebel",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Mojowarno",
                "kelurahan": "Catak Gayam",
                "kodepos": "61475"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Pakel",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Pulosari",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Nglebak",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Ngrimbi",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Mundusewu",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Ngampungan",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Kebondalem",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Mojotengah",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Jenisgelaran",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Karangan",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Banjaragung",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Bareng",
                "kelurahan": "Bareng",
                "kodepos": "61474"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Rejoagung",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Sidowarek",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Sugihwaras",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Ngoro",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Pulorejo",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kertorejo",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kesamben",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Genukwatu",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Jombok",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kauman",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Gajah",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Badang",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Banyuarang",
                "kodepos": "61473"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Puton",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Watugaluh",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Pundong",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Pandanwangi",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Kwaron",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Ngudirejo",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Kedawong",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Keras",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Jatirejo",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Kayangan",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Grogol",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Jatipelem",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Cukir",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Diwek",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Bulurejo",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Ceweng",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Bendet",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Brambang",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Balong Besuk",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Diwek",
                "kelurahan": "Bandung",
                "kodepos": "61471"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Tanggungan",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Wangkalkepuh",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Sukoiber",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Sukopinggir",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Pucangro",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Sepanyul",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Pesanggrahan",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Plumbon Gambang",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Mentaos",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Mejoyolosari",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Japanan",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Kedungturi",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Krembangan",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Godong",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Gudo",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Bugasur Kedaleman",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Gempol Legundi",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Gudo",
                "kelurahan": "Blimbing",
                "kodepos": "61463"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Mojokambang",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Pucang Simo",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Tinggar",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Karang Dagangan",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Kayen",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Brodot",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Gondang Manis",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Barong Sawahan",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Brangkal",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Bandar Kedung Mulyo",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Bandar Kedung Mulyo",
                "kelurahan": "Banjar Sari",
                "kodepos": "61462"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Sumberagung",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Temuwulan",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Plosogenuk",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Sembung",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Sukorejo",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Pagerwojo",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Perak",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Jantiganggong",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Kalang Semanding",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Kepuhkajang",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Gadingmangu",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Gadingmangu",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Turi Pinggir",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Perak",
                "kelurahan": "Cangkringrandu",
                "kodepos": "61461"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Sudimoro",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Sumber Agung",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Sumbersari",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Sidomulyo",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Ngogri",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Pacar Peluk",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Gongseng",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Kedung Rejo",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Megaluh",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Balonggemek",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Balongsari",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Megaluh",
                "kelurahan": "Dukuh Arum",
                "kodepos": "61457"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Tondowulan",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Sumberjo",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Plandaan",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Purisemanding",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Karang Mojo",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Klitih",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Plabuhan",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Jipurapah",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Kampungbaru",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Gebang Bunder",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Jatimlerek",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Bangsri",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Plandaan",
                "kelurahan": "Darurejo",
                "kodepos": "61456"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Tanjung Wadung",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Sumberringin",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Sumberaji",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Pengampon",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Sukodadi",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Sumber Gondang",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Munung Kerep",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Manduro",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Mangunan",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Marmoyo",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Kauman",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Kedung Jati",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Kabuh",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Karang Pakis",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Banjar Dowo",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kabuh",
                "kelurahan": "Genengan Jasem",
                "kodepos": "61455"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Sumber Teguh",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Tapen",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Sidokaton",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Sumber Nongko",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Randuwatang",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Ngampel",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Ngusikan",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Mojodanu",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Menturus",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Made",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Manunggal",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Kudubanjar",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Ketapang Kuning",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Kromong",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Kepuhrejo",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Keboan",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Kedung Bogo",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Cupak",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Katemas",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Bakalanrayung",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Kudu",
                "kelurahan": "Bendungan",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Tanggung Kramat",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ngusikan",
                "kelurahan": "Asem Gede",
                "kodepos": "61454"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Pandan Blole",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Ploso",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Rejoagung",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Losari",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Pager Tanjung",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Kebon Agung",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Kedungdowo",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Gedongombo",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Jatibanjar",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Jatigedong",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Bawangan",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Ploso",
                "kelurahan": "Dadi Tunggal",
                "kodepos": "61453"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Tamping Mojo",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Tembelang",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Sentul",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Pulo Gedang",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Pulorejo",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Rejoso Pinggir",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Mojokrapak",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Pesantren",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Kedung Losari",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Kedung Otok",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Kepuh Doko",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Jati Wates",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Kali Kejambon",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Bedah Lawak",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Tembelang",
                "kelurahan": "Gabusbanaran",
                "kodepos": "61452"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Tambak Rejo",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Tunggorono",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Sumberjo",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Kaliwungu",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Sambong Dukuh",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Candi Mulyo",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Dapur Kejambon",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jombatan",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Sengon",
                "kodepos": "61418"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Banjar Dowo",
                "kodepos": "61419"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Pulo Lor",
                "kodepos": "61417"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jabon",
                "kodepos": "61418"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Plosogeneng",
                "kodepos": "61416"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Kepatihan",
                "kodepos": "61417"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jombang",
                "kodepos": "61415"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Denanyar",
                "kodepos": "61416"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Jelakombo",
                "kodepos": "61412"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Plandi",
                "kodepos": "61412"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Mojongapit",
                "kodepos": "61413"
            },
            {
                "kecamatan": "Jombang",
                "kelurahan": "Kepanjen",
                "kodepos": "61411"
            }
        ],
        "k139": [
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Wotanmasjedong",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Watesnegoro",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Wonosari",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Sukoanyar",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Tambakrejo",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Tanjangrono",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Purwojati",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Sedati",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Srigading",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Ngoro",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Manduro Manggung Gajah",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kunjorowesi",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kutogirang",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Lolawang",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kembangsri",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Kesemen",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Bandarasri",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Candiharjo",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Ngoro",
                "kelurahan": "Jasem",
                "kodepos": "61385"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Tunggalpager",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Watukenongo",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Randuharjo",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Sekargadung",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Tempuran",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Pungging",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Purworejo",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Mojorejo",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Ngrame",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Kembangringgit",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Lebaksono",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Jatilangkung",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Kalipuro",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Kedungmunggal",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Curahmojo",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Jabontegal",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Bangun",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Banjartanggul",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Wonodadi",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Pungging",
                "kelurahan": "Balongmasin",
                "kodepos": "61384"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Singowangi",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Windurejo",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Sawo",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Simbaringin",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Pesanggrahan",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Sampangagung",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Payungrejo",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Kutorejo",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Payungrejo",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Kepuharum",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Kepuhpandak",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Karangasem",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Karangdiyeng",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Jiyu",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Kaligoro",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Wonokusumo",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Kutorejo",
                "kelurahan": "Gedangan",
                "kodepos": "61383"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Seduri",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Sumbertanggul",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Sarirejo",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Sawahan",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Pekukuhan",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Randubango",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Mojosari",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Mojosulur",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Ngimbangan",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Menanggal",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Modopuro",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Kedunggempol",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Leminggir",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Kauman",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Kebondalem",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Awang Awang",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Belahantengah",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Mojosari",
                "kelurahan": "Jotangan",
                "kodepos": "61382"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Tinggarbuntut",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Sumbertebu",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Sumberwono",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Salen",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Sidomulyo",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Peterongan",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Puloniti",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Pacing",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Pekuwon",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Ngastemi",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Ngrowo",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Mejoyo",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Mojotamping",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Kedunguneng",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Kutoporong",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Bangsal",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Bangsal",
                "kelurahan": "Gayam",
                "kodepos": "61381"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Tamiajeng",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Trawas",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Sukosari",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Sugeng",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Seloliman",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Selotapak",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Ketapanrame",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Penanggungan",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Kesiman",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Kedungudi",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Jatijejer",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Duyung",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Trawas",
                "kelurahan": "Belik",
                "kodepos": "61375"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Wiyu",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Warugunung",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Sumberkembar",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Tanjungkenongo",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Sajen",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Pandanarum",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Petak",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Padusan",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Pacet",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Nogosari",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Mojokembang",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Kuripansari",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Kesimantengah",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Kemiri",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Claket",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Kembangbelor",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cepokolimo",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Cembor",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Candiwatu",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Pacet",
                "kelurahan": "Bendunganjati",
                "kodepos": "61374"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Tawangrejo",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Sumengko",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Sumberjati",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Sumberagung",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Rejosari",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Mojogeneng",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Padangasri",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Manting",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Lebakjabung",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Kumitir",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Karangjeruk",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Jembul",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Jatirejo",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Gebangsari",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Dinoyo",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Dukuhngarjo",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Gading",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Baureno",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Jatirejo",
                "kelurahan": "Bleberan",
                "kodepos": "61373"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Wonoploso",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Pugeran",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Tawar",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Ngembat",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Padi",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Pohjejer",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kebontunggul",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kemasantani",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Kalikatir",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Karangkuten",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Jatidukuh",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Dilem",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gondang",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Gumeng",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Bening",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Centong",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Bakalan",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Gondang",
                "kelurahan": "Begaganlimo",
                "kodepos": "61372"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Tumapel",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Sumbersono",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Talok",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Sambilawang",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Segunung",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Sumberkarang",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Punggul",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Randugenengan",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Ngembeh",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Pohkecik",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Kedunglengkong",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Mojokarang",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Kedunggede",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Kalen",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Jrambe",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Dlanggu",
                "kelurahan": "Dlanggu",
                "kodepos": "61371"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Sadartengah",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Sumberjati",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Wunut",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Lengkong",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Ngarjo",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Kwatu",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Kwedenkembar",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Jumeneng",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Kepuhanyar",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Gebangmalang",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Jabon",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Tangunan",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Mojoanyar",
                "kelurahan": "Gayaman",
                "kodepos": "61364"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Tampungrejo",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Tambakagung",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Puri",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Sumbergirang",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Sumolawang",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Mlaten",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Plososari",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Kintelan",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Medali",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Kenanten",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Ketemasdungus",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Brayung",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Kebonagung",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Balongmojo",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Puri",
                "kelurahan": "Banjaragung",
                "kodepos": "61363"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Watesumpak",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Wonorejo",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Temon",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Trowulan",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Sentonorejo",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Tawangsari",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Pakis",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Panggih",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Jambuwok",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Jatipasar",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Kejagan",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Bicak",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Domas",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Beloh",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Balongwono",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Trowulan",
                "kelurahan": "Bejijong",
                "kodepos": "61362"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Tempuran",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Wringinrejo",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Ngingasrembyong",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Sambiroto",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Sooko",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Modongan",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Mojoranu",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Kedungmaling",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Klinterejo",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Japan",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Karangkedawang",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Brangkal",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Gemekan",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Jampirogo",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Sooko",
                "kelurahan": "Blimbingsari",
                "kodepos": "61361"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Talunblandong",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Temuireng",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Suru",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Sumberwuluh",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Randegan",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Simongagrok",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Pucuk",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Pulorejo",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Gunungsari",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Jatirowo",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Madureso",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Gunungan",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Dawarblandong",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Cendoro",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Cinandang",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Banyulegi",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Brayublandong",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Watesprojo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Dawar Blandong",
                "kelurahan": "Bangeran",
                "kodepos": "61354"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Pandankrajan",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Tanjungan",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojowatesrejo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojowiryo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojowono",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojosarirejo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojopilang",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojorejo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojokusumo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojokumpul",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojojajar",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojodowo",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojogebang",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Kedungsari",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Kemlagi",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Mojodadi",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Betro",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Japanan",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Kemlagi",
                "kelurahan": "Beratkulon",
                "kodepos": "61353"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Perning",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Sawo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Sidorejo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Paringan",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Penompo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Mojorejo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Ngabar",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Mlirip",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Mojolebak",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Kupang",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Lakardowo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Jetis",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Jolotundo",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Bendung",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Canggu",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Jetis",
                "kelurahan": "Banjarsari",
                "kodepos": "61352"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Terusan",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Pagerluyung",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Sidoharjo",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Ngareskidul",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Pagerjo",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Jerukseger",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Kemantren",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Gedeg",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Gembongan",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Gempolkrep",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Batankrajan",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Beratwetan",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Balongsari",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Gedeg",
                "kelurahan": "Bandung",
                "kodepos": "61351"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Surodinawan",
                "kodepos": "61328"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Blooto",
                "kodepos": "61327"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Blooto",
                "kodepos": "61327"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Kauman",
                "kodepos": "61324"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Pulorejo",
                "kodepos": "61325"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Miji",
                "kodepos": "61322"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Mentikan",
                "kodepos": "61323"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Gedongan",
                "kodepos": "61319"
            },
            {
                "kecamatan": "Prajurit Kulon",
                "kelurahan": "Kranggan",
                "kodepos": "61321"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Wates",
                "kodepos": "61317"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Magersari",
                "kodepos": "61318"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Meri",
                "kodepos": "61315"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Kedundung",
                "kodepos": "61316"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Balongsari",
                "kodepos": "61314"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Gunung Gedangan",
                "kodepos": "61315"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Sentanan",
                "kodepos": "61312"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Jagalan",
                "kodepos": "61313"
            },
            {
                "kecamatan": "Magersari",
                "kelurahan": "Purwotengah",
                "kodepos": "61311"
            }
        ],
        "k140": [
            {
                "kecamatan": "Jabon",
                "kelurahan": "Trompoasri",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Semambung",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Tambak Kalisogo",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Pejarakan",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Permisan",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Kupang",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Panggreh",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Kedungcangkring",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Kedungpandan",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Kedungrejo",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Dukuhsari",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Jemirahan",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Keboguyang",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Balongtani",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Jabon",
                "kelurahan": "Besuki",
                "kodepos": "61276"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Wonomlati",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Tanjeg Wagir",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Wangkal",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Waung",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Ploso",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Rejeni",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Tambakrejo",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Lemujut",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Mojoruntut",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Keper",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Keret",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Krembung",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Kedungrawan",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Kedungsumur",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Jenggot",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Kandangan",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Cangkring",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Gading",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Krembung",
                "kelurahan": "Balong Garut",
                "kodepos": "61275"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Renokenongo",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Siring",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Wunut",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Pesawahan",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Plumbon",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Porong",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Mindi",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Pamotan",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Kesambi",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Lajuk",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Kebonagung",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Kedungboto",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Kedungsolo",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Juwet Kenongo",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Kebakalan",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Gedang",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Glagah Arum",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Jatirejo",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Tulangan",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Porong",
                "kelurahan": "Candipari",
                "kodepos": "61274"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Tlasih",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Singopadu",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Sudimoro",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Modong",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Pangkemiri",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kepunten",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Medalem",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kepatihan",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kepuh Kemiri",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kemantren",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kenongo",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kepadangan",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kajeksan",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kebaron",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Kedondong",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Grogol",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Janti",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Jiken",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Grabagan",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Grinting",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Sentul",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tulangan",
                "kelurahan": "Gelang",
                "kodepos": "61273"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Putat",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Randegan",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Ngaban",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Penatarsewu",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Ketapang",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Ketegan",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kludan",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kedung Banteng",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kedung Bendo",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kalisampurno",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kalitengah",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kedensari",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Gempol Sari",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Kalidawir",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Banjar Panji",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Boro",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Ganggang Panjang",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Wedoro Klurak",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Tanggulangin",
                "kelurahan": "Banjar Asri",
                "kodepos": "61272"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Tenggulunan",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Sumorame",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Sugih Waras",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Sumokali",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Sepande",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Sidodadi",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Larangan",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Ngampelsari",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Kendalpecabean",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Klurak",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Kebunsari",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Kedung Peluk",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Kedungkendo",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Kalipecabean",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Karangtanjung",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Gelam",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Jambangan",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Durungbedug",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Candi",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Durungbanjar",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Balonggabus",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Bligo",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Tarik",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Candi",
                "kelurahan": "Balongdowo",
                "kodepos": "61271"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Sebani",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Segodobancang",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Singogalih",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Mindugading",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Mliriprowo",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Mergosari",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kramat Temenggung",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Mergobener",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Klantingsari",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kemuning",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kendalsewu",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kedinding",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kedungbocok",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Janti",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Kalimati",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Banjarwungu",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Gampingrowo",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Gempolklutuk",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Wono Plintahan",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Tarik",
                "kelurahan": "Balongmacekan",
                "kodepos": "61265"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Watutulis",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Wirobiting",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Simpang",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Temu",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Prambon",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Simogirang",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Kedungwonokerto",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Pejangkungan",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Kajartengguli",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Kedungkembar",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Kedungsugo",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Jatikalang",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Jedongcangkring",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Gampang",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Gedangrowo",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Jati Alun Alun",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Bulang",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Cangkringturi",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Prambon",
                "kelurahan": "Bendotretek",
                "kodepos": "61264"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Watesari",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Wonokarang",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Wonokupang",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Sumokembangsri",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Suwaluh",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Waruberon",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Seduri",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Seketi",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Singkalan",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Kemangsen",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Penambangan",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Jeruk Legi",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Kedung Sukodani",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Jabaran",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Bogem Pinggir",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Gagang Kepuhsari",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Balongbendo",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Bakung Temenggungan",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Bakung Pringgodani",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Balongbendo",
                "kelurahan": "Bakalan Wringinpitu",
                "kodepos": "61263"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Watugolong",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Tropodo",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Terung Wetan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Terung Kulon",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Tempel",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Terik",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Tambak Kemerakan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Sidorejo",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Sidomulyo",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Sidomojo",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Sedengan Mijen",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Krian",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Ponokawan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Kraton",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Kemasan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Katrungan (Katerungan)",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Keboharan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Junwangi",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Jeruk Gamping",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Jatikalang",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Barengkrajan",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Krian",
                "kelurahan": "Gamping",
                "kodepos": "61262"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Wonokasian",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Wonokalang",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Wonoayu",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Sumberejo",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Tanggul",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Simoketawang",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Simo Angin Angin",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Semambung",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Sawocangkring",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Popoh",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Plaosan",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Ploso",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Pilang",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Pagerngumbuk",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Mulyodadi",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Mojorangagung",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Lambangan",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Karangpuri",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Ketimang",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Jimbaran Kulon",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Jimbaran Wetan",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Becirongengor",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Wonoayu",
                "kelurahan": "Candinegoro",
                "kodepos": "61261"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Wilayut",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Suko",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Sukodono",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Suruh",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Plumbungan",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Sambungrejo",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Pademonegoro",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Pekarungan",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Masangan Wetan",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Ngaresrejo",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Keloposepuluh",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Masangan Kulon",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Jogosatru",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Jumputrejo",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Kebonagung",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Bangsri",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Cangkringsari",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Wage",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Wonocolo",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Anggaswangi",
                "kodepos": "61258"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Tawangsari",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Trosobo",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Taman",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Tanjungsari",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sepanjang",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sidodadi",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sambi Bulu",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Sadang",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kramat Jegu",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Ngelom",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Pertapan Maduretno",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Ketegan",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kletek",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kalijaten",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Kedungturi",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Gilang",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Jemundo",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Bringinbendo",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Geluran",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Bohar",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Waru",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Bebekan",
                "kodepos": "61257"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Wedoro",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tropodo",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Wadungasri",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tambak Rejo",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tambak Sawah",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tambak Sumur",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Pepelegi",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Tambak Oso",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Medaeng",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Ngingas",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Kepuh Kiriman",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Kureksari",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Bungurasih",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Janti",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Kedungrejo",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Wedi",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Berbek",
                "kodepos": "61256"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Semambung",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Sruni",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Tebel",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Punggul",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Sawotratap",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Keboansikep",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Ketajen",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Kragan",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Karangbong",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Keboananom",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Gedangan",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Gemurung",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Ganting",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Gedangan",
                "kelurahan": "Bangah",
                "kodepos": "61254"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Semampir",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Tambak Cemandi",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Sedati Gede",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Segoro Tambak",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Pulungan",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Sedati Agung",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Pepe",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Pranti",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Kwangsan",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Pabean",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Cemandi",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Gisik Cemandi",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Kalanganyar",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Buncitan",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Betro",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Wadungasih",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Sedati",
                "kelurahan": "Banjar Kemuning",
                "kodepos": "61253"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Siwalan Panji",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Sukorejo",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Sidomulyo",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Sidokerto",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Sawohan",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Sidokepung",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Entalsewu",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Pagerwojo",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Prasung",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Damarsi",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Dukuhtengah",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Banjarsari",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Buduran",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Buduran",
                "kelurahan": "Banjarkemantren",
                "kodepos": "61252"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sari Rogo",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Urangagung (Jedong)",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Kemiri",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Rangkahkidul",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Cemeng Kalang",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Bulusidokare",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Cemeng Bakalan",
                "kodepos": "61234"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Gebang",
                "kodepos": "61231"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Bluru Kidul",
                "kodepos": "61233"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sumput",
                "kodepos": "61228"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Banjarbendo",
                "kodepos": "61225"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Jati",
                "kodepos": "61226"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Lebo",
                "kodepos": "61223"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Suko",
                "kodepos": "61224"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Pucang",
                "kodepos": "61219"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Pucanganom",
                "kodepos": "61217"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sidoklumpuk",
                "kodepos": "61218"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sekardangan",
                "kodepos": "61215"
            },
            {
                "kecamatan": "Sukodono",
                "kelurahan": "Panjunan",
                "kodepos": "61216"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sidokare",
                "kodepos": "61214"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Celep",
                "kodepos": "61215"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Lemahputro",
                "kodepos": "61213"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Pekauman",
                "kodepos": "61213"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Magersari",
                "kodepos": "61212"
            },
            {
                "kecamatan": "Sidoarjo",
                "kelurahan": "Sidokumpul",
                "kodepos": "61212"
            },
            {
                "kecamatan": "Taman",
                "kelurahan": "Krembangan",
                "kodepos": "61212"
            }
        ],
        "k141": [
            {
                "kecamatan": "Tambak",
                "kelurahan": "Tanjungori",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Teluk Jatidawang",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Tambak",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Peromaan (Paromaan)",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Sukalela",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Sukaoneng",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Kepuh Legundi",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Kepuh Teluk",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Pekalongan",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Grejeg",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Kelompang Gubug",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Diponggo",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Tambak",
                "kelurahan": "Gelam",
                "kodepos": "61182"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Suwari",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Sungai Teluk",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Sidogedung Batu",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Sungai Rujing",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Pudakit Timur",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Sawahmulya",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Patarselamat",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Pudakit Barat",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Kota Kusuma",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Kumalasa",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Lebak",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Gunungteguh",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Kebon Teluk Dalam",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Dekatagung",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Balikterus",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Bululanjang",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Sangkapura",
                "kelurahan": "Daun",
                "kodepos": "61181"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Wedoroanom",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Wedoroanom",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Sumput",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Tanjungan",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Petiken",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Randegansari",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Mojosarirejo",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Mulung",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Kesambenwetan",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Krikilan",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Driyorejo",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Gadung",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Karangandong",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Cangkir",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Banjaran",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Wringinanom",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Driyorejo",
                "kelurahan": "Bambe",
                "kodepos": "61177"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Sumengko",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Watestanjung",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Sumbergede",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Sumberwaru",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Sembung",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Soko (Sooko)",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Sumberame",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Mondoluku",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Pasinan Lemahputih",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Pedagangan",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Lebanisuko",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Lebaniwaras",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Kesamben Kulon",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Kedunganyar",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Wringin Anom",
                "kelurahan": "Kepuhklagen",
                "kodepos": "61176"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Turirejo",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Slempit",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Tanjung",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Tulung",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Mojowuku",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Ngepung",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Sidoraharjo",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Lampah",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Menunggal",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Katimoho",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Kedamean",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Belahanrejo",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Cermen",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Glindah",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Sidowungu",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Kedamean",
                "kelurahan": "Banyuurip",
                "kodepos": "61175"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Setro",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Sidojangkung",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Putat Lor",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Randupadangan",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Pengalangan",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Pranti",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Mojotengah",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Pelemwatu",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Laban",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Menganti",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Hendrosari",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Hulaan",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Kepatihan",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Drancang",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Gadingwatu",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Gempolkurung",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Bringkang",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Domas",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Boboh",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Boteng",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Wotansari",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Menganti",
                "kelurahan": "Beton",
                "kodepos": "61174"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Wahas",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Wonorejo",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Tenggor",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Sekarputih",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Tanahlandean",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Pinggir",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Pucung",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Ngasin",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Pacuh",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Klotok",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Mojogede",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Ngampel",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Kedungpring",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Kedungsumber",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Ganggang",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Jombangdelik",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Karangsemanding",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Dapet",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Dohoagung",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Banjaragung",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Brangkal",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Babatan",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Balongpanggang",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Balong Panggang",
                "kelurahan": "Bandungsekaran",
                "kodepos": "61173"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Sedapurklagen",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Sirnoboyo",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Punduttrate",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Metatu",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Munggugebang",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Munggugianti",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Klampok",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Lundo",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Karangankidul",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Kedungrukem",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Kedungsekar",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Jogodalu",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Kalipadang",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Gluranploso",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Jatirembe",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Dermo",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Bulangkulon",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Bulurejo",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Deliksumber",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Banter",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Bengkelolor",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Balongtunjung",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Tambakberas",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Wedani",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Benjeng",
                "kelurahan": "Balongmojo",
                "kodepos": "61172"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Semampir",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Sukoanyar",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Padeg",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Pandu",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Ngabetan",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Ngembung",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Lengkong",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Morowudi",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Jono",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Kambingan",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Kandangan",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Guranganyar",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Ikerikergeger",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Dungus",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Gedangkulut",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Dampaan",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Dooro",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Dadapkuning",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Cerme Kidul",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Cerme Lor",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Betiting",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Cagakagung",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Wadak Lor",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Cerme",
                "kelurahan": "Banjarsari",
                "kodepos": "61171"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Wadak Kidul",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Tumapel",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Tambakrejo",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Tebaloan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Tirem",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Sumari",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Sumengko",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Petisbenem",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Samirplapan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Setrohadi",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Palebon",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Pandanan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Panjunan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Kemudi",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Kramat Kulon",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Kandangan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Kawistowindu",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Glanggang",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Gredek",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Ambeng Ambeng Watangrejo",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Bendungan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Duduk Sampeyan",
                "kelurahan": "Duduk Sampeyan",
                "kodepos": "61162"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Surowiti",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Wotan",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Sukodono",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Sumurber",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Prupuh",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Serah",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Siwalan",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Petung",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Pantenan",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Doudo",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Ketanen",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Banyutengah",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Campurejo",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Panceng",
                "kelurahan": "Dalegan",
                "kodepos": "61156"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Wonokerto",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sembung Anyar",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sembungan Kidul",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Tebuwung",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Tiremenggal",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sekargadung",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sawo",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Petiyin Tunggal",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Sambogunung",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Padang Bandung",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Mentaras",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Mojopetung",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Lowayu",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Madumulyorejo",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Kalirejo",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Karangcangkring",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Jrebeng",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Imaan",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Dukunanyar",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Gedongkedoan",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Dukuh Kembar",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Bulangan",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Bangeran",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Baron",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Babaksari",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Dukun",
                "kelurahan": "Babakbawo",
                "kodepos": "61155"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Tanjangawan",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Pangkah Wetan",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Sekapuk",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Pangkah Kulon",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Ngemboh",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Kebonagung",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Ketapang Lor",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Karangrejo",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Gosari",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Glatik",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Bolo",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Cangaan",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Ujung Pangkah",
                "kelurahan": "Banyuurip",
                "kodepos": "61154"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Wadeng",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Srowo",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Sukorejo",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Sedagaran",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Sidomulyo",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Sambipondok",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Randuboto",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Racitengah",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Racikulon",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Purwodadi",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Ngawen",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Pengulu",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Mriyunan",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Mojoasem",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Lasem",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Kertosono",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Golokan",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Kauman",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Bunderan",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Gedangan",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Watuagung",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Sidayu",
                "kelurahan": "Asempapak",
                "kodepos": "61153"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sungonlegowo",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Tajung Widoro",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sukorejo",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sukowati",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sidomukti",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sidorejo",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Sidokumpul",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Raciwetan",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Mojopuro Wetan",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Pegundan",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Melirang",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Mojopuro Gede",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Kramat",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Masangan",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Indrodelik",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Kemangi",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Kisik",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Bungah",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Gumeng",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Abar-Abir",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Bungah",
                "kelurahan": "Bedanten",
                "kodepos": "61152"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Tebalo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Yosowilangun",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Sukomulyo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Sumberejo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Tanggulrejo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Sembayat",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Suci",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Pongangan",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Roomo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Pejangganan",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Ngampel",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Peganden",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Manyar Sidorukun",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Manyarejo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Morobakung",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Leran",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Manyar Sidomukti",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Betoyokauman",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Gumeno",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Karangrejo",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Banjarsari",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Banyuwangi",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Manyar",
                "kelurahan": "Betoyoguci",
                "kodepos": "61151"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Sukorejo",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Tenggulunan",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Sekarkurung",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Sidomukti",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Prambangan",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Klangonan",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Ngargosari",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Kedanyang",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Kembangan",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Gulomantung",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Indro",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Karangkering",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Giri",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Gending",
                "kodepos": "61123"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Segoromadu",
                "kodepos": "61123"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Dahanrejo",
                "kodepos": "61124"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Sidomoro",
                "kodepos": "61122"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Singosari",
                "kodepos": "61122"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Kebomas",
                "kodepos": "61121"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Randuagung",
                "kodepos": "61121"
            },
            {
                "kecamatan": "Kebomas",
                "kelurahan": "Kawisanyar",
                "kodepos": "61121"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Kramatinggil",
                "kodepos": "61119"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Ngipik",
                "kodepos": "61119"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Sukorame",
                "kodepos": "61119"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Tlogopojok",
                "kodepos": "61118"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Gapurosukolilo",
                "kodepos": "61119"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Lumpur",
                "kodepos": "61117"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Karangturi",
                "kodepos": "61118"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Kroman",
                "kodepos": "61116"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Sukodono",
                "kodepos": "61116"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Pekelingan",
                "kodepos": "61115"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Karangpoh",
                "kodepos": "61116"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Kebungson",
                "kodepos": "61115"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Kemuteran",
                "kodepos": "61115"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Pekauman",
                "kodepos": "61114"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Trate",
                "kodepos": "61114"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Tlogobendung",
                "kodepos": "61113"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Bedilan",
                "kodepos": "61114"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Sidorukun",
                "kodepos": "61112"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Pulopancikan",
                "kodepos": "61113"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Sidokumpul",
                "kodepos": "61111"
            },
            {
                "kecamatan": "Gresik",
                "kelurahan": "Tlogopatut",
                "kodepos": "61111"
            }
        ],
        "k142": [
            {
                "kecamatan": "Tenggilis Mejoyo",
                "kelurahan": "Prapen",
                "kodepos": "60299"
            },
            {
                "kecamatan": "Tenggilis Mejoyo",
                "kelurahan": "Panjang Jiwo",
                "kodepos": "60299"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Penjaringan Sari",
                "kodepos": "60297"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Kedung Baruk",
                "kodepos": "60298"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Wonorejo",
                "kodepos": "60296"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Medokan Ayu",
                "kodepos": "60295"
            },
            {
                "kecamatan": "Gununganyar",
                "kelurahan": "Gunung Anyar",
                "kodepos": "60294"
            },
            {
                "kecamatan": "Gununganyar",
                "kelurahan": "Gunung Anyar Tambak",
                "kodepos": "60294"
            },
            {
                "kecamatan": "Gununganyar",
                "kelurahan": "Rungkut Menanggal",
                "kodepos": "60293"
            },
            {
                "kecamatan": "Gununganyar",
                "kelurahan": "Rungkut Tengah",
                "kodepos": "60293"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Rungkut Kidul",
                "kodepos": "60293"
            },
            {
                "kecamatan": "Rungkut",
                "kelurahan": "Kali Rungkut",
                "kodepos": "60293"
            },
            {
                "kecamatan": "Tenggilis Mejoyo",
                "kelurahan": "Kendangsari",
                "kodepos": "60292"
            },
            {
                "kecamatan": "Tenggilis Mejoyo",
                "kelurahan": "Tenggilis Mejoyo",
                "kodepos": "60292"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Airlangga",
                "kodepos": "60286"
            },
            {
                "kecamatan": "Tenggilis Mejoyo",
                "kelurahan": "Kutisari",
                "kodepos": "60291"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Baratajaya",
                "kodepos": "60284"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Mojo",
                "kodepos": "60285"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Pucang Sewu",
                "kodepos": "60283"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Kertajaya",
                "kodepos": "60282"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Simomulyo",
                "kodepos": "60281"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Simomulyo Baru",
                "kodepos": "60281"
            },
            {
                "kecamatan": "Gubeng",
                "kelurahan": "Gubeng",
                "kodepos": "60281"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Genteng",
                "kodepos": "60275"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Kapasari",
                "kodepos": "60273"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Peneleh",
                "kodepos": "60274"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Embong Kaliasin",
                "kodepos": "60271"
            },
            {
                "kecamatan": "Genteng",
                "kelurahan": "Ketabang",
                "kodepos": "60272"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Dr. Sutomo",
                "kodepos": "60264"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Keputran",
                "kodepos": "60265"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Kedungdoro",
                "kodepos": "60261"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Tegalsari",
                "kodepos": "60262"
            },
            {
                "kecamatan": "Tegalsari",
                "kelurahan": "Wonorejo",
                "kodepos": "60263"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Pakis",
                "kodepos": "60256"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Banyu Urip",
                "kodepos": "60254"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Putat Jaya",
                "kodepos": "60255"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Petemon",
                "kodepos": "60252"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Kupang Krajan",
                "kodepos": "60253"
            },
            {
                "kecamatan": "Sawahan",
                "kelurahan": "Sawahan",
                "kodepos": "60251"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Ngagelrejo",
                "kodepos": "60245"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Ngagel",
                "kodepos": "60246"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Jagir",
                "kodepos": "60244"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Wonokromo",
                "kodepos": "60243"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Darmo",
                "kodepos": "60241"
            },
            {
                "kecamatan": "Wonokromo",
                "kelurahan": "Sawunggaling",
                "kodepos": "60242"
            },
            {
                "kecamatan": "Wonocolo",
                "kelurahan": "Bendul Merisi",
                "kodepos": "60239"
            },
            {
                "kecamatan": "Wonocolo",
                "kelurahan": "Sidosermo",
                "kodepos": "60239"
            },
            {
                "kecamatan": "Wonocolo",
                "kelurahan": "Jemur Wonosari",
                "kodepos": "60237"
            },
            {
                "kecamatan": "Wonocolo",
                "kelurahan": "Margorejo",
                "kodepos": "60238"
            },
            {
                "kecamatan": "Gayungan",
                "kelurahan": "Gayungan",
                "kodepos": "60235"
            },
            {
                "kecamatan": "Wonocolo",
                "kelurahan": "Siwalankerto",
                "kodepos": "60236"
            },
            {
                "kecamatan": "Gayungan",
                "kelurahan": "Dukuh Menanggal",
                "kodepos": "60234"
            },
            {
                "kecamatan": "Gayungan",
                "kelurahan": "Menanggal",
                "kodepos": "60234"
            },
            {
                "kecamatan": "Jambangan",
                "kelurahan": "Pagesangan",
                "kodepos": "60233"
            },
            {
                "kecamatan": "Jambangan",
                "kelurahan": "Jambangan",
                "kodepos": "60232"
            },
            {
                "kecamatan": "Jambangan",
                "kelurahan": "Karah",
                "kodepos": "60232"
            },
            {
                "kecamatan": "Jambangan",
                "kelurahan": "Kebonsari",
                "kodepos": "60233"
            },
            {
                "kecamatan": "Gayungan",
                "kelurahan": "Ketintang",
                "kodepos": "60231"
            },
            {
                "kecamatan": "Wiyung",
                "kelurahan": "Wiyung",
                "kodepos": "60228"
            },
            {
                "kecamatan": "Wiyung",
                "kelurahan": "Jajartunggal",
                "kodepos": "60229"
            },
            {
                "kecamatan": "Wiyung",
                "kelurahan": "Babatan",
                "kodepos": "60227"
            },
            {
                "kecamatan": "Dukuh Pakis",
                "kelurahan": "Pradah Kali Kendal",
                "kodepos": "60226"
            },
            {
                "kecamatan": "Dukuh Pakis",
                "kelurahan": "Dukuh Kupang",
                "kodepos": "60225"
            },
            {
                "kecamatan": "Dukuh Pakis",
                "kelurahan": "Dukuh Pakis",
                "kodepos": "60225"
            },
            {
                "kecamatan": "Dukuh Pakis",
                "kelurahan": "Gunungsari",
                "kodepos": "60224"
            },
            {
                "kecamatan": "Karangpilang",
                "kelurahan": "Kedurus",
                "kodepos": "60223"
            },
            {
                "kecamatan": "Karangpilang",
                "kelurahan": "Kebraon",
                "kodepos": "60222"
            },
            {
                "kecamatan": "Wiyung",
                "kelurahan": "Balas Klumprik",
                "kodepos": "60222"
            },
            {
                "kecamatan": "Karangpilang",
                "kelurahan": "Waru Gunung",
                "kodepos": "60221"
            },
            {
                "kecamatan": "Karangpilang",
                "kelurahan": "Karangpilang",
                "kodepos": "60221"
            },
            {
                "kecamatan": "Sambikerep",
                "kelurahan": "Bringin",
                "kodepos": "60218"
            },
            {
                "kecamatan": "Sambikerep",
                "kelurahan": "Made",
                "kodepos": "60219"
            },
            {
                "kecamatan": "Sambikerep",
                "kelurahan": "Lontar",
                "kodepos": "60216"
            },
            {
                "kecamatan": "Sambikerep",
                "kelurahan": "Sambikerep",
                "kodepos": "60217"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Lidah Wetan",
                "kodepos": "60213"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Bangkingan (Bangkringan)",
                "kodepos": "60214"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Sumur Welut",
                "kodepos": "60215"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Jeruk",
                "kodepos": "60212"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Lidah Kulon",
                "kodepos": "60213"
            },
            {
                "kecamatan": "Lakar Santri",
                "kelurahan": "Lakar Santri",
                "kodepos": "60211"
            },
            {
                "kecamatan": "Benowo",
                "kelurahan": "Kandangan",
                "kodepos": "60199"
            },
            {
                "kecamatan": "Benowo",
                "kelurahan": "Sememi",
                "kodepos": "60198"
            },
            {
                "kecamatan": "Benowo",
                "kelurahan": "Klakah Rejo",
                "kodepos": "60198"
            },
            {
                "kecamatan": "Pakal",
                "kelurahan": "Babat Jerawat",
                "kodepos": "60197"
            },
            {
                "kecamatan": "Pakal",
                "kelurahan": "Pakal",
                "kodepos": "60196"
            },
            {
                "kecamatan": "Sambikerep",
                "kelurahan": "Benowo",
                "kodepos": "60195"
            },
            {
                "kecamatan": "Pakal",
                "kelurahan": "Sumberrejo (Sombe Rejo)",
                "kodepos": "60192"
            },
            {
                "kecamatan": "Pakal",
                "kelurahan": "Tambak Dono",
                "kodepos": "60193"
            },
            {
                "kecamatan": "Benowo",
                "kelurahan": "Tambakoso Wilangon",
                "kodepos": "60191"
            },
            {
                "kecamatan": "Benowo",
                "kelurahan": "Romokalisari (Babat Jerawat)",
                "kodepos": "60192"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Sonokwijenan",
                "kodepos": "60189"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Suko Manunggal",
                "kodepos": "60188"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Putat Gede",
                "kodepos": "60189"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Tandes (Kidul &#8211; Lor &#8211; Gedang Asin)",
                "kodepos": "60187"
            },
            {
                "kecamatan": "Sukomanunggal",
                "kelurahan": "Tanjungsari",
                "kodepos": "60187"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Balong Sari",
                "kodepos": "60186"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Karang Poh (Gadel &#8211; Tubanan)",
                "kodepos": "60186"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Banjar Sugian / Sugihan",
                "kodepos": "60185"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Manukan Kulon",
                "kodepos": "60185"
            },
            {
                "kecamatan": "Asemrowo",
                "kelurahan": "Tambak Langon",
                "kodepos": "60184"
            },
            {
                "kecamatan": "Tandes",
                "kelurahan": "Manukan Wetan (Bibis &#8211; Buntaran)",
                "kodepos": "60184"
            },
            {
                "kecamatan": "Asemrowo",
                "kelurahan": "Genting",
                "kodepos": "60182"
            },
            {
                "kecamatan": "Asemrowo",
                "kelurahan": "Greges",
                "kodepos": "60183"
            },
            {
                "kecamatan": "Asemrowo",
                "kelurahan": "Kalianak",
                "kodepos": "60183"
            },
            {
                "kecamatan": "Krembangan",
                "kelurahan": "Dupak",
                "kodepos": "60179"
            },
            {
                "kecamatan": "Asemrowo",
                "kelurahan": "Asemrowo",
                "kodepos": "60182"
            },
            {
                "kecamatan": "Krembangan",
                "kelurahan": "Moro Krembangan",
                "kodepos": "60178"
            },
            {
                "kecamatan": "Krembangan",
                "kelurahan": "Kemayoran",
                "kodepos": "60176"
            },
            {
                "kecamatan": "Krembangan",
                "kelurahan": "Perak Barat",
                "kodepos": "60177"
            },
            {
                "kecamatan": "Bubutan",
                "kelurahan": "Bubutan",
                "kodepos": "60174"
            },
            {
                "kecamatan": "Krembangan",
                "kelurahan": "Krembangan Selatan",
                "kodepos": "60175"
            },
            {
                "kecamatan": "Bubutan",
                "kelurahan": "Gundih",
                "kodepos": "60172"
            },
            {
                "kecamatan": "Bubutan",
                "kelurahan": "Tembok Dukuh",
                "kodepos": "60173"
            },
            {
                "kecamatan": "Bubutan",
                "kelurahan": "Alon Alon Contong",
                "kodepos": "60174"
            },
            {
                "kecamatan": "Pabean Cantikan",
                "kelurahan": "Perak Utara",
                "kodepos": "60165"
            },
            {
                "kecamatan": "Bubutan",
                "kelurahan": "Jepara",
                "kodepos": "60171"
            },
            {
                "kecamatan": "Pabean Cantikan",
                "kelurahan": "Krembangan Utara",
                "kodepos": "60163"
            },
            {
                "kecamatan": "Pabean Cantikan",
                "kelurahan": "Perak Timur",
                "kodepos": "60164"
            },
            {
                "kecamatan": "Pabean Cantikan",
                "kelurahan": "Nyamplungan",
                "kodepos": "60162"
            },
            {
                "kecamatan": "Semampir",
                "kelurahan": "Ujung",
                "kodepos": "60155"
            },
            {
                "kecamatan": "Pabean Cantikan",
                "kelurahan": "Bongkaran",
                "kodepos": "60161"
            },
            {
                "kecamatan": "Semampir",
                "kelurahan": "Pegirian",
                "kodepos": "60153"
            },
            {
                "kecamatan": "Semampir",
                "kelurahan": "Wonokusumo",
                "kodepos": "60154"
            },
            {
                "kecamatan": "Semampir",
                "kelurahan": "Ampel",
                "kodepos": "60151"
            },
            {
                "kecamatan": "Semampir",
                "kelurahan": "Sidotopo",
                "kodepos": "60152"
            },
            {
                "kecamatan": "Simokerto",
                "kelurahan": "Simolawang",
                "kodepos": "60144"
            },
            {
                "kecamatan": "Simokerto",
                "kelurahan": "Sidodadi",
                "kodepos": "60145"
            },
            {
                "kecamatan": "Simokerto",
                "kelurahan": "Simokerto",
                "kodepos": "60143"
            },
            {
                "kecamatan": "Simokerto",
                "kelurahan": "Kapasan",
                "kodepos": "60141"
            },
            {
                "kecamatan": "Simokerto",
                "kelurahan": "Tambakrejo",
                "kodepos": "60142"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Kapasmadya Baru",
                "kodepos": "60137"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Dukuh Setro",
                "kodepos": "60138"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Rangkah",
                "kodepos": "60135"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Tambaksari",
                "kodepos": "60136"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Gading",
                "kodepos": "60134"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Gading",
                "kodepos": "60134"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Pacar Keling",
                "kodepos": "60131"
            },
            {
                "kecamatan": "Tambaksari",
                "kelurahan": "Pacar Kembang",
                "kodepos": "60132"
            },
            {
                "kecamatan": "Kenjeran",
                "kelurahan": "Sidotopo/Sidoropo Wetan",
                "kodepos": "60128"
            },
            {
                "kecamatan": "Kenjeran",
                "kelurahan": "Tanah Kali Kedinding",
                "kodepos": "60129"
            },
            {
                "kecamatan": "Kenjeran",
                "kelurahan": "Tambak Wedi",
                "kodepos": "60126"
            },
            {
                "kecamatan": "Kenjeran",
                "kelurahan": "Bulak Banteng",
                "kodepos": "60127"
            },
            {
                "kecamatan": "Bulak",
                "kelurahan": "Bulak",
                "kodepos": "60124"
            },
            {
                "kecamatan": "Bulak",
                "kelurahan": "Kedung Cowek",
                "kodepos": "60125"
            },
            {
                "kecamatan": "Bulak",
                "kelurahan": "Kenjeran",
                "kodepos": "60123"
            },
            {
                "kecamatan": "Bulak",
                "kelurahan": "Sukolilo",
                "kodepos": "60122"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Semolowaru",
                "kodepos": "60119"
            },
            {
                "kecamatan": "Bulak",
                "kelurahan": "Komplek Kenjeran",
                "kodepos": "60121"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Medokan Semampir",
                "kodepos": "60119"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Klampis Ngasem",
                "kodepos": "60117"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Menur Pumpungan",
                "kodepos": "60118"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Nginden Jangkungan",
                "kodepos": "60118"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Gebang Putih",
                "kodepos": "60117"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Manyar Sabrangan",
                "kodepos": "60116"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Kalijudan",
                "kodepos": "60114"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Mulyorejo",
                "kodepos": "60115"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Kejawen Putih Tambak",
                "kodepos": "60112"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Dukuh Sutorejo",
                "kodepos": "60113"
            },
            {
                "kecamatan": "Sukolilo",
                "kelurahan": "Keputih",
                "kodepos": "60111"
            },
            {
                "kecamatan": "Mulyorejo",
                "kelurahan": "Kalisari",
                "kodepos": "60112"
            }
        ],
        "k143": [
            {
                "kecamatan": "Menukung",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Pelaik Keruap",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Sei/Sungai Sampak",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Sei/Sungai Sampuk",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Nanga Siyai",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Nusa Poring",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Oyah",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Nanga Keruap",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Menukung Kota",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Lihai",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Mawang Mentatai",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Melona",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Ella Hulu (Nanga Ella Hulu)",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Laman Mumbung",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Landau Leban",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Batu Onap",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Belaban Ella",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Batu Badak",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Menukung",
                "kelurahan": "Batas nangka",
                "kodepos": "79682"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Sungai Labu",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Sungai Mentoba",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Popai",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Penyuguk",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Perembang Nyuruh",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nyangau",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Pelempai Jaya",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nanga Nyuruh",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nantai Compa",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nanga Kempangai",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nanga Nuak",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nanga Ella Hilir",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Nanga Kalan",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Kerangan Kora",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Lengkong Nyadom",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Jabai",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Kahiya",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Bemban Permai",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Ella Hilir",
                "kelurahan": "Domet Permai",
                "kodepos": "79681"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Teluk Pangkal",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Tanjung Mahung",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Tanjung Sokan",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Telaga Raya",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Sepakat",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Sijau",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Tangkit",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Penyengkuang",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Ora",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Potai",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Sokan",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Betangai",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Nanga Libas",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Melana",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Muara Tanjung",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Keluing Taja",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Landau Kabu",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Ulak Muid",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Sokan",
                "kelurahan": "Gelata",
                "kodepos": "79675"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Togan Baru",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Tanjung Beringin Raya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Pelita Kanaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Suka Maju",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Pelita Jaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Madong Raya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Maris Permai",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Lintah Taum",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Loka Jaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Laja",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Keranjik",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Keluas Hulu",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Harapan Jaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Ganjang",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Bukit Raya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh Barat",
                "kelurahan": "Durian Jaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Bina Jaya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Bina Karya",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Batu Begigi",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Tanah Pinoh",
                "kelurahan": "Bata Luar",
                "kodepos": "79674"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Tumbak Raya",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Sayan Jaya",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Siling Permai",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Sayan",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Pekawai",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Pak",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Raku",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Kompi",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Mancur",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Nanga Kasai",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Meta Bersatu",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Madya Raya",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Mekar Pelita",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Lingkar Indah",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Karangan Purun (Kerangan Purun)",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Landau Sadak",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Berobai Permai",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Sayan",
                "kelurahan": "Bora",
                "kodepos": "79673"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Tengkajau",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tembawang Panjang",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tanjung Tengang",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Tekelak",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tebing Karangan",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tanjung Sari",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tanjung Lay",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Tanjung Niaga",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Tanjung Paoh",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Suka Damai",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Tanjung Arak",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Sido Mulyo",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Senibung",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Senempak",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Semadin Lengkong",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Sei/Sungai Bakah",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Poring",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Pelinggang",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Paal",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Nyanggai",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Nusa Pandau",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Natai Panjang",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Nanga Man",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Nanga Kelawai",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Nanga Pintas",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Nanga Kayan",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Nanga Kebebu",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Merpak",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Nanga Belimbing",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Melamut Bersatu",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Melawi Kiri Hilir",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Merah Atas",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Manding",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Manggala",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Landau Tubun",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Mandau Baru",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Kompas Raya",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Labai Mandiri",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Landau Garong",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Kelakik",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Kenual",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Engkurai",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Utara",
                "kelurahan": "Kayan Semapau",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Bina Jaya",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Pinoh Selatan",
                "kelurahan": "Bayur Raya",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Nanga Pinoh",
                "kelurahan": "Baru",
                "kodepos": "79672"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Upit (Upet)",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Sepan Tonak",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Tekaban",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Tiong Keranjik",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Pemuar",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Piawas",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Nusa Kenyikap",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Nanga Tikan",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Nanga Pau",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Nanga Raya",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Langan",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Nanga Menunuk",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Laman Bukit",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Kayu Bunga",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Keberak (Nanga Keberak)",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Labang",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Junjung Permai",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Guhung",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing Hulu",
                "kelurahan": "Beloyang",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Entebah",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Batu Nanta",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Belonsat",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Batu Ampar",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Batu Buil",
                "kodepos": "79671"
            },
            {
                "kecamatan": "Belimbing",
                "kelurahan": "Balai Agas",
                "kodepos": "79671"
            }
        ],
        "k144": [
            {
                "kecamatan": "Belitang",
                "kelurahan": "Padak",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Setuntung",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Menua Prama",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Nanga Ansar",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Maboh Permai",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Belitang Satu",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Belitang Dua",
                "kodepos": "79588"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Tabuk Hulu",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Terduk Dampak",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Sei/Sungai Antu Hulu",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Sei/Sungai Tapah",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Sebetung",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Seburuk",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Mengaret",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Paket/Pakit Mulau",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Bukit Rambat",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Ijuk (Ijok)",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Kumpang Ilong",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Balai Sepuak",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hulu",
                "kelurahan": "Batuk Mulau",
                "kodepos": "79587"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Tapang Pulau",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Sei/Sungai Ayak III",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Semadu",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Merbang",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Sei/Sungai Ayak I",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Menawai Tekam",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Kumpang Bis",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Empajak",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Belitang Hilir",
                "kelurahan": "Entabuk",
                "kodepos": "79586"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Tembaga",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Tembesuk",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Sebabas (Se Babas)",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Tamang",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Teluk Kebau",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Nanga Suri",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Nanga Mahap",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Landau Kumpai",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Lembah Beringin",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Cenayan",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Karang Betung",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Landau Apin",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Mahap",
                "kelurahan": "Batu Pahat",
                "kodepos": "79585"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Sei/Sungai Lawak",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Senangak",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Tapang Tingang",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Taman",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Pantok",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Rirang Jati",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Mongko",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Koman",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Mentukak",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Kiungkang",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Meragun",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Nanga Engkulun",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Tinting Boyok",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Nanga Taman",
                "kelurahan": "Lubuk Tajau",
                "kodepos": "79584"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Sunsong",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Tapang Perodah",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Sekonau",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Setawar",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Sei/Sungai Sambang",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Rawak Hilir",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Rawak Hulu",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Nanga Pemubuh",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Perongkan",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Nanga Manterap",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Cupang Gading",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Mondi",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Nanga Biaban",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hulu",
                "kelurahan": "Boti",
                "kodepos": "79583"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Sei/Sungai Ringin",
                "kodepos": "79582"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Selalong",
                "kodepos": "79582"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Bokak Sebumbun",
                "kodepos": "79582"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Gonis Tekam",
                "kodepos": "79582"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Peniti",
                "kodepos": "79516"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Sei/Sungai Kunyit",
                "kodepos": "79516"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Semabi",
                "kodepos": "79515"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Ensalang",
                "kodepos": "79516"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Timpuk",
                "kodepos": "79514"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Seberang Kapuas",
                "kodepos": "79515"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Tapang Semadak",
                "kodepos": "79513"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Landau Kodah",
                "kodepos": "79514"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Seraras",
                "kodepos": "79512"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Engkersik",
                "kodepos": "79513"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Merapi",
                "kodepos": "79512"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Tanjung",
                "kodepos": "79511"
            },
            {
                "kecamatan": "Sekadau Hilir",
                "kelurahan": "Mungguk",
                "kodepos": "79511"
            }
        ],
        "k145": [
            {
                "kecamatan": "Sajingan Besar",
                "kelurahan": "Sebunga",
                "kodepos": "79467"
            },
            {
                "kecamatan": "Sajingan Besar",
                "kelurahan": "Sei/Sungai Bening",
                "kodepos": "79467"
            },
            {
                "kecamatan": "Sajingan Besar",
                "kelurahan": "Sanatab",
                "kodepos": "79467"
            },
            {
                "kecamatan": "Sajingan Besar",
                "kelurahan": "Santaban",
                "kodepos": "79467"
            },
            {
                "kecamatan": "Sajingan Besar",
                "kelurahan": "Kaliau",
                "kodepos": "79467"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Sebubus",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Tanah Hitam",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Temajuk",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Mentibar",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Nibung",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Malek",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Matang Danau",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Paloh",
                "kelurahan": "Kalimantan",
                "kodepos": "79466"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Teluk Kembang",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Tri Mandayan",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Tekarang",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Teluk Kaseh",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Tangaran",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Tanjung Keracut",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Simpang Empat",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Tambatan",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sengawang",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sepadu",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Semata",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Sempadian",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sekura",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sei/Sungai Serabek",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sei/Sungai Kumpai",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sebagu",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sei/Sungai Baru",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Sayang Sedayu",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Sari Makmur",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Puringan",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Samustida",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Rambayan",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Pedada",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Pipit Teja",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Mulia",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Pancur",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Merubong",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Merpati",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Mekar Sekuntum",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Merabuan",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Matang Segarau",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Lela",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Matang Segantar",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Kubangga",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Kuala Pangkalan Keramat",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tekarang",
                "kelurahan": "Cepala",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Teluk Keramat",
                "kelurahan": "Berlimang",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Tangaran",
                "kelurahan": "Arung Parak",
                "kodepos": "79465"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Sulung",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Setalik",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Sepantai",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Senujuh",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Sendoyan",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Semanga",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Piantus",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Sekuduk",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Perigi Limus",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Perigi Landu",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Penakalan",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sejangkung",
                "kelurahan": "Parit Raja",
                "kodepos": "79463"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Tumuk/Tumok Manggis",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Tempatan",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sajad",
                "kelurahan": "Tengguli",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Tebing Batu",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Tanjung Mekar",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Sumber Harapan",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Tanjung Bugis",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Sempelai Sebedang",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Sepuk Tanjung",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Sei/Sungai Rambah",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Semangau",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Sebawi",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Sebayan",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Saing Rambi",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Sebangun",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Pendawan",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sebawi",
                "kelurahan": "Rantau Panjang",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Pasar Melayu",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sajad",
                "kelurahan": "Mekar Jaya",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Lubuk Dagang",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Lumbang",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Kartiasa",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Lorong",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Jagur",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sajad",
                "kelurahan": "Jirak",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Gapura",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Durian",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sajad",
                "kelurahan": "Beringin",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Sambas",
                "kelurahan": "Dalam Kaum",
                "kodepos": "79462"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Tebas Sungai",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Serindang",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Serumpun Buluh",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Tebas Kuala",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Sempalai",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Seret Ayon",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Sei/Sungai Kelambu",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Sejiram",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Segarau Parit",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Segedong",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Pusaka",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Seberkat",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Mekar Sekuntum",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Mensere",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Pangkalan Kongsi",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Maribas",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Matang Labung",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Mak Rampai",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Mak Tangguk",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Bukit Segoler",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Dungun Perapakan",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Batu Makjage",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Tebas",
                "kelurahan": "Bekut",
                "kodepos": "79461"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sentebang",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sei/Sungai Nyirih",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sarang Burung Usrat",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sei/Sungai Nilam",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sarang Burung Kolam",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sarang Burung Kuala",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Pelimpaan",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Sarang Burung Danau",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Bakau",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Dungun Laut",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Jawai",
                "kelurahan": "Parit Setia",
                "kodepos": "79454"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Tri Kembang",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Tempapan Kuala",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Tri Gaduh",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Teluk Pandan",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Tempapan Hulu",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Semparuk",
                "kelurahan": "Singaraya",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Salatiga",
                "kelurahan": "Serumpun",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Salatiga",
                "kelurahan": "Serunai",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Sijang",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Semparuk",
                "kelurahan": "Sepadu",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Semparuk",
                "kelurahan": "Sepinggan",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Salatiga",
                "kelurahan": "Sei/Sungai Toman",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Semparuk",
                "kelurahan": "Semparuk",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Sei/Sungai Palah",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Semparuk",
                "kelurahan": "Seburing",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Salatiga",
                "kelurahan": "Salatiga",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Sagu",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Pemangkat",
                "kelurahan": "Perapakan",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Ratu Sepudak",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Pemangkat",
                "kelurahan": "Pemangkat Kota",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Pemangkat",
                "kelurahan": "Penjajap",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Salatiga",
                "kelurahan": "Parit Baru",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Pemangkat",
                "kelurahan": "Jelutung",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Pemangkat",
                "kelurahan": "Harapan",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Galing",
                "kelurahan": "Galing",
                "kodepos": "79453"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Twi Mentibar",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Semelagi Besar",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau Timur",
                "kelurahan": "Seranggam",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Sei/Sungai Rusa",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau Timur",
                "kelurahan": "Selakau Tua",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Sei/Sungai Nyirih",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Sei/Sungai Daun",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Pangkalan Bemban",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Parit Baru",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau Timur",
                "kelurahan": "Gelik",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Kuala",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau Timur",
                "kelurahan": "Buduk Sempadang",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Selakau",
                "kelurahan": "Bentunai",
                "kodepos": "79452"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Tebuah Elok",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Sei/Sungai Sapak",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Sempurna",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Mukti Raharja",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Sabung",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Sei/Sungai Deden",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Mensade",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Bukit Mulya",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Madak",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Subah",
                "kelurahan": "Balai Gemuruh",
                "kodepos": "79417"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Semperiuk B",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Suah Api",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Sarilaba B",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Semperiuk A",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Sabaran",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Sarilaba A",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Jawai Laut",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Jelu Air",
                "kodepos": "79154"
            },
            {
                "kecamatan": "Jawai Selatan",
                "kelurahan": "Matang Terap",
                "kodepos": "79154"
            }
        ],
        "k146": [
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Tanjung Balai",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Sejowet",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Semedang (Sumedang)",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Sehe Lusur",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Paku Raya",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Permiit",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Kuala Behe",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Nyayum",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Bengawan Ampar",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Kedama",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Kuala Behe",
                "kelurahan": "Angkanyar",
                "kodepos": "79367"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Selange",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Tahu",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Moro Betung",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Meranti",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Kelampai Setolo",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Tenguwe",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Ampadi",
                "kodepos": "79366"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Temoyok",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Tengon Pelaik",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Semuntik",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Sepangah",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Serimbu",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Sekendal",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Sempatung Lawek",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Nyari",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Parek (Pare/Sutipadang)",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Jambu Tembawang",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Merayuh",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Engkangin",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Engkadik/Enkadik Pade",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Engkangin",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Air Besar",
                "kelurahan": "Bentiang Madomang",
                "kodepos": "79365"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Tembawang Bale",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Untang",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Tolok",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Songga",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Ta As",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Semade",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Sidan",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Ringo Lojok",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Sei/Sungai Lubang",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Padang Pio",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Mamek",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Ongkol Padang",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Ladangan",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Lintah Betung",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Kampet",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Kayuara",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Banyuke Hulu",
                "kelurahan": "Gamang",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Bagak",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Berinang Mayun",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Darit",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Anik Dingir",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Ansang",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Menyuke",
                "kelurahan": "Angkaras",
                "kodepos": "79364"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Tiang Tanjung",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Tunang",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Sompak",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Sei/Sungai Laki",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Tapanking (Tapang)",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Salumang",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Sampuro",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Sailo",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Sala&#8217;as",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Sabaka",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Pauh",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Pakumbang (Pak Kumbang)",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Parigi",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Mentonyek",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Pahong (Pahok)",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Lingkonong",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Garu",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Karangan",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Galar",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Bilayuk",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Caokng",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Babatn",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Mempawah Hulu",
                "kelurahan": "Ansolok",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Sompak",
                "kelurahan": "Ambawang",
                "kodepos": "79363"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Tempoak",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Rees",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Sepahat",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Menjalin",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Nangka",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Raba",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Bengkawe",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Menjalin",
                "kelurahan": "Lamo Anak",
                "kodepos": "79362"
            },
            {
                "kecamatan": "Sebangki",
                "kelurahan": "Sei/Sungai Segak",
                "kodepos": "79358"
            },
            {
                "kecamatan": "Sebangki",
                "kelurahan": "Rantau Panjang",
                "kodepos": "79358"
            },
            {
                "kecamatan": "Sebangki",
                "kelurahan": "Sebangki",
                "kodepos": "79358"
            },
            {
                "kecamatan": "Sebangki",
                "kelurahan": "Agak",
                "kodepos": "79358"
            },
            {
                "kecamatan": "Sebangki",
                "kelurahan": "Kumpang Tengah",
                "kodepos": "79358"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Tubang Raeng",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Temiang Sawi",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Temahar",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Tebedak",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Sekais",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Sungai Keli",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Rasan",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Sebirang",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Penyahu Dangku (Penyaho Dangku)",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Raja",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Papung",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Pawis Hilir",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Muun",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Nyiin",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Pak Mayam",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Munggu",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Mandor Kiru",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Kayu Ara",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Kersik Belantian",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Jelimpo",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Hilir Kantor",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Hilir Tengah",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Dara Itam I",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Engkadu",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Antan Rayan",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Angan Tembawang",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Jelimpo",
                "kelurahan": "Balai Peluntan",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Amboyo Selatan",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Amboyo Utara",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Ambarang",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Amboyo Inti",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Ngabang",
                "kelurahan": "Amang",
                "kodepos": "79357"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Sidas",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Tonang",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Sebatih",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Senakin",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Rabak",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Saham",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Pahauman",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Paloan",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Keranji Mancal",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Keranji Paidang",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Banying",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Gombang",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Andeng",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Sengah Temila",
                "kelurahan": "Aur Sampuk",
                "kodepos": "79356"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Sum Sum",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Selutung",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Semenok",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Simpang Kasturi",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Sekilap",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Sebadu",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Sala Tiga",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Mengkunyit",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Ngarak",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Pongok",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Mandor",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Manggang",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Keramas",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Kerohok",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Kayu Ara",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Kayu Tanam",
                "kodepos": "79355"
            },
            {
                "kecamatan": "Mandor",
                "kelurahan": "Bebatung",
                "kodepos": "79355"
            }
        ],
        "k147": [
            {
                "kecamatan": "Siding",
                "kelurahan": "Tangguh",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Tawang",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Sungkung III",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Tamong",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Sungkung I",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Sungkung II",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Sinar Baru",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Siding",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Sekida (Jagoi Sekida)",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Semunyin Jaya",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Kumba",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Siding",
                "kelurahan": "Lhi Bui/Lhibuie",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Gersik",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Jagoi Babang",
                "kelurahan": "Jagoi",
                "kodepos": "79286"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Sentangau Jaya",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Mayak",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Sahan",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Seluas",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Bengkawan",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Seluas",
                "kelurahan": "Kalon",
                "kodepos": "79285"
            },
            {
                "kecamatan": "Sanggau Ledo",
                "kelurahan": "Sango",
                "kodepos": "79284"
            },
            {
                "kecamatan": "Sanggau Ledo",
                "kelurahan": "Gua",
                "kodepos": "79284"
            },
            {
                "kecamatan": "Sanggau Ledo",
                "kelurahan": "Lembang",
                "kodepos": "79284"
            },
            {
                "kecamatan": "Sanggau Ledo",
                "kelurahan": "Bange",
                "kodepos": "79284"
            },
            {
                "kecamatan": "Sanggau Ledo",
                "kelurahan": "Danti",
                "kodepos": "79284"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Tebuah Marong (Trans)",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Lumar",
                "kelurahan": "Tiga Berkat",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Suti Semarang",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Tapen",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Suka Jaya",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Suka Maju",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Sidai",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Suka Damai",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Lumar",
                "kelurahan": "Seren Selimbau",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Serangkat",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Seles",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Semangat",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Muhi Bersatu",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Nangka",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Rodaya",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Lesabela",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Lomba Karya",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Lumar",
                "kelurahan": "Magmagan Karya",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Kiung",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Lumar",
                "kelurahan": "Lamolda",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Kelayu (Kelayuk)",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Jesape",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Ledo",
                "kelurahan": "Dayung",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Suti Semarang",
                "kelurahan": "Cempaka Putih",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Lumar",
                "kelurahan": "Belimbing",
                "kodepos": "79283"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Tumiang",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Tempapan",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Samalantan",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Saka Taru",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Pasti Jaya",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Saba&#8217;u",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Papan Tembawang",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Papan Uduk",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Lembah Bawang",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Marunsu",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Janyat",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Kinande",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Lembah Bawang",
                "kelurahan": "Godang Damar",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Bukit Serayan",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Samalantan",
                "kelurahan": "Babane",
                "kodepos": "79281"
            },
            {
                "kecamatan": "Sungai Raya Kepulauan",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Pangkalan II",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Setanduk",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya Kepulauan",
                "kelurahan": "Sei/Sungai Keran",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Pangkalan I",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Jaga A",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Jaga B",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Duri",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya Kepulauan",
                "kelurahan": "Rukma Jaya",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Sabandut",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya Kepulauan",
                "kelurahan": "Pulau Lemukutan",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Pawangi",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Mandor",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Sungai Raya Kepulauan",
                "kelurahan": "Karimunting",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Capkala",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Tujuh Belas",
                "kelurahan": "Sinar Tebudak",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Capkala",
                "kelurahan": "Aris",
                "kodepos": "79271"
            },
            {
                "kecamatan": "Tujuh Belas",
                "kelurahan": "Pisak",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Tujuh Belas",
                "kelurahan": "Kamuh",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Tujuh Belas",
                "kelurahan": "Bengkilu",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Tubajur",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Temia Sio",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Teriak",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Tanjung",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Telidik",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Setia Jaya",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Sumber Karya",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Sekaruh",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Sebente",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Sebetung Menyala",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Puteng",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Lulang",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Malo Jelayan",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Benteng",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Dharma Bhakti",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Bana",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Bangun Sari",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Teriak",
                "kelurahan": "Ampar Benteng",
                "kodepos": "79214"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Bumi Emas",
                "kodepos": "79212"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Bhakti/Bakti Mulya",
                "kodepos": "79213"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Tirta Kencana",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Sungai Betung",
                "kelurahan": "Suka Maju",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Sungai Betung",
                "kelurahan": "Suka Bangun",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Setia Budi",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Setia Budi",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Sungai Betung",
                "kelurahan": "Cipta Karya",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Sungai Betung",
                "kelurahan": "Karya Bhakti",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Bengkayang",
                "kelurahan": "Bani Amas/Emas",
                "kodepos": "79211"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Siaga (Nyempen)",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Sendoreng",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Serindu",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Rantau",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Monterado",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Nek Ginap",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Mekar Baru",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Goa Boma",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Jahandung",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Gerantung",
                "kodepos": "79181"
            },
            {
                "kecamatan": "Monterado",
                "kelurahan": "Beringin Baru",
                "kodepos": "79181"
            }
        ],
        "k148": [
            {
                "kecamatan": "Singkawang Timur",
                "kelurahan": "Sanggau Kolor",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Singkawang Timur",
                "kelurahan": "Nyarungkop",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Singkawang Timur",
                "kelurahan": "Pajintan",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Singkawang Timur",
                "kelurahan": "Mayasopa",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Singkawang Timur",
                "kelurahan": "Bagak Sahwa",
                "kodepos": "79251"
            },
            {
                "kecamatan": "Singkawang Selatan",
                "kelurahan": "Sijangkung",
                "kodepos": "79163"
            },
            {
                "kecamatan": "Singkawang Selatan",
                "kelurahan": "Sagatani",
                "kodepos": "79163"
            },
            {
                "kecamatan": "Singkawang Selatan",
                "kelurahan": "Sedau",
                "kodepos": "79163"
            },
            {
                "kecamatan": "Singkawang Selatan",
                "kelurahan": "Pangmilang",
                "kodepos": "79163"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Setapuk Kecil",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Semelagi Kecil",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Setapuk Besar",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Sei/Sungai Garam Hilir",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Sei/Sungai Rasau",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Sei/Sungai Bulan",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Utara",
                "kelurahan": "Naram",
                "kodepos": "79151"
            },
            {
                "kecamatan": "Singkawang Barat",
                "kelurahan": "Kuala",
                "kodepos": "79124"
            },
            {
                "kecamatan": "Singkawang Barat",
                "kelurahan": "Melayu",
                "kodepos": "79122"
            },
            {
                "kecamatan": "Singkawang Barat",
                "kelurahan": "Pasiran",
                "kodepos": "79123"
            },
            {
                "kecamatan": "Singkawang Barat",
                "kelurahan": "Tengah",
                "kodepos": "79121"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Bukit Batu",
                "kodepos": "79114"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Bukit Batu",
                "kodepos": "79114"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Sei/Sungai Wie",
                "kodepos": "79115"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Sekip Lama",
                "kodepos": "79113"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Condong",
                "kodepos": "79111"
            },
            {
                "kecamatan": "Singkawang Tengah",
                "kelurahan": "Roban",
                "kodepos": "79112"
            }
        ],
        "k149": [
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Sengkubang",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Sei/Sungai Bakau Kecil",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Pasir",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Penibungan",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Sejegi",
                "kodepos": "78918"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Parit Banjar",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Malikian",
                "kodepos": "78919"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Antibar",
                "kodepos": "78917"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Pulau Pedalaman",
                "kodepos": "78917"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Pasir Wan Salim",
                "kodepos": "78915"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Pasir Palembang",
                "kodepos": "78916"
            },
            {
                "kecamatan": "Mempawah Timur",
                "kelurahan": "Pasir Panjang",
                "kodepos": "78915"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Tanjung",
                "kodepos": "78913"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Kuala Secapah",
                "kodepos": "78914"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Tengah",
                "kodepos": "78911"
            },
            {
                "kecamatan": "Mempawah Hilir",
                "kelurahan": "Terusan",
                "kodepos": "78912"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Semparong Parit Raden",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Semudun",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Kunyit Laut",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Limau",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Kunyit Hulu",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Duri II (Dua)",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Kunyit Dalam",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Dungun",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Duri I (Satu)",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Mendalok",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Sei/Sungai Bundung Laut",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Sei/Sungai Kunyit",
                "kelurahan": "Bukit Batu",
                "kodepos": "78371"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Terap",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Toho Ilir",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Suak Berangan/Barangan",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Sembora/Samboro",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Sepang",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Pentek",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Sekabuk",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Pak Laheng",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Pakutan",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Kecurit",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Bumbun/Bunbun",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Toho",
                "kelurahan": "Benuang",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Ansiap",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sadaniang",
                "kelurahan": "Amawang/Amawaking",
                "kodepos": "78361"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Purun Kecil",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Rasau",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Pinyuh",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Batang",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Bakau Besar Laut",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Sei/Sungai Bakau Besar Darat",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Anjongan",
                "kelurahan": "Pak Bulu",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Peniraman",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Nusapati",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Anjongan",
                "kelurahan": "Kepayang",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Sei/Sungai Pinyuh",
                "kelurahan": "Jalan Galang",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Anjongan",
                "kelurahan": "Dema",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Anjongan",
                "kelurahan": "Anjungan Dalam",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Anjongan",
                "kelurahan": "Anjungan Melancar",
                "kodepos": "78353"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Wajo Hulu",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Wajo Hilir",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Sei/Sungai Burung",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Sei/Sungai Nipah",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Purun Besar (Sungai Purun Besar)",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Peniti Dalam II (Dua)",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Peniti Luar",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Peniti Besar",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Peniti Dalam I (Satu)",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Segedong",
                "kelurahan": "Parit Bugis",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Jungkat",
                "kodepos": "78351"
            },
            {
                "kecamatan": "Pontianak Utara",
                "kelurahan": "Batu Layang",
                "kodepos": "78244"
            },
            {
                "kecamatan": "Pontianak Utara",
                "kelurahan": "Siantan Tengah",
                "kodepos": "78242"
            },
            {
                "kecamatan": "Pontianak Utara",
                "kelurahan": "Siantan Hilir",
                "kodepos": "78243"
            },
            {
                "kecamatan": "Pontianak Utara",
                "kelurahan": "Siantan Hulu",
                "kodepos": "78241"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Tanjung Hilir",
                "kodepos": "78236"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Tambelan Sampit",
                "kodepos": "78234"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Dalam Bugis",
                "kodepos": "78235"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Parit Mayor",
                "kodepos": "78231"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Banjar Serasan",
                "kodepos": "78233"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Saigon",
                "kodepos": "78132"
            },
            {
                "kecamatan": "Pontianak Timur",
                "kelurahan": "Tanjung Hulu",
                "kodepos": "78137"
            },
            {
                "kecamatan": "Pontianak Tenggara",
                "kelurahan": "Bansir Laut",
                "kodepos": "78124"
            },
            {
                "kecamatan": "Pontianak Tenggara",
                "kelurahan": "Bangka Belitung Darat",
                "kodepos": "78124"
            },
            {
                "kecamatan": "Pontianak Tenggara",
                "kelurahan": "Bangka Belitung Laut",
                "kodepos": "78124"
            },
            {
                "kecamatan": "Pontianak Tenggara",
                "kelurahan": "Bansir Darat",
                "kodepos": "78124"
            },
            {
                "kecamatan": "Pontianak Selatan",
                "kelurahan": "Benua Melayu Laut",
                "kodepos": "78123"
            },
            {
                "kecamatan": "Pontianak Selatan",
                "kelurahan": "Benua Melayu Darat",
                "kodepos": "78122"
            },
            {
                "kecamatan": "Pontianak Selatan",
                "kelurahan": "Kota Baru",
                "kodepos": "78121"
            },
            {
                "kecamatan": "Pontianak Selatan",
                "kelurahan": "Parit Tokaya",
                "kodepos": "78121"
            },
            {
                "kecamatan": "Pontianak Selatan",
                "kelurahan": "Akcaya",
                "kodepos": "78121"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Darat Sekip",
                "kodepos": "78117"
            },
            {
                "kecamatan": "Pontianak Barat",
                "kelurahan": "Sei/Sungai Beliung",
                "kodepos": "78117"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Sei/Sungai Bangkong",
                "kodepos": "78116"
            },
            {
                "kecamatan": "Pontianak Barat",
                "kelurahan": "Sei/Sungai Jawi Dalam",
                "kodepos": "78115"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Sei/Sungai Bangkong",
                "kodepos": "78116"
            },
            {
                "kecamatan": "Pontianak Barat",
                "kelurahan": "Sei/Sungai Jawi Luar",
                "kodepos": "78113"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Mariana",
                "kodepos": "78112"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Sei/Sungai Jawi",
                "kodepos": "78113"
            },
            {
                "kecamatan": "Pontianak Kota",
                "kelurahan": "Tengah",
                "kodepos": "78111"
            }
        ],
        "k150": [
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Tebing Beseri",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Teluk Runjai",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Semantun",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Sidahari",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Tanggerang",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Rangga Intan",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Riam Danau Kanan",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Penyarang",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Periangan",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Pangkaln Suka",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Pasir Mayang",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Kusik Batu Lapu",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Limpang",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Kesuma Jaya",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Karang Dangin",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Biku Sarana",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Deranuk",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Air Dua",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Jelai Hulu",
                "kelurahan": "Asam Jelai",
                "kodepos": "78876"
            },
            {
                "kecamatan": "Pemahan",
                "kelurahan": "Usaha Baru",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Titi Baru",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Tumbang Titi",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Suka Mulia/Mulya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Tanjung Beulang",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Tanjung Maloi",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Serengkah Kanan",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Suka Damai",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Sepauhan Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Serengkah",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Sengkaharak",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Pemahan",
                "kelurahan": "Semayok Baru",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Sei/Sungai Melayu Baru",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Sei/Sungai Melayu Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Sei/Sungai Melayu",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Segar Wangi",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Petebang Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Piansak",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Pemuatan Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Pengatapan Raya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Pemahan",
                "kelurahan": "Pebihingan",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Nanga Kelampai",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Natai Panjang",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Pemahan",
                "kelurahan": "Muara Gerunggang",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Mahawa",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Makmur Abadi",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Mekar Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Pemahan",
                "kelurahan": "Lalang Panjang",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Karya Mukti",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Kepuluk",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Jelayan",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Jungkal",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Kalimas Baru",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Beringin Rayo",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Jairan Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Belaban Tujuh",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Sungai Melayu Rayak",
                "kelurahan": "Beringin Jaya",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Batu Tajam",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Aur Gading",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Tumbang Titi",
                "kelurahan": "Batu Beransah",
                "kodepos": "78874"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Simpang Tiga Sembelangaan",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Tajok Kayong",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Sepakat Jaya",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Siantau Raya",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Pateh Benteng",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Sebadak Raya",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Sei/Sungai Kelik",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Pangkalan Suka",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Pangkalan Teluk/Telok",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Mensubang",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Nanga Tayap",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Lembah Hijau II",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Kayong Utara",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Lembah Hijau I",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Cegolak",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Kayong Hulu",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Batumas",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Nanga Tayap",
                "kelurahan": "Betenung",
                "kodepos": "78873"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Teluk Mutiara",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Tanjung Maju",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Teluk Bayur",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Sungai Daka",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Sepotong",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Sinar Kuri",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Sukaramai",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Sempurna",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Randau Limat",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Riam Bunut",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Selangkut Raya",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Lanjut Mekar Sari",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Mekar Harapan",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Harapan Baru",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Kepari",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Banyun Sari",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Sungai Laur",
                "kelurahan": "Bengaras",
                "kodepos": "78872"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Senduruhan",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Sungai Bengaras",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Sandai Kiri",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Sekunkun",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Riam Dadap",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Sandai Kanan",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Randau",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Randau Jungkal",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Penjawaan",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Petai Patah",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Muara Jekak",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Pendamaran Indah",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Merimbang Jaya",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Menyumbung",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Krio Hulu",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Lubuk Kakap",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Kenyabur",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Jago Bersatu",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Cinta Manis",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Demit",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Beginci Darat",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Benua Krio",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Hulu Sungai",
                "kelurahan": "Batu Lapis",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Terusan",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Sandai",
                "kelurahan": "Alam Pakuan",
                "kodepos": "78871"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Sungai Buluh",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Suak Burung",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Suka Ramai",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Sengkuang Merabung",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Silat",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Pelempangan",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Ratu Elok",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Seguling",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Mekar Jaya",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Pakit Selaba",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Lembah Mukti",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Manis Mata",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Kemuning",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Kelampai",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Kelimantan",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Jambi",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Batu Sedau",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Bukit Gajah",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Air Dekakah",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Manis Mata",
                "kelurahan": "Asam Besar",
                "kodepos": "78864"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Sukasari",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Tanah Hitam",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Sukaria",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Sukaraja",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Suka Karya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Sukaharja",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Sukamulya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Runjai Jaya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Sari Bekayas",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Rangkong",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Riam Batu Gadig",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Planjau Jaya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Randai",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Pantai Ketikal",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Muntai",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Mekar Jaya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Membuluh Baru",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Karya Baru",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Harapan Baru",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Gahang",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Singkup",
                "kelurahan": "Bukit Kelambing",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Batu Payung",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Belaban",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Banda Sari",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Marau",
                "kelurahan": "Bantan Sari",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Air Durian Jaya",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Air Upas",
                "kelurahan": "Air Upas",
                "kodepos": "78863"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Sungai Jelayan",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Suka Damai",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Suka Harapan",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Seriam",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Selimatan Jaya",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Pangkalan Batu",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Pembedilan",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Natai Kuini",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Kendawangan Kiri",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Keramat Jaya",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Mekar Utama",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Kendawangan Kanan",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Banjar Sari",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Danau Buntar",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Kedondong",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Air Tarap",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Bangkal Serai",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Air Hitam Besar",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Kendawangan",
                "kelurahan": "Air Hitam Hulu",
                "kodepos": "78862"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Semandang Kiri",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Dua",
                "kelurahan": "Semandang Kanan",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Sekucing Labai",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Semandang Hulu",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Paoh Concong",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Sekucing Kualan",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Legong",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Merawa",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Dua",
                "kelurahan": "Mekar Raya",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Kualan Tengah",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Labai Hilir",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Kualan Hilir",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Kualan Hulu",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Dua",
                "kelurahan": "Gema",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Dua",
                "kelurahan": "Kampar Sebomban",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Kenanga",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Dua",
                "kelurahan": "Batu Daya",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Balai Pinang Hulu",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Simpang Hulu",
                "kelurahan": "Balai Pinang",
                "kodepos": "78854"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Suka Baru",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Tuan Tuan",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Sei/Sungai Pelang",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Sei/Sungai Nanjung",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Sei/Sungai Kinjil",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Sei/Sungai Besar",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Sei/Sungai Jawi",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Pesaguan Kanan",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Pesaguan Kiri",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Pematang Gadung",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Padang",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Pagar Mentimun",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Mulia Kerta",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Negeri Baru",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Kinjil Pesisir",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Mekar Sari",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Kemuning Biutak",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Matan Hilir Selatan",
                "kelurahan": "Harapan Baru",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Baru",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Banjar",
                "kodepos": "78822"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Ulak Medang",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Benua Kayong",
                "kelurahan": "Kauman",
                "kodepos": "78821"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Tanjung Pura",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Tempurukan",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Matan Hilir Utara",
                "kelurahan": "Tanjung Baik Budi",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Tanjung Pasar",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Sukaharja",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Suka Maju",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Suka Bangun",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Suka Bangun Dalam",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Matan Hilir Utara",
                "kelurahan": "Sei/Sungai Putri",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Sei/Sungai Awan Kiri",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Sei/Sungai Awan Kanan",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Sampit",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Mulia Baru",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Payak Kumang (Paya Kumang)",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Matan Hilir Utara",
                "kelurahan": "Laman Satong",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Muara Pawan",
                "kelurahan": "Mayak",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Matan Hilir Utara",
                "kelurahan": "Kuala Satong",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Matan Hilir Utara",
                "kelurahan": "Kuala Tolak",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Kali Nilam",
                "kodepos": "78813"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Kantor",
                "kodepos": "78811"
            },
            {
                "kecamatan": "Delta Pawan",
                "kelurahan": "Tengah",
                "kodepos": "78812"
            }
        ],
        "k151": [
            {
                "kecamatan": "Seponti",
                "kelurahan": "Wonorejo",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Seponti",
                "kelurahan": "Seponti Jaya",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Seponti",
                "kelurahan": "Telaga Arum",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Seponti",
                "kelurahan": "Podo Rukun",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Seponti",
                "kelurahan": "Sei/Sungai Sepeti",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Seponti",
                "kelurahan": "Durian Sebatang",
                "kodepos": "78857"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Teluk Batang Selatan",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Teluk Batang Utara",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Teluk Batang",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Sei/Sungai Paduan",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Banyu Abang",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Mas Bangun",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Pulau Maya (Pulau Maya Karimata)",
                "kelurahan": "Tanjung Satai",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Teluk Batang",
                "kelurahan": "Alur Bandung",
                "kodepos": "78856"
            },
            {
                "kecamatan": "Kepulauan Karimata",
                "kelurahan": "Pelapis",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Pulau Maya (Pulau Maya Karimata)",
                "kelurahan": "Satai Lestari",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Pulau Maya (Pulau Maya Karimata)",
                "kelurahan": "Kemboja (Kamboja)",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Kepulauan Karimata",
                "kelurahan": "Padang",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Pulau Maya (Pulau Maya Karimata)",
                "kelurahan": "Dusun Besar",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Pulau Maya (Pulau Maya Karimata)",
                "kelurahan": "Dusun Kecil",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Kepulauan Karimata",
                "kelurahan": "Betok Jaya",
                "kodepos": "78855"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Teluk Melano",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Rantau Panjang",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Sei/Sungai Mata Mata",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Pemangkat",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Penjalaan Hilir",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Pulau Kumbang",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Medan Jaya",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Nipah Kuning",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Padu Banjar",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Lubuk Batu",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Matan Jaya",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Simpang Hilir",
                "kelurahan": "Batu Barat",
                "kodepos": "78853"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sejahtera",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Simpang Tiga",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sutera (Sutra)",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Riam Berasap Jaya",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sedahan Jaya",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Pampang Harapan",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Pangkalan Buton",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Gunung Sembilan",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Harapan Mulia",
                "kodepos": "78852"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Benawai Agung",
                "kodepos": "78852"
            }
        ],
        "k152": [
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Tanjung Kapuas",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Nanga Suhaid",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Menapar",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Mensusai",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Madang Permai",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Mantan",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Laut Tawang",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Lubuk Pengail",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Jongkong Hulu",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Suhaid",
                "kelurahan": "Kerengas",
                "kodepos": "78775"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Selangkai",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Selimu",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Nanga Ngeri",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Perjuk",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Riam Tapang",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Nanga Luan",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Nanga Lungu",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Lebak Najah",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Nanga Dangkan I",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Entebi",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Landau Badai",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Landau Rantau",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Belimbing",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hulu",
                "kelurahan": "Dangkan Kota",
                "kodepos": "78774"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Setunggul",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Sei/Sungai Sena",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Sentabai",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Rumbih",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Seberu",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Penai",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Perigi",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Nanga Nuar",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Pangeran",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Bukit Penai",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Miau Merah",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Bongkong",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Silat Hilir",
                "kelurahan": "Baru",
                "kodepos": "78773"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Tajau Mada",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Tanjung Keliling",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Seneban",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Sejiram",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Pala Kota",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Ranyai",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Nanga Lot",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Nanga Pala",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Jerenjang",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Gurung",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Beluis Harum",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Emperiang",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Bekuan",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Belikai",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Seberuang",
                "kelurahan": "Bati",
                "kodepos": "78772"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Tua Abang",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Semitau Hilir",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Semitau Hulu",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Sekedau",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Padung Kumang",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Nanga Seberuang",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Nanga Kenepai",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Nanga Lemedak",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Marsedan Raya",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Kenepai Komplek",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Kenerak",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Semitau",
                "kelurahan": "Entipan",
                "kodepos": "78771"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Merakai Panjang",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Sei/Sungai Antu",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Sei/Sungai Asin",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Kantuk Bunut",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Langau",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Puring Kencana",
                "kelurahan": "Kantuk Asam",
                "kodepos": "78769"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Nanga Kantuk",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Tintin Peninjau",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Kumang Jaya",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Laja Sandang",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Bajau Andai",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Empanang",
                "kelurahan": "Keling Panggau",
                "kodepos": "78768"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Tajum",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Tinting Seligi",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Semuntik",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Seriang",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Pulau Majang",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Sebindang",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Janting",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Kekurak",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Badau",
                "kelurahan": "Badau",
                "kodepos": "78767"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Setulang",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Sungai Senunuk",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Sei/Sungai Ajung",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Sepandan",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Melemba",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Mensiau",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Sei/Sungai Abau",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Labian Ira&#8217;ang",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Lanjak Deras",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Vega",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Batang Lupar",
                "kelurahan": "Labian",
                "kodepos": "78766"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Tempurau",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Titian Kuala",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Sekubah",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Sekulat",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Semalah",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Nibung",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Piasak Hilir",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Piasak Hulu",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Mawan",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Nanga Leboyan",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Gerayau",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Gudang Hilir",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Gudang Hulu",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Dalam",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Engkerengas",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Selimbau",
                "kelurahan": "Benuis",
                "kodepos": "78765"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Tani Makmur",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Tunas Muda",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Parang",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Sejahtera Mandiri",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Simpang Senara",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Nanga Tepuai",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Nanga Yen",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Mubung",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Lubuk Antuk",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Mentawit",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Kelakar",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Landau Kumpang",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Bugang",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Karya Mandiri",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Hulu Gurung",
                "kelurahan": "Beringin",
                "kodepos": "78764"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Temenang",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Ujung Jambu",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Ujung Said",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Nanga Serian",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Penepian Raya",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Kandung Suli",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Karya Baru",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Pasar (Jengkong Pasar)",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Tanjung",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Kiri Hulu",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Kiri Tengah",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Bontai",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Kanan",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Jongkong (Jengkong)",
                "kelurahan": "Jongkong Kiri Hilir",
                "kodepos": "78763"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Temuyuk",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Selaup",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Semangut Utara",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Sungai Besar",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Riam Piyang",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Segitak",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Nanga Suruk",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Pantas Bersatu",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Nanga Semangut",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Nanga Kelibang",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Nanga Payang",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Bunut Hulu",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Nanga Dua",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Batu Tiga",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Beringin",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Ujung Pandang",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hulu",
                "kelurahan": "Bakong Permai",
                "kodepos": "78762"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Teluk Aur",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Tembang",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Nanga Tuan",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Empangau Hilir",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Entibab",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Kapuas Raya",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Empangau",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Bunut Tengah",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Sira Jaya",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Bunut Hilir",
                "kelurahan": "Bunut Hilir",
                "kodepos": "78761"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Riam Panjang",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Sasan",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Permata Perda",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Pinang Laka",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Mawan",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Pengkadan Hilir",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Hulu Pengkadan",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Kerangan Panjang",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Martadana",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Pengkadan (Batu Datu)",
                "kelurahan": "Buak Limbang",
                "kodepos": "78759"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Teluk Geruguk",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Tubang Jaya",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Sri Wangi",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Sangan",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Pemawan",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Riam Mengelai",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Jemah",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Ret",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Boyan",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Danau",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Mujan",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Nanga Betung",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Karya Maju",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Landau Mentail",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Delintas Karya",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Boyan Tanjung",
                "kelurahan": "Boyan Tanjung",
                "kodepos": "78758"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Tanjung Intan",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Tekalong",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Tangai Jaya",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Tanjung",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Nanga Mentebah",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Suka Maju",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Menaren",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Tekudak",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Mentebah",
                "kelurahan": "Kepala Gurung",
                "kodepos": "78757"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Segiam",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Semerantau",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Tapang Daan",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Rantau Kalis",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Ribang Kadeng",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Tubuk",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Peniung",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Rantau Bumbun",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Raun",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Sebintang",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Danau",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Kalis",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Nanga Lebangan",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Kalis Raya",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Kensuray",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Ulak Pauk",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Kalis",
                "kelurahan": "Bahenap",
                "kodepos": "78756"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Saujung Giling Manik",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Tamao",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Menua Sadap",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Pulau Manak",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Rantau Prapat",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Batu Lintang",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Langan Baru",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Banua Martinus",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hulu",
                "kelurahan": "Banua Ujung",
                "kodepos": "78755"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Pala Pintas",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Ujung Bayur",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Nanga Lauk",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Nanga Palin",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Lawik",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Nanga Embaloh",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Keliling Semulung",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Kirin Nangka",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Embaloh Hilir",
                "kelurahan": "Belatung",
                "kodepos": "78754"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Penyeluang",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Teluk Sindur",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Melapi Manday",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Nanga Manday",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Jongkong Manday",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Jelemuk",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Bika",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Bika",
                "kelurahan": "Bika Hulu",
                "kodepos": "78753"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Sei/Sungai Uluk",
                "kodepos": "78752"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Jaras",
                "kodepos": "78752"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Tanjung Lasa",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Tanjung Beruang",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Tanjung Karang",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Sibau Hulu",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Sungai Uluk Palin",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Seluan",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Sibau Hilir",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Putussibau Kota",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Pala Pulau",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Nanga Sambus",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Padua Mendalam",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Nanga Awin",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Nanga Nyabau",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Jangkang",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Lauk",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Banua Tanga",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Datah Dian",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Hilir Kantor",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Utara",
                "kelurahan": "Ariung Mendalam",
                "kodepos": "78716"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Urang Unsa",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Kedamin Hulu",
                "kodepos": "78715"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Tanjung Jati",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Tanjunglokang",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Melapi",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Sayut",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Suka Maju",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Kedamin Hilir",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Kereho Semula",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Ingko Tambe",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Kedamin Darat",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Bungan Jaya",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Cempaka Baru",
                "kodepos": "78714"
            },
            {
                "kecamatan": "Putussibau Selatan",
                "kelurahan": "Beringin Jaya",
                "kodepos": "78714"
            }
        ],
        "k153": [
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tonak Goneh",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Topan Nanga",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tanjung Miru",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tapang Manua",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tanjung Lalau",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Riam Panjang",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Tanah Merah",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nangkak Lestari",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Riam Muntik",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Toran",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Ungai",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Tebidah",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Tonggoi",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Payak",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Masau",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Oran",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Abai",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nanga Laar",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Mapan Jaya",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Merah Arai",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Merahau Permai",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Landau Bara",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Lintang Tambuk",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Empoyang",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Kerapuk Jaya",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Empoyang",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Empakan",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Tuguk",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Buluh Merindu",
                "kodepos": "78694"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Tertung Mau",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Sungai Menuang",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Sungai Sintang",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Sei/Sungai Buaya",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Sungai Garong",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Paoh Desa",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Pelaik",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Natai Tebedak",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Nyangkom",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Pakak",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Natai Lesung",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Nanga Tikan",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Nanga Mau",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Melingkat",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Mentunai",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Mekar Mandiri",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Landau Beringin",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Linggam Permai",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Kerapa Sepan",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Lalang Inggar",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Jaya Sakti",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Karya Baru",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Jambu",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Engkerangan",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Umin Jaya",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Bukit Segaloh",
                "kodepos": "78693"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Sungai Tapang",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Tauk",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Riguk",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Sei/Sungai Mali",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Pengkadan Sungai Rupa",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Penyak Lalang",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Nanga Dedai",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Nanga Jetak",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Pengkadan Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Menaong Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Merempit Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Lundang Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Mangat Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Ganis",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Kumpang",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Empaci",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Emparu Baru",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Baras",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Dedai",
                "kelurahan": "Dedai Kanan",
                "kodepos": "78691"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Tanjung Andan",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Menantak",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Sakai",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Sake",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Kesange",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Menakon",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Kemangai",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Keremoi",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Kepala Jungai",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Nanga Ambalau",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Buntut Purun",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Buntut Sabon",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Tunas Harapan",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Buntut Pimpin",
                "kodepos": "78684"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Teluk Harapan",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Tontang",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Tanjung Raya",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Sawang Senghiang",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Tahai Permai",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Pagar Lebata",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Rantau Malam",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Serawai",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Tekungai",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Mentatai",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Riyoi",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Segulang",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Bihe",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Nanga Jelundung",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Mentajoi",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Merako Jaya",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Gurung Senghiang",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Karya Jaya",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Begori",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Buntut Ponte",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Baras Nabun",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Serawai (Nanga Serawai)",
                "kelurahan": "Bedaha",
                "kodepos": "78683"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Telaga Dua",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Telaga Satu",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Simba Raya",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Sungai Risap Mensiku Bersatu",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Mensiku",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Sei/Sungai Risap",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Binjai Hulu",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Dak Jaya",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Empaka Kabiau Raya",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Ampar Bedang",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Binjai Hulu",
                "kelurahan": "Binjai Hilir",
                "kodepos": "78663"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Temiang Kapuas",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Temawang Bulai",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Temawang Muntai",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Tanjung Hulu",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Tanjung Ria",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Tawang Sari",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sungai Segak",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Tanjung Balai",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sukau Bersatu",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sungai Raya",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sirang Setambang",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sepulut",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sinar Pekayau",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sekubang",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Sekujam Timbai",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Semuntai",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Paoh Benua",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Peninsung",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Nanga Pari",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Nanga Sepauk",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Mait Hilir",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Manis Raya",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Nanga Libau",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Landau Panjang",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Lengkenat",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Kemantan",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Kenyauk",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Ensabang",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Gernis Jaya",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Bernayau",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Buluh Kuning",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Bedayan",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Sepauk",
                "kelurahan": "Bangun",
                "kodepos": "78662"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Tanjung Perada",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Tempunak Kapuas",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Tinum Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Sungai Buluh",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Riam Batu",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Suka Jaya",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Repak Sari",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Pulau Jaya",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Pangkal Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Paribang Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Pudau Bersatu",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Pagal Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Merti Jaya",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Nanga Tempunak",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Mensiap Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Mengkurat Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Kupan Jaya",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Kenyabur Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Kuala Tiga",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Jaya Mentari",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Benua Kencana",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Gurung Mali",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Benua Baru",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Tempunak",
                "kelurahan": "Balai Harapan",
                "kodepos": "78661"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Sungai Labi",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Sungai Lais",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Sepan Lebang",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Sei/Sungai Maram",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Sei/Sungai Pukat",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Pelimping",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Nanga Lebang",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Merpak",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Landau Kodam",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Mandiri Jaya",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Kebong",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Gemba Raya",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Karya Jaya Bhakti",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Bengkuang",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Ensaid Panjang",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Kelam Permai",
                "kelurahan": "Baning Panjang",
                "kodepos": "78656"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Sei/Sungai Ukoi",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Riam Kijang",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Sarai",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Ransi Dakan",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Rarai",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Penjernang Hulu",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Perembang",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Penjernang",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Nobal",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Merarai Dua",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Merarai Satu",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Lebak Ubah",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Manter",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Melayang Sari",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Kajang Baru",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Laman Natai",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Gurung Kempadik",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Bonet Engkabang",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Bonet Lama",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Bancoh",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Sungai Tebelian",
                "kelurahan": "Baya (Baya Betong)",
                "kodepos": "78655"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sungai Pisau",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Suak Medang",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sungai Bugau",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Senaning",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sepiluk",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sebetung Paluk",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sei/Sungai Seria",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sekaih",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Rasau",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Sebadak",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Nanga Bugau",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Nanga Sebawang",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Muakan Petinggi",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Nanga Bayan",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Empunak Tapang Keladan",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Empura",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Jasa",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Hulu",
                "kelurahan": "Bekuan Luyang",
                "kodepos": "78654"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Terta Karya",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Wana Bhakti",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Wirayuda",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Tanjung Sari",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Swadaya",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Senangan Kecil",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Sumber Sari",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Sungai Areh",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Panggi Agung",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Mungguk Lawang",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Nanga Kelapan",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Panding Jaya",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Marga Hayu",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Mungguk Gelombang",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Kayu Dujung",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Kerta Sari",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Landau Buaya",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Begelang Jaya",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Gut Jaya Bakti",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Tanjung Baung",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Tengah",
                "kelurahan": "Argo Mulyo",
                "kodepos": "78653"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Senibung",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Setungkup",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Sei/Sungai Mali",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Semajau Mekar",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Semuntai",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Nanga Merkak",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Nanga Sejirak",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Sei/Sungai Deras",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Bukit Sidin Permai",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Kenuak",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Nanga Ketungau",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Beluh Mulyo",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Betung Permai",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Baung Sengatap",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Batu Ampar",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Sungai Ana",
                "kodepos": "78619"
            },
            {
                "kecamatan": "Ketungau Hilir",
                "kelurahan": "Air Nyuruk",
                "kodepos": "78652"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Lalang Baru",
                "kodepos": "78617"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Merti Guna",
                "kodepos": "78618"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Tertong",
                "kodepos": "78616"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Anggah Jaya",
                "kodepos": "78617"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Mungguk Bantok",
                "kodepos": "78616"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Teluk Kelansam",
                "kodepos": "78616"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Kapuas Kiri Hilir",
                "kodepos": "78615"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Kapuas Kiri Hulu",
                "kodepos": "78616"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Kapuas Kanan Hulu",
                "kodepos": "78614"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Ladang",
                "kodepos": "78612"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Kapuas Kanan Hilir",
                "kodepos": "78613"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Tanjung Puri",
                "kodepos": "78611"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Baning Kota",
                "kodepos": "78612"
            },
            {
                "kecamatan": "Sintang",
                "kelurahan": "Tanjung Kelansam",
                "kodepos": "78611"
            }
        ],
        "k154": [
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Tanggung",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Terati",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Semirau",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Semombat",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Pisang",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Sape",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Selampung",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Ketori",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Empiyang",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Jangkang Benua",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Jangkang",
                "kelurahan": "Balai Sebut",
                "kodepos": "78591"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Trimulya",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Semuntai",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Serambai Jaya",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Sei/Sungai Mawang",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Semanggis Raya",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Kedukul",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Layak Omang",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Engkode",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Mukok",
                "kelurahan": "Inggis",
                "kodepos": "78581"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Sansat",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Teraju (Teraju Barat)",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Lumut",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Belungai Dalam",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Kampung Baru",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Bagan Asam",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Toba",
                "kelurahan": "Balai Belungai",
                "kodepos": "78572"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Sei/Sungai Kembayau",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Sei/Sungai Mayan",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Pampang Dua",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Meranggau",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Mukti Jaya",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Melobok",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Meliau Hulu",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Melawi Makmur",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Meliau Hilir",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Kunyil",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Lalang",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Kuala Buayan",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Kuala Rosan",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Enggadai",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Harapan Makmur",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Baru Lombak",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Cupang",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Bakti Jaya",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Meliau",
                "kelurahan": "Balai Tinggi",
                "kodepos": "78571"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Tebang Benua",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Tanjung Bunut",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Subah",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Sei/Sungai Jaman",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Sejotang",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Pulau Tayan Utara",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Sebemban",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Melugai",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Pedalaman",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Kawat",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Lalang",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Cempedak",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Emberas",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Balai Ingin",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Tayan Hilir",
                "kelurahan": "Beginjan",
                "kodepos": "78564"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Tae",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Temiang Mali",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Temiang Taba",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Semoncol",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Senyabang",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Kawing (Mak Kawing)",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Kebadu",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Padi Kaye",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Empirang Ujung",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Hilir",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Bulu Bala",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Balai",
                "kelurahan": "Cowet (Cuwet)",
                "kodepos": "78563"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Sosok",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Riyai",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Pandan Sembuat",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Peruan Dalam",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Mandong",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Menyabo",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Kedakas",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Engkasan",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Janjang",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Berakak",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Tayan Hulu",
                "kelurahan": "Binjai",
                "kodepos": "78562"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Suka Mulya",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Sebara",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Suka Grundi",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Pusat Damai",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Rahayu",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Palem Jaya",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Pandu Raya",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Maju Karya",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Marigin Jaya",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Marita",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Gunam",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Hibun",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Dosan",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Parindu",
                "kelurahan": "Embala",
                "kodepos": "78561"
            },
            {
                "kecamatan": "Entikong",
                "kelurahan": "Suruh Tembawang",
                "kodepos": "78557"
            },
            {
                "kecamatan": "Entikong",
                "kelurahan": "Pala Asang (Palapasang)",
                "kodepos": "78557"
            },
            {
                "kecamatan": "Entikong",
                "kelurahan": "Semangit (Semanget)",
                "kodepos": "78557"
            },
            {
                "kecamatan": "Entikong",
                "kelurahan": "Entikong",
                "kodepos": "78557"
            },
            {
                "kecamatan": "Entikong",
                "kelurahan": "Nekan",
                "kodepos": "78557"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Sotok",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Sei/Sungai Tekam",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Pengadang",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Raut Muara",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Lubuk Sabuk",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Malenggang",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Engkahan",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Kenaman",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Balai Karangan",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Sekayam",
                "kelurahan": "Bungkang",
                "kodepos": "78556"
            },
            {
                "kecamatan": "Beduai (Beduwai)",
                "kelurahan": "Sei/Sungai Ilai",
                "kodepos": "78555"
            },
            {
                "kecamatan": "Beduai (Beduwai)",
                "kelurahan": "Thang Raya",
                "kodepos": "78555"
            },
            {
                "kecamatan": "Beduai (Beduwai)",
                "kelurahan": "Kasromego",
                "kodepos": "78555"
            },
            {
                "kecamatan": "Beduai (Beduwai)",
                "kelurahan": "Mawang Muda",
                "kodepos": "78555"
            },
            {
                "kecamatan": "Noyan",
                "kelurahan": "Semongan",
                "kodepos": "78554"
            },
            {
                "kecamatan": "Beduai (Beduwai)",
                "kelurahan": "Bereng Berkawat",
                "kodepos": "78555"
            },
            {
                "kecamatan": "Noyan",
                "kelurahan": "Noyan",
                "kodepos": "78554"
            },
            {
                "kecamatan": "Noyan",
                "kelurahan": "Sei/Sungai Dangin",
                "kodepos": "78554"
            },
            {
                "kecamatan": "Noyan",
                "kelurahan": "Empoto",
                "kodepos": "78554"
            },
            {
                "kecamatan": "Noyan",
                "kelurahan": "Idas",
                "kodepos": "78554"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Tanjung Merpati",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Tunggal Bhakti",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Semayang",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Tanap",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Sebuduh",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Sejuah",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Kuala Dua",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Mobui",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Sebongkuh",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Upe",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Kembayan",
                "kelurahan": "Kelompu",
                "kodepos": "78553"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Majel",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Sami",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Tunggul Boyok",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Empodis",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Kampuh",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Bonti",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Bahta",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Bonti",
                "kelurahan": "Bantai",
                "kodepos": "78552"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Tanjung Kapuas",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Tapang Dulang",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Semerangkai",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Sei/Sungai Batu",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Sei/Sungai Mawang",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Sei/Sungai Muntik",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Rambin",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Sei/Sungai Alai",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Penyelimau Hilir",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Penyelimau Jaya",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Pana",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Penyeladi",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Mengkiang",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Nanga Biang",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Lintang Kapuas",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Lintang Pelaman",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Entakai",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Kambong",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Lape",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Belangin",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Botuh Lintang",
                "kodepos": "78516"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Sei/Sungai Sengkuang",
                "kodepos": "78514"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Tanjung Sekayam",
                "kodepos": "78515"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Ilir Kota",
                "kodepos": "78513"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Bunut",
                "kodepos": "78511"
            },
            {
                "kecamatan": "Kapuas (Sanggau Kapuas)",
                "kelurahan": "Beringin",
                "kodepos": "78512"
            }
        ],
        "k155": [
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Sei/Sungai Ambawang Kuala",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Simpang Kanan",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Teluk Bakung",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Pasak Piang",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Puguk",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Panca Roba",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Pasak",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Lingga",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Mega Timur",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Jawa Tengah",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Korek",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Bengkarek",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Sei/Sungai Ambawang",
                "kelurahan": "Durian (Sungai Durian)",
                "kodepos": "78393"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Terentang Hulu",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Teluk Bayur",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Teluk Empening",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Terentang Hilir",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Sei/Sungai Radak I",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Sei/Sungai Radak II",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Sei/Sungai Dungun",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Permata",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Teluk Kapuas",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Terentang",
                "kelurahan": "Betuah",
                "kodepos": "78392"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Sungai Raya Dalam",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Tebang Kacang",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Sei/Sungai Bulan",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Sei/Sungai Ambangah",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Sei/Sungai Asam",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Pulau Jambu",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Pulau Limbung",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Parit Dalam",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Mekar Sari",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Muara Baru",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Madu Sari",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Mekar Baru",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Kuala Dua",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Limbung",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Gunung Tamang",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Kali Bandung",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Arang Limbung",
                "kodepos": "78391"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Teluk Pakedai Dua",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tasik Malaya",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Teluk Nibung",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sei/Sungai Jawi",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sei/Sungai Kerawang",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sumber Agung",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sei/Sungai Besar",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Padang Tikar II",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Nipah Panjang",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Padang Tikar I",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Batu Ampar",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Muara Tiga",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Teluk Nangka",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Ambarawa",
                "kodepos": "78385"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sepakat Baru",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Seruat Dua",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Seruat Tiga",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sei/Sungai Selamat",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sei/Sungai Terus",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Pinang Dalam",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Pinang Luar",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Olak Olak Kubu",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Pelita Jaya",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Mengkalang",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Jangkang Satu",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Mengkalang Jambu",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Kubu",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Jangkang Dua",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Bemban (Sungai Bemban)",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Dabong (Dabung)",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Ambawang",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Baru",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Air Putih",
                "kodepos": "78384"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Teluk Pakedai I (Satu)",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Teluk Pakedai II (Dua)",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Teluk Gelam",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Teluk Pakedai Hulu",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Selat Remis",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Seruat I",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Sei/Sungai Nibung",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Sei/Sungai Nipah",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Sei/Sungai Deras",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Pasir Putih",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Kuala Karang",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Madura",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Teluk/Telok Pakedai",
                "kelurahan": "Arus Deras",
                "kodepos": "78383"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Rasau Jaya III (Tiga)",
                "kodepos": "78382"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Rasau Jaya Umum",
                "kodepos": "78382"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Pematang Tujuh",
                "kodepos": "78382"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Rasau Jaya II (Dua)",
                "kodepos": "78382"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sepuk Laut",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Tanjung Saleh",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Bintang Mas",
                "kodepos": "78382"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sei/Sungai Kupah",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sei/Sungai Rengas",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sei/Sungai Itik",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sei/Sungai Kakap",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Rasau Jaya",
                "kelurahan": "Rasau Jaya I (Satu)",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Sei/Sungai Belidak",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Punggur Kapuas",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Punggur Kecil",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Punggur Besar",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Pal Sembilan",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Jeruju Besar",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Sei/Sungai Kakap",
                "kelurahan": "Kalimas",
                "kodepos": "78381"
            },
            {
                "kecamatan": "Kuala Mandor-B",
                "kelurahan": "Retok",
                "kodepos": "78355"
            },
            {
                "kecamatan": "Kuala Mandor-B",
                "kelurahan": "Sei/Sungai Enau",
                "kodepos": "78355"
            },
            {
                "kecamatan": "Kuala Mandor-B",
                "kelurahan": "Kubu Padi",
                "kodepos": "78355"
            },
            {
                "kecamatan": "Kuala Mandor-B",
                "kelurahan": "Kuala Mandor A",
                "kodepos": "78355"
            },
            {
                "kecamatan": "Kuala Mandor-B",
                "kelurahan": "Kuala Mandor-B",
                "kodepos": "78355"
            },
            {
                "kecamatan": "Sei/Sungai Raya",
                "kelurahan": "Kapur",
                "kodepos": "78234"
            }
        ],
        "k156": [
            {
                "kecamatan": "Satui",
                "kelurahan": "Tegal Sari",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Wonorejo",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sumber Makmur",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Sumber Baru",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sumber Arum",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sinar Bulan",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sei/Sungai Danau",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sekapuk",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Setarap",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Satui Timur",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Sei/Sungai Cuka",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Pandamaran Jaya",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Satui Barat",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Purwodadi",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Mulya Makmur",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Mekar Jaya",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Karang Indah",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Makmur",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Jombang",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Bunati",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Bukit Baru",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Banjar Sari",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Bayan Sari",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Angsana",
                "kelurahan": "Angsana",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Satui",
                "kelurahan": "Al Kausar",
                "kodepos": "72275"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Tri Mulya",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Wanasari",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sumber Sari",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Tri Martani",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sei/Sungai Loban",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sumber Makmur",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sebamban Lama",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sei/Sungai Dua Laut",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sari Utama",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sebamban Baru",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Marga Mulya",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Sari Mulya",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Kerta Buana",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Karang Intan",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Damar Indah",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Dwi Marga Utama",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Indra Loka Jaya",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Batu Meranti",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Sungai Loban",
                "kelurahan": "Biduri Bersujud",
                "kodepos": "72274"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Wiritasi",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Serdangan",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Tanette",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Sepunggur",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Sei/Sungai Lembu",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Saring Sei/Sungai Binjai",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Saring Sei/Sungai Bubu",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Satiung",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Rantau Panjang Hulu",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Salimuran",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pulau Tanjung",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Rantau Panjang Hilir",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pulau Salak",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pulau Satu",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pejala",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Penyolongan",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pasar Baru",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pakatellu",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Mudalang",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Pagar Ruyung",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Muara Pagatan",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Muara Pagatan Tengah",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Manurung",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Mekar Jaya",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Karya Bhakti",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Kota Pagatan",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Kampung Baru",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Juku Eja",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Gusungnge",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Danau Indah",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Betung",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Batuah",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Beringin",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Baru Gelang",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Batarang",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hilir",
                "kelurahan": "Api-Api",
                "kodepos": "72273"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Wonorejo",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Waringin Tunggal",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Tibarau Panjang",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Teluk Kepayang",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Sei/Sungai Rukam",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Tamunih",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Tapus",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Ringkit",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Pacakan",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Mustika",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Manuntung",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Mangkalapi",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Kuranji",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Lasung",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Karang Sari Indah",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Karang Mulya",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Harapan Jaya",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Hati If",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Giri Mulya",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Guntung",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Darasan Binjai (Derasan Binjai)",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Dadap Kusan Raya",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Binawara",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Bakarangan",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Batu Bulan",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Batulicin",
                "kodepos": "72271"
            },
            {
                "kecamatan": "Kusan Hulu",
                "kelurahan": "Anjir Baru",
                "kodepos": "72272"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Polewali Marajae",
                "kodepos": "72218"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Sukamaju",
                "kodepos": "72217"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Kersik Putih",
                "kodepos": "72215"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Maju Bersama",
                "kodepos": "72216"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Tungkaran Pangeran",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Kusambi",
                "kodepos": "72214"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Pulau Panjang",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sejahtera",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Gunung Antasari",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Gunung Besar",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Batu Ampar",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Bersujud",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Kampung Baru",
                "kodepos": "72212"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Baroqah",
                "kodepos": "72213"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Sumber Wangi",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Sukadamai",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Sepakat",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Sido Mulyo",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Sela Selilau",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei/Sungai Dua",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Segumbang",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sari Gadung",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Sari Mulya/Mulia",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Rejosari",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Batulicin",
                "kelurahan": "Pondok Butun",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Rejo Winangun",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Pematang Ulin",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Pandan Sari",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Mentawakan Mulya/Mulia",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Manunggal",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Mekar Sari",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Maju Mulyo",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Mantewe",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Madu Retno",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Karang Rejo",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Harapan Maju",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Karang Bintang",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Gunung Hatalau Meratus Raya",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Gunung Raya",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Dukuh Rejo",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Emil Baru",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Mantewe",
                "kelurahan": "Bulu Rejo",
                "kodepos": "72211"
            },
            {
                "kecamatan": "Karang Bintang",
                "kelurahan": "Batulicin Irigasi",
                "kodepos": "72211"
            }
        ],
        "k157": [
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Teluk Sungai",
                "kodepos": "72181"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Tengah",
                "kodepos": "72181"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Labuan Barat",
                "kodepos": "72181"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Maradapan",
                "kodepos": "72181"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Tanjung Nyiur",
                "kodepos": "72181"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Wonorejo",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Tamiyang",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Barat",
                "kelurahan": "Sengayam",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Sekayu Baru",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Pamukan Indah",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Mulyo Harjo",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Barat",
                "kelurahan": "Marga Jaya",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Barat",
                "kelurahan": "Mayang Sari",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Lintang Jaya",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Barat",
                "kelurahan": "Mangka",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Harapan Baru",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Kalian",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Betung",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Binturung",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Barat",
                "kelurahan": "Batuah",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Bepara",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Balamea",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Utara",
                "kelurahan": "Bakau",
                "kodepos": "72169"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Tanjung Samalantakan",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Sesulung",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Sukadana",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Talusi",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Sakalimau",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Sekandis",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Rampacengal",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Sakadoyan",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Mulyodadi",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Pondok Labu",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Pamukan Selatan",
                "kelurahan": "Gunung Calang",
                "kodepos": "72168"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Terombong Sari",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Rantau Buda",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Rantau Jaya",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Manunggul Baru",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Manunggul Lama",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Magalau Hilir",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Magalau Hulu",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Buluh Kuning",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sungai Durian",
                "kelurahan": "Gendang Timburu",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Sukamaju",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Sepapah",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Sampanahan Hilir",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Sei/Sungai Betung",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Sampanahan",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Papaan",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Rampa Manunggul",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Basuang",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Gunung Batu Besar",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Sampanahan",
                "kelurahan": "Banjar Sari",
                "kodepos": "72166"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Sulangkit",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Wilas",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Sei/Sungai Hanyar",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Sei/Sungai Seluang",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Pudi",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Pudi Seberang",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Utara",
                "kelurahan": "Mangga",
                "kodepos": "72165"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Tanjung Selayar",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Tanjung Sari",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Tanah Rata",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Tanjung Batu",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Tamiang Bakung",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Si Ayuh",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Sembilang",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Senakin",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Senakin Seberang",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Sei/Sungai Punggawa",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Sebuli",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Geronggang",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Tengah",
                "kelurahan": "Sang-Sang",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Bungkukan",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Peramasan 2X 9 (Sanga/Sembilan)",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Kelumpang Barat",
                "kelurahan": "Batang Kulur",
                "kodepos": "72164"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Limbur",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Muara Ore",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Lalapin",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Limbungan",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Hampang",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Hulu Sampanahan",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Cantung Kiri Hulu",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Hampang",
                "kelurahan": "Cantung Kanan",
                "kodepos": "72163"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Sido Mulyo",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Mangkirana",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Sei/Sungai Kupang",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Laburan",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Karang Liwar",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Karang Payau",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Banua Lawas",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Cantung Kiri Hilir",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Bangkalan/Bangkalaan Dayak",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hulu",
                "kelurahan": "Bangkalan/Bangkalaan Melayu",
                "kodepos": "72162"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Telaga Sari",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Tegal Rejo",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Tarjun",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Tanjung Pangga",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Serongga",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Suka Maju",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Sei/Sungai Nipah",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Sangking Baru",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Sei/Sungai Kupang Jaya",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Sahapi",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Pulau Panci",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Pembelancanan",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Pelaju Baru",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Pantai Baru",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Pantai",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Mandala",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Kelumpang Hilir",
                "kelurahan": "Langadai",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Sungup Kanan",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Kelumpang Selatan",
                "kelurahan": "Bumi Asih",
                "kodepos": "72161"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Semisir",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Selaru",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Salino (Maniang)",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Sei/Sungai Pasir",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Pantai Baru",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Laut Tengah",
                "kelurahan": "Mekar Pura",
                "kodepos": "72156"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Sekapung",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Ujung",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Sarakaman",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Sei/Sungai Bali",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Mandin",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Rampa",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Balambus (Selambus)",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Sebuku",
                "kelurahan": "Kanibungan",
                "kodepos": "72155"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Teluk Sirih",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Teluk Aru",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Teluk Kemuning",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Tanjung Serudung",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Tanjung Seloka",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Tanjung Seloka Utara",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Tanjung Lalak Selatan",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Tanjung Lalak Utara",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Sei/Sungai Bahim",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Sei/Sungai Bulan",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Pulau Kerumputan",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Pulau Kerasian",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Pulau Kerayaan",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Labuan Mas",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Oka-Oka",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Kepulauan",
                "kelurahan": "Kerayaan Utara (Rosong Raya)",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Selatan",
                "kelurahan": "Alle Alle",
                "kodepos": "72154"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Tepian Balai",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Terangkih / Terangkeh",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Teluk Tamiang / Tamiyang",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Tata Mekar",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Tanjung Sungkai",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Tanjung Tengah",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Tanjung Kunyit",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Tanjung Pelayar",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Subur Makmur (Sepagar I)",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Sumber Sari (Sepagar II)",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Semaras",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Sepagar",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Lontar Timur",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Lontar Utara",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Sebanti",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Lontar Selatan",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Kampung Baru",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Barat",
                "kelurahan": "Gemuruh",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Gosong Panjang",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Bangun Rejo",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Tanjung Selayar",
                "kelurahan": "Bandar Raya",
                "kodepos": "72153"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Teluk Gosong",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Sejakah",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Tanjung Pengharapan",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Sei/Sungai Limau",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Kulipak",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Langkang Baru",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Langkang Lama",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Betung",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Karang Sari Indah",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Bekambit Asri",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Berangas",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Bekambit",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Timur",
                "kelurahan": "Batu Tunau",
                "kodepos": "72152"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Semayap",
                "kodepos": "72117"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Baharu Selatan",
                "kodepos": "72115"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Dirgahayu",
                "kodepos": "72116"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Sei/Sungai Taib",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Stagen",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Sarang Tiung",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Sebelimbingan",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Mega Sari",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Rampa",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Gunung Ulin",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Kotabaru Hulu",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Gunung Sari",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Gedambaan",
                "kodepos": "72114"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Kotabaru Tengah",
                "kodepos": "72113"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Sebatung",
                "kodepos": "72113"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Tirawan",
                "kodepos": "72112"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Batuah",
                "kodepos": "72112"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Sigam",
                "kodepos": "72112"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Kotabaru Hilir",
                "kodepos": "72111"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Baharu Utara",
                "kodepos": "72112"
            },
            {
                "kecamatan": "Pulau Laut Utara",
                "kelurahan": "Hilir Muara",
                "kodepos": "72111"
            }
        ],
        "k158": [
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Sei/Sungai Durian",
                "kodepos": "72167"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Pangelak",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Masingai II",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Kinarum",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Masingai I",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Kaong",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Upau",
                "kelurahan": "Bilas",
                "kodepos": "71575"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Solan",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Teratau",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Purui",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Nalui",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Namun",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Muang",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Jaro",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Lano",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Uwie",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Jaro",
                "kelurahan": "Garagata",
                "kodepos": "71574"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Sei/Sungai Kumap",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Simpung Layung",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Salikung",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Santuun",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Pelapi",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Ribang",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Pasar Batu",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Muara Uya",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Mangkupum",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Kupang Nunding",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Lumbang",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Kampung Baru",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Muara Uya",
                "kelurahan": "Binjai",
                "kodepos": "71573"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Wirang",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Waling",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Usih",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Suriyan",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Seradang",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Suput",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Panaan",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Nawin",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Mahe Pasar",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Marindi",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Kembangkuning",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Lok Batu",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Hegar Manah",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Hayup",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Halong",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Dambung (Raya)",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Catur Karya",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Burum",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Haruai",
                "kelurahan": "Bongkang",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Bumi Makmur",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Bintang Ara",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Sulingan",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Bintang Ara",
                "kelurahan": "Argo Mulyo",
                "kodepos": "71572"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Masukau",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Pembataan",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Maburai",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Mabuun",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Kasiau Raya",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Kasiau",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Belimbing Raya",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Kapar",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Warukin",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Murung Pudak",
                "kelurahan": "Belimbing",
                "kodepos": "71571"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Tanta Hulu",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Walangkir",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Pulau KuU",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Tamiyang",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Tanta",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Padangin",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Pamarangan Kanan",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Puain Kanan",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Murung Baru",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Padang Panjang",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Luk Bayur",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Mangkusip",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Tantaringin",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Tanta",
                "kelurahan": "Barimbun",
                "kodepos": "71561"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Mantuil",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Murung Karangan",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Padangin",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Manduin",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Madang",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Tamunti",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Muara Harus",
                "kelurahan": "Harus",
                "kodepos": "71555"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Sei/Sungai Rukam I",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Sei/Sungai Rukam II",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Pampanan",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Pugaan",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Jirak",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Talan",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Pugaan",
                "kelurahan": "Halangan",
                "kodepos": "71554"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Purai",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Sei/Sungai Anyar",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Hapalah",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Hariang",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Pematang",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Habau",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Habau Hulu",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Benua Rantau",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Bungin",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Banua Lawas",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Batang Banyu",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Bangkiling",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Banua Lawas",
                "kelurahan": "Bangkiling Raya",
                "kodepos": "71553"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Telagaitar",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Pulau",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Sei/Sungai Buluh",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Takulat",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Pasar Panas",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Pudak Stegal",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Masintan",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Paliat",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Karangan Putih",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Ampukung",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Bahungin",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Kelua (Klua)",
                "kelurahan": "Binturu",
                "kodepos": "71552"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Wayau",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Pamarangan Kiwa",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Puain Kiwa",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sei/Sungai Pimping",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kitang",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Mahe Seberang",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kambitin",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Kambitin Raya",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Hikun",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Juai",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Banyu Tajun",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Garunggung",
                "kodepos": "71515"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tanjung",
                "kodepos": "71513"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Agung",
                "kodepos": "71514"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Jangkung",
                "kodepos": "71512"
            }
        ],
        "k159": [
            {
                "kecamatan": "Halong",
                "kelurahan": "Uren",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Tabuan",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Suryatama",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Puyun",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Sumber Agung",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Mauya",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Padang Raya",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Mamigang",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Mantuyan",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Marajai",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Liyu",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Mamantang",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Karya",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Halong",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Kapul",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Gunung Riut",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Ha Uwai",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Binuang Santang",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Buntut Pilanduk",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Binjai Punggal",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Binju",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Bangkal",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Baruh Panyambaran",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Halong",
                "kelurahan": "Aniungan",
                "kodepos": "71666"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Tigarun",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Wonorejo",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Tawahan",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Teluk Bayur",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Sirap",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Panimbaan",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Sei/Sungai Batung",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Muara Ninian",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Mungkur Uyam",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Pamurus",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Marias",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Mihu",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Lalayau",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Juai",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Hukai",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Gulinggang",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Hamarung",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Buntu Karau",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Galumbang",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Tundi",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Juai",
                "kelurahan": "Bata",
                "kodepos": "71665"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Tangalin",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Simpang Nadung/Nadong",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sungsum",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Sikontan",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Simpang Bumbuan",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Putat Basiun",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Sei/Sungai Pumpung",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Pulantan",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Piyait",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Pudak",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Nungka",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Pematang",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Merah",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Mayanau",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Muara Jaya",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kambiyain",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Kedondong",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Langkap",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Gunung Batu",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Ju Uh",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Bihara Hilir",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Dayak Pitap",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Bihara",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Baramban",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Baru",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Awayan Hilir",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Badalungga",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Badalungga Hilir",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Awayan",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Auh",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Awayan",
                "kelurahan": "Ambakiang (Ambakiyang)",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Ajung",
                "kodepos": "71664"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Timbun Tulang",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Tariwin",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Pelajau",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Munjung",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Riwa",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Lok Batu",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Mampari",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Karuh",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Mantimin",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Kasai",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Gunung Manau",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Hamparaya",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Bungur",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Guha",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Banua Hanyar",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Batu Mandi",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Batu Mandi",
                "kelurahan": "Bakung",
                "kodepos": "71663"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Tarangan",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Telaga Purun",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Paringin Timur",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Sungai Ketapi",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Paran",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Murung Abuin",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Paringin Kota",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Maradap",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Murung Jambu",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Lok Batung",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Panggung",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Lingsir",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Murung Ilung",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Lamida Bawah",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Lasung Batu",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Kalahiang",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Inan",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Hujan Mas",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Halubau Utara",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Halubau",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Galumbang (Galombang)",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Dahai",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Binjai",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Baruh Bahinu Luar",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Baruh Bahinu Dalam",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Balida",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Balang",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Babayau",
                "kodepos": "71662"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Teluk Karya",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Teluk Karya",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Tampang",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Tanah Habang Kanan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Simpang Tiga",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Sei/Sungai Tabuk",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Pupuyuan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Sei/Sungai Awang",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Pimping",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Mundar",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Panaitan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Matang Hanau",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Matang Lurus",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lok Hamawang",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lok Panginangan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lampihong Kiri",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lampihong Selatan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lampihong Kanan",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Kusambi Hulu",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Lajar",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Kusambi Hilir",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Kandang Jaya",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Kupang",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Jimamun",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Jungkal",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Batu Merah",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Lampihong",
                "kelurahan": "Hilir Pasar",
                "kodepos": "71661"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Batu Piring",
                "kodepos": "71618"
            },
            {
                "kecamatan": "Paringin Selatan",
                "kelurahan": "Bungin",
                "kodepos": "71617"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Layap",
                "kodepos": "71616"
            },
            {
                "kecamatan": "Paringin",
                "kelurahan": "Mangkayahu",
                "kodepos": "71614"
            }
        ],
        "k160": [
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Tuhuran",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Waringin",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Teluk Daun",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Teluk Haur",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Tayur",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Telaga Bamban",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Tambak Sari Panji",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Tangkawang",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Tabalong Mati",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Tabing Lering",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Sei/Sungai Turak",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Sei/Sungai Turak Dalam",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Sei/Sungai Limas",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Pulantani/Polantani",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Sei/Sungai Binuang",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Pimping",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Pihaung",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Panyaungan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Pandawan (Pandawanan)",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Panawakan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Panangkalaan Hulu",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Panangkalaan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Palimbangan Sari",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Pamintangan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Panangian",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Palimbangan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Palimbangan Gusti",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Padang Luar",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Pakacangan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Pakapuran",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Padang Basar Hilir",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Padang Basar Hulu",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Muara Baruh",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Murung Karangan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Loksoga/Lok Suga",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Kamayahan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Kuangan",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Keramat/Karamat",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Haur Gading",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Jingah Bujur",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Cakru",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Guntung",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Haur Gading",
                "kelurahan": "Bayur",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Teluk Sinar",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Amuntai Utara",
                "kelurahan": "Air Tawar",
                "kodepos": "71471"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Teluk Cati",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Tatah Laban",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Teluk Betung",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Tambalang Tengah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Tapus Dalam",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Tambalang Kecil",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Tambalang Raya",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Tambalang",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Sei/Sungai Tabukan",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Pandan Tengah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Sandung",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Pandan Hilir",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Pandan Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Sei/Sungai Kuini",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Sei/Sungai Haji",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Rantau Karau Tengah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Rantau Karau Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Rantau Karau Raya",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Rantau Bujur Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Rantau Bujur Tengah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Rantau Karau Hilir",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Rantau Bujur Darat",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Rantau Bujur Hilir",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Pematang Benteng Hilir",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Pondok Babaris",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Putat Atas /Tatau",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Pasar Sabtu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Pematang Benteng",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Pandulangan",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Pangkalan Sari",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Nelayan",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Murung Asam",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Padang Bangkal",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Hilir Mesjid",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Jalan Lurus",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Hambuku Raya",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Hambuku Tengah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Gampa Raya",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Hambuku Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Hambuku Pasar",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Galagah",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Galagah Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Banyu Tajun Hulu",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Banyu Tajun Pangkalan",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Banyu Tajun Hilir",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Pandan",
                "kelurahan": "Banyu Tajun Dalam",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Sungai Tabukan",
                "kelurahan": "Banua Hanyar",
                "kodepos": "71455"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Zam Zam",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Teluk Limbung",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Luang Hulu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Nyiur",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Papuyu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Durait Tengah",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Luang Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Durait Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Durait Hulu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Parupukan",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Sei/Sungai Dalam",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Pajukungan Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Pajukungan Hulu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Murung Panti Hulu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Kalumpang Luar",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Murung Kupang",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Murung Panti Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Hambuku Lima",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Kalumpang Dalam",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Hambuku Baru",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Hambuku Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Babirik Hilir",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Babirik",
                "kelurahan": "Babirik Hulu",
                "kodepos": "71454"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Telaga Mas",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Tampakang",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Sei/Sungai Namang",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Sei/Sungai Panangah",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Sarang Burung",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Sapala",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Pandamaan",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Pararain",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Rintisan",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Paminggir",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Paminggir Seberang",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Pal Batu",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Palukahan",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Manarap Hulu",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Long Kong",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Manarap",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Danau Panggang",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Darussalam",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Baru",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Danau Panggang",
                "kelurahan": "Bitin",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Ambahai",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Paminggir",
                "kelurahan": "Bararawa",
                "kodepos": "71453"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Ujung Murung",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Teluk Sari",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Teluk Baru",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Teluk Paring",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Telaga Sari",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Telaga Silaba",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Simpang Tiga",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Telaga Hanyar",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Rukam Hulu",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Simpang Empat",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Panyiuran",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Pulau Tambak",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Rukam Hilir",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Padang Darat",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Padang Tanggul",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Murung Panggang",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Murung Sari",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Kutai Kecil",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Mamar",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Kayakah",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Keramat",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Kota Raja",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Jumba",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Ilir Mesjid",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Jarang Kuantan",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Cempaka",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Harusan Telaga",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Bajawit",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Banyu Hirang",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Selatan",
                "kelurahan": "Cangkering",
                "kodepos": "71452"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Tigarun",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Sei/Sungai Karias",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Tapus",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Rantawan",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Sei/Sungai Baring",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Pinang Kara",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Pinang Habang",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Palampitan Hilir",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Palampitan Hulu",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Pasar Senin",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Mawar Sari",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Muara Tapus",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Kota Raden Hilir",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Kota Raden Hulu",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Kembang Kuning",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Kebun Sari",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Harus",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Harusan",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Kandang Halang",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Danau Caramin",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Datu Kuning",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Sei/Sungai Malang",
                "kodepos": "71418"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Antasari",
                "kodepos": "71419"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Tangga Ulin Hulu",
                "kodepos": "71417"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Paliwara",
                "kodepos": "71418"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Teluk Buluh",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Teluk Sarikat",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Tangga Ulin Hilir",
                "kodepos": "71417"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Sei/Sungai Bahadangan",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Rantau Bujur",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Pulau Damar",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Pandulangan",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Patarikan",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Pawalutan",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Murung Padang",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Palanjungan Sari",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Karias Dalam",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Lok Bangkai",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Kaludan Besar",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Kaludan Kecil",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Danau Terate/Terati",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Garunggang",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Kalintamui",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Baruh Tabing",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Beringin/Baringin",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Banjang",
                "kelurahan": "Banjang",
                "kodepos": "71416"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Tambalangan",
                "kodepos": "71413"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Murung Sari",
                "kodepos": "71414"
            },
            {
                "kecamatan": "Amuntai Tengah",
                "kelurahan": "Hulu Pasar",
                "kodepos": "71412"
            }
        ],
        "k161": [
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Telang",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Tapuk",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Sumanggi",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Sumanggi Semerang / Seberang",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Pauh",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Maringgit",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Muara Rintis",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Labunganak",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Limpasu",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Karau",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Kabang",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Karatungan",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Ilung Pasar Lama",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Ilung Tengah",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Ilung",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Hawang",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Haur Gading",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Dangu",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Hapingin",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Awang",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Utara",
                "kelurahan": "Awang Baru",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Abung",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Limpasu",
                "kelurahan": "Abung Surapati",
                "kodepos": "71391"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Tandilang",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Nateh",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Pembakulan",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Hinas Kiri",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Juhu",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Muara Hungi",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Batu Tangga",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Datar Batung",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Batu Perahu",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Atiran",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Timur",
                "kelurahan": "Aing Bantai",
                "kodepos": "71382"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Wawai Gardu",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Tembuk Bahalang",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Wawai",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Rangas",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Tanah Habang",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Lunjuk",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Mahela",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Paya",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Limbar",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Lok Besar",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Kias",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Labuhan",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Cukan Lipai",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Kapar",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Birayang Surapati",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Birayang Timur",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Banua Rantau",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Birayang",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Tilahan",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Batang Alai Selatan",
                "kelurahan": "Anduhum",
                "kodepos": "71381"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Pasting",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Patikalain",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Hinas Kanan",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Kindingan",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Murung B",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Hantakan",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Haruyan Dayak",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Bulayak",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Datar Ajab",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Batu Tunggal",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Hantakan",
                "kelurahan": "Alat",
                "kodepos": "71372"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Pagat",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Pantai Batung",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Paya Besar",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Kali Baru",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Layuh",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Murung A",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Haliau",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Kahakan",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Bakti",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Baru",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Aluan Sumur",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Aluan",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Aluan Besar",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Batu Benawa",
                "kelurahan": "Aluan Mati",
                "kodepos": "71371"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Tabat Padang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Pengambau Hulu",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Sei/Sungai Harang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Pangambau Hilir Luar",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Panggung",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Pengambau Hilir Dalam",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Mangunang Seberang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Pandanu",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Lokbuntar",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Mangunang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Haruyan",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Haruyan Seberang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Batu Panggung",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Hapulang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Andang",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Haruyan",
                "kelurahan": "Barikin",
                "kodepos": "71363"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Tabat",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Tungkup",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Sei/Sungai Buluh",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Rantau Bujur",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Pemangkih Seberang",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Ranatu Kaminting (Rantau Keminting)",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Pahalatan",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Parumahan (Perumahan)",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Pemangkih",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Kupang Samhurang",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Mantaas",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Binjai Pirua",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Kadundung",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Kasarangan",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Banua Kupang",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Utara",
                "kelurahan": "Binjai Pemangkih",
                "kodepos": "71362"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Tabudarat Hulu",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Taras Padang",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Taal",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Tabudarat Hilir",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Sei/Sungai Jaranih",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Sei/Sungai Rangas",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Pantai Hambawang Barat",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Pantai Hambawang Timur",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Murung Taal",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Panggang Marak",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Mahang Baru",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Mundar",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Guha",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Jamil",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Durian Gantang",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Batang Bahalang",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Benua Kepayang",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Walatung",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Labuan Amas Selatan",
                "kelurahan": "Bangkal",
                "kodepos": "71361"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Pandawan",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Setiap",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Matang Ginalun",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Palajau",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Mahang Sei/Sungai Hanyar",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Masiraan",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Mahang Putat",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Kayu Rabah",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Mahang Matang Landung",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Kambat Selatan",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Kambat Utara",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Hulu Rasau",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Jaranih",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Jatuh",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Buluan",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Hilir Banua",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Banua Batung",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Banua Hanyar",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Banua Supanggal",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Pandawan",
                "kelurahan": "Banua Asam",
                "kodepos": "71352"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Mandingin",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Pajukungan",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Bukat",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Gambah",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Kayu Bawang",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Barabai Darat",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Benawa Tengah",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Banua Budi",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Banua Jingah",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Banua Binjai",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Babai",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Bakapas",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Ayuang",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Barabai Barat",
                "kodepos": "71314"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Awang Besar",
                "kodepos": "71315"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Barabai Timur",
                "kodepos": "71312"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Barabai Utara",
                "kodepos": "71313"
            },
            {
                "kecamatan": "Barabai",
                "kelurahan": "Barabai Selatan",
                "kodepos": "71311"
            }
        ],
        "k162": [
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Pakuan Timur",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Pandulangan",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Telaga Langsat",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Longawang",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Mandala",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Hamak Timur",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Hamak Utara",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Lok Binuang",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Gumbil",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Hamak",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Telaga Sili Sili",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Telaga Langsat",
                "kelurahan": "Ambutun",
                "kodepos": "71292"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Taniran Selatan",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Tawia",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Taniran Kubah",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Kayu Abang",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Bamban Selatan",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Bamban Utara",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Angkinang Selatan",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Bakarung",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Bamban",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Angkinang",
                "kelurahan": "Angkinang",
                "kodepos": "71291"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Tumingki",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Ulang (Muara Ulang)",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Lumpangi",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Malinau",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Panggungan",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Loksado",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Kamawakan",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Lok Lahung",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Hulu Banyu",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Haratai",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Loksado",
                "kelurahan": "Halunuk",
                "kodepos": "71282"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Tabihi",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Padang Batung",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Pahampangan",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Pandulangan",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Malutu",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Mawangi",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Malilingin",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Karang Jawa Muka",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Madang",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Kaliring",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Karang Jawa",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Jalatang",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Jambu Hulu",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Jembatan Merah",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Batu Laki",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Durian Rabung",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Telaga Bidadari",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Padang Batung",
                "kelurahan": "Batu Bini",
                "kodepos": "71281"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Tamiyang",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Tanah Bangkang",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Raya Selatan",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Raya Utara",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sei/Sungai Kali",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Karasikan",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Paring Agung",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sarang Halang",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Hariti",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Ida Manggala",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Bumi Barkat",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Hamalau",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Batang Kulur Kanan",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Batang Kulur Kiri",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Batang Kulur Tengah",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Asam",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Baru",
                "kodepos": "71271"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Tambingkar",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Sirih",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Sirih Hulu",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Kalumpang (Kelumpang)",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Kalumpang (Kelumpang)",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Karang Bulan",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Balanti",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Balimau",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Wasah Tengah",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Kalumpang (Kelumpang)",
                "kelurahan": "Bago Tanggul",
                "kodepos": "71262"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Wasah Hilir",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Wasah Hulu",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Ulin",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Simpur",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Kapuh",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Panjampang Bahagia",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Pantai Ulin",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Amparaya",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Simpur",
                "kelurahan": "Garunggang",
                "kodepos": "71261"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Tambak Bitin",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Teluk Labak",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Sei/Sungai Mandala",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Taluk Haur",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Pasungkan",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Sei/Sungai Garuda",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Paramaian",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Pandak Daun",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Panggandingan",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Pakan Dalam",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Pakapuran Kecil",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Mandala Murung Mesjid",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Murung Raya",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Paharangan",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Hakurung",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Hamayung",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Hamayung Utara",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Baruh Kembang",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Utara",
                "kelurahan": "Belah Paikat",
                "kodepos": "71253"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Tumbukan Banyu",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Tambangan",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Tanjung Selor",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Siang Gantung",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Parigi",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Pihanin Raya",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Samuda",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Muning Tengah",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Pandan Sari",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Habirau Tengah",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Muning Baru",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Muning Dalam",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Bayanan",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Habirau",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Baruh Jaya",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Baru",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Banjar Baru",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Selatan",
                "kelurahan": "Banua Hanyar",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Bajayau Tengah",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Badaun",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Bajayau",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Daha Barat",
                "kelurahan": "Bajayau Lama",
                "kodepos": "71252"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Sei/Sungai Kupang",
                "kodepos": "71217"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Baluti",
                "kodepos": "71217"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Bangkau",
                "kodepos": "71217"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Gambah Luar Muka",
                "kodepos": "71216"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Gambah Dalam Barat",
                "kodepos": "71215"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Gambah Luar",
                "kodepos": "71216"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Tibung Raya",
                "kodepos": "71214"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Gambah Dalam",
                "kodepos": "71215"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Jambu Hilir",
                "kodepos": "71214"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kandangan Utara",
                "kodepos": "71214"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Lungau",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Sei/Sungai Paring",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Bariang",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kandangan Barat",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Amawang Kiri",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Amawang Kiri Muka",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Amawang Kanan",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Kandangan",
                "kelurahan": "Kandangan Kota",
                "kodepos": "71211"
            }
        ],
        "k163": [
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Sei/Sungai Paring",
                "kodepos": "71213"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Sumber Harapan",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Sumber Baru",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Peramasan",
                "kelurahan": "Remo",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Rantau Nangka",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Peramasan",
                "kelurahan": "Peramasan Bawah",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Rantau Bakula",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Peramasan",
                "kelurahan": "Peramasan Atas",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Kupang Rejo",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Pakutik",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Belimbing Lama",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Hakim Makmur",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Kahelaan",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sei/Sungai Pinang",
                "kelurahan": "Belimbing Baru",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Peramasan",
                "kelurahan": "Angkipih",
                "kodepos": "70675"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Sei/Sungai Lurus",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Pengaron",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Pasar Baru",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Mangkauk",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Maniapun",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Panyiuran",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Madurejo",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Lumpangi",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Loktunggul",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Kertak Empat",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Lobang Baru",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Gunung Batu",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Benteng",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Batang Banyu",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Batu Tanam",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Sambung Makmur",
                "kelurahan": "Baliangin",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Ati Im",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Alimukim",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Pengaron",
                "kelurahan": "Antaraku",
                "kodepos": "70674"
            },
            {
                "kecamatan": "Telaga Bauntung",
                "kelurahan": "Telaga Baru",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Tanah Intan",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sungkai Baru",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Surian Hanyar",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sumber Sari",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sungkai",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Simpang Lima",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sindang Jaya",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Simpang Empat",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei/Sungai Langsat",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Telaga Bauntung",
                "kelurahan": "Rantau Bujur",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Pasar Lama",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Telaga Bauntung",
                "kelurahan": "Rampah",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Makmur Karya",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Paku",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Paring Tali",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Telaga Bauntung",
                "kelurahan": "Loktanah",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Lawiran",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Lokcantung",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Garis Hanyar",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Karya Makmur",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Keramat Mina",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Cinta Puri",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Benua Anyar",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Berkat Mulya",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Cabi",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Batu Balian",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Alalak Padang",
                "kodepos": "70673"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Tanah Abang",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Simpang Tiga",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Surian",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Takuti",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Pematang Danau",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Sei/Sungai Jati",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Mataraman",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Pasiraman",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Lok Tamu",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Mangka Lawat",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Bawahan Selan",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Gunung Ulin",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Bawahan Pasar",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Bawahan Seberang",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Mataraman",
                "kelurahan": "Baru",
                "kodepos": "70672"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Tiwingan Lama",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Tambangan",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Tiwingan Baru",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Sei/Sungai Tuan Ulu",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Tambak Danau",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Rantau Bujur",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Sei/Sungai Alat",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Sei/Sungai Tuan Ilir",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Rantau Balai",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Pingaran Ilir",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Pingaran Ulu",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Pasar Jati",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Pematang Hambawang",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Paau",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Munggu Raya",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Kelampaian Ilir",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Limamar",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Lokgabang",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Kaliukan",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Kalampayan Ulu",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Kalaan",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Kalampayan",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Danau Salak",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Jati",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Benua Riam",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Bunglai",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Banua Anyar I (Benua Anyar I)",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Banua Anyar II (Benua Anyar II)",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Belangian",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Astambul",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Astambul",
                "kelurahan": "Astambul Seberang",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Artain",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Apuai",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Aranio",
                "kelurahan": "Aranio",
                "kodepos": "70671"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Sei/Sungai Besar",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Sei/Sungai Landas",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Sei/Sungai Arfat",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Sei/Sungai Asam",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Penyambaran",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Pulau Nyiur",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Sei/Sungai Alang",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Pandak Daun",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Pasar Lama",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Padang Panjang",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Mandi Kapau Barat",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Mandi Kapau Timur",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Mandi Angin Barat",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Mandi Angin Timur",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Loktangga",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Mali Mali",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Kiram",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Lihung",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Karang Intan",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Jingah Habang Ilir",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Jingah Habang Ulu",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Balau",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Biih",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Abirau",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Awang Bangkal Barat",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Karang Intan",
                "kelurahan": "Awang Bangkal Timur",
                "kodepos": "70661"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Terapu",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Tambak Padi",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Tanipah",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Simpang Warga",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Simpang Warga Dalam",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Selat Makmur",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Sei/Sungai Musang",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Rumpiang",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Pulantan",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Pemurus",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Pindahan Baru",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Podok",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Lawahan",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Muara Halayung",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Labat Muara",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Kuin Besar",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Kuin Kecil",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Jambu Raya",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Kampung Baru",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Haur Kuning",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Jambu Burung",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Handil Purai",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Handil Baru",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Handil Bujur",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Balimau",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Bunipah",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Bakambat",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Beruntung Baru",
                "kelurahan": "Babirik",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Aluh Aluh Kecil Muara",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Aluh-Aluh Besar",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Aluh-Aluh",
                "kelurahan": "Aluh Aluh Kecil",
                "kodepos": "70655"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Tatah Layap",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Taibah Raya",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Tampang Awang",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Tatah Bangkal",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Simpang Empat",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Pemangkih Darat",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Pemangkih Tengah",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Sei/Sungai Lakum",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Pemangkih Baru",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Pemangkih Baru",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Pasar Kemis",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Mekar Sari",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Pandan Sari",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Mandar Sari",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Mekar Raya",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Manarap Baru",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Manarap Lama",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Manarap Tengah",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Layap Baru",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Jaruju Laut",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Kertak Hanyar I",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Kertakhanyar II",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Jaruju",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Benua Hanyar (Banua Hanyar)",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Kertak Hanyar",
                "kelurahan": "Belayung Baru",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Tatah Makmur",
                "kelurahan": "Bangkal Tengah",
                "kodepos": "70654"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Tajau Landung",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Tabuk Kota",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Tandipah",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Tabuk Keramat",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei/Sungai Tabuk",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Pinang Lama",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Lulut",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Pinang Baru",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Bangkal",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Pembantanan",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Sei/Sungai Bakung",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Pematang Panjang",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Pemakuan",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Paku Alam",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Pejambuan",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Lokbuntar",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Lok Baintan",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Lok Baintan Dalam",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Keliling Benteng Ilir",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Gudang Hirang",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Gudang Tengah",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Sei/Sungai Tabuk",
                "kelurahan": "Abumbun Jaya",
                "kodepos": "70653"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Tambak Sirang Laut",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Tambak Sirang Darat",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Tambak Sirang Baru",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Sei/Sungai Kupang",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Malintang",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Malintang Baru",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Makmur",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Keladan Baru",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Kayu Bawang",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Guntung Papuyu",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Guntung Ujung",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Gambut Barat",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Gambut",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Gambut",
                "kelurahan": "Banyu Hirang",
                "kodepos": "70652"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tambak Baru Ulu",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tungkaran",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tambak Baru",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tambak Baru Ilir",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Sekumpul",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Pesayangan",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Pasayangan Selatan",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Pasayangan Utara",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Labuan Tabu",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Pasayangan Barat",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Bincau Muara",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Indra Sari",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Telok Selong Ulu",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Bincau",
                "kodepos": "70619"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Rangas Ulu",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Tangkas",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Telok Selong",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Rangas Hambuku",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Rangas Tengah",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Rangas",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Batang",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Sei/Sungai Batang Ilir",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Keliling Benteng Ulu",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Penggalaman",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Keliling Benteng Tengah",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Barat",
                "kelurahan": "Antasan Sutun",
                "kodepos": "70618"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Tambak Anyar Ilir",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Tambak Anyar Ulu",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Pematang Baru",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Sei/Sungai Kitano",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Tambak Anyar",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Pekauman Dalam",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Pekauman Ulu",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Pekauman",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Murung Kenanga",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Melayu Tengah",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Melayu Ulu",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Mekar",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Melayu Ilir",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Keramat",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Keramat Baru",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Dalam Pagar Ulu",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Dalam Pagar Ulu",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Antasan Senor",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Antasan Senor Ilir",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Akar Bagantung",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Timur",
                "kelurahan": "Akar Baru",
                "kodepos": "70617"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Murung Keraton",
                "kodepos": "70616"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tunggul Irang Ilir",
                "kodepos": "70615"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tunggul Irang Ulu",
                "kodepos": "70615"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Jawa Laut",
                "kodepos": "70615"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tunggul Irang",
                "kodepos": "70615"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tanjung Rema Darat",
                "kodepos": "70613"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Jawa",
                "kodepos": "70614"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Sei/Sungai Sipai",
                "kodepos": "70612"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Tanjung Rema",
                "kodepos": "70613"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Cindai Alus",
                "kodepos": "70612"
            },
            {
                "kecamatan": "Martapura Kota",
                "kelurahan": "Keraton",
                "kodepos": "70611"
            }
        ],
        "k164": [
            {
                "kecamatan": "Piani",
                "kelurahan": "Miawa",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Pipitak Jaya",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Buni In Jaya",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Harakit",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Batu Ampar",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Batung",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Balawaian",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Piani",
                "kelurahan": "Baramban",
                "kodepos": "71191"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Suato Baru",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Suato Lama",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Pantai Cabe",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Salam Babaris",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Kambang Habang Baru",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Salam Babaris",
                "kelurahan": "Kambang Habang Lama",
                "kodepos": "71185"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Kambang Kuning",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Matang Batas",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Tarungin",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Burakai",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Hatungun",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Batu Hapu",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Bagak",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Tungkap",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Hatungun",
                "kelurahan": "Asam Randah",
                "kodepos": "71184"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Pulau Pinang Utara",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Raya Belanti",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Pualam Sari",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Pulau Pinang",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Mekarsari",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Padang Sari",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Binuang",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Gunung Batu",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Karang Putih",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "A Yani Pura",
                "kodepos": "71183"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Tatakan",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Timbaan",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Tambarangan",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Tandui",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Sawang",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Suato Tatakan",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Lawahan",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Rumintin",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Harapan Masa",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Hatiwin",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Teluk Haur",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Tapin Selatan",
                "kelurahan": "Cempaka",
                "kodepos": "71181"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Sei/Sungai Puting",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Sei/Sungai Salai",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Sei/Sungai Salai Hilir",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Rawana Hulu",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Sawaja",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Margasari Ilir",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Pariok",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Rawana",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Buas Buas Hilir",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Kaladan",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Batalas",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Utara",
                "kelurahan": "Buas Buas",
                "kodepos": "71171"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Pabaungan Pantai",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Sei/Sungai Rutas",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Sei/Sungai Rutas Hulu",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Pabaungan Hilir",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Pabaungan Hulu",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Marampiau Hilir",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Margasari Ulu",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Candi Laras",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Marampiau",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Baringin B",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Baulin",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Candi Laras Selatan",
                "kelurahan": "Baringin A",
                "kodepos": "71162"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Tirik",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Serawi",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Sukaramai",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Pematang Karangan Hulu",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Sei/Sungai Bahalang",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Papagan Makmur",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Pematang Karangan",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Pematang Karangan Hilir",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Pandahan",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Pandulangan",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Mandurian",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Mandurian Hilir",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Labung",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Hiyung",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Kepayang",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Batang Lantik",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Puncak Harapan",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Tapin Tengah",
                "kelurahan": "Andhika",
                "kodepos": "71161"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Lokpaikat",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Parandakan",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Bitahan Baru",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Budi Mulya",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Binderang",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Bitahan",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Ayunan Papan",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Lokpaikat",
                "kelurahan": "Bataratat",
                "kodepos": "71154"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Shabah",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Timbung",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Purut",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Rantau Bujur",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Paring Guling",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Linuh",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Hangui",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Kalumpang",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Bungur",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Bungur Baru",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Banua Padang",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bungur",
                "kelurahan": "Banua Padang Hilir",
                "kodepos": "71153"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Tangkawang Baru",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Waringin",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Tangkawang",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Parigi Kecil",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Paul",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Masta",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Parigi",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Gadung",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Gadung Karamat",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Ketapang",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Bakarangan",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Bakarangan",
                "kelurahan": "Bundung",
                "kodepos": "71152"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Rangda Malingkung",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Lumbu Raya",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Perintis Raya",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Jingah Babaris",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Kakaran",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Keramat",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Banua Hanyar Hulu",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Banua Halat Kiri",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Banua Hanyar",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Badaun",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Banua Halat Kanan",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Antasari",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Antasari Hilir",
                "kodepos": "71114"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Kupang",
                "kodepos": "71113"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Rantau Kanan",
                "kodepos": "71112"
            },
            {
                "kecamatan": "Tapin Utara",
                "kelurahan": "Rantau Kiwa",
                "kodepos": "71111"
            }
        ],
        "k165": [
            {
                "kecamatan": "Kintap",
                "kelurahan": "Sebamban Baru",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Sei/Sungai Cuka",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Sumber Jaya",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Riam Adungan",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Salaman",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Muara Kintap",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Pandansari",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Pasir Putih",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Kintap Kecil",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Kintap Pura (Kintapura)",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Mekar Raya (Mekarsari)",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Kebun Raya",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Kintap",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Kintap",
                "kelurahan": "Bukit Mulia",
                "kodepos": "70883"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tajau Pecah",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Pantai Linuh",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tajau Mulia",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Jilatan",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Jilatan Alur",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Gunung Mas",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Gunung Melati",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Damit",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Damit Hulu",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Durian Bungkuk",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Bluru",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Damar Lima",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Ambawang",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Batu Ampar",
                "kodepos": "70882"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Swarangan",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Simpang Empat Sungaibaru",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Sabuhur",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Karang Rejo",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Muara Asam Asam",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Jorong",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Asam Jaya",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Asri Mulia",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Batalang",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Asam Asam",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Jorong",
                "kelurahan": "Alur",
                "kodepos": "70881"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Panyipatan",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Suka Ramah",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Tanjung Dewa",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Kandangan Lama",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Kuringkit",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Bumi Asih",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Kandangan Baru",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Batakan",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Batu Mulya",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Panyipatan",
                "kelurahan": "Batu Tungku",
                "kodepos": "70871"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Takisung",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Telaga Langsat",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Tabanio",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Sumber Makmur",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Ranggang",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Ranggang Dalam",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Kuala Tambangan",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Pegatan Besar",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Benua Tengah",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Gunung Makmur",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Batilai",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Takisung",
                "kelurahan": "Benua Lawas",
                "kodepos": "70861"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Tambang Ulang",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Pulau Sari",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Sei/Sungai Jelai",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Martadah",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Martadah Baru",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Kayu Abang",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Gunung Raja",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Tambang Ulang",
                "kelurahan": "Kayu Abang",
                "kodepos": "70854"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Tambak Sarinan/Sarinah",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Sei/Sungai Rasau",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Tambak Karya",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Raden",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Sarikandi",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Pantai Harapan",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Maluka Bualin",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Padang Luas",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Kurau",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Kurau Utara",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Handil Negara",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Kali Besar",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Suruk",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Labuan (Amas)",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Maluka",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Gayam",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Birayang Atas",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Birayang Atas",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Bumi Harapan",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bumi Makmur",
                "kelurahan": "Handil Babirik",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Ujung Baru",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Kurau",
                "kelurahan": "Bawah Layung",
                "kodepos": "70853"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Ujung",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Sambangan",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Padang",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Pandahan",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Liang Anggang",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Nusa Indah",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Kait-Kait Baru",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Bentok Darat",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Bentok Kampung",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Kait Kait",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Bati Bati",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Banua Raya (Benua Raya)",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Bati-Bati",
                "kelurahan": "Banyu Irang",
                "kodepos": "70852"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Ujung Batu",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Tungkaran",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Tirta Jaya",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Telaga",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Tebing Siring",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Tanjung",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Sumber Mulia",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Tampang",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Sarang Halang",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Sei/Sungai Riam",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Sei/Sungai Bakar",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Pelaihari",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Pemuda",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Panggung Baru",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Panjaratan",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Pabahanan",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Panggung",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Pamalungan (Pamalongan)",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Kunyit",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Kampung Baru",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Ketapang",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Guntung Besar",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Galam",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Bumi Jaya",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Bajuin",
                "kelurahan": "Bajuin",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Ambungan",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Atu Atu",
                "kodepos": "70815"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Karang Taruna",
                "kodepos": "70812"
            },
            {
                "kecamatan": "Pelaihari",
                "kelurahan": "Angsau",
                "kodepos": "70814"
            }
        ],
        "k166": [
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Cempaka",
                "kodepos": "70733"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Sei/Sungai Tiung",
                "kodepos": "70734"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Bangkal",
                "kodepos": "70732"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Palam",
                "kodepos": "70731"
            },
            {
                "kecamatan": "Liang Anggang",
                "kelurahan": "Landasan Ulin Utara",
                "kodepos": "70724"
            },
            {
                "kecamatan": "Landasan Ulin",
                "kelurahan": "Syamsudin Noor",
                "kodepos": "70724"
            },
            {
                "kecamatan": "Landasan Ulin",
                "kelurahan": "Landasan Ulin Timur",
                "kodepos": "70724"
            },
            {
                "kecamatan": "Liang Anggang",
                "kelurahan": "Landasan Ulin Selatan",
                "kodepos": "70724"
            },
            {
                "kecamatan": "Landasan Ulin",
                "kelurahan": "Guntung Manggis",
                "kodepos": "70724"
            },
            {
                "kecamatan": "Liang Anggang",
                "kelurahan": "Landasan Ulin Tengah",
                "kodepos": "70723"
            },
            {
                "kecamatan": "Liang Anggang",
                "kelurahan": "Landasan Ulin Barat",
                "kodepos": "70722"
            },
            {
                "kecamatan": "Landasan Ulin",
                "kelurahan": "Guntung Payung",
                "kodepos": "70721"
            },
            {
                "kecamatan": "Banjar Baru Utara",
                "kelurahan": "Sei/Sungai Ulin",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Selatan",
                "kelurahan": "Sei/Sungai Besar",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Utara",
                "kelurahan": "Loktabat Utara",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Utara",
                "kelurahan": "Mentaos",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Selatan",
                "kelurahan": "Loktabat Selatan",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Utara",
                "kelurahan": "Komet",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Selatan",
                "kelurahan": "Kemuning",
                "kodepos": "70714"
            },
            {
                "kecamatan": "Banjar Baru Selatan",
                "kelurahan": "Guntung Paikat",
                "kodepos": "70713"
            }
        ],
        "k167": [
            {
                "kecamatan": "Alalak",
                "kelurahan": "Tatah Mesjid",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Semangat Bakti",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Semangat Dalam",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Semangat Karya",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Sei/Sungai Lumbah",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Sei/Sungai Pitung",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Pulau Sewangi",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Pulau Sugara",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Handil Bakti",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Panca Karya",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Pulau Alalak",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Berangas Timur",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Beringin",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Berangas Barat",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Berangas",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Belandean",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Alalak",
                "kelurahan": "Belandean Muara",
                "kodepos": "70582"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Tatah Alayung",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Terantang",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Tabing Rimbah",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Tanipah",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Puntik Tengah",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Sei/Sungai Ramania",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Sampurna",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Puntik Dalam",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Puntik Luar",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Lok Rawa",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Pantai Hambawang",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Karang Bunga",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Karang Indah",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Jejangkit Timur",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Jejangkit Barat",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Jejangkit Muara",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Jejangkit Pasar",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Cahaya Baru",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Bangkit Baru",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Jejangkit",
                "kelurahan": "Bahandang",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Mandastana",
                "kelurahan": "Antasan Segara",
                "kodepos": "70581"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Simpang Nungki",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Sei/Sungai Tunjang",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Sawahan",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Sei/Sungai Kambat",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Sei/Sungai Rasau",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Badandan",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Cerbon",
                "kelurahan": "Bantuil",
                "kodepos": "70571"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Tinggiran Tengah",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Tinggiran Baru",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Tinggiran Darat",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Tamban Raya",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Tamban Raya Baru",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Mekar Sari",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Jelapat II",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Karang Mekar",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Tanggul Rejo",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Mekar Sari",
                "kelurahan": "Indah Sari",
                "kodepos": "70568"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Tabunganen Pemurus",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Tabunganen Tengah",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Tabunganen Kecil",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Tabunganen Muara",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Teras Dalam",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Teras Luar",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Telan Kecil",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Telan Muara",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Telan Besar",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Kuala Lupak",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Sei/Sungai Jingah Besar",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Beringin Kencana",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tabunganen",
                "kelurahan": "Karya Baru",
                "kodepos": "70567"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tinggiran Luar II",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Muara",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Muara Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Sari Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Bangun Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Kecil",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Sidorejo",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Tamban Bangun",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Purwosari I",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Purwosari II",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Sekata Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Koanda",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Purwosari Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Jelapat Baru",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Jelapat I",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Tamban",
                "kelurahan": "Damsari",
                "kodepos": "70566"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Mentaren",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Pandan Sari",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Gandaria",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Hilir Mesjid",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Gandaraya",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Barunai Baru",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Danau Karya",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Anjir Seberang Pasar II",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Banyiur",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Anjir Pasar Lama",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Anjir Seberang Pasar",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Anjir Pasar Kota II",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Anjir Pasar Kota",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Andaman",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Pasar",
                "kelurahan": "Andaman II",
                "kodepos": "70565"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Sepakat Bersama",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Sei/Sungai Punggu",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Sei/Sungai Punggu Baru",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Patih Muhur Baru",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Patih Muhur",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Serapat Muara I",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Beringin Jaya",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Marabahan Baru",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Serapat Lama",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Serapat Muara",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Serapat Baru",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Serapat Baru I",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Muara Kota Tengah",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Muara Lama",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Anjir Muara",
                "kelurahan": "Anjir Muara Kota",
                "kodepos": "70564"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Sei/Sungai Seluang Pasar",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Sukaramai",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Samuda",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Sei/Sungai Seluang",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Parimata",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Patih Salera",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Rangga Surya",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Karang Buah",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Karang Dukuh",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Murung Keramat",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Binaan Baru",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Belawang",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Belawang",
                "kelurahan": "Bambangin",
                "kodepos": "70563"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Tumih",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Waringin Kencana",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Surya Kanta",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Sumber Rahayu",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Sido Mulyo",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Simpang Jaya",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Sei/Sungai Kali",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Pinang Habang",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Roham Raya",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Pendalaman",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Pendalaman Baru",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Kolam Kiri",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Kolam Makmur",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Kolam Kiri Dalam",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Kolam Kanan",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Karya Baru",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Dwi Pasari",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Handil Barabai",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Barambai Kolam Kanan",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Barambai Kolam Kiri",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Barambai Karya Tani",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Barambai",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Wanaraya",
                "kelurahan": "Babat Raya",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Barambai",
                "kelurahan": "Bagagap",
                "kodepos": "70562"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sinar Baru",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sei/Sungai Sahurai",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Simpang Arja",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sei/Sungai Gampa Asahi",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sei/Sungai Pantai",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sei/Sungai Bamban",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Sei/Sungai Gampa",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Pindahan Baru",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Teluk Tamba",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Rantau Badauh",
                "kelurahan": "Danda Jaya",
                "kodepos": "70561"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Tabukan Raya",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Tamba Jaya",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Pantang Baru",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Pantang Raya",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Rantau Bamban",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Karya Makmur",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Muara Pulau",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Karya Indah",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Karya Jadi",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Tabukan",
                "kelurahan": "Bandar Karya",
                "kodepos": "70553"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Tabatan",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Tabatan Baru",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kuripan",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Rimbun Tulang",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kabuau",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Jambu Baru",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Jarenang",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Asia Baru",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Jambu",
                "kodepos": "70552"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Sei/Sungai Lirik",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Ulu Benteng",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Murung Raya",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Palingkau",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Karya Maju",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Benua Anyar",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Balukung",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Banitan",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Batik",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Bahalayung",
                "kodepos": "70513"
            },
            {
                "kecamatan": "Bakumpai",
                "kelurahan": "Lepasan",
                "kodepos": "70512"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Marabahan Kota",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Penghulu",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Sidomakmur",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Bagus",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Baliuk",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Antar Raya",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Antar Baru",
                "kodepos": "70511"
            },
            {
                "kecamatan": "Marabahan",
                "kelurahan": "Antar Jaya",
                "kodepos": "70511"
            }
        ],
        "k168": [
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Pemurus Baru",
                "kodepos": "70249"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Pemurus Dalam",
                "kodepos": "70248"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Murung Raya",
                "kodepos": "70247"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Tanjung Pagar",
                "kodepos": "70247"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Basirih Selatan",
                "kodepos": "70245"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Kelayan Selatan",
                "kodepos": "70246"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Kelayan Timur",
                "kodepos": "70247"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Mantuil",
                "kodepos": "70244"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Basirih",
                "kodepos": "70245"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Pekauman",
                "kodepos": "70243"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Kelayan Tengah",
                "kodepos": "70242"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Kelayan Dalam",
                "kodepos": "70242"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Kelayan Luar",
                "kodepos": "70241"
            },
            {
                "kecamatan": "Banjarmasin Selatan",
                "kelurahan": "Kelayan Barat",
                "kodepos": "70241"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Sei/Sungai Lulut",
                "kodepos": "70238"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Benua Anyar",
                "kodepos": "70239"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Pemurus Luar",
                "kodepos": "70236"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Sei/Sungai Bilu",
                "kodepos": "70236"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Pengambangan",
                "kodepos": "70237"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Kuripan",
                "kodepos": "70236"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Kebun Bunga",
                "kodepos": "70235"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Pekapuran Laut",
                "kodepos": "70235"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Pekapuran Raya",
                "kodepos": "70234"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Melayu",
                "kodepos": "70234"
            },
            {
                "kecamatan": "Banjarmasin Timur",
                "kelurahan": "Karang Mekar",
                "kodepos": "70234"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Seberang Masjid",
                "kodepos": "70231"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Sei/Sungai Baru",
                "kodepos": "70233"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Kuin Cerucuk",
                "kodepos": "70129"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Gadang",
                "kodepos": "70231"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Kuin Selatan",
                "kodepos": "70128"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Alalak Tengah",
                "kodepos": "70126"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Kuin Utara",
                "kodepos": "70127"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Alalak Selatan",
                "kodepos": "70126"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Alalak Utara",
                "kodepos": "70125"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Pangeran",
                "kodepos": "70124"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Sei/Sungai Miai",
                "kodepos": "70123"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Surgi Mufti",
                "kodepos": "70122"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Antasan Kecil Timur",
                "kodepos": "70123"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Sei/Sungai Jingah",
                "kodepos": "70121"
            },
            {
                "kecamatan": "Banjarmasin Utara",
                "kelurahan": "Sungai Andai",
                "kodepos": "70122"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Pelambuan",
                "kodepos": "70118"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Telaga Biru",
                "kodepos": "70119"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Teluk Dalam",
                "kodepos": "70117"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Belitung Selatan",
                "kodepos": "70116"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Belitung Utara",
                "kodepos": "70116"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Pasar Lama",
                "kodepos": "70115"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Teluk Tiram",
                "kodepos": "70113"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Antasan Besar",
                "kodepos": "70114"
            },
            {
                "kecamatan": "Banjarmasin Barat",
                "kelurahan": "Telawang",
                "kodepos": "70112"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Kertak Baru Ulu",
                "kodepos": "70111"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Mawar",
                "kodepos": "70112"
            },
            {
                "kecamatan": "Banjarmasin Tengah",
                "kelurahan": "Kertak Baru Ilir",
                "kodepos": "70111"
            }
        ],
        "k169": [
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Sei/Sungai Hambawang",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Sebangau Jaya",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Sebangau Mulya",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Sebangau Permai",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Paduran Sabangau",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Panduran Mulya",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Sebangau Kuala",
                "kelurahan": "Mekar Jaya",
                "kodepos": "74874"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Tahai Jaya",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Wonoagung",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Tahai Baru",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Sei/Sungai Baru Tewu",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Sidodadi",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Maliku Mulya",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Purwodadi",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Kanamit Barat",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Kanamit Jaya",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Maliku Baru",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Kanamit",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Garantung",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Gandang",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Gandang Barat",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Maliku",
                "kelurahan": "Badirih",
                "kodepos": "74873"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Sei/Sungai Rungun",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Tanjung Perawan",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Sei/Sungai Pasanan (Papuyu I)",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Sei/Sungai Pudak (Papuyu III)",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Kiapak",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Sei/Sungai Barunai (Papuyu II)",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Hulu Permai",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Tengah",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Camatan (Cematan)",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Hulu",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Hilir",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Basantan",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Kahayan Kuala",
                "kelurahan": "Bahaur Batu Raya",
                "kodepos": "74872"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Talio Muara",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Talio Hulu",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Talio",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Pantik",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Sanggang",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Pangkoh Sari",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Pangkoh Hulu",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Mulyasari (Mulia Sari)",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Pangkoh Hilir",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Karya Bersama",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Kantan Dalam",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Kantan Muara",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Kantan Atas",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Gadabung",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Dandang",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Pandih Batu",
                "kelurahan": "Belanti Siam",
                "kodepos": "74871"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Tumbang Tarusan",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Tangkahen",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Tambak",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Pangi",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Ramang",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Pandawei",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Manen Kaleka",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Manen Paduran",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Pahawan",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Kasali Baru",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Lawang Uru",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Hanua",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Hurung",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Bawan",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Banama Tingang",
                "kelurahan": "Guha",
                "kodepos": "74863"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Tanjung Sangalang",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Tuwung",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Petuk Liti",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Sigi",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Tahawa",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Parahangan",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Penda Barania",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Bukit Rawi",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Pamarunan",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Bareng Rambang",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Bukit Bamba",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Bukit Liti",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Bahu Palawa",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Tengah",
                "kelurahan": "Balukun",
                "kodepos": "74862"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Mantaren II",
                "kodepos": "74861"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Buntoi",
                "kodepos": "74861"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Mantaren I",
                "kodepos": "74861"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Bereng",
                "kodepos": "74831"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Kahayan Hilir",
                "kodepos": "74841"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Tumbang Nusa",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Hanjak Maju",
                "kodepos": "74821"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Simpur",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Tanjung Taruna",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Pilang",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Saka Kajang",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Mintin",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Jabiren",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Garung (Garong)",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Jabiren Raya",
                "kelurahan": "Henda",
                "kodepos": "74816"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Kalawa",
                "kodepos": "74812"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Anjir Pulang Pisau",
                "kodepos": "74813"
            },
            {
                "kecamatan": "Kahayan Hilir",
                "kelurahan": "Gohong",
                "kodepos": "74812"
            }
        ],
        "k170": [
            {
                "kecamatan": "Delang",
                "kelurahan": "Sekombulan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Sepoyu",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Riam Penahan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Riam Tinggi",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Nyalang",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Penyombaan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Lopus",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Liku",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Mengkalang",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Kubung",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Kudangan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Delang",
                "kelurahan": "Landau Kantu",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Kinipan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Karang Mas",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Kina",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Ginih",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Jemuat",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Batu Tambun",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Batangkawa",
                "kelurahan": "Benakitan",
                "kodepos": "74664"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Tapin Bini",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Tangga Batu",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Suja",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Sumber Cahaya",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Sei/Sungai Tuat",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Sekoban",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Petarikan",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Sei/Sungai Buluh",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Nanga Matu",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Panopa",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Nanga Belantikan",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Kawa",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Karang Taba",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Kahingai",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Karang Besi",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Cuhai",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Benuatan",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Bintang Mengalih",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Bayat",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Belantikan Raya",
                "kelurahan": "Belibi (Balibi)",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Lamandau",
                "kelurahan": "Bakonsu",
                "kodepos": "74663"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Nanga Bulik",
                "kodepos": "74611"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Kujan",
                "kodepos": "74612"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Wonorejo",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Topalan",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Tri Tunggal",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Tamiang",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Toka",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Sumber Mulya",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Sungkup",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Sumber Jaya",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Sepondam",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Sei/Sungai Mentawa",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Sukamaju",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Purworejo",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Nuangan",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Pedongatan",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Nanga Palikodan",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Nanuah",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Nanga Kemujan",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Nanga Koring",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Modang Mas",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Mukti Manunggal",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Melata",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Merambang",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Mekar Mulya",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Lubuk Hiju",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Jangkar Prima",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Guci",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Bunut",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Bumi Agung",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Bukit Makmur",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Bukit Raya",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Bukit Indah",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Bukit Jaya",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Bina Bhakti",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Bina Bhakti",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik Timur",
                "kelurahan": "Batu Tunggal",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Beruta",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Batu Kotam",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Sematu Jaya",
                "kelurahan": "Batu Hambawang",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Menthobi Raya",
                "kelurahan": "Batu Ampar",
                "kodepos": "74162"
            },
            {
                "kecamatan": "Bulik",
                "kelurahan": "Arga Mulya",
                "kodepos": "74162"
            }
        ],
        "k171": [
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Tumbang Tihis",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Tumbang Puroh/Puruh",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Tumbang Manyarung",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Tumbang Sirat",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Tanjung Rendan",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Tumbang Bukoi/Bokoi",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Supang",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Tangirang",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Rahung Bungai",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Sei/Sungai Hanyo/Hanyu",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Masaha",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Mampai Jaya",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Lawang Tamang",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Katanjung",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Karetau Mantaa",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Hurung Tabengan",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Hurung Tampang",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Jakatan Pari",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Bulau Ngandung",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Dirung Koram",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Kapuas Hulu",
                "kelurahan": "Baronang II",
                "kodepos": "74581"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Tajepan",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Talekung Punai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Tambak Bajai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Sumber Mulia",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Sumber Alaska",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Suka Reja",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Sumber Agung",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Rawa Subur",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Saka Binjai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Suka Mukti",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palingkau Sejahtera",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Petak Batuah",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palingkau Jaya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palingkau Lama",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palingkau Asri",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palingkau Baru",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palangkau Baru",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Palangkau Lama",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Muara Dadahup",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Menteng Karya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Manuntung",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Mangala Permai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Karya Bersama",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Mampai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Kahuripan Permai",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Harapan Baru",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Bumi Rahayu",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Dadahup",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Dadahup Raya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Bina Mekar",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Bina Sejahtera",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Bina Karya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Bina Jaya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Dadahup",
                "kelurahan": "Bentuk Jaya",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Teluk Palinget",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Kapuas Murung",
                "kelurahan": "Belawang",
                "kodepos": "73593"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Sei Tatas Hilir",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Sei/Sungai Tatas",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Sakalagun",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Palangkai",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Narahan",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Narahan Baru",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Handiwung",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Mawar Mekar",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Banama",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Bunga Mawar",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Pulau Petak",
                "kelurahan": "Anjir Palambang",
                "kodepos": "73592"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Warna Sari",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Wargo Mulyo",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Tambun Makmur",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Tamban Lupak",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Tambun Jaya",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Tamban Baru Tengah",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Tamban Baru Tengah",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Tamban Baru Timur",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Simpang Bunga Tanjung",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Tamban Baru Mekar",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Sidomulyo",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Sei/Sungai Teras",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Sidorejo",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Palampai",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Sei Bakut",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Pematang",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Lupak Dalam",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Lupak Timur",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Baranggau",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Batanjung",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Kuala",
                "kelurahan": "Cemara Labat",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Bandar Raya",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Tamban Catur",
                "kelurahan": "Bandar Mekar",
                "kodepos": "73583"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Serapat Tengah",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Serapat Timur",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Serapat Barat",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Serapat Baru",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Mambulau Tengah",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Mambulau Timur",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Kapuas Timur",
                "kelurahan": "Anjir Mambulau Barat",
                "kodepos": "73581"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Pangkalan Sari",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Tambun Raya",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Pangkalan Rekan",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Tarung Manuah",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Maluen",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Naning",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Panarung",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Bungai Jaya",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Lunuk Ramba",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Batuah",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Basungkai",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Batu Nindan",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Basarang",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Basarang",
                "kelurahan": "Basarang Jaya",
                "kodepos": "73564"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Tumbang Nusa",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Tumbang Tukun",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Tapen",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Tumbang Diring",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Sei/Sungai Ringin",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Pujon",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Penda Muntei",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Masupa Ria",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Marapit (Merapit)",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Masaran",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Kayu Bulan",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Kota Baru",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Manis",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Karukus",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Kaburan",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Jangkang",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Hurung Pukung",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Mandau Talawang",
                "kelurahan": "Jakatan Masaha",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Dandang",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Hurung Kampin",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Buhut Jaya",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Batu Sambung",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Barunang",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Pasak Talawang",
                "kelurahan": "Balai Banjang",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Kapuas Tengah",
                "kelurahan": "Bajuh",
                "kodepos": "73555"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Timpah",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Tumbang Randang",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Lungku Layang",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Petak Puti",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Lawang Kajang",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Lawang Kamah",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Aruk",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Batapah",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Timpah",
                "kelurahan": "Danau Pantau",
                "kodepos": "73554"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Warga Mulya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Tumbang Muroi",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Tumbang Mangkurup",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sumber Makmur",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Tabore",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Tarantang",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sri Widadi",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Suka Maju",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sido Mulyo",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sekata Bangun",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sekata Makmur",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sei/Sungai Ahas",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sei/Sungai Kapar",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sei Gawing",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sei Gita",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Pulau Kaladan",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Rantau jaya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Sari Makmur",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Manyahi",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Muroi Raya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Manusup Hilir",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Manusup",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Mantangai Hulu",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Mantangai Tengah",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Lamunti Permai",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Mantangai Hilir",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Lamunti",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Lamunti Baru",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Lahei Mangkutup",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Lampetan",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Katimpun",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Katunjung",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Kaladan Jaya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Kalumpang",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Harapan Jaya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Humbang Raya",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Bukit Baru",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Mantangai",
                "kelurahan": "Danau Tawah/Rawah",
                "kodepos": "73553"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Teluk Hiri",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Sei/Sungai Dusun",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Sei/Sungai Kayu",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Saka Tamiang",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Sei Pitung",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Penda Ketapi",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Saka Mangkahai",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Mandomai",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Pantai",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Anjir Kalampan",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Basuta Raya",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Barat",
                "kelurahan": "Maju Bersama",
                "kodepos": "73552"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Sei/Sungai Pasah",
                "kodepos": "73525"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Sei/Sungai Asam",
                "kodepos": "73525"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Saka Batur",
                "kodepos": "73525"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Barimba",
                "kodepos": "73524"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Bakungin",
                "kodepos": "73525"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Hampatung",
                "kodepos": "73522"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Dahirang",
                "kodepos": "73523"
            },
            {
                "kecamatan": "Kapuas Hilir",
                "kelurahan": "Mambulau",
                "kodepos": "73521"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Raya Hilir",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Raya",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Raya Barat",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Karya",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Makmur",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Mulya",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Tamban Luar",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Terusan Baguntan Raya",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Dalam",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Utara",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Sei Jangkir",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Sei/Sungai Lunuk",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Pulau Telo Baru",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Pulau Mambulau",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Pulau Telo",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Bangun Harjo",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Budi Mufakat",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Bamban Raya",
                "kodepos": "73516"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Hulu",
                "kodepos": "73515"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Barat",
                "kodepos": "73514"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Tengah",
                "kodepos": "73514"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Selat Hilir",
                "kodepos": "73513"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Panamas",
                "kodepos": "73514"
            },
            {
                "kecamatan": "Selat",
                "kelurahan": "Murung Keramat",
                "kodepos": "73512"
            },
            {
                "kecamatan": "Bataguh",
                "kelurahan": "Pulau Kupang",
                "kodepos": "73511"
            }
        ],
        "k172": [
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Tuyun",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Tumbang Danau",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Tumbang Empas",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Tanjung Karitak",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Tewai Baru",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Sepang Simin",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Tampelas",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Rangan Tate",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Sepang Kota",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Luwuk Andan",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Sepang (Sepang Simin)",
                "kelurahan": "Pematang Limau",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Kampuri",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Mihing Raya",
                "kelurahan": "Dahian Tambuk",
                "kodepos": "74571"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Tumbang Talaken",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Tumbang Sepang",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Tumbang Mantuhe",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Tumbang Oroi",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Tumbang Samui",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Tumbang Jelemu",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Tehang",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Takaras",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Tangki Dahuyan",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Taringen",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Mangkawuk",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Luwuk Tukau",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing Raya",
                "kelurahan": "Putat Durei",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Fajar Harapan",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Gohong",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Bereng Belawan",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Bereng Jun",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Bangun Sari",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Manuhing",
                "kelurahan": "Belawan Mulya",
                "kodepos": "74562"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tusang Raya",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Tumbang Mujai",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Tumbang Rahuyan",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Tumbang Tue / Tuwe",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Tumbang Lapan",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Tumbang Malahoi/Malahui",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tumbang Langgah",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Tumbang Jelemu Kajuei",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tumbang Kuayan",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Tumbang Jatuh/Jutuh",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tumbang Jalemu Kajuei",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tumbang Bahanei",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Tumbang Barengei/Baringei",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Tumbang Bunut",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Tajah Antang Raya",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Talangkas (Talangkah)",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Sei/Sungai Antai",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Sanggal",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Rabambang",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Parempei",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Luwuk Kantor",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Luwuk Langkuas",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Linau",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Karya Bakti",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Jangkit",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Jalemu Masulan",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Jalemu Raya",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Jakatan Raya",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Barat",
                "kelurahan": "Hujung Pata",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Hantapang",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Bereng Baru",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan",
                "kelurahan": "Bereng Malaka",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Rungan Hulu",
                "kelurahan": "Batu Puter",
                "kodepos": "74561"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Tajungan",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Siruk",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Sian",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Tumbang Posu",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Pasangon",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Ponyoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Napoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Miri",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Masukih",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Tumbang Marikoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Manyoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Manyoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Tumbang Mahuroi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Lapan",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Koroi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Tumbang Hantung",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Korik/Kurik",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Tumbang Anoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Tumbang Hamputung",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Rangan Hiran",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Takaoi",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Teluk Kanduri",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Mangkuhung",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Penda Rangas",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Karetau Rambangun",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Karetau Sarian",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Damang Batu",
                "kelurahan": "Lawang Kanji",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Harowu",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Miri Manasa",
                "kelurahan": "Buntui (Buntoi)",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Dandang",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Kahayan Hulu Utara",
                "kelurahan": "Batu Tangkui",
                "kodepos": "74553"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Tumbang Pajangei",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Upun Batu",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Tewah",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Tumbang Habaon",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Tanjung Untung",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Teluk Lawah",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Sumur Mas",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Tajau Urap",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Sarerangan",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Sei/Sungai Riang",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Kasintu",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Rangan Mihing",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Sandung Tambun",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Batu Nyiwuh",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Karason Raya",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Tariak",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Tewah",
                "kelurahan": "Batu Nyapau",
                "kodepos": "74552"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Miwan",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Tambirah",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Lampaung/Lampahung",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Manyangan",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Hakau",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tumbang Anjir",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tanjung Riu",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Teluk Nyatu",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Tewang Pajangan",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Pilang Munduk",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Penda Pilang",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Petak Bahandang",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Hurung Bunut",
                "kodepos": "74511"
            },
            {
                "kecamatan": "Kurun",
                "kelurahan": "Kurun (Kuala Kurun)",
                "kodepos": "74511"
            }
        ],
        "k173": [
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Tumbang Bulan",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Teluk Sebulu",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Tewang/Tawang Kampung",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Singam Raya",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Subur Indah",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Sungai Kaki",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Selat Baning",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Setia Mulia",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Perigi (Parigi)",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Perigi (Parigi)",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Pegatan/Pagatan Hulu",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Pegatan/Pagatan Hilir",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Mekar Tani",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Mendawai",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Kampung Tengah",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Makmur Utama",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Mangun Jaya",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Mendawai",
                "kelurahan": "Kampung Melayu",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Jaya Makmur",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Kampung Baru",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Kampung Melayu",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Bakung Raya",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Katingan Kuala",
                "kelurahan": "Bumi Subur",
                "kodepos": "74463"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Tumbang Runen",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Tampelas",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Telaga",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Jahanjang",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Karuing",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Parupuk",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Galinggang",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Baun Bango",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Tumbang Panggo",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Kamipang",
                "kelurahan": "Asem Kumbang",
                "kodepos": "74462"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Talingke",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Tewang Tampang",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Luwuk Kiri",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Petak Bahandang",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Hiang/Hiyang Bana",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Luwuk Kanan",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Tasik Payawan",
                "kelurahan": "Handiwung",
                "kodepos": "74461"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Sabetung",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Salaman",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Senamang I",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Manangei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Mangketai",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Labaning",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Mahop/Mahup",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Kuai",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Kataei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Kaburai",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Kajamei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Karuei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Jiga",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Kabayan/Kabayang",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Tumbang Hangei II",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Gaei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Telok Tampang",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tumbang Dahuei",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Sei/Sungai Nanjan",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Rongan Rondan",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tanjung Batik",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Rantau Puka",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Rantau Bahai",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Rantau Pandan",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Rangan Kawit",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Rangan Ranjing",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Penda Tangaring Baru",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Penda Tangaring Lama",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Rangan Bahekang",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Kuluk Sepangi/Sapangi",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Penda Nange",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Dehes Asem",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Kiham Batang",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Tundu",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Katingan Hulu",
                "kelurahan": "Batu Bango",
                "kodepos": "74457"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Tabulus",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Taei",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Paku",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Susan &#8211; Malaman",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Mandurei",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Pahanei",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Dakei",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Hiran",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Lambi",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Tumbang Bemban",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Rangan Tangko",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Sebaung",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Rangan Burih",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Rangan Surei",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Buntut Leleng",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Kuluk Leleng",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Marikit",
                "kelurahan": "Batu Panahan",
                "kodepos": "74456"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Pangka",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Taranei/Karanei",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Tumbang Tanggoi",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Labehu",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Mangara/Manggara",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Manggo",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Kanei/Kamei",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Kawei",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Tumbang Jala",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Kaman",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Tumbang Baraoi",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Tumbang Habangoi/Habangei",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Rantau Bangkiang",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Tumbang Atei",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Nusa Kutau",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Kamanto",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Kuluk Habuhus",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Daya Manunggal",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Sanaman Mantikei (Senamang Mantikei)",
                "kelurahan": "Dehes",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Batu Badak",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Petak Malai",
                "kelurahan": "Batu Tukan",
                "kodepos": "74455"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tumbang Marak",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tumbang Pariyei",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tumbang Kalamei",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tumbang Lahang",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tewang Panjang",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Tumbang Hangei",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Samba Katung",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Telok",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Samba Danum",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Samba Kahayan",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Petak Puti",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Rantau Asem",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Samba Bakumpai",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Napu Sahur",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Mirah Kalanaman",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Katingan Tengah",
                "kelurahan": "Batu Badinding",
                "kodepos": "74454"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tumbang Tungku",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tura",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tumbang Lawang",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tumbang Tanjung",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tewang Papari",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tumbang Banjang",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tewang Derayu",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Tewang Karangan",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Kuluk Bali",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Manduing Lama",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Manduing Taheta",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Dahian Tunggal",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Geragu",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Pulau Malan",
                "kelurahan": "Buntut Bali",
                "kodepos": "74453"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tewang Rangkang",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tumbang Terusan",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tewang Rangas",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tarusan Danum",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tewang Beringin",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Tewang Manyangen",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Pendahara",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Sei/Sungai Keruh",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Bangkuang",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Tewang Sanggalang Garing (Sangalang)",
                "kelurahan": "Hapalam",
                "kodepos": "74452"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Tewang Kadamba",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Tumbang Liting",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Talangkah (Telangkah)",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Talian Kereng",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Kasongan Baru",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Kasongan Lama",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Banut Kalanaman",
                "kodepos": "74413"
            },
            {
                "kecamatan": "Katingan Hilir",
                "kelurahan": "Hampalit",
                "kodepos": "74413"
            }
        ],
        "k174": [
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Ujung Pandaran",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Samuda Kota",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Sei/Sungai Ijum Raya",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Sabamban",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Samuda Besar",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Samuda Kecil",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Regei Lestari",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Parebok",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Jaya Kelapa",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Kuin Permai",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Lampuyang",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Basirih Hulu",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Handil Sohor",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Jaya Karet",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Mentaya Hilir Selatan",
                "kelurahan": "Basirih Hilir",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Serambut",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Teluk Sampit",
                "kelurahan": "Basawang",
                "kodepos": "74363"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Rawa Sari",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Satiruk",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Bapinang Hulu",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Hanaut",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Makarti Jaya",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Bapinang Hilir",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Bapinang Hilir Laut",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Babriah",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Pulau Hanaut",
                "kelurahan": "Babaung",
                "kodepos": "74362"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Pondok Damar",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Sumber Makmur",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Bagendang Tengah",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Natai Baru",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Bagendang Hulu",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Bagendang Permai",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Torung/Turung",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hilir Utara",
                "kelurahan": "Bagendang Hilir",
                "kodepos": "74361"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Tawan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Tilap",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Tumbang Sapiri",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Sapia",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Penyahuan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Saluang",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Kania",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Payang",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Batu",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Getas",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tumbang Kamining/Kaminting",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tewei Hara",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Tanjung Jariangau",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Tanjung Batur",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Tangar",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Tangka Robah",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Tanah Haluan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Sationg",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Penda Durian",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Santilik",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Bukit Santuei",
                "kelurahan": "Lunuk Bagantung",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Pahirangan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Pemantang",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Kuala Kuayan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Buana Mustika",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Kapuk",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Kawan Batu",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Baampah",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Mentaya Hulu",
                "kelurahan": "Bawan",
                "kodepos": "74356"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Wonosari",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Tumbang Mujam",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Tehang",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Sumber Makmur",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Tanjung Jorong",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Sari Harapan",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Sebungsu",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Merah",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Parenggean",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Mekar Sari",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Mekar Jaya",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Luwuk Sampun",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Karang Tunggal",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Kabuau",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Karang Sari",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Damar Makmur",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Jati Waringin",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Bukit Makmur",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Tualan Hulu",
                "kelurahan": "Cempaka Putih",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Bukit Harapan",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Baringin Tunggal Jaya",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Barunang Miri",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Bajarau",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Parenggean",
                "kelurahan": "Bandar Agung",
                "kodepos": "74355"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Tumbang Koling/Kuling",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Sudan",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Sungai Ubar Mandiri",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Sei/Sungai Paring",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Selucing",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Rubung Buyung",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Pundu",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Patai",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Pelantaran",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Pantai Harapan",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Parit",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Lubuk Ranggan",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Luwuk Bunter (Lubuk Bunter)",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Keruing",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Jemaras",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Cempaka Mulia Barat",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga",
                "kelurahan": "Cempaka Mulia Timur",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Bukit Raya",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Tanah Putih",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Cempaga Hulu",
                "kelurahan": "Bukit Batu",
                "kodepos": "74354"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Soren",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Sumber Makmur",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Simpur",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Sebabi",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Rasau Tumbuh",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Peryang",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Pamalian",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Kota Besi Hulu",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Palangan",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Kenyala",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Kandan",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Kota Besi Hilir",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Camba",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Hanjalipan",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Telawang",
                "kelurahan": "Biru Maju",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Kota Besi",
                "kelurahan": "Bajarum",
                "kodepos": "74353"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Sepayang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Waringin Agung",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tumbang Sangai",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Ramei / Ramai",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tumbang Puan",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Manya",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Ngahan",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Kalang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tumbang Mangkup",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tumbang Boloi",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Gagu",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Hejan",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Tumbang Bajanei",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tukang Langit",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Sei/Sungai Puring",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Tri Buana",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Sei/Sungai Hanya",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Rantau Tampang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Rantau Sawang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Rantau Suang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Rantau Katang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Mulya Agung",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Gunung Makmur",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Luwuk Kuwan",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Kuluk Telawang",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Buntut Nusa",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Bukit Indah",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Antang Kalang",
                "kelurahan": "Bhakti Karya",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Beringin Agung",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Agung Muya",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Telaga Antang",
                "kelurahan": "Batu Agung",
                "kodepos": "74352"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Telaga Baru",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Pelangsian",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Bengkuang Makmur",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Eka Bahurui",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Bapanggang Raya",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Bapeang",
                "kodepos": "74326"
            },
            {
                "kecamatan": "Seranau",
                "kelurahan": "Mentaya Seberang",
                "kodepos": "74324"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Ketapang",
                "kodepos": "74325"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Pasir Putih",
                "kodepos": "74322"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Mentawa Baru Hilir",
                "kodepos": "74323"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Mentawa Baru Hulu",
                "kodepos": "74322"
            },
            {
                "kecamatan": "Mentawa Baru (Ketapang)",
                "kelurahan": "Sawahan (Mentawa Baru Hulu Utara)",
                "kodepos": "74321"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Tinduk",
                "kodepos": "74316"
            },
            {
                "kecamatan": "Seranau",
                "kelurahan": "Terantang Hilir",
                "kodepos": "74315"
            },
            {
                "kecamatan": "Seranau",
                "kelurahan": "Terantang",
                "kodepos": "74314"
            },
            {
                "kecamatan": "Seranau",
                "kelurahan": "Batuah",
                "kodepos": "74315"
            },
            {
                "kecamatan": "Seranau",
                "kelurahan": "Ganepo",
                "kodepos": "74314"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Tanah Mas",
                "kodepos": "74312"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Baamang Hulu",
                "kodepos": "74313"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Baamang Barat",
                "kodepos": "74312"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Baamang Tengah",
                "kodepos": "74312"
            },
            {
                "kecamatan": "Baamang",
                "kelurahan": "Baamang Hilir",
                "kodepos": "74311"
            }
        ],
        "k175": [
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Taberau",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tusuk Belawan",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Suei",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Setawai",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Setoli",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Sepan",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Salau",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Manjul",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Langkai",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Magin",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Kubang",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Laku",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Hentas",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Kalam",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Kasai",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tumbang Gugup",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Darap",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Tanjung Tukal",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tumbang Bahan",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Sepundu Hantu",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Tanjung Paku",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Rantau Panjang",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Riam Batang",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Rangkang Munduk",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Suling Tambun",
                "kelurahan": "Rantau Betung",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Mongoh Juoi",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Majang/Mojang Baru",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Marandang",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Seruyan Hulu",
                "kelurahan": "Buntut Sapau",
                "kodepos": "74291"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Wana Tirta",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Teluk Bayur",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Tumbang Bai/Bei",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Sukorejo (Suku Rejo)",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Tangga Batu",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Suka Mulya",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Suka Mandang",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Suka Makmur",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Suka Jaya (Suka Ayu)",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Suka Maju",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sandul",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sebabi",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sehabu (Sahabu/Sebabu)",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Ringin Agung",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Rantau Pulut",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Penyumpa",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Mugi Panyahu (Mugie Panyuhu)",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Panca Jaya",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Pangke",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Kalang",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Durian Tunggal",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Gantung Pengayuh",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Bumi Jaya (Bumi Ayu)",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Derawa",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Durian Kait",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Bukit Buluh/Buluk",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Batu Manangis",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Ayawan",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Seruyan Tengah",
                "kelurahan": "Batu Agung",
                "kodepos": "74281"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Tanjung Rangas II",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Tanjung Hara",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Tanjung Paring",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Tanjung Hanau/Hana",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Rangau Raya",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Pembuang Hulu I",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Pembuang Hulu II",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Parang Batang",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Paring Raya",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Panimba Raya",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Bahaur",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Hanau",
                "kelurahan": "Derangga",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Seluluk",
                "kelurahan": "Asam Baru",
                "kodepos": "74271"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Ulak Batu",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Raya",
                "kelurahan": "Terawan",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Telaga Pulang",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Raya",
                "kelurahan": "Tabiku",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Sembuluh I (Satu)",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Sembuluh II (Dua)",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Paren",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Raya",
                "kelurahan": "Salunuk (Selunuk)",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Cempaka Baru",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Palingkau",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Raya",
                "kelurahan": "Lanpasa",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Danau Sembuluh",
                "kelurahan": "Banua Usang",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Raya",
                "kelurahan": "Bangkal",
                "kodepos": "74261"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Sei/Sungai Urdang",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Persit Raya",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Sei/Sungai Perlu",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir Timur",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Kuala Pembuang Satu (I)",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir Timur",
                "kelurahan": "Mekar Indah",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Kuala Pembuang Dua (II)",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir Timur",
                "kelurahan": "Kartika Bhakti/Bakti",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Kuala Pembuang Dua (II)",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir Timur",
                "kelurahan": "Bangun Harja/Harjo",
                "kodepos": "74215"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Tanjung Rangas",
                "kodepos": "74214"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Baung",
                "kodepos": "74214"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Jahitan",
                "kodepos": "74214"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Muara Dua",
                "kodepos": "74214"
            },
            {
                "kecamatan": "Seruyan Hilir Timur",
                "kelurahan": "Pematang Panjang",
                "kodepos": "74213"
            },
            {
                "kecamatan": "Seruyan Hilir",
                "kelurahan": "Pematang Limau",
                "kodepos": "74213"
            }
        ],
        "k176": [
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Sei/Sungai Rangit Jaya",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Sumber Agung",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Purbasari",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Sei/Sungai Melawen",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Pangkalan Durin",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Pangkalan Tiga",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Pandu Sanjaya",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Pangkalan Dewa",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Makarti Jaya",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Lada",
                "kelurahan": "Lada Mandala Jaya",
                "kodepos": "74184"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sungai Pulau",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sungai Pulau",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sungai Kuning",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sungai Pakit",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Simpang Berambai",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sungai Bengkuang",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sei/Sungai Hijau",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Sido Mulyo/Ponco Mulyo",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Mulia/Mulya Jadi",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Natai Kerbau",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Pangkalan Banteng",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Kebon Agung",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Marga Mulya",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Karang Sari",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Karangmulya",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Arga Mulya",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Berambai Makmur",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Pangkalan Banteng",
                "kelurahan": "Amin Jaya",
                "kodepos": "74183"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Teluk Bogam",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Teluk Pulai",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Sekonyer",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Tendang",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Bedaun",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Cabang",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Kapitan",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sebuai",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Pangkalan Satu",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Sabuai Timur",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Kumai Hilir",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Kumai Hulu",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Keraya",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Kubu",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Bumi Harjo",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Candi",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kumai",
                "kelurahan": "Batu Belaman",
                "kodepos": "74181"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Sumber Mukti",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Tempayung",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Sukajaya",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Sakabulin",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Suka Makmur",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Rungun",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Sagu/Dusun Sukamulya",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Riam Durian",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Lalang",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Palih Baru",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Kondang",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Kota Waringin Hilir",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Kotawaringin Hulu",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Ipuh Bangun Jaya",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Kinjil",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Babual Baboti",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Kotawaringin Lama",
                "kelurahan": "Dawak",
                "kodepos": "74161"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Sambi",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Sukarami",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Penyombaan",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Riam",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Pangkut",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Penahan",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Kerabu",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Nanga Mua",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Pandau",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Umpang",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Utara",
                "kelurahan": "Gandis",
                "kodepos": "74152"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Tanjung Putri",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Tanjung Terantang",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Runtu",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Sulung Kenambui/Kanam Bui",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Natai Raya",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Natai Raya",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Pasir Panjang",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Medangsari",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Mendawai Seberang",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Natai Baru",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Kenambui",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Kumpai Batu Atas",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Kumpai Batu Bawah",
                "kodepos": "74117"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Mendawai",
                "kodepos": "74115"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Raja Seberang",
                "kodepos": "74116"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Raja",
                "kodepos": "74114"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Madurejo",
                "kodepos": "74112"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Baru",
                "kodepos": "74113"
            },
            {
                "kecamatan": "Arut Selatan",
                "kelurahan": "Sidorejo",
                "kodepos": "74111"
            }
        ],
        "k177": [
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Sembi Kuan",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Natai Kondang",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Sekuningan Baru",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Lupu Peruca",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Semantun",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Laman Baru",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Pempaning",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Jihing",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Nibung Terjun",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Kenawan",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Balai Riam",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Bangun Jaya",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Bukit Sungkai",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Permata Kecubung",
                "kelurahan": "Ajang",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Sukaraja",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Balai Riam",
                "kelurahan": "Air Dua",
                "kodepos": "74173"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Petarikan",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Pudu",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Padang",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Pangkalan Muntai",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Mendawai",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Natai Sedawak",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Pantai Lunci",
                "kelurahan": "Sei/Sungai Tabuk",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Sukamara",
                "kelurahan": "Karta Mulya/Mulia",
                "kodepos": "74172"
            },
            {
                "kecamatan": "Pantai Lunci",
                "kelurahan": "Sei/Sungai Pasir",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Pantai Lunci",
                "kelurahan": "Sei/Sungai Pasir",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Pantai Lunci",
                "kelurahan": "Sei/Sungai Cabang Barat",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Pantai Lunci",
                "kelurahan": "Sei/Sungai Damar",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Jelai",
                "kelurahan": "Sei/Sungai Bundung",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Jelai",
                "kelurahan": "Sei/Sungai Baru",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Jelai",
                "kelurahan": "Kuala Jelai",
                "kodepos": "74171"
            },
            {
                "kecamatan": "Jelai",
                "kelurahan": "Pulau Nibung",
                "kodepos": "74171"
            }
        ],
        "k178": [
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Tumbang Masalo",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tumbang Bondang",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tumbang Tonduk",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Tumbang Bauh",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tumbang Bana",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tumbang Bahan",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tumbang Bahan",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Penda Siron",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tahujan Laung",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Tawai Haui",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Narui",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Pelaci",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Tuhup",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Tupuh",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Laung II",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Maruwei I",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Maruwei II",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Makunjung",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Muara Laung I",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Liang Nyaling",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Lakutan",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Dirung Pinang",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Kohong",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Cinta Budiman",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Kalang Duhung",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Biha",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Hingan Tokung",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Bumban Tuhup",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Dirung Pundu",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Beralang",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Dirung Sararung/Sararong",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Beras Belange",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Batu Tuhup",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Barito Tuhup Raya",
                "kelurahan": "Batu Tojah",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Batu Karang",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Batu Bua II",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Laung Tuhup",
                "kelurahan": "Batu Bua I",
                "kodepos": "73991"
            },
            {
                "kecamatan": "Uut Murung",
                "kelurahan": "Tumbang Tujang",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Uut Murung",
                "kelurahan": "Tumbang Tupus/Topus",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Tumbang Tuhan/Tohan",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Tumbang Jojang",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Tumbang Tuan",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Teluk/Telok Jolo",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Uut Murung",
                "kelurahan": "Tumbang Olong",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Tumbang Kunyi",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Uut Murung",
                "kelurahan": "Tumbang Olong II",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Takajung",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Tumbang Mulut",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Muara Joloi II",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Tumbang Naan",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Parahau",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Tumbang Masao",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Olong Liko",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Seribu Riam",
                "kelurahan": "Muara Joloi I",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Uut Murung",
                "kelurahan": "Kalasin",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Laas Baru",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Batu Makap",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Sei/Sungai Gula",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sumber Barito",
                "kelurahan": "Kalapeh Baru",
                "kodepos": "73981"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Sei/Sungai Batang",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Tumbang Salio",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Purnama",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Tumbang Saan",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Sei/Sungai Bakanon",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Tumbang Lahung",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Muara Bakanon",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Tumbang Kolon",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Pantai Laga",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Tumbang Apat",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Batu Mirau",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Tumbang Bantian",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Juking Supan",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Osom Tompok",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Sungai Babuat",
                "kelurahan": "Tambelum",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Muara Babuat",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Siron",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Sei/Sungai Lobang",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Permata Intan",
                "kelurahan": "Baratu (Beratu)",
                "kodepos": "73971"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Soloi",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Tokung",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Ulu",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Tino Talih",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Olung Hanangan",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Tahujan Ontu",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Olung Muro",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Sei/Sungai Lunuk",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Nango",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Tabulang",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Nonokaliwon",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Saripoi",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Balo",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Saruhung",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Olung Dojou",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Puruk Kambang",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Mantiat Pari",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Puruk Batu",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Muwun",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Datah Kotou",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Oreng",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Dirung Bakung",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Konut",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Belawan",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Mahanyan",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Cangkang",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Mangkolisoi",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Penyang",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Kalang Kaluh",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Puruk Cahu",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Karali",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Muara Untu",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Kolam",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Panuut (Panaout)",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Tanah Siang Selatan",
                "kelurahan": "Dirung Lingkin",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Muara Jaan",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Tanah Siang",
                "kelurahan": "Doan Arung",
                "kodepos": "73961"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Muara Sumpoi",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Mangkahui",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Muara Bumban/Bamban",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Dirung",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Jangin/Juking Pajang",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Malasan/Masalan",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Beriwit",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Danau Usung",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Batu Putih",
                "kodepos": "73911"
            },
            {
                "kecamatan": "Murung",
                "kelurahan": "Bahitom/Bahintom",
                "kodepos": "73911"
            }
        ],
        "k179": [
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Sei/Sungai Liju",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Sampirang I",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Panaen",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Mampuak I",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Mampuak II (Pantung)",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Muara Wakat",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Liju",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Liangbuah",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Benangin V / 5",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Jamut",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Gandring",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Benangin II / 2",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Benangin III / 3",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Lawarang",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Benangin I / I",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Teweh Timur",
                "kelurahan": "Sampirang II",
                "kodepos": "73881"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Lampeong II",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Berong",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Payang",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Lampeong I",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Tambaba",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Walur",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Linon Besi I",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Baok",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Linon Besi II",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Tapen Raya",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Purei",
                "kelurahan": "Muara Mea",
                "kodepos": "73871"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Tongka",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Sangkurang",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Siwau",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Pelari",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Rarawa",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Baturaya I",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Malungai",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Tumpung Laung I",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Payang Ara",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Tumpung Laung II",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Ketapang",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Ruji",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Majangkan",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Sikan",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Kandui",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Pepas",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Jaman",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Rubei",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Baturaya II",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Montallat I",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Gunung Timang",
                "kelurahan": "Baliti",
                "kodepos": "73862"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Montallat II",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Paring Lahung",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Montallat (Montalat)",
                "kelurahan": "Kamawen",
                "kodepos": "73861"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Teluk Malewai",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Rahaden",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Papar Pujung",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Nihan Hulu",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Mukut",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Nihan Hilir",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Muara Inu",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Muara Pari",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Luwe Hilir",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Muara Bakah",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Luwe Hulu",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Lahei II",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Lahei I",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Karamuan",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Karendan",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Jangkang Lama",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Juju Baru",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Jangkang Baru",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Ipu",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Haragandang",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Hurung Enep",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei",
                "kelurahan": "Bengahon",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Benao Hulu",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Lahei Barat",
                "kelurahan": "Benao Hilir",
                "kodepos": "73852"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Terinsing/Trinsing",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Trahean",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Sikui",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Tawan Jaya",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Sei/Sungai Rahayu I",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Sei/Sungai Rahayu II",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Sabuh",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Rimba Sari",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Pandran Raya",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Pendreh/Pandreh",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Pandran Permai",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Malawaken",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Liang Naga",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Lemo II",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Hajak",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Jingah",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Lemo I",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Butong",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Datai Nirui",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Buntok Baru",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Bintang Ninggi II (Dua)",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Bukit Sawit",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Selatan",
                "kelurahan": "Bintang Ninggi I (Satu)",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Baru",
                "kelurahan": "Jambu",
                "kodepos": "73813"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Beringin Raya",
                "kodepos": "73814"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Lanjas",
                "kodepos": "73812"
            },
            {
                "kecamatan": "Teweh Tengah",
                "kelurahan": "Melayu",
                "kodepos": "73811"
            }
        ],
        "k180": [
            {
                "kecamatan": "Jenamas",
                "kelurahan": "Tampulang",
                "kodepos": "73763"
            },
            {
                "kecamatan": "Jenamas",
                "kelurahan": "Rantau Kujang",
                "kodepos": "73763"
            },
            {
                "kecamatan": "Jenamas",
                "kelurahan": "Tabatan",
                "kodepos": "73763"
            },
            {
                "kecamatan": "Jenamas",
                "kelurahan": "Rangga Ilung",
                "kodepos": "73763"
            },
            {
                "kecamatan": "Jenamas",
                "kelurahan": "Rantau Bahuang",
                "kodepos": "73763"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Sei/Sungai Jaya",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Teluk Timbau",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Mahajandau/Mahanjau",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Mangkatir",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Mengkatip",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Damparan",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Kalanis",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Lehai",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Batilap",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Dusun Hilir",
                "kelurahan": "Batampang",
                "kodepos": "73762"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Teluk Betung",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Teluk Sampudau",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Talio",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Tampijak",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Malitin",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Muara Arai",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Selat Baru",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Bintang Kurung",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Janggi",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Babai",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Karau Kuala",
                "kelurahan": "Bangkuang",
                "kodepos": "73761"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Wungkur Baru",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Tabak Kanilan",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Ugang Sayu",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Wayun",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Sei/Sungai Paken",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Sire",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Ruhing Raya",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Sarimbuah",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Patas I",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Patas II",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Ngurit",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Palo Rejo",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Muka Haji",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Marga Jaya",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Muara Singan",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Kayumban",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Malungai Raya",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Gagutur",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Bipak Kali",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Baruang/Ekeng",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Gunung Bintang Awai",
                "kelurahan": "Bintang Ara",
                "kodepos": "73753"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Telekoi (Talekoi)",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Tamparak",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Tamparak Layung",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Tarusan",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Reong",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Sei/Sungai Telang",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Panarukan",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Pendang",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Rampa Mea",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Marawan Lama",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Maruga",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Majundre",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Marawan Baru",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Hingan",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Hulu Tampang",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Danau Bambure",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Gunung Rantau",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Bantai Bambure",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Utara",
                "kelurahan": "Bundar",
                "kodepos": "73752"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Sanggu",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Tanjung Jawa",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Penda Asam",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Sababilah",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Pararapak",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Muara Ripung",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Pamait",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Kalahien",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Mabuan",
                "kodepos": "73751"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Teluk Telaga",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Tetei Lanan",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Telang Andrau",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Teluk Mampun",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Pamangka",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Mangaris",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Muara Talang",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Murung Paken",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Lembeng",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Madara",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Jelapat",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Danau Sadar",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Dangka",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Danau Ganting",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Danau Masura",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Hilir Sper",
                "kodepos": "73712"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Baru",
                "kodepos": "73713"
            },
            {
                "kecamatan": "Dusun Selatan",
                "kelurahan": "Buntok Kota",
                "kodepos": "73711"
            }
        ],
        "k181": [
            {
                "kecamatan": "Awang",
                "kelurahan": "Wungkur Nanakan",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Pianggu",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Tangkan",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Janah Mansiwui",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Danau",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Hayaping",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Janah Jari",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Bangkirayen",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Biwan",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Ampari",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Awang",
                "kelurahan": "Apar Batu",
                "kodepos": "73681"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Tamiang",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Pulau Padang",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Ramania",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Lalap",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Muani",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Kotam",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Kambitin",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Jango",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Betang Nalong",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Ampari Bora",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Patangkep Tutui",
                "kelurahan": "Bentot",
                "kodepos": "73671"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Taniran Pasar Panas",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Tewah Pupuh",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Banyu Landas",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Gudang Seng",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Kandris",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Bagok",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Benua Lima",
                "kelurahan": "Bamban",
                "kodepos": "73661"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Tumpung Ulung",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Tuyau",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Sumberejo",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Nagaleah",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Pinang Tunggal",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Muara Palantau",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Muru Duyung",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Kupang Bersih",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Lampeong",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Lebo",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Bararawa",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Ketap",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Wuran",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Pematang Karau",
                "kelurahan": "Bambulung",
                "kodepos": "73653"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Turan Amis",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Unsum",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Tangkum",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Taringsing",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Sumber Garonggong",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Tampa",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Simpang Naneng",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Sibung",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Simpang Bingkuang/Bangkuang",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Saing",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Runggu Raya",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Rodok",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Putut Tawuluh",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Putai",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Puri",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Patung",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Pangkan",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Paku Beto",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Muara Awang",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Netapin / Netampin",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Malintot",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Luau Jawuk",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Kupang Baru",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Lagan",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Lenggang",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Kandris",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Kalamus",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Ipu Mea",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Gandrung",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Karusen Janang",
                "kelurahan": "Dayu",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Baruyan",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Raren Batuah",
                "kelurahan": "Batuah",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Paku",
                "kelurahan": "Bantai Napu",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Ampah Dua",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Tengah",
                "kelurahan": "Ampah Kota",
                "kodepos": "73652"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Sumur",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Maragut",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Matarah",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Gumpa",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Tampulangit",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Harara",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Telang Baru",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Telang Siong",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Siong",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "angkarap",
                "kodepos": "73618"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Sarapat (Ex. Harara)",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Tamiang Layang",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Murutuwu",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Pulau Patai",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Karang Langit",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Maipe",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Jaweten",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Juru Banu",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Kali Napu",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Jaar",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Didi",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Paju Epat",
                "kelurahan": "Balawa",
                "kodepos": "73617"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Dorong",
                "kodepos": "73614"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Haringen",
                "kodepos": "73615"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Magantis",
                "kodepos": "73612"
            },
            {
                "kecamatan": "Dusun Timur",
                "kelurahan": "Matabu",
                "kodepos": "73613"
            }
        ],
        "k182": [
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Petuk Berunai",
                "kodepos": "73229"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Pager",
                "kodepos": "73229"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Mungku Baru",
                "kodepos": "73229"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Bukit Sua",
                "kodepos": "73229"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Gaung Baru",
                "kodepos": "73229"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Petuk Bukit",
                "kodepos": "73227"
            },
            {
                "kecamatan": "Rakumpit",
                "kelurahan": "Panjehang",
                "kodepos": "73228"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Kanarakan",
                "kodepos": "73226"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Banturung",
                "kodepos": "73224"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sei/Sungai Gohong",
                "kodepos": "73225"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Tumbang Tahai",
                "kodepos": "73223"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Tangkiling",
                "kodepos": "73221"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Marang",
                "kodepos": "73222"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Habaring Hurung",
                "kodepos": "73221"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Kameloh Baru",
                "kodepos": "73114"
            },
            {
                "kecamatan": "Jekan Raya",
                "kelurahan": "Petuk Katimpun",
                "kodepos": "73118"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Kalampangan",
                "kodepos": "73114"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Bereng Bengkel",
                "kodepos": "73114"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Danau Tundai",
                "kodepos": "73114"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Kereng Bangkirai",
                "kodepos": "73113"
            },
            {
                "kecamatan": "Sebangau",
                "kelurahan": "Sabaru",
                "kodepos": "73113"
            },
            {
                "kecamatan": "Jekan Raya",
                "kelurahan": "Palangka",
                "kodepos": "73112"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Tumbang Rungan",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Jekan Raya",
                "kelurahan": "Bukit Tunggal",
                "kodepos": "73112"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Panarung",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Tanjung Pinang",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Pahandut",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Pahandut Seberang",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Jekan Raya",
                "kelurahan": "Menteng",
                "kodepos": "73111"
            },
            {
                "kecamatan": "Pahandut",
                "kelurahan": "Langkai",
                "kodepos": "73111"
            }
        ],
        "k183": [
            {
                "kecamatan": "Derawan (Pulau Derawan)",
                "kelurahan": "Teluk Semanting",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Derawan (Pulau Derawan)",
                "kelurahan": "Pulau Derawan",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Derawan (Pulau Derawan)",
                "kelurahan": "Tanjung Batu",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Derawan (Pulau Derawan)",
                "kelurahan": "Pegat Bertumbuk",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Maratua",
                "kelurahan": "Maratua Teluk Alulu",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Maratua",
                "kelurahan": "Maratua Teluk Harapan",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Maratua",
                "kelurahan": "Maratua Payung-Payung",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Maratua",
                "kelurahan": "Maratua Bohesilian",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Derawan (Pulau Derawan)",
                "kelurahan": "Kasai",
                "kodepos": "77381"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Teluk Sumbang",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Tanjung Perepat",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Teluk Sulaiman",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Lobang Kelatak",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Pantai Harapan",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Giring-Giring",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Batu Putih",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Biduk-Biduk",
                "kelurahan": "Biduk-Biduk",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Ampen Medang",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Balik Kukuk (Balikukup)",
                "kodepos": "77373"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Tunggal Bumi",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Tubaan",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Tembudan",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Tabalar Ulu",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Talisayan",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Tabalar Muara",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Sumber Mulia",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Sumber Agung",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Suka Murya",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Semurut",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Purna Sari Jaya",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Kayu Indah",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Harapan Maju",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Karangan",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Dumaring",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Eka Sapta",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Tabalar",
                "kelurahan": "Buyung-Buyung (Radak)",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Campur Sari",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Talisayan",
                "kelurahan": "Bumi Jaya",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Biatan Ulu",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Bukit Makmur Jaya",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Biatan Ilir",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Biatan Lempake",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Biatan Bapinang",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Biatan",
                "kelurahan": "Biatan Baru",
                "kodepos": "77372"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Tanjung Perangat",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Tumbit Dayak",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Suaran",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Sukan Tengah",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Sambaliung",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Pesayan",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Pilanjau",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Rantau Panjang",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Long Lanuk",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Pegat Bukur",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Gurimbang",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Inaran",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Bebanir (Sei Bebanir Bangun)",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Sambaliung",
                "kelurahan": "Bena Baru",
                "kodepos": "77371"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Panaan",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Panaan",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Merasa",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Muara Lesan",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Merapun",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Sului",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Mapulu",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Merabu",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Lamcin",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Pelay",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Beliu",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Duhung",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Long Keluh",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Kelay",
                "kelurahan": "Lesan Dayak",
                "kodepos": "77362"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Punan Malinau",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Punan Segah",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Tepian Buah",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Pandan Sari",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Punan Mahkam",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Long Ayan",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Long Ayap",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Long Laay/Laai",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Gunung Sari",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Harapan Jaya",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Tasuk",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Segah",
                "kelurahan": "Bukit Makmur",
                "kodepos": "77361"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Sambakungan",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Samburakat",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Merancang Ilir",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Merancang Ulu",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Pulau Besing",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Maluang",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Melati Jaya",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Labanan Jaya",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Labanan Makarti",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Labanan Makmur",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Birang",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Gunung Tabur",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Tumbit Melayu",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Gunung Tabur",
                "kelurahan": "Batu-Batu",
                "kodepos": "77352"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Teluk Bayur",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Tanjung Redeb",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Karang Ambun",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Sei/Sungai Bedungun",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Gayam",
                "kodepos": "77315"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Bugis",
                "kodepos": "77312"
            },
            {
                "kecamatan": "Teluk Bayur",
                "kelurahan": "Rinding",
                "kodepos": "77313"
            },
            {
                "kecamatan": "Tanjung Redeb",
                "kelurahan": "Gunung Panjang",
                "kodepos": "77311"
            }
        ],
        "k184": [
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Sidodadi",
                "kodepos": "77111"
            },
            {
                "kecamatan": "Palaran",
                "kelurahan": "Bantuas",
                "kodepos": "75253"
            },
            {
                "kecamatan": "Palaran",
                "kelurahan": "Rawa Makmur",
                "kodepos": "75243"
            },
            {
                "kecamatan": "Palaran",
                "kelurahan": "Simpang Pasir",
                "kodepos": "75243"
            },
            {
                "kecamatan": "Palaran",
                "kelurahan": "Bukuan",
                "kodepos": "75241"
            },
            {
                "kecamatan": "Palaran",
                "kelurahan": "Handil Bakti",
                "kodepos": "75242"
            },
            {
                "kecamatan": "Loa Janan Ilir",
                "kelurahan": "Tani Aman",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Samarinda Seberang",
                "kelurahan": "Mesjid",
                "kodepos": "75133"
            },
            {
                "kecamatan": "Loa Janan Ilir",
                "kelurahan": "Sengkotek",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Loa Janan Ilir",
                "kelurahan": "Simpang Tiga (Loa Janan Ilir)",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Samarinda Seberang",
                "kelurahan": "Sei/Sungai Keledang",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Loa Janan Ilir",
                "kelurahan": "Rapak Dalam",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Samarinda Seberang",
                "kelurahan": "Baqa/Baka/Rapak Dalam",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Loa Janan Ilir",
                "kelurahan": "Harapan Baru",
                "kodepos": "75131"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Teluk Lerong Ilir",
                "kodepos": "75128"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Loa Buah",
                "kodepos": "75126"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Teluk Lerong Ulu",
                "kodepos": "75127"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Karang Asam Ilir",
                "kodepos": "75126"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Karang Asam Ulu",
                "kodepos": "75126"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Loa Bakung",
                "kodepos": "75126"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Karang Anyar",
                "kodepos": "75125"
            },
            {
                "kecamatan": "Sungai Kunjang",
                "kelurahan": "Lok Bahu",
                "kodepos": "75125"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Bukit Pinang",
                "kodepos": "75124"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Air Hitam",
                "kodepos": "75124"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Air Putih",
                "kodepos": "75124"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Dadi Mulya",
                "kodepos": "75123"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Gunung Kelua",
                "kodepos": "75123"
            },
            {
                "kecamatan": "Samarinda Ulu",
                "kelurahan": "Jawa",
                "kodepos": "75122"
            },
            {
                "kecamatan": "Samarinda Kota",
                "kelurahan": "Bugis",
                "kodepos": "75121"
            },
            {
                "kecamatan": "Samarinda Utara",
                "kelurahan": "Tanah Merah",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Temindung Permai",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Samarinda Utara",
                "kelurahan": "Sempaja Selatan",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Samarinda Utara",
                "kelurahan": "Sempaja Utara",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Gunung Lingai",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Mugirejo",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Bandara",
                "kodepos": "75119"
            },
            {
                "kecamatan": "Samarinda Utara",
                "kelurahan": "Sei/Sungai Siring",
                "kodepos": "75118"
            },
            {
                "kecamatan": "Samarinda Utara",
                "kelurahan": "Lempake",
                "kodepos": "75118"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sei/Sungai Pinang Dalam",
                "kodepos": "75117"
            },
            {
                "kecamatan": "Samarinda Kota",
                "kelurahan": "Sei/Sungai Pinang Luar",
                "kodepos": "75117"
            },
            {
                "kecamatan": "Samarinda Ilir",
                "kelurahan": "Sidomulyo",
                "kodepos": "75116"
            },
            {
                "kecamatan": "Samarinda Ilir",
                "kelurahan": "Pelita",
                "kodepos": "75117"
            },
            {
                "kecamatan": "Samarinda Ilir",
                "kelurahan": "Sidodamai",
                "kodepos": "75116"
            },
            {
                "kecamatan": "Samarinda Ilir",
                "kelurahan": "Sei/Sungai Dama",
                "kodepos": "75115"
            },
            {
                "kecamatan": "Sambutan",
                "kelurahan": "Makroman",
                "kodepos": "75115"
            },
            {
                "kecamatan": "Sambutan",
                "kelurahan": "Sambutan",
                "kodepos": "75115"
            },
            {
                "kecamatan": "Sambutan",
                "kelurahan": "Sindang Sari",
                "kodepos": "75114"
            },
            {
                "kecamatan": "Samarinda Ilir",
                "kelurahan": "Selili",
                "kodepos": "75114"
            },
            {
                "kecamatan": "Sambutan",
                "kelurahan": "Pulau Atas",
                "kodepos": "75114"
            },
            {
                "kecamatan": "Sambutan",
                "kelurahan": "Sei/Sungai Kapih",
                "kodepos": "75114"
            },
            {
                "kecamatan": "Samarinda Kota",
                "kelurahan": "Karang Mumus",
                "kodepos": "75113"
            },
            {
                "kecamatan": "Samarinda Kota",
                "kelurahan": "Pasar Pagi",
                "kodepos": "75111"
            },
            {
                "kecamatan": "Samarinda Kota",
                "kelurahan": "Pelabuhan",
                "kodepos": "75112"
            }
        ],
        "k185": [
            {
                "kecamatan": "Babulu",
                "kelurahan": "Sri Raharja",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Sumber Sari",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Sebakung Jaya",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Rawa Mulia",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Rintik",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Labangka",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Labangka Barat",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Gunung Makmur",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Gunung Mulia",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Babulu Laut",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Gunung Intan",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Waru",
                "kodepos": "76284"
            },
            {
                "kecamatan": "Babulu",
                "kelurahan": "Babulu Darat",
                "kodepos": "76285"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Bangun Mulya",
                "kodepos": "76284"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Sesulu",
                "kodepos": "76284"
            },
            {
                "kecamatan": "Waru",
                "kelurahan": "Api Api",
                "kodepos": "76284"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Mentawir",
                "kodepos": "76149"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Sepaku",
                "kodepos": "76148"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Karang Jinawi",
                "kodepos": "76148"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Wonosari",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Telemow",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Tengin Baru (Sepaku III)",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Sukaraja (Sepaku II)",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Suko Mulyo",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Pemaluan",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Semoi II",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Bukit Raya (Sepaku I)",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Bumi Harapan",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Argo Mulyo",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Binuang",
                "kodepos": "76147"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Pantai Lango",
                "kodepos": "76146"
            },
            {
                "kecamatan": "Sepaku",
                "kelurahan": "Maridan",
                "kodepos": "76146"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Jenebora",
                "kodepos": "76146"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Sepan",
                "kodepos": "76145"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Riko",
                "kodepos": "76145"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Petung",
                "kodepos": "76144"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Sotek",
                "kodepos": "76144"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Gersik",
                "kodepos": "76145"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Tanjung Tengah",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Bulu Minung",
                "kodepos": "76144"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Sidorejo",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Pejala",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Saloloang",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Sesumpu",
                "kodepos": "76142"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Giripurwa",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Kampung Baru",
                "kodepos": "76143"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Nipah Nipah",
                "kodepos": "76142"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Sei/Sungai Parit",
                "kodepos": "76142"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Lawe Lawe",
                "kodepos": "76142"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Penajam",
                "kodepos": "76141"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Gunung Seteleng",
                "kodepos": "76141"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Nenang",
                "kodepos": "76141"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Bukit Subur",
                "kodepos": "76141"
            },
            {
                "kecamatan": "Penajam",
                "kelurahan": "Giri Mukti",
                "kodepos": "76141"
            }
        ],
        "k186": [
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Sebakung Makmur",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Sebakung IV",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Sebakung",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Pinang Jatus",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Putang",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Munggu",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Perkuwin",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Petiku",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Muara Toyu",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Muaraadang II",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Muara Pias",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Muara Telake",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Mendik Karya",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Mendik Makmur",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Muara Lambakan",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Mendik",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Mendik Bhakti",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Makmur Jaya",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Maruat",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Gunungputar",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Kepala Telake",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Long Kali",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Tiwei",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Kali",
                "kelurahan": "Bente/Tualan",
                "kodepos": "76283"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Tajur",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Teluk Waru",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Sawit Jaya",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Sekurou Jaya",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Tajer Mulya",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Pait",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Samuntai",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Muara Adang I",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Olung",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Muara Adang",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Long Gelang",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Long Ikis",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Krayan Makmur",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Krayan Sentosa",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Lombok",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Kerta Bakti",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Krayan Bahagia",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Krayan Jaya",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Kayungo",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Kayungo Sari",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Brewe",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Bukit Seloka",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Jemparing",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Atang Pait",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Long Ikis",
                "kelurahan": "Belimbing",
                "kodepos": "76282"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Sandeley",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Rangan",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Pasir Mayang",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Pondong Baru",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Modang",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Padang Jaya",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Klempang Sari",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Kuaro",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Kendarom",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Kerta Bumi",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Keluang Lolo",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Keluang Paser Jaya",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Kuaro",
                "kelurahan": "Air Mati",
                "kodepos": "76281"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Suliliran Baru",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Sunge Batu",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Suatang Keteban",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Suliliran",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Suatang Baru",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Seniung Jaya",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Suatang",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Pasir Belengkong",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Sangkuriman",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Lempesu",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Olong Pinang",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Damit",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Laburan",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Laburan Baru",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Pasir Belengkong",
                "kelurahan": "Bekoso",
                "kodepos": "76271"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Tanjung Aru",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Tampakan",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Tebru Paser Damai",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Selengot",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Senipah",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Saing Prupuk",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Segendang",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Random",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Riwang",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Pengguren Jaya",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Petangis",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Lori",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Lomu",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Mengkudu",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Langgai",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Labuangkalo",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Kerang",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Kerang Dayo",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Batu Engau",
                "kelurahan": "Bai Jaya",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Keladen",
                "kodepos": "76261"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Uko",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Swan Slutung",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Sekuan Makmur",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Selerong",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Muara Payang",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Prayon",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Muara Kuaro",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Muara Langon",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Long Sayo",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Lusan",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Muara Komam",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Binangon",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Tanjung Pinang",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Komam",
                "kelurahan": "Batu Butok",
                "kodepos": "76253"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Suweto",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Songka",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Samurangau",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Sei/Sungai Terik",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Rantau Layung",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Rantau Buta",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Rantau Atas",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Rantau Bintungan",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Muser",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Luan (Dilibinti)",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Muara Andeh",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Libur Dinding",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Kasungai",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Legai",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Busui",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Muara Samu",
                "kelurahan": "Biu",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Batu Sopang",
                "kelurahan": "Batu Sopang",
                "kodepos": "76252"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Tapis",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Tepian Batang",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Tanah Grogot",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Tanah Periuk",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Senaken",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Sungai Langir",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Sei/Sungai Tuak",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Sempulang",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Pulau Rantau",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Rantau Panjang",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Pasir Baru",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Pepara",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Jone",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Muara Pasir",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Padang Pengrapat",
                "kodepos": "76251"
            },
            {
                "kecamatan": "Tanah Grogot",
                "kelurahan": "Janju",
                "kodepos": "76251"
            }
        ],
        "k187": [
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Batu Ampar",
                "kodepos": "76136"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Baru Tengah",
                "kodepos": "76132"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Baru Ulu",
                "kodepos": "76133"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Kariangau",
                "kodepos": "76134"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Margo Mulyo",
                "kodepos": "76131"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Baru Ilir",
                "kodepos": "76131"
            },
            {
                "kecamatan": "Balikpapan Barat",
                "kelurahan": "Marga Sari",
                "kodepos": "76131"
            },
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Karang Joang",
                "kodepos": "76127"
            },
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Gunung Samarinda Baru",
                "kodepos": "76128"
            },
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Graha Indah",
                "kodepos": "76129"
            },
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Muara Rapak",
                "kodepos": "76125"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Karang Jati",
                "kodepos": "76123"
            },
            {
                "kecamatan": "Balikpapan Utara",
                "kelurahan": "Gunung Samarinda",
                "kodepos": "76125"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Karang Rejo",
                "kodepos": "76124"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Sumber Rejo",
                "kodepos": "76124"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Gunung Sari Ulu",
                "kodepos": "76122"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Mekar Sari",
                "kodepos": "76122"
            },
            {
                "kecamatan": "Balikpapan Tengah",
                "kelurahan": "Gunung Sari Ilir",
                "kodepos": "76121"
            },
            {
                "kecamatan": "Balikpapan Timur",
                "kelurahan": "Lamaru",
                "kodepos": "76117"
            },
            {
                "kecamatan": "Balikpapan Timur",
                "kelurahan": "Teritip",
                "kodepos": "76118"
            },
            {
                "kecamatan": "Balikpapan Timur",
                "kelurahan": "Manggar",
                "kodepos": "76116"
            },
            {
                "kecamatan": "Balikpapan Timur",
                "kelurahan": "Manggar Baru",
                "kodepos": "76116"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Sepinggan",
                "kodepos": "76115"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Sepinggan Baru",
                "kodepos": "76115"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Sepinggan Raya",
                "kodepos": "76115"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Gunung Bahagia",
                "kodepos": "76114"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Sungai Nangka",
                "kodepos": "76114"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Damai Baru",
                "kodepos": "76114"
            },
            {
                "kecamatan": "Balikpapan Selatan",
                "kelurahan": "Damai Bahagia",
                "kodepos": "76114"
            },
            {
                "kecamatan": "Balikpapan Kota",
                "kelurahan": "Klandasan Ilir",
                "kodepos": "76113"
            },
            {
                "kecamatan": "Balikpapan Kota",
                "kelurahan": "Damai",
                "kodepos": "76114"
            },
            {
                "kecamatan": "Balikpapan Kota",
                "kelurahan": "Telaga Sari",
                "kodepos": "76111"
            },
            {
                "kecamatan": "Balikpapan Kota",
                "kelurahan": "Klandasan Ulu",
                "kodepos": "76112"
            },
            {
                "kecamatan": "Balikpapan Kota",
                "kelurahan": "Prapatan",
                "kodepos": "76111"
            }
        ],
        "k188": [
            {
                "kecamatan": "Anggana",
                "kelurahan": "Sidomulyo",
                "kodepos": "76131"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Tanjung Batu",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Teluk Dalam",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Sukamaju",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Mulawarman",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Perjiwa",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Separi",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Loa Ulung",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Loa Lepu",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Loa Pari",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Loa Raya",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Kerta Buana",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Bukit Raya",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Embalut",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Karang Tunggal",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Bukit Pariaman",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Buana Jaya",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Tenggarong Seberang",
                "kelurahan": "Bangun Rejo",
                "kodepos": "75572"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Sepakat",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Sumber Sari",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Sei/Sungai Payang",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Ponoragan",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Rempanga",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Lung Anai",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Margahayu (Marga Rahayu)",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Loa Kulu Kota",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Jongkang",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Loh Sumber",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Karya Utama",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Jembayan Tengah",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Jonggon",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Jembayan Dalam",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Loa Kulu",
                "kelurahan": "Jembayan",
                "kodepos": "75571"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Tuana Tuha",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Tubuhan",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Semayang",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Teluk Bingkai",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Teluk Muda",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Lamin Pulut",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Lamin Telihan",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Kahala",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Kenohan",
                "kelurahan": "Kahala Ilir",
                "kodepos": "75564"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Rebaq Rinding",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Tanjung Batuq Harapan",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Perian",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Pulau Harapan",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Muara Muntai Ilir",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Muara Muntai Ulu",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Muara Leka",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Muara Aloh",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Jantur Selatan",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Kayu Batu",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Jantur",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Jantur Baru",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Muara Muntai",
                "kelurahan": "Batuq",
                "kodepos": "75562"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sukabumi",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sumber Sari",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Wonosari",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sebelimbingan",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sedulang",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sangkuliman",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Sarinadi",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Pela",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Loleng",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Muhuran",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun Ulu",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Liang Ilir",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Liang Ulu",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun Ilir",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun Seberang",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun II (2)",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun III (3)",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kedang Ipil",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kedang Murung",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Kota Bangun I (1)",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Kota Bangun",
                "kelurahan": "Benua Baru",
                "kodepos": "75561"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Muara Wis",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Sebemban",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Melintang",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Muara Enggelam",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Lebak Mantan",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Lebak Cilong",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Muara Wis",
                "kelurahan": "Enggelam",
                "kodepos": "75559"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Umaq Bekuay/Bekuai",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Umaq Dian",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Umaq Tukung",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Tabang Lama",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Tukung Ritan",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Ritan Baru",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Sidomulyo",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Tiq",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Tuboq",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Ritan",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Salung",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Kebaq",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Pedohon",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Long Lalang",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Muara Belinau",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Gunung Sari",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Kampung Baru",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Bila Talang",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Buluk Sen",
                "kodepos": "75558"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Pulau Pinang",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Muai",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Perdana",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Long Beleh Haloq",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Long Beleh Modang",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Loa Sakoh",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Kembang Janggut",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Hambau",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Kelekat",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Bukit Layang",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Kembang Janggut",
                "kelurahan": "Genting Tanah",
                "kodepos": "75557"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Tunjungan",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Sido Mukti",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Teratak",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Sedulang",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Rantau Hempang",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Sabintulung",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Puan Cepak",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Muara Siran",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Panca Jaya",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Muara Kaman Ilir",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Muara Kaman Ulu",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Menamang Kiri",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Liang Buaya",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Kupang Baru",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Menamang Kanan",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Lebaho Ulaq",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Bunga Jadi",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Cipari Makmur",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Benua Puhun",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Muara Kaman",
                "kelurahan": "Bukit Jering",
                "kodepos": "75553"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Sumber Sari",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Selerong",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Senoni",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Sebulu Ulu",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Segihan",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Sebulu Ilir",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Sebulu Modern",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Mekar Jaya",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Sanggulan",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Giri Agung",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Manunggal Daya",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Beloro",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Sebulu",
                "kelurahan": "Budaya Lekaq Kidau",
                "kodepos": "75552"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Sukarame",
                "kodepos": "75515"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Mangkurawang",
                "kodepos": "75517"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Loa Ipuh",
                "kodepos": "75513"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Panji",
                "kodepos": "75514"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Timbau",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Melayu",
                "kodepos": "75512"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Maluhu",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Rapak Lambur",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Jahab",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Loa Ipuh Darat",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Loa Tebu",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Bendang Raya",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Bukit Biru",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Tani Harapan",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Tenggarong",
                "kelurahan": "Baru (Kampung Baru)",
                "kodepos": "75511"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Purwajaya",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Tani Bakti",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Loa Janan Ulu",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Loa Duri Ilir",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Loa Duri Ulu",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Bakungan",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Loa Janan",
                "kelurahan": "Batuah",
                "kodepos": "75391"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Semangkok",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Santan Tengah",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Santan Ulu",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Sebuntal",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Sambera Baru",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Santan Ilir",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Perangat Selatan",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Makarti",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Perangat Baru",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Kersik",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Marang Kayu",
                "kelurahan": "Bunga Putih",
                "kodepos": "75385"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Tanjung Limau",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Suka Damai",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Tanah Datar",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Sei/Sungai Bawang",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Seliki",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Salo Cella",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Salo Palai",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Muara Badak Ulu",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Gas Alam Badak I",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Muara Badak Ilir",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Badak Mekar",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Batu Batu",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Tani Baru",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Muara Badak",
                "kelurahan": "Badak Baru",
                "kodepos": "75382"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Sepatin",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Muara Pantuan",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Sei/Sungai Meriam",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Handil Terusan",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Kutai Lama",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Anggana",
                "kelurahan": "Anggana",
                "kodepos": "75381"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Handil Baru",
                "kodepos": "75279"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Muara Sembilang",
                "kodepos": "75279"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Wonotirto",
                "kodepos": "75276"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Samboja Kuala",
                "kodepos": "75277"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Kampung Lama",
                "kodepos": "75276"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "75276"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Handil Baru Darat",
                "kodepos": "75276"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Ambarawang Darat",
                "kodepos": "75274"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Ambarawang Laut",
                "kodepos": "75274"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Teluk Pemedas",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Margomulyo",
                "kodepos": "75273"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Sei/Sungai Seluang",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Tani Bakti",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Sei/Sungai Merdeka",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Salok Api Darat",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Salok Api Laut",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Sanipah",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Karya Jaya",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Karya Merdeka",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Bukit Merdeka",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Bukit Raya",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Argosari",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Samboja (Semboja)",
                "kelurahan": "Beringin Agung",
                "kodepos": "75271"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Muara Kembang",
                "kodepos": "75267"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Dondang",
                "kodepos": "75265"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Tamapole",
                "kodepos": "75266"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Muara Jawa Ilir",
                "kodepos": "75263"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Muara Jawa Pesisir",
                "kodepos": "75263"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Muara Jawa Tengah",
                "kodepos": "75264"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Muara Jawa Ulu",
                "kodepos": "75261"
            },
            {
                "kecamatan": "Muara Jawa",
                "kelurahan": "Teluk Dalam",
                "kodepos": "75262"
            },
            {
                "kecamatan": "Sanga-Sanga",
                "kelurahan": "Pendingin",
                "kodepos": "75256"
            },
            {
                "kecamatan": "Sanga-Sanga",
                "kelurahan": "Sarijaya",
                "kodepos": "75251"
            },
            {
                "kecamatan": "Sanga-Sanga",
                "kelurahan": "Sanga-Sanga Dalam",
                "kodepos": "75254"
            },
            {
                "kecamatan": "Sanga-Sanga",
                "kelurahan": "Jawa",
                "kodepos": "75251"
            },
            {
                "kecamatan": "Sanga-Sanga",
                "kelurahan": "Sanga-Sanga Muara",
                "kodepos": "75251"
            }
        ],
        "k189": [
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Tripati Makmur",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Wana Pariq",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Sirau",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Laham",
                "kelurahan": "Nyaribungan",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Memahak Teboq",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Laham",
                "kelurahan": "Muara Ratah",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Long Hubung",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Lutan",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Mata Libaq",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Laham",
                "kelurahan": "Long Gelawang",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Datah Bilang Ulu",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Laham",
                "kelurahan": "Laham",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Datah Bilang Ilir",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Long Hubung",
                "kelurahan": "Datah Bilang Baru",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Laham",
                "kelurahan": "Danum Paroy/Paroi",
                "kodepos": "75779"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Tuquk/Tukuq",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Suakong",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Tende",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Randa Empas",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Sambung",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Dilang Puti",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Jelmu Sibak",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Penarung",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Bentian Besar",
                "kelurahan": "Anan Jaya",
                "kodepos": "75778"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Sempatn",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Temula",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Tepulang",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Sembuan",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Muara Tokong",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Muara Nilik",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Muara Nyahing",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Mendika",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Muara Bomboy",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Lumpat Dahuq",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Mantar",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Keay",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Jontai",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Jengan Danum",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Intu Lingau",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Damai Seberang",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Dempar",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Damai Kota",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Bermai",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Besiq (Besik)",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Damai",
                "kelurahan": "Benung",
                "kodepos": "75777"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Sumber Sari",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Terajuk",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Sendawar",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Simpang Raya",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Sentalar",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Pepas Eheng",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Pepas/Pepes Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Rejo Basuki",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Ombau Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Ongko Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Muara Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Ngenyan Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Mu&#8217;ut",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Lakan Bilem",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Juhan Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Mencimai",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Gemuhan Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Juaq Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Engkuni Pasek",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Geleo Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Geleo Baru",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Barong Tongkok",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Belempung Ulaq",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Balok Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Nyuatan",
                "kelurahan": "Awai",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Barong Tongkok",
                "kelurahan": "Asa",
                "kodepos": "75776"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Payang",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Muara Lawa",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Muara Begai",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Lambing",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Lataq/Lotaq",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Cempedas",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Tepian Ulaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Dingin",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Tendiq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Lawa",
                "kelurahan": "Benggeris",
                "kodepos": "75775"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Teluk Tempudau",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Tanjung Pagar",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Tebisaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Tanjung Laong",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Tanah Mea (Tana Mea)",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Sebelang",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Rikong",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Sang Sang (Sansang)",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Rembayan / Rambayan",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Penawang",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Muhur",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Muara Kelawit",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Muara Ponaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Muara Jawaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Muara Baroh",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Muara Beloan",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Mendung",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Lendian Liang Nayuk/Nayung",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Kenyanyan",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Kiaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Kaliq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Kendisiq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Jerang Dayak",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Jerang Melayu",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Dasaq",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Muara Pahu",
                "kelurahan": "Gunung Bayan",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Bentas",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Siluq Ngurai",
                "kelurahan": "Betung",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Abit",
                "kodepos": "75774"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Tanjung Jan",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Tanjung Jone",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Pulau Lanting",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Tanjung Isuy",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Muara Tae",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Pentat",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Perigiq",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Mancong",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Muara Nayan",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Muara Ohong",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Bekokong Makmur (Resak Bekokong)",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Jempang",
                "kelurahan": "Lembonah",
                "kodepos": "75773"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Tanjung Sari",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Tanjung Soke",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Resak",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Penawai",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Pereng Talik",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Muaram Siram I (Siram Makmur)",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Muaram Siram II (Siram Jaya)",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Muara Kedang",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Muara Siram",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Lemper",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Muara Gusik",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Jambuk",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Jambuk Makmur",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Gerunggung",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Bukit Harapan",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Bongan",
                "kelurahan": "Deraya",
                "kodepos": "75772"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Tiong Ohang",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Tiong Buu",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Noha Tifab (Naha Tifab)",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Long Penaneh Tiga",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Naha Buan",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Noha Silat",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Long Kerioq/Keriok",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Long Penaneh Dua",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Long Penaneh Satu",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Apari",
                "kelurahan": "Long Apari",
                "kodepos": "75769"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Tuyoq",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Naha Aru",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Pakaq Baru",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Pahangai Satu",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Pakaq",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Lunuk",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Lirung Ubing",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Lunuk Baru",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Ujoh Bilang",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Pahangai Dua",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Datah Naha",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Liu Mulang",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Mamahak Ulu",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Long Isun",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Rukun Damai",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Pahangai",
                "kelurahan": "Delang Kerohong",
                "kodepos": "75768"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Long Merah",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Mamahak Ilir",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Long Hurai",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Long Melaham",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Long Bagun Ilir",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Long Bagun Ulu",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Batoq Kelo (Batu Kelau)",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Purworejo",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Bagun",
                "kelurahan": "Batu Majang",
                "kodepos": "75767"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Muyub/Muyup Ilir",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Ujoh Halang",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Muyub/Muyup Ulu",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Tukul",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Muara Mujan",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Tering Baru",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Muyub/Muyup Aket",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Tering Lama",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Muara Leban",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Tering Seberang",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Long Iram Kota",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Suko Mulyo",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Longiram Seberang",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Tering",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Long Daliq",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Long Iram Bayan",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Long Iram Ilir",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Kelubaq",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Kelian Dalam",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Keliway",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Kalian Luar",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sri Mulyo",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Tering",
                "kelurahan": "Jelemuq/Jelmuq",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sekolaq Joleq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Long Iram",
                "kelurahan": "Anah",
                "kodepos": "75766"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sekolaq Muliaq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sumber Bangun",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Sakaq Tada",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sumber Rejo",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sekolaq Darat",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Sekolaq Darat",
                "kelurahan": "Sekolaq Oday",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Muara Kalaq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Sakaq Lotoq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Muara Benangaq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Muara Bunyut",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Merayaq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Muara Batuq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Melak Ilir",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Melak Ulu",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Kelumpang",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Linggang Marimun",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Jengan",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Karangan",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Gunung Rampah",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Gemuruh",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Manor Bulatin (Mook Manaar Bulatn)",
                "kelurahan": "Gadur",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Empakuq",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Melak",
                "kelurahan": "Empas",
                "kodepos": "75765"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Penyinggahan Ulu",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Tanjung Haur",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Minta",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Penyinggahan Ilir",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Bakung",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Penyinggahan",
                "kelurahan": "Loa Deras",
                "kodepos": "75763"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Purwadadi",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Tutuh (Tutung)",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Melapeh Baru",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Mapan",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Melapeh",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Mencelew",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Bigung",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Kebut",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Bigung Baru",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Linggang Amer",
                "kodepos": "75576"
            },
            {
                "kecamatan": "Linggang Bigung",
                "kelurahan": "Bangun Sari",
                "kodepos": "75576"
            }
        ],
        "k190": [
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Tadoan",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Tanjung Mangkalihat",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Susuk Dalam",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Susuk Luar",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Sandaran",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Marukangan",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sandaran",
                "kelurahan": "Manubar",
                "kodepos": "75685"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Sempuyau (Sempayau)",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Tanjung Manis",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Tepian Terap",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Perupuk",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Pulau Miang",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Saka",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Pengadan (Pengadaan Baru)",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Peridan",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Mukti Lestari",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Mandu Dalam",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Pelawan",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Maloy",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Mandau Pantai Sejahtera",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Karangan Seberang",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Kerayaan",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Kolek",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Karangan Dalam",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Karangan Hilir",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Benua Baru Ulu",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Batu Lepoq",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Sangkulirang",
                "kelurahan": "Benua Baru Ilir",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Karangan",
                "kelurahan": "Baay",
                "kodepos": "75684"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Tepian Makmur",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Selatan",
                "kelurahan": "Teluk Singkam / Singkama",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Teluk Pandan",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Utara",
                "kelurahan": "Teluk Lingga",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Suka Rahmat",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Utara",
                "kelurahan": "Swarga Bara",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Tanjung Labu",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Selatan",
                "kelurahan": "Singa Geweh",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Suka Damai",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Selatan",
                "kelurahan": "Sangkima",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Utara",
                "kelurahan": "Singa Gembara",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Selatan",
                "kelurahan": "Sangata Selatan",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Sangatta Utara",
                "kelurahan": "Sangatta Utara",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Rantau Makmur",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Mukti Jaya",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Pulung Sari",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Martadinata",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Margo Mulyo",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Kebon Agung",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Rantau Pulung",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Kandolo",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Teluk Pandan",
                "kelurahan": "Danau Redan",
                "kodepos": "75683"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Sika Makmur",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Senyiur",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Sumber Sari",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Segoi Makmur",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Mukti Utama",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Muara Dun",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Melan",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Long Nah",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Long Tesak",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Kelinjau Ilir",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Long Faq/Poq Baru",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Gemar Baru",
                "kodepos": "75656"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Wana Sari",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Nehesliah Bing",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Wahau Baru",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Muara Wahau",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Long Wehea",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Karya Bakti",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Diaq Lay",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Jak Luay",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Benhes",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Muara Wahau",
                "kelurahan": "Dabeq",
                "kodepos": "75655"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Telaga",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Tanah Abang",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Long Mesangat (Mesengat)",
                "kelurahan": "Sumber Agung",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Mulupan",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Ngayau",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Senambah",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Mugi Rahayu",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Muara Bengkal Ilir",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Muara Bengkal Ulu",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Mawai Indah",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Himba Lestari",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Benua Harapan",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Benua Baru",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Batu Timbau",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Muara Bengkal",
                "kelurahan": "Batu Balai",
                "kodepos": "75654"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Kadungan Jaya",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Mata Air",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Pengadan Baru",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Bumi Rapak",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Cipta Graha",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Bumi Etam",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Bumi Jaya",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Tepian Langsat",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaubun",
                "kelurahan": "Bukit Permata",
                "kodepos": "75619"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Tepian Baru",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Tepian Indah",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Sepaso Timur",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Tebangan Lebak/Lembak",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Sepaso Induk",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Sepaso Selatan",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Sepaso Barat",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Selangkau",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Sekerat",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Keraitan",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Bengalon",
                "kelurahan": "Muara Bengalon",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Citra Manunggal Jaya",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Kaliorang",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Bukit Makmur",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Bumi Sejahtera",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Bangun Jaya",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Kaliorang",
                "kelurahan": "Bukit Harapan",
                "kodepos": "75618"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Mekar Baru",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Rantau Sentosa",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Long Nyelong",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Long Pejeng",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Long Bentuk",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Busang",
                "kelurahan": "Long Lees",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Muara Ancalong",
                "kelurahan": "Kelinjau Ulu",
                "kodepos": "75556"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Sri Pantun",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Suka Maju",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Rantau Panjang",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Sidomulyo",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Muara Pantun",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Miau Baru",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Marah Haloq",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Marah Kenyah (Long Melah)",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Marga Mulia",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Makmur Jaya",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Long Noran",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Long Segar",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Telen",
                "kelurahan": "Juk Ayak",
                "kodepos": "75555"
            },
            {
                "kecamatan": "Kongbeng",
                "kelurahan": "Kongbeng Indah",
                "kodepos": "75555"
            }
        ],
        "k191": [
            {
                "kecamatan": "Bontang Barat",
                "kelurahan": "Telihan (Gunung Telihan)",
                "kodepos": "75383"
            },
            {
                "kecamatan": "Bontang Barat",
                "kelurahan": "Kanaan",
                "kodepos": "75383"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Tanjung Laut Indah",
                "kodepos": "75325"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Berebas Pantai (Berbas Pantai)",
                "kodepos": "75325"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Berebas Tengah (Berbas Tengah)",
                "kodepos": "75325"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Bontang Lestari",
                "kodepos": "75325"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Satimpo",
                "kodepos": "75324"
            },
            {
                "kecamatan": "Bontang Selatan",
                "kelurahan": "Tanjung Laut",
                "kodepos": "75321"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Guntung",
                "kodepos": "75314"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Loktuan (Lok Tuan)",
                "kodepos": "75314"
            },
            {
                "kecamatan": "Bontang Barat",
                "kelurahan": "Belimbing",
                "kodepos": "75313"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Bontang Kuala",
                "kodepos": "75312"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Bontang Baru",
                "kodepos": "75311"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Gunung Elai",
                "kodepos": "75311"
            },
            {
                "kecamatan": "Bontang Utara",
                "kelurahan": "Api-Api",
                "kodepos": "75311"
            }
        ],
        "k192": [
            {
                "kecamatan": "Kayan Selatan",
                "kelurahan": "Metulang",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Kayan Selatan",
                "kelurahan": "Sungai Barang",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Mahak Baru",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Kayan Selatan",
                "kelurahan": "Long Uro",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Long Lebusan",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Long Top",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Kayan Selatan",
                "kelurahan": "Long Ampung",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Kayan Selatan",
                "kelurahan": "Lidung Payau",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Data Baru",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Dumu Mahak",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Sungai Boh",
                "kelurahan": "Agung Baru",
                "kodepos": "77573"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Long Temunyat/Temuyat",
                "kodepos": "77572"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Nawang Baru",
                "kodepos": "77572"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Long Nawang",
                "kodepos": "77572"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Long Payau",
                "kodepos": "77572"
            },
            {
                "kecamatan": "Kayan Hulu",
                "kelurahan": "Long Betaoh",
                "kodepos": "77572"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Metun (Long Metun I)",
                "kodepos": "77571"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Sei/Sungai Anai",
                "kodepos": "77571"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Long Metun (Long Metun II)",
                "kodepos": "77571"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Long Pipa",
                "kodepos": "77571"
            },
            {
                "kecamatan": "Kayan Hilir",
                "kelurahan": "Data Dian",
                "kodepos": "77571"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Long Uli",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Long Tabulo/Tebulo",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Pujungan",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Paliran",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Pua",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Long Berini Mararian",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Jelet",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Ketaman",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Belaka Pitau",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Bena",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Long Atua/Long Kemuat",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Long Aran",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Long Alango",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Pujungan",
                "kelurahan": "Lame",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Bahau Hulu",
                "kelurahan": "Apau Ping Mading",
                "kodepos": "77562"
            },
            {
                "kecamatan": "Sungai Tubu",
                "kelurahan": "Rian Tubu",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Temalang",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Paking",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Pulau Sapi",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Sungai Tubu",
                "kelurahan": "Long Titi",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Mentarang Baru",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Sungai Tubu",
                "kelurahan": "Long Pada",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Sungai Tubu",
                "kelurahan": "Long Ranau",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Sungai Tubu",
                "kelurahan": "Long Nyau",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Long Liku",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Long Bisai",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Long Gafid",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Lidung Kemenci",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Taras",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Mentarang",
                "kelurahan": "Harapan Maju",
                "kodepos": "77555"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Tanjung Lapang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Tanjung Nanga",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Setulang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Tanjung Keranjang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Sesua",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Setarap",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Seruyung",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Sentaban",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Sengayan",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Sempayang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Sembuak Warot/Warod",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Respen Tubu",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Salap",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Semanggaris",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Punan Rian",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Putat",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Punan Setarap",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Punan Long Adiu",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Punan Mirau",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Punan Bengalun",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Punan Gong Solok",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Pelita Kanaan",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Pelencau",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Nunuk Tanah Kibang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Paya Seturan",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Metut",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Nahakramo Baru",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Malinau Kota",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Malinau Seberang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Malinau Hilir",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Malinau Hulu",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Lubok Manis",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Luso",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Long Loreh",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Long Rat",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Long Kenipe",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Long Lake",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Long Bila",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Long Jalan",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Laban Nyarit",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Langap",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Long Adiu",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Barat",
                "kelurahan": "Kuala Lapang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Kaliamok",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Kelapis",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hulu",
                "kelurahan": "Halanga",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan",
                "kelurahan": "Bila Bekayuk",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Gong Solok",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Utara",
                "kelurahan": "Belayan",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Kota",
                "kelurahan": "Batu Lidung",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Malinau Selatan Hilir",
                "kelurahan": "Batu Kajang",
                "kodepos": "77554"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Sulit",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Semamu",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Simau",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Mekatif",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Pala",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Berang",
                "kodepos": "77155"
            },
            {
                "kecamatan": "Mentarang Hulu",
                "kelurahan": "Long Kebinu",
                "kodepos": "77155"
            }
        ],
        "k193": [
            {
                "kecamatan": "Sebatik",
                "kelurahan": "Tanjung Karang",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Timur",
                "kelurahan": "Tanjung Aru",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Timur",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik",
                "kelurahan": "Sungai Manurung",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Barat",
                "kelurahan": "Setabu",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Utara",
                "kelurahan": "Sei/Sungai Pancang",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Timur",
                "kelurahan": "Sei/Sungai Nyamuk",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Tengah",
                "kelurahan": "Sei Limau",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Utara",
                "kelurahan": "Seberang",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik",
                "kelurahan": "Padaidi",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Tengah",
                "kelurahan": "Maspul",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Barat",
                "kelurahan": "Liang Bunyu",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Utara",
                "kelurahan": "Lapri",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Tengah",
                "kelurahan": "Bukit Harapan",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Barat",
                "kelurahan": "Binalawan",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Timur",
                "kelurahan": "Bukit Aru Indah",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik Barat",
                "kelurahan": "Bambangan",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik",
                "kelurahan": "Balansiku",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Sebatik",
                "kelurahan": "Balansiku",
                "kodepos": "77483"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Tinampak I",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Tinampak II",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Tembalang",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Tetaban",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan Selatan",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Tau Baru",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sei Menggaris",
                "kelurahan": "Tabur Lestari",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sei Menggaris",
                "kelurahan": "Srinanti",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Sujau",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan Selatan",
                "kelurahan": "Selisun",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Semunad",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sei Menggaris",
                "kelurahan": "Sekaduyan Taka",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Sekikilan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Sebuku I (Sanur)",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Sebuku II (Makmur)",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sei Menggaris",
                "kelurahan": "Samaenre Semaja",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Salang",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan",
                "kelurahan": "Nunukan Utara",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Pembeliangan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan",
                "kelurahan": "Nunukan Tengah",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan",
                "kelurahan": "Nunukan Timur",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan",
                "kelurahan": "Nunukan Barat",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan Selatan",
                "kelurahan": "Nunukan Selatan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Naputi",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Melasu Baru",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan Selatan",
                "kelurahan": "Mansapa",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Lulu",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Kunyit",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Kulunsayan (Kalun Sayan)",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Harapan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Kekayap",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Tulin Onsoi",
                "kelurahan": "Blatikon (Belatikan)",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Nunukan",
                "kelurahan": "Binusan",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Bebanas",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Sebuku",
                "kelurahan": "Apas",
                "kodepos": "77482"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Ubel Sulok",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tukulen (Tukulon)",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tumatalas",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Tubus",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Tubus",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tau Lumbis",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tetagas",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tantalujuk",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tantu Libing",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Tanjung Hulu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tambalang Ulu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Tanjung Hilir",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Suyadon",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Tambalang Hilir",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Taluan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sungoi",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sumantipal",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sumentobol",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sinampala Satu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Sumalumung",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sibalu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sinampala Dua",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Semata",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Siawang",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sedalit",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Sedongon",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Semalat",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Sapuyan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Sasibu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Sanal",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Sangkup",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Samunti",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Saludan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Salan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Podong",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Pulu Bulawan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Payang",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Patal II (Dua)",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Patal I (Satu)",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Pa Loo",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Paluan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Panas",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Pa Lemumut",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Ngawol",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Nan Sapan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Nantukidan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Mansalong",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Nainsid",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Mamanis",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Long Bulu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Lintong",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Linsayung",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Likos",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Limpakon",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Liang",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Libang",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Langgason",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Lepaga",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Labuk",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Lagas",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Kuyo",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Labang",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Kalampising",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Kalisun",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Jukup",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Kabungalor",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Kalam Buku",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Deralon",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Duyan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Dabulon (Dubulon)",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Bulu Luan Hulu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Bulu Mengelom",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Bulu Laun Hulu",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis",
                "kelurahan": "Bulan Bulan",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Bulu Laun Hilir",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Batung",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Lumbis Ogong",
                "kelurahan": "Bokok",
                "kodepos": "77457"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Wa Laya",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Wa Yagung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Wa Yanud",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Tang Badui",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Tang Paye",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Sembudud",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Yalau",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Sinar Baru",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Urud",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Urang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Upan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Terutun",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Umung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Sire (Pasire)",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Sing",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Tera",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Rangeb (Parangeb)",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Raye",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Rupai",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Pirit",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Putuk",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Payak",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Pani",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Pawan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Nado",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Padi",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Pala",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Milau",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Mulak",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Malade (Pa Melade)",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Matung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Mering",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Lidung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Lutut",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Kemut",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Kidang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Kaber",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Inan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Kebuan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Ibang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Delung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Dalan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Betung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Butal",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Pa Api",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Pa Amai",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Umung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Ma Libu",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Tenem",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Tugul",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Rupan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Sepayang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Rian",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Rungan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Pasia",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Pupung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Puak",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Padi",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Nawang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Nuat",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Mutan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Matung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Kelupan / Klupan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Mangan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Kabid",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Budung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Katung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Bia Dung",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Long Birar",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Bawan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Long Berayang",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Liang Tuer",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Liang Turan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Liang Lunuk",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Liang Bua",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Liang Butan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Lepatar",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Liang Alig",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Lembudud",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Lembada",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Cinglat",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Kampung Baru",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Bungayan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Buduk Kubul",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Buduk Tumu",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Buduk Kinangan",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan",
                "kelurahan": "Ba Sikor",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Binuang (Ba Binuang)",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Krayan Selatan",
                "kelurahan": "Ba Liku",
                "kodepos": "77456"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Tujung",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Tulang",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Tepian",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Tagul",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Saduman",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Sabuluan",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Pulau Keras",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Pelaju (Plaju)",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Pagar",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Manuk Bungkul",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Pagaluyon",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Mambulu",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Lubok Buat",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Lubakan",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Liuk Bulu",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Labuk",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Katul",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Binanun",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Butas Bagu",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sembakung",
                "kelurahan": "Atap",
                "kodepos": "77453"
            }
        ],
        "k194": [
            {
                "kecamatan": "Tana Lia (Tanah Lia)",
                "kelurahan": "Tengku Dacing",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Tana Lia (Tanah Lia)",
                "kelurahan": "Tanah Merah",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Tana Lia (Tanah Lia)",
                "kelurahan": "Sambungan",
                "kodepos": "77453"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Tideng Pale Timur",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Tideng Pale",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Seputuk",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Sepala Dalung (Sesayap Dalung)",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Sesayap/Sepala",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Seludau",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Sengkong",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Sebidai/Sebiday",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Sedulun",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Rian",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Sebawang",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Mendupe/Mendopu",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Periuk",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Maning",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Mendelutung (Menjelutung)",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Kujau",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Limbu Sedulun/Sidulun",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Buang Baru (Buong Baru)",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap",
                "kelurahan": "Gunawan",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Bebatu",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Betayau",
                "kelurahan": "Bebakung",
                "kodepos": "77152"
            },
            {
                "kecamatan": "Sesayap Hilir",
                "kelurahan": "Badan Bikis",
                "kodepos": "77152"
            }
        ],
        "k195": [
            {
                "kecamatan": "Pulau Bunyu",
                "kelurahan": "Pulau Bunyu Timur",
                "kodepos": "77281"
            },
            {
                "kecamatan": "Pulau Bunyu",
                "kelurahan": "Pulau Bunyu Barat",
                "kodepos": "77281"
            },
            {
                "kecamatan": "Pulau Bunyu",
                "kelurahan": "Pulau Bunyu Selatan",
                "kodepos": "77281"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Terindak",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Turung",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Ujang",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Sekatak Buji",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Tenggiling",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Pungit",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Sekatak Bengara",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Pentian",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Punan Dulau",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Maritam",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Paru Abang",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Liu Agu (Liagu)",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Liu Agu (Liagu)",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Kelising (Keliising)",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Kelumbunan (Kelembunan)",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Kendari",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Kelincawan",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Bekiliu",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Bunau",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Anjar Arip (Anjararef)",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Bambang",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Naha Aya",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Sekatak",
                "kelurahan": "Ambalat",
                "kodepos": "77263"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Yiin/Pelaah",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Muara Pengean",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Peso",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Long Telenjau",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Long Tungu",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Lian",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Peleban",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Leju",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Long Lembu",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Buang",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Lasan",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Long Bia",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Long Bang Hulu",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Peso Hilir/Ilir",
                "kelurahan": "Long Bang",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Tanjung Palas Barat",
                "kelurahan": "Mara Satu",
                "kodepos": "77217"
            },
            {
                "kecamatan": "Peso",
                "kelurahan": "Lepak Aru",
                "kodepos": "77261"
            },
            {
                "kecamatan": "Tanjung Palas Barat",
                "kelurahan": "Long Sam",
                "kodepos": "77217"
            },
            {
                "kecamatan": "Tanjung Palas Barat",
                "kelurahan": "Mara Hilir",
                "kodepos": "77217"
            },
            {
                "kecamatan": "Tanjung Palas Barat",
                "kelurahan": "Long Beluah",
                "kodepos": "77217"
            },
            {
                "kecamatan": "Tanjung Palas Barat",
                "kelurahan": "Long Pari",
                "kodepos": "77217"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Tanjung Palas Tengah",
                "kodepos": "77216"
            },
            {
                "kecamatan": "Tanjung Palas Tengah",
                "kelurahan": "Tanjung Buka",
                "kodepos": "77216"
            },
            {
                "kecamatan": "Tanjung Palas Tengah",
                "kelurahan": "Salim Batu",
                "kodepos": "77216"
            },
            {
                "kecamatan": "Tanjung Palas Tengah",
                "kelurahan": "Silva Rahayu",
                "kodepos": "77216"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Wono Mulyo",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Tanjung Palas Ulu/Hulu",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Tanah Kuning",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Tanjung Agung",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Sajau",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Sajau Timur",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Ruhui Rahayu",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Pura Sajau",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Pimping",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Panca Agung",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Kelubir",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Mangkupadi",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Karang Agung",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Timur",
                "kelurahan": "Binai",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas Utara",
                "kelurahan": "Ardi Mulya",
                "kodepos": "77215"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Teras Nawang",
                "kodepos": "77214"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Karang Anyar",
                "kodepos": "77214"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Teras Baru",
                "kodepos": "77214"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Tanjung Selor Timur",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Tengkapak",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Jelarai Selor",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Tanjung Selor Ilir/Hilir",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Gunung Sari",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Gunung Seriang",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Apung",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Apung",
                "kodepos": "77212"
            },
            {
                "kecamatan": "Tanjung Selor",
                "kelurahan": "Tanjung Selor Ulu/Hulu",
                "kodepos": "77211"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Pejalin",
                "kodepos": "77211"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Tanjung Palas Ilir/Hilir",
                "kodepos": "77211"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Antutan",
                "kodepos": "77211"
            },
            {
                "kecamatan": "Tanjung Palas",
                "kelurahan": "Gunung Putih",
                "kodepos": "77211"
            }
        ],
        "k196": [
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Mamburungan",
                "kodepos": "77125"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Lingkas Ujung",
                "kodepos": "77126"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Kampung Enam",
                "kodepos": "77123"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Kampung Empat",
                "kodepos": "77124"
            },
            {
                "kecamatan": "Tarakan Utara",
                "kelurahan": "Juata Laut",
                "kodepos": "77116"
            },
            {
                "kecamatan": "Tarakan Utara",
                "kelurahan": "Juata Permai",
                "kodepos": "77116"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Pantai Amal",
                "kodepos": "77115"
            },
            {
                "kecamatan": "Tarakan Utara",
                "kelurahan": "Juata Krikil/Kerikil",
                "kodepos": "77116"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Gunung Lingkas",
                "kodepos": "77115"
            },
            {
                "kecamatan": "Tarakan Timur",
                "kelurahan": "Mamburungan Timur",
                "kodepos": "77115"
            },
            {
                "kecamatan": "Tarakan Tengah",
                "kelurahan": "Sebengkok",
                "kodepos": "77114"
            },
            {
                "kecamatan": "Tarakan Tengah",
                "kelurahan": "Selumit",
                "kodepos": "77113"
            },
            {
                "kecamatan": "Tarakan Tengah",
                "kelurahan": "Selumit Pantai",
                "kodepos": "77113"
            },
            {
                "kecamatan": "Tarakan Tengah",
                "kelurahan": "Pamusian",
                "kodepos": "77113"
            },
            {
                "kecamatan": "Tarakan Barat",
                "kelurahan": "Karang Rejo",
                "kodepos": "77112"
            },
            {
                "kecamatan": "Tarakan Tengah",
                "kelurahan": "Kampung Satu (Skip)",
                "kodepos": "77113"
            },
            {
                "kecamatan": "Tarakan Barat",
                "kelurahan": "Karang Balik",
                "kodepos": "77112"
            },
            {
                "kecamatan": "Tarakan Barat",
                "kelurahan": "Karang Anyar Pantai",
                "kodepos": "77111"
            },
            {
                "kecamatan": "Tarakan Barat",
                "kelurahan": "Karang Harapan",
                "kodepos": "77111"
            },
            {
                "kecamatan": "Tarakan Barat",
                "kelurahan": "Karang Anyar",
                "kodepos": "77111"
            }
        ],
        "k197": [
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Tinjul",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Sungai Harapan",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Sungai Raya",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Tanjung Irat",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Suak Buaya",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Sungai Buluh",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Marok Tua",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Posek",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Raya",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Kuala Raya",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Langkap",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Bukit Belah",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Busung Panjang",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Jaqoh",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Teluk",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Singkep Barat",
                "kelurahan": "Bakong",
                "kodepos": "29875"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Sekanah",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Sungai Besar",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Resun",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Resun Pesisir",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Linau",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Pancur",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Rantau Panjang",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Duara",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Limbung",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Belungkur",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Lingga Utara",
                "kelurahan": "Bukit Harapan",
                "kodepos": "29874"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Tanjung Lipat",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Temiang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Senayang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Tajur Biru",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Tanjung Kelit",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Pulau Medang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Rejai",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Pulau Duyung",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Pulau Batang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Pulau Bukit",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Pasir Panjang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Penaah",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Mamut",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Mensanak",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Benan",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Cempa",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Labo",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Baran",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Senayang",
                "kelurahan": "Batu Belubang",
                "kodepos": "29873"
            },
            {
                "kecamatan": "Selayar",
                "kelurahan": "Selayar",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Selayar",
                "kelurahan": "Penuba",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Selayar",
                "kelurahan": "Penuba Timur",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Pekajang",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Pekaka",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Panggak Laut",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Selayar",
                "kelurahan": "Pantai Harapan",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Nerekeh",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Panggak Darat",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Mepar",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Merawang",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Musai",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Kudung",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Kudung",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Keton",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Kelumu",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Kerandin",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Kelombok",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga",
                "kelurahan": "Daik",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Lingga Timur",
                "kelurahan": "Bukit Langkap",
                "kodepos": "29872"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Sungai Lumpur",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Sedamai",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Pelakak",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Persing",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Marok Kecil",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Lanjut",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Kota/Kote",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Dabo",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Dabo Lama",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep Pesisir",
                "kelurahan": "Berindat",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Berhala",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Batu Karang",
                "kodepos": "29871"
            },
            {
                "kecamatan": "Singkep",
                "kelurahan": "Batu Berdaun",
                "kodepos": "29871"
            }
        ],
        "k198": [
            {
                "kecamatan": "Jemaja Timur",
                "kelurahan": "Ulu Maras",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Rewak",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Sunggak",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Mampok",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Letung",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Landak",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja Timur",
                "kelurahan": "Kuala Maras",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Keramut",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Impol",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja Timur",
                "kelurahan": "Genting Pulur",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja Timur",
                "kelurahan": "Bukit Padi",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Batu Berapit",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Jemaja",
                "kelurahan": "Air Biru",
                "kodepos": "29792"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Tiangau",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Tiangau",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Telaga Kecil",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Temburun",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Telaga",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Tarempa Selatan",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Tarempa Barat Daya",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Tarempa",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Tarempa Barat",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Nyamuk",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Serat",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Sri Tanjung",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Munjan",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan",
                "kelurahan": "Munjan",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Linga",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Mengkait",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Kiabu",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Air Putih",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Timur",
                "kelurahan": "Batu Belah",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Selatan",
                "kelurahan": "Air Bini",
                "kodepos": "29791"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Teluk Sunting",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Teluk Siantan",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Tebang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Teluk Bayur",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Putik",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Piasan",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Piabung",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Mubur",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Payalaman",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Payamaram",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Liuk",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Matak",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Lidi",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Ladan",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Langir",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Belibak",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Candi",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Bayat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Palmatak",
                "kelurahan": "Batu Ampar",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Air Sena",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Siantan Tengah",
                "kelurahan": "Air Asuk",
                "kodepos": "29783"
            }
        ],
        "k199": [
            {
                "kecamatan": "Midai",
                "kelurahan": "Sebelat",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Midai",
                "kelurahan": "Gunung Jambat",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Midai",
                "kelurahan": "Sabang Barat",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Midai",
                "kelurahan": "Air Kumpai",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Midai",
                "kelurahan": "Air Putih",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Midai",
                "kelurahan": "Batu Belanak",
                "kodepos": "29784"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Teluk Buton",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Teluk Labuh",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Tengah",
                "kelurahan": "Tapau",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Laut",
                "kelurahan": "Tanjung Pala",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Tanjung Kumbik Utara",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Tanjung Batang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Tanjung",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Setumuk",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Sungai Ulu",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Serantas",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Sepempang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Seluan Barat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Seleman",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Sededap",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Selading",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Sabang Mawang Barat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Sabang Mawang Barat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Ranai Kota",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Sabang Mawang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Ranai Darat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Pulau Tiga",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Pengadah",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Limau Manis",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Kelarik Barat",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Kelarik Utara",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Kelanga",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Kelarik",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Kelarik Air Mali",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Laut",
                "kelurahan": "Kadur",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Tengah",
                "kelurahan": "Harapan Jaya",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur Laut",
                "kelurahan": "Ceruk",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Gunung Durian",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Selatan",
                "kelurahan": "Cemaga Selatan (Setegar)",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Selatan",
                "kelurahan": "Cemaga Tengah",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Selatan",
                "kelurahan": "Cemaga Utara (Singgang Bbulan)",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Selatan",
                "kelurahan": "Cemaga",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Utara",
                "kelurahan": "Belakang Gunung",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Batu Gajah",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Timur",
                "kelurahan": "Bandarsyah",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Laut",
                "kelurahan": "Air Payang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Pulau Laut",
                "kelurahan": "Air Payang",
                "kodepos": "29783"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Selaut",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Semedang",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Sedanau Timur",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Sedarat Baru",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Pian Tengah",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Sedanau",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Gunung Putri",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Mekar Jaya",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Batubi Jaya",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Bunguran Barat",
                "kelurahan": "Binjai",
                "kodepos": "29782"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Terayak",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Tanjung Setelung",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Subi Besar Timur",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Tanjung Balau",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Subi Besar",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Subi",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Serasan",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Pulau Panjang",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan Timur",
                "kelurahan": "Payak (Harapan Baru)",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Pulau Kerdau",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Pangkalan",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Meliah",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Subi",
                "kelurahan": "Meliah Selatan",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Kampung Hilir",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan Timur",
                "kelurahan": "Arung Ayam",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Batu Berian",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan",
                "kelurahan": "Jernalik",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan Timur",
                "kelurahan": "Air Ringau",
                "kodepos": "29781"
            },
            {
                "kecamatan": "Serasan Timur",
                "kelurahan": "Air Nusa",
                "kodepos": "29781"
            }
        ],
        "k200": [
            {
                "kecamatan": "Durai",
                "kelurahan": "Tanjung Kilang",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Sungai Pasir",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Buru",
                "kelurahan": "Tanjung Batu Kecil",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Buru",
                "kelurahan": "Tanjung Hutan",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Sei/Sungai Raya",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Durai",
                "kelurahan": "Simembang (Semembang)",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Pasir Benut",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral Barat",
                "kelurahan": "Pasir Panjang",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Durai",
                "kelurahan": "Sanglar",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral Barat",
                "kelurahan": "Pangke",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral Barat",
                "kelurahan": "Pangke Barat",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Buru",
                "kelurahan": "Lubuk Puding",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Meral Kota",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral Barat",
                "kelurahan": "Darussalam",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Durai",
                "kelurahan": "Durai",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Buru",
                "kelurahan": "Buru",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Baran Barat",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Meral",
                "kelurahan": "Baran Timur",
                "kodepos": "29664"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Tebing",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Teluk Uma",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Sugie",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Tanjung Pelanduk",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Selat Mie",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Pulaumoro",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Rawajaya",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Pongkar",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Pauh",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Pamak",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Moro Timur",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Niur Permai",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Keban",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Moro",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Kapling",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Tebing",
                "kelurahan": "Harjosari",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Jang (Pulau Jang)",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Kundur Utara",
                "kelurahan": "Teluk Radang",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Moro",
                "kelurahan": "Buluh Patah",
                "kodepos": "29663"
            },
            {
                "kecamatan": "Kundur Utara",
                "kelurahan": "Tanjung Berlian Kota (Urung)",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Tebias",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Utara",
                "kelurahan": "Tanjung Berlian Barat (Urung Barat)",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Tanjung Batu Barat",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Tanjung Batu Kota",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Ungar",
                "kelurahan": "Sungai Buluh",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Utara",
                "kelurahan": "Sei/Sungai Ungar Utara",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Sei/Sungai Ungar",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Sei/Sungai Asam",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Sebesi (Sei Sebesi)",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Barat",
                "kelurahan": "Sawang Selatan",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Sebele",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Barat",
                "kelurahan": "Sawang",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Barat",
                "kelurahan": "Sawang Laut",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Utara",
                "kelurahan": "Perayun",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Penarah",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Ungar",
                "kelurahan": "Ngal",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Lebuh",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Lubuk",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Barat",
                "kelurahan": "Kundur",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur",
                "kelurahan": "Gading Sari",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Kundur Barat",
                "kelurahan": "Gemuruh",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Belat",
                "kelurahan": "Degong",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Ungar",
                "kelurahan": "Batu Limau",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Ungar",
                "kelurahan": "Alai",
                "kodepos": "29662"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Teluk Air",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Tulang",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Tanjung Balai",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Tanjung Balai Kota",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Selat Mendaun",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Sungai Lakam Barat",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Sungai Lakam Timur",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Lubuk Semut",
                "kodepos": "29661"
            },
            {
                "kecamatan": "Karimun",
                "kelurahan": "Parit",
                "kodepos": "29661"
            }
        ],
        "k201": [
            {
                "kecamatan": "Galang",
                "kelurahan": "Pulau Abang",
                "kodepos": "29487"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sijantung",
                "kodepos": "29485"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Karas (Pulau Karas)",
                "kodepos": "29486"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Subang Mas",
                "kodepos": "29483"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Galang Baru (Pulau)",
                "kodepos": "29484"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Air Raja",
                "kodepos": "29483"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Rempang Cate",
                "kodepos": "29482"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Pulau Setokok",
                "kodepos": "29476"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sembulang (Pulau Rempang)",
                "kodepos": "29481"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Batu Legong",
                "kodepos": "29474"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Temoyong",
                "kodepos": "29475"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Pulau Buluh",
                "kodepos": "29472"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Pantai Gelam",
                "kodepos": "29473"
            },
            {
                "kecamatan": "Nongsa",
                "kelurahan": "Ngenang",
                "kodepos": "29468"
            },
            {
                "kecamatan": "Bulang",
                "kelurahan": "Bulang Lintang",
                "kodepos": "29471"
            },
            {
                "kecamatan": "Nongsa",
                "kelurahan": "Batu Besar",
                "kodepos": "29466"
            },
            {
                "kecamatan": "Nongsa",
                "kelurahan": "Kabil",
                "kodepos": "29467"
            },
            {
                "kecamatan": "Nongsa",
                "kelurahan": "Sambau",
                "kodepos": "29465"
            },
            {
                "kecamatan": "Nongsa",
                "kelurahan": "Sambau",
                "kodepos": "29465"
            },
            {
                "kecamatan": "Batam Kota",
                "kelurahan": "Teluk Tering",
                "kodepos": "29461"
            },
            {
                "kecamatan": "Bengkong",
                "kelurahan": "Bengkong Indah",
                "kodepos": "29458"
            },
            {
                "kecamatan": "Bengkong",
                "kelurahan": "Bengkong Laut",
                "kodepos": "29458"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Tanjung Sengkuang",
                "kodepos": "29453"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Kampung Seraya",
                "kodepos": "29454"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Sungai Jodoh",
                "kodepos": "29453"
            },
            {
                "kecamatan": "Batu Ampar",
                "kelurahan": "Batu Merah",
                "kodepos": "29452"
            },
            {
                "kecamatan": "Lubuk Baja",
                "kelurahan": "Tanjung Uma",
                "kodepos": "29445"
            },
            {
                "kecamatan": "Lubuk Baja",
                "kelurahan": "Kampung Pelita",
                "kodepos": "29443"
            },
            {
                "kecamatan": "Lubuk Baja",
                "kelurahan": "Lubuk Baja Kota",
                "kodepos": "29444"
            },
            {
                "kecamatan": "Lubuk Baja",
                "kelurahan": "Batu Selicin",
                "kodepos": "29441"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Tembesi",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Sungai Langkai",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Sungai Lekop",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Sungai Pelenggut/Pelunggut",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Sagulung Kota",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Sagulung",
                "kelurahan": "Sungai Binti",
                "kodepos": "29439"
            },
            {
                "kecamatan": "Batu Aji",
                "kelurahan": "Buliang",
                "kodepos": "29438"
            },
            {
                "kecamatan": "Batu Aji",
                "kelurahan": "Bukit Tempayan",
                "kodepos": "29438"
            },
            {
                "kecamatan": "Sei/Sungai Beduk",
                "kelurahan": "Mangsang (Mangsa)",
                "kodepos": "29437"
            },
            {
                "kecamatan": "Sei/Sungai Beduk",
                "kelurahan": "Tanjung Piayu",
                "kodepos": "29437"
            },
            {
                "kecamatan": "Sei/Sungai Beduk",
                "kelurahan": "Duriangka",
                "kodepos": "29437"
            },
            {
                "kecamatan": "Batam Kota",
                "kelurahan": "Sungai Panas",
                "kodepos": "29433"
            },
            {
                "kecamatan": "Bengkong",
                "kelurahan": "Tanjung Buntung",
                "kodepos": "29432"
            },
            {
                "kecamatan": "Sei/Sungai Beduk",
                "kelurahan": "Muka Kuning",
                "kodepos": "29433"
            },
            {
                "kecamatan": "Batam Kota",
                "kelurahan": "Sukajadi",
                "kodepos": "29432"
            },
            {
                "kecamatan": "Batam Kota",
                "kelurahan": "Taman Baloi",
                "kodepos": "29432"
            },
            {
                "kecamatan": "Lubuk Baja",
                "kelurahan": "Baloi Indah",
                "kodepos": "29432"
            },
            {
                "kecamatan": "Bengkong",
                "kelurahan": "Sadai",
                "kodepos": "29432"
            },
            {
                "kecamatan": "Batam Kota",
                "kelurahan": "Baloi Permai",
                "kodepos": "29431"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Sungai Harapan",
                "kodepos": "29428"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Tanjung Pinggir",
                "kodepos": "29428"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Tiban Indah",
                "kodepos": "29426"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Patam Lestari",
                "kodepos": "29427"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Tanjung Riau",
                "kodepos": "29425"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Tiban Lama",
                "kodepos": "29425"
            },
            {
                "kecamatan": "Sekupang",
                "kelurahan": "Tiban Baru",
                "kodepos": "29426"
            },
            {
                "kecamatan": "Batu Aji",
                "kelurahan": "Tanjung Uncang",
                "kodepos": "29424"
            },
            {
                "kecamatan": "Batu Aji",
                "kelurahan": "Kibing",
                "kodepos": "29422"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Pulau Terong",
                "kodepos": "29416"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Sekanak Raya",
                "kodepos": "29416"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Tanjung Sari",
                "kodepos": "29416"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Pecong",
                "kodepos": "29414"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Pemping",
                "kodepos": "29412"
            },
            {
                "kecamatan": "Belakang Padang",
                "kelurahan": "Kasu",
                "kodepos": "29413"
            }
        ],
        "k202": [
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Pulau Pinang",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Teluk Sekuni",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Pengikik",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Pulau Mentebung",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Kampung Melayu",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Kukup",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Batu Lepuk",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Tambelan",
                "kelurahan": "Kampung Hilir",
                "kodepos": "29193"
            },
            {
                "kecamatan": "Seri/Sri Kuala Lobam",
                "kelurahan": "Teluk Lobam",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Seri/Sri Kuala Lobam",
                "kelurahan": "Teluk Sasah",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Bintan Utara",
                "kelurahan": "Tanjung Uban Timur",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Bintan Utara",
                "kelurahan": "Tanjung Uban Utara",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Seri/Sri Kuala Lobam",
                "kelurahan": "Tanjung Permai",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Bintan Utara",
                "kelurahan": "Tanjung Uban Kota",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Bintan Utara",
                "kelurahan": "Tanjung Uban Selatan",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Sebung/Sebong Pereh",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Sri Bintan",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Pengudang",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Sebung/Sebong Lagoi",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Seri/Sri Kuala Lobam",
                "kelurahan": "Kuala Sempang",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Bintan Utara",
                "kelurahan": "Lancang Kuning",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Ekang Anculai",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Kota Baru",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Seri/Sri Kuala Lobam",
                "kelurahan": "Busung",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Teluk Sebong",
                "kelurahan": "Berakit",
                "kodepos": "29152"
            },
            {
                "kecamatan": "Toapaya",
                "kelurahan": "Toapaya Utara",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Toapaya",
                "kelurahan": "Toapaya Asri",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Toapaya",
                "kelurahan": "Toapaya Selatan",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Gunung Kijang",
                "kelurahan": "Teluk Bakau",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Toapaya",
                "kelurahan": "Toapaya",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Timur",
                "kelurahan": "Sungai Enam",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Timur",
                "kelurahan": "Sungai Lekop",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Pesisir",
                "kelurahan": "Mapur",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Pesisir",
                "kelurahan": "Numbing",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Mantang",
                "kelurahan": "Mantang Besar",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Mantang",
                "kelurahan": "Mantang Lama",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Mantang",
                "kelurahan": "Mantang Baru",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Gunung Kijang",
                "kelurahan": "Malang Rapat",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Gunung Kijang",
                "kelurahan": "Kawal",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Timur",
                "kelurahan": "Kijang Kota",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Pesisir",
                "kelurahan": "Kelong",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Timur",
                "kelurahan": "Gunung Lengkuas",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Gunung Kijang",
                "kelurahan": "Gunung Kijang",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Mantang",
                "kelurahan": "Dendun",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Bintan Pesisir",
                "kelurahan": "Air Glubi/Gelubi",
                "kodepos": "29151"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Pengujan",
                "kodepos": "29135"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Pangkil",
                "kodepos": "29136"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Bintan Buyu",
                "kodepos": "29133"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Penaga",
                "kodepos": "29134"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Tembeling",
                "kodepos": "29132"
            },
            {
                "kecamatan": "Teluk Bintan",
                "kelurahan": "Tembeling Tanjung",
                "kodepos": "29132"
            }
        ],
        "k203": [
            {
                "kecamatan": "Tanjung Pinang Timur",
                "kelurahan": "Batu IX (Sembilan)",
                "kodepos": "29125"
            },
            {
                "kecamatan": "Bukit Bestari",
                "kelurahan": "Tanjung Ayun Sakti",
                "kodepos": "29124"
            },
            {
                "kecamatan": "Bukit Bestari",
                "kelurahan": "Dompak",
                "kodepos": "29124"
            },
            {
                "kecamatan": "Bukit Bestari",
                "kelurahan": "Sei Jang",
                "kodepos": "29124"
            },
            {
                "kecamatan": "Tanjung Pinang Timur",
                "kelurahan": "Melayu Kota Piring",
                "kodepos": "29123"
            },
            {
                "kecamatan": "Bukit Bestari",
                "kelurahan": "Tanjung Unggat",
                "kodepos": "29122"
            },
            {
                "kecamatan": "Bukit Bestari",
                "kelurahan": "Tanjung Pinang Timur",
                "kodepos": "29122"
            },
            {
                "kecamatan": "Tanjung Pinang Timur",
                "kelurahan": "Kampung Bulang",
                "kodepos": "29122"
            },
            {
                "kecamatan": "Tanjung Pinang Timur",
                "kelurahan": "Pinang Kencana",
                "kodepos": "29122"
            },
            {
                "kecamatan": "Tanjung Pinang Kota",
                "kelurahan": "Kampung Bugis",
                "kodepos": "29115"
            },
            {
                "kecamatan": "Tanjung Pinang Timur",
                "kelurahan": "Air Raja",
                "kodepos": "29122"
            },
            {
                "kecamatan": "Tanjung Pinang Kota",
                "kelurahan": "Penyengat",
                "kodepos": "29114"
            },
            {
                "kecamatan": "Tanjung Pinang Barat",
                "kelurahan": "Kampung Baru",
                "kodepos": "29113"
            },
            {
                "kecamatan": "Tanjung Pinang Barat",
                "kelurahan": "Tanjung Pinang Barat",
                "kodepos": "29113"
            },
            {
                "kecamatan": "Tanjung Pinang Barat",
                "kelurahan": "Kemboja",
                "kodepos": "29112"
            },
            {
                "kecamatan": "Tanjung Pinang Kota",
                "kelurahan": "Tanjung Pinang Kota",
                "kodepos": "29111"
            },
            {
                "kecamatan": "Tanjung Pinang Kota",
                "kelurahan": "Senggarang",
                "kodepos": "29111"
            },
            {
                "kecamatan": "Tanjung Pinang Barat",
                "kelurahan": "Bukit Cermin",
                "kodepos": "29111"
            }
        ],
        "k204": [
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Way Panas",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Wonosobo",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Way Liwok",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Tulung Sari",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sridadi",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sumur Tujuh",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sri Melati",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sinar Saudara",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Soponyono",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Sanggi Unggak",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Simpang Bayur",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Sinar Bangun",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Sampang Turus",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Sanggi",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Pekon Balak",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Raja Basa",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Parda Suka",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Padang Manis",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Padang Ratu",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Negeri Agung",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Negeri Ngarip",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kunyayan",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Lakaran",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Karang Anyar",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kejadian",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kalirejo",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Kalisari",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Dadisari",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Gunung Doh",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Dadimulyo",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Dadirejo",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Banjarsari",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Banyu Urip",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Banjar Negoro",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Banding",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Bandar Sukabumi",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Wonosobo",
                "kelurahan": "Bandar Kejadian",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Bandar Negeri Semuong",
                "kelurahan": "Atar Lebar",
                "kodepos": "35686"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Terbaya",
                "kodepos": "35684"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Way Rilau",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Tanjung Jati",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Tanjung Raja",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Tengor",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Sukaraja",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Tanjung Betuah",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Suka Banjar",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Sukapadang",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Sawang Balak",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Pekondoh",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Putih Doh",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Kutakakhang",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Pampangan",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Kejadianlom",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Kubulangka",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Kacamarga",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Karang Buah",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Banjar Negeri",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Gedung (Pekon Gedung)",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Cukuh Balak",
                "kelurahan": "Banjar Manis",
                "kodepos": "35683"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Tanjungsari",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Sukarama (Sukamara)",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Suka Agung Timur",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Sukanegara (Suka Negeri)",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Sinarpetir",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Suka Agung Barat",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Pematang Nebak",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Napal",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Banjar Masin",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Bulok",
                "kelurahan": "Gunung Terang",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Way Harong",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Tekad",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Way Ilahan",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Tanjunggunung",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Talang Jawa",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Tanjung Begelung",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Sumber Mulyo/Mulya",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Talang Beringin",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Sindang Marga",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Srimanganten",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Sinar Sekampung",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Sinar Mancak",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Sinar Mulyo",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Sinar Jawa",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Pulau Panggung",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Sidomulyo",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Muara Dua",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Penantian",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Margomulyo",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Kemuning",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Karang Sari",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Gunung Megang",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Gunung Meraksa",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Datar Lebuay",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Gedung Agung",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Air Naningan",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Batu Bedil",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Batu Bedil",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pulau Panggung",
                "kelurahan": "Air Bakoman",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Air Naningan",
                "kelurahan": "Air Kubang",
                "kodepos": "35679"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Way Manak",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Way Piring",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tiuh Memon",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Way Jaha",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tanjung Agung",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tanjung Heran",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Talang Lebar",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tamansari",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Tangkit Serdang",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Suka Mulya",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Sukajadi",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Sumanda",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Sinar Agung",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Suka Maju",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Pungkut",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Rantau Tijang",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Kayuhubi",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Negeri Ratu",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Gading",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Gunung Kasih",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Gunung Tiga",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Binjai Wangi",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Campang Way Handak",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Banjar Agung Ilir",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Banjar Agung Udik",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Pugung",
                "kelurahan": "Babakan",
                "kodepos": "35675"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Tegineneng",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Tanjung Siom",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Tanjungjaya",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Pariaman",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Pekon Ampai",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Padang Ratu",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Kuripan",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kuripan",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Ketapang",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Banjar Agung",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Badak",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Limau",
                "kelurahan": "Antarbrak (Antarberak)",
                "kodepos": "35613"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Baros",
                "kodepos": "35611"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Ulu Semong",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Suka Maju",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Tanjung Baru",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Sirnagalih",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Sinar Banten",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Rejosari",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Petay Kayu",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Penantian Ulubelu",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Pagaralam Ulubelu",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Ngarip",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Karangrejo",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Muara Dua Ulu Belu",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Gunungtiga",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Gunung Sari",
                "kodepos": "35387"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Way Kerap",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Tulung Asahan",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Tugu Rejo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Tugu Papak",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sukaraja",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sukajaya",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sudimoro",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sudimoro Bangun",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sri Purnomo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sri Kuncoro",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sri Katon",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sido Mulyo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sedayu",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Sido Dadi",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Margomulyo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Parda Waras",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Kanoman",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Karang Agung",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Karang Rejo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Garut",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Kaca Pura",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Semaka",
                "kelurahan": "Bangun Rejo",
                "kodepos": "35386"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Way Gelang",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Umbul Buah",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Terdana",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Teratas",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Teba Bunuk",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Tanjungjati",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Teba",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Talangening (Tela Gening)",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Tanjung Agung",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Tanjung Anom",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Suka Banjar",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Talang Rejo",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Pulau Benawang",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Penanggungan",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Payung",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Pasar Madang",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Negeri Ratu",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Pajajaran",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Menggala",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Mulang Maya",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Negara Batin",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kota Batu",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kusa",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Maja",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kota Agung",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Kesugihan",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kedamaian",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Kelungu",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Kanyangan",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Karta",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Kampung Baru",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Kali Miring",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Kandang Besi",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Kagungan",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Campang Tiga",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Gedung Jambu",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Benteng Jaya",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Belu",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Barat",
                "kelurahan": "Banjarmasin",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Kota Agung Timur",
                "kelurahan": "Batu Keramat",
                "kodepos": "35384"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Way Asahan",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Way Nipah",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Teluk Brak",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Tirom",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Tampang Muda",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Tanjungan",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Kota Agung (Kota Agung Pusat)",
                "kelurahan": "Pardasuka",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Pesanguan",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Tampang",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Martanda",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Karang Brak",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Kaur Gading",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Guring",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Kampung Baru",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Pematang Sawa",
                "kelurahan": "Betung",
                "kodepos": "35382"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Way Halom",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Umbar",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Sukaraja",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Sukadamai",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Sukamernah (Sukamarnah)",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Sukabanjar",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Purwosari",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Sidoharjo",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Penanggungan",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Penyandingan",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Pekon Susuk",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Pekon Unggak",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Pariaman",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Paku",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Negeri Kelumbayan",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Napal",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Marga Mulya",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Merbau",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Lengkukai",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan",
                "kelurahan": "Kilauan Negeri",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Darussalam",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Kedaloman",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Ciherang",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Kelumbayan Barat",
                "kelurahan": "Batu Patah",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Banjar Agung",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gunung Alip",
                "kelurahan": "Banjar Negeri",
                "kodepos": "35379"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Purwodadi",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Sidokaton",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Landbraw",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Kota Dalom/Dalam",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Gisting Bawah",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Gisting Permai",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Banjarmanis",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Campang",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Gisting",
                "kelurahan": "Gisting Atas",
                "kodepos": "35378"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Way Halom",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Talang Sepuh",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Talang Padang",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sukanegeri Jaya",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sukarame",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Suka Negeri",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sukabumi",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Suka Bandung",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Suka Merindu",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sinar Semendo",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Singosari",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sinar Betung",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sinar Harapan",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sinar Petir",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Sinar Banten",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Kejayaan",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Negeri Agung",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Datarajan",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Kalibening",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Banding Agung",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Banjarsari",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Ulubelu",
                "kelurahan": "Air Abang",
                "kodepos": "35377"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Wonoharjo",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Sumber Rejo",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Tegal Binangun",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Simpang Kanan",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Sidomulyo",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Sidorejo",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Kebumen",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Margodadi",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Margoyoso",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Argopeni",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Dadapan",
                "kodepos": "35374"
            },
            {
                "kecamatan": "Sumberejo",
                "kelurahan": "Argomulyo",
                "kodepos": "35374"
            }
        ],
        "k205": [
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Wargo/Warga Mulyo",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Tanjung Rusia Timur",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Sukorejo",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Tanjung Rusia",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Sukanegeri (Sukanegara)",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Selapan",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Sidodadi",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Pujodadi",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Rantau Tijang",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Pardasuka Selatan",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Pardasuka Timur",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Pardasuka",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Pardasuka",
                "kelurahan": "Kedaung",
                "kodepos": "35682"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Waringinsari Timur",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Waringin Sari Barat",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Totokarto",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Tritunggal Mulya",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Tunggul Pawenang",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Sukoharum",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoyoso",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo III Barat",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo IV",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo I",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo II",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sukoharjo III",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Sinar Waya",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Srikaton",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sinar Baru",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Sinar Baru Timur",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Purwodadi",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Panggung Rejo Utara",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Siliwangi",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Pandan Surat",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Panggung Rejo",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Pandan Sari",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Pandan Sari Selatan",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Sukoharjo",
                "kelurahan": "Keputran",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Kuta Waringin",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Enggal Rejo",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Bandung Baru",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Bandung Baru Barat",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Adi Luwih",
                "kelurahan": "Adi Luwih",
                "kodepos": "35674"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Way Ngisom",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Way Kunir",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Tanjung Anom",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Tanjung Dalam/Dalom",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sumber Rejo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Sumber Bandung",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Sumber Agung",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sukaratu",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sukawangi",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Puji Harjo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Sidodadi",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pasir Ukir",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Patoman",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pamenang",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Panutan",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Padang Rejo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Pagelaran",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Margosari",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Neglasari",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Kresnomulyo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Margodadi",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Madaraya",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Kemilin",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Jati Agung",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Karangsari",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Gunung Raya",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Gumuk Rejo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Giri Tunggal",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Gumuk Mas",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Ganjaran",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Gemah Ripah",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Fajar Baru",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran Utara",
                "kelurahan": "Fajar Mulya / Mulia",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Bumi Rejo",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Candi Retno",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Ambarawa Timur",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Bumi Ratu",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Ambarawa",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Ambarawa",
                "kelurahan": "Ambarawa Barat",
                "kodepos": "35376"
            },
            {
                "kecamatan": "Pagelaran",
                "kelurahan": "Lugusari",
                "kodepos": "35375"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Waya Krui",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Sukamulya",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Waluyojati",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Sinar Mulyo/Mulya",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Sri Rahayu",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Sriwungu",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Pringsewu Utara",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Rejosari",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Sidoharjo",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Pringsewu Selatan",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Pringsewu Timur",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Podomoro",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Podosari",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Pringsewu Barat",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Pajar/Fajar Agung",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Mulyo Rejo",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Nusa Wungu",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Margakaya",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Fajaresuk (Pajar Esuk)",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Bumi Arum",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Bumi Ayu",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Pringsewu",
                "kelurahan": "Fajar Agung Barat",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Banyuwangi",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Banyumas",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Banyu Urip",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Banyumas",
                "kelurahan": "Banjarejo",
                "kodepos": "35373"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Yogyakarta Selatan",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wonosari",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Yogyakarta (Jogjakarta)",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wates Timur",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wonodadi",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wonodadi Utara",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wates",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Wates Selatan",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Tegalsari",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Tulung Agung",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Parerejo",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Tambahrejo Barat",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Tambak Rejo",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Klaten",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Mataram",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Panjerejo",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Gadingrejo Utara",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Kediri",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Gading Rejo",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Gadingrejo Timur",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Bulukarto",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Bulurejo",
                "kodepos": "35372"
            },
            {
                "kecamatan": "Gading Rejo",
                "kelurahan": "Blitarejo",
                "kodepos": "35372"
            }
        ],
        "k206": [
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sumber Sari",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sumber Agung",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Margasari",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Sukapura",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Mandalasari",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Margajasa",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Bandar Agung",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Kedaung",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Kuala Sekampung",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Sragi",
                "kelurahan": "Bakti Rasa",
                "kodepos": "35597"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Tridarmayoga",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Wai Sidomukti",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Sumbernadi",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Sumur",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Taman Sari",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Sidoasih",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Sri Pendowo",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ruguk",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Sido Luhur",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Pematang Pasir",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Lembung Nala",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Legundi",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Ketapang",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Berundung",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Karang Sari",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Kemukus",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Ketapang",
                "kelurahan": "Bangun Rejo",
                "kodepos": "35596"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Tanjung Sari",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Sukabakti",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Sukaraja",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Rejo Mulyo",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Suka Mulya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Pematang Baru",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Pulau Jaya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Pulau Tengah",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Palas Jaya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Palas Pasemah",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Mekar Mulya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Palas Aji",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bumi Restu",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Kalirejo",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bumi Daya",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bumi Asih",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bumi Asri",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bandan Hurip",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bangunan",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Way Kalam",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Palas",
                "kelurahan": "Bali Agung",
                "kodepos": "35594"
            },
            {
                "kecamatan": "Bakauheni",
                "kelurahan": "Totoharjo",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Tetaan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Taman Baru",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Tanjung Heran",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Bakauheni",
                "kelurahan": "Semanak",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Sukabaru",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Sukajaya",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Rawi",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Ruang Tengah",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Pasuruan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Pisang",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Panengahan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Kuripan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Padan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Bakauheni",
                "kelurahan": "Kelawi",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Klaten",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Kampungbaru",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Kekiling",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Kelau",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Bakauheni",
                "kelurahan": "Hatta (Hata)",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Gedungharta",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Gandri",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Gayam",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Banjarmasin",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Penengahan",
                "kelurahan": "Belambangan",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Bakauheni",
                "kelurahan": "Bakauheni",
                "kodepos": "35592"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Waymuli",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Tejang Pulau Sebesi",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Wai Muli Timur",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Sukaraja",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Tanjung Gading",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Kota Guring",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Kunyir",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Hargo Pancoran",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Kerinjing",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Canti",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Cugung",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Betung",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Canggung",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Banding",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Batu Balak",
                "kodepos": "35552"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Tengkujuh",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Wai Lubuk",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Wayurang",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Taman Agung",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Tajimalela",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Sukaratu",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Tajimalela",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Pematang",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Suka Tani",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Palembapang",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Pauh Tanjung Iman",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Munjuk Sempurna",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Negeri Pandan",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Marga Catur",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Merak Belantung",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Kesugihan",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Maja",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Kalianda",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Kecapi",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Kedaton",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Hara Banjar Manis",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Jondong",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Gunung Terang",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Canggu",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Bulok",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Bumi Agung",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Buah Berak",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Babulang",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Kalianda",
                "kelurahan": "Agom",
                "kodepos": "35551"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Trans Tanjungan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Tarahan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Tanjungratu",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Tanjungan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Tanjungagung",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Sumberagung",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Talang Way Sulan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Sukamaju",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Sukajaya",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Sidomekar",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Purwodadi",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Rangai Tri Tunggal",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Pemulihan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Pardasuka",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Neglasari",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Mekarsari",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Karya Tunggal",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Karang Pucung",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Way Sulan",
                "kelurahan": "Banjarsari",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Katibung",
                "kelurahan": "Babatan",
                "kodepos": "35452"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Sumber Jaya",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Way Huwi",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Sidoharjo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Sinar Rejeki",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Rejomulyo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Sidoasri (Sidodadi Asri)",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Margomulyo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Purwotani",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Margo Lestari",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Margo Rejo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Marga/Margo Agung",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Margo Dadi/Dari",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Karang Sari",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Marga Kaya",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Karang Rejo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Jatimulyo",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Karang Anyar",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Gedung Agung",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Gedung Harapan",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Banjar Agung",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Jati Agung",
                "kelurahan": "Fajar Baru",
                "kodepos": "35365"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Tanjungsari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Wai Sari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Sukadamai",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Sidosari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Rulung Raya",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Rulung Sari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Rulung Herok/Helok",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Rulung Mulya",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Rejosari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Pancasila",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Pemanggilan",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Purwosari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Negara Ratu",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Muara Putih",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Natar",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Merak Batin",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Krawang Sari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Mandah",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Kali Sari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Kali Sari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Haduyang",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Bumisari",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Candi Mas",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Banjarnegeri",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Beranti/Branti Raya",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Natar",
                "kelurahan": "Bandar Rejo",
                "kodepos": "35362"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Wonodadi",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Way Galih",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Wawasan",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Trimulyo",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Sri Katon",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Sukanegara",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Sinar Ogan",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Sindang Sari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Serdang",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Sidomukti",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Sabah Balau",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Rejomulyo",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Purwodadi Simpang",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Purwodadi Dalam",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Mulyo Sari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Malang Sari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Lematang",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Kertosari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Kali Asin",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Jatibaru",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Jati Indah",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Budi Lestari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Bintang",
                "kelurahan": "Galih Lunik",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Tanjung Sari",
                "kelurahan": "Bangunsari",
                "kodepos": "35361"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Triharjo",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Tanjung Baru",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Talang Jawa",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Suban",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Sinar Karya",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Pujirahayu",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Merbau Mataram",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Panca Tunggal",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Mekar Jaya",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Lebungsari",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Karang Raja",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Karang Jaya",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Batuagung",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Merbau Mataram",
                "kelurahan": "Baru Ranji",
                "kodepos": "35357"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Way Gelam",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Trimomukti",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Titiwangi (Candipuro)",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sinar Pasemah",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sidoasri",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Sinar Palembang",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Rawa Selapan",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Rantau Minyak",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Karya Mulya Sari",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Bumi Jaya",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Cintamulya",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Beringin Kencana",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Batuliman Indah",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Candipuro",
                "kelurahan": "Banyumas",
                "kodepos": "35356"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Talang Baru",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sukamarga",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sukabanjar",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sukamaju",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Siring Jaha",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Suak",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sidowaluyo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sidomulyo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sidorejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Way Panji",
                "kelurahan": "Sido Reno",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Sidodadi",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Way Panji",
                "kelurahan": "Sido Makmur",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Seleretno (Seloretno)",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Way Panji",
                "kelurahan": "Sido Harjo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Kota Dalam",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Budi Daya",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Campang Tiga",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Banjar Suri",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Sidomulyo",
                "kelurahan": "Bandar Dalam",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Way Panji",
                "kelurahan": "Bali Nuraga",
                "kodepos": "35353"
            }
        ],
        "k207": [
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Sukajaya",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Sri Mulyo",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Gedung Sari",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Karang Jawa",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Gedung Ratu",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Anak Ratu Aji",
                "kelurahan": "Bandar Putih Tua",
                "kodepos": "35513"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Tanjung Ratu Ilir",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Purnama Tunggal",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Candi Rejo",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Lempuyang Bandar",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Banjar Rejo",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Banjar Ratu",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Pangubuan (Pengubuan)",
                "kelurahan": "Banjar Kertarahayu",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Suko Binangun",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Sri Budaya",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Sri Busono",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Sido Binangun",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Sri Bawono",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Tias Bangun",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Way Seputih",
                "kelurahan": "Sangga Buana",
                "kodepos": "34179"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Tawang Negeri",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Tanjung Ratu",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Taman Sari",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Sinar Negeri",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Sidoharjo",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Sangun Ratu",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Segala Mider",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Pekandangan",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Riau Periangan",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Payung Mulya",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Payung Rejo",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Payung Batu",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Payung Dadi",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Payung Makmur",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Padang Rejo",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Nyukang Harjo",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Negeri Kepayung (Kepayungan)",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Negeri Ratu",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Negeri Katon",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Negeri Agung",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Negeri Jaya",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Lingga Pura",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Marga Jaya",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Kota Batu",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Gunung Raya",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Karang Anyar",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Pubian",
                "kelurahan": "Gunung Haji",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Gedung Haji",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Gedung Harta",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Surabaya",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Selagai Lingga",
                "kelurahan": "Galih/Gilih Karangjati",
                "kodepos": "34176"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Sri Agung",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Sumber Sari",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Purworejo",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Purwosari",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Sendang Ayu",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Margorejo",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Mojokerto",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Padang Ratu",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Kota Baru",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Kuripan",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Karang Tanjung",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Haduyang Ratu",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Karang Sari",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Padang Ratu",
                "kelurahan": "Bandarsari",
                "kodepos": "34175"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sukosari",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Watu Agung",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Wayakroy",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sri Way Langsep",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sri Purnomo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sri Mulyo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sri Dadi",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sri Basuki",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sinar Rejo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Sinar Sari",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Retno",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Sari (Asri)",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Mulyo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Rejo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Asih",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Baru",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Mukti",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Sendang Agung",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Ponco Warno",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Sendang Agung",
                "kelurahan": "Kutowinangun",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Kali Wungu",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Kali Rejo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Balai Rejo",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Kali Dadi",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Timbul Rejo",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Kalirejo",
                "kelurahan": "Agung Timur",
                "kodepos": "34174"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Tanjung Pandan",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sri Pendowo",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Suka Negara",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Suka Waringin",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sinar Luas",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sinar Seputih",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sidorejo",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sidoluhur",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sidomulyo",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Purwodadi",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Sidodadi",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Cimarias",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Bangunrejo",
                "kelurahan": "Bangun Rejo",
                "kodepos": "34173"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Untoro",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Trimurjo",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Tempuran",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Purwodadi",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Purwoadi",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Pujokerto",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Pujoasri",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Pujodadi",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Pujo Basuki",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Notoharjo",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Leman/Liman Benawi",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Depok Rejo",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Adipuro",
                "kodepos": "34172"
            },
            {
                "kecamatan": "Trimurjo",
                "kelurahan": "Simbar Waringin",
                "kodepos": "34171"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Uman Agung Mataram",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Terbanggi Mulya",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Terbanggi Besar",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Sriwijaya Mataram",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Sumber Rejeki Mataram",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Sendang Agung Mataram",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Mataram Udik",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Mataram Jaya",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bandar Mataram",
                "kelurahan": "Jati Datar Mataram",
                "kodepos": "34169"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Sri Kencana (Kencono)",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Bumi Nabung Timur",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Bumi Nabung Utara",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Bumi Nabung Selatan",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Bumi Nabung Baru",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Bumi Nabung",
                "kelurahan": "Bumi Nabung Ilir",
                "kodepos": "34168"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Tanjung Anom",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Gunung Batin Udik",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Gunung Batin Ilir",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Gunung Agung",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Gunung Batin Baru",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Bandar Sakti",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Terusan Nunyai",
                "kelurahan": "Bandar Agung",
                "kodepos": "34167"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Selusuban",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Simpang Agung",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Muji Rahayu",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Harapan Rejo",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Gayau Sakti",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Fajar Asri",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Endang Rejo",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Dono Arum",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Wirata Agung Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Agung",
                "kelurahan": "Bumi Kencana",
                "kodepos": "34166"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Utama Jaya Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Varia Agung",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Sumber Agung Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Trimulyo Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Rejosari Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Subing Karya",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Fajar Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Qurnia Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Bumi Setia Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Dharma Agung Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Yukum Jaya",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Seputih Mataram",
                "kelurahan": "Banjar Agung Mataram",
                "kodepos": "34164"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Ono Harjo",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Ono Harjo",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Poncowati",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Nambah Dadi",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Karang Endah",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Indra Putra Subing",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Bandar Jaya Timur",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Bandar Jaya Barat",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Terbanggi Besar",
                "kelurahan": "Adi Jaya",
                "kodepos": "34163"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Sinar Banten/Bekri",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Kesumadadi",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Rengas",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Kedatuan",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Kesuma Jaya",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Binjai Ngagung (Agung)",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Goras Jaya",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Bekri",
                "kelurahan": "Bangun Sari",
                "kodepos": "34162"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Wono Sari",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Tulung Kanan",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Wates",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Terbanggi Subing",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Terbanggi Agung",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Sukajadi",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Sukajawa",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Sidokerto",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Sri Katon",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Sidowaras",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Seputih Jaya",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Putra Buyut",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Negara Aji Tua",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Negara Bumi Ilir",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Negara Bumi Udik",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Negara Aji Baru",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Mulyo Haji",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Komering Agung",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Komering Putih",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Haji Pemanggilan",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Jaya Sakti",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Gunung Sugih Raya",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Gunung Sugih",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Gunung Sari",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Gunung Agung",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Fajar Bulan (Pajar Bulan)",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Buyut Udik",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Buyut Utara",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Buyut Ilir",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Bumiratu",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Bumirahayu",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Bumiraharjo",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Bumi Jaya",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Anak Tuha",
                "kelurahan": "Bumi Aji",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bumi Ratu Nuban",
                "kelurahan": "Bulusari",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Gunung Sugih",
                "kelurahan": "Bangun Rejo",
                "kodepos": "34161"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Surabaya Ilir",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Surabaya Baru",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Sumber Agung",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Subang Jaya",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Sido Dadi",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Raja Wali",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Gaya Baru Lima",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Cempaka Putih",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Cabang",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Bandar Surabaya",
                "kelurahan": "Beringin Jaya",
                "kodepos": "34159"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Sumber Katon",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Sri Mulya Jaya",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Sri Katon",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Rawa Betik",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Mataram Ilir",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Kenanga Sari",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Tujuh",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Tiga",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Satu",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Enam",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Empat",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Delapan",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Seputih Surabaya",
                "kelurahan": "Gaya Baru Dua",
                "kodepos": "34158"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Teluk Dalem Ilir",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Rukti Basuki",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Restu Buana",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Restu Baru",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Reno Basuki",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Rekso Binangun",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Rantau Jaya makmur",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Rantau Jaya Ilir",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Rantau Jaya Baru",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Mranggi Jaya",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Joharan",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Binakarya Putra",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Binakarya Baru",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Bina Karya Utama",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Bina Karya Sakti",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Putra Rumbia",
                "kelurahan": "Bina Karya Jaya",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Bina Karya Buana",
                "kodepos": "34157"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Tanjungkerajan",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Swastika Buana",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sumber Fajar",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sumber Baru",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sumber Bahagia",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sri Basuki",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Siswa Bangun",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Setia Bumi",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Setia Bakti",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sari Bakti",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sanggar Buana",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Banyak",
                "kelurahan": "Sakti Buana",
                "kodepos": "34156"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rukti Harjo",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rukti Endah",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rejo Basuki",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rejo Asri",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Ramayana",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Ratna Khaton",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Oetama (Utama)",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Nirwana",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Murti",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Kelandungan",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Indera",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Gunawan",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Rama Dewa",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Seputih Raman",
                "kelurahan": "Buyut Baru",
                "kodepos": "34155"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Sri Tejo Kencono",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Purworejo",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Sapto Mulyo",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Nambah Rejo",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Kota Gajah Timur",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Kota Gajah",
                "kelurahan": "Kota Gajah",
                "kodepos": "34153"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Toto Katon",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Tanggul Angin",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Sri Sawahan",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Sido Mulyo",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Ngesti Rahayu",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Nunggal Rejo",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Mojo Pahit",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Badran Sari",
                "kodepos": "34152"
            },
            {
                "kecamatan": "Punggur",
                "kelurahan": "Asto Mulyo",
                "kodepos": "34152"
            }
        ],
        "k208": [
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Umbul Limus",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Tajur",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Sukarame",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Sukamaju",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Suka Jaya Punduh",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Suka Jaya Pidada",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Rusaba",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Pulau Pahawang",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Pulau Legundi",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Penyandingan",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Pekon Ampai",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Pekon Ampai",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Maja",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Kunyaian",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Kota Jawa",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Kekatang",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Marga Punduh",
                "kelurahan": "Kampung Baru",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Bawang",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Batu Raja",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Punduh Pidada (Pedada)",
                "kelurahan": "Banding Agung",
                "kodepos": "35453"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Way Urang",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Wates Way Ratai",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Trimulyo",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Tanjung Agung",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Tambangan",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Sumber Jaya",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Talang Mulyo",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Sidodadi",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Sukajaya Lempasing",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Poncorejo",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Sanggi",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Pesawaran Indah",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Paya",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Padang Cermin",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Munca",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Mulyosari",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Khepong Jaya",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Hurun",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Harapan Jaya",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Hanura",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Hanau Berak",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Gunung Rejo",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Gebang",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Durian",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Cilimus",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Ceringin Sari",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Bunut",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Bunut Seberang",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Batu Menyan",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Padang Cermin",
                "kelurahan": "Banjaran",
                "kodepos": "35451"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Tebajawa",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Tempel Rejo",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Way Kepayang",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Tanjung Kerta",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Sukamaju",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Sukajaya",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Sinar Harapan",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Penengahan",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Pesawaran",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Mada Jaya",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Padang Cermin",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Pasar Baru",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Kota Jawa",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Kubu Batu",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Harapan Jaya",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Kedondong",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Kerta Sana",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Gunungsari",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Gunung Sugih",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Khilau",
                "kelurahan": "Bayas Jaya",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Kedondong",
                "kelurahan": "Babakan Loa",
                "kodepos": "35368"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Sukamandi",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Tanjung Agung",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Way Harong",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Sidodadi",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Sindang Garut",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Pekondoh",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Pekondoh Gedung",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Margodadi",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Padang Manis",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Gedung Dalam/Dalom",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Kotadalam",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Gedung Dalam/Dalom",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Baturaja",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Cimanuk",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Way Lima",
                "kelurahan": "Banjar Negeri",
                "kodepos": "35367"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Way Layap",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Wiyono",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Sungai Langka",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Tamansari",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Sukaraja",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Pampangan",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Sukabanjar",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Sukadadi",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Negeri Sakti",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Padang Ratu",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Kurungan Nyawa",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Kutoarjo",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Gedung Tataan",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Karang Anyar",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Kebagusan",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Bogorejo",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Cipadang",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Bernung",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Gedong Tataan (Gedung Tataan)",
                "kelurahan": "Bagelen",
                "kodepos": "35366"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Sriwedari",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Trimulyo",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Sinar Jati",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Panca Bakti",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Rejoagung",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Negara Ratuwates",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Margo Rejo",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Kresno Widodo",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Margo Mulyo",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Kota Agung",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Kejadian",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Gerning",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Gunung Sugih Baru",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Bumiagung",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Gedung/Gedong Gumanti",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Tegineneng",
                "kelurahan": "Batang Hari Ogan",
                "kodepos": "35363"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Trisno Maju",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Tri Rahayu",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Sinarbandung",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Sidomulyo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Purworejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Roworejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Pujorahayu",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Poncokresno",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Negeri Katon/Katun",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Negeri Ulangan Jaya",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Pejambon",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Lumbi Rejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Negarasaka",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Karang Rejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Kalirejo",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Halangan Ratu",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Kagungan Ratu",
                "kodepos": "35353"
            },
            {
                "kecamatan": "Negeri Katon",
                "kelurahan": "Bangun Sari",
                "kodepos": "35353"
            }
        ],
        "k209": [
            {
                "kecamatan": "Panjang",
                "kelurahan": "Ketapang Kuala",
                "kodepos": "35246"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Way Laga",
                "kodepos": "35245"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Ketapang",
                "kodepos": "35245"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Karang Maritim",
                "kodepos": "35245"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Way Lunik",
                "kodepos": "35244"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Way Gubak",
                "kodepos": "35244"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Panjang Selatan",
                "kodepos": "35243"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Pidada",
                "kodepos": "35241"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Srengsem",
                "kodepos": "35242"
            },
            {
                "kecamatan": "Panjang",
                "kelurahan": "Panjang Utara",
                "kodepos": "35241"
            },
            {
                "kecamatan": "Telukbetung Barat",
                "kelurahan": "Bakung",
                "kodepos": "35238"
            },
            {
                "kecamatan": "Telukbetung Barat",
                "kelurahan": "Batu Putuk",
                "kodepos": "35239"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Perwata",
                "kodepos": "35237"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Sukamaju",
                "kodepos": "35236"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Way Tataan",
                "kodepos": "35236"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Keteguhan",
                "kodepos": "35235"
            },
            {
                "kecamatan": "Telukbetung Barat",
                "kelurahan": "Negeri Olok Gading",
                "kodepos": "35233"
            },
            {
                "kecamatan": "Telukbetung Barat",
                "kelurahan": "Sukarame II",
                "kodepos": "35234"
            },
            {
                "kecamatan": "Telukbetung Barat",
                "kelurahan": "Kuripan",
                "kodepos": "35232"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Kota Karang",
                "kodepos": "35231"
            },
            {
                "kecamatan": "Telukbetung Timur",
                "kelurahan": "Kota Karang Raya",
                "kodepos": "35231"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Talang",
                "kodepos": "35229"
            },
            {
                "kecamatan": "Bumi Waras",
                "kelurahan": "Garuntang",
                "kodepos": "35227"
            },
            {
                "kecamatan": "Bumi Waras",
                "kelurahan": "Bumi Raya",
                "kodepos": "35228"
            },
            {
                "kecamatan": "Bumi Waras",
                "kelurahan": "Sukaraja",
                "kodepos": "35226"
            },
            {
                "kecamatan": "Bumi Waras",
                "kelurahan": "Bumi Waras",
                "kodepos": "35225"
            },
            {
                "kecamatan": "Bumi Waras",
                "kelurahan": "Kangkung",
                "kodepos": "35224"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Pesawahan",
                "kodepos": "35223"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Gedong Pakuan/Pakuon",
                "kodepos": "35222"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Teluk Betung",
                "kodepos": "35221"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Sumur Putri",
                "kodepos": "35215"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Sumur Batu",
                "kodepos": "35214"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Pengajaran",
                "kodepos": "35215"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Gulak Galik",
                "kodepos": "35214"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Pahoman",
                "kodepos": "35213"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Kupang Teba",
                "kodepos": "35212"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Kupang Raya",
                "kodepos": "35212"
            },
            {
                "kecamatan": "Telukbetung Selatan",
                "kelurahan": "Gunung Mas",
                "kodepos": "35211"
            },
            {
                "kecamatan": "Telukbetung Utara",
                "kelurahan": "Kupang Kota",
                "kodepos": "35211"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Sumber Agung",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Kelapa Tiga Permai",
                "kodepos": "35159"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Pinang Jaya",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Kemiling Permai",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Kemiling Raya",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Kedaung",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Beringin Raya",
                "kodepos": "35158"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Sukadana Ham",
                "kodepos": "35156"
            },
            {
                "kecamatan": "Langkapura",
                "kelurahan": "Gunung Agung",
                "kodepos": "35157"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Susunan Baru",
                "kodepos": "35155"
            },
            {
                "kecamatan": "Langkapura",
                "kelurahan": "Bilabong Jaya",
                "kodepos": "35155"
            },
            {
                "kecamatan": "Langkapura",
                "kelurahan": "Langka Pura",
                "kodepos": "35154"
            },
            {
                "kecamatan": "Langkapura",
                "kelurahan": "Langka Pura Baru",
                "kodepos": "35154"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Sumber Rejo",
                "kodepos": "35153"
            },
            {
                "kecamatan": "Kemiling",
                "kelurahan": "Sumber Rejo Sejahtera",
                "kodepos": "35153"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Segala Mider",
                "kodepos": "35152"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Sukajawa Baru",
                "kodepos": "35151"
            },
            {
                "kecamatan": "Langkapura",
                "kelurahan": "Gunung Terang",
                "kodepos": "35152"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Gedong Air",
                "kodepos": "35151"
            },
            {
                "kecamatan": "Tanjung Karang Barat",
                "kelurahan": "Suka Jawa",
                "kodepos": "35151"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Kampung Baru Raya",
                "kodepos": "35149"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Surabaya",
                "kodepos": "35148"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Kampung Baru",
                "kodepos": "35149"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Kota Sepang",
                "kodepos": "35148"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Sepang Jaya",
                "kodepos": "35148"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Sidodadi",
                "kodepos": "35147"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Sukamenanti",
                "kodepos": "35146"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Sukamenanti Baru",
                "kodepos": "35146"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Gedong Meneng",
                "kodepos": "35145"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Gedong Meneng Baru",
                "kodepos": "35145"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa Raya",
                "kodepos": "35144"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa Nunyai",
                "kodepos": "35144"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa Pemuka",
                "kodepos": "35144"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa Jaya",
                "kodepos": "35144"
            },
            {
                "kecamatan": "Rajabasa",
                "kelurahan": "Rajabasa",
                "kodepos": "35144"
            },
            {
                "kecamatan": "Tanjung Senang",
                "kelurahan": "Way Kandis",
                "kodepos": "35143"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu",
                "kodepos": "35142"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Raya",
                "kodepos": "35142"
            },
            {
                "kecamatan": "Tanjung Senang",
                "kelurahan": "Labuhan Dalam",
                "kodepos": "35142"
            },
            {
                "kecamatan": "Tanjung Senang",
                "kelurahan": "Perumnas Way Kandis",
                "kodepos": "35141"
            },
            {
                "kecamatan": "Tanjung Senang",
                "kelurahan": "Tanjung Senang",
                "kodepos": "35141"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Perumnas Way Halim",
                "kodepos": "35141"
            },
            {
                "kecamatan": "Tanjung Senang",
                "kelurahan": "Pematang Wangi",
                "kodepos": "35141"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Kedaton",
                "kodepos": "35141"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Gunung Sulah",
                "kodepos": "35136"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Way Halim Permai",
                "kodepos": "35135"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Sukabumi",
                "kodepos": "35134"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Sukabumi Indah",
                "kodepos": "35132"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Tanjung Baru",
                "kodepos": "35133"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Jagabaya III",
                "kodepos": "35132"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Kali Balok/Balau Kencana",
                "kodepos": "35132"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Way Dadi",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Way Dadi Baru",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Jagabaya II",
                "kodepos": "35132"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Sukarame Baru",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Korpri Raya",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Sukarame",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Sukarame",
                "kelurahan": "Korpri Jaya",
                "kodepos": "35131"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Tanjung Agung Raya",
                "kodepos": "35129"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Tanjung Raya",
                "kodepos": "35128"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Tanjung Gading",
                "kodepos": "35128"
            },
            {
                "kecamatan": "Tanjung Karang Timur",
                "kelurahan": "Tanjung Agung",
                "kodepos": "35126"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Rawa Laut",
                "kodepos": "35127"
            },
            {
                "kecamatan": "Tanjung Karang Timur",
                "kelurahan": "Sawah Lama",
                "kodepos": "35125"
            },
            {
                "kecamatan": "Way Halim",
                "kelurahan": "Jaga Baya I",
                "kodepos": "35123"
            },
            {
                "kecamatan": "Tanjung Karang Timur",
                "kelurahan": "Sawah Brebes",
                "kodepos": "35124"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Kedamaian",
                "kodepos": "35122"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Nusantara Permai",
                "kodepos": "35122"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Campang Jaya",
                "kodepos": "35122"
            },
            {
                "kecamatan": "Sukabumi",
                "kelurahan": "Campang Raya",
                "kodepos": "35122"
            },
            {
                "kecamatan": "Kedamaian",
                "kelurahan": "Bumi Kedamaian",
                "kodepos": "35122"
            },
            {
                "kecamatan": "Tanjung Karang Timur",
                "kelurahan": "Kebonjeruk",
                "kodepos": "35121"
            },
            {
                "kecamatan": "Tanjung Karang Timur",
                "kelurahan": "Kota Baru",
                "kodepos": "35121"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Gotong Royong",
                "kodepos": "35119"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Enggal",
                "kodepos": "35118"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Palapa",
                "kodepos": "35116"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Pelita",
                "kodepos": "35117"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Durian Payung",
                "kodepos": "35116"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Kaliawi",
                "kodepos": "35115"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Kaliawi Persada",
                "kodepos": "35115"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Pasir Gintung",
                "kodepos": "35113"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Penengahan",
                "kodepos": "35112"
            },
            {
                "kecamatan": "Kedaton",
                "kelurahan": "Penengahan Raya",
                "kodepos": "35112"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Tanjung Karang",
                "kodepos": "35111"
            },
            {
                "kecamatan": "Tanjung Karang Pusat",
                "kelurahan": "Kelapa Tiga",
                "kodepos": "35111"
            },
            {
                "kecamatan": "Enggal",
                "kelurahan": "Gunung Sari",
                "kodepos": "35111"
            }
        ],
        "k210": [
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Waspada",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Suka Mulya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Tiga Jaya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Sidomulyo",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Suka Jaya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Sidodadi",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Sekincau",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Pampangan",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Pahayu Jaya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Pahayu Jaya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Marga Jaya",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Mekar Sari",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Batu Api",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Sekincau",
                "kelurahan": "Giham Sukamaju",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Basungan",
                "kodepos": "34885"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Tambak Jaya",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Tanjung Raya",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Sukaraja",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Sukananti",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Pura Laksana",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Mutar Alam",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Padang Tambak",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Karang Agung",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Way Tenong",
                "kelurahan": "Fajar Bulan (Pajar Bulan)",
                "kodepos": "34884"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Tugu Ratu",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Tri Mekar Jaya",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Tembelang",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Sumber Agung",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Suoh",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Tanjungsari",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Suka Marga",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Srimulyo",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Roworejo",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Sidorejo",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Ringin Sari",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Ringin Sari",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Negeri Jaya",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Gunung Ratu",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Suoh",
                "kelurahan": "Banding Agung",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Bumi Hantatai",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Teba Liyokh",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Bandar Negeri Suoh",
                "kelurahan": "Bandar Agung",
                "kodepos": "34882"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Negeri Ratu",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Pekon Balak",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Sukabumi",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Sukaraja",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Kerang",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Kota Besi",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Kegeringan",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Kembahang",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Canggu",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Batu Brak",
                "kelurahan": "Gunung Sugih",
                "kodepos": "34881"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Ujung",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Tawan Suka Mulya",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Tapak Siring",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Tawan Suka Mulya",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Suka Maju",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Suka Mulya",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Tanjung Raya",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Suka Banjar",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Suka Banjar II Ujung Rembun",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Lombok Timur",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Pancur Mas",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Pagar Dewa",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Keagungan",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Lombok",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Lombok Selatan",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Jaga Raga",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Lumbok Seminung",
                "kelurahan": "Heni Harong",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Bumi Jaya",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Hanakau",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Bandar Baru",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Sukau",
                "kelurahan": "Buay Nyerupa",
                "kodepos": "34879"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sumber Alam",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sukajadi",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sri Menanti",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sukadamai",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sidodadi",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Sinar Jaya",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Rigis Jaya",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Semarang Jaya",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Mangarai",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Air Hitam",
                "kelurahan": "Gunung Terang",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Way Ngison",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Turgak",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Sukarame",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Suka Makmur",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Serungkuk",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Luas",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Kubu Liku Jaya",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Kejadian",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Kenali",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Hujung",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Fajar Agung",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Campang Tiga",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Bedudu",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Belalau",
                "kelurahan": "Bumi Agung",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Batu Kebayan",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Bakhu",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Atar Bawang",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Atar Kuwau",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Batu Ketulis",
                "kelurahan": "Argo Mulyo",
                "kodepos": "34872"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Way Petay/Petai",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Tugu Sari",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Tugu Mulya",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Gedung Surian",
                "kelurahan": "Trimulyo",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Tribudi Makmur",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Tri Budisukur (Tribudi Syukur)",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Sukapura",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Sukajaya",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Sindang Pagar",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Sinar Luas",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Sumber Jaya",
                "kelurahan": "Simpangsari",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Purawiwitan",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Gedung Surian",
                "kelurahan": "Puramekar",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Muara Jaya Satu (I)",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Purajaya",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Muara Baru",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Muara Jaya Dua (II)",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Gedung Surian",
                "kelurahan": "Gedung Surian",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Gedung Surian",
                "kelurahan": "Mekarjaya",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Gedung Surian",
                "kelurahan": "Cipta Waras",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Padang Dalom",
                "kodepos": "34818"
            },
            {
                "kecamatan": "Kebun Tebu",
                "kelurahan": "Cipta Mulya",
                "kodepos": "34871"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Gunung Sugih",
                "kodepos": "34816"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Watas",
                "kodepos": "34817"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Sebarus",
                "kodepos": "34815"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Kubu Perahu",
                "kodepos": "34813"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Pasar Liwa",
                "kodepos": "34814"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Way Mengaku",
                "kodepos": "34812"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Sukarame",
                "kodepos": "34811"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Way Empulau Ulu",
                "kodepos": "34811"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Padang Cahya",
                "kodepos": "34811"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Sedampah Indah",
                "kodepos": "34811"
            },
            {
                "kecamatan": "Balik Bukit",
                "kelurahan": "Bahway",
                "kodepos": "34811"
            }
        ],
        "k211": [
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Way Haru",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Way Tias",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Ulok Mukti",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Sumberejo",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Sumber Agung",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Suka Negeri",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Sukarame",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Suka Marga",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Suka Mulya",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Suka Maju",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Suka Banjar",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Raja Basa",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Penyandingan",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Siring Gading",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Pemerihan",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Pekon Mon",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Parda Suka",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Parda Haga",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Pagar Bukit Induk",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Pagar Bukit",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Padang Dalam",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Negeri Ratu Ngambur",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Negeri Ratu Ngaras",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Negara",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Mulang Maya",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Mauara Tembulih",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Kota Batu",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Kota Jawa",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Gedung Cahya Kuningan",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Ngambur",
                "kelurahan": "Bumi Ratu",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat",
                "kelurahan": "Bandar Jaya",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Bengkunat Belimbing",
                "kelurahan": "Bandar Dalam",
                "kodepos": "34883"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Way Sindi Utara",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Way Sindi",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Way Sindi Hanuan",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Tebakak Way Sidi",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Way Nukak",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Penggawa V Tengah",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Penggawa V Ulu",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Menyancang",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Penengahan",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Kebuayan",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Laay",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Way Batang",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Karya Penggawa",
                "kelurahan": "Asahan Way Sindi",
                "kodepos": "34878"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Tanjung Sakti",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Rata Agung",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Tanjung Jati",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Pagar Dalam",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Pengehan",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Lemong",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Malaya",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Bandar Pugung",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Cahaya Negeri",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Lemong",
                "kelurahan": "Bambang",
                "kodepos": "34877"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Walur",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Way Narta",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Sukamarga",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Sukadana",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Pemancar",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Pekon Lok",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Padang Rindu",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Pasar Pulau Pisang",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Negeri Ratu",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Labuhan",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Kota Karang",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Kuripan",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Kerbang Langgar",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Kerbang Dalam",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Gedau",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pulau Pisang",
                "kelurahan": "Bandar Dalam",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Batu Raja",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Utara",
                "kelurahan": "Balam",
                "kodepos": "34876"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Tulung Bamban",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Ulok Manik",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Way Jambu",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Tanjung Jati",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Tanjung Raya",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Tanjung Setia",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Sukarame",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Paku Negara",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Pelita Jaya",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Negeri Ratu Tenumbang",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Pagar Alam",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Biha",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Marang",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Bangun Negara",
                "kodepos": "34875"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Way Suluh",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Way Redak",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Way Napal",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Ulu Krui",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Walur",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Sukanegara",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Sukabaru",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Suka Jadi",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Serai / Seray",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Rawas",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Penggawa V",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Penggawa V Ilir",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Pemerihan",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Pasar Krui",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Pajar Bulan",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Pahmungan",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Padang Raya",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Padang Haluan",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Lintik",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Mandiri Sejati",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Labuan",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Kota Pasar Krui",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Tengah",
                "kelurahan": "Kampung Jawa",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Gunung Kemala",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Gunung Kemala Timur",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Banjar Agung",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Way Krui",
                "kelurahan": "Bumi Waras",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Krui Selatan",
                "kelurahan": "Balai Kencana",
                "kodepos": "34874"
            },
            {
                "kecamatan": "Pesisir Selatan",
                "kelurahan": "Sumur Jaya",
                "kodepos": "34871"
            }
        ],
        "k212": [
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Tiuh Baru",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Way Limau",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Tanjungmas",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Tegal Mukti",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Sungsang",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Sri Menanti",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Sri Mulyo",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Sri Basuki",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Sari Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Setia Negara",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Purwa Negara",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Rejosasi",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Purwa Agung",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Purwa Agung",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Penengahan",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Pagar Iman",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Negeri Kasih",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Negeri Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Negeri Besar",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Negeri Agung",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Negara Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Negara Mulya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Negara Batin",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Marga Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Mulyo/Mulya Sari",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Kotabumi Way Kanan",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Kota Jawa",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Kota Baru",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Kiling Kiling",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Karya Agung",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Kaliawi Indah",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Karta Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Kaliawi",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Kaliawi",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Gisting Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Gedung Menong",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Gedung Harapan",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Gedong/Gedung Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Gedong/Gedung Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Bumi Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Besar",
                "kelurahan": "Bima Sakti",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Bandar Kasih",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Negeri Agung",
                "kelurahan": "Bandar Dalam",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Way Tuba",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Negara Batin",
                "kelurahan": "Adi Jaya",
                "kodepos": "34769"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Sukarame",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Tiuh Balak II",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Suka Negeri",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Negeri Sungkai",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Negeri Ujan Mas",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Labuhan Jaya",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Negeri Mulya",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Gunung Sari",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Gunung Sari",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Gunung Labuhan",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Gunung Pekuwon",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Curup Patah",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Gunung Baru",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Bengkulu Tengah",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Bengkulu Rejo",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Bengkulu Raman",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Banjar Sakti",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Bengkulu",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Bengkulu Jaya",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Gunung Labuhan",
                "kelurahan": "Banjar Ratu",
                "kodepos": "34768"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Way Tuba",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Way Tuba Asri",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Way Pisang",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Way Agung",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Way Mencar",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Tanjung Tiga",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Tanjung Raya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Suma Mukti",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Sukadana",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Sukabumi",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Sri Tunggal",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Suka Agung",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Say Umpu",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Simpang Tiga",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Ramsai/Ramsay",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Punjul Agung",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Mulya Jaya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Nuar Maju",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Madang Jaya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Karya Maju",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Lebak Peniangan",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Lebung Lawe",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Karya Jaya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Gunung Sari",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Buay Bahuga",
                "kelurahan": "Bumiharjo",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Bumi Dana",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Bukit Harapan",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Bukit Gemuruh",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Beringin Jaya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Beringin Jaya",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Way Tuba",
                "kelurahan": "Bandar Sari",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Sumber Sari",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Rebang Tangkas",
                "kelurahan": "Air Ringkih",
                "kodepos": "34767"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Simpang Asam",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Sumber Baru",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Rantau Temiang",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Rebang Tinggi",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Rantau Jaya",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Neki",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Pasir Banjit",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Menanga Jaya",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Menanga Siamang",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Kemu",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Juku Batu",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Campang Delapan",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Dono Mulyo",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Bandar Agung",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Bonglai",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Bali Sadar Tengah",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Bali Sadar Utara",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Bali Sadar Selatan",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Tanjung Kurung Lama",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Banjit",
                "kelurahan": "Argomulyo",
                "kodepos": "34766"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Tangkas",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Talang Mangga",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Sinar Gading",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Sukajadi",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Kota Way",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Kasui Pasar",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Kedaton",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Karang Lantang",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Kasui Lama",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Kampung Baru",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Jaya Tinggi",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Jukuh Kemuning",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Gelombang Panjang",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Datar Bancong",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Umpu Kencana",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Kasui",
                "kelurahan": "Bukitbatu",
                "kodepos": "34765"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Umpu Bakti",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Tanjung Sari",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Tanjung Raya Giham",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Tanjung Raya Sakti",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Sriwijaya",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Sidoarjo",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Sri Rejeki",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Segera Mider",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Sangkaran Bakti",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Rambang Jaya",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Negeri Bumi Putra",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Panca Negeri",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Negeri Baru",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Negeri Batin",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Lembasung",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Karang Umpu",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Gistang",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Gunung Sangkaran",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Gedong Batin",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Gedung Riang",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Bumi Ratu",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Bumi Baru",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Brata Yuda (Bhereta Yuda)",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Wonoharjo",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Blambangan Umpu",
                "kelurahan": "Blambangan Umpu",
                "kodepos": "34764"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Sukamaju",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Tulang Bawang",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Serinumpi",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Sapto Renggo",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Serdang Kuring",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Pisang Baru",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Pisang Indah",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Mulyo Harjo",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Mesir Udik",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Karangan",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Karangan",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Giri Harjo",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Bumi Say Agung",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Bumi Agung Wates/Watas",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bahuga",
                "kelurahan": "Bumi Agung",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Bumi Agung",
                "kodepos": "34763"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Tanjung Serupa",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Way Tawar",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Tanjung Ratu",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Tanjung Agung",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Serupa Indah",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Sukabumi",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Pakuan Sakti",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Rumbih",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Pakuan Baru",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Pakuan Ratu",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Negara Tama",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Negara Ratu",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Negara Sakti",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Karang Agung",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Negara Harja",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Gunung Cahya",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Gunung Waras",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Bumi Mulya",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Tiuh Balak Pasar",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Pakuan Ratu",
                "kelurahan": "Bhakti Negara",
                "kodepos": "34762"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Tiuh Balak I",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Taman Asri",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Tiuh Balak",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Setia Negara",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Sukosari",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Gunung Katun",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Mekar Asri",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Gedung Rejo",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Gedung Pakuon",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Campur Asri",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Cugah",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Bumi Merapi",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Bumi Rejo",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjarmasin",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Sari",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Setia",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Negara",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Baru",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Mulya",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Bakti Negara",
                "kodepos": "34761"
            },
            {
                "kecamatan": "Baradatu",
                "kelurahan": "Banjar Agung",
                "kodepos": "34761"
            }
        ],
        "k213": [
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Wira Bangun",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Rejo Binangun",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Simpang Pematang",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Mukti Karya",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Marga Rahayu",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Harapan Jaya",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Jaya Sakti",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Fajar Indah",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Fajar Asri",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Fajar Baru",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Bangun Mulyo",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Simpang Pematang",
                "kelurahan": "Budi Aji",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Adi Mulya / Mulyo",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Adi Mulya / Mulyo",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Adi Luhur",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Panca Jaya",
                "kelurahan": "Adi Karya Mulya",
                "kodepos": "34698"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Wonosari",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Wiralaga II",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Tirtalaga",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Wiralaga I",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Tanjung Serayan",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Tanjung Menang",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Tanjung Mas Mulya",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Tanjung Mas Jaya",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Tanjung Mas Makmur",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Talang Batu/T Gunung",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Sungai Badak",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Sungai Cambai",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Sumber Makmur",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Sidomulyo",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Pangkal Mas Jaya",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Pangkal Mas Mulya",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Mulya Sari (Mulyosari)",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Nipahkuning",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Muara Mas",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Eka Mulya",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Marga/Margo Jadi",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Telogo Rejo",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Mesuji Timur",
                "kelurahan": "Dwi Karya Mustika",
                "kodepos": "34697"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sungai Buaya",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sungai Sidang",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Sido Rahayu",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Way Puji",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Kurnia Agung",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Gunung Tiga",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Iso Mukti",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Panggung Jaya",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Panggung Rejo",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Rawa Jitu Utara",
                "kelurahan": "Sidang Bandar Anom",
                "kodepos": "34696"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Sri Gedung Mulya",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Suka Agung",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Labuhan Makmur",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Panca Warna",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Rejo Mulyo",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Labuhan Baru",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Labuhan Batin",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Kebun Dalam",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Kejadian",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Hadi Mulyo",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Gedung Boga/Raja",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Buko Poso",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Way Serdang",
                "kelurahan": "Bumi Harapan",
                "kodepos": "34684"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Wira Jaya",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Tri Karya Mulya",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Sri/Seri Tanjung",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Tanjung Sari",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Muara Tenang",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Sinar Laga",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Kagungan Dalam",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Mekar Sari",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Harapan Mukti",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Brabasan",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Bujung Buring",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Gedung Ram",
                "kodepos": "34598"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Bangun Jaya",
                "kodepos": "34598"
            }
        ],
        "k214": [
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Wonokerto",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Wonokerto I",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Tunas Asri I",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Tirta Kencana",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Tunas Asri",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Pulung Kencana",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Penumangan Baru",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Penumangan",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Pagar Dewa Suka Mulya",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Panaragan",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Panaragan Jaya",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Pagar Dewa",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Mulya Kencana",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Mulya Asri (Mulyo Asri)",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Mulya Jaya",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Menggala Mas",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Candra Kencana",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Cahyou/Cahyow Randu",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Marga Jaya Indah",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Bujung Sari Marga",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Pagar Dewa",
                "kelurahan": "Bujung Dewa",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Wai Sido",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Tengah",
                "kelurahan": "Bandar Dewa",
                "kodepos": "34693"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Karta Sari",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Marga Kencana",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Karta",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Karta Raharja",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Kagungan Ratu",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Gunung Katun Tanjungan",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Gedung Ratu",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Tulang Bawang Udik",
                "kelurahan": "Gunung Katun Malai",
                "kodepos": "34691"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Tunas Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Wonorejo",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Tri Tunggal Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Toto Katon",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Toto Mulyo",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Toto Wonodadi",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Terang Mulya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Terang Bumi Agung",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Sumber Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Suka Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Setia Bumi",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Sido Makmur",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Sakti Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Panca Marga",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Mulya Sari",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Mulya Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Marga Sari",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Marga Mulya (Margo Mulyo)",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Marga Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Jaya Murni",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Gunung Agung I",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Gunung Terang",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Terang",
                "kelurahan": "Gunung Agung",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Gunung Agung",
                "kelurahan": "Bangun Jaya",
                "kodepos": "34683"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Murni Jaya",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Margo Mulyo",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Margo Dadi",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Makarti",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Gunung Menanti",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Gunung Timbul",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Daya Sakti",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Daya Murni",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Tumijajar",
                "kelurahan": "Daya Asri",
                "kodepos": "34594"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Pagar Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Pagar Buana",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Mekar Sari Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Mercu Buana",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Lesung Bakti Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Kibang Yekti Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Kibang Budi Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Kibang Tri Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Indraloka I",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Indraloka II",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Gunung Sari",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Lambu Kibang",
                "kelurahan": "Gilang Tunggal Makarta",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Balam Asri",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Balam Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Way Kenanga",
                "kelurahan": "Agung Jaya",
                "kodepos": "34388"
            }
        ],
        "k215": [
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Warga Makmur Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Tunggal Warga",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Warga Indah Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Tri Tunggal Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Tri Tunggal Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Tri Mulya Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Tri Mukti Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Tri Dharma Wira Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Sumber Makmur",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Sukamaju",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Ringin Sari",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Purwajaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Penawar Rejo",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Penawar Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Panca Karsa Purna Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Panca Mulya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Moris Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Mekar Indah Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Mekar Ayu",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Karya Murni Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Kahuripan Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Jaya Makmur",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Dwi Warga Tunggal Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Catur Karya Buana Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Bujuk Agung",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Bawang Tirto Mulyo",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Banjar Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Banjar Dewa",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Agung",
                "kelurahan": "Banjar Agung",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Baru",
                "kelurahan": "Balai Murni Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Agung Jaya",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Banjar Margo",
                "kelurahan": "Agung Dalem (Agung Dalam)",
                "kodepos": "34682"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Sukarame",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Penawar Baru",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Panduan Rajawali",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Mulya Aji",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Marga Jaya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Kecubung Raya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Kecubung Mulya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Kecubung Raya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Kecubung Jaya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Karya Bakti",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Gedung Aji",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Bina Bumi",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Meraksa Aji",
                "kelurahan": "Bangun Rejo",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Aji Permai Talang Buah",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Bandar Aji Jaya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Aji Murni Jaya",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Aji Jaya KNPI",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Gedung Aji",
                "kelurahan": "Aji Mesir",
                "kodepos": "34681"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Kuripan Dalem",
                "kodepos": "34619"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Lingai",
                "kodepos": "34619"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Tri Makmur Jaya",
                "kodepos": "34618"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Cempaka Dalem (Cempaka Dalam)",
                "kodepos": "34619"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Cempaka Jaya",
                "kodepos": "34617"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Kibang Pacing",
                "kodepos": "34617"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Sungai Luar",
                "kodepos": "34616"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Menggala",
                "kodepos": "34616"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Lebuh Dalem",
                "kodepos": "34615"
            },
            {
                "kecamatan": "Menggala Timur",
                "kelurahan": "Badarow Indah",
                "kodepos": "34616"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Kagungan Rahayu",
                "kodepos": "34613"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Ujung Gunung Ilir",
                "kodepos": "34614"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Astra Kesetra",
                "kodepos": "34613"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Bujung Tenuk",
                "kodepos": "34613"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Ujung Gunung",
                "kodepos": "34611"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Menggala Tengah",
                "kodepos": "34611"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Tiuh Toho",
                "kodepos": "34611"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Menggala Selatan",
                "kodepos": "34611"
            },
            {
                "kecamatan": "Menggala",
                "kelurahan": "Menggala Kota",
                "kodepos": "34611"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Yudha Karya Jitu",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Way Dente",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Wono Agung",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Teladas",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Pendowo Asri",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Sungai Burung",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Sungai Nibung",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Pasiran Jaya",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Mahabang",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Meda Sari",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Kekatung",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Kuala Teladas",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Karya Jitu Mukti",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Karya Cipta Abadi",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gunung Tapa Udik",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Hargo Mulyo",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Hargo Rejo",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gunung Tapa Ilir",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gunung Tapa Tengah",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gedung Meneng Baru",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gunung Tapa",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Gedung Karya Jitu",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gedung Meneng",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gedung Bandar Rejo",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Gedung Bandar Rahayu",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Dente Makmur",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Selatan",
                "kelurahan": "Bumi Ratu",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Sentosa",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Utama",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Mulya",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Sejahtera",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Jaya",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Makmur",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Abadi",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawajitu Timur",
                "kelurahan": "Bumi Dipasena Agung",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Bratasena Adiwarna",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Dente Teladas",
                "kelurahan": "Bratasena Mandiri",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Bakung Rahayu",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Bakung Udik",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Gedung Meneng",
                "kelurahan": "Bakung Ilir",
                "kodepos": "34596"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Yoso Mulyo (Duto Yoso Mulyo)",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Wiratama",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Wono Rejo",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Tri Tunggal Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Wiraagung Sari",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Tri Karya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Tri Rejo Mulyo",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Sumber Sari",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Tri Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Sumber Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Sumber Agung",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Suka Makmur",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Suka Bhakti",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Sidomakmur",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Sidomulyo",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Sidoharjo",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Sidodadi",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Setia Tama",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Sido Mekar",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Sido Mukti",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Rejosari",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Pulo Gadung",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Rawa Ragil",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Pasar Batang",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Panggung Mulya (Panggung Mulyo)",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Mulyo Dadi",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Panca Tunggal Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Mekar Asri",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Mesir Dwi Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Makarti Tama",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Karya Makmur",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Gedung Rejo Sakti",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Gedung Jaya",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Gedung Harapan",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Aji",
                "kelurahan": "Gedung Asri",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Dwi Mulyo",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Bumi Sari",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Penawar Tama",
                "kelurahan": "Boga Tama",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Gedung Aji Baru",
                "kelurahan": "Batu Ampar",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Batang Hari",
                "kodepos": "34595"
            },
            {
                "kecamatan": "Rawa Pitu",
                "kelurahan": "Andalas Cermin",
                "kodepos": "34595"
            }
        ],
        "k216": [
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Sumber Agung",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Surakarta",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Rejo Mulyo",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Sidomukti",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Peraduan Waras",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Pungguk Lama",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Penagan Ratu",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Papan Rejo",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Gedung Nyapah",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Bumi Jaya",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Bumi Agung Marga",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Timur",
                "kelurahan": "Banjar Agung",
                "kodepos": "34583"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Sumber Tani",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Subik",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Sinar Gunung",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Sri Bandung",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Pekurun Utara",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Pekurun Udik",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Pekurun Tengah",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Pekurun Selatan",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Pekurun Barat",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Pekurun",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Ogan Campang",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Ogan Jaya",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Negla Sari",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Nyapah Banyu",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Kedaton",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Kinciran",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Gunung Gijul",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Gunung Sadar",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Tengah",
                "kelurahan": "Gunung Besar",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Pekurun",
                "kelurahan": "Campang Gijul",
                "kodepos": "34582"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Way Lunik",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Tulung Singkip",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Trimodadi",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Tata Karya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Sukoharjo",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Tanjungiman",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Sukamaju",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Sido Rahayu",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Sinar Ogan",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Semuli Jaya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Semuli Raya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Ratu Abung",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Papan Asri",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Purba Sakti",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Pagar",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Pagar Gading",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Kembang Tanjung",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Kemalo Abung",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Kembang Gading",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Karya Sakti",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Kalibening Raya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Kalibalangan",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Jagang",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Gunung Sari",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Semuli",
                "kelurahan": "Gunung Kramat",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Cabang Empat",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Candi Mas",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Gilih Sukanegeri",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Cabang Abung Raya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Buring Kencana",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Bumi Restu",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Bumi Raya Pemekaran",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Bumi Raharja",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Bangun Sari",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Blambangan Pagar",
                "kelurahan": "Blambangan",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Bandar Sakti",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Surakarta",
                "kelurahan": "Bandar Agung",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Bandar Kagungan Raya",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Abung Selatan",
                "kelurahan": "Abung Jayo",
                "kodepos": "34581"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Sumber Agung",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Pakuan Agung",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Negeri Ujung Karang",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Karang Sakti",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Karang Sari",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Negeri Ratu",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Karang Mulyo",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Karang Rejo II",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Banjar Ratu",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Bandar Agung",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Muara Sungkai",
                "kelurahan": "Banjar Negeri",
                "kodepos": "34559"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Way Wakak",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Way Perancang",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Way Isem",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Tanjung Harta",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Talang Jembatan",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Simpang Abung/Agung",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Pengaringan",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Sabuk Empat",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Sabuk Indah",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Negeri Batin Jaya",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Ogan Lima",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Pematang Kasih",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Kistang",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Lepang Besar",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Kemplas/Kamplas",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Hujan Mas",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Gunung Maknibai",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Gunung Betuah",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Comok Sinar Jaya",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Cahaya Negeri",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Bumi Nabung",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Cahaya Mas",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Barat",
                "kelurahan": "Bumi Mandiri",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Bindu",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Beringin",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Ulak Ata",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Abung Kunang",
                "kelurahan": "Aji Kagungan",
                "kodepos": "34558"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tulung Balak",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Riang",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Suka Mulya",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sukasari",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sri Menanti",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sindang Agung",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sindang Marga",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sinarmulya",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sinar Jaya",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Priangan Ratu",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sido Mulyo",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Merambung",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Kemala Raja",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Karang Waringin",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Gunung Katon",
                "kodepos": "34557"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Tanjung Waras",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Ulak Rengas",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Tanjung Baru Timur",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Suka Menanti",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Tanjung Baru",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Suka Marga",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Sidomulyo",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Suka Maju",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Sekipi",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Sido Kayo",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Pulau Panggung",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Muara Dua",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Muara Aman",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Abung Tinggi",
                "kelurahan": "Kebun Dalam",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Bukit Kemuning",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Bukit Kemuning",
                "kelurahan": "Dwikora",
                "kodepos": "34556"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Tulung Buyut",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Sukadana Udik",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Tanah Abang",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Sukadana Ilir",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Ratu Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Padang Ratu",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Pampang Tanggub/Tangguk Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negeri Sakti",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Ogan Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negeri Ratu",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Negeri Campang Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Negeri Galih/Galik Rejo",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Negara Tulang Bawang Baru",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Negara Tulang Bawang",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negara Ratu",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negara Batin II",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Negara Kemakmuran",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negara Batin II",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Mulyo Rejo II",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Negara Batin",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Melungun Ratu",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Mulyo Rejo I",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Lubuk Rukam",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Mekar Asri",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Kota Negara",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Kota Negara Ilir",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Kota Napal",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Isorejo",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Hibul/Ibul Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Hanakau Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Haduyang Ratu",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Gedung Raja",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Gedung Makrifat",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Gedung Negara",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Ciamis",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Bunga Maju",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Gedung Batin",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Beringin Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Hulu Sungkai",
                "kelurahan": "Bunglay Tengah (Bunglao/Bonglai)",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Bumi Raharya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Batu Raja",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Tengah",
                "kelurahan": "Batu Nangkop",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Utara",
                "kelurahan": "Bangun Jaya",
                "kodepos": "34555"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Sri Jaya",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Sukajaya",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Sri Agung",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Sirna Galih",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Sinar Harapan",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Sidodadi",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Negeri Sakti",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Negara Agung",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Labuhan Ratu Pasar",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Lepang/Lempang Tengah",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Labuhan Ratu Kp",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Kubuhitu",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Kota Agung",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Karang Rejo",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Barat",
                "kelurahan": "Gunung Raja",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Ketapang",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Gedung Ketapang",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Gunung Labuan",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Cempaka Barat",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Cempaka Timur",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Cempaka",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Jaya",
                "kelurahan": "Cahaya Makmur",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Bumi Ratu",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Sungkai Selatan",
                "kelurahan": "Banjar Ketapang",
                "kodepos": "34554"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Kota Alam",
                "kodepos": "34519"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kotabumi Udik",
                "kodepos": "34518"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kotabumi Pasar",
                "kodepos": "34518"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kotabumi Ilir",
                "kodepos": "34517"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Sindang Sari",
                "kodepos": "34517"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kotabumi Tengah",
                "kodepos": "34516"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kotabumi Tengah Barat",
                "kodepos": "34516"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Cempedak",
                "kodepos": "34516"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Sribasuki",
                "kodepos": "34515"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Rejosari",
                "kodepos": "34514"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Kelapa Tujuh",
                "kodepos": "34513"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Wonomarto",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Way Melan",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Tanjung Senang",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Tanjung Aman",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Taman Jaya",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Talang Jali",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Talang Bojong",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Sinar Mas Alam",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Sumber Arum",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Sawo Jajar",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Margorejo",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Mulang Maya",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Madukoro Baru",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Madukoro",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Kota Gapura",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Karang Agung",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Kali Cinta",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Jerangkang",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Curup Guruh Kagungan",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi",
                "kelurahan": "Bojong Barat",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Utara",
                "kelurahan": "Banjar Wangi",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Bandar Putih",
                "kodepos": "34511"
            },
            {
                "kecamatan": "Kotabumi Selatan",
                "kelurahan": "Alam Jaya",
                "kodepos": "34511"
            }
        ],
        "k217": [
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Sumur Bandung",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Sumberjo",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Sumber Marga",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Sri Wangi",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Sri Rejosari",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Labuhan Ratu Satu (I)",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Labuhan Ratu Dua (II)",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Labuhan Ratu Danau",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Labuhan Ratu Baru",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Jepara",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Sakti",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Emas",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Fajar",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Dewa",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Caka",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Way Jepara",
                "kelurahan": "Braja Asri",
                "kodepos": "34396"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Wono Sari",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Tulus Rejo",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Sidodadi",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Siraman",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Kali Bening",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Pekalongan",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Jojog",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Gondang Rejo",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Ganti Warno",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Gantimulyo",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Adijaya",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Pekalongan",
                "kelurahan": "Adirejo",
                "kodepos": "34391"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Waringin Jaya",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Sri Menanti",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Sri Pendowo",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Sri Bawono (Bhawono)",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Sadar Sriwijaya",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Bandar Sribawono",
                "kelurahan": "Bandar Agung",
                "kodepos": "34389"
            },
            {
                "kecamatan": "Gunung Pelindung",
                "kelurahan": "Wai Mili",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Gunung Pelindung",
                "kelurahan": "Pelindung Jaya",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Gunung Pelindung",
                "kelurahan": "Pempen",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Gunung Pelindung",
                "kelurahan": "Nibung",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Gunung Pelindung",
                "kelurahan": "Negeri Agung",
                "kodepos": "34388"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Sumur Kucing",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Rejo Mulyo",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Pasir Sakti",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Purworejo",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Mekar Sari",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Mulyo Sari",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Kedung Ringin",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Pasir Sakti",
                "kelurahan": "Labuhan Ratu",
                "kodepos": "34387"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Trisinar",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Surya Mataram",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Sukaraja Tiga",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Sukadana Baru",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Negeri Katon",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Negeri Tua",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Negeri Agung",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Negeri Jemanten",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Jaya Guna",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Nabang Baru",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Gedung Wani",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Margatiga",
                "kelurahan": "Gedung Wani Timur",
                "kodepos": "34386"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Toba/Tuba",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Sidorejo",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Sindang Anom",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Purwo Kencono",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Pugung Raharjo",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Gunung Sugih Besar",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Mengandung Sari",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Gunung Pasir Jaya",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Bumi Mulyo",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Gunung Agung",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Gunung Mulyo",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Brawijaya",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Bauh Gunung Sari",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Bojong",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Sekampung Udik",
                "kelurahan": "Banjar Agung",
                "kodepos": "34385"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Sambi Rejo",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Tanjung Sari",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Peniangan",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Purwosari",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Pematang Tahalo",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Negara Batin",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Negara Saka",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Mekar Jaya",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Mumbang Jaya",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Jabung",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Gunung Sugih Kecil",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Gunung Raya",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Gunung Mekar",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Giri Mulyo",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Gunung Mas",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Bungkuk",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Bukit Raya",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Benteng Sari",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Belimbing Sari",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Marga Sekampung",
                "kelurahan": "Batu Badak",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Adirejo",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Asahan",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Jabung",
                "kelurahan": "Adiluhur",
                "kodepos": "34384"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Trimulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Wonokarto",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sumbersari",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Suko Harjo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sumber Gede",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sidomukti",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sidomulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sidodadi",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Sambikarto",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Mekar Mulya/Mulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Mekar Sari",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Karya Mukti",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Mekar Mukti",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Hargomulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Jadimulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Giri Klopo/Kelopo Mulyo",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Sekampung",
                "kelurahan": "Girikarto",
                "kodepos": "34382"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Telogo Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Sri Basuki",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Sumber Agung",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Purwodadi Mekar",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Rejo Agung",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Selo Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Nampi Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Bumi Harjo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Bumi Mas",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Batang Harjo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Buana Sakti",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Banjar Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Banar Joyo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Bale Rejo",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Adi Warno",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Batanghari",
                "kelurahan": "Balai Kencono",
                "kodepos": "34381"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Wana",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Sumberhadi",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Tanjung Aji",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Tebing",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Sidomakmur",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Melinting",
                "kelurahan": "Itik Renday (Rendai)",
                "kodepos": "34377"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Tanjung Wangi",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Tri Tunggal",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Sumber Rejo",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Sido Rahayu",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Sumber Jaya",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Mekar Karya",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Ngesti Karya",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Marga Batin",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Karya Basuki",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Jembrana",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Waway Karya",
                "kelurahan": "Karang Anom",
                "kodepos": "34376"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Raja Basa Lama Dua (II)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Raja Basa Lama Satu (I)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Tujuh (VII)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Raja Basa Lama",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Tiga (III)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Sembilan (IX)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Lima (V)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Delapan (VIII)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Empat (IV)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu Enam (VI)",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Labuhan Ratu",
                "kelurahan": "Labuhan Ratu",
                "kodepos": "34375"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Tegal Ombo",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Toto Mulyo",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Toto Projo",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Tanjung Kencono",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Tanjung Tirto",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Tambah Subur",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Kali Pasir",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Way Bungur (Purbolinggo Utara)",
                "kelurahan": "Taman Negeri",
                "kodepos": "34373"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Tulung Balak",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Sukaraja Nuban",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Trisno Mulyo",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Purwosari",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Sukacari",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Kedaton Induk",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Negara Ratu",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Kedaton II (Dua)",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Kedaton I (Satu)",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Gunung Tiga",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Gedung Dalam",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Bumi Jawa",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Batanghari Nuban",
                "kelurahan": "Cempaka Nuban",
                "kodepos": "34372"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Rejo Katon",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Restu Rahayu",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Rukti Sudiyo",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Ratna Daya",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Rejo Binangun",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Rantau Fajar",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Raman Fajar",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Raman Aji",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Raman Endra",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Kota Raman",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Raman Utara",
                "kelurahan": "Rama Puja",
                "kodepos": "34371"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Margo Toto",
                "kodepos": "34335"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Margo Jaya (Margajaya)",
                "kodepos": "34335"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Margo Sari",
                "kodepos": "34335"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Kibang",
                "kodepos": "34331"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Sumber Agung",
                "kodepos": "34332"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Purbo Sembodo",
                "kodepos": "34333"
            },
            {
                "kecamatan": "Metro Kibang",
                "kelurahan": "Jaya Asri",
                "kodepos": "34331"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Tulungpasik",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Way Areng (Wai Arang)",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Teluk Dalem",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Mandala Sari",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Mataram Baru",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Rajabasa Baru",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Mataram Baru",
                "kelurahan": "Kebon Damar",
                "kodepos": "34199"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Sri Minosari",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Sukorahayu",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Sri Gading",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Muara Gading Mas",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Labuhan Maringai",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Margasari",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Maringgai",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Karya Makmur",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Karyatani",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Bandar Negeri",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Labuhan Maringgai",
                "kelurahan": "Karang Anyar",
                "kodepos": "34198"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Mulya",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Yekti",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Indah",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Kencana",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Luhur",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Gemilang",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Braja Slebah",
                "kelurahan": "Braja Harjosari",
                "kodepos": "34196"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana Timur",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Surabaya Udik",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Terbanggi Marga",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana Selatan",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana Tengah",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana Jaya",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana Ilir",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Rantau Jaya Udik II",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Sukadana",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Putra Aji Satu",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Raja Basa Batanghari",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Rantau Jaya Udik I",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Pasar Sukadana",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Putra Aji Dua",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Negara Nabung",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Pakuan Aji",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Mataram Marga",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Muara Jaya",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Bumi Ayu",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Sukadana",
                "kelurahan": "Bumi Nabung Udik",
                "kodepos": "34194"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tegal Yoso",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Toto Harjo",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tanjung Inten",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tanjung Kesuma",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tegal Gondo",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tambah Dadi",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Tambah Luhur",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Taman Endah",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Taman Pajar",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Taman Cari",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Taman Bogo",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Purbolinggo",
                "kelurahan": "Taman Asri",
                "kodepos": "34192"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Nyampir",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Lehan",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Marga Mulya",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Mulyo Asri",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Catur Swako",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Donomulyo",
                "kodepos": "34182"
            },
            {
                "kecamatan": "Bumi Agung",
                "kelurahan": "Bumi Tinggi",
                "kodepos": "34182"
            }
        ],
        "k218": [
            {
                "kecamatan": "Metro Barat",
                "kelurahan": "Mulyosari",
                "kodepos": "34125"
            },
            {
                "kecamatan": "Metro Barat",
                "kelurahan": "Mulyojati",
                "kodepos": "34125"
            },
            {
                "kecamatan": "Metro Barat",
                "kelurahan": "Ganjar Asri",
                "kodepos": "34125"
            },
            {
                "kecamatan": "Metro Timur",
                "kelurahan": "Tejo Sari",
                "kodepos": "34124"
            },
            {
                "kecamatan": "Metro Selatan",
                "kelurahan": "Rejomulyo",
                "kodepos": "34123"
            },
            {
                "kecamatan": "Metro Selatan",
                "kelurahan": "Sumbersari",
                "kodepos": "34122"
            },
            {
                "kecamatan": "Metro Selatan",
                "kelurahan": "Margo Rejo",
                "kodepos": "34121"
            },
            {
                "kecamatan": "Metro Selatan",
                "kelurahan": "Magodadi",
                "kodepos": "34119"
            },
            {
                "kecamatan": "Metro Utara",
                "kelurahan": "Karang Rejo",
                "kodepos": "34119"
            },
            {
                "kecamatan": "Metro Utara",
                "kelurahan": "Purwo Sari",
                "kodepos": "34118"
            },
            {
                "kecamatan": "Metro Utara",
                "kelurahan": "Purwo Asri",
                "kodepos": "34117"
            },
            {
                "kecamatan": "Metro Utara",
                "kelurahan": "Banjar Sari",
                "kodepos": "34117"
            },
            {
                "kecamatan": "Metro Barat",
                "kelurahan": "Ganjar Agung",
                "kodepos": "34114"
            },
            {
                "kecamatan": "Metro Timur",
                "kelurahan": "Yosorejo",
                "kodepos": "34112"
            },
            {
                "kecamatan": "Metro Pusat",
                "kelurahan": "Hadimulyo Barat",
                "kodepos": "34113"
            },
            {
                "kecamatan": "Metro Pusat",
                "kelurahan": "Hadimulyo Timur",
                "kodepos": "34113"
            },
            {
                "kecamatan": "Metro Timur",
                "kelurahan": "Tejo Agung",
                "kodepos": "34112"
            },
            {
                "kecamatan": "Metro Timur",
                "kelurahan": "Iringmulyo",
                "kodepos": "34112"
            },
            {
                "kecamatan": "Metro Pusat",
                "kelurahan": "Yosomulyo",
                "kodepos": "34111"
            },
            {
                "kecamatan": "Metro Timur",
                "kelurahan": "Yosodadi",
                "kodepos": "34111"
            },
            {
                "kecamatan": "Metro Pusat",
                "kelurahan": "Imopuro",
                "kodepos": "34111"
            },
            {
                "kecamatan": "Metro Pusat",
                "kelurahan": "Metro",
                "kodepos": "34111"
            }
        ],
        "k219": [
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Rebi",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Tabarfane",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Juring",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Lutur",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Maekor",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Erersin",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan Utara",
                "kelurahan": "Hokmar",
                "kodepos": "97668"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Popjetur",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Ngaibor",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Ngaiguli",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Lor-lor",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Marfenfen (Marafenfen)",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Kalar-Kalar",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Laininir",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Jerol",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Kabalukin",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Gaimar",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Jelia",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Fatural",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Feruni",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Doka Barat",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan",
                "kelurahan": "Doka Timur",
                "kodepos": "97667"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Siya",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Meror",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Salarem",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Jorang",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Karey",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Gomarmeti",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Gomarsungai",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Dosimar",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Beltubur",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Selatan Timur",
                "kelurahan": "Batugoyang",
                "kodepos": "97666"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Warloy",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Warjukur",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Warabal",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Wakua",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Wailay",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Tanah Miring",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Selilau",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Selibata-Bata",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Ponom",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Papakula",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Namara",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Murai",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Mesiang",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Maririmar",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Manjau",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Mariri",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Lorang",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Maijuring",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Kwarbola",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Longgar",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Lola",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Koijabi",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Kobasel Timur",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Kobror",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Kobadangar",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Kobasel Fara",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Kaiwabar",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Karawai (Karawain)",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Jirlay",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Jambu Air",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Irloy",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Gulili",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Gomo Gomo",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Fatlabata",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Gardakau",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Dosinamalau",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Benjina",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Bemun",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Balatan",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Timur",
                "kelurahan": "Basada",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah Selatan",
                "kelurahan": "Apara",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Aru Tengah",
                "kelurahan": "Algadang",
                "kodepos": "97665"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Waiful (Waifual)",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Mohongsel",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Wafan",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Langhalau",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Leiting",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Kolaha",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Gomsey",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Berdefan",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Sir-Sir",
                "kelurahan": "Goda Goda",
                "kodepos": "97664"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Sewer",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Waria",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Kompane",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Kumul",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Jursiang",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Kabalsiang",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Kobamar",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Batulei (Batuley)",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Aru Utara Timur Batuley",
                "kelurahan": "Benjuring",
                "kodepos": "97663"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Wokam",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Wahayum / Wahayun",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Warialau",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Wangel",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Wahangulangula",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Ujir",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Tasin Waha",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Tungu",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Tunguwatu",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Siwalima",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Selmona",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Nafar (Napar)",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Samang",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Marlasi",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Mesidang",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Lau-Lau",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Kolamar",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Kobraur",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Kaibolafin",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Karangguli",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Jerwatu",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Kabufin",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Jabulenga",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Galai Dubu",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Gorar",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Aru Utara",
                "kelurahan": "Foket",
                "kodepos": "97662"
            },
            {
                "kecamatan": "Pulau-Pulau Aru",
                "kelurahan": "Durjela",
                "kodepos": "97662"
            }
        ],
        "k220": [
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Yamtel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Yamtel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Wermaf",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Wulurat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Weer Ohoiker",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Weer Ohoinam",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Werka",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Weer Frawaf",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Waurtahait",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Weduar Feer",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Weduar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Watuar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Waur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Watsin",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Watlar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Watkidat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Wafol",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Wakol",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Waerat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Uwat Wear",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Waer",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Uwat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Uwat Reyaan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Tutrean",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Udar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Uat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Tuburlay",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Tuburngil",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Tamangil Nuhuten",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Tamangil Nuhuyanat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Soin",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Soinrat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Soindat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Sirbante",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Sether (Sather)",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Reyamru",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Rerean",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Renfan (Renfaan)",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Renfaan Islam",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Renfaan GPM",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Renfaan Islam",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Rahareng",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Rahangiar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoiwang",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ohoiwirin",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoiwait",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Ohoituf",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ohoiraut",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Ohoirenan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoinangan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoinangan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ohoimajang",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoilim",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Ohoilean",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ohoifaruan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Ohoifau",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ohoiel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Ngurwalek",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ngurdu",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Ngurko",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ngefuit Atas",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ngat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ngefuit",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Ngan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Ngafan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Nerong",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Werfan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Nabaheng",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Ohoitadium",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Ohoiir",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Ngurditwain",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Kahar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Mun Essoy",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Ler Ohoilim",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Mataholat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Larat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Langgiar Haar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Laar Perda",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan",
                "kelurahan": "Kilwat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Kilwair",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Karkarit",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Hoor Kristen",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Hoor Islam",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Hollay",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Hollat Solair",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Hoko",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Hollat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Hoko",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Hoat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Harangur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Hangur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Wassar",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Renrahantel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Ohoiwait",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Ohoimur RK",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Ohoimur GPM",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Haar Ohoimel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Selatan Barat",
                "kelurahan": "Feer",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Fanwav",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Fako",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Fangamas",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Faa",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Elralang",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Dangarat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Depur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Elat",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Bombay",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar",
                "kelurahan": "Daftel",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Banda Suku Tigapuluh",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Banda Efruan",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Timur",
                "kelurahan": "Banda Eli/Ely",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Ad Ohoiwaf",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Ad Wearaur",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Besar Utara Barat",
                "kelurahan": "Ad Ngurwul",
                "kodepos": "97661"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Yatwav",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Watngon",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Yafavun",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Wain Baru",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Warbal",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Ur Pulau",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Wain",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Uf",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Tenbuk",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Tanimbar Kei",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Somlain",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Sare",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Semawi",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Revav (Rewav)",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Rumat",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Ohoiren",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Raat",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Ohoira",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Ohoinol",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Ohoidertutu",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Ohoilus",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Ngurwul",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Ohoidertom",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Barat",
                "kelurahan": "Matwair (Madwaer)",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Mastur",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Mastur",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Marfun",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Maar",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Iso",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Garara",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Elaar Ngursoin",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Elaar Lamagorang",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Elaar Let",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Denwet",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Disuk",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Danar Ohoiseb",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Danar Ternate",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur",
                "kelurahan": "Abean",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Kei Kecil Timur Selatan",
                "kelurahan": "Danar Lumefar",
                "kodepos": "97615"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Wirin",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Watngil",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Wearlilir",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Wab",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Warwut",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Tetoat",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Selayar",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Sitniohoi",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Sathean",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Rumadian",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Ohoiluk",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Ohoililir",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Ohoider Atas",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Ohoijang/Ohoiyong Watdek",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Ohoibadar",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Ohodertawun",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Ngursit",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Ngilngof",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Ngayub",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Ngabub",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Namar",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Madwat",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Loon",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Letman",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Letvuan",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Langgur",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Lairngangas",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Kolser",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Ibra",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Kelanit",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Faan",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Evu",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Kei Kecil",
                "kelurahan": "Dudunwahan",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Dian Pulau",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Manyeuw",
                "kelurahan": "Debut",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Dian Darat",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Hoat Sorbay",
                "kelurahan": "Arso",
                "kodepos": "97611"
            }
        ],
        "k221": [
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Yatoke",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Wulur",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Welora",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Watuwey (Watuwei)",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Wakpapapi",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Tutuwawang",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Wairatan (Wiratan/Wiratang)",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Rotnama",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Serili",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Telalora &#8211; Letoda",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Romkisar",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Pupioliora",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Regoha",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Romdara",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Nurnyaman",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Nura",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Nakarhamto",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Marsela",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Manuweri (Manuari)",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Luang Timur",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Mahaleta",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Luang Barat",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Letmasa",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Letwurung",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Lelang",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Latalola Kecil",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Lawawang",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Kumur",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Latalola Besar",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Kuay Melu",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Kroing",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Kehli",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Kokwari",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Dawelor Dawera",
                "kelurahan": "Ilmarang",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Ilih",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Ilbutung",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Iblatmuntah",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Emplawas (Empalwas)",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Bululora",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Elo",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Mdona Hyera/Hiera",
                "kelurahan": "Batu Gajah",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Bebar Timur",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Masela",
                "kelurahan": "Babyotang (Babiotang)",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Ahanari",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Babar Timur",
                "kelurahan": "Analutur",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Lakor",
                "kelurahan": "Yamluli",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Werwaru",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Wonreli",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Wakarleli",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Barat",
                "kelurahan": "Ustutun",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Uhak",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Tutuwaru",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Tutukey",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Tounwawan",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Tomra",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Tomliapat",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Barat",
                "kelurahan": "Telemar",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kepulauan Romang",
                "kelurahan": "Solath",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Lakor",
                "kelurahan": "Sera",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kisar Utara",
                "kelurahan": "Purpura (Pur-Pura)",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Patti",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Oirata Barat",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Oirata Timur",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Nuwewang",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kisar Utara",
                "kelurahan": "Nomaha",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Naumatang",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Nabar",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Moain",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Moning",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Masapun",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Mahuan",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Lurang",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Luhulely (Luhuleli)",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Lakor",
                "kelurahan": "Lolotwara (Lolotuara)",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Lekloor",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Lakor",
                "kelurahan": "Letoda",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kisar Utara",
                "kelurahan": "Lebelau",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Laitutun",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Kota Lama",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Barat",
                "kelurahan": "Klishatu",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Klis",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Lakor",
                "kelurahan": "Kety (Ketty)",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Barat",
                "kelurahan": "Karbubu",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Moa Lakor",
                "kelurahan": "Kaiwatu",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Kahilin",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kepulauan Romang",
                "kelurahan": "Jerusu",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Ilway",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Ilputih",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Ilwaki",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Barat",
                "kelurahan": "Ilmamau",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Ilpokil",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Kepulauan Romang",
                "kelurahan": "Hila",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Hiay",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Eray",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Utara",
                "kelurahan": "Elsulith",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Letti (Leti Moa Lakor)",
                "kelurahan": "Batumiau",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar Timur",
                "kelurahan": "Arwala",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Wetar",
                "kelurahan": "Amau",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Terselatan",
                "kelurahan": "Abusur",
                "kodepos": "97454"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Yaltubung",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Wanuwui (Manuwui)",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Upuhupun (Upuhupu)",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Wasarili",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Tela",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Tepa",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Rumalewang Kecil (Rumahlewang Kecil)",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Sinairusi",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Pota Kecil",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Rumalewang Besar (Rumahlewang Besar)",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Nusiata",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Pota Besar",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Lewah",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Hertuti",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Imroing",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Pulau Babar",
                "kelurahan": "Letsiara",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Pulau Wetang",
                "kelurahan": "Herley (Herley Hoka)",
                "kodepos": "97452"
            },
            {
                "kecamatan": "Damer",
                "kelurahan": "Batumerah",
                "kodepos": "97128"
            }
        ],
        "k222": [
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Yapas",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Warkar",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Tiflean",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Kur",
                "kelurahan": "Tubyal",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Romoin",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Mangur Niela",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Kur",
                "kelurahan": "Lokwirin",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Kanara",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Kur",
                "kelurahan": "Kaimear",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Kur Selatan",
                "kelurahan": "Hirit",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau-Pulau Kur",
                "kelurahan": "Finualen",
                "kodepos": "97652"
            },
            {
                "kecamatan": "Pulau Dullah Selatan",
                "kelurahan": "Tual",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Tayando Tam",
                "kelurahan": "Tayando Yamru",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Tayando Tam",
                "kelurahan": "Tayando Yamtel",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Tayando Tam",
                "kelurahan": "Tayando Ohoiel",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Tayando Tam",
                "kelurahan": "Tayando Langgiar",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Tamedan",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Tayando Tam",
                "kelurahan": "Tam Ngurhir",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Selatan",
                "kelurahan": "Taar",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Ohoitel",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Ohoitahit",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Ngadi",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Selatan",
                "kelurahan": "Masrum",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Selatan",
                "kelurahan": "Lodar Ell",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Labetawi",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Selatan",
                "kelurahan": "Ketsoblak",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Fiditan",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Dullah (Darat)",
                "kodepos": "97611"
            },
            {
                "kecamatan": "Pulau Dullah Utara",
                "kelurahan": "Dullah Laut",
                "kodepos": "97611"
            }
        ],
        "k223": [
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Ruku Ruku",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Pulau Panjang",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Lalasa",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Magat",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Perik Basaranggi",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Pulau Panjang",
                "kelurahan": "Argam",
                "kodepos": "97599"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Salagor Kota",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Suru",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Kwaos (Kuwaos)",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Lian Tasik",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Salagor Air",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Keta Rumadan",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Kian Laut",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Aruan Gaur",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Keta",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Teor",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Siritaun Wida Timur",
                "kelurahan": "Air Nanang",
                "kodepos": "97598"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Lapang Kampung Jawa",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Mamur",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Kiliwouw",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Karlokin",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Kartutin Kartenga",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Ker Ker",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Kampung Tengah Wermaf",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Duryar Rumoy",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Teor",
                "kelurahan": "Kampung Baru",
                "kodepos": "97597"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Waisalan",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Tunas Ilur",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Tinarin",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Tuha",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Sagey",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Miran",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Rarat",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Kota Siri (Kota Sirih)",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Goha",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Kilkoda",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Basarin",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Armada",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Bas",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Amarwawatu",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Aran",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Gorom Timur",
                "kelurahan": "Adar",
                "kodepos": "97596"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Wunin Eldedora",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Tamher Timur",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Tanah Baru",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Utiia",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Tamher Barat",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Sera",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Otademan",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Ondor",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Rumeon",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Miran Gota",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Namalean",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Loko",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Mida",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Lahema",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Kelibingan",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Kulugowa",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Keldor",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Kataloka",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Kelangan",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Ilili",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Karlomin",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Ilili",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Guliar",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Day (Dai)",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Efa (Effa)",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Pulau Gorong (Gorom)",
                "kelurahan": "Amarsekaru",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Wakate",
                "kelurahan": "Amar Laut",
                "kodepos": "97595"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Watu Watu",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Walang Tenga",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Waras Waras",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Urung",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Undur",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Taruy",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Sumbawa",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Sesar",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Selor",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Rumoga",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Rumfakar",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Mising",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Nekan",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Kumelang",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Kilwaru",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Kiltay (Kiltai)",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Kilmury",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Kilmoy (Kilmoi)",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Kilga Kilwouw",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Kilga Watubau",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Kilfura",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Kileser",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Kilbon Kway",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Kilbat",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Kian Darat",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Kellu",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Kelaba",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Keffing",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Kamar",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Gunak",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Seram Timur",
                "kelurahan": "Geser",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Gah",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Bati Kilwouw",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Danama",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Angar",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kian Darat",
                "kelurahan": "Artafela",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Tutuk Tolu",
                "kelurahan": "Airkasar",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Afang Kota",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Kilmury",
                "kelurahan": "Afang Defol",
                "kodepos": "97594"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Bula",
                "kodepos": "97555"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Waru",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Werinama",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Waiketam Baru",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Waisamet",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Tunsai",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Waematakabo",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Tubir Wasiwang",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Tum",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Tobo",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Tansi Ambon",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Solang",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Sumber Agung",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Silohan",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Salas",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Sesar",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Sabuai",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Rukun Jaya",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Polin",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Osong",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Nayet",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Nama Lena",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Limumir",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Nama Andan",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Naiwel Ahinulin",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Lapela",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Liliama",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Karay",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Kampung Gorom",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Kampung Wailola",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Jakarta Baru",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Jembatan Basah",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Kampung Baru",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Hote",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Funa Naibaya",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Gusalaut",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Hatumeten / Hatuimeten",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Englas",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Fattolo",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Dihil",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Elnusa",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula",
                "kelurahan": "Bula Air Fatolo",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Dawang",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Betuasa (Batuasa)",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Benggoi (Banggoi)",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Bemo",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Bemo Perak",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Teluk Waru",
                "kelurahan": "Bellis",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Werinama",
                "kelurahan": "Atiahu",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Bula Barat",
                "kelurahan": "Aki Jaya",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Abulate",
                "kodepos": "97554"
            },
            {
                "kecamatan": "Siwalalat",
                "kelurahan": "Adabai",
                "kodepos": "97554"
            }
        ],
        "k224": [
            {
                "kecamatan": "Banda",
                "kelurahan": "Selamon",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Tanah Rata",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Waer",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Pulau Rhum",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Rajawali",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Pulau Ai/Ay",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Pulau Hatta",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Lonthoir",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Merdeka",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Nusantara",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Kampung Baru",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Banda",
                "kelurahan": "Dwiwarna",
                "kodepos": "97593"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Tuhaha",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Ullath",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Tiouw",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Siri-Sori Amalatu",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Saparua",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Siri Sori",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Paperu",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Portho",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Noloth",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Ouw",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Kulur",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Itawaka",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Mahu",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Iha",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua Timur",
                "kelurahan": "Ihamahu",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Haria",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Saparua",
                "kelurahan": "Booi",
                "kodepos": "97592"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Wassu",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Sameth",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Pelauw",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Rohomoni",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Kariuw",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Oma",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Hulaliu",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Kabau",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Kailolo (Kailolopia)",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Aboru",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Pulau Haruku",
                "kelurahan": "Haruku",
                "kodepos": "97591"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Waai",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Tial",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Tulehu",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Tengah-Tengah",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Liang",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Salahutu",
                "kelurahan": "Suli",
                "kodepos": "97582"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Wakal",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu Barat",
                "kelurahan": "Wakasihu",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Seith",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Ureng",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Negeri Lima",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Mamala",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Morela",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu Barat",
                "kelurahan": "Larike",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu Barat",
                "kelurahan": "Liliboi",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Kaitetu",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Hitu Lama",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Hitu Mesing",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Hila",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu Barat",
                "kelurahan": "Hatu",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu",
                "kelurahan": "Asilulu",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Leihitu Barat",
                "kelurahan": "Allang",
                "kodepos": "97581"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Wotay",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Waru",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Watludan",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Trana",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Usliapan",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Nakupia",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Sefluru (Sifluru)",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Layeni",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Lesluru",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Mesa (Messa)",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Kokroman",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Kuralele",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Jerili",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Bumey",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Isu (Issu)",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Teon Nila Serua",
                "kelurahan": "Ameth",
                "kodepos": "97558"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Way Asih (Waiasih)",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Warasiwa",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Waiulu",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Waitonipa",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Waiputih",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Waitila",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Waimusi",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Wailoping",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Waimusal",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Wahai",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Tanah Merah",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Tihuanaa",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Sawai",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Seti",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Samai",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Sariputih",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Saleman",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Rumahwey",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Roho",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Rumah Sokat",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Pasanea",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Pasahari",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Paa",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Namto",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Marasahua",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Morokai (Morokay)",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Maneo Rendah",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Manusela",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Leaway (Leawai)",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Malaku",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Lisabata Timur",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Labuan",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Latea",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Kobisonta",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Kobi",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Kobi",
                "kelurahan": "Kobimukti",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Kanikeh (Kanike)",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Kalutu (Karlutu Kara)",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Huaulu",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Kaloa",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Gale-Gale",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Barat",
                "kelurahan": "Horale",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara Timur Seti",
                "kelurahan": "Aketernate",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Besi",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Seram Utara",
                "kelurahan": "Air Besar",
                "kodepos": "97557"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Wolu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Yamalatu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Yaputih",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Ulahahan",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Tehua",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Tehoru",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Teluti Baru",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Salamahu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Saunulu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Maneo Ratu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Mosso",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Piliama (Piliana)",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Lahakaba",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Laimu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Lafa",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Laha",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Telutih",
                "kelurahan": "Hunisi",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Haya",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Hatu",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Tehoru",
                "kelurahan": "Hatumete",
                "kodepos": "97553"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Yafila",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Yainuelo",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Teluk Elpaputih",
                "kelurahan": "Tananahu",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Teluk Elpaputih",
                "kelurahan": "Waraka",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Tamilouw",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Soahuku",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Teluk Elpaputih",
                "kelurahan": "Sahulau",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Sehati",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Sepa",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Teluk Elpaputih",
                "kelurahan": "Liang",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Makariki",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Rutah",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Hollo",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Amahai (Amaiiai)",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Banda Baru",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Amahai",
                "kelurahan": "Haruru",
                "kodepos": "97516"
            },
            {
                "kecamatan": "Masohi Kota",
                "kelurahan": "Lesane",
                "kodepos": "97514"
            },
            {
                "kecamatan": "Masohi Kota",
                "kelurahan": "Letwaru",
                "kodepos": "97515"
            },
            {
                "kecamatan": "Masohi Kota",
                "kelurahan": "Ampera",
                "kodepos": "97513"
            },
            {
                "kecamatan": "Masohi Kota",
                "kelurahan": "Namasina",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Sila",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Titawai (Titawaai)",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Masohi Kota",
                "kelurahan": "Namaelo",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Leinitu",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Nalahia",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Akoon",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Ameth",
                "kodepos": "97511"
            },
            {
                "kecamatan": "Nusalaut",
                "kelurahan": "Abubu",
                "kodepos": "97511"
            }
        ],
        "k225": [
            {
                "kecamatan": "Waelata",
                "kelurahan": "Widit",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Wapsalit",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Wanareja",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Wanakarta",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waetina",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waflan",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waflan",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Waetele",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Waenetat",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Batabual",
                "kelurahan": "Waemorat",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waelo",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Teluk Kaiely",
                "kelurahan": "Waelapia",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waeleman",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Waekasar",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Waekerta",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Waehata",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Wabloy",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Waegeren",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Teluk Kaiely",
                "kelurahan": "Seith",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Tifu",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Savana Jaya",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Parbulu",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Ohilahin",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Batabual",
                "kelurahan": "Pela",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Nafrua",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Batabual",
                "kelurahan": "Namlea Ilath",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Teluk Kaiely",
                "kelurahan": "Masarete",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Kubalahin",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Lele",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Teluk Kaiely",
                "kelurahan": "Kaiely (Kayeli)",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Teluk Kaiely",
                "kelurahan": "Kaki Air",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Batabual",
                "kelurahan": "Ilath",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Lolong Guba",
                "kelurahan": "Grandeng",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waeapo",
                "kelurahan": "Gogorea",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Deboway / Debowae",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Dava",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Batabual",
                "kelurahan": "Batu Jungku",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waelata",
                "kelurahan": "Basalale",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Wasi",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waspait",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Wasbaka",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Wamlana",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Wamana Baru",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Wainibe (Waenibe)",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Waipure (Waepure)",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waereman",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Waimangit (Waemangit)",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waelana-lana",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waemite",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waekose",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Tanjung Karang",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Waedanga",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Selwadu",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Raheriat",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Lemanpoli",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Kampung Baru",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Batlale",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Fena Leisela",
                "kelurahan": "Balbalu",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Awilinan",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Airbuaya",
                "kelurahan": "Air Buaya",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Waprea",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Lilialy",
                "kelurahan": "Waimiting (Waemiting)",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Waplau",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Wailihang (Waelihang)",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Waeura",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Waepotih",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Lilialy",
                "kelurahan": "Waeperang",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Lilialy",
                "kelurahan": "Ubung",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Skikilale",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Siahoni",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Lilialy",
                "kelurahan": "Sawa",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Sanleko",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Samalagi",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Namlea",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Namlea",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Lala",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Lamahang",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Lilialy",
                "kelurahan": "Jikumarasa (Jikumerasa)",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Karang Jaya",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Jamilu",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Waplau",
                "kelurahan": "Hatawano",
                "kodepos": "97571"
            },
            {
                "kecamatan": "Namlea",
                "kelurahan": "Batu Boy",
                "kodepos": "97571"
            }
        ],
        "k226": [
            {
                "kecamatan": "Waesama",
                "kelurahan": "Waeteba (Waetaba)",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Waesili",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Waetawa",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Waemasing",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Waelikut",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Simi",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Pohon Batu",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Lena",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Hote",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Waesama",
                "kelurahan": "Batu Kasa",
                "kodepos": "97574"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Wewali",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Wamkana",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Walunghelat",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Wali",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Wahaolon",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Waereman (Waeraman)",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Waeturen",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Waenalut",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Waenono",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Waenamaolon",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Waemala",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Waemulang",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Waeken",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Waelo",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Waekatin",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Waefusi",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Waehaka",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Trukat",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Uneth",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Tikbary",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Tifu",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Slealale",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Terkuri",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Siwatlahin",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Oki Baru",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Oki Lama",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Nusarua",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Namrinat",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Neath",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Mepa",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Nalbessy",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Liang",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Mangeswaen",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Masnana",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Lektama",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Leku",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Leksula",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Kase",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Labuang",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Kamlanglale",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Fatmite",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Grahwaen",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Fena Fafan",
                "kelurahan": "Fakal",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Elfule",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Leksula",
                "kelurahan": "Ewiri (Erwiri)",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Namrole",
                "kelurahan": "Batu Tulis",
                "kodepos": "97573"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Walbele",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Waepandan",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Waeha",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Waekeka",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Siopot",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Sekat",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Nanali",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Pasir Putih",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Fogi",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Biloro",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Emhugen",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Batu Layar",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Balpetu",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Balpetu",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Air Ternate",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Kepala Madan",
                "kelurahan": "Bala-bala",
                "kodepos": "97572"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Ulima",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Selasi",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Siwar",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Kampung Baru",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Lumoy",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Masawoy",
                "kodepos": "97512"
            },
            {
                "kecamatan": "Ambalau",
                "kelurahan": "Elara",
                "kodepos": "97512"
            }
        ],
        "k227": [
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Waesala (Waisala)",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Tuniwara",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Tonujaya",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Tomalehu Barat (Tumalehu Barat)",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Tomalehu Timur (Tumalehu Timur)",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Tahalupu",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Masawoi",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Sole",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Luhutuban",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Kelang Asaude",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Buano/Boano Utara",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Buano/Boano Selatan",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Kepulauan Manipa",
                "kelurahan": "Buano Hatuputih",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Huamual Belakang (Waisala)",
                "kelurahan": "Alang Asaude",
                "kodepos": "97567"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Watuy (Watui)",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Waisarisa",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Waisa",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Waipirit",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Waimital",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Waihatu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Waesamu (Waisamu)",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Uraur",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Tomalehu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Tihulale",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Tala",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Sumeith/Sameith Pasinaro",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Seruawan",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Seriholo",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Inamosol",
                "kelurahan": "Rumberu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Sanahu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Rumahkay",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Inamosol",
                "kelurahan": "Rambatu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Nuruwe (Nurue)",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Inamosol",
                "kelurahan": "Manusa",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Lohiatala",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Latu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Kamarian",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu Barat",
                "kelurahan": "Kamal",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Kairatu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Huku Kecil",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Inamosol",
                "kelurahan": "Hunitetu",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Inamosol",
                "kelurahan": "Huku Anakota",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Amalatu",
                "kelurahan": "Hualoy",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Kairatu",
                "kelurahan": "Hatusua",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Elpaputih",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Elpaputih",
                "kelurahan": "Abio Ahiolo",
                "kodepos": "97566"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Neniari / Niniari",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Piru",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Lumoly (Lumoli)",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Morekau",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Huamual",
                "kelurahan": "Luhu",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Huamual",
                "kelurahan": "Lokki",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Huamual",
                "kelurahan": "Kulur",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Kaibobo (Kaibobu)",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Kawa",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Seram Barat",
                "kelurahan": "Eti / Ety",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Huamual",
                "kelurahan": "Iha",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Huamual",
                "kelurahan": "Ariate",
                "kodepos": "97562"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Waraloin",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Walakone",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Wakolo",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Uwen Pantai",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Uweth / Uweh",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Tounusa",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Taniwel",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Solea",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Sukaraja",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Soakasale",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Sohuwe",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Nukuhai",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Rumahsoal",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Nuniali",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Riring",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Pasinalo",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Patahuwe",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Niwelehu",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Niniari",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Murnaten / Mornaten",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Nikulukan",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Maloang",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Masihuwey (Musihbuway)",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Matapa",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Lumahpelu (Lumapelu)",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Makububui",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Lohia Sapalewa",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Lisabata (Lisabata Barat)",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Lumahlatal (Lumalatal)",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Kasieh",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Laturake",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Hulung",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel Timur",
                "kelurahan": "Hatunuru",
                "kodepos": "97561"
            },
            {
                "kecamatan": "Taniwel",
                "kelurahan": "Buria",
                "kodepos": "97561"
            }
        ],
        "k228": [
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Wowonda",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Wermatang",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Welutu",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Weratan",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Sifnana",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Themin",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Tumbur",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Saumlaki",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Sangliat Dol",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Sangliat Krawain",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Rumasalut",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Olilit",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Makatiang",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Marantutul",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Matakus",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Lorulung",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Lermatang",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Latdalam",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Lauran",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Kamatubun",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Kabiarat Raya",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Bomaki",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Tanimbar Selatan",
                "kelurahan": "Ilngei",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wermakatian (Wer Maktian)",
                "kelurahan": "Batu Putih",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Arui Das",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Atubul Raya (Atabul Dol)",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Amdasa",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wertamrian",
                "kelurahan": "Arui Bab",
                "kodepos": "97464"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Wunlah",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Molu Maru",
                "kelurahan": "Wulmasa",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Nirunmas",
                "kelurahan": "Watmuri",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Nirunmas",
                "kelurahan": "Waturu",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Watmasa",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Watidal",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Walerang",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Molu Maru",
                "kelurahan": "Tutunametal",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Molu Maru",
                "kelurahan": "Wadankou (Wadankau)",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Teineman",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Sofyanin",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Nirunmas",
                "kelurahan": "Tutukembong",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Rumngeur",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Ritabel",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Romean",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Ridool",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Meyanodas",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Molu Maru",
                "kelurahan": "Nurkat",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Meyano Raya",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Meyanobab",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Lorwembun",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Lumasebu",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Nirunmas",
                "kelurahan": "Manglusi",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Lingada",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Lelingluan",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Lamdesar Barat",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Lamdesar Timur",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Labobar",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Kilon",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Kelaan",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Tanimbar Utara",
                "kelurahan": "Keliober",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Kilmasa",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Karatat",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Awear Rumngeur",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Awear",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Nirunmas",
                "kelurahan": "Arma",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Alusi Tamrian",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Alusi Bukyalin (Bukyalim)",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Alusi Kelaan",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Alusi Krawain",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Kormomolin",
                "kelurahan": "Alusi Batjas",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Molu Maru",
                "kelurahan": "Adodo Molo",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Yaru",
                "kelurahan": "Adodo Fordata",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Wuarlabobar",
                "kelurahan": "Abat",
                "kodepos": "97463"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Lingat",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Namtabung",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Werain",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Fursui",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Kandar",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Adaut",
                "kodepos": "97453"
            },
            {
                "kecamatan": "Selaru",
                "kelurahan": "Eliasa",
                "kodepos": "97453"
            }
        ],
        "k229": [
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Tihu",
                "kodepos": "97237"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Leahari",
                "kodepos": "97237"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Rutong",
                "kodepos": "97237"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Hutumuri",
                "kodepos": "97237"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Laha",
                "kodepos": "97236"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Wayame",
                "kodepos": "97234"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Tawiri",
                "kodepos": "97235"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Hative Besar",
                "kodepos": "97234"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Rumah Tiga",
                "kodepos": "97234"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Waiheru",
                "kodepos": "97233"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Poka",
                "kodepos": "97233"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Passo",
                "kodepos": "97232"
            },
            {
                "kecamatan": "Teluk Ambon",
                "kelurahan": "Hunuth (Durian Patah)",
                "kodepos": "97233"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Nania",
                "kodepos": "97232"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Negeri Lama",
                "kodepos": "97232"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Latta",
                "kodepos": "97231"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Lateri",
                "kodepos": "97231"
            },
            {
                "kecamatan": "Baguala",
                "kelurahan": "Halong",
                "kodepos": "97231"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Waihoka",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Naku",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Soya",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Hatalai",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Hukurila",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Kilang",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Leitimur Selatan",
                "kelurahan": "Ema",
                "kodepos": "97129"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Galala",
                "kodepos": "97128"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Hative Kecil",
                "kodepos": "97128"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Pandan Kasturi",
                "kodepos": "97128"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Batu Merah",
                "kodepos": "97128"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Honipopu",
                "kodepos": "97126"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Ahusen",
                "kodepos": "97127"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Batu Gaja (Batugajah)",
                "kodepos": "97127"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Uritetu",
                "kodepos": "97124"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Batu Meja",
                "kodepos": "97125"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Amantelu",
                "kodepos": "97122"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Rijali",
                "kodepos": "97123"
            },
            {
                "kecamatan": "Sirimau",
                "kelurahan": "Karang Panjang",
                "kodepos": "97121"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Nusaniwe I",
                "kodepos": "97117"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Latuhalat",
                "kodepos": "97118"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Seilale",
                "kodepos": "97118"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Benteng",
                "kodepos": "97117"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Nusaniwe",
                "kodepos": "97117"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Amahusu",
                "kodepos": "97117"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Kudamati",
                "kodepos": "97116"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Wainitu (Wainatu)",
                "kodepos": "97115"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Urimessing (Urimesing) Desa",
                "kodepos": "97113"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Urimessing (Urimesing) Kelurahan",
                "kodepos": "97113"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Mangga Dua",
                "kodepos": "97114"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Silale",
                "kodepos": "97111"
            },
            {
                "kecamatan": "Nusaniwe (Nusanive)",
                "kelurahan": "Waihaong",
                "kodepos": "97112"
            }
        ],
        "k230": [
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Woka Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Yawal",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Waisuba",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Waijoi",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Wasile",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Tutuling Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Toboino",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Tatam",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Tomares",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Tabanalou (Tabanoli)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Talaga Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Tanure",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Subaim",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Sondo-Sondo",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Sidomulyo",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Silalaysang (Silalayang)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Saolat",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Saramake (Saramaake)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Pintatu",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Puao",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Pintatu",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Nusa Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Nyaolako",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Nanas",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Nusa Ambu (Nusa Ambo)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Minamin",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Mekar Sari",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Marimoi",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Majiko Tongone",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Lolobata",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Loleba",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Labi Labi",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Ino Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Jiko Moi",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Kakaraino",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Helaitetor",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Iga",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Gulapapo",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Foli (Foly)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Hatetabako",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Ekorino",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Fayaul",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Ekor",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Dowongi Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Dodaga",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Daka Ino",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Bumi Restu",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Cemara Jaya",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Bukutio",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Utara",
                "kelurahan": "Bololo",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Tengah",
                "kelurahan": "Boki Miake (Bokimaake)",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Binagara",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile",
                "kelurahan": "Batu Raja",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Timur",
                "kelurahan": "Ake Daga",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Wasile Selatan",
                "kelurahan": "Ake Jawi",
                "kodepos": "97863"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Wayamli (Wayamly)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Yawanli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Wayafli (Wayamli)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Wasileo",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Tewil",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Waci",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Wailukum",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Teluk Buli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Tatangapu",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Sowoli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Sosolat",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Soa Laipoh",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Soa Sangaji",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Soa Gimalaha",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Sil",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Pumlanga",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Pekaulan",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Sailal",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Patlean",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Patlean Jaya",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Momole",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Pateley (Petelei)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Marasipno",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Maratana (Marathana Jaya )",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Miaf",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Kota Maba",
                "kelurahan": "Maba Sangaji (Gotowasi)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Loloelamo",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Lili",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Lolasita",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Jarajara",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Kasuba",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Gamesan",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Geltoli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Gotowasi",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Doromoi",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Utara",
                "kelurahan": "Dorosago (Dorosagu)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Gaifoli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Dorolamo",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Buli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Buli Asal",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Buli Karya",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Beringin Lamo (Baringin)",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Selatan",
                "kelurahan": "Bicoli",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Bangul",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Bebsili",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba",
                "kelurahan": "Baburino",
                "kodepos": "97862"
            },
            {
                "kecamatan": "Maba Tengah",
                "kelurahan": "Babasaram",
                "kodepos": "97862"
            }
        ],
        "k231": [
            {
                "kecamatan": "Patani",
                "kelurahan": "Yondeliu",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Yang",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani",
                "kelurahan": "Yeisowo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Umiyal",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani",
                "kelurahan": "Wailegi",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Umera",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Tepeleo Batudua",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Tilope (Tepeleo)",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Barat",
                "kelurahan": "Sibenpopo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Sanafi",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Sonof Kacepo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Peniti",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Sakam",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Palo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Pantura Jaya",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Nursifa",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Barat",
                "kelurahan": "Moreala",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Maliforo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Masure",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani",
                "kelurahan": "Kipai",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Kacepi",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Kapaleo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Gemia",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Pulau Gebe",
                "kelurahan": "Elfanun",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Damuli",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Barat",
                "kelurahan": "Bobane Jaya",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Utara",
                "kelurahan": "Bilifitu",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Barat",
                "kelurahan": "Bobane Indah",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani Barat",
                "kelurahan": "Banemo",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Patani",
                "kelurahan": "Baka Jaya",
                "kodepos": "97854"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Woekob",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Yeke",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Woejerana",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Were",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Waleh",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Weda / Wedana",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Wairoro Indah",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Sumber Sari",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Tilope (Tiloppe)",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Sosowomo",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Sidanga",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Sawai Itepo",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Nusliko",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Sagea",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Loleo",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Nurweda",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Messa",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Lembah Asri",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Lelilef Sawai",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Lelilef Waibulen",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Kobe",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Tengah",
                "kelurahan": "Kulo Jaya",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Kotalo",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Kluting Jaya",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Kiya",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Goeng",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Gemaf",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda",
                "kelurahan": "Fidy Jaya",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Fritu (Sepoh)",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Utara",
                "kelurahan": "Dotte",
                "kodepos": "97853"
            },
            {
                "kecamatan": "Weda Selatan",
                "kelurahan": "Air Salobar",
                "kodepos": "97853"
            }
        ],
        "k232": [
            {
                "kecamatan": "Oba",
                "kelurahan": "Woda",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Wama",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Toseho",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Tului Talagamori",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Tadupi",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Togema",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Tagalaya",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Sofifi",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Somahode",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Payahe",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Selamalofo",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Nuku",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Oba",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Lola",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Maidi",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Kusu Sinopa",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Lifofa",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Kusu Sinopa",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Koli",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Kosa",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Selatan",
                "kelurahan": "Hager",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Kaiyasa",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Gorojou",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Guraping",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Gita (Gita Raja)",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Galala",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba",
                "kelurahan": "Bale",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Bukit Durian",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Ampera",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Balbar",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Aketobato",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Aketobololo",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Akelamo",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Akesai",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Utara",
                "kelurahan": "Akekolano",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Akeguraci",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Tidore Timur (Pulau Tidore)",
                "kelurahan": "Tosa",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Oba Tengah",
                "kelurahan": "Akedotilou",
                "kodepos": "97852"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Tuguiha",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Topo Tiga",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Topo",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Tongowai",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Tomalou",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Tomagoba",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Soadara",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Toloa",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Sirongo Folaraha",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Seli",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Rum",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Rum Balibunga",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Ome",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Mareku",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Marekofo",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Maregam",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Maitara",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Maitara Selatan",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Timur (Pulau Tidore)",
                "kelurahan": "Mafututu",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Timur (Pulau Tidore)",
                "kelurahan": "Kalaodi",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Jaya",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Indonesiana",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Gurabati",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Gura-Bunga",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Goto",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Gubu Kusuma",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Folarora",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Fobaharu",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Timur (Pulau Tidore)",
                "kelurahan": "Dowora",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Selatan",
                "kelurahan": "Dokiri",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Bobo",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore Utara",
                "kelurahan": "Afa Afa",
                "kodepos": "97813"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Gamtufkange",
                "kodepos": "97812"
            },
            {
                "kecamatan": "Tidore (Pulau Tidore)",
                "kelurahan": "Soa Sio",
                "kodepos": "97811"
            }
        ],
        "k233": [
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Waisepa",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Wainin",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Waiman",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Selatan",
                "kelurahan": "Wainib",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Wailia",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Wailau",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Waigoiyofa",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Waihama",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Waibau",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Waiboga",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Selatan",
                "kelurahan": "Waigai",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Wai Ipa",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Selatan",
                "kelurahan": "Wai Tamua",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Wai Ina",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Umaloya",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Selatan",
                "kelurahan": "Skom",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Soamole",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Pohea",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Sama",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Paratina",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Pastina",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Nahi",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Ona",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Mangon",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Man-Gega",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Manaf",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Malbufa",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Kabau Pantai",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Barat",
                "kelurahan": "Kabau Darat",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Fukweu",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Selatan",
                "kelurahan": "Fuata",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Fokalik",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Fogi",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Fatkauyon",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Fatcei",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Fat Iba",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Fagudu",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana",
                "kelurahan": "Falahu",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Tengah",
                "kelurahan": "Bega",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sulabesi Timur",
                "kelurahan": "Baleha",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Sanana Utara",
                "kelurahan": "Bajo",
                "kodepos": "97795"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Ufung",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Timpaus",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Wayo",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Tanjung Una",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Tanjung Una",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Tikong",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Sumbong",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Tabona",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Pancoran",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat Laut",
                "kelurahan": "Salati",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Sahu",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Pancado",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Padang",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat Laut",
                "kelurahan": "Nggele",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Nunca",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Nggaki",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Mbono",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Minton",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Masonit",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Mananga",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Maranti Jaya",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Kramat",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Limbo",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Loho Bubba",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Kawalo",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Kilo",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat Laut",
                "kelurahan": "Kasong",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Kasuari",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Hai",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Jorjoga",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Holbota",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Gela",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Gurua",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Dege",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat",
                "kelurahan": "Bobong",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Barat Laut",
                "kelurahan": "Beringin Jaya",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Bapenu",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Selatan",
                "kelurahan": "Bahu",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Air Bulan",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Taliabu Utara",
                "kelurahan": "Air Kalimat",
                "kodepos": "97794"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Waitulia",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Timur",
                "kelurahan": "Waitina",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara Timur",
                "kelurahan": "Waisum",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Timur",
                "kelurahan": "Waitamela",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Selatan",
                "kelurahan": "Wailab",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Wailoba",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara Timur",
                "kelurahan": "Waisakai",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Waikoka",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Selatan",
                "kelurahan": "Waikafia",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Waikadai",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Waikadai Sula",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Urifola",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Wai U",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur",
                "kelurahan": "Tubang",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Lede",
                "kelurahan": "Todoli",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Lede",
                "kelurahan": "Tolong",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Sofan",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Saniahaya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Lede",
                "kelurahan": "Natang Kuning",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur",
                "kelurahan": "Samuya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Modapuhi Trans",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur",
                "kelurahan": "Penu",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Timur",
                "kelurahan": "Naflo",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara Timur",
                "kelurahan": "Pelita Jaya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Modapia",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Pastabulu",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Modapuhi",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Paslal",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Mantarara",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur",
                "kelurahan": "Parigi",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Minaluli",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Losseng",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Mangoli",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Lede",
                "kelurahan": "Lede",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Lede",
                "kelurahan": "Langganu",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara Timur",
                "kelurahan": "Kawata",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Timur",
                "kelurahan": "Kau/Kou (Kow)",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Kawadang",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Timur",
                "kelurahan": "Karamat Titdoy",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Selatan",
                "kelurahan": "Kaporo",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Kabunu",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Kamaya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Utara",
                "kelurahan": "Falabisahaya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Buruakol",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Tengah",
                "kelurahan": "Capalulu",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Selatan",
                "kelurahan": "Buya",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Taliabu Timur Selatan",
                "kelurahan": "Belo",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Selatan",
                "kelurahan": "Auponhia",
                "kodepos": "97793"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Pelita",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Pas Ipa",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Lelyaba",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Lekokadai",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Lekosula",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Johor Perda",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mangoli Barat",
                "kelurahan": "Dofa",
                "kodepos": "97792"
            }
        ],
        "k234": [
            {
                "kecamatan": "Obi Timur",
                "kelurahan": "Wooi",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Wayaloar",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Waringi",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Timur",
                "kelurahan": "Sum",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Tapa",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Soligi",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Timur",
                "kelurahan": "Sosepe",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Soasangaji",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Sambiki",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Pasir Putih",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Mano",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Ocimaloleo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Madapolo Timur",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Manatahan",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Loleo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Madapolo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Madapolo Barat",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Timur",
                "kelurahan": "Kello (Kelo)",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Laiwui",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Kawasi",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Jikotamo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Gambaru",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Jikohai (Jikohay)",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Fluks (Fluk)",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Galala",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Utara",
                "kelurahan": "Cap",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Selatan",
                "kelurahan": "Bobo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Buton",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Bobo",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Baru",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Anggai",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Alam Kenanga",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi Barat",
                "kelurahan": "Alam Pelita",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Air Mangga Indah",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Obi",
                "kelurahan": "Akegula",
                "kodepos": "97792"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Yoyok",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Yaba",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Wayaua",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Wiring",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Wayamiga",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Wayatim",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Wayakuba",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Waya",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Tuwokona (Tuakona)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Tutupa",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Tomori",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Tutuha",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Toin",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Timlonga",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Timlonga",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Tawabi",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Tembal",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Tawa",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Tawa",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Tanjung Obit",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Tabapoma",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Tabangame",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Tabalema",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Tabajaya",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Sumae",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Suma Tinggi",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Sidopo",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Silang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Songa",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Sengga Baru",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Sidanga",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Sawanakar (Sawangakar/Sawangkang)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Sawadai",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Sayoang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Sabatang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Sali Kecil",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Pigaraja",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Prapakanda",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Pelita (Pelita Jaya)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Pasimbaos",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Panamboang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Papaloang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Palamea",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Nusa Babullah (Nusa Babi)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Nyonyifi",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Marituso (Maritosu)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Nondang (Mondang)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Marikapal",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Marabose",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Makian (Kampung Makian)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Mandawong (Mandaong)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Loleongusu",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Loleo Jaya",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Loleomekar",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Selatan",
                "kelurahan": "Liaro",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Lata Lata",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Lele",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Labuha",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Kusubibi",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Kukupang (Kakupang)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Kubung",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Kupal",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Koubalabala",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Kokotu",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Kotunang (Kukutunang)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Kasiruta Dalam",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Kaputusan (Kaputusang)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Kampung Baru",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Kaireu",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Jiko (Jeko)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Jojame",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Timur",
                "kelurahan": "Jeret",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Indomut",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Indong",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat",
                "kelurahan": "Indari",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Imbu Imbu",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Gorua Lolaro (Lolorogurua)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Hidayat",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Gilalang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Goro Goro",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Geti Baru",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Barat Utara",
                "kelurahan": "Geti Lama",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Doko (Doku)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Galala",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Selatan",
                "kelurahan": "Dgandasuli (Gandasuli)",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Bisori",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Bori",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur Tengah",
                "kelurahan": "Bibinoi",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Belang Belang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Bajo",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kepulauan Botanglomang",
                "kelurahan": "Batutaga",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Selatan",
                "kelurahan": "Bahu",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Awanggoa",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan Timur",
                "kelurahan": "Babang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Kasiruta Barat",
                "kelurahan": "Arumamang",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Amasing Kota",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Amasing Kota Barat",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Amasing Kota Utara",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Bacan",
                "kelurahan": "Amasing Kali",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Mandioli Utara",
                "kelurahan": "Akedabo",
                "kodepos": "97791"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Wosi",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Tanjung Jere",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Tobaru",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Tabahijrah",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Tagea",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Tabahidayah",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Sumber Makmur",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Selatan",
                "kelurahan": "Sawat",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Matuting Tanjung",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Sakita (Saketa/Botonam)",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Selatan",
                "kelurahan": "Ranga Ranga",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Maffa",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Matuting",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Lelewi",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Luim",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Lalubi",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Selatan",
                "kelurahan": "Kuwo",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Kebun Raja",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Kotalou",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Selatan",
                "kelurahan": "Gane Luar",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Selatan",
                "kelurahan": "Gaimu",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur Tengah",
                "kelurahan": "Bisui",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Foya",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Foya Tobaru",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Gane Timur",
                "kelurahan": "Akelamo/Fida",
                "kodepos": "97783"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Yomen",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Yamly",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Tawabi",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Tawa",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Tokaka",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Tabamasa",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Sekely",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Suka Damai",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Samat",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Samo",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Posi Posi",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Saketa",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Pulau Gala",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Oha",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Papaceda",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Pasi Palele",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Moloku",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Nurjihat",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Lemo Lemo",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Kurunga",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Liboba Hijrah",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Koititi",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Kukupang",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Jibubu",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Gumirah (Gumira)",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kepulauan Joronga",
                "kelurahan": "Gonone",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Gane Dalam",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Fulai (Jikolamo/Fulay)",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Dowora",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Doro",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Dolik",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Bumi Rahmat",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Cango",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Boso",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat Utara",
                "kelurahan": "Batulak",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Gane Barat",
                "kelurahan": "Balitata",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Wayasipang",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Gane Barat Selatan",
                "kelurahan": "Awis",
                "kodepos": "97782"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Tawabi",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Talimau",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Sagawele",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Siko",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Pasir Putih",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Posi Posi",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Ngute Ngute",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Orimakurunga",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Modayama",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Ngokomalako",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Ligua",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Lelei",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Laromabati (Loromabati)",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Selatan",
                "kelurahan": "Laluin",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Kida",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Laigoma",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Karamat",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Barat",
                "kelurahan": "Hatejawa",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Gunange",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Guruapin",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Gayap",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Barat",
                "kelurahan": "Fofao",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Gafi",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Dorolamo",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Barat",
                "kelurahan": "Busua",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Buli",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Barat",
                "kelurahan": "Bokimiake",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa",
                "kelurahan": "Bajo",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Kayoa Utara",
                "kelurahan": "Ake Jailolo",
                "kodepos": "97781"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Wailoa",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Walo",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Waigitang / Waigitan",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Waikyon (Kota)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Soma (Suma)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Talapao (Talapaon)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Tegono",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Sangapati",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Rabutdaiyo (Rabutdaio)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Sabalei (Sebelei)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Ploli (Ploly)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Mateketen (Matekente)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Ombawa",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Matentengen (Matan Tengin)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Malapat",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Kiowor (Kyowor)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Gitang",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Gorup",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Gurua",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Dalam",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian (Pulau Makian)",
                "kelurahan": "Dauri (Daiwori)",
                "kodepos": "97756"
            },
            {
                "kecamatan": "Makian Barat (Pulau Makian)",
                "kelurahan": "Bobawa (Bobawae)",
                "kodepos": "97756"
            }
        ],
        "k235": [
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Gosoma Maluku",
                "kodepos": "97775"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Doku Mira",
                "kodepos": "97777"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Hino",
                "kodepos": "97774"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Gamlamo",
                "kodepos": "97776"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Seseli Jaya",
                "kodepos": "97773"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Yao",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Wewemo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Titigogoli",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Towara",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Tawakali",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Tanjung Saleh",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Sopi",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Sopi Majiko",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Sakita",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Rahmat",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Podimor Padange",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Pangeo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Mira",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Maba",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Losuo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Loleo Jaya",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Lifao",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Loleo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Libano",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Korago",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Kenari",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Hapo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Gorugo",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Gorua Selatan",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Gorua",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Goa Hira",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Cendana",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Cempaka",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Buho Buho",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Bido",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Utara",
                "kelurahan": "Bere Bere",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Bere Bere Kecil",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Jaya",
                "kelurahan": "Aru",
                "kodepos": "97772"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Yayasan",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Wayabula",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Wawama",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Waringin",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Totodoku",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Tutuhu (Tuthu)",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Usbar Pantai",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Tiley Pantai",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Sangowo Timur",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Tiley Kusu",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Sangowo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Sangowo Barat",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Saminyamau",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Sambiki",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Timur",
                "kelurahan": "Sambiki Baru",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Sabatai Baru",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Sabatai Tua",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Sabala",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Raja",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Pilowo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Posi Posi Rao",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Pandangan",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Ngele Ngele Besar",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Ngele Ngele Kecil",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Muhajirin",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Nakamura",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Mandiri",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Momojiu",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Morodadi",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Leo Leo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Lou Madoro",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Koloray (Kolorai)",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Joubela",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Juanga",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Falila",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Galo Galo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Gotalamo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Daruba",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Dehegila",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Daeo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Daeo Majiko",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Darame",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Cio Maloleo",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Cucumare",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Cio Dalam",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Cio Gerong",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Aru Burung",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Aru Irian",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan Barat",
                "kelurahan": "Bobula",
                "kodepos": "97771"
            },
            {
                "kecamatan": "Morotai Selatan",
                "kelurahan": "Aha",
                "kodepos": "97771"
            }
        ],
        "k236": [
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Wonosari",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Wateto",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Warudu",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Waringin Lelewi",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Wangeotek (Wangeotak)",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Waringin Lamo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Tunuo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Tuguis",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Toliwang",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Torawat",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Toboulamo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Tolabit",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Tobobo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Tiowor",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Tobanoma",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Talapao",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Terpadu",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Tahane",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Takimo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Tafasoho",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Tagono",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Sumber Agung",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Sosol",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Soma",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Soasangaji Dim Dim",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Soa Hukum",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Soamaetek",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Sasur",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Sangaji Jaya",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Popon",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Sabaleh",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Samsuma",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Pitago",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Peleri",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Pediwang (Pidiwang)",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Patang",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Parseba",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Ngofagita",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Ngofakiaha",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Ngofabobawa",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Momoda",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Ngoali",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Margomolyo (Margomoyo)",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Malapa",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Matsa",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Makaeling",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Makarti",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Leleseng (Lelesang)",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Mailoa",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Kusu",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Kusu Lofra",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Kuntum Mekar",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Kukumutuk",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Kai",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Kai",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Jati",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Gulo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Goruang",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Gamlaha",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Gayok",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Dowongimaiti",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Gagaapok",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Daru",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Doro",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Boulamo",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Bobale",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Utara",
                "kelurahan": "Bori",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Bobawa",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao",
                "kelurahan": "Biang",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Barumadehe",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Kao Barat",
                "kelurahan": "Beringin Agung",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Malifut",
                "kelurahan": "Balisosang",
                "kodepos": "97764"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Yaro",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Yaro",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "WKO",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Wosi (Wosia)",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Wari",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Wari Ino",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Barat",
                "kelurahan": "Wangongira",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Toma Halu",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Upa",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Tolonua Selatan",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Tolonuo",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Barat",
                "kelurahan": "Togoliua",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Tobe",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Todokuiha",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Talaga Paca",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Tioua",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Tanjung Niara (Wosi/Wosia Selatan)",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Tagalaya",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Barat",
                "kelurahan": "Sukamaju",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Rawajaya",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Ruko",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Popila Utara",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Popilo",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Pale",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Pitu",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "MKCM",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Paca",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Mawea",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Meti",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Luari",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Lina Ino",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Mahia (Wosi/Wosia Tengah)",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Leleoto",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Lemah Ino",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Kupa Kupa Selatan",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Barat",
                "kelurahan": "Kusuri",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Kumo",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Kupa Kupa",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Kokota Jaya (Kokotua Jaya)",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Katana",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Kalipitu",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Tengah",
                "kelurahan": "Kali Upa",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Kakara B",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Kakara A",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Gosoma",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Gura",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Gorua Selatan",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Gorua Utara",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Timur",
                "kelurahan": "Gonga",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Utara",
                "kelurahan": "Gorua",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo",
                "kelurahan": "Gamsungi",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Gamhoku",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Selatan",
                "kelurahan": "Efi Efi",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Tobelo Barat",
                "kelurahan": "Birinoa",
                "kodepos": "97762"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Tutumaloleo",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Torawa",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Toweka",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Togawa",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Togawabesi",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Togasa",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Soakonora",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Soatobaru",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Simau",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Soa Sio",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Samuda (Samudra)",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Seki",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Salimuli",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Saluta",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Roko",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Pelita",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Pune",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Ori",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Ngidiho",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Mamuya",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Limau",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Makete",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Jere Tua",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Lalonga",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Kira",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Jare (Jere)",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Igobula",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Dokulamo",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Jare (Jere)",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Barat",
                "kelurahan": "Duma",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Bobi Singo",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Dodowo",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela",
                "kelurahan": "Barataku",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Utara",
                "kelurahan": "Beringin Jaya",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Galela Selatan",
                "kelurahan": "Bale",
                "kodepos": "97761"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Wori Moi",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Tuakara",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Tobo Tobo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Tate",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Supu",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Salube",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Posi Posi",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Pocao",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Podol",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Momojiu",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Ngajam",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Jikolamo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Kapa Kapa",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Igo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Galao",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Kailupa",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Gisik",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Dowonggila",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Fitako",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Dorume",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Asimiro (Asmiro)",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Dotia (Doitia)",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Dama",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Utara",
                "kelurahan": "Apulea",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Dedeta",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Cera",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda Kepulauan",
                "kelurahan": "Dagasuli",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Tetewang",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Pasir Putih",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Gamsungi",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Dum Dum",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Bobane Igo (Bobaneigo)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Kao Teluk",
                "kelurahan": "Akelamo Raya (Akelamo Kao)",
                "kodepos": "97752"
            }
        ],
        "k237": [
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tuguis",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Totala Jaya",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tuakara",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tomodo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tosomolo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Totala",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tolofuo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tolofuo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Tasye",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Soa-Sio",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Salu",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Pumadada",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Linggua",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Jano",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Laba Besar",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Laba Kecil",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Jangailulu",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Kedi",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Gamkahe",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Kahatola",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Bantol",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Baja",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Bakun",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Aruku",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Tukuoku",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Tosoa",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Todoke",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Tuguaer",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Buo",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Tobobol (Tabobol)",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Tuguis",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Bosala",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tobaol",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tongute Ternate Selatan",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Bilote",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Tobelos",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Tengowango (Teongowango)",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tongute Ternate",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Barataku",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Talaga",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tongute Goin",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Loloda",
                "kelurahan": "Bakun Pantai",
                "kodepos": "97755"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tongute Sungi",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Soasangaji",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Togowo",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Tahafo",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Tolisaor",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Soana Masungi",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Togoreba Sungi (Tugureba Sungi)",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Sarau",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Togoreba Tua",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Podol",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Togola Sanger/Sangir",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Sangaji Nyeku",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Togola Wayolo/Wayoli",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Pasalulu",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Ngawet",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Ngalo Ngalo",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Nanas",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Jere",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Naga",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Goin",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Kie Lei (Kie Ici)",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Gamsungi",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Maritango",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Gamsida",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Kampung Baru",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Gamkonora",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Gam Lamo",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Gam Ici",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Duono",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Borona",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Bataka",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Baru",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Utara",
                "kelurahan": "Aru Jaya",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Akesibu",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu",
                "kelurahan": "Ake Boso",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Ibu Selatan",
                "kelurahan": "Adu",
                "kodepos": "97754"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Worat Worat",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Taraudu",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Todahe",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Tacim",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Tibobo",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Tacici",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Taraudu Kusu",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Susupu",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Taruba",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Taba Campaka",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Sasur Pantai",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Sidodadi",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Sasur",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Ropu Tengah Hulu",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Loce",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Ngaon",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Peot",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Golago Kusuma",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Lako Akediri",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Gamnyial",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Lako Akelamo",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Gamomeng",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Jaraoke",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Gamsungi",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Hoku Hoku Gam",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Campaka",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Idamgamlamo",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Dere",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Golo",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Balisoan",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Goro Goro",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu",
                "kelurahan": "Balisoan Utara",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Goal",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Awer",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Akelamo",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Aketola",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Sahu Timur",
                "kelurahan": "Air Panas",
                "kodepos": "97753"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Ulo",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Tuguraci",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Tedeng",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Toniku",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Taboso (Tobosa/Tabaso)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Todowangi (Todowongi)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Tataleka",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Tuada",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Taba Damai (DB)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Tewe",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Soakonora",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Suka Damai",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Tauro",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Sidangoli Dehe",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Sidangoli Gam",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Saria",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Rioribati",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Pornity (Porniti)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Ratem",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Kuripasai",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Payo",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Hoku Hoku Kie (Huku-Huku Kie)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Pateng",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Idamdehe",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Idamdehe Gamsungi",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Moiso",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Hijrah",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Matui",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Hate Bicara",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Loloy (Lolori)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Guaeria",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Marmabati (Marimbati)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Gufasa",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Jalan Baru",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Guaemaadu (Guaimaadu)",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Gamtala",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Gamlenge",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Galala",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Braha",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Gamlamo",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Bobanehena",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Dodinga",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Bobo",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Domato",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Bobo Jiko",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Buku Bualawa",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Biamaahi",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Buku Maadu",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Bobane Dano",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Bukumatiti",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Bangkit Rahmat",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Akelaha",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Akediri",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Ake Jailolo",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo Selatan",
                "kelurahan": "Akeara",
                "kodepos": "97752"
            },
            {
                "kecamatan": "Jailolo",
                "kelurahan": "Acango",
                "kodepos": "97752"
            }
        ],
        "k238": [
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Tomajiko",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Tubo",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Togolobe",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Tobololo",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Togafo",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Tifure",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Tifure",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Takofi",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Tafraka",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Takome",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Tafaga",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Tafamutu",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Sulamadaha",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Tadenas",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Salahuddin",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Sangaji Utara",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Perum Bersatu",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Rua",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Pantai Sagu",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Moti Kota",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Mayau",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Mangga Dua",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Mado",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Lelewi",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Loto",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Kota Baru",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Kulaba",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Kayu Merah",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Kastela",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Moti (Pulau Moti)",
                "kelurahan": "Figur",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Foramadiahi",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Jambula",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Faudu",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Dorpedu",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Hiri",
                "kelurahan": "Dorari Isa",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Bula",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Batang Dua",
                "kelurahan": "Bido",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Akehuda",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Pulau Ternate",
                "kelurahan": "Afe Taduma",
                "kodepos": "97751"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Tafure",
                "kodepos": "97728"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Sango",
                "kodepos": "97729"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Sangaji",
                "kodepos": "97727"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Tabam",
                "kodepos": "97728"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Toboleu",
                "kodepos": "97726"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Dufa Dufa",
                "kodepos": "97727"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Soa",
                "kodepos": "97725"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Kasturian",
                "kodepos": "97726"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Makasar Timur",
                "kodepos": "97724"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Salero",
                "kodepos": "97725"
            },
            {
                "kecamatan": "Ternate Utara (Kota)",
                "kelurahan": "Soa Sio",
                "kodepos": "97723"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Makasar Barat",
                "kodepos": "97724"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Santiong",
                "kodepos": "97722"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Moya",
                "kodepos": "97723"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Kalumpang",
                "kodepos": "97722"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Sasa",
                "kodepos": "97719"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Gamalama",
                "kodepos": "97721"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Gambesi",
                "kodepos": "97719"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Kalumata",
                "kodepos": "97718"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Ubo-Ubo",
                "kodepos": "97717"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Fitu",
                "kodepos": "97718"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Takoma",
                "kodepos": "97714"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Bastiong Talangame",
                "kodepos": "97716"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Ngade",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Jati",
                "kodepos": "97716"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Tanah Raja",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "97715"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Muhajirin",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Toboko",
                "kodepos": "97715"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Mangga Dua Utara",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Tanah Tinggi Barat",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Jati Perumnas",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Tobona (Tabona)",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Selatan (Kota)",
                "kelurahan": "Bastiong Karance",
                "kodepos": "97713"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Kampung Pisang",
                "kodepos": "97712"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Stadion",
                "kodepos": "97712"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Maliaro",
                "kodepos": "97711"
            },
            {
                "kecamatan": "Ternate Tengah (Kota)",
                "kelurahan": "Marikurubu",
                "kodepos": "97711"
            }
        ],
        "k239": [
            {
                "kecamatan": "Pulau Banyak Barat",
                "kelurahan": "Ujung Sialit",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak",
                "kelurahan": "Teluk Nibung",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak Barat",
                "kelurahan": "Suka Makmur",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak",
                "kelurahan": "Pulau Balai",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak Barat",
                "kelurahan": "Haloban",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak",
                "kelurahan": "Pulau Baguk",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Pulau Banyak Barat",
                "kelurahan": "Asantola",
                "kodepos": "24791"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Ujung Bawang",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Teluk Rumbia",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Ujung",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Takal Pasir",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Teluk Ambun",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Telaga Sakti",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Suka Damai",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Suka Makmur",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Siti Ambia",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Selok Aceh",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Pemuka",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Pulo Sarok",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Rantau Gedang",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Kota Simboling",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Pasar Singkil",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Paya Bumbung",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Ketapang Indah",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Kilangan",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Gosong Telaga Timur",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Gosong Telaga Utara",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Kampung Baru",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Gosong Telaga Barat",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Singkil Utara",
                "kelurahan": "Gosong Telaga Selatan",
                "kodepos": "24785"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Tanjung Betik",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Tulaan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Tunas Harapan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Tanah Bara",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Tanah Merah",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Suro Baru",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Sumber Mukti",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Suka Makmur",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Sri Kayu",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Situbuh-Tubuh",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kuala Baru",
                "kelurahan": "Suka Jaya",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Situban Makmur",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Sirimo Mungkur",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Singkohor",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Sikoran",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Siompin",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Seping Baru",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Sianjo Anjo Merah",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Sidorejo",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Sanggaberu Silulusan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Sebatang",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Pertampakan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Rimo",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Samar Dua",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Pea Jambu",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Penjahitan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Perangusan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Pangkalan Sulampi",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Pandan Sari",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Napa Galuh",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Mukti Lincir",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Mukti Jaya (Mukti Harapan)",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Muara Pea",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Mandumpang",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Lentong",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Lae Sipola",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Lapahan Buaya",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Singkohor",
                "kelurahan": "Lae Pinang",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Lae Butar",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Lae Bangun",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Lae Balno",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Labohan Kera",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Ladang Bisik",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kuala Baru",
                "kelurahan": "Kuala Baru Sungai",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Ketangkihan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kuala Baru",
                "kelurahan": "Kuala Baru Laut",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Keras",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kuala Baru",
                "kelurahan": "Kayu Menang",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Gunung Lagan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Cingkam",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Butar",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Kota Baharu",
                "kelurahan": "Danau Bungara",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Bulu Sema",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Bulu Ara",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Blok VI Baru",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Bukit Harapan",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Blok 18",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Blok 31",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Gunung Meriah (Mariah)",
                "kelurahan": "Blok 15",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Danau Paris",
                "kelurahan": "Biskang",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Ujung Limus",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Suro Makmur",
                "kelurahan": "Alur Linci",
                "kodepos": "24784"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Tuh Tuhan",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Tugan",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Sukarejo",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Tanjung Mas",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Silatong",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Sododadi",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Serasah",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Siatas",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Pandan Sari",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Pangi",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Pertabas",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lipat Kajang Atas",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Pakiraman",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lae Riman",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lipat Kajang",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lae Gambir",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lae Gecih",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Lae Nipe",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Kuta Karangan",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Kuta Tinggi",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Guha",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Kain Golong",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Kuta Batu",
                "kodepos": "24783"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Cibubukan",
                "kodepos": "24783"
            }
        ],
        "k240": [
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Tualang",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Tanah Tumbuh",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Teladan Baru",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Siperkas",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Suaq/Suak Jampak",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Sibuasan",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Sibungke",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Sepadan",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Panglima Saman",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Pasar Rundeng",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Muara Batu-Batu",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Oboh",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Lae Mate",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Mandilam",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Lae Mate",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Kuala Kepeng (Keupeng)",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Kuta Beringin",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Dah",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Geruguh",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Harapan Baru",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Binanga",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Blukur/Belukur Makmur",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Rundeng",
                "kelurahan": "Badar",
                "kodepos": "24786"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Suka Makmur",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Tangga Besi",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Suka Maju",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Subulussalam Utara",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Subulussalam Selatan",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Singgersing",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Subulussalam",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Subulussalam Barat",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Sigerun (Sigrun)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Sikerabang",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Sikelang",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Rantau Panjang",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Sepang",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Sekelondang (Sikelondang_",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Pulau Belen (Pulo Bellen)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Pulau Kedep (Pulo Kedep)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Penuntungan",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Penanggalan",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Pegayo",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Pasir Belo",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Panji",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Pasar Panjang",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Makmur Jaya",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Mukti Makmur",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Namo Buayo",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Lae Simolap",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Lae Saga",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Longkib",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Lae Oram (Aram)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Lae Motong",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Lae Langge",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Kuta Tengah",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Lae Bersih",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Lae Ikan",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Jontor",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Kampung Baru",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Kuta Cepu",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Jabi-Jabi",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Jambi Baru",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Darul Makmur",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Gunung Bakti",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Darussalam",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Cipar Pari/Pare",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Cipar Pari/Pare Timur",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Darul Aman (Ginasing)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Penanggalan",
                "kelurahan": "Cepu",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Simpang Kiri",
                "kelurahan": "Buluh Dori (Buluh Besi)",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Sultan Daulat",
                "kelurahan": "Bawan",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Bukit Alim",
                "kodepos": "24782"
            },
            {
                "kecamatan": "Longkib",
                "kelurahan": "Bangun Sari",
                "kodepos": "24782"
            }
        ],
        "k241": [
            {
                "kecamatan": "Semadam",
                "kelurahan": "Simpang Semadam",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Suka Makmur",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Titi Pasir",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Sepakat Segenep",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Selamat Indah",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Semadam Asal",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Semadam Awal",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Petanduk I",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Ngkeran Alur Buluh",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Pasar Puntung+P2723",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Menjilai",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Petanduk",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Kinga Lapter",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Kinga Tebing Tinggi",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Beringin Gayo",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Beringin Horas",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Lawe Kinga Gabungan",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Kampung Baru",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Semadam",
                "kelurahan": "Kebun Sere",
                "kodepos": "24678"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Ukhat Peseluk",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Uning Sigugur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Tuhi Jongkat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Ukekh Deleng",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Tunas Mude",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Titi Mas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Tuah Mesade",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Titai Hakhapen",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Tenembak Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Tanoh Subukh",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Tanoh Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Tanah Khekhine",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Tanjung Sari",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Sumukh Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Tanah Baru",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Suka Damai",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Suka Jaya",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Suka Maju",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Sigai Indah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Suka Damai",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Sepekhinding",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Sibulussalam",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Sepakat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Sejahtera",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Sejahtera Baru",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Sebungke",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Salim Pipit",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Salim Pinim",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Sade Ate",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Punce Nali",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Pintu Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Pinggan Mbelang",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Permata Musara",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Penguhapan",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Perdamean",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Pardamean",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Pardomuan I",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Pardomuan II",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Ndauh Nitenggo",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Naga Timbul",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Mulie Damai",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Mutiara Damai",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Muara Setia",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Muara Situlen",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Lumban Tua",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Meranti",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Lingge Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Lumban Sitio-Tio",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Tua Makmur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Tua Persatuan",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Tua Gabungan",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Lawe Sumur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Tawakh",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Sigala Timur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Sigala II",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Sigala Barat Jaya",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Serke",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Sigala Barat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Lawe Sekhakut",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Rakat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Perbunga",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Mantik",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Pekhidinen",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Lawe Malum",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Loning Sepakat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Loning I",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Loning Gabungan",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Loning Aman",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Lawe Kesumpat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Desky Tongah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Desky/Deski I",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Lawe Desky Sabas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Laut Tawar",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Kute/Kuta Tengah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Kute Serke",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Kute Makmur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Kute Bakti",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Kuta/Kute Lang-Lang",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Kompas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Khambung Tubung",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Kertimbang",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Kedataran Gabungan",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Karya Indah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Kayu Mbelin",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Kane Mende",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Jambur Damar",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Gunung Pak-Pak",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Hakhapen",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Gelah Musara",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Gaye Sendah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Gaya Jaya",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Gabungan Parsaoran",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Dolok Nauli",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Enmya Batu Dua Ratus",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Desky Jaya",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Darul Aman",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Cinta Makmur",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Bunbun Indah War",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Makmur",
                "kelurahan": "Cinta Damai",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Bukit Sepakat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Bunbun Alas",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Lawe Sigala-Gala",
                "kelurahan": "Bukit Merdeka",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Bukit Bintang Indah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Bukit Mekhiah",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Alur Baning",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Bintang Alga Musara",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Alas Mesikhat",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Babul Rahmah",
                "kelurahan": "Alukh Bakhu",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Tualang Sembilar",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Leuser",
                "kelurahan": "Akhih Majile",
                "kodepos": "24673"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Terutung Seperai",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Tualang Baru",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Terutung Payung Gabungan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Terutung Payung Hilir",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Terutung Payung Hulu",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Terutung Megara Asli",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Terutung Megara Lawe Pasaran",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Tengku Mbelin",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Terutung Megakhe Bakhu",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Tembilakh Bakhu",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Tenembak Bintang",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Teger Miko",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Setia Baru",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Rikit Bur II",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Sebudi Jaya",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Rikit",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Rema",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Rikit Bur",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Pulo Kedondong",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Pulo Perengge",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Pinding",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Penosan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Pedesi",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Pejuang",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Pancakh Iman",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Paya Khambung",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Maha Singkil",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Mbak Sako",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Lembah Haji",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Likat",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Lawe Sumur Baru",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Lawe Sumur Sepakat",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Lawe Polak",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Lawe Sumur",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Lawe Hijo Metuah",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Lawe Hijo",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Lawe Dua Gabungan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kute Seri",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kute Lesung",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kute Lang Lang Bakhu",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kute Gekhat",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kute Gekhat",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kute Buluh",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kute Bunin",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kute Antara",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kuta Lingga",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kuta Lengat Pagan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kuta Lengat Selian",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kuning I",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Kuning II",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kisam Kute Pasir",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kisam Kute Rambe",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kisam Lestari",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Kerukunan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Kisam",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Empat Lima",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Gumpang",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Gumpang Jaya",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Darussalam",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Darul Imami",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Buah Pala",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Cinta Damai",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Biak Muli Pantai Raja",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Biak Muli Sejahtera",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Biak Muli",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Biak Muli Bakhu",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Lawe Sumur",
                "kelurahan": "Berandang",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Bambel Gabungan",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Bambel Baru",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Bambel",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Amaliah",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Ampera",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Timang Khase",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Bukit Tusam",
                "kelurahan": "Alur Buluh (Kuta Buluh)",
                "kodepos": "24671"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Stambul Jaya",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Rumah Luar",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Salim Pinim II",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Rumah Kampung",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Rambah Sayang",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Rih Mbelang (Mebelang)",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pulo Ndadap",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pulo Ndadap",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pintu Khimbe",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pulo Gadung",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Paye Munje",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Perapat Batu Nunggul",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pasir Bangun",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pasir Nunggul",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Ngkeran",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pasikh Nunang",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Pasikh Pekhmate",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Muara Baru",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Lawe Tungkal",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Lawe Sempilang",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Lawe Konker/Kongkir",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Lawe Lubang Indah",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Lawe Konker Hilir",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Kute Mejile",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kuta Cingkam II",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kuta Batu II",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kuta Cingkam I",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kuta Batu Baru",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kuta Batu I",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Kubu",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Khutung Mbelang",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Engkeran",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Jambukh Pekhmate",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Deleng Kukusen",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Batu Hamparan",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Lawe Alas",
                "kelurahan": "Darul Amin",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Alukh Nangke",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Tanah Alas",
                "kelurahan": "Alur Langsat",
                "kodepos": "24661"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Terutung Kute",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Ujung Baru",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Tanjung Mbakhu",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Tanjung Muda",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Tanjung Lama",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Tanjung Leuser",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Tanjung Aman",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Serimuda",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Simpang Empat Tanjung",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Rambung Teldak",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Pulo Piku",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Rambung Jaya",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Pulo Gadung",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Mamas Baru",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Mamas",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Lawe Stul/Setul",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Makmur Jaya",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Lawe Mamas Indah",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Lawe Pinis",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Kute Rambe",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Kute Ujung",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Kuning Abadi",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Kitei Meranggun",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Kokhtan Jaya",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Gulo",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Istiqomah",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Darul Makmur",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Darul Hasanah",
                "kelurahan": "Buntul Kendawi",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Tanah Merah",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Tanoh Megakhe",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Suka Rimbun",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Simpang III Jongar",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Simpur Jaya",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Salang Sigotom",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Sepakat",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Salang Alas",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Salang Baru",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Salang Muara",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Ranto Dior/Diur",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Rumah Bundar",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Peranginan",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Purwodadi",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Penungkunen",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Penyeberangan Cingkam",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Natam Baru",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Louser",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Natam",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Lembah Alas",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Sembekan",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Lawe Sekekhah",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Mengkudu",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Penanggalan",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Ger Ger",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Lawe Bekung Tampahan",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Bekhingin",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Lawe Bekung",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Lawe Aunan",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Kuta/Kute Pasir",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Kuta/Kute Tinggi",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Ketambe",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Kumbang Indah",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Kumbang Jaya",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Kaya Pangur",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Kayu Mentangur",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Kampung Baru",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Jongar",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Kati Maju",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Jambur Lak Lak",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Jati Rasa",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Deleng Megakhe",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Deleng Damar",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Gusung Batu",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Bintang Bener",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Bukit Mbakhu",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Datok Pining",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Batu Mberong",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Bener Mepapah",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Badar",
                "kelurahan": "Badar Indah",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Ketambe",
                "kelurahan": "Aunan Sepakat",
                "kodepos": "24652"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Ujung Barat",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Tualang Lama",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Terutung Pedi",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Tenembak/Tenebak Lang-Lang",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Terutung Mbelang",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Tenembak Juhar",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Tanoh Khukahen",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Telaga Mekar",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Tading Niulihi",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Simpang Empat",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Pulo Sanggakh",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Pulonas",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Pulonas Baru",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Pulo Latong",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Pulo Peding",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Pesuluk Pesimbe",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Perapat Sepakat",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Perapat Titi Panjang",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Perapat Hulu",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Perapat Hilir",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Penampaan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Pekhapat Timur",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Pasir Penjengakan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Pasir Gala",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Muhajirin",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Muara Lawe Bulan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Mendabe",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Melayu I",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Mburung Datuk Sekhudane",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Mbarung",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Mbacang Racun",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Sagu Hulu",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Sagu Hilir",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Sagu Baru",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Sagu",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Rutung",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Kolok",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Lawe Pangkat",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Lawe Kinge",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Bambel",
                "kelurahan": "Lawe Kihing",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Lawe Hakhum",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kutem Baru",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Kute Rih",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kute Bantil",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Kutacane Lama",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kuta Genting",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kuta Galuh Asli",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kuta Buluh Botong",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Kota Kutacane",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Kati Jeroh",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Katembaru Bencawan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Kane Lot",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kandang Mbelang Mandiri",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Kampung Raja",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Kampung Nangka",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Kampung Melayu Gabungan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Bunga Melur Gabungan",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Deleng Pokhisen",
                "kelurahan": "Beriring Naru",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Batum Bln Baru",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Batu Mbulan Sepakat",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Batu Mbulan II",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Batu Mbulan Asli",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Batu Mbulan I",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Lawe Bulan",
                "kelurahan": "Bahagia",
                "kodepos": "24651"
            },
            {
                "kecamatan": "Babussalam",
                "kelurahan": "Alas Memancar",
                "kodepos": "24651"
            }
        ],
        "k242": [
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Uyem Beriring",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Terlis",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Telege Jernih",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Terangon",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Soyo",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Setul",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Rime Jaya",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Rumpi",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Rerebe",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Rempelan Pinang",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Pulo Gelime",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Reje Pudung",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Perlak",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Persada Tongra",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Pasir",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Paya Kumer",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Pantan Lues",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Padang",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Pantan Kela",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Lestari",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Makmur Jaya",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Kutesange",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Kute Reje",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Kuala Jernih",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Gewat",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Jabo",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Garut",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Gawar Belangi",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Bukut",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Teripe/Tripe Jaya",
                "kelurahan": "Buntul Musara",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Blang Kala",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Blang Kuncir",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Terangun (Terangon)",
                "kelurahan": "Berhut",
                "kodepos": "24656"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Uring",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Uning Pune",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Ulon Tanoh",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Tingkem",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Tampeng",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Tampeng Musara",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Singah Mulo",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Sepang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Rikit Dekat",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Sekuelen",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Rema",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Rema Baru",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Ramung Musara",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Putri Betung",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Raklunung",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Porang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Pintu Rime",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Pertik",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Pining (Pinding)",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Peparik Dekat",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Peparik Gaib",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Pepelah",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Penosan",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Penosan Sepakat",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Penggalangan",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Penampaan Uken",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Pasir Putih",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Penampaan Toa",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Meloak Sepakat",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Palok",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Marpunge",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Lesten",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Leme",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Lempuh",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Kutelengat Sepakat",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Kute Sere",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Kuta Ujung",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Kota Blang Kejeren",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Kuta Lintang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Kong Paluh",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Ketukah",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Kerukunan Kota (Kuta Panjang)",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Gumpang Lempuh",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Kampung Jawa",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Gele",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Putri Betung",
                "kelurahan": "Gumpang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Gegarang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Gajah",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Durin",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Pining (Pinding)",
                "kelurahan": "Ekan",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Cempa",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Cike",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Bustanussalam",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Blang Jerango",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Beranang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Bukit",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Kuta Panjang",
                "kelurahan": "Bener",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Bacang",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Blang Jerango",
                "kelurahan": "Akul",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Tungel Baru",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Blang Kejeren",
                "kelurahan": "Agusen",
                "kodepos": "24655"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Tungel",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Suri Musara",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Tetinggi",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Remukut",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Seneren",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Penomon Jaya",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Pinang Rugub",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Rempelam",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Mangang",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Padang Pasir",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Lukup Baru",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Kuning Kurnia",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Kuning",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Kenyaran",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Kota Rikit Gaib",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Cane Toa",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Cane Uken",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Cane Baru",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Atu Kapur",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Rikit Gaib",
                "kelurahan": "Ampa Kolak",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Pantan Cuaca",
                "kelurahan": "Aih Selah",
                "kodepos": "24654"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Ume Lah",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Uning Gelung",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Uning Sepakat",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Tetingi",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Sangir",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Rerebe",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Rak Lintang",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Porang Ayu",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Pangur",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Panglima/Panglime Linting",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Kute/Kuta Bukit",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Kong",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Kendawi",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Gantung Geluni",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Cinta Maju",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Blangtemung",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Bener Baru",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Blangbengkik",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Anak Reje",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Dabun Gelang (Debun Gelang)",
                "kelurahan": "Badak",
                "kodepos": "24653"
            },
            {
                "kecamatan": "Blang Pegayon",
                "kelurahan": "Akang Siwah",
                "kodepos": "24653"
            }
        ],
        "k243": [
            {
                "kecamatan": "Bandar",
                "kelurahan": "Wonosari",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Wihni Durin",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Wih Tenang Toa",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Wih Tenang Uken",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Wih Resap (Pintu Wih Resap)",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Wer Tingkem",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Uning Sejuk",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Uning",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Tempen Baru",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Tempen Baru",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Tembolon",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Temas Mumanang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tawar Sedenge",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Tawar Bengi",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Suku Wih Ilang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tanjung Pura",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Tansaran Bidin",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Suku Bener",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Suku Sara Tangke",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Simpur",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Sosial",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Simpang Utama",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Simpang Renggali",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Seni Antara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Sidodadi",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Selamat Rejo",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Selisih Mara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Rikit Musara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Rusip",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Remang Ketike Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Rata Mulie",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Ramung Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Purwosari",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pondok Ulung",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Puja Mulia",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pondok Baru",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pondok Gajah",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Perumpaken Benjadi",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Petukel/Petukal Blang Jurong",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Pemanggo",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Penosan Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Paya Ringkel",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Payung",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Paya Baning",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Pasir Putih",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Pantan Tengah Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Pantan Kuli",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pakat Jeroh",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Nosar Tawar Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Nosar Tawar Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Muyang Kute Mangku",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Mutiara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Makmur Sentosa",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Lot Bener Kelipah",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Lewajadi",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Kutelah Lane",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Keramat Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Kerlah",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Kepie / Kepies",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Kala Nempan",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Kala Tenang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Jongok Meluem",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Jungke",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Janarata",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Jelobok",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Jadi Sepakat",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Jamur Atu",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Hakim Peteri Pintu",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Hakim Wih Ilang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Gunung Musara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Gunung Sayang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Goneng",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Gunung Antara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Geruti Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Gele Semayang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Gerpa",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Cemparan Pakat Jeroh",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Darul Aman Ramu",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Gelampang Wih Tenang Uken",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Cemparan Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Cemparan Lama",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Burni Pase",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Ceding Ayu",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bukit Wih Ilang",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Buntul Gayo",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Buntul Fitri (Peteri)",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Blang Pulo",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Syiah Utama",
                "kelurahan": "Blang Panu",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Bintang Permata",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Beranun Teleden",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Bintang Musara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Bintang Bener",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Bener Lukup II",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Bener Pepanyi",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Batin Bandar Jaya",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Bener Kelipah Selatan",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bener Kelipah",
                "kelurahan": "Bener Kelipah Utara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Bale Musara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Bale Purnama",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bahgie Bentona",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Permata",
                "kelurahan": "Ayu Ara",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Wonosobo",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Mesidah",
                "kelurahan": "Amor",
                "kodepos": "24582"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Wih Pesam",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Uring",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Waq Pondok Sayur",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Uning Bersah",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Uning Teritit",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Tingkem Bersatu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Ujung Gele",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Tingkem Asli",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Tingkem Benyer",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Syura Jadi",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Suka Rami Atas",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Suka Rami Bawah",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Suka Makmur Timur",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Suka Jadi",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Suka Makmur",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Simpang Bolek",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Simpang Teririt",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Sidie Jadi",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Simpang Antara",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Serule Kayu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Rembele",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Pilar Jaya",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Reje Guru",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Paya Gajah",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Pasar Simpang Tiga",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Panji Mulia I",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Pante Raya",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Panji Mulia II",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Mupakat Jadi",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Mutiara Baru",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Meluem",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Merie Satu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Lut Kucak",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Mekar Jadi Ayu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Kute Tanjung",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Kute Kering",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Kute Lintang",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Kenawat Redlong",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Kebun Baru",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Jamur Uluh",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Karang Rejo",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Isaq Busur",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Jamur Ujung",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Hakim Tunggul Naru",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Godang",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Gegerung",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Delung Asli",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Delung Tue",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Cinta Damai",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Burni Telong",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Bukit Pepanyi",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Bujang",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Bukit Bersatu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Blang Sentang",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Blang Tampu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Blang Panas",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Blang Paku",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Blang Kucak",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Blang Menara",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Blang Ara",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Batin Wih Pongas",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Bener Mulie",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Wih Pesam",
                "kelurahan": "Bandar Baru",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Babussalam",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Bale Atu",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Bukit",
                "kelurahan": "Bale Redelong",
                "kodepos": "24581"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Wih Porak",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Uning Mas",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Umah Besi",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Ulu Naron",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Tunyang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Timang Rasa",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Timang Gajah",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Sumber Jaya",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Taman Firdaus",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Suka Damai",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Singah Mulo",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Singah Mulo",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Simpang Layang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Simpang Lancang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Setie",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Rimba Raya",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Rerongga (Reronga)",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Rembune",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Rata Ara",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Perdamaian",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Pulo Intan",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Pante Karya",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Pantan Sinaku",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Pantan Pediangen",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Pantan Lues",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Pantan Lah",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Negeri Antara",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Pancar Jelobok",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Pantan Kemuning",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Musaba",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Musara Pakat",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Meriah Jaya",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Mude Benara",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Linung Bale",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Mekar Ayu",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Lampahan Timur",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Lampahan Barat",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Kolam Para Kanis",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Lampahan",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Karang Jadi",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Kenine",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Gunung Tunyang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Kampung Baru",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Gemasih",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Gegur Sepakat",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Gayo Setie",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Gajah Putih",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Datu Beru Tunyang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Fajar Harapan",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Cekal Baru",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Damaran Baru",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Bukit Tunyang",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Bumi ayu",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Blang Rongka",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Bukit Mulie",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Blang Ara",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Blang Rakal",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Bener Meriah",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Bintang Berangun",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Timang Gajah",
                "kelurahan": "Bandar Lampahan",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Arul Cincin",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Pintu Rime Gayo",
                "kelurahan": "Arul Gading",
                "kodepos": "24553"
            },
            {
                "kecamatan": "Gajah Putih",
                "kelurahan": "Alam Jaya",
                "kodepos": "24553"
            }
        ],
        "k244": [
            {
                "kecamatan": "Bintang",
                "kelurahan": "Wakil Jalil",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Wihlah Setie",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Sintep",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Serule",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Merodot",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Mude Nosar",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Mengaya",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Linung Bulen II",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kuala II",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Linung Bulen I",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kelitu Sintep",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kuala I Bintang",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kejurun Syiah Utama",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kala Bintang",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Kala Segi Bintang",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Genuren",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Jamur Konyel",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Gegarang",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Gele Pulo",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Dedamar",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Bamil Nosar",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Bewang",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Bale Nosar",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Bintang",
                "kelurahan": "Atu Payung",
                "kodepos": "24571"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Wih Nongkal",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Wih Nongkal Tua",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Tawar Miko",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Tawardi",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Timang Rasa",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Tapak Moge Timur",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Ratawali",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Segene Balik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Tapak Moge",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Pantan Sile",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Pantan Jerik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Lukub Sabun Barat",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Lukub Sabun Tengah",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Lukub Sabun Timur",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Kute Panang",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Lukub Sabun",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Empu Balik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Kala Nongkal",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Buter Balik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Dedingin",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Balik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Blang Balik",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Bukit Rata",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Umang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Kute Panang",
                "kelurahan": "Atu Gogop",
                "kodepos": "24568"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Telege Sari",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Tanoh Abu",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Simpang III Uning",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Reje Payung",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Paya Dedep",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Paya Tungel/Tunggal",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Penarun",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Pantan Nangka",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Pantan Reduk",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Owaq",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Pantan Damar",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Merah Said",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Mungkur",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Merah Mege",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Merah Muyang/Mayang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Merah Pupuk",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Merah Jernang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Lumut",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Linge",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Riyem (Meriem)",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Robel",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Reje",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Keramil",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Rayang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kute Baru",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Kepala Akal",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Jamat",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Kemerleng (Air Asin Kemerleng)",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Jeget Ayu",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Jagong Jeget",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Gewat",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Ise Ise",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Gelampang Gading",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Gemboyah",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Gayo Murni",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Dispot Linge",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Gegarang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Delung Sekinel",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Bukit Sari",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Damar Mulia",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Bukit Kemuning",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Bintang Kekelip",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Jagong Jeget",
                "kelurahan": "Berawang Dewal",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Atu Lintang",
                "kelurahan": "Atu Lintang",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Arul Item",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Linge",
                "kelurahan": "Antara",
                "kodepos": "24563"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wihni Durin",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wih Sagi Indah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wihni Bakong",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wih Bersih",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wih Pesam",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Wih Porak",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Uning Berawang Ramung",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Tirmi Ara",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Tenebuk Kampung Baru",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Terang Engon",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Simpang Kemili",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Tanoh Depet",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Tanjung",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Serempah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Simpang Juli",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Semelit Mutiara",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Sepakat",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Selon",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Rutih",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Sanehen",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Rusip",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Reremal",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Remesen",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Rebe Gedung",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Rejewali",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Pondok Balik",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Ramung Ara",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Pilar Wih Kiri",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Pilar",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Pilar Jaya",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Paya Tampu/Tumpu",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Pepayungen Angkup",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Paya Kolak",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Paya Pelu",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Pantan Reduk",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Pantan Tengah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Paya Beke",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Pantan Penyo",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Meurandeh/Merandeh Paya",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Pantan Bener",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Mulie Jadi",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Mekar Maju",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Melala",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Makmur",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Mekar Indah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Lut Jaya",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Kuyun Toa",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Kuyun Uken",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Kuyun",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Kute Gelime",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Kuala Rawa",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Kekuyang",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Kerawang",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Kala Ketol",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Karang Ampar",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Jerata",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Jaluk",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Jerata",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Gunung Singit",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Jalan Tengah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Genting Gerbang",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Genting Bulen",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Depet Indah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Gelumpang/Glumpang Payung",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Celala",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Cibro",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Buter",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Cang Duri",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Burni Bius",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Burni Bius Baru",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Burlah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Buge Ara",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Blang Mancung",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Blang Mancung Bawah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Blang Delem",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Blang Kekumur",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Bius Utama",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Bintang Pepara",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Berawang Gading",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Bergang",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Ketol",
                "kelurahan": "Bah",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Atu Singkih",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Putih",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Relem",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Arul Pertik",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Rusip Antara",
                "kelurahan": "Arul Pertik",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Kumer Barat",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Kumer Selatan",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Kumer",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Silih Nara",
                "kelurahan": "Arul Gele",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Celala",
                "kelurahan": "Arul Gading",
                "kodepos": "24562"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Wih Terjun",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Wih Nareh",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Wih Lah",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Uring",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Wih Ilang",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Uning Pegantungen",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Uning",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Terang Ulen",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Ujung Gele",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Tebuk",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Tebes Lues",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Suka Damai",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Simpang Lukub Badak",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Simpang Uning Niken",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Simpang Kelaping",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Pucuk Deku",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Pepalang",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Pegasing",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Pedekok",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Paya Jeget",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Pantan Musara",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Panangan Mata",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Linung Ayu",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Lenga",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Lelumu",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Kute Lintang",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Kung",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Kedelah",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Kayu Kul",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Karang Bayur",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Kala Pegasing",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Jejem",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Jurusen",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Ie Relop",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Gele Lungi",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Blang Bebangka",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Bies Penentanan",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Bies Baru",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Bies Mulie",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Berawang Baro",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Atang Jungket",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bies",
                "kelurahan": "Arul Latong",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Pegasing",
                "kelurahan": "Arul Badak",
                "kodepos": "24561"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Umang",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Tensaren",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Ulu Nuwih/Nuih",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Simpang Empat",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Tan Saril",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Pendere Saril",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Sadong Juru Mudi",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Mongal",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Nunang Antara",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Lemah Burbana",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Mah Bengi",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Keramat Mupakat",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Lelabu",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Kebet",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Kemili",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Kala Kemili",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Gele Lah",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Calo Blang Gele",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Daling",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Empus Talu",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Blang Kolak II",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Bur Biah",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Blang Gele",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Blang Kolak I",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Bahgie",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Bebesen",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Atu Tulu",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Timangan Gading",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Bebesen",
                "kelurahan": "Atu Gajah Reje Guru",
                "kodepos": "24552"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Telege Atu",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Pinangan",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Paya Tumpi Baru",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Paya Tumpi I",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Paya Reje Tamidelem",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Paya Tumpi",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Kelupak Mata",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Kutelot",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Mendale",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Jongok Bathin",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Kala Lengkio",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Gunung Balohen",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Gunung Bukit",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Gunung Bahgie",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Bukit Iwih Tami Delem",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Bukit Sama",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Bukit",
                "kodepos": "24519"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Jongok Meluem",
                "kodepos": "24518"
            },
            {
                "kecamatan": "Kebayakan",
                "kelurahan": "Lot Kala",
                "kodepos": "24517"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Toweren Uken",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Waq Toweren",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Rawe",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Toweren Antara",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Toweren Toa",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Merah Mersa",
                "kodepos": "24516"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Pedemun One-One",
                "kodepos": "24515"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Teluk One-One",
                "kodepos": "24515"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Takengon Barat",
                "kodepos": "24514"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Kenawat",
                "kodepos": "24515"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Kute Nireje (Kuteni Raja)",
                "kodepos": "24512"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Asir-Asir",
                "kodepos": "24513"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Hakim Bale Bujang",
                "kodepos": "24513"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Bujang",
                "kodepos": "24512"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Gunung Suku",
                "kodepos": "24512"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Bale Atu",
                "kodepos": "24512"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Asir-Asir Asia",
                "kodepos": "24512"
            },
            {
                "kecamatan": "Lut Tawar",
                "kelurahan": "Takengon Timur",
                "kodepos": "24511"
            }
        ],
        "k245": [
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Sunting",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Wono Sari",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Rongoh",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Serba",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Perkebunan Pulau Tiga",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Perupuk",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Rantau Bintang",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Pengidam",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Perkebunan Alur Jambu",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Kaloy",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Jambo/Jambu Rambong",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Pantai Cempa",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Blang Kandis",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Harum Sari",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Batu Bedulang",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Bengkelang",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Bandar Khalifah",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Bandar Setia",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Batang Ara",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Aras Sembilan",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Babo",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Alur Tani II",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tamiang Hulu",
                "kelurahan": "Alur Tani I",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Tenggulun",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Tenggulun",
                "kelurahan": "Tenggulun",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Bandar Pusaka",
                "kelurahan": "Alur Jambu",
                "kodepos": "24478"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Tanjung Genteng",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Tanjung Mancang",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Suka Makmur",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Sungai Liput",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Tenggulun",
                "kelurahan": "Simpang Kiri",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Sidodadi",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Simpang Kanan",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Tenggulun",
                "kelurahan": "Selamat",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Seumadam",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Purwodadi",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Tenggulun",
                "kelurahan": "Rimba Sawang",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Kebun Sungai Liput",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Pangkalan",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Kampung Jawa",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Karangjadi",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Gerenggam",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Alur Selebu",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Kejuruan Muda",
                "kelurahan": "Bukit Rata",
                "kodepos": "24477"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Tanjung Gelumpang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Tanjung Karang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Tanjung Seumantoh",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Sulum",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Tanah Terban",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Sukajadi Paya Bujuk",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Suka Makmur",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Sekerak Kiri",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Sekumur",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Simpang Empat",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Sekerak Kanan",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Rantau Panjang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Pematang Durian",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Paya Tampah",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Payameta",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Paya Awe",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Pantai Tinjau",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Menang Gini",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Paya Kulbi",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Pahlawan",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Pantai Perlak",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Medang Ara",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Lubuk Sidup",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Kesehatan",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Kebun Tanah Terban",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Kebun Tanjung Seumantoh",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Kebun Medang Ara",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Kebun Batang Ara",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Kebun Afdeling Seleleh",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Juar",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Gampong Sukajadi",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Gampong Tupah",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Gampong Air Tenang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Gampong Bundar",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Gampong Johar",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Buket Panjang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Dalam",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Bandar Mahligai",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Bandar Mahligai",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Banai",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Sekerak",
                "kelurahan": "Baling Karang",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Alur Selalas",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Alur Bemban",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Alur Lhok",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Kota Kuala Simpang",
                "kelurahan": "Sriwijaya",
                "kodepos": "24475"
            },
            {
                "kecamatan": "Karang Baru",
                "kelurahan": "Alur Baung",
                "kodepos": "24476"
            },
            {
                "kecamatan": "Kota Kuala Simpang",
                "kelurahan": "Kota Kuala Simpang",
                "kodepos": "24475"
            },
            {
                "kecamatan": "Kota Kuala Simpang",
                "kelurahan": "Kota Lintang",
                "kodepos": "24475"
            },
            {
                "kecamatan": "Kota Kuala Simpang",
                "kelurahan": "Perdamaian",
                "kodepos": "24475"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Sukajadi",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Kota Kuala Simpang",
                "kelurahan": "Bukit Tempurung",
                "kodepos": "24475"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Suka Rahmat",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Suka Rakyat",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Suka Mulia",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Paya Bedi",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Rantau Pauh",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Landuh",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Kampung Durian",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Kebun Rantau",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Jamur Jelatang",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Jamur Labu",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Kamp Pertamina",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Benua Raja",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Ingin Jaya",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Alur Manis",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Rantau",
                "kelurahan": "Alur Cucur",
                "kodepos": "24474"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sukaramai II",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Tangsi Lama",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Tualang",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sidodadi",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sukaramai I",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sei Kuruk I",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sei Kuruk II",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Sei Kuruk III",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Perkebunan Seruway",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Peukan Seuruway",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Pantai Balai",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Paya Udang",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Perkebunan Gedung Biara",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Muka Sei Kuruk",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Padang Langgis",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Lubuk Damar",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Matang Sentang",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Kw Pusung Kapal",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Gelung",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Kampung Baru",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Binjai",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Gedung Biara",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Air Masin",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Seruway",
                "kelurahan": "Alur Alim",
                "kodepos": "24473"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tengku Tinggi",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tumpok Teungoh",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Upah",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Teluk Kemiri",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Teluk Kepayang",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Telaga Meuku Dua (Muku II)",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Telaga Meuku Satu (Muku I)",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Teluk Halban",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Tanjung Keramat",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong Parit",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong Lipat II",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong Mulia",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong Binjei",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong Lipat I",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Tanjong",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Sukajadi",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Suka Mulia Bendahara",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Suka Damai",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Suka Mulia/Mulya (Upah Hulu)",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Seuneubok Dalam Mesjid",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Seuneubok Dalam Upah",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Raja",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Rantau Pakam",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Perkebunan Sungai Iyu",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Perkebunan Upah",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Paya Rahat",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Mesjid Bendahara",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Mesjid Sungai Iyu",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Matang Seping/Seuping",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Matang Teupah",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Lambung Blang",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Lubok Bathil",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Marlempang",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Cinta Raja",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Kuala Genting",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Kuala Penaga",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Besar",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Bandar Khalifah",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Bandar Baru",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Balai",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Banda Mulia",
                "kelurahan": "Alur Nunang (Munang)",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Ujong Tanjong",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Bendahara",
                "kelurahan": "Alue Cantek",
                "kodepos": "24472"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Simpang Lhee",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Tanjung Neraca",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Tualang Baru",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Seuneubok Cantek",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Seuneubok Pidie",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Seuneubok Punti",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Sampaimah",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Sapta Marga",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Seuneubok Baru",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Paya Ketenggar",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Raja Tuha",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Pandan Sari",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Paya Baru",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Meunasah Paya",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Meurandeh",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Pahlawan",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Matang Ara Jawa",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Matang Cincin",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Mesjid",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Lueng Manyo",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Matang Ara Aceh",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Ie Bintah",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Kasih Sayang",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Krueng Sikajang",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Lhok Medang Ara",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Geudham",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Gelanggang Merak",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Buket Paya",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Bukit Panjang I",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Dagang Setia",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Bandung Jaya",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Benteng Anyer",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Buket Panjang II",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Alue Ie Puteh",
                "kodepos": "24471"
            },
            {
                "kecamatan": "Manyak Payed",
                "kelurahan": "Alue Sentang",
                "kodepos": "24471"
            }
        ],
        "k246": [
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Umah Taring",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Ujung Karang",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Tualang",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Peunaron",
                "kelurahan": "Srimulya",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Sunti",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Terujak",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Sembuang",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Seuleumak",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Rampah",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Sekualan",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Peunaron",
                "kelurahan": "Peunaron Baru",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Peunaron",
                "kelurahan": "Peunaron Lama",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Nalon",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Loot",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Mesir",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Lokop",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Jering",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Leles",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Bunin",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Peunaron",
                "kelurahan": "Bukit Tiga",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Peunaron",
                "kelurahan": "Arul Pinang",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Arul Durin",
                "kodepos": "24461"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Uram Jalan",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Ulee Jalan",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Ulee Ateung",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Teupin Mamplam",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Titi Barueh/Baroeh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Teupin Breuh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Tanjung Minjei",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Tanjung Ara",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Tampor/Tampur Paloh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Tampor/Tampur Boor",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Sungai Simpang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Suka Damai",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Seuneubok Tuha",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Simpang Jernih",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Seuneubok Saboh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Seuneubok Simpang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Seuneubok Pidie",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Seuneubok Pase",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Seuneubok Pangou",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Seuneubok Kandang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Seuneubok Benteng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Seuneubok Bayu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Rantau Panjang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Rambong Lop",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Putoh Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Putoh Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Pucok Alue Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Pucok Alue Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Peulalu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Pucok Alue Barat",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Paya Naden",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Paya Laman",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Paya Keutapang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Paya Deumam/Demam Peut",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Paya Demam/Deumam Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Paya Demam/Deumam Lhee",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Paya Demam/Deumam Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Panton Rayeuk M",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Panton Rayeuk T",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Pante Rambong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Panton Rayeuk A",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Panton Rayeuk B",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Pante Labu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Pante Panah",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Pante Merbo (Meureubo)",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Pante Kera",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Nicah Awe",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Pante Bayam",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Meunasah Tunong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Meunasah Tingkeum",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Meunasah Leubok",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Matang Weng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Meunasah Asan",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Melidi",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Matang Pudeng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Matang Rayeuk",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Matang Seupeng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Matang Perlak (Peureulak)",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Nibong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Kupula/Keupula Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Kupula/Keupula Lhee",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Kupula/Keupula Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Matang Kumbang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Matang Kruet",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Madat",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Guru",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Matang Jrok",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Lueng Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Lueng Peut",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Lueng Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Labuhan Keude",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Kuala Simpang Ulim",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Lampuh Rayeuk",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Krueng Lingka",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Kuala Parek",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Keude Tuha",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Keude Baro",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Jalan Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Jambo Reuhat",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Grong Grong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Hti Ranto Naro",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Geulumpang Payong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Gampong Blang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Gajah Mentah",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Gampong Baro",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Gampong Baro",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Bukit Drien",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Buket Kareng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Buket Drien",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Buket Bata",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Blang Seunong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Blang Ubit",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Banda Alam",
                "kelurahan": "Blang Rambong",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Blang Nie/Me",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Blang Andam",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Blang Awe",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Bintah",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Jernih",
                "kelurahan": "Batu Sumbang",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Ara Kundo",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Bantayan",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Alue Rangan",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Alue Mulieng",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Sungai Raya",
                "kelurahan": "Alue Itam",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Alue Buloh Satu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Pante Bidari (Beudari)",
                "kelurahan": "Alue Ie Mirah",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Madat",
                "kelurahan": "Abeuk Geulanteu",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Simpang Ulim",
                "kelurahan": "Alue Buloh Dua",
                "kodepos": "24458"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Ulee Blang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Ulee Tanoh",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Tumpok Teungoh",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Ujong Tunong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Ulee Ateung",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Tanjong Tok Blang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Teupin Raya",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Suka Makmur",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Simpang Lhee",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Seuneubok Bayu",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Seuneubok Cina",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Seuneubok Rambong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Seumatang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Perkebunan Julok Rayeuk Utara",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Perkebunan Julok Rayeuk Selatan",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Pelita Sagop Jaya",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Paya Bakong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Paya Pasi",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Matang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Naleung",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Lhok Rambong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Lhok Seuntang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Mane Rampak",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Labuhan (Labohan)",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Ladang Baro",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Keumuneng",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Kuala Geulumpang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Jambo Lubok",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Julok Tunong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Keude Kuta Binjei",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Jambo Balee",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Gampong Baro",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Bukit Dindeng",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Bukit Makmur",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Buket Seuraja (Seraja)",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Uyok",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Buket Panyang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Pauh/Paoh Dua",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Pauh/Paoh Satu",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Mideun",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Blang Nisam",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Jambee",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Keumuhang",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Blang Gleum",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Alue Patong",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Bandar Baro",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Alue Ie Itam",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Indra Makmur",
                "kelurahan": "Alue Ie Mirah",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Julok",
                "kelurahan": "Alue Cek Doi",
                "kodepos": "24457"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Tualang Dalam",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Ulee Glee",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Seuneubok Timur",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Teupin Pukat",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Seuneubok Kuyun",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Seuneubok Rambong",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Seuneubok Barat",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Seumatang Aron",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Seuleumak Muda",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Paya Enjee",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Peulawi",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Pulo U",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Meunasah Jeumpa",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Medang Ara",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Meunasah Hagu",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Matang Rayeuk (SMK)",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Matang Seuleumak",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Matang Reyeuk PP",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Matang Neuheun",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Matang Panyang",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Matang Kunyet",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Lhok Asahan",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Matang Bungong",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Kuala Bagok",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Idi Timur",
                "kelurahan": "Keutapang Dua",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Gampong Mesjid",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Keude Bagok Sa",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Gampong Keude Bagok Dua",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Gampong Lhee",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Buket Panyang",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Cot Asan",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Gampong Jalan",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Buket Meurak",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Buket Panjou",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Beurandang",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Blang Panjou",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Bantayan",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Baroh Bugeng",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Alue Siwah Serdang",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Nurussalam",
                "kelurahan": "Asan Tanjong",
                "kodepos": "24456"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Teupin Drum",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Trieng Gadeng",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Tuha I",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Tuha II",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Simpang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Seuneubok Kulam",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Seuneubok Lapang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Baroh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Buloh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Seuneubok Aceh Baro",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Peukan Idi Cut (Keude Idi Cut)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Pulo Blang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Panton Merbo (Meureubo)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Meunasah Keutapang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Meudang Ara (Medang Ara)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Meunasah Aroen (Aron)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Matang Geutou",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Matang Pineung",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Lhok Meureu",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Lhok Panjou/Panjau",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Lhok Panjou/Panjau",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kuala Idi Cut",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Lhok Geulumpang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Lhok Dalam",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Keude Dua",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kemuning V",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kemuning II",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kemuning III",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kemuning IV",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kapai Baro",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Kemuning I",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Grong-Grong",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Jungka Gajah",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Gunong Putoh",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Gampong Keude (Kampung Keude)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Gaseh Sayang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Dama Pulo I",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Gampong Baro )Kampung Baro)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Gampong Beunot (Kampung Beunot)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Buket Tualang",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Dama Polo II",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Buket Raya",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Buket Rumia/Rumiya",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Blang Buket",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Buket Kulam",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Buket Peulawi",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Bagok Panah III",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Bagok Panah IV",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Bagok Panah II",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Bagok Panah I",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alur Ludin I (Satu",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alur Ludin II (Dua)",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alur Merbo",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Iksan (Ihsan)",
                "kelurahan": "Alue Jangat",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alur Lhok",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alue Dalam",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Darul Aman",
                "kelurahan": "Alue Gadeng",
                "kodepos": "24455"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Ulee Blang",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Tunong Paya Kruep",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Tunong Ulee Gajah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Titi Baro",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Tunong Bugeng",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Tanjong Kapai",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Tanoh Anoe",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Teupin Batee",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Seuneubok Tuha",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Seuneubok Tutong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Seuneubok Teungoh Peudawa Puntong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Teupin Panah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Seuneubok Rambong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Punti",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Seuneubok Peunteut",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Seuneubok Panton",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Drien",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Jalan",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Meureudu (Meurudu)",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Buya",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Sampoimah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Seuneubok Bacee",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Sama Dua",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Paya Gaboh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Paya Kruep",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Paya Bili Dua",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Paya Bili I",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Paya Dua",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Padang Kasah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Paya Awe / Awee",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Meunasah Pu&#8217;uk",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Kuta Blang",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Kuta Lawah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Matang Rayeuk",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Kuala Peudawa Puntong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Kuta Baro",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Keutapang Mameh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Kuala Idi",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Keude Keumuneng",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Keumuneng Lhok",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Keudondong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Keude Aceh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Keude Blang",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Keude Blang",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gureb Blang Gampong Jawa",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Gampong/Kampung Kuala",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Gampong Teupin Panah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Gampong/Kampung Keude",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Gampong Keumuneng (Keumuning)",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gampong Tanjong",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gampong Baro",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gampong Jalan",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gampong Jawa",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Gampong Aceh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Dama pulo",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Buket Teumpeun",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Buket Tufah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Cempeudak",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Buket Rumiya",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Buket Teukuh",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Buket Pala",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Buket Puuk",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Buket Kuta",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Buket Langa",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Buket Meulinteung",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Buket Jok",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Buket Juara",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Blang Minjei",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Blang Guci (Seguci)",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Blang Kuta",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Blang Geulumpang",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Blang Buket",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Bantayan Timu",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Bantayan Barat",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Darul Falah",
                "kelurahan": "Alue Siwah",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Asan Rampak",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Alue Kumbang M",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Alue Lhok",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Alue Ie Itam",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Alue Dua Muka S",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Tunong",
                "kelurahan": "Alue Kumbang A",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peudawa",
                "kelurahan": "Alue Batee",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Idi Rayeuk",
                "kelurahan": "Alue Dua Muka O",
                "kodepos": "24454"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Tualang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Uteun Dama",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Tualang Pateng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Teumpeun",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Tanoh Rata",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Tampak",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Tanjung Tualang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Teupin",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Seuneubok Pidie",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Punti",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Rawang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Seuneubok Peusangan",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Paya",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Lapang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seuneubok Johan",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Jalan",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seuneubok Jaya",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Seumatang Muda Itam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seulemak Muda",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Seumali (Seumaly)",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Seumatang Keude",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Pulo Blang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Punti Payong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Punti",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Paya Unou",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Pertamina",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Paya Palas",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Paya Seungat",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Paya Meuligou",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Paya Kalui",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Paya Lipah",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Paya Biek",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Paya Gajah",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Pasir Putih",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Mon Geudong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Pasir Putih",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Matang Pelawi",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Matang Gleum",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Mata Ie",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Lubok Pempeng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Lhok Dalam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Kuala Leuge",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Leuge",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Kuala Bugak",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Kliet",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Krut Lintang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Keumuneng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Keude Peureulak",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Kabu",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Kebun Teumpeun",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Jeungki",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Geulanggang Merak",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Dama Tutong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Cot Muda Itam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Cot Keh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Cot Geulumpang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Cek Mbon",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Bukit Meuriam",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Buket Pala Bsp",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Blang Simpo",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Buket Pala",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Blang Bitra",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Blang Batee",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Blang Barom",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Blang Balok",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Bhom Lama",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Beusa Seberang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Beusa Meuranoe",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Beusa Baroh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Beuringin",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Beurandang",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Bangka Rimung",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Bandrong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Balee Buya",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Babah Krueng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Alue Udep",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Alue Tho",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Alue Rambong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Alue Nibong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Alue Gurep",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Alue Geunteng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak",
                "kelurahan": "Alue Dua Paya Gajah",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Alue Dua",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Alue Bugeng",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Alue Bu Tuha",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Alue Bu Tunong",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Alue Bu Jalan",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Barat",
                "kelurahan": "Alue Bu Jalan Baroh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Ranto Peureulak",
                "kelurahan": "Alue Batee",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Alue Bu Alue Lhok",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Simpang Peut",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Peureulak Timur",
                "kelurahan": "Alue Bu Alue Nireh",
                "kodepos": "24453"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Simpang Aneuh",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Sarah Teubee",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Sarah Kayee",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Rantau Panjang",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Paya Tampah",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Peutow",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Paya Peulawi",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Paya Rambong",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Merbau II (Merbou Dua)",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Paya Bili I (Satu)",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Paya Bili II (Dua)",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Keude Birem",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Jambo Labu",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Kemuning/Keumuneng Hulu",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Buket Seuleumak",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Buket Tiga",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Damar Siput",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Birem Rayeuk",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Blang Tualang",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Benteng",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Bayeun (Gampong Bayeun)",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Aramiyah",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Bayeun",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Teh",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Tuwi",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Seuleumak",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Sentang",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Punti",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Raya",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Nyamuk",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Kumba",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Gadeng Satu",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Rantau Selamat",
                "kelurahan": "Alue Kaul/Kaol",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Gadeng Dua",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Gadeng",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Drien",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Canang",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Alue Buloh/Baloh",
                "kodepos": "24452"
            },
            {
                "kecamatan": "Birem Bayeun",
                "kelurahan": "Afdeling II Bukit/Buket",
                "kodepos": "24452"
            }
        ],
        "k247": [
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Seulalah Baru",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Simpang Lhee",
                "kodepos": "24451"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Seulalah",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Pondok Keumuning",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Pondok Pabrik",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Meurandeh Teungoh",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Meurandeh Aceh",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Meurandeh Dayah",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Meurandeh",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Gampong Mutia",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Gampong Daulat",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Gampong Jawa",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Gampong Baro",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Gampong Blang",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Baroh Langsa Lama",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Asam Peutek",
                "kodepos": "24416"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Suka Jadi Makmur",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Timbang Langsa",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Pondok Kelapa",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Seuriget",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Paya Bujuk Seuleumak",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Paya Bujuk Tunong",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Paya Bujok Teungoh",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Lengkong",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Lhok Banie",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Geudubang Jawa",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Karang Anyar",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Birem Puntong",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Gedubang Aceh",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Alue Dua Bakaran Bate",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Tualang Teungoh",
                "kodepos": "24414"
            },
            {
                "kecamatan": "Langsa Baro",
                "kelurahan": "Alue Dua",
                "kodepos": "24415"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Paya Bujok Beuramo (Beuramoe)",
                "kodepos": "24414"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Paya Bujok Blang Pase",
                "kodepos": "24414"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Matang Seulimeng",
                "kodepos": "24413"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Peukan Langsa",
                "kodepos": "24412"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Blang Sinibong/Seunibong",
                "kodepos": "24412"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Blang Sinibong/Seunibong",
                "kodepos": "24412"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Suka JD KBN Ireng",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Sukarejo",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Sidorejo",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Simpang Wie",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Sidodadi",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Seuneubok Antara",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Matang Panyang",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Matang Seutui",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Kapa",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Matang Ceungai",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Cinta Raja",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Buket Pulo",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Buket Rata",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Buket Meudang Ara",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Buket Meutuah",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Lama",
                "kelurahan": "Bate Puteh",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Alue Pineung",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Alue Pineung Timue",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Timur",
                "kelurahan": "Alue Merbau",
                "kodepos": "24411"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Telaga Tujuh",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Sungai Paus Tanjong",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Sungai Pauh",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Sungai Paus Firdaus",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Sungai Paus Pusaka",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Serambi Indah",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Barat",
                "kelurahan": "Kuala Langsa",
                "kodepos": "24410"
            },
            {
                "kecamatan": "Langsa Kota",
                "kelurahan": "Alue Berawe",
                "kodepos": "24410"
            }
        ],
        "k248": [
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Teupin Bayu",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Teupin Gajah",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Ulee Gle",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjung Ara",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjung Punti",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjong Menuang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjong Meunye",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjong Dalam Utara",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Tanjong Jawa",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Tanjong Dalam Selatan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjong Ceungai",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Seureuke",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Tanjong Ceungai",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Seuneubok Dhou",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Seuneubok Pidie",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Rumoh Rayeuk",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Samakurok",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Pucok Alue",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Rantau Panyang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Rawang Itik",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Pante Gaki Bale",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Paya Tukai",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Padang Meuria",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Meunasah Panton Labu",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Meunasah Geudong",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Meunasah Merbo",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Seuke Pulot",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Teungoh Teungoh",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Matang Teungoh Selatan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Santot",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Serdang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Matang Rubek",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Maneh",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Raya",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Matang Keutapang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Jurong",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Drien",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Matang Arongan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Lhok Reudeup",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Lueng Tuha",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Lubok Pusaka",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Lhok Bintang Hu",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Lhok Merbo",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Lhok Beuringen",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Leubok Mane",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Langkahan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Kota Panton Labu",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Krueng Lingka",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Kampung Blang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Geulumpang Umpung Unou",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Geudumbak",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Cot Biek",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Ceumpeudak",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Cot Bada",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Bukit Padang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Buket Linteung",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Buket Batee Badan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Buket Jeurat Manyang",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Biram Rayeuk",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Buket Alue Puteh",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Biara Timur",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Biram Cut",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Bantayan",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Alue Papeun",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Biara Barat",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Alue Krak Kayee",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Tanah Jambo Aye",
                "kelurahan": "Alue Ie Mirah",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Langkahan",
                "kelurahan": "Alue Dua",
                "kodepos": "24394"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Ulee Rubek Timur",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Ulee Titi",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Ulee Rubek Barat",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Teupin Kuyun",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Ulee Matang",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Tanjong Dama",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Tanjong Pineung",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Simpang Peut",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Paya Dua Ujong",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Paya Dua Uram",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Meureubo/Meurebo Puntong",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Meunasah Sagou/Sagoe",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Panyang",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Puntong",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Karieng",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Lada",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Anou",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Matang Jeulikat",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Lhok Puuk",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Lhok Rambideng",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Mane Kawan",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Darul Aman",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Keude Simpang Jalan",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Lhok Geulituet (Geuletuet)",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Cot Patisah",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Cot Trueng",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Blang Tue",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Cot Kafiraton (Kapiraton)",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Bantayan",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Blang Pha",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Alue Campli",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Alue Kiran",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Seunuddon (Seunudon)",
                "kelurahan": "Alue Baroh",
                "kodepos": "24393"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Tanjong Glumpang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Ujong Dama",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Singgah Mata",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Pulo Seuke",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Rambong Dalam",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Pucok Alue Buket",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Pucok Alue",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "MPG Raya Blang Sialet",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Paya Bateung",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Mnsh Alue I Puteh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Mon Sukon",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Meurandeh Raya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Meunasah Hagu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Meunasah Pante",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Meunasah Bujok",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Meunasah Geudong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Ulim",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Meudang Ara",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Sijuek Teungoh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Sijuek Timu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Teungoh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Sijuek Barat",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Reudep",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Raya Timu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Pineung",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Rawa",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Raya Barat",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Panyang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Paya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Lawang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Linya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Manyam",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Kelayu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Kumbang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Cut",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Kareung",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Ceubrek",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Baro",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Matang Beringin",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Matang Bayu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Lhok Seutuy",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Lueng Bata",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Lhok Euncien",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Lhok Iboh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Krueng Lingka Timu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Keude Sampoiniet",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Krueng Lingka Barat",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Keude Alue Ie Puteh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Keude Menyee IV",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Keude Panteu Brueh",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Glumpang payong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Glumpang Samlakoe",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Cot Usen",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Geulumpang Bungkok",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cot Ulaya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Cot Murong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Cot Paya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cot Mane",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cot Manyang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Cot Kupok",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Cot Laba",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cinta Makmur",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cot Ara",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Cot Kumbang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Blang Seunong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Buket Dara Baro",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Ceumpeudak",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Blang Nibong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya Barat",
                "kelurahan": "Blang Rheu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Serdang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Arongan Lise",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Babussalam",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Keutapang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Rambong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Ie Tarek",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Jambok",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Buya",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Dama",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Geudong",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Bili Geulumpang",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Bili Rayeuk",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Anou Barat",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Baktiya",
                "kelurahan": "Alue Anou Timu",
                "kodepos": "24392"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Ulee Tanoh",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Tanjung/Tanjong Dama",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Teupin Gapeuh",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Merbo Lama",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Prie (Meunasah Prey)",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Merbo Jurong",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Merbo Jurong",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Matang Ranub Laseh",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Matang Tunong",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Matang Janeng",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Lueng Baro",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Matang Baroh",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Kuala Kereutou",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Kumbang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Kuala Cangkoi/Cangkoy",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Keutapang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Kuala Keretou Barat",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Keureutoau",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Keude Matang Panyang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Keude Lapang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Keude Jrat Manyang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Lapang",
                "kelurahan": "Geulanggang Baro",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Gampong Pande",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Gampong Cibrek (Meunasah Cibrek)",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Gampong Me Merbo",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Gampong Paloh",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Blang",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Cangguek (Meunasah Cangguek)",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Ulee Blang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Tanah Pasir",
                "kelurahan": "Alue (Meunasah Alue)",
                "kodepos": "24391"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tutong",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Ude",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Ujong Kulam",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Tunong Krueng",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tumpok Perlak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Tumpok Mesjid",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tumpok Barat",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Trieng Krueng Kreh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tj. Teungku Kari",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Trieng Teupin Keubue",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Teupin Keubue",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Teupin U",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Teungoh Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Teungoh Glumpang VII",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Teungoh Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Teungku Dibanda Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Teungku Dibanda Tektek",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tanjong Teungku Ali",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Tanjong Seurikui",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Tanjong Drien",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tanjong Haji Muda",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Tanjong Beurunyong",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Tanjong Babah Krueng",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Siren",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Simpang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Seuriweuk",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Serdang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Rayeuk Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Reungkam (Rengkam)",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Rayeuk Pange",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Rayeuk Matangkuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Punti Matangkuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Punti Matang Kuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Punti Glumpang VII",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Pucok Alue Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Pucok Alue Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Peureupok",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Paya Meudru",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Paya Lueng Jalo",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Parang Sikureung",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Pantee Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Pante Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Munye Tujuh (Meunye Tujoh)",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Munye Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Meuriya Matangkuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Meuria Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Meunye Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Mesjid Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Mee",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Matang Peusangan",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Matang Panyang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Matang Menye",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Matang Me",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Matang Keh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Lueng",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Leupe",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Leuhong",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Leubok",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Lawang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Kunyet Mule",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Krueng Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Keutapang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Keude Paya Bakong",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Keude Matangkuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Jok",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Keubon Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Jeumpa Glumpang VII",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Hagu",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Glumpang/Geulumpang Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Glumpang VII",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Glumpang (Geulumpang)",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Geureughek",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Seuneubok Aceh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Blang Ara",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Mampree",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Nga",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Alue Lhok",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Ampeh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Cot Tufah",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Gampong Alue Leukot",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Dayah Baro",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Ceumeucet",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Ceubreuk Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Bungong",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Cempeudak (Ceumpeudak)",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Buket Guru",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Buket Pidie",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Sialet",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Blang Supeng",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Paku",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Pante",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Blang Matangkuli",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Mane",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Blang Kuta",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Dalam",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Blang Gunci",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Bili Baro",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Beurancan Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Beuringin",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Baro",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Beuracan Rata",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Asan Seuleumak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Aron Pirak",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Asan Krueng Kreh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Aron Glumpang VII",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Ara Tonton Moncrang",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Alue Tho",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Alue Rimei",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Matangkuli",
                "kelurahan": "Alue Entok",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Paya Bakong",
                "kelurahan": "Alue Bieng",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Pirak Timur",
                "kelurahan": "Alue Bungkoh",
                "kodepos": "24386"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Ulee Buket",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Wakeuh (Nibong Wakheuh)",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Ujong Baroh B.",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Ujong Baroh Sb",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Tutong (Meunasah Tutong)",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Trieng",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Tumpok Aceh",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Tgk Dibale",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Teupin Jok",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Teungoh Pa",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Tanjong Putoh",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Tanjong Mesjid",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Sumbok Rayeuk",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Seulunyok",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Serba Jaman Baroh",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Serba Jaman Tunong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Rayeuk Meunye",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Rayeuk Naleung",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Rawa",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Rayeuk Kuta",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Rangkaya",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Ranto",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Punti Pa.",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Punti Sb.",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Pulo Blang",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Pulo Blang",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Plu Pakam",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Paya Terbang Teureubang)",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Paya Beurandang",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Nibong Baroh",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Pante B",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Menye Lhee",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Matang Mane",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Matang Baloy",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Matang Ben",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Matang Ceubrek (Cibrek)",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Manyang SB",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Manyang Tunong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Maddi",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Mamplam",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Leu Hong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Leupon Siren",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Keutapang",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Keupok Nibong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Keulilee",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Keude Nibong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Keh Nibong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Jeumpa B",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Keude Blang Jruen",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Hueng",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Teupin Me",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Hagu",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Serba Jaman",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Teungoh B",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Blang Trieng",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Deng",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Paya",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Gampong Alue",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Dayah Nibong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Ceubrek (Cibrek)",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Cot Barat",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Cot Dam/Dah",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Bukit Makarti",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Blang Pie",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Blang Bidok",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Blang Jruen",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Blang",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Bayi",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Alue Panah",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Alue Pangkat",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Alue Sijuek",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Alue Ngom",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Alue Ie Mirah",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Alue Gampong",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Tanah Luas",
                "kelurahan": "Alue Keujruen",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Nibong",
                "kelurahan": "Alue Buman",
                "kodepos": "24385"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Ulee Barat (Meunasah Ulee Barat)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Ulee Gunong",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Trieng Pantang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Tualang Tuha",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Teupin Keubeu",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Trieng Matang Ubi",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Rambot",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Rawa (Meunasah Rawa)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Pante",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Pulo Dulang (Meunasah Pulo Dolang)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meuye/Munye Matang Ubi",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Nga Lhoksukon Tengah",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Nga Matang Ubi",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Tuha",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Tutong",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Teungoh Lb",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Teungoh Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Meuria (Meuriya)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Nga Lhoksukon Barat",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Rayeuk Lb",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Leubok Ab",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Meurbo (Meureubo)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Geulinggang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Jok",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Buloh",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Cot Usibak",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Dayah Lt/Lb",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Asan Lhoksukon Barat",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Asan Lhoksukon Barat",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Arongan Ab",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Asan Ab",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Matang Teungoh Ab. (Meunasah Matang Teungoh)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meucat",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Meunasah Alue Drien Lb",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Mata Ie",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Mata U",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Matang Meunye",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Lhok Sentang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Mancang (Meunasah Mancang)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Manyang (Meunasah Manyang)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Krueng Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Kumbang Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Lhok Kareung",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Kota Lhoksukon",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Gampong Nga Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Keutapang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Geulumpang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Geumata (Meunasah Geumata)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Gampong Blang Aman",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Grong Grong",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Gampong Cot Ara",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Gampong Meunasah Ranto",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Gampong Matang Pupanji Ab",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Dayah Lb/Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Cot Asan",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Cot Geulumpang Ab",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Buket Sentang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Ceubrek (Meunasah Ceubrek)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Buket Krueng",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Buket Mee Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Bintang Hu",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Blang Rubek",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Buket Hagu",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Arongan Lt",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Babah Geudubang",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Beuringin Lb (Meunasah Beuringen)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Meudem (Mudem)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Ara Ab (Meunasah Ara)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Itam Baroh",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Itam Reudeup",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Buket",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Eumpok",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Abeuk Leupon (Leupen)",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Lhoksukon",
                "kelurahan": "Alue Abee",
                "kodepos": "24382"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Tanjong Mulieng",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Teungoh",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Tanjong Krueng Pase",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Pante",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Peureupok",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Pulo",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Meuriya/Meuria Aron",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Mon Crang",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Mesjid",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Meucat",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Manyang Baroh",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Matang Meunye",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Me Aron",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Kumbang",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Mampree",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Keude Aron",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Keude Teupin Punti",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Keutapang",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong U (Meunasah U)",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Hagu",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Kanot",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong Glok",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong Kulam",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong Blang",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong Ampeh",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Gampong Awe",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Dayah Meuriya/Meuria",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Dayah Teungku",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Cibrek Tunong",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Dayah Aron",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Ara (Meunasah Ara)",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Calong",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Cibrek Baroh",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Syamtalira Aron",
                "kelurahan": "Alue Gunto",
                "kodepos": "24381"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Tanjong Keumala",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Teupin Rusep/Reusep",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Ulee Geudong",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Riseh Teungoh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Riseh Tunong",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Rambong Payong",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Riseh Baroh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Paya Rabo Timur",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Punteuet (Punteut)",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Paya Rabo Lhok",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Pante Jaloh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Paya Gaboh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Kuyun",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Merbo (Meureubo)",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Meunasah Pulo",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Krek",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Jok",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Cut",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Gajah",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lancok",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Bayu",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Kuta Meuligoe",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lagang",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Jurong",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Krueng Baro",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Kubu",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Glee Dagang",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Gunci",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Cot Lambideng",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Gampong Sawang",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Blang Teurakan",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Cot Kumuneng/Keumuning",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Blang Cut",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Blang Manyak",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Blang Reuling",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Babah Buloh",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Babah Krueng",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Abeuk Reuling",
                "kodepos": "24377"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Ulee Nyeu",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Tingkeum",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Ulee Blang",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Paya Uleue",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Sangkelan",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Seuneubok",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Paya Beunyot",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Paya Dua",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Panton",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Paloh Mambu",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Paloh Mamprei/Mampree",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Meunasah Meucat",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Paloh Kayee Kunyet",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Jeulikat",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Meunasah Alue",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Meunasah Cut",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Jamuan",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Seumirah",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Gampong Keutapang",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Gampong Meunasah Rayeuk",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Gampong Peunayan",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Blang Pohroh",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Darussalam",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Blang Jrat",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Gampong Barat",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Alue Papeun",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Cot Me",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam Antara",
                "kelurahan": "Gampong Alue Dua",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Cot Leupee",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Cot Mambong",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Cot Jabet",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Blang Pala",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Blang Karieng",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Cot Entung",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Blang Dalam Baroh",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Blang Dalam Geunteng",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Blang Dalam Tunong",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Binjee",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Blang Crok",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Banda Baro",
                "kelurahan": "Alue Keureunyai",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Beunot (Meunasah Beunot)",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Nisam",
                "kelurahan": "Alue Bili",
                "kodepos": "24376"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Teupin Beulangan",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Ujong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Tanjung/Tanjong Mesjid",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Teupin Ara",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Tanjong Rengkam",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Tanjung/Tanjong Hagu",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Puuk",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Sawang",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Tanjong Kleng",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Pulo",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Pusong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Murong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Paya Terbang",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Meunasah Asan",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Matang Ulim",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Meucat",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Matang Puntong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Matang Tunong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Mancang",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Madan",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Laga Baro",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Lancang",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Kuta Krueng",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Krueng Mate",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Kuta Glumpang",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Krueng Baro Lgh",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Krueng Baro Blang Me",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Kitou",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Keude Geudong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Keude Blangmee Pulokat",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Tanjung Baroh",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Pie",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Tanjong Awe",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Mesjid",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Gampong Baro",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Blang Nibong",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Blang Peuria",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Blang Kabu",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Samudera",
                "kelurahan": "Beuringen",
                "kodepos": "24374"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Uram Jalan",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Ulee Gampong",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Ulee Meuria",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Tumpeun (Gampong Tumpeun)",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Trieng Meudurou",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Sukadamai",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Siren Tujoh",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Seunebok Baro",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Rheng",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Punti",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Rayeuk Jawa",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Pulo Meuria",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Pulo Blang Trieng",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Pulo Blang Mangat",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Peudari",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Nibong",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Meudang Ara",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Lhok Asan",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Langa (Meunasah Langa)",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Lancok",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Krueng Mbang",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Kayee Panyang",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Keude Bayu",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Dayah Tuha",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Garot",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Glong (Meunasah Glong)",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Dayah Seupeng",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Dayah Blang Seureukuy",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Darul Aman",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Darussalam",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Dayah Baro",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Cut Neuheun",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Cibrek",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Bungong",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Buket Glumpang",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Blang Seureukuy / Blang Serikuy",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Blang Patra",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Blang Majron",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Blang Awe",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Blang Bayu",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Beurandang",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Bie",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Baro Kulam Gajah",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Baroh Blang Rimueng",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Beunot (Meunasah Beunot)",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Alue Majron",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Bale",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Syamtalira Bayu",
                "kelurahan": "Alen",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Geuredong Pase",
                "kelurahan": "Alue Awe",
                "kodepos": "24373"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ulee Meuria",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ujung Kuta Batee",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ulee Ceubrek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ujong Reuba",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ujong Reuba",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ubit Paya Itek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Tumpok Teungku",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Teungoh Kuta Batee",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Teungoh Reuba",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Tualang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Saramaba",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Tanjong",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Rheng Bluek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Rheng Bluek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Rayeuk Paya Itek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Reudeup",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Rangkileh",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Ranto",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Rayeuk Matang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Pulo Kiton",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Pulo Drien Beukah",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Pulo Blang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Pulo Bluek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Paya Sutra",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Pri Keutapang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Nibong",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Nibong",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Paya Bili",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Meuria Bluek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Nga",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Meunye Peut",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Mesjid Bluek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Meunye Payong",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Manyang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Mee",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Mesjid",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Kumbang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Leubeuk Tuwe",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Keh",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Keude Jungka Gajah",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Keude Karieng",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Geulumpang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Cibrek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Drien Puntong",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Beuringen",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Blang Cut",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Bare Blang",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Baroh Kuta Batee",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Sido Mulyo",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Meurah Mulia",
                "kelurahan": "Barat Paya Itek",
                "kodepos": "24372"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Seuneubok Drien",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Pulo Rayeuk/Rayek",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Saweuk",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Pulo Iboh/Iboih",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Panton Rayeuk II",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Pulo Barat",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Mulieng/Muling Meucat",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Panton Rayeuk I",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meuria (Meuriya)",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Mulieng/Muling Manyang",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunasah Kulam",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunasah Kumbang",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunyecut Bahagia",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunasah Buket/Bukit",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunasah Dayah Meunara (Mon Ara)",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Lhok Jok",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Meunasah Blang Ara",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Krueng Seupeng",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Lang Kuta",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Krueng Manyang",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Krueng Seunong",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Keude Krueng",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Keureusek",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Guha Uleue",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Keude Blang Ara",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cot Seuminyong (Sumiyong)",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cot Seutui",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cot Meunye Cut",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cot Rheue",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cempeudak (Ceumpeudak)",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Cot Merbo/Meureubo",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Blang Riek",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Blang Talon",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Bayu",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Blang Ado",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Blang Gurah",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Babah Lueng",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Kuta Makmur",
                "kelurahan": "Alue Rambe",
                "kodepos": "24371"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Tumpok Beurandang",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Ulee Madon",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Reuleut Timur",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Tanoh Anoe/Anoue",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Teupin Banja",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Pante Gurah",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Pinto Makmur",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Reuleut Barat",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Paloh Awe",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Paloh Raya",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Panigah",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Meunasah Lhok",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Meunasah Pinto",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Meunasah Aron",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Meunasah Baro",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Meunasah Drang",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Mane Tunong",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Keude Mane",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Kuala Dua (Dewa)",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Keude Bungkaih",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Dakuta",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Kambam",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Cot Seurani",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Muara Batu",
                "kelurahan": "Cot Trueng",
                "kodepos": "24355"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Ulee Reuleung",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Uteun Geulinggang",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Tambon Baroh",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Tambon Tunong",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Ulee Pulo",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Paloh Lada",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Pulo Rungkom",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Lancang Barat",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Paloh Gadeng",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Paloh Igeuh",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Geulumpang Sulu Timur",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Keude Krueng Geukueh",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Bluka Teubai",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Geulumpang Sulu Barat",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Dewantara",
                "kelurahan": "Bangka Jaya",
                "kodepos": "24354"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Ulee Gampong",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Trieng Lhoksukon Selatan",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "U Baro (Meunasah U Baro)",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Pucok Alue",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Seupeng",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Meurebo",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Matang Teungoh L",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Lhok Reuhat",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Lueng Baro",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Kampung Tempel",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Jeulikat",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Kampung Bantan",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Drien Dua",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Cot Girek",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Ceumpeudak",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Beurandang Krueng",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Batu XII",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Beurandang Asan",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Beurandang Dayah",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Ara Lkoksukon Selatan",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Alue Leuhob",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Alue Seumambu",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Cot Girek",
                "kelurahan": "Alue Drien LS",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Paya Teungoh",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Seunebok Punti",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Pase Sentosa/Sentausa",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Paya Leupah",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Mancang",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Meunasah Baroh",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Meunasah Dayah Spk",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Keude Simpang Empat (IV)",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Kilo Meter VI",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Kilo Meter VIII",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Ie Tarek I",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Ie Tarek II",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Kebon Baru/Baro",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Alue Badee",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Simpang Kramat (Keramat)",
                "kelurahan": "Blang Raleue",
                "kodepos": "24313"
            }
        ],
        "k249": [
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Teungoh",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Tunong",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Ulee Blang Mane",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Rayeuk Kareung",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Seuneubok",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Mesjid Meuraya",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Mesjid Punteuet",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Mane Kareung",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Kuala",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Kumbang Punteuet",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Jeulikat",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Keude Panteuet",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Weu Panjou",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Jambo Timu",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Punteuet",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Teue",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Weu Baroh",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Buloh",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Blang Cut",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Alue Lim",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Asan Kareung",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Blang Mangat",
                "kelurahan": "Baloy",
                "kodepos": "24375"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Uteun Kot",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Ujong Pacu",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Paya Bili",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Paya Punteut (Punteuet)",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Paloh Batee",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Panggoi",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Paloh Punti",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Meuria Paloh",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Padang Sakti",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Meunasah Mee",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Meunasah Mesjid",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Meunasah Blang /Blt",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Meunasah Manyang",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Meunasah Alue",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Lhok Mon Puteh",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Keude Cunda",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Cot Trieng",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Cut Mamplam",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Cot Girek Kandang",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Blang Pulo",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Blang Panyang",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Blang Pohroh",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Blang Naleung Mameh",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Blang Crum",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Batu Phat Barat",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Satu",
                "kelurahan": "Batu Phat Timur",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Muara Dua",
                "kelurahan": "Alue Awe",
                "kodepos": "24352"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Ujong Blang",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Ulee Jalan",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Uteun Bayi",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Lancang Garam",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Mon Geudong",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Pusong Lama",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Kampung Jawa Lama",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Keude Aceh",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Kuta Blang",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Hagu Teungoh",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Hagu Selatan",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Kampung Jawa Baru",
                "kodepos": "24315"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Banda Masem",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Hagu Barat Laut",
                "kodepos": "24351"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Lhokseumawe",
                "kodepos": "24314"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Pusong Baru",
                "kodepos": "24314"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Simpang Empat",
                "kodepos": "24313"
            },
            {
                "kecamatan": "Banda Sakti",
                "kelurahan": "Tumpok Teungoh",
                "kodepos": "24311"
            }
        ],
        "k250": [
            {
                "kecamatan": "Makmur",
                "kelurahan": "Ulee Gle",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Trienggadeng",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Sukarame",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Tanjong Mulia",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Pulo Teungoh",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Panton Mesjid",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Paya Dua",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Pandak",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Pante Breuh",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Meureubo",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Mon Ara",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Leubu Mesjid",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Matang Kumbang",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Leubu Cot",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Leubu Me",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Lapehan Mesjid",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Kuta Barat",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Lapehan Mesjid",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Blang Perlak",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Buket Selamat",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Blang Kutang",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Blang Mane",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Batee Dabai",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Blang Dalam",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Alue Dua",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Makmur",
                "kelurahan": "Ara Lipeh",
                "kodepos": "24357"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Ujong Blang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Ulee Pusong",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Tingkeum Baro",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Tingkeum Manyang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Ujong Bayu",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Teupin Siron",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Tanjong Siron",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Tanjong Mesjid",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Tanjong Raya",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Samuti Makmur",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Samuti Rayeuk",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Tanjong Bungong",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Samuti Aman",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Samuti Krueng",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Pulo Reudeup",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Pulo Siron/Siren",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Rancong",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Pulo Nga",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Pulo Gisa",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Pulo Awe",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Pulo Blang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Paya Seupat",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Paya Rangkuluh",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Paya Baro",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Paya Kareung",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Parang Sikureung",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Pante Sikeumbong (Sikumbong)",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Paloh Peuradi",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Paloh Raya",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Paloh Me",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Paloh Kayee Kunyet",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Paloh Dama",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Mon Jeureujak",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Mon Keulayu",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Lingka Kuta",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Mon Jambee",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Meuse",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Lhok Mambang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Lhok Nga",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Lapang Barat",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Lapang Timur",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Kulu Kuta",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Keurumbok",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Kulu",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Keude Lapang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Jambo Kajeung",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Jarommah Baroh (Jarummah Baroh)",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Jarommah Me (Jarummahme)",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Imbudee (Iembudee)",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Gle Putoh",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Gle Putoh",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Geurugok",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Geulanggang Panah",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Geulanggang Rayeuk",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Dayah Panjoe",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Geulanggang Meunjee",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Dama Kawan",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Dayah Mesjid",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Tunong",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Crung/Crueng Kumbang",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Rambat",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Tube/Teubee",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Tufah/Tupah",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Cot Me",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Puuk",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Cot Baroh",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Jabet",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Cot Mane",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Cot Ara",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Ceubo",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Bukit/Buket Dalam",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Blang Rheue",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Blang Mee",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Blang Panjoe",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Blang Guron",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Blang Keude",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Blang Kubu",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Babah Suak",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Balee Me",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Ganda Pura",
                "kelurahan": "Alue Mangki",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Kuta Blang",
                "kelurahan": "Babah Jurong",
                "kodepos": "24356"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Ulee Jeumatan",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Ulee Ue",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Tanjongan Idem",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Ulee Alue",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Sangso",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Tanjong Baro",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Pulo Baroh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Pineung Siri Bee",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Pante Rheeng/Rheng",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Paloh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Namploh Papeuen",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Namploh Manyang",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Namploh Krueng",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Namploh Blang Garang",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Mideun Jok",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Namploh Baro",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Mideun Geudong",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meurah",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meunasah Puuk",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meunasah Lueng",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meunasah Lancok",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meunasah Lincah",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Meuliek",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Mesjid Baro",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Matang Wakeuh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Matang Teungoh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Matang Jareung",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Matang",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Lueng Keubeu",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Lhok Seumira",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Lancok",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Kandang",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Keude Aceh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Geulumpang/Glumpang Payong",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Geulumpang/Glumpang Bungkok",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Gampong Putoh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Gampong Meulum",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Gampong Baro",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Darussalam",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Cot Siren",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Cot Meurak Blang",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Cot Meurak Baroh",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Cot Mane",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Ankieng Barat",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Batee Iliek",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Samalanga",
                "kelurahan": "Alue Barat",
                "kodepos": "24264"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Uteun Sikumbong",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Uteun Rungkom",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Uteun Pupaleh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Uteun Kruet",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Ulee Rabo",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Ulee Blang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Ulee Gajah",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Tufah",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Teupin Panah",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Teupin Keupula",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Seumawe",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Tanjong Bungong",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Nalan",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Plimbang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Punti",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Seuneubok Baro",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Puuek (Pu Uk)",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Rambong Payong",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Sampo Ajad (Sampoi Ajat)",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Paya Bili",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Pulo Rangkileh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Pandrah Kandeh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Panton",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Panton Bili",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Padang Kasab",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Paloh Pupu",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Pandrah Janeng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Nase Barat",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Nase Me",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Tunong Lueng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Meunasah Reudeup",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Tambo",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Kota",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Lueng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Keupula",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Keutapang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Alue",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Matang Teungoh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Matang Nibong",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Matang Kulee",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lhueng Tengoh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Matang Bangka",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lhok Kulam",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Lhok Dagang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lheue Barat",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lheue Simpang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Lancok Ulim",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Lancok Bungo",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lampoh Oe",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Lancang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Kuta Trieng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Kuta Reusep/Rusep",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Keude Jeunieb",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Keude Plimbang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Krueng Baro/Baru",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Janggot Seungko",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Jeumpa Sikureung",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Jambo Dalam",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Garot",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Dayah Blang Raleu",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Garab",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Gampong Blang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Darul Aman",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Dayah Baro",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Cot Leubeng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Cot Geulumpang Baroh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Cot Geulumpang Tunong",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Cot Geulumpang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Blang Sama Gadeng",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Blang Mee Timur",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Blang Neubok",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Blang Poroh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Blang Lancang",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Blang Mee Barat",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Bantayan",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peulimbang (Plimbang)",
                "kelurahan": "Balee Daka",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Alue Seutui",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Pandrah",
                "kelurahan": "Alue Igeueh",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Jeunieb",
                "kelurahan": "Alue Lamsaba",
                "kodepos": "24263"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Tanjong Seulamat",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Teungku Di Bathon",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Sawang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Seuneubok Paya",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Pinto Rimba",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Pulo Ara",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Pulo Lawang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Paya Bunot",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Paya Timur",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Paya",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Paya Barat",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Mulia",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Neubok Naleung",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Tunong",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Tambo",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Rabo",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Mesjid",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Pulo",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Cut",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Bungo",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Alue",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Meunasah Baroh",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Lawang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Matang Pasi",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Matang Reuleut",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Keude Alue Rheing",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Kukue",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Kampong Baro",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Karieng",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Jabet",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Hagu",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Jaba",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Dayah Mon Ara",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Garot",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Cot Kruet",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Cot Laot",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Calok",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Cot Keutapang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Buket Paya",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Matang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Rangkuluh",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Beururu",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Glumpang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Kubu",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Blang Bati",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Ara Bungong",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Alue Keutapang",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Alue Sijuek",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Weu Jangka",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peudada",
                "kelurahan": "Alue Gandai",
                "kodepos": "24262"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Uteun Ghatom",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Uteun Raya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Uteun Bunta",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Ulee Ceue",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Ulee Jalan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Blang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Blang Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Teupin Raya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Teupin Reudeup",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Tanoh Mirah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Tanoh Anoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Tanjong Paya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Tanjongan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Tanjong Nie",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Tanjong Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Suwak",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Tanjong Beuridi",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Seuneubok Rawa",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Sagoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Seuneubok Aceh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Rambong Payong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Ruseb Ara",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Ruseb Dayah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Punjot",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pulo Ue Baroe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo Seuna",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo U",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo Reudeup",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pulo Pisang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo Pineung Meunasah II",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pulo Naleung",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Pulo Panyang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo Iboh/Iboeh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Pulo Harapan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pulo Blang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Meuneng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Reuhat",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Cut",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Lipah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Paya Bieng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Aboe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paya Aboe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pante Sukon",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Panton Geulima",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pante Ranub",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Pisang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Piyeu",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pante Paku",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Pante Peusangan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Pante Karya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Lhong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Cut",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Gajah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Pante Baro Kumbang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Pante Baro Bukit Panyang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Pante Baro Glee Siblah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Pante Ara",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Paloh Mampree",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Neuheuen",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Nicah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Paloh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Meunasah Meucap",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Meunasah Nibong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Matang Sagoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Me Rayeuk",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Matang Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Matang Glumpang II Meunasah Timur",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Matang Glumpang II Meunasah Dayah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Mata Mamplam",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Matang Cot Paseh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Mata Ie",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Lueng Daneun",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Lueng Kuli",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Lueng Baro",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Linggong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Lueng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Lancok Pante Ara",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Lhok Awe Awe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Lhok Bugeng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Lancok Lancok",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Lampoh Rayeuk",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Kuta Baro",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Lamkuta",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Kubu Raya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Kubu",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Kuala Raja",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Kuala Ceurape",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Krueng Juli Timur",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Krueng Dheue",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Krueng Juli Barat",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Krueng Beukah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Krueng Baro Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Keude Tanjong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Krueng Baro Babah Krueng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Karieng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Keude Matang Glumpang II",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Kareung (Kareueng)",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Kambuk (Kambuek)",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Kapa",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Jangka Alue U",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Jangka Keutapang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Jangka Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Jangka Alue Bie",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Jangka Alue",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Geulanggang Labu",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Geundot",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Glumpang Baroh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Gampong Putoh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Gampong Raya Dagang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Gampong Raya Tambo",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Gampong Baroh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Gampong Meulinteung",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Darussalam",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Dayah Baroe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot U Sibak",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Unoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Darul Aman",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Cot Saleut",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Trieng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Puuk",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Puuk",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Rabo Baroh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Nga",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Panjoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Kuta",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Lagasawa",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Keuranji",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Ijue (Ieju)",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Keumudee",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Glumpang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Buket",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Girek",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Batee",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Bada Baroh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Bada Tunong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Cot Bada Barat",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Cot Aneuk Batee",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Ceubrek",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Buket Sudan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bugak Mesjid",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bugeng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bugak Krueng",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bugak Krueng Mate",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Panjoe",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Rambong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bugak Blang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Geulanggang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Blang Mane",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Blang Pala",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Dalam",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Selatan",
                "kelurahan": "Blang Cut",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Asan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Blang Cut",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Blang Cirih",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Bayu",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Barat Lanyan",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Balee Kuyun",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bada Barat",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Bada Timur",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Awe Geutah Paya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Awe Geutah",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Alue Udeung",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Asan Bideun",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Alue Kuta",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Alue Krueb",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Alue Peuno",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Alue Keupula (Kupula)",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Alue Glumpang Sbl.K",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan Siblah Krueng",
                "kelurahan": "Alue Iet",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Peusangan",
                "kelurahan": "Alue Glumpang Ba",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Alue Buya Pasi",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Alue Bie Pusong",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Alue Buya",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Abeuk Jaloh",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Jangka",
                "kelurahan": "Alue Bayeu Utang",
                "kodepos": "24261"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ulee Kareung",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Teupok Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Uteun Reutoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Teupin Manee",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Tambue Barat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Teupok Baroh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Simpang Jaya",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Suka Tani",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Tamboi Tanjong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Seuneubok Peuraden",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Seuneubok Lhong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Seuneubok Gunci",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Seuleumbah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Rheum Barouh/Baroh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Rheum Timur",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Salah Sirong Jaya",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Rheum Barat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Ranto Panyang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Pulo Drien",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Pulo Lawang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Pulo Kiton",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Pulo Dapong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Pulo Ara Geudong Teungoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Peuneulet Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Peuneulet Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Paseh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Paya Cut",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Pante Peusangan",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Paloh Seulimeng",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Pante Baro",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Paku",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Paloh Panyang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Mon Jambee",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Mon Mane",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Meunasah Mamplam",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Meunasah Mesjid",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Meunasah Asan",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Meunasah Barat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Lipah Rayeuk",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Mane Meujingki",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Lipah Cut",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Lhok Tanoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Lhok Mane",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Lhaksamana",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Lhok Awe Teungoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Lancang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Kuta Meuligoe",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Kuala Jeumpa",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Krueng Meuseugob",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Krueng Simpo",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Kota Bireun (Bireuen)",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Jurong Binjee",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Keude Tambue",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Uruek Anoe",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Teungku Dilampoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Tambo Tanjong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Paya Ru",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Seupeng/Lampoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Meunasah Teungoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Meunasah Tambo",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Meunasah Seutuy",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Meunasah Jok",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Mee Teungoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Cot Meurak",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Keudee Dua",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Juli Cot Mesjid",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ie Rhob Timur",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ie Rhob Barat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ie Rhob Geulumpang/Glumpang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ie Rhob Babah Lueng",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Gle Meundong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Geulumpang Payong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Geulumpang Meu Jimjim",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geulanggang Kulam",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geulanggang Teungoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geulanggang Gampong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geulanggang Baro",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Geudong Tampu",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geudong Alue",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Geudong Geudong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Gampong Baro",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Cure Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Cure Baroh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Ulim",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Cot Trieng",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Tarom Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Tarom Baroh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Cot Peutek",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Meugoe",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Leusong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Keutapang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Cot Jrat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Iboh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Cot Gapu",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Geurundong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Iboeh Timu",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Bada",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Gadong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Bunyot (Beunyot)",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Calok",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Ceureucok",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Buket Mulia",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Buket Teukueh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Blang Tingkeum",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Teumulek",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Seupeng",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Tambue",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Rheum",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Seunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Blang Reulieng (Reuling)",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Panyang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Me",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Mane Dua Meunasah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Kuta Dua Meunasah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Mane Barat",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Blang Kuta Coh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Blang Keutumba (Ketumba)",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Dalam",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Gandai",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Cot Baroh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Cot Tunong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Blang Bladeh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Bireun Meunasah Reuleut",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Bireun Meunasah Teungku Digadong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Bireun Meunasah Capa",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Bireun Meunasah Dayah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Kota Juang",
                "kelurahan": "Bireun Meunasah Blang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Beurawang",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Batee Raya",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Batee Timoh",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Balee",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Balee Panah",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Arongan",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Alue Unou/Unoe",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Alue Limeng",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Simpang Mamplam",
                "kelurahan": "Alue Leuhob",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Aleu Rambong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Abeuk Usong",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Juli",
                "kelurahan": "Abeuk Budi",
                "kodepos": "24251"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Abeuk Tingkeum",
                "kodepos": "24251"
            }
        ],
        "k251": [
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Seunong",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Uteun Bayu",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Reudeup Melayu",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Pulo Gapu",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Reudeup Melayu",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Peulakan Tunong",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Pohroh",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Peulakan Tambo",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Paya Pisang Klat",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Paya Tunong",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Peulakan Cebrek",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Muko Buloh",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Paya Baroh",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meunasah Paku",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meurandeh Alue",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meuko Dayah",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meuko Kuthang",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meuko Baroh",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Lhok Pusong",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meugit Kayei Panyang",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Meugit Sagoe",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Kumba",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Kuta Krueng",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Keude Ulee Glee",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Krueng Kiran",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Jeulanga Mata Ie",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Jeulanga Mesjid",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Kampung Baro",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Gampong Ulee Glee",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Jeulanga Barat",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Drien Tujoh",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Gaharu",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Drien Bungong",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Cot Keng",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Blang Mirou",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Cot Geurufai",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Blang Dalam",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Blang Kuta",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Asan Kumbang",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Babah Krueng",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Beurasan",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Alue Sane",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Alue Keutapang",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Alue Me",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Tijin Husen",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Bandar Dua",
                "kelurahan": "Adan",
                "kodepos": "24188"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Tanjong Ulim",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Tijin Daboh",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Reuleut",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Sambongan Baro",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Siblah Coh",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Pulo Ulim",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Pulo Lhok",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Nangrhou Barat",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Pantang Cot Baloi",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Meunasah Pupu",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Nanggroe Timur",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Meunasah Kumbang",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Meunasah Mesjid",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Mesjid Ulim Baroh",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Mesjid Ulim Tunong",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Meunasah Bueng",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Lhok Gajah",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Keude Ulim",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Krueng",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Geulanggang",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Grong Grong Capa",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Cot Seutuy",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Dayah Baroh",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Dayah Leubue",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Blang Cari",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Blang Rheue",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Bale Ulim",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Bidok",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Ulim",
                "kelurahan": "Alue Keumiki",
                "kodepos": "24187"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Teupin Peuraho",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Seunong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rungkom",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Sarahmane",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rhing Mancang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rumpuen",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Reului Mangat",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rhing Blang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rhing Krueng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Rambong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Pohroh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Pulo U",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Muko Jurong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Pante Beureune",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Ms Kulam Beuracan",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Meuraksa",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Raya",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Meunasah Mulieng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Mancang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Meunasah Me",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Kulam Rentang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Meunasah Lhok",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Meunasah Kumbang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Jurong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Meunasah Hagu",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Bie",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Meunasah Bie",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Meuko Meugit",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Meunasah Balek",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Manyang Lamcok",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Mesjid Tuha",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Manyang Cut",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Lueng Bimba",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Lancok",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Lhok Sandeng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Kuta Trieng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Lampoh Lada",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Kota Meureudu",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Kudrang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Kuta Baroh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Kiran Dayah",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Kiran Krueng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Keurisi Meunasah Raya",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Kiran Baroh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Keudee Jangka Buya",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Keurisi Meunasah Lueng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Jurong Ara",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Jurong Binje",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Jurong Teungoh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Glumpang Tutong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Grong Grong",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Geulidah",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Gampong Cot",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Geunteng",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Gampong Blang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Dayah Tuha",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Dayah Kruet",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Dayah Timu",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Jangka Buya",
                "kelurahan": "Buket Teungoh",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Dayah Husen (Usien)",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Bunot",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Buangan",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Blang Cut",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Blang Awe",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Meurah Dua",
                "kelurahan": "Beuringen (Beringin)",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Tunong Pantee Raja",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Meureudu",
                "kelurahan": "Beurawang",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Tungkluet (Tung Kleut)",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Tuha",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Teungoh Pantee Raja",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Tu",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Sagou",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Tampui",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Reuseb",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Reudeup",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Rawasari",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Raya",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Peuradeu",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Peulandok Tunong",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Paya",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Peulandok Teungoh",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Muka Blang",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Panton Raya",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Meucat Pangwa",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Meue",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Mesjid Trienggadeng",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Me Peuduek (Peudok Baroh)",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Mesjid Peuduek",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Mesjid Pante Raja",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Matang",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Me Pangwa",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Kuta Pangwa",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Keudee Pante Raja",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Lhok Puuk",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Keude Trienggadeng",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Panteraja",
                "kelurahan": "Hagu",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "De (Gampong Dee)",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Dayah Pangwa",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Dayah Teumanah",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Dayah Ujong Baroh",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Cot Lheu Rheng",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Cot Makaso",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Ujong Leubat",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Trienggadeng",
                "kelurahan": "Buloh",
                "kodepos": "24185"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Tutong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Udeung",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Teungoh Musa",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Tualada",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Tanoh Mirah",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Siren",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Sarah Panyang",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Sawang",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Pulo Rheng",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Sagoe",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Pueb Lueng Nibong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Pulo Pueb",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Paru Cot",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Paru Keude",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Manyang",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Mesjid Lancok",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Kayee Raya",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Keude Lueng Putu",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Lancang Paru",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Kayee Jatoe",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Gampong Nyong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Jijiem",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Gampong Meunasah Baro (Baro Nyong)",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Dayah Nyong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Gampong Cut Nyong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Daboih",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Dayah Langien",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Blang Sukon",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Cut Langien",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Blang Iboih",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Blang Krueng",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Blang Baro",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Blang Glong",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Beurandeh",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Baroh Lancok",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Baroh Musa",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Balee",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Baroh Cot",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Aki Neungoh",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Alue",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Ara",
                "kodepos": "24184"
            },
            {
                "kecamatan": "Bandar Baru",
                "kelurahan": "Abah Lueng",
                "kodepos": "24184"
            }
        ],
        "k252": [
            {
                "kecamatan": "Mane",
                "kelurahan": "Turue Cut",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Mane",
                "kelurahan": "Mane",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Mane",
                "kelurahan": "Leuteung",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Mane",
                "kelurahan": "Blang Dalam",
                "kodepos": "24186"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Ukee",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Ude Gampong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "U Bungkok (U Bangkok)",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Sukon Mesjid",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Tufah Jeulatang",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Sukon Paku",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Simpang",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Sukon Baroh",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Seukee",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Seureukui Cebrek",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Sagoe",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Sangget (Sanget)",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Reudeup Meulayu",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Pulo Panjoe",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Pulo Lueng Teuga",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Pulo Dayah/Pulo Lon",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Pulo Iboih",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Pulo Gajah Mate",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Panjou",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Pulo Batee",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Palong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Neurok",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Meunjee",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Mee Teumpen (Meu Teumpeun)",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Manyang",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Lambaro",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Mamplam",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Kumbang Unoe",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Kumbang Keupula",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Kumbang Trueung Campli",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Kruet Teumpeun",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Krueng Nyong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Keutapang Mesjid",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Krueng Jangko",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Kayee Jatoe",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Keupula",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Jurong Baro",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Jurong Pande",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Geunteng",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Glumpang Bungkok",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Dayah Tanoh",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Gampong Jeumpa",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Dayah Gampong Pisang",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Daboih",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Cot Tunong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Bungong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Blang Tunong",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Cot Baroh",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Blang Drang",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Blang Baroh",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Blang Pueb",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Bili",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Balee",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Glumpang Baro",
                "kelurahan": "Balee Gantung",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Tanjong Krueng",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Sukon",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Teumpeun",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Tanjong",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Glumpang Tiga (Geulumpang Tiga)",
                "kelurahan": "Amut Mesjid",
                "kodepos": "24183"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Reung Reung",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Puuk",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Pasi Ie Leubeue",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Pasi Lhok",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Pusong",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meuraksa",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Kandang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Mee",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Mesjid",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Baro",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Gantung",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah Jareng (Jareing)",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Manyang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Lamkawe",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Matang Kuli",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Keupula (Kupula)",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Meunasah",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Keude Ie Leubeue",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Lancang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Kayee Panyang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Kampung Araih",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Jurong Mesjid",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Jurong Bale",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Jemeurang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Dayah Mon Ara (Deah Monara)",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Gampong Panjou (Gantung)",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Dayah Blang (Deah Blang)",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Cebrek",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Blang Cut",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Bentayan",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Barat",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Babah Jurong",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Asan",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Aron Kuta Baro",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Aron Asan Kumbang",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Arusan",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Ara",
                "kodepos": "24182"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ulee Barat",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ujong Gampong",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ujong Baroh",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Sukon",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Teungoh Mangki",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Seukee",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Seuk Cukok",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Sagoe",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Raya Paleue",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Pulo Raya",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Pulo Tu",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Pulo Gajah Mate",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Pulo Blang",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Padang",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Peukan Tuha",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Raya Paya",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Peukan Sot",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Lhee",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Pante",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Jurong",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Paloh Tok Dhue",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Gong",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Nien",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Empeh",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Mesjid Gigieng",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Mesjid Tungue",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Mantak Raya",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Mesjid Bungie",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Madika",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Mamplam",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Liliep Bungie",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Linggong Sagoe",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Kupula",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Jaja Baroh",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lambideng",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Jaja Tunong",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lheue",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Dayah Lampoh Awe",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Kampung Blang",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Dayah Blang",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Kulam Baro",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Dayah Blang Cut",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Curucok Sagoe",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Curucok Timur",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Curucok Barat",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Cot Ara",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Cot Jaja",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Cot Paleue",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Blang Leuen",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Pulo Glumpang",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Bunien",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Pulo Keunari",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Cebrek",
                "kodepos": "24181"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Panah",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Trieng Cudo Baroh",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Panton Beunot",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Trieng Cudo Tunong",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Peunadok",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Pulo Tambo",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Lhok Igeuh",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Rabo",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Mamprei",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Pulo Mesjid",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Mancang",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Pulo Siblah",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Dayah Gampong Baro",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Dayah Teungoh",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Dayah Cot",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Dayah Baroh",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Tiro/Truseb",
                "kelurahan": "Blang Reukui",
                "kodepos": "24174"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Ulee Tutue",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Tuha Kp/Gp Gajah",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Ulee Gampong",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Tong Pria",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Tong Weng",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Simbee (Blang Simbee)",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Tiba Raya",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Sagoe Teumpeun",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Sentosa",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Rinti",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Ribeun Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Reului Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Reubat",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Rapana",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Rambong",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Rambot Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Pulo Drien",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Peureulak Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Pante Beureueh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Paya Tiba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Paloh Raya",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Paloh Tinggi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Paloh Lhok Usi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Paloh Kambueh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Meucat Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Nibong",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Mesjid Yaman",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Meugit",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mesjid Tiba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mon Ara",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mesjid Usi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mesjid Jeurat Manyang",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Mesjid Keumangan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mee Tanjong",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Mesjid Gumpueng",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Mee Panyang Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Me Teungoh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Me Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Lingkok Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Lueng Sagoe",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Kumbang Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Lampoh Sirong Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Kulam Ara",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Gampong Lada",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Keumangan Cut",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Jiem",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Karieng",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Gampong Jadan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Jojo",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Gampong Cot Usi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Jumphoi Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Empeh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Dayah Usi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Didoh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Dayah Tidiek",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Dayah Syarif",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Dayah Kumba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Dayah Tanoh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Dayah Beureueh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Dayah Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Blangong Basah",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Campli Usi",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Cot Kuthang",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Blang Riek",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Blang Tidiek",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Blang Beureueh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Baroh Ujong Rimba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Blang Lileue",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Beureueh II",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Baro Ujong Rimba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Baro Yaman",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Balee Baro Keumangan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Barieh",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Baro Barat Yaman",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Bale Ujong Rimba",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara Timur",
                "kelurahan": "Alue Adan",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Mutiara",
                "kelurahan": "Bale Busu",
                "kodepos": "24173"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Ulee Tutue",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Tanjong Hagu",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Teungoh Baroh",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Ulee Cot Seupeng",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Sialet-Alet",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Sumboe Buga",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Reuba Waido",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Sawiet",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Rambayan Kupula",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Rambayan Lueng",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Puuk",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Peunayong",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Raya",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Sukon",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Krumbok",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Lueng",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meuleuweuk",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Meunasah Kabat",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Mesjid Rumpong",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Mee Lampoh Saka",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Menje Mesjid",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Mee Krueng Seumideun",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Mee Krukon",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Me Tanoh",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Mee Hagu",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Lueng Mesjid",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Kumbang waido",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Leuhop",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Kampong Blang Seupeng",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Keutapang",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Jurong",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Jurong Raya",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Kabat",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Gampong waido",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Jim",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dayah Tungku",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dua Paya",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dayah Muara",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dayah Seumideun",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dayah Sukon",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Dayah Bubue",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Cempala Kuneng",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Cot Meulu",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Blang Raya",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Bluk Arab",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Balee",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Peukan Baro",
                "kelurahan": "Balee Restong",
                "kodepos": "24172"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Wakeueh",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Yub Mee",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Tungkop Cut",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Ulee Birah",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Tuha Suwiek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Teungoh Blang",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Teungoh Suwiek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Tampieng Baroh",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Tamping/Tampieng Tunong",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Rumia",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Sukon Tungkop",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Sukon Ulee Gampong",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Rawa Tungkop",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Raya Lhok Kaju",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Peutou",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Pulo Gampong U",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Pante Garot",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Pante Lhok Kaju",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Meulayu",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Neulop II",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Tungkop",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Ulee Gampong",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Lam Ujong",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Suwiek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Dijiem",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Lamkabu",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Lamreuneung",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Mesjid Baro",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Keubang",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Keutapang",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Glee Gapui",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Guci",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Gampong Jurong",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Garot Cut",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Drien",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Dayah Muara Garot",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Dayah Caleue",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Dayah Keurako",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Dayah Bie",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Blang Rapai Gapui",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Cot Seukee",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Beureudeup",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Blang Lhok Kaju",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Beureudeup",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Baro Bluek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Baro Gapui",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Baro Jruek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Balee Baroh Bluek",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Indrajaya",
                "kelurahan": "Balee Baroh Gapui",
                "kodepos": "24171"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Tuha",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Geumpang",
                "kelurahan": "Pulo Loih/Lhoh",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Geumpang",
                "kelurahan": "Leupu",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Geumpang",
                "kelurahan": "Pucok",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Geumpang",
                "kelurahan": "Keune",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Kembang Tanjong (Tanjung)",
                "kelurahan": "Glumpang (Geulumpang)",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "T. Blang Beungoh",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Ulee Gunong",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Geumpang",
                "kelurahan": "Bangkeh",
                "kodepos": "24167"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Seunong",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Rantau Panyang",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Mesjid II",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Sejahtera",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Kawa",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Lhok",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Mesjid I",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Peunalon II",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Pulo Baro",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Paya Guci",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Peunalon I",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Nubok Badeuk",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Layan",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Lhok Keutapang",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Krueng Meuriyam",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Krueng Sikeuek",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Keubon Nilam",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Keudee",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Blang Dhot",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Blang Jeurat",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Blang Malo",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Blang Bungong",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Blang Dalam",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Alue Calong",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Alue Calong",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Tangse",
                "kelurahan": "Beungga",
                "kodepos": "24166"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Tunong",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "U Gadeng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Pulo Seupeng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Rheng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Sagoe",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Pulo Pante",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Pulo Raya",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Pulo Loh/Loih",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Pulo Baro",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Pulo Cahi (Pecahi)",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Pante Siren",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Papeuen Nicah",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Paloh Teungoh",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Pante Kulu",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Paku",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Paloh Naleueng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Mesjid Nicah",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Mesjid Tumpeudeng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Jijiem",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Kumbang",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Lingkok",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Dayah Keumala",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Dayah Menara",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Cot Kreh",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Cot Nuran",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Cut",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Asan Tumpeudeng",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Blang Tho",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Keumala",
                "kelurahan": "Asan Nicah",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Titeue",
                "kelurahan": "Alue",
                "kodepos": "24165"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Riweuk",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Tang Kueng",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Perlak Baroh",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Pulo Jeumpa",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Pulo Kerumbok",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Pasar Kota Bakti",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Perlak Asan",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Paloh Jeureula",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Pante Krueng",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Murong Cot",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Murong Lhok",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Lueng",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Raya",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Uke",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Blang Guyui",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Mali Mesjid",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Mancang",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Meunasah Bale",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lingkok",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Mali Cot",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Mali Guyui",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lhok Me",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lhok Panah",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lhok Empeh",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Leupeum Mesjid",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lam Ujong",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lameue Meunasah Baro",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Lamkuta",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Kandang",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Kp Pisang Bucue",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Kampong Cot",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Kampong Jeumpa",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Jurong Pante",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Kampong Baro",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Dayah Kp Pisang",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Dayah Tuha",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Cumbok Lie",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Cumbok Niwa",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Cot Cantek",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Cot Sukon",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Blang Kumot Baroh",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Blang Kumot Tunong",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Bucue",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Beutong Perlak",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Beutong Pocut",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Balue Kulu",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Balue Tanoh",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Sakti",
                "kelurahan": "Barieh",
                "kodepos": "24164"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Tuha Lala",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Tunong",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Teumeucet",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Teungoh Ilot",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Meuyub Lala",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Pulo Tanjong",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Mesjid Andeue",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Mesjid Ilot",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Meunasah Blang",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Lhok Lubu",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Kulu",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Kumbang",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Lagang",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Dayah Sinthop",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Krueng Lala",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Blang Cut",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Dayah Andeue",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Baroh Ilot",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Babah Jurong",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Ulee Tutue Raya",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Mila",
                "kelurahan": "Ara Bungkok",
                "kodepos": "24163"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Tunong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Sukon Lhong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Tanjong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Seupeung",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Sagoe",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Seukeum",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Runtoh",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Reusep",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Raya",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Reuba",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Puuk",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Pulo Baroh",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Pulo Tunong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Pante Aree",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Pangoe",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Metareum",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Neulop",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Mesjid Reubee",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Mesjid Bambong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Mesjid Beuah",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Mesjid Aree",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Lhee Meunasah",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Lueng Dama",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Krumbok",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Kumbang",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Krueng Cot",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Keutapang Bambong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Krueng",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Keutapang Aree",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Jambee",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Glee",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Geudong",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Dayah Reubee",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Gampong Cut (Meunasah Cut)",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Dayah Beuah",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Dayah Baro",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Daboh",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Ceurih Keupula",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Ceurih Cot",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Ceurih Blang Mee",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Bungo",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Ceurih Alue",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Delima",
                "kelurahan": "Buloh",
                "kodepos": "24162"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Tunong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Tunong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Trieng",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Tuha Gogo",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Teungoh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Teungoh Drien",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Suyo",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Siron Tanjong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Sukon",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Seunadeu",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Siron",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Sekeumbrok",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Seuleunggieng",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Raya",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Piala Kunyet",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Pulo Hagu",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Pante Cermen",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Pasar",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Perlak",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Muko",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Paloh Jeurat",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Tanjong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Meurinya",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mukee",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Paloh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Peudaya",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Gogo",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Kunyet",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Beurabo",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Mesjid Geuleudieng",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Kumbang",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Leuen",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Leuhob",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Keupula",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Khang Tanjong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Kret",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Kambuk Payapi",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Jurong Anoe",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Kambuk Nicah",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Gurong Cot",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Hagu",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Jok",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Geulumpang",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Gle",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Grong Grong",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Dayah Baroh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Dayah Tanoh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Gampong Cut",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Dayah Baroh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Crueng",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Cut",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Buni Reuleung",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Capa",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Cot Keutapang",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Buloh",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Buloh Buni",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Baro",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Blang Geuleudieng",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Blang Guci",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Aron Beunot",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Balee",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Baro",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Ujong Pie",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Padang Tiji",
                "kelurahan": "Adang",
                "kodepos": "24161"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Tuha Biheue",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Teungku Di Laweung",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Pawod",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Sagoe",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Suka Jaya",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Mesjid",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Papeun",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Ingin Jaya",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Keupula",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Krueng",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Dayah",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Gle Cut",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "IE Masen",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Batee",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Muara Tiga",
                "kelurahan": "Blang Raya",
                "kodepos": "24153"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Teupin Raya",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Tuha",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Teupin Jeue",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Teungoh",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Sukon",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Seulatan",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Sentosa",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Pulo Pande",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Pulo Tukok",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Rungkom",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Pulo Bungong",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Nicah Meuaneuk",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Pangge/Pilok",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Paya",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Meunasah Mee",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Neuheun",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Mesjid Meuaneuk",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Meucat",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Mesjid Calong",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Mesjid Beureuleung",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Kule",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Mee Beureuleung (Meunasah Mee)",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Kareung",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Kulam",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Karieng",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Gintong",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Grong Grong",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Geunteng Barat",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Geunteng Timur",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Dayah Baroh",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Dayah Tua",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Geulumpang Lhee",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Dayah",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Crueng",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Daka",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Calong Cut",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Calong Cut",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Beureuleung",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Bintang Hu",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Grong Grong",
                "kelurahan": "Baroh",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Awee",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Alue Lada",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Batee",
                "kelurahan": "Aron",
                "kodepos": "24152"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Ulee Ceueteubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Ulee Tutue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Tumpok Laweung",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Ujong Langgo",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Ulee Ceu Keulibeut",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Tijue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Tumpok 40",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Sirong",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Teubeng Tanjong",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Tibang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Seuri Weuk",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Seukeumbrok",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Seuke",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Rawa",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Raya Sanggeue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Raya Utue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Pulo Pisang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Puuk",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Puli",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Pulo Bubee",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Paya",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Paya Linteung",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Peukan Baro",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Meucat Teubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Paloh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Mancang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Mesjid Runtoh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Mesjid Utue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Leu Bue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Lhok Keutapang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Lampeudeu Tunong",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Lampoh Lada",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Labui",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Lamkuta",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Lampeudeu Baroh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Keutumbu",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Krueng Dhoe",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Keunire",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Keutapang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Kampong Baro",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Keudee Keulibeut",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Jawa Teubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Jeumpa",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Gampong Barat",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Gampong Pukat",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Gajah Ayee",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Gampong",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dayah Teubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dayah Teungoh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dayah Tutong",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dalueng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dayah Jeuleupe",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Dayah Tanoh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Cot Geunduek",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Cot Rheng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Cot Teungoh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Cot Geulumpang",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Bie",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Blangkula",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Bayu Teubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Baroh",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Batee",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Abo Teubeng",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Pidie",
                "kelurahan": "Alue",
                "kodepos": "24151"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Blang Paseh",
                "kodepos": "24119"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Lampoh Krueng",
                "kodepos": "24119"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Tanjong Krueng",
                "kodepos": "24116"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Blok Bengkel",
                "kodepos": "24118"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Keramat Dalam",
                "kodepos": "24115"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Pasi Rawa",
                "kodepos": "24116"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Pante Teungoh",
                "kodepos": "24114"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Kampong Asan",
                "kodepos": "24115"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Pasi Peukan Baro",
                "kodepos": "24113"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Blok Sawah",
                "kodepos": "24114"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Meunasah Peukan",
                "kodepos": "24111"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Benteng",
                "kodepos": "24112"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Kuala Pidie",
                "kodepos": "24112"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Blang Asan",
                "kodepos": "24111"
            },
            {
                "kecamatan": "Kota Sigli",
                "kelurahan": "Keramat Luar",
                "kodepos": "24111"
            }
        ],
        "k253": [
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Teuladan",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Suka Mulya (Sukamulia)",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Sukadamai",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Panca Kubu",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Paya Keureuleh",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Saree Aceh",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Lon Baroh",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Panca",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Lon Asan",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Lam Kubu",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Lambaro Tunong",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Lembah Seulawah",
                "kelurahan": "Lamtamot",
                "kodepos": "23952"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Ujong Keupula",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Ujong Mesjid Lampanah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Ujong Mesjid T. Abee",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Seuneubok",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Pulo",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Rabo",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Seulimeum",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Pasar Seulimeum",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Pinto Khop",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Meunasah Baro",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Meunasah Tunong",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Meurah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lhieb",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Mangeu",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lampisang Tunong",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lamteuba Droi",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Leungah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lampisang Dayah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lampisang Teungoh",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lamkuk",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lampanah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lampante",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lamcarak",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lamjruen",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Keunaloi",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lam Apeng",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Lambada",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Jeumpa",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Kampong/Gampong Raya",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Kayee Adang",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Jawie",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Iboih/Iboh Tunong",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Data Gaseu/Gaseue",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Iboih/Iboh Tanjong",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Capeung Baroh",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Capeung Dayah",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Beureunut",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Blang Tingkeum",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Buga",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Bak Seutui",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Batee Lhee",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Bayu",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Ayon",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Bak Aghu",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Alue Rindang",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Ateuk",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Bukit Meusara",
                "kodepos": "23919"
            },
            {
                "kecamatan": "Seulimeum",
                "kelurahan": "Alue Gintong",
                "kodepos": "23951"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Suka Tani",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Weu",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Cucum",
                "kodepos": "23918"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Jantho Baru",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Jantho Makmur",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Beung",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Data Cut",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Awee",
                "kodepos": "23917"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Jantho",
                "kodepos": "23912"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Teureubeh",
                "kodepos": "23913"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Jalin",
                "kodepos": "23915"
            },
            {
                "kecamatan": "Kota Jantho",
                "kelurahan": "Barueh",
                "kodepos": "23911"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Teunom",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Ulee Paya",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Rinon",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Seurapong",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Pasi Janeng",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Rabo",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Paloh",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Lhoh",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Melingge",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Lamteng",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Lapeng",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Deudap",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Gugob",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Lampuyang",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Alue Reuyeueng (Riyeung)",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Blang Situngkoh",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Pulo Aceh",
                "kelurahan": "Alue Raya",
                "kodepos": "23391"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Ruyung",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Paya Kameng",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Neuheun",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Meunasah Mon",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Meunasah Kulam",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Meunasah Keudee",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Lamnga",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Lam Reh",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Ladong",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Gampong Baro",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Ie Seu Um",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Durung",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Mesjid Raya",
                "kelurahan": "Beurandeh",
                "kodepos": "23381"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Tungkop",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Tanjung Deyah",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Siem",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Tanjong Seulamat",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Seuleu",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Miruk Lam Reudeup",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Limpok",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Miruek Taman",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lie Eue",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Lampineung",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lambaro Sukon",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lambiheu Siem",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lambada Peukan",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Ujong",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Lam Ujong",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Reh",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Timpeung",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Puuk",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Puja",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Klat",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Peudaya",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Keunung",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Duroy",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Gawee",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Bitra",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Biheu",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Lam Asan",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Lam Bada Lhok",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Lam Asan",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Krueng Kalee",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Labui",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Klieng Meuria",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Klieng Cot Aron",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Kajhu",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Gampong Blang",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Gampong Cot",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Cadek",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Cot Paya",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Blang Krueng",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Beurabung",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Baitussalam",
                "kelurahan": "Baet",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Darussalam",
                "kelurahan": "Angan",
                "kodepos": "23373"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Tumpok Lampoh",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Ujong Blang",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Puuk",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Rabeu",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Seupeu",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Meunasah Bak Trieng",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Paya Ue (Paya U)",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Leupung Ulee Alue",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Meulayo (Melayo)",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Leupung Mesjid",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lamteubee Mon Ara",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lamteubee Geupula",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lamsie (Lamsiem)",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lamroh",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lampoh Keudee",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lampoh Tarom",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lampuuk",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Lamme",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lambro Deyah",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lamceu",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Trieng",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lambro Bileu",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Raya",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Sabang",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Seunong",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Glumpang/Geulumpang",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Neuheun",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Baet",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Bunot Paya",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Bunot Tanoh",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Asan",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Alue Cut",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Lam Alue Raya",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Gue",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Krueng Anoi",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cucum",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Deyah",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Raya",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Yang",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Puklat",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Preh",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Mon Raya",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Peutano",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Mancang",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Masam",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Geundreut",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Lamme",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Beut",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Cot Cut",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Beurangong",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Bueng Bak Jok",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Bak Buloh",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Aron",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Kuta Baro",
                "kelurahan": "Babah Jurong",
                "kodepos": "23372"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Teubang Phui",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ujong XII",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Tantuha",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Tanjong",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Santan",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Siron",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Pasie Lubok",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Reuloh",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Rumpet",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Pantee",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Pasie LG",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Nya",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Paleuh Pulo",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Nya",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Miruk",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Teutong",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Meunasah Papeun",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Manyet",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Meunasah Manyang",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Manyang LG",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Manyang Pagar Air",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Meunasah Intan",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Baro",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Lueng Ie",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Meunasah Baet",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Meunasah Baktrieng",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lhok Sukon",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lubok Gapuy",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lubuk Batee",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lampreh L. Teungoh",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lampreh Lam Jamprok",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Lampermei (Lampermee)",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lamjamee Dayah",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lamjamee Lamkrak",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lambaro",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lamdaya",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lambunot",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lambada",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lam Urit",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lam Ue",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lam Sinyeu",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Lam Teungoh",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "lam Cot",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Lam Gapang",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Krueng Mak",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Lam Batee",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Kayee Lee",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Jurong Peujeura",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Kalut",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Gani",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Gla Deyah",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Krueng Barona Jaya",
                "kelurahan": "Gla Meunasah Baro",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Dham Ceukok",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Dham Pulo",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Cot Montiwan",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Cot Suruy",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Madhi/Madi",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Malem",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Cot Bada",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Cot Got",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Karieng",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Bung Ceukok",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Cot Alue",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Bueng Sidom",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Blang Miro",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Blang Preh",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Bineeh Blang",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Bantee Linteung",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Bha Ulee Tutu",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Bada",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Bakoy",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ateuk Lueng Ie",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Mon Panah",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Lam Puot",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Lam Ura",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Lamphang",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Blang Asan",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Simpang Tiga",
                "kelurahan": "Ateuk Cut",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ajee Rayeuk",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ateuk Anggok",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ajee Pagar Air",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Ulee Oe",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Ingin Jaya",
                "kelurahan": "Ajee Cut",
                "kodepos": "23371"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Ulee Kareung",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Tutui/Tutue",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Teudayah",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Tumbo Baro",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Siron Blang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Siron Krueng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Sihom Cot",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Sihom Lhok",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Sinyeu",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Sigapang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Seulangai",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Seureumo",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Seot/Seuot Baroh",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Seot/Seuot Tunong",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Riting",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Reuleung Geulumpang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Reuleung Karieng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Reukih Dayah",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Reukih Keupula",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Pasar Lampakuk",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Pasar Indrapuri",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Pakuk",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Mureu Ulee Titi",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Mureu Baro",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Mureu Bueng-Ue",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Mureu Lam Glumpang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Meusalee Lhok",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Mon Alue",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Meunara",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Manggra",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Maheng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lubok Batee",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lubok Buni",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lingom",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Limo Lamluweung (Lamlueng)",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Limo Mesjid",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lheue Jeumpa",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Limo Blang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Leupung Riwat",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Leupung Cut",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Leupung Rayeuk",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Leupung Baleu/Balee",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Leupung Bruk/Bruek",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lamtui",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampupok Baro",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampupok Raya",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lamsiteh Cot",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lampoh Raja",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampanah Teungoh",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampanah Tunong",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampanah Ranjo",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampanah Baro",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lampanah Dayah",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lampakuk",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lamleubok",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lambaro Samahani",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Siot",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lambunot",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lam Leupung",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Lueng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lam Kleng/Kleeng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lam Leuot",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Ilie Teungoh",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Beutong",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Ilie Ganto",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Lam Ilie Mesjid",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lam Beugak",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lam Ara Tunong",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lam Ara Cut",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Lam Ara Engkit",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Krueng Lamkareung",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Lam Aling/Alieng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Keumireu",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Keuruweung Blang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Keuruweung Krueng",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Jruek Bak Kreh",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Jruek Balee",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Ie Alang Lam Kreumeuh (Keureumeun)",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Ie Alang Mesjid",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Indrapuri",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Ie Alang Dayah",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Ie Alang Lam Ghui",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Grot Meunasah Baro",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Grot Meunasah Blang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Grot Meunasah Manyang",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Glee Jai",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Empee Ara",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Cot Kareung",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Cureh",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Bueng Simek",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Cot Bayu",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Malaka (Kuta Malaka)",
                "kelurahan": "Bughu",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Bandasafa",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Barih Lhok",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Bithak",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Indrapuri",
                "kelurahan": "Aneuk Glee",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Kota Cot Glie (Kuta Cot Glie)",
                "kelurahan": "Bak Sukon",
                "kodepos": "23363"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Wue Lhok",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Warabo",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Weu Bada",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Weu Krueng",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Teubang Phui Mesjid",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Ulee Lhat",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Teupin Batee",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Seuban Lhok",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Seumeut",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Teubang Phui Baro",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Piyeung Manee",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Reudeup",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Seuban Cot",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Peyeung Datu",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Piyeng Kuweu",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Piyeung Lhang",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Meunasah Tutong",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Mon Ara",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Peurumping",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Mata Ie",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Lampaseh Krueng",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Lampaseh Lhok",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Lam Siem",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Lam Raya",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Lamme Garot",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Kayee Kunyet",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Lam Nga",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Kampung Blang",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Empee Tunong",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Gampong Baroh",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Empee Bata",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Cot Seunong",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Dayah Daboh",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Data Makmur",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Rumpun",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Sayun",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Mancang",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Meulangen",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Nambak",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Leu-Ot",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Cot Lampoh Soh",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Cot Lhok",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Bagie",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Ho-Ho",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Cot Jambo",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bung Raya",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bung Tujuh",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bira Lhok",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bung Daroh",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Blank Bintang",
                "kelurahan": "Bueng Pageu",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bak Cirih",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bak Dilib",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Bira Cot",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Alue",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Mantasiek (Montasik)",
                "kelurahan": "Atong",
                "kodepos": "23362"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Tampok Jeurat Raya",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Weusiteh",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Sibreh Keumudee",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Tampok Blang",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Reuhat Tuha",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Seumeureung",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Panterawa",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Niron",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Luthu Lamweu",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Meunasah Bakthu",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Meunasah Tuha",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lamteh Dayah",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Luthu Dayah Krueng",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lampanah Ineu",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lampisang",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lamgeu Baro",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lamgeu Tuha",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lambarih Jurong Raya",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lambaro Sibreh",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lambirah",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lambarih Bak Mee",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lam Tanjong",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Kayee Adang",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Klieng Manyang",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Lam Lheu",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Dilib Lamteungoh",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Blang Cot/Cut",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Bukloh",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Dilib Bukti",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Baet Mesjid",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Baet Meusugo",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Aneuk Galong Baro",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Aneuk Galong Titi",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Baet Lampuot",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Utamong",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Suka Makmur",
                "kelurahan": "Aneuk Batee",
                "kodepos": "23361"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Umong Seurebee",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Tunong Krueng Kala",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Sengko Meulat",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Teungoh Blang Mee",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Teungoh Geunteut",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Pudeng",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Saney",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Mon Mata",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Paroy",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Pasie",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Meunasah Krueng Kala",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Meunasah Lhok",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lampaya",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Lam Kuta Blang Mee",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Lam Sujen",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Lam Juhang",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Keutapang",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Lam Griheu",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Jantang",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Kareung",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Gapuy",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Glee Bruek",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Cot",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Cundin",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Birek",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Baroh Krueng Kala",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Baroh Genteut",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoong",
                "kelurahan": "Baroh Blang Mee",
                "kodepos": "23354"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Weu Raya",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Seubun Keutapang",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Tanjong",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Seubun Ayon",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Pulot",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Naga Umbang",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Nusa",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Mon Ikeun",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Mnsh Mesjid Lam Puuk",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Mon Cut",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Mesjid Lamlhom",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Meunasah Mesjid (Mesjid Leupung)",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Manyang",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Lam Girek",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Karieng",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Cut",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Beutong",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Blang",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Baro Lamlhom",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Baro Lam Puuk",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Meunasah Balee Lamlhom",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Meunasah Bak Ue",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Layeun",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lambaro Seubun",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Lamsenia (Lamseunia)",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lambaro Kueh",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lam Kruet",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lam Gaboh",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lam Cok",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Kueh",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Lam Ateuk",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Leupung",
                "kelurahan": "Deah Mamplam",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Lhoknga (Lho'nga)",
                "kelurahan": "Ateuk Paya",
                "kodepos": "23353"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Ulee Tui/Thui",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Ulee Lueng",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Turam",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Tingkeum",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Teubalui",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Payaroh",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Punie",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Pasheu Beutong",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Neusok",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lheu Blang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Manee Dayah",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lhang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Leu Ue (Mata Ie)",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Leugeu",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lamtheun",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lamtadok",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lamsiteh",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lamsod",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lamreung",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lamsidaya",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lampeuneurut Gampong",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lampeuneurut Ujong Blang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lampasi Engking",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lampeuneuen",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lamkunyet",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lamcot",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lambatee",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lam Kawe",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lambaro Biluy",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lam Blang Manyang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lam Blang Trieng",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Lam Bleut",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lam Bheu",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Lagang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Kandang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Kuta Karang",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Geundrieng",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Gue Gajah",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Jeumpet Ajuen/Ajun",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Empetring (Empee Trieng)",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Garot Geuceu",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Deunong",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Daroy Kameu",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Blang Kiree",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Imarah",
                "kelurahan": "Bayu",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Darul Kamal",
                "kelurahan": "Bilui/Biluy",
                "kodepos": "23352"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Rima Jeuneu",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Rima Keuneurom",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Meunasah Tuha",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Paya Tieng",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Pulo Bunta",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lamteungoh",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lamtutui",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lampisang",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lamteh",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lamguron",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lamkeumok",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lampageu",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lambadeuk",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lambaro Nejid",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Manyang",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Rukam",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Hasan",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Isek",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Lumpu",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Awe",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Lam Geu Eu",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Gurah",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Keuneu Ue",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Gampong Baro",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Ajuen",
                "kodepos": "23351"
            },
            {
                "kecamatan": "Peukan Bada",
                "kelurahan": "Beuradeun",
                "kodepos": "23351"
            }
        ],
        "k254": [
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Wellang Kum (Wel Langkom)",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Suak Baru",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Wel Wel",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Ujung Pandang / Padang",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Sebbe",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Situfa Jaya",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Sibuluh",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Luan Sorip",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Putra Jaya",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Latitik",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Lauke",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Laure-E",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Lambaya",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Latak Ayah",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Kuta Padang",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Lakubang",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Lamayang",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Kuta Inang",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Kuta Batu",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Dihit",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeuleu Tengah",
                "kelurahan": "Kampung Aie",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Borengan",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Bubuhan",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Ujung Salang",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Simeulue Cut",
                "kelurahan": "Amarabu",
                "kodepos": "23894"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Tameng",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Tamon",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Suak Manang",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Serafon",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Panton Lawe",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Nasreuhe",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Padang Unoi",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Meunafah",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Mutiara",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lhok Paoh",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lubuk Baik",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lewak",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lhok Dalam",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lamerem",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Langi",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Lalla Bahagia",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Alapan (Alafan)",
                "kelurahan": "Lafakha",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Kenangan Jaya",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Karya Bakti",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Bunga",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Ganang Pusako",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Jaya Baru",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Ujung Harapan",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Salang",
                "kelurahan": "Along Jaya",
                "kodepos": "23893"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Sinar Bahagia",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Sital",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Silengas",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Sembilan",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Sigulai",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Salur Latun",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Sanggiran",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Salur Lasengalu",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Pulau Teupah",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Salur",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Miteum",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Naibos",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Nancala",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Malasin",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Maudil",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Lhok Bikhao",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Lhok Makmur",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Layabaung",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Leubang Hulu",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Lantik",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Laubang (Leubang)",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Inor",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Lamamek",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Laayon",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Bunon",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Batu Ragi",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Awe Seubal",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Babul Makmur",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Angkeo",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Barat",
                "kelurahan": "Awe Kecil",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Simeuleu Barat",
                "kelurahan": "Amabaan",
                "kodepos": "23892"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Ulul Mayang (Manyang)",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Ujung Tinggi",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Trans Maranti",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Trans Baru",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Trans Jernge",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Tanjung Raya",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Suka Maju",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Suka Karya",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Suka Jaya",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Suak Lamatan",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Sua-Sua",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Suak Buluh",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Situbuk",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Sinabang",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Simpang Abail",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Seuneubok",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Sefoyan",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Pulau Siumat",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Sambay",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Pulau Bengkalak",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Pasir Tinggi",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Muara Aman",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Nancawa",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Matan Urung",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Lugu",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Lugu Sek Bahak (Sebahak)",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Luan Balu",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Linggi",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Lataling",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Latiung",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Lasikin",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Lanting",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Labuhan Jaya",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Labuhan Bakti",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Labuhan Bajau",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Labuah",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Kuala Makmur",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Kuala Baru",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Kota Batu",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Kuala Bakti",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Kebun Baru",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Kahat",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Gunung Putih",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Ganting",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Busung Indah",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Bulu Hadik",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Batu-Batu",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Blang Sebel",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Badegong",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Batu Ralang",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Babussalam",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Amaiteng Mulia",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Ana Ao",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Ameria Bahagia",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Selatan",
                "kelurahan": "Alus Alus",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Air Dingin",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Simeuleu Timur",
                "kelurahan": "Air Pinang",
                "kodepos": "23891"
            },
            {
                "kecamatan": "Teupah Tengah",
                "kelurahan": "Abail",
                "kodepos": "23891"
            }
        ],
        "k255": [
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Krueng Luas",
                "kodepos": "23776"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Ujong/Ujung Tanoh",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Titi Poben",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Tepin/Teupin Tinggi",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Sigleng (Singleng)",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Seuneubok Puntho",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Seuneubok Pusaka",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Raket",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Seunabok Jaya",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Panton Bilie",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Pulo Paya",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Pinto Rimba",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Naca",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Padang Harapan",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Ladang Rimba",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Lhok Raya",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Kuta Padang",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Kuta Baro/Baru",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Krueng Batee",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Kapai Seusak",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Keude Trumon",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Jambo Papeun",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Jambo Dalem",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Ie Jeureuneh",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon",
                "kelurahan": "Ie Meudama",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Gampong Teungoh (Kampong Teungoh)",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Gunong Kapo/Kapho",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Tengah",
                "kelurahan": "Cot Bayu",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Trumon Timur",
                "kelurahan": "Alur Bujok",
                "kodepos": "23774"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Ujung Pulo Rayeuk",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Ujung Tanoh",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Ujung Mangki",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Ujung Padang",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Ujung Pulo Cut",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Simpang",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Ujung Gunong Rayek",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Ujung Gunung Cut",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Seubadeh",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Seulekat (Seuleukat)",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Seunebok Keranji",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Sawah Tingkeum",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Seneubok Alur Bulah/Buloh",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Padang Berahan",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Ladang Rimba",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Padang Berahan",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Keude Bakongan",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan Timur",
                "kelurahan": "Kampung Baru",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Jambo Keupok/Keuphok",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Gampong Drien (Kampung Drien)",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Darul Ikhsan",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Bakongan",
                "kelurahan": "Gampong Baro (Kampung Baru)",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Beutong",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Bukit Gading (Buket Gedeng)",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kota Bahagia",
                "kelurahan": "Alur Dua Mas",
                "kodepos": "23773"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Ujung Pasir",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Suaq Bakong",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Ujung",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Ujung Padang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Simpang Tiga",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Siurai-Urai",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Sapik",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Sialang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Simpang Dua",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Pulo Ie",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Rantau Binuang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Pulo Air",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Pucuk Lembang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Paya Dapur",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Paya Laba",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Pasi Lembang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Pasi Merapat",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Malaka",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Mersak",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Luar",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Lawe Melang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Lawe Sawah",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Lawe Buluh Didi",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Lawe Cimanok",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Koto Indarung",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Koto Menggamat",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Kapeh",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Kedai Kandang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Kedai Runding",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Kampung Padang",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Kampung Sawah",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Jua",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Jambo Papan",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Gelumbuk",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Indra Damai",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Selatan",
                "kelurahan": "Barat Daya",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Durian Kawan",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Tengah",
                "kelurahan": "Alur Keujrun",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Timur",
                "kelurahan": "Alai",
                "kodepos": "23772"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Simpang Empat",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Simpang Lhee",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Suaq Geuringgeng (Geringgeng)",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Pulo Ie I",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Pulo Kambing",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Pasi Kuala Asahan",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Pasi Kuala Bau",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Krueng Kluet",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Limau Purut (Kota Fajar)",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Kotafajar",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Krueng Batee",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Krueng Batu",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Kedai Padang",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Kampung Ruak",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Kampung Tinggi",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Jambo Manyang",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Kampung Paya",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Gunong Pulo",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Gunung Pudung",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Alur Mas",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Kluet Utara",
                "kelurahan": "Fajar Harapan",
                "kodepos": "23771"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Tengah Pisang",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Ujung Batu",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Pawoh",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Pisang",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Tengah Baru",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Padang Bakau",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Padang Baru",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Pasar Lama",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Lembah Baru",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Manggis Harapan",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Dalam",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Hulu Pisang",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Kota Palak",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Bakau Hulu",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Cacang",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Apha",
                "kodepos": "23761"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Peuneulop (Peunalop)",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Sawang Indah",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Tengah Peulumat",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Paya Peulumat",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Padang Peulumat",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Keumumu Hulu",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Keumumu Seberang",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Limau Saring",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Gunung Rotan",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Keumumu Hilir",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Aur Peulumat",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Timur",
                "kelurahan": "Beutong",
                "kodepos": "23758"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Tengah Lboh/Iboh",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Tutong",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Ujung Padang",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Pulo Ie",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Suak Lokan",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Panton Pawoh",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Panton Rubek",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Peulokan",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Kuta Trieng",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Pante Geulima",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Kuta Lboh/Iboh",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Blang Poroh",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Iku Lhung",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Batee Meucanang",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Labuhan Haji Barat",
                "kelurahan": "Blang Baru",
                "kodepos": "23757"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ujung Padang Asahan",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ujung Padang Rasian",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Silolo",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Teupin Gajah",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ujung Batee/Batu",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Pulo Ie Ii",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Seunebok (Si Neubok)",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Paya Ateuk (Payateuk)",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Pucuk/Pucok Krueng",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Pante Raja",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Panton Bili",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Pasi/Pasie Rasian",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Lhok Sialang Rayeuk",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Mata Le/Ie",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ladang Teungoh",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ladang Tuha",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Lhok Sialang Cut",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Krueng Kelee",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Ie/Le Mirah",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Kampung/Kampong Baro",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Pasie Raja",
                "kelurahan": "Kampung/Kampong Baru",
                "kodepos": "23755"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Rot Teungoh",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Lhok Aman",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Lhok Mamplam",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Labuhan Tarok II",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Ladang Baro",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Ladang Tuha",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Kuta Buloh II",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Labuhan Tarok",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Kuta Baro (Abro)",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Kuta Buloh I",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Ie/Le Dingen",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Jambo Papeun",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Keude Meukek",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Drien Jalo",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Ie/Le Buboh",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Buket Meuh",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Blang Bladeh",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Blang Kuala",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Blang Teungoh",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Alue Meutuah",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Aron/Arun Tunggai",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Ujung Karang",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Ujung Padang",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Meukek",
                "kelurahan": "Alue Baro",
                "kodepos": "23754"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Trieng Meuduro Baroh",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Trieng Meuduro Tunong",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Sikulat",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Simpang Tiga",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Sawang I",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Sawang II",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Sawang Bau",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Mutiara",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Panton Luas",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Meuligo",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Kuta Baro",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Lhok Pawoh",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sawang",
                "kelurahan": "Blang Geulinggang (Gelinggang)",
                "kodepos": "23753"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Ujung Tanah",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Tengah",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Ujung Kampung",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Tampang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Subarang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Suaq Hulu",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Payonan Gadang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Lubuk Layu",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Madat",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Luar",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Ladang Kasik Putih",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Ladang Panton Luas",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Kuta Blang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Kota Baru",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Jilatang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Gunung Cut",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Gunung Ketek",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Dalam",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Gadang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Batee Tunggai",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Baru",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Balai",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Alur Simerah",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Arafah",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Alur Pinang",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Air Sialang Tengah",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Air Sialang Hulu",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Sama Dua",
                "kelurahan": "Air Sialang Hilir",
                "kodepos": "23752"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Lhok Keutapang (Ketapang)",
                "kodepos": "23718"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Hilir",
                "kodepos": "23717"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Lhok Bengkuang",
                "kodepos": "23715"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Jambo Apha",
                "kodepos": "23716"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Hulu",
                "kodepos": "23714"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Padang",
                "kodepos": "23713"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Lhok Bengkuang Timur",
                "kodepos": "23712"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Tepi Air",
                "kodepos": "23712"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Pasar",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Panton Luas",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Panjupian",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Lhok Rukam",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Gunung Kerambil",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Batu Itam",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Air Pinang",
                "kodepos": "23711"
            },
            {
                "kecamatan": "Tapak Tuan",
                "kelurahan": "Air Berudang",
                "kodepos": "23711"
            }
        ],
        "k256": [
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Pantee Cermin",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Pantee Rakyat",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Gunung Samarinda",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Ie Merah",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Alue Jeureujak (Jerjak)",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Alue Peunawa",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Babah Rot",
                "kelurahan": "Blang Dalam",
                "kodepos": "23767"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Pasar Kuta Bahagia",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Muka Blang",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Padang Sikabu",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Panto Cut",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Lhok Gajah",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Lhung Gelumpang",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Lama Tuha",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Krueng Batee",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Kuala Terubue",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Kampung Tengah",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Keudee Baro",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Kota Bahagia",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Ie Mameh",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Gelanggang Gajah",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Blang Makmur",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Blang Panyang",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Alue Padee",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Kuala Batee",
                "kelurahan": "Alue Pisang",
                "kodepos": "23766"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Tangah",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Ujung Padang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Rubek Meupayong",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Rumah Dua Lapis",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Rumah Panjang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Pulau Kayu",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Pinang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Panjang Baru",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Pantai Perak",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Pawoh",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Palak Hilir",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Palak Hulu",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Padang Hilir",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Padang Panjang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Meunasah",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Padang Baru",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Ladang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Lampoh Drien",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Kedai Palak Kerambil",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Kedai Susoh",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Kepala Bandar",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Gadang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Durian Rampak",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Blang Dalam",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Cot Mancang",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Durian Jangek",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Baharu",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Susoh",
                "kelurahan": "Barat",
                "kodepos": "23765"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Pasar Blang Pidie",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Seunaloh",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Padang Geulumpang",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Meudang Ara",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Panton Raya",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Lhung Asan",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Lhung Tarok",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Mata Ie",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Lamkuta",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Kuta Tuha",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Ladang Neubok",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Kuta Tinggi",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Kuta Makmur",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Kuta Bahagia",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Kuta Jeumpa",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Kedai / Keude Paya",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Kedai / Keude Siblah",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Gudang",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Guhang",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Iku Lhung",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Geulumpang Payong",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Cot Mane",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Cot Jeurat",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Baru",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Baharu",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Alue Sungai Pinang",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Asoe Nanggroe (Aso Nanggro)",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Blang Pidie",
                "kelurahan": "Alue Manggota (Alum Anggota)",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Jeumpa",
                "kelurahan": "Alue Rambot",
                "kodepos": "23764"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Tangan Tangan Cut",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Ujung Tanah",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Suak Labu",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Suak Nibong",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Pesisir Mon Mameh",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Pisang",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Rambong",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Pante Geulumpang",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Padang Bak Jeumpa / Jumpa",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Padang Jaklok (Bakjok)",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Padang Kawa",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Kuta Bak Drien/Drin",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Mesjid",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Lhang",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Gunung Cut",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Drien Jalo",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Drien Kipah",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Bineh Krueng",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Blang Padang",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Tangan-Tangan",
                "kelurahan": "Adan",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Setia",
                "kelurahan": "Alue Dama",
                "kodepos": "23763"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Tokoh",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Ujung Tanah",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Ujung Tanah",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Teungoh",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Suka Damai",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Pusu Ingin Raya",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Sejahtera",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Seuneulop",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Pantom Makmur",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Paya",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Pante Pirak",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Padang Keulele/Kelele",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Pante Raja",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Padang",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Meurandeh",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Meunasah Sukon",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Lhong Baro",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Meunasah Teungoh / Tengah",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Lhok Puntol",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Ladang Tuha II",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Lhok Pawoh",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Ladang Panah",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Ladang Tuha I",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Kuta Paya",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Keudei",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Geulanggang Batee",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Blang Manggeng",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Lembah Sabil",
                "kelurahan": "Geulanggang Batee",
                "kodepos": "23762"
            },
            {
                "kecamatan": "Manggeng",
                "kelurahan": "Alue Rambot",
                "kodepos": "23762"
            }
        ],
        "k257": [
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Tuwi Saya",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Tungkop",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Tutut",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Sipot",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Tanoh Mirah",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Sakuy",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Sarah Perlak",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Pungkie",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Ramitie",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Leubok Beutong",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Lueng Baro",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Lancong",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Lancong",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Gaseu",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Gleung",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Gunong Buloh",
                "kodepos": "23683"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Ulee Pasi Ara",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Ulee Pulo",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Tuwi Empeuk",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Tangkeh",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Teumiket Ranom",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Simpang Teumarom",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Seuneubok Dalam",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Seuradeuk",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Rambong",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Rambong Pinto",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Peuluekung",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Paya Baro",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Paya Meugeundrang",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Pasi Panyang",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Pasi Malee",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Pasi Mali",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Pasi Jeut",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Napai",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Pasi Ara Wt",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Pasi Janeng",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Lueng Baro",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Mon Pasong",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Leubok Pasi Ara",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Lhok Malee",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Leubok/Lubok Panyang",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Kulam Kaju",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Kubu Capang (Keubeu Capang)",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Gunong Panyang",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Ie Sayang",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Karak",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Gampong Baro Kb",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Gampong Baro Wt",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Cot Punti",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Cot Lagan Lm",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Cot Rambong",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Blang Makmur",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Buket Meugajah (Bukit Megajah)",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Blang Luah Kb",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Blang Luah",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Blang Dalam",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Blang Cot Rubek",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Blang Cot Mameh",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Alue Meuganda",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Alue Seuralen",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Alue Perman",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Alue Kuyuen",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Alue Keumuneng",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Barat",
                "kelurahan": "Alue Leuhob",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Alue Empeuk",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Ujong Raja",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Woyla Timur",
                "kelurahan": "Alue Bilie",
                "kodepos": "23682"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Tumpok Ladang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Tuwi Buya",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Teuladan",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Teupin Panah",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Tegal Sari",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Tanjong Bunga",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Tanjong Meulaboh",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Tamping",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Suwak Awe",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Simpang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Sikundo",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Sibintang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Seumantok",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Seumara",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Sawang Teubee",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Sawang Rambot",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Putim",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Puuk",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Pulo Teungoh Manyeng",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pucok Pungkie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pungkie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Paya Baro Meuko",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Teungoh",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Peunia",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Kumbang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Meugat",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Jambu",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Jeumpa",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Pante Ceureumen (Pante Cermin)",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Pasi Ara",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Padang Mancang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Padang Sikabu",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Palimbungan",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Muko",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Mugo Rayeuk",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Mugo Cut",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Meunasah Rayeuk",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Meutulang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Meunasah Buloh",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Meunasah Gantung",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Meunasah Rambot",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Mesjid",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Meunasah Ara",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Menuang Kinco",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Menuang Tanjong",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Manjeng",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Marek",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Lhok Sari",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Manggie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Lek Lek",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Lawet",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Lhok Guci",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Lango",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Kuala Manye",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Keutambang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Krueng Beukah",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Keude Tanjong",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Keuramat",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Keude Aron",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Keude Suwak Awe",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Jambak",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Gunung Mata Ie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Gunong Tarok",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Geudong",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Sungai Mas",
                "kelurahan": "Drien Sibak",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Gampong Baro",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Cot Manggie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Drien Calee",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Canggai",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Blang Teungoh",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Blang Geunang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Blang Balee",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Blang Dalam",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Batu Jaya",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Beureugang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Berdikari",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Baro Paya",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Babah Meulaboh",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Babah Krueng Teklep",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Babah Lueng",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Babah Krueng Manggie",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Babah Iseung",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Alue Tampak",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Panton Reu",
                "kelurahan": "Antong",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Alue On",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Alue Peudeung",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Alue Lhok",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Kaway XVI",
                "kelurahan": "Alue Lhee",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Pante Ceureumen (Pantai Ceuremen)",
                "kelurahan": "Alue Keumang",
                "kodepos": "23681"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Tingkeum Panyang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Suak Tring",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Teumarom",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Ranto Panyang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Seumantok",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Pulo Ie",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Paya Luah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Paya Dua",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Pasi Lunak",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Pasi Pandan",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Pasi Birah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Pasi Aceh",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Panton",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Padang Jawa",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Lueng Tanoh Tho",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Lueng Jawa",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Lueng Buloh",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Kuala Bhee",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Lhung Tgk Yah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Keuleumbah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Jawi",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Ie Itam Baroh",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Ie Itam Tunong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Jawa",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Gunong/Gunung Rambong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Gunung Hampa",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Gempa Raya",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Glee Siblah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Drin Mangko",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Darul Huda",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Cot Lagan BB/Cm",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Cot Murong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Cot Situah",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Cot Keumudee",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Bakat",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Blang Mee",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Aron Tunong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Aron Baroh",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Alue Sundak",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Alue Blang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Alue Panyang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Woyla",
                "kelurahan": "Alue Sikaya",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Ulee Blang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Ujong Simpang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Ujong Nga",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Teupin Peuraho",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Ujong Beusa",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Seukee",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Seumaseh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Timah",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Pante Breuh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Suak Pangkat",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Suak Ie Beusou",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Pandan",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Suak Keumudee",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Suak Bidok",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Suak Geudubang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Simpang Peut",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Seuneubok Trap",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Seuneubok Lueng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Seuneubok Teungoh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Seumuleng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Rimba Langgeh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Reusak",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Pucok Lueng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Rangkileh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Rambong",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Peuribu",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Pinem",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Peulanteu Sp",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Panton Makmur",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Peulanteu Lb",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Paya Lumpat",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Pante Meutia",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Panton Bahagia",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Pange",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Lubok",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Mesjid Baro",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Leukeun",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Lhok Bubon",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Liceh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Kuta Padang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Layung",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Kubu",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Kuala Pling",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Krueng Tinggai",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Kuala Bubon",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Keureuseng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Keub",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Gunong Panah",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Gunong Pulo",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Karang Hampa",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Gampong Cot",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Gampong Ladang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Seumeureng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Deuah",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Drien Rampak",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Mesjid",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Pluh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Seulamat",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Lampise",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Cot Lada",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Cot Keumuneng",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Cot Kumbang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Darat",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Cot Buloh",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Cot Jurumudi",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Cot Amun",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Blang Sibeutong",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Beurawang",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Alue Sundak",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Arongan",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Alue Batee",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Alue Lhok",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Samatiga",
                "kelurahan": "Alue Raya",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Bubon",
                "kelurahan": "Alue Bakong",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Arongan Lambalek",
                "kelurahan": "Alue Bagok",
                "kodepos": "23652"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Lapang",
                "kodepos": "23618"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Drien Rampak",
                "kodepos": "23617"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Blang Berandang",
                "kodepos": "23618"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ujong Tanoh Darat",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Rundeng",
                "kodepos": "23616"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Ujong Baroh",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ujong Drien",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ujong Tanjong",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Reudeup",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Sumber Batu",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ranto Panyang Barat",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ranto Panyang Timur",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Ranub Dong",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pucok Reudeup",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pulo Teungoh Ranto",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Peunaga Pasi",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Peunaga Rayeuk",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Paya Peunaga",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Peunaga Cut Ujong",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pasi Mesjid",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pasi Pinang",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Paya Baro Rp.",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pasi Aceh Baroh",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Pasi Aceh Tunong",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Mesjid Tuha",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Meureubo",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Buloh",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Gunong Kleng",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Langung",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Balee",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Meureubo",
                "kelurahan": "Bukit Jaya",
                "kodepos": "23615"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Ujung Kalak",
                "kodepos": "23613"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Kuta Padang",
                "kodepos": "23614"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Panggong",
                "kodepos": "23612"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Pasar Aceh",
                "kodepos": "23612"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Suwak Indrapuri",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Suak Ribee",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Suak Sigadeng",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Suak Nie",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Suak Raya",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Padang Seurahet",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Seuneubok",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Kampung Darat",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Kampung Pasir",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Leuhan",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Kampung Belakang",
                "kodepos": "23611"
            },
            {
                "kecamatan": "Johan Pahlawan",
                "kelurahan": "Gampa",
                "kodepos": "23611"
            }
        ],
        "k258": [
            {
                "kecamatan": "Beutong",
                "kelurahan": "Tuwi Bunta",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Ujong Blang",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Panton Bayam",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Pante Ara",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Meunasah Krueng",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Meunasah Pante",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Lhok Seumot",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Meunasah Dayah",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong Ateuh Banggalang",
                "kelurahan": "Kuta Teungoh",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Kulam Jeurneh (Jeureuneh)",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Kuta Jeumpa",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Keude Seumot",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Krueng Cut",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Gunung Nagan",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Seumot",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Seunong",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Bumi Sari",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong Ateuh Banggalang",
                "kelurahan": "Blang Puuk",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Neuang",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Mesjid",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong Ateuh Banggalang",
                "kelurahan": "Blang Meurandeh",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Dalam",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Leumak",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Baro Pulo Raga",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Blang Baro Rambong",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong",
                "kelurahan": "Babah Krueng",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Beutong Ateuh Banggalang",
                "kelurahan": "Babah Suak",
                "kodepos": "23672"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Tuwi Meuleusong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Uteun Pulo",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Suak Bilie",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Suak Peureubong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Seumambek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Sawang Mane",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Sapek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Sapeng",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Rambong Rayeuk",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Pulo Teungoh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Rambong Cut",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Peureulak",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Paya Undan",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Peuleukung",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Paya Udeung",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Paya",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Padang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Pante Cermin (Ceureumen)",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Parom",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Nigan",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Mon Bateung",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Meureubo",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Meugat Meh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Meurandeh Suak",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Macah",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Lhok Rameun",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Lueng Baro",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Lhok Padang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Lhok Parom",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Lhok Pange",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Lhok Mesjid",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Lhok Beutong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kuta Sayeh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Latong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kuta Kumbang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kuta Paya",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Kuta Padang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kuta Baro Jeuram",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Kuta Baro Blang Mulieng",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kulu",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Kuta Aceh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Krueng Mangkom",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Krueng Kulu",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Krak Tampai",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Krueng Ceh/Cheh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Krueng Ceukou",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Keude Linteung",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Keude Neulop",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Kila",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Kabu Tunong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Kandeh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Kabu Baroh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Kabu Blang Sapek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Ie Beudoh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Gampong Teungoh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Jeuram",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Cot Teuku Dek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Gampong Cot",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Cot Peradi/Peuradi",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Cot Manyang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Cot Punti",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Cot Kuta",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Cot Lele (Lhe Lhe)",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Cot Kumbang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Cot Dirui",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Cot Gud",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Blang Sapek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Teungku",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Blang Puuk Nigan",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Blang Puuk Kulu",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Penyang",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Preh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Blang Murong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Blang Pateuk",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Blang Mulieng",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Geudong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Lango",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Bayu",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Blang Baro",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Ara Keude",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Bantan",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan Timur",
                "kelurahan": "Blang Ara Gampong",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Alue Tho",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Alue Peusaja",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Alue Dodok",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Alue Gajah",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Suka Makmue",
                "kelurahan": "Alue Kambuk/Kambuek",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Seunagan",
                "kelurahan": "Alue Buloh",
                "kodepos": "23671"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Ujong Tanjong",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Ujong Krueng",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Ujong Lamie",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Sumber Makmur",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Tuwi Buya",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Suka Mulia",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Suka Raja",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Suka Ramai",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Suka Jadi",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Suak Palembang",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Simpang Dua",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Sp. Deli Kampong",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Sp. Deli Kilang",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Serba Jadi",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Sido Jadi",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Pulo Kruet",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Pulo Teungoh",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Serba Guna",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Pulo Ie",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Panton Pange",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Panton Pange",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Pasie Keube Dom",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Neubok Yee PK",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Neubok Yee PP",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Lueng Keube Jagat/Jagad",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Mon Dua",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Makarti Jaya",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Ladang Baro",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Lamie",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Kuala Teripa/Tripa",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Kuta Trieng",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Krueng Seumanyam",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Kuala Seumanyam",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Kayee Unoe",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Krueng Alem",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Kabu",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Gunong Cut",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Karang Anyer",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Blang Luah",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Drien Tujoh",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Gelanggang Gajah",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Blang Baro",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Tripa Makmur",
                "kelurahan": "Babah Lueng",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Raya",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Waki",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Rambot",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Kuyun",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Geutah",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Jampak",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Sikuneng",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Bateung Brok",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Darul Makmur",
                "kelurahan": "Alue Bilie",
                "kodepos": "23662"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Padang",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Pasi",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Ujong Fatihah",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Sumber Daya",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Simpang Peut",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Suak Puntong",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Sarah Mantok",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Simpang Jaya",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Purworejo",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Rantau Selamat",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Purwosari",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Pulo Ie",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Purwodadi",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Pasi Luah",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Pulo",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Lueng T Ben",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Padang Panyang",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Padang Rubeek",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Lawa Batu",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Langkak",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Kuta Makmur",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Kuala Tuha",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Kuala Trang",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Kuala Tadu",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Kuala Baro",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Krueng Itam",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Jogja/Jokja",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gunong Sapek",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gunong Sapek",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Gunong Reubo",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gunong Pungkie",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gunong Gulungo (Geulugo)",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gunong Kupok",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Gubang Gajah",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Gapa Garu",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Gampong Lhok",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Cot Rambong (Cot Me)",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Cot Mue",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Cot Mee",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Blang Teungoh",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Cot Kumbang",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Blang Muko",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Blang Bintang",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Blang Baro",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Babah Rot",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Batu Raja",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Babah Dua",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala Pesisir",
                "kelurahan": "Arongan",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Alue Siron",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Alue Labue",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Alue Sepeng (Seupeung)",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Alue Ie Mameh",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Alue Gajah",
                "kodepos": "23661"
            },
            {
                "kecamatan": "Tadu Raya",
                "kelurahan": "Alue Bata",
                "kodepos": "23661"
            }
        ],
        "k259": [
            {
                "kecamatan": "Jaya",
                "kelurahan": "Ujong Sudeun",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Teumareum",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Ujong Muloh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Sapek",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Sango",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Sabet",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Putue",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Rumpet",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Pasar Lamno",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Panton Makmur",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Pante Cermin",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Pante Keutapang",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Nusa",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Mukhan",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Mukhan",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Meunasah Weh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Meunasah Tutong",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Meunasah Teungoh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Meunasah Serba",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Meunasah Rayeuk",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Meudheun",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Meudang Ghon",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lhuet",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Mareu",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lamme",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lamtui",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Leupe",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lamasan",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lambaroh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Kuala",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Lam Durian",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Kareng Ateuh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Keude Unga",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Krueng Tunong",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Jangeut",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Jambo Masi",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Gle Jong",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Gle Putoh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Gampong Baro",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Cot Dulang",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Darat",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Babah Krueng",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Bak Paoh",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Ceunamprong",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Babah Ie",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Babah Dua",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Jaya",
                "kelurahan": "Alue Rayeuk",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Indra Jaya",
                "kelurahan": "Alue Mie",
                "kodepos": "23657"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Ujong Rimba",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Seumantok",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Reuntang",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Sayeung",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Ranto Sabon",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Pulo Raya",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Pulo Raya",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Panton Krueng",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Patek",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Pajar",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Meunasah Kulam",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Masen",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Mataie (Mata IE)",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Lam Teungoh",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Lhok Kruet",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Ligan",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Kuala Bakong",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Kuala Ligan",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Krueng Tho",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Krueng Ayon",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Krueng No",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Gunung Cut",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Ie Jeureungeh",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Jeumpheuk",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Gampong Baro L",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Gampong Baro P",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Cot Pange",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Cot Punti (Ex Upt V Patek)",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Crak Mong",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Blang Luah",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Blang Monlung",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Cot Langsat",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Blang Dalam",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Babah Nipah",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Arongan",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Babah Dua",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Sampoiniet",
                "kelurahan": "Alue Gro",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Darul Hikmah",
                "kelurahan": "Alue Gajah",
                "kodepos": "23656"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Sapek",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Sawang",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Pante Kuyun",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Paya Laut",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Padang",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Lhok Geulumpang",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Lhok Timon",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Lhok Bot",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Lhok Buya",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Gampong Baroh",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Gle Subak",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Gunong Meunasah",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Setia Bakti",
                "kelurahan": "Gampong Baro",
                "kodepos": "23655"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Sentosa",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Ranto Panyang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Panton Makmur",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Panton Makmur",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Panggong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Padang Datar",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Mon Mata",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Keutapang",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Keude Krueng Sabee",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Kabong",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Kampung Blang (Gampong Blang)",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Dayah Baro",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Datar Luas",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Curek",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Buntha",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Bahagia",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Krueng Sabee",
                "kelurahan": "Alue Tho",
                "kodepos": "23654"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Tuwi Perya",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Weu / Weue Gajah",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Tuwi Kareung Panga",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Tuwi Kayee",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Tuwi Kareung I",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Tuwi Empeuk",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Tim Pleung",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Teupin Ara",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Tanoh Anou / Anoe",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Tanoh Manyang",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Seumira",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Sarah Raya",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Seuneubok Padang",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Rambong Payong",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Pulo Tinggi",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Pasi Tulak Bala",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Pasi Tulak Bala",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Pasi Teubee",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Pasi Timon",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Pasi Pawang",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Panton Kabu",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Panton Krueng",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Pasi Geulima",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Padang Kleng",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Panton",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Lueng Gayo",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Lhok Guci",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Kubu",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Kuta Tuha",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Ladang Baro",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Keude Teunom",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Krueng Beukah",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Gunong Meulinteung",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Keude Panga",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Gleputoh",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Gunong Buloh",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Gunong Mantok",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Gampong Harapan",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Gampong Baro",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Cot Trap",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Ceuraceu",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Blang Baro",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Blang Rame",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Buket Keumuneng",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Bintah",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Batee Meutudong",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Batee-Roo",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Alue Teungoh",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Babah Ceupan",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Alue Raya",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Alue Punti",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Alue Meuraksa / Meurasa",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Alue Krueng",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Alue Pande",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Teunom",
                "kelurahan": "Alue Ambang",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Pasie Raya",
                "kelurahan": "Alue Jang",
                "kodepos": "23653"
            },
            {
                "kecamatan": "Keude Panga",
                "kelurahan": "Alue Abet",
                "kodepos": "23653"
            }
        ],
        "k260": [
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Beurawang",
                "kodepos": "23527"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Keunekai",
                "kodepos": "23528"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Paya",
                "kodepos": "23529"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Balohan",
                "kodepos": "23525"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Jaboi",
                "kodepos": "23526"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Cot Ba&#8217;U",
                "kodepos": "23522"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Cot Abeuk",
                "kodepos": "23523"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Anoi Itam",
                "kodepos": "23524"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Ujong Kareung (Kareueng)",
                "kodepos": "23521"
            },
            {
                "kecamatan": "Sukajaya",
                "kelurahan": "Ie Meulee",
                "kodepos": "23521"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Batee Shok",
                "kodepos": "23517"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Iboih",
                "kodepos": "23518"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Krueng Raya",
                "kodepos": "23515"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Paya Seunara",
                "kodepos": "23516"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Kota Bawah Timur",
                "kodepos": "23513"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Aneuk Laot",
                "kodepos": "23514"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Kota Atas",
                "kodepos": "23511"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Kota Bawah Barat",
                "kodepos": "23512"
            }
        ],
        "k261": [
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Lampaloh",
                "kodepos": "23248"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Sukadamai",
                "kodepos": "23249"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Panteriek",
                "kodepos": "23247"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Blang Cut",
                "kodepos": "23248"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Cot Mesjid",
                "kodepos": "23246"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Lamseupeung",
                "kodepos": "23247"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Lueng Bata",
                "kodepos": "23247"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Ateuk Jawo",
                "kodepos": "23245"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Batoh",
                "kodepos": "23245"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Ateuk Munjeng",
                "kodepos": "23244"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Neusu Aceh",
                "kodepos": "23244"
            },
            {
                "kecamatan": "Lueng Bata",
                "kelurahan": "Lam Dom (Landom)",
                "kodepos": "23244"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Ateuk Deah Tanoh",
                "kodepos": "23244"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Sukaramai",
                "kodepos": "23243"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Neusu Jaya",
                "kodepos": "23243"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Seutui",
                "kodepos": "23243"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Merduati",
                "kodepos": "23242"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Kampung Baru",
                "kodepos": "23242"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Geuceu Meunara",
                "kodepos": "23241"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Peuniti",
                "kodepos": "23241"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Lam Lagang",
                "kodepos": "23239"
            },
            {
                "kecamatan": "Baiturrahman",
                "kelurahan": "Ateuk Pahlawan",
                "kodepos": "23241"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Geuceu Iniem",
                "kodepos": "23239"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Geuceu Kayee Jato",
                "kodepos": "23239"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Geuceu Komplek",
                "kodepos": "23239"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Mibo",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Lhong Raya",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Peunyeurat",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Lampuot",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Lhong Cut",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Emperom",
                "kodepos": "23236"
            },
            {
                "kecamatan": "Banda Raya",
                "kelurahan": "Lam Ara",
                "kodepos": "23238"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Lamjamee",
                "kodepos": "23235"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Lampoh Daya",
                "kodepos": "23235"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Ulee Pata",
                "kodepos": "23235"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Bitai",
                "kodepos": "23235"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Ulee Lheue",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Punge Blang Cut",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Surien",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Lamjabat",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Asoi Nanggro",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Gampong Baro",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Gampong Blang",
                "kodepos": "23234"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Lambung",
                "kodepos": "23233"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Punge Ujong",
                "kodepos": "23233"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Cot Lamkuweueh",
                "kodepos": "23233"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Cot Lamkuweueh",
                "kodepos": "23233"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Deyah Gelumpang (Deah Glumpang)",
                "kodepos": "23232"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Blang Oi",
                "kodepos": "23233"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Alue Deah Teungoh (Alue Deyah Tengoh)",
                "kodepos": "23232"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Deyah Baro (Deah Baro)",
                "kodepos": "23232"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Punge Jurong",
                "kodepos": "23231"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Lampaseh Kota",
                "kodepos": "23231"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Lamtemen Timur",
                "kodepos": "23230"
            },
            {
                "kecamatan": "Meuraxa",
                "kelurahan": "Lampaseh Aceh",
                "kodepos": "23231"
            },
            {
                "kecamatan": "Jaya Baru",
                "kelurahan": "Lamtemen Barat",
                "kodepos": "23230"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Peulanggahan",
                "kodepos": "23129"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Gampong Pande",
                "kodepos": "23128"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Keudah",
                "kodepos": "23129"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Lampula / Lampulo",
                "kodepos": "23127"
            },
            {
                "kecamatan": "Kuta Raja",
                "kelurahan": "Gampong Jawa",
                "kodepos": "23128"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Lambaro Skep",
                "kodepos": "23127"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Lamdingin",
                "kodepos": "23127"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Bandar Baru",
                "kodepos": "23126"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Keuramat (Kramat)",
                "kodepos": "23126"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Beurawe",
                "kodepos": "23124"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Kota Baru",
                "kodepos": "23125"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Peunayong",
                "kodepos": "23122"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Mulia",
                "kodepos": "23123"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Kuta Alam",
                "kodepos": "23121"
            },
            {
                "kecamatan": "Kuta Alam",
                "kelurahan": "Laksana",
                "kodepos": "23122"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Pango Deah",
                "kodepos": "23119"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Pango Raya",
                "kodepos": "23119"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Lamteh",
                "kodepos": "23118"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Ilie",
                "kodepos": "23119"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Lamglumpang",
                "kodepos": "23117"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Lambhuk",
                "kodepos": "23118"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Doi/Doy",
                "kodepos": "23117"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Iemasen Ulee Kareng",
                "kodepos": "23117"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Iemasen Kaye Adang",
                "kodepos": "23116"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Pineung",
                "kodepos": "23116"
            },
            {
                "kecamatan": "Ulee Kareng",
                "kelurahan": "Ceurih",
                "kodepos": "23117"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Gampong Peurada",
                "kodepos": "23116"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Alue Naga",
                "kodepos": "23116"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Deah Raya",
                "kodepos": "23116"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Tibang",
                "kodepos": "23114"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Lamgugob",
                "kodepos": "23115"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Rukoh",
                "kodepos": "23112"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Jeulingke",
                "kodepos": "23114"
            },
            {
                "kecamatan": "Syiah Kuala",
                "kelurahan": "Kopelma Darussalam",
                "kodepos": "23111"
            }
        ],
        "k262": [
            {
                "kecamatan": "Brang Rea",
                "kelurahan": "Sapugara Bree",
                "kodepos": "84458"
            },
            {
                "kecamatan": "Brang Rea",
                "kelurahan": "Tepas",
                "kodepos": "84458"
            },
            {
                "kecamatan": "Brang Rea",
                "kelurahan": "Bangkat Monte",
                "kodepos": "84458"
            },
            {
                "kecamatan": "Brang Rea",
                "kelurahan": "Desa Beru",
                "kodepos": "84458"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Tongo",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Sekongkang Bawah",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Talonang",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Tatar",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Kemuning",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Sekongkang Atas",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Sekongkang",
                "kelurahan": "Ai Kangkung",
                "kodepos": "84457"
            },
            {
                "kecamatan": "Maluk",
                "kelurahan": "Pasir Putih",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Maluk",
                "kelurahan": "Mantun",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Maluk",
                "kelurahan": "Maluk",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Jereweh",
                "kelurahan": "Dasan Anyar",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Jereweh",
                "kelurahan": "Goa",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Jereweh",
                "kelurahan": "Beru",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Maluk",
                "kelurahan": "Bukit Damai",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Jereweh",
                "kelurahan": "Belo",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Maluk",
                "kelurahan": "Benete",
                "kodepos": "84456"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Tepas Sepakat",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Sermong",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Tamekan",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Telaga Bertong",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Seminar Salit",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Seloto",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Sampir",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Mujahiddin",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Mura",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Rarak Ronges",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Moteng",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Mataiyang",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Menala",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Manemeng",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Lampok",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Lamuntet",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Labuan Lalar",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Lalang Liang (Lalar Liang)",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Kertasari (Labuan Kertasari)",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Kuang",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Brang Ene",
                "kelurahan": "Kalimantong",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Dalam",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Bugis",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Banjar",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Batu Putih",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Taliwang",
                "kelurahan": "Arab Kenangan",
                "kodepos": "84455"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Tebo",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Tuananga",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Tapir",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Tambak Sari",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Seteluk Tengah",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Seteluk Atas",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Seran",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Senayan",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Rempe",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Poto Tano",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Meraran",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Mantar",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Lamusung",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Kiantar",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Poto Tano",
                "kelurahan": "Kokarlian",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Kelanir",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Air Suning",
                "kodepos": "84454"
            },
            {
                "kecamatan": "Sateluk (Seteluk)",
                "kelurahan": "Desaloka",
                "kodepos": "84454"
            }
        ],
        "k263": [
            {
                "kecamatan": "Empang",
                "kelurahan": "Pamanto",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Tolo Oi",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Ongko",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Mata",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Lamenta",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Labuhan Jambu",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Labuhan Pidang",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Jotang Beru",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Labuhan Aji",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Labuhan Bontong",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Empang Bawah",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Gapit",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Jotang",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Bunga Eja",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Empang Atas",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Bantulante",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Empang",
                "kelurahan": "Boal",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Tarano",
                "kelurahan": "Banda",
                "kodepos": "84384"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Teluk Santong",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Usar",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Labangka",
                "kelurahan": "Suka Damai",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Labangka",
                "kelurahan": "Suka Mulya",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Sp II Prode/Perode",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Sp III Prode/Perode",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Maronge",
                "kelurahan": "Simu",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Sp I Prode/Perode",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Sepayung",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Selante",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Sepakat",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Plampang",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Labangka",
                "kelurahan": "Sekokat",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Muer",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Maronge",
                "kelurahan": "Maronge",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Maronge",
                "kelurahan": "Pemasar",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Maronge",
                "kelurahan": "Labuhan Sangoro",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Labangka",
                "kelurahan": "Jaya Makmur",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Labangka",
                "kelurahan": "Labangka",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Plampang",
                "kelurahan": "Brang Kolong",
                "kodepos": "84383"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Pungkit",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Tatede",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Mama",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Lopok Beru",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Lopok",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lape (Lape Lopok)",
                "kelurahan": "Lape",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Langam",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lape (Lape Lopok)",
                "kelurahan": "Hijrah",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lape (Lape Lopok)",
                "kelurahan": "Labuan Kuris",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lape (Lape Lopok)",
                "kelurahan": "Dete",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Lopok",
                "kelurahan": "Berora",
                "kodepos": "84382"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Songkar",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Serading",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Sebewe",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Poto",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Penyaring",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Pungkit",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Ngeru",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Olat Rawa",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Labuhan Ijuk",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Moyo",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Moyo Mekar",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Kakiang",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Kukin",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Batu Bangka",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Hilir",
                "kelurahan": "Berare",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Moyo Utara",
                "kelurahan": "Baru Tahan",
                "kodepos": "84381"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Suka Maju",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Orong Telu",
                "kelurahan": "Senawang",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Pada Suka",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Perung",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Orong Telu",
                "kelurahan": "Sebeok",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Lunyuk Ode",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Lunyuk Rea",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Orong Telu",
                "kelurahan": "Mungkin",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Jamu",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Orong Telu",
                "kelurahan": "Kelawis",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lunyuk",
                "kelurahan": "Emang Lestari",
                "kodepos": "84373"
            },
            {
                "kecamatan": "Lenangguar",
                "kelurahan": "Tatebal",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lenangguar",
                "kelurahan": "Telaga",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lantung",
                "kelurahan": "Sepukur",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Ropang",
                "kelurahan": "Ropang",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lantung",
                "kelurahan": "Padesa",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Ropang",
                "kelurahan": "Ranan",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lenangguar",
                "kelurahan": "Ledang",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lenangguar",
                "kelurahan": "Lenangguar",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Ropang",
                "kelurahan": "Lebin",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Ropang",
                "kelurahan": "Lawin",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Ropang",
                "kelurahan": "Lebangkar",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lantung",
                "kelurahan": "Ai Mual",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Lantung",
                "kelurahan": "Lantung",
                "kodepos": "84372"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Semamung",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Sempe",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Sebasang",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Pernek",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Marga Karya",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Mokong",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Leseng",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Lito",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Maman",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Batutering",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Berang Rea",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Moyo Hulu",
                "kelurahan": "Batubulan",
                "kodepos": "84371"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Kelungkung",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Tangkampulit (Tangkan Pulit)",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Tepal",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Batudulang",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Baturotok",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Batulanteh",
                "kelurahan": "Bao Desa",
                "kodepos": "84361"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Usar Mapin",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Tarusa",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Pulau Bungin",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Pulau Kaung",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Mapin Rea",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Marenteh",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Mapin Baru",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Mapin Kebak",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Luar",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Labuhan Burung",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Lekong",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Labuan Mapin",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Labuhan Alas",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Kalabeso",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Kalimango",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Gontar Baru",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Juranalas",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Juru Mapin",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas Barat",
                "kelurahan": "Gontar",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Dalam",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Buer",
                "kelurahan": "Buin Baru",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Alas",
                "kelurahan": "Baru",
                "kodepos": "84353"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Stowe Brang",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Tengah",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Rhee",
                "kelurahan": "Rhee Loka",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Rhee",
                "kelurahan": "Sampe",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Sebedo (Sabedo)",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Rhee",
                "kelurahan": "Rhee",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Pukat",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Motong",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Orong Bawa",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Rhee",
                "kelurahan": "Luk",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Labuan Bajo",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Bale Brang",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Utan",
                "kelurahan": "Jorok",
                "kodepos": "84352"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Uma Sima",
                "kodepos": "84317"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Brang Biji",
                "kodepos": "84318"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Sebotok",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Uma Beringin",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Samapuin",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Pungka",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Pelat",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Labuhan Badas",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Nijang",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Labuan Aji",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Labuan Sumbawa",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Kerato",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Kerekeh",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Bugis Medang",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Karang Dima",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Jorok",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Unter Iwes (Unterwiris)",
                "kelurahan": "Boak",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Labuhan Badas",
                "kelurahan": "Bajo Medang",
                "kodepos": "84316"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Brang Bara",
                "kodepos": "84314"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Pekat",
                "kodepos": "84315"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Lempeh",
                "kodepos": "84312"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Bugis",
                "kodepos": "84313"
            },
            {
                "kecamatan": "Sumbawa",
                "kelurahan": "Seketeng",
                "kodepos": "84311"
            }
        ],
        "k264": [
            {
                "kecamatan": "Pajo",
                "kelurahan": "Ranggo",
                "kodepos": "84272"
            },
            {
                "kecamatan": "Pajo",
                "kelurahan": "Woko",
                "kodepos": "84272"
            },
            {
                "kecamatan": "Pajo",
                "kelurahan": "Lepadi",
                "kodepos": "84272"
            },
            {
                "kecamatan": "Pajo",
                "kelurahan": "Lune",
                "kodepos": "84272"
            },
            {
                "kecamatan": "Pajo",
                "kelurahan": "Jambu",
                "kodepos": "84272"
            },
            {
                "kecamatan": "Hu'u",
                "kelurahan": "Daha",
                "kodepos": "84271"
            },
            {
                "kecamatan": "Hu'u",
                "kelurahan": "Hu&#8217;u",
                "kodepos": "84271"
            },
            {
                "kecamatan": "Hu'u",
                "kelurahan": "Rasabou",
                "kodepos": "84271"
            },
            {
                "kecamatan": "Hu'u",
                "kelurahan": "Adu",
                "kodepos": "84271"
            },
            {
                "kecamatan": "Hu'u",
                "kelurahan": "Cempi Jaya",
                "kodepos": "84271"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Tolo Kalo",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Tambora",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Ta&#8217;a",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Sorinomo",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Soro",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Peti",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Songgaja (Songgajah)",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Nangamiro",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Pekat",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Kempo",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Konte",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Kempo",
                "kelurahan": "Doro Kobo",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Kadindi",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Pekat",
                "kelurahan": "Beringin Jaya",
                "kodepos": "84261"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Soriutu",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Suka Damai",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Nangatumpu",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Nusa Jaya",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Kwangko",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Lanci Jaya",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Banggo",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Menggelewa (Manggelewa)",
                "kelurahan": "Doromelo",
                "kodepos": "84253"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Mbuju",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Taropo",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Lasi",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Malaju",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Kiwu",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Kilo",
                "kelurahan": "Kramat 2 (Keramat)",
                "kodepos": "84252"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Saneo",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Wawonduru",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Mumbu",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Nowa",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Riwo",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Madaprama",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Matua",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Montabaru",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Bara",
                "kodepos": "84251"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Sorisakolo",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Mbawi",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Menggeasi (Mangge Asi)",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Kareke",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Katua",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Mangge Nae",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Kandai Satu",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Karamabura",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Dorebara",
                "kodepos": "84219"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Kandai Dua",
                "kodepos": "84218"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Karijawa",
                "kodepos": "84217"
            },
            {
                "kecamatan": "Woja",
                "kelurahan": "Simpasai",
                "kodepos": "84216"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Bali 1",
                "kodepos": "84213"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Potu",
                "kodepos": "84214"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Dorotangga",
                "kodepos": "84212"
            },
            {
                "kecamatan": "Dompu",
                "kelurahan": "Bada",
                "kodepos": "84211"
            }
        ],
        "k265": [
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Taloko",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Oi Saro",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Piong",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Sandue",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Oi Panihi",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Labuhan Kananga",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Oi Bura",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Kore",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Kawinda Nae",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Tambora",
                "kelurahan": "Kawinda Toi",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Tanah Putih",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sanggar",
                "kelurahan": "Boro",
                "kodepos": "84191"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Soro",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Sumi",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Simpasai",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Sangia",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Sari",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Rato",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Raioi",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Rasabou",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Parangina",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Poja",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Nggelu",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Naru Barat",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Nae",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Naru",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Lauta Barat",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Mangge",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Melayu",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Lanta",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Lamere (Lamera)",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Lambu",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Kaleo",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Kowo",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Lambu",
                "kelurahan": "Hidirasa",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Jia",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Bugis",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Buncu",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Bajopulau",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Sape",
                "kelurahan": "Boke",
                "kodepos": "84182"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Waworada",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Lambitu",
                "kelurahan": "Teta",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Wadu Ruka",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Lambitu",
                "kelurahan": "Sambori",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Rupe",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Tarlawi",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Rompo",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Riamau (Rianmau)",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Ntori",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Pesa",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Raba",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Laju",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Maria",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Maria Utara",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Lambitu",
                "kelurahan": "Kuta",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Kawuwu",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Koboro",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Kombo",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Lambitu",
                "kelurahan": "Kaowa",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Karampi",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Karumbu",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Kambilo",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Kangga",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Kalodu",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Lambitu",
                "kelurahan": "Kaboro (Kabaro)",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Dumu",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Langgudu",
                "kelurahan": "Doro O&#8217;o",
                "kodepos": "84181"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Tonggorisa",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Tonggorisa",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Soki",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Teke",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Roka",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Runggu",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Renda",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Roi",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Panda",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Panda",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Ngali",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Ntonggu",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Lido",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Ncera",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Nata",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Doie",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Dore",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Belo",
                "kelurahan": "Cenggu",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Palibelo",
                "kelurahan": "Belo",
                "kodepos": "84173"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Tolouwi",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Tangga Baru",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Willamaci",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Tolotangga",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Simpasai",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Sondo",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Tangga",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Sekuru",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Sie",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Parado",
                "kelurahan": "Paradowane",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Pela",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Parado",
                "kelurahan": "Paradorato",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Monta",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Parado",
                "kelurahan": "Kuta",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Parado",
                "kelurahan": "Lere",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Parado",
                "kelurahan": "Kanca",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Waduwani",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Monta",
                "kelurahan": "Baralau",
                "kodepos": "84172"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Tenga",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Tente",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Samili",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Talabiu",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Rabakodo",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Risa",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Pandai",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Penapali",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Naru",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Nisa",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Kalampa",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Keli",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Donggobolo",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Woha",
                "kelurahan": "Dadibou",
                "kodepos": "84171"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Sai",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Sampungu",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Wadukopa",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Punti",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Rora",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Mpili",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "O&#8217;o",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Palama",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Kananta",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Kala",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Mbawa",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Bumi Pajo",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Donggo",
                "kelurahan": "Doridungga",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Soromandi",
                "kelurahan": "Bajo",
                "kodepos": "84162"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Tumpu",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Timu",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Sanolo",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Sondosia",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Tambe",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Rato",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Rada",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Rasabou",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Leu",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Nggembe",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Bontokape",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Bolo",
                "kelurahan": "Kananga",
                "kodepos": "84161"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Tolowata",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Rite",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Talapiti",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Mawu",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Nipa",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Wora",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Ambalawi",
                "kelurahan": "Kole",
                "kodepos": "84153"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Tadewa",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Tawali",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Pai",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Sangiang",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Nunggi",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Oitui",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Hidirasa (Hadirasa)",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Nanga Wera",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Ntoke",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Wera",
                "kelurahan": "Bala",
                "kodepos": "84152"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Sambinae",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Santi",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rite",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rontu",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Panggi",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Penanae",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Pane",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Oi Fo&#8217;o",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Nitu",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Ntobo",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Nungga",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Asakota",
                "kelurahan": "Melayu",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Matakando",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Mande",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Manggemaci",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Lewirato",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Lampe",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Lelamase",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Asakota",
                "kelurahan": "Kolo",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Kodo",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Asakota",
                "kelurahan": "Jatiwangi",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Kendo",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Asakota",
                "kelurahan": "Jatibaru",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Dodu",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Dara (Dora)",
                "kodepos": "84119"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Sarae",
                "kodepos": "84117"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Tanjung",
                "kodepos": "84118"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Nae",
                "kodepos": "84116"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Penatoi",
                "kodepos": "84115"
            },
            {
                "kecamatan": "Rasanae Timur",
                "kelurahan": "Kumbe",
                "kodepos": "84114"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Penaraga",
                "kodepos": "84115"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rabangodu Utara",
                "kodepos": "84113"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rabadompu Timur",
                "kodepos": "84113"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rabangodu Selatan",
                "kodepos": "84113"
            },
            {
                "kecamatan": "Raba",
                "kelurahan": "Rabadompu Barat",
                "kodepos": "84113"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Woro",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Sadia",
                "kodepos": "84112"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Tonda",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Rasanae Barat",
                "kelurahan": "Paruga",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Rade",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Mpuri",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Ndano",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Mpunda",
                "kelurahan": "Monggonao",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Mandawau",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Monggo",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Dena",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Bolo",
                "kodepos": "84111"
            },
            {
                "kecamatan": "Madapangga",
                "kelurahan": "Campa",
                "kodepos": "84111"
            }
        ],
        "k266": [
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Wakan",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Tanjung Luar",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Sukadamai",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Sukaraja",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Setungkep Lingsar",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Seriwe",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Sepapan",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Senyiur",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Sepit",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Sekaroh",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Selebung Ketangga",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Pijot Utara",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Pulau Maringkik",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Pijot",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Pemongkong",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Pene",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Pandan Wangi",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Pare Mas",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Montong Belae",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Kwang Rundun",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Mendana Raya",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Ketangga Jeraeng",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Ketapang Raya",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Ekas Buana",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Keruak",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Jerowaru",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Batunampar Selatan",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Dane Rase",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Jerowaru",
                "kelurahan": "Batunampar",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Keruak",
                "kelurahan": "Batu Putik",
                "kodepos": "83672"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Tanak Kaken",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Suwangi Timur",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Surabaya Utara",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Sukarara",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Suangi",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Surabaya",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Songak",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Sakra",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Sakra Selatan",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Rumbuk",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Rumbuk Timur",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Rensing Bat",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Rensing Raya",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Rensing",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Peresak",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Pematung",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Pengkelakmas",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Pejaring",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Moyot",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Montong Beter",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Montongtangi",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Mengkuru",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Lepak Timur",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Menceh",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Lepak",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Keselet",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Kuang Baru",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Lenting",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Kembang Are Sampai",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Jerogunung",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra",
                "kelurahan": "Kabar",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Gunung Rajak",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Gerisak Semanggelen",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Gelanggang",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Gereneng",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Timur",
                "kelurahan": "Gereneng Timur",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Bungtiang",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Gadungmas",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Borok Toyang",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Sakra Barat",
                "kelurahan": "Boyemare",
                "kodepos": "83671"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Suradadi",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Terara",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Sukadana",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Selagik",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Rarang Tengah",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Santong",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Rarang",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Rarang Batas",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Rarang Selatan",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Pringgajurang Utara",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Pringga Jurang",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Perian",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Pesanggrahan",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Montong Betok",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Pandan Duri",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Lendang Belo",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Leming",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Kilang",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Lando",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Montong Gading",
                "kelurahan": "Jenggik Utara",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Kalianyar",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Embung Raja",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Jenggik",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Tetebatu Selatan",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Terara",
                "kelurahan": "Embung Kandong",
                "kodepos": "83663"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Sikur Selatan",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Tetebatu",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Sikur",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Sikur Barat",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Montongbaan",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Semaya",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Montong Baan Selatan",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Kembang Kuning",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Kotaraja",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Loyok",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Gelora",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Jeruk Manis",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Sikur",
                "kelurahan": "Darmasari",
                "kodepos": "83662"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Rempung",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Timbanuh",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Pringgasela Selatan",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Pringgasela Timur",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Pengadangan",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Pengadangan Barat",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Pringgasela",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Masbagik Utara Baru",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Paok Motong",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Masbagik Timur",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Masbagik Utara",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Lendang Nangka Utara",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Masbagik Selatan",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Kumbang",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Lendang Nangka",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Kesik",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Jurit Baru",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Masbagik",
                "kelurahan": "Danger",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Jurit",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Pringgasela",
                "kelurahan": "Aik Dewa",
                "kodepos": "83661"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Sugian",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Senanggalih",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Sembalun Bumbung",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Sembalun Lawang",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Sembalun Timba Gading",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Sembalun",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Sambelia",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Sajang",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Madayin",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Obel Obel",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Padak Guar",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Labuhan Pandan",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Darakunci",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sembalun",
                "kelurahan": "Bilok Petung",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Dadap",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Belanting",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Telaga Waru",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Sambalia (Sambelia)",
                "kelurahan": "Bagik Manis",
                "kodepos": "83656"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Tanak Gadang",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Teko",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Suntalangu",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Seruni Mumbul",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Suela (Suwela)",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Puncak Jeringo",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Sapit",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Selaparang",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Pohgading Timur",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Pringgabaya",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Pringgabaya Utara",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Perigi",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Ketangga",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Pohgading",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Suela (Suwela)",
                "kelurahan": "Mekar Sari",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Labuhan Lombok",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Gunung Malang",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Kerumut",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Bagik Papan",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Batuyang",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Anggaraksa",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Pringgabaya",
                "kelurahan": "Apitaik",
                "kodepos": "83654"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Wanasaba Daya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Wanasaba Lauk",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Toya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Wanasaba",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Sukarema",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Otak Rarangan",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Tembeng Putik",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Mamben Daya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Mamben Lauk/Lauq",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Ramban Biak",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Mamben Baru",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Lauq",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Pesiraman",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Daya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Duren",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Kali Bambang",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Lenek Baru",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kembang Kerang Daya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Keroya",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kembang Kerang",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Karang Baru Timur",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Karang Baru",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kalijaga Tengah",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kalijaga Timur",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kalijaga Baru",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kalijaga Selatan",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Kalijaga",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Beriri Jarak",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Jineng",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Bandok",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Wanasaba",
                "kelurahan": "Bebidas",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Aikmel Timur",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Aikmel Utara",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Bagik Nyaka Santri",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Aikmel",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Aikmel Barat",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Aikmel",
                "kelurahan": "Aik Prapa",
                "kodepos": "83653"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Tumbuh Mulia",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Waringin",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Suralaga",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Tebaban",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Sukamulia",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Sukamulia Timur",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Paok Pampang",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Setanggor",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Setanggor Selatan",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Paok Lombok",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Padamara",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Kerongkong",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Nyiur Tebel",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Gapuk",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Gerung Permai",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Jantuk",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Sukamulia",
                "kelurahan": "Dasan Lekong",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Dames Damai",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Dasan Borok",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Bagik Payung Timur",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Bintang Rinjani",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Bagik Payung",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Bagik Payung Selatan",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Suralaga",
                "kelurahan": "Anjani",
                "kodepos": "83652"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Rakam",
                "kodepos": "83619"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Selong Kota",
                "kodepos": "83619"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Kelayu Utara",
                "kodepos": "83619"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Majidi",
                "kodepos": "83619"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Denggen",
                "kodepos": "83618"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Kembang Sari",
                "kodepos": "83618"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Danggen Timur",
                "kodepos": "83618"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Penedagandor",
                "kodepos": "83617"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Jorong",
                "kodepos": "83617"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Kertasari",
                "kodepos": "83617"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Labuhan Haji",
                "kodepos": "83616"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Surya Wangi",
                "kodepos": "83616"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Korleko Selatan",
                "kodepos": "83615"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Tirtanadi",
                "kodepos": "83615"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Geres",
                "kodepos": "83615"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Ijobalit",
                "kodepos": "83615"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Korleko",
                "kodepos": "83615"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Teros",
                "kodepos": "83614"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Tanjung",
                "kodepos": "83614"
            },
            {
                "kecamatan": "Labuhan Haji",
                "kelurahan": "Banjarsari",
                "kodepos": "83614"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Sandubaya",
                "kodepos": "83612"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Kelayu Selatan",
                "kodepos": "83613"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Pancor",
                "kodepos": "83611"
            },
            {
                "kecamatan": "Selong",
                "kelurahan": "Sekarteja",
                "kodepos": "83611"
            }
        ],
        "k267": [
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Sasake",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Pejanggik",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Pengadang",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Kelebuh",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Lajut",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Batunyala",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Beraim",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Jurang Jaler",
                "kodepos": "83582"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Semoyang",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Sengkerang",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Sukaraja",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Landah",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Marong",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Mujur",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Ganti",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Kidang",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Bilelando",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Tumpak",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Praya Timur",
                "kelurahan": "Beleka",
                "kodepos": "83581"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Tanak Awu",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Teruwai",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Segale Anyar",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Sengkol",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Sukadana",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Prabu",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Rembitan",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Pengembur",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Pengengat",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Kuta (Kute)",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Mertak",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Kawo",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Ketara",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Pujut",
                "kelurahan": "Gapura",
                "kodepos": "83573"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Setanggor",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Penujak",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Selong Belanak",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Mangkung",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Mekar Sari",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Bonder (Bondir)",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Kateng",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Banyu Urip",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat",
                "kelurahan": "Batujai",
                "kodepos": "83572"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Ungga",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Ranggagata",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Serage",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Pandan Indah",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Pelambik (Plambik)",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Kabul (Kabol)",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Montong Ajan",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Montong Sapah",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Batu Jangkih",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Praya Barat Daya",
                "kelurahan": "Darek",
                "kodepos": "83571"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Sintung",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Pringgarata",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Sepakek",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Murbaya",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Pemepek",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Bagu",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Pringgarata",
                "kelurahan": "Bilebante",
                "kodepos": "83562"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Sukarara",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Ubung",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Pengenjek",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Perina",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Puyung",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Labulia",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Labulia",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Gemel",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Jelantik",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Bonjeruk",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Bunkate",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Barejulat",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Jonggat",
                "kelurahan": "Batutulis",
                "kodepos": "83561"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Saba",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Selebung Rembiga",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Loangmaka",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Pendem",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Langko",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Lekor",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Janapria",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Kerembong",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Durian",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Janapria",
                "kelurahan": "Bakan",
                "kodepos": "83554"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Waja Geseng",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Montong Gamang",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Muncan",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Kopang Rembiga",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Lendang Ara",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Monggas",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Darmaji",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Dasan Baru",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Kopang",
                "kelurahan": "Bebuak",
                "kodepos": "83553"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Tanak Beak",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Teratak",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Tampak Siring",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Setiling",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Peresak",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Selebung",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Pagutan",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Mas-Mas",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Mantang",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Lantan",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Karang Sidemen",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Bujak",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Barabali",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Beber",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang",
                "kelurahan": "Aik Darek (Aiq Dareq)",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Aik Berik",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Batukliang Utara",
                "kelurahan": "Aik Bukak (Bukaq)",
                "kodepos": "83552"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Gonjak",
                "kodepos": "83522"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Gerantung",
                "kodepos": "83523"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Renteng",
                "kodepos": "83521"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Tiwugalih",
                "kodepos": "83518"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Prapen",
                "kodepos": "83519"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Leneng",
                "kodepos": "83515"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Panjisari",
                "kodepos": "83516"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Gerunung",
                "kodepos": "83514"
            },
            {
                "kecamatan": "Praya Tengah",
                "kelurahan": "Jontlak",
                "kodepos": "83513"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Semayan",
                "kodepos": "83512"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Mertak Tombok",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Montong Terep",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Praya",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Bunut Baok",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Jago",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Praya",
                "kelurahan": "Aik Mual",
                "kodepos": "83511"
            }
        ],
        "k268": [
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Ampenan Utara",
                "kodepos": "83511"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cakranegara Utara",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Sayang Sayang",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cakranegara Selatan Baru",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cakranegara Timur",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cakranegara Barat",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cakranegara Selatan",
                "kodepos": "83239"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Mayura",
                "kodepos": "83238"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Sapta Marga",
                "kodepos": "83238"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Cilinaya",
                "kodepos": "83238"
            },
            {
                "kecamatan": "Cakranegara",
                "kelurahan": "Karang Taliwang",
                "kodepos": "83238"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Turida",
                "kodepos": "83237"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Mandalika",
                "kodepos": "83237"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Selagalas",
                "kodepos": "83237"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Bertais",
                "kodepos": "83236"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Abian Tubuh Baru",
                "kodepos": "83237"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Babakan",
                "kodepos": "83233"
            },
            {
                "kecamatan": "Sandubaya (Sandujaya)",
                "kelurahan": "Dasan Cermen",
                "kodepos": "83232"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pejanggik",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Punia",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagutan Barat",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagutan Timur",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagesangan Barat",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagesangan Timur",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Mataram Barat",
                "kodepos": "83126"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagesangan",
                "kodepos": "83127"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Dasan Agung Baru",
                "kodepos": "83125"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Gomong",
                "kodepos": "83126"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Rembiga",
                "kodepos": "83124"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Dasan Agung",
                "kodepos": "83125"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Monjok Timur",
                "kodepos": "83122"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Karang Baru",
                "kodepos": "83123"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Monjok",
                "kodepos": "83122"
            },
            {
                "kecamatan": "Selaparang (Selaprang)",
                "kelurahan": "Monjok Barat",
                "kodepos": "83122"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Mataram Timur",
                "kodepos": "83121"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Pajarakan Karya",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Tamansari",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Doyan Peken",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Kebunsari",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Banjar",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Bintaro",
                "kodepos": "83118"
            },
            {
                "kecamatan": "Mataram",
                "kelurahan": "Pagutan",
                "kodepos": "83117"
            },
            {
                "kecamatan": "Sekarbela",
                "kelurahan": "Kekalik Jaya",
                "kodepos": "83116"
            },
            {
                "kecamatan": "Sekarbela",
                "kelurahan": "Tanjung Karang Permai",
                "kodepos": "83116"
            },
            {
                "kecamatan": "Sekarbela",
                "kelurahan": "Jempang Baru",
                "kodepos": "83116"
            },
            {
                "kecamatan": "Sekarbela",
                "kelurahan": "Karang Pule",
                "kodepos": "83116"
            },
            {
                "kecamatan": "Sekarbela",
                "kelurahan": "Tanjung Karang",
                "kodepos": "83115"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Pejeruk",
                "kodepos": "83113"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Ampenan Selatan",
                "kodepos": "83114"
            },
            {
                "kecamatan": "Ampenan",
                "kelurahan": "Ampenan Tengah",
                "kodepos": "83112"
            }
        ],
        "k269": [
            {
                "kecamatan": "Narmada",
                "kelurahan": "Suranadi",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Tanah/Tanak Beak",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Sesaot",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Sigerongan",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Selat",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Sembung",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Sedau",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Saribaye",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Peru (Keru)",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Peteluan Indah",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Peresak",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Nyur Lembang",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Pakuan",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Mekarsari",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Narmada",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Lingsar",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Lebah Sempaga",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Lembuak",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Gontoran",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Karang Bayan",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Langko",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Krama Jaya",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Golong",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Gegerung",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Giri Madia",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Gerimak Indah",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Gegelang",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Dasan Tereng",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Duman",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Buwun Sejati",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Dasan Geria",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Batu Mekar",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Bug-Bug",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Batu Kuta",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Lingsar",
                "kelurahan": "Batu Kumbung",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Sekotong Tengah",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Narmada",
                "kelurahan": "Badrain",
                "kodepos": "83371"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Pelangan",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Sekotong Barat",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Gili Gede Indah",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Kedaro",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Buwun Mas",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Cendi Manik",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Sekotong Timur",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Sekotong",
                "kelurahan": "Batu Putih",
                "kodepos": "83365"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Mareje",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Mareje Timur",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Labuan Tereng",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Lembar",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Lembar Selatan",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Jembatan Gantung",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Lembar",
                "kelurahan": "Jembatan Kembar",
                "kodepos": "83364"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Tempos",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Sukamakmur",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Taman Ayu",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Kebunayu",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Mesanggok",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Gerung Utara",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Giri Tembesi",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Gapuk",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Gerung Selatan",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Dasan Geres",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Dasan Tapen",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Banyu Urip",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Beleke",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Gerung",
                "kelurahan": "Babussalam",
                "kodepos": "83363"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Ombe Baru",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Rumak",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Lelede",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Montong Are",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kuripan Timur",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kuripan Utara",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kuripan",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Kuripan Selatan",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Kediri Selatan",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Jagaraga Indah",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Kediri",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Giri Sasak",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kuripan",
                "kelurahan": "Jagaraga",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Dasan Baru",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Gelogor",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Kediri",
                "kelurahan": "Banyumulek",
                "kodepos": "83362"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Terong Tawah",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Telagawaru",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Merembu",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Perampuan",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Kuranji Dalang",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Labuapi",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Bengkel",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Karang Bongkot",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Kuranji",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Bagik Polak Barat",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Bajur",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Labuapi",
                "kelurahan": "Bagik Polak",
                "kodepos": "83361"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Senggigi",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Senteluk",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Pusuk Lestari",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Sandik",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Meninting",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Lembah Sari",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Batu Layar",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Batu Layar",
                "kelurahan": "Bengkaung",
                "kodepos": "83355"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Taman Sari",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Sesela",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Ranjok",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Nambalan (Mambalan)",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Penimbung",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Midang",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Kekait",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Kekeri",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Mekarsari",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Jatisela",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Jeringgo",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Gunung Sari",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Guntur Macan",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Dopang",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Gelangsar",
                "kodepos": "83351"
            },
            {
                "kecamatan": "Gunungsari",
                "kelurahan": "Bukittinggi",
                "kodepos": "83351"
            }
        ],
        "k270": [
            {
                "kecamatan": "Bayan",
                "kelurahan": "Senaru",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Sukadana",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Loloan",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Mumbul Sari",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Sambik Elen",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Bayan",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Karang Bajo",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Anyar",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Bayan",
                "kelurahan": "Akar Akar",
                "kodepos": "83354"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Selengen",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Sesait",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Gangga",
                "kelurahan": "Sambik Bangkol",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Santong",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Gangga",
                "kelurahan": "Rempek",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Salut",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Kayangan",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Pendua",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Gangga",
                "kelurahan": "Gondang",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Gumantar",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Kayangan",
                "kelurahan": "Dangiang",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Gangga",
                "kelurahan": "Genggelang",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Gangga",
                "kelurahan": "Bentek",
                "kodepos": "83353"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tegal Maja",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Teniga",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Tanjung",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Pemenang",
                "kelurahan": "Pemenang Timur",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sigar Penjalin (Pinjalin)",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Sokong",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Pemenang",
                "kelurahan": "Pemenang Barat",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Medana",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Pemenang",
                "kelurahan": "Malaka",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Tanjung",
                "kelurahan": "Jenggala",
                "kodepos": "83352"
            },
            {
                "kecamatan": "Pemenang",
                "kelurahan": "Gili Indah",
                "kodepos": "83352"
            }
        ],
        "k271": [
            {
                "kecamatan": "Loli",
                "kelurahan": "Wee Dabo",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Wee Karou",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Ubu Pede",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Ubu Raya",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Tana Rara",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Tema Tana",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Manola",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Soba Wawi",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Doka Kaka",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Loda Pare",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Dira Tana",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Dede Kadu",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Bali Ledo",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Loli",
                "kelurahan": "Bera Dolu",
                "kodepos": "87284"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Tara Manu",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Wai Hura",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Wei Mangoma",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Rua",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Prai Bakul",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Rewa Rara",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Pari Rara",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Pahola",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Katiku Loku",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Mamodu",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Hoba Wawi",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Hupu Mada",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Ana Wolu",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Wanokaka",
                "kelurahan": "Bali Loku",
                "kodepos": "87272"
            },
            {
                "kecamatan": "Lamboya Barat",
                "kelurahan": "Wee Tana",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Watu Karere",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Wailibo (Welibo)",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Sodan (Sodana)",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Rajak (Rajaka)",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Ringu Rara",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Patiala Bawa",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya Barat",
                "kelurahan": "Patiala Dete",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Laboya Dete",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Pala Moko",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Kabu Karudi",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Laboya Bawa",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya Barat",
                "kelurahan": "Gaura",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya Barat",
                "kelurahan": "Harona Kala",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Lamboya",
                "kelurahan": "Bodo Hula",
                "kodepos": "87271"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Zala Kadu",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Wanokaza",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Wee Patola",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Ngadu Pada",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Tarona",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Malata",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Manu Kuku",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Manu Mada Pemekaran",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Lolo Tana",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Lolo Wano",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Lingu Lango",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Loko Ri (Ry)",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Kareka Nduku Selatan",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Kareka Nduku Utara",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Kalebu Ana Kaka",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Kareka Nduku",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Bondo Tera",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Tana Righu",
                "kelurahan": "Elu Loda",
                "kodepos": "87257"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Soba Rade",
                "kodepos": "87217"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Maliti",
                "kodepos": "87217"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Pada Eweta",
                "kodepos": "87217"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Kalembu Kuni",
                "kodepos": "87217"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Lapale",
                "kodepos": "87217"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Kodaka",
                "kodepos": "87215"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Tebara",
                "kodepos": "87216"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Kampung Baru",
                "kodepos": "87214"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Puu Mawo",
                "kodepos": "87212"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Wailiang",
                "kodepos": "87212"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Kampung Sawah",
                "kodepos": "87213"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Komerda",
                "kodepos": "87211"
            },
            {
                "kecamatan": "Kota Waikabubak",
                "kelurahan": "Modu Waimaringu",
                "kodepos": "87212"
            }
        ],
        "k272": [
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Tana Mbanas Selatan",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Weluk Prai Memang",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Tana Mbanas",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Tana Mbanas Barat",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Prai Karoku Jangga",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Soru",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Ngadu Olu",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Padira Tana",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Mbilur Pangadu",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Ngadu Bolu",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Maradesa Selatan",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Maradesa Timur",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Lenang Selatan",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Mara Desa",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Bulu Bokat Utara",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Lenang",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Bolubokat Barat",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay",
                "kelurahan": "Bulu Bokat (Bolu Bokat)",
                "kodepos": "87283"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Wangga Waiyengu I",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Waimanu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Wangga Wainyengu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Wai Rasa",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Wailawa",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Umbu Riri",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Pabal Selatan",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Mamijuk",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Pabal",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Kawolu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Langgang",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Umbu Jodu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Tana Modu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Prai Madeta",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Sambali Loku",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Pondok",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Oka Wacu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Mata Waikajawi",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Mata Redi",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Mata Woga",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Makata Keri",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Malinjak",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Manurara",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Maderi",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Konda Maloba",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Holur Kambata",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Kabela Wuntu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Elu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Dewa Tana",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana Selatan",
                "kelurahan": "Dameka",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Dewa Jara",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Daha Elu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Anapalu",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Katikutana",
                "kelurahan": "Anakalang",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Umbu Ratu Nggay Barat",
                "kelurahan": "Anajiaka",
                "kodepos": "87282"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Wendewa Timur",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Wendewa Utara",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Wendewa Barat",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Wendewa Selatan",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Watu Asa",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Wee Luri",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Ole Dewa",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Susu Wendewa",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Cendana Barat",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Manu Wolu",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Ole Ate",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Bondo Sulla",
                "kodepos": "87258"
            },
            {
                "kecamatan": "Mamboro",
                "kelurahan": "Cendana",
                "kodepos": "87258"
            }
        ],
        "k273": [
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Weri Lolo",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Umbu Wangu",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Milla Ate",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Rita Baru",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Tena Teke",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Denduka",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Mandungo",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Buru Kaghu",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Delo",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Bondo Ukka",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Buru Deilo",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Wewewa Selatan",
                "kelurahan": "Bondo Bela",
                "kodepos": "87263"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Wainyapu",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Waipaddi",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Walla Ndimu",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Waimahaka",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Waimaringi",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Wailangira",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Waikarara",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Waikadada",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Waikaninyo",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Umbu Ngedo",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Waiha",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Rada Loko",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Tana Mete",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Rada Loko",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Panenggo Ede",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Mata Kapore",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Mere Kehe",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Lete Loko",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Manu Toghi",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Balaghar",
                "kelurahan": "Kahale",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Karang Indah",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Ana Gogka",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Delu Depa",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi Bangedo",
                "kelurahan": "Dinjo",
                "kodepos": "87262"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Wura Homba",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Watu Wona",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Waitaru",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Wee Wella",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Wailabubur",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Tanjung Karoso",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Waiholo",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Pero Batang",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Pero Konda",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Onggol",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Mangganipi",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Noha",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Mali Iha",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Magho Linyo",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Koki",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Kori",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Kawango Hari",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Kapaka Madeta",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Kadu Eta",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Kalena Rongo",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Kandu Wela",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Homba Rande",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Kadaghu Tana",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Homba Karipit",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Homba Pare",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Hoha Wungo",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Hamonggo Lele",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Hameli Ate",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Bila Cenge",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi Utara",
                "kelurahan": "Bukambero",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Bondo Kodi",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kodi",
                "kelurahan": "Ate Dalo",
                "kodepos": "87261"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Wee Rena",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Watu Kawula",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Wee Londa",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Wee Pangali",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Rada Mata",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Waitabula",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Kalena Wano",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Langga Lero",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Kadi Pada",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Kota Tambolaka",
                "kelurahan": "Kalembu Kaha",
                "kodepos": "87255"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Wee Kambala",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Wee Mananda",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Rama Dana",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Totok",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Loko Kalada",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Payola Umbu",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Pogo Tena",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Karuni",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Lete Konda",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Wee Kura",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Loura (Laura)",
                "kelurahan": "Bondo Boghila",
                "kodepos": "87254"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Watu Labara",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Wee Kombaka",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Tawo Rara",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Waimangura",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Wali Ate",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Raba Ege",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Reda Pada",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Lua Koba",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Marokota",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Menne Ate",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Laga Lete",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Kalembu Kanaika",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Kalembu Weri",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Kalimbu Tilu",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Kabali Dana",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Barat",
                "kelurahan": "Kalaki Kambe",
                "kodepos": "87253"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Wee Rame",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Wee Patando",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Wee Paboba",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Wee Limbu",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Wee Namba",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Wee Lima",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Wee Kokora",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Wano Talla",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Tema Tana",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Tanggaba",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Puu Potto",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Pada Eweta",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Omba Rade",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Mata Pyawu",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Mataloko",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Mareda Kalada",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Utara",
                "kelurahan": "Mali Mada",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Lombu",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Lete Komouna",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Kanelu",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Kalembu Ndara Mane",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Eka Pata",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Kalingara",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Dangga Mangu",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Timur",
                "kelurahan": "Dikira",
                "kodepos": "87252"
            },
            {
                "kecamatan": "Wewewa Tengah (Wewera Tengah)",
                "kelurahan": "Bolora",
                "kodepos": "87252"
            }
        ],
        "k274": [
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Tanamanang",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Wulla (Wula)",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Tamma",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Paranda",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Pamburu",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Mburukulu",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Palanggai",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Lumbu Manggit",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Lambakara",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Lainjanji",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Laipandak",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "La Tena",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Kuruwaki",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Pahunga Lodu",
                "kelurahan": "Kaliuda",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Wula Waijelu",
                "kelurahan": "Hadakamali",
                "kodepos": "87182"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Watukuda (Watu Puda)",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Watu Hadang",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Umalulu",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Wanga",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Tanaraing",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Rindi",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Tamburi",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Ngaru Kanoru",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Patawang",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Mutunggeding",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Lumbukore",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Matawai Atu",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Umalulu",
                "kelurahan": "Lairuru",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Kayuri",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Kayuri",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Hanggaroru",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Kabaru",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Rindi",
                "kelurahan": "Haikatapu (Heikatapu)",
                "kodepos": "87181"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Tandula Jangga",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Praisalura",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Ngadu Ngala",
                "kelurahan": "Prauraming",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Nggongi",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Praimadita",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Ngadu Ngala",
                "kelurahan": "Prai Witu",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Nangga",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Ngadu Ngala",
                "kelurahan": "Kabanda",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Ngadu Ngala",
                "kelurahan": "Kakaha",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Jangga Mangu",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Karera",
                "kelurahan": "Ananjaki",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Ngadu Ngala",
                "kelurahan": "Hambautang (Hamba Wutang)",
                "kodepos": "87172"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Watumbaka",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Winumuru",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Wangga Meti",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "Wairara",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Waibidi (Waimbidi)",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "Praikalala",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Prai Mbana",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Prai Bokul/Bakul",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Palakahembi",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "Patamawai",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Meurumba (Meorumba)",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Pabera Tanera/Manera",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Mau Ramba",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Maubokul",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Matawai Katingga",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Matawai Maringu",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Maidang",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Marada Mundi",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Mahu Bokul",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Mahangwatu (Mehang Mata)",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Laitaku",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "Lujundilu",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Lai Meta",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Laindeha",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Lai Mbonga",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Kotak Kawau",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "La Hiru",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Kawangu",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Katiku Wai",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Katiku Tana",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Karipi",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Matawai Lappau (La Pawu)",
                "kelurahan": "Katiku Luku",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Kataka",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Karera Jangga",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Paberiwai",
                "kelurahan": "Kananggar",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Kambata Tana",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Kamanggih",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Kahaunguweti (Kahaungu Eti)",
                "kelurahan": "Kambata Bundung",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Pandawai",
                "kelurahan": "Kadumbul",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Wudi Pandak",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Mahu",
                "kelurahan": "Haray (Harai)",
                "kodepos": "87171"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Waikanabu",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Wangga Mbewa",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Tawui",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Tawui",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Tapil",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Tarimbang",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Praing Kareha",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Ramuk",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Pindu Horomi (Hurani)",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Mahaniwa",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Pinupahar (Pirapahar)",
                "kelurahan": "Lailunggi",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Billa",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Karita",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Kuki Talu",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Tabundung",
                "kelurahan": "Bangga Watu",
                "kodepos": "87161"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Wunga",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Kanatang",
                "kelurahan": "Temu",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Rambangaru",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Praibakul",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Kanatang",
                "kelurahan": "Mondu",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Kanatang",
                "kelurahan": "Ndapayami",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Napu",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Kanatang",
                "kelurahan": "Kuta",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Mbata Puhu",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Kadahang",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Haharu",
                "kelurahan": "Kalamba",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Kanatang",
                "kelurahan": "Hamba Praing",
                "kodepos": "87153"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "Watumbelar",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "Umamanu",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Tanatuku",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Tandula Jangga",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Tanarara",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Prai Paha",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Pulu Panjang",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Rakawatu",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Katala Hamu Lingu",
                "kelurahan": "Prai Bakul",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Prai Karang",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "Mondu Lambi",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Ngadulangi",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Matawai Pawali",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Katala Hamu Lingu",
                "kelurahan": "Matawai Amahu",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Makamenggit",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Katala Hamu Lingu",
                "kelurahan": "Mandahu",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Lewapaku",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "Laihawu",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Kondamara",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Katala Hamu Lingu",
                "kelurahan": "Lai Lara",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Katala Hamu Lingu",
                "kelurahan": "Kompa Pari (Kombapari)",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Kambu Hapang",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "Kangeli",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Nggaha Oriangu",
                "kelurahan": "Kahiri",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Kambata Wundut",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa Tidahu",
                "kelurahan": "BidiPraing",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Lewa",
                "kelurahan": "Bidi Hunga",
                "kodepos": "87152"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Wangga",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Mbata Kapidu",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Pambola Njara (Pambotanjara)",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Mauliru",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Matawai",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Mauhau",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Lukukamaru",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Malumbi",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kambata Mapambuhang",
                "kelurahan": "Luku Wingir (Wikir)",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Kambajawa",
                "kodepos": "87116"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Kiri Tana (Kiritana)",
                "kodepos": "87114"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Lambanapu",
                "kodepos": "87115"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Prailiu",
                "kodepos": "87113"
            },
            {
                "kecamatan": "Kambera",
                "kelurahan": "Kambaniru",
                "kodepos": "87114"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Kamalaputi",
                "kodepos": "87111"
            },
            {
                "kecamatan": "Kota Waingapu",
                "kelurahan": "Hambala",
                "kodepos": "87112"
            }
        ],
        "k275": [
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Watu Wangka",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Wae Lolos",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Wae Sano",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Tiwi Riwung",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Wae Jare",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Sano Nggoang",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Tandong/Tondong Belang",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Mata Wae",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Nampar Macing",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Liang Ndara",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Golo Tantong",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Kempo",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Sengang",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Golo Sembea",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Ndaring",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Golo Ndoal",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Manting",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Mbu",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Kempo",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Sano Nggoang",
                "kelurahan": "Golo Leleng",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Golo Damu",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Golo Desat",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Cunca Lolos",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Mbeliling",
                "kelurahan": "Cunca Wulang",
                "kodepos": "86757"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Wontong",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Raba",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Rego",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Rokap",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Nggilat",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Pacar",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Mbakung",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Nanga Kantor",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Loha",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Loha",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Compang",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Golo Lajang",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Macang Pacar",
                "kelurahan": "Bari",
                "kodepos": "86756"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Warloka",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Watu Nggelek",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Wae Kelambu",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Tanjung Boleng",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Tiwu Nampar",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Pota Wangka",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Sepang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Pontianak (Tanjung Pontianak)",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Pasir Putih",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Papa Garang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Pasir Panjang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Mbuit",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Nggorang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Labuan Bajo",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Macang Tanggar",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Gorontalo",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Komodo",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Golo Pongkor",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Golo Sepang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Golo Mori",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Golo Lujang",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Batu Tiga",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Golo Bilas",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Boleng",
                "kelurahan": "Golo Ketak",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Komodo",
                "kelurahan": "Batu Cermin",
                "kodepos": "86754"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Watu Waja",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Watu Umpu",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Wae Wako",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Wae Mose",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Wae Bangka",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Wae Kanta",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Tangge",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Surunumbeng",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Semang",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Siru",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Repi",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Ponto Ara",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Rehak",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Pong Majok",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Pong Welak",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Poco Rutang",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Pondo",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Orong",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Pengka",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Nanga Lili",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor",
                "kelurahan": "Ngancar",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Munting",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Nanga Bere",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Lalong",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Lale",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Kakor",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Gurung",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Galang",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Golo Ronggot",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Welak",
                "kelurahan": "Dunta",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Benteng Dewa",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Lembor Selatan",
                "kelurahan": "Benteng Dewa",
                "kodepos": "86753"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Wae Buka",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Waning",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Tueng",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Tentang",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Sama",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Tengku",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Raka",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Ranggu",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Pong Narang",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Ndoso",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Pangga",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Momol",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Nantal",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Lumut",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Lewur",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Kolang",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Lawi",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Kasong",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Golo Ruu",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Golo Wedong",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Golo Riwu",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Ndoso",
                "kelurahan": "Golo Poleng",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Compang Suka",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Golo Lewe",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Coal",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Compang Kules",
                "kodepos": "86752"
            },
            {
                "kecamatan": "Kuwus",
                "kelurahan": "Benteng Curu",
                "kodepos": "86752"
            }
        ],
        "k276": [
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Tubung Walang",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Umaleu",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Rumang",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Tobotani",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Mampir",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Panama",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Roho",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Leuwohung",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Loyobohor",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Kaohua",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Leuburi",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Buriwutung",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Kalikur",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Kalikur WL",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Bean",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Benihading",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Benihading II",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Atulaleng",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Bareng",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Wowong",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Buyasuri (Buyasari)",
                "kelurahan": "Atu Wa&#8217;lupang",
                "kodepos": "86692"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Wailolong",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Walangsawah",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Normal I",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Peusawah",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Roma",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Meluwiting",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Nilanapo",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Normal",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Mahal",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Mahal II",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Leudanung",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Leuwayang",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Hoelea II",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Lebewala",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Leubatang",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Hingalamamengi",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Hoelea",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Dolulolong",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Balauring",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Omesuri",
                "kelurahan": "Aramengi",
                "kodepos": "86691"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Tapobali",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Tubuk Rajan",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Wulandoni",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Puor",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Puor B",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Pantai Harapan",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Posiwatu",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Nuba Atalojo",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Nubaboli",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Nubahaeraka",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Nogo Doni",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Leworaja",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Lusilame",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Lerek",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Lelata",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Lewogroma",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Lebaata",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Lamalera B",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Katakeja",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Lamalera A",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Ile Kerbau",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Ile Kimok",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Imulolong",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Dori Pewut",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Dulir",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Atadei",
                "kelurahan": "Atakore",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Belobad (Belobao)",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Ataili",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Atakera",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Wulandoni (Wulandioni)",
                "kelurahan": "Alap Atadei",
                "kodepos": "86685"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Penikene",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Penikene",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Tewaowutung",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Lusiduawutun",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Pasir Putih",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Labalimut",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Lolong",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Boli Bean",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Duawutun",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Ile Boli",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Baobolak",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Belabaja",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Babokerong",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Nagawutung",
                "kelurahan": "Atawai",
                "kodepos": "86684"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Watodiri",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Waimatan",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Waowala",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Todanara",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Tanjung Batu",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Plilolon",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Tagawiti",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Napasabok",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Petuntawa",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Muruona",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Lamawara",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Lamawolo",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Laranwuntun",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Lamagute",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Lamatokan",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Lamaau",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Kolontobo",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Kolipadan",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Jontona",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Dulitukan",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Beutaran",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Bungamuda",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Aulesa",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape Timur",
                "kelurahan": "Bao Lali Duli",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Ile Ape",
                "kelurahan": "Amakaka",
                "kodepos": "86683"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Waijarang",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Watokobu",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Selandoro",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Udak Melomata",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Pada",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Paubokol (Paobokol)",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba Selatan",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Nuba Mado",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Belobatang",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Baolangu",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Waienga",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Bakalerek",
                "kodepos": "86682"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Tapobaran",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Tapolangu",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Merdeka",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Seranggorang",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lewoeleng",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lodotodokowa",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lamalela",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lamatuka",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lerahinga",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Hadakewa",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Lamadale",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Belorebong (Balurebong)",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Dikesare",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Baopama (Baopana)",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Banitobo",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Lebatukan",
                "kelurahan": "Atakowa",
                "kodepos": "86681"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lite Ulumado",
                "kodepos": "86616"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba Timur",
                "kodepos": "86616"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba Tengah",
                "kodepos": "86615"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba Barat",
                "kodepos": "86613"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba Utara",
                "kodepos": "86612"
            },
            {
                "kecamatan": "Nubatukan",
                "kelurahan": "Lewoleba",
                "kodepos": "86611"
            }
        ],
        "k277": [
            {
                "kecamatan": "Reok",
                "kelurahan": "Watu Baur",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Watu Tango",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Wangkung",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Torong Koe",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Wae Kajong",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Toe",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Sambi",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Rura",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Salama",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Reo",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Robek",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Ruis",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Nggalak",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Para Lando",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Lemarang",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Loce",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Mata Air",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok Barat",
                "kelurahan": "Lante",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Baru",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Reok",
                "kelurahan": "Bajak",
                "kodepos": "86592"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Wae Rii",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Welu",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Wudi",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Wae Renca",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Wae Mulu",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Timbu",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Wae Codi",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Satar Ngkeling",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Riung",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Ranggi",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Rado",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Ranaka/Renaka",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Poco",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Perak",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Pinggang",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Nenu",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Pagal",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Longko",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Ndehes",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Lenda",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Langkas",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Latung",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Ladur",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Lalong",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Lando",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Kentol",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Golo Wua (Golo Wuas)",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Golo Nejang",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Golo Watu/Wutu",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Golo Woi",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Golo Ncuang",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Golo Mendo",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Golo Lanak",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Golo Cador",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Gapong",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Golo",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Compang Ndehes",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Compang Cibal",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Bea Mese",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Bere",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal",
                "kelurahan": "Barang",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Bangka Kenda",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Wae Rii",
                "kelurahan": "Bangkajong",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Cibal Barat",
                "kelurahan": "Bangka Ara",
                "kodepos": "86591"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Wewo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Wongka",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Wae Ajang",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Ulu Belang",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Tado",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Umung",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Satar Ruwuk",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Todo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Satar Loung",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Tal",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Satar Luju",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Terong",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Ruang",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Satar Lenda",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Popo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Renda",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Ponggeok",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Pongkor",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Paka",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Papang",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Nuca Molas",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Ngkaer",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Lia",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Nao",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Legu",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Mocok",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Kole",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Mata Wae",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Langgo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Lungar",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Jaong",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Ling",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Koak",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Lolang",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Hilihintir",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Iteng",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Gulung",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Golo Ropong",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Golo Lambo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Golo Muntas",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese",
                "kelurahan": "Gara",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Cireng",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Ceka Luju",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Cambir Leca",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Bea Kondo",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Satar Mese Barat",
                "kelurahan": "Borik",
                "kodepos": "86561"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Tenda",
                "kodepos": "86518"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Waso",
                "kodepos": "86517"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Carep",
                "kodepos": "86519"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Wae Mantang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Urang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Wae Belang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Tengku Lese",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Pong Ompu",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Pong Murung",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Rai",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Pong Leko",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Pong Lengor",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Pong Lao",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Poco Liking",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Pong Lale",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Ndiwar",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Pau",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Meler",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Ketang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Nati",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Kakor",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Manong",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Lawir",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Lentang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Golo Worok",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Liang Bua",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Golo Langkok",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Gelong",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Dimpong",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Cumbi",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Compang Namut",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Compang Dari",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Compang Dalo",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Buar",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Bulan",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Beokakor",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Bangka Ruang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Benteng Tubi",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Bangka Lao",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Beo Rahong",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Bangka Dese",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Belang Turi",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Bangka Ajang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Ruteng",
                "kelurahan": "Benteng Kuwu",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Golo Dukal",
                "kodepos": "86515"
            },
            {
                "kecamatan": "Rahong Utara",
                "kelurahan": "Bangka Ruang",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Wali",
                "kodepos": "86514"
            },
            {
                "kecamatan": "Lelak",
                "kelurahan": "Bangka Lelak",
                "kodepos": "86516"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Pitak",
                "kodepos": "86513"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Watu",
                "kodepos": "86511"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Karot",
                "kodepos": "86512"
            },
            {
                "kecamatan": "Langke Rembong",
                "kelurahan": "Mbau Muku",
                "kodepos": "86511"
            }
        ],
        "k278": [
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Wea",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Wela Lada",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Satar Nawang",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Ulung Baras",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Pota",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Rana Mese",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Nanga Mbaling",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Nanga Mbaur",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Lada Mese",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Nampar Sepang",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Nanga Baras",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Kembang Mekar",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Golo Wangkung Utara",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Golo Wangkung",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Golo Wangkung Barat",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Golo Ngawan",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Golo Pari",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Buti",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Compang Congkar",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Sambi Rampas",
                "kelurahan": "Compang Lawi",
                "kodepos": "86584"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Wejang Wae",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Watu Lanur",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Wejang Mali",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Ulu Wae",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Urung Dora",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Watu Arus",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Tango Molas",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Tango Molas",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Satar Tesem",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Rengkam",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Poco Lia",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Pocong",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Rende Nao",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Nggalak Leleng",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Ngkiong Dora",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Mando Sawu",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Melo",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Leong",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Lento",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Golo Wune",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Gurung Turi",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Lenang",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Golo Nderu",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Golo Rengket",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Golo Ndari",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Golo Lero",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Golo Lobos",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Deno",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Compang Wunis",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Compang Weluk",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Compang Wesang",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Compang Raci",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Colol",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Compang Laho",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Benteng Rampas",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Benteng Wunis",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Bangka Pau",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Bea Waek",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Bangka Kuleng",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka",
                "kelurahan": "Bangka Leleng",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Arus",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Poco Ranaka Timur",
                "kelurahan": "Bangka Arus",
                "kodepos": "86583"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Tengkulawar",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Satar Punda",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Satar Punda Barat",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Tengku Leda",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Nampar Tabang",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Satar Kampas",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Satar Padut",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Keli",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Lencur",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Liang Deruk",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Goreng Meni",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Goreng Meni Utara",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Haju Wangi",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Rentung",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Wontong",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Munga Barat",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Nimbung",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Paleng",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Lembur",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Mangung",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Golo Munga",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Compang Mekar",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Compang Necak",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Lamba Leda",
                "kelurahan": "Compang Deru",
                "kodepos": "86582"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Teno Mese",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Tiwu Kondo",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Wae Rasan",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Sisir",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Sangan Kalo",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Sipi",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Rana Gapang",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Rana Kulan",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Nanga Meje",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Nanga Puun",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Paan Waru",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Lempang Paji",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Mosi Ngaran",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Lengko Namut",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Langgasai",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Legur Lai",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Langga Sai",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Haju Ngendong",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Golo Wuas",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Golo Wuas",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Golo Linus",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Golo Munde",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Golo Lebo",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Golo Lijun",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Golo Linus",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Gising",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Gising",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar",
                "kelurahan": "Biting",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Elar Selatan",
                "kelurahan": "Benteng Pau",
                "kodepos": "86581"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Ruan",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Tanah Rata",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Watu Nggene",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Rana Mbeling",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Rongga Koe",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Pong Ruan",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Rana Kolong",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Rana Mbata",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Pari",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Paan Leleng",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Mokel Morid",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Mbengan",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Mokel",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Lembur",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Gunung Mute",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Komba",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Golomeni",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Gunung Baru",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Golo Tolang",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Golo Ndele",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Golo Nderu",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Kota Komba",
                "kelurahan": "Bamo",
                "kodepos": "86572"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Watu Mori",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Torok Golo",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Wae Nggori",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Sita",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Satar Lahing",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Satar Lenda",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Sano Lokom/Lokon",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Sano Lokom/Lokon",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Rondo Woing",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Rondo Woing",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Rana Loba",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Rana Masak",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Ngampang Mas",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Poco Rii",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Nanga Labang",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Lalang",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Lidi",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Gurung Liwut",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Kota Ndora",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Golo Rutuk",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Rutuk",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Golo Ros",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Ros",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Golo Meleng",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Meleng",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Golo Loni",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Lalong",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Loni",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Golo Kantar",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Compang Loni",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Compang Teber",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Compang Kempo",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Compang Kantar",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Benteng Raja",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Benteng Riwu",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Bea Ngencung",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Borong",
                "kelurahan": "Bea Ngencung",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Bangka Kempo",
                "kodepos": "86571"
            },
            {
                "kecamatan": "Rana Mese",
                "kelurahan": "Bangka Masa",
                "kodepos": "86571"
            }
        ],
        "k279": [
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Wajomara",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Waekokak",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Wolowae",
                "kelurahan": "Totomala",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Towak",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Tonggu Rambang",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Wolowae",
                "kelurahan": "Tenda Kinde",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Wolowae",
                "kelurahan": "Tendatoto",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Tengatiba",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Renduwawo",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Tedakisa",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Tedamude",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Rendut Tutubhada",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Rendutenoe",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Rendu Butowe",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Olaia",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Nggolonio",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Nggolo Mbay",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Nangadhero",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Ngegedhawe",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Wolowae",
                "kelurahan": "Natatoto",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Mbay I",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Mbay II",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Lape",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Marapokot",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa Selatan",
                "kelurahan": "Langedhawe",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Dhawe",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Labolewa",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Danga",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Wolowae",
                "kelurahan": "Anakoli",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Aesesa",
                "kelurahan": "Aeramo",
                "kodepos": "86472"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Wokodekororo",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Wokowoe",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Woedoa",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Woewutu",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Wajo Timur",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Witurombaua",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Ulupulu I",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Utetoto",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Wajo",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Ulupulu",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Udiworowatu",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Tonggo",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Podenura",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Riti",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Paumali",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Pautola",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Pagomogo",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Ngera",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Nangaroro",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Nataute",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Mbaenuamuri",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Lewangera",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Ladolima Barat",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Ladolima Timur",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Ladolima",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Kotakeo Satu",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Kotawuji Barat",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Kotawuji Timur",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Kotakeo",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Kotakeo Dua",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Kotadirumali",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Kodaute",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Keo Tengah",
                "kelurahan": "Keli",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Degalea",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Nangaroro",
                "kelurahan": "Bidoa",
                "kodepos": "86464"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Wuliwalo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Wololelu",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Wolotelu",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Wolokisa",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Woloede",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Woewolo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Ua",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Ululoga",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Selalejo Timur",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Sawu",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Selalejo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Maukeli",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Mau Ponggo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Lokalaba",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Lajawajo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Lodaolo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Kotagana",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Jawapogo",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Keliwatulewa",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Bela",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Mauponggo",
                "kelurahan": "Aewoe",
                "kodepos": "86463"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Wolowea Timur",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Wolopogo",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Wolowea",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Wolowea Barat",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Solo",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Wea Au",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Rega",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Rigi",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Rowa",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Raja Selatan",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Raja Timur",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Ratongamobo",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Olakile",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Raja",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Natanage",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Natanage Timur",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Nagerawe",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Nagesapadhi",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Nageoga",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Kelimado",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Leguderu",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Mulakoli",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Focolodorawe",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Gerodhere",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Kelewae",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Dhereisa",
                "kodepos": "86462"
            },
            {
                "kecamatan": "Boawae",
                "kelurahan": "Alorawe",
                "kodepos": "86462"
            }
        ],
        "k280": [
            {
                "kecamatan": "Golewa",
                "kelurahan": "Were IV",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Were I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Were II",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Were III",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Were",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Watusipi",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Wae Ia",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Watunay",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Wagowela",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Turekisa",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Ulubelu",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Takatunga",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Todabelu",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Sobo I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Sobo",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Sarasedu",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Sarasedu I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Sangadeto",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Sadha",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Ratogesa",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Rakateda II",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Rakateda I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Rakalaba",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Radamasa",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Radabata",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Nirmala",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Mataloko",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Mangulewa",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Malanuza I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Kezewea",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Malanuza",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Dizi gedha",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Eko Roka",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa",
                "kelurahan": "Dadawea",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Barat",
                "kelurahan": "Bea Pawe",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Boba",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Boba I",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Golewa Selatan",
                "kelurahan": "Bawarani",
                "kodepos": "86461"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Watumanu",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Warupele I",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Warupele II",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Waesae",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Waebela",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Tiworiwu I",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Tiworiwu II",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Tiwurana",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Tiworiwu",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Sebowuli",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Nio Lewa",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Paupaga",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Naruwolo II",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Nenowea",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Naruwolo",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Naruwolo I",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Lekogoko / Lekogoku",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Manubhara",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Manubhara",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Legelapu",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Legariwu",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Kila",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Kelitei / Kelitey",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Keligejo",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Inerie",
                "kelurahan": "Inerie",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "F O A (Foa)",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Heawea",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Dariwali",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Binawali",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Jerebuu",
                "kelurahan": "Batajawa",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Aimere",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Aimere",
                "kelurahan": "Aimere Timur",
                "kodepos": "86452"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Wue",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Wolomeze I",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Wolomese Dua",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Wolomeze",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Wangka",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Wangka Selatan",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Turaloa",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Waepana",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Tarawaja",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Tarawali",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Taen Terong",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Taen Terong Dua",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Taen Terong Satu",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Tadho",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Seso",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Sambinasi",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Sambinasi Barat",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Ria",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Ria I",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Rawangkalo",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Piga I",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Nginamanu Selatan",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Piga",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Nginamanu",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Nginamanu Barat",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Nangamese",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Ngabheo",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Ngara",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Meli Waru",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Mengeruda",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Masu",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Masu Kedhi",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Loa",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Mainai",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Libunio",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Lengkosambi Barat",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Lengkosambi Timur",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Lengkosambi Utara",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Lengkosambi",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Latung",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Lanamai",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Lanamai I",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Faobata",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Denatana",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Wolomeze (Riung Selatan)",
                "kelurahan": "Denatana Timur",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Soa",
                "kelurahan": "Bogoboa",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung",
                "kelurahan": "Benteng Tengah",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Benteng Tawa I",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Riung Barat",
                "kelurahan": "Benteng Tawa",
                "kodepos": "86419"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Langagedha",
                "kodepos": "86418"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Lebijaga",
                "kodepos": "86418"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Susu",
                "kodepos": "86417"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Jawameze",
                "kodepos": "86416"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Ngedukelu",
                "kodepos": "86415"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Trikora",
                "kodepos": "86414"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Wololika",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Wawowae",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Watukapu",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Uluwae II",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Waewea",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Uluwae",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Uluwae I",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Ubedolumolo",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Ubedolumolo Satu",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Tura Muri",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Pape",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Naru (Natu)",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Ngoranale",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Inelika",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Nabelena",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Inegena",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa Utara",
                "kelurahan": "Genamere",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Borani",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Bowali",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Bomari",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Bela",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Beiwali",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Beja",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Bajawa",
                "kodepos": "86413"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Kisanata",
                "kodepos": "86411"
            },
            {
                "kecamatan": "Bajawa",
                "kelurahan": "Tanalodu",
                "kodepos": "86412"
            }
        ],
        "k281": [
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Wolojita",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Tenda",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Wiwipemo",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Nuamulu",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Pora",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Woloau",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Wolojita",
                "kelurahan": "Nggela",
                "kodepos": "86382"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Uludala",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Watukamba",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Ranakolo",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Ranokolo Selatan",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Ngalukoja",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Niranusa",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Otogedu",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Maurole",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Mausambi",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Keliwumbu",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Aewora",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Maurole",
                "kelurahan": "Detuwala",
                "kodepos": "86381"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Lise Kuru",
                "kodepos": "86379"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Rutu Jeja",
                "kodepos": "86377"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Ndikosapu",
                "kodepos": "86378"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Nggumbelaka",
                "kodepos": "86376"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Wologai Timur",
                "kodepos": "86376"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Mukureku",
                "kodepos": "86376"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Mukureku Sa Ate",
                "kodepos": "86376"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Kuru",
                "kodepos": "86375"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Kuru Sare",
                "kodepos": "86375"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Ndengga Rongge",
                "kodepos": "86375"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Taniwoda",
                "kodepos": "86374"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Tiwusora",
                "kodepos": "86374"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Detuara",
                "kodepos": "86374"
            },
            {
                "kecamatan": "Lepembusu Kelisoke",
                "kelurahan": "Tanalangi",
                "kodepos": "86374"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Wonda",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Wolosoko",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Tana Lo&#8217;o",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Wolokoli",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Serandori",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Ratemangga",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Rindiwawo",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Niromesi",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Nualise",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Nakambara",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Mole",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Mbuliwaralau Utara",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Mbuliwaralau",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Mbuli Loo",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Maubasa Timur",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Maubasa",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Maubasa Barat",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Lise Pu&#8217;u",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Lisedetu",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Lungaria",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Lise Lowobora",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Likanaka",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Kelisamba",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Jopu",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Bokasape",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Wolowaru",
                "kelurahan": "Bokasape Timur",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Ndori",
                "kelurahan": "Aebara",
                "kodepos": "86372"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wolotolo",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wolotolo Tengah",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Wolomuku",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wolomage",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wolomasi",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wologai",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wologai Tengah",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Watunggore Marilonga",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Wolofeo",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Watunggere (Watunggera)",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Unggu",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Turunalu",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Saga",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Sipijena",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Ranga",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Rateroru",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Roa",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Nuaone",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Randoria",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Niowula",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Nida",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Nggesabiri",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Ndito",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Nggesa",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Kebesani",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Maurole Selatan",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Golulada",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Kanganara",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Detusoko Barat",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Dile",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detusoko",
                "kelurahan": "Detusoko",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Detumbewa",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Rorurangga (Rendo Ranga)",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Detukeli",
                "kelurahan": "Detukeli",
                "kodepos": "86371"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Rendo Raterua",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Renga Menge",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Puutara",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Redodori",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Paderape",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Kazo Kapo",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Ndoriwoi",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Wolotopo Timur",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Pulau Ende",
                "kelurahan": "Aejeti",
                "kodepos": "86362"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Wolosambi",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Wolotopo",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Woloaro/Woloara",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Wolokota",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Woloaro/Woloara",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Tiwutewa",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Watuneso",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Sokoria",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Tanaroga",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Reka",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Rewarangga Selatan",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Roga",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Puutuga",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Ranggatalo",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Nila",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Onelako",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Nua Lima",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Ngguwa",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Ngauroga/Ngaluroga",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Ngalupolo/Naglupolo",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Ngalupolo",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Ndungga",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Nanganesa",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Mbewawora",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Lokoboko",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Manulondo (Manulando)",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Kurulimbu Selatan",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Liabeke",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Kurulimbu",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Kelikiku",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Kedebodu",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona",
                "kelurahan": "Kekasewa",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Fatamari",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Hubatuwa/Hobatua",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Ndona Timur",
                "kelurahan": "Demulaka",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Detupera",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Lio Timur",
                "kelurahan": "Bu Tanalagu",
                "kodepos": "86361"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Wewaria",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Wolooja",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Tanali",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Waka",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Welamosa",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Ratewati Selatan",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Ratewati Selatan",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Nuangenda",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Numba",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Mbotulaka",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Mukusaki",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Mautenda",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Mautenda Barat",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Fataatu Timur",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Kelitembu",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Detubela",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Ekoae",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Fataatu",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Aelipo I",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Aemuri",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Ae Ndoko",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Wewaria",
                "kelurahan": "Aelipo",
                "kodepos": "86353"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Zozozea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Uzuzozo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Titwerea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Watumite",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Tendambepa",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Timbaria",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Tendarea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Tanazozo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Tenda Ondo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Rapowawo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Romarea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Sanggarhorho",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Penggajawa",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Raporendu",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Ondorea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Ondorea Barat",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Ndorurea I",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Nggorea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Ndeturea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Ndorurea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Nabe",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Natanangge",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Mundinggasa",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Mbobhenga",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Magekapa",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Malawaru",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kobaleba",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kolipaka",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Kerirea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Kekandere",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kebirangga Selatan",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kebirangga Tengah",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kamumbheka/Kamubheka",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Kebirangga",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Jemburea",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Jegharonggo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Maukaro",
                "kelurahan": "Boafeo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Embuzozo",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Bheramari",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Nangapanda",
                "kelurahan": "Anaraja",
                "kodepos": "86352"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Worhopapa",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Wologai Dua",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Wolokaro",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Wologai",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Wawonato",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Watusipi",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Wajakea Jaya",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Uzuramba Barat",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Uzuramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Tomberabu II",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Tonggopajoa",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Tomberabu 1",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Tendambonggi",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Tinabani",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ruku Ramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Roworena",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Roworena Barat",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Rewarangga",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Riaraja",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Rateru",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ranoramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Randotonda",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Randorama",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Peozakaramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Raburia",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ndetundora III",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Nemboramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Nuaja",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ndetundora I",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ndetundora II",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Mbotutenda",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Nakuramba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Kota Ratu",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Mbomba",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Tengah",
                "kelurahan": "Kelimutu",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Kota Raja",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Ja Moke Asa",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Jejaraja",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Gheoghoma",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Emburia",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Embutheru",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Embundoa",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende",
                "kelurahan": "Embu Ngena",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Woloara",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Ende Utara",
                "kelurahan": "Borokanda",
                "kodepos": "86319"
            },
            {
                "kecamatan": "Ende Tengah",
                "kelurahan": "Paupire",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Pemo",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Ende Tengah",
                "kelurahan": "Onekore",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Nuamuri Barat",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Nduaria",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Nuamuri",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Kelimutu",
                "kelurahan": "Koanara",
                "kodepos": "86318"
            },
            {
                "kecamatan": "Ende Selatan",
                "kelurahan": "Tetandara I",
                "kodepos": "86316"
            },
            {
                "kecamatan": "Ende Timur",
                "kelurahan": "Mautapaga",
                "kodepos": "86317"
            },
            {
                "kecamatan": "Ende Selatan",
                "kelurahan": "Paupanda",
                "kodepos": "86315"
            },
            {
                "kecamatan": "Ende Selatan",
                "kelurahan": "Tetandara",
                "kodepos": "86316"
            },
            {
                "kecamatan": "Ende Selatan",
                "kelurahan": "Mbongawani",
                "kodepos": "86313"
            },
            {
                "kecamatan": "Ende Selatan",
                "kelurahan": "Rukunlima",
                "kodepos": "86314"
            },
            {
                "kecamatan": "Ende Tengah",
                "kelurahan": "Potulando",
                "kodepos": "86312"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Tou Timur",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Tou",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Niopanda",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Rangalaka",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Ndondo",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Liselande",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Loboniki",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Kotabaru",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Kotabaru",
                "kelurahan": "Hangalande",
                "kodepos": "86111"
            }
        ],
        "k282": [
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Titehena",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Tanah Lein",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Sulengwaseng",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Ongalereng",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Pamakayo",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Ritaebang",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Lewotanah Ole",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Nusadani",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Lewonama",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Lemanu",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Lamaole",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Lamawalang (Lamawohong)",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Kenere",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Karawatung",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Kalike / Kelike",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Kalike / Kelike Aimatan",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Kalelu",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Balaweling II",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Dani Wato",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Wulublolong",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Barat",
                "kelurahan": "Balaweling I",
                "kodepos": "86272"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Watanhura II",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Watobuku",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Watohari",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Tanah Werang",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Watanhura",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Menanga",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Motonwutun",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Liwo",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lohayong I",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lohayong II",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lewohedo",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Lewograran",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lamawai",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lebao",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Lewogeka",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Kwuta (Kawuta)",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Timur",
                "kelurahan": "Labelen",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Solor Selatan",
                "kelurahan": "Bubu Atagamu",
                "kodepos": "86271"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Wolokibang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Wureh",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Watololong",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Wewit",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Waiwuring",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Weranggere",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Wai Tukan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Wato Baya",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Waiwadan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Watololong",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Tonuwotan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Tuwagoetobi",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Tikatukang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Tobitika",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Sukutokan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Nubalema",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Sandosi",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Nisanulan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Sagu",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Nisakarang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Riangduli",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Nimun Dani Bao",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Riangpadu",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Mangaaleng",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Redontena",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Muda",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Pledo",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Lite",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Pepak Kelu",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Lewopulo",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Oringbele",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Kenotan",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Lewopao",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Pajinian",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Keluwain",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Lamapaha",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Nubalema Dua",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Ilepati",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Lamaleka",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Oe Sayang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Hurung",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Lewobele",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Horinara",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Lamabunga",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Homa",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Lamahoda",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Horowura",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Lamablawa",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Hoko Horowura",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Kolipetung",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Hinga",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Kolilanang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Duanur (Duwanur)",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Kolimasang",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Bukit Seburi II",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Kokotobo",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Danibao",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Kima Kamak",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Bugalima",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Barat",
                "kelurahan": "Bukit Seburi I",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Bidara",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara Tengah",
                "kelurahan": "Baya",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Balaweling Noten",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Baobage",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Witihama (Watihama)",
                "kelurahan": "Balaweling",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Adonara",
                "kelurahan": "Adonara",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Kelubagolit (Klubagolit)",
                "kelurahan": "Adobala",
                "kodepos": "86262"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Wotanulumado",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Waiwerang Kota",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Watanpao",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Wailebe",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Waiburak",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Tobilota",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Tuawolo",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Tapobali",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Terong",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Samasoge",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Tanah Tukan",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Pandai",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Puhu",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Nayubaya",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Oyangbarang",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Narasaosina",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Lewobunga",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Libubaru",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Lamalata",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Lamatewelu",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Kwaelaga Lamawato",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Lamahala Jaya",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Klukengnuking",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Kiwang Ona",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Kawela",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Gelong",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Ipiebang",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Karinglamalouk",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Bilal",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Dawataa",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Adonara Timur",
                "kelurahan": "Belota",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Wotan Ulumado",
                "kelurahan": "Beliko (Bliko)",
                "kodepos": "86261"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Tuakepa",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Watowara",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Waiula",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Tenawahang",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Serinuho",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Riawale/Rianwale",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Riangbura",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Riang Rita",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Pululera",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Nurri",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Ojandetun",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Pantai Oa",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Nobokonga",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Nileknoheng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Nobo",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Nobo",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Nelelamawangi Dua",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Nelerereng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Neleblolong",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Nelelamadiken",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Nele Lamawangi",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lewopao",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Nawokote",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Lewoingu",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Lewolaga",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Lewoawang",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lewat",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lewo Keleng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Lewat",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Leraboleng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lebanuba",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lamabayung",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Lamawolo",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Kobasoma",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Konga",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Klatanlo",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Ile Gerong",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Hokeng Jaya",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Helanlangowuyo",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Hewa",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Dun Tana Lewoingu",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Harubala",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Duli Jaya",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Dulipali",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Duablolong",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Dokeng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Dokeng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Bungalawan",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Boru Kedang",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Boleng",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Bokang Wolomatang",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Boru",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Bedalewun",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Bura",
                "kelurahan": "Birawan",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Ile Boleng",
                "kelurahan": "Bayuntaa",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Wulanggitang",
                "kelurahan": "Bayuntaa",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Titehena",
                "kelurahan": "Adabang",
                "kodepos": "86253"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Sinar Hading",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Waibao",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Sina Hadigala",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Sinamalaka",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Riangkotek",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Ratu Lodong",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Painapang",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Patisirawalang",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Lewobunga",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Nusanipa",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Lewobelen",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Latonliwo Dua",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Lamatutu",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Laton Liwo",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Kolaka",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Lamanabi",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Bantala",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Ile Padung",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Gekengderan",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Lewolema",
                "kelurahan": "Balukhering",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Bandona",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Aransina",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Tanjung Bunga",
                "kelurahan": "Bahinga",
                "kodepos": "86252"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Weri",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Watotika Ile",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Sarotari Timur",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Waihali",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Sarotari",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Sarotari Tengah",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Puken Tobi Wangi Bao",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Mokantarak",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Pohon Bao",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Lewokluok",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Lewomuda",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Lamika",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Lamawalang",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Kawalelo",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Bama",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Demon Pagong",
                "kelurahan": "Blepanawa",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Amagarapati",
                "kodepos": "86219"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Ekasapta",
                "kodepos": "86217"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Lokea",
                "kodepos": "86215"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Postoh",
                "kodepos": "86216"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Lohayong",
                "kodepos": "86215"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Pohon Sirih",
                "kodepos": "86214"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Balela",
                "kodepos": "86214"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Waibalun",
                "kodepos": "86212"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Larantuka",
                "kodepos": "86213"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Lewolere",
                "kodepos": "86212"
            },
            {
                "kecamatan": "Larantuka",
                "kelurahan": "Pantai Besar",
                "kodepos": "86213"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Watotutu",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Tiwatobi",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Wailolong",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Mudakeputu",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Riang Kemie",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Halakodanuan",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Lewohala",
                "kodepos": "86211"
            },
            {
                "kecamatan": "Ile Mandiri",
                "kelurahan": "Lewoloba",
                "kodepos": "86211"
            }
        ],
        "k283": [
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Kota Baru",
                "kodepos": "86215"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Watudiran",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Werang",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Wailamung",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Wairbleler",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Wairterang",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Tua Bao",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Timutawa",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Tanarawa",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Talibura",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Pogon",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Pruda",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Runut",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Ojang",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Nebe",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Natarmage",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Nangatobong",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Nangahale",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waiblama",
                "kelurahan": "Ilinmedo",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Kringa",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Lewomada",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Hoder",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Hikong",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Egon",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Darat Pantai",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Baang Koor",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Talibura",
                "kelurahan": "Darat Gunung",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Waigete",
                "kelurahan": "Aibura",
                "kodepos": "86183"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Watumilok",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Wolomapa",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Watuliwung",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Wairkoja",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Waiara",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Tekaiku",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Umagera",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Tanaduen",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Seusina",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Rubit",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Namangkewa",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Mekendetung",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Munerana",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Kokowahor",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Langir",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Kopong",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Ian Tena",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Kajowair",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Habi",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Heopuat",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Hewokloang",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kewapante",
                "kelurahan": "Geliting",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Kangae",
                "kelurahan": "Blatatatin",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Baomekot",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Hewokloang",
                "kelurahan": "Baomekot",
                "kodepos": "86181"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Wolomotong",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Wolonterang",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Wolokoli",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Watumerak",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Wogalirit",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Umauta",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Waihawa",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Mapitara",
                "kelurahan": "Natakoli",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Nenbura",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Ipir",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Doreng",
                "kelurahan": "Kloangpopot",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Hokor",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Mapitara",
                "kelurahan": "Egon Gahar",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Mapitara",
                "kelurahan": "Hale",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Mapitara",
                "kelurahan": "Hebing",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Bola",
                "kodepos": "86171"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Sikka",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Watutedang",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Korowuwu",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Lela",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Iligai",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Kolidetung",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Hepang",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Du",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Lela",
                "kelurahan": "Baopaat",
                "kodepos": "86161"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Wolowiro",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Wolowona",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Wolorega",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Renggarasi",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Tuwa",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Paga",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Poma",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Mbengu",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Mauloo",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Loke",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Masebewa",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Detubinga",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Paga",
                "kelurahan": "Lenandareta",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Bu Utara",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Bu Watuweti",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Tana Wawo",
                "kelurahan": "Bu Selatan",
                "kodepos": "86153"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Wuliwutik",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Tebuk",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Tilang",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Takaplager",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Magepanda",
                "kelurahan": "Reroroja",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Riit",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Nita",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Nitakloang",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Nirangkliung",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Magepanda",
                "kelurahan": "Magepanda",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Mahebora",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Lusitada",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Ladogahar",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Magepanda",
                "kelurahan": "Kolisia B",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Magepanda",
                "kelurahan": "Kolisia",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Magepanda",
                "kelurahan": "Done",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Nita",
                "kelurahan": "Bloro",
                "kodepos": "86152"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Nangameting",
                "kodepos": "86118"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Waioti",
                "kodepos": "86118"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Nangalimang",
                "kodepos": "86118"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Watugong",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Samparong",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Ribang",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Pemana",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Paubekor",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Parumaan",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Nelle (Maumerei)",
                "kelurahan": "Nelle Wutung",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Nelle (Maumerei)",
                "kelurahan": "Nelle Urung",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Nelle (Maumerei)",
                "kelurahan": "Nelle Lorang",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Nelle (Maumerei)",
                "kelurahan": "Manubura",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Nelle (Maumerei)",
                "kelurahan": "Nelle Barat",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Lepolima",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Koting D",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Koting A",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Koting B",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Koting",
                "kelurahan": "Koting C",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Kojagete",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Gunung Sari",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Kojadoi",
                "kodepos": "86116"
            },
            {
                "kecamatan": "Alok Barat",
                "kelurahan": "Hewuli",
                "kodepos": "86115"
            },
            {
                "kecamatan": "Alok Barat",
                "kelurahan": "Wuring",
                "kodepos": "86115"
            },
            {
                "kecamatan": "Alok Barat",
                "kelurahan": "Wolomarang",
                "kodepos": "86114"
            },
            {
                "kecamatan": "Alok Barat",
                "kelurahan": "Wailiti",
                "kodepos": "86114"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Napu Gera",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Parabubu",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Wolodhesa",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Kowi",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Liakutu",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Kota Uneng",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Korobhera",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Dobo Nua Puu",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Gera",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Bhera",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Mego",
                "kelurahan": "Dobo",
                "kodepos": "86113"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Kabor",
                "kodepos": "86112"
            },
            {
                "kecamatan": "Alok",
                "kelurahan": "Madawat",
                "kodepos": "86112"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Wairotang",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Tuanggeo",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Tuanggeo",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Rokirole",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Reruwairere",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Nitunglea",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Maluriwu",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Lidi",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Kesokoja",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Palue",
                "kelurahan": "Ladolaka",
                "kodepos": "86111"
            },
            {
                "kecamatan": "Alok Timur",
                "kelurahan": "Beru",
                "kodepos": "86111"
            }
        ],
        "k284": [
            {
                "kecamatan": "Ndao Nuse",
                "kelurahan": "Nuse",
                "kodepos": "85983"
            },
            {
                "kecamatan": "Ndao Nuse",
                "kelurahan": "Mbiu Lombo",
                "kodepos": "85983"
            },
            {
                "kecamatan": "Ndao Nuse",
                "kelurahan": "Ndaonuse",
                "kodepos": "85983"
            },
            {
                "kecamatan": "Ndao Nuse",
                "kelurahan": "Anarae",
                "kodepos": "85983"
            },
            {
                "kecamatan": "Ndao Nuse",
                "kelurahan": "Mbali Lendeiki",
                "kodepos": "85983"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oeteffu",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Sedeoen",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oeseli",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oelasin",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Oenggaut",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oebatu",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oebou",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oehandi",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Oebaffok",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Nemberala",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Mbokak",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Meoain",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Lekik",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Lalukoen",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Landu",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Dolasi",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Bo&#8217;a (Boa)",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Daya",
                "kelurahan": "Batutua",
                "kodepos": "85982"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Tualima",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Temas",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Tolama",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Oenitas",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Oetutulu",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Oelua",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Oelolot",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Netenaen",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Oebela",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Modosinal",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat",
                "kelurahan": "Mbueain",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Ingguinak",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Lidor",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Busalangga",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Daudolu",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Barat Laut",
                "kelurahan": "Boni",
                "kodepos": "85981"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Serubeba",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Pukuafu",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Sotimori",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Matasio",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Mukekuku",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Lifuleo",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Londalusi",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Hundi Hopo",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Lakamola",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Rote Timur",
                "kelurahan": "Faifua",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Daeurendale",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Daiama",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Tunganamo",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Landu Leko",
                "kelurahan": "Bolatena",
                "kodepos": "85974"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Sonimanu",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Tesabela",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Oeledo",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Olafulihaa",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Lenupetu",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Nusakdale",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Oebau",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Edalode",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Keoen",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Pantai Baru",
                "kelurahan": "Batulilok",
                "kodepos": "85973"
            },
            {
                "kecamatan": "Rote Selatan",
                "kelurahan": "Tebole",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Onotali",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Suebela",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Maubesi",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Nggodimeda",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Lidamanu",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Limakoli",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Tengah",
                "kelurahan": "Lidabesi",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Selatan",
                "kelurahan": "Inaoe",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Selatan",
                "kelurahan": "Lenguselu",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Selatan",
                "kelurahan": "Daleholu",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Rote Selatan",
                "kelurahan": "Dodaek",
                "kodepos": "85972"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Suelain",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Namodale",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Oematamboli",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Kolobolon",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Kuli",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Metina",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Helebeik",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Holoama",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Oelunggu",
                "kodepos": "85917"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Bebalain",
                "kodepos": "85918"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Mokdale",
                "kodepos": "85914"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Sanggaoen",
                "kodepos": "85916"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Tuanatuk",
                "kodepos": "85913"
            },
            {
                "kecamatan": "Lobalain",
                "kelurahan": "Baadale",
                "kodepos": "85912"
            }
        ],
        "k285": [
            {
                "kecamatan": "Pantar",
                "kelurahan": "Wailawar",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Tubbe (Tube)",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Tude",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Treweng/Tereweng",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Toang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Piringsina",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Tamak",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Pandai",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Ombay",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Nule",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Muriabang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Munaseli",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Merdeka",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Mawar",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Mauta",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Marisa",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Madar",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Lekom",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Leer",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Lalafang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Lamma",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Kalondama Tengah",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Kayang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Kalondama",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Kalondama Barat",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Kaleb",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Kabir",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Kaera",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Helandohi",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Illu",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Delaki",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Eka Jaya",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Bouweli",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Bunga Bali",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Bukit Mas",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Blang Merang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Beangonong",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Baranusa",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Timur",
                "kelurahan": "Batu",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat",
                "kelurahan": "Baraler",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Baolang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Bana",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar",
                "kelurahan": "Bandar",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Bagang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Tengah",
                "kelurahan": "Aramaba",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Pantar Barat Laut",
                "kelurahan": "Allumang",
                "kodepos": "85881"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Taramana",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Waisika",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Tanglapui",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Tanglapui Timur",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Pido",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Pureman",
                "kelurahan": "Purnama",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Mausamang",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Padang Panjang",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Nailang",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Maritaing",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Maukuru",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Lippang",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Pureman",
                "kelurahan": "Langkuru Utara",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Pureman",
                "kelurahan": "Langkuru",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Kolana Utara",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Kenarimbala",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Kolana Selatan",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Kamot",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Pureman",
                "kelurahan": "Kailesa",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Elok",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur",
                "kelurahan": "Belemana",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Timur Laut",
                "kelurahan": "Air Mancur",
                "kodepos": "85872"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Welai Selatan",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Tulleng",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Waimi",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Tasi",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Tominuku",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Talwai",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Tamanapui",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Silaipui",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Subo",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Padang Alang",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Sidabui",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Petleng",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Manmas",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Manetwati",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Nurbenlelang",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Malaipea",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Maikang",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Luba",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Likwatang (Likuwatang)",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Lembur",
                "kelurahan": "Lembur Timur",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Lembur Barat",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Lembur Tengah",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Kuneman",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Lella",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Lakwati",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Kelaisi Timur",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Kiraman",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Kalaisi Tengah",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Selatan",
                "kelurahan": "Kelaisi Barat",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Fungafeng",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Kafakbeka",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Alimebung (Alim Mebung)",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Dapitau",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Tengah Utara",
                "kelurahan": "Fuisama",
                "kodepos": "85871"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wolwal Selatan",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wolwal Tengah",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wolwal",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wolwal Barat",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Tribur",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wakapsir",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Wakapsir Timur",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Taman Mataru",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Probur",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Probur Utara",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Pailelang",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Pintu Mas",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Morba",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Moru",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Orgen",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Mataru Utara",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Moramam",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Mataru Timur",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Margeta",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Mataru Barat",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Mataru Selatan",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Manatang",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Lakatuli",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Kuifana",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Mataru",
                "kelurahan": "Kamaifui",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Halerman",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Daya",
                "kelurahan": "Kafelulang",
                "kodepos": "85861"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Ternate Selatan",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Pura Timur",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Pura Utara",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Ternate",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Pura Selatan",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Pura",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Pura Barat",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Otvai",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Pulau Buaya",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Pulau Pura",
                "kelurahan": "Maru",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "O A Mate",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Lefokisu",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Lewalu",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Hulnani",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Kabola",
                "kelurahan": "Lawahing",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Dulolong",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Dulolong Barat",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Alor Kecil",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Ampera",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Bampalola",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Alor Besar",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Kabola",
                "kelurahan": "Alila Timur",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Alila",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Alila Selatan",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Alaang",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Aimoli",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Alor Barat Laut",
                "kelurahan": "Adang",
                "kodepos": "85851"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Teluk Kenari",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Wetabua",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Nusa Kenari",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Kabola",
                "kelurahan": "Pante Deera",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Motongbang",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Mutiara",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Lendola",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Kabola",
                "kelurahan": "Kabola",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Kabola",
                "kelurahan": "Kopidil",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Fanating",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Adang Buom",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Air Kenari",
                "kodepos": "85819"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Welai Barat",
                "kodepos": "85816"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Welai Timur",
                "kodepos": "85817"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Kalabahi Barat",
                "kodepos": "85814"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Binongko",
                "kodepos": "85815"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Kalabahi Tengah",
                "kodepos": "85812"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Kalabahi Kota",
                "kodepos": "85813"
            },
            {
                "kecamatan": "Teluk Mutiara",
                "kelurahan": "Kalabahi Timur",
                "kodepos": "85811"
            }
        ],
        "k286": [
            {
                "kecamatan": "Raihat",
                "kelurahan": "Toheleten",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Raihat",
                "kelurahan": "Raifatus",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Raihat",
                "kelurahan": "Tohe",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Raihat",
                "kelurahan": "Asumanu",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Raihat",
                "kelurahan": "Maumutin",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Sisi Fatuberal",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Raihat",
                "kelurahan": "Aitoun",
                "kodepos": "85773"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Makir",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Maudemu",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Nualain",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Mahuitas",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Loonuna",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Lutarato",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Lamaksenulu",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Leowalu",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Kewar",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Henes",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Lakmaras",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Ekin",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Fulur",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Dirun",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen",
                "kelurahan": "Duarato",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Lamaknen Selatan",
                "kelurahan": "Debululik",
                "kodepos": "85772"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Tulakadi",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Umaklaran",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Takirin",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Tialai",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Sabarau/Saraban",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Silawan",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Raiulun",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Manleten",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Maneikun",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Lasiolat",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Halimodok",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Lakanmau",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Fatuba&#8217;a",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Dualasi",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Fatulotu",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Bauho",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Dafala",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Lasiolat",
                "kelurahan": "Baudaok",
                "kodepos": "85771"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Sisi",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Rainawe",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Lakekun Barat",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Lakekun Utara",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Litamali",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima Timur",
                "kelurahan": "Kotabiru",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Lakekun",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Babulu",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima",
                "kelurahan": "Babulu Selatan",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima Timur",
                "kelurahan": "Alas Utara",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima Timur",
                "kelurahan": "Alas Selatan",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Kobalima Timur",
                "kelurahan": "Alas",
                "kodepos": "85766"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Umutnana",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Tunmat",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Botin Leo Bele",
                "kelurahan": "Takarai",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Tunabesi",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Naibone",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Naisau",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Manumutin Silole",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Manulea",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Botin Leo Bele",
                "kelurahan": "Kereana",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Kufeu",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Ikan Tuanbeis",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Fatuaruin",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Fatoin",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Builaran",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Biau",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "Beaneno",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Io Kufeu",
                "kelurahan": "Bani Bani",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Botin Leo Bele",
                "kelurahan": "Babotin Selatan",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Botin Leo Bele",
                "kelurahan": "Babotin Maemina",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Botin Leo Bele",
                "kelurahan": "Babotin",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Sasitamean",
                "kelurahan": "As Manulea",
                "kodepos": "85765"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Wekmidar",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Webetun",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Wekeke",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Weain",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Tatuli",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Saenama",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Tafuli I",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Oekmurak",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Raisamane",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Nanin",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Niti",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Naet",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Naiusu",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Nanebot",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Muke",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Nabutaek",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Biudukfoho",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Boen",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Lotas",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Rinhat",
                "kelurahan": "Alala",
                "kodepos": "85764"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Weulun",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Wesey",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Wederok",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Weoe",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Weseben",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Webriamata",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Umalor",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Umatoos",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Umalawain",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Taaba",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Sikun",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Raimataus",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Seserai",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Rabasahain",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Rabasa Haerain",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Rabasa Biris",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Rabasa",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Motaulun",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Naas",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Oan Mane",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Maktihan",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Motaain",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Lorotolus",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Loofoun",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Lamudur",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Leunklot",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Lasaen",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Laleten",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Lamea",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Kleseleon",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Lakulo",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Haliklaran (Hauklaran)",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Forekmodok",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Haitimuk",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Halibasar",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Bone Tasea",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Fafoe",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Biris",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Barat",
                "kelurahan": "Besikama",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Badarai",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Weliman",
                "kelurahan": "Angkaes",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Wewiku",
                "kelurahan": "Alkani",
                "kodepos": "85763"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Umanenlawalu",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Wehali",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Suai",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Umakatahan",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Naimana",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Railor Tahak",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Kletek",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Lawalu",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Kakaniuk",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Kamanasa",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Kateri",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Harekakae",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Bereliku",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Fahiluka",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Barada",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Barene",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Wemeda",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Tengah",
                "kelurahan": "Bakiruk",
                "kodepos": "85762"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Tasain",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Teun",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Sanleo",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Renrua",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Raimanus",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Raiulun",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Rafae",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Numponi",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Leuntolu",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Mandeu",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Kusa",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Duakoran",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Raimanuk",
                "kelurahan": "Faturika",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Malaka Timur",
                "kelurahan": "Dirma",
                "kodepos": "85761"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Tukuneno",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Rinbesihat",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Nanaet Duabesi",
                "kelurahan": "Nanaet",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Nanaet Duabesi",
                "kelurahan": "Nanaenoe",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Naekasa",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Naitimu",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Leosama",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Lookeu",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Kabuna",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Kenebibi",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Lawalutolus",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Jenilu",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Nanaet Duabesi",
                "kelurahan": "Fohoeka",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Fatuketi",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Nanaet Duabesi",
                "kelurahan": "Dubesi",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Kakuluk Mesak",
                "kelurahan": "Dua Laus",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Bakustulama",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Tasifeto Barat",
                "kelurahan": "Derok Faturene",
                "kodepos": "85752"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Tniumanu",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Uabau",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Atambua Selatan",
                "kelurahan": "Rinbesi",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Tesa",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Naekekusa",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Oenaek",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Meotroy",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Atambua Selatan",
                "kelurahan": "Manuaman",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Kapitanmeo",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Bonibais",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Atambua Kota",
                "kelurahan": "Fatubenao",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Besesmus/Bisesmus",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Laen Manen",
                "kelurahan": "Besesmus/Bisesmus",
                "kodepos": "85718"
            },
            {
                "kecamatan": "Atambua Selatan",
                "kelurahan": "Lidak",
                "kodepos": "85716"
            },
            {
                "kecamatan": "Atambua Selatan",
                "kelurahan": "Fatukbot",
                "kodepos": "85717"
            },
            {
                "kecamatan": "Atambua Barat",
                "kelurahan": "Bardao (Berdao)",
                "kodepos": "85715"
            },
            {
                "kecamatan": "Atambua Barat",
                "kelurahan": "Umanen",
                "kodepos": "85713"
            },
            {
                "kecamatan": "Atambua Barat",
                "kelurahan": "Tulamalae",
                "kodepos": "85714"
            },
            {
                "kecamatan": "Atambua Kota",
                "kelurahan": "Manumutin",
                "kodepos": "85712"
            },
            {
                "kecamatan": "Atambua Kota",
                "kelurahan": "Tenukiik",
                "kodepos": "85711"
            },
            {
                "kecamatan": "Tasifeto Timur",
                "kelurahan": "Sadi",
                "kodepos": "85711"
            },
            {
                "kecamatan": "Atambua Kota",
                "kelurahan": "Atambua kota",
                "kodepos": "85711"
            }
        ],
        "k287": [
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Tualene/Tualena",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Sapaen",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Taunbaen",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Feotleu",
                "kelurahan": "Manumean",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Feotleu",
                "kelurahan": "Naku",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Lokomea",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Feotleu",
                "kelurahan": "Makun",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Hauteas",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Feotleu",
                "kelurahan": "Kuluan",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Boronubaen",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Boronubaen",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Upfaon",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Utara",
                "kelurahan": "Biloe",
                "kodepos": "85682"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Tunbes",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Tunbaen",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Tan Pah",
                "kelurahan": "Teba Timur",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Tokbesi",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Tan Pah",
                "kelurahan": "Teba",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Tautpah",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Sainiup",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Supun",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Tan Pah",
                "kelurahan": "Oerinbesi",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Pantae",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Oepuah Selatan",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Oepuah Utara",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Selatan",
                "kelurahan": "Oenaem",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Oepuah",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Tan Pah",
                "kelurahan": "Oekopa",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Matabesi",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Kaubele",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Biboki Moenleu",
                "kelurahan": "Luniup",
                "kodepos": "85681"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Usapinonot",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Tapenpah",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Tengah",
                "kelurahan": "Tainsala",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Susulaku A",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Susulaku B",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Subun",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Tengah",
                "kelurahan": "Sone",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Sekon",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Oinbit",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Oenain",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Utara",
                "kelurahan": "Oesoko",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Nunmafo",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Tengah",
                "kelurahan": "Maubesi",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Nansean",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Manunain A",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Manunain B",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Loeram",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Tengah",
                "kelurahan": "Letmafo",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Letneo",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Tengah",
                "kelurahan": "Lanaus",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Lapeom",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Utara",
                "kelurahan": "Humusu C",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Keun",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Utara",
                "kelurahan": "Humusu A",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Utara",
                "kelurahan": "Humusu B",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Utara",
                "kelurahan": "Fatumtasa",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Fatoin",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Fafinesu B",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Fafinesu C",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Fafinesu",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Fafinesu A",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Banae/Bannae",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Fafinesu",
                "kelurahan": "Banuan/Baunuan",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Atmen",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana Barat",
                "kelurahan": "Atmen I",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Insana",
                "kelurahan": "Ainiut",
                "kodepos": "85671"
            },
            {
                "kecamatan": "Miomaffo Tengah",
                "kelurahan": "Tuabatan",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Seo",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Mutis",
                "kelurahan": "Tasinifu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Suanae",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Saenam",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Sallu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Oetulu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Popnam",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Oeolo",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Oeperigi",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Oelneke",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Oenak",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Tengah",
                "kelurahan": "Noenasi",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Noepesu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Noemuti",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Mutis",
                "kelurahan": "Noelelo",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Noeltoko",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Nifuboke",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Noebaun",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Tengah",
                "kelurahan": "Nian",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Nibaaf",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti Timur",
                "kelurahan": "Naob",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Mutis",
                "kelurahan": "Naekake B",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Mutis",
                "kelurahan": "Naekake A",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti Timur",
                "kelurahan": "Manikin",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Manusasi",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Lemon",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti Timur",
                "kelurahan": "Kuaken",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Haulasi",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Kiuola",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Fatutasu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti Timur",
                "kelurahan": "Haekto",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Fatunisuan",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Eban",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Barat",
                "kelurahan": "Fatuneno",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Fatumuti",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Bijeli",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Bisafe",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Tengah",
                "kelurahan": "Bijaepasu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Noemuti",
                "kelurahan": "Banfanu",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Batnes",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Tengah",
                "kelurahan": "Akomi",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Musi",
                "kelurahan": "Ainan",
                "kodepos": "85661"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Tuntun",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Tunoe",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Tes",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Tubu",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Naibenu",
                "kelurahan": "Sunsea",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Taekas",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Sainoni",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Sunkaen",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Oetalus",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Oesena",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Oenenu Utara",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Oenino",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Oenenu",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Oenenu Selatan",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Oelbonak",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Oelami",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Nimasi",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Napan",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Nilulat",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Naiola",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Maurisu Selatan",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Maurisu Utara",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Nainaban",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Naibenu",
                "kelurahan": "Manamas",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Maurisu",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Kaenbaun",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Kiusili",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Kuanek/Kuenak",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Jak",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Haumeni Ana",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Nilulat",
                "kelurahan": "Inbate",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Haumeni",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Femnasi",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Faennake",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Fatusene",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Tengah",
                "kelurahan": "Buk",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Naibenu",
                "kelurahan": "Benus",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Bitefa",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Bokon",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Banain B",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Banain C",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Banain A",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Naibenu",
                "kelurahan": "Bakitolas",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Bikomi Utara",
                "kelurahan": "Baas",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Miomaffo Timur",
                "kelurahan": "Amol",
                "kodepos": "85651"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Tubuhue",
                "kodepos": "85617"
            },
            {
                "kecamatan": "Bikomi Selatan",
                "kelurahan": "Tublopo",
                "kodepos": "85617"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Maubeli",
                "kodepos": "85617"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Bansone",
                "kodepos": "85615"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Sasi",
                "kodepos": "85616"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Aplasi",
                "kodepos": "85614"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Tuamese",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Ponu",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Sifaniha",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Nonatbatan",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Oemanu",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Motadik",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Nifutasi",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Kotafoun",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Benpasi",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Biboki Anleu",
                "kelurahan": "Maukabatan",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Kefamenanu Selatan",
                "kodepos": "85613"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Kefamenanu Tengah",
                "kodepos": "85612"
            },
            {
                "kecamatan": "Kota Kefamenanu",
                "kelurahan": "Kefamenanu Utara",
                "kodepos": "85611"
            }
        ],
        "k288": [
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Pili",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Tesi Ayofanu",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Panite",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Oinlasi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "O Obibi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Oenai",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Nualunat",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Nunbena",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Napi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Nekmese",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Naile&#8217;u",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Kot Olin",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Fatuulan",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Hoibeti",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Fallas",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Fatukusi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Fatukusi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Boti",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Enonapi",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kot Olin",
                "kelurahan": "Binenok",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Kie (Ki'e)",
                "kelurahan": "Belle",
                "kodepos": "85575"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Tumu",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Toianas",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Tuataum",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Sono",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Tauanas",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Snok",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Skinu",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Sapnala",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Oeleu",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Sambet",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Obaki",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Noeolin",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Niti",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Nasi",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Lotas",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Muna",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Milli",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Lilo",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Lobus",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Fatuoni",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Koloto",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Utara",
                "kelurahan": "Fatilo",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Toianas",
                "kelurahan": "Bokong",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Kok Baun",
                "kelurahan": "Benahe (Benehe)",
                "kodepos": "85574"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Toi",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Sunu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Noebana",
                "kelurahan": "Suni",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Santian",
                "kelurahan": "Santian",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Saenam",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Sahan",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Sabun",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Putun",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Santian",
                "kelurahan": "Poli",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Op",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Nunleu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Oinlasi",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Noebana",
                "kelurahan": "Noebana",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Nunkolo",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Nifuleo",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Netutnana",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Santian",
                "kelurahan": "Nenotes",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Nenoat",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Nano",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Santian",
                "kelurahan": "Naifatu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Noebana",
                "kelurahan": "Mnelapetu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Meusin",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Santian",
                "kelurahan": "Manufui",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Noebana",
                "kelurahan": "Mella",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Leonani (Leonmeni)",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Lanu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Kualeu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Kokoi",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Hoineno",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Haumeni",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Fenun",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Noebana",
                "kelurahan": "Fatumnasi",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Fatu Manufui",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Fatulunu",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Nunkolo",
                "kelurahan": "Fat",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Boking",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Fae",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Boking",
                "kelurahan": "Baus",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Amanatun Selatan",
                "kelurahan": "Anin",
                "kodepos": "85573"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Tunis",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Telukh",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Tliu",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Taebone",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Sillu",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Sini",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Pisan",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Pene Utara",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Oelet",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Oenino",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Oe Ekam",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Oeleon",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Nunuhakniti",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Nunfutu",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Noe Noni",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Nifulinah",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Niki Niki Un",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Nifukiu",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Neke",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Mauleum",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Mnelaanen",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Kaeneno",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Hoi",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Kiki",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Ello",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Fatukopa",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Timur",
                "kelurahan": "Billa",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Bileon",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fatukopa",
                "kelurahan": "Besnam",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Fautmolo",
                "kelurahan": "Besle&#8217;u",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Oenino",
                "kelurahan": "Abi",
                "kodepos": "85572"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Tumu",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Sopo",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Taebesa",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Noebesa",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Oeekam",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Niki Niki",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Nobi Nobi",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Nakfunu",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Maunum Niki Niki",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Baki",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Amanuban Tengah",
                "kelurahan": "Bone",
                "kodepos": "85571"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Taupi",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Spaha",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Pene Selatan",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Sei",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Ofu",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Olais",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Oebo",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Pana",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Oehan",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Oeleu",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Noesiu",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Oetuke",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Lasi",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Nununamat",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Noebeba",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kusi",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kusi Utara",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kuanfatu",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Kolbano",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kelle",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kelle Tunan",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Kakan",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Haunobenak",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kuanfatu",
                "kelurahan": "Basmuti",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Kolbano",
                "kelurahan": "Babuin",
                "kodepos": "85563"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Tuppan",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Tuakole",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Tuapakas",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Toineke",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Tuafanu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Teas",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Pollo",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Oni",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Oepliki",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Oekiu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Oemaman",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Oebobo",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Oehela",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Oebelo",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Oe Ekam (Oelekam)",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Oebaki",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Noemuke",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Nunusunu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Naip",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Mio",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Linamnutu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Kualin",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Kiubaat",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Kualin",
                "kelurahan": "Kiufatu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Hane",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Fatutnana",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Noebeba",
                "kelurahan": "Eno Nabuasa",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Enoneten",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Benlutu",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Boentuka",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Batnun",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Amanuban Selatan",
                "kelurahan": "Bena",
                "kodepos": "85562"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Usapimnasi",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Tuasene",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Tunbes",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Tune",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Taneotob",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Barat",
                "kelurahan": "Salbait",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Puna",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Sainoni",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Pika",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Oinlasi",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Oelnunuh",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Oel&#8217;ekam",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Oelbubuk",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Nunbena",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Barat",
                "kelurahan": "Oel Uban (Oeuban)",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Fatumnasi",
                "kelurahan": "Nuapin",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Noinbila",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Noebesi",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Neke Manifeto",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Fatumnasi",
                "kelurahan": "Nenas",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Mnesatbubak",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Fatumnasi",
                "kelurahan": "Mutis",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Loli",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Liliana",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Laob",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Fatumnasi",
                "kelurahan": "Kuanoel",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Kualeu",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Barat",
                "kelurahan": "Koa",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Konbaki",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Nunbena",
                "kelurahan": "Feto Mone",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Kesetnana",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Fatumnasi",
                "kelurahan": "Fatumnasi",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Fatumnutu",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Barat",
                "kelurahan": "Fatukoko",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Bonleu",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Bisene",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Tengah",
                "kelurahan": "Binaus",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Biloto",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Selatan",
                "kelurahan": "Bikekneno",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Bijeli",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Mollo Barat",
                "kelurahan": "Besana",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Polen",
                "kelurahan": "Balu",
                "kodepos": "85561"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Tutem",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "To&#8217;manat",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Tunua",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Tobu",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Taiftob",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "To&#8217;fen",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Sebot",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Saubalan",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Pubasu",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Obesi",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Nefokoko",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Netpala",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Lelobatan",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Leloboko",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Iusmolo",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Kokfe&#8217;u",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Huetalan",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Halme",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Eon Besi",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Fatukoto",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Bijaepunu",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Bosen",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Tobu",
                "kelurahan": "Bestobe",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Mollo Utara",
                "kelurahan": "Ajaobaki",
                "kodepos": "85552"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Tubuhue",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Tubmonas",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Tublopo (Meometan)",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Tetaf",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Supul",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Pusu",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Oof",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Nusa",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Oe Oe",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Nulle",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Nifukani",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Naukae",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Haumenibaki",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Enoneontes",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kuatnana",
                "kelurahan": "Lakat",
                "kodepos": "85551"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Oekefan",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "So E (Soe)",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Noemeto",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Kuatae",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Kota Baru",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Kampung Baru",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Karang Siri",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Nonohonis",
                "kodepos": "85517"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Cendana",
                "kodepos": "85519"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Oebesa",
                "kodepos": "85515"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Nunumeu",
                "kodepos": "85516"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Kobekamusa",
                "kodepos": "85515"
            },
            {
                "kecamatan": "Amanuban Barat",
                "kelurahan": "Mnelalete",
                "kodepos": "85514"
            },
            {
                "kecamatan": "Kota Soe",
                "kelurahan": "Taubneno",
                "kodepos": "85511"
            }
        ],
        "k289": [
            {
                "kecamatan": "Raijua",
                "kelurahan": "Ledeunu",
                "kodepos": "85393"
            },
            {
                "kecamatan": "Raijua",
                "kelurahan": "Kolorae",
                "kodepos": "85393"
            },
            {
                "kecamatan": "Raijua",
                "kelurahan": "Ledeke",
                "kodepos": "85393"
            },
            {
                "kecamatan": "Raijua",
                "kelurahan": "Ballu",
                "kodepos": "85393"
            },
            {
                "kecamatan": "Raijua",
                "kelurahan": "Bolua",
                "kodepos": "85393"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Tada",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Loborai",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Matei",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Lobodei",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Loboaju",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Kujiratu",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Limagu (Limaggu)",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Keduru",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Keliha",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Jiwuwu",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Eimau",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Huwaga",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Eilode",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Eimadake (Eimmadake)",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Bodae",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Bolou",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Timur",
                "kelurahan": "Eiada",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Sabu Tengah",
                "kelurahan": "Bebae",
                "kodepos": "85392"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Wadumedi (Wadumeddi)",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Waduwala",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Teriwu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Titinalede",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Tanajawa",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Raerobo",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Roboaba",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raemude",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raenalulu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raenyale",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raemadia",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raemadia",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raedewa",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raeloro",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Raeloro",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Pedarro (Pedaro)",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Naawawi",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Molie (Mole)",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Menia",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Mebba",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Loboroi",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Mehona",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Lobohede",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Ledekepaka",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Lederaga",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Ledeke",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Ledeae",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Ledeana",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Halapadji",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Kotahawu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Lede Talo",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Eilogo",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Gurimonearu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Eikare",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Depe",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Djadu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Deme",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Barat",
                "kelurahan": "Delo",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Sabu Liae",
                "kelurahan": "Dainao",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Hawu Mehara",
                "kelurahan": "Daieko",
                "kodepos": "85391"
            }
        ],
        "k290": [
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Tunfeu",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Usapisonbai",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Tasikona",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Taloitan (Taloetan)",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Oemasi",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Oenif",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Oepaha",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Oben",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Oelomin",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Bismark (Bismarak)",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Nekamese",
                "kelurahan": "Bone",
                "kodepos": "85391"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Tuapanaf",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Takari",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Tanini",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Oelnaineno",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Oesusu",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Kauniki",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Noelmina",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Fatukona",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Hueknutu",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Takari",
                "kelurahan": "Benu",
                "kodepos": "85369"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Pitai",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Sulamu",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Pantai Beringin",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Pantulan",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Pariti",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Bipolo",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Sulamu",
                "kelurahan": "Oeteta",
                "kodepos": "85368"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Tunbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Teunbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Tesbatan II",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Toobaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Selatan",
                "kelurahan": "Sonraen",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Tesbatan",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Soba",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Selatan",
                "kelurahan": "Retraen",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Selatan",
                "kelurahan": "Sahraen",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Timur",
                "kelurahan": "Pakubaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Timur",
                "kelurahan": "Rabeka",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Ponain",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Oenoni Dua",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Oesena",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Timur",
                "kelurahan": "Oebesi",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Oenoni",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Nonbes",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Niukbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Selatan",
                "kelurahan": "Nekmese",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Merbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Nekbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Kotabes",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Barat",
                "kelurahan": "Erbaun",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Timur",
                "kelurahan": "Enoraen",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi",
                "kelurahan": "Apren",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amarasi Selatan",
                "kelurahan": "Buraen",
                "kodepos": "85367"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Lilmus",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Naikliu",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Kolabe",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Bakuin",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Fatunaus",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Utara",
                "kelurahan": "Afoan",
                "kodepos": "85365"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Timau",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Saukibe",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Soliu",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Ohaem",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Ohaem Dua",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Timur",
                "kelurahan": "Nunuanah",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Oelbanu",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Oelfatu",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Timur",
                "kelurahan": "Netemnanu Selatan",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Timur",
                "kelurahan": "Netemnanu Utara",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Daya",
                "kelurahan": "Nefoneut",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Timur",
                "kelurahan": "Natemnanu (Natummanu)",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Daya",
                "kelurahan": "Letkole",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Daya",
                "kelurahan": "Manubelon",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Leloboko",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Lelogama",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Timur",
                "kelurahan": "Kifu",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Honuk",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Laut",
                "kelurahan": "Faumes",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Fatusuki",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Tengah",
                "kelurahan": "Fatumonas",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Selatan",
                "kelurahan": "Fatumetan",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Tengah",
                "kelurahan": "Bonmuti",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Tengah",
                "kelurahan": "Bitobe",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Barat Daya",
                "kelurahan": "Bioba Baru (Barutaen)",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Amfoang Tengah",
                "kelurahan": "Binafun",
                "kodepos": "85364"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Sillu",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Tulnaku",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Seki",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Oenuntono (Oenunutono)",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Pathau",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Oenaunu",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Oeniko",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Oemofa",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Oemolo",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Oebola Dalam",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Oeleto (Oefeto)",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Oebola",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Nunmafo",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Naunu",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Muke",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Niunbaun",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Kuimasi",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Kiuoni",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Amabi Oefeto Timur",
                "kelurahan": "Enolanan",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Camplong II",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Ekateta",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Fatuleu",
                "kelurahan": "Camplong I",
                "kodepos": "85363"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Tuapukan",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Tuatuka",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Tanah Putih",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Raknamo",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Oesao II",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Pukdale",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Oesao",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Oefafi",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Oelatimo",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Naibonat",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Nunkurus",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Manusak",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Merdeka",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Kairane",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Kuanheum",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Fatukanutu (Fatuknutu)",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Amabi Oefeto",
                "kelurahan": "Fatuteta",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Timur",
                "kelurahan": "Babau",
                "kodepos": "85362"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Tarus",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Oeltua",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Penfui Timur",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Tanah Merah",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Oelpuah",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Oelnasi",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Oeletsala",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Oebelo",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Noelbaki",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Kupang Tengah",
                "kelurahan": "Mata Air",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Bokong",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Kuaklalo",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Baumata Utara",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Baumata Barat",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Baumata Timur",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Taebenu",
                "kelurahan": "Baumata",
                "kodepos": "85361"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Uitiuh Tuan",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Uitiuh Ana",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Uiboa",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Uitao",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Otan",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Uiasa",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Onansila (Oenansila)",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Naiken (Naikean)",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Huilelot",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Letbaun",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Hansisi",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Batuinan",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau",
                "kelurahan": "Bokonusan",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Semau Selatan",
                "kelurahan": "Akle",
                "kodepos": "85353"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Tesabela",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Sumlili",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Tablolong",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Oematanunu",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Oenaek",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Oenesu",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Nitneo",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Lifuleo",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Manulai I",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Kuanheun",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Batakte",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Kupang Barat",
                "kelurahan": "Bolok",
                "kodepos": "85351"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Naioni",
                "kodepos": "85239"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Batuplat",
                "kodepos": "85237"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Manulai II",
                "kodepos": "85238"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Manutapen",
                "kodepos": "85236"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Fatufeto",
                "kodepos": "85234"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Nunhila",
                "kodepos": "85234"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Mantasi",
                "kodepos": "85235"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Nunbaun Delha",
                "kodepos": "85233"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Nunbaun Sabu",
                "kodepos": "85233"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Penkase Oeleta",
                "kodepos": "85231"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Namosain",
                "kodepos": "85232"
            },
            {
                "kecamatan": "Alak",
                "kelurahan": "Alak",
                "kodepos": "85231"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Tode Kisar",
                "kodepos": "85229"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Solor",
                "kodepos": "85229"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Air Mata",
                "kodepos": "85229"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Nefonaek",
                "kodepos": "85229"
            },
            {
                "kecamatan": "Kelapa Lima",
                "kelurahan": "Oesapa",
                "kodepos": "85228"
            },
            {
                "kecamatan": "Kelapa Lima",
                "kelurahan": "Oesapa Barat",
                "kodepos": "85228"
            },
            {
                "kecamatan": "Kelapa Lima",
                "kelurahan": "Oesapa Selatan",
                "kodepos": "85228"
            },
            {
                "kecamatan": "Kelapa Lima",
                "kelurahan": "Kelapa Lima",
                "kodepos": "85228"
            },
            {
                "kecamatan": "Kelapa Lima",
                "kelurahan": "Lasiana",
                "kodepos": "85228"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Oeba",
                "kodepos": "85226"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Pasir Panjang",
                "kodepos": "85227"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Merdeka",
                "kodepos": "85225"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Fatubesi",
                "kodepos": "85226"
            },
            {
                "kecamatan": "Fatuleu Barat",
                "kelurahan": "Poto",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Barat",
                "kelurahan": "Tuakau",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Tengah",
                "kelurahan": "Passi",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Tengah",
                "kelurahan": "Nunsaen",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Tengah",
                "kelurahan": "Oelbiteno",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Barat",
                "kelurahan": "Nuataus",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Tengah",
                "kelurahan": "Nonbaun",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Barat",
                "kelurahan": "Kalali",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Fatuleu Barat",
                "kelurahan": "Naitae",
                "kodepos": "85223"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Lai Lai Bisi Kopan",
                "kodepos": "85221"
            },
            {
                "kecamatan": "Kota Lama",
                "kelurahan": "Bonipoi",
                "kodepos": "85221"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Bello",
                "kodepos": "85148"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Penfui",
                "kodepos": "85148"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Kolhua",
                "kodepos": "85146"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Naimata",
                "kodepos": "85147"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Sikumana",
                "kodepos": "85143"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Fatukoa",
                "kodepos": "85144"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Naikolan",
                "kodepos": "85142"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Oepura",
                "kodepos": "85142"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Nunleu",
                "kodepos": "85119"
            },
            {
                "kecamatan": "Maulafa",
                "kelurahan": "Maulafa",
                "kodepos": "85141"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Air Nona",
                "kodepos": "85119"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Kuanino",
                "kodepos": "85119"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Naikoten I (Satu)",
                "kodepos": "85118"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Naikoten II (Dua)",
                "kodepos": "85118"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Bakunase",
                "kodepos": "85116"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Bakunase Dua",
                "kodepos": "85116"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Oetete",
                "kodepos": "85112"
            },
            {
                "kecamatan": "Kota Raja",
                "kelurahan": "Fontein",
                "kodepos": "85112"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Tuak Daun Merah",
                "kodepos": "85111"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Liliba",
                "kodepos": "85111"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Oebobo",
                "kodepos": "85111"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Oebufu",
                "kodepos": "85111"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Fatululi",
                "kodepos": "85111"
            },
            {
                "kecamatan": "Oebobo",
                "kelurahan": "Kayu Putih",
                "kodepos": "85111"
            }
        ],
        "k291": [
            {
                "kecamatan": "Mimika Barat Jauh",
                "kelurahan": "Umar",
                "kodepos": "99974"
            },
            {
                "kecamatan": "Mimika Barat Jauh",
                "kelurahan": "Yapakopa",
                "kodepos": "99974"
            },
            {
                "kecamatan": "Mimika Barat Jauh",
                "kelurahan": "Potowayburu (Potowaiburu)",
                "kodepos": "99974"
            },
            {
                "kecamatan": "Mimika Barat Jauh",
                "kelurahan": "Tapormai",
                "kodepos": "99974"
            },
            {
                "kecamatan": "Mimika Barat Jauh",
                "kelurahan": "Aiduna (Aindua)",
                "kodepos": "99974"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Yaraya",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Wakia",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Wumuka",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Uta",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Pronggo",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Paripi",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Mimika",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Migiwia",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Mapuruku (Mapuruka/Mupuruka)",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Mapar",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Manaware (Manuare)",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Kokonao",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Kiyura",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Kawar (Kauar)",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Kipia",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Kapiraya",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Apiri (Ipiri)",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat (Mibar)",
                "kelurahan": "Amar",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Barat Tengah",
                "kelurahan": "Akar",
                "kodepos": "99973"
            },
            {
                "kecamatan": "Mimika Timur Tengah",
                "kelurahan": "Tiwaka",
                "kodepos": "99972"
            },
            {
                "kecamatan": "Mimika Timur Tengah",
                "kelurahan": "Keakwa",
                "kodepos": "99972"
            },
            {
                "kecamatan": "Mimika Timur Tengah",
                "kelurahan": "Kamora",
                "kodepos": "99972"
            },
            {
                "kecamatan": "Mimika Timur Tengah",
                "kelurahan": "Atuka",
                "kodepos": "99972"
            },
            {
                "kecamatan": "Mimika Timur Tengah",
                "kelurahan": "Aikawapuka",
                "kodepos": "99972"
            },
            {
                "kecamatan": "Mimika Timur Jauh",
                "kelurahan": "Ohotya",
                "kodepos": "99971"
            },
            {
                "kecamatan": "Mimika Timur Jauh",
                "kelurahan": "Omawita",
                "kodepos": "99971"
            },
            {
                "kecamatan": "Mimika Timur Jauh",
                "kelurahan": "Fanamo",
                "kodepos": "99971"
            },
            {
                "kecamatan": "Mimika Timur Jauh",
                "kelurahan": "Ayuka",
                "kodepos": "99971"
            },
            {
                "kecamatan": "Mimika Timur Jauh",
                "kelurahan": "Amamapare",
                "kodepos": "99971"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Utikini Baru",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Naena Muktipura",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Kuala Kencana",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Mulia Kencana",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Karang Senang",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Iwaka",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Kuala Kencana",
                "kelurahan": "Bhintuka",
                "kodepos": "99968"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Waa",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Tembagapura",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Singa (T&#8217;Singa)",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Puti",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Opitawak",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Jinoni (Jinonin)",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Jagamin",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Hoya",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Ilaga",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Banigogom",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Doliningokngin",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Tembagapura",
                "kelurahan": "Arwandop (Arwanop)",
                "kodepos": "99967"
            },
            {
                "kecamatan": "Jila",
                "kelurahan": "Jila",
                "kodepos": "99966"
            },
            {
                "kecamatan": "Jila",
                "kelurahan": "Noemun",
                "kodepos": "99966"
            },
            {
                "kecamatan": "Jila",
                "kelurahan": "Enggin",
                "kodepos": "99966"
            },
            {
                "kecamatan": "Jila",
                "kelurahan": "Geselama",
                "kodepos": "99966"
            },
            {
                "kecamatan": "Jita",
                "kelurahan": "Wenin",
                "kodepos": "99965"
            },
            {
                "kecamatan": "Jila",
                "kelurahan": "Diloa",
                "kodepos": "99966"
            },
            {
                "kecamatan": "Jita",
                "kelurahan": "Wapu",
                "kodepos": "99965"
            },
            {
                "kecamatan": "Jita",
                "kelurahan": "Sempan Timur",
                "kodepos": "99965"
            },
            {
                "kecamatan": "Jita",
                "kelurahan": "Sumapro",
                "kodepos": "99965"
            },
            {
                "kecamatan": "Jita",
                "kelurahan": "Noema (Nerva)",
                "kodepos": "99965"
            },
            {
                "kecamatan": "Agimuga",
                "kelurahan": "Fakafuku",
                "kodepos": "99964"
            },
            {
                "kecamatan": "Agimuga",
                "kelurahan": "Kiliarma",
                "kodepos": "99964"
            },
            {
                "kecamatan": "Agimuga",
                "kelurahan": "Amungun",
                "kodepos": "99964"
            },
            {
                "kecamatan": "Agimuga",
                "kelurahan": "Aramsolki",
                "kodepos": "99964"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Wania",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Pigapu",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Pomako (Paumako)",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Tipuka",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Kaugapu",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Mwapi",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Hiripau",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Timur",
                "kelurahan": "Kadun Jaya",
                "kodepos": "99963"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Wangirja",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Wonosari Jaya",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Timika Jaya",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Nayaro",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Nawaripi",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Kwamki",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Limau Asri",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Kamoro Jaya",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Koperapoka",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Harapan",
                "kodepos": "99910"
            },
            {
                "kecamatan": "Mimika Baru",
                "kelurahan": "Inaoga (Inauga)",
                "kodepos": "99910"
            }
        ],
        "k292": [
            {
                "kecamatan": "Haju",
                "kelurahan": "Wiyage",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Yagatsu",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Sogophe",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Warogom",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Pagai",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Semtaipim (Tsemtaipim)",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Mani",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Okor (Okoor)",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Katage",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Kerke",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Kaibusene",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Kasima",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Kaibu",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Atsipim",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Gairipim",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Amuro (Omuro)",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Arare",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Amenda",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Haju",
                "kelurahan": "Amagatsu",
                "kodepos": "99881"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Yemu",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Wonggi",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Womin",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Veta",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Tiau",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Tugumau",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Tayau",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Tarwa",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Tamanim",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Samurukie",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Senggo",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Pier",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Sagis",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Mu",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Kumaban",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Merokima",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Kumasma",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Keta",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Imembi",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Haku",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Honya",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Epem",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Fomu",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Passue Bawah",
                "kelurahan": "Busiri",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Bidneu (Bidnew)",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Bifo",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Binerbis",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Ti Zain",
                "kelurahan": "Basman",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Kaibar",
                "kelurahan": "Amasu (Amazu)",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Citakmitak",
                "kelurahan": "Abau",
                "kodepos": "99875"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Yame",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Kopi",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Meda",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Sigare",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Khabu",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Kiki",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Jufo Kecil",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Kaitok",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Keru",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Homang",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Isage",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Jufo Besar",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Eci",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Girimio",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Haffo",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Asaren (Assaren)",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Chanani (Khanami)",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Yatan",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Assue",
                "kelurahan": "Aboge",
                "kodepos": "99874"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Waman",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Waruwe",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Waghien/Koba (Waghain Bawah)",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Wagin",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Tereyemu (Teriyemu)",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Taragai / Taragae",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Upin",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Sene",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Sumur Aman",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Pano",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Monana",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Mur",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Linggua",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Komru",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Magabag / Magabak",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Kogoyaman",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Khatan / Katan",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Kofar",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Kogir",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Kaumi",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Kayagae (Kayagai)",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Kabe",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Ima",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Minyamur",
                "kelurahan": "Ati / Atti",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Ghanu",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Nambioman Bapai (Mambioman)",
                "kelurahan": "Agham (Aghain)",
                "kodepos": "99872"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Wanggate",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Yangpop",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Wairu (Mairu)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Urufu",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Tokom (Toghom)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Tokhompatu / Tokompatu (Thokompatu)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Soba",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Sepoh",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Rimba Jaya (Gayu)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Rayam",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Rep",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Passue/Taim",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Piai",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Obaa",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Paedam",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Nohon",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Muin",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Menya",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Metim",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Mappi Bawah / Yamui",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Marbin",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Masin",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Madu",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Kotiak",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Kogo",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Kotup (Xotup)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Kaime",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Kepi",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Harome",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Kadam (Ayim/Oyim)",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Enem",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Gauda",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Dagimon",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Obaa",
                "kelurahan": "Emete",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Bagaram",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Baitate",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Passue",
                "kelurahan": "Afogoma",
                "kodepos": "99871"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Yibin",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Yodom",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Yakomi",
                "kelurahan": "Yame",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Bamgi",
                "kelurahan": "Tagaimon Sino",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Bamgi",
                "kelurahan": "Yeloba",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Sien",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Bamgi",
                "kelurahan": "Tagaimon Karome",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Pies (Piyes)",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Sahapikia (Sahapikya)",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Bamgi",
                "kelurahan": "Sibi",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Ogorito",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Osso",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Oghoto",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Memes",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Mopio",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Bamgi",
                "kelurahan": "Konebi",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Mememu",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Kobeta",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Yakomi",
                "kelurahan": "Kokoya",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Homilikia (Homlikya)",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Isyaman",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Harapan",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Geturki",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Gimikia (Gemikya)",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Ebeske",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Venaha",
                "kelurahan": "Getiyo",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Yakomi",
                "kelurahan": "Borohaba",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Bosma",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Yakomi",
                "kelurahan": "Benggo",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Banamepe",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Edera",
                "kelurahan": "Bade",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Syahcame",
                "kelurahan": "Asset",
                "kodepos": "99853"
            },
            {
                "kecamatan": "Yakomi",
                "kelurahan": "Amk",
                "kodepos": "99853"
            }
        ],
        "k293": [
            {
                "kecamatan": "Fayit",
                "kelurahan": "Wiyar",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Yawas",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Tauro",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Waras",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Sayoa",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Nanai (Nanay)",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Piramat",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Pirien",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Kayarpis",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Mapane",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Isar",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Kagas",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Bora",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Basim",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Bawos",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Biopis",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Bakyor",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Amaita",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Bagair",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Ais",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Akan Tapak",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Acenep (Ocenep)",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Fayit",
                "kelurahan": "Ainamsato",
                "kodepos": "99782"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Yetsy",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Yuni",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Waw",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Sisakem",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Simini",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Pau",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Powetsy",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Manepsimni (Manep)",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Katew",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Dawer",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Fakan",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Jewes",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Buetkwar (Betkuar)",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Cumnew",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Beco",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Akat (Ayam)",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Akat",
                "kelurahan": "Bayiw Pinam",
                "kodepos": "99779"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Yensuku (Jesenko)",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Yamas",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Yeni / Yemi",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Werer (Warer)",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Weo",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Yakapis",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Walase",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Tomor",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Sona",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Suru-Suru",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Tii",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Sawa",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Se (See)",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Sauti",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Sagapu",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Salbik",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Paar",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Onavai",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Pupis",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Obio",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Omor",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Nakai",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Munu",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Mumugu Dua",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Mumugu",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Laluk",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Linduk",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Komor",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Koba",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Korobuk",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Kebikduk",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Katalina",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Jifak",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Kapi",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Jipawer (Yipawer)",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Hom-hom",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Hulam",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Fumeripit",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Eroko",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Esmapan",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Dumaten",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Er",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Erma",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Bu",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Besika",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Berip / Birip",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Berimono",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Atat",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Ayir",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "As",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Asgun",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Aou",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Pulau Tiga",
                "kelurahan": "Aoep",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Amor",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Suru-suru",
                "kelurahan": "Ajin",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Sawa Erma",
                "kelurahan": "Agani",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Unir Sirau",
                "kelurahan": "Abamu",
                "kodepos": "99778"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Yufri (Smith)",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Joerat",
                "kelurahan": "Yaun",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Uwus",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Yamoth (Yomoth)",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Saw",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Suwru",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Mbait",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Per",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Briten (Biriten/Beriten)",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Kaye",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Bis Agats",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Bisman",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Bou",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Birak",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Agats",
                "kelurahan": "Asuwetsy",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Yefiwangi (Yefuwagi)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Jetsy",
                "kelurahan": "Akamar",
                "kodepos": "99777"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Yaosakor",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Yauw (You)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Yaisu (Yasiu)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Wagi",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Warkal (Warkai)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Sakor",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Waganu",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Sagoni / Sogoni",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Sagare",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Pirpis",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Mausi",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Omanesep",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Fos",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Kaimo",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Kawet",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Damen",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Damen",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Cewewyamew",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Ayip",
                "kelurahan": "Comoro",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Biwar Laut",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Bipin (Bipim)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Biwar Darat",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Bakasei",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Bine",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Sirets",
                "kelurahan": "Awok",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Betcbamu",
                "kelurahan": "Atambuts",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Atsj (Atsy)",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Ambisu",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Atsy / Atsj",
                "kelurahan": "Amanamkai",
                "kodepos": "99776"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Yerfum",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Yaptambor",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Yamkap (Yankap)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Yagamit",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Yahui (Yahoi)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Wagasu",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Tereo / Tareo (Taero)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Suagai / Suwagai (Suwa)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Sohomane",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Sasime",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Simipit (Sinepit)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Simsagar",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Saramit / Seramit",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Sarmafo",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Sapen (Airosapem)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Santabor (Santambor)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Sanep / Sanem",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Sanapai (Senapai)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Samendoro / Semendoro",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Saman",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Primapun (Firimapun/Firimapon)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Kamur",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Kawem",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Kaipom",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Kagirim / Kairin",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Jitorsok",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Heiyaram",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Hainam",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Hahare",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Eseib (Esaib)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Ero Saman",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Emene (Eumene)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Bayun",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Pantai Kasuari",
                "kelurahan": "Bawor",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Safan",
                "kelurahan": "Aworkey / Aworket",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Amkun / Amkum (Ankum)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Amkai",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Amaru (Amoro)",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Der Koumur",
                "kelurahan": "Amagais",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Kopay",
                "kelurahan": "Aikut",
                "kodepos": "99773"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Wowi",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Woutu Brasa",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Woutu Kolof",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Waijens",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Waganu Dua",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Wagabus",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Wabak",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Vakam Dua",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Ulakin",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Vakam",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Ujung Batu",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Soray / Sorai",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Somnak",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Sipenap / Sipanap (Asarep)",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Pirabanak",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Patipi (Patippi)",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Pepera",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Mabul",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Nagatun",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Kapayap Tiga",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Karbis",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Kapayap Satu",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Kapayap Dua",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Emnam",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Jinak",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Daikot",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Dekamor / Dekamer",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Butukatnau",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Burbis",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Bor",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Bubis",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Bumu",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Binam",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Binamsain",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Ayak",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Banum",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Kolf Braza",
                "kelurahan": "Auban",
                "kodepos": "99766"
            },
            {
                "kecamatan": "Suator",
                "kelurahan": "Amakot",
                "kodepos": "99766"
            }
        ],
        "k294": [
            {
                "kecamatan": "Kawagit",
                "kelurahan": "Wanggom",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Firiwage",
                "kelurahan": "Waliburu",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Kawagit",
                "kelurahan": "Niop",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Mangga Tiga",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Manggelum",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Kouh",
                "kelurahan": "Kouh",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Kewam",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Kawagit",
                "kelurahan": "Kombai",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Firiwage",
                "kelurahan": "Karuwage",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Firiwage",
                "kelurahan": "Karuwage",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Gaguop",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Firiwage",
                "kelurahan": "Firiwage",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Firiwage",
                "kelurahan": "Kabuwage",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Burunggop",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Kawagit",
                "kelurahan": "Biwage",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Kawagit",
                "kelurahan": "Biwage Dua",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Manggelum",
                "kelurahan": "Bayanggop",
                "kodepos": "99665"
            },
            {
                "kecamatan": "Yaniruma",
                "kelurahan": "Yaniruma",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Winiktit",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Wombon",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Kombay",
                "kelurahan": "Yafufla",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Waropko",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Kombay",
                "kelurahan": "Wanggemalo",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Wametkapa",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ninati",
                "kelurahan": "Upyetetko",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Upkim",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Kombay",
                "kelurahan": "Ugo",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ninati",
                "kelurahan": "Tembutka",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ninati",
                "kelurahan": "Timka",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Kombay",
                "kelurahan": "Sinimburu",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ambatkwi (Ambatkui)",
                "kelurahan": "Kuken",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ninati",
                "kelurahan": "Ninati",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Yaniruma",
                "kelurahan": "Manggemakhe (Manggemahe)",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ambatkwi (Ambatkui)",
                "kelurahan": "Klofkamp (Kolopkam)",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ninati",
                "kelurahan": "Kawaktembut",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Kanggewot",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Ikcan",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Inggembit (Enggembit)",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Waropko",
                "kelurahan": "Jetetkun / Yetetkun",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Yaniruma",
                "kelurahan": "Fefero (Fefeko)",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Kombay",
                "kelurahan": "Dema",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ambatkwi (Ambatkui)",
                "kelurahan": "Awaken (Nyum)",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ambatkwi (Ambatkui)",
                "kelurahan": "Anyumka",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Ambatkwi (Ambatkui)",
                "kelurahan": "Arimbit",
                "kodepos": "99664"
            },
            {
                "kecamatan": "Bomakia",
                "kelurahan": "Uni",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Ujung",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Bomakia",
                "kelurahan": "Somi",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Mandobo",
                "kelurahan": "Sohokanggo (Sokanggo)",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Sadar",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Sohokanggo",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Navini",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Mandobo",
                "kelurahan": "Persatuan",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Patriot",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Mandobo",
                "kelurahan": "Mariam",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Mandobo",
                "kelurahan": "Mawan",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Makmur",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Kouh",
                "kelurahan": "Mandobo",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Hello",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Maju",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Hamkhu",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Ginggimop (Ginggimob)",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Bomakia",
                "kelurahan": "Bomakia Satu (I)",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Domo",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Bukit",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Bomakia",
                "kelurahan": "Bomakia Dua (II)",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Fofi",
                "kelurahan": "Bangun",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Aroa",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Arimop",
                "kelurahan": "Arimbet",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Mandobo",
                "kelurahan": "Ampera",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Bomakia",
                "kelurahan": "Aifo",
                "kodepos": "99663"
            },
            {
                "kecamatan": "Sesnuk",
                "kelurahan": "Yomkondo",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Iniyandit",
                "kelurahan": "Wariktop (Wariktoop)",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Umap",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Wanggat Kibi",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Sesnuk",
                "kelurahan": "Sesnuk",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Tinggam",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Iniyandit",
                "kelurahan": "Tetop",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Osso",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Iniyandit",
                "kelurahan": "Ogenetan (Ogenathan)",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Niyimbang",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Kombut",
                "kelurahan": "Mokbiran",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Mindiptana",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Iniyandit",
                "kelurahan": "Langgoan",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Kombut",
                "kelurahan": "Kawangtet",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Kamka",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Sesnuk",
                "kelurahan": "Kanggup",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Epsembit",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Imko",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Kakuna",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Kombut",
                "kelurahan": "Awayanka",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Awayanka",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Iniyandit",
                "kelurahan": "Autriop",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Sesnuk",
                "kelurahan": "Anggamburan",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Anggumbit",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Mindiptana",
                "kelurahan": "Andopbit",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Kombut",
                "kelurahan": "Amuan",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Sesnuk",
                "kelurahan": "Amboran",
                "kodepos": "99662"
            },
            {
                "kecamatan": "Ki",
                "kelurahan": "Watemu",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Subur",
                "kelurahan": "Waghai (Bhakti)",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Ki",
                "kelurahan": "Ujungkia",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Ki",
                "kelurahan": "Obinangge",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Subur",
                "kelurahan": "Subur",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Miri",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Ki",
                "kelurahan": "Metto",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Kapogu",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Subur",
                "kelurahan": "Kaisa (Kaisah)",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Kouh",
                "kelurahan": "Jair",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Butiptiri",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Getentiri",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Anggai",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Jair",
                "kelurahan": "Asiki",
                "kodepos": "99661"
            },
            {
                "kecamatan": "Subur",
                "kelurahan": "Aiwat",
                "kodepos": "99661"
            }
        ],
        "k295": [
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Wenda Asri",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Sota",
                "kelurahan": "Yanggandur",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Animha",
                "kelurahan": "Wayau (Wayao/Wajau)",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Telaga Sari",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Sota",
                "kelurahan": "Toray (Torray)",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Sumber Mulia",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Suka Maju",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Sota",
                "kelurahan": "Sota",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Animha",
                "kelurahan": "Senegi",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Rawasari",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Salor Indah",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Sota",
                "kelurahan": "Rawa Biru",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Poo",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Onggari",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Padang Raharja",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Nalkin",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Obathrow",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Melin Megikar",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Mimi Baru",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Makarti Jaya",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Kurik",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Kumbe",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Animha",
                "kelurahan": "Koa",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Kamno Sari",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Kartini",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Kaliki",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Kaiburse (Kaliburse)",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Animha",
                "kelurahan": "Kaisah",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Jemunaen Jaya (Jemunaim Jaya)",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Jaya Makmur",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Ivi Mahad",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Jagebob Raya",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Kurik",
                "kelurahan": "Harapan Makmur",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Sota",
                "kelurahan": "Erambu",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Gurinda Jaya",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Malind",
                "kelurahan": "Domande",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Animha",
                "kelurahan": "Baad",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Blandin Kakayo",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Jagebob",
                "kelurahan": "Angger Parmegi (Permegi)",
                "kodepos": "99656"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Yamuka",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Yeraha",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Ilyawab",
                "kelurahan": "Wogekel (Wogikel)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Woner",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Wetau",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Wanggambi",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Ilyawab",
                "kelurahan": "Wanam",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Wanam Kem",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Waan (Wan)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Turiram",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Tor",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Teri",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Tabonji",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Sibenda",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Suam",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Sabudom (Sabudon)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Sabon",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Ilyawab",
                "kelurahan": "Padua",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Mambum (Mambai)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Konorau",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Kumbis",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Konjombando",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Komolom",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Kladar",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Kiworo",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Waan",
                "kelurahan": "Kawe",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Kalilam",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Kaimaan (Kimaam)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Iromoro",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Deka",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Ilyawab",
                "kelurahan": "Bibikem",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Kimaam",
                "kelurahan": "Banli",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Bamol Satu (I)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Yowit (Yowied)",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Tabonji",
                "kelurahan": "Bamol Dua (II)",
                "kodepos": "99655"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Woboyo",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Ngguti (Nggunti)",
                "kelurahan": "Yawimu",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Wambi",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Wamal",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Welbuti",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Ngguti (Nggunti)",
                "kelurahan": "Taga Epe",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Sanggase",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Ngguti (Nggunti)",
                "kelurahan": "Po Epe",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Ngguti (Nggunti)",
                "kelurahan": "Salamepe (Salam-Epe)",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Okaba",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Ngguti (Nggunti)",
                "kelurahan": "Nakias",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Kaptel",
                "kelurahan": "Kanis Kobat",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Kaptel",
                "kelurahan": "Kaptel",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Kaptel",
                "kelurahan": "Kwemzied (Kwemzid)",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Makaling",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Kaptel",
                "kelurahan": "Ihalik",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Iwol",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Dufmira (Dufmiraf)",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Dokib",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Tubang",
                "kelurahan": "Dodalim",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Alaku",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Okaba",
                "kelurahan": "Alatep (Alatepi)",
                "kodepos": "99654"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Tof-Tof (Taftof)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Wan (Waan)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Tanas (Tanaas)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Selil",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Sigabel Jaya",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Sipias",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Seed Agung (Sedayu Agung Prasasti)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Selauw",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Nggayu",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Rawahayu",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Pachas",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Muting",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Manway Bob (Man Wai Bob)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Metaat Makmur (Mantaat Makmur)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Mandekman",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Kweel",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Kumaaf",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Kireli",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Kolam",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Kandrakai",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Kandrakai",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Geriser (Gerisar)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Kafyamke",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Enggol Jaya (Elanggol Jaya)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Enggal Jaya",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Efkab Makmur (Metaat Makmur)",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Bupul",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Bupul Indah",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Bunggay",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Bouwer",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Eligobel",
                "kelurahan": "Bumun",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Boha",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Baidub",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Ulilin",
                "kelurahan": "Belbeland",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Muting",
                "kelurahan": "Andaito",
                "kodepos": "99652"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Yabamaru",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Yasa Mulya (Joso Mulia)",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Waninggap Say",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Waninggap Nanggo",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Waninggap Miraf",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Urumb",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Waninggap Kay/Kai",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Soa",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Sumber Harapan",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Tambat",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Semangga Jaya (Semanggi Jaya)",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Sidomulyo",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Nggutibob (Ngunti Bob)",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Sarmayam Indah (Sermayam)",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Matara",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Muramsari",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Kuprik",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Marga Mulya",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Semangga",
                "kelurahan": "Kuper",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Hidup Baru",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Isanombias",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Amunkai (Amun Kay)",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Tanah Miring",
                "kelurahan": "Bersehati",
                "kodepos": "99651"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Tomerau",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Tomer",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Tomer",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Nasem",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Onggaya",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Mandala",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Kuler (Tunas Baru)",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Naukenjerai",
                "kelurahan": "Kondo",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Bambu Pemali",
                "kodepos": "99616"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Samkai (Karang Samkai)",
                "kodepos": "99615"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Maro",
                "kodepos": "99613"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Karang Indah",
                "kodepos": "99614"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Kelapa Lima",
                "kodepos": "99612"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Rimba Jaya",
                "kodepos": "99611"
            },
            {
                "kecamatan": "Merauke",
                "kelurahan": "Wasur",
                "kodepos": "99611"
            }
        ],
        "k296": [
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yawan",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yutanggo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yanamik",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yangkikalma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yambaikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yahatma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yama",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Yabusup",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wurupikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wutlarin",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Worol",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wileroma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wiltlanggo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wenemikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wilak",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Waroham",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wanggibo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wangkun",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wamhor",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wandikbongkion",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wambo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wambal",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wambalfak",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Walagima",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Wabuhuk",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Waghasilimo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Uwambo Satu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Ulusi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Usabiye",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Ulun",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Tagabaga",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Uluhufuk",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Suminaikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Suahe",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Suele",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sumbule Satu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sombule",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sohi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sohombunu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sengikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sebi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Salohe",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sapiwarek",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Salo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sabilironggo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Sahikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Pirikalem",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Pamumu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Obabin Satu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Obabin",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Noholuok",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Nilbo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Nofualma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Nenomani",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Musanahikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Mulip",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Moban",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Maleo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Mahial",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Lilukikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Lohomabel",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Lilinmuhuk",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Lasikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Langongkok",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Kolaima",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Landikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Klesu",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Kesuwi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Kewi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Kawokholik",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Kandibung",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Jinggiwi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hurualma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Iwim",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hundilip",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Humalma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hulikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hulhule",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hubliki",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Horenikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Hombanman",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Horakia",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Helaksili",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Heahobak",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Halisek",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Fuahe",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Fialem",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Fabulik",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Eliekma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Elesim",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Dombomi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Dosumo",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Detpagado",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Dahonaikma",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Burim",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Bumbum",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Bonggi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Bukurik",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Bonahik",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Arikbalek",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Beim",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Beyam",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Alugi",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Amuki",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Abagima",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Abenaho",
                "kelurahan": "Abenaho",
                "kodepos": "99587"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Yarema",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Yarema Satu",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Wiyukwilik",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Wiralesili",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Wanamalo",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Wasalalo",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Tembut",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Tikano",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Sobikambut",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Suerelihim",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Sabilikalem",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Sabualo",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Sien",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Pong",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Pipisim",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Naswer",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Natoksili",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Nohonil",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Nasinena",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Nanahoruk",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Malinwarek",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Makrig",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Moliyinggi",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Lambukmu",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Lilinsaluk",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kulet",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kundikele",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kengggembun",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kilal",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kinhe",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kayoma",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Kelampurim",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Humalen",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Ifkindi",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Holuokalem",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Hubliki",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Hukalopunu",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Holik",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Holuaogalma",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Hinongkoambut",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Fari",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Filiyahik",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Habalo",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Eal",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Falukwalilo",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Bablik",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Benyam",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Apalapsili",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Asilikma",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Yahamer",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Apalapsili",
                "kelurahan": "Alimuhuk",
                "kodepos": "99586"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Werenggik",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Wompoli",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Welarek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Welearekpunu",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Wasupahik",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Ulum",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Walingkapma",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Wangkulamsafiang",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Ubalihi",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Suntam",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Tinmuhuk",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Suntam",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Soharam",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Sukalimi",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Selek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Silfal",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Sinahal",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Seherek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Selebi",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Saly",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Samaria",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Sakam",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Salema",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Poholanggen",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Poik",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Poik Satu",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Pisanggo",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Panalulun",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Pirang",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Pami",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Panal",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Mohonu",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Montek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Nohonil",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Menepini",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Mohobiye",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Loholan",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Mabualem",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Mamioan",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Koum",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Langam",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Lasik",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Kayalem",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Kayo",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Kampol",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Hobut",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Holowi",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Irarek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Hilariki",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Hindalimukuk",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Halialo",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Halisek",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Helebol",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Fikfak",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Folongsili",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Funui",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Amuluk",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Dikohobari",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Feingkama",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Ambiliki",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Welarek",
                "kelurahan": "Ampolongsili",
                "kodepos": "99585"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Yabema",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Yakikma",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Werekma",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Wara",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Warikma",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Wasua",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Wagagu",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Walkep",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Tanabasi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Ubi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Ulo",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Sira",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Sowi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Subuiruinggama",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Sipsoi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Sipson",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pungkahik",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Sili",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pisireg",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pihel",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pirip",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pangkik",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Pasimbolo",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Ohobam",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Ohoniam",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Momonhusi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Lapora",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Maribu",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Molinggi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Keron",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Kwikma",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Honita",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Isila",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Hobakma",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Holani",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Habiyeambut",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Helabu",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Hesmat",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Emon",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Eregi",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Elelim Satu",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Elilem",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Duwong",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Elelim",
                "kelurahan": "Aluis",
                "kodepos": "99584"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Wirsa",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Yakwa",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Wermas",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Wibi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Welap",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Thamaksin",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Trikora",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Prititi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Siwa",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Pepera",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Pinampi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Pisol",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Paluke",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Pensale",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Narau",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Niyawi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Naira",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Merseng",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Maksaram",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Makrip",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Lulum",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Lawe",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Kutakuruk",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Kukdomol",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Konobun",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Key",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Karamina",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Kapawa",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Kamika",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Iyap",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Himi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Ikon",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Dukunasi",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Gilika",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Benawa Satu",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Burukuku",
                "kodepos": "99583"
            },
            {
                "kecamatan": "Benawa",
                "kelurahan": "Ampera",
                "kodepos": "99583"
            }
        ],
        "k297": [
            {
                "kecamatan": "Walma",
                "kelurahan": "Yetehup",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Yaminikma",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Yangkali",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Yalmun",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Wisomy (Wisomi)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Wubri",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Yaholikma",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Wewasi",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Wewasi",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Werene",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Weri",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Weresili",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Walmak",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Wanan (Wanam)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Welile",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Walma",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Walahan",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Vuno",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Wahe",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Ubalihion",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Ubahak",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Uldam",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Tulpa",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Tonggoi",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Tukam",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Tohong",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Tolombing",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Tiple",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Tapla",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Talambela (Talempela)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Tibul (Timbul)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Suron",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Susek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Suahi",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Sosonikma (Sosomikma)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Suhuntek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Siwikma",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Solinggul (Solingul)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Silong",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Silkom",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Serengan",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Dirwemna (Diruwena)",
                "kelurahan": "Silion",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Sentul",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Sehelu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Semlu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Samiyul",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Saruk",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Dirwemna (Diruwena)",
                "kelurahan": "Salengkomo",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Salakma",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Puldama",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Pue",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Pontempilik (Pontenpelek)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Pronggoli",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Potenikma (Pontenikma)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Piliam",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Pilong",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Pini",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Panggema",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Palukae",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Pelentum (Palentum)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Paliptek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Paleroman",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Oklahama",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Ohena",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Nipsan",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Nurap",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Oblin",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Niniwi",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Nelisa",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Nalca",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Muhumu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Mine",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Nahomas",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Membaham (Mimbaham)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Menetsing",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Manggona",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Maniek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Masahan",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Mabien",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Manbolak",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Lulum",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Luwemna",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Lirutek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Lelambo Dua (II)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Letin",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Letfet",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Lelambo",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Lelambo",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Kouwet",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Kuni",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Konosa",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Kosarek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Koluang",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kona",
                "kelurahan": "Kona (Konda)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Kiltam",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Kolu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Kinkun",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Kibianggen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Kikila",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Keleng",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Kemumanggen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Kasen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Kabukal",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Imsekla",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Ilukdamon",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Ilwap",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Ikmu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Illion",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Huhulpunu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Hontulun",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Homtongo",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Walma",
                "kelurahan": "Honggoluk",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Kosarek",
                "kelurahan": "Hombuka",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Anggruk",
                "kelurahan": "Hohe",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Homboki",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Hiklahin",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Hoet",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubalihi",
                "kelurahan": "Hereki (Hereky)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Hereapini (Hereanini)",
                "kelurahan": "Hereapini (Heriapini)",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Pronggoli (Proggoli)",
                "kelurahan": "Helapilik",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Hein",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Goak",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Furuluk",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Galsin",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Gidomen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Emm",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Endomen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Famek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Dirwemna (Diruwena)",
                "kelurahan": "Dirwemna",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Dirwemna (Diruwena)",
                "kelurahan": "Dolsen",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Duram",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Dengat",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Duram",
                "kelurahan": "Digitme",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Endomen",
                "kelurahan": "Delon",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Bolda",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nalca",
                "kelurahan": "Cenderawasih",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Nipsan",
                "kelurahan": "Biu",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Ubahak",
                "kelurahan": "Bisifak",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Panggema",
                "kelurahan": "Bineluk",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Baru",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Talambo",
                "kelurahan": "Bari",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Balsek",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Puldama",
                "kelurahan": "Bako",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Dirwemna (Diruwena)",
                "kelurahan": "Aksal",
                "kodepos": "99582"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Yup",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Yolar",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Yaruhuk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Yamin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Yamdua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Yalmabi",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Yalisomon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Yalidamon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Yabi (Jabi)",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Weni",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Wemin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Webile",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Weawen (Weanem)",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Wasumurji",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Wanim",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Wahaik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Waerek",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Usabini",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Tapasik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Yahuliambut",
                "kelurahan": "Tanggeam",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Sulda",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Suntamon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Yahuliambut",
                "kelurahan": "Suksale",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Yahuliambut",
                "kelurahan": "Subundalek",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Subayo",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Sosi",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Somsek",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Soline",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Sombat",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Sohwal",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Sohonggik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Sohobma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Sohondipmu",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Sohal",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Sobaham",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Soamrin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Singokla",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Simbuk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Silakma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Silak Pahabol",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Sia",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Sesepne",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Senayom",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Serahak",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Sele",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Sela",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Salbi",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Phoy",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Puahuun",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Palamdua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Orisin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Obis",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Oakbisik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Ninia",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Niniahan",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Morome",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Musan",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Moo",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Monin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Monamna",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Mondon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Momenggama",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Mekdua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Masumkon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Maldua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Megom (Megon)",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Makero",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Lukun",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Lolat",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Yahuliambut",
                "kelurahan": "Linggisen",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Liligan",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Liligan II",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Langda",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Kwelena",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Laryi",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Kwelemdua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Kulahaik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Kubialat",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Kubuk Dua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Kitikni",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Koropun (Korupun)",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Kirabuk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Kini",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Kenkeni",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Kemligin",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Karelma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Kap-Kap",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Kabulan",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Kahul",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Kabianggama",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Hwealoma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Husmo",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Hubik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Horomon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Homidipmu",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Holdomen",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Holuwon (Holuon)",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Hilipuk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Hemuka",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Hamik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Esalek",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Erim",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Ejub",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwikma",
                "kelurahan": "Enggelasue",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Dolbol",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Domul",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Dohong",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Dirik",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Dinggila",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Hilipuk",
                "kelurahan": "Dindok",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Dildau",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Denema",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Debula",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Dagi",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Danggema",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Lolat",
                "kelurahan": "Bunde",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Bulbul",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Buahun",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Bongkok",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Bomela",
                "kelurahan": "Bomela",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Holuon",
                "kelurahan": "Bineleruk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Suntamon",
                "kelurahan": "Binalkon",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Bera",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Batkuk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Benembia",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Bebekle",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Basal",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Soloikma",
                "kelurahan": "Banga",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kwelemdua (Kwelamdua)",
                "kelurahan": "Baluk",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sobaham",
                "kelurahan": "Balinggama",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Kabianggama (Kabianggema)",
                "kelurahan": "Bahbolma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Ninia",
                "kelurahan": "Babet",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Sela",
                "kelurahan": "Asok",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Yahuliambut",
                "kelurahan": "Amfulma",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Koropun (Korupun)",
                "kelurahan": "Amdua",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Langda",
                "kelurahan": "Alirji",
                "kodepos": "99578"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Yuarima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Yogosem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Yosua",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Yeriko",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Yerekama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Yelepunu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Yeleas",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Yasip",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Yalimo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Yahufa",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Yagarelo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Yagabo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Wusagasem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Wulin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Wulik Punua",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Wubialo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Worapi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Wisikma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kona",
                "kelurahan": "Wetmuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Wirilu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Wesangma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Wesama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Wesagalep",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Weriaput",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Werima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Wendame",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Wenapuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Wenapong",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Wemasili",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Weleheikma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Weakma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Wea",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Wanesi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Wanem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Wamarek",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Walkuma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Walkruk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Walet",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Walialema",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Waeklek",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Userem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Wakunyama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Usaregeik (Usaragek)",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Ukha",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Ujin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Ugulintipmu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Ugem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Uase",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Tukuarek",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Uam",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Toman I",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Toman II",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Tolikapura (Talikapura)",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Tokuni",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Togoluk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Teret",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Tanda",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Tangma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Surukmu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Suok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Suok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Sumo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Sumegen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Sumegen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Suesi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Suet",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Sowaling",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Subsal",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Sokamu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Soroh",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Solok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Sodule",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Sogasio",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Soba",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Sipnidipmu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Simeka",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Siolorema",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Silorin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Silimo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Siloma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Silon",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Senipan",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Sianele",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Seredela",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Sengsenagaik",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Sengangge",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Seima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kona",
                "kelurahan": "Seklak",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Sebu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Sebin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Sangatur",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Sarmuge",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Samboga",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Samenage",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Sagasal",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Saikama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Rentis",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Sagaduk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Sabin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Pusuaga",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Pupi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Punno",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Pugun",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Pona",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Plau",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Pinda",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Patin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Penigik",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Pasema",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Panyoke",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Parela",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Orugai",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Olomisang",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Paima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Obolma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Obokain",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Notnarek",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Nyikinen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Obio",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Nesuwen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Nilen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Musanarek",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Nekilei",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Munu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Mul",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Muke",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Muara Balim",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Mugi (Mogi)",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Muara",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Moruf",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Mosomduba",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Molama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Momnowok (Momnowak)",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Massi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Mesahaput",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Maruku",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Lugulmu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Lukulema",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Maku",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Lua",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Luarima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Lidipmu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Lowet",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Lokon",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Pasema",
                "kelurahan": "Lemoko",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Libuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Leleak",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Kwakenoro",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Kwiriside",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Kuleken",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kona",
                "kelurahan": "Kugun",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Kuari",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Kuaserama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Kosihun",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Koper",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Kolodoma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Kokamu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Kiroma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Kiribun",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Kinika",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Kilise",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Kima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kona",
                "kelurahan": "Kalpok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Kayo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Keikey",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Jinusugu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Jibak",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Jibuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Itlay Wopi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Ison",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Ilokomo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Ibiroma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Hunisi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Hwesalemo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Huluagaima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Hunenima",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Huken",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Hulesi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Huguma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Hugilokon",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Huglot",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Huewi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Homhom",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Hokolekma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Hombesaloma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Hogio Satu / I",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Hirin",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Hogio Dua / II",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Hihundes",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Hipela",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Hesmat",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Hesmat",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Herawe",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Soba",
                "kelurahan": "Hepinis",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Hepia",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Hendikma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Hendag",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Harap",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Harapan",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Helo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Hanoasuok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Haletuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Haleroma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Hagawen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Suru Suru",
                "kelurahan": "Gofa",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Golowen",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Fabe",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Werima",
                "kelurahan": "Esema Husage",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Esalien",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Esaikma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yogosem",
                "kelurahan": "Esahapuk",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Eroma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Eksa",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Eremnahom",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Eki",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Hogio (Hugio)",
                "kelurahan": "Dugumhad",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Dekbok",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Dekai",
                "kelurahan": "Dekai",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Obio",
                "kelurahan": "Busupa",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Seredela (Seredala)",
                "kelurahan": "Burupmakot",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Sumo",
                "kelurahan": "Boor",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Musaik",
                "kelurahan": "Bue",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Balum Erang",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Samenage",
                "kelurahan": "Asotapo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Silimo",
                "kelurahan": "Asia",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Wusuma",
                "kelurahan": "Apdagma (Apdakma)",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Anjelma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Amuma",
                "kelurahan": "Amuma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Ukha",
                "kelurahan": "Amisangi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kayo",
                "kelurahan": "Alhai",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kurima",
                "kelurahan": "Air Garam",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Tangma",
                "kelurahan": "Aleleng",
                "kodepos": "99571"
            }
        ],
        "k298": [
            {
                "kecamatan": "Pamek",
                "kelurahan": "Yabosorom",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Yokul",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Wakidam",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Teli",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Turwe",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Tanime",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Talemu",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Supleyu",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Serabum",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Sikibur",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Pinggon",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Seban",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Pamek",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Onya",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Perem",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Mungkona",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Omban",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Mandalak",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Marikla",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Lumdakna",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Londinin",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Malingdam",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Lim Lim",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Limiri",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Lalakon",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Laydamban",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Kweredala",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Kolgir",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Kwime",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Kalek",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Kameme",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Imde",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Eipumek / Elpomek (Elpuna)",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Cangdamban",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Bunyirye",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Bunggon",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Calab",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Borme",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Bukam",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Bordamban",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Bob",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Basiringe",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Bime",
                "kelurahan": "Bime",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Baramirye",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Pamek",
                "kelurahan": "Barice",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Eipumek",
                "kelurahan": "Barama",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Aringgon",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Mofinop",
                "kelurahan": "Yubu",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Borme",
                "kelurahan": "Arina",
                "kodepos": "99577"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Youlban (Yolban)",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Yokom",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Yarigon",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Murkim",
                "kelurahan": "Tero",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Mofinop",
                "kelurahan": "Tual",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Weime",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Sople",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Taramlu",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Segame",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Sabi",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Rubol",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Peteng",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Palur Satu",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Oksip",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Omtamur",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Palur Dua",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Nongme",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Nomteren",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Neep",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Mofinop",
                "kelurahan": "Muara Asbi",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Mongham (Mogham)",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Muara",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Mofinop",
                "kelurahan": "Mot / Moot",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Mofinop",
                "kelurahan": "Milki",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Meryang",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Limrepasikne",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Mekdamgon",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Merpasikne",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Kwarban",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Karye / Kariye",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Murkim",
                "kelurahan": "Delemo",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Weime",
                "kelurahan": "Daluban",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Nongme",
                "kelurahan": "Cangpally",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Murkim",
                "kelurahan": "Bumi",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Murkim",
                "kelurahan": "Bias",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Belomo",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Batom",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Batom Dua",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Bakata",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batani",
                "kelurahan": "Batani",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Akyako",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Batom",
                "kelurahan": "Abukerom",
                "kodepos": "99576"
            },
            {
                "kecamatan": "Awinbon",
                "kelurahan": "Yelobib",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Umding",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Ulkubi",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Walapkubun",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Tarngop",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Tarup",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Onkor",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Sakup",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Narnger",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Nenginum",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Omor",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Awinbon",
                "kelurahan": "Mikir",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Awinbon",
                "kelurahan": "Nanum Anaip",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Nanum",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Kurukim (Kurumkim/Kurumklin)",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Marang Tiking",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Kamyoim",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Kamyoim",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Awinbon",
                "kelurahan": "Kawe",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Imsin",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Ewenkatop",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Iwur",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Dinmot Arim",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Iwur (Okiwur)",
                "kelurahan": "Dipol",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Bitipding",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Tarup",
                "kelurahan": "Beten Dua",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Awinbon",
                "kelurahan": "Awinbon",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Arintap",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kawor",
                "kelurahan": "Ater",
                "kodepos": "99575"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Wantem (Wanten)",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Tinibil",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Tomka",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Tatam",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okhika",
                "kelurahan": "Tengnong",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksebang",
                "kelurahan": "Sebul",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Pomding",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Sopamikma",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Paune",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Okyop (Okyob/Okyaop)",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Pelebib",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Okyako",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Okyaop",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Oktumi",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okhika",
                "kelurahan": "Okteneng",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Oktem",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Oktau",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Oktae",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Oktaru",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Okpa",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Okngam",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Oknanggul",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Okma",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Oklip",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Okhim",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Okhik (Oklip)",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Okhaka",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okhika",
                "kelurahan": "Okelwel",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Oketur",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Okdilam",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Okdunam",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Okbumul",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Okbem",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksebang",
                "kelurahan": "Okano",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Okamin",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Okalut Kumal",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksebang",
                "kelurahan": "Mangabip",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Mangoldoki",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Kukihil",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksebang",
                "kelurahan": "Kubiphkop (Kubibkop)",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Lolim",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okhika",
                "kelurahan": "Kotyobakon",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oklip",
                "kelurahan": "Komok",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Kaep",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Kiwi",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Ehiptem (Ehipten/Ohiptem)",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Honkuding",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok Timur",
                "kelurahan": "Dikdon",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Diip",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Bilip Bayo",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Delpem",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Bomding",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Bemhimiku",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Berusaha",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Oksamol",
                "kelurahan": "Autpahik",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Okbemtau",
                "kelurahan": "Atang Doki",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Kiwirok",
                "kelurahan": "Asua",
                "kodepos": "99574"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Yumakot",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Yun Muku",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Yakmor",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Yapimakot",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Wanbakon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Wok Bakon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Tibal",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Kalomdol",
                "kelurahan": "Tulo",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Tapasik",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Tapob",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Tapasik Dua",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Siminbuk",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Sumtamon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Seramkatop",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Polsam",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Payol Masumkon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Pepera",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Parim",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksop",
                "kelurahan": "Oktumi",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Okdo",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksop",
                "kelurahan": "Oksop",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Okatem",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Ok Telab",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Okbon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Serambakon",
                "kelurahan": "Modusit",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Muruman",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Molbib Silibib",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksop",
                "kelurahan": "Mimin",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Limarum",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Mabilabol",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Masim",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Kungulding",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Kutdol",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Kabiding (Betaabib)",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Kalomdol",
                "kelurahan": "Kalomdol (Kalondoi)",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Kasawi",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Imiryi",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Kalomdol",
                "kelurahan": "Imik",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Eraduman",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Ibot",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Denom",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Bunamdol",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Kalomdol",
                "kelurahan": "Dabolding",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Bulangkop",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Bondik",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Pepera",
                "kelurahan": "Bon Yakwol",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Binalkom",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Bape",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Bakwalin Yub",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Kalomdol",
                "kelurahan": "Arinkop",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksop",
                "kelurahan": "Atenor / Atenar",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Ok Aom",
                "kelurahan": "Aplim",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksop",
                "kelurahan": "Alutbakon",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Alemsom",
                "kelurahan": "Alemsom",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Okbape",
                "kelurahan": "Akmer",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Aldom",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Oksibil",
                "kelurahan": "Akmakot",
                "kodepos": "99573"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Yuaban Satu",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Yitarget",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Yuaban Dua",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Yapil",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Tupoplyom",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Woro",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Teriame",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Tupalma Dua",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Tupalma Satu",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Teiraplu",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Terapdei",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Sabin",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Pipal",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Sinami / Sinani (Yuaban)",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Pedam",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Peneli",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Omliom",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Oksemar",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Oktanglap",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Okaplo",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Okbifisil (Okbifisis)",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Murme",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Manunggal (Okipur)",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Markom",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Maigame",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Maksum",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Luban",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Lulis Wii",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Lulis",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Kirimu",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Kalimbu",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Jetfa",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Jubly",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Dumpasi",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Iriding",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Jetfa",
                "kelurahan": "Bris",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Borban",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Teiraplu",
                "kelurahan": "Bautme",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Atoldol (Atolbol)",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbab",
                "kelurahan": "Atembabol",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Aboy",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Aboy",
                "kelurahan": "Armise",
                "kodepos": "99572"
            },
            {
                "kecamatan": "Okbibab",
                "kelurahan": "Abmisibil (Apmisibil)",
                "kodepos": "99572"
            }
        ],
        "k299": [
            {
                "kecamatan": "Yal",
                "kelurahan": "Yimogi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Yibi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Yebandui",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Yal",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Yaba",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Wolmbam",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Wanduama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Undo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Suelama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Sitdobo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Samba",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Putdumu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Pukle",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Paruarem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Palpam",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Muruldumu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Mugiarem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Mam",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Laruid",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Lambule",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Lakda",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Kibo",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Kibiaren",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Gularem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Girimnak",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Gimi",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Diringgama",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Dengpa",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Dendamba",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Mam",
                "kelurahan": "Darakma",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Dangenpem",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Biledumu",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Yal",
                "kelurahan": "Betbere",
                "kodepos": "99571"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Yunat",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Yike",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Embetpen",
                "kelurahan": "Yenai",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Yeretma",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Yepma",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kora",
                "kelurahan": "Wilimi",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wusi",
                "kelurahan": "Wusi",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Embetpen",
                "kelurahan": "Wendama",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Weneworarosa",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kora",
                "kelurahan": "Wenam",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Urugi",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Ulunmu",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Tribit (Tribid)",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Tribit (Tribid)",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Tombisik",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Sokore",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Tobonggom",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Sralala",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pija",
                "kelurahan": "Sitgama",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wosak",
                "kelurahan": "Sitpogol",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Simie",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Sagapusatu",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pija",
                "kelurahan": "Siat",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Pirim-Pirim",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Rapa",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wosak",
                "kelurahan": "Pilini",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Pijama",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wusi",
                "kelurahan": "Pijemae",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Pasir Putih",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pija",
                "kelurahan": "Pija",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Mbua Tengah",
                "kelurahan": "Paris",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pija",
                "kelurahan": "Nganai",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kora",
                "kelurahan": "Nanggoal",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wosak",
                "kelurahan": "Ndugwa (Ndukwa)",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pija",
                "kelurahan": "Murupbaye",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Mumugu",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Moba",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Moba Dua",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Mandala",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Langpang",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Krepkuri",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Kwegono",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Kibid",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kora",
                "kelurahan": "Kora",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Kemali",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Kenyam",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Gunea (Gunia)",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wusi",
                "kelurahan": "Ginigit",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wusi",
                "kelurahan": "Gimigip",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Ginid",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Gebem",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Gilingga",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Gearek",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Dim",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Gearek",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Delpel",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Embetpen",
                "kelurahan": "Digilimu",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kora",
                "kelurahan": "Brutnai",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Gearek",
                "kelurahan": "Bomegi",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kenyam",
                "kelurahan": "Bombam",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Embetpen",
                "kelurahan": "Bisikimu",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wosak",
                "kelurahan": "Biala",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Benggem",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Wosak",
                "kelurahan": "Bambisik",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Banggabeak",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Moba",
                "kelurahan": "Anggulpa",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Krepkuri",
                "kelurahan": "Alguru",
                "kodepos": "99565"
            },
            {
                "kecamatan": "Kilmid",
                "kelurahan": "Yutpul",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Yuguru",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Yoe",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Yelsengge",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Yigi",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yenggelo",
                "kelurahan": "Yenggelo",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Yebadolma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Wutpaga",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Wundui",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Wuone",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Wumaga",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yenggelo",
                "kelurahan": "Wuarem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nenggeagin",
                "kelurahan": "Wulagumi",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Womsit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Wiritlak",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Wipdumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Wangun",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nenggeagin",
                "kelurahan": "Wenggenambut",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Uenoma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Urugom",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Unue",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Tinigele",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Uburu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Talpam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Geselma (Geselema)",
                "kelurahan": "Talem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Paro",
                "kelurahan": "Tawelma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Sumbo",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Suwenem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Sirit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Simiye",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Dal",
                "kelurahan": "Silankuru",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Dal",
                "kelurahan": "Silan",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Siginimarem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Sigmie",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Setmit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Sigimbut",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Sarit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Sanwaruk",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Sambua",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Sabiem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kilmid",
                "kelurahan": "Puruwa",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Peya-Peya",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Pereki",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Pesat",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Paro",
                "kelurahan": "Paro",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Pasebem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Paru",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Otalama",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Palsam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Opmu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Osama",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Oldobo",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Nowomlusu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Olunmu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yenggelo",
                "kelurahan": "Nolo",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Alama",
                "kelurahan": "Nolit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Nirigimbirik",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Nirkuri",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nenggeagin",
                "kelurahan": "Neggea",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nenggeagin",
                "kelurahan": "Nenggeagin",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Ngenamba",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Narugu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Narugum",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Muli",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Mirikil",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Mugi",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Miniem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Miri",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Mbua (Mbuwa)",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Meborok",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yenggelo",
                "kelurahan": "Maima",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Manet",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Mapenduma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Lunggadumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Lumbukdumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Wutpaga",
                "kelurahan": "Luaren",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Paro",
                "kelurahan": "Loaraba",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Paro",
                "kelurahan": "Lombrik",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Likubuk",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Litkui",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Kusit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Labrik",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Lemurak",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Kurigi",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Kumbuklema",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Kumbun",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Alama",
                "kelurahan": "Kulesa",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Geselma (Geselema)",
                "kelurahan": "Kosolpem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Kroptak (Koroptak)",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Komoroam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nenggeagin",
                "kelurahan": "Kombama",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Kosobak",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Kolma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Kogomaru",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Kokujondumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Klabypma (Kiabypma)",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kilmid",
                "kelurahan": "Kilmid",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Kibisim",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Kigam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Kemamburu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Keret Anggoma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Kibendumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Kelapa Dua",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Karunggame",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Kagayem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Kabolilangen",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Kabolilangen",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Iniye",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Ininkwaning",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Isiklak",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Dal",
                "kelurahan": "Gurumbe",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Gulama",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Dal",
                "kelurahan": "Grinbun",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Go",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Koroptak",
                "kelurahan": "Golparek",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Gisarok",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Alama",
                "kelurahan": "Gin",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Gilpid",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Geselma (Geselema)",
                "kelurahan": "Geselma (Geselema)",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kilmid",
                "kelurahan": "Gemam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Eregenmanggal",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Endumu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Embuklem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Ekilapok (Ekilapo)",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Dumdum",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mapenduma",
                "kelurahan": "Duma",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Dondoklema",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Dobopem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Disendobo",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Digilimu",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Delsa",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mugi",
                "kelurahan": "Delit",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Dal",
                "kelurahan": "Dal",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbulmu Yalma",
                "kelurahan": "Brambel",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Dakbri",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Yigi",
                "kelurahan": "Borot",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Biripem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Bone",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Nirkuri",
                "kelurahan": "Binime",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Kegayem",
                "kelurahan": "Bambi",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Iniye",
                "kelurahan": "Baklema",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mbua (Mbuga)",
                "kelurahan": "Arugia",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Area",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Mebarok",
                "kelurahan": "Aptam",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Paro",
                "kelurahan": "Animarem",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Alama",
                "kelurahan": "Alama",
                "kodepos": "99564"
            },
            {
                "kecamatan": "Inikgal",
                "kelurahan": "Abualak",
                "kodepos": "99564"
            }
        ],
        "k300": [
            {
                "kecamatan": "Wina",
                "kelurahan": "Yugubuk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Yugumengga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Yoka",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Yonira",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Yowo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Yogweme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Yogweme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Yandono",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Yiku",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Yanora",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Yaleme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Yali",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Wulinaga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Yagagobak",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Wobe",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Woraga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Winengga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Wina",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Weri",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Wiki",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Wenigi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Wekaru",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Wayongga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Warna",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Warka",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Wanuwi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Wariru",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Wanuwi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Wamili",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Wamolo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Wakumendek",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Vokuyo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Umar",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Umagi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Tioner",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Tirib",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Tigu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Tioga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Timoga/Kabupaga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Tigir",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Teleme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Telenggeme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Tenek",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Tawi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Telekonok",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Takri",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Tabunakme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Tabonakme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Tabuh",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Sigou",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Punggelak",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Saksi Maler",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Siak",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Popaga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Prawa",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Piriluk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Pirage",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Pinde",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Pindanggun",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Pekaleme",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Panaga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Papedari",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Pakare",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Pagongga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Paido",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Oker",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Nugini",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Nolopur",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Nugari",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Nilogabu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Nogari",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Nangga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Nambu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Nakwil",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Muruneri",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Nakwi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Muna",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Murni",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Miyanagame",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Mino",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Membramonggen",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Minegimen",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Mamit",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Malela",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Makido",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Luki",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Mabuk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Liwina",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Logulo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Linggira",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Lawor (Lawori)",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Kwa",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Kutiom",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Laniloma",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Kurupu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Kuruku",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Kurik",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Kuoklanggunik",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Kurba",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Kowari",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Kungge",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Konengga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Kotorambur",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Koinggambu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Kobon",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Kimunuk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Kembu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Kimugu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Kembu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Karu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Kalibu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Kaliundi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Kalarin",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Itoli",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Kabori",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Kagi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Holandia",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Ibunuh",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Gurin",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Gwak Dugunik",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Gualo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Gubuk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Gumbini",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Gingga",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Giko",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Gindugunik",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Genani",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Geneluk",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Genage",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Gelok",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Geka",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Umagi",
                "kelurahan": "Gatini",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Gagulineri",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Friji",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Finai",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Eragani",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Panaga",
                "kelurahan": "Eragani",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Egiam",
                "kelurahan": "Egiam",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Enggawogo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Dustra/Yamiriko",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Dundu",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Dugunagep",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Dow/Bijere",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Dotori",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Dorera",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Dolunggun",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gika",
                "kelurahan": "Dimbara",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Dagari",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Bolubur",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Poganeri",
                "kelurahan": "Bogokila",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dow",
                "kelurahan": "Bire",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Bimo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Bimbogul",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Beleise",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Beremo",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Timori",
                "kelurahan": "Bawi",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Aworera",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Gundagi (Gudage)",
                "kelurahan": "Aworera",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Telenggeme",
                "kelurahan": "Aukuni",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wari (Taiyeve)",
                "kelurahan": "Arebe/Ninggame",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Yuko",
                "kelurahan": "Ambirik",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wina",
                "kelurahan": "Akima",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Kembu",
                "kelurahan": "Agimdek",
                "kodepos": "99569"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Yigonime",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Yelly",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Yebena",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Yebena",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Yamulo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Yaliwak",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Yakep",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Wunabu",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Wumelak",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Wuluk",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Woyi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Wondame",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Wonoluk",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Wonitu",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Wisman",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Wilileme",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Weyage",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Wewo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Welesi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Wenggun",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Wama",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Walelo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Wamigi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Undi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Tunibur",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Towolome",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Tiyonggi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Tinggom",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Tinoweno",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Timer",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Timobur (Kimobur)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Timopur",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Timbindelo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Teropme",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Talinamber",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Purugi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Posman",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Pagona",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Paba",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Orelukban",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Numbe",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Nunggawi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Dundu (Ndundu)",
                "kelurahan": "Nini",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Nombori",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Nowo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Nabunage",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Mome",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Mololowa",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Martelo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Missa",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Lugwi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Logon",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Marlo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Logilome",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Ligiibak / Ligimbak (Lagimbak)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Lerewere",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Kwarini",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Laura",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Kutime",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kuripaga (Kuripara)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Kupara",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Kumbur",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Kumbo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Kulutin",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kubalo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Korlo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kondangwi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Konipaga",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kononda (Kokondao)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Kogagi",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Kolanggun",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kilungga",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kipino",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Kimilo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Kerena",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kanggineri",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Kanggime",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Kagimaluk",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Kabomangen (Kubumangen)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Gulak",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Gunalo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Jekito",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wakuwo",
                "kelurahan": "Golena",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Gilo",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nabunage",
                "kelurahan": "Geningga",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Ewan",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Egoni",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Dundu",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Delelak",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Derek",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Wonoki (Woniki)",
                "kelurahan": "Bugum",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Berenggo (Berengga)",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Bogonuk",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Nunggawi (Munggawi)",
                "kelurahan": "Belep",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Gilubandu (Gilumbandu/Gilimbandu)",
                "kelurahan": "Baguni",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Kanggime (Kanggima )",
                "kelurahan": "Aulani",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Aliduda",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Bogonuk",
                "kelurahan": "Andomak",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Aweku",
                "kelurahan": "Agin",
                "kodepos": "99568"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Yuneri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Yugumena",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Yugu Mabur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Yudimba",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Yiragame",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Yiyogobak/Kibur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Yiluk/Kondenggun",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Yilogonime/Tabinabo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Yemarima (Yemarma)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Yawineri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Yido",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Yalipura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Yalokobak",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Yalogo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Yalikaluk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Wuronggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Wurineri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Wunggilipur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Wugi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Wunggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Wonabunggame",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Wonabunggame",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Wolu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Wona",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Wonabu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Wiyangger",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Woji",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Wiyaluk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Witipur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Wijamurik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Winalo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Weu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Weyambi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Wenduri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Wenome",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Wanggugup",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Umar",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Wabuna",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Umaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Tunggunale",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Tomagi/Gubagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Tomagipura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Tiri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tiyenggupur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Tinger",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Tingwi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Tingwineri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Tinagoga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Tingapura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Tina",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Timori",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Tina",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Timoneri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Timojimo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Tidur Mabuk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Tigir",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Telelomi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Tenabaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Tebenalo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Tabowanimbo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Tagikun (Tigikum)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Tabo Wanimbo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Silabulo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Purugi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Pulanggun",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Pokegi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Piraleme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Pindelo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Pindak",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Peko (Peku)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Palagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Onggokme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Omibur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Numbugawe",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Numbuboton",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Numba",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Nelawi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Nawu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Nalorini",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Muara",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Murik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Mopi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Mowilome",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Mondagul",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Molera",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Minagame",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Minagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Mili",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Milineri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Menggenagame",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Menggenagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Megapura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Menggeba",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Markar",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Mbinime/Jinulira",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Mandura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Mampulaga (Nampulaga)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Lubuk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Luwik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Luanggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Losmen",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Loma",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Longgoboma",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Loguk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Liwese",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Lirak",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Lenggup",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Leragawi/Megagirakuk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Linggira",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Lena",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Kurik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Kurbaya",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kuragepura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Kumbur (Kumbu)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Kuma",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Airgaram",
                "kelurahan": "Kubur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kuloname",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Kubu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Kubugiwa",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Kuari",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Kuagembur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Kopenawai",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Kotori",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Kotorambur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Kondegun",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Konda",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kolilan",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Koli",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kiranage",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kogimagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Kogoyapura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Kibur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Kimobur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Kibu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Keragigelok",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Keribaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Kenen",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Kendemaya",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Karubaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Kanggilo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Kalewi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Kaiga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Jinuwanu",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Jinulira",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Jelepele",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Imurik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Igari",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Gurikme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Gurikagewa",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Guniki",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Guburini",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Gubura",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Gubagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Goyage",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Gitar",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Gininggadonak",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Gineri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Gimo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Gilopaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Gilok",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Gilime",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Geya",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Geyaneri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Yuneri",
                "kelurahan": "Gembileme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Ganage",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Galubup",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Elsadai",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Enanagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Erimbur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Dugi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Ebenhaiser",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Dimbara",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Doge",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Didelonik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Danggulurik",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wugi",
                "kelurahan": "Buangludah",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Bopa (Bobe)",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Bogome",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Biuk",
                "kelurahan": "Biuk",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Bini",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Bieleme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Beleme",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Benari",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Nelawi",
                "kelurahan": "Barename/Luanggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Bawi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Banggeri",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Numba",
                "kelurahan": "Baliminggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Baliminggi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wenam",
                "kelurahan": "Baganagapur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Arulo",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Arumagi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Aruku",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Wunin (Wumin)",
                "kelurahan": "Arombok",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kondaga (Konda)",
                "kelurahan": "Arikoba",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Aridunda",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Goyage",
                "kelurahan": "Angkasa",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Anawi",
                "kelurahan": "Anawi",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Karubaga",
                "kelurahan": "Ampera",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Alopur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Geya",
                "kelurahan": "Alobaga",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kai",
                "kelurahan": "Again",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Li Anogomma",
                "kelurahan": "Aburage",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Kuari",
                "kelurahan": "Abepur",
                "kodepos": "99562"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Yibalo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Yinama",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Yimabnime",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Windik",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Wulurik",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Wonaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Waringga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Weri",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Wanuk",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Wanggulam",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Wania",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Wania",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Tenggagama",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Umaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Tari",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Tarawi",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Tanabume",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Rumbepaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Silo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Tagi",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Ripa",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Omuk",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Peyola",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Niagale",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Nogobumbu",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Nunggalo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Munagame",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Nanggurilime",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Minggen",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Mingganggo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Milipaa",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Mawi",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Marbuna",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Melaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Mairini",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Malta",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Lerewere",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Logi",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Lamaluk",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Lambogo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Kurewunuk",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Kologume",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Kinebe",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Kogotime",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Kekoli",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Kanere",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Kanewunuk",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Kandimbaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Kamboniki",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Jawalane",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Habag",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Kaloniki",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Gunombo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Gulak",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Gelalo",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Getiem",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Gabunggobak",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Galala",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Ekoni",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Gabunam",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Dunduma",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Durima",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Dunda",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Donggem",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Duma",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Bumbu",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Delegari",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Bokoneri",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Bolly",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Bini",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Bokondini",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Bitillabur",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Bilubaga",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Kamboneri",
                "kelurahan": "Berembanak",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Belela",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Arelam",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokondini",
                "kelurahan": "Apiam",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bokoneri",
                "kelurahan": "Abimbak",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Danime",
                "kelurahan": "Ambena",
                "kodepos": "99561"
            },
            {
                "kecamatan": "Bewani",
                "kelurahan": "Abena",
                "kodepos": "99561"
            }
        ],
        "k301": [
            {
                "kecamatan": "Pirime",
                "kelurahan": "Yugumabur (Yugumobur)",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Yugwa (Yukwa)",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Yiwili",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Yamiga",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Yalipak",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Wuluwa",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Wirini",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Wiyagi",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Wenam",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Wanggagome",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Wamindik",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Wame",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Tologi",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Umbanume",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Tikome",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Takobak",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Tekul",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Ogodome (Odagome)",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Pirime",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Nanim",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Nileme",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Melendik",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Milimbo",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Nambume",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Libome",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Magegobak",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Lubutini",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Kuwanom",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Langgime",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Kugame",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Kulia",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Kelulome",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Ilunggume (Ilunggame/Ilunggime)",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Karunggame",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Golo",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Golomi",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Golopura",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Ekaba",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Pirime",
                "kelurahan": "Ekanom",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Dimba",
                "kelurahan": "Dimba",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Anitila",
                "kodepos": "99567"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Yugume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Yogobak (Yakobak)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Yirene",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Malagaineri (Malagineri)",
                "kelurahan": "Yigemili",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Yeyugu (Gilubandu)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Wupaga",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Wulundia",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Wamitu",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Wamlru",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Tiwa",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Malagaineri (Malagineri)",
                "kelurahan": "Wabiragi",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Tinggipura",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Tinime",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Timi",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Popome",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Tima",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Tabukeker",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Oyi",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Ponalo (Panalo)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Olume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Malagaineri (Malagineri)",
                "kelurahan": "Oka",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Nobo (Gubo)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Ninabua",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Name",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Nenggeya",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Mume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Mokoni",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Malagaineri (Malagineri)",
                "kelurahan": "Malagai",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Milinggame (Malimneri)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Mabume (Nabune)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Lugom (Logom)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Luarem",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Longgalo",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Kwenukwi",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Kuyawage",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Kumuluk",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Kuabaga (Kuobaga)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Kukepake",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Gurika",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Konikme",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Gume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Guninggame",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Guari (Giari/Guare)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Gubo (Nobo)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Giwan",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Golikme (Golime)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Dura",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Dugume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Dugu &#8211; Dugu",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Bonanip",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Bruyugu",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiom",
                "kelurahan": "Bokon",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Balime (Balingga)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Balimneri",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Balingga",
                "kelurahan": "Balime",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Malagaineri (Malagineri)",
                "kelurahan": "Bagi",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Tiomneri",
                "kelurahan": "Argeneri (Arigeneri/Argineri)",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Kuyawage",
                "kelurahan": "Andugume",
                "kodepos": "99563"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Yudani",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Yugumabur (Yubumabur)",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Sallemo (Salemo)",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Wupi",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Poga",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Piwugun",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Pirawun",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Odika",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Pindalo",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Lugobak",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Megalunik",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Muara",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Kanumbume",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Lualo",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Gukopi (Gukop)",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Gikur",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Gipura",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Bigipaga",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Ekapame",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Gamelia",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Gamelia",
                "kelurahan": "Ayafofa",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Poga",
                "kelurahan": "Abua",
                "kodepos": "99556"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Yugumia",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Yaneko",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Tigima (Tigime)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Wanuga",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Penggima (Penggime)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Pirambor",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Teyiko (Seiko)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Lelam",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Mamiri",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Kondena",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Labora",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Kemiri",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Kimbo",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Kamulume (Kumulume)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Keloyak (Kolayak)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Indigu (Indugu)",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Juta",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Gondura",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Guburni",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Indawa",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Bonom",
                "kodepos": "99555"
            },
            {
                "kecamatan": "Makki (Maki)",
                "kelurahan": "Eyuni",
                "kodepos": "99555"
            }
        ],
        "k302": [
            {
                "kecamatan": "Ibele",
                "kelurahan": "Zapma",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Zinai",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Yoman Weya",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Yagarobak (Yagakobak)",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Yelebarek",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Yokalpalek",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Witalak",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Yabem",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Waukahilapok",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Wililimo",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Tailarek (Taila)",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Tipalok",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Senogolik",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Sunili",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Pelima",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Pipukmo",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Musiamia",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Musiamia Dua",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Mulukmo",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Meagama",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Landia",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Likino",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Kikhumo",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Kosihilapok",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Kosimeage (Kosimeaga)",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Jibilabaga",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Iyora",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Isugunik",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Isakusa",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Inanekelok",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Ikilumo",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Ibele",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Husoak",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Huluaima",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikosi (Hobikosi)",
                "kelurahan": "Hubikosi",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Hubukiak / Hubikiak",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Holaliba",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Hom-hom",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Hitelowa",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Hituma (Hetuma)",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Heatnem",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Habema",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Filia",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Entagekokma",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Duabalek",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Hubikiak",
                "kelurahan": "Dokopku",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Bokiem",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Taelarek",
                "kelurahan": "Budliem",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Ibele",
                "kelurahan": "Ayobaibur (Ayubaibur)",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Pelebaga",
                "kelurahan": "Aleak",
                "kodepos": "99566"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Yordania Tulak",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Yanggapura",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Yogobur",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Yalengga (Yelengga)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Wuragukme",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Wunan",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Wugurima",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Wolo (Wollo)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Wollo Timur",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Wodloma",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Wilaloma",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Wenamela",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Wananuk",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Wandinggunie",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Wamanuk Dua",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Walakma",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Walak Selatan",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Tumun",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Towagame",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Totni",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Tononggame (Tenonggame)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Tirunggu",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Koragi",
                "kelurahan": "Telegai",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Koragi",
                "kelurahan": "Tenondek",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Tekani",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Tegabaga",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Tanahmerah",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Tamokilo (Tamokilu/Tamokulu)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Tagulik",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Taginem (Tagineri)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Tagime",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Koragi",
                "kelurahan": "Tagibaga",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Taganik",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Porome",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Poitmos",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Pilimo",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Pirambor (Pirambot)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Pagaluk",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Owagambak",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Onggobalo",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Onggaba (Onggabaga/Onggobaga)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Okwa",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Nunggarugum",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Munak",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Mulugame",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Molagalome",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Melemei",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Mebunukme",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Manili",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Manda",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Lapeyo",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Lani Timur",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Lakwame",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Molagalome",
                "kelurahan": "Kwigiluk",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Kugitero",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Koragi",
                "kelurahan": "Kumudiluk",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Kukurima",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Koragi",
                "kelurahan": "Koragi",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Kodlangga",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Irilinga / Irilingga (Irilingan)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Injuta",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagime",
                "kelurahan": "Gume Kupari",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Gelelame",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Dlinggama",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Dlonggoki",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Dewene",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Bugi",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Bitti",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Bolakme",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Tagineri",
                "kelurahan": "Binibaga",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Bandua",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bolakme",
                "kelurahan": "Bimu",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bpiri",
                "kelurahan": "Ayoma",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Wollo (Wolo)",
                "kelurahan": "Alugi",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Bugi",
                "kelurahan": "Air Garam",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Akorek",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Yalengga",
                "kelurahan": "Aipakma",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Yumugima",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Yonggime",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Yumbun",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wame",
                "kelurahan": "Yanenggame",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Yerega",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Yalinggume",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Wonenggulik",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Wawanca",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Wogi",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wame",
                "kelurahan": "Wame",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wame",
                "kelurahan": "Wanggonoma",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Tikawo",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Walak",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Silamik",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Temia",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Sekom",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Siapma",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Pumasili",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Piramid",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Pilibaga",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Perabaga",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Mulupalek",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Olagi",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Molebaga",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Miligatnem",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Miliama (Muliama)",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Loki",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Kosihave",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Logotpaga",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Kimbim",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Kombagwe",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Konan",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Hukuragi",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Kewin",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Holkima",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Holasili",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Hamuhi",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Helefa / Halefa",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Elabukama",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Gobalimo",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Gigilobo",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wame",
                "kelurahan": "Dumapaga",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Elaboga (Elaboge)",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wame",
                "kelurahan": "Dogoname",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Beam",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Delekama",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Balima",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Muliama",
                "kelurahan": "Asologaima",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Asologaima (Asalogaima)",
                "kelurahan": "Araboda",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Silo Karno Doga",
                "kelurahan": "Apnae",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Anegara (Anegera)",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Algonik",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Musatfak",
                "kelurahan": "Abulukmo",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Piramid",
                "kelurahan": "Aboneri",
                "kodepos": "99554"
            },
            {
                "kecamatan": "Wadangku",
                "kelurahan": "Yomosimo",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Libarek",
                "kelurahan": "Wenabubaga",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Wosiala",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Wara",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wita Waya",
                "kelurahan": "Wiligima",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Waga Waga",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Yiwika (Jiwika)",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wadangku",
                "kelurahan": "Wadangku",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Usilimo",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Utkolo Satu",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Undulumo",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wita Waya",
                "kelurahan": "Tulem",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Umpagalo",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Libarek",
                "kelurahan": "Punakul",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Suroba",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Siba",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Pisugi",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Pabuma",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Pikhe",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Obya",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wadangku",
                "kelurahan": "Musiem",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Mulima",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Libarek",
                "kelurahan": "Musalfak",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Meagamia",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Mebagaima",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Libarek",
                "kelurahan": "Muliama",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Kumima (Kimina)",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wita Waya",
                "kelurahan": "Koma",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wadangku",
                "kelurahan": "Luku-luku",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Libarek",
                "kelurahan": "Kilubaga",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Iyantik",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Gua Wisata",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Isaiman",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Hopama",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Fikha",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Eragama (Erema)",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Alona",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wita Waya",
                "kelurahan": "Alolik",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wita Waya",
                "kelurahan": "Alula",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Akiaput",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Wadangku",
                "kelurahan": "Agulimo",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Pisugi",
                "kelurahan": "Aikima",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Usilimo",
                "kelurahan": "Abutpuk",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Kurulu",
                "kelurahan": "Abusa",
                "kodepos": "99552"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Yumogima (Isibitlah)",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Yomaima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Popugoba",
                "kelurahan": "Yomote",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Popugoba",
                "kelurahan": "Yelelo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Yogonima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Yapema",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walaik",
                "kelurahan": "Yelai",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Yelekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Yaloaput",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Yagara",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Wilekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Wiaima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Wouma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Wesakin",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Wesakma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Welesi",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Wesaganya",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walaik",
                "kelurahan": "Welekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Wekia",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Waoba (Wuroba/Waroba)",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Wamusage",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Wamaroma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Wamena Kota",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walaik",
                "kelurahan": "Walaik",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Popugoba",
                "kelurahan": "Waima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Walelagama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Tulima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Trikora",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Sumunikama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Tomisa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Sinata",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Sogokmo (Sogomo)",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Sinapuk",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Sinarekowa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Silumarek",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Siliwa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Sinakma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Sekan Dalam",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Siepkosi",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Sapalek",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Sapalek",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Sekan",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Popugoba",
                "kelurahan": "Pupugoba (Popugoba)",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Pugima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Pobiatma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Pipitmo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Pawekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Parema",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Okilik",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Noagalo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Nanggo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Napua",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Ninabua",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Miami",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Minimo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Mulinekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Menagaima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Mawampi",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Maima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Manika",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Lukaken",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Logonoba",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Lunaima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Lantipo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Lanitipo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Lani Matuan",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Kubulakma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Kulaken",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Kuantapo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wouma",
                "kelurahan": "Ketimavit",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Kora Jaya",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Korambirik",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Kepi",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Kemisake",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Iwigima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Kama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walelagama",
                "kelurahan": "Itlay Halitopo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Siepkosi",
                "kelurahan": "Isawa Himan",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Husewa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Hulekama / Hurekama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Ilokama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Hukulimo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Honelama Dua",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Honaima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Honelama",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walaik",
                "kelurahan": "Holima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Hitigima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Holima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Itlay Hisage",
                "kelurahan": "Helepalegem",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Heraewa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Hesatom",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Helaluwa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Heberima",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Napua",
                "kelurahan": "Healekma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Maima",
                "kelurahan": "Esiak",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Walaik",
                "kelurahan": "Elarek",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Dinggilimo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Autakma",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wamena",
                "kelurahan": "Batu Merah",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Asotipo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Asotapo",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Asojelipele",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asolokobal",
                "kelurahan": "Asolokobal",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Welesi",
                "kelurahan": "Apenas",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Asotipo",
                "kelurahan": "Air Garam",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Trikora",
                "kelurahan": "Anggulpa",
                "kodepos": "99511"
            },
            {
                "kecamatan": "Wesaput",
                "kelurahan": "Agamoa",
                "kodepos": "99511"
            }
        ],
        "k303": [
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Wiyugobak",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Yagalim",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Seralema",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Megabilis (Megambilis)",
                "kelurahan": "Taria",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Moga",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Ninugagas (Ninunggas)",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Sembegulik (Samabegulik)",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Megabilis (Megambilis)",
                "kelurahan": "Megambilis (Megabilis)",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Luarima",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Keniwa",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Kobakma",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Megabilis (Megambilis)",
                "kelurahan": "Higisyam",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Megabilis (Megambilis)",
                "kelurahan": "Homasam",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Guawage",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Dokle",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Gimbis",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Baliblabuk (Baliklabur)",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Boroges",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Kobakma",
                "kelurahan": "Anduang",
                "kodepos": "99558"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Wirima",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Melenggama",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Kalarin",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Ilugwa",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Ilusimo (Ilusilimo)",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Ilugwa",
                "kelurahan": "Danama",
                "kodepos": "99557"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Yagabur",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Yabendili",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Yalenggolo (Yelonggolo)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Wurigelebur",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Winima",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Winam",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Uganda",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Wanilok (Yelam)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Tongrik (Tenggrik/Togirik/Yinaga)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Timeria",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Tari",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Tikapura",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Pelanme (Pelame)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Pagale",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Onggobalo (Gwipu)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Mologi (Molagi)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Mogonik",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Mabuna (Mabuma)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Kugap",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Kumbu",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Kindok (Lobi)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Kino (Kinou)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Gelora",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Kambo",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Kelila",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Erageam (Eragaem)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Egaima (Engaima)",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Enggama",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Dibunggen",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Dogobak",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Binime",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Ayeki",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Eragayam",
                "kelurahan": "Arsbol",
                "kodepos": "99553"
            },
            {
                "kecamatan": "Kelila",
                "kelurahan": "Apalo",
                "kodepos": "99553"
            }
        ],
        "k304": [
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Wiyantre (Wiantie)",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Wulukbubun",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Naramben",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Skanto (Skamto)",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Traimelyan (Traimilyan)",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Intaimelyan (Intaimilyan)",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Jaifuri",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Skamto (Skanto)",
                "kelurahan": "Arsopura",
                "kodepos": "99469"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Yetty",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Yuwanaim (Arso 2)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Yamta (PIR 2)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Yanama (PIR 1)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Yaturaharja (Arso 10)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Yammua (Yamua/Arso 6)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Yamara (PIR 5)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Workwana",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Wembi Dalam",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Wonorejo (PIR 4)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Wambes Luar",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Warbo (Arso 7)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Skofro",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Suskun (Wambes Dalam)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Ubiyau",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Sangke",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Sawiatami (Sawyatami)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Pyawi (Wembi Luar)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Nawa (Sawanawa)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Sanggaria (Arso 1)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Kriku",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Kwimi (Kwime)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso Timur",
                "kelurahan": "Kibay",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Dukwia (Arso 8)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Ifia-fia (Arso 11)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Arso Swakarsa (Asiaman)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Bagia (PIR 3)",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Yuwainda",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Arso",
                "kelurahan": "Arso Kota",
                "kodepos": "99468"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Kalimo",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Pund",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Banda",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Kalifam",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Waris",
                "kelurahan": "Ampas",
                "kodepos": "99467"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Yuruf",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Umuaf",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Towe Hitam",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Towe Atas",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Terfones / Terpones",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Tefanma",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Semografi",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Milky (Milki)",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Embi",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Lules",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Dubu",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Towe",
                "kelurahan": "Bias",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Web",
                "kelurahan": "Amgotro",
                "kodepos": "99466"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Woslay",
                "kodepos": "99465"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Yabanda (Jabanda)",
                "kodepos": "99465"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Warlef",
                "kodepos": "99465"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Usku",
                "kodepos": "99465"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Senggi",
                "kodepos": "99465"
            },
            {
                "kecamatan": "Senggi",
                "kelurahan": "Molof",
                "kodepos": "99465"
            }
        ],
        "k305": [
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Tayali",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Tayai",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Tayai (Taiyai)",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Taiyeve",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Sikari",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Sikari",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Pepasena II",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Pepasena I",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Kaly",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Kai (Kayi/Kay Seta)",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Haiya (Haya)",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Haiya",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Fuao",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Fona",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Fona",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Fokri",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Fokri",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Faria",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Dou",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Dou",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Hulu/Ulu",
                "kelurahan": "Dabra",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Rofaer (Rufaer)",
                "kelurahan": "Bareri",
                "kodepos": "99377"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Towao (Tuwao)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Wakeyadi",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Saswa Kwesar (Sausaua Kwesar/Sasawakwesar)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Towao",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Obagoi (Obogoi)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Namunaweja",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Noyadi",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Metaweja",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Murumare (Muaramarei)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Marina Valen (Marine Valen)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Marina Valen (Marine Valen)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Kustra",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Kuastra",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Ery (Eri)",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Kasonaweja",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah Timur",
                "kelurahan": "Biri",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Burmeso",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Danau Bira",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Babija",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Yoke",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Tengah",
                "kelurahan": "Anggreso",
                "kodepos": "99376"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Trimuris",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Warembori",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Kapeso",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Swaseso (Suaseno)",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Baudi",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Mamberamo Hilir/Ilir",
                "kelurahan": "Bagusa (Bagus)",
                "kodepos": "99375"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Teuw",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Watiaro",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Taya",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Tamakuri",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Sipisi",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Sorabi",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Sawai",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Rawiwa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Poiwai",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Rapamarei",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Nandofoai (Nadofoai/Nadofuai)",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Marikai",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Kerema",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Gesa Baru",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Kamai",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Dadat",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Bonoi",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Barapasi (Baradasi)",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Bariwaro",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Waropen Atas",
                "kelurahan": "Bensor",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Benuki",
                "kelurahan": "Baitanasa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Sawai",
                "kelurahan": "Anasi",
                "kodepos": "98262"
            }
        ],
        "k306": [
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Webro",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Waim",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Wari",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Wamariri",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Surimania (Syurimania)",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Tamaya",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Siantoa",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Sasawapece",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Subu",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Niwerawar",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Samorkena",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Nisro",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Murara",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Martewar",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Masep",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Maniwa",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Karfasia",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Kwawitania",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Burgena",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Kamenawari",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Kapeso",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Aurimi",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Bina",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Arbais",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Pantai Barat",
                "kelurahan": "Aruswar",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Apawer Hulu",
                "kelurahan": "Airoran",
                "kodepos": "99374"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Waskey / Tanjung Batu",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Waskey",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Tefarewar",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Wapo",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Tanjung Batu",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Siaratesa",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Sawar",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Sewan",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Pulau Armo",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Sarmi",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Sarmo",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Mararena",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Manukania",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Liki",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Lembah Neidam",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Kasukwe",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Holmafen (Holmafem)",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Ebram",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Ebram",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Binyer",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Biyer",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Timur",
                "kelurahan": "Bagaisewar Dua / II",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Bagaisewar II",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Bagaiserwar",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi",
                "kelurahan": "Armo",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Angkasa Dua",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Sarmi Selatan",
                "kelurahan": "Amsira",
                "kodepos": "99373"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Togonfo (Teminabor)",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Waaf",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Waaf Dua/II",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Samanente",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Omte (Kwepke)",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Safron Tane Dua/II",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Sakron Tane",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Denander Dua/II",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Konderjan",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Bora Bora",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Tor Atas",
                "kelurahan": "Denander",
                "kodepos": "99372"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Yamben (Jamber/Beneraf)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Timron",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Vinyabor",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Sunum (Yamna)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Nengke II (Nengke Baru)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Tabrawar",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Nengke",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Komra",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Fito",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Keder II",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Dabe",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Dabe II",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Betaf",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Betaf II (Tamnir)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Arare",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Ansudu (Ansubi)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur",
                "kelurahan": "Ansudu II (Srem)",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Pantai Timur Barat",
                "kelurahan": "Ampera",
                "kodepos": "99371"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Tetom",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo Timur",
                "kelurahan": "Tamas Sari",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Rotea",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Taronta Srum",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Maweswares (Mawesres)",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Rimser Sari",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Krim Podena",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo Timur",
                "kelurahan": "Mawes Mukti",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo Timur",
                "kelurahan": "Mawesday",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Kiren",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo Timur",
                "kelurahan": "Kaptiau",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Bebon Jaya",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo Timur",
                "kelurahan": "Gwin Jaya",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Anus",
                "kodepos": "99355"
            },
            {
                "kecamatan": "Bonggo",
                "kelurahan": "Armopa",
                "kodepos": "99355"
            }
        ],
        "k307": [
            {
                "kecamatan": "Kaureh",
                "kelurahan": "Yadouw (Yadauw)",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Tabbeyan (Tabeyan)",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Taqwa/Takwa Bangun",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Kaureh",
                "kelurahan": "Umbron",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Kaureh",
                "kelurahan": "Sebum",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Kaureh",
                "kelurahan": "Soskotek",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Pagai",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Ongan Jaya",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Purnawajati (Purnama Jati)",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Naira",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Nawa Mukti",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Nawa Mulya",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Kaureh",
                "kelurahan": "Lapua",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Muara Nawa (Muarah Mawa)",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Kwarja (Kwaja)",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Hulu Atas",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Kamikaru",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Bumi Sahaja",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Yapsi",
                "kelurahan": "Bundru",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Airu",
                "kelurahan": "Aurina",
                "kodepos": "99364"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Nembukrang Sari (Nimbokrang Sari)",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Rhepang Muaif/Muaib",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Wahab",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Hamongkrang",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Nembukrang (Nimbokrang)",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Berab",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Bunyom",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Benyom Jaya I",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimbokrang",
                "kelurahan": "Benyom Jaya II",
                "kodepos": "99362"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Yenggu Baru",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Yakasib (Yakasip/Yokasib)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Yenggu (Yenggu Lama)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Sumbe",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Tabri",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Singgriway (Singgriwai)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Sarmai Bawah",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Singgri",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Sarmai Atas",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Pobaim",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Sanggai",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Meyu",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Oyengsi",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Kuipon (Kuipons)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Kuwase",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Karya Bumi",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Kaitemung (Kautemung)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Hanggaiy Hamong",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Imestum (Imustum)",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Imsar",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nambluong",
                "kelurahan": "Besum",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Gemebs",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Nimboran",
                "kelurahan": "Benyom",
                "kodepos": "99361"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Puay (Puai)",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Yokiwa",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Nendali",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Nolokla",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Ayapo (Itakiwa)",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Asei Kecil",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Sentani Timur",
                "kelurahan": "Asei Besar",
                "kodepos": "99359"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Yakonde",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Sentani Barat",
                "kelurahan": "Waibron",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Sosiri",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Sentani Barat",
                "kelurahan": "Sabron Sari",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Sentani Barat",
                "kelurahan": "Sabron Yaru (Sabro Yaru)",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Kwadeware (Kanda)",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Sentani Barat",
                "kelurahan": "Maribu",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Doyo Baru",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Doyo Lama",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Dondai (Donday)",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Sentani Barat",
                "kelurahan": "Dosay (Dosai)",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Waibu",
                "kelurahan": "Bambar",
                "kodepos": "99358"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Swentab",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Soaib",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Yanbra (Yanim)",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Sekori",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Skoaim",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Sama",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Sabeab Kecil",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Pupehabu",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Omon",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Gresi Selatan",
                "kelurahan": "Omon",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Namei",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Nembu Gresi",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Mandayawan",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Nambon",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Mamda",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Gresi Selatan",
                "kelurahan": "Klaisu",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Kwansu",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Jagrang",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Gresi Selatan",
                "kelurahan": "Iwon",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Hatib",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Ibub",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Damoi Kati",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Demetin",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Braso",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk Gresi",
                "kelurahan": "Bring",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Gresi Selatan",
                "kelurahan": "Bangai",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Bengguin Progo",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Kemtuk",
                "kelurahan": "Aib",
                "kodepos": "99357"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Sawesuma (Sawa Suma)",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Sentosa (Santosa)",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Nandalzi (Nandaizi)",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Beneik",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Garusa",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Unurum Guay",
                "kelurahan": "Guriyad (Guryard)",
                "kodepos": "99356"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Snamay",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Yaugapsa (Yougapsa)",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Snamay",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Muaif",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Muris Kecil",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Maruway",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Meukisi",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Kamdera",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Buseryo",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Yokari",
                "kelurahan": "Endokisi",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Demta Kota",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Demta",
                "kelurahan": "Ambora",
                "kodepos": "99354"
            },
            {
                "kecamatan": "Ravenirara",
                "kelurahan": "Yongsu Sapari",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Yefase (Yepase)",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Yewena",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Ravenirara",
                "kelurahan": "Yongsu Besar (Dosoyo)",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Waiya",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Wambena",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Tablasupa",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Ravenirara",
                "kelurahan": "Ormuwari (Newa)",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Ravenirara",
                "kelurahan": "Nehibe (Nacha Tawa)",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Doromena",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Entiyebo",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Depapre",
                "kelurahan": "Kendate",
                "kodepos": "99353"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Yobeh",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Yoboy",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Ebungfau (Ebungfa)",
                "kelurahan": "Simporo (Babo/Yosiba/Homf)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Sereh",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Keheran (Kehiran)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Sentani Kota",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Ebungfau (Ebungfa)",
                "kelurahan": "Khameyaka (Kameyoka/Khamaeka)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Ifale (Ilfele)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Ifar Besar",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Hinekombe",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Hobong",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Sentani",
                "kelurahan": "Dobonsolo",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Ebungfau (Ebungfa)",
                "kelurahan": "Ebungfa",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Ebungfau (Ebungfa)",
                "kelurahan": "Abar (Atabar)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Ebungfau (Ebungfa)",
                "kelurahan": "Babrongko (Ifar Bobrongko)",
                "kodepos": "99352"
            },
            {
                "kecamatan": "Heram",
                "kelurahan": "Yoka",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Heram",
                "kelurahan": "Yabansai",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Heram",
                "kelurahan": "Yabansai",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Heram",
                "kelurahan": "Waena",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Skow Sae (Skouw Sae)",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Skow Yambe (Skouw Yambe)",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Nafri",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Mosso",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Skow Mabo (Skouw Mabo )",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Koya Tengah",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Koya Timur",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Koya Barat",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Koya Koso",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Muara Tami",
                "kelurahan": "Holtekamp",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Kota Baru",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Heram",
                "kelurahan": "Hedam",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Engros (Enggros)",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Asano",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Awiyo",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Vim",
                "kodepos": "99225"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Wahno",
                "kodepos": "99226"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Abepantai",
                "kodepos": "99351"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Argapura",
                "kodepos": "99222"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Ardipura",
                "kodepos": "99223"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Entrop",
                "kodepos": "99224"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Way Mhorock",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Abepura",
                "kelurahan": "Waena",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Tahima Sorama",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Tobati",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Numbai (Numbay)",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Jayapura Selatan",
                "kelurahan": "Hamadi",
                "kodepos": "99221"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Tanjung Ria",
                "kodepos": "99117"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Mandala",
                "kodepos": "99115"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Imbi",
                "kodepos": "99116"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Angkasapura",
                "kodepos": "99113"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Trikora",
                "kodepos": "99114"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Gurabesi",
                "kodepos": "99111"
            },
            {
                "kecamatan": "Jayapura Utara",
                "kelurahan": "Bhayangkara (Bayangkara)",
                "kodepos": "99112"
            }
        ],
        "k308": [
            {
                "kecamatan": "Doufu",
                "kelurahan": "Tayai (Taya)",
                "kodepos": "98976"
            },
            {
                "kecamatan": "Doufu",
                "kelurahan": "Iratoi",
                "kodepos": "98976"
            },
            {
                "kecamatan": "Doufu",
                "kelurahan": "Kordei (Kordesi)",
                "kodepos": "98976"
            },
            {
                "kecamatan": "Doufu",
                "kelurahan": "Doufu/Dovo",
                "kodepos": "98976"
            },
            {
                "kecamatan": "Doufu",
                "kelurahan": "Faisau",
                "kodepos": "98976"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Wiha",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Yauria (Wambru)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Wakme",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Weni (Isweli/Weli)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Pogoma (Bokoma)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Agadugume",
                "kelurahan": "Tuput",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Pamebut",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Molu",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Nikoleme (Nigolome/Nikoame)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Kunikomo (Kumikomo)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Kelemame",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Kilugame",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Kembru (Yimuk)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Kalibuk (Kalebut/Kawibut)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Agadugume",
                "kelurahan": "Jiwot",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Jigiunggi (Jiginggi)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Golu",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Gigobak (Gogobak)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Bina",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Bina",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Amulame (Amulume)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Bacini (Bakcini)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Sinak",
                "kelurahan": "Akenggen",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Pogoma",
                "kelurahan": "Aguit (Aguwit)",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Agadugume",
                "kelurahan": "Agadugume",
                "kodepos": "98973"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Yaikimaki",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Wuloni",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Yenggrenok (Yanggerenok)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Upaga",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Wako",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Toanggi",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Pinggil (Piggil)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Ognanim (Oknanim)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Paluga",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Pinapa",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Misimaga",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Mundidok",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Mayuberi",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Mindiba (Miandiwa/Mudiba)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Kimak (Kimale)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Kunga (Kuiga)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Maki",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Kago",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Gome",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Eromaga (Eromoga)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Gilini",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Bubet",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Erelmekawia",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Ilaga",
                "kelurahan": "Bologobak (Pologobak/Pologobale)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Bela",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Amungkalpia (Amungkalipia)",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Gome",
                "kelurahan": "Agiyome",
                "kodepos": "98972"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Yulokoma",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Wonalbe",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Wangbe",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Wamki (Dengkibuma)",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Ulipia",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Tinggilbet",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Pungki",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Puluk",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Pubet / Gimorit",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Ogamki",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Pilokoma",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Nungai",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Mirilaukin",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Milawak",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Kelandiruma",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Jindak",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Jambul",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Hikinat (Ikinat)",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Daung",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Erong Berong/Berang",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Dambet (Dangbet/Kalemogom)",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Beoga",
                "kelurahan": "Babe",
                "kodepos": "98971"
            },
            {
                "kecamatan": "Wangbe",
                "kelurahan": "Ailpalin (Alpalin)",
                "kodepos": "98971"
            }
        ],
        "k309": [
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Wurabak",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Wuramburu",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Wanume",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Waliba",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Waliba",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Tiolome",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Tigir",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Ogoluk",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Ninggineri",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Nggininik",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Mewut",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Lumo",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Maloinggen",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Mewoluk",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Gumbru",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Kililumo",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Gilibe",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Gililome",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Golapaga",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Biak",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Dologobak",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Balinggup",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Mewoluk (Mewulok)",
                "kelurahan": "Belabaga",
                "kodepos": "98918"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Yerei",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Turumo",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Wanggiba",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Yeihneri",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Soi",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Tenggabanggwi",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Mbomban",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Kaho",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Kiyage",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Mandara Lani",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Fii",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Gueri",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Farride",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Fawi",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Deide",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Dorey",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Evo",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Dagai 2",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Debite",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Bakusi",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Biricare",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Dagai",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Amuringgik",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Atoli",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Yamoneri",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Fawi",
                "kelurahan": "Ambo",
                "kodepos": "98917"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Wurak",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Yagaluk",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Wugiwagi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Tirigoi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Tenomanggen",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Piyapigi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Pulau Timur",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Pulogengga",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Pereya",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Pindebaga",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Ngginigum",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Ninom",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Numbukenggawi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Mobigi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Moulo",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Laworege",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Maka",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Kurikpulok",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Lambo",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Kirigimaduk",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Kobarak",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Juria I",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Kalengga",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Jigelo",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Jiguluk",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Jimbanime",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Jibonok",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Gubulome",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Gumawi",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Jembeneri",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Galonegame",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Girmor",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Bunume",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Digulome",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Dolinggame",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Aulukme",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Belantara",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Andiron",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Anggutari",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Aringgak",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Agopaga",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Ambit-Mbit",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Ilu",
                "kelurahan": "Aberiambut",
                "kodepos": "98916"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Yawor",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Yonggi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Wumbiri",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Wutime",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Wanduri",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Wongi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Woraluk",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Walon",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Wambagalo",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Tombok",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Tinggirege",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Pugunom",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Pugunom",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Palilome",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Papak",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Onendu",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Oum",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Pagolome",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Numbok",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Nume",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Noba Noba",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Nogi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Ndugwir",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Nioga",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Niyoluk Abili",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Modu",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Muruwi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Milineri",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Meyongga",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Melela",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Mepar",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Lugubago",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Mabuk Timur",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Mandura",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Kerin",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Komanggi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Jigonikme",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Jinggi",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Kenendaga",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Guna",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Gubume",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Gubuneri",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Ginilume",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Gibaga",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Gilome",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Anebalui",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Apelome",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Wariru",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Jigonikme",
                "kelurahan": "Akwibaga",
                "kodepos": "98915"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Sigou",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Tubugime",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Tumbiwolu",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Nalu",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Nambu",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Kelandu",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Kikup",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Gunung Tayok",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Digi",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Gubugani",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Asua",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Awiyam",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Degei",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Yogolawi",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Torere",
                "kelurahan": "Ambok",
                "kodepos": "98914"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Yalimambu",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Yamo",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wurage",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Yalikambi",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wunagelo",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wundu",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wiyaktini",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wolame",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Wolame",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Tiogeme",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Temu",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Pulau Yamo",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Purbalo",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Sanoba",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Nowoneri",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Niruwi",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Nowome",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Molobak",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Nami",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Kimibut",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Kwatineri",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Imulineri",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Jigunikime",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Kabilimbut",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Gibaga",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Goyage",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Dokome",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Dondo",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Aligapaga",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Yamo",
                "kelurahan": "Binime",
                "kodepos": "98913"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yunggwi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yonggun",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yogorini",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yaniruk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yarmukum",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yamengga",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yanenggawi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Wurungkime",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Yalibate",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Wurabume",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Wonwi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Wundini",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Uragi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Wiyage",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Turotaro",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Torage",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Towoluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Tingginime",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Tombo",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Tenolok",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Tingginambut",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Tinggineri",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Ponggoname",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Purugi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Pilibur",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Pernaluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Pilia",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Paralo",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Pelalo",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Palumagi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Papua",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Pagarugom",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Nuwi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Oriluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Nenegame",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Nggegen",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Naburage",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Nakongwe",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Melekom",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Mondogoneri",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Monia",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Lura Bara",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Lerawera",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Longgawi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Lumbuk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Kiburu",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Kalome",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Kayogebur",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jugumblawi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jinggwi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jiramok",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jiwone",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jibinggame",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Jigobak",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Gubupur",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Gwenggu",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Inikimaluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Ginipago",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Greja Lama",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Gigume",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Gimanggen",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Ginilome",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Diraluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Gibume",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Cilome",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Degi",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Bime",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Binggelakme",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Bonaluk",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Berem",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Berime",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Bigirage",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Aulakmu",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Aulame",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Tingginambut",
                "kelurahan": "Agape",
                "kodepos": "98912"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Yoboluk",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Yoga",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Yalinggua",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Yambi",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Yambidugun",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Wuyukwi",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Wuyuneri",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Wanduri",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Wondenggobak",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Wonome",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Urgele",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Usir",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Towogi",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Trikora",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Towemarib",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Talilome",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Tenolok",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Pruleme",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Puncak Senyum",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Omabut",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Pagaleme",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Pepera",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Muliagambut",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Muliambut",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Moulo",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Mondu",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Lunggineri",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Kulok Enggame",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Lulame",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Kilunggwi",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Kulirik",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Ilamburawi",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Jiginikime",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Karubate",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Dangenpaga",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Doligobak",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Ginigom",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Anggutare",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Berelema",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Birak Ambut",
                "kodepos": "98911"
            },
            {
                "kecamatan": "Mulia",
                "kelurahan": "Amulome",
                "kodepos": "98911"
            }
        ],
        "k310": [
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Yotapuga",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Yawetadi",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Pugatadi Satu (I)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Ugapuga",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Obayo",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Pugatadi Dua (II)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Mogou",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Nuwa (Nua)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Idakebo",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Ikrar",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Koyakago (Kuyakago)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Duntek (Dumtek)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Utara (Ikrar/Ikrat)",
                "kelurahan": "Ekimani (Ekiwani)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Bunauwo",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Deiyapa (Deiyepa)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Boduda",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Timur",
                "kelurahan": "Bokaibutu (Bukaibuto)",
                "kodepos": "98863"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Yepo (Makidimi)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Tuwaida",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Ugikagouda",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Putapa (Uputapa)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Ugikebo",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Pouwoda (Pouwuoda)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Puweta/Pueta I",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Pona (Sukamaju)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Puweta/Pueta II",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Obaibega",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Motito",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Makidimi (Makidini)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Mauwa",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Matadi",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Kimipugi (Kimupugi)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Kegemani (Kigamani)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Idakotu",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Ikebo (Moanemani)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Idadagi",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Ekemanida (Ekemenida)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Egebutu",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu",
                "kelurahan": "Dikiyouwa/Dikiyouma (Tokapo)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Digikebo",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Dogimani (Abgoigi)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Denemani",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Denemani",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Bogiyateugi",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Kamu Selatan",
                "kelurahan": "Botumuma (Botumoma)",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Dogiyai",
                "kelurahan": "Bobubutu",
                "kodepos": "98862"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Yegiyepa (Yegeiyepa)",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Sukikai Selatan",
                "kelurahan": "Wigoumakida",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Sukikai Selatan",
                "kelurahan": "Unito",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Ukagu",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Tipaugi",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Sukikai Selatan",
                "kelurahan": "Sukikai",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Sukikai Selatan",
                "kelurahan": "Iyaro",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Kegata",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Ideduwa",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Egipa",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Deniyode (Deneiode)",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Piyaiye (Sukikai)",
                "kelurahan": "Apogomakida (Aporomakida)",
                "kodepos": "98857"
            },
            {
                "kecamatan": "Mapia Barat",
                "kelurahan": "Yegoukotu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Upibega (Ukudawata)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Barat",
                "kelurahan": "Toubaikebo (Tobaikebo)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Timepa",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Putapa",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Piyakunu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Obaikagopa (Abaugi)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Modio",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Barat",
                "kelurahan": "Maikotu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Barat",
                "kelurahan": "Maikotu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Magode (Magone)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Gapoya",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Gabaikunu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Diyoudimi",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Diyeugi",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Dawaikunu",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Atou (Tuamani)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Bomomani",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Tengah",
                "kelurahan": "Adauwo",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia Barat",
                "kelurahan": "Abouyaga",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Mapia",
                "kelurahan": "Abaimaida",
                "kodepos": "98854"
            }
        ],
        "k311": [
            {
                "kecamatan": "Wapoga",
                "kelurahan": "Totoberi",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Napan",
                "kelurahan": "Weinami",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Wapoga",
                "kelurahan": "Samanui",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Wapoga",
                "kelurahan": "Taumi",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Nifasi",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Napan",
                "kelurahan": "Napan",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Napan",
                "kelurahan": "Masipawa",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Moora",
                "kelurahan": "Moor",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Maidei",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Makimi",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Moora",
                "kelurahan": "Mambor",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Legari Jaya",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Wapoga",
                "kelurahan": "Keuw",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Wapoga",
                "kelurahan": "Kamarisanoi",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Moora",
                "kelurahan": "Kama",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Moora",
                "kelurahan": "Hariti",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Makimi",
                "kelurahan": "Biha",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Moora",
                "kelurahan": "Arui",
                "kodepos": "98861"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Yaro Makmur",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Wiraska",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Nabire Barat",
                "kelurahan": "Waroki",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Wanggar Sari",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Wanggar Pantai",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Wanggar",
                "kelurahan": "Wanggar Makmur",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Wanggar",
                "kelurahan": "Karadiri",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Nabire Barat",
                "kelurahan": "Wadio",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Nabire Barat",
                "kelurahan": "Kalisemen",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Jaya Mukti",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Nabire Barat",
                "kelurahan": "Gerbang Sadu",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Nabire Barat",
                "kelurahan": "Bumi Raya",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Wanggar",
                "kelurahan": "Bumi Mulya (Bumimulia)",
                "kodepos": "98856"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Ugida",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Tibai",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Siriwo (Unipo)",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Mabua / Mabou",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Epomani",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Aibore",
                "kodepos": "98854"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Urumusu",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Menou",
                "kelurahan": "Yageugi",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Dipa",
                "kelurahan": "Tagauto (Tadautu)",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Topo",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Topo Jaya",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Ororodo",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Parauto",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Margajaya",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Menou",
                "kelurahan": "Ogiyai (Ogiay/Waisai)",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Menou",
                "kelurahan": "Kunupi / Obaipoto",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Menou",
                "kelurahan": "Lokodimi / Bokomidi",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Dipa",
                "kelurahan": "Jainoa",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Dipa",
                "kelurahan": "Jigikebo",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Dipa",
                "kelurahan": "Epouwa (Epowa)",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Gamei Jaya",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Dipa",
                "kelurahan": "Dikia (Dikiya)",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Yaro (Yaro Kabisay)",
                "kelurahan": "Bomopai",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Uwapa",
                "kelurahan": "Argomulyo",
                "kodepos": "98853"
            },
            {
                "kecamatan": "Teluk Umar",
                "kelurahan": "Yeretuar",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Yaur",
                "kelurahan": "Yaur",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Yaur",
                "kelurahan": "Sima",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Yaur",
                "kelurahan": "Wami (Wamijaya)",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Teluk Umar",
                "kelurahan": "Goni",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Teluk Umar",
                "kelurahan": "Napan Yaur",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Yaur",
                "kelurahan": "Akudiomi (Akuidiomi)",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Teluk Umar",
                "kelurahan": "Bawei",
                "kodepos": "98852"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Siriwini",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Teluk Kimi",
                "kelurahan": "Waharia",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Sanoba (Sanoba Pantai)",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Teluk Kimi",
                "kelurahan": "Samabusa",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Teluk Kimi",
                "kelurahan": "Kimi",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Teluk Kimi",
                "kelurahan": "Lani",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Kalibobo",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Teluk Kimi",
                "kelurahan": "Air Mandidi",
                "kodepos": "98818"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Nabarua",
                "kodepos": "98817"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Karang Mulia",
                "kodepos": "98815"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Oyehe",
                "kodepos": "98816"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Girimulyo",
                "kodepos": "98814"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Karang Tumaritis",
                "kodepos": "98812"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Bumi Wonorejo",
                "kodepos": "98813"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Kali Susu",
                "kodepos": "98811"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Morgo",
                "kodepos": "98811"
            },
            {
                "kecamatan": "Nabire",
                "kelurahan": "Kali Harapan",
                "kodepos": "98811"
            }
        ],
        "k312": [
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Yagaito (Jagaito/Tagito)",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Yanei (Janei/Yamei)",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Yanei (Janei/Yamei)",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Mogalo Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Mbugulo Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Mbialapa",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Kalawa",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Kigitadi",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Isandoga Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Jae Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Hulapuga",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Isan Doga",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Dubasiga Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Hipadipa (Hitadipa)",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Debasiga Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Wandai",
                "kelurahan": "Debasiga Dua Pemda",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Debasiga",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Debasiga Dua / II",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Bugalaga",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Danggatadi",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Biandoga",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Biatapa",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Biandoga",
                "kelurahan": "Aneya",
                "kodepos": "98784"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Unabindaga (Unabundaga/Unabundoga)",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Tomasiga",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Kombogasiga",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Solip",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Tausiga (Tousiga)",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Jawasiga",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Kembagel",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Dapiaga",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Fugisiga (Pugisiga)",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Bamogo (MBamogo)",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Dangkoa",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Agisiga",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Agisiga",
                "kelurahan": "Balamai (Balaimai)",
                "kodepos": "98783"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Titigi",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Ndugusiga",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Eknemba / Enemba",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Bonogo",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Yoparu",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Yalai (Jabai/Jalai)",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Yokatapa (Tokatapa)",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Wabui",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Ugimba",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Wandoga",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Sanaba (Sanamba)",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Soagama",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Titigi",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Mindau",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Puyagiya",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Mamba",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Mbilusiga",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Kumlagupa",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Kulapa",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Emondi (Kemondi)",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Hitadipa",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Danggomba",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Degeyabu",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Hitadipa",
                "kelurahan": "Eknemba/Elenemba",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Sugapa",
                "kelurahan": "Bilogai",
                "kodepos": "98768"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Zomban Doga (Zombandoga)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Pogapa",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Selemana (Selemama)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Waiagepa / Waigepa",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Ogeapa / Ogeayapa",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Mapa",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Maya",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Kenetapa (Kendetapa/Kenedepa)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Kobae (Kobai)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Hugitapa / Hubitapa",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Hiyabu",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Bubisiga (Bugusiga)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Degesiga (Degesise)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Engganega",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Bilai Dua (II)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Bilai Satu (I)",
                "kodepos": "98767"
            },
            {
                "kecamatan": "Homeo (Homeyo)",
                "kelurahan": "Agapa",
                "kodepos": "98767"
            }
        ],
        "k313": [
            {
                "kecamatan": "Bibida",
                "kelurahan": "Ugidimi (Ugimiba)",
                "kodepos": "98782"
            },
            {
                "kecamatan": "Dumadama",
                "kelurahan": "Duma",
                "kodepos": "98782"
            },
            {
                "kecamatan": "Dumadama",
                "kelurahan": "Dogomo (Dogoma)",
                "kodepos": "98782"
            },
            {
                "kecamatan": "Bibida",
                "kelurahan": "Bibida",
                "kodepos": "98782"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Youtadi",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Uwagi",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Nawipauwo",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Nomouwodide",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Ipouwa",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Bogobaida",
                "kelurahan": "Bogobaida",
                "kodepos": "98769"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Yabomaida",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Toyaimoti",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Tuguwai",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Makidini",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Ekadide",
                "kelurahan": "Obaipugaida",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Ekadide",
                "kelurahan": "Pasir Putih",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Deta",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Ekadide",
                "kelurahan": "Eyagitaida",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Ekadide",
                "kelurahan": "Geida",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Dauwagu",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Akoubaida",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Baguwo",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Agapo",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Ekadide",
                "kelurahan": "Agapo",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Aradide",
                "kelurahan": "Abatadi",
                "kodepos": "98766"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Totiyo",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Ukauwo",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Waipa",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Tadia (Kepi/Todia/Tadia Ayaikebo)",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Tipakotu",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Siriwo (Dadou)",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Okaitadi",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Muyetadi",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Obano",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Kegouda",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Kito",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Mogoya",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Geiketago",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Siriwo",
                "kelurahan": "Bua (Kotu/Ikotu)",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Epabutu",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Paniai Barat",
                "kelurahan": "Beko",
                "kodepos": "98763"
            },
            {
                "kecamatan": "Yatamo",
                "kelurahan": "Wotai",
                "kodepos": "98726"
            },
            {
                "kecamatan": "Yatamo",
                "kelurahan": "Keniapa (Udaigida)",
                "kodepos": "98726"
            },
            {
                "kecamatan": "Yatamo",
                "kelurahan": "Epouto",
                "kodepos": "98725"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Mugimeketago",
                "kodepos": "98724"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Panibagata",
                "kodepos": "98722"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Pugaida (Daupaida)",
                "kodepos": "98723"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Obayaweta",
                "kodepos": "98722"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Ipawa (Ipuwa)",
                "kodepos": "98721"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Munayepa (Mujaiyepa)",
                "kodepos": "98721"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Togowa",
                "kodepos": "98719"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Enarotali",
                "kodepos": "98719"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Kebo I",
                "kodepos": "98718"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Kebo II",
                "kodepos": "98718"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Ibumaida (Ibomomaida)",
                "kodepos": "98717"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Kagupago (Kagupagu)",
                "kodepos": "98718"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Oyaigo",
                "kodepos": "98716"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Dei",
                "kodepos": "98716"
            },
            {
                "kecamatan": "Yatamo",
                "kelurahan": "Uwebutu (Unebutu)",
                "kodepos": "98715"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Oyaigo (Ayaigo/Kagupaida)",
                "kodepos": "98715"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Uwamani",
                "kodepos": "98715"
            },
            {
                "kecamatan": "Kebo",
                "kelurahan": "Awebutu",
                "kodepos": "98715"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Tuamakida",
                "kodepos": "98713"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Madi",
                "kodepos": "98714"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Awabutu",
                "kodepos": "98713"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Timida",
                "kodepos": "98713"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Wage",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Waidede",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Kopo",
                "kodepos": "98712"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Toko",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Uwibutu",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Pugo",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Kogekotu",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Kopaidagi",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Papato",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Kagupago",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Ipakiye",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Geibu",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Geko",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Aikai",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Badawo",
                "kodepos": "98711"
            },
            {
                "kecamatan": "Paniai Timur",
                "kelurahan": "Dagouto",
                "kodepos": "98711"
            }
        ],
        "k314": [
            {
                "kecamatan": "Bowobado",
                "kelurahan": "Kopai Dua (II)",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Bowobado",
                "kelurahan": "Kopai Satu (I)",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Edagotadi",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Edaiyodagi",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Damabagata",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Bowoubado",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Dakebo",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Bagou (Bagau)",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Timur",
                "kelurahan": "Bagumoma",
                "kodepos": "98781"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Widuwakiya (Widuwakia)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Yenudoba/Jinidoba",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Wagomani",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Widimei (Widi Mey)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Onago",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Piyakadimi (Piyekedimi)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Tenedagi",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Diyai",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Digibagata",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Demago (Deemago)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Buwoudimi",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Bomou III",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Bomou Satu (I)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Bobai",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Bomou Dua (Bomau II)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Ayate (Ayatei)",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Atouda",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Tigi",
                "kelurahan": "Amago",
                "kodepos": "98764"
            },
            {
                "kecamatan": "Kapiraya",
                "kelurahan": "Kapiraya (Komauto)",
                "kodepos": "98727"
            },
            {
                "kecamatan": "Tigi Barat",
                "kelurahan": "Gakokebo",
                "kodepos": "98727"
            },
            {
                "kecamatan": "Bowobado",
                "kelurahan": "Woge",
                "kodepos": "98724"
            }
        ],
        "k315": [
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Wonti Bokadaro",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Wanda",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Wobari",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Wafuka",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Tetui",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Urato",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Soyoi",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Sooh",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Spoiri",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Masirei",
                "kelurahan": "Saurisirami",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Masirei",
                "kelurahan": "Sinonde",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Risei Sayati",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Poira",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Odase",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Masirei",
                "kelurahan": "Odase",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Mayaghaido",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Napani",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Mambai",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Masirei",
                "kelurahan": "Mayagaido (Mayaghaido)",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Kuaisa",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Masirei",
                "kelurahan": "Koweda",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Fafai",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Kawari",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Dirou",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Diwa",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Demba",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Risei Sayati",
                "kelurahan": "Daimboa",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Dairi",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Kirihi",
                "kelurahan": "Antu Aru",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Demba Masirei",
                "kelurahan": "Aniboi",
                "kodepos": "98263"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Sowiwa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Ruambak Jaya",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Rasawa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Nau",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Oiboa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Moroa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Botawa",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Oudate Waropen",
                "kelurahan": "Baino Jaya",
                "kodepos": "98262"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Waweri",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Waren Dua (II)",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Waren Satu (I)",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Wapoga",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Usaiwa",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Uri",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Ureifasei Satu (I)",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Ureifasei Tiga (III)",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Syewa Merare",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Ureifasei Dua (II)",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Ruambak Jaya",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Sawara Jaya",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Pirare",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Ronggaiwa",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Rorisi",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Pirare",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Paradoi",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Otodemo",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Nubuai",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Nonomi",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Waropen Bawah",
                "kelurahan": "Nau",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Kemon Jaya",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Mambui",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Harapan Jaya",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Kamarisano",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Demisa",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Dokis",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Ghoyui",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Wapoga Inggerus",
                "kelurahan": "Awera",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Ureifasei",
                "kelurahan": "Apainabo",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Awaso",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Awera",
                "kodepos": "98261"
            },
            {
                "kecamatan": "Inggerus",
                "kelurahan": "Aiboa",
                "kodepos": "98261"
            }
        ],
        "k316": [
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Wawuti",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Yapanani",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Waniwon",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Wanampompi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Wamori",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Wadapi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Umani",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Saweru",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Toroa",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Sanayoka",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Rondepi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Roipi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Roipi Dua",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Rambai",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Ransarnoni (Ramsamoni)",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Perea",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Nuniandei",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Menawi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Mananayam",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Marawi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Mambawi (Ambai III)",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Kontiunai (Kontinuai)",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Kawipi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Kainui II",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Kabuaena",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Kainui I",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Imboriawa",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Borai",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Dorau",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Baisore",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Bairei",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Baisore",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Ambai II",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Ambai I",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Aiwaraggani",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Angkaisera",
                "kelurahan": "Aitiri",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Kepulauan Ambai",
                "kelurahan": "Adiwipi",
                "kodepos": "98255"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Worioi (Wariori)",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Windesi",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Waisani",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Serewen",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Saruman",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Rosbori",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Rasiri (Woisiri)",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Poom II",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Nurawi",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Poom I",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Kairawi (Karawi)",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Munggui",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Makiroan (Mokiroan)",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Poom",
                "kelurahan": "Humbe Awai",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Asai",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Yenusi Marau",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Pulau Yerui",
                "kelurahan": "Yeituarau",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Aryobu",
                "kodepos": "98254"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Wooi",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Yarori",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Woinap",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Woiwani",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Webi",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Wimoni",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Warabori",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Pulau Yerui",
                "kelurahan": "Umpeki",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Toweta",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Sasawa",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Rembai",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Natabui",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Nuiwiora",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Papuma (Papuama)",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Pulau Yerui",
                "kelurahan": "Miosnum",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Narei",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Marau",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Maniri",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Karemoni",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Kaonda",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Kanaki",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Kairawi",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Pulau Yerui",
                "kelurahan": "Jeniari",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Jaimaria",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Inowa",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Dumani",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Haihorei",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Pulau Yerui",
                "kelurahan": "Ausem",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Awado",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Barat",
                "kelurahan": "Ansus",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Wonawa",
                "kelurahan": "Aibondeni",
                "kodepos": "98253"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Yobi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Woinsupi (Waisopi)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Woda",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Warironi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Waita",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Waindu",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Wabuayar (Wabuayer)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Wabompi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Wabo",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Tindaret",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Tarei",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Soromasen (Soromasem)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Siromi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Sere Sere",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Sawenui (Sawenoi)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Sambrawai (Samrawai)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Sawendui",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Roswari (Artaneng)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Randawaya",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Nunsembai (Hunsembai)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Nunsiari",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Mnukwar",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Mereruni (Maseruni)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Mansesi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Manusundu",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Kurudu",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Kororompui",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Kiriyou (Kiriyow)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Korombobi (Karombobi)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Kirimbri",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Utara",
                "kelurahan": "Karawi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Karoaipi (Koroapi)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Kaipuri",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Dawai",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Duai",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Doreiamini (Doreianmini)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Bareraipi (Barerai/Bareraif)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Barawai II",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Ayari",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Yapen Timur",
                "kelurahan": "Awunawai",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Barawai",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Arareni",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Pulau Kurudu",
                "kelurahan": "Andesaria (Andersaria)",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Teluk Ampimoi",
                "kelurahan": "Ampimoi",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Raimbawi",
                "kelurahan": "Aisau",
                "kodepos": "98252"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Tatui",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Yapan",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Sarawandori Dua",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Serawandori (Sarawandori)",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Serui Laut",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Ramangkurani",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Pasir Putih",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Panduami (Pandoami/Panduani)",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Pasir Hitam",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Nundawipi",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Numaman",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Mariadei (Mariaderi)",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Mariarotu (Mariar Rotu)",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Mambo",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Manainin",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Kanawa",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Kandowarira",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Ketuapi",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Kamanap",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Bawai",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Famboaman (Fambuaman)",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Banawa",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Barawaikap",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Ariepi Dua",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Aromarea",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Ambaidiru",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Kosiwo",
                "kelurahan": "Ariepi",
                "kodepos": "98215"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Anataurei (Anatorei)",
                "kodepos": "98214"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Serui Jaya",
                "kodepos": "98212"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Tarau",
                "kodepos": "98213"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Turu",
                "kodepos": "98211"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Warari",
                "kodepos": "98211"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Serui Kota",
                "kodepos": "98211"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Manaini",
                "kodepos": "98211"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Mantembu",
                "kodepos": "98211"
            },
            {
                "kecamatan": "Yapen Selatan",
                "kelurahan": "Imandoa",
                "kodepos": "98211"
            }
        ],
        "k317": [
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Yenbebon (Yenbepon)",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Yenbeba",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Warido",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Subangungsi (Submanggunsi)",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Supmander",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Wansra",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Serbin",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Semayen",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Saribi",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Rimbaraya",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Rawar",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Pondori (Pomdori)",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Pomdori Sup",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Pakreki",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Namber",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Masyara",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Orkeri",
                "kelurahan": "Manwor Indah",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Kansai",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Mambodo Sawai",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Kameri",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Yenmaru",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Barat",
                "kelurahan": "Baruki",
                "kodepos": "98172"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Warbukor",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Yenburwo",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Syoribo",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Serdori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Saunbri (Sauribru)",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Sauri",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Sandou (Sandau)",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Rarsibo",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Manggari",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Pyefuri",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Mandori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Mandori Sup",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Koryakam",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Kornasoren",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Kamumi",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Indaibori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Bruyadori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Dafi",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Duai",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Barkori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Bawei",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Asaibori",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Numfor Timur",
                "kelurahan": "Asaryendi",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Arimi Jaya",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Andei",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Poiru",
                "kelurahan": "Andei Sup",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Bruyadori",
                "kelurahan": "Amberparem (Amperem)",
                "kodepos": "98171"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Yeri",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Wundi",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Supraima",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Yen Manaina",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Sokani",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Sorina",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Sasari",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Sandidori (Sandedori)",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Saribra",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Paidori",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Pasi",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Samber Pasi",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Pai",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Padaido",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Nusi Babaruk",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Nyansoren",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Nusi",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Meos Manguandi (Mangguandi)",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Mbromsi",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Kanai",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Karabai",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Inyebomi (Inbeyomi)",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Padaido",
                "kelurahan": "Auki",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Aimando Padaido",
                "kelurahan": "Anobo",
                "kodepos": "98158"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Yawosi (Fanindi)",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Yeruboy",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Wouna",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Bondifuar",
                "kelurahan": "Wopes",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Wodu (Makuker)",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Wir Insos",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Wasori",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Wasani",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Warawaf",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Bondifuar",
                "kelurahan": "Wandos",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Sup Mbrur",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Bondifuar",
                "kelurahan": "Syurdori",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Soor (Sor)",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Saway",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Bondifuar",
                "kelurahan": "Sansundi",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Marur",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Maniri",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Manbeori",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Mamfias",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Madirai",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Koyomi",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Komboy",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Karuiberik",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Karmon",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Inyobi",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Inswanbesi Sup",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Inswanbesi",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Indawi",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Imbari",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Bondifuar",
                "kelurahan": "Doubo",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Biawer",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Diano",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Ammoy",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Warsa",
                "kelurahan": "Aman",
                "kodepos": "98157"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Wisata Binsari",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Yafdas",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Yafdas",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Snerbo",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Sumberker",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Sambawofuar",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Samofa",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Mansinyas",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Maryendi",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Kinmom",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Mandouw",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Karang Mulia",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Kamorfuar",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Darmopis",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Anjarew",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Anjereuw",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Adadinasnosen (Adainasnosen)",
                "kodepos": "98156"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Yendidori",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Waroi (Waroy)",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Wirmaker",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Syabes",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Urfu",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Samber Sup",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Moibaken",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Suneri",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Padwa",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Sunyar",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Padwa Pantai",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Rarmpimbo (Rampimbo)",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Inpendi",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Samber",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Kabidon",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Binyeri",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Birub",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Adoki",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Yendidori",
                "kelurahan": "Amyabenram",
                "kodepos": "98155"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Yomdori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Wombrisauw",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Yenbepioper (Yembepioper)",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Warberik (Waberik)",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Wasyai",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Swainober",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Sumbinya (Sunbinya)",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Swaipak",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Sopendo Sup Karkir",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Sosmay / Sosmai",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Sopendo",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Saswarbo",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Rarsbari",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Ramdori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Sarwa",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Opuri",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Orkdori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Marisen",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Napdori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Mandenderi",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Mamoribo",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Mardori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Karnindi",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Kababur",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Kanaan",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Insiri",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Farusi",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Insusbari",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Dedifu",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Dousi",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Banasrares",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Busdori",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Asarkir",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Asaryendi",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Swandiwe",
                "kelurahan": "Andoina",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Andei",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Barat",
                "kelurahan": "Amponbukor",
                "kodepos": "98154"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Yobdi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Wonabraidi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Warsansan",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Wodu",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Waromi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Warbon",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Wari",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Warbinsi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Saukobye",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Rumbin",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Rosayendo",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Sarwom",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Rodifu",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Nermnu",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Mamorbo",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Mnuswor",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Korem",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Mambesak",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Dofyo Wafor",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Faknikdi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Kobeoser",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Dernafi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Dasdo",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Asur",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Yawosi",
                "kelurahan": "Bosnabraidi",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Andey (Andei)",
                "kelurahan": "Armnu",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Biak Utara",
                "kelurahan": "Andei",
                "kodepos": "98153"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Yensama",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Yenusi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Woniki",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Yenberok",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Yendakem",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Wasori",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Wadibu",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Waderbo",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Tanjung Barari",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Soryar",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Sunde",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Seepse (Sepse)",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Soon",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Sawadori",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Sauri",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Sawa",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Sareidi (Saraeidi)",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Ruar",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Rim",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Rimba Jaya",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Owi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Opiaref",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Orwer",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Makmakerbo",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Marao",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Mandon",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Kajasi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Kakur",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Insumamires (Insumarires)",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Kajasbo",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Inofi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Inmdi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Bindusi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Bosnik Sup",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Animi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Bakribo",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Anggaduber",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Oridek",
                "kelurahan": "Anggopi",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Adorbari",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Timur",
                "kelurahan": "Afefbo",
                "kodepos": "98152"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Yenures",
                "kodepos": "98119"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Sanumi",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Mnubabo (Ambroben)",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Swapodibo",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Karyendi",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Paray (Parai)",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Manggandisapi",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Samau",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Insrom",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Mokmer",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Kababur",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Manswan",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Inggiri",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Inggupi",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Samofa",
                "kelurahan": "Brambaken",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Sorido",
                "kodepos": "98116"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Anggraidi",
                "kodepos": "98118"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Waupnor",
                "kodepos": "98115"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Babrinbo",
                "kodepos": "98116"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Burokub",
                "kodepos": "98113"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Saramom",
                "kodepos": "98114"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Mandala",
                "kodepos": "98111"
            },
            {
                "kecamatan": "Biak Kota",
                "kelurahan": "Fandoi",
                "kodepos": "98112"
            }
        ],
        "k318": [
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Wayori (Mayori)",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Waryei (Wariyei)",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Utara",
                "kelurahan": "Warsa",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Utara",
                "kelurahan": "Warbor",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Utara",
                "kelurahan": "Puweri",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Napisandi",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Mapia",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Masyai",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Utara",
                "kelurahan": "Kobari Jaya",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Koryakam (Koiryakam)",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Utara",
                "kelurahan": "Fanjur",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Supiori Barat",
                "kelurahan": "Amyas",
                "kodepos": "98162"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Yamnaisu",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Yawerma",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Wonggeina (Wongkeina)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Wombonda",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Wafor",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Waryesi",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Waberfondi (Warbepondi)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Sorendidori (Sorendiweri)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Syurdori",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Sauyas",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Rayori",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Rayori",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Maryaidori",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Mbruwandi (Bruwandi)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Marsram",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Manggonswan (Mangoswan)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Imbrisbari (Imbirsbari)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Ineki",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Insumbrei",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Fanindi",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Duber",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Timur",
                "kelurahan": "Douwbo (Doubo)",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Didiabolo",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Biniki",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Kepulauan Aruri",
                "kelurahan": "Aruri",
                "kodepos": "98161"
            },
            {
                "kecamatan": "Supiori Selatan",
                "kelurahan": "Awaki",
                "kodepos": "98161"
            }
        ],
        "k319": [
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Wayati Timur",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Weri",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Wonodadi Mulya",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Wayati",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Wayati Barat (Faukia)",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Waserat",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Warisa Mulya",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Wawosan",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Urat",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Wambar (Wambrar)",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Tuberwasak",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Tunas Gain",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Tesha",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Tomage",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Tarak",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Saharei",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Sanggram",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Otoweri",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Pinang Agung",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Mekarsari",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Onimsari",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Mbimajaya",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Mas (Maas)",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Kwama",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Malakuli (Tanjung Purkadi)",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Kiria Bisa (Air Bisa)",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Kotam",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Kiaba",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Timur",
                "kelurahan": "Kalamanuk",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Bombarai (Bomberay)",
                "kelurahan": "Bumimoroh Indah",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Faur",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Karas",
                "kelurahan": "Antalisa",
                "kodepos": "98662"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Werpigan",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Wurkendik",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Werfa (Werfra)",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Werba",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Wartutin",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Werabuan",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Us",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tuimar",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Teluk Patipi",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tetar",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tibatibananam",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tanehamur",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tanisapata (Tanisapatu)",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Tawar",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Sum",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Siboru",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Sipatnanam",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Salakiti",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Purwasak",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Rumbati",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Patipi Pasir",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Patipi Pulau (Nusa)",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Puar",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Mawar",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Ofie",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Degen",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Fakfak Barat",
                "kelurahan": "Kiat",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Teluk Patipi",
                "kelurahan": "Adora",
                "kodepos": "98661"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Wos",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Wargep",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Waremu",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Wagap",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Ugar",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Wabung",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Ubadari",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Sisir",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Sekar",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Rangkendak",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Pikpik",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Pattukar",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Patimburak",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Mitimber",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Nembukteb",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Mbahamdandra",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Mananmur",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Mandoni",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Mamur",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Mambunibuni",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Kwamkwamur",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Kuagas",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Kriawaswas",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Kramongmongga",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Kokas Kota",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Kinam",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Kayuni",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Kampung Baru",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Kaburbur",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Goras",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Homor Kokma",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Furir",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Gewab",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Fior",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Batufiafas",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Darembang",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kramongmongga (Kramamongga)",
                "kelurahan": "Bahbadan",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Andamata",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Kokas",
                "kelurahan": "Arguni",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Wagon Utara",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Wrikapal",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Wagom Selatan",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Tanama",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Torea",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Sekban",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Sekru",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Pirma",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Sakartemin",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Pasir Putih",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Mandopma",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Nemiwikarya (Nemewi Karya)",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Nemiwikarya (Nemewi Karya)",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Katemba",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Kayu Merah",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Kapartutin",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Kaduria (Raduria)",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Kanantare",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Fak Fak Utara",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Gewerpe",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Dulanpokpok",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak",
                "kelurahan": "Fak Fak Selatan",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Brongkendik",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Dana Weria",
                "kodepos": "98611"
            },
            {
                "kecamatan": "Fakfak Tengah",
                "kelurahan": "Air Besar",
                "kodepos": "98611"
            }
        ],
        "k320": [
            {
                "kecamatan": "Buruway",
                "kelurahan": "Nusaulan Jaya",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Tairi",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Yarona",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Guriasa",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Hia",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Kambala",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Esania",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Gaka",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Adijaya",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Buruway",
                "kelurahan": "Edor",
                "kodepos": "98656"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Wagoha (Wagioha)",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Wosokuno",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Ure (Mury/Uray)",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Urubika",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Omba Pamuku",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Teluk Etna",
                "kelurahan": "Rurumo",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Teluk Etna",
                "kelurahan": "Siawatan",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Teluk Etna",
                "kelurahan": "Nariki",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Yamor",
                "kelurahan": "Hairapara",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Teluk Etna",
                "kelurahan": "Boiya",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Teluk Etna",
                "kelurahan": "Bamana",
                "kodepos": "98655"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Werafuta",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Werua",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Waho",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Wamesa",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Trikora",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Sermuku (Ubia Sermuku)",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Sisir",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Tanggaromi",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Sara",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Saria",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Rauna",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Oray",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Marsi",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Murano",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Namatota",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Lumira",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Mai Mai",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Kooy",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Krooy",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Kooy",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Kaimana Kota",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Kamaka",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Foroma Jaya",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Jarati",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kambraw (Kamberau)",
                "kelurahan": "Bahumia",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Kaimana",
                "kelurahan": "Coa",
                "kodepos": "98654"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Wermenu",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Weswasa",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Warua",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Warwasi",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Wanoma",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Waromi",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Wainaga",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Urisa",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Wanggita",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Turgani (Tugarni)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Ukiara",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Tuguwara",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Tanusan",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Tanusan",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Sumun",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Seraran",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Sawi (Sawia)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Sawatewera (Sawatawera)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Ruwara",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Pigo",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Nagura",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Moyana",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Maskur",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Kufuriyai",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Manggera",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Kokoroba",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Kensi (Kasira)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Inari",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Jawera",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Gusi",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Furnusu (Fumusu)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Fudima",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Feternu (Fetermu)",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Bawah (Yerusi)",
                "kelurahan": "Egerwara",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Erigara",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Bofuwer",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Borogerba",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Bayeda",
                "kodepos": "98653"
            },
            {
                "kecamatan": "Teluk Arguni Atas",
                "kelurahan": "Afuafu",
                "kodepos": "98653"
            }
        ],
        "k321": [
            {
                "kecamatan": "Aifat",
                "kelurahan": "Martaim",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kokas",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Faitsawe",
                "kodepos": "98652"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Yeek",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Yarat",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Yarat Timur",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Womba",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Wormu",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Werjaya",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Wayane",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Winuni",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Tolak",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Wakom",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Warba",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Tahsimara",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Tiam",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Tiefromen",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Susumuk",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Tahahite",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Susai",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Sunei",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Srumate",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Sorry",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Sikof",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Sawin",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Saud",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Sanem",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Sasior Nafe",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Same Rakator",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Sampika",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Sabah",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Sabah",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Roma",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Raha",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Pitor",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Mowes",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Neset",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Mupas",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Mosun Timur",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Mosun Utara",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Mosun",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Selatan",
                "kelurahan": "Makiri",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Mesyam",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Man",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kumurkek Barat",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kumurkek",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Krus",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Konja Barat",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Konja",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Konja",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kocuas Utara",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kocuwer",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Kocuwer Selatan",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Kamat",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Kisor",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Kaitana",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Irata",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Hora Iek",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Imsun",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Howait",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Futon",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Haenkanes",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Fuog",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Fuog",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Fuog Selatan",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Frawebo",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Framneway",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Frambu",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Fonatu",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Faitmayaf",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Faitmayat Barat",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Faan",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Buoh Sa",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Bori",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Bori Timur",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Bori Timur",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Ayawasi",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Utara",
                "kelurahan": "Ayawasi Selatan",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Ayata",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Awet Main",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Assem",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Selatan",
                "kelurahan": "Asiaf Zaman",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Akingging",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Aiwesa",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Aisyo",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Aitrem",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat",
                "kelurahan": "Ainod",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Aisa",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Ainesra",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Jauh",
                "kelurahan": "Aimau",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Aikus",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur",
                "kelurahan": "Aikrer",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aifat Timur Tengah",
                "kelurahan": "Aifam",
                "kodepos": "98463"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Yumame",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Wrait U",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Yaksoro",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Wiho",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Waybomatah",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Tohmri",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Way U",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Tehak Tee",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Tehak Kecil",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Tehak Besar",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Sumanis",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Subrit",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Subin",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Sris",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Srirtabam",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Sowai Sau",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Siyo",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Sira Tee",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Sira Aya",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Sarimo",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Sira",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Sabun",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Mirafan",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Korom",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Karsu",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Kamro Selatan",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Kamro",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Kambufatem Utara",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Kambusabo",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Kambufatem",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Jitmau Timur",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Jitmau",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Itigah",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Isir",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Irohmrar",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Inta",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Iroh Sohser",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Ikuf Utara",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Ikuf",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Ihore",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Ibasuf",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Hosyo Ata",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Gohsames",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Hasweh",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Framboh",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Fategomi",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Framafir",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Fatem",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Barat",
                "kelurahan": "Fatase",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Raya",
                "kelurahan": "Faithowes",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Fan",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Eway",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Erokwero",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Bohwat",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Awit",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Bofait",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Tengah",
                "kelurahan": "Asnaif",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Asmuruf Tee",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo Utara",
                "kelurahan": "Asmuruf U",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Afkrem",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Aitinyo",
                "kelurahan": "Aitinyo",
                "kodepos": "98462"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Woman",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Yohwer",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Waren I",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Waren II",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Warbo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Wabam",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Uri",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Tut",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Tuso",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Twer",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Tomase",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Tehak Tee",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Temel",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Suwiam",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Tehak Besar",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Tbo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Suswa",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Sufu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Subrit",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Sosian",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Smusuwioh",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Soan",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Sorowan",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Sipat",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Simiyah",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Sien",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Sfarare",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Sfacko",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Sfakraka",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Seneh",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Seya",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Sembaro",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Semu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Sefayit",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Sehu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Segior",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Sawara Jaya",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Sawo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Sarafambai",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Sauf",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Sagrim",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Rindu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Sagrim",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Orsu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Rawas",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Orain",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Nonomi",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Mopi",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Nafasi",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Men",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Mirafan",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Mapura",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Mefkajim II",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Lemauk Klit",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Mano",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Mahos",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Kona",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Kosah",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Koma Koma",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Kombif",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Kofait",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Kartapura",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Keyum",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Karfa",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Kanisabar",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Kambuskato Utara",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Kanggilo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Kambuifa",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Kambuskato",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Kambuaya",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Isnum",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Isusu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Isme",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Ismayu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Insas",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Inta",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Hufioh",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Huberita",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Gohsames",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan",
                "kelurahan": "Hawioh",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Framu",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Framafir",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara Timur",
                "kelurahan": "Frabo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Fraharo",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Fiane",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Fategomi",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Fanse",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Fan",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur Selatan",
                "kelurahan": "Faitwosur",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Faitsimar",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Faitsiur",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Timur",
                "kelurahan": "Faitmajin",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Fait Nggre",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Barat",
                "kelurahan": "Chaliat",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Bohwat",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Tengah",
                "kelurahan": "Bawy",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare Selatan",
                "kelurahan": "Batu Zaman",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Bakrabi",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Ayamaru",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Asses",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Asmuruf U",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Utara",
                "kelurahan": "Asmuruf Tee",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Selatan Jaya",
                "kelurahan": "Arus",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru",
                "kelurahan": "Afes",
                "kodepos": "98461"
            },
            {
                "kecamatan": "Ayamaru Jaya",
                "kelurahan": "Adoh",
                "kodepos": "98461"
            }
        ],
        "k322": [
            {
                "kecamatan": "Batanta Utara",
                "kelurahan": "Yensawai Timur",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Wamega",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Selatan",
                "kelurahan": "Yenanas",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Utara",
                "kelurahan": "Yensawai Barat",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Waimeci",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Selatan",
                "kelurahan": "Waiman",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Wailen",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Wailen",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Waijan",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Wailabu",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Waidim",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Barat",
                "kelurahan": "Waibon",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Waibu",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Barat",
                "kelurahan": "Solol",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Samate",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Sakabu",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Kapatlap",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Barat",
                "kelurahan": "Kalwal",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Tengah",
                "kelurahan": "Kalobo",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Barat",
                "kelurahan": "Kaliam",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Jefman Barat",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Salawati Utara (Samate)",
                "kelurahan": "Jefman Timur",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Utara",
                "kelurahan": "Arefi Timur",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Selatan",
                "kelurahan": "Amdui",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Batanta Utara",
                "kelurahan": "Arefi Selatan",
                "kodepos": "98484"
            },
            {
                "kecamatan": "Misool Selatan",
                "kelurahan": "Yellu",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kepulauan Sembilan",
                "kelurahan": "Wejim Barat",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kepulauan Sembilan",
                "kelurahan": "Wejim Timur",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Usaha Jaya",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool (Misool Utara)",
                "kelurahan": "Wejim",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Selatan",
                "kelurahan": "Usaha Jaya",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Tomolol",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool (Misool Utara)",
                "kelurahan": "Tomolol",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kofiau",
                "kelurahan": "Tolabi (Tolobi)",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kepulauan Sembilan",
                "kelurahan": "Satukurano",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool (Misool Utara)",
                "kelurahan": "Satukorano (Sato Kurana)",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kepulauan Sembilan",
                "kelurahan": "Pulau Tikus",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kofiau",
                "kelurahan": "Mikiran",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Barat",
                "kelurahan": "Magey",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Limalas Barat",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Limalas Timur",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool (Misool Utara)",
                "kelurahan": "Lenmalas",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Barat",
                "kelurahan": "Kapatcol",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Barat",
                "kelurahan": "Lilinta",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Selatan",
                "kelurahan": "Harapan Jaya",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Barat",
                "kelurahan": "Gamta",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Folley",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool (Misool Utara)",
                "kelurahan": "Folley",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Selatan",
                "kelurahan": "Fafanlap",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kofiau",
                "kelurahan": "Dibalal",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Selatan",
                "kelurahan": "Dabatan",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kofiau",
                "kelurahan": "Deer",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Barat",
                "kelurahan": "Biga",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Kofiau",
                "kelurahan": "Awat",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Misool Timur",
                "kelurahan": "Audam",
                "kodepos": "98483"
            },
            {
                "kecamatan": "Waigeo Timur",
                "kelurahan": "Yensener (Yesner)",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Yenwaupnoor (Yenwaupnor)",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Yenbuba",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Selatan",
                "kelurahan": "Yenbeser",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Yembekwan",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Timur",
                "kelurahan": "Yembekaki",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Kota Waisai",
                "kelurahan": "Warmasen",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Selatan",
                "kelurahan": "Wawiyai",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Teluk Mayalibit",
                "kelurahan": "Warsamdin",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Kota Waisai",
                "kelurahan": "Waisai",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Warimak",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Waifoi",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Timur",
                "kelurahan": "Urbinasopen",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Sawandarek",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Sawinggrai",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Selatan",
                "kelurahan": "Saporkren",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Kota Waisai",
                "kelurahan": "Sapordanco",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Timur",
                "kelurahan": "Puper",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Selatan",
                "kelurahan": "Saonek",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Teluk Mayalibit",
                "kelurahan": "Mumes",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Teluk Mayalibit",
                "kelurahan": "Lopintol",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Kurkapa",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Teluk Mayalibit",
                "kelurahan": "Kalitoko",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Kapisawar",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Kabilol",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Kabuy (Kabui)",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Go",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Waigeo Selatan",
                "kelurahan": "Friwen",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Kota Waisai",
                "kelurahan": "Bonwakir",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Arway",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Tiplol Mayalibit",
                "kelurahan": "Beo",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Meos Mansar",
                "kelurahan": "Arborek",
                "kodepos": "98482"
            },
            {
                "kecamatan": "Warwabomi",
                "kelurahan": "Warmanai",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Kepulauan Ayau",
                "kelurahan": "Yenkawir",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Warwabomi",
                "kelurahan": "Warkori",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat",
                "kelurahan": "Waisilip",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Saupapir",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Supnin",
                "kelurahan": "Urai",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat",
                "kelurahan": "Selpele",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Saukabu",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat",
                "kelurahan": "Saleo",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Ayau",
                "kelurahan": "Rutum",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Kepulauan Ayau",
                "kelurahan": "Rutum",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Ayau",
                "kelurahan": "Reni",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Kepulauan Ayau",
                "kelurahan": "Reni",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Supnin",
                "kelurahan": "Rauki",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Pam",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Warwabomi",
                "kelurahan": "Mnier",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat",
                "kelurahan": "Mutus",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Ayau",
                "kelurahan": "Meosbekwan",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Kepulauan Ayau",
                "kelurahan": "Meosbekwan",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Meosmanggara",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Manyaifun",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Kalisade",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Supnin",
                "kelurahan": "Kapadiri",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Kabare",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat Kepulauan",
                "kelurahan": "Gag",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Supnin",
                "kelurahan": "Duber",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Kepulauan Ayau",
                "kelurahan": "Doreker (Dorekar)",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Darumbab",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Bonsayor",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Warwabomi",
                "kelurahan": "Boni",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Barat",
                "kelurahan": "Bianci",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Asukweri",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Waigeo Utara",
                "kelurahan": "Andey",
                "kodepos": "98481"
            },
            {
                "kecamatan": "Ayau",
                "kelurahan": "Abidon",
                "kodepos": "98481"
            }
        ],
        "k323": [
            {
                "kecamatan": "Yembun",
                "kelurahan": "Sumbekas",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Syarwom",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Metbesa",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Baun (Baum)",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Mefnanyam (Metnayam)",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Metbelum",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Bamus",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Yembun",
                "kelurahan": "Bamus Waiman (Bamuswayman)",
                "kodepos": "98474"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Yabuow (Jabouw)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Werwaf",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Abun",
                "kelurahan": "Weyaf",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Wertam",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Werur",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Werur Besar",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Wayo",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Abun",
                "kelurahan": "Wau",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Uigwem",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Abun",
                "kelurahan": "Waibem (Waiben)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Abun",
                "kelurahan": "Warmandi",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Tabamsere",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Syumbab",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Syujak",
                "kelurahan": "Syujak (Sujak)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Syukwes",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Syubun",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Syuau",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Syujak",
                "kelurahan": "Soon",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Siakwa",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Sikor",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Sausapor",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Ruf",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Abun",
                "kelurahan": "Saubeba",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Miri/ Ibiah",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Revewes (Ruvewes)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Kwoor",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Mawor",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Meis",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Krisnos",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Kwesefo (Kosefa)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Kranfotsu",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Jokte",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Syujak",
                "kelurahan": "Iof",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Hopmare",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Kwoor",
                "kelurahan": "Batde (Baddei)",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Fef",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Syujak",
                "kelurahan": "Banso",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Syujak",
                "kelurahan": "Frafane",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Ayiamane",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Bikar",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Fef (Peef)",
                "kelurahan": "Ases",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Sausapor",
                "kelurahan": "Emaos",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Ayae",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Miyah (Meyah)",
                "kelurahan": "Aibogiar",
                "kodepos": "98473"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Siwis",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Sengkeduk (Saengkeduk)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Salewok (Selewok)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Selekebu (Selekopi)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Malawarsai (Malaworsai)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Mega",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Malawarsai (Malaworsai)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Moraid",
                "kelurahan": "Dela (Della)",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Senopi",
                "kelurahan": "Wausin (Asiti)",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Nekori",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Senopi",
                "kelurahan": "Senopi",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Injai",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Jandurau",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Inam",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Inambuari",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Ajami",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Akmuri",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Kebar",
                "kelurahan": "Atai",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Senopi",
                "kelurahan": "Afrawi",
                "kodepos": "98372"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Wasnebri (Warsnembri/Wasnembri)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Wefiani",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Wasarak",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Soukorem (Saukorem)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Waru",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Sasuy (Sasui)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Serayo",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Meriambeke (Meriambeker)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Mangganek (Arupi)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Amberbaken",
                "kelurahan": "Bondopi",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Atori",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Bawey",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Bijanfou (Bijamfou/Buanfou)",
                "kodepos": "98371"
            },
            {
                "kecamatan": "Mubrani",
                "kelurahan": "Arfu",
                "kodepos": "98371"
            }
        ],
        "k324": [
            {
                "kecamatan": "Maudus",
                "kelurahan": "Suluh",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Warbo",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Klaso",
                "kelurahan": "Siwis",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Klaso",
                "kelurahan": "Selekobo",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Klaso",
                "kelurahan": "Miskum",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Klaso",
                "kelurahan": "Saengkeduk",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Klaso",
                "kelurahan": "Klamugun",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Masos",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Klaos",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Ketawas",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Klatim",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Maudus",
                "kelurahan": "Dasri",
                "kodepos": "98472"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Sawatuk",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Teluk Dore",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Sailala",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Saluk",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Klayili",
                "kelurahan": "Malalilis",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Malaumkarta",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Klayili",
                "kelurahan": "Malakobutu",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Maladofok",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Klayili",
                "kelurahan": "Kwakeik",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Makbon",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Luwelala (Suwelala)",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Kuadas (Kwadas)",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Klayili",
                "kelurahan": "Klayali",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Klatin (Klatim)",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Klayili",
                "kelurahan": "Klawuluh",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Klatin (Klatim)",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Dasri",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Sayosa",
                "kelurahan": "Batu Payung",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Batu Lubang/Lobang",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Batu Lubang/Lobang Pantai",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Asbaken",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Makbon",
                "kelurahan": "Bainkete",
                "kodepos": "98471"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Warmon",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Mariat",
                "kelurahan": "Mariyai",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Mariat Pantai",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Mariat Gunung",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Malawele",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Malawili",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Maibo",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Malasom",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Mariat",
                "kelurahan": "Klasukuk",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Mariat",
                "kelurahan": "Klamalu",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Mariat",
                "kelurahan": "Klamasen",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Klabinain",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Klaigit",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Aimas",
                "kelurahan": "Aimas",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Mariat",
                "kelurahan": "Klaben",
                "kodepos": "98457"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Wariayau",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Mlais",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Malajapa",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Malasigit",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klawana",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klamugun",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klasman",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klalomon",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klamano",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Keladum",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Klagulu",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Klamono",
                "kelurahan": "Gisim Darat",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Wisbiak",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Wanurian",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Wensi",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Wilty",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Tarsa",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Totjin",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Mlawen",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Sas",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Mlawes",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Mumpi",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Mlakhan",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Mlat",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Klamne",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Mimpe",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Klarion",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Klais",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Klabra",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Klabot",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Kaas",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Hobard",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Indiwi",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klabot",
                "kelurahan": "Buk",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Disfra",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Beraur",
                "kelurahan": "Bagung",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Brianlo",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Klawak",
                "kelurahan": "Ayolokle",
                "kodepos": "98453"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Wasingsan",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Wayenkede",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Wajin",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Waliam",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Segun",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Waimon",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Wainlabat",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Seget",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Sakapul",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Maralol",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Sailolof",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Majemau",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Malabam (Malaban)",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Manoket",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Klasigon (Klasegun)",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Klayas",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Kasimle",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Katlol (Klotlof/Katlof)",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Seget",
                "kelurahan": "Kasim",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Segun",
                "kelurahan": "Gisim",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Dulbatan",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Salawati Selatan",
                "kelurahan": "Duriankari",
                "kodepos": "98452"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Wonosobo",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Wen",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Walal",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Ninjemur",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Matawolot",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Rawa Sugi",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Makotyamsa",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Malaus",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Makbalim",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Makbusun (Makbusum)",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Majaran",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Majener",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Klawoton",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Klasof",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Klasmelek",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Klasarin",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Klalin (Klain)",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Klafdalim",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Moisegen",
                "kelurahan": "Klaforo",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Salawati",
                "kelurahan": "Katinim",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Arar",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Mayamuk",
                "kelurahan": "Jeflio (Yeflio/Yefilio)",
                "kodepos": "98451"
            },
            {
                "kecamatan": "Sorong Utara",
                "kelurahan": "Matalamagi (Matalamangi)",
                "kodepos": "98419"
            },
            {
                "kecamatan": "Sorong Utara",
                "kelurahan": "Sawagumu",
                "kodepos": "98419"
            },
            {
                "kecamatan": "Sorong Utara",
                "kelurahan": "Malaingkedi",
                "kodepos": "98419"
            },
            {
                "kecamatan": "Sorong Utara",
                "kelurahan": "Malanu",
                "kodepos": "98419"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klasuat",
                "kodepos": "98418"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klawuyuk",
                "kodepos": "98419"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klamana",
                "kodepos": "98418"
            },
            {
                "kecamatan": "Sorong",
                "kelurahan": "Remu Utara",
                "kodepos": "98416"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klasaman",
                "kodepos": "98417"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Giwu",
                "kodepos": "98418"
            },
            {
                "kecamatan": "Sorong Manoi",
                "kelurahan": "Malabutor",
                "kodepos": "98416"
            },
            {
                "kecamatan": "Sorong Manoi",
                "kelurahan": "Malawei",
                "kodepos": "98416"
            },
            {
                "kecamatan": "Sorong Manoi",
                "kelurahan": "Klasabi",
                "kodepos": "98416"
            },
            {
                "kecamatan": "Sorong Utara",
                "kelurahan": "Klagete",
                "kodepos": "98416"
            },
            {
                "kecamatan": "Sorong Manoi",
                "kelurahan": "Remu Selatan",
                "kodepos": "98415"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klawalu",
                "kodepos": "98415"
            },
            {
                "kecamatan": "Sorong Manoi",
                "kelurahan": "Klaligi",
                "kodepos": "98414"
            },
            {
                "kecamatan": "Sorong",
                "kelurahan": "Klasuur",
                "kodepos": "98414"
            },
            {
                "kecamatan": "Sorong",
                "kelurahan": "Klakublik",
                "kodepos": "98414"
            },
            {
                "kecamatan": "Sorong",
                "kelurahan": "Klademak",
                "kodepos": "98414"
            },
            {
                "kecamatan": "Sorong Timur",
                "kelurahan": "Klablim",
                "kodepos": "98414"
            },
            {
                "kecamatan": "Sorong Kepulauan",
                "kelurahan": "Soop",
                "kodepos": "98413"
            },
            {
                "kecamatan": "Sorong Kepulauan",
                "kelurahan": "Raam",
                "kodepos": "98413"
            },
            {
                "kecamatan": "Sorong",
                "kelurahan": "Kampung Baru",
                "kodepos": "98413"
            },
            {
                "kecamatan": "Sorong Kepulauan",
                "kelurahan": "Dum Timur (Doom Timur)",
                "kodepos": "98413"
            },
            {
                "kecamatan": "Sorong Kepulauan",
                "kelurahan": "Dum Barat (Doom Barat)",
                "kodepos": "98413"
            },
            {
                "kecamatan": "Sorong Barat",
                "kelurahan": "Rufei",
                "kodepos": "98412"
            },
            {
                "kecamatan": "Sorong Barat",
                "kelurahan": "Klabala",
                "kodepos": "98412"
            },
            {
                "kecamatan": "Sorong Barat",
                "kelurahan": "Tanjung Kasuari",
                "kodepos": "98411"
            },
            {
                "kecamatan": "Sorong Barat",
                "kelurahan": "Saoka",
                "kodepos": "98411"
            },
            {
                "kecamatan": "Sorong Barat",
                "kelurahan": "Klawasi",
                "kodepos": "98411"
            }
        ],
        "k325": [
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Wenslolo",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Wenslolo",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Wensough",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Fokour",
                "kelurahan": "Welek",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Wendi",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Ween",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Fokour",
                "kelurahan": "Wandum",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Sawiat",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Sfakyo",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Sodrofoyo",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Sasnek",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Fokour",
                "kelurahan": "Pasir Putih",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Mlabolo",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Kofalit",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Klamit",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Fokour",
                "kelurahan": "Bemus",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Elles",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Sawiat",
                "kelurahan": "Alma",
                "kodepos": "98456"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Yahadian",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Wadoi",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Udagaga",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Tawanggire",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Topdan",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Totona",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Tarof",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Tapuri",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Tambani",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Tapas",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Solta Baru",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Sumano",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Siwatori",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Siri-siri",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Siranggo",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Serkos",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Sibae",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Puragi",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Saga",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Odeare",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Onimsefa",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Nusa",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Negeri Besar",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Nayakore",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Mukamat",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Mugim",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Mogotemin (Mogatemin)",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Mogibi",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Migrito",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Migori",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Mate",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Mate",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Korewatara",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Kayubiro",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Kasuweri",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Kamundan Satu",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Karirif",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Kais",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Kamundan Dua",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Inanwatan (Inawatan)",
                "kelurahan": "Isogo",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Haemaran (Kurana)",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Ikana",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Bubuko",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Daimar",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Daubak",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Benawa II",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Birawaku",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Matemani",
                "kelurahan": "Bedare",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kais (Matemani Kais)",
                "kelurahan": "Benawa I",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda",
                "kelurahan": "Arbasina",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Atori",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Kokoda Utara",
                "kelurahan": "Adona",
                "kodepos": "98455"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Wersar",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Woloin",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Wernas",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Wehali",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Wermit",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Wayer",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Wamargege",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Wardik",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Ungi (unggi)",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Waigo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Tokass",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Tofot",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Tapiri",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Tegirolo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Sungguer",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Srer",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Sisir",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Seyolo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Siribau",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Siribau",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Sesor",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Sbir",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Sayal",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Nambro",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Nagna",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Moswaren",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Mlaswat",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Manggroholo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Manelek",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Kwowok",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Magis",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Konda",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Komanggaret",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Klaogin",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Keyen",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Kohoin",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Kayabo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Kenaya",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Kamisabe",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Kamisabe",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Kaibus",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Joshiro",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Kakas",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Hararo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Hasik Jaya",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Gorolo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Seremuk",
                "kelurahan": "Haha",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Moswaren",
                "kelurahan": "Bumi Ajo",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Saifi",
                "kelurahan": "Botain",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Baldon (Boldon)",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Bariat",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Wayer",
                "kelurahan": "Bagraga (Bagaraga)",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Aibobor",
                "kodepos": "98454"
            },
            {
                "kecamatan": "Teminabuan",
                "kelurahan": "Ani Sesna",
                "kodepos": "98454"
            }
        ],
        "k326": [
            {
                "kecamatan": "Moskona Timur",
                "kelurahan": "Sumuy (Sumui)",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Utara",
                "kelurahan": "Moyeba",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Mowitka",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Utara",
                "kelurahan": "Mosum",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Morombuy",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Mogromus",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Meyorga",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Meyom",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Meyetga",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Masyeta",
                "kelurahan": "Mesomda",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Timur",
                "kelurahan": "Mesna",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Meryeb",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Utara",
                "kelurahan": "Merestim (Maristim)",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Merdey",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Menyembrui (Menyembru)",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Mekiesefeb",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Menggerba",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Masyeta",
                "kelurahan": "Masyeta",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Masyeta",
                "kelurahan": "Maestofu (Mestofu)",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Laudoho",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Masyeta",
                "kelurahan": "Kalibiru",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Jahabra",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Utara",
                "kelurahan": "Inofina",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Moskona Timur",
                "kelurahan": "Igomu",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Ibori",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Biscoop",
                "kelurahan": "Eniba",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Merdey",
                "kelurahan": "Anajero",
                "kodepos": "98373"
            },
            {
                "kecamatan": "Weriagar",
                "kelurahan": "Weriagar Utara",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Weriagar",
                "kelurahan": "Weriagar",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Weriagar",
                "kelurahan": "Weriagar Baru",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Mayado",
                "kelurahan": "Vasco Damneen (Vesco Damnem/Sten)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Weriagar",
                "kelurahan": "Tuanaikin",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Tomu",
                "kelurahan": "Sebyar Rejosasi/Rejosari",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Tomu",
                "kelurahan": "Taroy (Toray/Taroi)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Tomu",
                "kelurahan": "Tomu",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Weriagar",
                "kelurahan": "Mogotira (Mogmesa)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Selatan",
                "kelurahan": "Rawara",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Barat",
                "kelurahan": "Meyerga (Mayerga)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Selatan",
                "kelurahan": "Meyenda",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Aranday",
                "kelurahan": "Manunggal Karya/Jaya",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Mayado",
                "kelurahan": "Meyado (Mayado/Meyabo)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Barat",
                "kelurahan": "Mejnic (Majnic/Majnik)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Barat",
                "kelurahan": "Macok",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Kamundan",
                "kelurahan": "Kenara",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Kamundan",
                "kelurahan": "Kalitama II (Kalitami II)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Aranday",
                "kelurahan": "Kecap",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Barat",
                "kelurahan": "Istiwkem (Istikem)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Selatan",
                "kelurahan": "Jagiro",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Kamundan",
                "kelurahan": "Kalitama I (Kalitami I)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Selatan",
                "kelurahan": "Inggof",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Tomu",
                "kelurahan": "Ekam",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Kamundan",
                "kelurahan": "Bibiram",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Mayado",
                "kelurahan": "Barma Baru (Stencool)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Aranday",
                "kelurahan": "Baru (Kampung Baru)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Moskona Selatan",
                "kelurahan": "Barma Barat",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Mayado",
                "kelurahan": "Barma",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Aranday",
                "kelurahan": "Aranday (Arandai)",
                "kodepos": "98365"
            },
            {
                "kecamatan": "Wamesa (Idoor)",
                "kelurahan": "Yansei (Yansey/Ensei/Yensei)",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Wesiri",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Waraitama",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Ugdohop",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Wamesa (Idoor)",
                "kelurahan": "Wamesa I &#8211; Idoor",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Wamesa (Idoor)",
                "kelurahan": "Wamesa II &#8211; Yakati",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tuhiba",
                "kelurahan": "Tuhiba",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Tuasai",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tuhiba",
                "kelurahan": "Tisaida",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Sir",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tuhiba",
                "kelurahan": "Sibena Raya (I)",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tembuni",
                "kelurahan": "Tembuni",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tuhiba",
                "kelurahan": "Sibena Permai (II)",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Pasamai",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Menci",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tembuni",
                "kelurahan": "Mogoi Baru",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Masina",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Wamesa (Idoor)",
                "kelurahan": "Mamuranu",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tuhiba",
                "kelurahan": "Kucir",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Korano Jaya",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Iguriji",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Cumnaji",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Huss",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Dataran Beimes",
                "kelurahan": "Horna (Homa)",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Bintuni Timur",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Bumi Saniari",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Beimes",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Bintuni Barat",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Banjar Ausoy",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tembuni",
                "kelurahan": "Bangun Mulyo/Mulia",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Manimeri",
                "kelurahan": "Atibo Manimeri",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Bintuni",
                "kelurahan": "Argosigemerai",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Tembuni",
                "kelurahan": "Araisum",
                "kodepos": "98364"
            },
            {
                "kecamatan": "Aroba",
                "kelurahan": "Yaru",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Aroba",
                "kelurahan": "Wimbro (Nelayan Wimbro)",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kaitaro",
                "kelurahan": "Warganusa I (Warbanusa I)",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kaitaro",
                "kelurahan": "Warga Nusa II",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kuri",
                "kelurahan": "Wagura",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kaitaro",
                "kelurahan": "Suga",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kaitaro",
                "kelurahan": "Tugarama (Tugerama)",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kuri",
                "kelurahan": "Sarbe",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Sumuri (Simuri)",
                "kelurahan": "Tanah Merah",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kaitaro",
                "kelurahan": "Sara",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Sumuri (Simuri)",
                "kelurahan": "Tofoi",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Aroba",
                "kelurahan": "Sangguar",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Aroba",
                "kelurahan": "Sido Makmur",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Sumuri (Simuri)",
                "kelurahan": "Saengga",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Fafurwar (Irorutu)",
                "kelurahan": "Riendo",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kuri",
                "kelurahan": "Refideso",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kuri",
                "kelurahan": "Naramasa",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Kuri",
                "kelurahan": "Obo",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Babo",
                "kelurahan": "Nusei",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Fafurwar (Irorutu)",
                "kelurahan": "Meryedi (Maryedi)",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Babo",
                "kelurahan": "Kasira",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Sumuri (Simuri)",
                "kelurahan": "Materabu Jaya",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Babo",
                "kelurahan": "Irarutu III",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Fafurwar (Irorutu)",
                "kelurahan": "Fruata (Fuata/Irorutu II)",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Sumuri (Simuri)",
                "kelurahan": "Forada",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Babo",
                "kelurahan": "Amutu",
                "kodepos": "98363"
            },
            {
                "kecamatan": "Aroba",
                "kelurahan": "Aroba",
                "kodepos": "98363"
            }
        ],
        "k327": [
            {
                "kecamatan": "Roon",
                "kelurahan": "Yende",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Wosimi (Wosimo)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Yabore",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Yende",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wondiboy (Wasior Selatan)",
                "kelurahan": "Wondiboi (Wondiboy)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Teluk Duairi (Wasior Utara)",
                "kelurahan": "Yopanggar (Yopengar)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Wondamawi",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Wasior II",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Webi",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Wasior I",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Undurara",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Uryemi (Uriyem/Uriemi)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Torey",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Tandia",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Syabes",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Teluk Duairi (Wasior Utara)",
                "kelurahan": "Sobey Indah",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Kuri Wamesa",
                "kelurahan": "Sobiar",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Teluk Duairi (Wasior Utara)",
                "kelurahan": "Sobei (Sobey)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Kuri Wamesa",
                "kelurahan": "Simei (Simiei)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Sasirey (Sasirei)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Senderawoy / Senderawoi (Sindrawoi)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Sariay",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Sararti",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Ramiki",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Rasiey (Rasiei)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Rado",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Oya (Oyaa)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Nggatum",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Niab",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Moru (Gayabaru)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Kuri Wamesa",
                "kelurahan": "Nanimori",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Menarbu",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Mena",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wondiboy (Wasior Selatan)",
                "kelurahan": "Manopi",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Maniwak",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Maimare (Maimari)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wondiboy (Wasior Selatan)",
                "kelurahan": "Kaibi",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wondiboy (Wasior Selatan)",
                "kelurahan": "Isuy (Issui)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wondiboy (Wasior Selatan)",
                "kelurahan": "Kabouw (Kabuow)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Rasiei",
                "kelurahan": "Isey (Isei)",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Iriati",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Naikere (Wasior Barat)",
                "kelurahan": "Inyora",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roon",
                "kelurahan": "Inday",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Kuri Wamesa",
                "kelurahan": "Dusner",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Wasior",
                "kelurahan": "Dotir",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Kuri Wamesa",
                "kelurahan": "Ambumi",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Teluk Duairi (Wasior Utara)",
                "kelurahan": "Aisandami",
                "kodepos": "98362"
            },
            {
                "kecamatan": "Roswar",
                "kelurahan": "Yomber",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Yopmios",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Yembekiri II",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Yomakan",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Yombekiri I (Yembe Kiri)",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Soug Jaya",
                "kelurahan": "Yarmatum (Yermatum)",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Wamesa",
                "kelurahan": "Yarpate",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Yariari",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Windesi",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Nikiwar",
                "kelurahan": "Werianggi",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Wetitindau",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Nikiwar",
                "kelurahan": "Werabur",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Wamesa Tengah",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Roswar",
                "kelurahan": "Waprak",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Roswar",
                "kelurahan": "Syeiwar",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Nikiwar",
                "kelurahan": "Tamoge",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Sombokoro",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Soug Jaya",
                "kelurahan": "Siresi",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Windesi",
                "kelurahan": "Sandey",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Soug Jaya",
                "kelurahan": "Reyob (Rayob)",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Wamesa",
                "kelurahan": "Sabubar",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Soug Jaya",
                "kelurahan": "Nuspairo",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Wamesa",
                "kelurahan": "Nordiwar",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Nikiwar",
                "kelurahan": "Mamisi",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Nikiwar",
                "kelurahan": "Kurei",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Wamesa",
                "kelurahan": "Karuan",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Soug Jaya",
                "kelurahan": "Kaprus",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Isenebuai (Senebuay/Senebuai)",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Rumberpon",
                "kelurahan": "Iseren (Isren)",
                "kodepos": "98361"
            },
            {
                "kecamatan": "Wamesa",
                "kelurahan": "Ambuar",
                "kodepos": "98361"
            }
        ],
        "k328": [
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Umuhousi (Umousi)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Tubes",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Tahosta (Tohosta)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Sibjo",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Mindermes",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Isim",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Inyuara",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Hugamod (Hugomot)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Duhugesa",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Disra (Desra)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Disihu (Dihisu)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Dataran Isim",
                "kelurahan": "Dibera",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Yekwandi",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Tahota (Tohota)",
                "kelurahan": "Yermatum",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Yamboy (Yamboi)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Wama",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Waren",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Toboo (Tobouw/Tobou)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Wamcei",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Susmorof",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Swer",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Siwi",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Tahota (Tohota)",
                "kelurahan": "Seimeba",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Sesum",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Sabri",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Tahota (Tohota)",
                "kelurahan": "Reyob",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Nuhuwai (Nuhuei/Nuhuwei)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Ransiki Kota",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Neny Pantai (Nenei Pantai/Nij)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Nenei (Neney)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Kobrey",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Mambrema",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Tahota (Tohota)",
                "kelurahan": "Kaprus",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Hiyou",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Hamawi",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Hamor",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Gaya Baru",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Disi",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Dembek",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Momi Waren",
                "kelurahan": "Demini",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Bamaha",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Benyas",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Neney (Nenei)",
                "kelurahan": "Aryawen Moho (Aryawenm)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Ransiki",
                "kelurahan": "Abresso (Abreso)",
                "kodepos": "98355"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Watariri",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Warkwandi",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Waroser",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Wamigti",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Wandoki",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Warbiadi",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Sidomulyo",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Sindang Jaya (Sidang Jaya)",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Muari",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Oransbari",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Masabui Dua (II)",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Masabui Satu (I)",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Margorukun",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Akeju",
                "kodepos": "98353"
            },
            {
                "kecamatan": "Oransbari",
                "kelurahan": "Margomulyo",
                "kodepos": "98353"
            }
        ],
        "k329": [
            {
                "kecamatan": "Sururey",
                "kelurahan": "Tomstera",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Tuhubea (Duhubeya)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Tomsir",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Sungedes (Sungudes)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Sneraner (Sneremer)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Sururey (Sururai)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Sirgemeih (Sergemeh)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Miseda",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Saugemeba",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Kusmenau",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Menesrij",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Kopo",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Kostera",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Kobrey",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Iranmeba",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Inyaub",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Gedeira",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Duguhani (Dugahani)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Dugrimog",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Desra (Disra)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Debetik (Dibetik)",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Demdamei",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Cirnohu",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Danmou",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Didohu",
                "kelurahan": "Cigera",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Sururey",
                "kelurahan": "Anuk",
                "kodepos": "98359"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Ungga",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Waminda (Wamminda)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Unti",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Umpug",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Ugjehek (Ujeheg)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Sugemah (Sugemeh)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Timtou (Timtouw)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Soudin",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Smainggi (Smaingei/Smanggei)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Slomiou",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Siyau (Syou)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Simerbei",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Sinaitousi (Sinaytoisi/Sinitousi)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Njuar",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Piyausi (Pinyausi)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Sigim",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Ndabouw",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Nimbiau",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Ninsimoi",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Mnoubei (Minogbei/Minogbey/Minbey)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Mokwam",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Mitiede",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Misapngoisi (Misangoisi)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Misadwer",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Minmo",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Minggre (Mingre)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Minhou (Mihou)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Miconti (Micomti)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Mieicomti (Miey Comty/Mieycomti)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Mihij",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Micadiwor",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Mbigma (Mbingma)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Memanker (Memangker)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Menyambouw (Minyambouw)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Mainda",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Manggesuk",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Kwau",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Kibauw (Kipouw)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Jim",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Kaungwam",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Inggrahim (Inggramhim)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Injuar",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Ipingoisi (Ipingosi)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Indonbey (Indobey/Indondey)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Imbrekti",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Indabri",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Imbenti",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Imbonggun",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Imandrigo",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Idemai",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Ijigrek (Ijigreg)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Driye",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Handuk",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Demaisi",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Catubouw",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Coijut (Coijud)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Coisi",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Bingwoyud (Bingywoyut)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Bueibei (Dueibei)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Bingrayud (Binggarud)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Bingwaimod (Bigwaimud)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Bahamyenti",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Awibehey (Awibehei)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Ayau",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Apui",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Asarbey (Asarbei)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Andang",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Anggra",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Catubouw",
                "kelurahan": "Aiga",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Aiwou (Aywou)",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Amber",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Aduer",
                "kodepos": "98358"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Urwong",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Tumbeibehei",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Umcep",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Tigau Comti",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Tingkwoikio (Tingwoikiou)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Tingkwoikio (Tingwoikiou)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Sopnyai",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Punggung (Pungug)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Nungkimor",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Penibut",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Ntap",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Ngimoubre (Ngimoubri)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Monut",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Minggot (Manggot)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Minyeimemut (Minyeimemud)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Mbrande",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Mbegau",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Leiheak (Liehak)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Kwok II (Kwokwisngung)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Kwok I",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Kwaiyehep",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Kisap",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Ikimabow (Ikimabou)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Humeysi (Humeisi)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Gweipingbai (Gueipinbai)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Heatie Bouw (Haktiebou)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Guweuti (Gueiuti/Guwei Uti)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Demunti",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Cangoisi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Asai II (Asay II)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Hingk",
                "kelurahan": "Arion (Aryon)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Usti",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Uper",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Ubeisa",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Tubyam (Tuabyam)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Ulong (Ullong)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Tridaga",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Tombrok",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Taiga (Taige)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Suteibei (Suteibey)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Testega Pamaha",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Spiyougup",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Srubey",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Susi",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Sisrang (Seseran)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Siskedouw (Siskedowo)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Sakumi (Sakuni/Sakurai)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Sibiogud",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Mentubei (Mentubey)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Pamaha",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Ngisrow (Ingisrow)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Membey",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Memti",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Krobut (Kiwot)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Koney",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi Gida",
                "kelurahan": "Itkau",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Inyebow (Inyebouw)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Iraiweri",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Irbos",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Membey",
                "kelurahan": "Imbeisaba",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Igmbai (Igembai/Imbeisba)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Imbai",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Horeta",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Hungku",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Genyu",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Disura",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Anggi",
                "kelurahan": "Ayau (Ayaubey)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Deroubu (Deuroba)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Asum (Ansum)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Taige",
                "kelurahan": "Awigau (Awaikum/Awaigau)",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Cening",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Testega",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Testega",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Mofoukeda",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Morumfeyi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Mengehena (Meigehenawu)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Menjugiji (Mejugiji)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Meifowoska",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Meimersa",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Meksi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Meidogda",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Meifekeni",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Jigja (Jijga)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Iba",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Menyambouw (Minyambouw)",
                "kelurahan": "Figoud",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Dumbre (Dumbrey)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Testega",
                "kelurahan": "Demoura (Demuora)",
                "kodepos": "98315"
            }
        ],
        "k330": [
            {
                "kecamatan": "Masni",
                "kelurahan": "Yonsoribo (Yen Soribo)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Wariori",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Wariori Indah",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Yensum",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Wariki",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Waramui (Waramoi)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Wamnowi (Womnowi)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Wamfoura (Wam Bola)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Undi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Urey",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Sidey Makmur",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Sidey Baru",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Sidey Jaya",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Sibuni",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Sidey",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Sembab",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Saray",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Ririnfos",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Prafi Barat",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Muara Wariori",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Mowbja (Moubja/Mobja)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Muara Prafi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Meyof II",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Meyof I",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Meyeruk",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Merejemeg (Merejneg)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Meiforga",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Membowi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Masni",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Manggupi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Mansaburi (Smaryam)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Mantedi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Macuan",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Makwan",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Koyani (Koyami)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Kasi (Kassi)",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Kali Merah",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Sidey",
                "kelurahan": "Kaironi",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Jowen",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Inyei",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Igor",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Bowi Subur",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Aurmios",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Masni",
                "kelurahan": "Aska",
                "kodepos": "98357"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Wasegi/Waseki Indah",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Wasegi Pop (Waseki)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Wanggonoma",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Umbui (Umbuy)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Uhyehekbrig (Ogyehek/Oyehek Brig)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Udapi Hilir (Udap Hilir)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Prafi Mulia/Mulya",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Semi (Somi)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Lismaungu (Lismanggu)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Krenu (Kerenu/Krenui)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Kali Amin",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Inguisi (Ingkwoisi)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Desay",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Bogor",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Bendip Matoa (Debit Motoa)",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Aimasi",
                "kodepos": "98356"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Urwambei",
                "kodepos": "98354"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Umcen (Umcem)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Warmare",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Subsay",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Tanah Merah",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Sotea",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Srayndabey (Sraindabey)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Ngungguen",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Nimbay",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Snaimboy",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Madrat",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Meniy (Meny)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Imboisrati (Iboisrati)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Indisey",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Hingk",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Ibuwau",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Duwin (Duwin Umsini)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Gueintuy (Guentuy)",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Warmare",
                "kelurahan": "Dindey",
                "kodepos": "98352"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Yoom II",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Wedoni",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Yonggam",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Yoom I",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Waruri (Weluri)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Wasay (Wasai)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Warmumi (Warmomi)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Warnyeti",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Warsuami (Waswami)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Warkomi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Warmawai",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Wariari",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Warkapi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Warbefor",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Umnum",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Warami",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Tanah Rubu",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Ukemboisi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Teluk Mubri",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Sowi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Sayro",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Singgimeba",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Pami",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Saubeba",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Rembuy",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Ningdip",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Ngunibouw (Nginibouw/Nginybou)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Nimbiauw (Nimbauw)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Mupi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Mubraidiba",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Mubri",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Mironi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Misabugoid",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Misapmeysi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Meyes",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Meyunfoka",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Menyumfoku",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Prafi",
                "kelurahan": "Mebiji / Mebji",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Mebji",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Mbatma",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Maruni",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Masiepi (Masyepi)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Lebau",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Mandopi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Katebu",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Inya",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Iroduas",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Indibo",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Indo Oufa",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Imhasuma",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Imboisika",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Imboiti",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Imbeisika",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Hanghouw",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Dobut (Doopud/Doput)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Dihara",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Cuyehep",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Bremi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Caban Dua",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Utara",
                "kelurahan": "Asai",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Tanah Rubuh",
                "kelurahan": "Ayawi",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Angrisi (Anggresi)",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Acemo",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Selatan",
                "kelurahan": "Anday",
                "kodepos": "98315"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Amban",
                "kodepos": "98314"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Arowi",
                "kodepos": "98313"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Pasir Putih",
                "kodepos": "98313"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Wosi",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Sanggeng",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Soribo",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Udopi",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Manokwari Barat",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Padarni",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Binirauw (Biniraw)",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Inggramui",
                "kodepos": "98312"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Mansinam",
                "kodepos": "98311"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Susweni",
                "kodepos": "98311"
            },
            {
                "kecamatan": "Manokwari Barat",
                "kelurahan": "Manokwari Timur",
                "kodepos": "98311"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Ayambori",
                "kodepos": "98311"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Bakaro",
                "kodepos": "98311"
            },
            {
                "kecamatan": "Manokwari Timur",
                "kelurahan": "Aipiri",
                "kodepos": "98311"
            }
        ],
        "k331": [
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Teratak Air Hitam",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Tanjungsimandolak",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Talontom",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Tanjung",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Simandolak",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Pulautongah",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Siberakun",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Seberang Teratak Air Hitam",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Pulaulancang",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Pulaukopung Sentajo",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Pulaukalimanting",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Pulaukomang Sentajo",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Muaro Sentajo",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Parit Teratak Air Hitam",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Pulauingu",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Marsawa",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Muara Langsat",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Koto Sentajo",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Langsat Hulu",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Jalur Patah",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Kampung Baru Sentajo",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Koto Benai",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Geringing Baru",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Gunungkesiangan",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Beringin Jaya",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Sentajo Raya",
                "kelurahan": "Geringging Jaya",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Benai",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Benai Kecil",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Banjar Benai",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Benai",
                "kelurahan": "Banjar Lopak",
                "kodepos": "29566"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Teluk Beringin",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Toar",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Teberau Panjang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Tanjung Medang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Sungai Kalilawar/Kelelawar",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Sungai Pinang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Sigaruntang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Sumpu",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Sungai Alah",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Siberobah",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Seberang Pulau Busuk",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Serosah/Serusa",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Seberang Sungai",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Sampurago",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Seberang Gunung",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pulau Panjang Hulu",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pulau Sipan",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Pulau Rumput",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pulau Panjang Hilir",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Pulau Mungkur",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pulau Busuk Jaya",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Pisang Berebus",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pulau Busuk",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Petapahan",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Pasar Inuman",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Lubuk Ambacang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Mudik Ulo",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Lubuk Terantang",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Koto Kombu",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Lebuh Lurus",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Koto Gunung",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Koto Inuman",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Kampung Baru Koto",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Ketaping Jaya",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Hulu Kuantan",
                "kelurahan": "Inuman",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Kampung Baru",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Gunung Toar",
                "kelurahan": "Gunung",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Bedeng Sikuran",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Inuman",
                "kelurahan": "Banjar Nantigo",
                "kodepos": "29565"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Sungaimanau",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Sungaibesar",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Sungaibesar Hilir",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Setiang",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Seberang Cengar",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Seberang Pantai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Saik",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Sangau",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Perhentian Sungkai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Pulaubinjai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Rantau Sialang",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Pebaun Hilir",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Pebaun Hulu",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Pantai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Pangkalan",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Pasar Lubuk Jambi",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Muaro Tombang",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Muaro Tiu Makmur",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Muarapetai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Muaratobek",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Luai",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Lubuk Ramo",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Kinali",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Koto Cengar",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Koto Lubuk Jambi",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Kasang",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Kampung Baru Ibul",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Bukitpedusunan",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Pucuk Rantau",
                "kelurahan": "Ibul",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Banjar Guntung",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Banjar Padang",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Bukit Kauman",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Aur Duri",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Kuantan Mudik",
                "kelurahan": "Air Buluh",
                "kodepos": "29564"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Sungai Sirih",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Tanjung Pauh",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Sungai Kuning",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Sungai Paku",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Sungai Keranji",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Sungai Buluh",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Sungai Bawang",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Sumber Jaya",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Sumber Datar",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Suka Damai",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Sukamaju",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Simpang Raya",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Pulau Padang",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Petai",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Pasir Emas",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Petai",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Muara Lembu",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Pangkalan Indarung",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Muara Bahan",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Logas Hilir",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Koto Baru",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Kebun Lado",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Logas",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Beringin Jaya",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Singingi Hilir",
                "kelurahan": "Bukit Raya",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Teratak Jering",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Singingi",
                "kelurahan": "Air Mas/Emas",
                "kodepos": "29563"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Tanjung",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Tanjungpisang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Teratak Baru",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Sungaisorik",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Simpang Tanah Lapang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Rawang Oguang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Simpang Pulauberalo",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Pulaukulur",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Pulaumadinah",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Rawang Bonto",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Pulaubaru",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Pulauberalo",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Pulaukijang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Perhentian Luas",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Pelukahan",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Pengalihan",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Pasar Baru",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Pasar Usang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Koto Tuo",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Koto Rajo",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Lumbok",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Kepala Pulau",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Kasang Limau Sundai",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Kampung Medan",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Kampung Tengah",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Dusun Tuo",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Gunungmelintang",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Kampung Madura",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir",
                "kelurahan": "Banuaran",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Kuantan Hilir Seberang",
                "kelurahan": "Danau",
                "kodepos": "29561"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Sungai Rambai",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Teratak Rendah",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Situgal",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Sukaraja",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Sidodadi",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Sikijang",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Lubuk Kebun",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Rambahan",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Sako Margosari",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Hulu Teso",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Kuantan Sako",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Logas",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Bumi Mulya",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Logas Tanah Darat",
                "kelurahan": "Giri Sako",
                "kodepos": "29556"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Teluk Pauh",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Tanjung Medan",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Sikakak",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Pulau Panjang Cerenti",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Pulau Jambu",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Pulau Bayur",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Pesikaian/Pasikaian",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Pasar Cerenti",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Koto Cerenti",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Koto Peraku",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Kompe Berangin",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Kampung Baru",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Cerenti",
                "kelurahan": "Kampung Baru Timur",
                "kodepos": "29555"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Tanah Bekali",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Teluk Pauh",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Sungai Langsat",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Sukaping",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Sako",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pulau Tengah/Tongah",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Rawang Binjai",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pulau Rengas",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pulau Kumpai",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pulau Deras",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pembatang",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pauh Angit",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pauh Angit Hulu",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Padang Tanggung",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Pasar Baru Pangean",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Padang Kunik",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Pangean",
                "kelurahan": "Koto Pangian/Pangean",
                "kodepos": "29553"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Koto Taluk",
                "kodepos": "29515"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Sungaijering",
                "kodepos": "29513"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pulaukedundung",
                "kodepos": "29514"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Titian Modang Kopah",
                "kodepos": "29512"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Sitorajo",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Seberang Taluk Hilir",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Simpang Tiga",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Seberang Taluk",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Sawah",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pulaugodang Kari",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pulaubaru",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pulaubanjar Kari",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pulauaro",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pasar Teluk",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Pintu Gobang",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Koto Tuo",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Munsalo",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Kopah",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Koto Kari",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Beringin Taluk",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Jake",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Jaya",
                "kodepos": "29511"
            },
            {
                "kecamatan": "Kuantan Tengah",
                "kelurahan": "Bandar Alai",
                "kodepos": "29511"
            }
        ],
        "k332": [
            {
                "kecamatan": "Seberida",
                "kelurahan": "Serasam",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Sibabat",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Titian Resak",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Payarumbai",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Petala Bumi",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Pangkalan Kasai",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Buluh Rampai",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Kelesa",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Beligan",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Seberida",
                "kelurahan": "Bukit Meranti",
                "kodepos": "29371"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Tanjung Danau",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Tanjung Danau",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Sungai Air Putih",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Sungai Lala",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Perkebunan Sei/Sungai Lala",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Perkebunan Sei/Sungai Parit",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Pasir Bongkal",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Pasir Kelampaian",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Pasir Selabau",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Morong",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Pasir Batu Mandi",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Kelawat",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Sungai Lala",
                "kelurahan": "Kuala Lala",
                "kodepos": "29363"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Usul",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Talang Lakat",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Talang Bersemi",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Talang Mulia/Mulya",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Sungai Akar",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Siambul",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Sipang",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Seberida",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Sanglap",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Ringin",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Punti Anai",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Rantau Langsat",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Pematang Manggis",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Penyaguan",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Pataling/Petaling Jaya",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Pejangki",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Lahai Kemuning",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Kuala Gading",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Kuala Kilan",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Kerubung Jaya",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Danau Rambai",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Kepayang Sari",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Bukit Lingkar",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Bukit Lipai",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Cenaku Kecil",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Gansal",
                "kelurahan": "Belimbing",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Anak Talang",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Aur Cina",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Anak Talang",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Cenaku",
                "kelurahan": "Alim",
                "kodepos": "29355"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Sukamaju",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Sungai Aur",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Serai Wangi",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Setako Jaya (Raya)",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Sencano Jaya",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Semelinang Tebing",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Semilinang Darat",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Pesajian",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Punti/Panti Kayu",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Selunak",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Pematang Benteng",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Peranap",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Pematang",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Peladangan",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Pauh Peranap",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Batang Peranap",
                "kelurahan": "Koto Tuo",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Pandan Wangi",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Baturijal Hulu",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Gumanti",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Ketipopura",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Baturijal Hilir",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Peranap",
                "kelurahan": "Batu Rijal Barat",
                "kodepos": "29354"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Sungai Sagu",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Wonosari",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Sukajadi",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Seko Lubuk Tigo",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Sidomulyo",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Rejosari",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Pasir Sialang Jaya",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Redang Seko",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Mekar Sari",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Pasir Ringgit",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Lirik Area",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Lambang Sari IV",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Lambang Sari V",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Japura",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Lambang Sari I II III",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Gudang Batu",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lirik",
                "kelurahan": "Banjar Balam",
                "kodepos": "29353"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Tasik Juang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Teluk Sejuah",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Tanjung Beludu",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Tanah Merah",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Tanjung Gading",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Selantai",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Tujuh Buah Tangga",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Sei/Sungai Maju",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Sei/Sungai Parit",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Pring Jaya",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Sei/Sungai Elok",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Sei/Sungai Limau",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Parigi",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Gedabu",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Talang Durian Cacar",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Sungai Banyak Ikan",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Sungai Golang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Sungai Kuning Benio",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Simpang Kelayang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Simpang Kota Medan",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Sei/Sungai Pasir Putih",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Sekar Mawar",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Serumpun Jaya",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Sei Beras Hilir",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Sei Beras-Beras",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Rimba Seminai",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Rimpian",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Pontian Mekar",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Pulau Sengkilo",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Pondok Gelugur",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Polak Pisang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Petonggan",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Petalongan",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Pelangko",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Pasir Keranji",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Lubuk Sitarak",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Pasir Beringin",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Lubuk Batu Tinggal",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Kulim Jaya",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Kulim Jaya",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Kota Baru",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Kuantan Tenang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Kota Medan",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Kembang Harum",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Kelayang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Jatirejo",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Kampung Bungo",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Dusun Tua",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Dusun Tua Pelang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Candi Rejo",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Bukit Indah",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Bukit Selanjut",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rakit Kulim",
                "kelurahan": "Batu Sawar",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Kelayang",
                "kelurahan": "Bongkal Malang",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Lubuk Batu Jaya",
                "kelurahan": "Air Putih",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Batu Gajah",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Air Molek I",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Pasir Penyu",
                "kelurahan": "Air Molek II",
                "kodepos": "29352"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Tanah Datar",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Tanah Makmur",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Sungai Baung",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Sungai Dawu",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Talang Jerinjing",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Pematang Jaya",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Pematang Reba",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Redang",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Kota Lama",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Pekan Heran",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Danau Baru",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Danau Tiga",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Bukit Petaling",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Barangan",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Rantau Bakung",
                "kodepos": "29345"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Air Jernih",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Alang Kepayang",
                "kodepos": "29351"
            },
            {
                "kecamatan": "Rengat Barat",
                "kelurahan": "Sialang Dua Dahan",
                "kodepos": "29344"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Tambak",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Tanjung Sari",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Teluk Sungkai",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Rawa Sekip",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Suka Jadi",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Pulau Gelang",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Pulau Jumat",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Rawa Asri",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Kuala Cenaku",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Kuala Cenaku",
                "kelurahan": "Kuala Mulia",
                "kodepos": "29335"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sei/Sungai Guntung Tengah",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sekip Hulu",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sungai Raya",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sei/Sungai Guntung Hilir",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Rawa Bangun",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sekip Hilir",
                "kodepos": "29318"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Rantau Mapesai",
                "kodepos": "29319"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Pasir Kemilu",
                "kodepos": "29316"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Sungai Beringin",
                "kodepos": "29317"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Kampung Pulau",
                "kodepos": "29314"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Kuantan Baru",
                "kodepos": "29314"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Kampung Dagang",
                "kodepos": "29313"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Pulau Gajah",
                "kodepos": "29313"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Kampung Besar Kota",
                "kodepos": "29312"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Kampung Besar Seberang",
                "kodepos": "29312"
            },
            {
                "kecamatan": "Rengat",
                "kelurahan": "Pasar Kota Rengat",
                "kodepos": "29311"
            }
        ],
        "k333": [
            {
                "kecamatan": "Gaung",
                "kelurahan": "Terusan Kempas",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Teluk Kabung",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Telukmerbau",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Soren",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Sungai Baru",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Pungkat",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Semambu Kuning",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Simpang Gaung",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Pintasan",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Lahang Tengah",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Lahang Baru",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Lahang Hulu",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Jerambang",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Kuala Lahang",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Belantaraya",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Gaung",
                "kelurahan": "Gembira",
                "kodepos": "29282"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Tanjung Melayu",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Teluk Dalam",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Sungai Buluh",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Sungai Piyai",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Tanjung Lajau",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Sungai Berapit",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Sungai Bela",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Perigi Raja",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kuala Indragiri",
                "kelurahan": "Sapat",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Concong Tengah",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Kampung Baru",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Panglima Raja",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Concong Dalam",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Concong",
                "kelurahan": "Concong Luar",
                "kodepos": "29281"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Talang Jangkang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Sencalang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Teluk Kelasa",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Sekara",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Sekayan",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Selensen",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Petalongan",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Petalongan",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Pebenaan",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Pengalihan/Pengalehan",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Pasar Kembang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Nusantara Jaya",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Nyiur Permai",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Pancur",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Lubuk Besar",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kuala Lemang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Lintas Utara",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Limau Manis",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kota Baru Seberida",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kuala Keritang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kota Baru Reteh",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Kemuning Tua",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Keritang",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kembang Mekar Sari",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Kemuning Muda",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Keritang",
                "kelurahan": "Kayu Raja",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Batu Ampar",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Dusun Tuk Jimun",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Tanjung Labuh",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Air Balui",
                "kodepos": "29274"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Sungai Mahang",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Sungai Terab/Terap",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Sungai Undan",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Seberang Sanglar",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Sungai Asam",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Sanglar",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Seberang Pulau Kijang",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Pulau Kecil",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Pulau Kijang",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Pulau Ruku",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Mugo Mulyo",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Pandan Sari",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Pesanggerahan (Pasenggarahan)",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Mekar Sari",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Metro",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Reteh",
                "kelurahan": "Madani",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Kuala Patah Parang",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Kuala Sungai Batang",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Benteng",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Benteng Barat",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Sungai Batang",
                "kelurahan": "Benteng Utara",
                "kodepos": "29273"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Sungai Rukam",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Teluk Medan",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Sungai Ambat",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Sungai Lokan",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Simpang Tiga Daratan",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Suhada",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Rantau Panjang",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Simpang Tiga",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Pusaran",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Pengalihan",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Jaya Bhakti",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Pantai Seberang Makmur",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Enok",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Enok",
                "kelurahan": "Bagan Jaya",
                "kodepos": "29272"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tekulai Hulu",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tekulai Hilir",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tekulai Bugis",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tanjung Baru",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Tanah Merah",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Sungai Nyiur",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Sungai Laut",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Selat Nama",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tanah Merah",
                "kelurahan": "Kuala Enok",
                "kodepos": "29271"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Tempuling",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Teluk Kiambang",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Teluk Jira",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Tanjung Pidada",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Sungai Rabit",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Sungai Salak",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Sungai Gantang",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Sungai Arah",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Rumbai Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Pekan Tua",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Pangkalan Tujuh",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Mumpa",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Kerta Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Kulim Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Kempas Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Karya Tunas Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Harapan Tani",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Karya Tani",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Tempuling",
                "kelurahan": "Harapan Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Danau Pulai Indah",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Kempas",
                "kelurahan": "Bayas Jaya",
                "kodepos": "29261"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Teluk Nibung",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Sungai Danai",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Suko Harjo Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Sri Danai",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Suka Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Sempadan Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Mayang Sari Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Pulau Burung",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Sapta Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Keramat/Kramat Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Beringin Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Binangun Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Bukit Sari Intan Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pulau Burung",
                "kelurahan": "Bangun Harjo Jaya",
                "kodepos": "29256"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Wonosari",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Tunggal Rahayu Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Terusan Beringin Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Tegal Rejo",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Teluk Bunian",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Tanjung Simpang",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Tagaraja (Tegaraja)",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Tanjung Raja",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Tagagiri Tama Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Sungai Simbar",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Sungai Teritip",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Sumber Makmur Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Sumber Sari Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Simpang Kateman",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Sumber Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Sari Mulia/Mulya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Saka Rotan",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Sapta Mulya Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Rotan Semelur",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Saka Palas Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Pinang Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Penjuru",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Pelangiran",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Kuala Selat",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Makmur Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Intan Mulia/Mulya Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Kelapa Patih Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Hidayah",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Indra Sari Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Griya Mukti Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Hibrida Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Hibrida Mulya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Teluk Belengkong",
                "kelurahan": "Gembaran",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Gaung/Baung Rejo Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Catur Karya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Bandar Sri Gemilang",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Bandar Sri Gemilang",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Pelangiran",
                "kelurahan": "Bagan Jaya",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Amal Bakti",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Suraya Mandiri",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Kateman",
                "kelurahan": "Air Tawar",
                "kodepos": "29255"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Pulau Cawan",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Sepakat Jaya",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Igal",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Khairiah Mandah",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Pelanduk",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bolak Raya",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Cahaya Baru",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bente",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bidari Tanjung Datuk",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Belaras",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Belaras Barat",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Batang Tumu",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bekawan",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bantaian (Bantayan)",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Batang Sari",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Teluk Tuasan",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Mandah",
                "kelurahan": "Bakau Aceh",
                "kodepos": "29254"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Teluk Pinang",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Teluk Sungka",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Teluk Pantaian",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Sungai Empat",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Sungai Iliran",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Kuala Gaung",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Rambaian",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Idaman",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Kelumpang",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Tasik Raya",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Gaung Anak Serka",
                "kelurahan": "Harapan Makmur",
                "kodepos": "29253"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Tanjung Siantar",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Raya",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Luar",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Piring",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Rawa",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Simpang Jaya",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Junjangan",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sungai Dusun",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Pasir Emas",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Sialang Jaya",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Gemilang Jaya",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Batang Tuaka",
                "kelurahan": "Kuala Sebatu",
                "kodepos": "29252"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Tembilahan Hulu",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Sungai Beringin",
                "kodepos": "29214"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Tembilahan Barat",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Sialang Panjang",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Sungai Intan",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Pekan Kamis",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan Hulu",
                "kelurahan": "Pulau Palas",
                "kodepos": "29213"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Tembilahan Kota",
                "kodepos": "29212"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Seberang Tembilahan Selatan",
                "kodepos": "29212"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Sungai Perak",
                "kodepos": "29212"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Seberang Tembilahan",
                "kodepos": "29212"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Seberang Tembilahan Barat",
                "kodepos": "29212"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Tembilahan Hilir",
                "kodepos": "29211"
            },
            {
                "kecamatan": "Tembilahan",
                "kelurahan": "Pekan Arba",
                "kodepos": "29212"
            }
        ],
        "k334": [
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Teluk Bano I",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Sungai Menasib (Sei Manasib)",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Suka Maju",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Simpang Kanan",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Pematang Ibul",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Salak",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Pematang Damar",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Pasir Putih Utara",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Pelita",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Pasir Putih",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Pasir Putih Barat",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Meranti Makmur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Panca Mukti",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Lubuk Jawi",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Makmur Jaya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Kencana",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Kota Paret/Parit",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Jaya Agung",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Harapan Makmur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Harapan Makmur Selatan",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Gelora",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bhayangkara Jaya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Bukit Damar",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Bukit Mas",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Sempurna",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Mukti",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Permata",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Lestari",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Makmur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Mas Raya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Kanan",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Kiri",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Bakti",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Balam",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Jaya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Balam Sempurna Kota",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Balam Sempurna",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Balai Jaya Kota",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Balam Jaya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bakti Makmur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Balai Jaya",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sinembah Utara",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bahtera Makmur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bahtera Makmur Kota",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sinembah Timur",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sinembah",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sinembah Barat",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sinembah Kota",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Simpang Kanan",
                "kelurahan": "Bagan Nibung",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Sapta Permai",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Batu",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Batu Kota",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Manunggal",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Bagan Sinembah",
                "kelurahan": "Bagan Bakti",
                "kodepos": "28992"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Teluk Pulai",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Teluk Piyai",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Teluk Piyai Pesisir",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Teluk Nilap",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Teluk Nilap Jaya",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Sungaimajo Pusako",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Tanjung Leban",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Teluk Merbau",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sungai Kubu Hulu",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sungai Segajah Jaya",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Sungai Daun",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sei/Sungai Sigajah/Segajah Makmur",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Sei/Sungai Panji Panji",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Sei/Sungai Majo",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sei/Sungai Kubu",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Rantau Panjang Kiri",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Rantau Panjang Kiri Hilir",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Sei Segajah Makmur",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu",
                "kelurahan": "Rantau Panjang Kanan",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Pulaujemur",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Pulau Halang Hulu",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Pulau Halang Muka",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Pasir Limau Kapas",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Pulau Halang Belakang",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Panipahan Darat",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Panipahan II (Baru)",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Panipahan Laut",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pasir Limau Kapas",
                "kelurahan": "Panipahan",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Kubu Babussalam",
                "kelurahan": "Jojol",
                "kodepos": "28991"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Teluknayang",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Tanjungsari",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Teluk Berembun",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Teluk Mega",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Tanjungmedan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Tanjungmedan Barat",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Tanjungmedan Utara",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Tangga Batu",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Sungaipinang",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Sungaitapah",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Sri Kayangan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Suka Jadi",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sintong Pusaka",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sintong Bakti",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sintong Makmur",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sintong",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Siarang-arang",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sekeladi",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sekeladi Hilir",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Rantau Kopar",
                "kelurahan": "Sekapas",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Rantau Kopar",
                "kelurahan": "Sei/Sungai Rangau",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Sei Meranti Darussalam",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Sedinginan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Rantau Kopar",
                "kelurahan": "Rantau Kopar",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Sei Meranti",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Rantau Bais",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Pujud Utara",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Putat",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Pujud",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Pujud Selatan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Perkebunan Tanjung Medan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Pondok Kresek",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Perkebunan Siarang-arang",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Mumugo Pemekaran",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Menggala Sempurna",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Mumugo",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Mesah",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Menggala Sakti",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Melayu Tengah",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Labuhan papan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Melayu Besar",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Melayu Besar Kota",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih Tanjung Melawan",
                "kelurahan": "Batu Hampar",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Kasang Bangsawan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Bangko Pusaka (Pusako)",
                "kelurahan": "Bangko Pusaka (Pusako)",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Tanah Putih",
                "kelurahan": "Banjar XII",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Babussalam Rokan",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Rantau Kopar",
                "kelurahan": "Bagan Cempedak",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Air Hitam",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Pujud",
                "kelurahan": "Akar Belingkar",
                "kodepos": "28983"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Teluk Pulau Hilir",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Teluk Pulau Hulu",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Rimba Melintang",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Seremban Jaya",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Mukti Jaya",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Pematang Botam",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Pematang Sikek",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Lenggadai/Langgadai Hulu",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Lenggadai/Langgadai Hilir",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Jumrah",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Karya Mukti",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Parit Aman",
                "kodepos": "28914"
            },
            {
                "kecamatan": "Rimba Melintang",
                "kelurahan": "Harapan Jaya",
                "kodepos": "28953"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Jawa",
                "kodepos": "28913"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Labuhan Tangga Besar",
                "kodepos": "28913"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Telukbano II",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Sungai Nyamuk",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Suak Air Hitam",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Suak Temenggung",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Sinaboi Kota",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Serusa",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Senaboi/Sinaboi",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Batu Hampar",
                "kelurahan": "Sei/Sungai Sialang",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Batu Hampar",
                "kelurahan": "Sei/Sungai Sialang Hulu",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Sei/Sungai Bakau",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Rokan Baru Pesisir",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Sei Besar",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Raja Bejamu",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Pekaitan",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Rokan Baru",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Labuhan Tangga Kecil",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Pedamaran",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Labuhan Tangga Baru",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Labuhan Tangga Hilir",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Kubu I",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Sinaboi (Senaboi)",
                "kelurahan": "Darussalam",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Pekaitan",
                "kelurahan": "Karyo Mulyo Sari",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Batu Hampar",
                "kelurahan": "Bantayan/Bantaian Hilir",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Batu Hampar",
                "kelurahan": "Bantayan/Bantaian",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Batu Hampar",
                "kelurahan": "Bantayan/Bantaian Baru",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Punak Pesisir",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Timur",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Punak",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Punak Meranti",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Hulu",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Jawa Pesisir",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Barat",
                "kodepos": "28912"
            },
            {
                "kecamatan": "Bangko",
                "kelurahan": "Bagan Kota",
                "kodepos": "28911"
            }
        ],
        "k335": [
            {
                "kecamatan": "Bukit Kapur",
                "kelurahan": "Kampung Baru",
                "kodepos": "28882"
            },
            {
                "kecamatan": "Bukit Kapur",
                "kelurahan": "Kayu Kapur (Bukit Kayu Kapur)",
                "kodepos": "28882"
            },
            {
                "kecamatan": "Bukit Kapur",
                "kelurahan": "Gurun Panjang",
                "kodepos": "28882"
            },
            {
                "kecamatan": "Bukit Kapur",
                "kelurahan": "Bukit Nanas / Nenas",
                "kodepos": "28882"
            },
            {
                "kecamatan": "Bukit Kapur",
                "kelurahan": "Bagan Besar",
                "kodepos": "28882"
            },
            {
                "kecamatan": "Sungai Sembilan",
                "kelurahan": "Tanjung Penyembal",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Dumai Selatan",
                "kelurahan": "Bukit Timah",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Sungai Sembilan",
                "kelurahan": "Lubuk Gaung",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Sungai Sembilan",
                "kelurahan": "Batu Teritip",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Sungai Sembilan",
                "kelurahan": "Bangsal Aceh",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Sungai Sembilan",
                "kelurahan": "Basilam Baru",
                "kodepos": "28826"
            },
            {
                "kecamatan": "Medang Kampai",
                "kelurahan": "Teluk Makmur",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Dumai Selatan",
                "kelurahan": "Ratu Sima",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Medang Kampai",
                "kelurahan": "Pelintung",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Medang Kampai",
                "kelurahan": "Guntung",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Medang Kampai",
                "kelurahan": "Mundam",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Dumai Selatan",
                "kelurahan": "Bukit Datuk",
                "kodepos": "28825"
            },
            {
                "kecamatan": "Dumai Barat",
                "kelurahan": "Simpang Tetap Darul Ichsan",
                "kodepos": "28824"
            },
            {
                "kecamatan": "Dumai Barat",
                "kelurahan": "Pangkalan Sesai",
                "kodepos": "28824"
            },
            {
                "kecamatan": "Dumai Barat",
                "kelurahan": "Purnama",
                "kodepos": "28823"
            },
            {
                "kecamatan": "Dumai Kota",
                "kelurahan": "Rimba Sekampung",
                "kodepos": "28822"
            },
            {
                "kecamatan": "Dumai Kota",
                "kelurahan": "Laksamana",
                "kodepos": "28821"
            },
            {
                "kecamatan": "Dumai Selatan",
                "kelurahan": "Mekar Sari",
                "kodepos": "28821"
            },
            {
                "kecamatan": "Dumai Barat",
                "kelurahan": "Bagan Keladi",
                "kodepos": "28821"
            },
            {
                "kecamatan": "Dumai Timur",
                "kelurahan": "Tanjung Palas",
                "kodepos": "28816"
            },
            {
                "kecamatan": "Dumai Timur",
                "kelurahan": "Jaya Mukti",
                "kodepos": "28815"
            },
            {
                "kecamatan": "Dumai Timur",
                "kelurahan": "Buluh Kasap",
                "kodepos": "28814"
            },
            {
                "kecamatan": "Dumai Timur",
                "kelurahan": "Teluk Binjai",
                "kodepos": "28813"
            },
            {
                "kecamatan": "Dumai Selatan",
                "kelurahan": "Bumi Ayu",
                "kodepos": "28813"
            },
            {
                "kecamatan": "Dumai Kota",
                "kelurahan": "Sukajadi",
                "kodepos": "28812"
            },
            {
                "kecamatan": "Dumai Kota",
                "kelurahan": "Bintan",
                "kodepos": "28812"
            },
            {
                "kecamatan": "Dumai Kota",
                "kelurahan": "Dumai Kota",
                "kodepos": "28811"
            },
            {
                "kecamatan": "Dumai Timur",
                "kelurahan": "Bukit Batrem",
                "kodepos": "28811"
            }
        ],
        "k336": [
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Titian Antui",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Tasik Tebing Serai",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Tengganau",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Tasik Serai Timur",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Tasik Serai",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Tasik Serai Barat",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Tambusai Batang Dui",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Talang Mandi",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Sungai Meranti",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Simpang Padang",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Serai Wangi",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Sesumbo Ampai (Kesumo Ampai)",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Semunai",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Sebangar",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Pematang Pudu",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Pinggir",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Petani",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Pematang Obo",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Pangkalan Libut",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Pamesi",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Melibur",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Muara Basung",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Kuala Penaso",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Kuala Penaso",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Harapan Baru",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Gajah Sakti",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Duri Barat",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Duri Timur",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Buluh Manis",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Bumbung",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Buluh Apo",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Beringin",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Boncah Mahang",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Batang Serosa",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Bathin Betuah",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Bathin Sobanga",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Balai Raja",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Balik Alam",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Balai Makam",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Pinggir",
                "kelurahan": "Balai Pungut",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Babussalam",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Air Jamban",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Mandau",
                "kelurahan": "Air Kulim",
                "kodepos": "28784"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Titi Akar",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Teluk Rhu",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Terkul",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Tanjung Punak",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Teluk Lecah",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Tanjung Medang",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Tanjung Kapal",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Suka Damai",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Sukarjo Mesin",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Puteri Sembilan",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Sei/Sungai Cingam",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Sri Tanjung",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Parit Kebumen",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Pergam",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Pangkalan Nyirih",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Pangkalan Pinang",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Makeruh",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Pancur Jaya",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Kadur",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Hutan Panjang",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat Utara",
                "kelurahan": "Hutan Ayu",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Dungun Baru",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Batu Panjang",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Rupat",
                "kelurahan": "Darul Aman",
                "kodepos": "28781"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Tanjung Datuk",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Tanjung Belit",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Tanjung Damai",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sungai Limau (Linau)",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sungai Nibung",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sungai Siput",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sepotong",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sumber Jaya",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Muara Dua",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Sadar Jaya",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Lubuk Garam",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Lubuk Gaung",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Lubuk Muda",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Langkat",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Liang Banir",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Bandar Jaya",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Siak Kecil",
                "kelurahan": "Koto Raja",
                "kodepos": "28771"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Tenggayun",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Temiang",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sungai Selari",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Tanjung Leban",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sepahat",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sukajadi",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sungai Pakning",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Parit I Api Api",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Sejangat",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Dompas",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Pakning Asal",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Pangkalan Jambi",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Buruk Bakul",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Bukit Batu",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Bukit Kerikil",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Batang Duku",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bukit Batu",
                "kelurahan": "Api Api",
                "kodepos": "28761"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Telukpambang",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Telukpapal",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Ulu Pulau",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Sukamaju",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Teluklancar",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Resam Lapis",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Selat Baru",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Pampang Pesisir",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Pasiran",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Pampang Baru",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Mentayan",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Muntai",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Muntai Barat",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Kembung Baru",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Kembung Luar",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Deluk",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Jangkang",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Berancah",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Bantan Tua",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Bantan Tengah",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Bantan Timur",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Bantan Air",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bantan",
                "kelurahan": "Bantan Sari",
                "kodepos": "28754"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Damon/Damun",
                "kodepos": "28713"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Senggoro",
                "kodepos": "28714"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Wonosari",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Bengkalis Kota",
                "kodepos": "28712"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Sungaibatang",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Teluk Latak",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Temeran",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Senderek",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Simpang Ayam",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Sei/Sungai Alam",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Sekodi",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Prapat Tunggal",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Rimba Sekampung",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Sebauk",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Penampi",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Penebal",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Pedekik",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Pematang Duku",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Pematang Duku Timur",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Pangkalan Batang",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Pangkalan Batang Barat",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Meskom",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Palkun",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Kelemantan Barat",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Ketam Putih",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Kuala Alam",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Kelebuk",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Kelemantan",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Air Putih",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Damai",
                "kodepos": "28711"
            },
            {
                "kecamatan": "Bengkalis",
                "kelurahan": "Kelapa Pati",
                "kodepos": "28711"
            }
        ],
        "k337": [
            {
                "kecamatan": "Mempura",
                "kelurahan": "Teluk Merempan",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Merempan Hilir",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Paluh",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Sungai Mempura",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Kampung Tengah",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Kota Ringin",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Benteng Hilir",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Mempura",
                "kelurahan": "Benteng Hulu",
                "kodepos": "28773"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Tualang",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Tualang Timur",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Pinang Sebatang Timur",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Pinang Sebatang",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Pinang Sebatang Barat",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Perawang",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Perawang Barat",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Meredan",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Tualang",
                "kelurahan": "Meredan Barat",
                "kodepos": "28772"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Temusai",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Tuah Indrapura",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Suak Merambai",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Sungai Berbari",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Sungai Limau",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Pebadaran",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Perincit",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Kemuning Muda",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Langsat Permai",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Jati Baru",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Jaya Pura",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Dosan",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Dusun Pusaka",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Dayangsuri",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Buatan/Buantan Lestari",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Bunga Raya",
                "kelurahan": "Bunga Raya",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Pusako",
                "kelurahan": "Benayah",
                "kodepos": "28763"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Sungai Gondang",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Telaga Sam-Sam",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Sam Sam",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Simpang Belutu",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Libo Jaya",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Pencing Bekuto",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Jambai Makmur",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kandis",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kandis Kota",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Bekalar",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Belutu",
                "kodepos": "28686"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Selat Guntung",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Sungai Tengah",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Rempak",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Sabak Permai",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Minas",
                "kelurahan": "Minas Timur",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Minas",
                "kelurahan": "Rantau Bertuah",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Minas",
                "kelurahan": "Minas Barat",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Minas",
                "kelurahan": "Minas Jaya",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Laksamana",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Minas",
                "kelurahan": "Mandi Angin",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Bandar Sungai",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Belading",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Teluk Rimba",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sabak Auh",
                "kelurahan": "Bandar Pedada",
                "kodepos": "28685"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Tumang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Teluk Lancang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Tasik Seminai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Teluk Merbau",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Tasik Betung",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Sungai Lodang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Sri Gemilang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Suka Mulia/Mulya",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Sri Gemilang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Sengkemang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Sawit Permai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Sialang Sakti",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Rawang Air Putih",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Pangkalan Pisang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Rantau Panjang",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Muara Kelantan",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Olak",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Pangkalan Makmur",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Muara Bungkal",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Merempan Hulu",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Lubuk Umbut",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Lubuk Tilan",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Merangkai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Lubuk Jering",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Langkai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Keranji Guguh",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Kuala Gasib",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Kampung Rempak",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Dayun",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Kampung Dalam",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Empang Pandan",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Buatan I",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Koto Gasib",
                "kelurahan": "Buatan II",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Siak",
                "kelurahan": "Buantan Besar",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Berumbung Baru (Tilan)",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Buana Makmur",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Mandau",
                "kelurahan": "Bencah Umbai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Dayun",
                "kelurahan": "Banjar Seminai",
                "kodepos": "28671"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Teluk Mesjid",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Teluk Batil",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Teluk Lanus",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Sungai Kayu Ara",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Sungai Rawa",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Tanjung Kuras",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Penyengat",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Rawa Mekar Jaya",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Sungai Apit",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Mengkapan",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Parit I/II",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Lalang",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Kayu Ara Permai",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Harapan",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Sungai Apit",
                "kelurahan": "Bunsur",
                "kodepos": "28662"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Simpang Perak Jaya",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Sri Gading",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Sialang Palas",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Sialang Baru",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Seminai",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Rawang Kao Barat",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Rawang Kao",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Lubuk Dalam",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Kerinci Kiri",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Kumbara Utama",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Kerinci Kanan",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Jati Mulia/Mulya",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Gabung Makmur",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Lubuk Dalam",
                "kelurahan": "Empang Baru",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Delima Jaya",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Bukit Harapan",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Buatan Baru",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Bukit Agung",
                "kodepos": "28654"
            },
            {
                "kecamatan": "Kerinci Kanan",
                "kelurahan": "Buana Bakti/Bhakti",
                "kodepos": "28654"
            }
        ],
        "k338": [
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Topang",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Wonosari",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Teluk Samak",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Tenggayun Raya",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Telaga Baru",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Telesung",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Tanjung Medang",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Tanjung Samak",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Tebun",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Tanjung Gemuk",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Tanjung Kedabu",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Sungai Gayung Kiri",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Tanjung Bakau",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Tanah Merah",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Sungai Cina",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Sokop",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Sonde",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Sendaur",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Segomeng",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Sialang Pasung",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Repan",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Permai",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Penyagun",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Melai",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Lemang",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Mekar Baru",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Kayu Ara",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Kedabu Rapat",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Kayu Ara",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Kedabu Rapat",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang",
                "kelurahan": "Citra Damai",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Bokor",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Bungur",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Bina Maju",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Pesisir",
                "kelurahan": "Beting",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Bantar",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Rangsang Barat",
                "kelurahan": "Anak Setatah",
                "kodepos": "28755"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Tenan",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Teluk Buntal",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Tanjung Sari",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Tanjung Peranap",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Tanjung Gadai",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Tanjung Darul Takzim",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Tanjung",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sesap",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Sungai Tohor",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Sungai Tohor Barat",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Selatpanjang Timur",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Sendanu Darul Ihsan",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Selatpanjang Selatan",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Selatpanjang Kota",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Selatpanjang Barat",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Nipah Sendanu",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Mekong",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Mengkikip",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Lukun",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Maini Darul Aman",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Mantiasa",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Kepau Baru",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Insit",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Kundur",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Timur",
                "kelurahan": "Batin Suir",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Gogok Darussalam",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Banglas Barat",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Batang Malas",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Alai",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi Barat",
                "kelurahan": "Alai Selatan",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Banglas",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Alah Air Timur",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Teluk Belitung",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Teluk Ketapang",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Alah Air",
                "kodepos": "28753"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Tanjungbunga",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Tanjung Pisang",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Sungai Tengah",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Tanjung Kulim",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Tanjung Padang",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Sungai Anak Kamal",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Renak Dungun",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Semukut",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Selat Akar",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Pelantai",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Putri Puyu",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Padang Kamal",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Pangkalan Balai",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Pangkalan Balai",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Mengkirau",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Mengkopot",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Mayang Sari",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Mekar Sari",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Mekar Delima",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Lukit",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Kudap",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Ketapang Permai",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Kuala Merbau",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Dedap",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Batang Meranti",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Centai",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Pulaumerbau",
                "kelurahan": "Baran Melintang",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Tasik Putri Puyu",
                "kelurahan": "Bandul",
                "kodepos": "28752"
            },
            {
                "kecamatan": "Merbau",
                "kelurahan": "Bagan Melibur",
                "kodepos": "28752"
            }
        ],
        "k339": [
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Teluk Aur",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Sei Kuning",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Sei Salak",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Rambah Utama",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Rambah Samo",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Rambah Samo Barat",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Rambah Baru",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Pasir Makmur",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Masda Makmur",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Marga Mulya",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Lubuk Napal",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Langkitin",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Lubuk Bilang",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Rambah Samo",
                "kelurahan": "Karya Mulya",
                "kodepos": "28565"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Ulak Patian",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Sontang",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Teluk Sono",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Rawa Makmur",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Rantau Binuang Sakti",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan Hulu",
                "kelurahan": "Pekan Tebih",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Pauh",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan Hulu",
                "kelurahan": "Muara Jaya",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Suka",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Tengah",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan Hulu",
                "kelurahan": "Kepenuhan Jaya",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Raya",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan Hulu",
                "kelurahan": "Kepenuhan Hulu",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Baru",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Hilir",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Barat Mulya",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Barat Sei Rokan Jaya",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan",
                "kelurahan": "Kepenuhan Barat",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Kasang Padang",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Kepenuhan Hulu",
                "kelurahan": "Kepayan",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Bonai",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Bonai Darussalam",
                "kelurahan": "Kasang Mungai",
                "kodepos": "28559"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Tingkok",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Tanjung Medan",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Tambusai Timur",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Tambusai Utara",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Tambusai Barat",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Tambusai Tengah",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Sungai Kumango",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Tali Kumain",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Suka Maju",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Simpang Harapan",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Suka Damai",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Rantau Sakti",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Sialang Rindang",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Payung Sekaki",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Rantau Panjang",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Mekar Jaya",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Pagar Mayang",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Mahato",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Mahato Sakti",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Batas",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Lubuk Soting",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai",
                "kelurahan": "Batang Kumu",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Tambusai Utara",
                "kelurahan": "Bangun Jaya",
                "kodepos": "28558"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Tanjung Belit",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Tangun",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Sungai Dua Indah",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Sungai Sitolang",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Sialang Jaya",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Suka Maju",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Serambau/Serombau Indah",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Rambah Tengah Utara",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Sejati",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Rambah Tengah Hilir",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Rambah Tengah Hulu",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Rambah Muda",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Rambah Tengah Barat",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Rambah Hilir Timur",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Rambah Jaya",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Rambah Hilir",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Rambah Hilir Tengah",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Pematang Berangan",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Rambah",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Pasir Utama",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Pasir Maju",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Pasir Pengarayan",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Pasir Jaya",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Pasir Agung",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Pasir Intan",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Pasir Baru",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Muara Musu",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Koto Tinggi",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Menaming",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Rambah Hilir",
                "kelurahan": "Lubuk Krapat/Kerapat",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bangun Purba Timur Jaya",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bangun Purba",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bangun Purba Barat",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Tanah Datar",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Rambah",
                "kelurahan": "Babussalam",
                "kodepos": "28557"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Sungai Kuti",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Pagaran Tapah Darussalam",
                "kelurahan": "Sangkir Indah",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Pagaran Tapah Darussalam",
                "kelurahan": "Rimba Jaya",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Pagaran Tapah Darussalam",
                "kelurahan": "Rimba Makmur",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Pasir Indah",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Pasir Luhur",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Muara Intan",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Pagaran Tapah Darussalam",
                "kelurahan": "Pagaran Tapah",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Kota Lama",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Kota Raya",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Muara Dilam",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Kota Baru",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Kota Intan",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Intan Jaya",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Pagaran Tapah Darussalam",
                "kelurahan": "Kembang Damai",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Bagan Tujuh",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Kunto Darussalam",
                "kelurahan": "Bukit Intan Makmur",
                "kodepos": "28556"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Tibawan",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Tanjung Medan",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Suligi",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Sikebau Jaya",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Sei Kandis",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Sei Kandis",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Rokan",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Rokan Koto Ruang",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Pemandang",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Pendalian",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Lubuk Bendahara Timur",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Lubuk Betung",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Cipang Kiri Hulu",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Lubuk Bendahara",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Cipang Kiri Hilir",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Cipang Kanan",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Bengkolan Salak",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Rokan IV Koto",
                "kelurahan": "Alahan",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Ujung Batu",
                "kelurahan": "Ujung Batu Timur",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Pendalian IV Koto",
                "kelurahan": "Air Panas",
                "kodepos": "28555"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Tapung Jaya",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Ujung Batu",
                "kelurahan": "Ujung Batu",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Tandun Barat",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Sei/Sungai Kuning",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Tandun",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Tandun",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Ujung Batu",
                "kelurahan": "Pematang Tebih",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Puo Raya",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Ujung Batu",
                "kelurahan": "Ngaso",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Kumain",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Kota/Koto Ranah",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Koto Tandun",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Giti",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Kabun",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Dayo",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Bencah/Boncah Kesuma",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Tandun",
                "kelurahan": "Bono Tapung",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Aliantan",
                "kodepos": "28554"
            },
            {
                "kecamatan": "Kabun",
                "kelurahan": "Batu Langkah Besar",
                "kodepos": "28554"
            }
        ],
        "k340": [
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Terusan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Utama Karya",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Teluk Paman",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Teluk Paman Timur",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Tanjung Permai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Tanjung Mas",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Tanjung Karang",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Tanjung Belit",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Tanjung Belit Selatan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Sarik",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Sungai Simpang Dua",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Rambai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Sungai Santi",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Raja",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Sungai Petai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Paku",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Sungai Pagar",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Liti",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Geringging",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Sungai Harapan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Sungai Lipai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Sungai Bunga/Bungo",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Suka Makmur",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Simalinyang",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Subarak",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Simalinyang",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Sahilan Darussalam",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Rantau Kasih",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Pangkalan Serai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Penghidupan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Pangkalan Kapas",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Muara Selaya",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Padang Sawah",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Muarobio",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Mentulik",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Makmur Sejahtera",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Mayang Pongkai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Mekar Jaya",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Lubuk Sakai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Ludai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Lipat Kain Utara",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Lubuk Bingau",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Lipat Kain",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Lipat Kain Selatan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Kuntu",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Kuntu Darussalam",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Koto Damai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Kebun Tinggi",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Kota Lama",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Karya Bakti/Bhakti",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Kebun Durian",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "IV Koto Setingkai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Hidup Baru",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Gunung Sahilan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Gunung Sari",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Gema",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Gunung Sahilan",
                "kelurahan": "Gunung Mulya",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Gajah Bertalut",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Dua Sepakat",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Gading Permai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri",
                "kelurahan": "Domo",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Danau Sontul",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Deras Tajak",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Bukit Betung",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Bukit Sakai",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Tengah",
                "kelurahan": "Bina Baru",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Batu Sasak",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hilir",
                "kelurahan": "Bangun Sari",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Batu Sanggan",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Kampar Kiri Hulu",
                "kelurahan": "Aur Kuning",
                "kodepos": "28471"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Tri Manunggal",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Tebing Lestari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Tapung Lestari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Tanjung Sawit",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Tapung Makmur",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Tandan Sari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sumber Makmur",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Sumber Sari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Talang Danto",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Sinama Nenek",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Sukaramai",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Suka Maju",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sibuak",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sibuak",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sei/Sungai Agung",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sei/Sungai Lembu Makmur",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sei/Sungai Putih",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Rimba Beringin",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Sari Galuh",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Pelambaian",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Petapahan",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Petapahan Jaya",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Pagaruyung",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Pancuran Gading",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Pantai Cermin",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Kusau Makmur",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Muara Mahat Baru",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Mukti Sari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Kota/Koto Bangun",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Koto Aman",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Kota Baru",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Kota Garo",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Kinantan/Kenantan",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Kijang Rejo",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Kijang Makmur",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Karya Indah",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Kasikan",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Kijang Jaya",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Indra Sakti",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Indrapuri",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Gerbang Sari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Gading Sari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Cinta Damai",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Danau Lancang",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hulu",
                "kelurahan": "Bukit Kemuning",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Batu Gajah",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Bencah Kelubi",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung Hilir",
                "kelurahan": "Beringin Lestari",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Tapung",
                "kelurahan": "Air Terbit",
                "kodepos": "28464"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Suka Mulya",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Pulautarap",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Silam",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Pulau Lawas",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Pasir Sialang",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Pulau",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Pulau Jambu",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Muara Uwai",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Lereng",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Merangin",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Empat Balai",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Kuok",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Laboi Jaya",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Bukit Melintang",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Bukit Payung",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Bukit Sembilan",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Kuok (Bangkinang Barat)",
                "kelurahan": "Batulangka Kecil",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Bangkinang Seberang",
                "kelurahan": "Binuang",
                "kodepos": "28463"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Teluk Kenidai",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Terantang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Tambang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Tarai Bangun",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Perhentian Raja",
                "kelurahan": "Sialang Kubang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Sei/Sungai Pinang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Pulau Permai",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Rimba/Rimbo Panjang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Palung Raya",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Parit Baru",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Perhentian Raja",
                "kelurahan": "Pantai Raja",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Perhentian Raja",
                "kelurahan": "Lubuk Sakat",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Padang Luas",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Kualu Nenas",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Kuapan",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Perhentian Raja",
                "kelurahan": "Kampung Pinang",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Kemang Indah",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Kualu",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Perhentian Raja",
                "kelurahan": "Hangtuah",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Gobah",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Aursati",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Tambang",
                "kelurahan": "Balam Jaya",
                "kodepos": "28462"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Tanjung Berulak",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Tanjung Rambutan",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Tanjung Bungo",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Simpang Kubu",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Simpang Kubu",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Sei/Sungai Tonang",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Sendayan",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Sei/Sungai Putih",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Sei/Sungai Jalau",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Sawah Baru",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Sawah",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Ranah Singkuang",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Rumbio",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Ranah",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Ranah Baru",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Pulau Rambai",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Pulau Sarak",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Pulau Tinggi",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Penyasawan",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Pulau Jambu",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Pulau Birandang",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Padang Mutung",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Muara Jalai",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Naga Beralih",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Naga Beralih",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Koto Tibun",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Limau Manis",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Koto Perambahan",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Kampung Panjang (Air Tiris)",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Utara",
                "kelurahan": "Kayu Aro",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Kampar",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Bukit Ranah",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar Timur",
                "kelurahan": "Deli Makmur",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Air Tiris",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Kampar",
                "kelurahan": "Batu Belah",
                "kodepos": "28461"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Simpang Petai",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Tambusai",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Teratak",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Bukit Kratai",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Pulau Payung",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Alam Panjang",
                "kodepos": "28458"
            },
            {
                "kecamatan": "Rumbio Jaya",
                "kelurahan": "Batang Batindih",
                "kodepos": "28458"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Tanjung Alai",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Tanjung",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Siberuang",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Tabing",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Pongkai Istiqomah",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Pulau Gadang",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Ranah Sungkai",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Muara Takus",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Pongkai",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Koto Tuo",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Koto Tuo Barat",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Lubuk Agung",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Koto Mesjid",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Gunung Bungsu",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Gunung Malelo",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Batu Bersurat",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Binamang",
                "kodepos": "28453"
            },
            {
                "kecamatan": "XIII Koto Kampar",
                "kelurahan": "Balung",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Koto Kampar Hulu",
                "kelurahan": "Bandur Picak",
                "kodepos": "28453"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Tanjung Balam",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Teratak Buluh",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Tanah Merah",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Pangkalan Baru",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Pangkalan Serik",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Pandau Jaya",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Kubang Jaya",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Lubuk Siam",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Kepau Jaya",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Desa Baru",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Buluh Nipis",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Siak Hulu",
                "kelurahan": "Buluh Cina",
                "kodepos": "28452"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Sipungguk/Sepungguk",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Siabu",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Salo Timur",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Ganting Damai",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Salo",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Salo",
                "kelurahan": "Ganting",
                "kodepos": "28451"
            },
            {
                "kecamatan": "Bangkinang",
                "kelurahan": "Langgini",
                "kodepos": "28412"
            },
            {
                "kecamatan": "Bangkinang",
                "kelurahan": "Ridan Permai",
                "kodepos": "28411"
            },
            {
                "kecamatan": "Bangkinang",
                "kelurahan": "Kumantan",
                "kodepos": "28411"
            },
            {
                "kecamatan": "Bangkinang",
                "kelurahan": "Bangkinang (Bangkinang Kota)",
                "kodepos": "28411"
            }
        ],
        "k341": [
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Terbangiang",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Teluk Beringin",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Teluk Dalam",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Teluk Bakau",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Tanjung Sum",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Teluk",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Tambun",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Sungai Upih",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Sungai Mas",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Sungai Solok",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Sokoi",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Sialang Bungkuk",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Sialang Godang",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Kuala Kampar",
                "kelurahan": "Serapung",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Lubuk Terap",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Pompa Air",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Lubuk Raja",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Lubuk Keranji Timur",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Angkasa",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Lubuk Keranji",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bandar Petalangan",
                "kelurahan": "Air Terjun",
                "kodepos": "28384"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Sungai Buluh",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bandar Sei Kijang",
                "kelurahan": "Simpang Beringin",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Sialang Kayu Batu",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bandar Sei Kijang",
                "kelurahan": "Sei Kijang",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Petani",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Pangkalan Bunut",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bandar Sei Kijang",
                "kelurahan": "Muda Setia",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Merbau",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bandar Sei Kijang",
                "kelurahan": "Lubuk Ogung",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Lubuk Mas",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bandar Sei Kijang",
                "kelurahan": "Kiab Jaya",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Lubuk Mandian Gajah",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Balam Merah",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Keriung",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Bunut",
                "kelurahan": "Bagan Laguh",
                "kodepos": "28383"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Ukui II (Dua)",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Tri Mulya Jaya",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Ukui I (Satu)",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Terantang Manuk",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Tanjung Kuyo",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Tanjung Kuyo",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Sorek Satu",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Surya Indah",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Talau",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Sorek Dua",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Silikuan Hulu",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Sialang Indah",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Sari Mulia (Sarimulya)",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Sidomukti",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Rawang Sari",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Sari Makmur",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Pangkalan Lesung",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Pesaguan",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Palas",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Mulia Subur (Mulyasubur)",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Meranti",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Lubuk Kembang Sari",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Mayang Sari",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Kesuma",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Lubuk Kembang Bunga",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Harapan Jaya",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Kemang",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Kampung Baru",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Dusun Tua",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Lesung",
                "kelurahan": "Genduang",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Dundangan",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Bukit Jaya",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Bukit Gajah",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Beringin Indah",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Betung",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Pangkalan Kuras",
                "kelurahan": "Batang Kulim",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Bagan Limau",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Air Emas",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Ukui",
                "kelurahan": "Air Hitam",
                "kodepos": "28382"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Sotol",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Tambak",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Segati",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Penarikan",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Rantau Baru",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Pangkalan Kerinci Kota",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Pangkalan Kerinci Timur",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Padang Luas",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Pangkalan Gondai",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Pangkalan Kerinci Barat",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Langkan",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Makmur",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Langgam",
                "kelurahan": "Langgam",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Kuala Terusan",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Teluk Meranti",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pangkalan Kerinci",
                "kelurahan": "Bukit Agung",
                "kodepos": "28381"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Telayap",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Teluk Binjai",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Tanjung Air Hitam",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Sungai Ara",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Segamai",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Ransang",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Sering",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Petodaan",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Pulau Muda",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Pematang Tinggi",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Pelalawan",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Pangkalan Terap",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Pangkalan Tampoi/Tampui",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Pangkalan Panduk",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Mak Teduh",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Lalang Kabung",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Lipai Bulan",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Labuhan Bilik",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Kuala Tolam",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Kuala Panduk",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Kerumutan",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Teluk Meranti",
                "kelurahan": "Gambut Mutiara",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Delik",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Bukit Lembah Subur",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Pelalawan",
                "kelurahan": "Batang Nilo Kecil",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Beringin Makmur",
                "kodepos": "28353"
            },
            {
                "kecamatan": "Kerumutan",
                "kelurahan": "Banjar Panjang",
                "kodepos": "28353"
            }
        ],
        "k342": [
            {
                "kecamatan": "Tampan",
                "kelurahan": "Simpang Baru",
                "kodepos": "28293"
            },
            {
                "kecamatan": "Tampan",
                "kelurahan": "Sidomulyo Barat",
                "kodepos": "28294"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Tampan",
                "kodepos": "28292"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Labuh Baru Barat",
                "kodepos": "28292"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Labuh Baru Timur",
                "kodepos": "28292"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Air Hitam",
                "kodepos": "28292"
            },
            {
                "kecamatan": "Tampan",
                "kelurahan": "Delima",
                "kodepos": "28291"
            },
            {
                "kecamatan": "Tampan",
                "kelurahan": "Tuah Karya",
                "kodepos": "28291"
            },
            {
                "kecamatan": "Tenayan Raya",
                "kelurahan": "Tangkerang Timur",
                "kodepos": "28289"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tangkerang Utara",
                "kodepos": "28289"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tangkerang Selatan",
                "kodepos": "28288"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Tebing Tinggi Okura",
                "kodepos": "28287"
            },
            {
                "kecamatan": "Tenayan Raya",
                "kelurahan": "Kulim",
                "kodepos": "28286"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Simpang Tiga",
                "kodepos": "28284"
            },
            {
                "kecamatan": "Marpoyan Damai",
                "kelurahan": "Tangkerang Tengah",
                "kodepos": "28282"
            },
            {
                "kecamatan": "Tenayan Raya",
                "kelurahan": "Sail",
                "kodepos": "28285"
            },
            {
                "kecamatan": "Bukit Raya",
                "kelurahan": "Tangkerang Labuai",
                "kodepos": "28281"
            },
            {
                "kecamatan": "Marpoyan Damai",
                "kelurahan": "Tangkerang Barat",
                "kodepos": "28282"
            },
            {
                "kecamatan": "Tenayan Raya",
                "kelurahan": "Rejosari",
                "kodepos": "28281"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Meranti Pandak",
                "kodepos": "28266"
            },
            {
                "kecamatan": "Rumbai",
                "kelurahan": "Muara Fajar",
                "kodepos": "28267"
            },
            {
                "kecamatan": "Rumbai",
                "kelurahan": "Umban Sari",
                "kodepos": "28265"
            },
            {
                "kecamatan": "Rumbai",
                "kelurahan": "Palas",
                "kodepos": "28264"
            },
            {
                "kecamatan": "Rumbai",
                "kelurahan": "Rumbai Bukit",
                "kodepos": "28264"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Lembah Sari",
                "kodepos": "28262"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Lembah Damai",
                "kodepos": "28263"
            },
            {
                "kecamatan": "Rumbai",
                "kelurahan": "Sri Meranti",
                "kodepos": "28261"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Limbungan Baru",
                "kodepos": "28261"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Padang Bulan",
                "kodepos": "28156"
            },
            {
                "kecamatan": "Rumbai Pesisir",
                "kelurahan": "Limbungan",
                "kodepos": "28261"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Kampung Baru",
                "kodepos": "28154"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Padang Terubuk",
                "kodepos": "28155"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Kampung Bandar",
                "kodepos": "28153"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Sago",
                "kodepos": "28151"
            },
            {
                "kecamatan": "Senapelan",
                "kelurahan": "Kampung Dalam",
                "kodepos": "28152"
            },
            {
                "kecamatan": "Lima Puluh",
                "kelurahan": "Tanjung Rhu",
                "kodepos": "28143"
            },
            {
                "kecamatan": "Lima Puluh",
                "kelurahan": "Pesisir",
                "kodepos": "28144"
            },
            {
                "kecamatan": "Lima Puluh",
                "kelurahan": "Rintis",
                "kodepos": "28141"
            },
            {
                "kecamatan": "Lima Puluh",
                "kelurahan": "Sekip",
                "kodepos": "28142"
            },
            {
                "kecamatan": "Sail",
                "kelurahan": "Suka Maju",
                "kodepos": "28131"
            },
            {
                "kecamatan": "Sail",
                "kelurahan": "Suka Mulia (Sukamulya)",
                "kodepos": "28131"
            },
            {
                "kecamatan": "Sail",
                "kelurahan": "Cinta Raja",
                "kodepos": "28131"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Kampung Tengah",
                "kodepos": "28128"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Pulau Karam",
                "kodepos": "28127"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Jadirejo",
                "kodepos": "28126"
            },
            {
                "kecamatan": "Marpoyan Damai",
                "kelurahan": "Sidomulyo Timur",
                "kodepos": "28125"
            },
            {
                "kecamatan": "Marpoyan Damai",
                "kelurahan": "Wonorejo",
                "kodepos": "28125"
            },
            {
                "kecamatan": "Marpoyan Damai",
                "kelurahan": "Maharatu",
                "kodepos": "28125"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Kedungsari",
                "kodepos": "28123"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Kampung Melayu",
                "kodepos": "28124"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Sukajadi",
                "kodepos": "28121"
            },
            {
                "kecamatan": "Sukajadi",
                "kelurahan": "Harjosari",
                "kodepos": "28122"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Tanah Datar",
                "kodepos": "28115"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Simpang Empat",
                "kodepos": "28116"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Kota Tinggi",
                "kodepos": "28112"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Kota Baru",
                "kodepos": "28114"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Suka Ramai",
                "kodepos": "28111"
            },
            {
                "kecamatan": "Pekanbaru Kota",
                "kelurahan": "Suma Hilang",
                "kodepos": "28111"
            }
        ],
        "k343": [
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Saragian",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Sayoang",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Petoosang",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Pupu Uring (Puppuring)",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Kalumammang",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Mombi",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Pao Pao",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Alu (Allu)",
                "kelurahan": "Alu (Allu)",
                "kodepos": "96365"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Todang Todang",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Tangan Baru",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Tandasura (Tandassura)",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Renggeang",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Salarri",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Samasundu",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Palece",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Pendulangan (Pandulangan)",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Limboro",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Napo",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Limboro",
                "kelurahan": "Lembang Lembang",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Tangnga-Tangnga",
                "kodepos": "91412"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Tubbi",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Taramanu",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Taramanu Tua",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Ratte",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Taloba",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Pollewani",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Piriang Tapiko",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Poda",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Besoangin Utara",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Peburru",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Arabua",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Besoangin",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Tinambung",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tubbi Taramanu (Tutar/Tutallu)",
                "kelurahan": "Ambo Padang",
                "kodepos": "91355"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Tandung",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Tammejarra (Tammajarra)",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Sepa Batu",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Tammangalle",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Pambusuang",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Sabang Subik",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Lekopadis",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Mosso",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Pallis",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Lambanan",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Lego",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Karama",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Galung Tulu",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Batulaya",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Tinambung",
                "kelurahan": "Galung Lombok",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Balanipa",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Balanipa",
                "kelurahan": "Bala",
                "kodepos": "91354"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Ugi Baru",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Tenggelang (Tenggelan)",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Tapambanua (Patambanua)",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Sumarang (Sumarrang)",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Suruang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Sepporraki",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Segerang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Sattoko",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Sambali Wali",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Sabura",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Rappang Barat",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Rumpa",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Pussui",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Pussui Barat",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Puccadi",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Pulliwa",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Pappang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Parappe",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Panyampa",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Padang Timur",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Mapilli Barat",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Ongko",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Padang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Mambu",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Mapilli",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Lenggo",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Luyo (Lujo)",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Landi Kanusuang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Lapeo",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Laliko",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Lampoko",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Lagi-Agi",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Kurma",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Kenje",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Katumbangan",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Katumbangan Lemo",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Karombang",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Ihing",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Daala Timur",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Gattungan",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Bulo",
                "kelurahan": "Bulo",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Buku",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Bonra",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Botto",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Bonne-Bonne",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Campalagian",
                "kelurahan": "Bonde",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Mapilli",
                "kelurahan": "Beroangin",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Batupanga Daala",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Batu Panga",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Luyo",
                "kelurahan": "Baru",
                "kodepos": "91353"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Tuttula",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Tumpiling",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Tonro Lima",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Tapango Barat",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Tapua",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Tapango",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Sumberjo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Sugih Waras",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Sidodadi",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Sidorejo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Rappang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Riso",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Rangoan",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Patampanua",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Pelitakan",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Pasiang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Palatta",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Nepo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Matangnga",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Matakali",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Lilli",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Mambu Tapua",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Kurrak",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Kebunsari",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Katimbang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Kalimbua",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Jambu Malea",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Indo Makkombong (Indomakkondang)",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Geleso",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Dakka",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Bussu",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Campurjo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Bumiayu",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Bunga-Bunga",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Bumi Mulyo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Batu",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Banua Baru",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matakali",
                "kelurahan": "Barumbung",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Bakka-Bakka",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapango",
                "kelurahan": "Banato Rejo",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Matangnga",
                "kelurahan": "Ba&#8217;ba Tapua",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Wonomulyo",
                "kelurahan": "Arjosari",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Kuajang",
                "kodepos": "91351"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Paku",
                "kodepos": "91351"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Wattang",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Madatte",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Sulewatang",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Anreapi",
                "kelurahan": "Pappandangan",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Anreapi",
                "kelurahan": "Kelapa Dua",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Anreapi",
                "kelurahan": "Kunyi",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Anreapi",
                "kelurahan": "Anreapi",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Anreapi",
                "kelurahan": "Duampanua",
                "kodepos": "91315"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Darma",
                "kodepos": "91314"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Lantora",
                "kodepos": "91313"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Tonyaman",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Mirring",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Rea",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Amola",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Batetangnga",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Kaleok",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Amassangan",
                "kodepos": "91312"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Takatidung",
                "kodepos": "91311"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Polewali",
                "kodepos": "91311"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Pekkabata",
                "kodepos": "91311"
            },
            {
                "kecamatan": "Polewali",
                "kelurahan": "Manding",
                "kodepos": "91311"
            },
            {
                "kecamatan": "Binuang",
                "kelurahan": "Mammi",
                "kodepos": "91311"
            }
        ],
        "k344": [
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Wulai",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambaira",
                "kelurahan": "Tampaure",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Sarjo",
                "kelurahan": "Sarjo",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Sarjo",
                "kelurahan": "Sarude",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Pangiang",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Polewali",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Randomayang",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Sarjo",
                "kelurahan": "Letawa",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Sarjo",
                "kelurahan": "Maponu",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambaira",
                "kelurahan": "Kasoloang",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambaira",
                "kelurahan": "Kalukunangka",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Bambalamotu",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambalamotu",
                "kelurahan": "Kalola",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Bambaira",
                "kelurahan": "Bambaira",
                "kodepos": "91574"
            },
            {
                "kecamatan": "Duripoku",
                "kelurahan": "Taranggi",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Dapurang",
                "kelurahan": "Tirta Buana",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Duripoku",
                "kelurahan": "Sipakainga",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Duripoku",
                "kelurahan": "Tammarunnang",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Dapurang",
                "kelurahan": "Serasa",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Sarudu",
                "kelurahan": "Sarudu",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Duripoku",
                "kelurahan": "Saptanajaya",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Sarudu",
                "kelurahan": "Kumasari",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Sarudu",
                "kelurahan": "Patika",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Sarudu",
                "kelurahan": "Doda",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Dapurang",
                "kelurahan": "Dapurang",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Sarudu",
                "kelurahan": "Bulu Mario",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Towoni (Toroni)",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Dapurang",
                "kelurahan": "Bulu Bonggu",
                "kodepos": "91573"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Singgani",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Sumbersari",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Ompi",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Parabu",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Motu",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Lilimori (Lelemori)",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Kulu",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Lelejae",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Kenangan",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Kasano",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Kasta Buana",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Bulu Parigi",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Karave (Karabe)",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Bulu Taba",
                "kelurahan": "Bukit Harapan",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Batu Matoru",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Baras",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Bambakoro",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Baras",
                "kelurahan": "Balanti",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Tikke Raya",
                "kelurahan": "Tikke",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Lariang",
                "kelurahan": "Bajawali",
                "kodepos": "91572"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Pedanda",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pedongga",
                "kelurahan": "Pedanda II",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Pasangkayu",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Martajaya",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pedongga",
                "kelurahan": "Martasari",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Tikke Raya",
                "kelurahan": "Pajalele (Pakawa)",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Malei",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pedongga",
                "kelurahan": "Malei II",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Tikke Raya",
                "kelurahan": "Lariang",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Tikke Raya",
                "kelurahan": "Makmur Jaya",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Tikke Raya",
                "kelurahan": "Jengeng Raya",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Karya Bersama",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pedongga",
                "kelurahan": "Batu Oge",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Pasangkayu",
                "kelurahan": "Ako",
                "kodepos": "91571"
            },
            {
                "kecamatan": "Dapurang",
                "kelurahan": "Benggaulu",
                "kodepos": "91512"
            }
        ],
        "k345": [
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Waeputeh",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Topoyo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Tumbu",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Tobadak",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Toabo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Topare (Tapore)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Tarailu",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Tinali",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Tapilina (Tappilina)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Tangkau (Tangkou)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Tanam Buah (Tanambua)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Tabolang",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Sukadamai",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Sisango",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Sejati",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Sinabatta",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Sartanama",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Sampaga",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Salupangkang (Salopangkang)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Salupangkang IV (Salupangkang IV)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Salumanurung",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Salukayu (Salokayu)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Salulekbo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Salugatta (Salogatta)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Salubarana",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Salubaja (Salo Baja)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Saluadak (Salo Adak)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Pontanakayang",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Polopangale",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Pololereng",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Polocamba",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Passapa (Pasapa)",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Paraili",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Papalang",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Pangalloang",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Pangale",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Mahahe",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Palongaan",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Lumu",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Lemo-Lemo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Losso",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Lembah Hada",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Lamba-Lamba",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Kombiling",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Pangale",
                "kelurahan": "Kuo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Kire",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Kalonding",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Kabubu",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Sampaga",
                "kelurahan": "Bunde",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Budong-Budong",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Bojo",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Bonda",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Boda-Boda",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Batu Parigi",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Papalang",
                "kelurahan": "Batu Ampa",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Barakkang",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Topoyo",
                "kelurahan": "Bambamanurung",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tobadak",
                "kelurahan": "Bambadaru",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Budong-Budong",
                "kelurahan": "Babana",
                "kodepos": "91563"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Tommo",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Tumonga",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Tamejarra (Tammejarra)",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Tamemongga",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Tamalea (Talondo I)",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Sandapang",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Siraun",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Sandana",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Salumakki",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Salutiwo",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Rante Mario",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Saludengen",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Mappu",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Polio (Ba&#8217;san)",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Malino",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Makkaliki",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Lumika",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Lumika I",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Leling Utara",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Limbong",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Leling",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Leling Barat",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Lasa",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Kondo Bulo",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Kinatang",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Karama",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Karataun",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Kalepu",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Kalumpang",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Campaloga",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Kakulasang (Kakullasan)",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Hinua",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Tommo",
                "kelurahan": "Buana Sakti",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Buttuada",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Bonehau",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalumpang",
                "kelurahan": "Batu Makkada",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Bonehau",
                "kelurahan": "Banuada",
                "kodepos": "91562"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Uhaimate",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Sondoang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Pokkang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Sinyonyoi",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Keang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Pammulukang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Kalukku",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Kalukku Barat",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Guliling",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Kabuloang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Beru-Beru",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Belang-Belang",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Kalukku",
                "kelurahan": "Bebanga",
                "kodepos": "91561"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Karema",
                "kodepos": "91515"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Rimuku",
                "kodepos": "91515"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Bambu",
                "kodepos": "91514"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Karampuang",
                "kodepos": "91515"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Sumare",
                "kodepos": "91513"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Tasokko",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Sukamaju",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Simboro",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Rangas",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Salletto",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Lembah Hopo",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Mora Benggaulu",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Mora IV",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Lara",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Lara III",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "KUPTD Botteng I",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "KUPTD Botteng II",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Kambunong",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Karossa",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Kayucalla",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Karossa",
                "kelurahan": "Kadaila",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Botteng Utara",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Simboro dan Kepulauan",
                "kelurahan": "Botteng",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Kep. Bala Balakang",
                "kelurahan": "Bala Balakang",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Kep. Bala Balakang",
                "kelurahan": "Bala Balakang Timur",
                "kodepos": "91512"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Mamunyu",
                "kodepos": "91511"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Tadui",
                "kodepos": "91511"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Batu Pannu",
                "kodepos": "91511"
            },
            {
                "kecamatan": "Mamuju",
                "kelurahan": "Binanga",
                "kodepos": "91511"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Tanete Pao",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Tampalang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Takandeang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Taang (Ta&#8217;an)",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Rantedoda",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Pangasaan",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Pasabu (Pasa Bu)",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Orobatu",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Labuang Rano",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Lebani",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Kopeang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Kasambang",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Dungkait",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Galung",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang",
                "kelurahan": "Bela",
                "kodepos": "91352"
            },
            {
                "kecamatan": "Tapalang Barat",
                "kelurahan": "Ahu",
                "kodepos": "91352"
            }
        ],
        "k346": [
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Ulumanda",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Sambabo",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Sulai",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Tandeallo",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Salutahongan",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Popenga",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Salutambung",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Mekkatta Selatan",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Panggalo",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Maliaya",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Malunda",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Mekkatta",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Lombong",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Lombong Timur",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Lamungang Batu",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Lombang",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Lombang Timur",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Ulumunda",
                "kelurahan": "Kabiraan",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Kayuangin",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Malunda",
                "kelurahan": "Bambangan",
                "kodepos": "91453"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Tubo Tengah",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Ulidang",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Tubo Poang",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Tubo Selatan",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Tubo",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Tammerodo Utara",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Tammerodo",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Tallum Banua",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Tallambalao",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Tallubanua Utara",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Seppong",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Sendana",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Sendana",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Pundau",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Puttada",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Onang Utara",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Paminggalan",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Onang Selatan",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Mosso Dua",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Mosso",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Manyamba",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Limboro Rambu-Rambu",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Limbua",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Lalatedzong",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Leppangang",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Bukit Samang",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Tubo (Tubo Sendana)",
                "kelurahan": "Bonde Bonde",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Binanga",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Banua Sendana",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Tinambung",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Tammeredo Sendana",
                "kelurahan": "Awo",
                "kodepos": "91452"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Simbang",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Sirindu",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Lalampanua",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Pesuloang",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Bonde Utara",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Buttu Pamboang",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Bonde",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Betteng",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Bababulo Utara",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Balombong",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Banua Adolang",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Bababulo",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Adolang",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Pamboang",
                "kelurahan": "Adolang Dhua",
                "kodepos": "91451"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Rangas",
                "kodepos": "91415"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Totoli",
                "kodepos": "91415"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Palipi Soreang",
                "kodepos": "91415"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Pamboborang",
                "kodepos": "91415"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Baru",
                "kodepos": "91415"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Baruga",
                "kodepos": "91414"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Tande Timur",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Tande",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Lembang",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Buttu Baruga",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Labuang Utara",
                "kodepos": "91413"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Labuang",
                "kodepos": "91412"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Galung",
                "kodepos": "91411"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Pangali-Ali",
                "kodepos": "91411"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Pangali-Ali",
                "kodepos": "91411"
            },
            {
                "kecamatan": "Banggae Timur",
                "kelurahan": "Baruga Dua",
                "kodepos": "91411"
            },
            {
                "kecamatan": "Banggae",
                "kelurahan": "Banggae",
                "kodepos": "91411"
            }
        ],
        "k347": [
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Uhaidaho",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Uhailanu",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Salutambun",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Taora",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Ranteberan (Rante Berang)",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Salurindu",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Ralleanak Utara",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Panetean",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Ralle Anak",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Pamoseang Pangga",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Kabae",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Kebanga",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Buntu Malangka",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Baruru",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Buntumalangka",
                "kelurahan": "Aralle Timur",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Aralle Utara",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Aralle (Arrale)",
                "kelurahan": "Aralle",
                "kodepos": "91373"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Saluleang",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Tabulahan",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Tampak Kurra",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Periangan",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Salubakka",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Malatiro",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Pangandaran",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Gandang Dewata",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Lakahang",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Tabulahan",
                "kelurahan": "Burana",
                "kodepos": "91372"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Ulumambi Barat",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Tapalina",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Ulumambi",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Tanete Tomba",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Talipukki",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Sondonglayu",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Sikamase",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Sendana",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Salundengen",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Selumaka",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Salumokanan Barat",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Salumokanan Utara",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Salumokanan",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mehalaan",
                "kelurahan": "Salukonta",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Salukepopok",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Salubulo",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Salukadi",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Salubanua",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Saluassing",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Rante Bulahan",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Rantelemo",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Pamoseang",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Minanga",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mehalaan",
                "kelurahan": "Mehalaan",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mehalaan",
                "kelurahan": "Mesakada",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Masoso",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Lembang Mokallang",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Limbadebata",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Mambi",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Leko",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mambi",
                "kelurahan": "Bujung Manurung",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Buangin",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Bambang Timur",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Mehalaan",
                "kelurahan": "Botteng",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Rantebulahan Timur",
                "kelurahan": "Bambang Buda",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Tamalantik",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Bambang",
                "kelurahan": "Bambang",
                "kodepos": "91371"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Sindagamanik",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Pidara",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Sipakuan",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Parondobulawan",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Minake",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Malabo",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Mannababa",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Mesakada",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tanduk Kalua",
                "kelurahan": "Ballabatu",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Balla Tumuka",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Balla Barat",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Balla Satanetean",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Balla",
                "kelurahan": "Balla",
                "kodepos": "91366"
            },
            {
                "kecamatan": "Tawalian",
                "kelurahan": "Tawalian Timur",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Satenetean",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Tawalian",
                "kelurahan": "Tawalian",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Rante Puang",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Tawalian",
                "kelurahan": "Rante Tangnga",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Orobua Selatan",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Orobua Timur",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Paladan",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Malangkena Padang",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Orobua",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Sesena Padang",
                "kelurahan": "Lisuan Ada",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Tawalian",
                "kelurahan": "Kariango",
                "kodepos": "91365"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Tabang",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Tabang Barat",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Tadokalua",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Masupu",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Bakadisura",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Tabang",
                "kelurahan": "Kalama",
                "kodepos": "91364"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Tallang Bulawan",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Ulusalu",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Sapan",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Siwi",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Panura",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Minanga Timur",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Nosu",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Pana",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Masewwe",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Minanga",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Mamullu",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Manipi",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Datubaringan",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Pana",
                "kelurahan": "Karaka",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Tondok Bakaru",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Nosu",
                "kelurahan": "Batupapan",
                "kodepos": "91363"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Taupe",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Pebassian",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Rambusaratu",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "mbana Salulo",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Osango",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Lambanan",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Mamasa",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Mambuliling",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Bombong Lambe",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Mamasa",
                "kelurahan": "Buntu Buda",
                "kodepos": "91362"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Tadisi",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Tabone",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Sipai",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Sumarorong",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Sepang",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Sibanawa",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Rippung",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Salubalo",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Sasakan",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Matande",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Messawa",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Pasma Mambu",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Makuang",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Messawa",
                "kelurahan": "Malimbong",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Batang Uru",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Batangnguru Timur",
                "kodepos": "91361"
            },
            {
                "kecamatan": "Sumarorong",
                "kelurahan": "Banea",
                "kodepos": "91361"
            }
        ],
        "k348": [
            {
                "kecamatan": "Angkona",
                "kelurahan": "Watangpanua",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Wanasari",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Tawakua",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Taripa",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Solo",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Tampinna",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Maliwowo",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Mantadulu",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Balirejo",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Angkona",
                "kelurahan": "Lamaeto",
                "kodepos": "92985"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Tabarano",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Wasuponda",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Parumpanai",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Nuha",
                "kelurahan": "Soroako/Soroaku",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Nuha",
                "kelurahan": "Nikkel/Nekkel",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Nuha",
                "kelurahan": "Nuha",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Nuha",
                "kelurahan": "Magani",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Nuha",
                "kelurahan": "Matano",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Kawata",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Ledu-Ledu",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Wawondula",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Wasuponda",
                "kelurahan": "Balambano",
                "kodepos": "92983"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Tokalimbo",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Tole",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Timampu",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Matompi",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Pekaloa",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Rante Angin",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Mahalona",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Masiku",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Lioka",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Loeha",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Langkea Raya",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Libukan Mandiri",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Baruga",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Buangin",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Kalosi",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Bantilang",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Towuti",
                "kelurahan": "Asuli",
                "kodepos": "92982"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Tarabbi",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Manurung",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Pongkeru",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Harapan",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Lakawali",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Atue",
                "kodepos": "92981"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Mabonta",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lewonu",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lumbewe",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Laro",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lauwo",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lambarese",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lanosi",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Kalatiri",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Lagego",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Cendana",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Harapan",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Jalajja",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Burau Pantai",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Bonepute",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Burau",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Burau",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Batu Putih",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Wonorejo Timur",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Burau",
                "kelurahan": "Asana",
                "kodepos": "92975"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Teromu",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Wonorejo",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Sumber Makmur",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Sindu Agung",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Pertasi Kencana",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Sumber Agung",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Non Blok",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Panca Karsa",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Mekar Sari",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Margolembo",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Koroncia",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Maleku",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Manggala",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Kalaena Kiri",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Mangkutana",
                "kelurahan": "Kasintuwu",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Argomulyo",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Kalaena",
                "kelurahan": "Argomulyo",
                "kodepos": "92973"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Ujung Baru",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Tadulako",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Tomoni",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Rante Mario",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Sumber Alam",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Patengko/Pattengko",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Purwosari",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Mulyasari",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Margomulyo",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Mandiri",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Manunggal",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Lestari",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Kalpataru",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Cendana Hitam Timur",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Kertoraharjo (Kertaraharjo)",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Beringin Jaya",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Cendana Hitam",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Bayondo",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Bangun Karya",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni",
                "kelurahan": "Bangun Jaya",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Tomoni Timur",
                "kelurahan": "Alam Buana",
                "kodepos": "92972"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Tarengge Timur",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Tarengge",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Rinjani",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Tabaroge",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Maramba",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Pepuro Barat",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Lampenai",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Lera",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Madani",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Korombua",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Kalaena",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Kanawatu",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Bawalipu",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Cendana Hijau",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Bahari",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Wotu",
                "kelurahan": "Balobalo",
                "kodepos": "92971"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Ussu",
                "kodepos": "92937"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Laskap",
                "kodepos": "92935"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Puncak Indah",
                "kodepos": "92936"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Balantang",
                "kodepos": "92933"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Baruga",
                "kodepos": "92932"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Wewangriu",
                "kodepos": "91981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Malili",
                "kodepos": "91981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Pasipasi",
                "kodepos": "91981"
            },
            {
                "kecamatan": "Malili",
                "kelurahan": "Lakawali Pantai",
                "kodepos": "91981"
            }
        ],
        "k349": [
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Tarobok",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Sassa",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Sumpira",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Salassa",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Salulemo",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Radda",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Palandan",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Polewali",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Mukti Jaya",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Mukti Tama",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Mario",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Mekar Sari Jaya",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Meli",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Marannu",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Lawewe",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Lembang-Lembang",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Bumi Harapan",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Kariango",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Lara",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Baebunta",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Baebunta",
                "kelurahan": "Beringin Jaya",
                "kodepos": "92965"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Tedeboe",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Onondoa",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Rampi",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Sulaku",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Dodolo",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Rampi",
                "kelurahan": "Leboni",
                "kodepos": "92964"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Wonosari",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Tulung Sari",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Wonokerto",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Tamboke",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Tolangi",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Tulung Indah",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sumber Baru",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sukamaju",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sukamukti",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sukadamai",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sukaharapan",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Sidoraharjo",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Subur",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Salulemo",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Saptamarga",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Paomacang",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Rawamangun",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Minanga Tallu",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Mulyasari",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Mulyorejo",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Lampuawa",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Lino",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Banyuwangi",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Kaluku",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Sukamaju",
                "kelurahan": "Katulungan",
                "kodepos": "92963"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Toradda (Torada)",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Rompu",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Sepakat",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Sumillin",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Pongo",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Pandak",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Pincara",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Pombakka",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Lero",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Masamba",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Lantang Tallang",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Lapapa",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Kamiri",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Laba",
                "kodepos": "92961"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Waetuo (Waetuwo)",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Wara",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Pole Jiwa",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Pombakka",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Waelawi",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Pengkajoang",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Limbong Wara",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Pao",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Pembuniang",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Kalitata",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Arusu",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Baku-Baku",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Malangke Barat",
                "kelurahan": "Cenning",
                "kodepos": "92957"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Wono",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Tanama Kaleang",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Tirobali",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Padang Raya",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Taloto",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Marante",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Padang Balua",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Hoyane",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Lodang",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Malimongan",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Embonatanah",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Tulak Tallu",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Seko",
                "kelurahan": "Beroppa",
                "kodepos": "92956"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Terpedo Jaya",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Tete Uri",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Salama",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Tandung",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Pompaniki",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Sabbang",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Pararra",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Pengkendekan",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Mari-Mari",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Marobo",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Kampung Baru",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Malimbu",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Dandang",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Kalotok",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Buangin",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Buntu Terpedo",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Batu Alang",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Bone Subur",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Pengkendekan",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Sabbang",
                "kelurahan": "Bakka",
                "kodepos": "92955"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Marampa",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Minanga",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Komba",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Limbong",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Kanandede",
                "kodepos": "92954"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Tokke",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Tandung",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Tingkara (Tingkaran)",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "To Lada",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Takkalala",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Pince Pute",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Pute Mata",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Salekoe",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Pattimang",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Petta Landung",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Ladongi",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Malangke",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Benteng",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Malangke",
                "kelurahan": "Girikusuma",
                "kodepos": "92953"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Uraso",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Sumber Wangi",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Tarra Tallu",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Ujung Mattajang",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Mekar Jaya Tondok",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Sumber Harum",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Mappedeceng",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Mangale",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Harapan/Kampung Baru",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Hasanah",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Kapidi",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Cendana Putih Satu",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Cendana Putih",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Cendana Putih Dua",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Mappedeceng",
                "kelurahan": "Benteng",
                "kodepos": "92917"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Baliase",
                "kodepos": "92916"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Bone",
                "kodepos": "92914"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Kasimbong",
                "kodepos": "92915"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Baloli (Balebo)",
                "kodepos": "92912"
            },
            {
                "kecamatan": "Masamba",
                "kelurahan": "Kappuna",
                "kodepos": "92913"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Tamuku",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Sumberdadi",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Sukaraya",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Sidomakmur",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Sidomukti",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Sidobinangun",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Sadar",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Poreang",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Rampoang",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Pongko",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Patoloan",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Muktisari",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Munte",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Patila",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Karondang",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Bungadidi",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Tana Lili",
                "kelurahan": "Bungapati",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Banyu Urip",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Batang Tongka",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Bone Bone",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Bone-Bone",
                "kelurahan": "Bantimurung",
                "kodepos": "91966"
            },
            {
                "kecamatan": "Limbong",
                "kelurahan": "Rinding Allo",
                "kodepos": "91854"
            }
        ],
        "k350": [
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Lembang Matene",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Pulo Madu",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Kalaotoa",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Karumpa",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Garaupa Raya",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Sambali",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasilambena",
                "kelurahan": "Garaupa",
                "kodepos": "92863"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Lambego",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Majapahit",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Komba-Komba",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Lamantu",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Bonea",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Bonerate",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimarannu",
                "kelurahan": "Batu Bingkung",
                "kodepos": "92862"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Ujung",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Teluk Kampe",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Tarupa",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Tanamalala",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Rajuni",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Tambuna",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Massungke",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Nyiur Indah",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Lembang Baji",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Maminasa",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Latondu",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Labuang Pamajang",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Khusus Pasitallu",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Kembang Ragi",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Kayuadi",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Jinato",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Bontomalling",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Bontojati",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Bontobulaeng",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Pasimasunggu Timur",
                "kelurahan": "Bontobaru",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Takabonerate",
                "kelurahan": "Batang",
                "kodepos": "92861"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Tambolongan",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Patilereng",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Polassi",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Patikarya",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Lantimbongan",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Lowa",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Laiyolo Baru",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Laiyolo",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Harapan",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Khusus Bahuluang",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Binanga Sombaiya",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontosikuyu",
                "kelurahan": "Appatanah",
                "kodepos": "92855"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Tamalanrea",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Tanete",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Pamatata",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Menara Indah",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Onto",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Mekar Indah",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Maharayya",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Lalang Bata",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Kayu Bauk",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Buki",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Buki Timur",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Batangmata Sapo",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Bongaiya",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Bontonasaluk",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Barat Lambongan",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomatene",
                "kelurahan": "Batangmata",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Balang Butung",
                "kodepos": "92854"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Mare Mare",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Parak",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Polebungin",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Kaburu",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Kohala",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Bontomarannu",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Jambuiya",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Buki",
                "kelurahan": "Bontolempangan",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Bonea Timur",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Bontokoraang",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Bonea Makmur",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Bontomanai",
                "kelurahan": "Barugaiya",
                "kodepos": "92851"
            },
            {
                "kecamatan": "Benteng",
                "kelurahan": "Benteng Utara",
                "kodepos": "92812"
            },
            {
                "kecamatan": "Benteng",
                "kelurahan": "Benteng",
                "kodepos": "92812"
            },
            {
                "kecamatan": "Benteng",
                "kelurahan": "Benteng Selatan",
                "kodepos": "92812"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Putabangun",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Bontotangnga",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Kahu-Kahu",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Kalepadang",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Bontosunggu",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Pasimassunggu",
                "kelurahan": "Bontosaile",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Bontolebang",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Bontobangun",
                "kodepos": "92811"
            },
            {
                "kecamatan": "Bontoharu",
                "kelurahan": "Bontoborusu",
                "kodepos": "92811"
            }
        ],
        "k351": [
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Tunreng Tellue",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Sumpang Minangae",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Tadang Palie",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Pattiro Sompe",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Polewali",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Pattiro Bajo",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Pattiro Riolo",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Pakkasalo",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Pasaka",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Manajeng",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Maroanging",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Massenrengpulu",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Malluse Tasi",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Lette Tanah",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Mabbiring",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Cinnong",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Kalibong",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Balieng Toa",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Bulie",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Sibulue",
                "kelurahan": "Ajang Pulu",
                "kodepos": "92781"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Unra",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Waetuwo (Waetuo)",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Pude",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Raja",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Tarasu",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Padaelo",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Polewali",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Paccing",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Massangkae",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Mappalo Ulaweng",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Matuju",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Mallahae",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Mallari",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Lettekko",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Maccope",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Lappo Ase",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Lappa Bosse",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Lemo",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Kalero",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Kading",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Kajuara",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Gona",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Jaling",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Carebbu",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Cari Gading",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Cumpiga",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Bulumparee",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Cakke Bone",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Bulu Tanah",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Awo Lagading",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Awang Tangka",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Buareng",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Ancu",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Angkue / Angku",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Kajuara",
                "kelurahan": "Abbumpungeng",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Awangpone",
                "kelurahan": "Abbanuang",
                "kodepos": "92776"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Pancaitana",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Tebba",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Ulu Balang",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Mappatoba",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Manera",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Malimongeng",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Bellu",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Salomekko",
                "kelurahan": "Gattareng",
                "kodepos": "92775"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Ujunge",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Rappa",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Samaenre",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Libureng",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Muara",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Padatuo",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Bulu-Bulu",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Gareccing",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Biccoing",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Bonepute",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Tonra",
                "kelurahan": "Bacu",
                "kodepos": "92774"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Ujung Salangketo",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Ujung Tanah",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Tellongeng",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Tellu Boccoe",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Pattiro",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Sumaling",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Mattiro Walie",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Padaelo",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Mattampa Walie",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Mario",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Lapasa",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Lappa Upang",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Karella",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Lakukang",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Data",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Kadai",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Batu Gading",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Mare",
                "kelurahan": "Cege",
                "kodepos": "92773"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Welenreng",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Padang Loang",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Tanete",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Tanete Harapan",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Kawerang",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Lompu",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Cinennung",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Kanco",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Arasoe",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Awo",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Abbumpungeng",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Cina",
                "kelurahan": "Ajangpulu",
                "kodepos": "92772"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Watu",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Wollangi",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Samaelo",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Sugiale",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Talungeng",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Lampoko",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Parippung",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Corawalie",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Kajaolaliddong",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Kampuno",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Cinnong",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Congko",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Cempaniga",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Cingkang",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Bacu",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Barebbo",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Talabangi",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Watangcani",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Apala",
                "kodepos": "92771"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Pationgi",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Pattuku",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Paccing",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Patimpeng",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Pammusureng",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Mattiro Walie",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Masago",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Massila",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Maddanrengpulu",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Lamoncong",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Langi",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Latellang",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Ere Cinnong",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Bulusirua",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Bulu Ulaweng",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Patimpeng",
                "kelurahan": "Batulappa",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Bontojai",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Bana",
                "kodepos": "92768"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Tompong Patu",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Pasaka",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Sanrego",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Nusa",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Palattae",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Matajang",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Mattoanging",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Lalepo",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Maggenrang (Manggenrang)",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Labuaja",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Bontocani",
                "kelurahan": "Kahu",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Carima",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Hulo",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Cakkela",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Cammilo",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Balle",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Bonto Padang",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Arallae",
                "kodepos": "92767"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Wanuawaru",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Tanabatue",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Tappale",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Tompobulu",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Suwa",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Swadaya",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Polewali",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Ponre-Ponre",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Pitumpidange",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Poleonro",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Mattiro Walie",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Mario",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Mattiro Bulu",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Mattiro Deceng",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Mallinrung",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Bune",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Ceppaga",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Laburasseng (Laburasse)",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Baringeng",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Libureng",
                "kelurahan": "Binuang",
                "kodepos": "92766"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Turu Adae",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Salebba",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Tellu Boccoe",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Poleonro",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Salampe",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Mattampae",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Pattimpa",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Bolli",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Ponre",
                "kelurahan": "Mappesangka",
                "kodepos": "92765"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Turucinnae",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Sengeng Palie",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Poleonro",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Seberang",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Mattampa Walie",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Padaelo",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Massenrenpulu",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Mattampa Bulu",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Lalebata",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Mamminasae",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Barakkae",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lamuru",
                "kelurahan": "Barugae",
                "kodepos": "92764"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Waekeccee",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Walimpong",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Ujung Lamuru",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Tungke",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Tonronge",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Selli",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Sengeng Palie",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Tenri Pakkua",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Samaenre",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Pattuku Limpoe",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Mattiro Walie",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Patangkai",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Liliriawang",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Mattaro Puli",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Mattampa Walie",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Lappariaja",
                "kelurahan": "Lili Riattang",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Bengo",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Bengo",
                "kelurahan": "Bulu Allapporenge",
                "kodepos": "92763"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Ulaweng Cinnong",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Timusu",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Teamusu",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Tadang Palie",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Tea Malala",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Mula Menree",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Pallawa Rukka",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Sappe Walie",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Lilina Ajangale",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Manurunge",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Galung",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Jompie",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Lamakkaraseng",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Cani Sirenreng",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Ulaweng",
                "kelurahan": "Cinnong",
                "kodepos": "92762"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Tirong",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Ureng",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Usa",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Siame",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Tanah Tenga",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Pasempe",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Passippo",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Palakka",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Panyili",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Melle",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Mico",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Lemoape",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Maduri",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Mattanete Bua",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Bainang",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Palakka",
                "kelurahan": "Cinennung",
                "kodepos": "92761"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Wellulang",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Welado",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Waemputtange",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Ulaweng Riaja",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Waempubbue",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Tocinnong",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Timurung",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Tacipong",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Tassipi",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Telle",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Pompanua",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Pompanua Riattang (Salewangeng)",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Pinceng Pute",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Pacciro",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Mattaro Purae",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Opo",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Manciri",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Mampotu",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Lebbae",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Leppangeng",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Lili Riattang",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Labissa",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Laponrong",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Benteng Tellue",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Bila",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Amessangeng",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Amali Riattang",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Ajangale",
                "kelurahan": "Allamungeng Patue",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Amali",
                "kelurahan": "Ajang Laleng",
                "kodepos": "92755"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Watang Ta",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Watu",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Pusungnge",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Ujung Tanah",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Pallime",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Panyiwi",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Pacubbe",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Pallae",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Naga Uleng",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Latonro",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Laoni",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Labotto",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Labonge (Lebbongnge)",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Cenrana",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Cenrana",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Awang Cengrana / Cenrana",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Ajallasse",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Watang Padacenga (Turumame/Padacengnga)",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Uloe",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Unnyi",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Tempe",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Tocina",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Ujung",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Solo",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Tawaroe",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Praja Maju",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Sailong",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Sanrangeng",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Panyili",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Pattiro",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Padacenga (Pada Cengnga)",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Pakkasalo",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Matajang",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Melle",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Mario",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Lallatang",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Kampoti",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Laccori",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Waji",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Dua Boccoe",
                "kelurahan": "Cabbeng",
                "kodepos": "92753"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Tokaseng",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Ulo",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Sijelling",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Tajong",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Patangnga",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Pongka",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Padaidi",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Palongki",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Mattoanging",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Otting",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Lea",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Lappae",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Lamuru",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Lanca",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Ajjalireng",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tellu Siattinge",
                "kelurahan": "Itterung",
                "kodepos": "92752"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Majang",
                "kodepos": "92734"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Bulu Tempe",
                "kodepos": "92735"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Macege",
                "kodepos": "92732"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Macanang",
                "kodepos": "92733"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Jeppee",
                "kodepos": "92732"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Polewali",
                "kodepos": "92731"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Watang Palakka",
                "kodepos": "92731"
            },
            {
                "kecamatan": "Tanete Riattang Barat",
                "kelurahan": "Mattiro Walie",
                "kodepos": "92731"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Pappolo",
                "kodepos": "92721"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Waetuo",
                "kodepos": "92718"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Pallette",
                "kodepos": "92719"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Panyula",
                "kodepos": "92717"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Tibojong",
                "kodepos": "92716"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Bajoe",
                "kodepos": "92716"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Lonrae",
                "kodepos": "92716"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Toro",
                "kodepos": "92715"
            },
            {
                "kecamatan": "Tanete Riattang Timur",
                "kelurahan": "Cellu",
                "kodepos": "92715"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Biru",
                "kodepos": "92714"
            },
            {
                "kecamatan": "Kahu",
                "kelurahan": "Biru",
                "kodepos": "92714"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Watampone",
                "kodepos": "92713"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Ta",
                "kodepos": "92712"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Walennae",
                "kodepos": "92711"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Manurungnge",
                "kodepos": "92711"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Masumpu",
                "kodepos": "92711"
            },
            {
                "kecamatan": "Tanete Riattang",
                "kelurahan": "Bukaka",
                "kodepos": "92711"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Tondong",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Tapong",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Tellangkere",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Samaenre",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Polewali",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Sadar",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Pallawa",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Pallawa",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Lagori",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Gaya Baru",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Bonto Masunggu",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Batu Putih",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Barebbo",
                "kelurahan": "Kading",
                "kodepos": "90225"
            }
        ],
        "k352": [
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Rompe Gading",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Limam Poccoe",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Lebbotengngae",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Laiya",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Labuaja",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Cenrana Baru",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Cenrana",
                "kelurahan": "Baji Pamai",
                "kodepos": "92754"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Wanua Waru",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Samaenre",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Tellumpanuae",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Uludaya",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Padaelo",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Sabila",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Gattareng Matinggi",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Mattampapole",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Bentenge",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Barugae",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Mallawa",
                "kelurahan": "Batu Putih",
                "kodepos": "90563"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Timpuseng",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Sawaru",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Pattanyamang",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Pattiro Deceng",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Moncongloe",
                "kelurahan": "Moncongloe Bulu",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Moncongloe",
                "kelurahan": "Moncongloe Lappara",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Cenrana",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Moncongloe",
                "kelurahan": "Moncongloe",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Moncongloe",
                "kelurahan": "Bonto Bunga",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Mario Pulana",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Moncongloe",
                "kelurahan": "Bonto Marannu",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Cempaniga",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Camba",
                "kelurahan": "Benteng",
                "kodepos": "90562"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Tukamasea",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Tanete",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Toddolimae",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Tompo Bulu",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Simbang",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Sambueja",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Pucak",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Minasa Baji",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Samangki",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Mangeloreng",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Mattoangin",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Leang-Leang",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Kalabbirang",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Jenetaesa",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Simbang",
                "kelurahan": "Bonto Tallasa",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Bontomatinggi",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Bonto Manurung",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Bonto Somba",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Benteng Gajah",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tompu Bulu",
                "kelurahan": "Bonto Manai",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Baruga",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bantimurung",
                "kelurahan": "Alatengae",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Tunikamaseang",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Tupabiring",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Pajukukang",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Salenrang",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Bontoa",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Minasa Upa",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Bonto Bahari",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Bonto Lempangan",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Toddo Pulia",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Bontoa (Maros Utara)",
                "kelurahan": "Ampekale",
                "kodepos": "90554"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Purna Karya",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Sudirman",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Damai",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Kuru Sumange",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Leko Pancing",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Allaere",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Tanralili",
                "kelurahan": "Borong",
                "kodepos": "90553"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Tenrigangkae",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Tellumpoccoe",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Temappadduae",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Pattontongan",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Pabentengan",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Marumpa",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Nisombalia",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Bontoa",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Hasanuddin",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "Bonto Matene",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Bonto Matene",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Marusu",
                "kelurahan": "A Bulosibatang",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Mandai",
                "kelurahan": "Baji Mangngai",
                "kodepos": "90552"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Taroada",
                "kodepos": "90516"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Pettuadae",
                "kodepos": "90516"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Baji Pamai",
                "kodepos": "90516"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Pallantikang",
                "kodepos": "90516"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Adatongeng",
                "kodepos": "90516"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Baju Bodoa",
                "kodepos": "90515"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Soreang",
                "kodepos": "90513"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Allepolea",
                "kodepos": "90514"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Mattiro Deceng",
                "kodepos": "90513"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Marrannu",
                "kodepos": "90513"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Maccini Baji",
                "kodepos": "90513"
            },
            {
                "kecamatan": "Lau",
                "kelurahan": "Bonto Marannu",
                "kodepos": "90513"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Bori Bellaya",
                "kodepos": "90512"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Bori Kamase",
                "kodepos": "90512"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Raya",
                "kodepos": "90511"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Turikale",
                "kodepos": "90511"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Majannang",
                "kodepos": "90511"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Mattiro Tasi",
                "kodepos": "90511"
            },
            {
                "kecamatan": "Maros Baru",
                "kelurahan": "Borimasunggu",
                "kodepos": "90511"
            },
            {
                "kecamatan": "Turikale",
                "kelurahan": "Alliritengae",
                "kodepos": "90511"
            }
        ],
        "k353": [
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Tongke-Tongke",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Sanjai",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Saukang",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Samataring",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Salohe",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Pasimarannu",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Pattalassang",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Lasiai",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Panaikang",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Bongki Lengkese",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Kaloling",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Kampala",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Timur",
                "kelurahan": "Biroro",
                "kodepos": "92671"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Kassi Buleng",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Pasir Putih",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Bonto Tengnga",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Bonto Katute",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Bonto Sinala",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Barambang",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Batu Belerang",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Borong",
                "kelurahan": "Biji Nangka",
                "kodepos": "92662"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Songing",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Talle",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Sangiasseri",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Puncak",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Palae",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Palangka",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Polewali",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Gareccing",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Bulu Kamase",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Alenangka",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Sinjai Selatan",
                "kelurahan": "Aska",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Tompobulu",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Lappacinrana",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Lamatti Riattang",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Lamatti Riawang",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Lamatti Riaja",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Bulu Tellue",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Bulupoddo",
                "kelurahan": "Duampanuae",
                "kodepos": "92654"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Turungan Baji",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Terasa",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Tassililu",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Bonto Salama",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Boto Lempangan",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Gunung Perak",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Balakia",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Barania",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Barat",
                "kelurahan": "Arabika",
                "kodepos": "92653"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Saotanre",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Saotengnga",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Samaenre",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Saohiring",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Mattunreng Tellue",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Pattongko",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Kanrung",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Kompang",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Gantarang",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Bonto",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Sinjai Tengah",
                "kelurahan": "Baru",
                "kodepos": "92652"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Pulau Padaelo",
                "kodepos": "92616"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Pulau Persatuan",
                "kodepos": "92616"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Pulau Buhung Pitue",
                "kodepos": "92616"
            },
            {
                "kecamatan": "Pulau Sembilan",
                "kelurahan": "Pulau Harapan",
                "kodepos": "92616"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Alewanuae",
                "kodepos": "92616"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Lamatti Rilau",
                "kodepos": "92613"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Lappa",
                "kodepos": "92614"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Bongki",
                "kodepos": "92615"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Biringere",
                "kodepos": "92611"
            },
            {
                "kecamatan": "Sinjai Utara",
                "kelurahan": "Balangnipa",
                "kodepos": "92612"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Tellu Limpoe",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Saotengah",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Sukamaju",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Samaturue",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Lembang Lohe",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Mannanti",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Massaile",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Kalobba",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Erabaru",
                "kodepos": "91671"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Bua",
                "kodepos": "91671"
            }
        ],
        "k354": [
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Tamatto",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Seppang",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Salemba",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Paccaramengang",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Padang Loang",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Manjalling",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Manyampa",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Lonrong",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Garanta",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Dannuang",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Balong",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Bijawang",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Ujung Loe",
                "kelurahan": "Balleanging (Balleangin)",
                "kodepos": "92661"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Tanah Towa",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Tambangan",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Tanah Jaya",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Possi Tanah",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Sangkala",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Sapanang",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Pantama",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Pattiroang",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Malleleng",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Malleleng",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Lembanglohe",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Lembanna",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Lolisang",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Laikang",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Lembang",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Bonto Baji",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Bonto Biraeng",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Bontorannu",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Tugondeng",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Kajang",
                "kelurahan": "Batunilamung",
                "kodepos": "92574"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Singa",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Tanuntung",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Karassing",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Pataro",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Gunturu",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Bonto Kamase",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Hero Lange-Lange (Herlang)",
                "kelurahan": "Borong",
                "kodepos": "92573"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Tritiro",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Pakubalaho",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Tamalanrea",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Ekatiro",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Lamanda",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Caramming",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Dwi Tiro",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Buhung Bundang",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Bonto Marannu",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Bonto Tangnga",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Bonto Barua",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Bonto Bulaeng",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Tanah Lemo",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bontotiro",
                "kelurahan": "Batang",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Sapolohe",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Tanah Beru",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Darubiah",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Lembanna",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Benjala",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Bira",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Bonto Bahari",
                "kelurahan": "Ara",
                "kodepos": "92571"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Polewali",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Taccorong",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Paenre Lompoe",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Palambarae",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Mattekko",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Padang",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Gattareng",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Jalanjang",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Mariorennu",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Dampang",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bontonyeleng",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bontoraja",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bukit Harapan",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bonto Sunggu",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bontomasila",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bialo",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Bonto Macinna",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Benteng Gatareng",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Benteng Malewang",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Gantorang/Gantarang (Gangking)",
                "kelurahan": "Barombong",
                "kodepos": "92561"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Topanda",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Tibona",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Tanah Harapan",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Tanete",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Swatani",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Salassae",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Sapo Bonto",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Palampang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Pangalloang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Karama",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Kambuno",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Jawi &#8211; Jawi",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Jojjolo",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bulolohe",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Bulo-Bulo",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bontomanai",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Bontomangiring",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bontoharu",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bontolohe",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bontobangun",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Bonto Minasa",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bonto Matene",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Bonto Bulaeng",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Batukaropa",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Batulohe",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Baruga Riattang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Barugae",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Balang Taroang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Ballasaraja",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Bulukumba (Bulukumpa)",
                "kelurahan": "Balang Pesoang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Anrang",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Rilau Ale",
                "kelurahan": "Bajiminasa",
                "kodepos": "92552"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Kalumeme",
                "kodepos": "92518"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Sopa",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Tamaona",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Sipaenre",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Somba Palioli",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Mattirowalie",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Oro Gading",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Kindang",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Kahayya",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Caile",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Garuntungan",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Benteng Palioi",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Borong Rappoa",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Anrihua",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Kindang",
                "kelurahan": "Balibo",
                "kodepos": "92517"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Tanah Kongkong",
                "kodepos": "92513"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Bintarore",
                "kodepos": "92514"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Terang-Terang",
                "kodepos": "92511"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Kasimpureng",
                "kodepos": "92511"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "L O K A (Loka)",
                "kodepos": "92511"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Bentengnge",
                "kodepos": "92511"
            },
            {
                "kecamatan": "Ujung Bulu",
                "kelurahan": "Ela-Ela",
                "kodepos": "92511"
            }
        ],
        "k355": [
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Bontotiro",
                "kodepos": "92572"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Loka",
                "kodepos": "92516"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Ujung Bulu",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Tompobulu",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tombolo (Tombo)",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tolo Timur",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tolo Utara",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tolo",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tolo Barat",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Tolo Selatan",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Samataring",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Rumbia",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Lebang Manai Utara",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Pallantikang",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Kassi",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Lebang Manai",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Gantarang",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Bontomanai",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Bonto Nompo",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Kelara",
                "kelurahan": "Bontolebang",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Togo-Togo",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Bonto Cini",
                "kodepos": "92371"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Tarowang",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Tino",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Palajau",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Pao",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Kalumpang Loe",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Kampala",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Maccinibaji",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Camba-Camba",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Kaluku",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Bulo-Bulo",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Bungeng",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Borong Lamu",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Batang",
                "kelurahan": "Bontoraya",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Bonto Ujung",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Bontorappo",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Balang Baru",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Balang Loe Tarowang",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Arungkeke",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Arungkeke",
                "kelurahan": "Arungkeke Pallantikang",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Tarowang",
                "kelurahan": "Allu Taroang",
                "kodepos": "92361"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Tombo-Tombolo",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Tuju",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Punagaya",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Pattiro",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Pappalluang",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Pallengu",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Pantai Bahari",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Pallantikang",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Kapita",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Mallasoro",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Marayoka",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Jenetallasa",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Kalimporo",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Garassikang",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Gunung Silanu",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Bontomanai",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Bontorannu",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Bulujaya",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Beroanging",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala",
                "kelurahan": "Benteng",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Banrimanurung",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Bangkala Barat",
                "kelurahan": "Barana",
                "kodepos": "92352"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Turatea",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Turatea Timur",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Tonrokassi Barat",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Tonrokassi Timur",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Taman Roya",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Tonrokassi",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Tanammawang",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Manjangloe",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Maero",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Kareloe",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Lentu",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Karelayu",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Datara",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Borongtala",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Bulusibatang",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Bulusuka",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Bontosunggu",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Bontotangnga",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Bontoramba",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Batujala",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Tamalatea",
                "kelurahan": "Bontojai",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Bangkalaloe",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Baraya",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Bontoramba",
                "kelurahan": "Balumbungang",
                "kodepos": "92351"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Monro-Monro",
                "kodepos": "92316"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Pabiringa",
                "kodepos": "92316"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Bontoa",
                "kodepos": "92316"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Empoang Utara",
                "kodepos": "92316"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Balang",
                "kodepos": "92315"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Balang Beru",
                "kodepos": "92316"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Sapanang",
                "kodepos": "92314"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Jenetallasa",
                "kodepos": "92314"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Parasangang Beru",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Tanjonga",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Langkura",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Mangepong",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Paitana",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Jombe",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Kayuloe Timur",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Bungungloe",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Bululoe",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Kayuloe Barat",
                "kodepos": "92312"
            },
            {
                "kecamatan": "Turatea",
                "kelurahan": "Bontomatene",
                "kodepos": "92313"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Sidenre",
                "kodepos": "92311"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Empoang",
                "kodepos": "92311"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Empoang Selatan",
                "kodepos": "92311"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Panaikang",
                "kodepos": "92311"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Balang Toa",
                "kodepos": "92311"
            },
            {
                "kecamatan": "Binamu",
                "kelurahan": "Biringkassi",
                "kodepos": "92311"
            }
        ],
        "k356": [
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Tombolo",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Rappoa",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Tanahloe",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Pattallassang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Pattallassang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Pattaneteang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Nipa-Nipa",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Pajukukang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Lumpangan",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Lembang Gantarangkeke",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Layoa",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Labbo",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Gantarangkeke",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Kaloling",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Borongloe",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Campaga",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Ereng-Ereng",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Bonto-Bontoa",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Biangloe",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Bonto Tappalang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Batu Karaeng",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Biangkeke",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Pajukukang",
                "kelurahan": "Baruga",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Balumbung",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Banyorang",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Gantarang Keke (Gantareng Keke)",
                "kelurahan": "Bajiminasa",
                "kodepos": "92461"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Tiro",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Tallasa",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Tangnga",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Salluang",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Sunggu",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Rannu",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Rita",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Matene",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Marannu",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Manai",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Maccini",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Majannang",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Lojong",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Langkasa",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Lebang",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Loe",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Jaya",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Karaeng",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Uluere",
                "kelurahan": "Bonto Daeng",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Jai",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Cinde",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Sinoa",
                "kelurahan": "Bonto Bulaeng",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Bissappu",
                "kelurahan": "Bonto Atu",
                "kodepos": "92451"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Pa Bumbungan",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Parangloe",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Mamampang",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Mappilawing",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Pa Bentengan",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Letta",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Lonrong",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Barua",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Kampala",
                "kodepos": "92415"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Onto",
                "kodepos": "92413"
            },
            {
                "kecamatan": "Eremerasa",
                "kelurahan": "Ulugalung",
                "kodepos": "92414"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Lamalaka",
                "kodepos": "92412"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Pallantikang",
                "kodepos": "92411"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Tappanjeng",
                "kodepos": "92411"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Lembang",
                "kodepos": "92411"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Mallilingi",
                "kodepos": "92411"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Karatuang",
                "kodepos": "92411"
            },
            {
                "kecamatan": "Bantaeng",
                "kelurahan": "Kayu Loe",
                "kodepos": "92411"
            }
        ],
        "k357": [
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Topejawa",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Panyangkalang",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Pattoppakang",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Punaga",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Lengkese",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Mangadu",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Cikowang",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Laikang",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Lakatong",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Bontoparang",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Banggae",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Mangara Bombang",
                "kelurahan": "Bontomanai",
                "kodepos": "92261"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Tamasaju",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Pattinoang",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Sampulungan",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Tamalate",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Pa&#8217;lalakkang",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Pa&#8217;rasangang Beru",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Pakkabba",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Mappakalompo",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Kalukuang",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Bontolebang",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Campagaya",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Kalenna Bontongape",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Bonto Sunggu",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Bontokaddopepe",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Bonto Lanra",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Aeng Batu-Batu",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Utara",
                "kelurahan": "Aeng Towa",
                "kodepos": "92255"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Popo",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Sawakong",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Tarowang",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Parangmata",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Mangindara",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Parambambe",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Kale Bentan",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Kalukubodo",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Kadatong",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Galesong Baru",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Galesong Kota",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Bontokanang",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Bontomangape",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Bonto Marannu",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Bonto Loe",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong",
                "kelurahan": "Boddia",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Bonto Kassi",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Bentang",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Galesong Selatan",
                "kelurahan": "Baramamase",
                "kodepos": "92254"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Rajaya",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Su&#8217;rulangi",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Pa Bundukang",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Patte Ne",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Canrego",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Lantang",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Moncongkomba",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Bulukunyi",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Cakura",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Tompotana",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Polombangkeng Selatan (Polobangkeng)",
                "kelurahan": "Bontokadatto",
                "kodepos": "92252"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Soreang",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Takalar Kota",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Patani",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Rewataya",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Mattirobaji",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Pa&#8217;batangang",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Balangdatu",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Mappakasunggu",
                "kelurahan": "Maccinibaji",
                "kodepos": "92232"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Tonasa",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Ujung Baji",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Paddinging",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Sanrobone",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Banyuanyara",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Sanrobone",
                "kelurahan": "Laguruda",
                "kodepos": "92231"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Towata",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Timbuseng",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Parang Baddo",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Parang Luara",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Pa Rappunganta",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Palleko",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Panrannuangku",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Mannongkoki",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Massamaturu",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Mattompodalle",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Lassang",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Lassang Barat",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Malewang",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Kampung Beru",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Ko Mara",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Balangtanaya",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Barugaya",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Polombangkeng Utara (Polobangkeng)",
                "kelurahan": "Kale Ko&#8217;mara",
                "kodepos": "92221"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Sabintang",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Salaka",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Sombala Bella",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Pappa",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Pattallassang",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Kalabbirang",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Maradekaya",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Pallantikang",
                "kodepos": "92211"
            },
            {
                "kecamatan": "Patallassang",
                "kelurahan": "Bajeng",
                "kodepos": "92211"
            }
        ],
        "k358": [
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Palantikang (Pallantikang)",
                "kodepos": "92215"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Ulujangan",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Rannaloe",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Sapaya",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Mangempang",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Paladingang",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Mangempang",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Julumatene",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Lassa Lassa",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Buakkang",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Jenebatu",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Bontotangnga",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Bontomanai",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Bontolempangan",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bontolempangang",
                "kelurahan": "Bontoloe",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Bungaya",
                "kelurahan": "Bissoloro",
                "kodepos": "92176"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Tanete",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Malakaji",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Rappoala",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Rappolemba",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Datara",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Garing",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Cikoro",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Tompobulu",
                "kelurahan": "Bontobuddung",
                "kodepos": "92175"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Sicini",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Pattapang",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Parigi",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Malino",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Majannang",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Manimbahoi",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Jonjo",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Garassi",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Bonto Lerung",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Buluttana (Bulutana)",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Tinggimoncong",
                "kelurahan": "Gantarang",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Tassese",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Bilanrengi",
                "kodepos": "92174"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Tamalatea",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Tanakkaraeng",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Moncongloe",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Pattallikang",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Manuju",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Lanna",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Lonjoboko",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Bontokassi",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Bontoparang",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Borisalo (Borisallo)",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Manuju",
                "kelurahan": "Bilalang (Billang)",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Belabori",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Parangloe",
                "kelurahan": "Belapunranga",
                "kodepos": "92173"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Tonasa",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Tamaona",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Timbuseng",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Tabbinjai",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Sunggumanai",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Romangloe",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Sokkolia",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Romang Lompoa",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Pattalassang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Pao",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Panaikang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Pakatto",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Pacellekang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Nirannuang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Mamampang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Mata Allo",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Kanreapia",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Jenemadinging",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Borongloe",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Erelembang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pattallassang",
                "kelurahan": "Borong Pa Lala",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Bontomanai",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Bolaromang",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Bontomarannu",
                "kelurahan": "Bili-Bili",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Tombolo Pao",
                "kelurahan": "Balassuka",
                "kodepos": "92171"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Tetebatu",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Toddotoa",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Taeng",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Pangkabinanga",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Parang Banoa",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Panakkukang",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Mangalili",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Pallangga",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Julupamai",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Kampili",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Julukanaya",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Julukanaya",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Bungaejaya",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Jenetallasa",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Bontoramba",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Pallangga",
                "kelurahan": "Bontoala",
                "kodepos": "92161"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Tindang",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Tanrara",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Tamallayang",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Salajo",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Sengka",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Romanglasa",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Salajengki (Salajangki)",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Katangka",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Manjapai",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Pabundukang",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Kalaserena",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Kalebarembeng",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Jipang",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bulogading",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo Selatan",
                "kelurahan": "Bontoramba",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bulogading",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bontonompo",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bontolangkasa Selatan",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bontolangkasa Utara",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bontobiraeng",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bontobiraeng Selatan",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Barembeng",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bontonompo",
                "kelurahan": "Bategulung",
                "kodepos": "92153"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Tangkebajeng",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Tubajeng",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Tanabangka",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Paraikate (Paraikatte)",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Panciro",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Panyangkalang",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Mata Allo",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Pabentengan",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Manjalling",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Maradekaya",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Mandalle (Mandale)",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Maccinibaji",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Lempangang",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Limbung",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Kalemandalle",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Kalebajeng",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Borimatangkasa",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Gentungang",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng Barat",
                "kelurahan": "Bontomanai",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Bontosunggu",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Bajeng",
                "kelurahan": "Bone",
                "kodepos": "92152"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Mawang",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Bontoramba",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Batangkaluku",
                "kodepos": "92117"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Romangpolong",
                "kodepos": "92118"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Kalegowa",
                "kodepos": "92116"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Tombolo",
                "kodepos": "92114"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Pandang Pandang",
                "kodepos": "92115"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Samata",
                "kodepos": "92113"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Katangka",
                "kodepos": "92114"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Tompobalang",
                "kodepos": "92112"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Paccinongan",
                "kodepos": "92113"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Sungguminasa",
                "kodepos": "92111"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Tamarunang",
                "kodepos": "92112"
            },
            {
                "kecamatan": "Somba Opu (Upu)",
                "kelurahan": "Bonto Bontoa",
                "kodepos": "92111"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Taring",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Tonrorita",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Pencong",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Lembangloe",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Parangloe",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Julukanaya",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Lauwa",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Borimasunggu",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Berutallasa",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Baturappe",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biringbulu",
                "kelurahan": "Batumalonro",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Tinggimae",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Moncobalang",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Tamannyeleng",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Lembangparang",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Kanjilo",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Benteng Somba Opu",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Barombong",
                "kelurahan": "Biringngalla",
                "kodepos": "90225"
            }
        ],
        "k359": [
            {
                "kecamatan": "Sopai",
                "kelurahan": "Salu Sopai",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Tombang Langda",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Nonongan Selatan",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Nonongan Utara",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Marante",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Langda",
                "kodepos": "92119"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Pasang",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Piongan",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Paku",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Parinding",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Ma Dong",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Dende",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Kapolang",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Dende' Piongan Napo",
                "kelurahan": "Buntu Tagari",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Tondon",
                "kelurahan": "Tondon Siba Ta",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Tondon",
                "kelurahan": "Tondon",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Tondon",
                "kelurahan": "Tondon Langi",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Tondon",
                "kelurahan": "Tondon Matallo",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Rante",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Tandung Nanggala",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Tagari",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Nanna Nanggala",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Palangi",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Nanggala",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Nanggala Sangpiak Salu",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Lilikira",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Karua",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Karre Penanian",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Basokan",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Nanggala",
                "kelurahan": "Karre Limbong",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Balusu Bangun Lipu",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Balusu Ao Gading",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Awak Kawasik",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Balusu",
                "kodepos": "91855"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Sikuku",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Talimbangan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Sarambu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Roroan Bara-Bara",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Sapan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Rindingallo",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Pulu Pulu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Rante Uma",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Ponglu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Polo Padang",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Parandangan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Pengkaroan Manuk (Pangkoroan Manuk)",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Pangkung Batu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Paonganan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Pangala Utara",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Pangala",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Maiting",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Awan Rante Karua",
                "kelurahan": "Londong Biang",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Limbong Malting",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Lo Ko Uru Tanete Batu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Lempopoton",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Kantun Poya",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Kapala Pitu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Buntu Minanga",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Buntu Batu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Awan Rante Karua",
                "kelurahan": "Buntu Karua",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Benteng Kado",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Kapalla Pitu (Kapala Pitu)",
                "kelurahan": "Benteng Mamulu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntu Pepasan",
                "kelurahan": "Batu Busa (Batu Basa)",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Awan Rante Karua",
                "kelurahan": "Batu Lotong",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Baruppu",
                "kelurahan": "Baruppu Selatan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Baruppu",
                "kelurahan": "Baruppu Utara",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Baruppu",
                "kelurahan": "Baruppu Benteng Batu",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Baruppu",
                "kelurahan": "Baruppu Parodo",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Awan Rante Karua",
                "kelurahan": "Awan",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Rindingallo",
                "kelurahan": "Ampang Batu (Appang)",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Tongkonan Basse",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Bangkelekila",
                "kelurahan": "Toyasa Akung",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean Suloara",
                "kelurahan": "Tonga Riu",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Bangkelekila",
                "kelurahan": "Tampan Bonga",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Tallang Sura&#8217;",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean Suloara",
                "kelurahan": "Suloara",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Sapan Kua Kua",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean Suloara",
                "kelurahan": "Sesean Matallo",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Tiroallo",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Ulusalu",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Pebulian",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Pesondongan",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Sangkaropi",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Malimbong",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Matallo",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Ballopasange",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Liku Lambe",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sa'dan",
                "kelurahan": "Sa&#8217; Dan Andulan",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Rinding Kila&#8217; Balabatu",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Rantebua Sumalu",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Rantebua",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Rantebua Sanggalagi",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Parinding",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Pitung Penanian",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Pangli Palawa",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Pangli Selatan",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Palawa",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Misa Ba&#8217;Bana",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Makuan Pare",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean Suloara",
                "kelurahan": "Landorundun",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean Suloara",
                "kelurahan": "Lempo",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Buntao",
                "kelurahan": "Issong Kalua",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Bori Lombongan",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Buntulobo",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Bori Rante Letok",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Deri",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Buangin",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sesean",
                "kelurahan": "Bori",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Rantebua",
                "kelurahan": "Bokin",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Bangkelekila",
                "kelurahan": "Bangkelekila",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Bangkelekila",
                "kelurahan": "Batu Limbong",
                "kodepos": "91853"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "Tallung Penanian",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "Tandung La&#8217;bo&#8217;",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Sangbua (Sangubua)",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Tadongkon",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Tallulolo",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Salu",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sopai",
                "kelurahan": "Salu Rante",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Rinding Batu",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "Pata&#8217; Padang",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Pantanakan Lolo",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "La&#8217;Bo",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "Paepalean",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Ba&#8217;tan",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Sanggalangi",
                "kelurahan": "Buntu La&#8217;Bo",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Kesu",
                "kelurahan": "Angin Angin",
                "kodepos": "91852"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Rante Pasele",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Saloso",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Singki",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Penanian",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Karassik",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Limbong",
                "kodepos": "91835"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Laang Tanduk",
                "kodepos": "91834"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Mentiro Tiku",
                "kodepos": "91834"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Tikala",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Pangden",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Sereale",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Buntu Batu",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Embatau",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Buntu Barana",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Tantanan Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Benteng Kado To&#8217;ria",
                "kodepos": "91833"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Tallunglipu Matallo",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Tampo Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Rantepaku Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Tagari Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Tallunglipu",
                "kelurahan": "Buntu Tallunglipu",
                "kodepos": "91832"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Pasele",
                "kodepos": "91831"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Rantepao",
                "kodepos": "91831"
            },
            {
                "kecamatan": "Rantepao",
                "kelurahan": "Malango",
                "kodepos": "91831"
            }
        ],
        "k360": [
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Tumale",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "To Balo",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "To&#8217;bia",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Tarramatekkeng",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Tampa",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Tirowali",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Pattedong Selatan",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Pattedong",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Parekaju",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Padang Sappa",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Padang Subur",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Paccerakang",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Olang",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Mario",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Muladimeng (Maladimeng)",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Jenne/Je&#8217;ne Maeja",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Lampuara",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Buntu Karya",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Buntu Kanna",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang",
                "kelurahan": "Buntu Kamiri",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Bassiang",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Bassiang Timur",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Ponrang Selatan",
                "kelurahan": "Bakti",
                "kodepos": "91999"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Salusana",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Sampano",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Temboe",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Gandang Batu",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "La Loa",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Malewong",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Bonepute",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Dadeko",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Babang",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong Selatan",
                "kelurahan": "Batu Lappa",
                "kodepos": "91998"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Riwang",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Riwang Selatan",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Rante Alang",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Rantebelu",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Komba Selatan",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Larompong",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Lumaring",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Buntu Pasik",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Komba",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Bukit Sutta (Sutera)",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Buntu Matabbing (Matabing)",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Bilante",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Larompong",
                "kelurahan": "Binturu",
                "kodepos": "91997"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Tallang",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Towondo (Towondu)",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Salubua",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Suli",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Poringan",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Papakaju",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Murante",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Padang Lambe",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Muhajirin",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Malela",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Lindajang",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Lempo Pacci",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Kaladi Darussalam",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Kasiwiang",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Kaili",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Cimpu Utara",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Cakkeawo",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Cimpu",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli Barat",
                "kelurahan": "Buntu Barana",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Buntu Kunyi",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Suli",
                "kelurahan": "Botta",
                "kodepos": "91996"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Tetekang",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Tumbu Barak",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Sumabu",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Tallang Bulawang",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Saronda",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Samulang",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Sampeang",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Sampa",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Rumaju",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Saga",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Marinding",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Pangi",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Kadong-Kadong",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Langkiddi",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Buntu Babang",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Jambu",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Bonelemo Utara",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Bonelemo Barat",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Balla",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo Barat",
                "kelurahan": "Bonelemo",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Bajo",
                "kelurahan": "Bajo",
                "kodepos": "91995"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Tanamanai",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Wara",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Tampumia Radda",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Tampumia Radda",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Senga Selatan",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Seppong",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Saluparemang Selatan",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Senga",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Salu Paremang",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Sabe",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Pasamai",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Pammanu",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Libukang",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Paconne",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Lauwa",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Lebani",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Lamunre",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa Utara",
                "kelurahan": "Lamunre Tengah",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Kamanre",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Kurrusumanga",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Bunga Eja",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Kamanre",
                "kelurahan": "Cilallang",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Belopa",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Balo- Balo",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Belopa",
                "kelurahan": "Balubu",
                "kodepos": "91994"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Tampumia",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Tanjong",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Padang Ma Bud",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Padang Tuju",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Saluinduk",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Noling",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Padang Kamburi",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Buntu Batu",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Malenggang",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Bua Ponrang (Bupon)",
                "kelurahan": "Balutan",
                "kodepos": "91993"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Urasu (Uraso)",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "To Long",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Tede",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Ta&#8217;ba",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Tasangtongkonan",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Tabi",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Salubua",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Sinaji",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Pantilang",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Mappetajang",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Maindo",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Lissaga",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Lange",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Ledan",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Karatuan",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Kanna",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Kanna Utara",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Dampan",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Buntu Tallang",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Buntu Batu",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Bonglo",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Bolu",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Basse Sangtempe Utara",
                "kelurahan": "Barana",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bassesang Tempe (Bastem)",
                "kelurahan": "Andulan",
                "kodepos": "91992"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Toddopuli",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Tiromanda",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Tana Rigellan (Tanah Rigella)",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Sakti",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Raja",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Puty",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Posi",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Pamessakang",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Padang Kalua",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Pabbaresseng",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Lengkong",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Lare-Lare",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Karang-Karangan",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Bukit Harapan",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Bua",
                "kelurahan": "Barowa",
                "kodepos": "91991"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "To Pongo",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Wiwitan",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "To Lemo",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Siteba",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Se Pong",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Setiarejo",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Seriti",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Salutubu",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Sangtandung",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Salulino",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Salulino",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Pongsamelung",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Salu Jambu",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Pompengan Timur",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Pongko",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Pompengan Tengah",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Pelalan",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Padang Kalua",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Limbong",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Marabuana",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Lewandi",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Lempe",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Lempe Pasang",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Lamasi I",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Lamasi Hulu",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Buntu Awo",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Lamasi",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Bosso Timur",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Bosso",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Lamasi",
                "kelurahan": "Awo Gading",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang Utara",
                "kelurahan": "Bolong",
                "kodepos": "91952"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Walenrang",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Tanete",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Tombang",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Sukadamai",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Taba (Tabah)",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Saragi",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Seba-Seba",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Pompengan Pantai",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Rante Damai",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Pompengan",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Pangalli",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Lalong",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Kendekan",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Timur",
                "kelurahan": "Lamasi Pantai",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Kalibamamase",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Ilan Batu",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang Barat",
                "kelurahan": "Ilan Batu Uru",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Harapan",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Lamasi Timur",
                "kelurahan": "Bulolondong",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Bulo",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Bara Mamase",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Walenrang",
                "kelurahan": "Batusitanduk",
                "kodepos": "91951"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Ulusalu",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Tobaru",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "To&#8217;lajuk",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Tabang",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Tibussan",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Pajang",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Pangi",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Lambanan",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Kadundung",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Buntu Sarek",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Latimojong",
                "kelurahan": "Boneposi",
                "kodepos": "91921"
            }
        ],
        "k361": [
            {
                "kecamatan": "Wara Selatan",
                "kelurahan": "Songka",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Wara Selatan",
                "kelurahan": "Sampoddo",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Sendana",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Peta",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Purangi",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Salubattang",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Sumarambu",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Wara Selatan",
                "kelurahan": "Binturu",
                "kodepos": "91959"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Maccani",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Maroangin",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Petonjangan",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Batu Walenreng",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Telluwanua",
                "kelurahan": "Jaya",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Wara Barat",
                "kelurahan": "Battang",
                "kodepos": "91958"
            },
            {
                "kecamatan": "Wara Selatan",
                "kelurahan": "Takkalala",
                "kodepos": "91926"
            },
            {
                "kecamatan": "Sendana",
                "kelurahan": "Mawa",
                "kodepos": "91925"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Boting",
                "kodepos": "91923"
            },
            {
                "kecamatan": "Mungkajang",
                "kelurahan": "Murante",
                "kodepos": "91924"
            },
            {
                "kecamatan": "Mungkajang",
                "kelurahan": "Kambo",
                "kodepos": "91925"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Amassangan",
                "kodepos": "91922"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Tompo Tikka",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Barat",
                "kelurahan": "Tomarundung",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Salekoe",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Salotellue",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Sarutanga",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Pontap",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Pontap",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Ponjalae",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Barat",
                "kelurahan": "Padang Lambe",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Pajalesang (Panjelesang)",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Mungkajang",
                "kelurahan": "Mungkajang",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Malatuntung (Malatunrung)",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Mungkajang",
                "kelurahan": "Latuppa",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Barat",
                "kelurahan": "Lebang",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Lagaligo",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara",
                "kelurahan": "Dangerekko",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Timur",
                "kelurahan": "Benteng",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Wara Barat",
                "kelurahan": "Battang Barat",
                "kodepos": "91921"
            },
            {
                "kecamatan": "Bara",
                "kelurahan": "Temmalebba",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Bara",
                "kelurahan": "Tobulung",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Penggoli",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Bara",
                "kelurahan": "Rampoang",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Bara",
                "kelurahan": "Balandai",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Bara",
                "kelurahan": "Buntu Datu",
                "kodepos": "91914"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Patte&#8217;ne",
                "kodepos": "91912"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Sabbamparu (Saabbang Baru)",
                "kodepos": "91913"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Salobulo",
                "kodepos": "91911"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Luminda",
                "kodepos": "91912"
            },
            {
                "kecamatan": "Wara Utara",
                "kelurahan": "Batupasi",
                "kodepos": "91911"
            }
        ],
        "k362": [
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Tumbang Datu",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla (Sanggala)",
                "kelurahan": "Turunan",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Selatan",
                "kelurahan": "Tokesan",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Saluallo",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla (Sanggala)",
                "kelurahan": "Tongko Sarapung",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Selatan",
                "kelurahan": "Raru Sibunuan",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Ratele Bi Kambisa",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Selatan",
                "kelurahan": "Rante Alang",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Leatung Matallo",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Leatung",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla (Sanggala)",
                "kelurahan": "Kaero",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla (Sanggala)",
                "kelurahan": "Buntu Masakke",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla (Sanggala)",
                "kelurahan": "Bulian Massabu",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Utara",
                "kelurahan": "Bebo",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Selatan",
                "kelurahan": "Batualu",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Sangalla Selatan",
                "kelurahan": "Batualu Selatan",
                "kodepos": "91881"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Tanete",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Simbuang",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Simbuang Batuallo",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Sima",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Sangpeparikan (Sengpoarikan)",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Puang Bembe Mesakada (Ponghembe)",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Pongbembe",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Miallo",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Simbuang",
                "kelurahan": "Makkodo",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Kondo Dewata",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Butang",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Mappak",
                "kelurahan": "Dewata",
                "kodepos": "91873"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Ratte Buttu",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Rano",
                "kelurahan": "Rumandan",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Rano",
                "kelurahan": "Rano Timur",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Rano",
                "kelurahan": "Rano Utara",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Rano",
                "kelurahan": "Rano",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Rano",
                "kelurahan": "Rano Tengah",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Poton",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Mappa&#8217;",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Bau Selatan",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Bua Kayu",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Uluway Barat",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Bonggakaradeng",
                "kelurahan": "Bau",
                "kodepos": "91872"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Tengan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Uluway (Uluwai)",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Simbuang",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Tampo",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Sillanan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Rantedada",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Salubarani",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Randanan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Rante Kalua",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Perindingan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Pemanukan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Pemanukan",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Pa&#8217;Tengko",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Pakala",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Mebali",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Lemo",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Marinding",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Kandora",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Ke Pe Tinoring",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Gasing",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Garassik",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Kaduaja",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Buntu Tangti",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Gandangbatu",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Buntu Limbong",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Buntu Tabang",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Mengkendek",
                "kelurahan": "Buntu Datu (Batu)",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Benteng Deata",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Gandang Batu Sillanan",
                "kelurahan": "Bentengambeso",
                "kodepos": "91871"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Tonglo",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Taparan Utara",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Tapparan",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Rantetayo",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Rante Limbong",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Rante Kurra",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Padangiring",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Maroson",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rantetayo",
                "kelurahan": "Madandan",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Lipungan Tanete",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Limbong Sangpolo",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Kurra",
                "kelurahan": "Bambalu",
                "kodepos": "91862"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Ullin",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Talion",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "To&#8217; Pao",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Salutandung",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Sarapeang",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Salu",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Salu Boronan",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Sa&#8217;tandung",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Rembon",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Ratte Talonge",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Rea Tulak Langi",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Ra&#8217;bung",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Palesan",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Pattan Ulusalu",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Maroson",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Malimbong",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Limbong",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Leppan (Lepan)",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Kole Sawangan",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Lemo Menduruk",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Kayu Osing (Kayosing)",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Kole Barebatu",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Bua Tarrung",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Buri",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Saluputti",
                "kelurahan": "Batu Tiakka",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Batu Sura",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Rembon",
                "kelurahan": "Banga",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Malimbong Balepe",
                "kelurahan": "Balepe",
                "kodepos": "91861"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Se&#8217;seng",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Tiroan",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Sandana",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Sasak",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Pali&#8217;",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Patongloan",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Rembo-Rembo",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Kandua&#8217;",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Kole Palian",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Le&#8217;tek",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Burasia",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Buttu Limbong",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Bau",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Bittuang",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Bittuang",
                "kelurahan": "Balla",
                "kodepos": "91856"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Sese Salu (Sese&#8217;alu)",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Ratte",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Pondingao",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Paku",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Paliorong",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Kadungdung",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Belau",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Masanda",
                "kelurahan": "Belau Utara",
                "kodepos": "91854"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Tampo Makale",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Tondon Mamullu",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale Utara",
                "kelurahan": "Sarira",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale Utara",
                "kelurahan": "Tambunan",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Rante",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale Utara",
                "kelurahan": "Lemo",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale Utara",
                "kelurahan": "Lion Tondok Iring",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Kamali Pentalluan",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Lapandan",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Lea",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Buntu Burake",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Botang",
                "kodepos": "91817"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "To&#8217; Sapan",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Sandabilik",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Tiromanda",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Pateke",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Randan Batu",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Pasang",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Bone Buntu Sisong",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale Selatan",
                "kelurahan": "Pabuaran",
                "kodepos": "91815"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Manggau",
                "kodepos": "91814"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Batu Papan",
                "kodepos": "91813"
            },
            {
                "kecamatan": "Makale Utara",
                "kelurahan": "Bungin",
                "kodepos": "91812"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Tarongko",
                "kodepos": "91811"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Pantan",
                "kodepos": "91811"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Bombongan",
                "kodepos": "91811"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Lamunan",
                "kodepos": "91811"
            },
            {
                "kecamatan": "Makale",
                "kelurahan": "Ariang",
                "kodepos": "91811"
            }
        ],
        "k363": [
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Tuncung",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Wira Karya",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Tanete",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Tapong",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Salo Dua",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Sawito",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Tallang Rilau",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Patondon Salu",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Puncak Harapan",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Palakka",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Pariwang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Pasang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Ongko",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Paladang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Mangkawani (Mengkawani)",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Matajang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Lebani",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Limbuang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Kaluppang",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Labuku",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Bungin",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Boto Malangga",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Bulo",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Batu Mila",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Bungin",
                "kelurahan": "Baruka",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Boiya",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Bangkala",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Maiwa",
                "kelurahan": "Baringin",
                "kodepos": "91761"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Tongkonan Basse",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Taulo",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baroko",
                "kelurahan": "Tongko",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Tallungura",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Sumillan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Salassa",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Sanglepongan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Sumbang",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Rampunan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baroko",
                "kelurahan": "Patongloan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Parombean",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Pana",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Mundan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Pabaloran",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Mata Allo",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Mekkale (Mekkalak)",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Mandalan",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Masalle",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Kalosi",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Kambiolangi",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Buntu Sugi",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Curio",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Buntu Barana",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Curio",
                "kelurahan": "Buntu Pema",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Buntu Sarong",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Alla",
                "kelurahan": "Bolang",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baroko",
                "kelurahan": "Benteng Alla",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baroko",
                "kelurahan": "Benteng Alla Utara",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Masalle",
                "kelurahan": "Batu Kede",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baroko",
                "kelurahan": "Baroko",
                "kodepos": "91754"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Tomenawa",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Salukanan",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Tiro Wali",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Potok Ullin",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Perangian (Parangian)",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Pasui",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Pepandungan",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Pandung Batu",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Parinding",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Lunjen",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Latimojong",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Ledan",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Kadingeh",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Kendenan",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Langda",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Janggurara",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Eran Batu",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Buntu Batu",
                "kelurahan": "Buntu Mondong",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Bontongan",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Baraka",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Bone Bone",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Balla",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Baraka",
                "kelurahan": "Banti",
                "kodepos": "91753"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Tindallun",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Tangru",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Tampo",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Tanete",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Tallung Tondok",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Siambo",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Singki",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Saruran",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Rante Mario",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Salu Dewata",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Mandatte",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Mataran",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Pekalobean",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Malua",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Mampu",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Dulang",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Kolai",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Lakawan",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Buntu Batuan",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Bubun Lamba",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Malua",
                "kelurahan": "Bonto",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Bamba Puang",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Anggeraja",
                "kelurahan": "Batu Noni",
                "kodepos": "91752"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tungka",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tungka",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tokkonan",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tuara",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Temban",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tobalu",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Taulan",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Rossoan",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Tallu Bamba",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Puserren",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Ranga",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Pinang",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Pundilemo",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Malalin",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Lewaja",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Lebang",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Lembang",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Leoran",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Karrang",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Karueng",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Juppandang",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Kaluppini",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Cendana",
                "kelurahan": "Cendana",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Cemba",
                "kodepos": "91711"
            },
            {
                "kecamatan": "Enrekang",
                "kelurahan": "Buttu Batu",
                "kodepos": "91711"
            }
        ],
        "k364": [
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Tanatoro",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Lombo",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Leppangeng",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Dengeng-Dengeng",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Lagading",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Buntu Buanging",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Compong",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Bila Riase",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Bola Bulu",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Botto",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Belawae",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Sumpangmango",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Raise/Riase",
                "kelurahan": "Batu",
                "kodepos": "91691"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Ponrengae",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Lancirang",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Lasiwala",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Otting",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Bulucenrana",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Dongi",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Kalempang",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Betao",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Betao Riase",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Ajubissue",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Pitu Riawa",
                "kelurahan": "Anabannae",
                "kodepos": "91683"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Sidenreng (Watang Sidenreng)",
                "kodepos": "91682"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Taccimpo",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Tanru Tedong",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Salobukkang",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Salomalori",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Kampale",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Padangloang",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Padangloang Alau",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Kalosi",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Kalosi Alau",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Dua Pitue",
                "kelurahan": "Bila",
                "kodepos": "91681"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Wanio Timoreng",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Wettee",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Lise",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Wanio",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Cenrana",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Corawali",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Lajonga",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Bapangi",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Bilokka",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Panca Lautan (Lautang)",
                "kelurahan": "Allesalewoe",
                "kodepos": "91672"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Todang Pulu",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Teppo",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Teteaji",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Pajalele",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Polewali",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Baula",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Massepe",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Amparita",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Tellu Limpoe",
                "kelurahan": "Arateng",
                "kodepos": "91662"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Uluale",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Lainungan",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Lawawoi",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Mattirotasi",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Carawali",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Ciro-Ciroe",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Batulappa",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Buae",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Arawa",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Watang Pulu",
                "kelurahan": "Bangkai",
                "kodepos": "91661"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Tonrong Rijang",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Mario",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Rijang Panua",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Madenra",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Kampung Baru",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Kulo",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Tonronge",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Kulo",
                "kelurahan": "Abbokongeng",
                "kodepos": "91653"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Passeno",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Sipodeceng",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Manisa",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Panreng",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Benteng",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Duampanua",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Baranti",
                "kelurahan": "Baranti",
                "kodepos": "91652"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Rappang",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Timoreng Panua",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Lalebata",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Macorawalie",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Cipotakari",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Kadidi",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Bulo",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Panca Rijang",
                "kelurahan": "Bulo Wattang",
                "kodepos": "91651"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Damai",
                "kodepos": "91616"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Mojong",
                "kodepos": "91614"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Sereang",
                "kodepos": "91615"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Kanyuara",
                "kodepos": "91613"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Talawe",
                "kodepos": "91613"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Talumae",
                "kodepos": "91613"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Aka-Akae",
                "kodepos": "91613"
            },
            {
                "kecamatan": "Wattang Sidenreng (Watang Sidenreng)",
                "kelurahan": "Empagae",
                "kodepos": "91613"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Wala",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Takkalasi",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Tanete",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Pangkajene",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Rijang Pittu",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Lautang Benteng",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Majelling Watang",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Majjelling",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Kanie",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Lakessi",
                "kodepos": "91611"
            },
            {
                "kecamatan": "Maritengngae",
                "kelurahan": "Allakuang",
                "kodepos": "91611"
            }
        ],
        "k365": [
            {
                "kecamatan": "Suppa",
                "kelurahan": "Wiringtasi",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Watang Pulu",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Watang Suppa",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Wae Tuwoe",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Tellumpanua",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Ujung Labuang",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Samaulue",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Tasiwalie",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Maritengngae",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Polewali",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Mallongi Longi",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Lotang Salo",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Suppa",
                "kelurahan": "Lero",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Barang Palie",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Lanrisang",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Lerang",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Lanrisang",
                "kelurahan": "Amassangang",
                "kodepos": "91272"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Padakkalawa",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Pananrang (Pannanrang)",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Marannu",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Padaelo",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Padaidi",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Makkawaru",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Manarang",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Bunga",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Tanra Tuo",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Mattiro Bulu",
                "kelurahan": "Alitta",
                "kodepos": "91271"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Sikkuale",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Tadang Palie",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Mattunru Tunrue",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Salipolo",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Cempa",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Cempa",
                "kelurahan": "Mangki",
                "kodepos": "91262"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Samaenre",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Siwolong Polong",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Patobong",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Mattongang Tongang",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Pallameang",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Massulowalie",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Mattiro Tasi",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Mattombong",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Mattiro Sompe",
                "kelurahan": "Langa",
                "kodepos": "91261"
            },
            {
                "kecamatan": "Tiroang",
                "kelurahan": "Tiroang",
                "kodepos": "91256"
            },
            {
                "kecamatan": "Tiroang",
                "kelurahan": "Pakkie (Fakkie)",
                "kodepos": "91256"
            },
            {
                "kecamatan": "Tiroang",
                "kelurahan": "Pammase",
                "kodepos": "91256"
            },
            {
                "kecamatan": "Tiroang",
                "kelurahan": "Marawi",
                "kodepos": "91256"
            },
            {
                "kecamatan": "Tiroang",
                "kelurahan": "Mattiro Deceng",
                "kodepos": "91256"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Ulusaddang",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Suppirang",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Tadokkong",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Sabbang Paru",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Sali Sali",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Pangaparang",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Rajang",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Letta",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Pakeng",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Kariango",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Lembang Mesakada",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Betteng",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Binanga Karaeng",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Basseang",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Benteng Paremba",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Lembang",
                "kelurahan": "Bakaru",
                "kodepos": "91254"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Tatae",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Batulappa",
                "kelurahan": "Watang Kassa (Mattirowali)",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Pekkabata",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Batulappa",
                "kelurahan": "Tapporang",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Massewae",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Paria",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Katomporang",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Lampa",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Maroneng",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Batulappa",
                "kelurahan": "Kaseralau",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Batulappa",
                "kelurahan": "Kassa",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Kaliang",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Kaballangang",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Data",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Bittoeng",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Bungi",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Batulappa",
                "kelurahan": "Batulappa",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Battusawe (Buttu Sawe)",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Baba Binanga",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Duampanua",
                "kelurahan": "Barugae",
                "kodepos": "91253"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Teppo",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Tonyamang",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Pincara",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Sipatuo",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Mattiro Ade",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Padangloang",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Masolo",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Maccirinna",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Malimpung",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Benteng",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Patampanua",
                "kelurahan": "Leppangang",
                "kodepos": "91252"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Bentengnge",
                "kodepos": "91221"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Sipatokkong",
                "kodepos": "91218"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Penrang",
                "kodepos": "91219"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Salo",
                "kodepos": "91216"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Siparappe",
                "kodepos": "91217"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Mamminasae",
                "kodepos": "91215"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Pacongang",
                "kodepos": "91215"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Laleng Bata",
                "kodepos": "91215"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Macinnae",
                "kodepos": "91214"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Benteng Sawitto",
                "kodepos": "91215"
            },
            {
                "kecamatan": "Paleteang",
                "kelurahan": "Temmassarangnge",
                "kodepos": "91213"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Macorawalie",
                "kodepos": "91212"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Jaya",
                "kodepos": "91213"
            },
            {
                "kecamatan": "Watang Sawitto",
                "kelurahan": "Sawitto",
                "kodepos": "91211"
            }
        ],
        "k366": [
            {
                "kecamatan": "Soreang",
                "kelurahan": "Lakessi",
                "kodepos": "91133"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Ujung Lare",
                "kodepos": "91131"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Wattang Soreang",
                "kodepos": "91132"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Bukit Indah",
                "kodepos": "91131"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Kampung Pisang",
                "kodepos": "91131"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Ujung Baru",
                "kodepos": "91131"
            },
            {
                "kecamatan": "Soreang",
                "kelurahan": "Bukit Harapan",
                "kodepos": "91131"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Tiro Sompe",
                "kodepos": "91125"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Lumpue",
                "kodepos": "91123"
            },
            {
                "kecamatan": "Bacukiki",
                "kelurahan": "Lompoe",
                "kodepos": "91125"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Cappagalung",
                "kodepos": "91122"
            },
            {
                "kecamatan": "Bacukiki",
                "kelurahan": "Wattang Bacukiki",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Sumpang Minangae",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Bacukiki",
                "kelurahan": "Lemoe",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Kampung Baru",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Bacukiki",
                "kelurahan": "Galung Maloang",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Bacukiki Barat",
                "kelurahan": "Bumi Harapan",
                "kodepos": "91121"
            },
            {
                "kecamatan": "Ujung",
                "kelurahan": "Ujung Sabbang",
                "kodepos": "91114"
            },
            {
                "kecamatan": "Ujung",
                "kelurahan": "Ujung Bulu",
                "kodepos": "91113"
            },
            {
                "kecamatan": "Ujung",
                "kelurahan": "Mallusetasi",
                "kodepos": "91111"
            },
            {
                "kecamatan": "Ujung",
                "kelurahan": "Lapadde",
                "kodepos": "91112"
            },
            {
                "kecamatan": "Ujung",
                "kelurahan": "Labukkang",
                "kodepos": "91111"
            }
        ],
        "k367": [
            {
                "kecamatan": "Keera",
                "kelurahan": "Paojepe",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Pattirolokka",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Labawang",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Lalliseng",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Ciromanie",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Inrello",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Keera",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Awota",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Ballaere (Ballere)",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Tobarakka",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Keera",
                "kelurahan": "Awo",
                "kodepos": "90993"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Tangkoro",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Tanrongi",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Tellesang",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Siwa",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Marannu",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Simpellu",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Lauwa",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Lompoloang",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Benteng",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Bulete",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Abbanderangnge",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Alesilurunge",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Pitumpanua",
                "kelurahan": "Batu",
                "kodepos": "90992"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Watan Rumpia",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Tosora",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Tua",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Uraiang",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Tellu Limpoe",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Tengnga",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Rumpia",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Tajo",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Liu",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Macanang",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Paria",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Lamiku",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Limpo Majang",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Laerung",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Cinnong Tabi",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Botto Penno",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Botto Tanre",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Majauleng",
                "kelurahan": "Botto Benteng",
                "kodepos": "90991"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Ujung Tanah",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Rajamawelang (Rajamawellang)",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Sanreseng Ade",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Solo",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Pasir Putih",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Pattangngae (Patangae)",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Lempong",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Manurung",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Bola",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Lattimu",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Walanga",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Makmur",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Bola",
                "kelurahan": "Balielo",
                "kodepos": "90984"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Padaelo",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Temmabarang",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Doping",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Penrang",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Lawesso",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Raddae",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Benteng",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Penrang",
                "kelurahan": "Tadang Palie",
                "kodepos": "90983"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Salobulo",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Towalida",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Minangae",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Sakkoli",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Assorajange",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Barangmamase",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Akkotengeng",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Alewadeng",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Soro",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Sajoanging",
                "kelurahan": "Akkajeng",
                "kodepos": "90982"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Lagoari",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Parigi",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Lamarua",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Peneki",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Botto",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Leweng",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Ceppaga",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Manyili",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Bocco",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Pantai Timur",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Aluppang",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Wecudai",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Takkalalla",
                "kelurahan": "Ajuraja",
                "kodepos": "90981"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Tobatang",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Watampanua",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Simpursia (Simpurusia)",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Tadang Palie",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Pammana",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Patila",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Pallawarukka",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Lempa",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Lampulung",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Lapaukke",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Kampiri",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Lagosi",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Abbanuange",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Pammana",
                "kelurahan": "Cina",
                "kodepos": "90971"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Walennae (Walenae)",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Worongnge",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Ujungpero",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Wage",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Tolotenreng (Talotenreng)",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Ugi",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Sompe",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Tadangpalie",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Pasaka",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Salotengnga",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Pallimae",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Liu",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Mallusesalo",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Benteng Lompoe",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Sabbang Paru",
                "kelurahan": "Bila",
                "kodepos": "90961"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Paselloreng",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Poleonro",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Polewalie",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Mamminasae (Maminasae)",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Gilireng",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Lamata",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Alausalo",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Arajang",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Gilireng",
                "kelurahan": "Abbatireng",
                "kodepos": "90954"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Wele",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Ongkoe",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Sappa",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Macero",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Malangke (Malakke)",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Lautang",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Limpo Rilau",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Belawa",
                "kodepos": "90953"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Tangkoli",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Minanga Tellue",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Sogi",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Kalola",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Mattirowalie",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Anabanua",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Dua Limpoe",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Maniang Pajo",
                "kelurahan": "Abbanuange",
                "kodepos": "90952"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Waetuwo",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Wajoriaja",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Wewangrewu",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Ujung Baru",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Ujunge",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Tonralipue",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Tancung",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Pakkanna",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Palippu",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Pincengpute",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Nepo",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Pajalele",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Mappadaelo",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Mario",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Lowa",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Assorajang",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Mannagae",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Wiringpalennae",
                "kodepos": "90918"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Baru Tancung",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Mattirotappareng",
                "kodepos": "90918"
            },
            {
                "kecamatan": "Tana Sitolo",
                "kelurahan": "Inalipue",
                "kodepos": "90951"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Laelo",
                "kodepos": "90917"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Salomenraleng",
                "kodepos": "90917"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Sitampae",
                "kodepos": "90916"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Atakkae",
                "kodepos": "90915"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Siengkang",
                "kodepos": "90914"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Padduppa",
                "kodepos": "90914"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Pattirosompe",
                "kodepos": "90913"
            },
            {
                "kecamatan": "Belawa",
                "kelurahan": "Leppangeng",
                "kodepos": "90913"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Lapongkoda",
                "kodepos": "90913"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Teddaopu",
                "kodepos": "90912"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Tempe",
                "kodepos": "90911"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Watallipue",
                "kodepos": "90911"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Maddukkelleng",
                "kodepos": "90911"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Cempalagi",
                "kodepos": "90911"
            },
            {
                "kecamatan": "Tempe",
                "kelurahan": "Bulupabbulu",
                "kodepos": "90911"
            }
        ],
        "k368": [
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Tetewatu",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Ujung",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Paroto",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Palangiseng",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Parenring",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Macanre",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Masing",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Pajalesang",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Cabenge",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Kebo",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Abbanuange",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Lili Rilau",
                "kelurahan": "Baringeng",
                "kodepos": "90871"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Watutoa",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Watu",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Tettikengrarae",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Mario Rilau",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Mario Rittengnga",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Soga",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Labessi",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Goarie",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Mario Riaja",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Barae",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Gattareng",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Congko",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Mario Riwawo",
                "kelurahan": "Gattareng Toa",
                "kodepos": "90862"
            },
            {
                "kecamatan": "Citta",
                "kelurahan": "Tinco",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Timusu",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Pattojo",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Rompegading",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Ganra",
                "kelurahan": "Lompulle",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Citta",
                "kelurahan": "Labae",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Citta",
                "kelurahan": "Kampiri",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Jampu",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Jennae",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Ganra",
                "kelurahan": "Ganra",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Galung",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Citta",
                "kelurahan": "Citta",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Ganra",
                "kelurahan": "Enrekeng",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Ganra",
                "kelurahan": "Belo",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Appanang",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Liliraja (Lili Riaja)",
                "kelurahan": "Barang",
                "kodepos": "90861"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Tottong",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Pesse",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Pising (Solie)",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Sering",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Leworeng",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Labokong",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Lalabata Riaja",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Donri-Donri",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Donri-Donri",
                "kelurahan": "Kessing",
                "kodepos": "90853"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Tellulimpoe",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Manorang Salo",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Panincong",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Patampanua",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Laringgi",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Limpomajang",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Kaca",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Bulue",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Attang Salo",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Mario Riawa",
                "kelurahan": "Batu-Batu",
                "kodepos": "90852"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Maccile",
                "kodepos": "90814"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Salo Karaja",
                "kodepos": "90814"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Ompo",
                "kodepos": "90813"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Bila",
                "kodepos": "90814"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Umpungeng",
                "kodepos": "90811"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Lalabata Rilau",
                "kodepos": "90812"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Lemba",
                "kodepos": "90811"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Mattabulu",
                "kodepos": "90811"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Botto",
                "kodepos": "90811"
            },
            {
                "kecamatan": "Lalabata",
                "kelurahan": "Lapajung",
                "kodepos": "90811"
            }
        ],
        "k369": [
            {
                "kecamatan": "Balusu",
                "kelurahan": "Takkalasi",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Mattirowalie",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Pattappa",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Pujananting",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Mattappawalie",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Madello",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Lompo Riaja",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Lompo Tengah",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Lempang",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Libureng",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Lampoko",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Kading",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Jangan-Jangan",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Kamiri",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Riaja",
                "kelurahan": "Harapan",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Bulo-Bulo",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Gattareng",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Binuang",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Balusu",
                "kelurahan": "Balusu",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Pujananting",
                "kelurahan": "Bacu-Bacu",
                "kodepos": "90762"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Tellumpanua",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Pao-Pao",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Tanete",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Lipukasi",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Pancana",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Lasitae",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Lalabata",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Lalolang",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Corawali",
                "kodepos": "90761"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Palanro",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Manuba",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Nepo",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Cilellang",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Kupa",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Bojo",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Bojo Baru",
                "kodepos": "90753"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Paccekke",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Siddo",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Lawallu",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Mangkoso",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Kiru-Kiru",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Ajakkang",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Soppeng Riaja",
                "kelurahan": "Batupute",
                "kodepos": "90752"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Siawung",
                "kodepos": "90712"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Sumpang Binangae",
                "kodepos": "90712"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Anabanua",
                "kodepos": "90712"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Mangempang",
                "kodepos": "90712"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Tompo",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Tuwung",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Palakka",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Sepee",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Mallusetasi",
                "kelurahan": "Mallawa",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Tanete Rilau",
                "kelurahan": "Garessi",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Coppo",
                "kodepos": "90711"
            },
            {
                "kecamatan": "Barru",
                "kelurahan": "Galung",
                "kodepos": "90711"
            }
        ],
        "k370": [
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Tampaang",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Sapuka",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Satanger",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Sabaru",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Sailus",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Poleonro",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Sabalana",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Balo Baloang",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Tangaya",
                "kelurahan": "Kapoposan Bali",
                "kodepos": "90673"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Pammas",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Sabaru",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Kalu-Kalukuang",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Kanyurang",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Marasende",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Dewakang",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Kalmas (Kalukuang Masalima)",
                "kelurahan": "Doang Doanganlompo",
                "kodepos": "90672"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Walie",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Walie",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Sompe",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Ujung",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Langi",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Matae",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Labangeng",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Dolangeng",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Kanja",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Deceng",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Bulu",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Bone",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Bombang",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattiro Bintang",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring Utara",
                "kelurahan": "Mattiro Baji",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Liukang Tupabbiring",
                "kelurahan": "Mattaro Adae",
                "kodepos": "90671"
            },
            {
                "kecamatan": "Balocci",
                "kelurahan": "Tonasa",
                "kodepos": "90661"
            },
            {
                "kecamatan": "Balocci",
                "kelurahan": "Kassi",
                "kodepos": "90661"
            },
            {
                "kecamatan": "Balocci",
                "kelurahan": "Tompo Bulu",
                "kodepos": "90661"
            },
            {
                "kecamatan": "Balocci",
                "kelurahan": "Balleangin",
                "kodepos": "90661"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Tamarupa",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Balocci",
                "kelurahan": "Balocci Baru",
                "kodepos": "90661"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Parenreng",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Segeri",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Manggalung",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Coppo Tompong",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Mandalle",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Bonto Matene",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Bone",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Boddie",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Mandalle",
                "kelurahan": "Benteng",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Baring",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Segeri",
                "kelurahan": "Bawasalo",
                "kodepos": "90655"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Talaka",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Tamangapa",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Pitue",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Punranga",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Pitu Sunggu",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Marang (Ma Rang)",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Padang Lampe",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Attang Salo",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Bonto-Bonto",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Marang (Ma Rang)",
                "kelurahan": "Alesipitto",
                "kodepos": "90654"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Taraweang",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Pundata Baji",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Manakku",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Mangallekana",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Patallassang",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Kassi Loe",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Labakkang",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Gentung",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Kanaungan",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Bonto Manai",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Borimasunggu",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Bara Batu",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Labakkang",
                "kelurahan": "Batara",
                "kodepos": "90653"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Sapanang",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Tabo-Tabo",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Mangilu",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Samalewa",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Boriappaka",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Bowong Cindea",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Bulu Cindea",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Panaikang",
                "kodepos": "90619"
            },
            {
                "kecamatan": "Bungoro",
                "kelurahan": "Biring Ere",
                "kodepos": "90651"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Tekolabbua",
                "kodepos": "90617"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Bonto Langkasa",
                "kodepos": "90618"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Kabba",
                "kodepos": "90619"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Paddoang Doangan",
                "kodepos": "90617"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Bonto Perak",
                "kodepos": "90617"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Mappasaile",
                "kodepos": "90617"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Pabundukang",
                "kodepos": "90616"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Minasa Tene",
                "kodepos": "90615"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Bontokio",
                "kodepos": "90614"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Bontoa",
                "kodepos": "90615"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Kalabbirang",
                "kodepos": "90615"
            },
            {
                "kecamatan": "Minasa Tene",
                "kelurahan": "Biraeng",
                "kodepos": "90614"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Anrong Appaka",
                "kodepos": "90612"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Sibatua",
                "kodepos": "90613"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Jagong",
                "kodepos": "90611"
            },
            {
                "kecamatan": "Pangkajene",
                "kelurahan": "Tumampua",
                "kodepos": "90611"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Tondongkura",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Malaka",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Lanne",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Bulu Tellue",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Bonto Birao",
                "kodepos": "90561"
            },
            {
                "kecamatan": "Tondong Tallasa",
                "kelurahan": "Bantimurung",
                "kodepos": "90561"
            }
        ],
        "k371": [
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Tamalanrea Indah",
                "kodepos": "90245"
            },
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Tamalanrea Jaya",
                "kodepos": "90245"
            },
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Parang Loe",
                "kodepos": "90245"
            },
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Tamalanrea",
                "kodepos": "90245"
            },
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Bira",
                "kodepos": "90244"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Pai",
                "kodepos": "90243"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Sudiang Raya",
                "kodepos": "90242"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Buluroken (Bulurokeng)",
                "kodepos": "90243"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Sudiang",
                "kodepos": "90242"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Paccerakang",
                "kodepos": "90241"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Untia",
                "kodepos": "90241"
            },
            {
                "kecamatan": "Tamalanrea",
                "kelurahan": "Kapasa",
                "kodepos": "90241"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Tamangapa",
                "kodepos": "90235"
            },
            {
                "kecamatan": "Biring Kanaya",
                "kelurahan": "Daya",
                "kodepos": "90241"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Manggala",
                "kodepos": "90234"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Bangkala",
                "kodepos": "90235"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Paropo",
                "kodepos": "90233"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Tello Baru",
                "kodepos": "90233"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Antang",
                "kodepos": "90234"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Batua",
                "kodepos": "90233"
            },
            {
                "kecamatan": "Manggala",
                "kelurahan": "Borong",
                "kodepos": "90233"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Karuwisi Utara",
                "kodepos": "90232"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Tamamaung",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Karuwisi",
                "kodepos": "90232"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Pandang",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Panaikang",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Sinri Jala",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Karampuang",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Pampang",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Panakkukang",
                "kelurahan": "Masale",
                "kodepos": "90231"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Tanjung Merdeka",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Maccini Sombala",
                "kodepos": "90224"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Parang Tambung",
                "kodepos": "90224"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Barombong",
                "kodepos": "90225"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Kassi-Kassi",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Balang Baru",
                "kodepos": "90224"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Mappala",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Bongaya",
                "kodepos": "90223"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Buakana",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Jongaya",
                "kodepos": "90223"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Karunrung",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Rappocini",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Banta-Bantaeng",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Tidung",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Bonto Makkio",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Ballaparang",
                "kodepos": "90222"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Pa Baeng-Baeng",
                "kodepos": "90221"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Mannuruki",
                "kodepos": "90221"
            },
            {
                "kecamatan": "Tamalate",
                "kelurahan": "Mangasa",
                "kodepos": "90221"
            },
            {
                "kecamatan": "Rappocini",
                "kelurahan": "Gunung Sari",
                "kodepos": "90221"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Ujung Pandang Baru (Makassardang Baru)",
                "kodepos": "90217"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Wala-Walaya",
                "kodepos": "90217"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Tammua",
                "kodepos": "90216"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Rappokalling",
                "kodepos": "90216"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Rappojawa",
                "kodepos": "90215"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Kaluku Bodoa",
                "kodepos": "90215"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Buloa",
                "kodepos": "90212"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Kalukuang",
                "kodepos": "90214"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Tallo",
                "kodepos": "90212"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Pannampu",
                "kodepos": "90213"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Suwangga",
                "kodepos": "90211"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Lembo",
                "kodepos": "90213"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Lakkang",
                "kodepos": "90211"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "La Latang",
                "kodepos": "90211"
            },
            {
                "kecamatan": "Tallo",
                "kelurahan": "Bunga Eja Beru",
                "kodepos": "90211"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Pattunuang",
                "kodepos": "90174"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Ende",
                "kodepos": "90174"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Butung",
                "kodepos": "90173"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Mampu",
                "kodepos": "90173"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Malimongan",
                "kodepos": "90172"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Malimongan Tua",
                "kodepos": "90172"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Melayu",
                "kodepos": "90171"
            },
            {
                "kecamatan": "Wajo",
                "kelurahan": "Melayu Baru",
                "kodepos": "90171"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Kodingareng",
                "kodepos": "90168"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Barrang Lompo",
                "kodepos": "90166"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Barrang Caddi",
                "kodepos": "90167"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Tabaringan",
                "kodepos": "90165"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Totaka",
                "kodepos": "90165"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Tamalabba",
                "kodepos": "90164"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Ujung Tanah",
                "kodepos": "90164"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Cambaya",
                "kodepos": "90162"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Gusung",
                "kodepos": "90163"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Pattingalloang Baru",
                "kodepos": "90161"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Camba Berua",
                "kodepos": "90162"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Gaddong",
                "kodepos": "90157"
            },
            {
                "kecamatan": "Ujung Tanah",
                "kelurahan": "Pattingalloang",
                "kodepos": "90161"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Bontoala Parang",
                "kodepos": "90157"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Bontoala Tua",
                "kodepos": "90156"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Parang Layang",
                "kodepos": "90155"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Bontoala",
                "kodepos": "90156"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Bunga Ejaya",
                "kodepos": "90154"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Layang",
                "kodepos": "90154"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Malimongan Baru",
                "kodepos": "90152"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Baraya",
                "kodepos": "90153"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Tompo Balang",
                "kodepos": "90151"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Wajo Baru",
                "kodepos": "90151"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Barana",
                "kodepos": "90145"
            },
            {
                "kecamatan": "Bontoala",
                "kelurahan": "Timungan Lompoa",
                "kodepos": "90151"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maccini Gusung",
                "kodepos": "90144"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maccini Parang",
                "kodepos": "90144"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maccini",
                "kodepos": "90144"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Bara-Baraya Timur",
                "kodepos": "90143"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Bara-Baraya Utara",
                "kodepos": "90143"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Bara-Baraya",
                "kodepos": "90143"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Bara-Baraya Selatan",
                "kodepos": "90143"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maricaya",
                "kodepos": "90142"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maricaya Baru",
                "kodepos": "90142"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maradekaya Selatan",
                "kodepos": "90141"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maradekaya Utara",
                "kodepos": "90141"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Lariang Bangi",
                "kodepos": "90141"
            },
            {
                "kecamatan": "Makassar",
                "kelurahan": "Maradekaya",
                "kodepos": "90141"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Mandala",
                "kodepos": "90135"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Sambung Jawa",
                "kodepos": "90134"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Tamparang Keke",
                "kodepos": "90134"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Mamajang Dalam",
                "kodepos": "90135"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Parang",
                "kodepos": "90133"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Karang Anyar",
                "kodepos": "90134"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Mamajang Luar",
                "kodepos": "90132"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Bonto Lebang",
                "kodepos": "90133"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Bonto Biraeng",
                "kodepos": "90132"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Labuang Baji",
                "kodepos": "90132"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Maricaya Selatan",
                "kodepos": "90131"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Pa Batang",
                "kodepos": "90131"
            },
            {
                "kecamatan": "Mamajang",
                "kelurahan": "Baji Mappakasunggu",
                "kodepos": "90131"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Bontorannu",
                "kodepos": "90126"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Tamarunang",
                "kodepos": "90126"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Kunjung Mae",
                "kodepos": "90125"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Mario",
                "kodepos": "90125"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Mariso",
                "kodepos": "90122"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Lette",
                "kodepos": "90123"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Panambungan",
                "kodepos": "90121"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Kampung Buyang",
                "kodepos": "90121"
            },
            {
                "kecamatan": "Mariso",
                "kelurahan": "Mattoangin",
                "kodepos": "90121"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Pisang Selatan",
                "kodepos": "90114"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Pisang Utara",
                "kodepos": "90115"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Sawerigading",
                "kodepos": "90113"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Lajangiru",
                "kodepos": "90114"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Maloku",
                "kodepos": "90112"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Mangkura",
                "kodepos": "90113"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Lae &#8211; Lae",
                "kodepos": "90111"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Losari",
                "kodepos": "90112"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Bulogading",
                "kodepos": "90111"
            },
            {
                "kecamatan": "Ujung Pandang",
                "kelurahan": "Baru",
                "kodepos": "90111"
            }
        ],
        "k372": [
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Wosu",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Umpanga",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Wata",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Ungkaya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Uedago",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Umbele",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Topogaro (Tofogaro)",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Solonsa Jaya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Tondo",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Samarenda",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Sampeantaba",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Solonsa",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Puntari Makmur",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Pebotoa",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Parilangke",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Pebatae",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Moahino",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Marga Mulya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Limbo Makmur",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Lasampi",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Larobenu",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Karaupa",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Lambelu",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Lantula Jaya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Harapan Jaya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Emea",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Wita Ponda",
                "kelurahan": "Bumi Harapan",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Beringin Jaya",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Bahonsuai",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Bahoea Reko Reko",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bumi Raya",
                "kelurahan": "Atananga",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Bungku Barat",
                "kelurahan": "Ambunu",
                "kodepos": "94976"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Wawangkolono",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Ulunipa",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Torukune",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Ulunambo",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Tanona",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Terebino",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Tanjung Tiram",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Tafagapi",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Samarengga",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Pulau Tiga",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Pulau Tengah",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Padei Laut",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Padei Darat",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Ngapaea",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Padalaa",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Morompaitonga",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Matarape",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Mbokitta",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Matano",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Kofalagadi",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Masadian",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Dongkalan",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Menui Kepulauan",
                "kelurahan": "Buranga",
                "kodepos": "94975"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Were&#8217;a",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Waru-Waru",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Umbele",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Umbele Lama",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Torete",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Tangofa",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Siumbatu",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Tandaoleo",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Sambalagi",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Puungkeu",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Sainoa",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Pulaubapa",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Pulau Dua Darat",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Pulau Dua",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Polewali",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Po&#8217;o",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Poaro",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Paku",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Panimbawang",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Pado-Pado",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Padabale",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Padabaho",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "One Ete",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Makarti Jaya",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Lokombulo",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Le-Le",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Lamontoli",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Laroenai",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Lalemo",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Labota",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Lalampu",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Lafeu",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Koburu",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Keurea",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Kaleroang",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Jawi-Jawi",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Fatufia",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Dampala",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Buton",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Bungintende",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Bungingkela",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Pesisir",
                "kelurahan": "Buleleng",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Boelimau",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Buajangka",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Bete Bete",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Selatan",
                "kelurahan": "Bakala",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Bahomakmur",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bahodopi",
                "kelurahan": "Bahodopi",
                "kodepos": "94974"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Unsongi",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Ululere",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Tudua",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Tofuti",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Tofoiso",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Sakita",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Puungkoilu",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Oneputeh Jaya",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Obungi",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Nambo",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Matansala",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Menlui",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Matano",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Marsaoleh",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Laroue",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Lanona",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Lamberea",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Lahuafu",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Ipi",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Kolono",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Geresa",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bente",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bahoruru",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bahontobungku",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Bahomotefe",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bahomoko",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bahomohoni",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Timur",
                "kelurahan": "Bahomoahi",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Tengah",
                "kelurahan": "Bahomante",
                "kodepos": "94973"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Uewajo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Woomparigi",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Ueruru",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Uepakatu",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Uempanapa",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Uemasi",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tokonanaka",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tokala Atas",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tirongan Atas",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tirongan Bawah",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Taronggo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Tananagaya",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tanaku Raya",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Tana Sumpu",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Tambarobone",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Tambale",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Siliti",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Sea",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Posangke",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Salubiro",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Pokeang",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Parangisi",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Pandauke",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Opo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Momo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Minangobino",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Menyoe",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Matube",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Lijo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Lemowalia",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Lemo",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Kolo Bawah",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Kalombang",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Kolo Atas",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Mamosalato",
                "kelurahan": "Girimulya",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Boba",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Bungku Utara",
                "kelurahan": "Baturube",
                "kodepos": "94972"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Ungkea",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Ulu Laa",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Towara",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Towara Pantai",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Tontowea",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Tompira",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Togo Mulyo",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Toddopoli Uebangke",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Tiu",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Tandoyondo",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Tanauge",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Tambayoli",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Tamainusi",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Sumara Jaya",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Tadaku Jaya",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Sampalowo",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Panca Makmur",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Peboa",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Mondowe",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Onepute",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Molores",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Moleono",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Molino",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Barat",
                "kelurahan": "Maralee",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Mohoni",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Malino Jaya",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Lembah Sumara",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Malino",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Koromatantu",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Koya",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Kolonodale",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Korolaki",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Korolama",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Gililana",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Keuno",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Ganda-Ganda",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Bunta",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Binor Jaya",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia Timur",
                "kelurahan": "Bungintimbe",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Soyo Jaya",
                "kelurahan": "Bau",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Bahoue",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Petasia",
                "kelurahan": "Bahontula",
                "kodepos": "94971"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Wawopada",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Waraa",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Uluanso",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Tinompo",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Tingkeao",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Ronta",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Pontangoa",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Po&#8217;ona",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Petumbea",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Pa&#8217;awaru",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Mora",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Mandula",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Lemboroma",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Lembobelala",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Lembobaru",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Kumpi",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Korowou",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Korow Alelo",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Korompeli",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Korobonde",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Jamor Jaya",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Dolupo Karya",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo Raya",
                "kelurahan": "Bintangor Mukti",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Beteleme",
                "kodepos": "94966"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Wowondula",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Tomui Karya",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Tomata",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Tiwaa",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Tamonjengi",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Taende",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Tabarano",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Saemba Walati",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Saemba",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Peonea",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Peleru",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Pambarea",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Lembontonara",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Mayumba",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Londi",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Lanumor",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Lee",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Kolaka",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Gontara",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Kasingoli",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Utara",
                "kelurahan": "Era",
                "kodepos": "94965"
            },
            {
                "kecamatan": "Mori Atas",
                "kelurahan": "Ensa",
                "kodepos": "94965"
            }
        ],
        "k373": [
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Toropot",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Togong Sagu",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Timpaus",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Taduno",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Tabulang",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Tadiana Bungin",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Sasabobok",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Sonit",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Panapat",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Paisulamo",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Paisubebe",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Padingkian",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Ndindibung",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Nggasuang",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Mbuang Mbuang",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Minanga",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Mbeleang",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Mansalean",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Mandel",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Lipu Talas",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Lipulalongo",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Lantibung",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Lalong",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Lalong",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Kokudang",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Keak",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Kasuari",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Kaukes",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Kalupapi",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Kanari",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Bungin",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Dungkean",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bangkurung",
                "kelurahan": "Bone Bone",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Bontosi",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Bokan Kepulauan",
                "kelurahan": "Bolokut",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Labobo (Lobangkurung)",
                "kelurahan": "Alasan",
                "kodepos": "94892"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Tolokibit",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Tolise Tubono",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Tintingo",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Tinakin Laut",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Timbong Mominit",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Tano Bonunungan",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Potil Pololoba",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Pososlalongo",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Popisi",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Pasir Putih",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Paisumosoni",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Mominit",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Monsongan",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Matanga",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Lompio",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Malino Padas",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Lokotoy",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Lampa",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Labuan Kapelak",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Kokini",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Lambako",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Kelapa Lima",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Kendek",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Gong-Gong",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Dangkalan",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai",
                "kelurahan": "Dodung",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Utara",
                "kelurahan": "Bone Baru",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Selatan",
                "kelurahan": "Bantean",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Adean",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Banggai Tengah",
                "kelurahan": "Badumpayan",
                "kodepos": "94891"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Tompudau",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Tinangkung",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Tobing",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Tobungin",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Tatakalai",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Ponding-Ponding",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Saiyong",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Salakan",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Palam",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Mansamat A",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Mansamat B",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Paisumosoni",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Lalong",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Luk Sagu",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Manggalai",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Gansal",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Gansal",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Bulungkobit",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Bungin",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Bongganan",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Bobu",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung Utara",
                "kelurahan": "Bampanga",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Baka",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Bakalan",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Tinangkung",
                "kelurahan": "Ambelang",
                "kodepos": "94885"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Tone",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Tonuson",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Tobungku",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Sampaka",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Sobonon",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Sambiut",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Sakay",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Salangano",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Nulion",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Peley",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Lopito",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Lopito",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Lobuton",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Kombutokan",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Kalumbatan",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum Selatan",
                "kelurahan": "Kanali",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Bolonan",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Abason",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Totikum (Totikung)",
                "kelurahan": "Batang Babasal",
                "kodepos": "94884"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Tombos",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Tunggaling",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Tolulos",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Tomboniki",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Selekan",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Tangkop",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Popidolon",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Popisi",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Saleati",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Okumel",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Patukuki",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Mamulusan",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Luk",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Loolong",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Liang",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Labibi",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Kolak",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Koyobunga",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Boyomoute",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Kindandal",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Basosol",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Binuntuli",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Balayon",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Balombong",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Apal",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Liang",
                "kelurahan": "Bajo",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Peling Tengah",
                "kelurahan": "Alakasing",
                "kodepos": "94883"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Toy-Toy",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Unu",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Tolo",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Toolon",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Suit",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Tatarandang",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Sumondung",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Sambulangan",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Sosom",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Sabelak",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Sabang",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Peling Seasa",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Pandaluk",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Pipilogot Paipaisu",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Palabatu Dua",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Palabatu Satu",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Osan",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Paisuluno",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Montop",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Ombuli",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Oluno",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Momotan",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Montomisan",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Mangais",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Minanga",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Minanga",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Lolantang",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Luk Panenteng",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Mandok",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Labotakandi",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Lemelu",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Lalanday",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Koyobunga",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Kayubet",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Komba-Komba",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Bonepuso",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Bulagi Dua",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Bulagi Satu",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Kambal",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Boluni",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Bolubung",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Boloi (Boloy)",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Bangunemo",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Balalon",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Utara",
                "kelurahan": "Bakalinga",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Babang",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi",
                "kelurahan": "Alul",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Bulagi Selatan",
                "kelurahan": "Alasan Nggolobuton",
                "kodepos": "94882"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Tatendeng",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Tatabau",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Tataba",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Sapelang",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Seano",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Talas-talas",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Palapat",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Paisubatu",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Peling Lalomo",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Okulo Potil",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Olusi",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Malanggong",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Leme-Leme Darat",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Lumbi-Lumbia",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Leme-Leme Bungin",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Lelang Matamaling",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Labasiano",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Lalengan",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Landonan Bebeau",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Buko",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Kambani",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Labangun",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko",
                "kelurahan": "Batangono",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Buko Selatan",
                "kelurahan": "Apal",
                "kodepos": "94881"
            },
            {
                "kecamatan": "Tinangkung Selatan",
                "kelurahan": "Kampung Baru",
                "kodepos": "94714"
            }
        ],
        "k374": [
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Tongke",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Toweer",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Tintingan",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Tombos",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Teku",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Tanotu",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Tanggawas",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Talima A",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Talima B",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Talang Batu",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Sepe",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Resarna",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Ra&#8217;u",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Poyang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Pulau Dua",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Pangkalaseang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Pangkalaseang Baru",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Ondoliang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Padang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Luok",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Mamping",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Kuntang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Kiloma",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Gorontalo",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Kampangar",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Dondo",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Giwang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Dolom",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Dale-Dale",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Selatan",
                "kelurahan": "Booy",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Boloak",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Batu Mandi",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak Utara",
                "kelurahan": "Batu Simpang",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Balantak",
                "kelurahan": "Balantak",
                "kodepos": "94773"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Tompotika Makmur",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Taugi",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Tangabean (Tangeban)",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Simpangan",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Seresa",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Rangga-Rangga",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Purwo Agung",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Padangan",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Minangandala",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Kospa Buata Karya (Dwata Karya)",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Kembang Mentha (Merta/Menta)",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Eteng",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Duata Karya",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Masama",
                "kelurahan": "Cemerlang",
                "kodepos": "94772"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Tinonda",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Sobol Baru",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Sulubombong",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Sobol",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Sirong",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Poroan",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Pondang",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Lonas",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Nipa",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Labotan",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Lomba",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Kotabaru",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Kota Raya",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Kagitakan",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Garuga",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Boras",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Bombongan",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Bonebobakal",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Bolla",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Mantoh",
                "kelurahan": "Binotik",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Bahari Makmur",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Lamala",
                "kelurahan": "Baruga",
                "kodepos": "94771"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Uwe Mea",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Uwelolu",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Tolisu",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Tou",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Toili",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Toili",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Tohiti Sari",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Tirta Sari",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Sumber Harjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Singkoyo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Tirta Kencana",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Sindang Sari",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Sindang Baru",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Sidoharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Sido Makmur",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Sidoharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Selamet Raharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Sentral Sari",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Selamet Raharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Saluan",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Samalore",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Saluan",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Rata",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Piondo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Rusa Kencana",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Pandan Wangi",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Pasir Lamba",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Mulio Harjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Mulyoharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Moilong",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Minakarya",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Moilong",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Minakarya",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Minahaki",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Minahaki",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Mekar Sari",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Mantawa Bonebae (Bonebai)",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Mekar Jaya",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Mantawa",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Mansahang",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Lembah Kramat",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Makapa",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Karya Makmur",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Karang Anyar",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Karya Jaya",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Gunung Kramat",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Kami Wangi",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Bumiharjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Cendanapura",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Dongin",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Bumi Harjo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Bumi Harapan",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili Barat",
                "kelurahan": "Bukit Makarti",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Bentang",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Bukit Jaya",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Toili",
                "kelurahan": "Argakencana",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Argo Mulyo",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Moilong",
                "kelurahan": "Arga Kencana",
                "kodepos": "94765"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Uso",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Tolando",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Sukamaju Satu",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Sukamaju",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Sisipan",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Sisipan",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Paisu Buloli",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Ombolu",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Ondo-Ondolu",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Ondo-Ondolu I",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Maleo Jaya",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Masungkang",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Nonong",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Maasing",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Honbola",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Kayowa",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Lamo",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Gori-Gori",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Bugis",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui Selatan",
                "kelurahan": "Bonebalantak",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Balantang",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Batui",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Batui",
                "kelurahan": "Bakung",
                "kodepos": "94762"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Uling",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Solan",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Tangkiang",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Sayangbongin",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Samadoya",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Sayambongin",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Padungnyo",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Padungnyo",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Padang",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Nambo Lempek Baru",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Nambo Padang",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Nambo Bosaa",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Nambo Lempek",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Manyula",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Mendono",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Lumbe",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Lontio Baru",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Lontio",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Lontio",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Koyoan",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Nambo",
                "kelurahan": "Koyoan Permai",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Kintom",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Dimpalon",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Kalolos",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Kintom",
                "kelurahan": "Babang Buyangge",
                "kodepos": "94761"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Tuntung",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Tombongan Ulos",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Tobelombang",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Toima",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Tetesulu",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Temeang (Tomeang)",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Sumber Mulia",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Sumber Agung",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Simpang Satu",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Simpang Dua",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Salabenda",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Saiti",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Rantau Jaya",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Pulo Dalagan",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Pongian",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Polo",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Pimbombo (Pibombo)",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Petak",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Obok Balingara",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Pakowa Bunta (Pokowa Bunta)",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Nanga-Nangaon",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Matabas",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Mantan B",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Mantan A",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Longgolian",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Lontio",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Lokait",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Laonggo",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Koninis",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Koili",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Kalumbangan",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Kalaka",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Jaya Makmur",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Kabua Dua",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Hion",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Huhak",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Gonohop",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Dwipa Karya",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Dowiwi",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Dondo Simoli",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Doda Bunta",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Demangan Jaya",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Damai Makmur",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Bunta II",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Bunta I",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Bolobungkang",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Binohu",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Bohotokong",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Simpang Raya",
                "kelurahan": "Beringin Jaya",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Bella",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Bangketa",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Batu Hitam",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Bunta",
                "kelurahan": "Balanga",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Nuhon",
                "kelurahan": "Balaang",
                "kodepos": "94753"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Uwedaka",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Uwe Daka-Daka",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Uha Uhangon",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Trans Mayayap",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Tombang",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Tongkonunuk",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Toipan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Toiba",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Tintingan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Tikupon",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Taloyon",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Tampe",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Taima",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Siuna",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Sinampangnyo",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Sepak",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Sampaka",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Sama Jatem",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Salipi",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Poh",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Pinapuan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Pisou",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Pagimana",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Pakowa",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Niubulan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Nipa Kaleman / Kalemoan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Nain",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Mayayap",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Malik Makmur",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Malik",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Longkoga Timur",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Lobu",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Longkoga Barat",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Lembah Tompotika",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Lembah Makmur",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Lamo",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Lambuli",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Lambangan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Bubung",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Kadodi",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Jaya Bakti",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Huhak",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Hohudongan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Gomuo",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Soho",
                "kodepos": "94713"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Dongkalan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Keleke",
                "kodepos": "94713"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Dwi Karya",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Bungin Timur",
                "kodepos": "94712"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Dolom",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Bungin",
                "kodepos": "94712"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Bungawon",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Tontouan",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Bulu",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Bualemo B",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Salodik",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Bualemo A",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Mangkio Baru",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Luwuk",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Bondat",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Bolobungkang",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Lumpoknyo",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Binsil Padang",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Lenyek",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Binsil Ch",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Kilongan Permai",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Bualemo (Boalemo)",
                "kelurahan": "Bima Karya",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Kilongan",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Keraton",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Basabungan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Kamumu",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Baloadoda",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Buon Mandiri",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Bunga",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Balean",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Balai Gondi",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Bumi Beringin",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Bajo Poat",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Boyou",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Lobu",
                "kelurahan": "Bahingin",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Biak",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Asaan",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk",
                "kelurahan": "Baru",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Pagimana",
                "kelurahan": "Ampera",
                "kodepos": "94752"
            },
            {
                "kecamatan": "Luwuk Utara",
                "kelurahan": "Awu",
                "kodepos": "94711"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Uwedikan",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Pohi",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Molino",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Louk",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Lontos",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Lauwon",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Kayutanyo",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Indang Sari",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Hunduhon",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Bukit Mulya",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Boitan",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Baya",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Timur",
                "kelurahan": "Bantayan",
                "kodepos": "94723"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Tombang Permai",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Simpong",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Tanjung Tuwis",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Maahas",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Kompo",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Jole",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Hanga-Hanga Permai",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Hanga Hanga",
                "kodepos": "94717"
            },
            {
                "kecamatan": "Luwuk Selatan",
                "kelurahan": "Bukit Membual",
                "kodepos": "94717"
            }
        ],
        "k375": [
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Tutung",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Tumotok",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Tongidon",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Tingki",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Tiga Pulau",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Salinggoha",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Popolii (PopolIi)",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Pautu",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Pasokan",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Olilan",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Malenge",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Malapo",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Luok",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Loe",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Kondongan",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Kolami (Kolampi)",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Katogop (Kotogop)",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Kadoda",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Kalia",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Kabalutan",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Dolong B",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Kepulauan",
                "kelurahan": "Dolong A (Dolonga)",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Walea Besar",
                "kelurahan": "Biga",
                "kodepos": "94692"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Wakai",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Una Una",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Tanjung Pude",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Tumbulawa",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Taningkola",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Siatu",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Tanimpo",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Malino",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Molowagu",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Lindo",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Luangon",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Lembanya",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Kulingkinari",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Kambutu",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Kavetan",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Bomba",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Cendana",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Binanguna",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Una - Una",
                "kelurahan": "Bambu",
                "kodepos": "94691"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Wanasari",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Urundaka",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Uemakuni",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Uebone",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Tete Bawah",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Tete Atas",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Tampabatu",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Sabo",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Sukamaju",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Pusungi",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Mpoa",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Mantangisi",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Longge",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Kajulangko",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Giri Mulyo",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Bulan Jaya",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Borone",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Bantuga",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Balingara",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Ampana Tete",
                "kelurahan": "Balanggala",
                "kodepos": "94684"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Urulepe",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Uentanaga Bawah",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Uentanaga Atas",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Uamalingku",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Tongkabo",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Tobil",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Sumoli",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Sansarino",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Sampobae",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Saluaba",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Sabulira Toba",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Pulau Enam",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Muara Toba",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Patingko",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Muara Toba",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Lembanato",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Matobiai (Motobisi)",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Malotong",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Lebiti",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Labiabae",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Labuan",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Dondo Barat",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Katupat",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Kololio",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Buntongi",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Dondo",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Bone Rato",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Bungayo",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Bangkagi",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Baulu",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Awo",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Togean",
                "kelurahan": "Benteng",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Ampana",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Bailo",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Watusongu",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ampana Kota",
                "kelurahan": "Bailo Baru",
                "kodepos": "94683"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Uematopa",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Tobamawu",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Uekambuno",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Takibangke",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Tampanombo",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Paranonge",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Rompi",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Marowo",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Mire",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Borneang",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Cempa",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Kasiala",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Bongka Koy (Koi)",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Bongka Makmur",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Boneveto (Bonevoto)",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Bonebae II",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Ulu Bongka",
                "kelurahan": "Bonebae I",
                "kodepos": "94682"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Ujung Tibu",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Uekuli",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Tongku",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Uedele",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Toliba",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Tombiano",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Tojo",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Tatari",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Tayawa",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Tanamawau",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Sandada",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Nggawia",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Pancuma",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Podi",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Matako",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Mawomba",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Malei Tojo",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Malewa",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Korondoda",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Lemoro",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Kalemba I",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Kalemba II",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Galuga",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Kabalo",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Betaua",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Dataran Bugi",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Banano",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo Barat",
                "kelurahan": "Bambalo",
                "kodepos": "94681"
            },
            {
                "kecamatan": "Tojo",
                "kelurahan": "Bahari",
                "kodepos": "94681"
            }
        ],
        "k376": [
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Wayura",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Tokilo",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Tolambo",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Uelene",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Taripa",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Tindoli",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Barat",
                "kelurahan": "Salukaiya (Salukaia)",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Barat",
                "kelurahan": "Taipa",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Singkona",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Petiro",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Salindu",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Pendolo",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Panjo",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Pasir Putih",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Pancasila",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Panda Jaya",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Pandayora",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Mesewe (Masewe)",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Olumokunde",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Barat",
                "kelurahan": "Meko",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Mayoa",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Maya Jaya",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Maya Sari",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Kamba",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Boe",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Korobono",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Bangun Jaya",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Barati",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Selatan",
                "kelurahan": "Bancea",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Tenggara",
                "kelurahan": "Amporiwo",
                "kodepos": "94664"
            },
            {
                "kecamatan": "Pamona Barat",
                "kelurahan": "Uranosari",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Uelincu",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Tonusu",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Tiu",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Barat",
                "kelurahan": "Toinasa",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Tendeadongi",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Tentena",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Sulewana",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Soe",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Saojo",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Sawidago",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Sangira",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Poleganyara",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Sangele",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Peura",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Petirodongi",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Pamona",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Panjoka",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Matialemba",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Mayakeli",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Leboni",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Utara",
                "kelurahan": "Lena",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Leboni",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Kelei",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Kancuu",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Dulumai",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Timur",
                "kelurahan": "Didiri (Ompo Didiri)",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Pamona Puselemba",
                "kelurahan": "Buyumpondoli",
                "kodepos": "94663"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Watuawu",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Tampemadoro",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Tiyado (Toyado)",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Tongko",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Tagolu",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Tambaro",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Sepe",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Silanca",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Sintuwulemba",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Maliwuko",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Pandiri",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Bategencu",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Malei",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lage",
                "kelurahan": "Labuan",
                "kodepos": "94661"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Tuare",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Pada",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Runde",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Tomehipi",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Lempe",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Lengkeka",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Kolori",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Lelio",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Barat",
                "kelurahan": "Kageroa",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Hanggira",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Gintu",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Doda",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Bulili",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Bomba",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Bakekau",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Piore",
                "kelurahan": "Wanga",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Bewa",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Piore",
                "kelurahan": "Watutau",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Selatan",
                "kelurahan": "Badangkaiya (Badangkaia)",
                "kodepos": "94654"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Watumaeta",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Wuasa",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Timur",
                "kelurahan": "Tamadue",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Timur",
                "kelurahan": "Winowanga",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Torire",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Piore",
                "kelurahan": "Siliwanga",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Piore",
                "kelurahan": "Talabosa",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Sedoa",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Rompo",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Timur",
                "kelurahan": "Maholo",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Timur",
                "kelurahan": "Mekar Sari",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Dodolo",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Katu",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Bumi Banyubaru",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Bumi Banyusari",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Piore",
                "kelurahan": "Betue",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Bariri",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Tengah",
                "kelurahan": "Baleura (Baliura)",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Lore Utara",
                "kelurahan": "Alitupu",
                "kodepos": "94653"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Tumora",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Towu (Towo)",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Tri Mulya",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Toini",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Tokorondo",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Tiwaa",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Tobe",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Tangkura",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Tambarana",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Sangginora",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Pinedapa I",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Saatu",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Pantangolemba",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Patiwunga",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Padalembara",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Padalembara",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Masamba",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Masani",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Mapane",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Mambuke / Mambake",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Lape",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Malitu",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Lanto Jaya",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Kilo",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Kasiguncu",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Kalora",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Kawende",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Dewua",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Betania",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir",
                "kelurahan": "Bega",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Selatan",
                "kelurahan": "Betalemba",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Pesisir Utara",
                "kelurahan": "Bakti Agung",
                "kodepos": "94652"
            },
            {
                "kecamatan": "Poso Kota Selatan",
                "kelurahan": "Kawua",
                "kodepos": "94619"
            },
            {
                "kecamatan": "Poso Kota Selatan",
                "kelurahan": "Ranononcu",
                "kodepos": "94619"
            },
            {
                "kecamatan": "Poso Kota",
                "kelurahan": "Moengko Lama",
                "kodepos": "94618"
            },
            {
                "kecamatan": "Poso Kota",
                "kelurahan": "Gebang Rejo",
                "kodepos": "94619"
            },
            {
                "kecamatan": "Poso Kota Selatan",
                "kelurahan": "Lembamawo (Lembomawo)",
                "kodepos": "94617"
            },
            {
                "kecamatan": "Poso Kota",
                "kelurahan": "Moengko Baru",
                "kodepos": "94618"
            },
            {
                "kecamatan": "Poso Kota",
                "kelurahan": "Kayamanya",
                "kodepos": "94617"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Tegal Rejo",
                "kodepos": "94615"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Bonesompe",
                "kodepos": "94616"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Madale",
                "kodepos": "94614"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Lawanga",
                "kodepos": "94615"
            },
            {
                "kecamatan": "Poso Kota Selatan",
                "kelurahan": "Bukit Bambu",
                "kodepos": "94614"
            },
            {
                "kecamatan": "Poso Kota Selatan",
                "kelurahan": "Sayo",
                "kodepos": "94613"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Kasintuwu",
                "kodepos": "94611"
            },
            {
                "kecamatan": "Poso Kota Utara",
                "kelurahan": "Lombogia (Lombugia)",
                "kodepos": "94612"
            }
        ],
        "k377": [
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Umu",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Timbulon",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Tolau",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Tayokan",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Talaki",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Pandangan",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Taat",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Pionoto",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Oyak",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Paleleh",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Mulangato",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Nandu",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Matinan",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Lunguto",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Lunguto",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Lipubogu",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Lokodidi",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Lilito",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Lintidu",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Labuton",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Hulubalang",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Kuala Besar",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Harmoni",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Dutuno",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Dopalak",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Diapatih",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Bulagidun",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Gadung",
                "kelurahan": "Bulagidun Tanjung",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh Barat",
                "kelurahan": "Bodi",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Paleleh",
                "kelurahan": "Batu Rata",
                "kodepos": "94568"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Ponipingan",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Tamit",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Lonu",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Pokobo",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Domag Mekar",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Inalatan",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Kenamukan (Konamukan)",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Bunobogu (Bonobogu)",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Bunobogu Selatan",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bunobogu",
                "kelurahan": "Botugolu",
                "kodepos": "94567"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Tayadun",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Kodolagon",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Poongan",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Bongo",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Doulan",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Bokat",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Bokat IV",
                "kodepos": "94566"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Tongon",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Wakat",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Soraya",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Taluan",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Potugu",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Pujimulyo",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Pinamula",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Pomayagon",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Panimbul",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Permata Putih",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Monggonit",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Panilan Jaya",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Pajeko",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Maniala",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Momunu",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Lomuli",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Mangubi",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Lamadong I",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Lamadong II",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Jatimulyo",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Kokobuka",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Boilan",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Momunu",
                "kelurahan": "Guamonial",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Airterang",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Tiloan",
                "kelurahan": "Balau",
                "kodepos": "94565"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Winangun",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Unone",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Tang",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Tikopo",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Potangoan",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Rantemaranu",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Negeri Lama",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Mopu",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Kantanan",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Langudon",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Modo",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Duamayo (Duwamayo)",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Diat",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Bukamog",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Bokat",
                "kelurahan": "Butukan",
                "kodepos": "94564"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Ngune",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Yugut",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Mooyong",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Mulat",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Mokupo",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Monano",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Mendaan",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Leok II",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Leok II",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Leok I",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Leok 1",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Lamakan",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Kumaligon",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Kumaligon",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Kulango",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Kali",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Kali",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Ilambe",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Busak I",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Busak II",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Lakea (Lipunoto)",
                "kelurahan": "Buol",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Buol",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Bungkudu",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Biau",
                "kelurahan": "Bugis",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Bukal",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Karamat",
                "kelurahan": "Baruga",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Biau",
                "kodepos": "94563"
            },
            {
                "kecamatan": "Bukal",
                "kelurahan": "Binuang",
                "kodepos": "94563"
            }
        ],
        "k378": [
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Timbolo",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Teluk Jaya",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Santigi",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Pinjan",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Salumpaga",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Dako Pamean",
                "kelurahan": "Lingadan",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Lakuan Toli Toli",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Laulalang",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Dako Pamean",
                "kelurahan": "Galumpang",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Dako Pamean",
                "kelurahan": "Kapas",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Dako Pamean",
                "kelurahan": "Dungingis",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Binontoan",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Tolitoli Utara",
                "kelurahan": "Diule",
                "kodepos": "94562"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Tinigi",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sandana",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Tende",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Ogomuli (Ogomoli)",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sabang",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Lantapan",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Lalos",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Kalangkangan",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Lakatan",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Ginunggung",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Bajugan",
                "kodepos": "94561"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Soni",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Tampiala",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Mimbala",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Paddumpu",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Kombo",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Lempe",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Bangkir",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Dongko",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Selatan",
                "kelurahan": "Abbajareng",
                "kodepos": "94554"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Simatang Utara",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Tompoh",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Sese",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Simatang Tanjung",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Ogotua",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Malambigu",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Banagan",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Kabinuang",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Tinabogan",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dampal Utara",
                "kelurahan": "Bambapula",
                "kodepos": "94553"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Sibaluton",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Silondou",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Ogowele",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Salumbia",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Ogosipat",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Ogogili",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Malulu",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Ogogasang",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Malala",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Malomba",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Lais",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Luok Manipi",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Kongkomos",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Labonu",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Kayu Lompa",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Kinapasan",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Basidondo",
                "kelurahan": "Galandau",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Anggasan",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Dondo",
                "kelurahan": "Bambapun",
                "kodepos": "94552"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Sibea",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Salugan",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Mulyasari (Mulia Sari)",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Oyom",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Tinading",
                "kodepos": "94517"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Janja",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Maibua",
                "kodepos": "94518"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Ogomatanang",
                "kodepos": "94517"
            },
            {
                "kecamatan": "Lampasio",
                "kelurahan": "Lampasio",
                "kodepos": "94517"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Dadakitan",
                "kodepos": "94517"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Buntuna",
                "kodepos": "94517"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Pulias",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Sambujan",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Tambun",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Muara Besar",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Pagaitan",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Kamalu",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Labuan Lobo",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Buga",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Kabetan",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Batuilo",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Bilo",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Ogo Deide",
                "kelurahan": "Bambalaga",
                "kodepos": "94516"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Lelean Nono",
                "kodepos": "94515"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Tuweley",
                "kodepos": "94515"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Nalu",
                "kodepos": "94513"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Baru",
                "kodepos": "94514"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Panasakan",
                "kodepos": "94511"
            },
            {
                "kecamatan": "Baolan",
                "kelurahan": "Sidoarjo",
                "kodepos": "94512"
            }
        ],
        "k379": [
            {
                "kecamatan": "Bolano",
                "kelurahan": "Wanamukti Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Wanamukti Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Wanamukti",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Wanagading",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Tuladenggi Sibatang",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Tuladenggi Pantai",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Tompo",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Tirtanagaya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Tinombala Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Tinombala Jaya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Tinombala Sejati",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Tinombala",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Taopa",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Taopa Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Taopa Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Sritabaang",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Tabolobolo",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Siendeng",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Sidomukti",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Sejoli",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Santigi",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Salepae",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Salum Pengut",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Petunasugi",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Persatuan Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Pandelalap (Lalap)",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Persatuan Sejati",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Paria",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Palapi",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Pande",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Olonggata",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Ongka",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Ongka Trimuspasari",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Ogomolos",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Ogorandu",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Moutong Timur",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Moutong Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Nunurantai",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Mbelang Mbelang",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Moutong Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Moutong Tengah",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Margapura",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Malino",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Maranti",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Lobu",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Malalan",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Lembah Bomban",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Lambunu Timur",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Lambunu Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Lambunu",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Lambanau",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Labuan",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Kotanagaya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Kotaraya Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Karya Mandiri",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Kayu Jati",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Kota Raya Selatan",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Karya Abadi",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Karya Agung",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Gio",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Gunungsari",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Bukit Makmur",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Ganongol Sari",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Bolaung Olonggata",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Ongka Malino",
                "kelurahan": "Bosagon Jaya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Bolano Tengah",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Bolano Utara",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Bolano",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Bolano Barat",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Taopa",
                "kelurahan": "Bilalea",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Beringin Jaya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano",
                "kelurahan": "Bajo",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Bolano Lambunu/Lambulu",
                "kelurahan": "Anutapura",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Moutong",
                "kelurahan": "Aedan Raya",
                "kodepos": "94479"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Ulatan",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Tomini Barat",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Tomini Utara",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Tomini",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Supilopong",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Tilung",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Tingkulang",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Sumber Agung",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Pebounang",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Palasa Lambori",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Palasa Tangki",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Ogotomubu (Ogotumubu)",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Palasa",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Ogobayas",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Ogotion",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Mensung",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Mepanga",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Moubang",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Kotaraya",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Kotaraya Timur",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Kayu Agung",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Eeya",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Mepanga",
                "kelurahan": "Bugis",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Dongkalan",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Bobalo",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Biga",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Palasa",
                "kelurahan": "Bambasiang",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Ambesia Selatan",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Ambesia",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tomini",
                "kelurahan": "Ambesia Barat",
                "kodepos": "94476"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Tinombo",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Tibu",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Tada Timur",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Tada Utara",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Tada",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Tada Selatan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sintuwu Raya",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sipayo",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Sinei",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Siney Tengah",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Silabia",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Sigenti Selatan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Silutung",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Sigenti Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Sigenti Bersehati",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Sigenti",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sidoan Timur",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sidoan Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sidoan Selatan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Sidoan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Siavu",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Siaga",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Polly",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Ogobagis",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Patingke",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Muara Jaya",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Ogoalas",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Maninili",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Maninili Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Maninili Utara",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Malanggo (Malggo)",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Malanggo Pesisir",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Lombok",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Lombok Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Lado",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo Selatan",
                "kelurahan": "Khatulistiwa",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Dusunan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Dusunan Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Bondoyong",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Dongkas",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Bainaa Selatan",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Bainaa",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Bainaa Barat",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Tinombo",
                "kelurahan": "Ambason Mekar",
                "kodepos": "94475"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Towera",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Uevolo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Toribulu",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Toribulu Selatan",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Tomoli",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Tomoli Utara",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Toraranga",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Tomili Selatan",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Tombi",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Tolole",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Tolole Raya",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Tavalo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Toga",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Tamanpedagi",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Tandaigi",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Siniu",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Siniu Sayogindano",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Singura",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Silanga",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Sienjo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Silampayang",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Sidole Barat",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Sidole Timur",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Sibolago",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Sidole",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Toribulu",
                "kelurahan": "Pinotu",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Posona",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Pangku",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Paranggi",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Ogolugus",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Siniu",
                "kelurahan": "Marantale",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Lemo Tengah",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Lemo Utara",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Lemo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Laemanta",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Kasimbar Palapi",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Kasimbar Selatan",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Labuan Donggulu",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Kasimbar",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Kasimbar Barat",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Donggulu Selatan",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Kasimbar",
                "kelurahan": "Donggulu",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Buranga",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Ampibabo Utara",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Ampibabo Timur",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Aloo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Ampibabo",
                "kelurahan": "Ampibabo",
                "kodepos": "94474"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Tumpapa Indah",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Tolai Barat",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Tolai Timur",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Torue",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Tanah Lanto",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Tolai",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Suli",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Suli Indah",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Torono",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Trans",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Taliabo",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Tambu",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Piore",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Salubanga",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Auma",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Gandasari",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Sausu Pakareme",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Purwosari",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Malakosa",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Sausu",
                "kelurahan": "Maleali (Maleyali)",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Beraban",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Lebagu",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Balinggi",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Balinggi",
                "kelurahan": "Balinggi Jati",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Torue",
                "kelurahan": "Astina",
                "kodepos": "94473"
            },
            {
                "kecamatan": "Parigi Utara",
                "kelurahan": "Toboli Barat",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Tindaki",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Utara",
                "kelurahan": "Toboli",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Sumber Sari",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Pombalowo (Pembalowo)",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Utara",
                "kelurahan": "Sakina Jaya",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Petapa",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Pelawa Baru",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Pelawa",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Parigimpu&#8217;u",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Utara",
                "kelurahan": "Pangi",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Parigimpu",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Olobaru",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Olaya",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Matolele",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Nambaru",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Mertasari",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Masari",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Masigi",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Loji",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Maesa",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Lemusa",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Lobu Mandiri",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Lebo",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Kayuboko",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Jononunu",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Kampal",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Jono Kalora",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Dolago",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Gangga",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Selatan",
                "kelurahan": "Boyantongo",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Tengah",
                "kelurahan": "Binangga",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Bambalemo",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Bantaya",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Baliara",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Utara",
                "kelurahan": "Avolua",
                "kodepos": "94471"
            },
            {
                "kecamatan": "Parigi Barat",
                "kelurahan": "Air Panas",
                "kodepos": "94471"
            }
        ],
        "k380": [
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Watunonju",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Tuva (Tuwa)",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Uenuni",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Tanah Harapan",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Tongoa",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Soulowe",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Nokilalaki",
                "kelurahan": "Sopu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Sintuwu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Simoro",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Sigimpu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Sidondo IV",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Sidondo II",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Sidondo III",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Sidondo I",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Sidera",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Tanambulava",
                "kelurahan": "Sibalaya Utara",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Tanambulava",
                "kelurahan": "Sibowi",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Rejeki",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Sejahtera",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Tanambulava",
                "kelurahan": "Sibalaya Selatan",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Rahmat",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Rantelede (Ranteleda)",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Petimbe",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Pombewe",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Omu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Pakuli",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Pandere",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Olobuju",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Mpanau",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Ngatabaru",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Makmur",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Maranatha",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Lolu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Lemban Tongoa",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Loru",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Tanambulava",
                "kelurahan": "Lambara",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Kapiroe",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Nokilalaki",
                "kelurahan": "Kamarora B",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Gumbasa",
                "kelurahan": "Kalawara",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Kalukubula",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Nokilalaki",
                "kelurahan": "Kamarora A",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Jono Oge",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Bunga",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Nokilalaki",
                "kelurahan": "Kadidia",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Nokilalaki",
                "kelurahan": "Bulili",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Sigi Biromaru",
                "kelurahan": "Bora",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Bobo",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Bahagia",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Baku-Bakulu",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Berdikari",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Palolo",
                "kelurahan": "Ampera",
                "kodepos": "94364"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Watukilo",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Winatu",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Wangka",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Towulu",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Toro",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Tompi Bugis",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Tomua",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Tangkulowi",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Lindu",
                "kelurahan": "Tomado",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Siwongi",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Sungku",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Salutome",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Rantewulu",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Salua",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Lindu",
                "kelurahan": "Puro/Puroo",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Palimakijawa (Pilimakujawa/Makujawa)",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "O&#8217;o",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Palamaki",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Namo",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Moa",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Mataue",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Lempelero",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Lonca",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Lawua",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Lindu",
                "kelurahan": "Langko",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi Selatan",
                "kelurahan": "Gimpu",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Bolapapu",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Banggaiba",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Kulawi",
                "kelurahan": "Boladangko",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Lindu",
                "kelurahan": "Anca",
                "kodepos": "94363"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Wayu",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Wiapore",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Wugaga",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Uwemanje",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Tinggede",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Tinggede Selatan",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Sunju",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Taipanggabe",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Sibedi",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Soi",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Porame",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Rondingo",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Padende",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Panasibaja",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Ongulero",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Lewara",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Matantimali",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Lebanu",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Kalora",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Kanuna",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola Barat",
                "kelurahan": "Dombu",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Doda",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Daenggune",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Boyabaliase",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Bolobia",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Bomba",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Beka",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Binangga",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Marawola",
                "kelurahan": "Baliase",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Kinovaru",
                "kelurahan": "Balane",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Wisolo",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Walatana",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Watubula",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Waturalele",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Soulowe",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Tulo",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Sibonu",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Sambo",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Rarampadende",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Pulu",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Rogo",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Potoya",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Poi",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Pesaku",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Pewunu",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Mantikole",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Maku",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Kotarindau",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Langleso (Langaleso)",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Kota Pulu",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Kaleke",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Kalukutinggu",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Karawana",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo",
                "kelurahan": "Kabobona",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Bulubete",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Jono",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Bobo",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Balumpewa",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Bangga",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Balongga",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Selatan",
                "kelurahan": "Baluase",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Dolo Barat",
                "kelurahan": "Balamoa",
                "kodepos": "94361"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Peana",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Onu",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Parelea",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Mapahi",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Morui (Murui)",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Mamu",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Koja",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Lawe",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Lone Basa",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Kalamanta",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Kantewu",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Kantewu II",
                "kodepos": "94112"
            },
            {
                "kecamatan": "Pipikoro",
                "kelurahan": "Banasu",
                "kodepos": "94112"
            }
        ],
        "k381": [
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Toviora (Towiora)",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Vacava",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Tinauka",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Tamodo",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Tinauka",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Rio Mukti",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Polando Jaya",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Polanto Jaya",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Panca Mukti",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Pantalobete",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Palintuma",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Ngovi Vacava",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Minti Makmur",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Mbulava",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Lalundu",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Karavia",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Kanagalongga",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Gimpubia",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Dangaraa",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Bukit Indah",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Rio Pakava (Riopakawa)",
                "kelurahan": "Bonemarawa",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Bambakaenu",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Pinembani",
                "kelurahan": "Bambakanini",
                "kodepos": "94362"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Tonggolobibi",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Talaga",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Sioyong",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Siwalempu",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Samalili",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Siboalong (Siboang)",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Rerang",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Sabang",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Ponggerang",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol Utara",
                "kelurahan": "Pesik",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Panii",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Parisan Agung",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol Utara",
                "kelurahan": "Ogoamas II",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Panggalasiang (Pangalaseang)",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol Utara",
                "kelurahan": "Ogoamas I",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Malonas",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol Utara",
                "kelurahan": "Lenju",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Kembayang (Kambayang)",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Lemba Mukti",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Karyamukti",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Bukit Harapan",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Bukit Harapan",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Damsol (Dampelas Sojol)",
                "kelurahan": "Budimukti",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Bou",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol Utara",
                "kelurahan": "Bengkoli",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Balukang",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Sojol",
                "kelurahan": "Balukang II",
                "kodepos": "94356"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Walandano",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Tambu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Tovia Tambu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Sipure",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Siweli",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Simagaya",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Sibayu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Sibualong",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Pomolulu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Rano",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Palau",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Meli",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Manimbaya",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Mapane Tambu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Malei",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Lombonga",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Malino",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Labean",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang",
                "kelurahan": "Kampung Baru Sibayu",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Ketong",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Balaesang Tanjung",
                "kelurahan": "Kamonji",
                "kodepos": "94355"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Tanjung Padang",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Tompe",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Tondo S",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Sibado",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Sipi",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Lende Tovea",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Lompio",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Ombo",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Lende",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Jono oge",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Balintuma",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sirenja",
                "kelurahan": "Dampal",
                "kodepos": "94354"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Toaya",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Toaya Vunta/Funta",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Taripa",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Tibo",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Sumari",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Tamarenja",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Sindosa",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Sipeso",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Sikara Tobata",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Saloya",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Masaingi",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Oti",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Lero Tatari",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Marana",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Kumbasa",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Lero",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Kavaya",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Kaliburu Kata",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Kaliburu",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Enu",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Batusuya Go&#8217;o",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Dalaka",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tombusabora",
                "kelurahan": "Batusuya",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue",
                "kelurahan": "Amal",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Sindue Tobata",
                "kelurahan": "Alindau",
                "kodepos": "94353"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wombo Mpanau (Panau)",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wombo Kalonggo",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wani Tiga",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wombo",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wani Lumbumpetigo",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wani Satu / I",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Nupa Bomba",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Wani Dua / II",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Salumbone",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Toposo",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Lumbubaka",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Panimba",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Induk",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Kungguma",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Labuan",
                "kelurahan": "Labuan Lelea",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Guntarano",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Tanantovea",
                "kelurahan": "Bale",
                "kodepos": "94352"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Watatu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Towale",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Tosale",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Tolongano",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Tanahmea",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Tanampulu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Tanjung Batu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Surumana",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Salusumpu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Sarombaya",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Salumpaku",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Salungkaenu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Powelua",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Salubomba",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Mekar Baru",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Mbuwu",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Ongulara",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Malino",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lumbumamara",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Maleni",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lumbulama",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Lumbudolo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lumbu Tarombo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Lolioge",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lumbu Tarombo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Loli Saluran",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Loli Pesua",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Loli Dondo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lembasada",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Limboro",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Lampo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Lalombi",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Tengah",
                "kelurahan": "Kola-Kola",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Labuan Bajo",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Kabonga Kecil",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Ganti",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Gunung Bale",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Kabonga Besar",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Boneoge",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa",
                "kelurahan": "Boya",
                "kodepos": "94351"
            },
            {
                "kecamatan": "Banawa Selatan",
                "kelurahan": "Bambarimi",
                "kodepos": "94351"
            }
        ],
        "k382": [
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Pengawu",
                "kodepos": "94239"
            },
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Tawanjuka",
                "kodepos": "94237"
            },
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Palupi",
                "kodepos": "94238"
            },
            {
                "kecamatan": "Palu Selatan",
                "kelurahan": "Tatura Selatan",
                "kodepos": "94236"
            },
            {
                "kecamatan": "Palu Selatan",
                "kelurahan": "Tatura Utara",
                "kodepos": "94236"
            },
            {
                "kecamatan": "Palu Timur",
                "kelurahan": "Lolu Selatan",
                "kodepos": "94235"
            },
            {
                "kecamatan": "Palu Timur",
                "kelurahan": "Lolu Utara",
                "kodepos": "94235"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Tanamodindi",
                "kodepos": "94234"
            },
            {
                "kecamatan": "Palu Selatan",
                "kelurahan": "Birobuli Utara",
                "kodepos": "94231"
            },
            {
                "kecamatan": "Palu Selatan",
                "kelurahan": "Petobo",
                "kodepos": "94232"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Kawatuna",
                "kodepos": "94233"
            },
            {
                "kecamatan": "Palu Selatan",
                "kelurahan": "Birobuli Selatan",
                "kodepos": "94231"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Buluri",
                "kodepos": "94228"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Tipo",
                "kodepos": "94228"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Watusampu",
                "kodepos": "94229"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Silae",
                "kodepos": "94227"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Kabonena",
                "kodepos": "94227"
            },
            {
                "kecamatan": "Ulujadi",
                "kelurahan": "Donggala Kodi",
                "kodepos": "94226"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Balaroa",
                "kodepos": "94226"
            },
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Duyu",
                "kodepos": "94225"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Ujuna",
                "kodepos": "94222"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Kamonji",
                "kodepos": "94223"
            },
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Nunu",
                "kodepos": "94222"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Siranindi",
                "kodepos": "94221"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Lere",
                "kodepos": "94221"
            },
            {
                "kecamatan": "Tatanga",
                "kelurahan": "Boyaoge",
                "kodepos": "94221"
            },
            {
                "kecamatan": "Palu Barat",
                "kelurahan": "Baru",
                "kodepos": "94221"
            },
            {
                "kecamatan": "Palu Utara",
                "kelurahan": "Mamboro Barat",
                "kodepos": "94148"
            },
            {
                "kecamatan": "Palu Utara",
                "kelurahan": "Taipa",
                "kodepos": "94147"
            },
            {
                "kecamatan": "Palu Utara",
                "kelurahan": "Mamboro",
                "kodepos": "94148"
            },
            {
                "kecamatan": "Palu Utara",
                "kelurahan": "Kayumalue Pajeko",
                "kodepos": "94145"
            },
            {
                "kecamatan": "Palu Utara",
                "kelurahan": "Kayumalue Ngapa",
                "kodepos": "94146"
            },
            {
                "kecamatan": "Tawaeli",
                "kelurahan": "Pantoloan Boya",
                "kodepos": "94143"
            },
            {
                "kecamatan": "Tawaeli",
                "kelurahan": "Baiya",
                "kodepos": "94142"
            },
            {
                "kecamatan": "Tawaeli",
                "kelurahan": "Pantoloan",
                "kodepos": "94143"
            },
            {
                "kecamatan": "Tawaeli",
                "kelurahan": "Panawu (Panau)",
                "kodepos": "94141"
            },
            {
                "kecamatan": "Tawaeli",
                "kelurahan": "Lambara",
                "kodepos": "94141"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Tondo",
                "kodepos": "94119"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Lasoani",
                "kodepos": "94116"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Talise",
                "kodepos": "94118"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Poboya",
                "kodepos": "94115"
            },
            {
                "kecamatan": "Mantikulore",
                "kelurahan": "Layana Indah",
                "kodepos": "94111"
            },
            {
                "kecamatan": "Palu Timur",
                "kelurahan": "Besusu Tengah",
                "kodepos": "94111"
            },
            {
                "kecamatan": "Palu Timur",
                "kelurahan": "Besusu Timur",
                "kodepos": "94111"
            },
            {
                "kecamatan": "Palu Timur",
                "kelurahan": "Besusu Barat",
                "kodepos": "94111"
            }
        ],
        "k383": [
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Watunohu",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Watumotaha",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Tambuha",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Tadoumera (Tadaumera)",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Sapoiha",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Sorona (Sarona)",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Samaturu",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Puurau",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Paruttelang",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Nyule",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Lawolatu",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Ngapa",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Watunohu",
                "kelurahan": "Lahabaru",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Lapai",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Koreiha",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Ngapa",
                "kelurahan": "Beringin",
                "kodepos": "93958"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Tanggeau",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Tiwu",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Watumea",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Tahibua",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Meeto",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Sawanggaoha",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Mattiro Bulu",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Mala-Mala",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Lapolu",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Tiwu",
                "kelurahan": "Lawadia",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Koroha",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Lametuna",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Kalu-Kaluku",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Kamisi",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Jabal Kubis",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Jabal Nur",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Kodeoha",
                "kelurahan": "Awo",
                "kodepos": "93957"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Wawo",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Woise",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Woitombo",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Ulu Wawo",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Walasiho",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Tinukari",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Torotuo (Torotua)",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Salurengko",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Tebagiano",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Raoda",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Rante Baru",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Rante Angin",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Pumbolo",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Maroko",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Pohu",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Lawekara",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Wawo",
                "kelurahan": "Latawe",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Lapasi-Pasi",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Latawaro",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Ranteangin",
                "kelurahan": "Landolia",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Lombai (Lambai)",
                "kelurahan": "Lambai",
                "kodepos": "93956"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Tobela",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Tolala",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Tinuna",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Tangga Ruru",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Tetebawo",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Seuwwa",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Sarowali",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Sarambu",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Puncak Harapan",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Ponggi",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Porehu",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Patikala",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Parung Lampe",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Nimbuneha",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Padaelo",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Makkuaseng",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Mataiwoi",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Mosiku",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Loka",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Lengkong Batu",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Lelewulu",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Lelewawo",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Lawaki Jaya",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Latowu",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Lalume",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Larui",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Delang Delang",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Kalahunde",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Bukit Baru",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Batu Putih",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Batuapi",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Tolala",
                "kelurahan": "Bahari",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Porehu",
                "kelurahan": "Bangsala",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Alipato",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Batu Putih",
                "kelurahan": "Ainani Tajriani",
                "kodepos": "93955"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Toaha",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "To&#8217;lemo",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Teposua",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Tarengga",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Salutotong",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Sipakainge",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Puundoho",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Powalaa",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Saludongka",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Pasampang",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Pakue",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Olo Oloho (Olaha)",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Mataleuno",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Mikuasi",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Lawata",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Majapahit",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Lanipa",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Latali",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Lalombundi",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Tengah",
                "kelurahan": "Labipi",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Kondara",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Kosali",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Kalo",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue",
                "kelurahan": "Kasumeeto",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Pakue Utara",
                "kelurahan": "Amowe (Amoe)",
                "kodepos": "93954"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Ujung Tobaku (Tobako)",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Watuliu (Watuliwu)",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Tojabi",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Totallang",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Sulaho",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Pucak Monapa",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Rante Limbung",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Patowonua",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Pitulua Dua (II)",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Lanipa-Nipa",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Lambuno",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Batu Ganda Permai",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Babussalam",
                "kodepos": "93916"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Pitulua",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Simbula",
                "kodepos": "93914"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Katoi",
                "kodepos": "93913"
            },
            {
                "kecamatan": "Katoi",
                "kelurahan": "Maruge",
                "kodepos": "93914"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Ponggiha",
                "kodepos": "93912"
            },
            {
                "kecamatan": "Lasusua",
                "kelurahan": "Lasusua",
                "kodepos": "93911"
            }
        ],
        "k384": [
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Wowa Tamboli",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Tosiba",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Ulu Konaweha",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Tamboli",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Tonganapo",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Puu Tamboli",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Sani Sani",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Lawulo",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Malaha",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Lambolemo",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Latuo",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Kaloloa",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Konaweha",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Amomutu",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Samaturu",
                "kelurahan": "Awa",
                "kodepos": "93915"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Uete",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Undolo",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Wesinggote",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Tongauna",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Ueesi",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Tawanga",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Tondowatu",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Sanggona",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Pehanggo",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Pora Bua",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Alaaha",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Liku Walanapo",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Uluiwoi",
                "kelurahan": "Ahilulu",
                "kodepos": "93575"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Wonuambuteo",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Wunggoloko/Wunggololeo",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Welala",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Wia-Wia",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Wande",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Tokai",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Tinete",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Taosu",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Raraa",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Taore",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Putemata",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Pomburea",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Pombeyoha",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Polenga Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Poli Polia",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Polemaju Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Penanggo Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Penanggoosi",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Pangi-Pangi",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Pekorea",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Lowa",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Mokupa",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Lembah Subur",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Lambandia",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Lere Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Lalowosula/Lalowosuka",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Lalolere",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Ladongi (Jaya)",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Iwoimenggura",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Inotu",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Iwoimea Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Gunung Jaya",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Bou",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Dangia",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Atolanu",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Awiu",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Anggaloosi",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Ladongi",
                "kelurahan": "Atula",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Poli Polia",
                "kelurahan": "Andowengga",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Aere",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Lambandia (Lambadia)",
                "kelurahan": "Aladadio",
                "kodepos": "93573"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Woiha",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Lalolae",
                "kelurahan": "Wesalo",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Tumbudadio",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Teposua",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Tirawuta",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Tasaeha/Tasahea",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Tawainalu",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Lalolae",
                "kelurahan": "Talodo",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Simbune",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Simbalai/Simbalae",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Rate-Rate",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Roko Roko",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Loea",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Poni Poniki",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Lara",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Peatoa",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Lalowura",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Orawa",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Lamoare",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Mataiwoi",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Lalolae",
                "kelurahan": "Lalolae",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Loka",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Lalolae",
                "kelurahan": "Lalosingi",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Tirawuta",
                "kelurahan": "Lalingato",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Loea",
                "kelurahan": "Iwoikondo",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Lalolae",
                "kelurahan": "Keisio",
                "kodepos": "93572"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Woitombo",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Watupute",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Weamo",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Ulu Mowewe",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Tinondo",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Solewatu",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Talata",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Tawarombadaka",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Nelombu",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Puosu",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Lamunde",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Lapangisi",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Horodopi",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Mowewe",
                "kelurahan": "Inebenggi",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Ameroro",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Wulonggere",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tinondo",
                "kelurahan": "Ambapa",
                "kodepos": "93571"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Wowoli",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Wolulu",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Tondowolio",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Watubanggo",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Toari",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Tanggetada",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Tanggeau",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Sumber Rezeki",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Tandebura (Tandai Bura)",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Rano jaya",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Ranomentaa",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Puundaipa",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Rahanggada",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Puudongi",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Powisoa Jaya (Pewisoa Jaya)",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Pondouwae",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Popalia",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Polinggona",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Polenga (Tirawuta)",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Polinggona",
                "kelurahan": "Plasma Jaya",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Petudua",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Peoho",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Palewai",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Oneeha",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Mata Osu",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Lamunde",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Longgosipi",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Lalonggolosua",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Lamedai",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Anawua",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Lamoiko",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Tanggetada",
                "kelurahan": "Anaiwoi",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Toari",
                "kelurahan": "Lakito",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Tambea",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Kukutio",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Tonggoni",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Gunung Sari",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Totobo",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Watumbangga (Watubanggo)",
                "kelurahan": "Kastura",
                "kodepos": "93563"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Pomalaa",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Sopura",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Pelambua",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Tikonu",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Pesouha",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Puundoho",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Kumoro",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Puuroda",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Oko Oko",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Puulemo",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Dawi-Dawi",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Puubenua",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Hakatutobu",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Puubunga",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Pomalaa",
                "kelurahan": "Huko Huko",
                "kodepos": "93562"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Ngapa",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Wundulako",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Pewutaa",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Towua I",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Lamekongga",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Una Mendaa",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Longori / Langori",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Tamborasi",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Watalara",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Bende",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lasiroku",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Tikonu",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Kowioha",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Muara Lapao Pao",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "Sabiano",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Baula",
                "kelurahan": "Baula",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Langgomali",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wundulako",
                "kelurahan": "19 Nopember",
                "kodepos": "93561"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lapao-Pao",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Ulu Wolo",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lalonggopi",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Wolo",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lambopini",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Tolowe Pondre",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lana",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Ulu Kalo",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Ladahai",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Ulu Lapao-Pao",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Lalonaha",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Samaenre",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Iwoimenda",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Iwoimopuro",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Wolo",
                "kelurahan": "Donggala",
                "kodepos": "93552"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Lalombaa",
                "kodepos": "93518"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Sabilambo",
                "kodepos": "93519"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Kolakaasih",
                "kodepos": "93512"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Sea",
                "kodepos": "93514"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Mangolo",
                "kodepos": "93512"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Watuliandu",
                "kodepos": "93515"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Ulunggolaka",
                "kodepos": "93512"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Balandete",
                "kodepos": "93517"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Induha",
                "kodepos": "93512"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Latambaga",
                "kodepos": "93514"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Tahoa",
                "kodepos": "93511"
            },
            {
                "kecamatan": "Latambaga",
                "kelurahan": "Sakuli",
                "kodepos": "93514"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Laloeha",
                "kodepos": "93511"
            },
            {
                "kecamatan": "Kolaka",
                "kelurahan": "Lamokato",
                "kodepos": "93511"
            }
        ],
        "k385": [
            {
                "kecamatan": "Laonti",
                "kelurahan": "Wandaeha",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Watundolu (Batu Jaya)",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Woru Woru",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Tue Tue",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Ulu Sawa",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Sangi-Sangi",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Tambeanga",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Tambolosu",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Puudirangga (Puundirangga)",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Rumbia Rumbia",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Namu",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Peo Indah",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Lawisata",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Malaringgi",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Mata Bondu",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Labotaone",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Labuan Beropa",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Laonti",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Cempedak",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Laonti",
                "kelurahan": "Kondono",
                "kodepos": "93892"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Wonua Jaya",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Wawatu",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Wawondengi",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Wawosunggu",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Ulusena",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Watu Porambaa",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Tanjung Tiram",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Tambosupa",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Selabangga",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Sumber Sari",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Ranooha Raya",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Sanggula",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Pudaria Jaya",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Puasana",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Moramo",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Penambea Barata",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Mekar Jaya",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Mata Lamokula",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Mata Watu-Watu",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Lapuko",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Lombueya (Lombuea)",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Marga Cinta",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Landipo",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Lamokula",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Lamboo",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo Utara",
                "kelurahan": "Lalowaru",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Bisikori",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Lakomea",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Amohola",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Moramo",
                "kelurahan": "Bakutaru",
                "kodepos": "93891"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Wundumbolo",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Torokeku",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Wadanggo",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Watumelewe",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Tombeleu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Tinanggea",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Teteinea Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Telutu Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Tatangge",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Suka Mukti",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Sumber Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Roraya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Puunangga",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Puurema Subur",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Panggoosi",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Potuho Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Palo Tawo",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Padaleu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Moolo Indah",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Ngapaah (Ngapaaha)",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Mokupa Jaya (Makupa Jaya)",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Mondoke (Mandoke)",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Merongga Raya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Matandahi",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lapulu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lasuai",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Matambawi",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lapoa",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Lambodi Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lanowulu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Lambandia",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Lalouesamba",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lalo Watu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Lalonggasu",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Lalembu Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Bungin Permai",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Kapuwila",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Bomba-bomba",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Atari Jaya",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Lalembuu / Lalumbuu",
                "kelurahan": "Atari Indah",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Akuni",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Tinanggea",
                "kelurahan": "Asingi",
                "kodepos": "93885"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Andolo",
                "kodepos": "93884"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Wonua/Wanua Raya",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Wawouru",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Waworaha",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Wawonggura",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Wawo Wonua",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Watumerembe",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Watarapa (Waturapa)",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Watumbohoti",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Watudemba",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Ululakara",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Tolihe",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Sangi-Sangi",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Sambahule",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Parasi",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Onembute",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Palangga",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Mondoe",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Mekar Sari",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Mekar Jaya",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Lalowua",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Matabubu",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Lakara",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Kiaea",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Koeono",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Eewa",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Kapu Jaya",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Aosole",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Baito",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Anggondara",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Amosara (Amasara)",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga Selatan",
                "kelurahan": "Amondo (Amonda)",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Ahuangguluri",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Palangga",
                "kelurahan": "Alakaya",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Baito",
                "kelurahan": "Ahuangguluri",
                "kodepos": "93883"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Watumeeto",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Wonua Kongga",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Punggaluku",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Rambu-Rambu",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Torobulu",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Pangan Jaya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Polewali",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Pamandati",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Ombu-Ombu Jaya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Molinese",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Matabubu Jaya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Lambakara",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Lamong Jaya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Lerepako",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Lainea",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Lalonggombu",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Laeya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Kalo-kalo",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Labokeo",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Bangun Jaya",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Kaindi",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Baito",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Lainea",
                "kelurahan": "Aoreo",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Ambesea",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Anduna",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Anggoroboti",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Ambalodangge",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Ambakumina (Ambukumina)",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Laeya",
                "kelurahan": "Aepodu",
                "kodepos": "93881"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Tetehaka",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Uelawa",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Unggulino",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Teteasa",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Teporombua",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Simbangu",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Tambekuku (Tombekuku)",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Sandarsi Jaya",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Sandey",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Puuwehuko",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Puusanggula",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Puungoni",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Puuroe",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Puulipu",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Puosu (Puuosu)",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Puunggawu Kawu (Punggawukawu)",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Puao",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Pudambu",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Polo-Pololi",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Pewutaa",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Motaha",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Palawewu (Palowewu)",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Mokalelelo",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Mokoau",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Lipu Masagena",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Matabondu",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Mataiwoi",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Langgea Indah",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Lere",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Lamoeri",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Lamooso",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Landabaro",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Lamoen",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Lambandia",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Kosambi",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Lamara",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Kasebo",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Iwoi Mendoro",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Horodopi",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Boloso",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Epeesi",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Benua Utama",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Basala",
                "kelurahan": "Basala",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Benua",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Benua",
                "kelurahan": "Awalo",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Aopa",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Angata",
                "kelurahan": "Angata",
                "kodepos": "93875"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Wonua",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Tanea",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Ranowila",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Wolasi",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Puosu Jaya",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Ranowila",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Morome (Morame)",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Pambulaa Jaya",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Leleka",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Matawolasi",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Masagena",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Lebo Jaya",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Lamomea",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Lawoila",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Konda",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Lalowiu",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Lambusa",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Cialam Jaya",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Aunupe",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Amoito Jaya",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Aonupe",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Wolasi",
                "kelurahan": "Aoma",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Amohalo",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Alebo",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Konda",
                "kelurahan": "Ambololi",
                "kodepos": "93874"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Wuura",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Wonua Sangia",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Wonukao",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Wonua Sari",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Wawobende",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Wonua Monapa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Watu-Watu",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Wanua Kongga",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Ulusabulakoa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Tridana Mulia",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Tetesingi",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Toluwonua",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Talumbinga",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Tetenggabo",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Rano Mbayasa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Sabulakoa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Rakawuta",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Rano Aopa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Punggulahi",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Puwehuko",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Pudahoa (Pudohoa)",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Mulya Sari (Muliasari)",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Monapa",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Morini Mulya",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Mowila",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Mataiwoi",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Matabenua / Watawunua",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Landono",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Landono Dua",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Lamelori (Lamolori)",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Lamebara (Lamibara)",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Lalosingi",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Lakomea",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Lalonggapu",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Koronua",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Mowila",
                "kelurahan": "Kondoano",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Asaria",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Endanga",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Amotowo",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Arongo",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Landono",
                "kelurahan": "Abenggi",
                "kodepos": "93873"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Sindang Kasih",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Tunduno",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Ranomeeto",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Ranooha",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Onewila",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Rambu-Rambu Jaya",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Opaasi",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Langgea",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Laikandonga",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Lameruru (Lameuru)",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Jati Bali",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Kota Bangun",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Laikaha",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Boro-Boro R",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Duduria",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Amokuni",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Boro-Boro Lameuru",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Amoito",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Amoito Siama",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto",
                "kelurahan": "Ambaipua",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Ranomeeto Barat",
                "kelurahan": "Abeko",
                "kodepos": "93871"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Watu Mokala",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Wunduwatu",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Punggapu",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Puundoho",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Raha Menda Jaya",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Mataiwoi",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Lalobao",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Bima Maroa",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Bumi Raya",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Bekenggasu Indah",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Ataku",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Asembu Mulya",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Adayu Indah",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Anggokoti",
                "kodepos": "93819"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Wawobende",
                "kodepos": "93818"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Mate Upe",
                "kodepos": "93818"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Lapoa Indah",
                "kodepos": "93818"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Awolo",
                "kodepos": "93816"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Wulele Jaya",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Adaka Jaya",
                "kodepos": "93815"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Wanua Maroa",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Tirto/Tirta Wartani",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Silea Jaya",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Tetenggolasa",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Ranooha Lestari",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Adayu Indah",
                "kodepos": "93814"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Papawu",
                "kodepos": "93813"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Anese",
                "kodepos": "93813"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Lalonggombu",
                "kodepos": "93813"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Andolo Utama",
                "kodepos": "93813"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Pelandia",
                "kodepos": "93812"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Puudaria Jaya",
                "kodepos": "93812"
            },
            {
                "kecamatan": "Buke",
                "kelurahan": "Buke",
                "kodepos": "93812"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Alengge Agung",
                "kodepos": "93811"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Potoro",
                "kodepos": "93811"
            },
            {
                "kecamatan": "Andoolo",
                "kelurahan": "Alangga",
                "kodepos": "93811"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Ulusena Jaya",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Wawoosu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Waworano",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Torodale",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Tumbu-Tumbu Jaya",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Ulunese",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Silea",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Tiraosu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Rumba-Rumba",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Sarandua",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Sawah",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Rambu-Rambu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Roda",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Ngapawali",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Puudongi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Puupi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Matandahi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Meletumbu (Meletumbo)",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Mondoe Jaya",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Langgowala",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Mataiwoi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Lamotau",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Langgapulu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Lambangi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Kolono",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Lamapu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Ampera",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Awunio",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Batu Putih",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Alosi",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Amolengu",
                "kodepos": "93395"
            },
            {
                "kecamatan": "Kolono",
                "kelurahan": "Adinete",
                "kodepos": "93395"
            }
        ],
        "k386": [
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Numana",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Wisata Koto",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Wungka",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mola Utara",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mola Samaturu",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mola Selatan",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mola Bahari",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mola Nelayan Bakti/Bhakti",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mandati III",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Matahora (Metohora)",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Liyamawi",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mandati I",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Mandati II",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Liya One Melangka (Liya Onemaelangka)",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Liya Togo",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Kapota Utara",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Komala",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Kapota",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Kabita Togo",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Waloindi",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Wangi-Wangi Selatan",
                "kelurahan": "Kabita",
                "kodepos": "93795"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Togo Binongko",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Wali",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Sowa",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Taipabu",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Rukuwa",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Popalia",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Palahidu",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Palahidu Barat",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Makoro",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Oihu",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Jaya Makmur",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Kampo-Kampo (Kompo-Kompo)",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Binongko",
                "kelurahan": "Lagongga (Longongga)",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Togo Binongko",
                "kelurahan": "Haka",
                "kodepos": "93794"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Waitii Barat",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Wawotimu",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Waha",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Waitii",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Tongano Barat",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Tongano Timur",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Teemoane",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Timu",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Runduma",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Rukuwa",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Patua Dua (II)",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Patipelong",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Patua",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Palahidu Barat",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Onemay",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Lamanggau",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Kulati (Kulate)",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia",
                "kelurahan": "Kollo Soha",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Dete",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Kahiyanga (Kahianga)",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Tomia Timur",
                "kelurahan": "Bahari",
                "kodepos": "93793"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Waduri",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Tanomeha",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Tanjung",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Sombano",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Sandi",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Tampara",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Samabahari",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Peropa",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Ollo",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Ollo Selatan",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Pajam",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Mantigola (Mantingola)",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Lentea",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Laulua (Laolua)",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Lefuto (Lewuto)",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Lagiwae",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Langge",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Kasuwari (Kasuari/Kaswari)",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Horuo (Haruo)",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Kalimas",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa Selatan",
                "kelurahan": "Darawa",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Balasuna Selatan",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Buranga",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Ambeua Raya",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Balasuna",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Wapia-pia",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Kaledupa",
                "kelurahan": "Ambeua",
                "kodepos": "93792"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Wandoka Selatan",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Wandoka Utara",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Waha",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Wanci",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Wandoka",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Waetuno",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Waginopo",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Tindoi Timur",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Waelumu",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Tindoi",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Posalu",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Sombu",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Pongo",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Pookambua",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Maleko",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Pada Raya Makmur",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Patuno",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Koroe Onawe",
                "kodepos": "93791"
            },
            {
                "kecamatan": "Wangi-Wangi",
                "kelurahan": "Longa",
                "kodepos": "93791"
            }
        ],
        "k387": [
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Tangkeno",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Tapuhaka",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Lengora Selatan",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Lengora",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Lengora Pantai",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Toli-Toli",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Lambale",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Wumbubura",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Lamonggi",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Ulungkura",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Tengah",
                "kelurahan": "Enano",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Dongkala",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Balo",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Timur",
                "kelurahan": "Bungi-bungi",
                "kodepos": "93783"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Wumbulasa",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena",
                "kelurahan": "Teomokole",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena",
                "kelurahan": "Tirongkotua",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Tedubara",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Barat",
                "kelurahan": "Rahantari",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Barat",
                "kelurahan": "Sikeli",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Sangia Makmur",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena",
                "kelurahan": "Rahadopi",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena",
                "kelurahan": "Rahampuu",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Selatan",
                "kelurahan": "Puu Nunu",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Selatan",
                "kelurahan": "Pongkalaero",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Mapila",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Larolanu (Laromanu)",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Selatan",
                "kelurahan": "Langkema",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Utara",
                "kelurahan": "Eemokolo (Eeomokola)",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Selatan",
                "kelurahan": "Batuawu",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Barat",
                "kelurahan": "Baliara Kepulauan",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Kabaena Barat",
                "kelurahan": "Baliara Selatan",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Wumbu Bangka",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Kabaena Barat",
                "kelurahan": "Baliara",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Wia-Wia",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Watu-Watu",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Watu Kalangkari",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Watu Mentade",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Tunas Baru",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Totole",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Tinabite",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Tembe",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Rau-Rau",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Taubonto",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Pasare Apua",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Rarongkeu",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Rarowatu",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Morengke",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Pangkuri",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Lomba Kasih",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Marga Jaya",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Lantari",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Lantowua",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Langkadue",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Langkowala",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Lamuru",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Lampeantani",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Lamuru",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu",
                "kelurahan": "Ladumpi",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Mata Usu",
                "kelurahan": "Kolombi Matausu",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Kalaero",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Hukaea",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Lentarai Jaya S. (Lantari Jaya)",
                "kelurahan": "Anugerah",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Rarowatu Utara",
                "kelurahan": "Aneka Marga",
                "kodepos": "93774"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Wambarema",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Selatan",
                "kelurahan": "Waemputang",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Toburi",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Tenggara",
                "kelurahan": "Terapung",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Timur",
                "kelurahan": "Teppoe",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Rompu Rompu",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Tampawulu (Tanpabulu)",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Tanah Poleang",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Pulau Ea (Es)",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Tenggara",
                "kelurahan": "Marampuka",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Timur",
                "kelurahan": "Puu Lemo",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Timur",
                "kelurahan": "Mambo",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Tenggara",
                "kelurahan": "Larete",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Lawatu Ea (Es)",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Tenggara",
                "kelurahan": "Lamoare",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Selatan",
                "kelurahan": "La Ea",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Utara",
                "kelurahan": "Karya Baru",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Selatan",
                "kelurahan": "Kali Baru",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Timur",
                "kelurahan": "Biru",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Selatan",
                "kelurahan": "Batu Putih",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Timur",
                "kelurahan": "Bambaea",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Poleang Selatan",
                "kelurahan": "Akacipong",
                "kodepos": "93773"
            },
            {
                "kecamatan": "Tontonunu (Tontonuwu)",
                "kelurahan": "Watu Melomba",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Tontonunu (Tontonuwu)",
                "kelurahan": "Tongkoseng",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Tontonunu (Tontonuwu)",
                "kelurahan": "Tontonunu",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Timbala",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Toari Buton",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Salosa",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Tontonunu (Tontonuwu)",
                "kelurahan": "Tete Haka",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Rakadua",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Rano Komea",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Tontonunu (Tontonuwu)",
                "kelurahan": "Puu Wonua",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Tengah",
                "kelurahan": "Paria",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Tengah",
                "kelurahan": "Poleondro",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Pukurumba (Pokorumba)",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Pabiring",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Pallimae",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Tengah",
                "kelurahan": "Mulaeno",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Matiro Walie",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Tengah",
                "kelurahan": "Lebo Ea",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Kasabolo",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Kastarib",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Lameong-Meong",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Boepinang Barat",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Bulumanai",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Barangga",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Boeara",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang",
                "kelurahan": "Boepinang",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Balasari",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Toli-Toli",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Poleang Barat",
                "kelurahan": "Baba Molingku",
                "kodepos": "93772"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Tajuncu",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Tajuncu",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Pulau Tambako",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia Tengah",
                "kelurahan": "Poea",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Pemontoro (Pomontoro)",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Kepulauan Masaloka Raya",
                "kelurahan": "Masaloka Selatan",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Kepulauan Masaloka Raya",
                "kelurahan": "Masaloka Timur",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Mawar",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Kepulauan Masaloka Raya",
                "kelurahan": "Masaloka",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Kepulauan Masaloka Raya",
                "kelurahan": "Masaloka Barat (Tengah)",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Lora",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia Tengah",
                "kelurahan": "Lauru",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Liano",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Lampopala",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Lanto Wonua (Lantawonua)",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia Tengah",
                "kelurahan": "Lampata",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Lameroro",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Laloa",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Kasipute",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia Tengah",
                "kelurahan": "Kampung Baru",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Rumbia",
                "kelurahan": "Doule",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Hambawa Indah",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Mata Oleo",
                "kelurahan": "Batusame Indah",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Kepulauan Masaloka Raya",
                "kelurahan": "Batu Lamburi",
                "kodepos": "93771"
            }
        ],
        "k388": [
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Wulu (Wuluh)",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Telaga/Talaga I",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Telaga/Talaga II",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Telaga/Talaga Besar",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Pangilia",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Liwu Lompona",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Talaga Raya (Telaga Raya)",
                "kelurahan": "Kokoe",
                "kodepos": "93781"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Wongko Lakudo",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Wajogu",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Wanepa Nepa",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Teluklasongko",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Waara",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Mone",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Nepa Mekar",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "One Waara",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Metere",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Moko",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Lolibu",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Madongka",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Matawine",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Boneoge",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Lakudo",
                "kelurahan": "Lakudo",
                "kodepos": "93763"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Watorambe Bata",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Watolo (Watalo)",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Watorambe",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Wasilomata I",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Wasilomata II",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Wambuloli",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Wantopi",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Terapung",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Wakambangura",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Wakambangura II",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Polindu",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Tanailandu",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Napa",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Ongkolaki (Oengkolaki)",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Morikana",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Mawasangka",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Matara (Matara)",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Lasori",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Lanto",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Lantongau",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Lakuroa (Lakorua)",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Lalibo",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Langkomu",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Katukobari",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Lagili",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Kanapa-Napa",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Kance Bungi",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Tengah",
                "kelurahan": "Gundu Gundu",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Inulu",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Gumamano",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Dariango",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Bone Marambe",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Bungi",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka Timur",
                "kelurahan": "Batubanawa",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Balobone",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Banga",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Mawasangka",
                "kelurahan": "Air Bajo",
                "kodepos": "93762"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Watulea",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Walando",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Waliko",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Wadiabero",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Wakea Kea",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Rahia",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Tolandona",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Tolandona Matanaeo",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Kolowa",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Lakapera",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Lowu-Lowu",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Doda Bahari",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Kamama Mekar",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Baruta Analaki",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Baruta Lestari",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Bombonawulu",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Sangia Mambulu",
                "kelurahan": "Baruta",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Gu",
                "kelurahan": "Bantea",
                "kodepos": "93761"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Wasambaa",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Wasuamba",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Wajahjaya",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Waoleona",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Walompo",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Umalaoge",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Wagari",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Talaga Baru",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Togo Mangura",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Sumber Agung",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Suandala",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Sumber Sari",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Siomanuru",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Sribatara",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Siontapina (Siotapina)",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Sampuabalo",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Sangia Arano",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Rejo Sari",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Nambo",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Megabahari",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Mopaano/Mopano",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Mulia Jaya",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Matanauwe",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Manuru",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Lawele",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Lasalimu",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Labuandiri",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Lasembangi",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Kumbewaha",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Kuraa",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Karya Jaya",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Kinapani Makmur",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Kakenauwe",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Kamaru",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Harapan Jaya",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Bonelalo",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Gunung Jaya",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu",
                "kelurahan": "Benteng",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Siontapia (Siontapina)",
                "kelurahan": "Bahari Makmur",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Balimu",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Ambuau Togo",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Lasalimu Selatan",
                "kelurahan": "Ambuau Indah",
                "kodepos": "93756"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Watumotobe",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Wambulu",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Waondo Wolio",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Wakuli",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Wakangka",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Tumada",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Wakalambe",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Todanga",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Tuangila",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Mambulugo",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Lambusango",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Lambusango Timur",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Kamelanta",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Boneatiro Barat",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Bukit Asri",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Boneatiro",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Kapontori",
                "kelurahan": "Barangka",
                "kodepos": "93755"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Wolowa Baru",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Wolowa",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Winning",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Wasuemba",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Wasampela",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Wasaga",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Warinta",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Wagola",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Wangu Angu",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Wabula Satu",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Takimpo",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Wabula",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Sukamaju",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Saragi",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Sukamaju",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Mantowu",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Matawia",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Laburunci",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Lapodi",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kondowa",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Koholimombona",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kombeli",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Kaumbu",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kancinaa",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kaongkeongkea",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kahulungaya",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kambulabulana",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Holimombo Jaya",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Kabawakole",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Galanti",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Holimombo",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wolowa",
                "kelurahan": "Bungi",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Dongkala",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Banabungi",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Wabula",
                "kelurahan": "Bajo Bahari",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Pasar Wajo",
                "kelurahan": "Awainulu",
                "kodepos": "93754"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Wawoangi",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Windu Makmur",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Watiginanda",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Wacuala",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Wambongi",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Todombulu",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Tolando Jaya",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Tira",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Taduasa",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Lapandewa Makmur",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Lipumangau",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Sandang Pangan",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Lapandewa Kaidea",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Lapandewa Jaya",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Katilombu",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Lapandewa",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Hendea",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Jaya Bakti",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Gayabaru",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Gerak Makmur",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Gunung Sejuk",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Burangasi",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Lapandewa",
                "kelurahan": "Burangasi Rumbia",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Batuatas Timur",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Batu Atas Liwu",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Batu Atas",
                "kelurahan": "Batuatas Barat",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Bangun",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Bahari Dua",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Bahari Tiga",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Watuampara",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Sampolawa",
                "kelurahan": "Bahari",
                "kodepos": "93753"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Waonu",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Wakinamboro",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Uwemaasi",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Waindawula",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Pogalampa (Poogalampa)",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Tongali",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Molona",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Nggulanggula",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Molagina",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Mbanua",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Mokobeau",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Masiri",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Mawambunga",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Majapahit",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Marawali",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Lawela Selatan",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Lipu",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Lawela",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Lapanairi (Lampanairi)",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Lapara",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Laompo",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Lamaninggara",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Lantoi (Lontoi)",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Lalole",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Lakambau",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Katampe",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Karae",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Kapoa",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Kapoa Barat",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu Barat",
                "kelurahan": "Kamoali",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Kaofe",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Kaimbulawa",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Busoa",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Biwinapada",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Bola",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Siompu",
                "kelurahan": "Batuawu",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Batauga",
                "kelurahan": "Bandar Batauga",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Banabungi",
                "kodepos": "93752"
            },
            {
                "kecamatan": "Kadatua",
                "kelurahan": "Banabungi Selatan",
                "kodepos": "93752"
            }
        ],
        "k389": [
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Rumbia",
                "kodepos": "93771"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Wonua/Wanua Morome",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Wawosanggula",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Waworaha",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Watundehoa (Watandehoa)",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Watusa",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Watarema",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Wanuahoa",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Unggulino",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Ulumeraka",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Ulu Meraka (Sukamaju)",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Ulu Onembute",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Uepai",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Trimulya",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Uepai",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Tetehaka",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Tetembomua",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Tawapandere",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Tawarotebota",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Tanggobu",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Tawamelewe",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Sonai",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Rawua",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Silea",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Puuhopa",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Puusangi",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Puriala",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Nopoosi",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Onembute",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Meraka",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Mokaleleo",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Matahoalu",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Mataiwoi",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Lambuya",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Lambuya",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Lalonggatu",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Laloonaha",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Kumapo",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Onembute",
                "kelurahan": "Kasumeia",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Baruga",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Awuliti (Wawuliti)",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Anggopiu",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Asaki (Isaki)",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Ameroro",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Lambuya",
                "kelurahan": "Amberi",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Amaroa",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Uepai (Uwepai)",
                "kelurahan": "Alo-Oloho",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Ahuawali",
                "kodepos": "93464"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Wonua Monapa",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Wonggeduku",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Wawoone",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Wawosolo",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Wawolemo",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Wawonggole",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Watulawu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Wawohine",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Ulu Benua",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Tomulipu / Wakusao",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Tirawuta",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Tetemotaha",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Teteona",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Puumbinisi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Tawarolondo",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Puasana",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Puday",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Puuduria",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Mumundowu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Pondidaha",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Mata Iwoi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Mendikonu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Mata Bura",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Masoose",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lamokuni",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Langgonawe",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lanonggasai",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lalousu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lambangi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lalohao",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Lalonona",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Lahonggumbi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Lahututu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Hongoa",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Kasumokuni",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Duriaasi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Dunggua",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Bendewuta",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Dawi-dawi",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Benua",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Belatu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Wonggeduku",
                "kelurahan": "Baruga",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Amonggedo",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Amonggedo Baru",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Anahinunu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Ambulanu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Amesiu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Amonggedo",
                "kelurahan": "Amandete",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Pondidaha",
                "kelurahan": "Ahuawatu",
                "kodepos": "93463"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Wonua Mbae",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Wawotobi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Woerahi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Waworaha",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Waworoda Jaya",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Uelawu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Uete",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Wawolatoma",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Tudaone",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Tudameaso",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Titioa",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Tongauna",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Tawanga",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Sendang Mulya Sari",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Sanuanggamo",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Sanggona",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Puusinauwi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Puusinauwi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Puosu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Puundopi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Pinole",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Olua Ao",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Palarahi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Nohu Nohu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Nesowi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Nario Indah",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Mokowu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Momea",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Meluhu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Mekar Sari",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Mata Iwoi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Latoma Jaya",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Larowiu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Lamelay",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Lalowata",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Lalosabila",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Lalonggowuna",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Kulahi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Konawe",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Korumba",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Kasupute",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Karandu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Inalahi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Inolobu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Hudoa",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Hopa-Hopa",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Bungguosu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Bose Bose",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Barowila",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Konawe",
                "kelurahan": "Asinowowo",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Asao",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Anggotoa",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Anggohu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Andoluto",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Andalambe",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Andeposunde",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Wawotobi",
                "kelurahan": "Analahumbuti",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Amboniki",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Tongauna",
                "kelurahan": "Ambopi",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Latoma",
                "kelurahan": "Ambekaeri Utama",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Walay",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Meluhu",
                "kelurahan": "Ahuhu",
                "kodepos": "93461"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Sambeani",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Sambaosu",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Padangguni",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Padangguni Utama",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Punggaluku",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Padang Mekar",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Nekudu",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Mekarjaya",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Lasada",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Matanggorai",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Kumapo",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Langgea",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Garuda",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Kasuwura Indah",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Epeeya",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Awua Sari",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Awua Jaya",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Atodopoi",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Asipako",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Asolu",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Asinua Jaya",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Arubia",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Angohi",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Asinua",
                "kelurahan": "Ambodiaa",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Abuki",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Aleuti",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Abuki",
                "kelurahan": "Alosika",
                "kodepos": "93452"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Lawulo",
                "kodepos": "93419"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Unaaha",
                "kodepos": "93418"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Andabia",
                "kodepos": "93419"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Unaasi",
                "kodepos": "93417"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Anggaberi",
                "kodepos": "93418"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Parauna",
                "kodepos": "93417"
            },
            {
                "kecamatan": "Anggaberi",
                "kelurahan": "Toriki",
                "kodepos": "93417"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Wawonggole",
                "kodepos": "93416"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Inolobunggadue",
                "kodepos": "93416"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Tobeu",
                "kodepos": "93416"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Tuoy",
                "kodepos": "93415"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Asambu",
                "kodepos": "93416"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Asinua",
                "kodepos": "93416"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Latoma",
                "kodepos": "93414"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Arombu",
                "kodepos": "93412"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Ambekairi",
                "kodepos": "93413"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Puunaaha",
                "kodepos": "93411"
            },
            {
                "kecamatan": "Unaaha",
                "kelurahan": "Tumpas",
                "kodepos": "93411"
            },
            {
                "kecamatan": "Puriala",
                "kelurahan": "Poanaha (Puanaha)",
                "kodepos": "93411"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Wunse Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Wungkolo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Wawosou",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Wawouso Baru",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Wawoone",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Waworope",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Wawolaa",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Wawoea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Wawobili",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Wawobeau",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Watuonde",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Wakadawu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Wawo Indah",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Tumbu Tumbu Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Tumburano",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Teparoko",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Tondonggito",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Tangkombuno",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Tekonea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Tambaone",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Tambaone Utama",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Sinar Masolo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Sukarela Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Sinaalu Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Sawaea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Sawapatani",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Sainoa Indah",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Sainoa Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Rawa Indah",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Roko Roko",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Puuwatu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Puurau",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Polara",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Palingi Barat",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Patande",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Palingi",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Nambo Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Nambo Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Nanga",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Munse",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Munse Indah",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Mekar Sari",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Morobea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Mataiwoi",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Mawa",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Matabubu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Mata Langgara",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Matabubu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Mata Dimba",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Mata Buranga",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Mata Baho",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Masolo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Laywo Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Lebo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Lawey",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur",
                "kelurahan": "Lapulu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Langara Laut",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Lansilowo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Langara Indah",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Langara Iwawo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Langara Bajo",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Lamongupa",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Lampeapi",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Lamaluo (Lamoluo)",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Ladianta",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Labeau",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Labisa",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Utara",
                "kelurahan": "Dongkalaea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tenggara",
                "kelurahan": "Dompo-dompo Jaya",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Dimba",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Barat",
                "kelurahan": "Bukit Permai",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Bobolio",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Tengah",
                "kelurahan": "Batumea",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Selatan",
                "kelurahan": "Baku-baku",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Bangun Mekar",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Wawonii Timur Laut",
                "kelurahan": "Baho Bubu",
                "kodepos": "93393"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Wonua Morini",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Wawolimbue",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Ulu Lalimbue",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Ulupohara",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Tondowatu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Totambe Jaya",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Tombawatu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Tani Indah",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Tanggobu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Silea",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Tabanggele",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Sambaraasi",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Sampara",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Ranomolua",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Rawua",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Puurui (Puuruy)",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Rambu Kongga",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Puundoho",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Puuloro",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Punggaluku",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Pusangi (Puusangi)",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Pohara",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Polua",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Porara",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Pebunooha Dalam",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Pereoe",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Paku Jaya",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Pebunooha",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Paku",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Muara Sampara",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Onembute",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Mendikonu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Morosi",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Lawonua",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Laosu Jaya",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Lasoso",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Laosu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Laloumera",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Lamendora",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Lalonggombuno",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Lalonggaluku",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Lalimbue",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Lalimbue Jaya",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Labotoy",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Lakomea",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Konggamea",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Labela",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Kapoiala",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Kapoiala (Kapoyala)",
                "kelurahan": "Kapoiala Baru",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Galu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Diolo",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Bondoala",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Besulutu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Bondoala",
                "kelurahan": "Besu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Baini",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Bao-Bao",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Anggalomoare",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Asunde",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Andepali",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Andomesinggo",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Andadowi",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Andaroa",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Besulutu",
                "kelurahan": "Amosilu",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Sampara",
                "kelurahan": "Abeli Sawa (Abelisawah)",
                "kodepos": "93354"
            },
            {
                "kecamatan": "Routa",
                "kelurahan": "Walandawe",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Routa",
                "kelurahan": "Tirawonua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Routa",
                "kelurahan": "Routa",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Routa",
                "kelurahan": "Parudongka",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Wawobungi",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Waworaha",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Watunggarandu",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Toronipa",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Toli Toli",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Toolawawo",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Telaga Biru",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Sorue Jaya",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Tapulaga",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Saponda Laut",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Sawapudo",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Soropia",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Rapambinopaka",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Saponda",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Puuwonua",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Nii Tanasa",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Mekar",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Lolanggasumeeto",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Leppe",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Bumi Indah",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Lalonggasumeeto",
                "kelurahan": "Lalombonda",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Bajoe",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Bokori",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Bajo Indah",
                "kodepos": "93351"
            },
            {
                "kecamatan": "Soropia",
                "kelurahan": "Atowatu",
                "kodepos": "93351"
            }
        ],
        "k390": [
            {
                "kecamatan": "Bungi",
                "kelurahan": "Tampuna",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Bungi",
                "kelurahan": "Walia Buku",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Lea-Lea",
                "kelurahan": "Palabusa",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Bungi",
                "kelurahan": "Ngkaring-Karing (Ngkari-Ngkari)",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Lea-Lea",
                "kelurahan": "Kolese",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Lea-Lea",
                "kelurahan": "Lowu-Lowu",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Bungi",
                "kelurahan": "Lia Buku",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Lea-Lea",
                "kelurahan": "Kantalai",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Bungi",
                "kelurahan": "Kampeonaho",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Lea-Lea",
                "kelurahan": "Kalia Lia",
                "kodepos": "93758"
            },
            {
                "kecamatan": "Sora Walio (Sorowalio)",
                "kelurahan": "Karya Baru",
                "kodepos": "93757"
            },
            {
                "kecamatan": "Sora Walio (Sorowalio)",
                "kelurahan": "Gonda Baru",
                "kodepos": "93757"
            },
            {
                "kecamatan": "Sora Walio (Sorowalio)",
                "kelurahan": "Kaisabu Baru",
                "kodepos": "93757"
            },
            {
                "kecamatan": "Sora Walio (Sorowalio)",
                "kelurahan": "Bugi",
                "kodepos": "93757"
            },
            {
                "kecamatan": "Murhum",
                "kelurahan": "Melai",
                "kodepos": "93727"
            },
            {
                "kecamatan": "Murhum",
                "kelurahan": "Wajo",
                "kodepos": "93726"
            },
            {
                "kecamatan": "Murhum",
                "kelurahan": "Baadia",
                "kodepos": "93727"
            },
            {
                "kecamatan": "Betoambari",
                "kelurahan": "Labalawa",
                "kodepos": "93725"
            },
            {
                "kecamatan": "Murhum",
                "kelurahan": "Lamangga",
                "kodepos": "93725"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Tarafu",
                "kodepos": "93722"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Wameo",
                "kodepos": "93722"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Wameo",
                "kodepos": "93722"
            },
            {
                "kecamatan": "Betoambari",
                "kelurahan": "Waborobo",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Murhum",
                "kelurahan": "Tanganapada",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Betoambari",
                "kelurahan": "Sulaa",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Nganganaumala",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Betoambari",
                "kelurahan": "Lipu",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Kaobula",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Lanto",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Batupoaro",
                "kelurahan": "Bone-Bone",
                "kodepos": "93721"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Waruruma",
                "kodepos": "93719"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Batulo",
                "kodepos": "93717"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Lakologou (Lakologuo)",
                "kodepos": "93719"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Kadolo",
                "kodepos": "93716"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Kadolomoko",
                "kodepos": "93716"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Bataraguru",
                "kodepos": "93714"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Kadolo Katapi",
                "kodepos": "93715"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Wangkanapi",
                "kodepos": "93713"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Tomba",
                "kodepos": "93712"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Wale",
                "kodepos": "93711"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Liwuto",
                "kodepos": "93711"
            },
            {
                "kecamatan": "Kokalukuna",
                "kelurahan": "Sukanayo (Sukanaeyo)",
                "kodepos": "93711"
            },
            {
                "kecamatan": "Wolio",
                "kelurahan": "Bukit Wolio Indah",
                "kodepos": "93711"
            }
        ],
        "k391": [
            {
                "kecamatan": "Wakorumba Selatan",
                "kelurahan": "Wambona",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Wakorumba Selatan",
                "kelurahan": "Pure",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Raimuna",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasi Kolaga",
                "kelurahan": "Tampunabale",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Pohorua",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Batukara",
                "kelurahan": "Moolo",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Oenggumora",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Maligano",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Liwumetingki",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasi Kolaga",
                "kelurahan": "Mata Indaha / Indah",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Lapole",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Latompa",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Batukara",
                "kelurahan": "Lanobake (Laano Bake)",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Maligano",
                "kelurahan": "Langkoroni",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasi Kolaga",
                "kelurahan": "Lambelu",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Labulawa",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasi Kolaga",
                "kelurahan": "Kolese",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Wakorumba Selatan",
                "kelurahan": "Labunia",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Koholifano",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Bumbu",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Pasir Putih",
                "kelurahan": "Kamosope",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Batukara",
                "kelurahan": "Bone Bone",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Batukara",
                "kelurahan": "Baluara",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Wakorumba Selatan",
                "kelurahan": "Bakealu",
                "kodepos": "93674"
            },
            {
                "kecamatan": "Wakorumba Selatan",
                "kelurahan": "Wakorumba",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Wansugi",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Wataliku",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Sarimulyo",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Sarimulyo",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Pasipadangan",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Tiworo Selatan",
                "kelurahan": "Sangia Tiworo",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Pajala",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Tiworo Selatan",
                "kelurahan": "Parura Jaya",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Maginti",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Oensuli",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Lakandito",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Lupia",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Kembang Mominasa",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Komba-Komba",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Tiworo Selatan",
                "kelurahan": "Kasimpa Jaya",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Tiworo Selatan",
                "kelurahan": "Katangana",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Kangkunawe (Langkunawe)",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Kabangka",
                "kelurahan": "Kabangka",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Tiworo Selatan",
                "kelurahan": "Barakkah",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Gala",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Abadi Jaya",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Maginti",
                "kelurahan": "Bangko",
                "kodepos": "93664"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Wapuale",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Wasolangka",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Warambe",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Wakumoro",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Walambeno Wite",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Marobo",
                "kelurahan": "Tapitapi",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Marobo",
                "kelurahan": "Wadolao / Wadolau",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Marobo",
                "kelurahan": "Pasikuta",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Marobo",
                "kelurahan": "Poaroha",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Oelongko",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Parigi",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Bone (Bone Tondo)",
                "kelurahan": "Oelongko",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Marobo",
                "kelurahan": "Marobo",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Bone (Bone Tondo)",
                "kelurahan": "Matombura",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Latampu (Tampu)",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Labulu-Bulu",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Laiba",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Parigi",
                "kelurahan": "Kolasa",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Bone (Bone Tondo)",
                "kelurahan": "Bone Lolibu",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Bone (Bone Tondo)",
                "kelurahan": "Bone Tondo",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Bone (Bone Tondo)",
                "kelurahan": "Bone Kancitala",
                "kodepos": "93663"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Waleale",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Watondo",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Tombula",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Oempu",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Tanjung",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Lianosa",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Matano Oe",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Lawama",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Lapadindi",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Lakologou",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Lamorende",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Labasa",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Laghontoghe / Lahontohe",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Kontumolepe",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Kotano Wuna",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Fongkaniwa",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Tongkuno Selatan",
                "kelurahan": "Kalidawa",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Wantiworo",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Tongkuno",
                "kelurahan": "Danagoa",
                "kodepos": "93662"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Rangka",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Tanjung Batu",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Lamaeo",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Lembo",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Laimpi",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Lahorio",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Kontumere",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Kilambibito",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Kontu Kowuna",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Kasaka",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Kawite Wite",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Bente",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Kambawuna",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Kafo Foo",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kabawo",
                "kelurahan": "Bea",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Kontu Kowuna",
                "kelurahan": "Bahutara",
                "kodepos": "93661"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Lagasa",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Lasunapa",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Palangga",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Ghonsume",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Gone Balano",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Banggai",
                "kodepos": "93659"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Wabintingi",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Waarah",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Masalili",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Mantobua",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Mabodo",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Liangkobori",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Lohia",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Lapodidi",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Liabalano (Labalano)",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Lakauduma",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Korihi",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Lakarinta",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Bungi",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Kontunaga",
                "kelurahan": "Kontunaga",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Kondongia",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Lohia",
                "kelurahan": "Bolo",
                "kodepos": "93658"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Wakoila",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Waukuni",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Nihi",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Ondoke",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Maperaha",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Marobea",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Lawada Jaya",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Lombu Jaya",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Kampobalano",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Sawerigadi (Sawerigading/Sewergadi)",
                "kelurahan": "Lakalamba",
                "kodepos": "93657"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Wali",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Wakadia",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Lakauduma",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Matarawa",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Labaha",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Lakapodo",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Bangkali",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Watopute",
                "kelurahan": "Dana",
                "kodepos": "93656"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Umba",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Tanjung Pinang",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Tangkumaho",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Sida Mangura (Sidamagura)",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Masara",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Lapokainse",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Lemoambo",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Latawe",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Lakawoghe",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Lahaji",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Kusambi",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Konawe",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Napano Kusambi",
                "kelurahan": "Kombikuno",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Kasakamu",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Bakeramba",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Kusambi",
                "kelurahan": "Guali",
                "kodepos": "93655"
            },
            {
                "kecamatan": "Towea",
                "kelurahan": "Wangkolabu",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Tampo",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Pentiro",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Towea",
                "kelurahan": "Renda",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Parida",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Napalakura",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Napabalano",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Towea",
                "kelurahan": "Moasi",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Lasalepa",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Langkumapo",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Napabalano",
                "kelurahan": "Lambiku",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Towea",
                "kelurahan": "Lakarama",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Labunti",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Labone",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Bonea",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Kombungo",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Lasalepa",
                "kelurahan": "Bangun Sari",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Towea",
                "kelurahan": "Bahari",
                "kodepos": "93654"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Wulanga Jaya",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Waumere",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Waturempe",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Wapae Jaya",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Wanseriwu",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Wandoke",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Tondasi",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Tiworo",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Tiga",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Tasipi",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Suka Damai",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Sido Makmur",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Santiri",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Santigi / Santingi",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Momuntu",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Mekar Jaya",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Mandike",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Laworo",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Langku-Langku",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Lasama",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Lakabu",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Tengah",
                "kelurahan": "Labokolo",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Kepulauan",
                "kelurahan": "Katela",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Tiworo Utara",
                "kelurahan": "Bero",
                "kodepos": "93653"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Wuna",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Wamelai (Lambubalano)",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Waulai",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Watumela",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Walelei",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Wakontu",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Sawerigadi",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Madampi",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Latugho",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Lindo",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Latompe",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Lapolea",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Lasosodo",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Lalemba",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Lapadaku",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Lailangga",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Lakanaha",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Lawa",
                "kelurahan": "Lagadi",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Lafinde",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Kampani",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Wa Daga",
                "kelurahan": "Katobu",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Barangka",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Barangka",
                "kelurahan": "Bungkolo",
                "kodepos": "93652"
            },
            {
                "kecamatan": "Duruka",
                "kelurahan": "Wapunto",
                "kodepos": "93618"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Watonea",
                "kodepos": "93615"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Mangga Kuning",
                "kodepos": "93616"
            },
            {
                "kecamatan": "Batalaiwaru (Batalaiworu)",
                "kelurahan": "Wawesa",
                "kodepos": "93614"
            },
            {
                "kecamatan": "Batalaiwaru (Batalaiworu)",
                "kelurahan": "Wakorambu",
                "kodepos": "93614"
            },
            {
                "kecamatan": "Batalaiwaru (Batalaiworu)",
                "kelurahan": "Sidodadi",
                "kodepos": "93614"
            },
            {
                "kecamatan": "Batalaiwaru (Batalaiworu)",
                "kelurahan": "Laiworu",
                "kodepos": "93614"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Raha III",
                "kodepos": "93613"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Wamponiki",
                "kodepos": "93611"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Raha II",
                "kodepos": "93612"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Raha I",
                "kodepos": "93611"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Butung-Butung",
                "kodepos": "93611"
            },
            {
                "kecamatan": "Katobu",
                "kelurahan": "Laende",
                "kodepos": "93611"
            }
        ],
        "k392": [
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Waode Kalowo",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Ronta",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Tatombuli",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Waode Angkalo",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Rante Gola",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Pongkawulu",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Ngapa&#8217;ea",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Morindino",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Langere",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Mata",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Laanoipi",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Lagundi",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Lahamoko Jaya",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Koepisino",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Kondo",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Kambowa",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Koboruno",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Gunung Sari",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Damai Laborona",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Een Sumala",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Bubu Barat",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Buranga",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Bubu",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Bonegunu",
                "kelurahan": "Bonegunu",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Bente",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kambowa",
                "kelurahan": "Baluara",
                "kodepos": "93673"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Wowonga Jaya",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Wasalabose",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Wandaka",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Waode Buri",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Waculaea",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Wamboule",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Ulunambo",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Tri Wacu-Wacu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Torombia (Torumbia)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Tomoahi",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Sara&#8217;ea",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Soloy/Soioi Agung",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Rombo",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Rahmat Baru",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Pebaoa",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Peteteaa",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Marga Karya",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Mekar Jaya",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Loji",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Malalanda",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Linsowu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Lipu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Lemo (Bonerombo)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Lemo Ea",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Lelamo",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Lauki",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Lantagi",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Lapandewa",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Lampala Jaya (Dampala Jaya)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Lakonea",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Lambale",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Lamoahi",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Labulanda",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Labelete",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Laangke",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Laanosangia (Lanosangia)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Kurolabu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Kotawo (Kotawa)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Karya Mulya",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Kasulatombi",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Kalibu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Karya Bakti",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Jampaka",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Kadacua",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Eelahaji",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "E&#8217;erinere",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Bonelipu",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Barat",
                "kelurahan": "Bumi Lapero/Lapera",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu Utara",
                "kelurahan": "Bira",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Banu-Banua Jaya",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Kulisusu (Kalingsusu/Kalisusu)",
                "kelurahan": "Bangkudu (Ereke)",
                "kodepos": "93672"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Wantulasi",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Wamorapa",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Oengkapala",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Sumampeno",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Lasiwa",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Matalagi",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Labuko",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Laea",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Labuan",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Labuan Bajo",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Labuan Walio",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Laba Jaya",
                "kodepos": "93671"
            },
            {
                "kecamatan": "Wakorumba Utara",
                "kelurahan": "Labaraga",
                "kodepos": "93671"
            }
        ],
        "k393": [
            {
                "kecamatan": "Asera",
                "kelurahan": "Wunduhaka",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Wiwirano",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Wawontoaha",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Wawosangi",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Wawolimbue",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Wawoheo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Wanggudu Raya/Jaya",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Wanggudu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Walasolo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Walalindu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Walandawe",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Wacu Pinodo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Todoloiyo Trans",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Tinondo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Tetewatu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Tapuwatu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Tetewatu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Tadoloiyo (Todoloiyo)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Tambakua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Sambandete",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Puuwanggudu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Puuwonua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Puunggomosi",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Puuhialu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Puusuli",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Polo-Polora",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Pondoa",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Paka Indah",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Padalere Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Mopute",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Padalere",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Mataiwoi",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Mata Osole (Molore)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Macumelewe (Wacumelewe)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Mata Benua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Laroonaha",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Linomioyo (Linomoiyo)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Laumoso",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Larompana",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Laronaga (Laronanga)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Langgeo Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Landiwo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Larobende",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Landawe",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Landawe Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Lamparinga",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Lamondowo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Lamonae",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Lamonae Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Lameoru",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Lambudoni",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Labungga",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Lahimbua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Kuratao",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Kota Mulya",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Kota Maju",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Kolosua",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Hialu Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Wiwirano",
                "kelurahan": "Culambatu",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Bendewuta",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Banggarema",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Oheo",
                "kelurahan": "Bandaeha",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Asemi Nunulai (Aseminulai)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Asera",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Andowia",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Anggolohipo",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Amorome Utama",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Asera",
                "kelurahan": "Amorere (Amorome)",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Andowia",
                "kelurahan": "Amolame",
                "kodepos": "93353"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Wawoluri",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Waworaha",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Waturambaha",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Wawolesea",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Toreo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Watukila",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Ulu Sawa",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Tondowatu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Tonggalino (Tongalino)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Tobimetia",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Tobi Meita",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Tetelupai",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Tinobu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Tapunggaea",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Tapuemea",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Tangauna (Tongauna)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Taipa",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Sari Mukti",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Sawa",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Puuwonggia",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Puupi",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Puusiambu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Puulemo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Punggulahi",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Pudonggala Utama",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Puudonggala (Pudonggala)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Poni Poniki",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Polora Indah",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Pasir Putih",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Pariama",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Otole",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Padaleu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Muara Tinobu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Otipulu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Motui",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Mowundo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Morombo Pantai",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Morombo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Matapila",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Molore",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Molawe",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Matanggonawe",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Matandahi",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Mandiopo (Mandiodo)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Mataiwoi",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Lemobajo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Lembo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Laramo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Larodangge",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Langgikima (Langgi Kima)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Lapulu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Lametono",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Lambuluo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Lameruru",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Laimeo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Lalembo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Lalowaru",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Kapolano",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Sawa",
                "kelurahan": "Kokapi",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Labengki",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Kampobunga",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Bungguosu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Boenaga",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Boenaga",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Barasanga",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Basule",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Belalo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Motui",
                "kelurahan": "Banggina (Banggima)",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Bandaeha",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Awila Puncak",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Molawe",
                "kelurahan": "Awila",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Andeo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Andumowu",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lembo",
                "kelurahan": "Alo-Alo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Langgikima",
                "kelurahan": "Alenggo",
                "kodepos": "93352"
            },
            {
                "kecamatan": "Lasolo",
                "kelurahan": "Abola",
                "kodepos": "93352"
            }
        ],
        "k394": [
            {
                "kecamatan": "Abeli",
                "kelurahan": "Tondonggeu",
                "kodepos": "93238"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Tobimeita",
                "kodepos": "93237"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Sambuli",
                "kodepos": "93238"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Nambo",
                "kodepos": "93236"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Petoaha",
                "kodepos": "93236"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Puday (Pudai)",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Poasia",
                "kodepos": "93235"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Talia",
                "kodepos": "93235"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Bungkutoko",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Lapulu",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Benua Nirae",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Abeli",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Abeli",
                "kelurahan": "Anggalomelai (Anggolomelai)",
                "kodepos": "93234"
            },
            {
                "kecamatan": "Poasia",
                "kelurahan": "Rahandouna",
                "kodepos": "93232"
            },
            {
                "kecamatan": "Poasia",
                "kelurahan": "Matabubu",
                "kodepos": "93233"
            },
            {
                "kecamatan": "Kambu",
                "kelurahan": "Mokoau",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Kambu",
                "kelurahan": "Padaleu",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Kambu",
                "kelurahan": "Lalolara",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Kambu",
                "kelurahan": "Kambu",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Poasia",
                "kelurahan": "Anduonohu",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Poasia",
                "kelurahan": "Anggoeya",
                "kodepos": "93231"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Mata",
                "kodepos": "93129"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Sanua",
                "kodepos": "93127"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Mangga Dua",
                "kodepos": "93128"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Dapu-Dapura",
                "kodepos": "93127"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Lahundape",
                "kodepos": "93127"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Kandai",
                "kodepos": "93127"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Kendari Caddi",
                "kodepos": "93126"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Sodohoa",
                "kodepos": "93124"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Gunung Jati",
                "kodepos": "93125"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Punggaloba",
                "kodepos": "93123"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Tipulu",
                "kodepos": "93122"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Benu-Benua",
                "kodepos": "93123"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Purirano",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Watu-Watu",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Kessilampe",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Kendari Barat",
                "kelurahan": "Kemaraya",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Jati Mekar",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Kendari",
                "kelurahan": "Kampung Salo",
                "kodepos": "93121"
            },
            {
                "kecamatan": "Wua-Wua",
                "kelurahan": "Mataiwoi",
                "kodepos": "93118"
            },
            {
                "kecamatan": "Kadia",
                "kelurahan": "Bende",
                "kodepos": "93118"
            },
            {
                "kecamatan": "Wua-Wua",
                "kelurahan": "Bonggoeya",
                "kodepos": "93118"
            },
            {
                "kecamatan": "Wua-Wua",
                "kelurahan": "Anawai",
                "kodepos": "93118"
            },
            {
                "kecamatan": "Kadia",
                "kelurahan": "Anaiwoi",
                "kodepos": "93118"
            },
            {
                "kecamatan": "Wua-Wua",
                "kelurahan": "Wua-Wua",
                "kodepos": "93117"
            },
            {
                "kecamatan": "Kadia",
                "kelurahan": "Wowawanggu",
                "kodepos": "93117"
            },
            {
                "kecamatan": "Kadia",
                "kelurahan": "Pondambea",
                "kodepos": "93117"
            },
            {
                "kecamatan": "Baruga",
                "kelurahan": "Wundupopi",
                "kodepos": "93116"
            },
            {
                "kecamatan": "Kadia",
                "kelurahan": "Kadia",
                "kodepos": "93117"
            },
            {
                "kecamatan": "Baruga",
                "kelurahan": "Watubangga",
                "kodepos": "93116"
            },
            {
                "kecamatan": "Baruga",
                "kelurahan": "Baruga",
                "kodepos": "93116"
            },
            {
                "kecamatan": "Baruga",
                "kelurahan": "Lepo-Lepo",
                "kodepos": "93116"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Tobuuha",
                "kodepos": "93115"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Lalodati",
                "kodepos": "93115"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Punggolaka",
                "kodepos": "93115"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Watulondo",
                "kodepos": "93114"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Abeli Dalam",
                "kodepos": "93115"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Wawombalata",
                "kodepos": "93113"
            },
            {
                "kecamatan": "Puuwatu",
                "kelurahan": "Puuwatu (Puwatu)",
                "kodepos": "93114"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Alolama",
                "kodepos": "93112"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Labibia",
                "kodepos": "93113"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Korumba",
                "kodepos": "93111"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Mandonga",
                "kodepos": "93111"
            },
            {
                "kecamatan": "Mandonga",
                "kelurahan": "Anggilowu",
                "kodepos": "93111"
            }
        ],
        "k395": [
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Powalutan",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Ranoyapo (Ranoiapo)",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Poopo Barat",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Poopo Utara",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Poopo",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Pontak",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Picuan Satu",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Pontak Satu",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Lompad Baru",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Mopolo",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Picuan",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Beringin",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Ranoyapo",
                "kelurahan": "Lompad",
                "kodepos": "95999"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Tiniwangko (Tiniawangko)",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Tanamon",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Poigar Satu",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Poigar Dua",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Ongkaw Dua",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Ongkaw Satu",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Durian",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Boyong Pante",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Blongko",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Sinonsayang",
                "kelurahan": "Aergale",
                "kodepos": "95959"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Wulurmaatus",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Sinisir",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Pinasungkulan Utara",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Pinasungkulan",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Palelon",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Linelean",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Makaaruyen",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Mokobang",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Kakenturan Barat",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Modoinding",
                "kelurahan": "Kakenturan",
                "kodepos": "95958"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Toyopon",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Wanga",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Wanga Amongena",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Tondey Dua",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Tondey Satu",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Tondey (Tondei)",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Tokin",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Tokin Baru",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Raanan Lama",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Raanan Baru Dua",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Raanan Baru Satu",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Raanan Baru",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Picuan Baru",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Motoling Mawale",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Motoling Satu",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Malola Satu",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Motoling",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Motoling Dua",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Lolombulan Makasili",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Makasili",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Malola",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling",
                "kelurahan": "Lalumpe",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Kumelembuai Atas",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Kumelembuai Dua",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Kumelembuai Satu",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Kumelembuai",
                "kelurahan": "Kumelembuai",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Barat",
                "kelurahan": "Keroit",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Karimbow",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Motoling Timur",
                "kelurahan": "Karimbow Talikuran",
                "kodepos": "95956"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Tewasen",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Wakan",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Rumoong Bawah",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Teep",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Rumong Barat",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Kawangkoan Bawah",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Pondos",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Elusan",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang Barat",
                "kelurahan": "Kapitu",
                "kodepos": "95955"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Uwuran Dua",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Uwuran Satu",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Ritey",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Ranoyapo",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Pondang",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Ranomea",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Ranoketang Tua",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Maliku Satu",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Pinaling",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Lopana",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Malenos Baru",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Maliku",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang Timur",
                "kelurahan": "Kota Menara",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Lewet",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Kilometer Tiga",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Bitung",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Amurang",
                "kelurahan": "Buyungon",
                "kodepos": "95954"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Wiau Lapi",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Wuwuk",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Talaitad Utara",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Tumaluntung",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Suluun Tiga",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Talaitad",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Suluun Dua",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Suluun Empat",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Suluun Satu",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Romoong Atas",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Romoong Atas Dua",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Pinapalangkow (Pinapalangkon)",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Lansot Timur",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Pinamorongan",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Lansot",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Koreng",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Suluun Tareran",
                "kelurahan": "Kapoyah (Kapoya)",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Tareran",
                "kelurahan": "Kaneyan",
                "kodepos": "95953"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Tumani",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Tompaso Baru Satu",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Torout",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Tompaso Baru Dua",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Temboan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Sion",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Tambelang",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Raraatean",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Lowian Satu",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Pinaesaan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Liningaan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Lowian",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Lindangan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Kinaweruan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Kinaweruan",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Kinalawiran",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Kinamang",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Kinamang I",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tompaso Baru",
                "kelurahan": "Karowa",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Maesaan",
                "kelurahan": "Bojonegoro",
                "kodepos": "95357"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Sapa Timur",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Tawaang",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Tenga",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Sapa",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Sapa Barat",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Radey",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuweru",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuweru Utara",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure Tiga",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure Tinanian",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure Kinamang",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure Satu",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Molinow",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Pakuure Dua",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tenga",
                "kelurahan": "Boyong Atas",
                "kodepos": "95355"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Wawona",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Wawontulap",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Tumpaan Baru",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Tumpaan Dua",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Tumpaan Satu",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Tangkuney",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Tumpaan",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Rap-Rap",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Sondaken",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Sulu",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Popontolen",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Pungkol",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Paslaten Satu",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Popareng",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Paslaten",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Matani Satu",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Munte",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Lelema",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tumpaan",
                "kelurahan": "Matani",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Bajo",
                "kodepos": "95352"
            },
            {
                "kecamatan": "Tatapaan",
                "kelurahan": "Arakan",
                "kodepos": "95352"
            }
        ],
        "k396": [
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Toundanouw Atas",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Toundanouw Satu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Toundanouw (Toundanou)",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Suhuyon",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Tambelang",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Utara",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Tiga",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Timur",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Satu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Selatan",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Tengah",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Dua",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Kota",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Silian Raya",
                "kelurahan": "Silian Barat",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Ranoako",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Ranoketang Atas",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Ranoketang Atas Satu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Lowatak (Lowantag)",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Lobu Satu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Lobu Atas",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Lobu Dua",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Lobu Kota",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Kalait Tiga",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan",
                "kelurahan": "Lobu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Kalait Dua",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Kalait Satu",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Bunag",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Kalait",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Touluaan Selatan",
                "kelurahan": "Banga",
                "kodepos": "95998"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Watuliney Indah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Wiau",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Watuliney Tengah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Tumbak",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Watuliney",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Tumbak Madani",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Tatengesan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Tatengesan Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Tababo",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Tababo Selatan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Utara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Soyowan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Tengah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Tenggara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Timur",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Selatan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Dua",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Ratatotok Muara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Ponosakan Belang",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Ponosakan Indah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Moreah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Moreah Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Molompar Timur",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Molompar Utara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Minanga Timur",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Molompar",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Minanga Tiga",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Minanga",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Minanga Dua",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Minanga Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Makalu Selatan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Mangkit",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Buku Tenggara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Makalu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Buku Utara",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Buku Selatan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Buku Tengah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Borgo Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Buku",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Beringin (Beringing)",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Borgo",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Bentenan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Bentenan Indah",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Pusomaen",
                "kelurahan": "Bentenan Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Belang",
                "kelurahan": "Belang",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Basaan Satu",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Basaan",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Ratatotok",
                "kelurahan": "Basaan Dua",
                "kodepos": "95997"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Winorangian Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Tonsawang Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Winorangian",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Tonsawang",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Tiga Tengah",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Tombatu Tiga Timur",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Tiga",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Tombatu Tiga Selatan",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Tiga",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Dua Tengah",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Dua Utara",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Tombatu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Dua",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Tombatu Dua Barat",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Pisa",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Mundung Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Mundung",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar Dua Utara",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar Dua Selatan",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar Atas",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Molompar Dua",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Kuyanga",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Utara",
                "kelurahan": "Kuyanga Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Kali",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Kali Oki",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Esandom Dua",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Esandom Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu Timur",
                "kelurahan": "Esandom",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Betelan Satu",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Tombatu",
                "kelurahan": "Betelan",
                "kodepos": "95996"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wongkai Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wongkay (Wongkai)",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wioi Timur",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wioy (Wioi)",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wioi Tiga",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wioi Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Wioi Dua",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Wawali Pasan",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Wawali",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Towuntu Timur",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Towuntu Barat",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Towuntu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Tosuraya Selatan",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Tosuraya Barat",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Tosuraya",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Tolombukan Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Tolombukan Barat",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Tolombukan",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Rasi",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Rasi Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Poniki",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Pangu Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Pangu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan Timur",
                "kelurahan": "Pangu Dua",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Nataan",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Maulit",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Lowu Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Lowu Utara",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Ratahan",
                "kelurahan": "Lowu Dua",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Liwutung Dua",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Liwutung Satu",
                "kodepos": "95995"
            },
            {
                "kecamatan": "Pasan",
                "kelurahan": "Liwutung",
                "kodepos": "95995"
            }
        ],
        "k397": [
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Tule Tengah",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Tule Utara",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Tule",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Tarun Selatan",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Sawang Utara",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Tarun",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Melonguane Timur",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Sawang",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Mala Timur I",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Melonguane",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Mala Timur",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Mala",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Kiama Barat",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Kiama Maredaren",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Kiama",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Bowombaru Utara",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Bowombaru (Bawombaru)",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane Timur",
                "kelurahan": "Bowombaru Tengah",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Melonguane",
                "kelurahan": "Ambela",
                "kodepos": "95885"
            },
            {
                "kecamatan": "Miangas",
                "kelurahan": "Miangas",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Marampit",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Marampit Timur",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Karatung Tengah",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Laluhe",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Karatung",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Karatung Selatan",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Dampulis",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Dampulis Selatan",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Nanusa",
                "kelurahan": "Kakorotan",
                "kodepos": "95884"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Taturan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Sambuara",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Sambuara Satu",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Taruan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Maririk",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Mamahan Barat",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Malat Utara",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Mamahan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Lalue Utara",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Malat",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Lalue",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Lalue Tengah",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Lahu",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Damau Bawone",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Kuma Selatan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Damau",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Kuma",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Gemeh Wantane",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Bulude Selatan",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Gemeh Raamata",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Bulude",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Birang",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Gemeh",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Akas",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Akas Balane",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Essang Selatan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Essang",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Talolang",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Ensem Timur",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Sereh I",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Ensem",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Sereh",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Bulude Selatan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Essang",
                "kelurahan": "Bulude",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Salibabu",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Salibabu Utara",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Kalongan",
                "kelurahan": "Musi I",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Batumbalango",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Musi",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Bannada",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge Selatan II",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Bambung Timur",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge Selatan I",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Bambung",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge Selatan",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Arangkaa",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge II",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Gemeh",
                "kelurahan": "Apan",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge I",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Ambia Utara",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Moronge",
                "kelurahan": "Moronge",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Essang Selatan",
                "kelurahan": "Ambia",
                "kodepos": "95883"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Lirung Matane",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Lirung I",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Tuabatu Barat",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Lirung",
                "kelurahan": "Lirung",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Tuabatu",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Kalongan",
                "kelurahan": "Kalongan Utara",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Tabang Barat",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Kalongan",
                "kelurahan": "Kalongan Selatan",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Tabang",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Kalongan",
                "kelurahan": "Kalongan",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Riung Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Dalum",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Riung",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Bitunuris Selatan",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Rainis Baru Penga",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Bitunuris",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Rainis",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Pulutan",
                "kelurahan": "Pulutan Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Salibabu",
                "kelurahan": "Balang",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Pulutan",
                "kelurahan": "Pulutan Selatan",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Parangen",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Pulutan",
                "kelurahan": "Pulutan",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Kalongan",
                "kelurahan": "Alude",
                "kodepos": "95871"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Nunu Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Nunu",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Ganalo",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Pulutan",
                "kelurahan": "Daran Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Pulutan",
                "kelurahan": "Daran",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Dapihe",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Dapalan",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Binalang Timur",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Binalang",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Bantane Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Bantane",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Ammat Selatan",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Tampan Amma",
                "kelurahan": "Ammat",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Alo Utara",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Rainis",
                "kelurahan": "Alo",
                "kodepos": "95882"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Tarohan Selatan",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Tarohan",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Rusoh (Russoh/Ruso)",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Rae Selatan",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Pampalu (Papmpalu)",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Rae",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Niampak Utara",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Selatan",
                "kelurahan": "Matahit",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Makatara Timur",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Makatara",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Lobbo I",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Lobbo",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Beo Timur",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Beo Barat",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Beo",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Beo Bantik",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Bengel",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo",
                "kelurahan": "Bantik Lama",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Awit Selatan",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Beo Utara",
                "kelurahan": "Awit",
                "kodepos": "95881"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Taduwale",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Taduna",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Rarange",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Peret",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Pantuge Timur (Patuge Timur)",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Pantuge (Patuge)",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Pannulan",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Pangeran",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Mangaran",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Kordakel",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Kabaruan Timur",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Kabaruan",
                "kelurahan": "Kabaruan",
                "kodepos": "95872"
            },
            {
                "kecamatan": "Damao (Damau)",
                "kelurahan": "Ighik",
                "kodepos": "95872"
            }
        ],
        "k398": [
            {
                "kecamatan": "Biaro",
                "kelurahan": "Tope",
                "kodepos": "95864"
            },
            {
                "kecamatan": "Biaro",
                "kelurahan": "Lamanggo",
                "kodepos": "95864"
            },
            {
                "kecamatan": "Biaro",
                "kelurahan": "Karungo",
                "kodepos": "95864"
            },
            {
                "kecamatan": "Biaro",
                "kelurahan": "Buang",
                "kodepos": "95864"
            },
            {
                "kecamatan": "Biaro",
                "kelurahan": "Dalinsaheng",
                "kodepos": "95864"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "W O",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Tulusang (Tulusan)",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Pumpente",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Pahiama",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Mulengan (Mulengen)",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Mohonsawang",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "Minanga",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Mahangiang",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Lesah Rende",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "Lumbo",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Lesah",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Laingpatehi",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Humbia",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Kisihang",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Haasi",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "Bulangan",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Buha",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Boto",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Birarikei",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Birarikei",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Bira Kimia (Birakiama)",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "Bawoleu",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Utara",
                "kelurahan": "Bawo",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang Selatan",
                "kelurahan": "Batumawira",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Barangka Pehe",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Balehumara",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Bahoi",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Tagulandang",
                "kelurahan": "Apengsala",
                "kodepos": "95863"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Winangun",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Tanaki",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Talawid",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Pelingsawang",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Peling",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Pehe",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Paseng",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Paniki",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Ondong",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Nameng",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Mini",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Makoa (Mekar Sari)",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Makalehi Utara",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Makalehi Timur",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Makalehi",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Mahuneni",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Lehi",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Laghaeng",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Kiawang",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Kinali",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Kawahang",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Kanawong",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Kapeta",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat",
                "kelurahan": "Bumbiha",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Hiung",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Selatan",
                "kelurahan": "Batusenggo",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Barat Utara",
                "kelurahan": "Batubulan",
                "kodepos": "95862"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Tatahadeng (Tatehadeng)",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Tarorane",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Tapile",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Tengah",
                "kelurahan": "Salili",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Sawang",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Pangirolong",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Pahepa",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Mala",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Matole",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Lia Satu",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Lia",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Tengah",
                "kelurahan": "Lai",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Lahopang",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Karalung Satu",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Kanang",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Karalung",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Kalihiang",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Deahe (Beaha)",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Tengah",
                "kelurahan": "Dompase",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Dame",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Dame I",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Bukide",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Buise",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Buhias",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Biau Seha",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Binalu",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Biau",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Bebali",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Tengah",
                "kelurahan": "Beong",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Bandil",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur Selatan",
                "kelurahan": "Balirangen",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Apelawo",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Bahu",
                "kodepos": "95861"
            },
            {
                "kecamatan": "Siau Timur",
                "kelurahan": "Akesimbeka",
                "kodepos": "95861"
            }
        ],
        "k399": [
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Tumalede",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Tambung",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Tenda",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Simueng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Sampakang",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Salurang",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Pintareng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Mandoi",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Malisade",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Palareng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Malamenggu",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Lehupu",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Lesabe",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Lesabe I",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Laotongan",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Hangke",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Kalagheng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Dalokaweng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Bulo",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Bukide",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Bowone",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Binebas",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Birahi",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Bentung",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Beeng Laut",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Beeng",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan",
                "kelurahan": "Batuwingkung",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tenggara",
                "kelurahan": "Basauh",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Selatan Tengah",
                "kelurahan": "Aha Patung",
                "kodepos": "95858"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Tariangbaru",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Timbelang",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Talengen",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Sensong",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Rendingan",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Palahanaeng",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Pelelangen",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Malueng",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Miulu",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Kuma I",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Kuma",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Kulur II",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Kulur I",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Gunung",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Bowongkali",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Bungalawang",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Biru",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Tengah",
                "kelurahan": "Bira",
                "kodepos": "95857"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Utaurano",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Tola",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Tarolang",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Raku",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Pusunge",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Petta Selatan",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Petta Timur",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Petta Barat",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Petta",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Nusa Tabukan",
                "kelurahan": "Nusa",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Nusa Tabukan",
                "kelurahan": "Nanusa",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Nusa Tabukan",
                "kelurahan": "Nanedakele",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Naha I",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Naha (Nahasahabe)",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Moade",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Kepulauan Marore",
                "kelurahan": "Matutuang",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Kepulauan Marore",
                "kelurahan": "Marore",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Mala",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Lenganeng",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Likuang",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Kepulauan Marore",
                "kelurahan": "Kawio",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Kalurae",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Kalekube I",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Kalekube",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Kalasuge",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Nusa Tabukan",
                "kelurahan": "Bukide Timur",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Nusa Tabukan",
                "kelurahan": "Bukide",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Bowongkulu I",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Bowongkulu",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Beha",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Bengketang",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tabukan Utara",
                "kelurahan": "Bahu",
                "kodepos": "95856"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Ulungpeliang",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Pokol",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Pananaru",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Nagha II (Dua)",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Nagha I (Satu)",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Menggawa II",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Menggawa",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Makalakuhe",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Mahumu II",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Mahumu I",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Lelipang",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Mahumu",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Kalinda I",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Kalinda",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Hesang",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Kalama Darat",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Bebu",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Binala",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Dagho",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tamako",
                "kelurahan": "Balane",
                "kodepos": "95855"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Taleko Batusaiki",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Sowaeng (Mawira)",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Pindang",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Para I",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Para",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Ngalipaeng Satu (I)",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Ngalipaeng Dua (II)",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Mawira",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Mahenggetang (Mahengetang)",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Lehimi Tariang",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Lapepahe",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Lapango I",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Lapango",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Kaluwatu",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Laine",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Kalama",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Kahakitang",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Tatoareng",
                "kelurahan": "Dalako Bembanahe",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Bebalang",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Tawoali",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu Selatan",
                "kelurahan": "Batunderang",
                "kodepos": "95854"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Taloarane",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Taloarane I",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Pinebentengang",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Sesiwung",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Manumpitaeng",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Nahepese",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Lebo",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Mala",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Kauhis",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Karatung I",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Karatung II",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Belengang",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Bengka",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Hiung",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Barangka",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Barangkalang",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Manganitu",
                "kelurahan": "Bakalaeng",
                "kodepos": "95853"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Talawid",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Tarianglama",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Lipang (Lipaeng)",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Mohongsawang",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Pempalaraeng",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Kendahe Dua",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Kendahe Satu",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Kendahe",
                "kelurahan": "Kawaluso",
                "kodepos": "95852"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Akembawi",
                "kodepos": "95818"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Kolongan Beha",
                "kodepos": "95818"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Angges",
                "kodepos": "95817"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Kolongan Mitung",
                "kodepos": "95817"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Dumuhung",
                "kodepos": "95816"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Tona II",
                "kodepos": "95815"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Tona I",
                "kodepos": "95815"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Kolongan Beha Baru",
                "kodepos": "95815"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Enengpahembang",
                "kodepos": "95815"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Tapuang",
                "kodepos": "95815"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Batulewehe",
                "kodepos": "95814"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Tidore",
                "kodepos": "95814"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Soataloara I",
                "kodepos": "95813"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Soataloara II",
                "kodepos": "95813"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Sawang Bendar",
                "kodepos": "95812"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Mahena",
                "kodepos": "95813"
            },
            {
                "kecamatan": "Tahuna Timur",
                "kelurahan": "Lesa",
                "kodepos": "95812"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Santiago",
                "kodepos": "95811"
            },
            {
                "kecamatan": "Tahuna Barat",
                "kelurahan": "Pananekeng",
                "kodepos": "95811"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Apeng Sembeka",
                "kodepos": "95811"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Bungalawang",
                "kodepos": "95811"
            },
            {
                "kecamatan": "Tahuna",
                "kelurahan": "Manente",
                "kodepos": "95811"
            }
        ],
        "k400": [
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Tutuyan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Tutuyan II",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Tutuyan III",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Tombolikat Selatan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Togid",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Tombolikat",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Paret",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Paret Timur",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Kotabunan Selatan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Kotabunan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Kotabunan Barat",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Dodop Mikasa",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Kayumoyondi",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Dodap",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Tutuyan",
                "kelurahan": "Dodap Pantai",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat Selatan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat Tengah",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat I",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat II",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Buyat Barat",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Bulawan",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Bulawan Dua",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Bulawan Satu",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Kotabunan",
                "kelurahan": "Bukaka",
                "kodepos": "95782"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Tangaton",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Tobongon",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Purworejo Tengah",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Purworejo Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Sumber Rejo",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Pinonobatuan",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Purworejo",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Moyongkota",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Moyongkota Baru",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Moonow",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Mototompian",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Modayag II",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Modayag III",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Mokitompia",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Moat (Mooat)",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Modayag",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Lanut",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Liberia",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Liberia Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Kokapoi",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Kokapoi Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Guaan",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Inaton",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Candi Rejo",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Bongkudai Utara",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Buyandi",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Bongkudai Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Bongkudai Selatan",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Bongkudai Barat",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Bongkudai Baru",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Bangunan Wuwuk",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Bangunan Wuwuk Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag Barat",
                "kelurahan": "Bangunan Wuwuk Timur",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Modayag",
                "kelurahan": "Badaro",
                "kodepos": "95781"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Nuangan I",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Nuangan Selatan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Nuangan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Nuangan Barat",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Motongkad Tengah",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Motongkad Utara",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Motongkad",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Motongkad Selatan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Molobog",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Molobog Barat",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Molobog Timur",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Matabulu",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Matabulu Timur",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Loyow",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Jiko",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Jiko Belanga",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Jiko Utara",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Iyok",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Idumun",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Atoga Timur",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Bai",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Nuangan",
                "kelurahan": "Atoga",
                "kodepos": "95775"
            }
        ],
        "k401": [
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Torosik",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Tolotoyon",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Tobayagan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Pinolosian",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Pinolosian Selatan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Posilagon",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Onggunoi",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Pidung",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Nunuk",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Matandoi (Motandoi)",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Modisi",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Mataindo",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Lungkap",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Linawan I",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Kombot",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Linawan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian",
                "kelurahan": "Ilomata",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Dumagin B",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Iligon",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Dumagin A",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Deaga",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Timur",
                "kelurahan": "Dayow",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Adow Selatan",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Tonala",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Pinolosian Tengah",
                "kelurahan": "Adow",
                "kodepos": "95775"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Tolutu",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Toluaya",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Tolondadu II",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Tolondadu",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Tolondadu I",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Tangagah",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Sondana",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Soputa",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Tabilaa",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Sinombayuga",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Soguo",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Salongo",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Sinandaka",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Saibuah",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Sakti",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Popodu",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Pilolahunga",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Pinolantungan",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Momalia II",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Pangia",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Momalia I",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Molibagu",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Milangodaa Barat",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Meyambanga",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Milangodaa",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Lion",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Luwoo",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Manggadaa",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Dudepo",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Duminanga",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Biniha",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Biniha Timur",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Posigadan",
                "kelurahan": "Batuliodu",
                "kodepos": "95774"
            },
            {
                "kecamatan": "Bolaang Uki",
                "kelurahan": "Bakida",
                "kodepos": "95774"
            }
        ],
        "k402": [
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Werdhi Agung Selatan",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Werdhi Agung Timur",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Werdhi Agung Utara",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Wangga Baru",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Werdhi Agung",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Uuwan / Uuan",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Toraut Utara",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Totaut / Toraut",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Matayangan",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Mekaruo",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Kinomaligan (Kinomalingan)",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Kosio",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Kosio Timur",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Ibolian Satu / I",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Kasio Barat",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Ikhwan",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Ibolian",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Tengah",
                "kelurahan": "Ibolian",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Doloduo Satu",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Doloduo",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Barat",
                "kelurahan": "Doloduo Dua",
                "kodepos": "95773"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Tumokang Baru",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Tumokang Timur",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Tonom",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Toruakat",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Tapadaka Timur",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Tapadaka Utara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Tapadaka Satu / I",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Siniyung Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Pusian",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Serasi",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Siniyung",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Pinonobatuan Barat",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Ponompian",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Osion",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Pinonobatuan",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Mototabian",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Utara Dua",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Utara Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Selatan Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Utara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Selatan Dua",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopuya Selatan",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopugad Utara Dua",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopugad Utara Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopugad Selatan Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopugad Utara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Mopugad Selatan",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Mogoyunggung Dua",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Mogoyunggung Satu",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Modomang",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Mogoyunggung",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Konarom",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Konarom Barat",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Konarom Utara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Kembang Mertha",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Kembang Sari",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Kanaan",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Imandi",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Ikuna",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Dumoga II",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Dumoga I",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Dumoga",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Dumara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Dondomon",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Dondomon Selatan",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Utara",
                "kelurahan": "Dondomon Utara",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga",
                "kelurahan": "Bumbungon",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Tenggara",
                "kelurahan": "Bonawang",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Amertha Sari",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Tungoi II",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Dumoga Timur",
                "kelurahan": "Amertha Buana",
                "kodepos": "95772"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Tanoyan Utara",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Tapa Aog",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Tungoi I",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Mopusi",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Tanoyan Selatan",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Matali Baru",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Mengkang",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Mopait",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Kopandakan II",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Lolayan",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Abak",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Bakan",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Lolayan",
                "kelurahan": "Bombanon",
                "kodepos": "95771"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Pasir Putih",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Pangi",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Pangi Timu",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Lolanan",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Maelang",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Cempaka",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Domisil Moonow",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Bolangat Timur",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Batumera (Batu Merah)",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Bolangat",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Babo",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangtombolang",
                "kelurahan": "Ayong",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Tuyat",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Tandu",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Totabuan",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Solog",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Pinogaluman",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Sauk",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Pindolili",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Pindol",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Mongkoinit",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Motabang",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Lolak II",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Lolak Induk",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Lolak Tombolango",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Lalow",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Diat",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Labuhan Uki (Labuan Uki)",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Buntalo Selatan",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Buntalo Timur",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Bumbung",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Buntalo Induk",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Baturapa Induk",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Lolak",
                "kelurahan": "Baturapa I",
                "kodepos": "95761"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Tiberias",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Wineru",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Poigar III",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Pomoman",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Tanjung Mariri",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Poigar",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Poigar II",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nonapan Dua",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nonapan Satu",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nanasi Timur",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nonapan",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nonapan Baru",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Nanasi",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mondatong",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mondatong Baru",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mariri Lama",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mariri Satu (I)",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mariri Baru",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Mariri Dua (II)",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Poigar",
                "kelurahan": "Gogaluman",
                "kodepos": "95753"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Tadoy",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Tadoy I",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Solimandungan Baru",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Solimandungan Dua",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Solimandungan Satu",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Lolan",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Lolan Dua",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Langagon Dua",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Langagon Satu",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Inobonto Satu",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Komangaan",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Langagon",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Inobonto Dua",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Inobonto",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Bolaang",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Bolaang Satu",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Bantik",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang",
                "kelurahan": "Bangomolunow",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Ambang I",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Bolaang Timur",
                "kelurahan": "Ambang II",
                "kodepos": "95752"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Wangga Satu",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Wangga",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Tuduaog Baru",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Sinsingon Timur",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Tuduaog",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Sinsingon",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Sinsingon Barat",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Poyuyanan",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Poopo Barat",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Poopo Selatan",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Passi 2",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Poopo",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Passi 1",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Pangian Barat",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Pangian Tengah",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Otam Barat",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Pangian",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Muntoi Timur",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Otam",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Mobuya",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Muntoi Induk (Muntoy)",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Manembo",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Lobong",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Kolingangaan",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Inuai",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Insil",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Timur",
                "kelurahan": "Insil Baru",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Bintau",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Passi Barat",
                "kelurahan": "Bulud",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Bilalang IV",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Bilalang Baru",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Bilalang III",
                "kodepos": "95751"
            },
            {
                "kecamatan": "Bilalang",
                "kelurahan": "Bilalang III Utara",
                "kodepos": "95751"
            }
        ],
        "k403": [
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tuntung",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tuntung Timur",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tombulang",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tontulow (Tuntulow)",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tintulow Utara",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tambulong Timur",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tanjung Sidupa",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Tambulong Pantai",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Solo",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Pontak",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Soligir",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Padango",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Komus Satu",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Kuala",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Kuala Utara",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Komus Dua (II)",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Komus Dua Timur",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Kayuogu",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Inomunga Utara",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Duini",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Gihang",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Inomunga",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Dalapuli Timur",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Dengi",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Busato",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Dalapuli",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Dalapuli Barat",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Buko Selatan",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Buko Utara",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Boroko Utara",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Buko",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Boroko",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Boroko Timur",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Bigo",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Kaidipang",
                "kelurahan": "Bigo Selatan",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Batu Batayo",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Pinogaluman",
                "kelurahan": "Batutajam",
                "kodepos": "95765"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Wakat",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Tanjung Labou",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Tote",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Talaga Tumoagu",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Tanjung Buaya",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Sonuo",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Talaga",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Saleo Satu",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Paku Selatan",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Saleo",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Paku",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Ollot II",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Ollot I",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Ollot",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Nunukan",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Nagara",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Mokoditek I (Mookoditek I)",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Lipu Bogu",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Mokoditek (Mookoditek)",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Langi",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Keimanga",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Jambu Sarang",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Iyok",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Bolangitang I",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Bolangitang II",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Barat",
                "kelurahan": "Bolang Itang Barat",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Bohabak IV",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Bohabak II",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Bohabak III",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Bohabak I",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Biontong II",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Biontong I",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Biontong",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Binuni",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Binuanga",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Binjeita II",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Binjeita I",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bolang Itang Timur",
                "kelurahan": "Binjeita",
                "kodepos": "95764"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Voa A",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Talaga",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Vahuta",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Pimpi",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Padang Barat",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Padang",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Mome",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Minanga",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Kuhanga",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Kopi",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Bunia",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Bunong",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Huntuk",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Bintauna Pantai/Pante",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Batulintik",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Bintauna",
                "kelurahan": "Bintauna",
                "kodepos": "95763"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Tombolango",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sidodadi",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Suka Makmur",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sangtombolang",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sangkup III (Sangkub III)",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sanglup Empat",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sangkup I (Sangkub I)",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sangkup II (Sangkub II)",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sampiro",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Sangkub Timur",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Pangkusa",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Mokusato",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Monompia",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Busisingo",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Busisingo Utara",
                "kodepos": "95762"
            },
            {
                "kecamatan": "Sangkub",
                "kelurahan": "Ampeng Sembeka",
                "kodepos": "95762"
            }
        ],
        "k404": [
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Moyag",
                "kodepos": "95719"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Motoboi Besar",
                "kodepos": "95719"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Kobo Besar",
                "kodepos": "95719"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Kobo Kecil",
                "kodepos": "95719"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Matali",
                "kodepos": "95718"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Sinindian",
                "kodepos": "95718"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Tabang",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Poyowa Kecil",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Poyowa Besar I",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Poyowa Besar II",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Motoboi Kecil",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Pombundayan (Pobundayan)",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Kopandakan I",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Mongondow",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Selatan",
                "kelurahan": "Bungko",
                "kodepos": "95717"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Mongkonai Barat",
                "kodepos": "95716"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Tumubui / Tomobui (Tumobuy)",
                "kodepos": "95716"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Mogolaing",
                "kodepos": "95716"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Mogolaing",
                "kodepos": "95716"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Molinow",
                "kodepos": "95716"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Upai",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Gogagoman",
                "kodepos": "95715"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Pontodon",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Sia",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Genggulang",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Bilalang I",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Bilalang II",
                "kodepos": "95714"
            },
            {
                "kecamatan": "Kotamobagu Utara",
                "kelurahan": "Biga",
                "kodepos": "95713"
            },
            {
                "kecamatan": "Kotamobagu Timur",
                "kelurahan": "Kotobangon (Kotabangon)",
                "kodepos": "95712"
            },
            {
                "kecamatan": "Kotamobagu Barat",
                "kelurahan": "Kotamobagu",
                "kodepos": "95711"
            }
        ],
        "k405": [
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Wolaang",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Winebetan",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Walewangko",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Waleure",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Tumaratas II",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Walantakan",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Tounelet",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Tumaratas I",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Tempang III",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Toraget",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Temboan",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Tempang I",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Tempang II",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Taraitak Satu",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Teep",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Taraitak",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Sumarayar",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Rumbia",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Raringis Utara",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Raringis Selatan",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Raranon",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Raringis",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Noongan III",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Palamba",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Paslateh (Paslaten)",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Noongan I",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Noongan II",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Lowian",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Manembo",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Kopiwangker",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Koyawas",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Utara",
                "kelurahan": "Karumenga",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Kawatak",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Karondoran",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Atep Satu",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Kaayuran Atas",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Kaayuran Bawah",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Selatan",
                "kelurahan": "Atep",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Barat",
                "kelurahan": "Ampreng",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Amongena Tiga",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Amongena Dua (II)",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Langowan Timur",
                "kelurahan": "Amongena Satu (I)",
                "kodepos": "95694"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Touure",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Touure Dua",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Tonsewer",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Tonsewer Selatan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tolok Satu",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Tompaso Dua",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tolok Satu",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tempok Selatan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tolok",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Talikuran",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tember",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Tempok",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Pinabetengan Selatan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Pinabetengan Utara",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Sendangan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Penaesaan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso Barat",
                "kelurahan": "Pinabetengan",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Liba",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Kamanga",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Tompaso",
                "kelurahan": "Kamanga Dua",
                "kodepos": "95693"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Uner",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Uner Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Tombasian Bawah",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Tondegesan",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Tondegesan I",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Tondegesan II",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Tombasian Atas",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Tombasian Atas Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Talikuran Utara",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Talikuran",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Talikuran Barat",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Sendangan",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Sendangan Selatan",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Sendangan Tengah",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Ranolambot",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Kinali",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Kinali Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Satu Barat",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Satu Utara",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Dua Barat",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Dua Timur",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Utara",
                "kelurahan": "Kiawa Dua",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kayuum (Kayuuwi)",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kayuuwi Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan",
                "kelurahan": "Kanonang Tiga",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kanonang Satu",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kanonang Dua",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kanonang Empat",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Kawangkoan Barat",
                "kelurahan": "Kanonang Lima",
                "kodepos": "95692"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Tounelet",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Tincep",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Tounelet",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Talikuran",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Talikuran Satu",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Timbukar",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Sandangan Satu",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Sawangan",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Sendangan",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Rambunan",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Rambunan Amian",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Leilem II",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Leilem III",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Kotongan Atas Satu",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Leilem I",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Kolongan Atas II",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Kolongan Atas I",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Kauneran",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Sonder",
                "kelurahan": "Kauneran Satu",
                "kodepos": "95691"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Sawangan",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Tulap",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Rerer",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Rerer I",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Lalumpe",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Makalisung",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Ranowangko Dua",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kolongan I",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kombi",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kolongan",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kalawiran",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kayu Besi",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Kombi",
                "kelurahan": "Kinaselon (Kinaleosan)",
                "kodepos": "95684"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Watulaney Amian",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Watumea",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Watulaney",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Telap",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Touliang Oki",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Tandengan",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Tandengan Satu",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Seretan Timur",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Seretan",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Ranomerut",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Maumbi",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Parentek",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Karor",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Kayuroya",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Kaleosan",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Kapataran",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Kapataran Satu",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Eris",
                "kelurahan": "Eris",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Lembean Timur",
                "kelurahan": "Atep Oki",
                "kodepos": "95683"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Wineru",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Wailang",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Wasian",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Toulimembet",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Tumpaan",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Tounelet",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Tountimomor",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Touliang",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Talikuran",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Totolan",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Rinondor",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Sendangan",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Simbel",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Paslaten",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Passo (Paso)",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Pahaleten",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Panasen",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Mahembang",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Makalelon",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Kaweng",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas",
                "kelurahan": "Kayuwatu",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Kakas Barat",
                "kelurahan": "Kalawiran",
                "kodepos": "95682"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Tampusu",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Timu",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Sinuian",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Talikuran",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Pulutan",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Sendangan",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Parepei (Parepey)",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Paslaten",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Kasuratan",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Leleko",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Remboken",
                "kelurahan": "Kaima",
                "kodepos": "95681"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Warembungan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Winangun Atas",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Tateli Weru",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Tikela",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Tombuluan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Tateli Tiga",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Tateli Satu",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Tateli",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Tateli Dua",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Suluan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Sea Tumpengan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Sea II",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Sea Mitra",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Sea",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Sea I",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Rumengkor Satu",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Sawangan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Rumengkor",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Rumengkor Dua",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Pineleng Satu Timur",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Pineleng Satu",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Pineleng Dua Indah",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Pineleng Dua",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Lotta",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Koka",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Koha Selatan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Koha Timur",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Koha Barat",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Koha",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Kembes Satu",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Kembes Dua",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombulu",
                "kelurahan": "Kamangta",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Kali Selatan",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Pineleng",
                "kelurahan": "Kali",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Kalasey Satu",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Kalasey Dua",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Mandolang",
                "kelurahan": "Agotey",
                "kodepos": "95661"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Teling",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Senduk",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Tambala",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Sarani Matani",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Ranowangko",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Ranotongkor Timur",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Ranotongkor",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Poopoh",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Pinasungkulan",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lolah Tiga",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Mokupa",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lolah Satu",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lolah Dua",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lolah",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lemoh Timur",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lemoh Uner",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lemoh Barat",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri Timur",
                "kelurahan": "Lemoh",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Kumu",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tombariri",
                "kelurahan": "Borgo",
                "kodepos": "95651"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Urongo",
                "kodepos": "95619"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Tounsaru",
                "kodepos": "95619"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Tataaran Patar",
                "kodepos": "95619"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Peleloan",
                "kodepos": "95619"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Tataaran Satu (I)",
                "kodepos": "95618"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Tataaran Dua (II)",
                "kodepos": "95618"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Maesa Unima",
                "kodepos": "95618"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Tuutu",
                "kodepos": "95617"
            },
            {
                "kecamatan": "Tondano Selatan",
                "kelurahan": "Koya",
                "kodepos": "95618"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Rinegetan",
                "kodepos": "95617"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Roong",
                "kodepos": "95617"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Tounkuramber",
                "kodepos": "95616"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Wawalintouan",
                "kodepos": "95616"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Wewelen",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Masarang",
                "kodepos": "95616"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Sumalangka",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Watulambot",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Barat",
                "kelurahan": "Rerewokan",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Sasaran",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Kembuan Satu",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Wulauan (Wulaoan)",
                "kodepos": "95614"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Kembuan",
                "kodepos": "95615"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Luaan",
                "kodepos": "95614"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Marawas",
                "kodepos": "95614"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Wengkol",
                "kodepos": "95613"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Kampung Jawa",
                "kodepos": "95614"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Kendis",
                "kodepos": "95613"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Ranowangko",
                "kodepos": "95613"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Liningaan",
                "kodepos": "95612"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Makalonsouw",
                "kodepos": "95612"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Papakelan",
                "kodepos": "95612"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Toulour",
                "kodepos": "95611"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Katinggolan",
                "kodepos": "95612"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Kiniar",
                "kodepos": "95611"
            },
            {
                "kecamatan": "Tondano Timur",
                "kelurahan": "Taler",
                "kodepos": "95611"
            },
            {
                "kecamatan": "Tondano Utara",
                "kelurahan": "Tonsea Lama",
                "kodepos": "95371"
            }
        ],
        "k406": [
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Motto",
                "kodepos": "95559"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Kelapa Dua",
                "kodepos": "95558"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Lirang",
                "kodepos": "95559"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Pintukota",
                "kodepos": "95557"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Binuang",
                "kodepos": "95558"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Pancuran",
                "kodepos": "95556"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Mawali",
                "kodepos": "95555"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Pasirpanjang",
                "kodepos": "95553"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Papusungan",
                "kodepos": "95554"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Batulubang",
                "kodepos": "95552"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Posokan",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Paudean",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Kareko",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Nusu",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Gunung Woka (Batu Woka)",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Selatan (Bitung Selatan)",
                "kelurahan": "Dorbolaang (Dorbolaan)",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Lembeh Utara",
                "kelurahan": "Batukota",
                "kodepos": "95551"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Sagerat Weru Satu (I)",
                "kodepos": "95546"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Tanjung Merah",
                "kodepos": "95547"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Manembo Nembo Tengah",
                "kodepos": "95545"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Sagerat",
                "kodepos": "95546"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Sagerat Weru Dua (II)",
                "kodepos": "95546"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Manembo Nembo Atas",
                "kodepos": "95545"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Manembo Nembo Bawah",
                "kodepos": "95545"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Wangurer",
                "kodepos": "95543"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Atas",
                "kodepos": "95544"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Weru Dua (II)",
                "kodepos": "95543"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Weru Satu (I)",
                "kodepos": "95543"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Bawah",
                "kodepos": "95542"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Wangurer Timur",
                "kodepos": "95541"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Wangurer Utara",
                "kodepos": "95541"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Wangurer Barat",
                "kodepos": "95541"
            },
            {
                "kecamatan": "Matuari (Bitung Barat)",
                "kelurahan": "Tendeki",
                "kodepos": "95539"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Paceda",
                "kodepos": "95541"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Apela Dua (II)",
                "kodepos": "95537"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Apela Satu (I)",
                "kodepos": "95537"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Kumersot (Kumeresot)",
                "kodepos": "95538"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Pinasungkulan",
                "kodepos": "95535"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Karondoran",
                "kodepos": "95536"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Batu Putih Atas",
                "kodepos": "95535"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Batu Putih Bawah",
                "kodepos": "95535"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Tewaan",
                "kodepos": "95532"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Danowudu",
                "kodepos": "95533"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Pinokalan",
                "kodepos": "95531"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Pinangunian",
                "kodepos": "95529"
            },
            {
                "kecamatan": "Ranowulu (Bitung Utara)",
                "kelurahan": "Dua Sudara (Duasadara)",
                "kodepos": "95531"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Tandu Rusa",
                "kodepos": "95527"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Makawidey",
                "kodepos": "95528"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Winenet Satu (I)",
                "kodepos": "95525"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Aertembaga Dua (II)",
                "kodepos": "95526"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Aertembaga Satu (I)",
                "kodepos": "95526"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Winenet Dua (II)",
                "kodepos": "95525"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Peteten Dua (II)",
                "kodepos": "95524"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Peteten Satu (I)",
                "kodepos": "95524"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Kakenturan Dua (II)",
                "kodepos": "95523"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Kakenturan Satu (I)",
                "kodepos": "95523"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Bitung Timur",
                "kodepos": "95522"
            },
            {
                "kecamatan": "Aertembaga (Bitung Timur)",
                "kelurahan": "Kasawari",
                "kodepos": "95521"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Bitung Tengah",
                "kodepos": "95521"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Madidir Ure",
                "kodepos": "95515"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Madidir Unet",
                "kodepos": "95516"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Kadoodan",
                "kodepos": "95513"
            },
            {
                "kecamatan": "Madidir (Bitung Tengah)",
                "kelurahan": "Madidir Weru",
                "kodepos": "95514"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Pakadoodan",
                "kodepos": "95512"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Pateten Tiga",
                "kodepos": "95512"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Indah",
                "kodepos": "95511"
            },
            {
                "kecamatan": "Girian",
                "kelurahan": "Girian Permai",
                "kodepos": "95511"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Bitung Barat Dua (II)",
                "kodepos": "95511"
            },
            {
                "kecamatan": "Maesa",
                "kelurahan": "Bitung Barat Satu (I)",
                "kodepos": "95511"
            }
        ],
        "k407": [
            {
                "kecamatan": "Tomohon Timur",
                "kelurahan": "Kumelembuai (Kumelembuay)",
                "kodepos": "95449"
            },
            {
                "kecamatan": "Tomohon Timur",
                "kelurahan": "Rurukan Satu",
                "kodepos": "95449"
            },
            {
                "kecamatan": "Tomohon Timur",
                "kelurahan": "Rurukan",
                "kodepos": "95447"
            },
            {
                "kecamatan": "Tomohon Timur",
                "kelurahan": "Paslaten Dua",
                "kodepos": "95446"
            },
            {
                "kecamatan": "Tomohon Timur",
                "kelurahan": "Paslaten Satu",
                "kodepos": "95446"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Matani Dua",
                "kodepos": "95444"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Matani Satu",
                "kodepos": "95445"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Kolongan",
                "kodepos": "95442"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Matani Tiga",
                "kodepos": "95443"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Talete Dua",
                "kodepos": "95441"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Talete Satu",
                "kodepos": "95441"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Kamasi",
                "kodepos": "95442"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Kamasi Satu",
                "kodepos": "95441"
            },
            {
                "kecamatan": "Tomohon Tengah",
                "kelurahan": "Kolongan Satu",
                "kodepos": "95441"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Walian Satu",
                "kodepos": "95439"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Walian",
                "kodepos": "95439"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Walian Dua",
                "kodepos": "95439"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Tumatangtang Satu",
                "kodepos": "95439"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Pangolombian",
                "kodepos": "95437"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Uluindano",
                "kodepos": "95438"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Lahendong",
                "kodepos": "95435"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Tondangow",
                "kodepos": "95436"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Pinaras",
                "kodepos": "95434"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Kampung Jawa",
                "kodepos": "95433"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Lansot",
                "kodepos": "95431"
            },
            {
                "kecamatan": "Tomohon Selatan",
                "kelurahan": "Tumatangtang",
                "kodepos": "95432"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Taratara Satu",
                "kodepos": "95425"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Woloan Tiga",
                "kodepos": "95423"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Taratara Dua",
                "kodepos": "95424"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Taratara Tiga",
                "kodepos": "95423"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Woloan Satu Utara",
                "kodepos": "95423"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Woloan Dua",
                "kodepos": "95422"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Taratara",
                "kodepos": "95423"
            },
            {
                "kecamatan": "Tomohon Barat",
                "kelurahan": "Woloan Satu",
                "kodepos": "95421"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Wailan",
                "kodepos": "95418"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kayawu",
                "kodepos": "95419"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kakaskasen",
                "kodepos": "95418"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kakaskasen Dua",
                "kodepos": "95416"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kakaskasen Tiga",
                "kodepos": "95417"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kinilow",
                "kodepos": "95414"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kakaskasen Satu",
                "kodepos": "95415"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Tinoor Satu",
                "kodepos": "95412"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Kinilow Satu",
                "kodepos": "95413"
            },
            {
                "kecamatan": "Tomohon Utara",
                "kelurahan": "Tinoor Dua",
                "kodepos": "95411"
            }
        ],
        "k408": [
            {
                "kecamatan": "Kema",
                "kelurahan": "Tontalete",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Waleo",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Waleo Dua",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Makalisung",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Tontaalete Rok-rok",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Lansot",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Lilang",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Kema Satu",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Kema Tiga",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Watutumou III",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kema",
                "kelurahan": "Kema Dua",
                "kodepos": "95379"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Watutumou I",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Watutumou II",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Maumbi",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Suwaan",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kolongan Tetempangan",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kuwil",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kawangkoan",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kawangkoan Baru",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kolongan",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kaleosan",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Kalawat",
                "kelurahan": "Kalawat",
                "kodepos": "95378"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Wawunian",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Tanah Putih",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Tarabitan",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Termaal (Termal)",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Paputungan",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Serey (Serei)",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Talise",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Mubune",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Munte",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Palaes",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Maliambao",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Gangga Satu",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Jayakarsa",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Kinabuhutan",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Gangga Dua",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Airbanua (Aer Banua)",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Bahoi",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Likupang Barat",
                "kelurahan": "Bulutui",
                "kodepos": "95377"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Wori",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Talawaan Atas",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Talawaan Bantik",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Tiwoho",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Ponto",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Nain Satu",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Nain Tatampi",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Mantehage Tinongko",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Minaesa",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Nain",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Mantehage Buhias",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Mantehage Tangkas",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Mantehage Bango",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Lantung",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Kima Bajo",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Kulu",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Lansa",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Darunu",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Bulo",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Winuri",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Wori",
                "kelurahan": "Budo",
                "kodepos": "95376"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Wangurer",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Werot",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Wineru",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Rinondoran",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Sarawet (Serawet)",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Resetlemen",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Pulisan",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Paslaten",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Marinsow (Marinsouw)",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Pinenek",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Likupang Satu",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Maen",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Lihunu",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Likupang Dua",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Likupang Kampung Ambong",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Libas",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Kokoleh Dua",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Kokoleh Satu",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Kinunang",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Kaweruan",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Kahuku",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Kalinaun",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Timur",
                "kelurahan": "Ehe",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Likupang Selatan",
                "kelurahan": "Batu",
                "kodepos": "95375"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Winetin",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Wusa",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Warisa Kampung Baru",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Warukapas",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Wasian",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Warisa",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Warisa Kampung Baru",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Teep Warisa",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Tumbohon",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Tetey",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Tatelu Rondor",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Tatelu Satu",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Talawaan",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Pinilih",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Paniki Baru",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Patokaan",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Paniki Baru",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Matungkas",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Paniki Atas",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Talawaan",
                "kelurahan": "Mapanget",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Lumpias",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Laikit",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Lumpias",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Klabat",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Watudambo Dua",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Dimembe",
                "kelurahan": "Dimembe",
                "kodepos": "95373"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Tumaluntung",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Watudambo",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Lembean",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Paslaten",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Treman",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Kawiley (Kawilei)",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Kauditan Satu",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Kaima",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Karegesan",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Kauditan Dua",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Kauditan",
                "kelurahan": "Kaasar",
                "kodepos": "95372"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Sawangan",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Sukur",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Tanggari",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Sarongsong Dua",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Sarongsong Satu",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Rap-Rap",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Sampiri",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Airmadidi Atas",
                "kodepos": "95371"
            },
            {
                "kecamatan": "Airmadidi",
                "kelurahan": "Airmadidi Bawah",
                "kodepos": "95371"
            }
        ],
        "k409": [
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Paniki Satu",
                "kodepos": "95259"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Mapanget Barat",
                "kodepos": "95258"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Kima Atas",
                "kodepos": "95259"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Paniki Dua",
                "kodepos": "95257"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Lapangan",
                "kodepos": "95258"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Kairagi Dua",
                "kodepos": "95254"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Paniki Bawah",
                "kodepos": "95256"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Kairagi Satu",
                "kodepos": "95253"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Bengkol",
                "kodepos": "95251"
            },
            {
                "kecamatan": "Mapanget",
                "kelurahan": "Buha",
                "kodepos": "95252"
            },
            {
                "kecamatan": "Bunaken",
                "kelurahan": "Pandu",
                "kodepos": "95249"
            },
            {
                "kecamatan": "Bunaken Kepulauan",
                "kelurahan": "Bunaken",
                "kodepos": "95246"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Tumumpa Dua",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Tumumpa Satu",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Sumompo",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Tuminting",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Sindulang Dua",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Sindulang Satu",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Mahawu",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Islam (Kampung Islam)",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Maasing",
                "kodepos": "95238"
            },
            {
                "kecamatan": "Tuminiting",
                "kelurahan": "Bitungkarangria",
                "kodepos": "95239"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Ternate Tanjung",
                "kodepos": "95234"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Singkil Satu",
                "kodepos": "95234"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Kombos Timur",
                "kodepos": "95234"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Singkil Dua",
                "kodepos": "95234"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Kombos Barat",
                "kodepos": "95234"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Ternate Baru",
                "kodepos": "95232"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Ketang Baru",
                "kodepos": "95232"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Wawonasa",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken",
                "kelurahan": "Molas",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken",
                "kelurahan": "Tongkeina / Tongkaina",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken",
                "kelurahan": "Meras",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken Kepulauan",
                "kelurahan": "Manado Tua Satu",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken Kepulauan",
                "kelurahan": "Manado Tua Dua",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Singkil",
                "kelurahan": "Karame",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken",
                "kelurahan": "Bailang",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Bunaken Kepulauan",
                "kelurahan": "Alung Banua",
                "kodepos": "95231"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Malalayang Satu Timur",
                "kodepos": "95163"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Malalayang Satu Barat",
                "kodepos": "95163"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Malalayang Dua (II)",
                "kodepos": "95163"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Batu Kota",
                "kodepos": "95163"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Malalayang Satu (I)",
                "kodepos": "95162"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Winangun Satu",
                "kodepos": "95161"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Winangun Dua",
                "kodepos": "95161"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Taas",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Perkamil",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Paal IV",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Paal Dua",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Malendeng",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Kairagi Weru",
                "kodepos": "95129"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Ranomut",
                "kodepos": "95128"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Dendengan Luar",
                "kodepos": "95127"
            },
            {
                "kecamatan": "Paal Dua",
                "kelurahan": "Dendengan Dalam",
                "kodepos": "95127"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Tikala Baru",
                "kodepos": "95126"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Banjer",
                "kodepos": "95125"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Tikala Kumaraka",
                "kodepos": "95124"
            },
            {
                "kecamatan": "Tikala",
                "kelurahan": "Tikala Ares",
                "kodepos": "95124"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Lawang Irung",
                "kodepos": "95123"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Komo Luar",
                "kodepos": "95122"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Pinaesaan",
                "kodepos": "95122"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Istiqlal",
                "kodepos": "95121"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Calaca",
                "kodepos": "95121"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Teling Bawah",
                "kodepos": "95119"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Teling Atas",
                "kodepos": "95119"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Pakowa",
                "kodepos": "95119"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Wanea",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Ranotana Weru",
                "kodepos": "95118"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Tingkulu",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Tanjung Batu",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Karombasan Selatan",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Karombasan Utara",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Wanea",
                "kelurahan": "Bumi Nyiur",
                "kodepos": "95117"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Ranotana",
                "kodepos": "95116"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Kleak",
                "kodepos": "95115"
            },
            {
                "kecamatan": "Malalayang",
                "kelurahan": "Bahu",
                "kodepos": "95115"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Sario Tumpaan",
                "kodepos": "95114"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Sario Utara",
                "kodepos": "95114"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Titiwungen Utara",
                "kodepos": "95113"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Sario",
                "kodepos": "95114"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Sario Kota Baru",
                "kodepos": "95113"
            },
            {
                "kecamatan": "Sario",
                "kelurahan": "Titiwungen Selatan",
                "kodepos": "95113"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Bumi Beringin",
                "kodepos": "95113"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Wenang Utara",
                "kodepos": "95111"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Mahakeret Barat",
                "kodepos": "95112"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Mahakeret Timur",
                "kodepos": "95112"
            },
            {
                "kecamatan": "Wenang",
                "kelurahan": "Wenang Selatan",
                "kodepos": "95111"
            }
        ],
        "k410": [
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Ranah Pantai Cermin",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Sitapus",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Lubuk Ulang Aling Selatan",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Lubuk Ulang Aling Tengah",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Abai",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Dusun Tangah",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir Batang Hari",
                "kelurahan": "Lubuk Ulang Aling",
                "kodepos": "27779"
            },
            {
                "kecamatan": "Sangir",
                "kelurahan": "Lubuk Gadang Selatan",
                "kodepos": "27778"
            },
            {
                "kecamatan": "Sangir",
                "kelurahan": "Lubuk Gadang Timur",
                "kodepos": "27778"
            },
            {
                "kecamatan": "Sangir",
                "kelurahan": "Lubuk Gadang Utara",
                "kodepos": "27778"
            },
            {
                "kecamatan": "Sangir Balai Janggo",
                "kelurahan": "Talunan Maju",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir",
                "kelurahan": "Lubuk Gadang",
                "kodepos": "27778"
            },
            {
                "kecamatan": "Sangir Balai Janggo",
                "kelurahan": "Sungai Kunyit Barat",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Balai Janggo",
                "kelurahan": "Talao Sungai Kunyit",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Jujuan",
                "kelurahan": "Padang Air Dingin",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Jujuan",
                "kelurahan": "Padang Gantiang",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Balai Janggo",
                "kelurahan": "Sungai Kunyit",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Jujuan",
                "kelurahan": "Lubuk/Lubuak Malako",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sangir Jujuan",
                "kelurahan": "Malako",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Sako Selatan Pasia Talang",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Sako Utara Pasia Talang",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sangir Jujuan",
                "kelurahan": "Bidar Alam",
                "kodepos": "27777"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pulakek Koto Baru",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Sako Pasia Talang",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pasir Talang Timur",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Pauh Duo",
                "kelurahan": "Pauh Duo Nan Batigo",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pasir Talang Barat",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pasir Talang Selatan",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pasar Muara Labuh",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Pasir Talang",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Pauh Duo",
                "kelurahan": "Luak Kapau Alam Pauh Duo",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Koto Baru",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Pauh Duo",
                "kelurahan": "Kapau Alam Pauh Duo",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Sungai Pagu",
                "kelurahan": "Bomas",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Koto Parik Gadang Diateh",
                "kelurahan": "Pakan Rabaa Utara",
                "kodepos": "27775"
            },
            {
                "kecamatan": "Pauh Duo",
                "kelurahan": "Alam Pauh Duo",
                "kodepos": "27776"
            },
            {
                "kecamatan": "Koto Parik Gadang Diateh",
                "kelurahan": "Pakan Rabaa Tengah",
                "kodepos": "27775"
            },
            {
                "kecamatan": "Koto Parik Gadang Diateh",
                "kelurahan": "Pakan Rabaa Timur",
                "kodepos": "27775"
            },
            {
                "kecamatan": "Koto Parik Gadang Diateh",
                "kelurahan": "Pakan Rabaa",
                "kodepos": "27775"
            }
        ],
        "k411": [
            {
                "kecamatan": "Asam Jujuhan",
                "kelurahan": "Tanjung Alam",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Sungai Rumbai Timur",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Asam Jujuhan",
                "kelurahan": "Sei/Sungai Limau",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Asam Jujuhan",
                "kelurahan": "Sinamar",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Sungai Rumbai",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Asam Jujuhan",
                "kelurahan": "Lubuk Besar",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Kurnia Selatan",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Koto Tinggi",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Sungai Rumbai",
                "kelurahan": "Kurnia Koto Salak",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Koto Ranah",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Koto Laweh",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Koto Gadang",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Koto Besar",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Asam Jujuhan",
                "kelurahan": "Alahan Nan Tigo",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Bonjol",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Tiumang",
                "kelurahan": "Tiumang",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Besar",
                "kelurahan": "Abai Siat",
                "kodepos": "27684"
            },
            {
                "kecamatan": "Padang Laweh",
                "kelurahan": "Sopan Jaya",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Tiumang",
                "kelurahan": "Sipangkur",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Salak",
                "kelurahan": "Simalidu",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Sembilan Koto (IX Koto)",
                "kelurahan": "Silago",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Simalidu",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Sialang Gaung",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Tiumang",
                "kelurahan": "Sei/Sungai Langkok",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Pulau Mainan",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Salak",
                "kelurahan": "Padukuan",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Salak",
                "kelurahan": "Pulau Mainan",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Padang Laweh",
                "kelurahan": "Padang Laweh",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Padukuan",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Sembilan Koto (IX Koto)",
                "kelurahan": "Lubuk Karak",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Sembilan Koto (IX Koto)",
                "kelurahan": "Nan IV Bawauah",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Padang Laweh",
                "kelurahan": "Muaro Sopan",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Koto Baru",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Koto Padang",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Salak",
                "kelurahan": "Koto Salak",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Padang Laweh",
                "kelurahan": "Batu Rijal",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Tiumang",
                "kelurahan": "Koto Baringin",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Sembilan Koto (IX Koto)",
                "kelurahan": "Banai",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Ampang Kuranji",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Baru",
                "kelurahan": "Ampalu",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Koto Salak",
                "kelurahan": "Ampalu",
                "kodepos": "27681"
            },
            {
                "kecamatan": "Timpeh",
                "kelurahan": "Tabek (Penyebrangan)",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Timpeh",
                "kelurahan": "Taratak Tinggi",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Timpeh",
                "kelurahan": "Timpeh",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Sitiung",
                "kelurahan": "Sei/Sungai Duo",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Sitiung",
                "kelurahan": "Siguntur",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Sitiung",
                "kelurahan": "Sitiung",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Timpeh",
                "kelurahan": "Panyuberangan",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Timpeh",
                "kelurahan": "Ranah Palabi",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Sungai Kambut",
                "kodepos": "27573"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "27573"
            },
            {
                "kecamatan": "Sitiung",
                "kelurahan": "Gunung Medan",
                "kodepos": "27678"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Gunung Selasih",
                "kodepos": "27573"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Sikabau",
                "kodepos": "27573"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Sungai Dareh",
                "kodepos": "27573"
            },
            {
                "kecamatan": "Pulau Punjung",
                "kelurahan": "Empat Koto Pulau Punjung",
                "kodepos": "27573"
            }
        ],
        "k412": [
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Tanjung Kaliang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Siaur",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Sungai Batuang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Sungai Lansek",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Maloro",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Muaro Takuang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Padang Tarok",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Kamang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Kunangan Parik Rantang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Lubuk Tarantang",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Timbulun",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Kamang Baru",
                "kelurahan": "Aia Amo",
                "kodepos": "27572"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Taratak Baru Utara",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Tanjung Lolo",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Taratak Baru",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Sibakur",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Sinyamu",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Tanjung Gadang",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Langki",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Tanjung Gadang",
                "kelurahan": "Pulasan",
                "kodepos": "27571"
            },
            {
                "kecamatan": "Kupitan",
                "kelurahan": "Desa Kampung Baru",
                "kodepos": "27564"
            },
            {
                "kecamatan": "Kupitan",
                "kelurahan": "Padang Sibusuk",
                "kodepos": "27564"
            },
            {
                "kecamatan": "Kupitan",
                "kelurahan": "Pamuatan",
                "kodepos": "27564"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Unggan",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Kupitan",
                "kelurahan": "Batu Manjulur",
                "kodepos": "27564"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Tanjung Bonai Aur",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Tanjung Bonai Aur Selatan",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Sumpur Kudus Selatan",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Tamparungo",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Tanjuang Labuah",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Sumpur Kudus",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Sisawah",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Kumanis",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Mangganti",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Sumpur Kudus",
                "kelurahan": "Silantai",
                "kodepos": "27563"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Palaluar",
                "kodepos": "27562"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Tanjung",
                "kodepos": "27562"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Padang Laweh",
                "kodepos": "27562"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Limo Koto",
                "kodepos": "27562"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Bukit Bual",
                "kodepos": "27562"
            },
            {
                "kecamatan": "Koto VII",
                "kelurahan": "Guguk",
                "kodepos": "27562"
            },
            {
                "kecamatan": "IV Nagari",
                "kelurahan": "Mundam Sakti",
                "kodepos": "27561"
            },
            {
                "kecamatan": "IV Nagari",
                "kelurahan": "Palangki",
                "kodepos": "27561"
            },
            {
                "kecamatan": "IV Nagari",
                "kelurahan": "Koto Baru",
                "kodepos": "27561"
            },
            {
                "kecamatan": "IV Nagari",
                "kelurahan": "Koto Tuo",
                "kodepos": "27561"
            },
            {
                "kecamatan": "IV Nagari",
                "kelurahan": "Muaro Bodi",
                "kodepos": "27561"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Silongo",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Silokek",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Paru",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Pematang Panjang",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Sijunjung",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Latang",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Lubuak Tarok",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Muaro",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Lalan",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Kampung Dalam",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Kandang Baru",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Lubuak Tarok",
                "kelurahan": "Buluah Kasok",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Durian Gadang",
                "kodepos": "27553"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Solok Ambah",
                "kodepos": "27511"
            },
            {
                "kecamatan": "Sijunjung",
                "kelurahan": "Aie Angek",
                "kodepos": "27553"
            }
        ],
        "k413": [
            {
                "kecamatan": "Talawi",
                "kelurahan": "Rantih",
                "kodepos": "27449"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Salak",
                "kodepos": "27447"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Sikalang",
                "kodepos": "27448"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Talawi Hilir/Hilia",
                "kodepos": "27445"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Talawi Hilir/Hilia",
                "kodepos": "27445"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Kumbayau",
                "kodepos": "27443"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Bukik Gadang",
                "kodepos": "27444"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Talawi Mudik/Mudiak",
                "kodepos": "27444"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Tumpuak Tangah",
                "kodepos": "27442"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Batu Tanjuang",
                "kodepos": "27443"
            },
            {
                "kecamatan": "Silungkang",
                "kelurahan": "Muaro Kalaban",
                "kodepos": "27435"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Datar Mansiang",
                "kodepos": "27441"
            },
            {
                "kecamatan": "Silungkang",
                "kelurahan": "Silungkang Oso",
                "kodepos": "27433"
            },
            {
                "kecamatan": "Silungkang",
                "kelurahan": "Taratak Bancah/Boncah",
                "kodepos": "27434"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Kolok Nan Tuo",
                "kodepos": "27427"
            },
            {
                "kecamatan": "Silungkang",
                "kelurahan": "Silungkang Tigo",
                "kodepos": "27431"
            },
            {
                "kecamatan": "Silungkang",
                "kelurahan": "Silungkang Duo",
                "kodepos": "27432"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Durian II",
                "kodepos": "27425"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Kolok Mudik/Mudiak",
                "kodepos": "27425"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Durian I",
                "kodepos": "27425"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Santur/Santua",
                "kodepos": "27424"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Talago Gunung/Gunuang",
                "kodepos": "27423"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Lubang Panjang",
                "kodepos": "27424"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Saringan",
                "kodepos": "27421"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Balai Batu Sandaran",
                "kodepos": "27422"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Air/Aia Dingin",
                "kodepos": "27419"
            },
            {
                "kecamatan": "Barangin",
                "kelurahan": "Lumindai",
                "kodepos": "27421"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Lunto Timur",
                "kodepos": "27416"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Air Mulyo (Aurmulyo)",
                "kodepos": "27418"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Lunto Barat",
                "kodepos": "27418"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Kubang Sirakuk/Sirakuak Utara",
                "kodepos": "27415"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Pasar Kubang",
                "kodepos": "27415"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Kubang Tangah",
                "kodepos": "27413"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Kubang Sirakuk/Sirakuak Selatan",
                "kodepos": "27415"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Pasar",
                "kodepos": "27411"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Tanah Lapang",
                "kodepos": "27411"
            },
            {
                "kecamatan": "Lembah Segar",
                "kelurahan": "Kubang Utara Sikabu",
                "kodepos": "27412"
            }
        ],
        "k414": [
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Taruang Taruang",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Siaro Aro",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Sungai Durian",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Sungai Jambur",
                "kodepos": "27388"
            },
            {
                "kecamatan": "Junjung Sirih",
                "kelurahan": "Paninggahan",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Pianggu",
                "kodepos": "27388"
            },
            {
                "kecamatan": "Junjung Sirih",
                "kelurahan": "Muaro Pingai",
                "kodepos": "27388"
            },
            {
                "kecamatan": "Junjung Sirih",
                "kelurahan": "Muaro Pingai",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Guguk Sarai",
                "kodepos": "27388"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Indudur",
                "kodepos": "27388"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Supayang",
                "kodepos": "27387"
            },
            {
                "kecamatan": "IX Koto Sei Lasi",
                "kelurahan": "Bukit Bais",
                "kodepos": "27388"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Sirukam",
                "kodepos": "27387"
            },
            {
                "kecamatan": "Danau Kembar",
                "kelurahan": "Simpang Tanjuang Nan Ampek/IV",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Payung Sekaki",
                "kelurahan": "Aia Luo",
                "kodepos": "27387"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Salayo Tanang Bukik Sileh",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Koto Laweh",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Limau Lunggo",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Koto Anau",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Danau Kembar",
                "kelurahan": "Kampung Batu Dalam",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Batu Banyak",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Lembang Jaya",
                "kelurahan": "Batu Bajanjang",
                "kodepos": "27383"
            },
            {
                "kecamatan": "Bukit Sundi",
                "kelurahan": "Parambahan",
                "kodepos": "27381"
            },
            {
                "kecamatan": "Bukit Sundi",
                "kelurahan": "Muaro Paneh",
                "kodepos": "27381"
            },
            {
                "kecamatan": "Bukit Sundi",
                "kelurahan": "Kinari",
                "kodepos": "27381"
            },
            {
                "kecamatan": "Bukit Sundi",
                "kelurahan": "Dilam",
                "kodepos": "27381"
            },
            {
                "kecamatan": "Bukit Sundi",
                "kelurahan": "Bukit Tandang",
                "kodepos": "27381"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Lolo",
                "kodepos": "27373"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Surian",
                "kodepos": "27373"
            },
            {
                "kecamatan": "Tigo Lurah",
                "kelurahan": "Tanjung Balik Sumiso",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Hiliran Gumanti",
                "kelurahan": "Talang Babungo",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Tigo Lurah",
                "kelurahan": "Simanau",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Hiliran Gumanti",
                "kelurahan": "Sungai Abu",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Hiliran Gumanti",
                "kelurahan": "Sarik Alahan Tigo",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Tigo Lurah",
                "kelurahan": "Rangkiang Luluih",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Tigo Lurah",
                "kelurahan": "Garabak Data",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Tigo Lurah",
                "kelurahan": "Batu Banjajang",
                "kodepos": "27372"
            },
            {
                "kecamatan": "Lembah Gumanti",
                "kelurahan": "Sungai Nanam",
                "kodepos": "27371"
            },
            {
                "kecamatan": "Lembah Gumanti",
                "kelurahan": "Salimpat",
                "kodepos": "27371"
            },
            {
                "kecamatan": "Lembah Gumanti",
                "kelurahan": "Alahan Panjang",
                "kodepos": "27371"
            },
            {
                "kecamatan": "Lembah Gumanti",
                "kelurahan": "Aia Dingin",
                "kodepos": "27371"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Talang",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Sungai Janiah",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Koto Gaek Guguk",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Koto Gadang Guguak",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Jawi Jawi",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Cupak",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Batang Barus",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Gunung Talang",
                "kelurahan": "Aia Batumbuak",
                "kodepos": "27365"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Tanjung Bingkuang",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Tanjung",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Saok Laweh",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Panyakalan",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Salayo",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Koto Hilalang",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Koto Baru",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Gauang",
                "kodepos": "27361"
            },
            {
                "kecamatan": "Kubung",
                "kelurahan": "Gantuang Ciri",
                "kodepos": "27361"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Tanjung Alai",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Tikalak",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Sumani",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Saning Baka",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Singkarak",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Aripan",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Kacang",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Singkarak",
                "kelurahan": "Koto Sani",
                "kodepos": "27356"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Sulik Aia",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Tanjung Balik",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Pasilihan",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Siberambang",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Nagari Sulit Air",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Paninjauan",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Labuah Panjang",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Katialo",
                "kodepos": "27355"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Kuncir",
                "kodepos": "27355"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Laing",
                "kodepos": "27325"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Nan Balimo",
                "kodepos": "27326"
            },
            {
                "kecamatan": "X Koto Diatas",
                "kelurahan": "Bukit Kanduang",
                "kodepos": "27355"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Koto Panjang",
                "kodepos": "27323"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Tanjung Paku",
                "kodepos": "27324"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Kampung Jawa",
                "kodepos": "27321"
            },
            {
                "kecamatan": "Tanjung Harapan",
                "kelurahan": "Pasar Pandan Air Mati",
                "kodepos": "27322"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "Simpang Rumbio",
                "kodepos": "27316"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "Aro IV Korong",
                "kodepos": "27317"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "Sinapa Piliang",
                "kodepos": "27313"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "IX Korong",
                "kodepos": "27314"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "Kampai Tabu Kerambil/Karambia",
                "kodepos": "27315"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "VI Suku",
                "kodepos": "27311"
            },
            {
                "kecamatan": "Lubuk Sikarah",
                "kelurahan": "Tanah Garam",
                "kodepos": "27312"
            }
        ],
        "k415": [
            {
                "kecamatan": "Sungayang",
                "kelurahan": "Sungayang",
                "kodepos": "27294"
            },
            {
                "kecamatan": "Sungayang",
                "kelurahan": "Tanjung (Tanjuang)",
                "kodepos": "27294"
            },
            {
                "kecamatan": "Sungayang",
                "kelurahan": "Sungai Patai",
                "kodepos": "27294"
            },
            {
                "kecamatan": "Sungayang",
                "kelurahan": "Minangkabau",
                "kodepos": "27294"
            },
            {
                "kecamatan": "Lintau Buo",
                "kelurahan": "Tigo Jangko",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Sungayang",
                "kelurahan": "Andaleh Baruh/Baruah Bukik",
                "kodepos": "27294"
            },
            {
                "kecamatan": "Lintau Buo Utara",
                "kelurahan": "Tapi/Tepi Selo",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo",
                "kelurahan": "Taluak",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo Utara",
                "kelurahan": "Tanjung/Tanjuang Bonai",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo",
                "kelurahan": "Pangian",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo Utara",
                "kelurahan": "Lubuk/Lubuak Jantan",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo",
                "kelurahan": "Buo",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Lintau Buo Utara",
                "kelurahan": "Batu Bulek",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Padang Ganting",
                "kelurahan": "Padang Ganting",
                "kodepos": "27282"
            },
            {
                "kecamatan": "Lintau Buo Utara",
                "kelurahan": "Balai Tangah",
                "kodepos": "27292"
            },
            {
                "kecamatan": "Tanjung Emas",
                "kelurahan": "Tanjung Barulak",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Padang Ganting",
                "kelurahan": "Atar",
                "kodepos": "27282"
            },
            {
                "kecamatan": "Tanjung Baru",
                "kelurahan": "Tanjung Alam",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Tanjung Emas",
                "kelurahan": "Saruaso",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Tanjung Emas",
                "kelurahan": "Koto Tangah",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Tanjung Emas",
                "kelurahan": "Pagaruyung (Pagaruyuang)",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Tanjung Baru",
                "kelurahan": "Barulak",
                "kodepos": "27281"
            },
            {
                "kecamatan": "Rambatan",
                "kelurahan": "Simawang",
                "kodepos": "27271"
            },
            {
                "kecamatan": "Rambatan",
                "kelurahan": "Padang Magek",
                "kodepos": "27271"
            },
            {
                "kecamatan": "Rambatan",
                "kelurahan": "Rambatan",
                "kodepos": "27271"
            },
            {
                "kecamatan": "Rambatan",
                "kelurahan": "Balimbing",
                "kodepos": "27271"
            },
            {
                "kecamatan": "Rambatan",
                "kelurahan": "III/Tigo Koto",
                "kodepos": "27271"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Tanjung Barulak",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh Selatan",
                "kelurahan": "Sumpur",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Pitalah",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Sabu",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Gunung Rajo (Gunuang Rajo)",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh Selatan",
                "kelurahan": "Padang Laweh Malalo",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Bungo Tanjung/Tanjuang",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh Selatan",
                "kelurahan": "Guguak Malalo",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Batipuh Baru (Batipuah Baruah)",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh Selatan",
                "kelurahan": "Batu Taba",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Batipuh Ateh (Batipuah Ateh)",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Batipuh",
                "kelurahan": "Batipuh Ateh (Batipuah Ateh)",
                "kodepos": "27265"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Sungai Jambu",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Tabek",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Sawah Tangah",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Simabur",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Batu Basa",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Pariangan",
                "kelurahan": "Pariangan",
                "kodepos": "27264"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Supayang",
                "kodepos": "27263"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Situmbuk (Situmbuak)",
                "kodepos": "27263"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Sumanik (Sumaniak}",
                "kodepos": "27263"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Lawang Mandahiling/Mandahiliang",
                "kodepos": "27263"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Salimpaung",
                "kodepos": "27263"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Talang Tangah",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Salimpaung",
                "kelurahan": "Tabek Patah",
                "kodepos": "27262"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Simpuruik",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Sungai Tarab",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Pasie/Pasir Laweh",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Rao-Rao",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Padang Laweh",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Kumango",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Koto Baru",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Koto Tuo",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Lima Kaum",
                "kelurahan": "Cubadak",
                "kodepos": "27216"
            },
            {
                "kecamatan": "Sungai Tarab",
                "kelurahan": "Gurun",
                "kodepos": "27261"
            },
            {
                "kecamatan": "Lima Kaum",
                "kelurahan": "Baringin",
                "kodepos": "27213"
            },
            {
                "kecamatan": "Lima Kaum",
                "kelurahan": "Parambahan",
                "kodepos": "27211"
            },
            {
                "kecamatan": "Lima Kaum",
                "kelurahan": "Labuah/Labuh",
                "kodepos": "27211"
            },
            {
                "kecamatan": "Lima Kaum",
                "kelurahan": "Lima Kaum (Limo Kaum)",
                "kodepos": "27211"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Tambangan",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Panyalaian",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Singgalang",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Pandai Sikek",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Paninjauan",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Koto Baru",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Koto Laweh",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Aie Angek (Aia/Air Angek)",
                "kodepos": "27151"
            },
            {
                "kecamatan": "Sepuluh Koto (X Koto)",
                "kelurahan": "Jaho",
                "kodepos": "27151"
            }
        ],
        "k416": [
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Guguk Malintang",
                "kodepos": "27128"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Sigando",
                "kodepos": "27126"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Ganting",
                "kodepos": "27127"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Koto Katik",
                "kodepos": "27123"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Ngalau",
                "kodepos": "27124"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Ekor Lubuk",
                "kodepos": "27125"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Tanah Pak Lambik",
                "kodepos": "27121"
            },
            {
                "kecamatan": "Padang Panjang Timur",
                "kelurahan": "Koto Panjang",
                "kodepos": "27122"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Silaing Bawah",
                "kodepos": "27118"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Pasar Usang",
                "kodepos": "27116"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Silaing Atas",
                "kodepos": "27117"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Bukik/Bukit Surungan",
                "kodepos": "27115"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Pasar Baru",
                "kodepos": "27113"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Balai-Balai",
                "kodepos": "27114"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Kampung Manggis",
                "kodepos": "27111"
            },
            {
                "kecamatan": "Padang Panjang Barat",
                "kelurahan": "Tanah Hitam",
                "kodepos": "27112"
            }
        ],
        "k417": [
            {
                "kecamatan": "Sei Beremas",
                "kelurahan": "Aia Bangih",
                "kodepos": "26573"
            },
            {
                "kecamatan": "Sungai Aur",
                "kelurahan": "Sungai Aur",
                "kodepos": "26573"
            },
            {
                "kecamatan": "Koto Balingka",
                "kelurahan": "Parit/Parik",
                "kodepos": "26572"
            },
            {
                "kecamatan": "Lembah Melintang",
                "kelurahan": "Ujung Gading",
                "kodepos": "26572"
            },
            {
                "kecamatan": "Gunung Tuleh",
                "kelurahan": "Rabi Jonggor",
                "kodepos": "26571"
            },
            {
                "kecamatan": "Luhak Nan Duo",
                "kelurahan": "Koto Baru",
                "kodepos": "26567"
            },
            {
                "kecamatan": "Gunung Tuleh",
                "kelurahan": "Muaro Kiawi",
                "kodepos": "26571"
            },
            {
                "kecamatan": "Kinali",
                "kelurahan": "Katiagan Mandiangin",
                "kodepos": "26567"
            },
            {
                "kecamatan": "Kinali",
                "kelurahan": "Kinali",
                "kodepos": "26567"
            },
            {
                "kecamatan": "Pasaman",
                "kelurahan": "Lingkuang Aua",
                "kodepos": "26566"
            },
            {
                "kecamatan": "Luhak Nan Duo",
                "kelurahan": "Kapar",
                "kodepos": "26567"
            },
            {
                "kecamatan": "Pasaman",
                "kelurahan": "Aia Gadang",
                "kodepos": "26566"
            },
            {
                "kecamatan": "Pasaman",
                "kelurahan": "Aua Kuniang",
                "kodepos": "26566"
            },
            {
                "kecamatan": "Talamau",
                "kelurahan": "Talu",
                "kodepos": "26561"
            },
            {
                "kecamatan": "Talamau",
                "kelurahan": "Sinuruik",
                "kodepos": "26561"
            },
            {
                "kecamatan": "Talamau",
                "kelurahan": "Kajai",
                "kodepos": "26561"
            },
            {
                "kecamatan": "Sasak Ranah Pasisir/Pesisie",
                "kelurahan": "Sasak",
                "kodepos": "26366"
            },
            {
                "kecamatan": "Ranah Batahan",
                "kelurahan": "Desa Baru",
                "kodepos": "26366"
            },
            {
                "kecamatan": "Ranah Batahan",
                "kelurahan": "Batahan",
                "kodepos": "26366"
            }
        ],
        "k418": [
            {
                "kecamatan": "Tanjung Mutiara",
                "kelurahan": "Tiku Selatan",
                "kodepos": "26473"
            },
            {
                "kecamatan": "Tanjung Mutiara",
                "kelurahan": "Tiku Utara",
                "kodepos": "26473"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Tanjung Sani",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Mutiara",
                "kelurahan": "Tiku Limo Jorong",
                "kodepos": "26473"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Maninjau",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Paninjauan",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Sungai Batang",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Koto Kaciak",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Koto Malintang",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Duo Koto",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Koto Gadang",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Lubuk Basung",
                "kelurahan": "Manggopoh",
                "kodepos": "26451"
            },
            {
                "kecamatan": "Tanjung Raya",
                "kelurahan": "Bayua",
                "kodepos": "26471"
            },
            {
                "kecamatan": "Lubuk Basung",
                "kelurahan": "Kampung Tangah",
                "kodepos": "26451"
            },
            {
                "kecamatan": "Lubuk Basung",
                "kelurahan": "Lubuk Basung",
                "kodepos": "26451"
            },
            {
                "kecamatan": "Lubuk Basung",
                "kelurahan": "Geragahan",
                "kodepos": "26451"
            },
            {
                "kecamatan": "Lubuk Basung",
                "kelurahan": "Kampung Pinang",
                "kodepos": "26451"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Simarasok",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Tabek Panjang",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Padang Tarok",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Salo",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Koto Baru III Jorong",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Koto Tinggi",
                "kodepos": "26192"
            },
            {
                "kecamatan": "Baso",
                "kelurahan": "Bungo Koto Tuo",
                "kodepos": "26192"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Pasia",
                "kodepos": "26191"
            },
            {
                "kecamatan": "Candung",
                "kelurahan": "Lasi",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Panampung",
                "kodepos": "26191"
            },
            {
                "kecamatan": "Candung",
                "kelurahan": "Canduang Koto Laweh",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Lambah",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Biaro Gadang",
                "kodepos": "26191"
            },
            {
                "kecamatan": "Candung",
                "kelurahan": "Bukik Batabuah",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Balai Gurah",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Batu Taba",
                "kodepos": "26191"
            },
            {
                "kecamatan": "IV Angkat Candung (Ampek Angkek)",
                "kelurahan": "Ampang Gadang",
                "kodepos": "26191"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Taluak Ampek Suku",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Sungai Pua (Puar)",
                "kelurahan": "Sungai Pua",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Sungai Pua (Puar)",
                "kelurahan": "Sariak",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Padang Lua",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Pakan Sinayan",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Sungai Pua (Puar)",
                "kelurahan": "Padang Laweh",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Ladang Laweh",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Kubang Putiah",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Banuhampu",
                "kelurahan": "Cingkariang",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Sungai Pua (Puar)",
                "kelurahan": "Batu Palano",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Sungai Pua (Puar)",
                "kelurahan": "Batagak",
                "kodepos": "26181"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Tigo Koto Silungkang",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Sungai Puar",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Salareh Aia",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Sipinang",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Baringin",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Palembayan",
                "kelurahan": "Ampek Koto Palembayan",
                "kodepos": "26164"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Tigo Balai",
                "kodepos": "26162"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Parik Panjang",
                "kodepos": "26162"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Panta Pauh",
                "kodepos": "26162"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Matur Mudiak",
                "kodepos": "26162"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Matur Hilia",
                "kodepos": "26162"
            },
            {
                "kecamatan": "Matur",
                "kelurahan": "Lawang",
                "kodepos": "26162"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Sungai Landia",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Ampek Nagari (IV Nagari )",
                "kelurahan": "Sitalang",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Ampek Nagari (IV Nagari )",
                "kelurahan": "Sitanang",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Sianok Anam Suku",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Sianok Anam Suku",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Malakak",
                "kelurahan": "Malalak Timur",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Malakak",
                "kelurahan": "Malalak Selatan",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Koto Tuo",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Malakak",
                "kelurahan": "Malalak Barat",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Koto Panjang",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Koto Gadang",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Guguk Tabek Sarajo",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Ampek Nagari (IV Nagari )",
                "kelurahan": "Bawan",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Ampek Nagari (IV Nagari )",
                "kelurahan": "Batu Kambing",
                "kodepos": "26161"
            },
            {
                "kecamatan": "IV Koto (Ampek Koto)",
                "kelurahan": "Balingka",
                "kodepos": "26161"
            },
            {
                "kecamatan": "Kamang Magek",
                "kelurahan": "Magek",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Tilatang Kamang",
                "kelurahan": "Koto Tangah",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Kamang Magek",
                "kelurahan": "Kamang Mudiak",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Tilatang Kamang",
                "kelurahan": "Kapau",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Tilatang Kamang",
                "kelurahan": "Gaduk",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Kamang Magek",
                "kelurahan": "Kamang Hilia",
                "kodepos": "26152"
            },
            {
                "kecamatan": "Palupuh",
                "kelurahan": "Pagadih",
                "kodepos": "26151"
            },
            {
                "kecamatan": "Palupuh",
                "kelurahan": "Pasia Laweh",
                "kodepos": "26151"
            },
            {
                "kecamatan": "Palupuh",
                "kelurahan": "Koto Rantang",
                "kodepos": "26151"
            },
            {
                "kecamatan": "Palupuh",
                "kelurahan": "Nan Tujuah",
                "kodepos": "26151"
            }
        ],
        "k419": [
            {
                "kecamatan": "Simpang Alahan Mati",
                "kelurahan": "Simpang",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Bonjol",
                "kelurahan": "Limo Koto",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Bonjol",
                "kelurahan": "Koto Kaciak",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Bonjol",
                "kelurahan": "Ganggo Hilia",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Bonjol",
                "kelurahan": "Ganggo Mudiak",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Simpang Alahan Mati",
                "kelurahan": "Alahan Mati",
                "kodepos": "26381"
            },
            {
                "kecamatan": "Rao",
                "kelurahan": "Taruang Taruang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Selatan",
                "kelurahan": "Tanjung Betung",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Mapat Tunggul",
                "kelurahan": "Pintu Padang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Mapat Tunggul Selatan",
                "kelurahan": "Silayang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Mapat Tunggul Selatan",
                "kelurahan": "Muaro Sei/Sungai Lolo",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao",
                "kelurahan": "Padang Metinggi/Mantinggi",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Tigo Nagari (III Nagari)",
                "kelurahan": "Malampah",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Mapat Tunggul",
                "kelurahan": "Muara Tais",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Selatan",
                "kelurahan": "Lubuak Layang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Mapat Tunggul",
                "kelurahan": "Lubuk/Lubuak Gadang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Selatan",
                "kelurahan": "Lansek Kadok",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Tigo Nagari (III Nagari)",
                "kelurahan": "Ladang Panjang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Utara",
                "kelurahan": "Koto Rajo",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Utara",
                "kelurahan": "Languang",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Rao Utara",
                "kelurahan": "Koto Nopan",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Panti",
                "kelurahan": "Panti",
                "kodepos": "26352"
            },
            {
                "kecamatan": "Tigo Nagari (III Nagari)",
                "kelurahan": "Binjai",
                "kodepos": "26353"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Tanjuang Baringin",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Padang Gelugur",
                "kelurahan": "Padang Gelugur",
                "kodepos": "26352"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Sundata",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Pauah",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Aia Manggih",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Durian Tinggi",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Lubuk Sikaping",
                "kelurahan": "Jambak",
                "kodepos": "26318"
            },
            {
                "kecamatan": "Duo Koto (II Koto)",
                "kelurahan": "Cubadak (Pasa Cubadak)",
                "kodepos": "26311"
            },
            {
                "kecamatan": "Duo Koto (II Koto)",
                "kelurahan": "Simpang Tonang",
                "kodepos": "26311"
            }
        ],
        "k420": [
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Muaro Paiti",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Sialang",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Koto Lamo",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Lubuk/Lubuak Alai",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Durian Tinggi",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Galugua/Gelugur",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Kapur IX/Sembilan",
                "kelurahan": "Koto Bangun",
                "kodepos": "26273"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Pangkalan",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Tanjung/Tanjuang Balik",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Tanjung/Tanjuang Pauah",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Manggilang",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Tarantang",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Gunuang Malintang",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Pangkalan Koto Baru",
                "kelurahan": "Koto Alam",
                "kodepos": "26272"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Solok Bio-Bio",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Taram",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Pilubang",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Sarilamak",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Koto Tuo",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Lubuak Batingkok",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Harau",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Batu Balang",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Bukik Limbuku",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Harau",
                "kelurahan": "Gurun",
                "kodepos": "26271"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Sitanang",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Tanjung/Tanjuang Gadang",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Halaban",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Labuah Gunuang",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Balai Panjang",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Batu Payung",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Bukik Sikumpa",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Luak (Luhak)",
                "kelurahan": "Tanjung Haro (Sikabu-Kabu/Tanjuang Haro)",
                "kodepos": "26261"
            },
            {
                "kecamatan": "Lareh Sago Halaban",
                "kelurahan": "Ampalu",
                "kodepos": "26262"
            },
            {
                "kecamatan": "Luak (Luhak)",
                "kelurahan": "Mungo",
                "kodepos": "26261"
            },
            {
                "kecamatan": "Luak (Luhak)",
                "kelurahan": "Sungai Kamuyang",
                "kodepos": "26261"
            },
            {
                "kecamatan": "Bukik Barisan",
                "kelurahan": "Sungai Naniang",
                "kodepos": "26257"
            },
            {
                "kecamatan": "Luak (Luhak)",
                "kelurahan": "Andaleh",
                "kodepos": "26261"
            },
            {
                "kecamatan": "Bukik Barisan",
                "kelurahan": "Baruah Gunuang",
                "kodepos": "26257"
            },
            {
                "kecamatan": "Bukik Barisan",
                "kelurahan": "Koto Tangah",
                "kodepos": "26257"
            },
            {
                "kecamatan": "Bukik Barisan",
                "kelurahan": "Maek",
                "kodepos": "26257"
            },
            {
                "kecamatan": "Gunuang Omeh (Gunung Mas)",
                "kelurahan": "Talang Anau",
                "kodepos": "26256"
            },
            {
                "kecamatan": "Bukik Barisan",
                "kelurahan": "Banja Laweh",
                "kodepos": "26257"
            },
            {
                "kecamatan": "Gunuang Omeh (Gunung Mas)",
                "kelurahan": "Koto Tinggi",
                "kodepos": "26256"
            },
            {
                "kecamatan": "Gunuang Omeh (Gunung Mas)",
                "kelurahan": "Pandam Gadang",
                "kodepos": "26256"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Sungai Rimbang",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Tanjuang Bungo",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Kurai",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Limbanang",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Suliki",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Suliki",
                "kelurahan": "Andiang",
                "kodepos": "26255"
            },
            {
                "kecamatan": "Mungka",
                "kelurahan": "Simpang Kapuak",
                "kodepos": "26254"
            },
            {
                "kecamatan": "Mungka",
                "kelurahan": "Sungai Antuan",
                "kodepos": "26254"
            },
            {
                "kecamatan": "Mungka",
                "kelurahan": "Talang Maua",
                "kodepos": "26254"
            },
            {
                "kecamatan": "Mungka",
                "kelurahan": "Jopang Manganti",
                "kodepos": "26254"
            },
            {
                "kecamatan": "Mungka",
                "kelurahan": "Mungka",
                "kodepos": "26254"
            },
            {
                "kecamatan": "Guguak (Gugu)",
                "kelurahan": "Simpang Sugiran",
                "kodepos": "26253"
            },
            {
                "kecamatan": "Guguak (Gugu)",
                "kelurahan": "Sungai Talang",
                "kodepos": "26253"
            },
            {
                "kecamatan": "Guguak (Gugu)",
                "kelurahan": "VII/Tujuh Koto Talago",
                "kodepos": "26253"
            },
            {
                "kecamatan": "Guguak (Gugu)",
                "kelurahan": "Guguak VIII Koto",
                "kodepos": "26253"
            },
            {
                "kecamatan": "Guguak (Gugu)",
                "kelurahan": "Kubang",
                "kodepos": "26253"
            },
            {
                "kecamatan": "Situjuah Limo/Lima Nagari",
                "kelurahan": "Tungka",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Suayan",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Situjuah Limo/Lima Nagari",
                "kelurahan": "Situjuah Gadang",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Situjuah Limo/Lima Nagari",
                "kelurahan": "Situjuah Ladang Laweh",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Situjuah Limo/Lima Nagari",
                "kelurahan": "Situjuah Batua",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Situjuah Limo/Lima Nagari",
                "kelurahan": "Situjuah Banda Dalam",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Sariak Laweh",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Sei/Sungai Balantik",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Durian Gadang",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Koto Tangah Batu Hampa",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Pauh Sangik",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Taeh Bukik",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Akabiluru",
                "kelurahan": "Batu Hampa",
                "kodepos": "26252"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Sungai Beringin",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Taeh Baruah",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Koto Tangah Simalanggang",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Piobang",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Simalanggang",
                "kodepos": "26251"
            },
            {
                "kecamatan": "Payakumbuh",
                "kelurahan": "Koto Baru Simalanggang",
                "kodepos": "26251"
            }
        ],
        "k421": [
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Payobasung/Payobasuang",
                "kodepos": "26237"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Koto Baru",
                "kodepos": "26237"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Koto Panjang",
                "kodepos": "26237"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Bodi",
                "kodepos": "26235"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Padang Alai",
                "kodepos": "26236"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Balai Jaring/Jariang",
                "kodepos": "26235"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Sicincin Hilir/Hilie",
                "kodepos": "26233"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Sicincin Mudik/Mudiak",
                "kodepos": "26233"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Ranah",
                "kodepos": "26231"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Padang Tiakar Hilir/Hilie",
                "kodepos": "26232"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Padang Tiakar Mudik/Mudiak",
                "kodepos": "26232"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Balai Batimah",
                "kodepos": "26231"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Balai Nan Tuo",
                "kodepos": "26231"
            },
            {
                "kecamatan": "Payakumbuh Timur",
                "kelurahan": "Padang Tangah Payobadar",
                "kodepos": "26231"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Labuh/Labuah Basilang",
                "kodepos": "26229"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Tanjung Pauh/Tanjuang Pauah",
                "kodepos": "26229"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Kapalo Koto",
                "kodepos": "26228"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Sawah Padang",
                "kodepos": "26228"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Ibuh",
                "kodepos": "26229"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Ampangan",
                "kodepos": "26228"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Aur Kuning/Aua Kuniang",
                "kodepos": "26228"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Limbukan",
                "kodepos": "26227"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Limo Kampung/Kampuang",
                "kodepos": "26227"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Padang Kerambil/Karambia",
                "kodepos": "26227"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Pakan Sinayan",
                "kodepos": "26226"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Koto Tuo",
                "kodepos": "26226"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Kubu Gadang",
                "kodepos": "26226"
            },
            {
                "kecamatan": "Payakumbuh Selatan",
                "kelurahan": "Balai Panjang",
                "kodepos": "26226"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Tanjung/Tanjuang Gadang",
                "kodepos": "26225"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Bulakan Balai Kandih",
                "kodepos": "26225"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Payolansek",
                "kodepos": "26225"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Talang",
                "kodepos": "26225"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Padang Tinggi",
                "kodepos": "26224"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Piliang",
                "kodepos": "26224"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Sei/Sungai Panago/Pinago",
                "kodepos": "26224"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Balai Nan Duo",
                "kodepos": "26224"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Padang Tangah",
                "kodepos": "26224"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Padang Datar",
                "kodepos": "26223"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Subarang Batung (Betung/Batuang)",
                "kodepos": "26223"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Tanah Mati",
                "kodepos": "26223"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Parik/Parit Rantang",
                "kodepos": "26222"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Koto Tangah",
                "kodepos": "26223"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Parak Batung (Betung/Batuang)",
                "kodepos": "26222"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Daya Bangun",
                "kodepos": "26221"
            },
            {
                "kecamatan": "Payakumbuh Barat",
                "kelurahan": "Nunang",
                "kodepos": "26221"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Sei/Sungai Durian",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Padang Sikabu",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Parambahan",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Parik Muko Aie (Parit Muka Air)",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Koto Panjang",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Lamposi Tigo Nagari",
                "kelurahan": "Koto Panjang Dalam",
                "kodepos": "26219"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Padang Kaduduk/Kaduduak",
                "kodepos": "26218"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Tarok",
                "kodepos": "26218"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Payonibung/Payonibuang",
                "kodepos": "26217"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Timbago/Tambago",
                "kodepos": "26217"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Napar",
                "kodepos": "26218"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Cubadak Air/Aie",
                "kodepos": "26217"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Payolinyam",
                "kodepos": "26217"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Taruko",
                "kodepos": "26216"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Tanjung Anau (Tanjuang Anau)",
                "kodepos": "26216"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Pasir/Pasie",
                "kodepos": "26215"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Betung (Batung/Batuang)",
                "kodepos": "26216"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Talawi",
                "kodepos": "26216"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Nan Kodok",
                "kodepos": "26215"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Kaning/Kaniang Bukik",
                "kodepos": "26215"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Muaro",
                "kodepos": "26215"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Gadang",
                "kodepos": "26214"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Jaring/Jariang",
                "kodepos": "26214"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Labuh/Labuah Baru",
                "kodepos": "26213"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Cacang",
                "kodepos": "26214"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Gurun",
                "kodepos": "26212"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Kalikih",
                "kodepos": "26213"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Kubu Gadang",
                "kodepos": "26213"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Balai Baru",
                "kodepos": "26212"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Koto Baru",
                "kodepos": "26211"
            },
            {
                "kecamatan": "Payakumbuh Utara",
                "kelurahan": "Bunian",
                "kodepos": "26211"
            }
        ],
        "k422": [
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Sapiran",
                "kodepos": "26137"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Birugo",
                "kodepos": "26138"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Ladang Cakiah",
                "kodepos": "26135"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Belakang Balok",
                "kodepos": "26136"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Kubu Tanjung",
                "kodepos": "26133"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Pakan Labuh/Labuah",
                "kodepos": "26134"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Parit Antang",
                "kodepos": "26132"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Manggis Ganting",
                "kodepos": "26129"
            },
            {
                "kecamatan": "Aur Birugo Tigo Baleh",
                "kelurahan": "Aur Kuning",
                "kodepos": "26131"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Campago Guguk Bulek",
                "kodepos": "26128"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Pulai Anak Air",
                "kodepos": "26125"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Koto Selayan",
                "kodepos": "26126"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Garegeh",
                "kodepos": "26127"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Puhun Tembok",
                "kodepos": "26124"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Kubu Gulai Bancah",
                "kodepos": "26122"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Puhun Pintu Kabun",
                "kodepos": "26123"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Tarok Dipo",
                "kodepos": "26117"
            },
            {
                "kecamatan": "Mandiangin Koto Selayan",
                "kelurahan": "Campago Ipuh",
                "kodepos": "26121"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Bukit Cangang Kayu Ramang",
                "kodepos": "26116"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Bukit Apit Puhun",
                "kodepos": "26114"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Kayu Kubu",
                "kodepos": "26115"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Aur Tajungkang Tengah Sawah",
                "kodepos": "26111"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Pakan Kurai",
                "kodepos": "26112"
            },
            {
                "kecamatan": "Guguk Panjang (Guguak Panjang)",
                "kelurahan": "Benteng Pasar Atas",
                "kodepos": "26113"
            }
        ],
        "k423": [
            {
                "kecamatan": "Silaut",
                "kelurahan": "Talang Binjai",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Sungaipulai",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Sungaisarik",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Sungaisirah",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Sindang Lunang",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Silaut",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Sambungo",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Utara",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Pondok Parian Lunang",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Pasir Binjai",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Selatan",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Tengah",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Tiga",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Dua",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Satu",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Lunang",
                "kelurahan": "Lunang Barat",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Durian Seribu",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Lubuk Bunta",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Silaut",
                "kelurahan": "Air Hitam",
                "kodepos": "25674"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Tluk Kualo Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Tiga Sepakat Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Tigosungai Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Tluk Amplu Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Tebing Tinggi Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Tanjungpondok Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Tanah Bakali Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Talang Koto Pulai Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Sungaipinang Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Talang Balarik Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Simpanggunung Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Sungaigambir Sako Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Simpang Lama Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Riakdanau Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Pasar Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Palokan Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Pulaurajo Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Muaro Sakai Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Muara Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Limau Purut Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Lubuk Betung Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Kudo-Kudo Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Koto Anau Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Lalang Panjang Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Kampung Tengah Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Kobu Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Indrapura/Inderapura Timur",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Indrapura/Inderapura Utara",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Indrapura/Inderapura Selatan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Indrapura/Inderapura Tengah",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Indrapura/Inderapura Barat",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Dusun Baru Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Pancung Soal",
                "kelurahan": "Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Bukitbuai Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Airpura",
                "kelurahan": "Damar Lapan Batang Inderapura",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Batang Arah Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Batang Betung Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Ranah Ampek Hulu Tapan",
                "kelurahan": "Binjai Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Sungaisirah Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Basa Ampek Balai Tapan",
                "kelurahan": "Ampang Tulak Tapan",
                "kodepos": "25673"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Punggasan Utara",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Rantau Simalenang Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Punggasan Timur",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Pasar Lama Muara Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Punggasan",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Muarakandis Punggasan",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Padang XI Punggasan",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Pasar Bukit Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Lagan Mudik Punggasan",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Muaragadang Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Air Haji Tengah",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Air Haji Tenggara",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Lagan Hilir Punggasan",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Air Haji",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Linggo Sari Baganti",
                "kelurahan": "Air Haji Barat",
                "kodepos": "25668"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Sungaitunu Barat",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Sungaitunu Utara",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Pelangai Kaciak",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Sungai Tunu",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Sungailiku Pelangai",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Pasia Palangai",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Pelangai",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Nyiur Malambai Pelangai",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Palangai Gadang",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Ranah Pesisir",
                "kelurahan": "Koto VIII Pelangai",
                "kodepos": "25666"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Lakitan Timur",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Lakitan Utara",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Taratak",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Lakitan Selatan",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Lakitan Tengah",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Rawanggunung Mamelo Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Kambang Timur",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Koto Taratak",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Kambang Utara",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Lansano Taratak",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Lakitan",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Koto Nan Tigo Selatan Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Kambang",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Koto Nan Tigo Utara Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "IV Koto Mudiek",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Lengayang",
                "kelurahan": "Kambang Barat",
                "kodepos": "25663"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Aur Duri Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Sungainyalo Mudiak Aia",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Ganting Mudiak Selatan Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Taratak Sei Lundang",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Ganting Mudiak Utara Surantih",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Siguntur Tua",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Amping Parak",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Sungai Pinang",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Sutera",
                "kelurahan": "Amping Parak Timur",
                "kodepos": "25662"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Siguntur",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Taluk Tigo Sakato",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Pulaukaram Ampang Pulai",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Teratak Tempatih IV Koto Mudiek",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Setara Nanggalo",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Tuik IV Koto Mudiek",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Kapuh Utara",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Sungainyalo IV Koto Mudiek",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Mandeh",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Taluak",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Nanggalo",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Koto Nan Duo IV Koto Hilie",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Kampungbaru Korong nan Ampek",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "Koto Nan Tigo IV Koto Hilie",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Kapuh",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Batang Kapas",
                "kelurahan": "IV Koto Hilie",
                "kodepos": "25661"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Jinang Kampung Pansur Ampang Pulai",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Barung Barung Balantai Selatan",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Cerocok Anau Ampang Pulai",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Barung-Barung Balantai Tengah",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Duku",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Barung Barung Balantai",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Duku Utara",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Ampang Pulai",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Barung-Barung Balantai Timur",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Tanjungdurian Pasar Baru",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Batu Hampa",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Sawahlaweh Pasar Baru",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Koto XI Tarusan",
                "kelurahan": "Batuhampar Selatan",
                "kodepos": "25654"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Talaok",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Puluik Puluik",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Puluik-Puluik Selatan",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Pasar Baru",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Limau Gadang Pancung Taba",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Muaro Aie",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Pancung Taba",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Nagari Bayang Utara",
                "kelurahan": "Koto Ranah",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Kubang Koto Berapak",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Koto Baru Koto Berapak",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Koto Berapak",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Kapeh Panji Jaya Talaok",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Kapelgam Koto Berapak",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Kapujan Koto Berapak",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Gurun Panjang Utara",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Gurun Panjang Barat",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Gurun Panjang Selatan",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Aur Begalung Talaok",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Gurun Panjang",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Api-api Pasar Baru",
                "kodepos": "25652"
            },
            {
                "kecamatan": "Bayang",
                "kelurahan": "Asamkamba Pasar Baru",
                "kodepos": "25652"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Taratak Tangah Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Sungaisariak Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Tambang",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Sungaigayo Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Salido",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Salido Sari Bulan",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Sungaibukik Kaciak Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Painan Timur Painan",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Sago Salido",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Painan Selatan Painan",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Painan",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Koto Rawang",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Limau Gadang Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Batu Kunik Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Bunga Pasang Salido",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Gunungbungkuak Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Ampang Tareh Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Ampuan Lumpo",
                "kodepos": "25651"
            },
            {
                "kecamatan": "IV Jurai",
                "kelurahan": "Balai Sinayan Lumpo",
                "kodepos": "25651"
            }
        ],
        "k424": [
            {
                "kecamatan": "Batang Anai",
                "kelurahan": "Kasang",
                "kodepos": "25586"
            },
            {
                "kecamatan": "Batang Anai",
                "kelurahan": "Katapiang",
                "kodepos": "25586"
            },
            {
                "kecamatan": "Batang Anai",
                "kelurahan": "Sungai Buluh",
                "kodepos": "25586"
            },
            {
                "kecamatan": "Enam Lingkung",
                "kelurahan": "Toboh Ketek",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Batang Anai",
                "kelurahan": "Buayan Lubuk Alung",
                "kodepos": "25586"
            },
            {
                "kecamatan": "Enam Lingkung",
                "kelurahan": "Parit Malintang",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Enam Lingkung",
                "kelurahan": "Sicincin",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Enam Lingkung",
                "kelurahan": "Sungai Asam",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Enam Lingkung",
                "kelurahan": "Koto Tinggi",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Enam Lingkung",
                "kelurahan": "Pakandangan",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Enam Lingkung",
                "kelurahan": "Lubuk Pandan",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Kayu Tanam",
                "kelurahan": "Kapalo Hilalang",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Kayu Tanam",
                "kelurahan": "Kayu Tanam",
                "kodepos": "25584"
            },
            {
                "kecamatan": "2 X 11 Kayu Tanam",
                "kelurahan": "Guguak",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Enam Lingkung",
                "kelurahan": "Gadua/Gadur",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Sintuk/Sintuak Toboh Gadang",
                "kelurahan": "Toboh Gadang",
                "kodepos": "25582"
            },
            {
                "kecamatan": "2 X 11 Kayu Tanam",
                "kelurahan": "Anduring (Anduriang)",
                "kodepos": "25584"
            },
            {
                "kecamatan": "Lubuk Alung",
                "kelurahan": "Sikabu Lubuk Alung",
                "kodepos": "25581"
            },
            {
                "kecamatan": "Sintuk/Sintuak Toboh Gadang",
                "kelurahan": "Sintuk/Sintuak",
                "kodepos": "25582"
            },
            {
                "kecamatan": "Lubuk Alung",
                "kelurahan": "Pasie Laweh Lubuk Alung",
                "kodepos": "25581"
            },
            {
                "kecamatan": "Lubuk Alung",
                "kelurahan": "Pungguang Kasiak Lubuk Alung",
                "kodepos": "25581"
            },
            {
                "kecamatan": "Lubuk Alung",
                "kelurahan": "Aie Tajun Lubuk Alung",
                "kodepos": "25581"
            },
            {
                "kecamatan": "Lubuk Alung",
                "kelurahan": "Lubuk Alung",
                "kodepos": "25581"
            },
            {
                "kecamatan": "Patamuan",
                "kelurahan": "Tandikat/Tandikek",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Patamuan",
                "kelurahan": "Tandikek Utara",
                "kodepos": "25573"
            },
            {
                "kecamatan": "VII Koto Sungai Sarik",
                "kelurahan": "Sungai Sariak",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Patamuan",
                "kelurahan": "Sungai Durian",
                "kodepos": "25573"
            },
            {
                "kecamatan": "VII Koto Sungai Sarik",
                "kelurahan": "Lurah Ampalu",
                "kodepos": "25573"
            },
            {
                "kecamatan": "V Koto Timur",
                "kelurahan": "Limau Puruik",
                "kodepos": "25573"
            },
            {
                "kecamatan": "VII Koto Sungai Sarik",
                "kelurahan": "Lareh Nan Panjang",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Padang Sago",
                "kelurahan": "Koto Baru",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Padang Sago",
                "kelurahan": "Koto Dalam",
                "kodepos": "25573"
            },
            {
                "kecamatan": "V Koto Timur",
                "kelurahan": "Kudu Ganting/Gantiang",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Padang Sago",
                "kelurahan": "Batu Kalang",
                "kodepos": "25573"
            },
            {
                "kecamatan": "V Koto Timur",
                "kelurahan": "Gunung Padang Alai",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Ulakan Tapakih/Tapakis",
                "kelurahan": "Ulakan",
                "kodepos": "25572"
            },
            {
                "kecamatan": "VII Koto Sungai Sarik",
                "kelurahan": "Balah Aia",
                "kodepos": "25573"
            },
            {
                "kecamatan": "Nan Sabaris",
                "kelurahan": "Sunua",
                "kodepos": "25571"
            },
            {
                "kecamatan": "Ulakan Tapakih/Tapakis",
                "kelurahan": "Tapakih/Tapakis",
                "kodepos": "25572"
            },
            {
                "kecamatan": "Nan Sabaris",
                "kelurahan": "Pauah Kambar",
                "kodepos": "25571"
            },
            {
                "kecamatan": "Nan Sabaris",
                "kelurahan": "Kurai Taji",
                "kodepos": "25571"
            },
            {
                "kecamatan": "Nan Sabaris",
                "kelurahan": "Padang Bintungan",
                "kodepos": "25571"
            },
            {
                "kecamatan": "IV Koto Aur Malintang",
                "kelurahan": "III Koto Aur Malintang Timur",
                "kodepos": "25564"
            },
            {
                "kecamatan": "IV Koto Aur Malintang",
                "kelurahan": "III Koto Aur Malintang Utara",
                "kodepos": "25564"
            },
            {
                "kecamatan": "Nan Sabaris",
                "kelurahan": "Kapalo Koto",
                "kodepos": "25571"
            },
            {
                "kecamatan": "IV Koto Aur Malintang",
                "kelurahan": "III Koto Aur Malintang",
                "kodepos": "25564"
            },
            {
                "kecamatan": "IV Koto Aur Malintang",
                "kelurahan": "III Koto Aur Malintang Selatan",
                "kodepos": "25564"
            },
            {
                "kecamatan": "IV Koto Aur Malintang",
                "kelurahan": "Balai Baiak Malai III Koto",
                "kodepos": "25564"
            },
            {
                "kecamatan": "Sungai Geringging/Garingging",
                "kelurahan": "Sungai Sirah Kuranji Hulu",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Sungai Geringging/Garingging",
                "kelurahan": "Malai III Koto",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Batang Gasan",
                "kelurahan": "Malai V Suku",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Sungai Geringging/Garingging",
                "kelurahan": "Kuranji Hulu",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Batang Gasan",
                "kelurahan": "Gasan Gadang",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Sungai Limau",
                "kelurahan": "Pilubang",
                "kodepos": "25561"
            },
            {
                "kecamatan": "Sungai Geringging/Garingging",
                "kelurahan": "Batu Gadang Kuranji Hulu",
                "kodepos": "25563"
            },
            {
                "kecamatan": "Sungai Limau",
                "kelurahan": "Koto Tinggi Kuranji Hilir",
                "kodepos": "25561"
            },
            {
                "kecamatan": "Sungai Limau",
                "kelurahan": "Kuranji Hilir",
                "kodepos": "25561"
            },
            {
                "kecamatan": "V Koto Kampung Dalam",
                "kelurahan": "Sikucur",
                "kodepos": "25552"
            },
            {
                "kecamatan": "Sungai Limau",
                "kelurahan": "Guguak Kuranji Hilir",
                "kodepos": "25561"
            },
            {
                "kecamatan": "V Koto Kampung Dalam",
                "kelurahan": "Campago",
                "kodepos": "25552"
            }
        ],
        "k425": [
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Sikabu",
                "kodepos": "25539"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Palak Aneh",
                "kodepos": "25539"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Taluak",
                "kodepos": "25538"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Kampung/Kampuang Apar",
                "kodepos": "25539"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Marunggih",
                "kodepos": "25538"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Pasir Sunur / Pasa Sunua (Pasia Sunua)",
                "kodepos": "25538"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Marabau",
                "kodepos": "25537"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Padang Cakua",
                "kodepos": "25537"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Sungai Kasai",
                "kodepos": "25537"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kampung Kandang",
                "kodepos": "25536"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Toboh Palabah",
                "kodepos": "25536"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Bungo Tanjung",
                "kodepos": "25535"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kampung Tangah",
                "kodepos": "25535"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kampung Tangah",
                "kodepos": "25535"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Rambai",
                "kodepos": "25533"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kajai",
                "kodepos": "25534"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Simpang (Kurai Taji)",
                "kodepos": "25532"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Pungguang Ladiang",
                "kodepos": "25533"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Pauh Kurai Taji (Pauah)",
                "kodepos": "25532"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Balai Kurai Taji",
                "kodepos": "25531"
            },
            {
                "kecamatan": "Pariaman Selatan",
                "kelurahan": "Batang Tajongkek",
                "kodepos": "25531"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Aia Santok",
                "kodepos": "25531"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Sungai Rambai",
                "kodepos": "25529"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Sikapak Barat",
                "kodepos": "25526"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Sikapak Timur",
                "kodepos": "25526"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kampung Baru Padusunan",
                "kodepos": "25524"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Pakasai",
                "kodepos": "25524"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Cubadak Aia Utara",
                "kodepos": "25524"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Cubadak Air/Aia",
                "kodepos": "25524"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Tanjuang Sabar",
                "kodepos": "25523"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Cubadak Aia Selatan",
                "kodepos": "25524"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Kampuang Gadang",
                "kodepos": "25523"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Talago Sariak",
                "kodepos": "25523"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Apar",
                "kodepos": "25522"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Mangguang",
                "kodepos": "25522"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Naras Hilir (Nareh Hilia)",
                "kodepos": "25522"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Tungka Utara",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Ampalu",
                "kodepos": "25522"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Padang Birik-Birik (Biriak-Biriak)",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Sintuk (Sintuak)",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Tungka Selatan",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Balai Naras/Nareh",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Utara",
                "kelurahan": "Naras I (Nareh I)",
                "kodepos": "25521"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Koto Marapak",
                "kodepos": "25519"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jati Hilir (Jalan Hilia)",
                "kodepos": "25519"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Batang Kabung / Kabuang",
                "kodepos": "25519"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Bato",
                "kodepos": "25519"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jawi-Jawi II",
                "kodepos": "25518"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Alai Galombang",
                "kodepos": "25519"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Taratak",
                "kodepos": "25517"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jawi-Jawi I",
                "kodepos": "25518"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Sungai Sirah",
                "kodepos": "25516"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Ujung Batung (Ujuang Batuang)",
                "kodepos": "25516"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jati Mudik / Mudiak",
                "kodepos": "25517"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Cubadak Mentawai",
                "kodepos": "25516"
            },
            {
                "kecamatan": "Pariaman Timur",
                "kelurahan": "Sungai Pasak",
                "kodepos": "25516"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Karan Aur",
                "kodepos": "25514"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jalan Baru",
                "kodepos": "25515"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Jalan Kereta Api",
                "kodepos": "25515"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Pasir",
                "kodepos": "25513"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung/Kampuang Baru",
                "kodepos": "25514"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Pauah/Pauh Timur",
                "kodepos": "25512"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung Perak",
                "kodepos": "25513"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Lohong",
                "kodepos": "25513"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung Pondok",
                "kodepos": "25512"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung Pondok II",
                "kodepos": "25512"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Pauah/Pauh Barat",
                "kodepos": "25512"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Rawang",
                "kodepos": "25511"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung Jawa I",
                "kodepos": "25511"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Kampung Jawa II",
                "kodepos": "25511"
            },
            {
                "kecamatan": "Pariaman Tengah",
                "kelurahan": "Cimparuah",
                "kodepos": "25511"
            }
        ],
        "k426": [
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Sirilogui",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Sotboyak",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Barat",
                "kelurahan": "Simalegi/Simaligi",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Barat",
                "kelurahan": "Simatalu Sipokak",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Muara/Muaro Sikabaluan",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Barat",
                "kelurahan": "Sigapokna",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Mongan Poula/Paula",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Malancang",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Tengah",
                "kelurahan": "Cimpungan",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Utara",
                "kelurahan": "Bojakan",
                "kodepos": "25394"
            },
            {
                "kecamatan": "Siberut Barat Daya",
                "kelurahan": "Taileleu (Pasakiat Taleleu)",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Tengah",
                "kelurahan": "Saibi Muara (Saibi Samukop/Samokop)",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Tengah",
                "kelurahan": "Saliguma",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Barat Daya",
                "kelurahan": "Sagulubek (Sagalubeg/Sagalubbek)",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Selatan",
                "kelurahan": "Muntei",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Selatan",
                "kelurahan": "Muara/Muaro Siberut",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Selatan",
                "kelurahan": "Matotonan",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Selatan",
                "kelurahan": "Maileppet/Maleppet",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Selatan",
                "kelurahan": "Madobak Ugai",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Siberut Barat Daya",
                "kelurahan": "Katurai/Katurei",
                "kodepos": "25393"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Tuapejat",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Sipora Jaya",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Sioban",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Sido Makmur",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Nem-Nem Leleu",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Saureinuk",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Matobek",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Mara",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Gosooinan (Goisooinan)",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Bukit Pamewa",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Bosua",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Utara",
                "kelurahan": "Betumonga (Beutomonga)",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sipora Selatan",
                "kelurahan": "Beriulou",
                "kodepos": "25392"
            },
            {
                "kecamatan": "Sikakap",
                "kelurahan": "Taikako",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Selatan",
                "kelurahan": "Sinakak",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Utara",
                "kelurahan": "Silabu",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Sikakap",
                "kelurahan": "Sikakap",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Utara",
                "kelurahan": "Saumanganyak",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Sikakap",
                "kelurahan": "Matobek",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Selatan",
                "kelurahan": "Malakopak",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Selatan",
                "kelurahan": "Makalo",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Selatan",
                "kelurahan": "Bulasat",
                "kodepos": "25391"
            },
            {
                "kecamatan": "Pagai Utara",
                "kelurahan": "Betumonga",
                "kodepos": "25391"
            }
        ],
        "k427": [
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Teluk Kabung Utara",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Teluk Kabung Tengah",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Teluk Kabung Selatan",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Indarung",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Bungus Timur",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Bungus Selatan",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Bungus Teluk Kabung",
                "kelurahan": "Bungus Barat",
                "kodepos": "25237"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Batu Gadang",
                "kodepos": "25236"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Beringin/Baringin",
                "kodepos": "25235"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Tarantang",
                "kodepos": "25234"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Koto Lalang",
                "kodepos": "25232"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Padang Besi",
                "kodepos": "25233"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Pampangan Nan XX",
                "kodepos": "25227"
            },
            {
                "kecamatan": "Lubuk Kilangan",
                "kelurahan": "Bandar Buat (Banda Buek)",
                "kodepos": "25231"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Koto Baru",
                "kodepos": "25227"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Pagambiran Ampulu (Pangambiran Ampalu) Nan XX",
                "kodepos": "25226"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Gates Nan XX",
                "kodepos": "25227"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Kampung Jua Nan XX",
                "kodepos": "25225"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Kampung Baru Nan XX",
                "kodepos": "25225"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Pitameh Tanjung Saba Nan XX",
                "kodepos": "25224"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Tanah Sirah Piai Nan XX",
                "kodepos": "25224"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Cengkeh/Cangkeh Nan XX",
                "kodepos": "25225"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Batang Taba Nan XX",
                "kodepos": "25223"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Parak Laweh Pulau Air Nan XX",
                "kodepos": "25223"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Banuaran",
                "kodepos": "25222"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Tanjung Aur/Tanjuang Aua",
                "kodepos": "25222"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Gurun Laweh/Lawas",
                "kodepos": "25221"
            },
            {
                "kecamatan": "Lubuk Begalung",
                "kelurahan": "Lubuk Begalung",
                "kodepos": "25221"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Air Manis (Aia Manih)",
                "kodepos": "25217"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Teluk Bayur/Taluak Bayua",
                "kodepos": "25217"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Mato/Mata Air",
                "kodepos": "25216"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Rawang",
                "kodepos": "25216"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Bukik/Bukit Gado-gado",
                "kodepos": "25215"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Seberang Palinggam",
                "kodepos": "25215"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Seberang Padang",
                "kodepos": "25214"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Batang Arau",
                "kodepos": "25215"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Ranah Parak Rumbio",
                "kodepos": "25212"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Pasar Gadang",
                "kodepos": "25213"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Alang Laweh",
                "kodepos": "25211"
            },
            {
                "kecamatan": "Padang Selatan",
                "kelurahan": "Belakang Pondok",
                "kodepos": "25211"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Dadok Tunggul Hitam",
                "kodepos": "25176"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Koto Panjang Ikua Koto",
                "kodepos": "25175"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Lubuk Minturun",
                "kodepos": "25175"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Air Pacah",
                "kodepos": "25176"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Lubuk Buaya",
                "kodepos": "25173"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Padang Sarai",
                "kodepos": "25173"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Pasir/Pasie Nan Tigo",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Batang Kabung (K Ganting)",
                "kodepos": "25172"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Koto Pulai",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Parupuk Tabing",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Batipuh Panjang",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Bungo Pasang",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Lambung Bukit/Bukik",
                "kodepos": "25168"
            },
            {
                "kecamatan": "Koto Tangah",
                "kelurahan": "Balai Gadang",
                "kodepos": "25171"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Koto Luar",
                "kodepos": "25164"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Limau Manis",
                "kodepos": "25166"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Kepala/Kapalo Koto",
                "kodepos": "25163"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Limau Manis Selatan",
                "kodepos": "25163"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Cupak Tangah",
                "kodepos": "25162"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Piai Tangah",
                "kodepos": "25162"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Binuang Kampung Dalam",
                "kodepos": "25161"
            },
            {
                "kecamatan": "Pauh",
                "kelurahan": "Pisang",
                "kodepos": "25161"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Gunung Sarik",
                "kodepos": "25158"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Sei/Sungai Sapih",
                "kodepos": "25159"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Korong Gadang",
                "kodepos": "25156"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Kuranji",
                "kodepos": "25157"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Ampang",
                "kodepos": "25154"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Kalumbuk",
                "kodepos": "25155"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Pasar Ambacang",
                "kodepos": "25152"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Lubuk Lintah",
                "kodepos": "25153"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Kurao Pagang",
                "kodepos": "25147"
            },
            {
                "kecamatan": "Kuranji",
                "kelurahan": "Anduring",
                "kodepos": "25151"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Surau Gadang",
                "kodepos": "25146"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Kampung Olo",
                "kodepos": "25143"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Tabing Banda Gadang",
                "kodepos": "25144"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Gurun Lawas/Laweh",
                "kodepos": "25145"
            },
            {
                "kecamatan": "Nanggalo",
                "kelurahan": "Kampung Lapai",
                "kodepos": "25142"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Gunung Pangilun",
                "kodepos": "25137"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Alai Parak Kopi",
                "kodepos": "25139"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Ulak Karang Selatan",
                "kodepos": "25134"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Lolong Belanti",
                "kodepos": "25136"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Air Tawar Timur",
                "kodepos": "25132"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Ulak Karang Utara",
                "kodepos": "25133"
            },
            {
                "kecamatan": "Padang Utara",
                "kelurahan": "Air Tawar Barat",
                "kodepos": "25132"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Kubu Parak Karakah (Dalam)",
                "kodepos": "25126"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Jati",
                "kodepos": "25129"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Jati Baru",
                "kodepos": "25129"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Kubu Marapalam",
                "kodepos": "25125"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Andalas",
                "kodepos": "25126"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Parak Gadang Timur",
                "kodepos": "25123"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Simpang Haru",
                "kodepos": "25123"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Sawahan Timur",
                "kodepos": "25121"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Ganting Parak Gadang",
                "kodepos": "25122"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Kampung Pondok",
                "kodepos": "25119"
            },
            {
                "kecamatan": "Padang Timur",
                "kelurahan": "Sawahan",
                "kodepos": "25121"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Belakang Tangsi",
                "kodepos": "25118"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Berok Nipah",
                "kodepos": "25118"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Olo",
                "kodepos": "25117"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Ujung Gurun",
                "kodepos": "25114"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Purus",
                "kodepos": "25115"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Padang Pasir",
                "kodepos": "25112"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Flamboyan (Plamboyan Baru)",
                "kodepos": "25114"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Rimbo Kaluang",
                "kodepos": "25111"
            },
            {
                "kecamatan": "Padang Barat",
                "kelurahan": "Kampung Jao",
                "kodepos": "25112"
            }
        ],
        "k428": [
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Tunas Peracak",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Suka Baru",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Tulang Bawang",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Negeri Ratu Baru",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Sabah Lioh",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Negeri Ratu",
                "kodepos": "32387"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Tulung Sari",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Trimo Rejo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Trimoharjo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Taman Harjo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Taman Mulyo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Taraman",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Taman Agung",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Taman Asri",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Sriwangi Ulu",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Suka Mulya",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sugih Waras",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Sriwangi",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sidowaluyo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Rejosari",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sari Guna",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Mujo Rahayu",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Petanggan",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Margodadi",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Margorejo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Lubuk Harjo",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Karang Marga",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Krujon",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Jaya Mulia/Mulya",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Karang Endah",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Gunung Sugih",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Semendawai Suku III",
                "kelurahan": "Cahaya/Cahya Negeri",
                "kodepos": "32386"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Windusari",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Sukanegara",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Sumber Rejo",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Tri Karya",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Ringin Sari",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Senu Marga",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Sinar Bali",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Rejosari Jaya",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Rejosari Jaya",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Tenggara",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Tunggal",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Maju",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Jaya",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Bali",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Agung",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Nusa Bakti",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Karya Makmur",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Kutosari",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Madu Gondo",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Karang Sari",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Karsa Jaya",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Karya Maju",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Karang Jadi",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Giri Mulyo",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Dadi Rejo",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang III",
                "kelurahan": "Ganti Warno",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Argo Mulyo",
                "kodepos": "32385"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Sukaraja",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Ulak Baru",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Negeri Sakti",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Sukabumi",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Harisan Jaya",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Kuripan",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Meluai Indah",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Gunung Batu",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Gunung Jati",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Campang Tiga Jaya",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Campang Tiga Ulu",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Cempaka",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Cempaka",
                "kelurahan": "Campang Tiga Ilir",
                "kodepos": "32384"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Ulak Buntar",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Toto Rejo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Tanjung Kemuning",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Tegal Besar",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Tegal Sari",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sumber Rahayu",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sumber Rejo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sumber Sari",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sumber Harapan",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sumber Jaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sukoharjo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Suka Jaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sri Budaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Sri Mulyo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Srijaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Rejo Mulyo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Sri Bantolo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Purwosari",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Raman Jaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Purworejo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Mulya Sari",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang Mulya",
                "kelurahan": "Purwodadi",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Kemuning Jaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Margo Mulyo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Keli Rejo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Karang Jaya",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Karang Manik",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Batu Mas",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Darma Buana",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Tegal Rejo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Triyoso",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang II",
                "kelurahan": "Bangun Rejo",
                "kodepos": "32383"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sumber Suko Jaya",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Tanjung Raya",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Tawang Rejo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sukosari",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sumber Suko",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Sumber Agung",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sukajadi",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sukarami",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sidomulyo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Sidorejo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sidogede",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sido Makmur",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sido Rahayu",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Sidodadi",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Rejosari",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Pujorahayu",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Serbaguna",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Margo Koyo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Panca Tunggal",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Gumawang",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Gunung Mas",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Harjo Winangun",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Karang Kemiri",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Bedilan",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Belitang",
                "kelurahan": "Gedung Rejo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Way Salak",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Belitang Jaya",
                "kelurahan": "Banjar Rejo",
                "kodepos": "32382"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Tumijaya",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Sukomulyo",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Peracak Jaya",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Perjaya",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Peracak",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Kurungan Nyawa I",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Mendah",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Jayapura",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Kambang",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Keromongan",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Bunga Mayang",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Jayapura",
                "kelurahan": "Condong",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Terantang/Trantang Sakti",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Bunga Mayang",
                "kelurahan": "Baturaja Bungin",
                "kodepos": "32381"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Pulau Negara",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Saung Dadi",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Negeri Pakuan",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Pahang Asri",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Pemetung Basuki",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Negeri Agung",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Negeri Agung Jaya",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Bantan Pelita",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Banuayu",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Banumas",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Wana Bhakti",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Bandar Jaya",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Buay Pemuka Beliung / Peliung",
                "kelurahan": "Bantan",
                "kodepos": "32367"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Sukanegara",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Surabaya",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Suka Damai",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Sri Kencana",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Sri Mulyo",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Riang Bandung",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Riang Bandung Ilir",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Rasuan Baru",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Pandan Agung",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Pandan Jaya",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Nikan",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Margotani II",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Kertanegara",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Kota Negara",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Margo Tani",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Karang Negara",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Karya Makmur",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Dadi Mulyo",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Jati Mulyo II (Mulya II)",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Batumarta VI",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Batumarta X",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Bina Amarta",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku II",
                "kelurahan": "Banban Rejo",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Madang Suku III",
                "kelurahan": "Banding Agung",
                "kodepos": "32366"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Yosowinangun",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Tulus Ayu",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Tugu Harum",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Tugu Mulyo",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Tridadi",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Tanah Merah",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Tebing Sari Mulya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Rasuan Darat",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Simpang Karta Mulya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Rantau Jaya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Rasuan",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Pandan Sari I",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Pelita Jaya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Mengulak",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Mekar Jaya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Mendayun",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Karang Binangun",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Marga Cinta",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Karta Mulya",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Jatisari",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Jaya Bakti",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Harjo Mulyo",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Jati Mulyo I",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Gunung Terang",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Belitang Madang Raya",
                "kelurahan": "Bangsa Negara",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Madang Suku I",
                "kelurahan": "Agung Jati",
                "kodepos": "32362"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Way Halom",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Tekorejo (Tokorejo)",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Tebat Jaya",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Tanjungsari",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Tanjungmas",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Tambak Boyo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Surya Menang",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sumedang Sari",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Sumber Agung",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sumber Asri",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sumber Asri",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sukoharjo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sukodadi",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Sukaraja",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Sukaraja Tuha",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Suka Maju",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Sri Katon",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Sri Dadi (Saridati)",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Sri Bunga",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Sri Bulan",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Rejodadi",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Rowodadi",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Rawasari",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Pisang Jaya",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Pengandonan",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Pandan Sari III",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Mulyo Agung",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Muncak Kabau",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Muda Sentosa",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Metro Rejo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Limansari",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Kurungan Nyawa III",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Kurungan Nyawa II",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Kurungan Nyawa I",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Kurungan Nyawa",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Kumpul Rejo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Kedu",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Kedung Rejo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Karang Tengah",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Cipta Muda",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Ganjar Agung",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Bangun Harjo",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang Timur",
                "kelurahan": "Banyumas Asri",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Madang",
                "kelurahan": "Aman Jaya",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Buay Pemuka Bangsa Raja",
                "kelurahan": "Anyar",
                "kodepos": "32361"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Veteran Jaya",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Tanjung Kemala Barat",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Terukis Rahayu",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Sungai Tuha Jaya",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Kota Baru Selatan",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Bukit Sari",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Kota Baru Barat",
                "kodepos": "32315"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Paku Sengkunyit",
                "kodepos": "32314"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Dusun Martapura",
                "kodepos": "32313"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Kota Baru",
                "kodepos": "32311"
            },
            {
                "kecamatan": "Martapura",
                "kelurahan": "Pasar Martapura",
                "kodepos": "32312"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Wana Makmur",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Warna Sari (Wanasari)",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Nirwana",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Tulung Harapan",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Melati Jaya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Mulia Jaya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Kota Mulya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Kota Tanah",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Melati Agung",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Karang Menjangan",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Karang Mulya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Karang Anyar",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Karang Melati",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Burnai Mulya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Harapan Jaya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Bungin Jaya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Timur",
                "kelurahan": "Burnai Jaya",
                "kodepos": "32185"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Tanjung Mas",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Sri Tanjung",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Suka Negeri",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Tanjung Kukuh",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Menanga Sari",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Menanga Tengah",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Kangkung Ilir",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Menanga Besar",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Kangkung",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Betung",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Betung Timur",
                "kodepos": "32184"
            },
            {
                "kecamatan": "Semendawai Barat",
                "kelurahan": "Adu Manis",
                "kodepos": "32184"
            }
        ],
        "k429": [
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Tenang",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Ulak Pandan",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Siring Agung",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Tebat Gabus",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Simpang Tiga",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Singa Laga",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Pulau Panggung",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Simpang Empat",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Pajar Bulan",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Padang Bindu",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Padang Lay",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Gunung Megang",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Kota Padang",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Muara Payang",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Berasang",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Danau Rata",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Balayan",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Bandar Alam Lama",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Kisam Tinggi",
                "kelurahan": "Air Alun",
                "kodepos": "32279"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Sura",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Sugih Waras",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Sukajadi Blambangan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Perupus Blambangan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Saung Naga",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Simpang Saga",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Peninjauan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Penanggungan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Negeri Batin Baru",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Padang Bindu",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Padang Sari",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Nagar Agung",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Kota Aman",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Kota Aman",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Karang Endah",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Kagelang Blambangan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Gedung Wani",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Bumi Genap",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Gedung Nyawa",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Bedeng Blambangan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Runjung",
                "kelurahan": "Belambangan",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Tanjung Raya",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Runjung Agung",
                "kelurahan": "Air Baru",
                "kodepos": "32278"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Tanjung Menang Ilir",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Tanjung Menang Ulu",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Talang Baru",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Tanjung Iman",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Sukarena",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Surabaya",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Sukabumi",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Sukaraja I",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Sukarami",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Peninggiran",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Negeri Cahya",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Negeri Agung",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Negeri Batin",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Lubuk Liku",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Madura",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Kuripan II / Dua",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Kota Agung",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Kuripan",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Kota Karang",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Tiga Dihaji",
                "kelurahan": "Karang Pendeta",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Kenali",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Bunga Mas",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Buay Sandang Aji",
                "kelurahan": "Gunung Terang",
                "kodepos": "32277"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Teluk Agung",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Tanjung Besar",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Sri Menanti",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Sukaraja",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Pulau Duku",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Selabung Belimbing Jaya",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Sinar Marga",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Pere&#8217;an",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Kepayang",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Kota Baru",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Kota Dalam",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Kemang Bandung",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Bunut",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Galang Tinggi",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Way Wangi Seminung",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Mekakau Ilir",
                "kelurahan": "Air Baru",
                "kodepos": "32276"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Way Timah",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Way Relai",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Telanai",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Terap Mulia",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Tanjung Sari",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Tanjung Setia",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Tanjung Jati",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Tanjung Agung",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Tanjung Baru",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Tanjung Baru",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Talang Merbau",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Tangsi Agung",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Surabaya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Surabaya Timur",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Sumber Mulia",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sumber Makmur",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Sumber Jaya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Sukarami",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sukamaju",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Sukamarga",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Sukajaya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Sukabumi",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sugih Waras",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Suka Negeri",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sipatuhu",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sipatuhu II",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Subik",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Simpang Sender Utara",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Simpang Sender Tengah",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Simpang Sender Timur",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Simpang Sender",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Simpang Sender Selatan",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Sidodadi",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Serumpun Jaya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Segigok Raya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Remanam Jaya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Rantau Nipis",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Pakhda Suka",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Pilla",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Penantian",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Pagar Dewa",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Padang Ratu",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Kota Batu",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Mekarsari",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Kiwis Raya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Karang Indah",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Gunung Raya",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Hangkusa/Lingkusa",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Jepara",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Gedung Ranau",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Gunung Aji",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Buay Pematang Ribu Ranau Tengah",
                "kelurahan": "Gedong/Gedung Baru",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Bumi Agung",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Warkuk Ranau Selatan",
                "kelurahan": "Bedeng Tiga",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Bandar Agung Ranau",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Banding Agung",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Ulu Danau",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Wates",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Banding Agung",
                "kelurahan": "Air Rupik",
                "kodepos": "32274"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Ujan Mas",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Tebat Layang",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Tanjung Kari",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Tanjung Bulan Ulu",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Tanah Pilih",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Simpang Pancur",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Simpang Luas",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Sadau Jaya",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Simpang Luas",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Pulau Kemuning",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Pulau Beringin Utara",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Pematang Obar",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Pulau Beringin",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Pecah Pinggan",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Pematang Danau",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Pagar Agung",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Muara Sindang Tengah",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sindang Danau",
                "kelurahan": "Muara Sindang Ilir",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Kemu",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Kemu Ulu",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Guntung Jaya",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Gunung Batu",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Sungai Are",
                "kelurahan": "Cukoh Nau (Cukuh/Cuko Nau)",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Anugerah Kemu",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Pulau Beringin",
                "kelurahan": "Aromantai",
                "kodepos": "32273"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Ulak Agung Ilir",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Ulak Agung Ulu",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Tanjung Jati",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Tanjung Jati",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Sukaraja",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Sugihan",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Sukananti",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Siring/Sring Alam",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Simpang Lubuk Dalam",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Pius",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Pulau Kemiling",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Simpang Campang",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Penantian",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Penyandingan",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Pangandonan",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Pagar Dewa",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Muara Sindang",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Muara Dua Kisam",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Lawang Agung",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Keban Agung",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Gunung Gare",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Bayur Tengah",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Kisam Ilir",
                "kelurahan": "Campang Jaya",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Dusun Tengah",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Bandar Alam Baru",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Tanjung Sari",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Muaradua Kisam",
                "kelurahan": "Alun Dua",
                "kodepos": "32272"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Tanjung Jaya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Tanjung Menang",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Tanjung Baru",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Tanjung Durian",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sumber Ringin",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Talang Padang",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sipin",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sri Menanti",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sumber Raya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sinar Baru",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sinar Napalan",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sidodadi",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Sido Rahayu",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Serakat Jaya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Kota Way",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Mekar Jaya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Karet Jaya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Kembang Tinggi",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Durian Sembilan",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Danau Jaya",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Tunas Jaya",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buay Pemaca",
                "kelurahan": "Air Kelian",
                "kodepos": "32265"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Tekana",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Tanjung Sari",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Sinar Mulyo",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Sinar Danau",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Simpang Agung",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Simpangan",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Karang Agung",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Lubar",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Gemiung",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Jagaraga",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Simpang",
                "kelurahan": "Bungin Campang",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Damarpura",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Buana Pemaca",
                "kelurahan": "Bandar",
                "kodepos": "32264"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Sukaraja Dua",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Sukajaya",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Suka Banjar",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Rantau Panjang",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Ruos",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Pendagan",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Pelawi",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Pelangki",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Pekuolan",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Pancur Pungah",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Pasar Muaradua",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Kisau",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Mehanggin",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Majar",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Gunung Tiga",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Gunung Cahya",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Datar",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Gedung Lepihan",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Bumi Agung Jaya",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Bumijaya",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Bumi Agung",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Bendi",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Batu Belang Dua",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Muaradua",
                "kelurahan": "Batu Belang Jaya",
                "kodepos": "32211"
            },
            {
                "kecamatan": "Buay Rawan",
                "kelurahan": "Banjar Agung",
                "kodepos": "32211"
            }
        ],
        "k430": [
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Tanjung Manggus",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Markisa",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Merbau",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Lunggaian",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Lubuk Batang Lama",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Lubuk Banjar",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Lubuk Batang Baru",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Gunung Meraksa",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Karta Mulia",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Kurup",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Banu Ayu",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Belatung",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Air Wall",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Lubuk Batang",
                "kelurahan": "Bandar Agung",
                "kodepos": "32192"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Sinar Kedaton",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Sp Tiga",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Suka Pindah",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Rantau Panjang",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Saung Naga",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Peninjauan",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Panti Jaya",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Penilikan/Mitra Ogan",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Mendala",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Mitra Kencana",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Lubuk Rukam",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Makarti Jaya",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Makarti Tama",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Kepayang",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Lubuk Kemiling",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Kedaton Timur",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Kedondong",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Kampai",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Karang Dapo",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Kedaton",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Durian",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Bunglai",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Belimbing",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Peninjauan",
                "kelurahan": "Bindu",
                "kodepos": "32191"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Marga Mulya",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Sri Mulya",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Tanjung Makmur",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Karya Mukti",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Marga Bakti",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Sinar Peninjauan",
                "kelurahan": "Karya Jaya",
                "kodepos": "32159"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Umpan",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Way Heling",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Tihang",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Tualang",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Tanjung Agung",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Tanjung Lengkayap",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Sukaraja",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Sundan",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Simpang Empat",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Pajar Bulan",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Segara Kembang",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Pagar Dewa",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Negeri Agung",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Negeri Ratu",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Lubuk Dalam",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Lubuk Hara",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Gedung Pakuan/Pekuon",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Karang Endah",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Fajar Jaya",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Bandar Jaya",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Lengkiti",
                "kelurahan": "Bumi Kawa",
                "kodepos": "32158"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Sukajadi",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Ulak Lebar",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Mendingin",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Pedataran",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Kelumpang",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Gunung Tiga",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Ulu Ogan",
                "kelurahan": "Belandang",
                "kodepos": "32157"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Tubohan",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Ulak Pandan",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Tebing Kampung",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Singapura",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Suka Merindu",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Sukarami",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Raksa Jiwa",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Seleman",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Panggal Panggal",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Pengaringan",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Pandan Dulang",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Panai Makmur",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Padang Bindu",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Nyiur Sayak",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Kebun Jati",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Kebon Agung",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Batang Hari",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Bedegung",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Guna Makmur",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Ujan Mas",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Semidang Aji",
                "kelurahan": "Banjar Sari",
                "kodepos": "32156"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Tanjung Pura",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Tanjung Sari",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Tanjungan",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Surau",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Semanding",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Tangsi Lontar",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Muara Saeh",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Pengandonan",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Lontar",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Lontar",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Lubuk Tupak",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Karang Lantang",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Kemala Jaya",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Gunung Meraksa",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Gunung Kuripan",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Gunung Liwat",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Muara Jaya",
                "kelurahan": "Beringin",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Pengandonan",
                "kelurahan": "Belambangan",
                "kodepos": "32155"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Lubuk Banjar",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Marta Jaya",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Batumarta I",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Batumarta II",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Lekis Rejo",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Battu Winangun",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Lubuk Raja",
                "kelurahan": "Batu Raden",
                "kodepos": "32152"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Rantau Kumpai",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Tungku Jaya",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Negeri Sindang",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Penantian",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Penyandingan",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Lubuk Leban",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Mekar Jaya",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Mekar Sari",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Kungkilan",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Lubuk Baru",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Pusar",
                "kodepos": "32126"
            },
            {
                "kecamatan": "Sosoh Buay Rayap",
                "kelurahan": "Bandar",
                "kodepos": "32151"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Sukamaju",
                "kodepos": "32124"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Laya",
                "kodepos": "32125"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Saung Naga",
                "kodepos": "32124"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Talang Jawa",
                "kodepos": "32122"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Tanjung Agung",
                "kodepos": "32123"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Karang Endah",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Tanjung Karang",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Batu Putih",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Karang Agung",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Air Gading",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Barat",
                "kelurahan": "Batu Kuning",
                "kodepos": "32121"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Sukajadi",
                "kodepos": "32117"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Pasar Lama",
                "kodepos": "32115"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Pasar Baru",
                "kodepos": "32116"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Sukaraya",
                "kodepos": "32112"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Tanjung Baru",
                "kodepos": "32113"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Baturaja Lama",
                "kodepos": "32114"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Terusan",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Kemelak Bindung Langit",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Sepancar Lawang Kulon",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Baturaja Permai",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Kemala Raja",
                "kodepos": "32111"
            },
            {
                "kecamatan": "Baturaja Timur",
                "kelurahan": "Air Paoh/Pauh",
                "kodepos": "32111"
            }
        ],
        "k431": [
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Tegal Rejo",
                "kodepos": "31713"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Tanjung Enim Selatan",
                "kodepos": "31711"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Pasar Tanjung Enim",
                "kodepos": "31712"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Keban Agung",
                "kodepos": "31711"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Lingga",
                "kodepos": "31711"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Tanjung Enim",
                "kodepos": "31711"
            },
            {
                "kecamatan": "Lawang Kidul",
                "kelurahan": "Darmo",
                "kodepos": "31711"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Rami Pasai",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Pagar Dewa",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Pagar Jati",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Padang Bindu",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Hidup Baru",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Benakat",
                "kelurahan": "Betung",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Tanding Marga",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Tanjung Miring",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Sukarami",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Sungai Rotan",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Suka Maju",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Suka Merindu",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Suka Dana",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Suka Jadi",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Suka Cinta",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Petar Dalam",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Penandingan",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Paya Angus",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Muara Lematang",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Modong",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Kasai",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Danau Tampang",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Sungai Rotan",
                "kelurahan": "Danau Rata",
                "kodepos": "31357"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Tenam Bungkuk",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Tebing Abang",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Tanah Abang",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Seri Tanjung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Rekimai Jaya",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Pulau Panggung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Perapau",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Penyandingan",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Penindaian",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Palak Tanah",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Pagar Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Muara Tenang",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Muara Dua",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Muara Danau",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Kota Padang",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Karya Nyata",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Kota Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Gunung Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Tengah",
                "kelurahan": "Batu Surau",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Laut",
                "kelurahan": "Babatan",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Tanjung Tiga",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Tanjung Karangan",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Tanjung Lalang",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Sugih Waras",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Suka Raja",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Siring Agung",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Seleman",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Segamit",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Penyandingan",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Pulau Panggung",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Pandan Dulang",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Pandan Enim",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Pagar Jati",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Pajar Bulan",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Pagar Dewa",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Paduraksa",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Padang Bindu",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Matas",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Muara Emil",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Muara Meo",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Lesung Batu",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Lubuk Nipis",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Lambur",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Lebak Budi",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Indramayu",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Embawang",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Cahaya Alam",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Datar Lebar",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Tanjung Agung",
                "kelurahan": "Bedegung",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Semendo Darat Ulu",
                "kelurahan": "Aremantai",
                "kodepos": "31355"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Tanjung Terang",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Teluk Lubuk",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Tanjung",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Tanjung Muning",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Sumaja Makmur",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Talang Padang",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Perjito",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Sidomulyo",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Panang Jaya",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Penanggiran",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Kayu Ara Sakti",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Lubuk Mumpo",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Gunung Megang Dalam",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Gunung Megang Luar",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Dalam",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Darmo Kasih",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Fajar Indah",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Cinta Kasih",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Belimbing",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Berugo",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Bulang",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Gunung Megang",
                "kelurahan": "Bangun Sari",
                "kodepos": "31352"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Ujan Mas Baru",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Ujan Mas Lama",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Ulak Bandung",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Pinang Belarik",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Tanjung Raman",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Muara Gula Baru",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Muara Gula Lama",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Ujan Mas",
                "kelurahan": "Guci",
                "kodepos": "31351"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tempirai Timur",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tempirai Utara",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tempirai",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tempirai Selatan",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tambak",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Tanding Marga",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Spantan Jaya (Sepantan)",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Sungai Langan",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Sukarami",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Rajajaya",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Purun",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Perambatan",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Prabumenang",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Pengabuan",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Pengabuan Timur",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Pasar II",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Muara Ikan",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Lubuk Tampui",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Mangkunegara",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Karang Tanding",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal Utara",
                "kelurahan": "Kota Baru",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Karang Agung",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Gunung Raja",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Betung Barat",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Gunung Menang",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Abab",
                "kelurahan": "Betung",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Babat",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Air Itam Barat",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Penukal (Penukal Abab)",
                "kelurahan": "Air Itam Timur",
                "kodepos": "31315"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Tanah Abang Selatan",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Tanah Abang Utara",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Sedupi",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Sukaraja",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Raja",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Raja",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Raja Barat",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Muara Sungai",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Pandan",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Harapan Jaya",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Lunas Jaya",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Modong",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Bumiayu",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Tanah Abang",
                "kelurahan": "Curup",
                "kodepos": "31314"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Tungkal",
                "kodepos": "31313"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Tanjung Serian",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Muara Enim",
                "kodepos": "31312"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Tanjung Jati",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Tanjung Raja",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Muara Lawai",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Pasar I",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Saka Jaya",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Kepur",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Lubuk Empelas",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Muara Harapan",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Harapan Jaya",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Karang Raja",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Bulang",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Ubi Utara",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Muara Enim",
                "kelurahan": "Air Lintang",
                "kodepos": "31311"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Sungai Baung",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Sungai Ibul",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Akar",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Suka Damai",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Sukamaju",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Semangus",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Sinar Dewa",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Kertadewa",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Panta Dewa",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Psr.Bayangkara",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Handayani Mulya",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Ubi Timur",
                "kodepos": "31213"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Benakat Minyak",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Benuang",
                "kodepos": "31214"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Ubi Selatan",
                "kodepos": "31212"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Talang Ubi",
                "kelurahan": "Talang Ubi Barat",
                "kodepos": "31211"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Tanjung Kemala",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Sumber Rahayu",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Sumber Asri",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Sumber Mulya",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Sukarami",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Suka Merindu",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Sugih Waras",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Sugihan",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Pagar Gunung",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Prabu Menang",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Pagar Dewa",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Pagar Agung",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Negeri Agung",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Lubai Persada",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Mekar Jaya",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Marga Mulia",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Lecah",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Lubai Makmur",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Kencana Mulia",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Kota Baru",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Karang Mulia",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Karang Sari",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Jiwa Baru",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Karang Agung",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Beringin",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Gunung Raja",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Lubai",
                "kelurahan": "Aur",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Baru Rambang",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Tebat Agung",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang",
                "kelurahan": "Air Keruh",
                "kodepos": "31173"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Suban Jeriji",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Tanjung Menang",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Pangkalan Babat",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Siku",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Muara Emburung",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Muara Niru",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Manunggal Jaya",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Manunggal Makmur",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Kuripan",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Lubuk Raman",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Kahuripan Selatan",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Kasih Dewa",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Jemenang",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Kahuripan Baru",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Gerinam",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Gunung Raja",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Gemawang",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Dangku",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Aur Duri",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Banuayu",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Batu Raja",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Air Enau",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Air Limau",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Tapus",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Air Talas",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Tanjung Medang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Teluk Limau",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Teluk Jaya",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Rambang Dangku",
                "kelurahan": "Air Cekdam",
                "kodepos": "31172"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Tanjung Bunut",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Tambangan Kelekar",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Talang Nangka",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Talang Taling",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Suka Menang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Sungai Duren",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Talang Balai",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Suban Baru",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Suka Jaya",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Sigam",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Sialingan",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Putak",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Sebau",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Segayam",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Pinang Banjar",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Petanang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Pelempang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Payabakal",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Pedataran",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Midar",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Mulia Abadi",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Patra Tani",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Menanti",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Menanti Selatan",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Melilian/Mililian",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Lembak",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Kartamulia/Kerta Mulia",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Kayu Ara Batu",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Kemang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Karang Endah Selatan",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Jambu",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Karang Endah",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Harapan Mulya/Mulia",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Ibul",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Gedung Buruk",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Gelumbang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Gumai",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Gaung Telang",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Kelekar",
                "kelurahan": "Embacang Kelekar",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Gaung Asam",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Gelumbang",
                "kelurahan": "Bitis",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Babat",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Arisan Musi",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Muara Belida",
                "kelurahan": "Arisan Musi Timur",
                "kodepos": "31171"
            },
            {
                "kecamatan": "Lembak",
                "kelurahan": "Alai",
                "kodepos": "31171"
            }
        ],
        "k432": [
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Napal Licin",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Pulau Kidak",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Sosokan",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Muara Kulam",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Kuto Tanjung",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Muara Kuis",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Trikarya",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Ulu Rawas",
                "kelurahan": "Jangkat",
                "kodepos": "31669"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwodadi",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Rejosari",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Sadar Karya",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Pagarsari",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Purwa Karya",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Kertosari",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Mangun Harjo",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Mardiharjo",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Bangun Sari",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Purwodadi",
                "kelurahan": "Karyadadi",
                "kodepos": "31668"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Sumber Sari",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Srijaya Makmur",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Sumber Makmur",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Kerani/Krani Jaya",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Mulya Jaya",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Karya Makmur",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Kelumpang Jaya",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Jadi Mulya I",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Bumi Makmur",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Nibung",
                "kelurahan": "Jadi Mulya",
                "kodepos": "31667"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Sungai Pinang",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Tri Anggunjaya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Semeteh",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Sido Mulyo",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Sindang Jaya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Semangus",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Semangus Baru",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Prabumulih I",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Prabumulih II",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Pendingan",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Pian Jaya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Mukti Karya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Pelita Jaya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Muara Rengas",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Muara Lakitan",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Lubuk Pandan",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Marga Baru",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Bumi Makmur Jaya",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Harapan Makmur",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Muara Lakitan",
                "kelurahan": "Anyar",
                "kodepos": "31666"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Yudha Karya Bhakti",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Sukowono",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukarena",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sukowarno",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Sidodadi",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Sugih Waras",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Ngestiboga II",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Purwodadi",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Rantau Alih",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Margoyoso",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Ngestiboga I",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Margatani",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Margatunggal",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Donorejo (Donorojo)",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Giriyoso",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Kertosono",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Ciptodadi",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Jaya Loka",
                "kelurahan": "Bumi Rejo",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Sukakarya",
                "kelurahan": "Bangun Rejo",
                "kodepos": "31665"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Tugu Sempurna",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Temuan Jaya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Temuan Sari",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Remayu",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Sukamulya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Tanjung Lama",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Petunang",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Pulau Panggung",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Mekar Sari",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Muara Kelingi",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Petrans Jaya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Marga Sakti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Mambang",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Mandi Aur",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Mangan Jaya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Lubuk Tua",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Lubuk Rumbai",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Lubuk Muda",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Karya Teladan",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Lebanjaya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Karya Sakti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Karya Mukti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Jaya Tunggal",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Jaya Bakti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Binjai",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Dharma/Darma Sakti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Bingin Janggut",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Muara Kelingi",
                "kelurahan": "Beliti Jaya",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Banpres",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Bamasko",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Wukir Sari",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tuah Negeri",
                "kelurahan": "Air Beliti",
                "kodepos": "31663"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Wonorejo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Wonokerto",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Widodo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Trikoyo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Tegal Rejo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Surodadi",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Tambah Asri",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Sukomulio",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Srikaton",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Siti Harjo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Ngadirejo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Sidoharjo",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Nawangsari",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Mataram",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Kali Bening",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Tugumulyo",
                "kelurahan": "Dwijaya",
                "kodepos": "31662"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Tanah Periuk I",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Suro (Dusun Suro)",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Simpang Gegas Temuan",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Satan Indah Jaya",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Rantau Serik/Resik",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Pedang (Dusun Pedang)",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Rantau Bingin",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Pasar Muara Beliti",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Muara Kati Lama",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Muara Kati Baru II",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Muara Beliti Baru",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Muara Kati Baru I",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Mana Resmi",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Lubuk Besar",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Ketuan Jaya",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Kebur",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Durian Remuk",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Tiang Pumpung Kepungut",
                "kelurahan": "Batu Bandung",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Bumi Agung",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Air Lesing",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Muara Beliti",
                "kelurahan": "Air Satan",
                "kodepos": "31661"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Rantau Kadam",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Setia Marga",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Karang Dapo II",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Kertasari",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Bina Karya",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Karang Dapo I",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Biaro Baru",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Biaro Lama",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Wonosari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Karang Dapo",
                "kelurahan": "Aringin",
                "kodepos": "31658"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Trisakti",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Sumber Rejo",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Tegal Sari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Pagar Ayu",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Rejosari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Muara Megang I",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Mulyo Sari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Megang Sakti V",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Mekarsari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Muara Megang",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Megang Sakti III",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Megang Sakti IV",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Megang Sakti II",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Karya Mulya",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Marga Puspita",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Megang Sakti I",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Jajaran Baru",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Jajaran Baru II",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Teladas",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Megang Sakti",
                "kelurahan": "Campur Sari",
                "kodepos": "31657"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Sungai Lanang",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Surulangun",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Sungai Jauh",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Sungai Kijang",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Simpang Nibung Rawas",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Sungai Baung",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Pulau Lebar",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Remban",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Pasar Surulangun",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Pangkalan",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Lubuk Kemang",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Lubuk Mas",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Lesung Batu",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Lesung Batu Muda",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ulu",
                "kelurahan": "Kerta Dewa",
                "kodepos": "31656"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Pauh",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Pauh I",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Bingin Teluk",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Mandi Angin",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Beringin Makmur II",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Beringin Sakti",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Belani",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Beringin Makmur I",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Batu Kucing",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Terusan",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rawas Ilir",
                "kelurahan": "Air Bening",
                "kodepos": "31655"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Sungai Jernih",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Sukamenang",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Sukaraja",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Rantau Jaya",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Rantau Telang",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Noman Baru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Pantai",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Noman",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Muara Tiku",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Muara Batang Empu",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Muara Rupit",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Maur Baru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Maur Lama",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Lubuk Kumbang/Kumbung",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Lubuk Rumbai",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Lubuk Rumbai Baru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Lawang Agung",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Karang Waru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Karang Jaya",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Karang Anyar",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Embacang Lama",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Bukit Ulu",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Embacang Baru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Karang Jaya",
                "kelurahan": "Bukit Langkap",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Bingin Rupit Ulu",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Batu Gajah Baru",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Beringin Jaya",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Rupit",
                "kelurahan": "Batu Gajah",
                "kodepos": "31654"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Trimukti",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Tambangan",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Trijaya",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Sungai Naik",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Suka Makmur",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Sungai Bunut",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Sadu",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Sembatu Jaya Spi",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Raksa Budi",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Mulyo Harjo",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Pangkalan Tarum Lama",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Pelawe",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Lubuk Pauh",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Mekar Jaya",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Kembang Tanjung",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Kota Baru",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Gunung Kembang Baru",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Gunung Kembang Lama",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Batukuning Lakitan Ulu (BTS)/Cecar",
                "kelurahan": "Bangun Jaya",
                "kodepos": "31652"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Taba Tengah",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Taba Remanik",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Taba Renah",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Taba Gindo",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Napal Melintang",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Prabu/Perabu Menang",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Selangit",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Lubuk Ngin",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Lubuk Ngin Baru",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Muara Nilau",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Batu Gane",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Selangit",
                "kelurahan": "Karang Panggung",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sumber Sari",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Terawas",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sumber Harta",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sumber Jaya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sumber Karya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukaraya Baru",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukarejo",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sukamulya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sukarami Jaya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukaraya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukamana",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukamerindu",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sukajaya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Sukamaju",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sukakarya",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Paduraksa",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Pasenan",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Sri Mulyo",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Madang/Medang",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Kosgoro",
                "kodepos": "30771"
            },
            {
                "kecamatan": "Sumber Harta",
                "kelurahan": "Jamburejo",
                "kodepos": "30771"
            },
            {
                "kecamatan": "STL Ulu Terawas",
                "kelurahan": "Babat",
                "kodepos": "30771"
            }
        ],
        "k433": [
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Watervang",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Wira Karya",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Taba Lestari",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Taba Koji",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Mesat Seni",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Nikan Jaya",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Megang",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Majapahit",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Karya Bakti",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Cereme Taba",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Dempo",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Air Kuti",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Batu Urip Taba",
                "kodepos": "31628"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Tanah Priuk",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Batu Urip",
                "kodepos": "31627"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Siring Agung",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Taba Pingin",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Simpang Priuk",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Perumnas Rahmah",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Rahmah",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Moneng Sepati",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Marga Rahayu",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Marga Mulya",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Lubuk Binjai",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Lubuk Kupang",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Jukung",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Karang Ketuan",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Dua (II)",
                "kelurahan": "Eka Marga",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Air Temam",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Selatan Satu (I)",
                "kelurahan": "Air Kati",
                "kodepos": "31626"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Satu (I)",
                "kelurahan": "Taba Jemekeh",
                "kodepos": "31625"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Mesat Jaya",
                "kodepos": "31624"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Jawa Kanan Ss",
                "kodepos": "31621"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Jawa Kiri",
                "kodepos": "31622"
            },
            {
                "kecamatan": "Lubuk Linggau Timur Dua (II)",
                "kelurahan": "Jawa Kanan",
                "kodepos": "31621"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Taba Baru",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Sumber Agung",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Senalang",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Puncak Kemuning",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Ponorogo",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Marga Rejo (Margorejo)",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Pasar Satlit",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Marga Bakti",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Kenanga",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Jogoboyo (Joyoboyo)",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Kali Serayu",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Belalau II",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Durian Rampak",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Petanang Ulu",
                "kodepos": "31618"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Belalau I",
                "kodepos": "31619"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Dua (II)",
                "kelurahan": "Ulak Surung",
                "kodepos": "31617"
            },
            {
                "kecamatan": "Lubuk Linggau Utara Satu (I)",
                "kelurahan": "Petanang Ilir",
                "kodepos": "31618"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Kayu Ara",
                "kodepos": "31615"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Sidorejo",
                "kodepos": "31616"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Lubuk Tanjung",
                "kodepos": "31614"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Lubuk Aman",
                "kodepos": "31614"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Sukajadi",
                "kodepos": "31613"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Tapak Lebar",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Ulak Lebar",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Tanjung Aman",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Tanjung Indah",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Pelita Jaya",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Muara Enim",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Pasar Permiri",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Lubuk Linggau Ilir",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Lubuk Linggau Ulu",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Lubuk Durian",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Keputraan",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Bandung Ujung",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Dua (II)",
                "kelurahan": "Bandung Kanan",
                "kodepos": "31611"
            },
            {
                "kecamatan": "Lubuk Linggau Barat Satu (I)",
                "kelurahan": "Bandung Kiri",
                "kodepos": "31611"
            }
        ],
        "k434": [
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Talang Padang",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Ulak Babuk",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Pasar Tanjung Padang",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Remantai",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Talang Durian",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Padang Titiran",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Macang Manis",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Kembahang Lama",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Lampar Baru Talang Padang",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Lubuk Buntak",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Kembahang Baru",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Karang Are",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Talang Padang",
                "kelurahan": "Canggu",
                "kodepos": "31596"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Talang Padang",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Talang Randai",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Padang Gelai",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Pagar Jati",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Penantian",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Nanjungan",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Padang Bindu",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Muara Aman",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Muara Rungga",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Muara Sindang",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Kebon Jati (Keban Jati)",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Lawang Agung",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Air Mayan",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Pasemah Air Keruh",
                "kelurahan": "Bandar Agung",
                "kodepos": "31595"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Tapa Lama",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Teluk Bengkulu",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Tapa Baru",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Puntang",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Tangga Rasa",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Simpang Perigi",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Paduraksa",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Pulau Kemang",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Padang Tepong",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Muara Betung",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Muara Kalangan",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Marta Pura",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Lubuk Puding Baru",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Lubuk Puding Lama",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Karang Gede",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Kunduran",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Karang Anyar",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Karang Dapo Baru",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Karang Dapo Lama",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Batu Lintang",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Galang",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Sikap Dalam",
                "kelurahan": "Bandar Aji",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Batu Bidung",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Umojati",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Ulu Musi",
                "kelurahan": "Air Kelinsar",
                "kodepos": "31594"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Tebat Payang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Tanjung Raman",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Tanjung Jati",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Tanjung Eran",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Tanjung Alam",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Sukarami",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Sarang Bulan",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Rantau Kasai",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Rantau Dodor",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Rantau Aleh (Rantaualih)",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Pendopo",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Pagar Jati",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Pagar Alam",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Nibong (Nibung)",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Padang Bindu",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Nanjungan",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Muara Lintang Baru",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Muara Lintang Lama",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Muara Danau",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Manggilang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Muara Karang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Lubuk Tapang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Lubuk Sepang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Lubuk Layang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Lubuk Cik",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Lingge",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Lesung Batu",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Landur",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Karang Tanding",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Kungkilan (Nungkilan)",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Karang Caya",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Jarakan",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Gunung Meraksa Lama",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Endalo",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Beruge Ilir",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Gunung Meraksa Baru",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Batu Ampar",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Batu Cawang",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Bayau",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Bandar Agung",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo",
                "kelurahan": "Baruge/Beruge Tengah",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Pendopo Barat",
                "kelurahan": "Air Kandis",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Lintang Kanan",
                "kelurahan": "Babatan",
                "kodepos": "31593"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Tanjung Tawang",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Talang Baru",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Talang Benteng",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Sapapanjang",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Seleman Ulu",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Niur",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Suka Dana",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Padang Burnai",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Sawah",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Muara Pinang Lama",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Seleman Ilir",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Muara Semah",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Pajar Menang",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Muara Timbuk",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Muara Pinang Baru",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Lubuk Ulak",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Belimbing",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Gedung Agung",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Lubuk Tanjung",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Batu Galang",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Muara Pinang",
                "kelurahan": "Batu Jungul",
                "kodepos": "31592"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Ulak Mengkudu",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Terusan Lama",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Ujung Alih",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Terusan Baru",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Tanjung Ning Tengah",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Tanjung Ning Jaya",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Tanjung Ning Lama",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Tanjung Ning Simpang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tanjung Makmur",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tanjung Kupang Baru",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tanjung Kupang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Tanjung Kupang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Suka Kaya",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Taba",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sugi Waras",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Sawah",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Seguring Kecil",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Rantau Tenang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Pancurmas (Unit Jaya)",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Pasar Tebing Tinggi",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Muara Saling",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Mekar Jaya",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Mekarti Jaya",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Lubuk Gelanggang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Lubuk Kelumpang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Lampar Baru",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kupang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kemang Manis",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kota Gading",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Saling",
                "kelurahan": "Kebon",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Jaya Loka",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Fajar/Pajar Bakti",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Batu Raja Baru",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Batu Raja Lama",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Batu Panceh",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Aur Gading",
                "kodepos": "31453"
            }
        ],
        "k435": [
            {
                "kecamatan": "Jarai",
                "kelurahan": "Tertap",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Sukananti",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Tanjung Menang",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Talang Tinggi",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Penantian",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Sadan",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Nantigiri",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Serambi",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Pagar Dewa",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Pelajaran",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Pamah Salak",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Muaratawi",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Muara Payang",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Muara Gelumpai",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Mangun Sari",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Muara Jauh",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Lawang Agung Baru",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Lawang Agung Lama",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Lubuk Saung/Sawung",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Karang Tanding",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Kedaton",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Jemaring",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Gunung Megang",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Bandaraji",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Jarai",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Ulak Lebar",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Muarapayang",
                "kelurahan": "Bandu Agung",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Ujung Pulau",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Gunung Kaya",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Tanjung Sakti",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Jarai",
                "kelurahan": "Aromantai",
                "kodepos": "31591"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Talang Tinggi",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Tanjung Alam",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Suban",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Sindang Panjang",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Simpang Tiga Pomo/Pumu",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Pulau Panggung",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Pulau Panas",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Lubuk Dalam",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Pajar Bulan",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Kepala Siring",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Penandingan",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Kembang Ayun",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Pagar Jati",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Karang Agung",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Negeri Kaya",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Gunung Raya",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Pagar Agung",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Gunung Meraksa",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Muara Cawang",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Gunung Kembang",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Lubuk Tabun",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Gunung Kerto",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Masam Bulan",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Gunung Ayu",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Gunung Agung",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Genting/Genteng",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumi",
                "kelurahan": "Benteng",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Tanjung Sakti Pumu",
                "kelurahan": "Batu Rancing",
                "kodepos": "31581"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Ulak Pandan",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Tanjung Telang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Telatang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Tanjung Menang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Tanjung Pinang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Tanjung Jambu",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Tanjung Lontar",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Suka Merindu",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Talang Akar",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Suka Marga",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Suka Cinta",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Sirah Pulau",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Purwosari (Purwa Sari)",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Prabu Menang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Sengkuang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Perangai",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Padang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Payo",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Nanjungan",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Negeri Agung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Muara Lawai",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Muara Maung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Muara Temiang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Merapi",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Lubuk Pedaro/Pedaru",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Lematang Jaya",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Lubuk Kepayang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Lubuk Betung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Lebuay Bandung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Lebak Budi",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Karang Rejo",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Kebur",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Karang Endah",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Gunung Kembang",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Barat",
                "kelurahan": "Gunung Agung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Selatan",
                "kelurahan": "Geramat",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Cempaka Wangi",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Gedung Agung",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Banjar Sari",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Tunggul Bute",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Merapi Timur",
                "kelurahan": "Arahan",
                "kodepos": "31471"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Tebat",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Tebat Langsat",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Nibung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Tanjung Raman",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Kurung Ilir",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Kurung Ulu",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Bay/Bai",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Sukarame",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Singapura",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Sukaraja",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Pandan Arang Ilir",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Pandan Arang Ulu",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Padang Perigi",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Pagar Ruyung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Muntar Alam Baru",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Muntar Alam Lama",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Lawang Agung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Muara Gula",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Kebon Jati",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Kota Agung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Karang Agung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Karang Endah",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Bintuhan",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Gedung Agung",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Gunung Lewat",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Kota Agung",
                "kelurahan": "Bangke",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Air Dingin Baru",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Air Dingin Lama",
                "kodepos": "31462"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Tinggi Hari",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Trans SP II Padang Muaradua",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Tanjung Mulak",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Tanjung Sirih",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Tanjung Raja",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Tanjung Aur",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Talang Sawah",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Talang Sejemput",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Siring Agung",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Sumber Karya",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Sawah Darat",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Simpur",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Sinjar Bulan",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Rimba Sujud",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Rindu Hati",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Pulau Pinang",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Penantian",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Pulau Pinang",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Pagar Gunung (Pagar Agung)",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Pagar Batu",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Padang Gumay",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Padang Pagun",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Pagar Alam",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Muara Cawang",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Muara Siban",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Muara Dua",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Lubuk Sepang",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Merindu",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Gumay Ulu",
                "kelurahan": "Lubuk Selo",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Lesung Batu",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Kuba",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Kupang",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Kerung",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Kedaton",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Karang Dalam",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Karang Agung",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pulau Pinang",
                "kelurahan": "Jati",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Garmidar Ulu",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Danau",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Garmidar Ilir",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Bandung Agung",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Batu Rusa",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Pagar Gunung",
                "kelurahan": "Air Lingkar",
                "kodepos": "31461"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Tanjung Menang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Talang Berangin",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Talang Jawa",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Sukananti",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Sengkuang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Penindaian",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Penandingan",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Pengentaan",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Pajar Bulan",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Padang Masad/Masat",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Muara Tiga",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Padang Bindu",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Mengkenang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Tanjung Tebat",
                "kelurahan": "Muara Danau",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Lubuk Dendan",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Lesung Batu",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Lawang Agung Mulak",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Keban Agung",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Karang Lebak",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Jadian Lama",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Jadian Baru",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Geramat",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Durian Dangkal",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Datar Balam",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Danau Belidang",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Babatan",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Mulak Ulu",
                "kelurahan": "Air Puar",
                "kodepos": "31453"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Wonorejo",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Ulak Bandung",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Wanaraya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Tanjung Bindu",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Tanjung Kurung",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Tanjung Aur",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Tanjung Alam",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Sungai Laru",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Tanda Raja",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Sukarami",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Sukoharjo",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Sukaraja",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Suka Merindu",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Singapura",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Suka Bakti",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Sirah Pulau",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Sidomakmur",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Seronggo",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Saung Naga",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Sendawar",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Purnamasari",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Purwo Rejo",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Purwa Raja",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Purbamas",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Pulau Beringin",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Penantian",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Petikal Baru",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Penantian",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Pagar Jati",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Pagardin",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Pandan Arang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Paduraksa",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Nanjungan",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Padang Bindu",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Muara Danau",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Muara Lingsing",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Muara Empayang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Mekar Jaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Marga Mulia",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Maspura",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Lubuk Seketi",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Lubuk Tampang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Linggar Jaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Lubuk Lungkang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Kepala Siring",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Lubuk Nambulan",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Keban Raya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Lubuk Kuta",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Kencana Sari",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Lubuk Layang Ilir",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Keban Agung",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Lubuk Layang Ulu",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Keban Agung Sp I",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Karang Endah",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Jajaran Lama",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Karang Cahaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Jajaran Baru",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Gunung Kembang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Jagabaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Gelumbang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Gunung Aji",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Gunung Karto",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Datar Serdang",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Gedung Agung",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Bunga Mas",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Cempaka Sakti",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Cecar",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Darma Raharja",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Batu Urip",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Cecar",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Beringin Jaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Tengah",
                "kelurahan": "Banyumas",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Bandarjaya",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Selatan",
                "kelurahan": "Banuayu",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Timur",
                "kelurahan": "Babat Lama",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Kikim Barat",
                "kelurahan": "Babat Baru",
                "kodepos": "31452"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Ulak Lebar",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanah Pilih",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Ulak Mas",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Talang Tinggi",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Tanjung Tebat",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Sukanegara",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Tanjung Payang",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Sukarami",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanjung Periuk",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Sukajadi",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Suka Makmur",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Selawi",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanjung Karangan",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Senabing",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanjung Baru",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Sugih Waras",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Rd PJKA Bandar Agung",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Sari Bunga Mas",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pagar Sari",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Penandingan",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Padang Lengkuas",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pagar Negara",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Ngalam Baru",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Muara Tandi",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Nantal",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Muara Cawang",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Manggul",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Makartitama",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Lubuk Tuba",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Mandi Angin",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Lahat Tengah",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Lubuk Atung",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Lubuk Mabar",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Keban",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Kota Raya",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Girimuliya",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Karang Anyar",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Karang Baru",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Darmo",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Endikat Ilir",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Batuniding",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Bd PJKA Lahat",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Gumay Talang",
                "kelurahan": "Batay",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Bandar Jaya",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Banjar Negara",
                "kodepos": "31419"
            },
            {
                "kecamatan": "Pseksu",
                "kelurahan": "Pagar Agung",
                "kodepos": "31417"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Gunung Gajah",
                "kodepos": "31418"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Talang Jawa Utara",
                "kodepos": "31416"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pagar Agung",
                "kodepos": "31417"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Talang Jawa Selatan",
                "kodepos": "31416"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Talang Jawa Induk",
                "kodepos": "31416"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Bandar Agung",
                "kodepos": "31414"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Kota Negara",
                "kodepos": "31414"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Kota Baru",
                "kodepos": "31415"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pasar Bawah",
                "kodepos": "31412"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pasar Lama",
                "kodepos": "31413"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Pasar Baru",
                "kodepos": "31411"
            },
            {
                "kecamatan": "Lahat",
                "kelurahan": "Kota Jaya",
                "kodepos": "31412"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Ulak Bandung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Tongkok",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Tanjung Raya",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Tanjung Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Talang Tangsi",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Talang Pagar Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Talang Mengkenang",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Talang Padang Tinggi",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Talang Baru",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Sumur",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Sukaraja",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Sukamerindu",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Sukabumi",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Rambai Kaca",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Pulau Panggung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Pulau",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Pajar Tinggi",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Pajar Bulan",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Pagar Kaya",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Kota Raya Lembak",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Kota Raya Darat",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Karang Caya",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Kapitan",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Jentian",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Gunung Liwat",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Sukamerindu",
                "kelurahan": "Guru Agung",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Gelung Sakti",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Benua Raja",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Bantunan",
                "kodepos": "31356"
            },
            {
                "kecamatan": "Pajar Bulan",
                "kelurahan": "Aceh",
                "kodepos": "31356"
            }
        ],
        "k436": [
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Sukorejo",
                "kodepos": "31551"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Gunung Dempo",
                "kodepos": "31529"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Sidorejo",
                "kodepos": "31527"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Nendagung",
                "kodepos": "31527"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Ulu Lurah/Rurah",
                "kodepos": "31526"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Tumbak Ulas",
                "kodepos": "31526"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Tebat Giri Indah",
                "kodepos": "31526"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Besemah Serasan",
                "kodepos": "31526"
            },
            {
                "kecamatan": "Pagar Alam Selatan",
                "kelurahan": "Tanjung Payang",
                "kodepos": "31522"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Reba Tinggi",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Selatan",
                "kelurahan": "Prahu Dipo",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Selatan",
                "kelurahan": "Penjalang",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Tengah",
                "kelurahan": "Padang Temu",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Tengah",
                "kelurahan": "Pelang Kenidai",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Muara Siban",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Selatan",
                "kelurahan": "Lubuk Buntak",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Tengah",
                "kelurahan": "Karang Dalo",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Selatan",
                "kelurahan": "Kance Diwe",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Tengah",
                "kelurahan": "Jokoh",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Jangkar Mas",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Tengah",
                "kelurahan": "Candi Jaya",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Bumi Agung",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Burung Dinang",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Dempo Selatan",
                "kelurahan": "Atung Bungsu",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Dempo Makmur",
                "kodepos": "31519"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Agung Lawangan",
                "kodepos": "31521"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Curup Jare",
                "kodepos": "31516"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Bangun Rejo",
                "kodepos": "31517"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Selibar",
                "kodepos": "31515"
            },
            {
                "kecamatan": "Dempo Utara",
                "kelurahan": "Pagar Wangi",
                "kodepos": "31515"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Kuripan Babas",
                "kodepos": "31514"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Pagar Alam",
                "kodepos": "31512"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Alun Dua",
                "kodepos": "31513"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Beringin Jaya",
                "kodepos": "31511"
            },
            {
                "kecamatan": "Pagar Alam Utara",
                "kelurahan": "Bangun Jaya",
                "kodepos": "31512"
            }
        ],
        "k437": [
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Tanjung Telang",
                "kodepos": "31146"
            },
            {
                "kecamatan": "Cambai",
                "kelurahan": "Muara Sungai",
                "kodepos": "31144"
            },
            {
                "kecamatan": "Cambai",
                "kelurahan": "Sungai Medang",
                "kodepos": "31145"
            },
            {
                "kecamatan": "Cambai",
                "kelurahan": "Sindur",
                "kodepos": "31142"
            },
            {
                "kecamatan": "Cambai",
                "kelurahan": "Pangkul",
                "kodepos": "31143"
            },
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Payu Putat",
                "kodepos": "31141"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Karya Mulya",
                "kodepos": "31135"
            },
            {
                "kecamatan": "Cambai",
                "kelurahan": "Cambai",
                "kodepos": "31141"
            },
            {
                "kecamatan": "Prabumulih Selatan",
                "kelurahan": "Tanjung Menang",
                "kodepos": "31134"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Karang Bindu",
                "kodepos": "31133"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Tanjung Rambang",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Sinar Rambang",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Rambang Senuling",
                "kodepos": "31132"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Talang Batu",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Karangan",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Kembang Tanduk",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Rambang Kapak Tengah",
                "kelurahan": "Jungai",
                "kodepos": "31131"
            },
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Patih Galung",
                "kodepos": "31127"
            },
            {
                "kecamatan": "Prabumulih Utara",
                "kelurahan": "Wonosari",
                "kodepos": "31126"
            },
            {
                "kecamatan": "Prabumulih Selatan",
                "kelurahan": "Majasari",
                "kodepos": "31124"
            },
            {
                "kecamatan": "Prabumulih Utara",
                "kelurahan": "Mangga Besar",
                "kodepos": "31125"
            },
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Prabumulih",
                "kodepos": "31123"
            },
            {
                "kecamatan": "Prabumulih Utara",
                "kelurahan": "Pasar Prabumulih II",
                "kodepos": "31121"
            },
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Gunung Kemala",
                "kodepos": "31122"
            },
            {
                "kecamatan": "Prabumulih Utara",
                "kelurahan": "Pasar Prabumulih",
                "kodepos": "31121"
            },
            {
                "kecamatan": "Prabumulih Barat",
                "kelurahan": "Muntang Tapus",
                "kodepos": "31121"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Gunung Ibul Barat",
                "kodepos": "31117"
            },
            {
                "kecamatan": "Prabumulih Utara",
                "kelurahan": "Anak Petai",
                "kodepos": "31121"
            },
            {
                "kecamatan": "Prabumulih Selatan",
                "kelurahan": "Sukaraja",
                "kodepos": "31112"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Karang Jaya",
                "kodepos": "31115"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Sukajadi",
                "kodepos": "31111"
            },
            {
                "kecamatan": "Prabumulih Selatan",
                "kelurahan": "Tanjung Raman",
                "kodepos": "31116"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Tugu Kecil",
                "kodepos": "31111"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Muara Dua",
                "kodepos": "31113"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Prabu Jaya",
                "kodepos": "31111"
            },
            {
                "kecamatan": "Prabumulih Selatan",
                "kelurahan": "Sukaraja",
                "kodepos": "31112"
            },
            {
                "kecamatan": "Prabumulih Timur",
                "kelurahan": "Karang Raja",
                "kodepos": "31111"
            }
        ],
        "k438": [
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Upang Marga",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Upang",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Tirto Raharjo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Tirto Harjo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Tirta Jaya",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Tirta/Tirto Mulyo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Tanjung Baru",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Timbul Jaya",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Sumber Makmur",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Sugih Waras",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Srimulyo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Sidomulyo 18",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Sidomulyo 20",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Sidoharjo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Sidorejo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Rejosari",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Srikaton",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Sido Makmur",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Saleh Makmur",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Muara Padang",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Purwodadi",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Margomulyo 20",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Margo Mulyo 16",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Mekar Jaya",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Marga Sugihan",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Marega Rukun (Margo/Marga Rukun)",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Juru Taro (Taroh)",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Kuala/Kualo Sugihan",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Karang Anyar",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Gilirang",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Indrapura",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Jalur Mulya",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Ganesha Mukti",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Enggalrejo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Ganesha Mukti",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Daya Makmur",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Daya Kusuma/Kesuma",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Daya Murni",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Damarwulan",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Cendana",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Daya Bangun Harjo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Bintaran",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Beringin Agung",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Air Solok Batu",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Sugihan",
                "kelurahan": "Argo Mulyo",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Padang",
                "kelurahan": "Air Gading",
                "kodepos": "30975"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Upang Karya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Upang Ceria",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Upang Jaya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Upang Cemara",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Terusan Tengah",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Terusan Dalam",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Terusan Muara",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Telang Sari",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Telang Makmur",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Telang Rejo",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Telang Karya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Telang Jaya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Talang Lubuk",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Talang Indah",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Sumber Mulya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Sumber Jaya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Sumber Hidup",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Sri Tiga",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Purwosari",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Panca Mukti",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Mulya Sari",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Mukti Jaya",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Muara Telang Marga/Margo",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Muara Telang",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Mekar Sari",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Mekar Mukti",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Muara Telang",
                "kelurahan": "Marga Rahayu",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Karang Baru",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Sumber Marga Telang",
                "kelurahan": "Karang Anyar",
                "kodepos": "30974"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Upang Mulya",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Upang Makmur",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Tirta Kencana",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Tanjungmas",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Tanjungbaru",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Sungaisemut",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Salek/Saleh Mulya",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Salek/Saleh Mukti",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Salek/Saleh Agung (Makmur)",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Air Salek",
                "kelurahan": "Salek/Saleh Jaya",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Purwosari",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Pengestu",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Muarabaru",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Pendowo Harjo",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Makarti Jaya",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Makarti Jaya",
                "kelurahan": "Delta Upang",
                "kodepos": "30972"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Teluk Payo",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Tanah Pilih",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sungsang IV",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Tabala Jaya",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sungsang III",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sungsang II",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sungsang I",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Rimau Sungsang",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Sri Agung",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Mekarsari",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Muara Sungsang",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Perajen Jaya",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Majuria",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Marga Sungsang",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Karang Sari",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Banyuasin II",
                "kelurahan": "Jati Sari",
                "kodepos": "30971"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Tanjung Tiga",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Tebing Abang",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Talang Kemang",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Tanjung Menang Musi",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sungainaik",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sungai Lilin",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sungai Pinang",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Semuntul",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sri Jaya",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sukarela",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Rantau Harapan",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Sejagung",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Penandingan",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Rantau Bayur",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Muara Abab",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Pagar Bulan",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Peldas",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Lubuk Rengas",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Kemang Bejalu",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rantau Bayur",
                "kelurahan": "Lebung",
                "kodepos": "30968"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanjung Merbu",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanjung Kerang",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Sungai Pinang",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanah Lembak",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Sungai Dua",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Sungai Kedukan",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Suka Pindah",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Siju",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Rambutan",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Sako",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Pelaju",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Pulau Parang",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Menten",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Pangkalan Gelebak",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Parit",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Gelebak Dalam",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Kebon Sahang",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Desa Baru",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Durian Gadis",
                "kodepos": "30967"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Tirto Sari",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Teluk Tenggirik",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Tirta Makmur",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Sungai Gerong",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Sungai Rebo",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Sido Makmur",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Sidomulyo",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Rimba Jaya",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Sebokor",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Sebubus",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Pulau Borang",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Perambahan",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Perambahan Baru",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Pematang Palas",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Perajen",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Panca Mulya",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Panca Desa",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Nusa Makmur",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Padang Rejo",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Mariana Ilir",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Muarabaru",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Merah Mata",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Kumbang Padang Permata",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Mariana",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Cinta Manis Lama",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Banyuasin I",
                "kelurahan": "Duren Ijo",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Budi Mulyo",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Cinta Manis Baru",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Air Kumbang",
                "kelurahan": "Air Kumbang Bakti",
                "kodepos": "30962"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Tanjung Lago",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Talang Keramat",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Tanah Mas",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Talang Buluh",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Sumber Mekar Mukti",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Sungai Rengit",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Sungairengit Murni",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Sukajadi",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Sukamoro",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Suka Damai",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Suka Tani",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Sebalik",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Seri/Sri Menanti",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Pangkalan Benteng",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Manggar Raya",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Muara Sugih",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Kuala Puntian",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Kenten",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Kenten Laut",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Gasing",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Bangun Sari",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Banyu Urip",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Tanjung Lago",
                "kelurahan": "Bunga Karang",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Talang Kelapa",
                "kelurahan": "Air Batu",
                "kodepos": "30961"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Wana Mukti",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Wonodadi",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Wonosari",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Tirta Mulya (Tirto Mulyo)",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Teluk Tenggulang",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Sumber Rejo",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Tabuan Asri",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Teluk Betung",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Sumber Mukti",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Sumber Mulya/Mulyo",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Sumber Agung",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Sukamulya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Suka Karya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Suka Raja",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Sukajaya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Senda Mukti (Air Senda II)",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Songo Makmur",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Sidomulyo",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Ringin Harjo",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Rukun Makmur",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Purwodadi",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Rawa Banda",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Penuguan",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Pancamulya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Nunggal Sari",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Mekar Sari",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Mukut",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Keluang",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Marga Rahayu",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Majatra",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Karang Mulya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Karang Manunggal",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Kelapa Dua",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Karang Anyar",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Karang Asem",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Bumi Serdang",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Bumi Rejo",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Dana Mulya",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Buana Murti (Air Senda III)",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Budi Asih",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Tungkal Ilir",
                "kelurahan": "Bentayan",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Pulau Rimau",
                "kelurahan": "Banjarsari (Air Senda IV)",
                "kodepos": "30959"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Tanjung Laut",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Taja / Talang Jaya Raya II",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Talang Ipuh",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Taja / Talang Jaya Indah",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Taja / Talang Jaya Mulya",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Taja / Talang Jaya Raya 1",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Sukaraja",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Suka Mulya",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Sedang",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Sri / Seri Kembang",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Rimba Asam",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Rimba Terab",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Pulau Rajak",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Lubuk Lancang",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Meranti",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Lubuk Karet",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Durian Daun",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Bukit",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Biyuku",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Betung",
                "kelurahan": "Betung",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Bengkuang",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Suak Tapeh",
                "kelurahan": "Air Senggeris",
                "kodepos": "30958"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Terlangu",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Tanjung Menang",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Terentang",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Tanjung Kepayang",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Sri Bandung",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Tanjung Agung",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Suka Makmur",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Sidang Mas",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Santansari",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Rimba Alai",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Rimba Balai",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Rejodadi",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Regan Agung",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Purwosari",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Pulau Harapan",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Pulau Muning",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Pelajau Ilir",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Petaling",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Pangkalan Panji",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Pelajau",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Muara Damai",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Lubuk Saung",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Mainan",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Limau",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Limbang Mulya",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Langkan",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Sembawa",
                "kelurahan": "Lalang Sembawa",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Galang Tinggi",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Kedondong Raye",
                "kodepos": "30953"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Suka Mulya",
                "kodepos": "30916"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Kayuara Kuning",
                "kodepos": "30915"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Mulya Agung",
                "kodepos": "30914"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Seterio",
                "kodepos": "30912"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Suka Raja Baru",
                "kodepos": "30913"
            },
            {
                "kecamatan": "Banyuasin III",
                "kelurahan": "Pangkalan Balai",
                "kodepos": "30911"
            }
        ],
        "k439": [
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Ulak Segara",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Tangai Sukananti",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Tanjung Bulan",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Tanjung Miring",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Sukananti",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Sunur",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Tambang Rambang",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Kuang Dalam Timur",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Lubuk Tunggal",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Ibul Dalam",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Kayu Ara",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Kuang Dalam Barat",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Talang Tengah Laut",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Ulak Kembahang",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Rambang Kuang",
                "kelurahan": "Beringin Dalam",
                "kodepos": "30869"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Lubuk Keliat",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Payalingkung",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Talang Tengah Darat",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Kasih Raja",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Ketiau",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Betung II",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Embacang",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Lubuk Keliat",
                "kelurahan": "Betung I",
                "kodepos": "30868"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Santapan Barat",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Santapan Timur",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Tanjung Alai",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Muara Kumbang",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Pandan Arang",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Lubuk Rukam",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Lubuk Segonang",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Miji",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kumbang Ulu",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kandis II",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kumbang Ilir",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Tanjung Mas",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Kandis",
                "kelurahan": "Kandis I",
                "kodepos": "30867"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Sukananti",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Sukananti Baru",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Talang Sari",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Sirah Pulau Kilip",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Suka Maju (Sukamuju)",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Sukamarga",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Rantau Alai",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Sanding Marga",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Lebung Bandung",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Mekar Sari",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Kelampaian",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Rantau Alai",
                "kelurahan": "Kertabayang",
                "kodepos": "30866"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Seri Menanti",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Suka Cinta",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Tanabang Ulu",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Rantau Sialang",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Seri Kembang",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Muara Kuang",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Munggu",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Nagasari",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Kuang Anyar",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Kalampadu (Kelampadu)",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Muara Kuang",
                "kelurahan": "Kasah",
                "kodepos": "30865"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Ulak Banding",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Ulak Bedil",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Ulak Segelung",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Timbangan",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Tunas Aur",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Tanjung Pule",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Tebing Gerinting Selatan",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Tebing Gerinting Utara",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Tanjung Dayang Utara",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Tanjung Gelam",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Tanjung Lubuk",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Tanjung Baru",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Tanjung Dayang Selatan",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Tanjung Agung",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Sungai Rambutan",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Talang Aur",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Sukaraja Baru",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Sukaraja Lama",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Suka Mulia",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Suak/Soak Batok",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Sudimampir",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Purnajaya",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Sejaro Sakti",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Permata Baru",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Pulau Kabai",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Pulau Semambu",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Parit",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Penyandingan",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Palem Raya",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Muara Penimbung Ulu",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Muara Penimbung Ilir",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Meranjat Ilir",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Meranjat II",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Meranjat III",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Mandiangin",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Lubuk Sakti",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Meranjat I",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Lorok",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Indralaya Mulia",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Indralaya Raya",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Bakung",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Indralaya Indah",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Beti",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya Selatan",
                "kelurahan": "Arisan Gading",
                "kodepos": "30862"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Sakatiga",
                "kodepos": "30816"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Tanjung Seteko",
                "kodepos": "30817"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Sakatiga Seberang",
                "kodepos": "30814"
            },
            {
                "kecamatan": "Indralaya",
                "kelurahan": "Tanjung Sejaro",
                "kodepos": "30815"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Payakabung",
                "kodepos": "30812"
            },
            {
                "kecamatan": "Indralaya Utara",
                "kelurahan": "Tanjung Pering",
                "kodepos": "30813"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Tebedak II",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Tebedak I",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Tambak",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Tambak Baru",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Pinang I",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Pinang II",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Batu Timur",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Tanjung Lalang",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Laut",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Batu Seberang",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Atap Barat",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Baru Petai",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Batu",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Tanjung Atap",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Talang Seleman",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Seri Tanjung",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Seri Kembang III",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Seri Kembang I",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Seri Kembang II",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Senuro Timur",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Seri Bandung",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Sentul",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Senuro Barat",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Rengas I",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Rengas II",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Payaraman Barat",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Payaraman Timur",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Paya Besar",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Pajar/Fajar Bulan",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Payaraman",
                "kelurahan": "Lubuk Bandung",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Limbang Jaya II",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Limbang Jaya I",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Bangun Jaya",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Batu",
                "kelurahan": "Burai",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Ulak Kerbau Baru",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Ulak Kerbau Lama",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja Utara",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Temiang",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja Selatan",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja Timur",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja Barat",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Raja",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Agas",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Talang Balai Lama",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Talang Dukun",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Talang Balai Baru II",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Talang Balai Baru I",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Tajung Serian",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang Nibung",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Sungai Rotan",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang II",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang III",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang Lagati",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Suka Pindah",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Sungai Pinang I",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Skonjing",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Sridalam/Seri Dalam",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Siring Alam",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Serijabo Baru",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Serijabo",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Sejangko II",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Rantau Panjang Ulu",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Sejangko I",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Rantau Panjang Ilir",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Pinang Mas",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Penyandingan",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Sungai Pinang",
                "kelurahan": "Pinang Jaya",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Kota Daro I",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Kota Daro II",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Ketapang II",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Kerinjing",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Ketapang I",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Jagarajo (Jaga Raya)",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Tanjung Raja",
                "kelurahan": "Belanti",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Jagalano (Jagalana)",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Rantau Panjang",
                "kelurahan": "Arisan Deras",
                "kodepos": "30661"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Ulak Kembahang II",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Ulak Petangisan",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Ulak Kembahang I",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Ulak Aur Standing",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Teluk Kecapi",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Talang Pangeran Ilir",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Talang Pangeran Ulu",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Sungai Rasau",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Sungai Ondok",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Sungai Lebung",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Sungai Lembung Ulu",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Sungai Keli",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Sungai Keli",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Sukarami",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Suka Merindu",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Simpang Pelabuhan Dalam",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Seribanding (Seri Bandung)",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Sembadak",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Saranglang",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Segayam",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Pulau Negara",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Rawajaya",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Pemulutan Ilir",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Pemulutan Ulu",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Pipa Putih",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Pematang Bungur",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Pegayut",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Pelabuhan Dalam",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Pematang Bangsal",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Palu",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Naikan Tembakang",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Muara Baru",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Muara Dua",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Maju Jaya",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Mayapati",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Mekarjaya",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Lebung Jangkar",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Lebak Pering",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Kamal",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Kedukan Bujang",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Kapuk",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Ibul Besar II",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Ibul Besar III",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Ibul Besar I",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Harimau Tandang",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Harapan",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Selatan",
                "kelurahan": "Cahaya Marga",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Babatan Saudagar",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan",
                "kelurahan": "Aurstanding",
                "kodepos": "30653"
            },
            {
                "kecamatan": "Pemulutan Barat",
                "kelurahan": "Arisan Jaya",
                "kodepos": "30653"
            }
        ],
        "k440": [
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ulak Embacang",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Tanjung Raya",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Terusan",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Panai",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Penggage",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ngulak II",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ngulak III",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ngunang",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Nganti",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ngulak",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Ngulak I",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Macang Sakti",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Keban II",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Kemang",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Keban I",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Jud I",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Jud II",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Air Balui",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Sanga Desa",
                "kelurahan": "Air Itam",
                "kodepos": "30759"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Warga Mulya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Tri Mulya Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Tanjung Kaputran",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sukamaju",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sukajaya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Sukajadi",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Suka Makmur",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Suka Makmur",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Suka Damai",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Sri Karang Rejo",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Sri Gading",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sidorahayu",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sido Mukti",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Sialang Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Sari Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Ringin Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Perumpung Raya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Purwo Agung (Purwa Agung)",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Mulya Jaya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Mulya Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Mekar Sari",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Mandala Sari",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karya Mukti",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Madya Mulya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Tirta",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Sari",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Rejo",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Mukti",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Makmur",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Karang Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Jaya Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Gali Sari",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Cinta Karya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Bumi Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Bukit Indah",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Bangun Harjo/Harja",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Air Putih Ulu",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Bandar Agung",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Plakat Tinggi (Pelakat Tinggi)",
                "kelurahan": "Air Putih Ilir",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lalan (Sungai Lalan)",
                "kelurahan": "Agung Jaya",
                "kodepos": "30758"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Teluk Kijing III",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Teluk Kijing II",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Teluk Kijing I",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Teluk",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Tebing Bulang",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Tanjung Agung Timur",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Tanjung Agung Utara",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Tanjung Agung Barat",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Tanjung Agung Selatan",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Talang Mandung",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Talang Simpang",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Suka Lali",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Sungai Dua",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Sindang Marga",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Rukun Rahayu",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Setia Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Sinar Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Rantau Sialang",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Rejosari",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Rantau Keroya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Petaling",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Purwosari",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Layan",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Mekarjaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Pagar Kaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Kertayu",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Lais",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Jirak",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Keramat Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Kerta Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Gajah Mati",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Jembatan Gantung",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Epil",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Lais",
                "kelurahan": "Danau Cala",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Baru Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Telang",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Wono Rejo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Sungai Keruh",
                "kelurahan": "Bangkit Jaya",
                "kodepos": "30757"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Sumber Sari",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Tampang Baru",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Sumber Harum",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Sukajaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Suka Damai",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Srimulyo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Sindang Marga",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Sinar Harapan",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Sinar Tungkal",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Sido Mulyo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Simpang Tungkal",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Simpang Bayat",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Pulai Gading",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Senawar Jaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Pangkalan Tungkal",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Peninggalan",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Pangkalan Bayat",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Pandan Sari",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Pagar Desa",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Muara Medak",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Muara Merang",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Mendis Jaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Muara Bahar",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Mekar Jaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Mendis",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Margo Mulyo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Kepayang",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Lubuk Harjo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Mangsang",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Berojaya Timur",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Kali Berau",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Beji Mulyo",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Berlian Jaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Bayung Lencir",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Bayung Lencir Indah",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Tungkal Jaya",
                "kelurahan": "Banjar Jaya",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Bayung Lencir",
                "kelurahan": "Bayat Ilir",
                "kodepos": "30756"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Ulak Kembang",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Tenggulang Jaya (Nilam Sari)",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Tanjung Kerang",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Tanjung Bali",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Tenggulang Baru",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Talang Leban",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Tanah Abang",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Talang Buluh",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Supat Barat",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Supat Timur",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Sungai Napal",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Supat",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Sungai Lilin",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Sungai Lilin Jaya",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Sumber Jaya",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Sumber Rejeki",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Suka Maju",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Srigunung",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Sukadamai Baru",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Saud",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Seratus Lapan",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Pinang Banjar",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Pinggap",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Sako/Suko Suban",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Pangkalan Bulian",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Pangkalan Bulian",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Mulyorejo (Mulya Asih)",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Nusa Serasan",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Panca Tunggal",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Mekar Jadi",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Lubuk Buah",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Letang",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Lubuk Bintialo",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Linggo Sari",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Langkap",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Cinta Damai",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Gajah Mati",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Bumi Kencana",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Bukit Sejahtera",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Bukit Selabu",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Batanghari Leko",
                "kelurahan": "Bukit Pangkuasan",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Berlian Makmur",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Sungai Lilin",
                "kelurahan": "Bukit Jaya",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Babat Ramba Jaya",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Bandar Tenggulang",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Tegal Mulya",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Tenggaro",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Babat Supat",
                "kelurahan": "Babat Banyuasin",
                "kodepos": "30755"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Sumber Agung",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Tanjung Dalam",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Mulyo Asih",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Sido Rejo",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Sridamai",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Mekarsari",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Keluang",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Loka Jaya (Pelancu Indah)",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Mekar Jaya",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Cipta Praja",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Dawas",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Keluang",
                "kelurahan": "Karya Maju",
                "kodepos": "30754"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Ulak Paceh Jaya",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Ulak Teberau",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Ulak Paceh",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Toman",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Talang Piase",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Tanjung Durian",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Sugiwaras",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Sungai Angit",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Sri Mulyo",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Sugi Raya",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Simpang Sari",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Sereka",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Rantau Panjang",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Pandan Dulang",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Rantau Kasih",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Muara Punjung",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Napal",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Kasmaran",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Mangun Jaya",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Karang Waru",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Karang Ringin II",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Karang Ringin I",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Karang Anyar",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Lawang Wetan",
                "kelurahan": "Bumi Ayu",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Beruga / Beruge",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Bangun Sari",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Sungai Medak",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Babat Toman",
                "kelurahan": "Babat",
                "kodepos": "30752"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Sukarami",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Sungai Batang",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Serasan Jaya",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Soak Baru",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Lumpatan Dua (2)",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Muara Teladan",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Rimbah Ukur",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Kayuara",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Lumpatan",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Bandar Jaya",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Bailangu Timur",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Balai Agung",
                "kodepos": "30711"
            },
            {
                "kecamatan": "Sekayu",
                "kelurahan": "Bailangu",
                "kodepos": "30711"
            }
        ],
        "k441": [
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Tegal Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Surya Karta",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Talang Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Sungai Sodong",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Talang Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Sungai Tepuk",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Surya Adi",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Sungai Sibur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Sungai Menang",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Sumbu Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Sumber Mulya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Sungai Ceper",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Sumber Baru",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Sumber Deras",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Pematang Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Sidomulyo",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Pagar Dewa",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Suka Mukti",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Pematang Bina Tani",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Suka Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Mulya Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Rotan Mulya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Mukti Karya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Sedyo Mulyo",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Mesuji Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Pematang Sukaramah",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Marga Bakti (Margo Bhakti)",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Pematang Sukatani",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Mataram Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Pinang Indah",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Jaya Bhakti",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Mekar Wangi",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Pematang Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Kali Deras",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Kota Baru",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Pematang Panggang",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Gedung Rejo",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Makarti Mulya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Harapan Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Labuhan Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Gajah Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Kerta Mukti",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Gajah Mati",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Kembang Jajar",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Gading Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Kemang Indah",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Gading Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Karya Usaha",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Embacang",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji",
                "kelurahan": "Karya Mukti",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Embacang Permai",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Karya Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Cipta Sari",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Karangsia",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Dabuk Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Kampung Baru",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Catur Tunggal",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Balian Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Cahaya Mas",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Sugih Waras",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Cahaya Mulya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Talang Pangeran",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Bumi Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Ulak Ketapang",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Sungai Menang",
                "kelurahan": "Bumi Pratama Mandira",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Serapek",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Beringin Jaya",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Seriguna",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Mesuji Makmur",
                "kelurahan": "Binakarsa (Bhina Karsa)",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Sinar Harapan Mulya",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Mesuji Raya",
                "kelurahan": "Balian Makmur",
                "kodepos": "30681"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Panca Tunggal Benawa",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Penyandingan",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Mulya Guna",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Kuripan",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Muara Telang",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Cintamarga",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Bumi Harapan",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Tanjung Makmur",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Teluk Gelam",
                "kelurahan": "Benawa",
                "kodepos": "30673"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Sukadamai",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Sukaraja",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Sumber Hidup",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Serinanti",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Suka Pulih",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Pulau Geronggang",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran VI",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran IV",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran V",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran II",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran III",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Pancawarna",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Pedamaran I",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Mari Baya",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Menang Raya",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Kayu Labu",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran Timur",
                "kelurahan": "Gading Rejo",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Cinta Jaya",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Ulak Kapal",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Pedamaran",
                "kelurahan": "Burnai Timur",
                "kodepos": "30672"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Merindu",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Ulak Balam",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Laga",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Laut",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Lubuk PMD",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Baru",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Sukamulya",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Sukarami",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Seritanjung",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Pengaraian",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Pulau Gemantung",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Kotabumi",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Jambu Ilir",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Jukdadak",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tanjung Lubuk",
                "kelurahan": "Bumiagung",
                "kodepos": "30671"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Tanjung Batu",
                "kodepos": "30664"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Talang Daya",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Sunggutan",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Sungai Bungin",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Sukadamai",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Rimba Samak",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Suka Raja",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Riding",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Rambai",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Pulauan (Pulawan)",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Pangkalan Lampam",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Perigi",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Lirik",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Deling",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Lebung Batang",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Darat",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Air Rumbai",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Pangkalan Lampam",
                "kelurahan": "Air Pedara",
                "kodepos": "30659"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Ulak Kedondong",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Talang Rimba",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Sungai Pasir",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Sungai Somor",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Sungai Lumpur",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Sungai Jeruju",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Sungai Ketupak",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Pelimbangan",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Kuala Sungai Pasir",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Kuala Sungai Jeruju",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Cengal",
                "kelurahan": "Cengal",
                "kodepos": "30658"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Tugumulyo",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Tulung Harapan",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Tugu Jaya",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Tugu Agung",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Tebing Suluh",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Tanjung Sari II",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Tanjung Sari I",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Tania Makmur",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Sumber Agung",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Sungai Belida",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Sukamulya",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Suka Jaya",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Suka Maju",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Sindang Sari",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Rantau Durian II",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Purwo Asri",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Rantau Durian I",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Muara Burnai I",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Muara Burnai II",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Mukti Sari",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Lubuk Makmur",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Lubuk Seberuk",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Mekar Jaya",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing Jaya",
                "kelurahan": "Lempuing Indah",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Kepahyang",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Kuta Pandan",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Dabuk Rejo",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Cahya Bumi",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Cahya Maju",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Bumi Agung",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Lempuing",
                "kelurahan": "Bumi Arjo",
                "kodepos": "30657"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Sungai Batang",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Tirta Mulya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Serijaya Baru",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Simpang Heran",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Suka Mulya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Rantau Karya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Rengas Abang",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Pangkalan Damai",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Pangkalan Sakti",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Negeri Sakti",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Nusakerta",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Nusantara",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Mukti Jaya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Marga Tani",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Jadi Mulya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Kerta Mukti",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Banyu Biru",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Bukit Batu",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Ujung Tanjung",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Air Sugihan",
                "kelurahan": "Bandar Jaya",
                "kodepos": "30656"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Tulung Selapan Ulu",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Tulung Seluang",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Toman",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Tulung Selapan Ilir",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Simpang Tiga Jaya",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Simpang Tiga Makmur",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Simpang Tiga Sakti",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Simpang Tiga",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Rantau Lurus",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Petaling",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Pulau Beruang",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Penanggoan Duren",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Penyandingan",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Kayu Ara",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Lebung Gajah",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Lebung Itam",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Cambai",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Tulung Selapan",
                "kelurahan": "Jerambah Rengas",
                "kodepos": "30655"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Ulak Kemang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Ulak Pianggu",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Tapus",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Ulak Depati",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Serdang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Serimenang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Tanjung Kemang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Secondong",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Sepang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Pulau Betung",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Pulau Layang",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Kuro",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Manggaris (Menggeris)",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Pampangan",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Kandis",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Keman",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Jermun",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Jungkal",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Pampangan",
                "kelurahan": "Bangsal",
                "kodepos": "30654"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Ulak Tembaga",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Terusan Menang",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Ulak Jermun",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Terusan Jawa",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Terate",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Tanjung Aur",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Tanjung Alai",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Tanjung Alai",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Talang Cempedak",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Sukaraja",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Sukadarma",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Sirah Pulau Padang",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Simpang Empat",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Serdang Menang",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Penyandingan",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Rawang Besar",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Rengas Pitu",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Pedu",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Pematang Buluran",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Pematang kijang",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Muara Batun",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Pantai",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Lubuk Ketepeng",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Mangun Jaya",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Jejawi",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Karang Agung",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Lingkis",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Bubusan",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Berkat",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Bungin Tinggi",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Batu Ampar",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Belanti",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Jejawi",
                "kelurahan": "Air Itam",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Sirah Pulau Padang",
                "kelurahan": "Awal Terusan",
                "kodepos": "30652"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Teloko",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Tanjung Serang",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Tanjung Menang",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Tanjung Rancing",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Serigeni Baru",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Serigeni Lama",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Lubuk Dalam",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Muara Baru",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Perigi",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Buluh Cawang",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Kayu Agung",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Kijang Ulu",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Arisan Buntal",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Banding Anyar",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Tanjung Lubuk",
                "kodepos": "30617"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Anyar",
                "kodepos": "30618"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Kedaton",
                "kodepos": "30617"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Kota Raya",
                "kodepos": "30617"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Jua Jua",
                "kodepos": "30616"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Cinta Raja/Raya",
                "kodepos": "30614"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Sidareksa",
                "kodepos": "30615"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Sukadana",
                "kodepos": "30611"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Paku",
                "kodepos": "30612"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Mangun Jaya",
                "kodepos": "30613"
            },
            {
                "kecamatan": "Kayu Agung",
                "kelurahan": "Celikah",
                "kodepos": "30611"
            }
        ],
        "k442": [
            {
                "kecamatan": "Plaju",
                "kelurahan": "Talang Putri",
                "kodepos": "30268"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Plaju Ilir",
                "kodepos": "30268"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Talang Bubuk",
                "kodepos": "30268"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Bagus Kuning",
                "kodepos": "30268"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Komperta",
                "kodepos": "30268"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "11 Ulu",
                "kodepos": "30267"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Plaju Darat",
                "kodepos": "30267"
            },
            {
                "kecamatan": "Plaju",
                "kelurahan": "Plaju Ulu",
                "kodepos": "30266"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "16 Ulu",
                "kodepos": "30265"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "13 Ulu",
                "kodepos": "30263"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "14 Ulu",
                "kodepos": "30264"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "Tangga Takat",
                "kodepos": "30264"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "Sentosa",
                "kodepos": "30261"
            },
            {
                "kecamatan": "Seberang Ulu II",
                "kelurahan": "12 Ulu",
                "kodepos": "30262"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Ogan Baru",
                "kodepos": "30258"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Karya Jaya",
                "kodepos": "30259"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Keramasan",
                "kodepos": "30259"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Kemas Rindo",
                "kodepos": "30258"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Kertapati",
                "kodepos": "30258"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "15 Ulu",
                "kodepos": "30257"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "2 Ulu",
                "kodepos": "30257"
            },
            {
                "kecamatan": "Kertapati",
                "kelurahan": "Kemang Agung",
                "kodepos": "30258"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "3-4 Ulu",
                "kodepos": "30255"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "1 Ulu",
                "kodepos": "30257"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "8 Ulu",
                "kodepos": "30252"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "7 Ulu",
                "kodepos": "30253"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "5 Ulu",
                "kodepos": "30254"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "9/10 Ulu",
                "kodepos": "30251"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "Silaberanti",
                "kodepos": "30251"
            },
            {
                "kecamatan": "Seberang Ulu I",
                "kelurahan": "Tuan Kentang",
                "kodepos": "30251"
            },
            {
                "kecamatan": "Sematang Borang",
                "kelurahan": "Sako Baru",
                "kodepos": "30165"
            },
            {
                "kecamatan": "Sako",
                "kelurahan": "Sako",
                "kodepos": "30163"
            },
            {
                "kecamatan": "Sako",
                "kelurahan": "Sukamaju",
                "kodepos": "30164"
            },
            {
                "kecamatan": "Sako",
                "kelurahan": "Sialang",
                "kodepos": "30161"
            },
            {
                "kecamatan": "Sematang Borang",
                "kelurahan": "Suka Mulya",
                "kodepos": "30161"
            },
            {
                "kecamatan": "Sematang Borang",
                "kelurahan": "Sri Mulya (Srimulyo)",
                "kodepos": "30162"
            },
            {
                "kecamatan": "Sematang Borang",
                "kelurahan": "Karya Mulya",
                "kodepos": "30161"
            },
            {
                "kecamatan": "Sematang Borang",
                "kelurahan": "Lebong/Lebung Gajah",
                "kodepos": "30161"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Talang Betutu",
                "kodepos": "30155"
            },
            {
                "kecamatan": "Alang-Alang Lebar",
                "kelurahan": "Alang-Alang Lebar",
                "kodepos": "30154"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Sukarami",
                "kodepos": "30153"
            },
            {
                "kecamatan": "Alang-Alang Lebar",
                "kelurahan": "Talang Kelapa",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Alang-Alang Lebar",
                "kelurahan": "Srijaya",
                "kodepos": "30153"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Sukodadi",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Suka Bangun",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Sukajaya",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Alang-Alang Lebar",
                "kelurahan": "Karya Baru",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Kebun Bunga",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Gandus",
                "kelurahan": "Pulo Kerto",
                "kodepos": "30149"
            },
            {
                "kecamatan": "Sukarami",
                "kelurahan": "Jambe (Talang Jambe)",
                "kodepos": "30151"
            },
            {
                "kecamatan": "Gandus",
                "kelurahan": "Gandus",
                "kodepos": "30149"
            },
            {
                "kecamatan": "Gandus",
                "kelurahan": "Karang Jaya",
                "kodepos": "30149"
            },
            {
                "kecamatan": "Gandus",
                "kelurahan": "36 Ilir",
                "kodepos": "30147"
            },
            {
                "kecamatan": "Gandus",
                "kelurahan": "Karang Anyar",
                "kodepos": "30148"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "Kemang Manis",
                "kodepos": "30144"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "32 Ilir",
                "kodepos": "30145"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "35 Ilir",
                "kodepos": "30146"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "29 Ilir",
                "kodepos": "30143"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "30 Ilir",
                "kodepos": "30144"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "27 Ilir",
                "kodepos": "30141"
            },
            {
                "kecamatan": "Ilir Barat II",
                "kelurahan": "28 Ilir",
                "kodepos": "30142"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "Bukit Lama",
                "kodepos": "30139"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "Lorok Pakjo",
                "kodepos": "30137"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "Siring Agung",
                "kodepos": "30138"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "26 Ilir",
                "kodepos": "30136"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "26 Ilir D. I",
                "kodepos": "30136"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "23 Ilir",
                "kodepos": "30133"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "24 Ilir",
                "kodepos": "30134"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "Talang Semut",
                "kodepos": "30135"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "19 Ilir",
                "kodepos": "30132"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "Demang Lebar Daun",
                "kodepos": "30131"
            },
            {
                "kecamatan": "Bukit Kecil",
                "kelurahan": "22 Ilir",
                "kodepos": "30131"
            },
            {
                "kecamatan": "Ilir Barat I",
                "kelurahan": "Bukit Baru",
                "kodepos": "30131"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Talang Aman",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "20 Ilir III",
                "kodepos": "30129"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Pahlawan",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Pipa Reja",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Sekip Jaya",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "20 Ilir IV",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "Ario Kemuning",
                "kodepos": "30128"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "20 Ilir I",
                "kodepos": "30126"
            },
            {
                "kecamatan": "Kemuning",
                "kelurahan": "20 Ilir II",
                "kodepos": "30127"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "Kepandean Baru",
                "kodepos": "30125"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "15 Ilir",
                "kodepos": "30124"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "17 Ilir",
                "kodepos": "30125"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "13 Ilir",
                "kodepos": "30123"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "14 Ilir",
                "kodepos": "30124"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "Sei Pangeran",
                "kodepos": "30121"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "16 Ilir",
                "kodepos": "30122"
            },
            {
                "kecamatan": "Kalidoni",
                "kelurahan": "Sei Selincah",
                "kodepos": "30119"
            },
            {
                "kecamatan": "Ilir Timur I",
                "kelurahan": "18 Ilir",
                "kodepos": "30121"
            },
            {
                "kecamatan": "Kalidoni",
                "kelurahan": "Sei Lais",
                "kodepos": "30119"
            },
            {
                "kecamatan": "Kalidoni",
                "kelurahan": "Sei Selayur",
                "kodepos": "30119"
            },
            {
                "kecamatan": "Kalidoni",
                "kelurahan": "Kalidoni",
                "kodepos": "30119"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "1 Ilir",
                "kodepos": "30117"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "2 Ilir",
                "kodepos": "30118"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "5 Ilir",
                "kodepos": "30115"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "3 Ilir",
                "kodepos": "30116"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "8 Ilir",
                "kodepos": "30114"
            },
            {
                "kecamatan": "Kalidoni",
                "kelurahan": "Bukit Sangkal",
                "kodepos": "30114"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "Sungai Buah",
                "kodepos": "30111"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "11 Ilir",
                "kodepos": "30112"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "9 Ilir",
                "kodepos": "30113"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "Kuto Batu",
                "kodepos": "30111"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "Lawang Kidul",
                "kodepos": "30111"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "10 Ilir",
                "kodepos": "30111"
            },
            {
                "kecamatan": "Ilir Timur II",
                "kelurahan": "Duku",
                "kodepos": "30111"
            }
        ],
        "k443": [
            {
                "kecamatan": "Hinai",
                "kelurahan": "Perkebunan Tanjung Beringin",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Suka Damai",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Kebun Lada",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Muka Paya",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Paya Rengas",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Cempa",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Hinai Kanan",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Baru Pasar VIII (8)",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Batu Melenggang",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Teluk Bakung",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Suka Maju",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Tapak Kuda",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pulau Banyak",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Serapuh Asli",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Serai",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Tengah",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pekubuan",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Cengal",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Cengal Barat",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Paya Perupuk",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Sei/Sungai Tualang",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Tangkahan Durian",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Lubuk Kertang",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Pangkalan Batu",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Perlis",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Kelantan",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Brandan Barat",
                "kelurahan": "Lubuk Kasih",
                "kodepos": "20881"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Suka Jaya",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Sekoci",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Serang Jaya",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Serang Jaya Hilir",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Salahaji",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Pir Adb Besitang",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Kampung Lama",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Pekan Besitang",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Bukit Selamat",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Halaban",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Bukit Kubu",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Besitang",
                "kelurahan": "Bukit Mas",
                "kodepos": "20859"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Sei/Sungai Meran",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Sei/Sungai Siur",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Pintu Air",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Pulau Kampai",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Pulau Sembilan",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Pematang Tengah",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Perkebunan Damar Condong",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Perkebunan Perapen",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Pangkalan Siata",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Paya Tampak",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Damar Condong",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pematang Jaya",
                "kelurahan": "Limau Mungkur",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Alur Cempedak",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Beras Basah",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Pangkalan Susu",
                "kelurahan": "Bukit Jengkol",
                "kodepos": "20858"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Securai Utara",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Teluk Meku",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Pelawi Utara",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Securai Selatan",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Brandan Timur Baru",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Pelawi Selatan",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Brandan Barat",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Babalan",
                "kelurahan": "Brandan Timur",
                "kodepos": "20857"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Paya Bengkuang",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pekan Gebang",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Sangga Lima (Perkebunan Serap)",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pasar Rawa",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Pasiran",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Padang Langkat",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Paluh Manis",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Dogang",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Kwala Gebang",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Air Hitam",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Gebang",
                "kelurahan": "Bukit Mengkirai",
                "kodepos": "20856"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Telaga Jernih",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Teluk",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Sungai Ular",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Tanjung Ibus",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Secanggang",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Selotong",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Suka Mulia",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Perkotaan",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Kwala Besar",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Pantai Gading",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Karang Gading",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Kebun Kelapa",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Kepala Sungai",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Jaring Halus",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Karang Anyar",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Hinai Kiri",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Secanggang",
                "kelurahan": "Cinta Raja",
                "kodepos": "20855"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Tamaran",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Suka Damai Timur",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Suka Jadi",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Perkebunan Tanjung Beringin",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Suka Damai",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Kebun Lada",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Muka Paya",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Paya Rengas",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Cempa",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Hinai Kanan",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Baru Pasar VIII (8)",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Hinai",
                "kelurahan": "Batu Melenggang",
                "kodepos": "20854"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Teluk Bakung",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Suka Maju",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Tapak Kuda",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pulau Banyak",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Serapuh Asli",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Serai",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Tengah",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pekubuan",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Cengal",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pematang Cengal Barat",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Paya Perupuk",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pekan Tanjung Pura",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Lalang",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Pantai Cermin",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Kwala Langkat",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Kwala Serapuh",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Baja Kuning",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Bubun",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Tanjungpura",
                "kelurahan": "Karya Maju",
                "kodepos": "20853"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Tebing Tanjung Selamat",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Tanjung Putus",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Suka Ramai",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Sei Serdang",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Serapuh Abc",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Sei Bamban",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Sei Musam",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Paluh Pakih Babussalam",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Padang Tualang",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Namu/Namo Sialang",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Kwala Pesilam (Kuala Besilam)",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Kwala Musam",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Jati Sari",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Karya Jadi",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Bukit Sari",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Buluh Telang",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Besilam",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Batang Serangan",
                "kelurahan": "Batang Serangan",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Padang Tualang",
                "kelurahan": "Banjar Jaya",
                "kodepos": "20852"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Stabat Lama Barat",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Stungkit",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Stabat Lama",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Mekar Jaya",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Paya Tusam",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Pertumbukan",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Jentera Stabat",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Kebun Balok",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Bukit Melintang",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Gergas",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Gohor Lama",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Besilam Bukit Lembasa",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Wampu",
                "kelurahan": "Bingai",
                "kodepos": "20851"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Paya Mabar",
                "kodepos": "20816"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Sido Mulyo",
                "kodepos": "20813"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Perdamaian",
                "kodepos": "20815"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Stabat Baru",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Pantai Gemi",
                "kodepos": "20812"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Simpang Tiga",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Sawit Seberang",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Sei Litur Tasik",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Mekar Sawit",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Sawit Hulu",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Mangga",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Kwala Bingai",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Dendang",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Karang Rejo",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Kwala Begumit",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Ara Condong",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Stabat",
                "kelurahan": "Banyumas",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Alur Gadung",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Sawit Seberang",
                "kelurahan": "Alur Melati",
                "kodepos": "20811"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Timbang Lawan",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Ujung Bandar",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Tanjung Lenggang",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Timbang Jaya",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Sumber Jaya",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Suka Rakyat (Sukarayat)",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Sematar",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Simpang Pulau Rambung",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Sebertung",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Sampe Raya",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Sei Musam Kendit",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Perkebunan Sei Musam",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Perkebunan Turangi",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Perkebunan Pulau Rambung",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Perkebunan Bukit Lawang",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Perkebunan Bungara",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Pekan Bahorok",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Perkebunan Amaltani",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Lau Damak",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Musam Pembangunan",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Batu Jongjong",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Bahorok",
                "kelurahan": "Empus",
                "kodepos": "20774"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Ujung Bandar",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Ujung Terang",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Tanjung Langkat",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Turangi",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Telaga Said",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Sidorejo",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Sulkam",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Serapit",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Sei Bilah Timur",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Puraka II",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Sei Bilah Barat",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Rampah",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Puraka I Pertamina",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Pulau Semikat",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Perkebunan Tanjung Keliling",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Perkebunan Tambunan",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Perkebunan Namotongan",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Perkebunan Marikie",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Perkebunan Gelugur Langkat",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Perkebunan Bandar Telu",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Pancur Ido",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Parangguam",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Panco Warno",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Namoteras",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Naman Jahe",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Pama/Pamah Tambunan",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Mekar Makmur",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Minta Kasih",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Lama Baru",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Lau Gugur",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Lau Tepu",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Lama",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Kutagajah",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Kutambaru",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Harapan Makmur",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Kutambaru",
                "kelurahan": "Kaperas",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Harapan Baru",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Harapan Jaya",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Harapan Maju",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Alur Dua",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sei Lepan",
                "kelurahan": "Alur Dua Baru",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Gunung Tinggi",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Salapian",
                "kelurahan": "Adin Tengah",
                "kodepos": "20773"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Tanjung Keriahan/Keriahen",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Sido Makmur",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Suka Damai",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Suka Pulung",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Sei Penjara",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Raja Tengah",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Pekan Kuala",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Perkebunan Bekiun",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Garunggang",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Namo Mbelin",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Parit Bindu",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Besadi",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Blangkahan/Belangkahan",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Dalan Naman (Dalam Daman)",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Bela Rakyat",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Beruam",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Balai Kasih",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Kuala",
                "kelurahan": "Bekiung",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Sirapit (Serapit)",
                "kelurahan": "Aman Damai/Dame",
                "kodepos": "20772"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Telaga",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Purwobinangun",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Rumah Galuh",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Simpang Kuta Buluh",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Pasar VI Kwala Mencirim",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Pasar VIII Namu Terasi",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Pekan Sawah",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Namu Ukur Selatan",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Namu Ukur Utara",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Pasar IV Namu/Namo Terasi",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Gunung Ambat",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Belinteng",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Kwala Mencirim",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Tanjung Merahe",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Mekar Jaya",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Selayang",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Sei Binge (Bingai)",
                "kelurahan": "Durian Lingga",
                "kodepos": "20771"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Selayang Baru",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Perhiasan",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Sei Limbat",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Padang Brahrang",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Padang Cermin",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Pekan Selesai",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Mancang",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Nambiki",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Kwala/Kuala Air Hitam",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Lau Mulgap",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Bekulap",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Selesai",
                "kelurahan": "Kuta Parit",
                "kodepos": "20762"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Suka Makmur",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Sabi/Sambi Rejo",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Tanjung Jati",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Sido Mulyo",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Kwala Begumit",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Sendang Rejo",
                "kodepos": "20761"
            },
            {
                "kecamatan": "Binjai",
                "kelurahan": "Perdamaina (Perdamean)",
                "kodepos": "20761"
            }
        ],
        "k444": [
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Tamiang Mudo",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Tanjung Alai",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Tolang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Simpang Mandepo",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Simpang Pining",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Simpang Duhu Lombang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Simpang Duku Dolok",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Simpang Banyak Jae",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Simpang Banyak Julu",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Sibinail",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Silogun",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Ranjo Batu",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Patahajang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Pasar Muara Sipongi",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Pakantan Dolok",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Pakantan Lombang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Muara Saladi",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Limau Manis",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Kampung Pinang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Koto Baringin",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Huta Toras",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Huta Rimbaru Up (Hutaimbaru)",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Huta Padang Up",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Huta Lancat",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Huta Padang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Huta Godang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Huta Gambir",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Pakantan",
                "kelurahan": "Huta Julu",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Bandar Panjang Tuo",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Habincaran",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Muara Sipongi",
                "kelurahan": "Bandar Panjang",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Ulu Pungkut",
                "kelurahan": "Alahan Kae",
                "kodepos": "22998"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Ujung Marisi",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Usor Tolang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Tamiang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Tobang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Tambangan Pasoman",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Tambangan Tonga",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Tambangan Jae",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Tambang Bustak",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Singengu Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Singengu Jae",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Simpang Tolang Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Simpang Tolang Jae",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Simangambat TB",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Sibio-Bio",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Simandolan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Sibanggor Tonga",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Sibanggor Jae",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Sibanggor Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Sian Tona",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Saba Dolok",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Sayur Maincat",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Purba Lamo",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Rao Rao Dolok",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Rao Rao Lombang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Purba Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Patialo",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Purba Baru",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Pastap Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Pasar Maga",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Pastap",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Panjaringan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Pasar Kotanopan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Pasar Laru",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Padang Sanggar",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Pagar Gunung",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Pangkat",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Muara Siambak",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Padang Bulan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Muara Pungkut",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Muara Saladi",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Muara Mais Jambur",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Muara Potan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Muara Mais",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Manambin",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Muara Botung",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Maga Dolok",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Maga Lombang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Laru Lombang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Lumban Pasir",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Laru Baringin",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Laru Bolak",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Laru Dolok",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Hutatinggi",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Hutanamale",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Huta Tonga AB",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Hutabaringin Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Pungkut Tonga",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Rimbaru Sm",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Pungkut Jae",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Pungkut Julu",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Padang Sm",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Puli",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Baringin Tb",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Huta Dangka",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Huta Lombang",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Husor Tolang (Unsor Tolan)",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Huta Barat",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Gunung Tua Ms",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Gunung Tua Sm",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Puncak Sorik Marapi/Merapi",
                "kelurahan": "Handel",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Botung",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Gading Bain",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Bangun Purba",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Kotanopan",
                "kelurahan": "Batahan",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Lembah Sorik Merapi",
                "kelurahan": "Aek Marian Mg",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Tambangan",
                "kelurahan": "Angin Barat",
                "kodepos": "22994"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Tabuyung",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Tagilang Julu",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Sikapas",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Singkuang I (Pasar I Singkuang)",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Pasar II Singkuang",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Rantau Panjang",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Sale Baru",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Manuncang",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Panunggulan",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Lubuk Kapundung",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Lubuk Kapundung II",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Batu Mundam",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Muara Batang Gadis",
                "kelurahan": "Huta Imbaru",
                "kodepos": "22989"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Sinunukan III",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Sinunukan IV",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Sinunukan VI",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Sinunukan I",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Sinunukan II",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Pasar Batahan",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Pulau Tamang",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Sari Kenanga Batahan",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Kubangan Tompek",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Muara Pertemuan",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Pasar Baru Batahan",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Kuala Batahan",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Kampung Kapas II",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Kampung Kapas",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Kampung Kapas I",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Bintungan Bejangkar",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Bintungan Bejangkar Baru",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Batu Sondat",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Batahan III",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Batu Sondat",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Banjar Aur Utara",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Batahan I",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Batahan II",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Sinunukan",
                "kelurahan": "Airapa",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Batahan",
                "kelurahan": "Banjar Aur",
                "kodepos": "22988"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Tegal Sari",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Tunas Karya",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sinunukan V",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sundutan Tigo",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Taluk",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sikara Kara III",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sikara Kara IV",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sikara Kara",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sikara Kara I",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Sikara Kara II",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Rukun Jaya",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Setia Karya",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Patiluban Mudik",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Perkebunan Patiluban",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pasar VI Natal",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Patiluban Hilir",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pasar III Natal",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pasar IV Natal",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pasar II Natal",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pardamean Baru",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Pasar I Natal",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Panggarutan",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Bonda Kase",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Buburan",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Kampung Sawah",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Balimbing",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Natal",
                "kelurahan": "Bintuas",
                "kodepos": "22987"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Tombang Taluang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Ulu Torusan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Tarlola",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Tangsi Atas",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Tapus",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Sopo Tinjak",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Tandikek",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Simpang Koje",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Sipogu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Simpang Talap",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Simpang Gambir",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Simpang Durian",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Simpang Bajole",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Simpang Duku",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Simanguntong",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Simaninggir",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Rao Rao",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Sampuran",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Sikumbu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Rantobi",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Ranto Nalinjang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Ranto Panjang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Perbatasan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Perk. Simpang Gambir",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Pangkalan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Padang Silojongan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Muara Lampungan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Muara Soma",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Muara Bangko",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Manisak",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Lubuk Samba",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Lubuk Kancah",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Lobung",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Kampung Baru",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Huta Nauli",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Huta Raja",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Huta Lobu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Huta Baringin",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Hatupangan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Hadangkahan",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Guo Batu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Gonting",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Dalan Lidang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Bulu Soma",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Batu Madinding",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Bonca Bayuon",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Batu Gajah",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Banjar Malayu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Banjar Maga",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Ranto Baek/Baik",
                "kelurahan": "Bangun Saroha",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Bangkelang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Ampung Siala",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Bandar Limabun",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Ampung Padang",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Aek Nangali",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Ampung Julu",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Aek Nabara",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Aek Manyuruk",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Aek Manggis",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Aek Holbung",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Batang Natal",
                "kelurahan": "Aek Guo",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Langga Bayu (Lingga Bayu)",
                "kelurahan": "Aek Garinggin",
                "kodepos": "22983"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Tanjung Mompang",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Tor Banua Raja",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Sukaramai",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Sopo Sorik",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Simanondong",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Sayur Maincat",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Rumbio",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Mondan",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Pasar Huta Bargot",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Mompang Jae",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Mompang Julu",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Hutarimbaru",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Jambur Padang Matinggi",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Kampung Baru",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Hutabargot Simalang",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Hutanaingkan",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Huta Bargot Setia",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Huta Dame",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Huta Bargot Nauli",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Huta Bargot Lombang",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Binanga",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Huta Bargot Dolok",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Panyabungan Utara",
                "kelurahan": "Beringin/Baringin Jaya",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Naga Juang",
                "kelurahan": "Tambiski",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Huta Bargot",
                "kelurahan": "Bangun Sejati",
                "kodepos": "22978"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Sidojadi",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Naga Juang",
                "kelurahan": "Sayur Matua",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Malintang Julu",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Pasar Baru Malintang",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Lambou Darul Ihsan",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Malintang Jae",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Janji Matogu",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Huta Bangun",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Huta Bangun Jae",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Naga Juang",
                "kelurahan": "Banua Simanosor",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Naga Juang",
                "kelurahan": "Humbang I",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Naga Juang",
                "kelurahan": "Banua Rakyat",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Bange",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Bukit Malintang",
                "kelurahan": "Bange Nauli",
                "kodepos": "22977"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Tangga Bosi II",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Tangga Bosi III",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Tanjung Sialang",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Tangga Bosi",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Simangambat",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Simaninggir",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Sinonoan",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Sihepeng",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Siabu",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Sibaruang",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Muara Batang Angkola",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Pintu Padang Jae",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Pintu Padang Julu",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Lumban Pinasa",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huta Raja",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Lumban Dolok",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huta Godang Muda",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huta Puli",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huta Baringin",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huraba",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Huraba II",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Aek Mual",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Siabu",
                "kelurahan": "Aek Garut",
                "kodepos": "22976"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Tano Bato",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Roburan Lombang",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Pagaran Gala-Gala",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Roburan Dolok",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Kayu Laut",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Lumban Dolok",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Hutarimbaru",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Hutaraja Hutajulu",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Hayuraja",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Kota Siantar",
                "kodepos": "22919"
            },
            {
                "kecamatan": "Panyabungan Selatan",
                "kelurahan": "Aek Ngali",
                "kodepos": "22952"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Ipar Bondar",
                "kodepos": "22918"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Kayu Jati",
                "kodepos": "22919"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Tua Labuan Pasir",
                "kodepos": "22918"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Tua Tonga",
                "kodepos": "22918"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Tua Jae",
                "kodepos": "22918"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Tua Julu",
                "kodepos": "22918"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Pasar Hilir",
                "kodepos": "22916"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan Jae",
                "kodepos": "22917"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan Tonga",
                "kodepos": "22916"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Pidoli Lombang",
                "kodepos": "22915"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan Julu",
                "kodepos": "22916"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Pidoli Dolok",
                "kodepos": "22915"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan II",
                "kodepos": "22913"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Tanjung Julu",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Sopo Batu",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Tanjung",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Siobon",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Sipapaga",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Sirangkap",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Sarak Matua",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Sigalapang Julu",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Ranto Natas",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Salambue",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Pardomuan",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Parmompang",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Parbangunan / Perbangunan",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Pagur",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan I",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Padang Laru",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Pagaran Tonga",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Manyabar",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Kampung Padang",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Lumban Pasir",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Huta Rimbaru Gb",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Huta Lombang Lubis",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Huta Tinggi",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Huta Bangun",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Manaon",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Gunung Barani",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Gunung Baringin",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Banjar Lancat",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Darussalam",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Aek Banir",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Timur",
                "kelurahan": "Aek Nabara",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Aek Mata",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Sirambas",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Adian Jior",
                "kodepos": "22912"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Saba Jior",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Ruding",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Kota",
                "kelurahan": "Panyabungan III",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Longat",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Huta Baringin",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Huta Tonga BB",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Barbaran Jae",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Batang Gadis",
                "kodepos": "22911"
            },
            {
                "kecamatan": "Panyabungan Barat",
                "kelurahan": "Barbaran",
                "kodepos": "22911"
            }
        ],
        "k445": [
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Ziabiang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Teluk Limo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Wawa",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Tuwaso",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Tano Mokinu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Tebolo Melayu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Siofa Ewali",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Sisarahili",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Siofa Banua Lorang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Sigese",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Siofa Banua Marit",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Silimaewali",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Sinauru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Sifitu Ewali",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Silina",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Sifauruasi",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Silina Baru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Sibaranun",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Simaluaya",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Siduaewali",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Sepakat",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Sialema",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Sebuasi",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Saeru Melayu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Orahili",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Pasar Pulau Telo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Rapa Rapa Melayu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Onaya",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Omega",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Memong",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Majino Lorang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Merit Baru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Lumbui Nias",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Makole",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Luaha Idanopono",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Maufa",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Lumbui Melayu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Majino Lorang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Loboi",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Limo Biang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Lasonde",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Labuan Rima Baru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Lambak",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Labuan Rima",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Labuan Bajau",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilioro Dua Tebolo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Labuan Hiu / Hiyu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Hiligeho Sogawu",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Koto",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilikana",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Labara",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Hili Amo Dula",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Hilizamorogotano",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilianombasela",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Jeke",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Hayo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Hiliotalua",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Gondia",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilioro Mao",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Hale Baluta",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilinifaese",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Gobo Baru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Hilioro Dua Tebolo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Bawo Omasio",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Simuk",
                "kelurahan": "Gobo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Bawo Ofuloa",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Fuge",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Bawo Analita Saeru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Eho Baluta",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Baruyu Lasara",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Duru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Bawo Ama Helato",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Eho",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Baruyu Sibohou",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Bintuang",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Balogia",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Hibala",
                "kelurahan": "Bawonifaoso",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Baluta",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Bawositora",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Bale-Bale",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Barat",
                "kelurahan": "Bawolawindra",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Bale-Bale Sibohou",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu",
                "kelurahan": "Bawodobara",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Bais",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Tanah Masa",
                "kelurahan": "Bawo Orudua",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Bais Baru",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Utara",
                "kelurahan": "Afore Gobo",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Pulau-Pulau Batu Timur",
                "kelurahan": "Adam",
                "kodepos": "22881"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Uluidanoduo",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Umbu Sohahua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Tetezou (Fetezou)",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Taluzusua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Siwalubanua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Somambawa",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Sobawagoli",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Sinar Baho",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Sinar Baru",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Sinar Susua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Sitolu Banua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Silimabanua Somambawa",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Sihareo",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Sifitubanua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Orahili Balaekha",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Sarahililaza",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Olanori",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Oladano",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Mondrowe",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Na&#8217;ai",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Oikhoda Balaekha",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Mehaga",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Mogae",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Lahusa",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Lahusa Satu",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Hoya",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hilizomboi",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hiliwatema",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Hilizanuwo",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hiliorudua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hilisimaetano",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Hilisao&#8217;oto",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Hiliorahua Tasua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hilinawalo Balaekha",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Hilidohona",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Sidua'ori",
                "kelurahan": "Hililaora",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hiligambukha",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Harenoro",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Hiliabolata",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Hilialawa",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Golambanua I",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Golambanua II",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Fanedanu",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Somambawa",
                "kelurahan": "Gabungan Tasua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Bawolato",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Bawootalua",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Bawozihono",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Lahusa",
                "kelurahan": "Angorudua Balaekha",
                "kodepos": "22874"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Umbunasi",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Umbu Orahua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Ulu Mazo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Umbu Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Tuhegafoa",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Tobualo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Tetegawa&#8217;ai Ehomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Tetegawa&#8217;ai Ehomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Tanoniko&#8217;o",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Tafulu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Sisiwa Ewali",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Sisobahili",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Suka Maju Mohili",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Sirahia",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Sisarahili Ewo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Siraha (Sirahia)",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Sindrolo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Siofabanua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Silima Banua Umbunasi",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Sinar Helaowo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Sifaoroasi Mola",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Siholi",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Sifaoroasi Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Sifalago Susua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Perjuangan",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Sifalago Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Orlin",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Orlin",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Orahuahili",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Orahua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Orahua Uluzoi",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Orahili Susua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Orahili Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Orahili Sibohou",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Orahili Mola",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Orahili Boe",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Luahandroito",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Lolosoni",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Lolozukhu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Lewa-Lewa",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Lawa-Lawaluo Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Lawindra",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Lahusa Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Lawa-Lawaluo Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hiliwaebu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilizamurugo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilitobara Susua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Hiliuso",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hiliserangkai",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilisibohou",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hilisalo&#8217;o",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilioru dua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hilimbowo Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hiliorahua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilimboe",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilimboho",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Hilimaufa",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Hilimbaruzo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hiligabungan",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilidanayao",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Hilibadalu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hilianaa Susua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hilialo&#8217;oa",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Hilianaa Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Hiliadulosoi",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Gununggabungan",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Harefa Orahua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Mazo",
                "kelurahan": "Guigui",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Fonedanu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Foikhugaga",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Fanedanu Sibohou",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Doli-Doli Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Dao-dao Zanuwo Idano Tae",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Dao-dao Zanuwo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Damai",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Buhawa",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Bohalu",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Susua",
                "kelurahan": "Bintang Baru",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Balombaruzo Orahua",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Balohili Mola",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Boronadu",
                "kelurahan": "Balohili Gomo",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Gomo",
                "kelurahan": "Awoni",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Umbunasi",
                "kelurahan": "Ambukha",
                "kodepos": "22873"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Tundrombaho",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Tesikhori",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Tuhemberua",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Tumari",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Tarewe",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Sisarahili Huruna",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Suka Maju",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Sifaoro&#8217;asi",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Sifaoroasi Huruna",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Sifalago",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Sambulu",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Orudua Lawa-Lawa Lou",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Puncak Lolomatua",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Mombawa Oladano",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Olayama",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Orahili Ulunoyo",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Luahamofakhe",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Marao",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Lawa-Lawa Luo",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Loloana&#8217;a",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Lalimanawa",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Koendrafo",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Hilizoliga",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Ko&#8217;olotano",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Hiliwaebu",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Hilimanawa",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Hilisangowola",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Hiliuso",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Hilifaondrato",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Hilimaera",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Hilifalawu",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Hilifakhe",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Fadoro Tuhemberua",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Hili Otalua",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Ehosakhozi",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Ewo",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Botohili Ndruria",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lolomatua",
                "kelurahan": "Caritas Sogawunasi",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Borowosi",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Huruna",
                "kelurahan": "Bawahosi Huruna",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Bawo Lolomatua",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Ambukha Satu",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Ulunoyo",
                "kelurahan": "Amorosa",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Tuindrao Satu",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Susua",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Tuhemberua Amandraya",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Tuindrao",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Sisobahili",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Sisobambowo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Sirofi",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Sisarahili Susua",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Sisarahili",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Sinar Ino&#8217;o",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Sikhorilafau",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Sifaoro&#8217;asi",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Ramba-Ramba",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Sifaoroasi",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Orahili Fondrako",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Orudu Sibohou",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Lolozaria",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Orahili Eho",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Lolomoyo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hume",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Loloabolo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Lahusa Susua",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Hiliwosi",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilisawato",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilitatao",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilisalo&#8217;o",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Hilinifaoso",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilioru dua",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilindraso",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilimezaya",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilimbowo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilimbulawa",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilimbowo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilimagiao",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilimaera",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hiligumbu",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilihoru",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hilifadolo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hiligafoa",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Hilifadolo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hiliadolowa",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Hiliamozula / Hiliamauzula",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Foikhu Fondrako",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Fondrakoraya",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Dao-dao",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Boholu",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Bagoa",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Baohao",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Aramo",
                "kelurahan": "Aramo",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Ulususua",
                "kelurahan": "Amandraya Ulususua",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Amandraya",
                "kelurahan": "Amandraya",
                "kodepos": "22866"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Siwalawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Sondregeasi",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Soto&#8217;o Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Samadaya Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Siliwulawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Pasar Teluk Dalam",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Pekan Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Orahili Fau",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Ono Hondro",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Orahili Faomasi",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Nanowa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Ndraso Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Lawindra",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Lolomboli",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Lagundri",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Lahusa Fau",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hinawalo Fau",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Idala Jaya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hilizihono",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilizoroilawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilizalo&#8217;otano Larono",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilizalo&#8217;otano Laowo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilizalo&#8217;otano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilitobara",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisoromi",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilisondrekha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisataro Raya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisataro Gewa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisataro Nandisa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisataro Eho Sofayo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilisataro",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilisaootoniha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilisanekhehosi",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hilisalawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilionaha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hiliofonaluo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilindraso Raya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilindrasoniha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilinamozaua",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilinamozaua Raya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilinawalo Mazino / Mazingo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilinamoniha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilimondregeraya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilimagari",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Hilimae Namolo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hililaza Hilinawalo Mazino",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilikara",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hililaza",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hilikara Maha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hiligito",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hiliganowo Salo&#8217;o",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hiligeho",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Hilifondege Hilizoroilawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilifarono",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hiliganowo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hilifarokha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Hilifalawu",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilifalago",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Hiliaurifa Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilifalago Raya",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hiliamuri",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hiliasi",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hiliana&#8217;a",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Hiliamaetaniha",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hiliamaetaluo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Hilialawa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Hilialito Saua",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Ete Batu",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Ganowo Saua",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Faomasi Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Botohilitano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Eho Orahili",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Eho Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Botohili Sorake",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Botohilisalo&#8217;o",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Botohili Silambo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Bawosaodano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Bonia Hilisimaetano",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Bawomataluo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Bawonahono",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Bawomaenamolo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Bawolahusa",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Bawohosi Maniamolo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Mazino",
                "kelurahan": "Bawolahusa Doli-doli",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Maniamolo",
                "kelurahan": "Bawogosali",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Toma",
                "kelurahan": "Bawoganowo",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Fanayama",
                "kelurahan": "Bawofanayama",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Bawo Nifaoso",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Bawo Zaua",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Bawo Dobara",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Bawo Lowalani",
                "kodepos": "22865"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Tuho Owo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Umbu&#8217;asi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Umbuasi Barat",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Togizita",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Togozita Satu",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Tetehosi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Talio",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Soledua Satu",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Soroma&#8217;asi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Suka Maju",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Soledua Dua",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Soledua",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Sisobahili Huruna",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Sisobahili Siwalawa",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Sisarahili Ekholo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Sisarahili Oyo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Samiri",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Simandraolo / Simandaolo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Simandraolo O&#8217;ou",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Onohazumba",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Orahili Huruna",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Manawadano",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Nituwu Boho",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Lolowau",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Maluo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Lolohowa",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Lolomoyo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Lolomaya",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Lolofaoso",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Hiliweto",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "La&#8217;uso",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Hilioru dua",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Hilitoese",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Hilinamozihono",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Hilinamozihono Moale",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Hilimbowo Siwalawa",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Hilimbuasi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Hiligodu",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Hilikara",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Hilimbowo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Hilidulo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Hili Fadolo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Hiliadulo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Helefanikha",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Dao-dao Sowo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Onohazumba",
                "kelurahan": "Fadoro&#8217;ewo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Bukitburasi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Botohili",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Berua Siwalawa",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Bawosalo&#8217;o Bawoluo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Bawohosi",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Bawosaloo Siwalawa / Sowalawa",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilimegai",
                "kelurahan": "Bawasalo&#8217;o Dao-dao",
                "kodepos": "22864"
            },
            {
                "kecamatan": "O'o'u (Oou)",
                "kelurahan": "Balohili O&#8217;o&#8217;u",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Hilisalawa'ahe (Hilisalawaahe)",
                "kelurahan": "Anaoma",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lolowau",
                "kelurahan": "Amuri",
                "kodepos": "22864"
            }
        ],
        "k446": [
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Sitolubanua",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Sohoya",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Tagaule",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Siofaewali",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Sisarahili Bawolato",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Siofabanua",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Sifaoroasi Uluhou",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Sindrondro",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Orahua Faondrato",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Si&#8217;ofaewali Selatan",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Lagasimahe",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Orahili",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Orahua",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hilihao Cugala",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hiliwarokha",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hou",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hilialawa",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hilifaosi",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hiliganoita",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Banua Sibohou Silima Ewali",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Botohaenga",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Gazamanu",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Balale Toba&#8217;a",
                "kodepos": "22876"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Tuhewaebu",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Tiga Serangkai Maliwa&#8217;a",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Tetehosi",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Tete Goenaai",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Sisobahili Iraonohura",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Saiwahili Hiliadulo",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Sandruta",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Otalua",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Orahili Zuzundrao",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Oladano",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Maliwaa",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Mondrali",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Laowo Hilimbaruzo",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hilionozega",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Laira",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hilinaa Tafuo",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hilimoasio Dua",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hilimoasio",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hililawae",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hiligogowaya Maliwa&#8217;a",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Hili&#8217;adulo",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Bozihona",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Bobozioli Loloanaa",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Biouti Timur",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Biouti",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Baruzo",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Awoni Lauso",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Idano Gawo",
                "kelurahan": "Ahedano",
                "kodepos": "22872"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Wea-Wea",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Umbu",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Tulumbaho",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Tulumbaho Salo&#8217;o",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Tuhembuasi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Somolo-Molo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Somi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Somi Botogo&#8217;o",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Soewe (Saewe)",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "So&#8217;ewali",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Sogaeadu",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Sisobahili",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Sisobawino I",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Sisaratandrawa",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Sisarahili Sogeadu",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Sirete",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Sihareo Sogaeadu",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Sifaoroasi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Saitagaramba (Saetagaramba)",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Olindrawa Sisarahili",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Lolozasai",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Nifolo&#8217;o Lauru",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Loloanaa Gido",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Lewuombanua",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Lewuoguru I",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Lauri",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Lasara Idanoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Lasela",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Ladea",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Ladea Orahua",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Lahemo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Iodano",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Huno (Hino)",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Hilizoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Hiliotalua",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Hilisebua",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Hiliweto Gido",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Hilimbana",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Hiliborodano",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Somolo-Molo (Samolo)",
                "kelurahan": "Hiligodu Somolo-Molo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Hilibadalu",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Sogae Adu (Sogaeadu)",
                "kelurahan": "Baruzo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gido",
                "kelurahan": "Akhelaume",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Sisobahili Ulugawo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Sifaoroasi Ulugawo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Sisarahili Soroma&#8217;asi",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Orahili Somolomolo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Onodalinga",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Mohili",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Lawa-Lawaluo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Holi",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Hilimbowo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Hiliweto Gela (Somolomolo)",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Hilibadalu",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Hiligafoa",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Fatodano",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Ulugawo",
                "kelurahan": "Fahandrona",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Tuhegafoa II",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Sisobalauru",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Tanoseo",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Sinarikhi",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Sisobahili I Tanoseo",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Onozitolidulu",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Silimabanua",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Ombolata Sisarahili",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Ononamolo I Bot",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Onowaembo Hiligara",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Lasara Tanose&#8217;o",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Ombolalasalo&#8217;o",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Hiliduho",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Fadoro Lauru",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Dima",
                "kodepos": "22854"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Tuhemberua",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Sisarahili Ma U",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Sihare&#8217;o III Bawosalo&#8217;o Berua",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Sihare&#8217;o III Hilibadalu",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Sihaero III",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Lewa &#8211; Lewa",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Lewu Oguru II (Lewuaguru II)",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Lasara Siwalu Banua",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Dekha",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Balodano",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Ma'u",
                "kelurahan": "Atualuo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Orahili Idanoi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Onombongi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lolowua",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lolowua Hiliwarasi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lolofaoso Lalai",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lolofaoso",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lawa-Lawa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Lalai I/II",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Hilizia Lauru",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Hilihoru",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hiliduho",
                "kelurahan": "Hiligodu Tanoseo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Fulolo Lalai",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Fadoro Lalai",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Fadoro Hunogoa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Ehosakhozi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Dahadano Botombawo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Bawolato",
                "kelurahan": "Dahana Bawolato",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Hili Serangkai (Hilisaranggu)",
                "kelurahan": "Awela",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Tuhegafoa I",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Talafu",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Tetehosi Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Simanaere Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Sisobahili Dola",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Ola Nori",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Ononamolo Talafu",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Lololana&#8217;a",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Mohili Berua Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Hiliwaele II",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Lazara Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Hiliwaele I",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Hiliambowo Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Hiligodu Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Banua Sibohou Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Hilimbowo Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Balohili Botomuzoi",
                "kodepos": "22815"
            },
            {
                "kecamatan": "Botomuzoi",
                "kelurahan": "Fulolo Botomuzoi",
                "kodepos": "22815"
            }
        ],
        "k447": [
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Sisobawino II",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Wango",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Hiliuso",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Lolofitu",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Hilimbuasi",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Duria",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Hilimbowo Mau",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Lolofitu Moi",
                "kelurahan": "Ambukha",
                "kodepos": "22875"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Hayo",
                "kodepos": "22867"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Tiga Serangkai",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Sitolu Banua",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Sisobambowo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Sisobaoho",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Ono Waembo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Ono Limbu",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Lolowau",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Lologundre",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Iraonogaila",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Hiliadulo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Lahomi (Gahori)",
                "kelurahan": "Bawozamaiwo",
                "kodepos": "22864"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Tuwa Tuwa",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Tugala",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Tugalagawu",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Tetehosi",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Togideu",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Togim Bogi",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Sinene Eto",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Sirombu",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Sisobandrao",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Orahili",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Pulau Bogi",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Lahawa",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Lahusa",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Ombolata",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Imana",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Kafo Kafo",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Hilimberua",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Hinako",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Gunung Cahaya",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Halamona",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Hanofa",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Bawosaloo",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Fadoro",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Bawasawa",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "ZuzuNdrao",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Sirombu",
                "kelurahan": "Balowondrate",
                "kodepos": "22863"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Tumori",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Tuwuuna",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Tuhemberua",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Tuho&#8217;owo",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Tetehosi",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Sitolu Ewali",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Sisarahili I",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Sitolu Banua Fadoro",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Simae&#8217;asi",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Sidua Hili",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Siana&#8217;a",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Onozalukhu You",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Ulu Moro'o (Ulu Narwo)",
                "kelurahan": "Saloo",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Lolozirugi",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Lologolu",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Ulu Moro'o (Ulu Narwo)",
                "kelurahan": "Lawelu",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Lasara Baene",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Lasara Bahili",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Lakhena",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Iriani Gambo",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Iraono Gambo",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Hiliwaloo II",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Hiliwalo&#8217;o I",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Hilifadolo",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Ulu Moro'o (Ulu Narwo)",
                "kelurahan": "Hilisangawola",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Ulu Moro'o (Ulu Narwo)",
                "kelurahan": "Hilibadalu",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Hili Waele",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Gunung Baru",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Moro'o",
                "kelurahan": "Hili Soromi",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Fadoro Bahili",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Fadoro",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe",
                "kelurahan": "Doli-Doli",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Ulu Moro'o (Ulu Narwo)",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "22862"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Taraha",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Tarahoso",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Sihareo",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Ononamolo I",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Ononamolo II",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Lolomboli",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Lahagu",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Hilimbowo",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Hiambanua",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Hili Mayo",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Balodano",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Utara",
                "kelurahan": "Hilimbaruzo",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Sisarahili II",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Sisobandrao",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Sisobaoho",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Ononamolo III",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Orahili Badalu",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Onolimbuyou",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Lolohia",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Mazingo",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Onolimburaya",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Lasara Bagawu",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Lasara Faga",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Iraonogeba",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Fadoro Sifulu Banua",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Mandrehe Barat",
                "kelurahan": "Hilidaura",
                "kodepos": "22812"
            }
        ],
        "k448": [
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Tuhegeo I",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Tuhegeo II",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Tetehosi II",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Tetehosi I",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Siwalubanua I",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Siwalubanua II",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Simanaere",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Samasi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Sifalaete",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Onowaembo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Ombolata",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Loloanaa Idanoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Lewuoguru Idanoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Humene",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Idanotae",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Hiliweto Idanoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Hilimbowo Idanoi",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Hilimbawo Desolo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Hilihambawa",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Helefanikha",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Fadoro",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Fowa",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Binaka",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Dahana",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Bawodesolo",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Idanoi",
                "kelurahan": "Awaai",
                "kodepos": "22871"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Tetehosi Ombolata",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Tetehosi Afia",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Teluk Belukar",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Sisobahili II Tanoseo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Tarakhaini",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Sihareo I Tabaloho",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Orahili Tanose&#8217;o",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Onozitoli Tabaloho",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Ononamolo I Lot",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Ombolata Simenari (Simanari)",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Olora",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Nazalou Lolowua",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Niko&#8217;otano Dao",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Nazalou Alo&#8217;oa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Mazingo Tabaloho",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Luaha Laraga",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Lolomboli",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Lololawa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Lolofaoso Tabaloho",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Lololakha",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Loloanaa / Lolomoyo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Lasara Sowu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Irano Lase (Iraono Lase)",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Hilimbowo Olora",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Hiligodu Ulu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Hiligodu Ombolata",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Hiligara",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Gawu Gawu Bouso/Bauso",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Hambawa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Selatan",
                "kelurahan": "Faekhu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Fadoro You",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Alo'oa",
                "kelurahan": "Fadoro Hilimbowo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli Utara",
                "kelurahan": "Afia",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Mudik",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Saombo",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Iraonogeba",
                "kodepos": "22812"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Tuhemberua Ulu",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Tumori",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Tumori Balohili",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sisarahili Sisambalahe",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sisobahili Tabaloho",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sisarahili Gamo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Sihareo Siwahili",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Simandraolo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sifalaete Ulu",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sihareo II Tabaloho",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Pasar Gunung Sitoli",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Saewe",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Sifalaete Tabaloho",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Onozitoli Olora",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Onozitoli Sifaoroasi (Siforoasi)",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Orahili Tumori",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Ononamolo II",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Onozikho",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Ombolata Ulu",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Ono Waembo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Madula",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Miga",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Moawo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Lolomoyo/Lelewonu Nikootano",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Madolaoli",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Lolomoyo Tuhemberua",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Ilir",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Lasara Bahili",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Hilimbaruzo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Hilina A",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Hilinakhe",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli Barat",
                "kelurahan": "Gada",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Hili Hao",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Dahana Tabaloho",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Fadoro Lasara",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Boyo",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Dahadano Gawu Gawu",
                "kodepos": "22811"
            },
            {
                "kecamatan": "Gunungsitoli",
                "kelurahan": "Bawodesolo (Bawadesolo)",
                "kodepos": "22811"
            }
        ],
        "k449": [
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Siwawo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Te&#8217;olo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Siwabanua",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Ononazara",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Ononamolo Tumula",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Ononamolo Alasa",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Ombolata",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Loloana&#8217;a",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Lahemboho",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Humene Siheneasi",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Hiligawoni",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Gunung Tua",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Harefa",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Fulolo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Dahana Tugala Oyo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Fabaliwa Oyo",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Dahana Alasa",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Tugala Oyo",
                "kelurahan": "Botona&#8217;ai",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Banua Sibohou II",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Bitaya",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Banua Sibohou I",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Afulu",
                "kodepos": "22857"
            },
            {
                "kecamatan": "Alasa",
                "kelurahan": "Anaoma",
                "kodepos": "22861"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Sitolu Banua",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Siheneasi",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Ombolata",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Sifaoroasi",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Onozalukhu",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Marafala",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Moawo",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Iraono Lase",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Lahewa (Pasar Lahewa)",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Lasara",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Hilizukhu",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Holi",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Hili Goduhoya",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Hili Hati",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Hilina A",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Fadoro Sitolu Hili",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Hili Gawolo",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Fadoro Hilihambawa",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Fadoro Hilimbowo",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Afia",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Umbubalodano",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Lahewa",
                "kelurahan": "Balefadoro Tuho",
                "kodepos": "22853"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Tetehosi Maziaya",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Sisarahili Teluk Siabang",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Teluk Bengkuang",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Silima Banua",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Siofa Banua",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Sifahandro",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Sawo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Seriwau",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Onozitoli Sawo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Sanawuyu",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Ombolata Sawo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Ladara",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Lasara Sawo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Laaya",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Hilisaloo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Hilimbosi",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sawo",
                "kelurahan": "Hiliduruwa",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Fino",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Fulolo Saloo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Botolakha",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Sitolu Ori",
                "kelurahan": "Batom Bawo",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Banuagea",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Tugala Lauru",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Tuhemberua",
                "kelurahan": "Alooa",
                "kodepos": "22852"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Tetehosi Sorowi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Tefao",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Sisobahili",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Sifaoroasi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Ombolata Afulu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Meafu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Muzoi",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Maziaya",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Lukhu Lase",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lombuzaua",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lolomboli",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lolofaoso",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lawira II",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lawira Satua",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Lawira I",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Lauru Lahewa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Lauru Fadoro",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Lauru I Afulu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lahewa Timur",
                "kelurahan": "Laowowaga",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Hiligeo Afia",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Hiligodu",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Hilidundra",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Harewakhe",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Faekhunaa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Afulu",
                "kelurahan": "Faekhunaa",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Fadoro Fulolo",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Baho",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Lotu",
                "kelurahan": "Dahadano",
                "kodepos": "22851"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Tuhenakhe I",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Sisobahili",
                "kodepos": "22817"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Orahili",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Sisarahili",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Namohalu",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Hilibanua",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Lasara",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Esiwa",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Berua",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Dahana Hiligodu",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Namohalu Esiwa",
                "kelurahan": "Banua Sibohou",
                "kodepos": "22816"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Mazingo",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Hilina A",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Laehuwa",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Hilimbowo Kare",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Banua Sibohou III",
                "kodepos": "22814"
            },
            {
                "kecamatan": "Alasa Talumuzoi",
                "kelurahan": "Harefanaese",
                "kodepos": "22814"
            }
        ],
        "k450": [
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Padang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Batu IV",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Batu V",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Batu II",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Batu III",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ujung Batu I",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Tanjung Sari (Pirtrans Sosa Ib)",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Tanjung Ale",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Tanjung Baringin",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sosa Mulia",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Suka Dame (Pirtrans Sosa III B)",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sungai Korang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Simangambat",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sigala Gala",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sigalapung",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sido Mulyo (Pirtrans Sosa III A)",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Siabu",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Sibodak Sosa Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Pirtrans Sosa II",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Pasar Panyabungan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Payombur (Payaombor)",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Panyabungan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Parmainan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Mulya Sari",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Margo Mulia (Pirtrans Sosa IV)",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Pagaran Dolok Sosa Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Mananti Sosa Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Lubuk Bunut",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Huta Raja Tinggi",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Gunung Mulia",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Huta Raja Tinggi",
                "kelurahan": "Ali Aga",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Ujung Batu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Tanjung Botung Sosa Jae",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Tanjung Bale",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Tanjung",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Sungai Jior",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Simarancar",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Sisoma",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Siginduang",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Siborna Bunut",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Rao Rao Dolok",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Roburan",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Ramba",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Plasma Mondang (Trans Sosa IV)",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Pasir Julu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Pasir Jae",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Pasar Ujung Batu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Parau Sorat",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Mondang",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Parapat",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Mandian",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Mananti Sosa Julu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Janji Raja",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Lumban Huayan",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Hurung Jilok",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Huta Imbaru",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Huta Raja Lama",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Harang Julu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Horuan",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Hapung Torop",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Harang Jae",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Handio",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Hapung",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Gunung Baringin",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Gunung Tua",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Ampolu",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Batu Gajah",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Aek Tinga",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Sosa",
                "kelurahan": "Aer Bale",
                "kodepos": "22765"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Tano Bato",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Tapian Nauli",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Tanjung Botung",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Tanjung Durian",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Tanjung Purba Baru (Purbatua)",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Tanjung",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Tanjung Baringin Sim",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Surodingin",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Tangga Bosi",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sitarolo Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Siraisan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Siolip",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Simanuldang Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Simanuldang Jae",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Simaninggir",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Sihiuk",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sigorbus Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sigorbus Jae",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Sidomulio",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Sibulus Salam",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sibuhuan Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Sibulus Salam",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Sibual Buali",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sayur Matua",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Sayur Mahincat/Maincat",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Siali Ali",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Salambue (Sialam Bue)",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Sangkilon",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Saba Rimba/Riba",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Sabahotang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Pintu Padang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Purbatua",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Pasar Ipuh",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pasar Latong",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Pasar Sibuhuan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Paringgonan Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Parsombaan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Paringgonan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Paran Batu (Pagaran)",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Pancaukan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pagaran Silindung",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Pagur Satio",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Panarian",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pagaran Mompang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pagaran Malaka",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pagaran Jae Batu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Pagaran Jalu Jalu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Mompang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Pagaran Baringin",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Matondang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Limbong",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Janji Matogu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Janji Lobi",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Janji Lobi Lima",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Hutarimbaru",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Huta Nopan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Huta Ibus",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Huta Lombang",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Huta Dolok (Latong)",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Hasahatan Jae",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Hasahatan Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Handang Kopo",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Handis Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Gunung Intan",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Gunung Manobot",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Gunung Barani",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Bulu Sonik",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Bonal",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Binabo Jae",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Binabo Julu",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Batang Bulu Lama",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Batang Bulu Tanggal",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Batang Tanggal Baru",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Batang Bulu Baru",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Batang Bulu Jae",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Arse Simatorkis",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun",
                "kelurahan": "Bangun Raya",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Barumun Selatan",
                "kelurahan": "Banua Tonga",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Ulu Barumun",
                "kelurahan": "Aekharuaya",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Ulu Aer",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Lubuk Barumun",
                "kelurahan": "Aek Lancat",
                "kodepos": "22763"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sosopan Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Siundol Jae",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Siundol Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sosopan",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Simartolu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Siundol Dolok",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Simaninggir Sosopan",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sihaporas",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sianggunan",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sibual-Buali",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Sigala Gala",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Pagaran Bira Jae",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Pagaran Bira Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Huta Baru Sosopan",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Huta Baru Siundol",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Hulim",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Huta Bara",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Huta Bara",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Binanga Tolu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Banua Tonga",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Sosopan",
                "kelurahan": "Aek Bargot",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Unte Rudang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Ujung Padang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Ujung Gading",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Tanjung Rokan",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Tobing",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Tobing Tinggi",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Tanjung Morang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Tanjung",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Tandihat",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Sitada Tada",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sisalean",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Sipagabu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Siolip",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Siparau",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Silenjeng",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Simaninggir",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sihaborgoan Barum",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sihaborgoan Dalan",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Sidokan",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sidong-Dong",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Siboris Dolok",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Siboris Lombang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sibatu Loting",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Sibontar",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Siboris Bahal",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Sayur Mahincat",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Sayur Matua",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Pp Makmur",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Paya Bahung",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Pasar Binanga",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Paran Tonga An",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Paran Napa Jae",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Paran Julu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Paran Napa Dolok",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Paran Dolok",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Pangirkiran Dolok",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Padang Hasior Lombang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Padang Matinggi",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Padang Garugur",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Padang Garugur Jae",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Padang Hasior Dolok",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Padang Galugur Julu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Padang Galugur Tonga",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Marenu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Manombo",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Lubuk Gonting",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Janji Raja",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Janji Maria",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Janji Matogu Ur",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Janji Manahan",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Huta Ruhom",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Huta Bargot",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Hadungdung Pintu Padang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Gunung Manaon Ur",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Hadungdung Aek Rampa",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Gunung Malintang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Gunung Baringin",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Gulangan",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Ginduang Batu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Gading",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Binanga",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Bire",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Batu Sundung",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Bara Batu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Bangkuang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Balangka",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Bakkudu (Bangkudu)",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Bahal Batu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Aek Tunjang",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Aek Siala",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Barumun Tengah",
                "kelurahan": "Aek Tanduk",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Aek Nabara Tonga",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Aek Nabara Julu",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Aek Nabara Jae",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Sihapas Barumun",
                "kelurahan": "Aek Goti",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Aek Buaton",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Aek Nabara Barumun",
                "kelurahan": "Aek Bonban",
                "kodepos": "22755"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Tobing Tinggi Hr",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Tanjung Morang Hr",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Tobing Jae",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Tobing Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tanjung Botung Pinarik",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tanjung Barani",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tanjung Baru",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Tanjung Baringin",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tandalon",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tangga Batu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Tamiang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Sipirok Baru",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Siojo",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Sihoda-Hoda (Tarutung)",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Sibodak Papaso",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Sigading",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Siadam",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Siala Gundi",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Salambue",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Rombayan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Pulo Barian/Bariang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Ramba",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Pinarik",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Paya Bujing",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Pasir Lancat Lama",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Pasir Pinang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Pasir Lancat Baru",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Pasar Huristak",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Paran Tonga Hr (An)",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Papaso",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Pagaran Tayas",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Pagaran Dolok Pinarik",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Pagaran Manggis",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Muara Tige",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Pagaran Baringin/Beringin",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Muara Malinto Baru",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Muara Malinto Lama",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Manggis",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Hutanopan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Huta Baru",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Huta Pasir Ulak Tano",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Hatongga",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Huristak",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Gunung Manaon Hr",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Gunung Matinggi",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Gunung Intan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Gunung Manaon",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Gonting Jae",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Gonting Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Gala Bonang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Ganal",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Bulu Cina",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Botung",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Huristak",
                "kelurahan": "Binanga Tolu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Lubu Sutam",
                "kelurahan": "Aek Sorik",
                "kodepos": "22742"
            }
        ],
        "k451": [
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Tolang Julu",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Tolang Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Tanjung Medan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Sipange Siunjam",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Sisoma",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Situmba",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Sipange Godang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Sipange Julu",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Simpang Tolang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Simaninggir TT",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Silaiya Tanjung Leuk",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Silaiya",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Sialang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Samonggal Parmonangan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Sayur Matinggi",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Purba Tua",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Panabari Hutatonga",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Panindoan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Mondang",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Lumban Jabi-Jabi",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Lumban Ratus",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Lumban Huayan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Kota Tua",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Janji Mauli Baringin",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Ingul Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Hutaraja",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Huta Pardomuan",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Harean",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Bulu Gading",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Bange",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Batu Horpak",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Aek Uncim",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Aek Libung",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Aek Raja",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Aek Parupuk",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Aek Badak Julu",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Tano Tombangan Angkola",
                "kelurahan": "Aek Kahombu",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Tatengger",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Sayur Matinggi",
                "kelurahan": "Aek Badak Jae",
                "kodepos": "22774"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Tahalak Ujung",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sorimadingin",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sorik",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sitampa",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sori Madingin Pp",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sijungkit",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sipangko",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sigalangan",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sigulang Losung",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sidadi Dua",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sidadi Julu Satu",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pintu Padang Raya Satu",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Sibulele",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pintu Padang Raya Dua",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pasar Lamo/Lama",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pasir",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pargumbangan",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Pangaribuan",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Padang Kahombu",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Muara Tais III",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Muara Tais II",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Muara Tais I",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Muara Nauli",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Janji Mauli Mt",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Huta Tonga",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Janji Manaon",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Huta Padang",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Huta Holbung / Holdung",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Hurase / Hurare",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Benteng Huraba",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Bintuju",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Basilam Baru",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Aek Nauli",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Bargot Topong",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Batang Angkola",
                "kelurahan": "Aek Gunung",
                "kodepos": "22773"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Ulu Mamis Situnggaling",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Tapus Sipagabu",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Tolang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Tapus Godang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Sunge Sigiring Giring",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Silangkitang Tambiski",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Somba Debata Purba",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Silangkitang Tambiski",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Sihulambu",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Silangkitang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Sigolang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Saut Banua Simanosor",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Sidapdap Simanosor",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Pintu Padang Mandalasena",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Purbasinomba Sena",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Pasar Simangambat",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Pasar Sipagimbar",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Padang Mandailing Garugur",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Parau Sorat Sitabo-Tabo",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Huta Pohan",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Huta Tonga",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Lobu Tayas",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Huta Hombang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Galanggang",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Huta Baru",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Damparan Haunatas",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Biru",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Barumun Tua (Baringin Tua)",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Batang Parsuluman",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Aek Urat",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Saipar Dolok Hole",
                "kelurahan": "Aek Simotung",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Aek Bilah",
                "kelurahan": "Aek Latong",
                "kodepos": "22758"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Sipogu",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Pinagar",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Pinagar",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Pardomuan",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Nanggar Jati Hutapadang",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Natambang Roncitan",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Nanggar Jati",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Lancat",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Arse",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Arse",
                "kelurahan": "Arse Hanopan",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Somba Tolang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Situmba",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Situmba Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Sipirok Godang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Simaninggir",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Sibadoar",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Siala Gundi",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Sialaman",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Sarogodung",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Sampean",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Saba Batang Miha",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Ramba Sihosur",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Pasar Sipirok",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Parau Sorat",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Pargarutan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Paran Padang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Paran Dolok Mardomu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Paran Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Pangaribuan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Pangurabaan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Pahae Aek Sagala",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Panaungan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Padang Bujur",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Marsada",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Kilang Papan",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Luat Lombang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Janji Mauli",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Huta Suhut I",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Hasang Marsada",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Gadu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Bunga Bondar",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Dolok Sordang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Dolok Sordang Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Bulu Mario",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Batu Satail",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Batang Tura Julu",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Baringin",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Barnang/Barnag Koling",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Batang Tura",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Bagas Lombang",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Bagas Nagodang III",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Sipirok",
                "kelurahan": "Aek Batang Paya",
                "kodepos": "22742"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Wek IV Batang Toru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Wek III Batang Toru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Wek II Batang Toru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Tarapung Raya",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Telo",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Wek I Batang Toru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Tanjung Dolok",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sumuran",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Sugi Tonga",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sisipa",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sisoma Jae",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Simaninggir",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sigala-Gala (Perkebunan Sigala-gala)",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sipenggeng",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Perkebunan Hapesong",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Sianggunan",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Perkebunan Batang Toru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Pasar Sempurna",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Pardamean",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Padang Lancat",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Muara Upu",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Napa",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Muara Ampolu",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Mombang Boru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Muara Manompas",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Marancar Godang",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Hutabaru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Marancar",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Huta Godang",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Huta Raja",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Huraba",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Hapesong Lama",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Haunatas",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Hapesong Baru",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Garoga",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Gapuk Tua",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Batu Hula",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Batu Horing",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Muara Batang Toru",
                "kelurahan": "Bandar Hapinis",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Aek Sabaon",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Aek Nauli",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Batang Toru",
                "kelurahan": "Aek Pining",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Marancar",
                "kelurahan": "Aek Nabara",
                "kodepos": "22738"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Tindoan Laut",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Siuhom",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Sisundung",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Sitinjak",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Simatorkis Sisoma",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Simatohir / Simatoir",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Simatohir",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Simataniari",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Sigumuru",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Sialogo",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Sibangkua",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Sangkunur",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Rianiate",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Perkebunan",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Parsalakan",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Malombu",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Panobasan",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Lobu Layan Sigordang",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Lembah Lubuk Manik",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Bandar Tarutung",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Batu Godang",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Sangkunur",
                "kelurahan": "Aek Pardomuan",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Barat (Padang Sidempuan)",
                "kelurahan": "Aek Nabara",
                "kodepos": "22735"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Tiang Aras",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Tabusira",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Sosopan Pargarutan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Sirumambe",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Siregar Matogu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Simandalu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Sijungkang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Sanggapati",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pasar Pargarutan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pargarutan Julu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pargarutan Tonga",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pargarutan Huta Baru",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pargarutan Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Panompuan Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pargarutan Dolok",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Panompuan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Pall Sebelas",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Marisi",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Lantosan Rogas",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Lantosan Rogas",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Huraba",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Gunung Manungkap",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Timur (Padang Sidempuan)",
                "kelurahan": "Batang Tura Sirumambe",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Tapian Nauli",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Situmbaga",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Tandihat",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Simarpinggan",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Sinyior",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Sibongbong",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Sihopur",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Sihuik Huik",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Siamporik Dolok",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Siamporik Lombang",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Pardomuan",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Perkebunan Simarpinggan",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Pintu Padang",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Gunung Baringin",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Napa",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Aek Natas",
                "kodepos": "22732"
            },
            {
                "kecamatan": "Angkola Selatan (Siais)",
                "kelurahan": "Dolok Gadang / Godang",
                "kodepos": "22732"
            }
        ],
        "k452": [
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Tamosu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Simardona",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Simangambat Dolok",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Simaninggir Psm",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Simanapang",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Sayur Matinggi",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Sayur Matinggi II Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Purba Tua",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pasir Ampolu Hepeng",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pintu Padang",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pangkalan Dolok Lama",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Parau Sorat",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pasar Matanggor",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pangkalan Dolok Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Pagaran Batu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Padang Garugur",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Padang Matinggi",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Janji Mauli",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Morang",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Padang Bujur Baru",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Huta Lambung",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Janji Manahan",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Gunung Tua Jati (Tumbujat)",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Gunung Tua Julu",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Galanggang",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Gunung Tua Batang Onang",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Batu Mamak",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Batu Nanggar",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Batu Pulut",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Batang Onang Baru",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Batang Onang",
                "kelurahan": "Batang Onang Lama",
                "kodepos": "22762"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Unte Manis",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Tanjung Longat",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Tarutung Bolak",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Tanjung Baru B",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sunut",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Tanjung Baru Silaiya",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sungai Datar",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sungai Pining",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sitonun",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Situmbaga",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Siraga Huta Padang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Siranap",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sipogas A",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sipogas B",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sipiongot",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sinabongan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simatorkis",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Simundol",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simataniari",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simataniari Jae",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simaninggir Sip",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simanosor",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Simaninggir Simundol",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Simangambat Tua",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Simadihon",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Simangambat",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Silangge",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Silogo-Logo",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Siloung",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sijara-Jara",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sijorang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sihalo-Halo/Sihatubang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sijantung Jae",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sijantung Julu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Siganyal (Singanyal)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sigugah",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sigordang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sigala Gala",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sigambal (Simambal)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sibio-Bio",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sibur Bur",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sibayo Jae",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sibanga Panahasahan (Binanga Panosahan)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sibayo",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sialang Dolok",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Sialan Gundi",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Sayur Matinggi",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Saba Bangunan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Salusuhan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Purbatua (Purbaba Tua)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Rongkare",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pintu Padang Merdeka",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pinarik",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pulo Liman (Limo)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Paya Ombik",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pijor Koling",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pasar Sipiongot",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pasar Sayur Matinggi",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pasar Simundol",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pasang Lela",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Parmeraan/Parmeran",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pancaran (Rancaran)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Parigi",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Panyabungan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Panca",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pamonoran",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pagaran Siregar",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Pamarai (Paramai)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pagaran Julu I",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Pagaran Julu II",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Padang Matinggi Simundol",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Padang Matinggi Gnt",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Napa Sundali",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Nahulu Julu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Padang Malakka (Balakka)",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Naga Saribu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Nahulu Jae",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Mompang Lombang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Nabonggal",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Nabundong",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Mompang Dolok",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Malino",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Lubuk Lanjang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Lubuk Kundur",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Kuala Simpang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Lubuk Godang",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Janjimanahan Gnt",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Karang anyer/Anyar",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Kuala Baringin",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Janji Manahan Gul",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Janji Manahan Sil",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Janji Matogu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Jambur Batu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Huta Imbaru Simundol",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Huta Baru Sil",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Huta Baru Sip",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Huta Imbaru Gil",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Hasahatan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Hatiran",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Gunung Sormin",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Gunung Maria",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Gunung Salamat",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Gumaruntar",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Gumbot",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Gadung Holbung",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Gonting Bange",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Dolok Sanggul",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Bukit Tinggi",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Bunut",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Dalihan Natolu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Batu Runding",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Binanga Gumbot",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Bintais Julu",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Baringin Sil",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Batu Hibul",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Baringin Sip",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Bahap",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Bandar Nauli",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Sundur",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Tangga",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Arse",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Aek Simanat",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Suhat Jae",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Suhut Tr",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Rao Tapian Nadenggan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Aek Kundur",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Aek Kanan",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Ilung",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok Sigompulon",
                "kelurahan": "Aek Jabut",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Dolok",
                "kelurahan": "Aek Haruaya",
                "kodepos": "22756"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Ujung Padang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Ubar",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Tapus Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Tanjung Toram",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Tanjung Marulak",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Tangga Tangga Hambeng",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sunge Tolang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sunge Orosan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sunge Durian",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sosopan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Sobar",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Siunggam Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Siunggam Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Siunggam Tonga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sitonun (Sitenun)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Situmbaga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Siunggam Dolok",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sitabola",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Sitanggoru",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Siringkit Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Siringkit Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Sipupus Lombang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sipenggeng",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Siopuk/Sihopuk Lama",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sipaho",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Siombob",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Simasi",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Simbolon",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Silantoyung",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Simaninggir",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Simanosor",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sihapas Hapas",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sihodahoda",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sigama Ujung Gading",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sigimbal",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sigama",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sigala-Gala",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sidikkat (Sidingkat)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Siboru Angin",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sibatang Kayu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Siancimun",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sibagasi",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Sialang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sandean Tonga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sandean Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Sandean Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Sampuran",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Saba Sitahul Tahul",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Saba Bangunan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Rondaman Siburegar",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Saba",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Rampa Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Rondaman",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Rahunning/Rahuning Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Rampa Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Purba Tua",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Purba Sinomba",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Pasir Baru",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Pasar Gunung Tua",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Parupuk Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Parupuk Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Paran/Parang Padang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Parlimbatan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Paran Nangka",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Paran Honas",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Paran Gadung",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Pangirkiran",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Paolan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Pangarambangan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Pancur Pangko",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Pagaran Tonga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Pamuntaran",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Pagaran Singkam",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Pagar Gunung",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Padang Garugur",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Padang Bujur",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Napalancat",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Padang Baruas",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Napa Gadung Laut",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Naga Saribu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Mompang/Mempang II",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Nabongal (Nabonggal)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Mandi Angin Lombang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Mompang I",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Mananti (Marenti)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Mandi Angin Dolok",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Lubuk Torop",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Losung Batu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Liang Hasona/Asona",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Lantosan II",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Hutanopan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Japinulik",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Hutaimbaru II",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Hutaimbaru",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Huta Baru Nangka",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Huta Lombang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Hiteurat",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Hasambi",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Hasahatan (Hasatan)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Hambiri Boding",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Halongonan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Hambulo",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gunung Tua Tonga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Hajoran",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gunung Tua Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gunung Tua Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Gunung Manaon III",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gunung Tua Baru",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Gunung Intan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gunung Manaon II",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Garoga",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Garonggang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Gulangan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Gariang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Botung",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Bukit Raya Sedang / Sordang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Dolok Sae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Borgot/Bargot Topong Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Borgot/Bargot Topong Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Bolatan",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batu Tunggal",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Batu Tunggal",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batu Sundung",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Batu Rancang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batu Tambun",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batu Mamak",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Batu Gana",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batang Pane I",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batang Pane II",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batang Pane III",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batang Baruhar Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Batang Baruhar Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Balimbing Julu",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Bangun Purba",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Balimbing Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Halongonan",
                "kelurahan": "Balimbing",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Balakka (Balangka)",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Ambasang Natigor",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Aek Suhat",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Aek Tolang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Aek Gambir",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Aek Jangkang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak Julu",
                "kelurahan": "Aek Bargot",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Bolak",
                "kelurahan": "Aek Bayur",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Ujung Gading Julu",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Ulok/Ulak Tano",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Ujung Batu Julu",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Ujung Gading Jae",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Tobing Tinggi Ub",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Ujung Batu Jae",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Tanjung Maria",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Tanjung Botung",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Simangambat Julu",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Sionggoton",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Sigagan",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Simangambat Jae",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Paya Bahung Ub (Lb/An)",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Pasar Lancat Ub (Pasir Lancat Julu)",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Paran Padang",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Paran Tonga Sim",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Martujuan",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Mandasip",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Marlaung",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Mananti",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Manare Tua",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Langkimat",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Labuhan Jurung",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Kosik Putih",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Jabi-Jabi",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Jambu Tonang",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Janji Matogu Sim",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Huta Pasir",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Huta Raja",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Gunung Manaon Ub",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Huta Baringin",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Huta Baru",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Gunung Manaon Sim",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Simangambat",
                "kelurahan": "Aek Raru",
                "kodepos": "22747"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Tanjung Salamat/Selamat",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Torluk Muara Dolok",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Sitopayan",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Simandi Angin",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Sipirok",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Sigama Napa Halas/Alas",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Sihambeng",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Rondaman Dolok (Ronda Mandolok)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Rondaman Lombang (Ronda Manlombang)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Portibi Jae",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Portibi Julu",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Portibi Julu",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Pasir Pinang",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Pijor Koling",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Padang Manjoir",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Parsarmaan (Parsamaan)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Napa Halas",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Napa Lombang",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Mangaledang Lama",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Muara Sigama",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Janji Matogu",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Lantosan I",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Mangaledang",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Hadungdung",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Hotang Sasa",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Gunung Martua",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Gunung Manaon",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Guma Rupu Lama",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Gumarupu Baru",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Gunung Baringin",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Bangkudu (Bakkudu)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Bangkudu (Bakkudu)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Aloban",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Bahal",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Balakka Torop (Balangka Torop)",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Aek Siala",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Aek Torop",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Portibi",
                "kelurahan": "Aek Haruya",
                "kodepos": "22741"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Suka Dame",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Sitabar",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Simaninggir",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Sidongdong",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Sampuran Simarloting",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Pintu Bosi",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Parmeraan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Pangirkiran",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Aek Nauli",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Hulu Sihapas",
                "kelurahan": "Aek Godang",
                "kodepos": "22733"
            }
        ],
        "k453": [
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Tinjoman Lama",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Singali",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Singali",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Sabungan Sipabangun",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Sabungan Jae",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Partihaman Saroha",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Palopat Maria",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Lubuk Raya",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Lembah Lubuk Manik",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Hutaimbaru",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Hutaimbaru",
                "kelurahan": "Huta Padang",
                "kodepos": "22753"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Ujung Gurab",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Tarutung Baru",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Simirik",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Simasom",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Simatohir",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Siloting",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Sihitang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Sigulang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Salambue",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Rimba Soping",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Purbatua Pijor Koling",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Purwodadi",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Pudun Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Pudun Julu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Pintu Langit Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Pijor Koling",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Perkebunan Pijor Koling",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Palopat (Pal IV) Pijor Koling",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Mompang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Manunggang Julu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Manunggang Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Labuhan Rasoki",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Labuhan Labo",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Joring Lombang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Joring Natobang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Huta Limbong",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Huta Lombang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Huta Padang",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Huta Koje Pijor Koling",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Gunung Hasahatan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Tenggara",
                "kelurahan": "Goti",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Batunadua Jae",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Batunadua Julu",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Angkola Julu",
                "kelurahan": "Batu Layan",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Baruas",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Batang Bahal",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Aek Tuhul",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Bargot Topong",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Aek Najaji",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Batunadua",
                "kelurahan": "Aek Bayur",
                "kodepos": "22733"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Silandit",
                "kodepos": "22728"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Padang Matinggi Lestari",
                "kodepos": "22727"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Padang Matinggi",
                "kodepos": "22727"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Ujung Padang",
                "kodepos": "22725"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Aek Tampang",
                "kodepos": "22726"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Wek V",
                "kodepos": "22723"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Wek VI",
                "kodepos": "22724"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Sitamiang",
                "kodepos": "22723"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Sitamiang Baru",
                "kodepos": "22723"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Hanopan",
                "kodepos": "22721"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Sidangkal",
                "kodepos": "22721"
            },
            {
                "kecamatan": "Padang Sidempuan Selatan",
                "kelurahan": "Losung",
                "kodepos": "22722"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Wek IV",
                "kodepos": "22719"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Wek II",
                "kodepos": "22718"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Wek III",
                "kodepos": "22719"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Tano Bato",
                "kodepos": "22716"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Tobat",
                "kodepos": "22716"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Batang Ayumi Jae",
                "kodepos": "22716"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Wek I",
                "kodepos": "22715"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Losung Batu",
                "kodepos": "22713"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Panyanggar",
                "kodepos": "22714"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Sadabuan",
                "kodepos": "22715"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Timbangan",
                "kodepos": "22711"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22712"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Bincar",
                "kodepos": "22711"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Kantin",
                "kodepos": "22711"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Kayu Ombun",
                "kodepos": "22711"
            },
            {
                "kecamatan": "Padang Sidempuan Utara (Padangsidimpuan)",
                "kelurahan": "Batang Ayumi Julu",
                "kodepos": "22711"
            }
        ],
        "k454": [
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Toga Basir",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Sitardas",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Sitonong Bangun",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Simanosor",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Sihapas",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Sihaporas",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Sihadatuon",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Sibabangun",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Sibio Bio",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Sialogo",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Sialogo (Sihalogo)",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Pulo Pakkat I",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Pulo Pakkat II",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Pinang Baru",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Pinangsori",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Pagaran Honas",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Parjalihotan Baru",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Mombang Boru",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Muara Sibuntuon",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Masundung",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Lumut Nauli",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Lubuk Ampolu",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Lumut",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Lumut Maju",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Kebun Pisang",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Lopian",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Jago Jago",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Suka Bangun",
                "kelurahan": "Janji Maria",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Hutabalang",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Hutagurgur",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Pinangsori",
                "kelurahan": "Gunung Marijo",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Gunung Kelambu",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Sibabangun",
                "kelurahan": "Anggoli",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Badiri",
                "kelurahan": "Aek Horsik",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Uratan",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Lumut",
                "kelurahan": "Aek Gambir",
                "kodepos": "22654"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sogar",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sosor Gonting",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sirami Ramian",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sitiris-Tiris",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sigolang",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sijungkang",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Rinabolak",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Sawah Lamo",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Lobu Tua",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Pangaribuan",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Bondar Sihudon II",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Ladang Tengah",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Tapian Nauli IV",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Andam Dewi",
                "kelurahan": "Bondar Sihudon I",
                "kodepos": "22651"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Tapian Nauli II",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Tapian Nauli III",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Tapian Nauli I",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Sait Nihuta Kalangan II",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Mela I",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Mela II",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Bair",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Mela Dolok",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Tukka",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Tapian Nauli",
                "kelurahan": "Aloban",
                "kodepos": "22618"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Sipange",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Tapian Nauli Saur Manggita",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Huta Nabolon",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Sigiring Giring",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Bona Lumban",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Sarudik",
                "kelurahan": "Sipan",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Tukka",
                "kelurahan": "Aek Bontar",
                "kodepos": "22617"
            },
            {
                "kecamatan": "Sarudik",
                "kelurahan": "Sibuluan Nalambok",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Sibuluan Nauli",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Sibuluan Raya",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Sarudik",
                "kelurahan": "Pondok Batu",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Sarudik",
                "kelurahan": "Sarudik",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Sibuluan Indah",
                "kodepos": "22614"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Lubuk Tukko",
                "kodepos": "22615"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Kalangan",
                "kodepos": "22616"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Aek Sitio Tio",
                "kodepos": "22613"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Aek Tolang",
                "kodepos": "22613"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Simaninggir",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Hajoran",
                "kodepos": "22612"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Rampa",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Sarudik",
                "kelurahan": "Pasir Bidang",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Naga Timbul",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Nauli",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Pandan",
                "kelurahan": "Pandan",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Bonandolok",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Sitahuis",
                "kelurahan": "Mardame",
                "kodepos": "22611"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Tumba Jae",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Siordang",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Tumba",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Sigodung",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Simpang Tiga Lae Bingke",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Saragih",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Sarma Nauli",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Pardomuan",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Sampang Maruhur",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Pasar Onan Manduamas",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Pagaran Nauli",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Muara Ore",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Masnauli",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Binjohara",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Lae Monong",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Manduamas",
                "kelurahan": "Manduamas Lama",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Unte Boang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sirandorung",
                "kelurahan": "Bajamas",
                "kodepos": "22565"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Sosor Gadong",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Ujung Batu",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Siharbangan",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Sigambo Gambo",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Sihorbo",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Siantar Ca",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Siantar Dolok",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Sibintang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Purbatua",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Patupangan",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Pasar Batu Gerigis",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Pasar Terandam",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Padang Masiang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Pananggahan",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Parik Sinomba",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Muara Bolak",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Kinali",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Kedai Gedang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Huta Tombak",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Kampung Mudik",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Kampung Solok",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Gabungan Hasang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus Utara",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Baringin",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Bungo Tanjung",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sosor Gadong",
                "kelurahan": "Barambang",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Barus",
                "kelurahan": "Aek Dakka",
                "kodepos": "22564"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Tarutung Bolak",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Teluk Roban",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Sorkam Kanan 2",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Suga-Suga (Hutagodang)",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Suka Maju",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Sorkam",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Sipea Pea",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Sorkam Kanan",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Simarpinggan",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Sipakpahi",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Sidikalang",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Sidaling",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Simargarap",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Pelita",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Rianiate",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Pearaja",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Pasaribu Tobing Jae",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Pasaribu Tobing",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Pasar Sorkam",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Pargaringan",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Pargarutan",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Pardamean",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Pahieme",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Pahieme II",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Makmur",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Nai Pos Pos Barat",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Maduma",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Fajar",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Gonting Mahe",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Botot",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam",
                "kelurahan": "Dolok Pantis",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Aek Raso",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Sorkam Barat",
                "kelurahan": "Binasi",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Unte Mungkur IV",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Pasaribu Tobing",
                "kelurahan": "Aek Nadua",
                "kodepos": "22563"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Unte Mungkur II",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Unte Mungkur III",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Satahi Nauli",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Sipakpahi Aek Lobu",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Unte Mungkur I",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Rawa Makmur",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Kolang Nauli",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Makarti Nauli",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Pasar Onan Hurlang",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Hudopa Nauli",
                "kodepos": "22562"
            },
            {
                "kecamatan": "Kolang",
                "kelurahan": "Hurlang Muara Nauli",
                "kodepos": "22562"
            }
        ],
        "k455": [
            {
                "kecamatan": "Sibolga Selatan",
                "kelurahan": "Aek Parombunan",
                "kodepos": "22538"
            },
            {
                "kecamatan": "Sibolga Selatan",
                "kelurahan": "Aek Manis",
                "kodepos": "22536"
            },
            {
                "kecamatan": "Sibolga Selatan",
                "kelurahan": "Aek Muara Pinang",
                "kodepos": "22537"
            },
            {
                "kecamatan": "Sibolga Sambas",
                "kelurahan": "Pancuran Bambu",
                "kodepos": "22535"
            },
            {
                "kecamatan": "Sibolga Sambas",
                "kelurahan": "Pancuran Pinang",
                "kodepos": "22534"
            },
            {
                "kecamatan": "Sibolga Selatan",
                "kelurahan": "Aek Habil",
                "kodepos": "22533"
            },
            {
                "kecamatan": "Sibolga Sambas",
                "kelurahan": "Pancuran Kerambil",
                "kodepos": "22531"
            },
            {
                "kecamatan": "Sibolga Sambas",
                "kelurahan": "Pancuran Dewa",
                "kodepos": "22532"
            },
            {
                "kecamatan": "Sibolga Kota",
                "kelurahan": "Pasar Belakang",
                "kodepos": "22523"
            },
            {
                "kecamatan": "Sibolga Kota",
                "kelurahan": "Pancuran Gerobak",
                "kodepos": "22524"
            },
            {
                "kecamatan": "Sibolga Kota",
                "kelurahan": "Kota Baringin (Beringin)",
                "kodepos": "22521"
            },
            {
                "kecamatan": "Sibolga Kota",
                "kelurahan": "Pasar Baru",
                "kodepos": "22522"
            },
            {
                "kecamatan": "Sibolga Utara",
                "kelurahan": "Simaremare",
                "kodepos": "22513"
            },
            {
                "kecamatan": "Sibolga Utara",
                "kelurahan": "Huta Barangan",
                "kodepos": "22514"
            },
            {
                "kecamatan": "Sibolga Utara",
                "kelurahan": "Huta Tonga Tonga",
                "kodepos": "22512"
            },
            {
                "kecamatan": "Sibolga Utara",
                "kelurahan": "Sibolga Ilir",
                "kodepos": "22513"
            },
            {
                "kecamatan": "Sibolga Utara",
                "kelurahan": "Angin Nauli",
                "kodepos": "22511"
            }
        ],
        "k456": [
            {
                "kecamatan": "Muara",
                "kelurahan": "Simatupang",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Sitanggor",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Unte Mungkur",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Silali Toruan",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Silando",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Papande",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Sampuran",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Sibandang",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Huta Lontung",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Huta Nagodang",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Dolok Martumbur",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Aritonang",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Bariba Niaek",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Muara",
                "kelurahan": "Batu Binumbun",
                "kodepos": "22476"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Sitampurung",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Sitabotabo",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Sitabotabo Toruan",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Silait-Lait",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Sigumbang",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Siborong-Borong I",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Siborong-Borong II",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Siaro",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Pohan Julu",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Pohan Tonga",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Pohan Jae",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Pasar Siborong Borong",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Parik Sabungan",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Lumban Tonga Tonga",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Paniaran",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Lobu Siregar II",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Lobu Siregar I",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Hutabulu",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Bahal Batu III",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Bahal Batu II",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Simpang Bolon",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Siborong-Borong",
                "kelurahan": "Bahal Batu I",
                "kodepos": "22474"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Sibalanga",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Sibaganding",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Parsosoran",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Padang Siandomang",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Parinsoran Pangorian",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Lontung Jae II",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Gonting Salak",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Lontung Jae I",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Gonting Garoga",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Garoga Sibargot",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Silantom Tonga",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Garoga",
                "kelurahan": "Aek Tangga",
                "kodepos": "22473"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Silantom Julu",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Silantom Jae",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Sigotom Julu",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Sampagul",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Sibingke",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Rahut Bosi",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Purbatua",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Parsorminan I",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Parsibarungan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Parlombuan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Parratusan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Pansurnatolu",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Pakpahan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Najumambe",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Padang Parsadaan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Harianja",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Lumban Sinaga Simatupang",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Lumban Siregar",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Batumanumpak",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Godung Borotan",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Pangaribuan",
                "kelurahan": "Batu Nadua",
                "kodepos": "22472"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Tapian Nauli III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Tapian Nauli II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sipahutar II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sipahutar III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Tapian Nauli I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Siabal Abal IV",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sipahutar I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Siabal Abal I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Siabal Abal II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Siabal Abal III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sabungan Nihuta V",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sabungan Nihuta IV",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sabungan Nihuta II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sabungan Nihuta III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Onan Runggu IV",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Sabungan Nihuta I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Onan Runggu II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Onan Runggu III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Aek Nauli III",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Aek Nauli IV",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Onan Runggu I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Aek Nauli I",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Sipahutar",
                "kelurahan": "Aek Nauli II",
                "kodepos": "22471"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Simangumban Jae",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Simangumban Julu",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Pardomuan",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Silosung",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Dolok Sanggul",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Dolok Saut",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Lobu Sihim",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Tordolok Nauli",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Simangumban",
                "kelurahan": "Aek Nabara",
                "kodepos": "22466"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Sukamaju",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Sitolu Ompu (Bahal)",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Sitolu Ompu",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Sigurung Gurung",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Silangkitang",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Siopat Bahal",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Sidua Bahal",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Sibulan Bulan",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Setia",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Robean",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Selamat",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Sarulla (Pasar Sarulla)",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Parsaoran Nainggolan",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Parsaoran Samosir",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Purbatua",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Pardomuan Nainggolan",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Pardomuan Janji Angkola",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Parsaoran Janji Angkola",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Pardamean Nainggolan",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Jae",
                "kelurahan": "Nahornop Marsada",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Huta Nagodang",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Janji Nauli",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Sitoluama",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Purbatua",
                "kelurahan": "Bonani Dolok",
                "kodepos": "22465"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Simasom Toruan",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Simataniari",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Simasom",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Simanampang",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Simardangiang",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Pantis",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Sibaganding",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lumban Tonga",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Onan Hasang",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Pangurdotan",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lumban Gaol",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lumban Garaga",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lumban Jaean",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lontung Dolok",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lumban Dolok",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Huta Barat",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Janji Matogu",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Pahae Julu",
                "kelurahan": "Lobu Pining",
                "kodepos": "22463"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Siantar Nai Pospos",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Sibalanga",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pagaran Pisang",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pansur Batu",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pagaran Lambung II",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pagaran Lambung III",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pagaran Lambung IV",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Dolok Nauli",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Pagaran Lambung I",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Banuaji II",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Banuaji IV",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Adiankoting",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Adian Koting",
                "kelurahan": "Banuaji I",
                "kodepos": "22461"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Sipultak",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Sipultak Dolok",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Sibaragas",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Simamora Hasibuan",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Pagaran",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Parhorboan",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Lumban Motung",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Lumban Silintong",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Lumban Julu",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Lumban Ina Ina",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Hasibuan",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Lubis",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Banua Luhu",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Pagaran",
                "kelurahan": "Dolok Saribu",
                "kodepos": "22458"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Purba Dolok",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Sisordak",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Pertengahan",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Manalu",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Manalu Dolok",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Manalu Purba",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Lobusunut",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Hutatua",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Hutatinggi",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Horison Ranggigit",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Hutajulu",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Hutajulu Parbalik/Purbalik",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Batu Arimo",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Parmonangan",
                "kelurahan": "Aek Raja",
                "kodepos": "22453"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Situmeang Hasundutan",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Tapian Nauli",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Sipoholon",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Situmeang Habinsaran",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Sihujur",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Simanungkalit",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Sipahutar",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Rura Julu Dolok",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Rura Julu Toruan",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Jambur Nauli",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Lobusingkam",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Pagar Batu",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Hutauruk Hasundutan (Hutaraja Simanungkalit)",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Huta Raja Hasundutan",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Hutauruk",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Pansur Napitu",
                "kodepos": "22419"
            },
            {
                "kecamatan": "Sipoholon",
                "kelurahan": "Huta Raja",
                "kodepos": "22452"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Simorangkir Julu",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Sangkaran",
                "kodepos": "22418"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Sitompul",
                "kodepos": "22418"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Simanampang",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Simorangkir Habinsaran",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Siandor Andor",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Sidagal",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Lumban Siagian Jae",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Lumban Siagian Julu",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Si Raja Hutagalung",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Enda Portibi",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Siatas Barita",
                "kelurahan": "Lobuhole",
                "kodepos": "22417"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Partali Julu",
                "kodepos": "22416"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Partali Toruan",
                "kodepos": "22416"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbajutoruan",
                "kodepos": "22416"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Sitampurung",
                "kodepos": "22415"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hapoltahan",
                "kodepos": "22416"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbajutonga",
                "kodepos": "22416"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Siraja Oloan",
                "kodepos": "22415"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan IV",
                "kodepos": "22415"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbubu Pea",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbubu I",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbubu II",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan III",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbubu Dolok",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan VII",
                "kodepos": "22413"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan VIII",
                "kodepos": "22413"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan I",
                "kodepos": "22414"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan V",
                "kodepos": "22413"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan VI",
                "kodepos": "22413"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Sosunggulon",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan IX",
                "kodepos": "22412"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Aek Siansimun / Siamsimun",
                "kodepos": "22413"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Parbaju Julu",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Simamora",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan XI",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutauruk",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutagalung Siwaluompu",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutapea Banuarea",
                "kodepos": "22411"
            },
            {
                "kecamatan": "Tarutung",
                "kelurahan": "Hutatoruan X",
                "kodepos": "22411"
            }
        ],
        "k457": [
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sitolu Bahal",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Tapian Nauli",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Sihonongan",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Siponjot",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sitio II",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sigumpar",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Siharjulu",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sibuntuon",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sigompul",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Sibuntoan Parpea / Parpean",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Siboru Torop",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Pearung",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Pearung Silali",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Parulohan",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Pargaulan",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Paranginan Utara",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Nagasaribu IV",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Nagasaribu V",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Paranginan Selatan",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Nagasaribu III",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Naga Saribu I",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Naga Saribu II",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Lumban Sialaman",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Lumban Sianturi",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Lumban Barat",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Lobu Tua",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Lobutolong Habinsaran",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Hutasoit II",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Paranginan",
                "kelurahan": "Lobu Tolong",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Dolok Margu (Dolok Marduga)",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Habeahan",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Hutasoit",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Lintong Nihuta",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22475"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Tipang",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sosor Tambok",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sosortolong Sihite III",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Siunong-Unong Julu",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sosor Gonting",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Sitapongan",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sirisirisi",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Sipitu Huta",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Simangulampe",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Simarigung",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Sinambela",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Simamora",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Simangaronsang",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sihite II",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sileang",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Sigulok",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sihite I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Sibuntuon",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sigala Gala (Silaga Laga)",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Siborboron",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Sampean",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Sanggaran I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Saitnihuta",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Ria-Ria",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Purba Manalu",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Purba Dolok",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Pollung",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Pasaribu",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Parsingguran I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Parsingguran II",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Parik Sinomba",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Pardomuan",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Pandumaan",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Pansur Batu",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Pakkat",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Nagurguran",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Matiti II",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Matiti I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Marbun",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Bakti Raja",
                "kelurahan": "Marbun Tonga Marbun Dolok",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Janji",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Lumban Purba",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Lumban Tobing",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Hutaginjang",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Hutaraja",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Huta Paung Utara",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Hutabagasan",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Huta Julu",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Huta Paung",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Dolok Sanggul (Pasar Dolok Sanggul)",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Huta Gurgur",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Bonan Dolok II",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Bonani Onan",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Batu Nagajar",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Sijama Polang",
                "kelurahan": "Bonan Dolok I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Aek Nauli I",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Pollung",
                "kelurahan": "Aek Nauli II",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Tara Bintang",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Dolok Sanggul",
                "kelurahan": "Aek Lung",
                "kodepos": "22457"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Sitanduk",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Utara",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon VII",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Toruan",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Tonga",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Timur",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Timur II",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Selatan",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Sibulbulon",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Julu",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sionom Hudon Runggu",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Simbara",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sihotang Hasugian Tonga",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Simataniari",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Sihotang Hasugian Toruan",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sihotang Hasugian Dolok II",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sihotang Hasugian Habinsaran",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Sihotang Hasugian Dolok I",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Sihombu",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Sibongkare Sianju",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Pusuk II Simaninggir",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Sibongkare",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Pusuk I",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Mungkur",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Janji Hutanapa",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Tara Bintang",
                "kelurahan": "Marpadan",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Baringin",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Parlilitan",
                "kelurahan": "Baringin Natam",
                "kodepos": "22456"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Tukka Dolok",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Sipagabu",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Sijarango I",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Sijarango",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Rura Tanjung",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Siambaton",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Siambaton Pahae",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Purba Sianjur",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Rura Aek Sopang",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Pulo Godang",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Purba Baringin",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Purba Bersatu",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Peadungdung",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Parmonangan",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Pakkat Hauagong",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Panggugunan",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Lumban Tonga-Tonga",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Manalu",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Hauagong",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Karya",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Ambobi Paranginan",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Pakkat",
                "kelurahan": "Banuarea",
                "kodepos": "22455"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Sibuluan",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Sigalogo",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Sihikkit",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Sampetua",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Sanggaran II",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Parbotihan",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Parnapa",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Janji Nagodang",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Onan Ganjang",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Batu Nagodang Siatas",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Hutajulu",
                "kodepos": "22454"
            },
            {
                "kecamatan": "Onan Ganjang",
                "kelurahan": "Aek Godang",
                "kodepos": "22454"
            }
        ],
        "k458": [
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Singkam",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Sianjur Mulamula",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Siboro",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Sari Marihit",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Pasar Pangururan",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Huta Gurgur",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Hasinggaan",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Ginolat",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Boho",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Bonan Dolok",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Sianjur Mula-Mula",
                "kelurahan": "Aek Sipitudai",
                "kodepos": "22396"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Tuktuk Siadong",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Tanjungan",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Tomok",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Simarmata",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Tamba Dolok",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Sihusapi",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Simanindo Sangkal",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Sabulan",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Parbalohan",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Pardomuan",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Parmonangan",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Martoba",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Maduma",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Janji Raja",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Dosroha",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Holbung",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Garoga",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Buntu Mauli",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Cinta Dame",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Sitio-Tio",
                "kelurahan": "Cinta Maju",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Toguan Galung",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Simanindo",
                "kelurahan": "Ambarita",
                "kodepos": "22395"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Tambun Sungkean",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Sitamiang",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Sitinjak",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Sipinggan Lumban Siantar",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Siruma Hombar",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Sipira",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Sibonor Ompuratus",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Sinaga Uruk Pandiangan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Silima Lombu",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Rinabolak",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Pasaran I",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Pasaran Parsaoran",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Pardomuan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Parhusip III",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Pakpahan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Pananggangan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Pangaloan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Nainggolan",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Onan Runggu",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Janji Matogu",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Nainggolan",
                "kelurahan": "Huta Rihit",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Huta Hotang",
                "kodepos": "22394"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Urat II",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Urat Timur",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Suhut Nihuta Pardomuan",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Sigaol Simbolon",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Simbolon Purba",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Sigaol Marbun",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Saor Nauli Hatoguan",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Parsaoran Urat",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Palipi",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Huta Ginjang",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Hatoguan",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Palipi",
                "kelurahan": "Gorat Pallombuan",
                "kodepos": "22393"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Tanjung Bunga",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Sitoluhuta",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Situngkir",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Siogung Ogung",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Siopat Sosor",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Sinabulan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Sijambur",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Sialanguan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Sianting Anting",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Salaon Tonga Tonga",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Salaon Toba",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Salaon Dolok",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Sabungan Nihuta",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Saitnihuta",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Ronggur Nihuta",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Rianiate",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Pintu Sona",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Parsaoran I",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Parmonangan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Parlondut",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Parhorasan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Pardugul",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Pardomuan I",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Parbaba Dolok",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Paraduan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Panampangan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Lumban Suhi Suhi Toruan",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Lumban Pinggol",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Lumban Suhi Suhi Dolok",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Huta Tinggi",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Ronggur Nihuta",
                "kelurahan": "Lintongnihuta",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Huta Bolon",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Huta Namora",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Pangururan",
                "kelurahan": "Aek Nauli",
                "kodepos": "22392"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Turpuk Sagala",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Turpuk Sihotang",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Turpuk Malau",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Sosor Dolok",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Turpuk Limbong",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Siparmahan",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Partungko Naginjang",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Sampur Toba",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Janji Martahan",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Onan Runggu",
                "kelurahan": "Harian",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Hariara Pohan",
                "kodepos": "22391"
            },
            {
                "kecamatan": "Harian",
                "kelurahan": "Dolok Raja",
                "kodepos": "22391"
            }
        ],
        "k459": [
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Sirungkungon",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Sionggang Tengah",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Sionggang Utara",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Sinar Sabungan",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Sionggang Selatan",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Sihiong",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Silamosik II",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Silombu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Sibaruang",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Sigapiton",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Sibadihon",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Pasar Lumban Julu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Partoruan Lumban Lobu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Parsaoran Sibisa",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Pardomuan Motung",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Parsaoran Ajibata",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Pardolok Lumban Lobu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Pardamean Sibisa",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Pardomuan Ajibata",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Naga Timbul",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Nagatimbul Timur",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Pardamean Ajibata",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Lumban Lobu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Lumban Sangkalan",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Motung",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Jangga Toruan",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Jonggi Nihuta",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Lintong Julu",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Hutanamora",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Jangga Dolok",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Bonatua Lunasi",
                "kelurahan": "Harungguan",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Hatinggian",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Ajibata",
                "kelurahan": "Horsik",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Lumban Julu",
                "kelurahan": "Aek Natolu Jaya",
                "kodepos": "22386"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Sigaol Barat",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Sigaol Timur",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Siregar Aek Nalas",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Sibuntuon",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Sampuara",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Parik",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Partor Janji Matogu",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Partoruan Janji Matogu",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Parbagasan Janji Matogu",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Parhabinsaran Janji Matogu",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Lumban Nabolon",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Marom",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Lumban Binanga",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Lumban Holbung",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Dolok Saribu Lumban Nabolon",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Dolok Nagodang",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Uluan",
                "kelurahan": "Dolok Saribu Janji Matogu",
                "kodepos": "22385"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Tangga Batu I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Tangga Batu II",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Silamosik I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Simpang Sigura-Gura",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Tonga Tonga II",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Siantar Utara",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Tonga-Tonga III",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Sitiotio",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Tonga Tonga I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Dangsina",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Siantar Sigordang",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Raut Bosi",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Pintu Pohan",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Pintu Pohan Dolok",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Patane IV",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Patane V",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Patane II",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Patane III",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Patane I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Parparean III",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Parparean IV",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Pasar Porsea",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Parparean I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Parparean II",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Pangombusan",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda VIII",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda VII",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda VI",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda III",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda IV",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda V",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda II",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Siantar Narumonda",
                "kelurahan": "Narumonda I",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Meranti Utara",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Nalela",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Meranti Timur",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Meranti Tengah",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Lumban Manurung",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Lumban Sitorus",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Lumban Gurning",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Lumban Huala",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Jonggi Manulus",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Halado",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Gala Gala Pangkailan",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Bius Gu Barat",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Dolok Nauli",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Porsea",
                "kelurahan": "Amborgang",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Parmaksian",
                "kelurahan": "Banjar Ganjang",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Pintu Pohan Meranti",
                "kelurahan": "Ambar Halim",
                "kodepos": "22384"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Taon Marisi",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Tornagodang",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Sibuntuon",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Simare",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Sipagabu",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Riganjang",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Siantarasa",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Purba Tua",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Rianiate",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Pasar Borbor",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Parsoburan Tengah",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Parsoburan Barat",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Pararungan",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Pangururan",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Pangururan II",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Pangururan III",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Panamparan",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Pangunjungan",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Natu Mingka",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Pagar Batu",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Lumban Sewa",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Ruhap/Ruhaya",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Napajoriho",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Lumban Rau Timur",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Lumban Rau Utara",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Lumban Rau Tenggara",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Lumban Rau Tengah",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Rau Balik",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Rau Barat",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Rau Selatan",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Pinasa",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Pinasa Saroba",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Lintong",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Pea",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Lintong",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lobu Hole",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Lumban Gaol",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Liat Tondung",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Hutagurgur",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Janji Maria",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Hitetano (Tite Tano)",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Cinta Damai",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Batu Nabolon",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Nassau",
                "kelurahan": "Batu Manumpak",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Bor-Bor",
                "kelurahan": "Aek Unsim",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Habinsaran",
                "kelurahan": "Aek Ulok",
                "kodepos": "22383"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Siringkiron",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Sitorang I",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Silaen",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Simanobak",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Sinta Dame (Cinta Damai)",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Sibide Barat",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Pardomuan",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Sigodang Tua",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Natolutali",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Parsambilan",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Napitupulu",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Pintu Batu",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Marbulang",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Sibide",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Meranti Barat",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Ombur",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Hutagaol Sihujur",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Panindi",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Lumban Dolok",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Huta Namora",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Huta Gurgur I",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Huta Gurgur II",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Tinggir Nipasir",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Ujung Tanduk",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Silaen",
                "kelurahan": "Dalihan Natolu",
                "kodepos": "22382"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Situa-Tua",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sitoluama",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Siraja Gorat",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sitangkola",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Simatibung",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sintong Marnipi",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Sigumpar Dangsina",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Sigumpar Julu",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Sigumpar Barat",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Sigumpar",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sibuea",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sidulang",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Pintu Bosi",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Sibarani Nasampulu",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Pasar Lagu Boti",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Pardinggaran",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Ompu Raja Hutapea",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Ompu Raja Hutapea Timur",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Nauli",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Ompu Raja Hatulian",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Marsangap",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Lumban Binanga",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Maju",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Haunatas II",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Lumban Bagasan",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Gasa Ribu",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Haunatas I",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Banua Huta",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Sigumpar",
                "kelurahan": "Dolok Jior",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Laguboti",
                "kelurahan": "Aruan",
                "kodepos": "22381"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Balige II",
                "kodepos": "22316"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Balige III",
                "kodepos": "22315"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Balige I",
                "kodepos": "22316"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Pardede Onan",
                "kodepos": "22313"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Dolok Haume Bange",
                "kodepos": "22314"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Tara Bunga",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Tangga Batu Barat",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Tangga Batu Timur",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Tambunan Sunge",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Sibuntuon",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Silalahi Pagar Batu",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Sianipar Sihail Hail",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Sibola Hotangsas",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Siboruon",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Sangkar Nihuta",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Sariburaja Janji Maria",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Parsuratan",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Paindoan",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Meat",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Matio",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Pea Timur",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Silintong",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Gaol",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Gorat",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Pea",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Lintong Nihuta",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Longat",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Lumban Bul Bul",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Huta Namora",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Hutagaol Peatalun (Peatalum)",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Huta Bulu Mejan",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Huta Dame",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Tampahan",
                "kelurahan": "Gukguk/Gurgur Aek Raja",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Bonan Dolok III",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Hinalang Bagasan",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Bonan Dolok I",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Bonan Dolok II",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Aek Bolon Julu",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Baru Ara",
                "kodepos": "22312"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Napitupulu Bagasan",
                "kodepos": "22311"
            },
            {
                "kecamatan": "Balige",
                "kelurahan": "Aek Bolon Jae",
                "kodepos": "22312"
            }
        ],
        "k460": [
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Tanjung Saluksuk",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Simanduma",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Lingga Raja",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Onan Lama",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Bandar Huta Usang",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Bukittinggi",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Pegagan Hilir",
                "kelurahan": "Laksa",
                "kodepos": "22283"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan V",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan VI",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan II",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan III",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan IV",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Lae Hole",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Parbuluan I",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Parbuluan",
                "kelurahan": "Bangun",
                "kodepos": "22282"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Sumbul",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Silahi Sabungan",
                "kelurahan": "Silalahi II",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Silahi Sabungan",
                "kelurahan": "Silalahi III",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Sempung Polling",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Silahi Sabungan",
                "kelurahan": "Silalahi I",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu X",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Perjuangan",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu VII",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu VIII",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu IX",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu V",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu VI",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu II",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu III",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu IV",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Silahi Sabungan",
                "kelurahan": "Paropo",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Pegagan Julu I",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Lumban Toruan",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Pandiangan",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Pandiangan",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Kuta Gugung",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Lae Parira",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Lumban Sihite",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Sumbul",
                "kelurahan": "Dolok Tolong",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Kentara",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Lae Parira",
                "kelurahan": "Bulu Duri",
                "kodepos": "22281"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Sopobutar",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Simungun",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Pardomuan",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Lae Saring",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Lae Markelang",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Lae Itam",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Lae Luhung",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Janji",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Siempat Nempu Hilir",
                "kelurahan": "Jambur Indonesia",
                "kodepos": "22263"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Urik Belin",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Tungtung Batu",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Sumbari",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Siratah",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Siboras",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Parongil",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Polling Anak-Anak",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Palipi",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Lae Rambong",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Longkotan",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Lae Panginuman",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Bonian",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Lae Ambat",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Bongkaras",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Silima Pungga-Pungga",
                "kelurahan": "Bakal Gajah",
                "kodepos": "22262"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Soban",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Sosor Lontung",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Sinampang",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Sihorbo",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Jumantuang",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Juma Teguh",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Juma Siulok",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Huta Imbaru",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Gomit",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Bunturaja",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Tualang",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu",
                "kelurahan": "Adian Nangka",
                "kodepos": "22261"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Tambahan",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Sipoltong",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Sungai Raya",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Silumboyah",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Sigambir Gambir",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Pangaribuan",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Pandan",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Gunung Meriah",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Kuta Tengah",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Lae Nuaha",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Siempat Nempu Hulu",
                "kelurahan": "Bakal Julu",
                "kodepos": "22254"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Tanah Pinem",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Renun",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Suka Dame",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Pasir Tengah",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Lau Tawar",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Pamah",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Kutabuluh",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Lau Primbon",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Kempawa",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Kuta Gambir",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Gunung Tua",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tanah Pinem",
                "kelurahan": "Harapan",
                "kodepos": "22253"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Tiga Lingga",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Ujung Teran",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Sumbul Tengah",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Sukandebi",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Palding/Polding",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Palding/Polding Jaya Sumbul",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Sarintonu",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Lau Pakpak",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Lau Sireme",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Lau Bagot",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Lau Mil",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Lau Molgap",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Bertungen Julu",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Tiga Lingga",
                "kelurahan": "Juma Gerat",
                "kodepos": "22252"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Tupak Raja",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Rante Besi",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Berampu (Brampu)",
                "kelurahan": "Sambaliang",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Kendit Liang",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Lau Lebah",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Berampu (Brampu)",
                "kelurahan": "Pasi",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Gunung Sitember",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Berampu (Brampu)",
                "kelurahan": "Karing",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Bukit Lau Kersik",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Gundaling",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Gunung Sitember",
                "kelurahan": "Batu Gun-Gun",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Berampu (Brampu)",
                "kelurahan": "Berampu (Brampu)",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Sitinjo",
                "kelurahan": "Sitinjo II",
                "kodepos": "22219"
            },
            {
                "kecamatan": "Berampu (Brampu)",
                "kelurahan": "Banjar Toba",
                "kodepos": "22251"
            },
            {
                "kecamatan": "Sitinjo",
                "kelurahan": "Sitinjo",
                "kodepos": "22219"
            },
            {
                "kecamatan": "Sitinjo",
                "kelurahan": "Sitinjo I",
                "kodepos": "22219"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Bintang",
                "kodepos": "22219"
            },
            {
                "kecamatan": "Sitinjo",
                "kelurahan": "Panji Dabutar (Bako)",
                "kodepos": "22219"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Kalang Simbara",
                "kodepos": "22218"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Sidiangkat",
                "kodepos": "22216"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Bintang Hulu",
                "kodepos": "22217"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Belang Malum",
                "kodepos": "22215"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Kalang",
                "kodepos": "22213"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Kuta Gambir",
                "kodepos": "22214"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Sidikalang Kota",
                "kodepos": "22211"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Batang Beruh",
                "kodepos": "22212"
            },
            {
                "kecamatan": "Sidikalang",
                "kelurahan": "Huta Rakjat",
                "kodepos": "22211"
            }
        ],
        "k461": [
            {
                "kecamatan": "Sitellu Tali Urang Julu",
                "kelurahan": "Ulu Merah",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Traju",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Tinada",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Silima Kuta",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Simberuna",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Tanjung Meriah",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Julu",
                "kelurahan": "Silima Kuta",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Siempat Rube IV",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Siempat Rube I",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Siempat Rube II",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Salak II",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Sibongkaras",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Prongil",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Perolihen",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Salak I",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Perjaga",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Julu",
                "kelurahan": "Pardomuan",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Penanggalan Binanga Boang",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Mbinalun",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Mungkur",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Maholida",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Malum",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Mahala",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Julu",
                "kelurahan": "Lae Langge Namuseng",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Kuta Tinggi",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Kuta Babo",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Siempat Rube",
                "kelurahan": "Kuta Jungak",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Kaban Tengah",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Julu",
                "kelurahan": "Cikaok",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Tinada",
                "kelurahan": "Buluh Tellang",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Salak",
                "kelurahan": "Boangmanalu Salak",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Sitellu Tali Urang Jehe",
                "kelurahan": "Bandar Baru",
                "kodepos": "22272"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Surung Mersada",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Sukaramai",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pagindar",
                "kelurahan": "Sibagindar",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pergetteng Getteng Sengkut",
                "kelurahan": "Simerpara",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Perduhapen",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Pardomuan",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Parpulungan/Parpulungen",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Majanggut II",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pagindar",
                "kelurahan": "Napatalun Perlambuken",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pagindar",
                "kelurahan": "Pagindar",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Majanggut I",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pagindar",
                "kelurahan": "Lae Mbentar",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Kutadame",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Kutasaga",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pergetteng Getteng Sengkut",
                "kelurahan": "Kecupak II",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Kerajaan",
                "kelurahan": "Kuta Mariah/Meriah",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pergetteng Getteng Sengkut",
                "kelurahan": "Kecupak I",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pergetteng Getteng Sengkut",
                "kelurahan": "Aornakan II",
                "kodepos": "22271"
            },
            {
                "kecamatan": "Pergetteng Getteng Sengkut",
                "kelurahan": "Aornakan I",
                "kodepos": "22271"
            }
        ],
        "k462": [
            {
                "kecamatan": "Merek",
                "kelurahan": "Situnggaling",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Sukamandi",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Tongging",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Regaji",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Sibolangit",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Pertibi Lama",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Pertibitembe",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Negeri Tongging",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Pancur Batu",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Pangambaten",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Nagalingga",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Nagara",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Kodon-Kodon",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Merek",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Mulia Rakyat",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Garingging",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Bandar Tongging",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Dokan",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Tanjung Barus",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Merek",
                "kelurahan": "Ajinembah",
                "kodepos": "22173"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Sukanalu",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Talimbaru",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Tangkidik",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Sinaman",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Sukajulu",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Semangat",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Serdang",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Sikab",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Rumamis",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Sarimanis",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Persadanta",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Pertumbuken",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Paribun",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Penampen",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Barus Julu",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Bulan Jahe",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Bulan Julu",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Barus Jahe",
                "kelurahan": "Barus Jahe",
                "kodepos": "22172"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Tigapanah",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Ujung Sampun",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Sukamaju",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Sukapilihen",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Suka Mbayak",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Sukadame",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Sugihen",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Singa",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Suka",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Sampun",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Salit",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Seberaya",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Mulawari",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Melas",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Manuk Mulia",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Lau Riman",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Lepar Samura",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Lambar",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kuta Mbelin",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kutajulu",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kuta Bale",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kuta Kepar",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Kubucolia",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kubu Simbelang",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Bunuraya",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Kacinambun",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Dolat Rayat",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Dolat Rayat",
                "kelurahan": "Bukit",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Ajijulu",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Ajimbelang",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Bertah",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Ajibuhara",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Tiga Panah",
                "kelurahan": "Ajijahe",
                "kodepos": "22171"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Mardingding",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Rimo Bunga",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Tanjung Pamah",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Lau Pengulu",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Lau Solu",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Lau Kasumpat",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Lau Mulgap",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Lau Pakam",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Bandar Purba",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Mardinding",
                "kelurahan": "Kuta Pengkih",
                "kodepos": "22165"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Rambah Tampu",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Tanjung Gunung",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Martelu",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Mbal-Mbal Petarum",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Perbulan",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Lingga Muda",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Lau Baleng",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Lau Peradep",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Lau Peranggunen (Peranggunan)",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Kinangkong",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Kutambelin",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Buluh Pancur",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Laubaleng",
                "kelurahan": "Durin Rugun",
                "kodepos": "22164"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Sugihen",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Sukababo",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Sigenderang",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Namosuro",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Pasar Baru",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Pernantin",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Naga",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Nageri",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Lau Lingga",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Mbetong (Mbetung)",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Kutambelin",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Lau Kidupen",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Kutagugung",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Kidupen",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Keriahen",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Ketawaren",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Juhar Perangin-Angin",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Juhar Tarigan",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Juhar Ginting Sadanioga",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Jandi (Jandi Meriah)",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Juhar Ginting",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Buluh Pancur",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Gunung Juhar",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Bekilang",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Tiga Binanga",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Juhar",
                "kelurahan": "Batu Mamak",
                "kodepos": "22163"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Simolap",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Simpang Pergendangen Perlambe",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Pergendangen",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Pertumbuken",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Limang",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Perbesi",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kutambaru Punti Batu Mama",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Lau Kapur",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Julu",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Raya",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Galoh/Galuh",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Gerat",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuala",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Bangun",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kuta Buara",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Gunung",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Kem Kem",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Bunga Baru",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Tiga Binanga",
                "kelurahan": "Batumanak",
                "kodepos": "22162"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Singgamanik",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Sukarame",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Selakkar",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Sarimunte",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Sarinembah",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Nageri",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Parimbalang",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Pertumbungen",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Kutambaru",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Kutasuah",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Munthe",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Kineppen",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Kutagerat",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Kabantua",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Gurubenua",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Gunung Manumpak",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Gunung Saribu",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Biak Nampe",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Buluh Naman",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Bandar Meriah",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Munte",
                "kelurahan": "Barung Kersap",
                "kodepos": "22161"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Siabang Abang",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Tanjung Merahe",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Ujung Deleng",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Pola Tebu",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Rih Tengah",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Lau Buluh",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Liang Merdeka",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Negeri Jahe",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Kuta Male",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Kuta Buluh Gugung",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Gunung Meriah",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Jinabun",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Kuta Buluh",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Bintang Meriah",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Buah Raya",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Ujung Payung",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Tiga Nderket (Tiganderket)",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Kuta Buluh",
                "kelurahan": "Amburudi (Mburidi)",
                "kodepos": "22155"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Tanjung Pulo",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Temburun",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Tanjung Mbelang",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Tanjung Merawa",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Suka Tendel",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Susuk",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Selandi",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Suka Meriah",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Penampen",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Perbaji",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Rimokayu",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Nari Gunung Dua",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Nari Gunung Satu",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Payung",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Mardingding",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Kutambaru",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Jandi Meriah",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Kuta Kepar",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Kutagaluh",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Gurukinayan",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Tiganderket",
                "kelurahan": "Gunung Merlawan",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Batukarang",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Payung",
                "kelurahan": "Cimbang",
                "kodepos": "22154"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Tiga Pancur",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Torong",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Ujung Teran",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Surbakti",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Sukatepu",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Simacem",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Suka Nalu",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Suka Ndebi",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Sigarang Garang",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Serumbia",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Sada Perarih",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Semangat",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Semangat Gunung",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Ndokum Siroga",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Perteguhen",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Pintu Besi",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Nang Belawan",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Ndeskati",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Lingga Julu",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Lingga Julu",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Naman",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Kuta Tengah",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Lingga",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Kuta Tonggal",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Kuta Gugung",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Kuta Mbelin",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Kuta Rayat",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Kebayaken",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Jeraya",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Gung Pinto",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Jaranguda",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Gongsol",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Gamber",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Gajah",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Cinta Rayat",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Merdeka",
                "kelurahan": "Deram",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Bulan Baru",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Berastepu",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Beganding",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Nama Teran",
                "kelurahan": "Bekerah",
                "kodepos": "22153"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Tambak Lau Mulgap I",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Tambak Lau Mulgap II",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Rumah Brastagi/Berastagi",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Sempajaya",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Gurusinga",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Lau Gumba",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Raya",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Gundaling II",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Gundaling I",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Padang Mas",
                "kodepos": "22115"
            },
            {
                "kecamatan": "Brastagi (Berastagi)",
                "kelurahan": "Doulu (Daulu)",
                "kodepos": "22152"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Kampung Dalam",
                "kodepos": "22113"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Lau Cimba",
                "kodepos": "22114"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Samura",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Sumber Mufakat",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Gung Negeri",
                "kodepos": "22112"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Rumah Kabanjahe",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Ketaren",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Lausimono",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Kaban",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Kacaribu",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Kandibata",
                "kodepos": "22111"
            },
            {
                "kecamatan": "Kabanjahe",
                "kelurahan": "Gung Leto",
                "kodepos": "22111"
            }
        ],
        "k463": [
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Teluk Pulai Luar",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Tanjung Leidong",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Teluk Pulai Dalam",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Pangkalan Lunang",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Simandulang",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Teluk Piai",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Air Hitam",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kuala Ledong (Kualuh Leidong)",
                "kelurahan": "Kelapa Sebatang",
                "kodepos": "21475"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Tanjung Mangedar",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Teluk Binjai",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Kuala Bangka",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Sei/Sungai Apung",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Sei/Sungai Sentang",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Hilir",
                "kelurahan": "Kampung Mesjid",
                "kodepos": "21474"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Suka Rame Baru",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Suka Rame",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Sono Martani",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Simangalam",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Sialang Taji",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Siamporik",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Sidua Dua",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Pulo Dogom",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Perkebunan Mambang/Membang Muda",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Perkebunan Kanopan Ulu",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Perkebunan Labuhan Haji",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Perkebunan Londut",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Parpaudangan",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Perkebunan Hanna",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Kuala Beringin",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Hasang",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Lobu Huala",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Gunting Saga",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Gunung Melayu",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Damuli Kebun (Perkebunan Damuli)",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Damuli Pekan",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Selatan",
                "kelurahan": "Bandar Lama",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Aek Kanopan Timur",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Kualuh Hulu",
                "kelurahan": "Aek Kanopan",
                "kodepos": "21457"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Ujung Padang",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Simonis",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Terang Bulan",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Sidomulyo",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Sibito",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Purwo Rejo",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Poldung",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Rombisan",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Perkebunan Padang Halaban",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Perkebunan Panigoran",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Perkebunan Aek Pamingke",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Perkebunan Halim B",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Padang Maninjau",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Pangkalan",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Karang Ayer (Anyar)",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Kampung Yaman",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Bandar Selamat",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Bandar Durian",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Bandar Durian II",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Aek Kuo",
                "kelurahan": "Aek Korsik",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Sungai Raja",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Aek Natas",
                "kelurahan": "Adian Torop",
                "kodepos": "21455"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Silumajang",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Simpang Marbau",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Pematang",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Perkebunan Berangir",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Pulo Jantan",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Meranti Omas",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Pasang Lela",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Hatapang",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Kampung Pajak",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Bangun Rejo",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Batu Tunggal",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Sumber Mulyo",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Tubiran",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Na IX-X",
                "kelurahan": "Aek Kota Batu",
                "kodepos": "21454"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Sipare Pare Hilir",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Sipare Pare Tengah",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Pulo Bargot",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Simpang Empat",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Perkebunan Pernantian",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Perkebunan Marbau Selatan",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Perkebunan Milano",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Marbau Selatan",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Perkebunan Brussel",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Lobu Rampah",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Marbau",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Belongkut",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Bulung Gihit (Bulungihit)",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Babussalam",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Aek Tapa",
                "kodepos": "21452"
            },
            {
                "kecamatan": "Marbau",
                "kelurahan": "Aek Hitetoras",
                "kodepos": "21452"
            }
        ],
        "k464": [
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Wonosari",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Sanggul",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Sanggul",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Tawar",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Penggantungan",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Berombang",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Lumut",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Telaga Suka",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Hilir",
                "kelurahan": "Sei Baru",
                "kodepos": "21473"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Sei Rakyat",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Sei Siati/Siarti",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Selat Beting",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Sei Nahodaris",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Sei Plancang",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Pasar Tiga",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Sei Merdeka",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Bagan Bilah",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Tengah",
                "kelurahan": "Labuhan Bilik",
                "kodepos": "21472"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Tanjung Sarang Elang",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Teluk Sentosa",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Tanjung Haloban",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Sidomulyo",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Sijawijawi (Sei Jawi Jawi)",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Selat Besar",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Sei Tarolat",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Sei Tampang",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Sei Kasih",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Sei Sentosa",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Perkebunan Sennah",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Perkebunan Negeri Lama",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Negeri Lama Seberang",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Perkebunan Bilah",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Negeri Lama",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Negeri Baru",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Maranti/Meranti Paham",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Cinta Makmur",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Bilah Hilir",
                "kelurahan": "Kampung Bilah",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Panai Hulu",
                "kelurahan": "Ajamu (Perkebunan IV Ajamu)",
                "kodepos": "21471"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Tebing Tinggi Pangkatan",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Perkebunan Pangkatan",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Sennah (Kampung Sennah)",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Sidorukun",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Kampung Padang",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Pangkatan",
                "kelurahan": "Pangkatan (Kampung Pangkatan)",
                "kodepos": "21462"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Tanjung Tiram (Siram)",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 5/Lima Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 6/Enam Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 3/Tiga Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 4/Empat Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 1/Satu Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "S 2/Dua Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Perbaungan",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Pondok Batu",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 8/Delapan Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Pematang Slang (Seleng)",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 6/Enam Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 7/Tujuh Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 4/Empat Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 5/Lima Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 2/Dua Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 3/Tiga Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Meranti",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "N 1/Satu Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Kampung Dalam",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Lingga Tiga",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Gunung Selamat",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Bandar Tinggi",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Bilah Hulu",
                "kelurahan": "Emplasmen Aek Nabara",
                "kodepos": "21451"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Sioldengan",
                "kodepos": "21428"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Urung Kompas",
                "kodepos": "21429"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Sigambal",
                "kodepos": "21425"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Perdamean",
                "kodepos": "21426"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Danobale",
                "kodepos": "21427"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Lobu Sona",
                "kodepos": "21423"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Sidorejo",
                "kodepos": "21424"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Ujung Bandar",
                "kodepos": "21422"
            },
            {
                "kecamatan": "Rantau Selatan",
                "kelurahan": "Bakaran Batu",
                "kodepos": "21421"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Aek Paing",
                "kodepos": "21419"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Pulo Padang",
                "kodepos": "21419"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Bina Raga (Rinaraga)",
                "kodepos": "21416"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Cendana",
                "kodepos": "21417"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Kartini",
                "kodepos": "21418"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Kampung Baru",
                "kodepos": "21415"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Sirandorung",
                "kodepos": "21414"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Siringo-Ringo",
                "kodepos": "21413"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Padang Bulan",
                "kodepos": "21414"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Tebing Linggahara Baru",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Rantauprapat",
                "kodepos": "21412"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Tanjung Medan",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Tebing Linggahara",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Sibargot",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Perkebunan Afdeling I Rantau Prapat",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Perkebunan Afdeling II Rantau Prapat",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Rantau Utara",
                "kelurahan": "Padang Matingi",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Perkebunan Aek Buru Selatan",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Bandar Kumbul",
                "kodepos": "21411"
            },
            {
                "kecamatan": "Bilah Barat",
                "kelurahan": "Janji",
                "kodepos": "21411"
            }
        ],
        "k465": [
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Ujung Gading",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Sabungan",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Sampean",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Parimburan",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Marsonja",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Langga Payung",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Huta Godang",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Hajoran",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Sei/Sungai Kanan",
                "kelurahan": "Batang Nadenggan",
                "kodepos": "21465"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Torganda",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Torgamba",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Teluk Rampah",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Sungai Rumbia (Perkebunan Sei Rumbia)",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Sungai Meranti",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Sosopan",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Sisumut",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Simatahari",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Rasau",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Perkebunan Normark",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Pinang Dame",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Perkebunan Nagodang",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Pasir Tuntung",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Pangarungan",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Mampang",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Kota Pinang",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kota Pinang",
                "kelurahan": "Hadundung",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Bunut",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Beringin Jaya",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Bukit Tujuh",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Bangai",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Aek Batu",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Aek Raso",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Torgamba",
                "kelurahan": "Asam Jawa",
                "kodepos": "21464"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Teluk Panji IV",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Teluk Panji II",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Teluk Panji III",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Teluk Panji (Kampung Teluk Panji)",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Teluk Panji I",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perlabian (Kampung Perlabian)",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Tanjung Medan",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perkebunan Tolan (Pekan Tolan)",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perkebunan Tolan I/II",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perkebunan Perlabian",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perkebunan Teluk Panji",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Air Merah",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Kampung Rakyat",
                "kelurahan": "Perkebunan Batang Seponggol",
                "kodepos": "21463"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Suka Dame",
                "kodepos": "21461"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Ulumahuam",
                "kodepos": "21461"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Rintis",
                "kodepos": "21461"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Binanga Dua",
                "kodepos": "21461"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Mandala Sena",
                "kodepos": "21461"
            },
            {
                "kecamatan": "Silangkitang",
                "kelurahan": "Aek Goti",
                "kodepos": "21461"
            }
        ],
        "k466": [
            {
                "kecamatan": "Sei Kepayang Timur",
                "kelurahan": "Sei Tempurung",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Tualang Pandau",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Timur",
                "kelurahan": "Sei Sembilang",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Serindan",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Timur",
                "kelurahan": "Sei Pasir",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Sei Paham",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Timur",
                "kelurahan": "Sei Lunang",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Lendir",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Nangka",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Kepayang Kiri",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Sei Kepayang Kanan",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Sei Kepayang Tengah",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Timur",
                "kelurahan": "Sarang Helang",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang Barat",
                "kelurahan": "Sei Jawi-Jawi",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Bangun Baru",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Perbangunan",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Sei Kepayang",
                "kelurahan": "Pertahanan",
                "kodepos": "21381"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Sei/Sungai Apung Jaya",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Sei/Sungai Apung",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Kapias Batu VIII",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Pematang Sungai/Sei Baru",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Bagan Asahan Baru",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Bagan Asahan Pekan",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Asahan Mati",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Tanjung Balai",
                "kelurahan": "Bagan Asahan",
                "kodepos": "21352"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Tangga",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Situnjak",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Rahuning I",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Rahuning II",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Rahuning",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Perkebunan Padang Pulau",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Perkebunan Bandar Pulau",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Perkebunan Bandar Selamat",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Perkebunan Gunung Melayu",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Perkebunan Aek Nagaga",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Perkebunan Aek Tarum",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Mekar Marjanji",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Padang Pulau",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Huta Rao",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Lobu Rappa",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Marjanji Aceh",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Gunung Melayu",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Gonting Malaha",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Gunung Berkat",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Buntu Maraja",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Gajah Sakti",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Bandar Pulau Pekan",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Rahuning",
                "kelurahan": "Batu Anam",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Bandar Pulau",
                "kelurahan": "Aek Nagali",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Aek Songsongan",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Tunggul Empat Lima (45)",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Songsongan",
                "kelurahan": "Aek Bamban",
                "kodepos": "21274"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Sengon Sari",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Sei Piring",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Pulau Rakyat Tua",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Rawa Sari",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Persatuan",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Pulau Rakyat Pekan",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Padang Sipirok",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Ofa Padang Mahondang",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Orika",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Padang Mahondang",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Manis",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Lobbu Jior (Lobu Jiur)",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Ledong Barat",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Ledong Timur",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Bangun",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Pulau Rakyat",
                "kelurahan": "Baru",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Aek Nabuntu",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Alang Bombon (Bonbon)",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Aek Loba",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Aek Loba Afdeling I",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Kuasan",
                "kelurahan": "Aek Loba Pekan",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Aek Korsik",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Aek Ledong",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Tanjung Asri",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Aek Ledong",
                "kelurahan": "Aek Bange",
                "kodepos": "21273"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Tanjung Alam",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Sei/Sungai Alim Ulu",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Sijabut Teratai",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Sei Kamah II",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Sei Kamah Baru",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Sei Kamah II",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Pulau Pule",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Sei Alim Hasak",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Pinanggiripan",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Perkebunan Sei Dadap I-II",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Perkebunan Sei Dadap III-IV",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Perkebunan Air Batu III-IV",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Perkebunan Pulahan",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Perkebunan Air Batu I-II",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Pasiran",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Hessa Perlompongan",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Sei Dadap",
                "kelurahan": "Bahung Sibatu-Batu",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Danau Sijabut",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Hessa Air Genting",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Air Teluk Hessa",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Teluk Dalam",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Air Batu",
                "kelurahan": "Air Genting",
                "kodepos": "21272"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Simpang Empat",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sipaku Area",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei Dua Hulu",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Sei/Sungai Lama",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Silomlom",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Pulau Tanjung",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Perkebunan Teluk Dalam",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Pulau Maria",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Perkebunan Hessa",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Perkebunan Suka Raja",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Mekar Tanjung",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Simpang Empat",
                "kelurahan": "Anjung Gadang",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Tanah Rakyat",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Teluk Dalam",
                "kelurahan": "Air Teluk Kiri",
                "kodepos": "21271"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Taman Sari",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Sukajadi",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Suka Damai",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Suka Damai Barat",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Suka Makmur",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Serdang",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Sidomulyo",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Sei Beluru",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Rawang Pasar VI",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Rawang Pasar IV",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Rawang Pasar V",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Rawang Baru",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Rawang Lama",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Pulo Bandring",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Pondok Bungur",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Perhutaan Silau",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Rawang Panca Arga",
                "kelurahan": "Panca Arga",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Perkebunan Sei Balai (Baleh)",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Meranti",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Gedangan",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Gajah",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Pulo Bandring",
                "kelurahan": "Bunut Seberang",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Subur",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Silau Laut",
                "kelurahan": "Silo Lama",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Meranti",
                "kelurahan": "Air Putih",
                "kodepos": "21264"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Punggulan",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Silau Laut",
                "kelurahan": "Silo Baru",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Silau Laut",
                "kelurahan": "Silo Bonto",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Silau Laut",
                "kelurahan": "Lubuk Palas",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Pasar Lembu",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Banjar",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Binjai Serbangan",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Silau Laut",
                "kelurahan": "Bangun Sari",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Suka Makmur",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Air Joman",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Air Joman",
                "kelurahan": "Air Joman Baru",
                "kodepos": "21263"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Sei Nadoras",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Silau Jawa",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Huta Bagasan",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Huta Padang",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Sei Kopas",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Bandar Pasir Mandoge",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Bandar Pasir Mandoge",
                "kelurahan": "Gotting Sidodadi",
                "kodepos": "21262"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Tinggi Raja",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Setia Janji",
                "kelurahan": "Urung Pane",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Teladan",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Terusan Tengah",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Sionggang",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Setia Janji",
                "kelurahan": "Silau Maraja",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Sumber Harapan",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Sidomulyo",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Setia Janji",
                "kelurahan": "Sei Silau Tua",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Sei Silau Timur",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Prapat Janji",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Setia Janji",
                "kelurahan": "Sei Silau Barat",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Piasa Ulu",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Perkebunan Sei Silau",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Tinggi Raja",
                "kelurahan": "Padang Sari",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Mekar Sari",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Lestari",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Karya Ambalutu",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Buntu Pane",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Setia Janji",
                "kelurahan": "Bangun Sari",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Buntu Pane",
                "kelurahan": "Ambalutu",
                "kodepos": "21261"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Karang Anyer",
                "kodepos": "21229"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Selawan",
                "kodepos": "21228"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Siumbut Baru",
                "kodepos": "21226"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Kedai Ledang",
                "kodepos": "21227"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Siumbut-Umbut",
                "kodepos": "21225"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Mutiara",
                "kodepos": "21223"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Sentang",
                "kodepos": "21224"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Kisaran Timur",
                "kodepos": "21222"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Teladan",
                "kodepos": "21222"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Kisaran Naga",
                "kodepos": "21219"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Gambir Baru",
                "kodepos": "21221"
            },
            {
                "kecamatan": "Kisaran Timur Kota",
                "kelurahan": "Lestari",
                "kodepos": "21221"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Sendang Sari",
                "kodepos": "21218"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Tegal Sari",
                "kodepos": "21218"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Sidomukti",
                "kodepos": "21217"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Kisaran Baru",
                "kodepos": "21216"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Mekar Baru",
                "kodepos": "21216"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Kisaran Barat",
                "kodepos": "21214"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Kisaran Kota",
                "kodepos": "21215"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Tebing Kisaran",
                "kodepos": "21215"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Sidodadi",
                "kodepos": "21212"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Sei Renggas",
                "kodepos": "21213"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Bunut Barat",
                "kodepos": "21211"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Dadi Mulyo",
                "kodepos": "21212"
            },
            {
                "kecamatan": "Kisaran Barat Kota",
                "kelurahan": "Bunut",
                "kodepos": "21211"
            }
        ],
        "k467": [
            {
                "kecamatan": "Datuk Bandar",
                "kelurahan": "Pahang",
                "kodepos": "21369"
            },
            {
                "kecamatan": "Datuk Bandar Timur",
                "kelurahan": "Bunga Tanjung",
                "kodepos": "21367"
            },
            {
                "kecamatan": "Datuk Bandar",
                "kelurahan": "Pantai Johor",
                "kodepos": "21368"
            },
            {
                "kecamatan": "Datuk Bandar",
                "kelurahan": "Sirantau",
                "kodepos": "21368"
            },
            {
                "kecamatan": "Datuk Bandar Timur",
                "kelurahan": "Semula Jadi",
                "kodepos": "21365"
            },
            {
                "kecamatan": "Datuk Bandar Timur",
                "kelurahan": "Pulau Simardan",
                "kodepos": "21366"
            },
            {
                "kecamatan": "Datuk Bandar Timur",
                "kelurahan": "Selat Tanjung Medan",
                "kodepos": "21364"
            },
            {
                "kecamatan": "Datuk Bandar Timur",
                "kelurahan": "Selat Lancang",
                "kodepos": "21364"
            },
            {
                "kecamatan": "Datuk Bandar",
                "kelurahan": "Gading",
                "kodepos": "21362"
            },
            {
                "kecamatan": "Datuk Bandar",
                "kelurahan": "Sijambi",
                "kodepos": "21361"
            },
            {
                "kecamatan": "Sei Tualang Raso",
                "kelurahan": "Keramat Kubah",
                "kodepos": "21344"
            },
            {
                "kecamatan": "Sei Tualang Raso",
                "kelurahan": "Sei Raja",
                "kodepos": "21345"
            },
            {
                "kecamatan": "Sei Tualang Raso",
                "kelurahan": "Muara Sentosa",
                "kodepos": "21342"
            },
            {
                "kecamatan": "Sei Tualang Raso",
                "kelurahan": "Sumber Sari",
                "kodepos": "21343"
            },
            {
                "kecamatan": "Sei Tualang Raso",
                "kelurahan": "Pasar Baru",
                "kodepos": "21341"
            },
            {
                "kecamatan": "Teluk Nibung",
                "kelurahan": "Beting Kwala/Kuala Kapias",
                "kodepos": "21335"
            },
            {
                "kecamatan": "Teluk Nibung",
                "kelurahan": "Pematang Pasir",
                "kodepos": "21333"
            },
            {
                "kecamatan": "Teluk Nibung",
                "kelurahan": "Sei Merbau",
                "kodepos": "21334"
            },
            {
                "kecamatan": "Teluk Nibung",
                "kelurahan": "Kapias Pulau Buaya",
                "kodepos": "21331"
            },
            {
                "kecamatan": "Teluk Nibung",
                "kelurahan": "Perjuangan",
                "kodepos": "21332"
            },
            {
                "kecamatan": "Tanjung Balai Utara",
                "kelurahan": "Matahalasan",
                "kodepos": "21325"
            },
            {
                "kecamatan": "Tanjung Balai Utara",
                "kelurahan": "Tanjungbalai Kota IV",
                "kodepos": "21322"
            },
            {
                "kecamatan": "Tanjung Balai Utara",
                "kelurahan": "Sejahtera",
                "kodepos": "21323"
            },
            {
                "kecamatan": "Tanjung Balai Utara",
                "kelurahan": "Kuala Silau Bestari",
                "kodepos": "21324"
            },
            {
                "kecamatan": "Tanjung Balai Utara",
                "kelurahan": "Tanjungbalai Kota III",
                "kodepos": "21321"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Karya",
                "kodepos": "21314"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Indra Sakti",
                "kodepos": "21315"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Pantai Burung",
                "kodepos": "21316"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Tanjungbalai Kota II",
                "kodepos": "21312"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Perwira",
                "kodepos": "21313"
            },
            {
                "kecamatan": "Tanjung Balai Selatan",
                "kelurahan": "Tanjungbalai Kota I",
                "kodepos": "21311"
            }
        ],
        "k468": [
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Tanjung Sigoni",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Sidomulyo",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Sei Rakyat",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Sei Raja",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Sei Buah Keras",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pematang Nibung",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pematang Cengkering",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pangkalan Dodek Baru",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pakam Raya Selatan",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pangkalan Dodek",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pakam Raya",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pakam",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Pagurawan",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Nenas Siam",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Medang Baru",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Medang",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Mandarsah",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Lalang",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Durian",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Cengkering Pekan",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Medang Deras",
                "kelurahan": "Aek Nauli",
                "kodepos": "21258"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Tanjunggading",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Tanjung Prapat/Parapat",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Tanjung Seri",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Simpang Kopi",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Tanjung Kasau",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Simodong",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Sei Suka Deras",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Perkebunan Tanjung Kasau",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Sei Simujur",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Pematang Kuning",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Perkebunan Sipare-Pare",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Pelanggiran Laut Tador",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Pematang Jering",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Laut Tador",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Mekar Sari",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Kwala/Kuala Indah",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Kwala/Kuala Tanjung",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Dwi Sri",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Kandangan",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Titi Payung",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Sei Suka",
                "kelurahan": "Brohol",
                "kodepos": "21257"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanjung Kubah",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanjung Muda",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanjungmulya",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanjung Harapan",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Sukaraja",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanah Merah",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Tanah Rendah",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Perkotaan",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Sipare-Pare",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Pasar Lapan",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Pematang Panjang",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Kampung Kelapa",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Limau Sundai",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Indrasakti",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Aras",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Air Putih",
                "kelurahan": "Indrapura Kota",
                "kodepos": "21256"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Sumber Rejo",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Titi Merah",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Titi Putih",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Sumber Makmur",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Sumber Padi",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Pulau Sejuk",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Simpang Dolok",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Simpang Gambus",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perupuk",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Tanah Itam Hilir",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Tanah Itam Hulu",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Tanah Gambus",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Kwala Gunung",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Lima Puluh",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Limau Manis",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Pematang Tengah",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Perkebunan Dolok",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Mangkai Lama",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Pasir Permit",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Pematang Panjang",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Lubuk Hulu",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Kwala/Kuala Gunung",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Mangkai Baru",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Empat Negeri",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Lima Puluh Kota",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Gambus Laut",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Lubuk Besar",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Guntung",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Lubuk Cuik",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Barung-Barung",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Gunung Bandung",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Bulan-Bulan",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Cahaya Pardomuan",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Air Hitam",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Limapuluh",
                "kelurahan": "Antara",
                "kodepos": "21255"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Petatal",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Sei Muka",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Sumber Tani",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Perkebunan Petatal",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Perkebunan Tanah Datar",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Padang Genting",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Gunung Rante",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Pahang",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Indra Yaman",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Panjang",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Karang Baru",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Labuhan Ruku",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Dahari Selebar",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Mekar Baru",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Glugur Makmur",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Mesjid Lama",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Benteng",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Binjai Baru",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Dahari Indah",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Ujung Kubu",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Talawi",
                "kelurahan": "Bangun Sari",
                "kodepos": "21254"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Tali Air Permai",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Tanjungmulia",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Tanjungtiram",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Sei Mentaram",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Suka Jaya",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Suka Maju",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Pahlawan",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Pematang Rambe",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Kapal Merah",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Lima Laras",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Mekar Laras",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Jati Mulia",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Kampung Lalang",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bandar Sono",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bogak",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Guntung",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bagan Dalam",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bandar Rahmat",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bagan Baru",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Tanah Timbul",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Tanjung Tiram",
                "kelurahan": "Bagan Arya",
                "kodepos": "21253"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Suko Rejo",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Suka Ramai",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Sei Balai",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Siajam",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Sidomulio",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Perkebunan Sei Balai",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Perkebunan Sei Bejangkar",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Mekar Baru",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Mekar Mulio",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Perjuangan",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Durian",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Kwala Sikasim",
                "kodepos": "21252"
            },
            {
                "kecamatan": "Sei Balai",
                "kelurahan": "Benteng Jaya",
                "kodepos": "21252"
            }
        ],
        "k469": [
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Ujung Padang",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Tinjoan (Tinjowan)",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Taratak Nagodang",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Teluk Tapian",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Sordang Bolon",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Tanjung Rapuan",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Sei Merbou",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Siringan Ringan",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Riah Poso",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Sayur Matinggi",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Kampung Lalang",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Pagar Bosi",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Pulo Pitu Marihat",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Dusun Ulu",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Huta Parik",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Bangun Sordang",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Ujung Padang",
                "kelurahan": "Aek Ger Ger Sidodadi",
                "kodepos": "21187"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Talun Rejo",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Wonorejo",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Sipolha Horisan",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Talun Madear",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Sihaporas",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Simantin",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Sarimattin",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Sait Buttu Saribu",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Purba Ganda",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Purwosari",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Pematang Bandar",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Pematang Sidamanik",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Pematang Tambun Raya",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Pardomuan Nauli",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Kerasaan II",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Mariah Bandar",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Kandangan",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Kerasaan I",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Jorlang Huluan",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Bandar",
                "kelurahan": "Bandar Manis",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Gorak",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Pematang Sidamanik",
                "kelurahan": "Bandar Manik",
                "kodepos": "21186"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Timbaan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Sugarang Bayu",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Tanjung Hataran",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Perlanaan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Sido Tani",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Perdagangan I",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Perdagangan II",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Perdagangan III",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pematang Kerasaan Rejo",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Pematang Kerasaan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Partimbalan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Nagori Bandar",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Panombean Baru",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Naga Soppa",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Naga Jaya II",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Naga Jaya I",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Lias Baru",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Laras",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Gunung Serawan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Bandar Tongah",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Dolok Parmonangan",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Bandar Silou",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Bandar Tinggi",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bandar Rakyat",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Bandar Rejo",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bandar Pulo",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Bandar Masilam",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Masilam",
                "kelurahan": "Bandar Masilam II",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Bandar Betsy II",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Bandar Jawa",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Bah Gunung",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Bandar Betsy I",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bandar Huluan",
                "kelurahan": "Bandar Betsy I",
                "kodepos": "21184"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Telun/Talun Saragih",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Tempel Jaya",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Sei Torop",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Sidomulyo",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Teladan",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Nanggar Bayu",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Parbutaran",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Sei Mangkei",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Mayang",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Mekar Rejo",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Marihat Butar",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Marihat Tanjung",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Bosar Maligas",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Dusun Pengkolan",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Gunung Bayu",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Adil Makmur",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Bosar Maligas",
                "kelurahan": "Boluk",
                "kodepos": "21183"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Raja Maligas I",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Silak Kidir",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Marihat Mayang",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Pulo Bayu",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Raja Maligas",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Mancuk",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Mariah Hombang",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Maligas Bayu",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Huta Bayu",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Jawa Baru",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Bosar Bayu",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Dolok Sinumbah",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Tanjung Pasir",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Totap Majawa",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Huta Bayu Raja",
                "kelurahan": "Bahal Batu",
                "kodepos": "21182"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Pematang Tanah Jawa",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Panembean Marjanji (Panambean Marjandi)",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Parbalogan",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Pardamean Asih",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Muara Mulia",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Pagar Jambi",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Marubun Jaya",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Mekar Mulia",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Maligas Tongah",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Marubun Bayu",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Balimbingan",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Bayu Bagasan",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Bosar Galugur",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Bah Kisat",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Baja Dolok",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Baliju",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Bah Jambi II",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Tanah Jawa",
                "kelurahan": "Bah Jambi III",
                "kodepos": "21181"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Tumorang",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Tonduhan",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Girsang Sipangan Bolon",
                "kelurahan": "Tiga Raja",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Syahkuda Bayu (Sah Kuda Bayu)",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Tangga Batu",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Girsang Sipangan Bolon",
                "kelurahan": "Sipangan Bolon",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Silulu",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Silau Malela",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Silau Bayu",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Girsang Sipangan Bolon",
                "kelurahan": "Sibaganding",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Serapuh",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Saribu Asih",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Senio",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Pematang Gajing",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Pematang Syahkuda (Sah Kuda)",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Rabuhit",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Pematang Asilum",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Parhundalian Jawadipar",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Girsang Sipangan Bolon",
                "kelurahan": "Parapat",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Haranggaol Horison",
                "kelurahan": "Nagori Sihalpe",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Haranggaol Horison",
                "kelurahan": "Nagori Purba Harison/Harisan",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Haranggaol Horison",
                "kelurahan": "Nagori Purba Pasir",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Haranggaol Horison",
                "kelurahan": "Nagori Purba Saribu",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Marihat Bukit",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Nagori Malela",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Karang Sari",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Lingga",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Margo Muliyo",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Karang Anyer",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Karang Rejo",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Jawa Tongah II",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Jawa Tongah",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Hutadipar",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Haranggaol Horison",
                "kelurahan": "Haranggaol",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Gajing",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Girsang Sipangan Bolon",
                "kelurahan": "Girsang",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Buttu Turunan (Buntu Turunan)",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Dolok Malela",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Buttu Bayu (Buntu Bayu)",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Bukit Maraja",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Bandar Siantar",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Hatonduhan",
                "kelurahan": "Bosar Nauli",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Malela",
                "kelurahan": "Bangun",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Gunung Maligas",
                "kelurahan": "Bandar Malela",
                "kodepos": "21174"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Ujung Bondar",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Siatasan",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Tiga Dolok",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Negeri Dolok",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Pondok Buluh",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Marihat Marsada",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Marihat Pondok",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Marihat Raja",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Marihat Dolok",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Dolok Tomuan",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Gunung Mariah",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Lumban Gorat",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Bandar Dolok",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Dolok Panribuan",
                "kelurahan": "Dolok Parmonangan",
                "kodepos": "21173"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Sibunga Bunga",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Tiga Balata",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Panombean Huta Urung",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Pinang Ratus",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Dolok Marlawan",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Jorlang Hataran",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Kasindir",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Bah Sampuran",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Dipar Hataran",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Jorlang Hataran",
                "kelurahan": "Bah Birong Ulu",
                "kodepos": "21172"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Tiga Bolon",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Mekar Sidamanik",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Sarimatondang",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Sidamanik",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Manik Hataran",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Manik Maraja",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Bahal Gajah",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Birong Ulu Manriah",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Kebun Sayur Bah Butong",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Bah Butong 1",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Bah Butong 2",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Ambarisan",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Sidamanik",
                "kelurahan": "Bah Biak",
                "kodepos": "21171"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Tagur",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Perasmian",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Saran Padang",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Mariah Dolok",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Marubun Lokkung",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Paribuan",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Huta Saing",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Dolok Mariah",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Cingkes",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Dolok Silau",
                "kelurahan": "Bawang",
                "kodepos": "21168"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Ujung Saribu",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Ujung Mariah",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Sinar Baru",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Silimakuta Barat",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Silimakuta",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Siboras",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Saribu Dolok",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Saribujandi/Saribu Janji",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Purba Tua Baru",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Purba Sinombah",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Purba Tua",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Naga Saribu",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Naga Mariah",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Pematang Silima Huta",
                "kelurahan": "Mardinding",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Silimakuta",
                "kelurahan": "Bangun Mariah/Meriah",
                "kodepos": "21167"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Urung Purba",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Tiga Runggu",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Tanoh/Tano Tinggir",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Simpang Panei",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Talun Kondot",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Simbolon Tengkoh",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Seribu Jadi",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Purba Tengah/Tongah",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Purba Sipinggan",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Purba Dolog",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Pematang Purba",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Pematang Panombeian",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Pematang Pane",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Panombeian",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Nagori Bosar",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Marjandi",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Marjandi Pisang",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Huta Raja",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Purba",
                "kelurahan": "Hinalang (Kinalang)",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Panombeian Panei",
                "kelurahan": "Banuh Raya",
                "kodepos": "21165"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Togu Domu Nauli",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Sinaman Labah",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Tigaras",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Silabah Jaya",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Sirube-rube Gunung Purba",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Sibuntuon",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Parjalangan",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Dolok Saribu",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Parik Sabungan",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Bangun Pane",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Dolok Pardamean",
                "kelurahan": "Butu Bayu Panei Raja",
                "kodepos": "21163"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Sondi Raya",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Raya Usang",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Siporkas",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Pematang Raya",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Raya Bayu",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Raya Huluan",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Merek Raya",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Bandar",
                "kelurahan": "Marihat Bandar",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Dolok Huluan",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Bah Bolon",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Bahapal Raya",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Raya",
                "kelurahan": "Dalig Raya",
                "kodepos": "21162"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Simpang Raya Dasma",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Sipoldas",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Simantin Pane Dame",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Simpang Pane Raya",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Sigodang",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Sigodang Barat",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Mekar Sari Raya",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Panei Tongah",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Siborna",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Janggir Leto",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Bangun Rakyat",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Bangun Dasmariah",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Simanabun",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Sinasih",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Panei",
                "kelurahan": "Bah Bolon Tongah",
                "kodepos": "21161"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Silau/Silou Dunia",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Silau/Silou Paribuan",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Pardomuan Bandar",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Pardomuan Tongah",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Nagori Tani",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Negeri Dolok",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Dolok Marawa",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Dolok Saribu Bangun",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Mariah Buttu",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Buttu Bayu",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Damaritang",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Bandar Maruhur",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Bandar Nagori",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Sorba Dolog",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Silou Kahean",
                "kelurahan": "Bah Sarimah",
                "kodepos": "21157"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Sambosar Raya",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Sindar Raya",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Puli Buah",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Panduman",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Durian Banggal",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Gunung Datas",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Bah Tonang",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Bangun Raya",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Amborokan Pane Raya",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Raya Kahean",
                "kelurahan": "Bah Bulian",
                "kodepos": "21156"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Serbelawan",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Silenduk",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Kahean",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Padang Mainu",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Merangir Satu",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Tenera",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Mainu",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Merangir Dua",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Ilir Dua",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolok Ilir Satu",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Bahung Kahean",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Bandar Selamat",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Dolog Kataran (Hataran)",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Bah Tobu",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Dolok Batu Nanggar",
                "kelurahan": "Bahung Huluan",
                "kodepos": "21155"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Purba Sari",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Sinaksak",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Negeri Bayu Muslimin",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Pematang Dolok Kahean",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Nagur Usang",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Naga Dolok",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Dolok Ulu",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Dolok Kahean",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Dolok Maraja",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Tapian Dolok",
                "kelurahan": "Batu Silangit",
                "kodepos": "21154"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Mekar Bahalat",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Moho",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Tanjung Maraja",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Jawa Maraja",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Mariah Jambi",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Bah Joga",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Bahalat Bayu",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Jawa Maraja Bah Jambi",
                "kelurahan": "Bah Jambi I",
                "kodepos": "21153"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Silau Manik",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Sitalasari",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Siantar Estate",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Silampuyang",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Silau Malaha",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Sejahtera",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Pematang Silampuyang",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Pematang Simalungun",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Rambung Merah",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Nusa Harapan",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Pantoan Maju",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Laras Dua",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Lestari Indah",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Marihat Baris",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Dolok Marlawan",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Karang Bangun",
                "kodepos": "21151"
            },
            {
                "kecamatan": "Siantar",
                "kelurahan": "Dolok Hataran",
                "kodepos": "21151"
            }
        ],
        "k470": [
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Kahean",
                "kodepos": "21147"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Baru",
                "kodepos": "21145"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Sukadame",
                "kodepos": "21146"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Bane",
                "kodepos": "21142"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Martoba",
                "kodepos": "21143"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Melayu",
                "kodepos": "21144"
            },
            {
                "kecamatan": "Siantar Utara",
                "kelurahan": "Sigulang Gulang I",
                "kodepos": "21141"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Siopat Suhu",
                "kodepos": "21139"
            },
            {
                "kecamatan": "Siantar Sitalasari",
                "kelurahan": "Bah Kapul",
                "kodepos": "21139"
            },
            {
                "kecamatan": "Siantar Sitalasari",
                "kelurahan": "Bah Sorma",
                "kodepos": "21139"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Tambun Nabolon",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Tanjung Pinggir",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Tanjung Tongah",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Sitalasari",
                "kelurahan": "Setia Negara",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Sumber Jaya",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Sitalasari",
                "kelurahan": "Gurilla",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Naga Pita",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Martoba",
                "kelurahan": "Pondok Sayur",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Sitalasari",
                "kelurahan": "Bukit Sofa/Shofa",
                "kodepos": "21137"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Asuhan",
                "kodepos": "21136"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Kebun Sayur",
                "kodepos": "21134"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Merdeka",
                "kodepos": "21135"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Pahlawan",
                "kodepos": "21132"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Tomuan",
                "kodepos": "21133"
            },
            {
                "kecamatan": "Siantar Timur",
                "kelurahan": "Pardomuan",
                "kodepos": "21131"
            },
            {
                "kecamatan": "Siantar Marimbun",
                "kelurahan": "Simarimbun",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marimbun",
                "kelurahan": "Tong Marimbun",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Parhorasan Nauli",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marimbun",
                "kelurahan": "Nagahuta",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Baringin Pancur Nauli (Bp Nauli)",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Baringin Pancur Nauli (Bp Nauli)",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Mekar Nauli",
                "kodepos": "21129"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Sukaraja",
                "kodepos": "21128"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Pardamean",
                "kodepos": "21128"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Sukamakmur",
                "kodepos": "21128"
            },
            {
                "kecamatan": "Siantar Marimbun",
                "kelurahan": "Marihat Jaya",
                "kodepos": "21128"
            },
            {
                "kecamatan": "Siantar Marihat",
                "kelurahan": "Sukamaju",
                "kodepos": "21127"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Aek Nauli",
                "kodepos": "21126"
            },
            {
                "kecamatan": "Siantar Marimbun",
                "kelurahan": "Pematang Marihat",
                "kodepos": "21127"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Kristen",
                "kodepos": "21124"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Martimbang",
                "kodepos": "21125"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Karo",
                "kodepos": "21122"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Toba",
                "kodepos": "21123"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Dwikora",
                "kodepos": "21118"
            },
            {
                "kecamatan": "Siantar Selatan",
                "kelurahan": "Simalungun",
                "kodepos": "21121"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Timbang Galung",
                "kodepos": "21116"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Proklamasi",
                "kodepos": "21117"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Teladan **",
                "kodepos": "21115"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Simarito",
                "kodepos": "21113"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Sipinggol Pinggol",
                "kodepos": "21114"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Bantan",
                "kodepos": "21111"
            },
            {
                "kecamatan": "Siantar Barat",
                "kelurahan": "Banjar",
                "kodepos": "21112"
            }
        ],
        "k471": [
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Sibulan",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Tanah Besih",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sei Priok (Sungai Periok)",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Sei Serimah",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Penonggol",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Pertapaan",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Paya Pinang",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Penggalangan",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Penggalian",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Paya Pasir",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Paya Mabar",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Naga Kesiangan",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Paya Bagas",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Paya Lombang",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Laut Tador",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Mariah Padang",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Kuta Pinang",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kuta Baru",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Kedai Damar",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Gunung Kataran",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Jambu",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Bahilang",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Syahbandar",
                "kelurahan": "Binjai",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Tebing Tinggi",
                "kelurahan": "Bah Sumbu",
                "kodepos": "20998"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Sei/Sungai Buluh",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Sentang",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Sialang Buah",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Pematang Guntung",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Pematang Kuala",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Pematang Seterak (Strak)",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Pasar Baru",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Pekan Sialang Buah",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Makmur",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Matapao",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Bogak Besar",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Teluk Mengkudu",
                "kelurahan": "Liberia",
                "kodepos": "20997"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Sukajadi",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Pematang Terang",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Pematang Cermai",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Nagur",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Pekan Tanjung Beringin",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Bagan Kuala",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Tanjung Beringin",
                "kelurahan": "Mangga Dua",
                "kodepos": "20996"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Suka Damai",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Tanah Raja",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Sinah Kasih",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Silau Rakyat",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Simpang Empat",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Sei Rejo",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Sei Parit",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Sei Rampah",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Sei Bamban Estate",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Sei Belutu",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Sei Buluh Estate",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Sei Bamban",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Rambung Sialang Hulu",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Rambung Sialang Tengah",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Rambung Sialang Tengah",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Rambung Estate",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Rambung Sialang Hilir",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Pergulaan",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Pon (Kampung Pon)",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Pematang Pelintahan",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Penggalangan",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Firdaus Estate",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Pematang Ganjang",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Gempolan",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Cempedak Lobang",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Sei Rampah",
                "kelurahan": "Firdaus",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Bandar Khalifah",
                "kelurahan": "Pekan Bandar Khalipah",
                "kodepos": "20994"
            },
            {
                "kecamatan": "Sei Bamban",
                "kelurahan": "Bakaran Batu",
                "kodepos": "20995"
            },
            {
                "kecamatan": "Bandar Khalifah",
                "kelurahan": "Kayu Besar",
                "kodepos": "20994"
            },
            {
                "kecamatan": "Bandar Khalifah",
                "kelurahan": "Bandar Tengah",
                "kodepos": "20994"
            },
            {
                "kecamatan": "Bandar Khalifah",
                "kelurahan": "Gelam Sei Sarimah",
                "kodepos": "20994"
            },
            {
                "kecamatan": "Bandar Khalifah",
                "kelurahan": "Juhar (Kampung Juhar)",
                "kodepos": "20994"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Panglong",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Paretokan (Paritokan)",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Pabatu III",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Pabatu VI",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Pabatu I",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Pabatu II",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Nagaraja I",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Limbong",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Mainu Tengah/Tongah",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Kalembak",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Korajim",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Bandarawan",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Dolok Merawan",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Gunung Para II",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Bah Damar",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Afdeling VII Dolok Ilir",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Dolok Merawan",
                "kelurahan": "Afdeling VI Dolok Ilir",
                "kodepos": "20993"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Tinokkah",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Sipispis",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Simalas",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Silau Padang",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Sibarau (Sibangu)",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Serbananti",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Rimbun",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Pispis",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Parlambean",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Naga Raja",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Nagur Pane",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Marjanji",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Marubun",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Gunung Manako",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Gunung Pane",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Mariah Nagur",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Damak Kurat",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Buluh Duri",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Baja Dolok",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Sipispis",
                "kelurahan": "Bartong",
                "kodepos": "20992"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Tanjung Maria",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Tegal Sari",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Ujung Silau",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Tambak Cekur",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Tanjung Harap",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Sarang Torop",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Silau Merawan",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Serba Jadi",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Sarang Ginting",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Pulau Gambar",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Pulau Tagor",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Pekan Kemis",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Pertambatan",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Pekan Dolok Masihul",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Martebing",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Pardomuan",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Kwala/Kuala Bali",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Mala Sori",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Manggis",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Kerapuh",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Kelapa Bajohor/Bajohom",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Kota Tengah",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Huta Nauli",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Karang Tengah",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Durian Puloan",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Havea",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Dolok Manampang",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Dolok Sagala",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Dame",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Blok Sepuluh",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Bukit Cermin Hilir",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Bantan",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Batu 12",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Batu 13",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Baja Ronggi",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Serba Jadi",
                "kelurahan": "Bah Sidua Dua",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Bah Kerapuh",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Ujung Rambung",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Dolok Masihul",
                "kelurahan": "Aras Panjang",
                "kodepos": "20991"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Pantai Cermin Kiri",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Pematang Kasih",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Sementara",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Lubuk Saban",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Naga Kisar",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Pantai Cermin Kanan",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Kota Pari",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Kuala Lama",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Besar 2 Terjun",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Celawan",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Tanjung Putus",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pantai Cermin",
                "kelurahan": "Ara Payung",
                "kodepos": "20987"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Tualang",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Tanah Merah",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Tanjung Buluh",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Sukasari",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Suka Jadi",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Simpang Tiga Pekan",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Suka Beras",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Sei/Sungai Sijenggi",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Senah/Sennah",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Sei/Sungai Buluh",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Sei/Sungai Naga Lawan",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Putuaran/Petuaran Ilir",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Putuaran/Petuaran Ulu",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Pondok Tengah",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Pematang Tatal",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Pondok Tengah",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Melati Satu",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Pegajahan",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Lubuk Rotan",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Melati Dua",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Melati Kebon/Kebun",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Lubuk Cemara",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Lubuk Dendang",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Lidah Tanah",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Lubuk Bayas",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Lestari Dadi",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Kota Galuh",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Kesatuan",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Jati Mulyo",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Karang Anyar",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Jambur Pulau",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Deli Muda Ilir",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Deli Muda Ulu",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Cinta Air",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Citaman Jernih",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Bingkat",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Bengkel",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Pegajahan",
                "kelurahan": "Bengabing",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Batang Terap",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Perbaungan",
                "kelurahan": "Adolina",
                "kodepos": "20986"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Ujung Negeri Hulu",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Ujung Negeri Kahan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Tarean",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Tapak Meriah",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Sungai Buaya",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Silinda",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Sialtong",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Siahap",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Sei Karih",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Sei Ujan-Ujan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Sarang Ginting Kahan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Sarang Ginting Hulu",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Rubun Dunia",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Pergajahan/Pegajahan Kahan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Perbahingan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Pergajahan/Pegajahan Hulu",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Panombean",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Pamah",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Pagar Manik",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Marihat Dolok",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Kulasar",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Kotarih Baru",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Kotarih Pekan",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Kampung Kristen",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Huta Galuh",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Huta Durian",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Gudang Garam",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Durian Kondot",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Dolok Masango",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Demak/Damak Gelugur",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Damak Tolong Buho",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Bintang Bayu",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Silinda",
                "kelurahan": "Batu Masagi",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Banjaran Godang",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Bandar Pinang Rambe",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Bandar Pinang Kebun/Kebon",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Bandar Magondang (Nagodang)",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Bintang Bayu",
                "kelurahan": "Bandar Negeri",
                "kodepos": "20984"
            },
            {
                "kecamatan": "Kotarih",
                "kelurahan": "Bandar Bayu",
                "kodepos": "20984"
            }
        ],
        "k472": [
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Jati Utomo",
                "kodepos": "20749"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Cengkeh Turi",
                "kodepos": "20747"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Jati Makmur",
                "kodepos": "20748"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Damai",
                "kodepos": "20745"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Jati Karya",
                "kodepos": "20746"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Pahlawan",
                "kodepos": "20743"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Kebun Lada",
                "kodepos": "20744"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Sumber Karya",
                "kodepos": "20737"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Jati Negara",
                "kodepos": "20741"
            },
            {
                "kecamatan": "Binjai Utara",
                "kelurahan": "Nangka",
                "kodepos": "20742"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Dataran Tinggi",
                "kodepos": "20736"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Sumber Mulyo Rejo",
                "kodepos": "20735"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Mencirim",
                "kodepos": "20733"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Tunggurono",
                "kodepos": "20734"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Tanah Tinggi",
                "kodepos": "20731"
            },
            {
                "kecamatan": "Binjai Timur",
                "kelurahan": "Timbang Langkat",
                "kodepos": "20732"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Pujidadi",
                "kodepos": "20727"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Bhakti Karya",
                "kodepos": "20728"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Tanah Seribu",
                "kodepos": "20726"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Tanah Merah",
                "kodepos": "20725"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Rambung Barat",
                "kodepos": "20723"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Binjai Estate",
                "kodepos": "20724"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Rambung Timur",
                "kodepos": "20721"
            },
            {
                "kecamatan": "Binjai Selatan",
                "kelurahan": "Rambung Dalam",
                "kodepos": "20722"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Suka Maju",
                "kodepos": "20719"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Payaroba",
                "kodepos": "20718"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Bandar Senembah",
                "kodepos": "20719"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Limau Sundai",
                "kodepos": "20716"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Limau Mungkur",
                "kodepos": "20717"
            },
            {
                "kecamatan": "Binjai Barat",
                "kelurahan": "Sukaramai",
                "kodepos": "20717"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Tangsi",
                "kodepos": "20714"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Berngam",
                "kodepos": "20715"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Satria",
                "kodepos": "20714"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Kartini",
                "kodepos": "20713"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Setia",
                "kodepos": "20713"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Binjai",
                "kodepos": "20712"
            },
            {
                "kecamatan": "Binjai Kota",
                "kelurahan": "Pekan Binjai",
                "kodepos": "20711"
            }
        ],
        "k473": [
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Tambangan Hulu",
                "kodepos": "20636"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Satria",
                "kodepos": "20636"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Deblod Sundoro",
                "kodepos": "20636"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Bagelen",
                "kodepos": "20634"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Damar Sari",
                "kodepos": "20636"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Tebing Tinggi Lama",
                "kodepos": "20632"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Rambung",
                "kodepos": "20633"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Tebing Tinggi",
                "kodepos": "20631"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Pasar Gambir",
                "kodepos": "20628"
            },
            {
                "kecamatan": "Padang Hilir",
                "kelurahan": "Tambangan",
                "kodepos": "20631"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Mandailing",
                "kodepos": "20626"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Pasar Baru",
                "kodepos": "20627"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Persiakan",
                "kodepos": "20624"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Tualang",
                "kodepos": "20624"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Bandarsono",
                "kodepos": "20625"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Lubuk Raya",
                "kodepos": "20624"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Padang Merbau",
                "kodepos": "20624"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanjung Marulak Hilir",
                "kodepos": "20616"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Lubuk Baru",
                "kodepos": "20622"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanjung Marulak",
                "kodepos": "20616"
            },
            {
                "kecamatan": "Padang Hulu",
                "kelurahan": "Pabatu",
                "kodepos": "20623"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Mekar Sentosa",
                "kodepos": "20616"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Tanjung Marulak Hilir",
                "kodepos": "20616"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Sri Padang",
                "kodepos": "20616"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Durian",
                "kodepos": "20621"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Badak Bejuang",
                "kodepos": "20615"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Lalang",
                "kodepos": "20614"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Rantau Laban",
                "kodepos": "20614"
            },
            {
                "kecamatan": "Tebing Tinggi Kota",
                "kelurahan": "Bandar Utama",
                "kodepos": "20613"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Bandar Sakti",
                "kodepos": "20613"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Teluk Karang",
                "kodepos": "20612"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Bulian",
                "kodepos": "20612"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Pinang Mancung",
                "kodepos": "20612"
            },
            {
                "kecamatan": "Rambutan",
                "kelurahan": "Karya Jaya",
                "kodepos": "20611"
            },
            {
                "kecamatan": "Bajenis",
                "kelurahan": "Berohol",
                "kodepos": "20611"
            }
        ],
        "k474": [
            {
                "kecamatan": "Galang",
                "kelurahan": "Tanjung Siporkis",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Timbang Deli",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Tanjung Gusti",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Tanah Abang",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Tanah Merah",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sei Putih",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Sei Karang",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Pisang Pala",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Pulau Tagor Batu",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Petumbukan",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Petangguhan",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Paya Sampir",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Paya Kuda",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Paya Itik",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Paku",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Kotasan",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Nogorejo",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Kotangan",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Keramat Gajah",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Kelapa Satu",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Jaharum B",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Juhar Baru",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Jaharum A",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Galang Suka",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Galang Kota",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Batu Lokong",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Galang Barat",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Baru Titi Besi",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Galang",
                "kelurahan": "Bandar Kuala",
                "kodepos": "20585"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Ujung Meriah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Simompar (Simempar)",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Pekan Gunung Meriah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Marjanji Tongah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Kuta Tengah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Marjanji Pematang",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Gunung Sinembah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Kuta Bayu",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Gunung Seribu",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Gunung Paribuan",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Gunung Meriah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Gunung Meriah",
                "kelurahan": "Bintang Meriah",
                "kodepos": "20583"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tiga Juhar",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tanjung Timur",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tanjung Raja",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tanjung Muda",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tanjung Bangku (Bampu)",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Tanah Gara Hulu",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Sipinggan",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Sibunga-Bunga Hilir",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Rumah Rih",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Rumah Sumbul",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Rumah Lengo",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Liang Pematang",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Ranggitgit",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Kuta Mbelin",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Liang Muda",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Gunung Manumpak A",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Gunung Manumpak B",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Durian Empat Mbelang",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Durin Tinggung",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Urung Ganjang",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hulu",
                "kelurahan": "Bah Bah Buntu",
                "kodepos": "20582"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Tanjung Purba",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Ujung Rambe",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Sibaganding",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Suka Luae (Lewei)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Sialang",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Rumah Deleng",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Marambun Ujung Jawi",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Perguroan (Perguruan)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Geriahan (Greahan)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Mabar",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Marambun Barat (Marombun Barat)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Cimahi (Cimahe)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Damak Maliho",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Batu Gingging",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Batu Rata",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bangun Purba",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bangun Purba Tengah",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bandar Meriah",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bandar Gunung",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bandar Kuala (Kwala)",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bah Perak",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bah Balua",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Bangun Purba",
                "kelurahan": "Bagerpang",
                "kodepos": "20581"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Sei Tuan",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Sei Tuan",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Regemuk",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Ramonia II (Ramunia Dua)",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Rantau Panjang",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Perkebunan Ramonia (Ramunia)",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Ramonia I (Ramunia Satu)",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Pantai Labu Pekan",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Pematang Biara",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Paluh Sebaji",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Pantai Labu Baru",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Kelambir",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Kubah Sentang",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Durian",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Denai Lama",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Denai Sarang Burung",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Denai Kuala",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Binjai Bakung",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Tumpatan",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Pantai Labu",
                "kelurahan": "Bagan Serdang",
                "kodepos": "20553"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Sidourip",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Sidoarjo Dua Ramunia",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Sidodadi Ramonia (Ramunia)",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Serdang",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Pasar Enam Kuala Namu",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Pasar Lima Kebun Kelapa",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Emplasmen Kuala Namu",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Karang Anyar",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Aras Kabu",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Beringin",
                "kelurahan": "Beringin",
                "kodepos": "20552"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Tanjung Garbus II",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Tanjung Garbus Kampung",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Sukamandi Hulu",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Sumberejo",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Suka Mulia",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Sukamandi Hilir",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Purwodadi",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Sidodadi Batu Delapan",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Pasar Miring (Sidoarjo Satu Psr Miring)",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Perbarakan",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Pagar Merbau I",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Pagar Merbau II",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Jati Rejo",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Bandar Dolok",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Pagar Merbau",
                "kelurahan": "Jati Baru (Sidoarjo I)",
                "kodepos": "20551"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Pasar Melintang",
                "kodepos": "20518"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Sekip",
                "kodepos": "20517"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Pagar Jati",
                "kodepos": "20518"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Cemara",
                "kodepos": "20517"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Syahmad",
                "kodepos": "20515"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Lubuk Pakam Tiga",
                "kodepos": "20516"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Paluh Kemiri",
                "kodepos": "20513"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Petapahan",
                "kodepos": "20514"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Pagar Merbau Tiga",
                "kodepos": "20515"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Lubuk Pakam Pekan",
                "kodepos": "20512"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Lubuk Pakam I-II",
                "kodepos": "20511"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Tanjung Garbus",
                "kodepos": "20511"
            },
            {
                "kecamatan": "Lubuk Pakam",
                "kelurahan": "Bakaran Batu",
                "kodepos": "20511"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Tandam/Tandem Hulu Satu",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Tandam/Tandem Hulu Dua",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Tandam/Tandem Hilir Dua",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Tandam/Tandem Hilir Satu",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Selemak",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Sialang Muda",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Paluh Manan",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Paya Bakung",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Sei/Sungai Baharu",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Paluh Kurau",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Klumpang Kebon",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Kota Datar",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Kota Pantang (Rantang)",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Klambir Lima Kebon",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Klumpang Kampung",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Klambir",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Klambir Lima Kampung",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Desa Lama (Kampung Lama)",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Hamparan Perak",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Hamparan Perak",
                "kelurahan": "Bulu Cina",
                "kodepos": "20374"
            },
            {
                "kecamatan": "Labuhan Deli",
                "kelurahan": "Manunggal",
                "kodepos": "20373"
            },
            {
                "kecamatan": "Labuhan Deli",
                "kelurahan": "Pematang Johar",
                "kodepos": "20373"
            },
            {
                "kecamatan": "Labuhan Deli",
                "kelurahan": "Telaga Tujuh",
                "kodepos": "20373"
            },
            {
                "kecamatan": "Labuhan Deli",
                "kelurahan": "Helvetia",
                "kodepos": "20373"
            },
            {
                "kecamatan": "Labuhan Deli",
                "kelurahan": "Karang Gading",
                "kodepos": "20373"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Tanjung Sari",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Tumpatan Nibung",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Sena",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Sidodadi",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Sugiharjo",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Mesjid",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Paya Gambar",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Bintang Meriah",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Baru (kampung Baru)",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Bintang Meriah",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Tembung",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Batang Kuis",
                "kelurahan": "Bakaran Batu",
                "kodepos": "20372"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Sei Rotan",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Sumber Rejo Timur",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Percut",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Saentis",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Sampali",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Pematang Lalang",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Laut Dendang",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Medan Estate",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Kenangan Baru",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Kolam",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Cinta Damai (Dame)",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Cinta Rakyat",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Kenangan",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Bandar Khalipah Kebon",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Bandar Setia",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Amplas",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Percut Sei Tuan",
                "kelurahan": "Bandar Khalifah (Klippa)",
                "kodepos": "20371"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Tadukan Raga",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Tala Peta",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Telun Kenas",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Rambai",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Siguci",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Sumbul",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Negara Beringin/Bringin",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Penungkiren",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Lau Rempah",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Limau Mungkur",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Kuta Jurung",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Lau Barus Baru",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Lau Rakit",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Juma/Jumma Tombak",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Sinembah Tanjung Muda Hilir",
                "kelurahan": "Gunung Rintis",
                "kodepos": "20363"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Telaga Sari",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Ujung Serdang",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Wono Sari",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Tanjung Morawa B",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Sei/Sungai Merah",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Tanjung Baru",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Tanjung Morawa A",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Perdamean (Perdamaian)",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Punden Rejo",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Pekan Tanjung Morawa",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Penara Kebon/Kebun",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Limau Manis",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Medan Sinembah",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Naga Timbul",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Lengau Serpang",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Dalu X B (Dalu Sepuluh B)",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Dagang Krawan (Kerawan)",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Dalu X A (Dalu Sepuluh A)",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Dagang Kelambir",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Bangun Sari Baru",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Buntu Badimbar (Bedimbar)",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Bangun Sari",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Bangun Rejo",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Bandar Labuhan",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Tanjung Morawa",
                "kelurahan": "Aek Pancur",
                "kodepos": "20362"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Patumbak Satu",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Sigara Gara",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Marindal Satu",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Patumbak Dua",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Patumbak Kampung",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Lantasan Lama",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Marindal Dua",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Tanjung Sena",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Patumbak",
                "kelurahan": "Lantasan Baru",
                "kodepos": "20361"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Selamat (Kampung Selamat)",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Sidodadi",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Sidomulyo",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Rumah Gerat",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Sari Laba Jahe",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Penen",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Per Ria Ria",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Namo Tualang",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Mbaruae (Mbaruai)",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Namo Suro Baru",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Kuta Mulyo",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Madinding Julu",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Candi Rejo",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Kuala Dekah",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Biru-Biru",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Tanjung Beringin",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Ujung Deleng",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Biru-Biru",
                "kelurahan": "Aji Baho",
                "kodepos": "20358"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Suka Makmur",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Tambunen",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Sibolangit",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Sikeben",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Suka Maju",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Sayum Sabah",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Sembahe",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Rumah Pil-Pil",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Rumah Sumbul",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Sala Bulan",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Rambung Baru",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Rumah Kinangkung Sp",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Martelu",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Negri Gugung",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Puang Aja",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Ketangkuhen",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Kuala",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Cinta Rakyat",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Durin Serugun",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Bukum",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Buluh Awar",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Bingkawan",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Buah Nabar",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Bengkurung",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Betimus Baru",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Batu Layang",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Batu Mbelin",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Uruk Gedang",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Sibolangit",
                "kelurahan": "Bandar Baru",
                "kodepos": "20357"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Timbang Lawan",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Ujung Labuhen",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Suka Mulia Hulu",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Tangkahan",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Sudi Rejo",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Suka Mulia Hilir",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Salang Tungir",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Silue Lue",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Rumah Mbacang",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Rambe",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Rumah Keben",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Pinang",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Mungkur (Rimo Mungkur)",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Pakam",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Landur",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Mbarao (Mbaru)",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Mbelin",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Namo Batang",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Lau Mulgap",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Lubang Ido",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Kuta Tengah",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Kuta Tuala (Tualah)",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Jaba",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Jati Kusuma (Kesuma)",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Kuala Simeme",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Gunung Klawas (Kelawas)",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Deli Tua",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Gunung Berita",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Batu Rejo",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Bekukul",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Cinta Rakyat",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Batu Penjemuran",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Batu Gemuk",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Namo Rambe",
                "kelurahan": "Batu Mbelin",
                "kodepos": "20356"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Mekar Sari",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Suka Makmur",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Deli Tua Barat",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Deli Tua Timur",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Kedai Durian",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Suka Rende",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Deli Tua",
                "kelurahan": "Deli Tua",
                "kodepos": "20355"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Suka Dame",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Suka Makmur",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Sawit Rejo",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Sei Mencirim",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Silebo Lebo",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Perpanden",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Sampe Gita (Cita)",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Namo Rube Julu",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Pasar X",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Lau Bakeri",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Namo Mirik",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Kuala Lau Bicik",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Kutalimbaru",
                "kelurahan": "Kuta Limbaru",
                "kodepos": "20354"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Tuntungan II",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Tengah (Kampung Tengah)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Tiang Layar",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Tuntungan I",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Suka Raya",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Tanjung Anom",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Sembahe Baru",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Simalingkar A",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Sugou (Sugau)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Salam Tani",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Sei Glugur (Gelugur)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Pertampilan (Pertampilen)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Perumnas Simalingkar",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Namo Riam",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Namo Rih",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Namo Simpur",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Lama",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Namo Bintang",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Durin Simbelang A",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Gunung Tinggi",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Hulu (Kampung Hulu)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Bintang Meriah",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Dorin Tonggal (Durin Tunggal)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Durian Jangak",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Tunggal Kanan (Sungal Kanan)",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Pancur Batu",
                "kelurahan": "Baru (Desa Baru)",
                "kodepos": "20353"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Tanjung Gusta",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Telaga Sari",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Suka Maju",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Sukajadi (Serba Jadi)",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Sei Mencirim",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Sei Semayang",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Sm Diski",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Purwodadi",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Sei Beras Sekata",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Mulyo Rejo",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Paya Geli",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Puji Mulyo",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Helvetia",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Kampung Lalang",
                "kodepos": "20351"
            },
            {
                "kecamatan": "Sunggal",
                "kelurahan": "Medan Krio",
                "kodepos": "20351"
            }
        ],
        "k475": [
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Nelayan Indah",
                "kodepos": "20524"
            },
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Tangkahan",
                "kodepos": "20525"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Belawan Bahagia",
                "kodepos": "20415"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Belawan Bahari",
                "kodepos": "20414"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Belawan Sicanang",
                "kodepos": "20412"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Bagan Deli",
                "kodepos": "20414"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Belawan I",
                "kodepos": "20411"
            },
            {
                "kecamatan": "Medan Belawan Kota",
                "kelurahan": "Belawan II",
                "kodepos": "20412"
            },
            {
                "kecamatan": "Medan Marelan",
                "kelurahan": "Terjun",
                "kodepos": "20256"
            },
            {
                "kecamatan": "Medan Marelan",
                "kelurahan": "Labuhan Deli",
                "kodepos": "20254"
            },
            {
                "kecamatan": "Medan Marelan",
                "kelurahan": "Rengas Pulau",
                "kodepos": "20255"
            },
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Sei Mati",
                "kodepos": "20252"
            },
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Pekan Labuhan",
                "kodepos": "20253"
            },
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Martubung",
                "kodepos": "20252"
            },
            {
                "kecamatan": "Medan Labuhan",
                "kelurahan": "Besar",
                "kodepos": "20251"
            },
            {
                "kecamatan": "Medan Marelan",
                "kelurahan": "Paya Pasir",
                "kodepos": "20250"
            },
            {
                "kecamatan": "Medan Marelan",
                "kelurahan": "Tanah Enam Ratus",
                "kodepos": "20245"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Titi Papan",
                "kodepos": "20244"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Kota Bangun",
                "kodepos": "20243"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Mabar Hilir",
                "kodepos": "20242"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Tanjung Mulia Hilir",
                "kodepos": "20241"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Mabar",
                "kodepos": "20242"
            },
            {
                "kecamatan": "Medan Deli",
                "kelurahan": "Tanjung Mulia",
                "kodepos": "20241"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Pulo Brayan Darat II",
                "kodepos": "20239"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Pulo Brayan Darat I",
                "kodepos": "20239"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Pulo Brayan Bengkel Baru",
                "kodepos": "20239"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Glugur Darat II",
                "kodepos": "20238"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Pulo Brayan Bengkel",
                "kodepos": "20239"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Glugur Darat I",
                "kodepos": "20238"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Tegal Rejo",
                "kodepos": "20237"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sidorame Barat II",
                "kodepos": "20236"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sidorame Timur",
                "kodepos": "20236"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sidorame Barat I",
                "kodepos": "20236"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Gaharu",
                "kodepos": "20235"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Durian",
                "kodepos": "20235"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Sidodadi",
                "kodepos": "20234"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sei Kerah Hilir II",
                "kodepos": "20233"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sei Kera Hulu",
                "kodepos": "20233"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Sei Kerah Hilir I",
                "kodepos": "20233"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Pahlawan",
                "kodepos": "20233"
            },
            {
                "kecamatan": "Medan Perjuangan",
                "kelurahan": "Pandau Hilir",
                "kodepos": "20232"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Perintis",
                "kodepos": "20231"
            },
            {
                "kecamatan": "Medan Timur",
                "kelurahan": "Gang Buntu",
                "kodepos": "20231"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Amplas",
                "kodepos": "20229"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Medan Tenggara",
                "kodepos": "20228"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Binjai",
                "kodepos": "20228"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Tegal Sari Mandala III",
                "kodepos": "20226"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Denai",
                "kodepos": "20227"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Tegal Sari Mandala II",
                "kodepos": "20226"
            },
            {
                "kecamatan": "Medan Denai",
                "kelurahan": "Tegal Sari Mandala I",
                "kodepos": "20226"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Tembung",
                "kodepos": "20225"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Bantan Timur",
                "kodepos": "20224"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Bantan",
                "kodepos": "20224"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Bandar Selamat",
                "kodepos": "20223"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Sidorejo Hilir",
                "kodepos": "20222"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Indra Kasih",
                "kodepos": "20221"
            },
            {
                "kecamatan": "Medan Tembung",
                "kelurahan": "Sidorejo",
                "kodepos": "20222"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Sitirejo II",
                "kodepos": "20219"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Sitirejo III",
                "kodepos": "20219"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Siti Rejo I",
                "kodepos": "20219"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Sudi Rejo I",
                "kodepos": "20218"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Sudi Rejo II",
                "kodepos": "20218"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Teladan Barat",
                "kodepos": "20217"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Teladan Timur",
                "kodepos": "20217"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Pasar Merah Barat",
                "kodepos": "20217"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Tegal Sari III",
                "kodepos": "20216"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Pasar Merah Timur",
                "kodepos": "20217"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Tegal Sari I",
                "kodepos": "20216"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Tegal Sari II",
                "kodepos": "20216"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Sukaramai I",
                "kodepos": "20216"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Sukaramai II",
                "kodepos": "20216"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Kota Matsum IV",
                "kodepos": "20215"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Kota Matsum III",
                "kodepos": "20215"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Kota Matsum I",
                "kodepos": "20215"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Kota Matsum II",
                "kodepos": "20215"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Sei Rengas Permata",
                "kodepos": "20214"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Sei Rengas II",
                "kodepos": "20214"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Sei Rengas I",
                "kodepos": "20214"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Pusat Pasar",
                "kodepos": "20212"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Mesjid",
                "kodepos": "20213"
            },
            {
                "kecamatan": "Medan Area",
                "kelurahan": "Pandau Hulu II",
                "kodepos": "20211"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Pasar Baru",
                "kodepos": "20212"
            },
            {
                "kecamatan": "Medan Kota",
                "kelurahan": "Pandau Hulu I",
                "kodepos": "20211"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Suka Raja",
                "kodepos": "20159"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Kampung Baru",
                "kodepos": "20158"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Sei Mati",
                "kodepos": "20159"
            },
            {
                "kecamatan": "Medan Polonia",
                "kelurahan": "Sari Rejo",
                "kodepos": "20157"
            },
            {
                "kecamatan": "Medan Polonia",
                "kelurahan": "Suka Damai",
                "kodepos": "20157"
            },
            {
                "kecamatan": "Medan Polonia",
                "kelurahan": "Polonia",
                "kodepos": "20157"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Titi Rantai/Rante",
                "kodepos": "20156"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Merdeka",
                "kodepos": "20154"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Padang Bulan",
                "kodepos": "20155"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Babura",
                "kodepos": "20154"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Darat",
                "kodepos": "20153"
            },
            {
                "kecamatan": "Medan Baru",
                "kelurahan": "Petisah Hulu",
                "kodepos": "20153"
            },
            {
                "kecamatan": "Medan Polonia",
                "kelurahan": "Madras Hulu",
                "kodepos": "20152"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Jati",
                "kodepos": "20152"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Hamdan",
                "kodepos": "20151"
            },
            {
                "kecamatan": "Medan Polonia",
                "kelurahan": "Anggrung",
                "kodepos": "20152"
            },
            {
                "kecamatan": "Medan Maimun",
                "kelurahan": "Aur",
                "kodepos": "20151"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Timbang Deli",
                "kodepos": "20148"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Bangun Mulia",
                "kodepos": "20149"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Harjosari II",
                "kodepos": "20147"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Titi Kuning",
                "kodepos": "20146"
            },
            {
                "kecamatan": "Medan Amplas",
                "kelurahan": "Harjosari I",
                "kodepos": "20147"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Kedai Durian",
                "kodepos": "20145"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Suka Maju",
                "kodepos": "20146"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Pangkalan Masyhur",
                "kodepos": "20143"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Gedung Johor",
                "kodepos": "20144"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Mangga",
                "kodepos": "20141"
            },
            {
                "kecamatan": "Medan Johor",
                "kelurahan": "Kwala Bekala",
                "kodepos": "20142"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Sidomulyo",
                "kodepos": "20137"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Ladang Bambu",
                "kodepos": "20138"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Lau Cih",
                "kodepos": "20136"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Namu Gajah",
                "kodepos": "20136"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Simalingkar B",
                "kodepos": "20135"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Simpang Selayang",
                "kodepos": "20135"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Kemenangan Tani",
                "kodepos": "20136"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Tanjung Sari",
                "kodepos": "20132"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Asam Kumbang",
                "kodepos": "20133"
            },
            {
                "kecamatan": "Medan Tuntungan",
                "kelurahan": "Tanjung Selamat",
                "kodepos": "20134"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Padang Bulan Selayang II",
                "kodepos": "20131"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Sempakata",
                "kodepos": "20132"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Beringin",
                "kodepos": "20131"
            },
            {
                "kecamatan": "Medan Selayang",
                "kelurahan": "Padang Bulan Selayang I",
                "kodepos": "20131"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Lalang",
                "kodepos": "20127"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Sunggal",
                "kodepos": "20128"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Tanjung Gusta",
                "kodepos": "20125"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Helvetia",
                "kodepos": "20124"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Cinta Damai",
                "kodepos": "20126"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Dwi Kora",
                "kodepos": "20123"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Helvetia Tengah",
                "kodepos": "20124"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Tanjung Rejo",
                "kodepos": "20122"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Helvetia Timur",
                "kodepos": "20124"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Sei Sikambing B",
                "kodepos": "20122"
            },
            {
                "kecamatan": "Medan Helvetia",
                "kelurahan": "Sei Sikambing C II",
                "kodepos": "20123"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Simpang Tanjung",
                "kodepos": "20122"
            },
            {
                "kecamatan": "Medan Sunggal",
                "kelurahan": "Babura Sunggal",
                "kodepos": "20121"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sei Putih Timur II",
                "kodepos": "20118"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sei Sikambing D",
                "kodepos": "20119"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sei Putih Tengah",
                "kodepos": "20118"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sei Putih Timur I",
                "kodepos": "20118"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Sei Agul",
                "kodepos": "20117"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sei Putih Barat",
                "kodepos": "20118"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Glugur Kota",
                "kodepos": "20115"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Pulo Brayan Kota",
                "kodepos": "20116"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Karang Berombak",
                "kodepos": "20117"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Silalas",
                "kodepos": "20114"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Petisah Tengah",
                "kodepos": "20112"
            },
            {
                "kecamatan": "Medan Petisah",
                "kelurahan": "Sekip",
                "kodepos": "20113"
            },
            {
                "kecamatan": "Medan Barat",
                "kelurahan": "Kesawan",
                "kodepos": "20111"
            }
        ]
    }
}

function userState(state = intialState, action) {
    switch (action.type) {
        default: {
            return state
        }
    }
}

export default userState

